export const exportActionsTypes = {
  GET_EXPORT: 'GET_EXPORT',
  SAVE_EXPORT_RESPONSE: 'SAVE_EXPORT_RESPONSE',
  CLEAR_EXPORT: 'CLEAR_EXPORT',
  ADD_COLUMNS: 'ADD_COLUMNS',
  CLER_COLUMNS: 'CLER_COLUMNS'
}

export const exportActions = {
  getExport: (url, params, headers) => ({
    type: exportActionsTypes.GET_EXPORT,
    url,
    params,
    headers
  }),
  saveexportResponse: data => ({
    type: exportActionsTypes.SAVE_EXPORT_RESPONSE,
    data
  }),
  clearPosts: () => ({
    type: exportActionsTypes.CLEAR_EXPORT
  }),
  addColumnsIba: data => ({
    type: exportActionsTypes.ADD_COLUMNS,
    data
  }),
  clearColsIba: () => ({
    type: exportActionsTypes.CLER_COLUMNS
  })
}
