import {
  addMerchantCLTypes,
  merchantActionsTypes,
  merchantDeleteTypes,
  saveMerchantTypes,
  merchantGetDetailsTypes,
  editMerchantTypes,
  addMerchantUploadTypes
} from '../actions'

export const addMerchantCLInitialState = {
  list: null
}
export const addMerchantCLStoreKey = 'addMerchantCLStore'

export const addMerchantCLReducer = (state = addMerchantCLInitialState, action) => {
  switch (action.type) {
    case addMerchantCLTypes.REQUEST:
      return { ...state, loadingAMCL: true }
    case addMerchantCLTypes.RESPONSE:
      return { ...state, dataAMCL: action.data, statusAMCL: action.data.status, messageAMCL: action.data.message, loadingAMCL: false }
    case addMerchantCLTypes.CLEAR:
      return { ...state, dataAMCL: null, messageAMCL: '', statusAMCL: '' }
    default:
      return state
  }
}

export const merchantSaveKey = 'merchantSaveStore'

export const saveMerchantReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case saveMerchantTypes.REQUEST:
      return { ...state, loadingSM: true }
    case saveMerchantTypes.RESPONSE:
      return {
        ...state,
        saveMerchant: res.data,
        saveMerchantStatus: res.status,
        saveMerchantMessage: res.message,
        loadingSM: false
      }
    case saveMerchantTypes.CLEAR:
      return { ...state, saveMerchant: null, saveMerchantStatus: '', saveMerchantMessage: '' }
    default:
      return state
  }
}

export const merchantInitialState = {
  list: null
}

export const merchantStoreKey = 'merchantStore'

export const merchantReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case merchantActionsTypes.GET_MERCHANT:
      return { ...state, loading: true }
    case merchantActionsTypes.SAVE_MERCHANT_RESPONSE:
      return { ...state, getMerchant: res.data, loading: false }
    case merchantActionsTypes.CLEAR_MERCHANT:
      return { ...state, getMerchant: null }
    case merchantGetDetailsTypes.GET_MERCHANT_DETAILS:
      return { ...state, loadingGMD: true }
    case merchantGetDetailsTypes.MERCHANT_DETAILS_RESPONSE:
      return {
        ...state,
        merchantGetDetails: action.data && action.data.data,
        statusGMD: action.data && action.data.status,
        messagesGMD: action.data && action.data.message,
        loadingGMD: false
      }
    case merchantGetDetailsTypes.SET_CURRENT_CLIENT_ID:
      return { ...state, currentClientId: action.payload }
    case merchantGetDetailsTypes.CLEAR_MERCHANT_DETAILS:
      return { ...state, statusGMD: '', messagesGMD: '' }
    default:
      return state
  }
}

export const merchantDeleteStoreKey = 'merchantDeleteStore'

export const deleteMerchantReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case merchantDeleteTypes.DELETE_MERCHANT:
      return { ...state, loading: true }
    case merchantDeleteTypes.SAVE_DELETE_MERCHANT_RESPONSE:
      return {
        ...state,
        deleteMerchant: res.data,
        deleteMerchantStatus: res.status,
        deleteMerchantMessage: res.message,
        loading: false
      }
    case merchantDeleteTypes.CLEAR_DELETE_MERCHANT:
      return { ...state, deleteMerchant: null, deleteMerchantStatus: '', deleteMerchantMessage: '' }
    default:
      return state
  }
}

export const editMerchantStoreKey = 'editMerchantStore'

export const editMerchantReducer = (state = {}, action) => {
  switch (action.type) {
    case editMerchantTypes.REQUEST:
      return { ...state, loadingEMD: true }
    case editMerchantTypes.RESPONSE:
      return {
        ...state,
        dataEMD: action.data,
        statusEMD: action.data.status,
        messageEMD: action.data.message,
        loadingEMD: false
      }
    case editMerchantTypes.CLEAR:
      return { ...state, dataEMD: null, messageEMD: '', statusEMD: '' }
    default:
      return state
  }
}

export const addMerchantUploadInitialState = {
  list: null
}
export const addMerchantUploadStoreKey = 'addMerchantUploadStore'

export const addMerchantUploadReducer = (state = addMerchantUploadInitialState, action) => {
  switch (action.type) {
    case addMerchantUploadTypes.REQUEST:
      return { ...state, loadingAMUpload: true }
    case addMerchantUploadTypes.RESPONSE:
      return { ...state, dataAMUpload: action.data && action.data.data, statusAMUpload: action.data.status, messageAMUpload: action.data.message, loadingAMUpload: false }
    case addMerchantUploadTypes.CLEAR:
      return { ...state, dataAMUpload: null, messageAMUpload: '', statusAMUpload: '' }
    default:
      return state
  }
}
