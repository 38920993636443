import { USER_ERROR } from '../../../utils/constants'

export const validate = (values, setErrors) => {
  const errors = {}
  if (!values.fileUpload) {
    errors.fileUpload = USER_ERROR.UPLOAD_FILE
  }
  setErrors(errors)
  return errors
}

export const validate2 = (values, setErrors) => {
  const errors = {}
  if (!values.midUpload) {
    errors.midUpload = USER_ERROR.FILE
  }
  setErrors(errors)
  return errors
}

export const validate3 = (values, setErrors) => {
  const errors = {}
  if (!values.companyId) {
    errors.companyId = USER_ERROR.COMPANYNAME
  }
  if (!values.merchantId) {
    errors.merchantId = USER_ERROR.MERCHANT_ID
  }
  if (!values.atUpload) {
    errors.atUpload = USER_ERROR.FILE
  }
  setErrors(errors)
  return errors
}
