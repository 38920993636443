export const getRootCauseActionsTypes = {
  REQUEST: 'GET_ROOT_CAUSE_REQUEST',
  RESPONSE: 'GET_ROOT_CAUSE_RESPONSE',
  ERROR: 'GET_ROOT_CAUSE_ERROR',
  CLEAR: 'GET_ROOT_CAUSE_CLEAR'
}

export const getRootCauseActions = {
  get: (params, headers) => ({
    type: getRootCauseActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getRootCauseActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getRootCauseActionsTypes.CLEAR
  })
}

export const getFunctionalActionsTypes = {
  REQUEST: 'GET_FUNCTIONAL_REQUEST',
  RESPONSE: 'GET_FUNCTIONAL_RESPONSE',
  ERROR: 'GET_FUNCTIONAL_ERROR',
  CLEAR: 'GET_FUNCTIONAL_CLEAR'
}

export const getFunctionalActions = {
  get: (params, headers) => ({
    type: getFunctionalActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getFunctionalActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getFunctionalActionsTypes.CLEAR
  })
}

export const getSubFunactionalActionsTypes = {
  REQUEST: 'GET_SUB_FUNCTIONAL_REQUEST',
  RESPONSE: 'GET_SUB_FUNCTIONAL_RESPONSE',
  ERROR: 'GET_SUB_FUNCTIONAL_ERROR',
  CLEAR: 'GET_SUB_FUNCTIONAL_CLEAR'
}

export const getSubFunactionalActions = {
  get: (params, headers) => ({
    type: getSubFunactionalActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getSubFunactionalActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getSubFunactionalActionsTypes.CLEAR
  })
}

export const getOperationalTypeActionsTypes = {
  REQUEST: 'GET_OPERATIONAL_TYPES_REQUEST',
  RESPONSE: 'GET_OPERATIONAL_TYPES_RESPONSE',
  ERROR: 'GET_OPERATIONAL_TYPES_ERROR',
  CLEAR: 'GET_OPERATIONAL_TYPES_CLEAR'
}

export const getOperationalTypeActions = {
  get: (params, headers) => ({
    type: getOperationalTypeActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getOperationalTypeActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getOperationalTypeActionsTypes.CLEAR
  })
}
