export const ForgotPasswordActionsTypes = {
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  SAVE_FORGOT_PASSWORD_RESPONSE: 'SAVE_FORGOT_PASSWORD_RESPONSE',
  CLEAR_FORGOT_PASSWORD: 'CLEAR_FORGOT_PASSWORD'
}

export const ForgotActions = {
  forgotPassword: (data, headers) => {
    return {
      type: ForgotPasswordActionsTypes.FORGOT_PASSWORD,
      payload: data, headers
    }
  },
  saveForgotPasswordResponse: (data) => {
    return {
      type: ForgotPasswordActionsTypes.SAVE_FORGOT_PASSWORD_RESPONSE,
      data
    }
  },
  clearForgotPassword: () => ({
    type: ForgotPasswordActionsTypes.CLEAR_FORGOT_PASSWORD
  })
}

export const VerifyUserActionsTypes = {
  VERIFY_USER: 'VERIFY_USER',
  SAVE_VERIFY_USER_RESPONSE: 'SAVE_VERIFY_USER_RESPONSE',
  CLEAR_VERIFY_USER: 'CLEAR_VERIFY_USER'
}

export const VerifyActions = {
  verifyUser: (data, headers) => {
    return {
      type: VerifyUserActionsTypes.VERIFY_USER,
      payload: data, headers
    }
  },
  saveVerifyUserResponse: (data) => {
    return {
      type: VerifyUserActionsTypes.SAVE_VERIFY_USER_RESPONSE,
      data
    }
  },
  clearVerifyUser: () => ({
    type: VerifyUserActionsTypes.CLEAR_VERIFY_USER
  })
}

export const ResetPasswordActionsTypes = {
  RESET_PASSWORD: 'RESET_PASSWORD',
  SAVE_RESET_PASSWORD_RESPONSE: 'SAVE_RESET_PASSWORD_RESPONSE',
  CLEAR_RESET_PASSWORD: 'CLEAR_RESET_PASSWORD'
}

export const ResetPasswordActions = {
  resetPassword: (data, headers) => {
    return {
      type: ResetPasswordActionsTypes.RESET_PASSWORD,
      payload: data, headers
    }
  },
  saveResetPasswordResponse: (data) => {
    return {
      type: ResetPasswordActionsTypes.SAVE_RESET_PASSWORD_RESPONSE,
      data
    }
  },
  clearResetPassword: () => ({
    type: ResetPasswordActionsTypes.CLEAR_RESET_PASSWORD
  })
}

export const ChangePasswordActionsTypes = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SAVE_CHANGE_PASSWORD_RESPONSE: 'SAVE_CHANGE_PASSWORD_RESPONSE',
  CLEAR_CHANGE_PASSWORD: 'CLEAR_CHANGE_PASSWORD'
}

export const ChangePasswordActions = {
  changePassword: (data, headers) => {
    return {
      type: ChangePasswordActionsTypes.CHANGE_PASSWORD,
      payload: data, headers
    }
  },
  saveChangePasswordResponse: (data) => {
    return {
      type: ChangePasswordActionsTypes.SAVE_CHANGE_PASSWORD_RESPONSE,
      data
    }
  },
  clearChangePassword: () => ({
    type: ChangePasswordActionsTypes.CLEAR_CHANGE_PASSWORD
  })
}
