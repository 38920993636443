/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import Topbar from "./Topbar";
import _ from "lodash";
import color from "../../../../utils/colors";
import { headersWithAuth } from "../../../../utils/helper";
import { connect } from "react-redux";
import {
  clientCredFilterActions,
  merchantCredFilterActions,
  midCredFilterActions,
  partnerCredFilterActions,
} from "../../../../store/actions";
import {
  setLocalStorage,
  removeLocalStorage,
  getLocalStorageMenu,
} from "../../../../utils/helper";
import ReactSelect from "../../../../theme/layout/components/ReactSelect";
import {
  AUTH_BASED_FILTER,
  GET_AUTH_BASED_FILTER,
  SET_STORAGE
} from "../../../../utils/constants";

function HeaderWrapper(props) {
  const {
    getPartnerDispatch,
    getPartner,
    getClientDispatch,
    clearClientDispatch,
    getClient,
    getMerchantDispatch,
    clearMerchantDispatch,
    getMerchant,
    getMIDCodeDispatch,
    clearMIDCodeDispatch,
    getmidCRM,
    setFilterFunctionDispatch,
    loadingClient,
    loadingMerchant,
    loadingMID,
    setCredFilterParamsDispatch,
  } = props;
  const didMount = React.useRef(false);
  const pathName = useLocation().pathname;
  const [selectedPartnerOption, setSelectedPartnerOption] = useState([] as any);
  const [partnerOption, setPartnerOption] = useState([] as any);
  const [selectedClientOption, setSelectedClientOption] = useState([] as any);
  const [clientOption, setClientOption] = useState([] as any);
  const [selectedMerchantOption, setSelectedMerchantOption] = useState(
    [] as any
  );
  const [merchantOption, setMerchantOption] = useState([] as any);
  const [selectedMIDOption, setSelectedMIDOption] = useState([] as any);
  const [midOption, setMIDOption] = useState([] as any);
  const [onBlurOptions, setOnBlurOptions] = useState({
    clientId: false,
    merchantId: false
  } as any);
  const [formData, setFormData] = useState({
    partner: "",
    clientId: "",
    merchantId: "",
    mid: "",
  });
  const [typingTimeout, setTypingTimeout] = useState<any>(0);
  const { config, classes, attributes } = useLayout();
  const { header, aside } = config;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      alignItems: "baseline",
      background: "#fff",
      minHeight: "35px",
      border: "solid 0px",
      borderBottom: "solid 1px",
      boxShadow: "0 0 0 1px #fff",
      marginBottom: "0px",
      "&:hover": {
        border: "#fff",
        borderBottom: "solid 1px",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#cfc3c3",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      width: "0px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
      borderColor: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1em",
      color: "black",
      fontWeight: 200,
    }),
  };

  const getAuthDetails = getLocalStorageMenu(SET_STORAGE.ATTRIBUTE_DEATILS)
  const getAttributes = getAuthDetails && getAuthDetails ? JSON.parse(getAuthDetails) : null

  const checkUserRole = () => {
    const atributes = getAttributes && getAttributes.showAttribute ? getAttributes.showAttribute : [];
    setLocalStorage(SET_STORAGE.CLIENT_ATTRIBUTE_DEATILS, JSON.stringify(atributes));
    let type = "All";
    atributes.forEach((n: any) => {
      if (atributes.length == 2 && ['Client', 'Merchant'].includes(n)) {
        type = "Client"
      }
      if (atributes.length == 1 && ['Merchant'].includes(n)) {
        type = "Merchant"
      }
    });
    return type
  }

  const resetOptions = () => {
    clearClientDispatch();
    clearMerchantDispatch();
    clearMIDCodeDispatch();
    setSelectedPartnerOption([]);
    setSelectedClientOption([]);
    setClientOption([]);
    setSelectedMerchantOption([]);
    setMerchantOption([]);
    setMIDOption([]);
    setSelectedMIDOption([]);
    // if (formData.partner) {
    setFilterFunctionDispatch(true);
    // }
    setFormData({
      partner: "",
      clientId: "",
      merchantId: "",
      mid: "",
    });
    setCredFilterParamsDispatch({});
    removeLocalStorage(AUTH_BASED_FILTER.PARTNER);
    removeLocalStorage(AUTH_BASED_FILTER.CLIENT);
    removeLocalStorage(AUTH_BASED_FILTER.MERCHANT);
    removeLocalStorage(AUTH_BASED_FILTER.MID);
    removeLocalStorage(GET_AUTH_BASED_FILTER.PARTNER);
    removeLocalStorage(GET_AUTH_BASED_FILTER.CLIENT);
    removeLocalStorage(GET_AUTH_BASED_FILTER.MERCHANT);
    removeLocalStorage(GET_AUTH_BASED_FILTER.MID);

    if (checkUserRole() === 'All') {
      const param = {
        skipPagination: true
      }
      getPartnerDispatch(param, headersWithAuth);
      getClientDispatch(null, headersWithAuth);
      getMIDCodeDispatch(null, headersWithAuth);
    }
    if (checkUserRole() === 'Client') {
      const param = {
        partnerId: getAttributes && getAttributes.partnerId ? getAttributes.partnerId : ''
      };
      getClientDispatch(param, headersWithAuth);
    }
    if (checkUserRole() === 'Merchant') {
      const param = {
        skipPagination: true
      }
      getMIDCodeDispatch(param, headersWithAuth);
    }
  };

  const getDefaultPartnerOption = (data: any) => {
    const defaultOptions = [] as any[];
    if (!_.isEmpty(data)) {
      data.map((item: any) =>
        defaultOptions.push({
          label: item.company ? item.company : "",
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  const getDefaultMidOption = (array) => {
    const data = array && array.data ? array.data : [];
    const defaultOptions = [] as any[];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item.merchantId && item.merchantId.merchantName
            ? item.merchantId.merchantName
            : "--"
            } (${item.midNumber ? item.midNumber : "--"})`,
          midValue: item._id,
          value:
            item.merchantId && item.merchantId._id ? item.merchantId._id : "",
        })
      );
      return defaultOptions;
    }
  };

  useEffect(() => {
    if (didMount.current) {
      let data = getDefaultPartnerOption(getPartner);
      if (data && data.length > 0) {
        data = data.filter((dt) => dt.label && dt.label.length > 0);
      }
      setPartnerOption(data);
    }
  }, [getPartner]);

  useEffect(() => {
    if (didMount.current) {
      let data = getDefaultMidOption(getmidCRM);
      if (data && data.length > 0) {
        data = data.filter((dt) => dt.label && dt.label.length > 0);
      }
      setMerchantOption(data);
    }
  }, [getmidCRM]);

  const handleSelectBlur = (e: any, name: any) => {
    typingTimeout && clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (name === 'clientId' && onBlurOptions.clientId) {
          const param = { limit: 25 };
          getClientDispatch(param, headersWithAuth);
          setOnBlurOptions((values: any) => ({ ...values, clientId: false }));
        }
        if (name === 'merchantId' && onBlurOptions.merchantId) {
          const param = { limit: 25 };
          getMIDCodeDispatch(param, headersWithAuth)
          setOnBlurOptions((values: any) => ({ ...values, merchantId: false }));
        }
      }, 1000)
    );
  }

  const handleInputChange = (val: any, name: any) => {
    if (val && val.length >= 3) {
      typingTimeout && clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          if (name === 'clientId' && !(selectedPartnerOption && selectedPartnerOption.value)) {
            const param = { company: val };
            getClientDispatch(param, headersWithAuth);
            setOnBlurOptions((values: any) => ({ ...values, clientId: true }));
          }
          if (name === 'merchantId' && !(selectedClientOption && selectedClientOption.value)) {
            const param = { skipPagination: true, }
            getMIDCodeDispatch(param, headersWithAuth)
            setOnBlurOptions((values: any) => ({ ...values, merchantId: true }));
          }
        }, 1000)
      );
    }
  }

  const handleChangePartner = (selectedOption: any) => {
    setSelectedClientOption(null);
    setSelectedMerchantOption(null);
    setSelectedMIDOption(null);
    if (selectedOption !== null) {
      setSelectedPartnerOption(selectedOption);
      setFormData((values) => ({ ...values, partner: selectedOption.value }));
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      if (getPartner && getPartner.length == 0) {
        setTypingTimeout(
          setTimeout(() => {
            const param = {
              company: selectedOption.label,
            };
            getPartnerDispatch(param, headersWithAuth);
          }, 1000)
        );
      }
      if (getPartner && getPartner.length > 0) {
        setLocalStorage(AUTH_BASED_FILTER.PARTNER, selectedOption.value);
        setLocalStorage(
          GET_AUTH_BASED_FILTER.PARTNER,
          JSON.stringify(selectedOption)
        );
        setTypingTimeout(
          setTimeout(() => {
            const param = {
              partnerId: selectedOption.value,
            };
            getClientDispatch(param, headersWithAuth);
          }, 1000)
        );
      }
    } else {
      removeLocalStorage(AUTH_BASED_FILTER.PARTNER);
      removeLocalStorage(GET_AUTH_BASED_FILTER.PARTNER);
      setSelectedPartnerOption([] as any[]);
      setFormData((values) => ({
        ...values,
        partner: "",
        clientId: "",
        merchantId: "",
        mid: "",
      }));
    }
  };

  const handleChangeClient = (selectedOption: any) => {
    setSelectedMerchantOption(null);
    setSelectedMIDOption(null);
    if (selectedOption !== null) {
      setSelectedClientOption(selectedOption);
      setLocalStorage(AUTH_BASED_FILTER.CLIENT, selectedOption.value);
      setLocalStorage(
        GET_AUTH_BASED_FILTER.CLIENT,
        JSON.stringify(selectedOption)
      );
      setFormData((values) => ({ ...values, clientId: selectedOption.value }));
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      if (getClient && getClient.result && getClient.result.length > 0) {
        setTypingTimeout(
          setTimeout(() => {
            const param = {
              clientId: selectedOption.value,
            };
            getMerchantDispatch(param, headersWithAuth);
            const midParam = {
              clientId: selectedOption.value,
            };
            getMIDCodeDispatch(midParam, headersWithAuth);
          }, 1000)
        );
      }
    } else {
      removeLocalStorage(AUTH_BASED_FILTER.CLIENT);
      removeLocalStorage(GET_AUTH_BASED_FILTER.CLIENT);
      setSelectedClientOption([] as any[]);
      setFormData((values) => ({
        ...values,
        clientId: "",
        merchantId: "",
        mid: "",
      }));
    }
  };

  const handleChangeMerchant = (selectedOption: any) => {
    setSelectedMIDOption(null);
    if (selectedOption !== null) {
      setSelectedMerchantOption(selectedOption);
      setLocalStorage(AUTH_BASED_FILTER.MERCHANT, selectedOption.value);
      setLocalStorage(AUTH_BASED_FILTER.MID, selectedOption.midValue);
      setLocalStorage(
        GET_AUTH_BASED_FILTER.MERCHANT,
        JSON.stringify(selectedOption)
      );
      setFormData((values) => ({
        ...values,
        merchantId: selectedOption.value,
        mid: selectedOption.midValue,
      }));
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      if (getMerchant && getMerchant.result && getMerchant.result.length > 0) {
        const getClientIdselOption = _.filter(getMerchant.result, function (x) {
          if (selectedOption.label === x.merchantName) {
            return x;
          }
        });
        if (getClientIdselOption && getClientIdselOption.length > 0) {
          setTypingTimeout(
            setTimeout(() => {
              const getClientId =
                getClientIdselOption &&
                getClientIdselOption[0] &&
                getClientIdselOption[0].clientId &&
                getClientIdselOption[0].clientId._id;
              const param = {
                clientId: getClientId,
              };
              getMIDCodeDispatch(param, headersWithAuth);
            }, 1000)
          );
        }
      }
    } else {
      setSelectedMerchantOption([] as any[]);
      setFormData((values) => ({ ...values, merchantId: "", mid: "" }));
      removeLocalStorage(AUTH_BASED_FILTER.MERCHANT);
      removeLocalStorage(GET_AUTH_BASED_FILTER.MERCHANT);
      removeLocalStorage(AUTH_BASED_FILTER.MID);
      removeLocalStorage(GET_AUTH_BASED_FILTER.MID);
    }
  };

  const handleSearch = () => {
    const credBasedPartnerValue = getLocalStorageMenu(
      AUTH_BASED_FILTER.PARTNER
    );
    const credBasedClientValue = getLocalStorageMenu(AUTH_BASED_FILTER.CLIENT);
    const credBasedMerchantValue = getLocalStorageMenu(
      AUTH_BASED_FILTER.MERCHANT
    );
    const credBasedMidValue = getLocalStorageMenu(
      AUTH_BASED_FILTER.MID
    );
    const params = {
      parnerId: credBasedPartnerValue,
      clientId: credBasedClientValue,
      merchantId: credBasedMerchantValue,
      mid: credBasedMidValue,
    };
    // if (formData.partner) {
    setFilterFunctionDispatch(true);
    // }
    setCredFilterParamsDispatch(params);
  };

  const getDefaultOptions = (data: any, name: any) => {
    const defaultOptions = [] as any[];
    if (!_.isEmpty(data)) {
      data.map((item: any) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  useEffect(() => {
    let data = getDefaultOptions(getClient && getClient.result, "company");
    if (data && data.length > 0) {
      data = data.filter((dt) => dt.label && dt.label.length > 0);
    }
    setClientOption(data);
  }, [getClient]);

  const credBasedPartnerValue = getLocalStorageMenu(
    GET_AUTH_BASED_FILTER.PARTNER
  );
  const credBasedClientValue = getLocalStorageMenu(
    GET_AUTH_BASED_FILTER.CLIENT
  );
  const credBasedMerchantValue = getLocalStorageMenu(
    GET_AUTH_BASED_FILTER.MERCHANT
  );
  const credBasedMidValue = getLocalStorageMenu(GET_AUTH_BASED_FILTER.MID);

  useEffect(() => {
    setSelectedPartnerOption(
      credBasedPartnerValue ? JSON.parse(credBasedPartnerValue) : null
    );
    setSelectedClientOption(
      credBasedClientValue ? JSON.parse(credBasedClientValue) : null
    );
    setSelectedClientOption(
      credBasedClientValue ? JSON.parse(credBasedClientValue) : null
    );
    setSelectedMerchantOption(
      credBasedMerchantValue ? JSON.parse(credBasedMerchantValue) : null
    );
    setSelectedMIDOption(
      credBasedMidValue ? JSON.parse(credBasedMidValue) : null
    );
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    }
  });

  const slugs = [
    "/chargeback-management",
    "/prevention-alerts",
    "/order-insight",
    "/issuer-bank-inquiries",
    "/issuer-bank-alerts",
  ];

  useEffect(() => {
    if (checkUserRole() === 'All') {
      const param = {
        skipPagination: true
      }
      getPartnerDispatch(param, headersWithAuth);
      getClientDispatch(null, headersWithAuth);
      getMIDCodeDispatch(null, headersWithAuth);
    }
    if (checkUserRole() === 'Client') {
      const param = {
        partnerId: getAttributes && getAttributes.partnerId ? getAttributes.partnerId : ''
      };
      getClientDispatch(param, headersWithAuth);
    }
    if (checkUserRole() === 'Merchant') {
      const param = {
        skipPagination: true
      };
      getMIDCodeDispatch(param, headersWithAuth);
    }
  }, [])

  return (
    <div
      id="kt_header"
      className={
        clsx(
          "header",
          classes.header.join(" "),
          "align-items-stretch"
        )}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(" "),
          "d-flex align-items-stretch justify-content-between"
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            >
              <KTSVG
                path="/media/icons/duotune/abstract/abs015.svg"
                className="svg-icon-2x mt-1"
              />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="/perform-summary" className="d-lg-none">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo-2.svg")}
                className="h-30px"
              />
            </Link>
          </div>
        )}
        {/* end::Logo */}
        {aside.display && (
          <div className="d-flex align-items-center flex-lg-grow-0 me-1">
            <Link to="/" className="d-lg-none">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo-2.svg")}
                className="h-30px"
              />
            </Link>
          </div>
        )}
        {/* begin::Wrapper */}
        {slugs.includes(pathName) ? (
          <>
            <div className="row g-2 col-lg-4 mt-2">
              {
                checkUserRole() === 'All' ? (
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    {/* <div className="col-lg-12"> */}
                    <label className="fs-8 fw-bolder">Partner</label>
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name="partnerId"
                      placeholder="Select..."
                      className="basic-single"
                      classNamePrefix="select"
                      handleChangeReactSelect={handleChangePartner}
                      handleInputChange={(val: any) => { handleInputChange(val, 'partnerId') }}
                      onBlur={(e) => handleSelectBlur(e, 'partnerId')}
                      options={partnerOption}
                      value={selectedPartnerOption}
                    />
                    {/* </div> */}
                  </div>
                ) : null
              }
              {
                ['All', 'Client'].includes(checkUserRole()) ? (
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    {/* <div className="col-lg-12"> */}
                    <label className="fs-8 fw-bolder">Client</label>
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name="clientId"
                      placeholder="Select..."
                      className="basic-single"
                      classNamePrefix="select"
                      isLoading={loadingClient}
                      handleChangeReactSelect={handleChangeClient}
                      handleInputChange={val => handleInputChange(val, 'clientId')}
                      onBlur={(e) => handleSelectBlur(e, 'clientId')}
                      noOptionsMessage={() => "Please select Partner!"}
                      options={clientOption}
                      value={selectedClientOption}
                    />
                    {/* </div> */}
                  </div>
                ) : null
              }
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                {/* <div className="col-lg-12"> */}
                <label className="fs-8 fw-bolder">Merchant</label>
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="merchantId"
                  placeholder="Select..."
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={loadingMerchant || loadingMID}
                  handleChangeReactSelect={handleChangeMerchant}
                  handleInputChange={val => handleInputChange(val, 'merchantId')}
                  onBlur={(e) => handleSelectBlur(e, 'merchantId')}
                  noOptionsMessage={() => "Please select Client!"}
                  options={merchantOption}
                  value={selectedMerchantOption}
                />
                {/* </div> */}
              </div>
            </div>
            <div className="d-flex justify-content-start col-lg-4 mt-5">
              <button
                type="button"
                className="btn btn-sm btn-primary m-1"
                onClick={() => {
                  handleSearch();
                }}
              >
                <span className="indicator-label">Go</span>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-secondary m-1"
                onClick={() => {
                  resetOptions();
                }}
              >
                <span className="indicator-label">Reset</span>
              </button>
            </div>
          </>
        ) : null}

        <div className="d-flex align-items-stretch justify-content-end flex-lg-grow-1">
          <div className="d-flex align-items-center" id="kt_header_nav">
            <span className="badge badge-light-success text-dark mr-2">
              {getAttributes && getAttributes.role}
            </span>
            <span className="mx-2">
              {getAttributes && getAttributes.role === 'Client User' ?
                (getAttributes && getAttributes.clientName) :
                getAttributes && getAttributes.role === 'Partner User' ?
                  (getAttributes && getAttributes.partnerName) : ''
              }
            </span>
          </div>
          {/* begin::Navbar */}
          {header.left === "menu" && (
            {/* <div className="d-flex align-items-stretch" id="kt_header_nav">
              <Header />
            </div>*/}
          )}
          {header.left === "page-title" && (
            <div className="d-flex align-items-center" id="kt_header_nav" />
          )}

          <div className="d-flex align-items-stretch flex-shrink-0">
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div >
  );
}

const mapStateToProps = (state: any) => {
  const {
    midCredFiltertore,
    partnerCrudFilterStore,
    clientCredFilterStore,
    merchantCredFilterStore,
  } = state;
  return {
    getPartner:
      partnerCrudFilterStore && partnerCrudFilterStore.getPartner
        ? partnerCrudFilterStore.getPartner
        : [],
    loadingGetPartner:
      partnerCrudFilterStore && partnerCrudFilterStore.loadingGetPartner
        ? partnerCrudFilterStore.loadingGetPartner
        : false,
    getClient:
      clientCredFilterStore && clientCredFilterStore.getClient
        ? clientCredFilterStore.getClient
        : [],
    loadingClient:
      clientCredFilterStore && clientCredFilterStore.loading
        ? clientCredFilterStore.loading
        : false,
    getMerchant:
      merchantCredFilterStore && merchantCredFilterStore.getMerchant
        ? merchantCredFilterStore.getMerchant
        : [],
    loadingMerchant:
      merchantCredFilterStore && merchantCredFilterStore.loading
        ? merchantCredFilterStore.loading
        : false,
    getmidCRM:
      midCredFiltertore && midCredFiltertore.getmidCRM
        ? midCredFiltertore.getmidCRM
        : [],
    loadingMID:
      midCredFiltertore && midCredFiltertore.loading
        ? midCredFiltertore.loading
        : false,
    setFilterFunction:
      partnerCrudFilterStore &&
        partnerCrudFilterStore.setFilterFunction &&
        partnerCrudFilterStore.setFilterFunction
        ? partnerCrudFilterStore.setFilterFunction
        : false,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getPartnerDispatch: (params: any, headers) =>
    dispatch(partnerCredFilterActions.getAuthPartner(params, headers)),
  setFilterFunctionDispatch: (data: any) =>
    dispatch(partnerCredFilterActions.setFilterFunction(data)),
  setCredFilterParamsDispatch: (data: any) =>
    dispatch(partnerCredFilterActions.setCredFilterParams(data)),
  clearPartnerDispatch: () =>
    dispatch(partnerCredFilterActions.clearAuthPartner()),
  getClientDispatch: (params: any, headers) =>
    dispatch(clientCredFilterActions.getFilterClient(params, headers)),
  clearClientDispatch: () =>
    dispatch(clientCredFilterActions.clearFilterClient()),
  getMerchantDispatch: (params: any, headers) =>
    dispatch(merchantCredFilterActions.getMerchant(params, headers)),
  clearMerchantDispatch: () =>
    dispatch(merchantCredFilterActions.clearMerchant()),
  getMIDCodeDispatch: (params: any, headers) =>
    dispatch(midCredFilterActions.getmidFilter(params, headers)),
  clearMIDCodeDispatch: () => dispatch(midCredFilterActions.clearmidFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWrapper);
