import {
  addUserroleTypes,
  userRoleActionsTypes,
  userRoleGetDetailsTypes,
  editUserroleTypes,
  userRoleDeleteTypes,
  userTypeActionsTypes
} from '../actions'

export const addUserroleStoreKey = 'addUserroleStore'

export const addUserroleReducer = (state = {}, action) => {
  switch (action.type) {
    case addUserroleTypes.REQUEST:
      return { ...state, loadingAUR: true }
    case addUserroleTypes.RESPONSE:
      return { ...state, dataAUR: action.data, statusAUR: action.data.status, messageAUR: action.data.message, loadingAUR: false }
    case addUserroleTypes.CLEAR:
      return { ...state, dataAUR: null, messageAUR: '', statusAUR: '' }
    default:
      return state
  }
}

export const userroleStoreKey = 'userroleStore'

export const userroleReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case userRoleActionsTypes.GET_USER_ROLE:
      return { ...state, loadingGetUR: true }
    case userRoleActionsTypes.SAVE_USER_ROLE_RESPONSE:
      return { ...state, userRoleData: res.data && res.data, count: res.count, loadingGetUR: false }
    case userRoleActionsTypes.CLEAR_USER_ROLE:
      return { ...state, userRoleData: null, count: 0 }
    case userRoleGetDetailsTypes.GET_USER_ROLE_DETAILS:
      return { ...state, loadingURD: true }
    case userRoleGetDetailsTypes.USER_ROLE_DETAILS_RESPONSE:
      return {
        ...state,
        userroleDetails: res && res.data,
        statusURD: res && res.status,
        messagesURD: res && res.message,
        loadingURD: false
      }
    case userRoleGetDetailsTypes.CLEAR_USER_ROLE_DETAILS:
      return { ...state, statusURD: '', messagesURD: '', userroleDetails: '' }
    default:
      return state
  }
}

export const getUserTypeStoreKey = 'getUserTypeStore'

export const getUserTypeReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case userTypeActionsTypes.REQUEST:
      return { ...state, loadingGetRole: true }
    case userTypeActionsTypes.RESPONSE:
      return {
        ...state,
        getDataUserType: res.data && res.data,
        statusGetType: res.status,
        messageGetType: res.message,
        loadingGetRole: false
      }
    case userTypeActionsTypes.CLEAR:
      return { ...state, getDataUserType: null, messageGetType: '', statusGetType: '' }
    default:
      return state
  }
}

export const editUserroleStoreKey = 'editUserroleStore'

export const editUserroleReducer = (state = {}, action) => {
  switch (action.type) {
    case editUserroleTypes.REQUEST:
      return { ...state, loadingEUR: true }
    case editUserroleTypes.RESPONSE:
      return { ...state, dataEUR: action.data && action.data, statusEUR: action.data.status, messageEUR: action.data.message, loadingEUR: false }
    case editUserroleTypes.CLEAR:
      return { ...state, dataEUR: null, messageEUR: '', statusEUR: '' }
    default:
      return state
  }
}

export const userroleDeleteStoreKey = 'userroleDeleteStore'

export const deleteUserroleReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case userRoleDeleteTypes.DELETE_USER_ROLE:
      return { ...state, loading: true }
    case userRoleDeleteTypes.SAVE_DELETE_USER_ROLE_RESPONSE:
      return {
        ...state,
        deleteUserrole: res.data,
        deleteUserroleStatus: res.status,
        deleteUserroleMessage: res.message,
        loading: false
      }
    case userRoleDeleteTypes.CLEAR_DELETE_USER_ROLE:
      return { ...state, deleteUserrole: null, deleteUserroleStatus: '', deleteUserroleMessage: '' }
    default:
      return state
  }
}
