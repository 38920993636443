import {
  issuerbanklistActionsTypes,
  getIssuerBankExportColumnsActionsTypes,
  issuerbankalertlistActionsTypes,
  getIssuerBankAlertExportColumnsActionsTypes
} from '../actions'

export const issuerbanklistInitialState = {
  list: null
}

export const issuerbanklistStoreKey = 'issuerbanklistStore'

export const issuerbanklistReducer = (state = issuerbanklistInitialState, action) => {
  switch (action.type) {
    case issuerbanklistActionsTypes.GET_ISSUER_BANK_LIST:
      return { ...state, loading: true }
    case issuerbanklistActionsTypes.SAVE_ISSUER_BANK_LIST_RESPONSE:
      return { ...state, issuerbanklists: action.data, loading: false }
    case issuerbanklistActionsTypes.CLEAR_ISSUER_BANK_LIST:
      return { ...state, issuerbanklists: null }
    default:
      return state
  }
}

export const issuerbankExportColumnStoreKey = 'issuerbankExportColumnStore'

export const issuerbankExportColumnReducer = (state = issuerbanklistInitialState, action) => {
  switch (action.type) {
    case getIssuerBankExportColumnsActionsTypes.GET_EXPORT_COLUMNS:
      return { ...state, issuerBankExportColumns: action.data }
    case getIssuerBankExportColumnsActionsTypes.CLEAR_COLUMNS:
      return { ...state, issuerBankExportColumns: null }
    default:
      return state
  }
}
export const issuerbanklistalertsInitialState = {
  list: null
}

export const issuerbanklistalertsStoreKey = 'issuerbanklistalertsStore'

export const issuerbanklistalertsReducer = (state = issuerbanklistalertsInitialState, action) => {
  switch (action.type) {
    case issuerbankalertlistActionsTypes.GET_ISSUER_BANK_ALERT_LIST:
      return { ...state, loading: true }
    case issuerbankalertlistActionsTypes.SAVE_ISSUER_BANK_ALERT_LIST_RESPONSE:
      return { ...state, issuerbanklistalerts: action.data, loading: false }
    case issuerbankalertlistActionsTypes.CLEAR_ISSUER_BANK_ALERT_LIST:
      return { ...state, issuerbanklistalerts: null }
    default:
      return state
  }
}

export const issuerbankalertExportColumnStoreKey = 'issuerbankalertExportColumnStore'

export const issuerbankalertExportColumnReducer = (state = issuerbanklistalertsInitialState, action) => {
  switch (action.type) {
    case getIssuerBankAlertExportColumnsActionsTypes.GET_ALERT_EXPORT_COLUMNS:
      return { ...state, issuerBankAlertExportColumns: action.data }
    case getIssuerBankAlertExportColumnsActionsTypes.CLEAR_ALERT_COLUMNS:
      return { ...state, issuerBankAlertExportColumns: null }
    default:
      return state
  }
}
