import {
  addGatewaySettingsActionsTypes,
  getGatewaySettingsDetailsActionsTypes,
  updateGatewaySettingsActionsTypes,
  deleteGatewaySettingsActionsTypes
} from '../actions'

export const addGatewaySettingsStoreKey = 'addGatewaySettingsStore'

export const addGatewaySettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addGatewaySettingsActionsTypes.REQUEST:
      return { ...state, loadingAddGateway: true }
    case addGatewaySettingsActionsTypes.RESPONSE:
      return {
        ...state,
        addGatewaySettings: res.data,
        statusAddGateway: res.status,
        messageAddGateway: res.message,
        loadingAddGateway: false
      }
    case addGatewaySettingsActionsTypes.CLEAR:
      return {
        ...state,
        addGatewaySettings: null,
        statusAddGateway: '',
        messageAddGateway: ''
      }
    default:
      return state
  }
}

export const getGatewaySettingsDetailsStoreKey = 'getGatewaySettingsDetailsStore'

export const getGatewaySettingsDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getGatewaySettingsDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsGateway: true }
    case getGatewaySettingsDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsGatewaySettings: res.data,
        statusGetDetailsGateway: res.status,
        messageGetDetailsGateway: res.message,
        loadingGetDetailsGateway: false
      }
    case getGatewaySettingsDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsGatewaySettings: null,
        statusGetDetailsGateway: '',
        messageGetDetailsGateway: ''
      }
    default:
      return state
  }
}

export const updateGatewaySettingsStoreKey = 'updateGatewaySettingsStore'

export const updateGatewaySettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updateGatewaySettingsActionsTypes.REQUEST:
      return { ...state, loadingUpdateGateway: true }
    case updateGatewaySettingsActionsTypes.RESPONSE:
      return {
        ...state,
        updateGatewaySettings: res.data,
        statusUpdateGateway: res.status,
        messageUpdateGateway: res.message,
        loadingUpdateGateway: false
      }
    case updateGatewaySettingsActionsTypes.CLEAR:
      return {
        ...state,
        updateGatewaySettings: null,
        statusUpdateGateway: '',
        messageUpdateGateway: ''
      }
    default:
      return state
  }
}

export const deleteGatewaySettingsStoreKey = 'deleteGatewaySettingsStore'

export const deleteGatewaySettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteGatewaySettingsActionsTypes.REQUEST:
      return { ...state, loadingDeleteGateway: true }
    case deleteGatewaySettingsActionsTypes.RESPONSE:
      return {
        ...state,
        deleteGatewaySettings: res.data,
        statusDeleteGateway: res.status,
        messageDeleteGateway: res.message,
        loadingDeleteGateway: false
      }
    case deleteGatewaySettingsActionsTypes.CLEAR:
      return {
        ...state,
        deleteGatewaySettings: null,
        statusDeleteGateway: '',
        messageDeleteGateway: ''
      }
    default:
      return state
  }
}
