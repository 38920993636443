import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addMerchantSMTPTypes,
  addMerchantSMTPActions,
  getMerchantSMTPDetailsActionsTypes,
  getMerchantSMTPDetailsActions,
  addClientSMTPTypes,
  addClientSMTPActions,
  getClientSMTPActionsTypes,
  getClientSMTPActions,
  getClientSMTPDetailsActionsTypes,
  getClientSMTPDetailsActions,
  variableActionsTypes,
  variableActions,
  addMerchantTemplateTypes,
  addMerchantTemplateActions,
  templateActionsTypes,
  templateActions,
  templateGetDetailsTypes,
  templateDetailsActions,
  editTemplateTypes,
  editTemplatesActions,
  templateDeleteTypes,
  deleteTemplateActions,
  addGeneralTemplateTypes,
  addGeneralTemplateActions,
  generalTemplateActionsTypes,
  generalTemplateActions,
  generalTemplateGetDetailsTypes,
  generalTemplateDetailsActions,
  editGeneralTemplateTypes,
  editGeneralTemplateActions,
  generalTemplateDeleteTypes,
  deleteGeneralTemplateActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addMerchantSMTP (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.merchantSmtp, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addMerchantSMTPActions.saveaddMerchantSMTPResponse(json.data))
    } else {
      yield put(addMerchantSMTPActions.saveaddMerchantSMTPResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addMerchantSMTPTypes.RESPONSE, data })
  }
}

function * fetchMerchantSMTPDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.merchantSmtp}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(getMerchantSMTPDetailsActions.saveResponse(json.data))
    } else {
      yield put(getMerchantSMTPDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getMerchantSMTPDetailsActionsTypes.RESPONSE, data })
  }
}

function * addClientSMTP (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.clientSmtp, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addClientSMTPActions.saveaddClientSMTPResponse(json.data))
    } else {
      yield put(addClientSMTPActions.saveaddClientSMTPResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addClientSMTPTypes.RESPONSE, data })
  }
}

function * fetchClientSMTP (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.clientSmtp, { params, headers: headers })
    if (json.status === 200) {
      yield put(getClientSMTPActions.saveResponse(json.data))
    } else {
      yield put(getClientSMTPActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getClientSMTPActionsTypes.RESPONSE, data })
  }
}

function * fetchClientSMTPDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.clientSmtp}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(getClientSMTPDetailsActions.saveResponse(json.data))
    } else {
      yield put(getClientSMTPDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getClientSMTPDetailsActionsTypes.RESPONSE, data })
  }
}

function * fetchVariables (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.Variable, { params, headers: headers })
    if (json.status === 200) {
      yield put(variableActions.saveVariableResponse(json.data))
    } else {
      yield put(variableActions.saveVariableResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: variableActionsTypes.RESPONSE, data })
  }
}

function * addMerchantTemplate (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.merchantTemplate, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addMerchantTemplateActions.saveaddMerchantTemplateResponse(json.data))
    } else {
      yield put(addMerchantTemplateActions.saveaddMerchantTemplateResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addMerchantTemplateTypes.RESPONSE, data })
  }
}

function * fetchTemplate (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.merchantTemplate, { params, headers: headers })
    if (json.status === 200) {
      yield put(templateActions.saveTemplateResponse(json.data))
    } else {
      yield put(templateActions.saveTemplateResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: templateActionsTypes.RESPONSE, data })
  }
}

function * getTemplateDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.merchantTemplate}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(templateDetailsActions.saveTemplateDetailsResponse(json.data))
    } else {
      yield put(templateDetailsActions.saveTemplateDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: templateGetDetailsTypes.RESPONSE, data })
  }
}

function * editTemplate (actions) {
  const { payload, headers } = actions
  const { id, data } = payload && payload
  const endPointUrl = `${serviceList.merchantTemplate}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, data, { headers: headers })
    if (json.status === 200) {
      yield put(editTemplatesActions.saveeditTemplatesResponse(json.data))
    } else {
      yield put(editTemplatesActions.saveeditTemplatesResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editTemplateTypes.RESPONSE, data })
  }
}

function * deleteTemplate (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.merchantTemplate}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteTemplateActions.savemResponseDeleteTemplate(json.data))
    } else {
      yield put(deleteTemplateActions.savemResponseDeleteTemplate([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: templateDeleteTypes.RESPONSE, data })
  }
}

function * addGeneralTemplate (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.generalTemplate, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addGeneralTemplateActions.saveResponse(json.data))
    } else {
      yield put(addGeneralTemplateActions.saveResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addGeneralTemplateTypes.RESPONSE, data })
  }
}

function * fetchgeneralTemplate (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.generalTemplate, { params, headers: headers })
    if (json.status === 200) {
      yield put(generalTemplateActions.saveResponse(json.data))
    } else {
      yield put(generalTemplateActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: generalTemplateActionsTypes.RESPONSE, data })
  }
}

function * getGeneralTemplateDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.generalTemplate}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(generalTemplateDetailsActions.saveResponse(json.data))
    } else {
      yield put(generalTemplateDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: generalTemplateGetDetailsTypes.RESPONSE, data })
  }
}

function * editGeneralTemplate (actions) {
  const { payload, headers } = actions
  const { id, data } = payload && payload
  const endPointUrl = `${serviceList.generalTemplate}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, data, { headers: headers })
    if (json.status === 200) {
      yield put(editGeneralTemplateActions.saveResponse(json.data))
    } else {
      yield put(editGeneralTemplateActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editGeneralTemplateTypes.RESPONSE, data })
  }
}

function * deletegeneralTemplate (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.generalTemplate}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteGeneralTemplateActions.saveResponse(json.data))
    } else {
      yield put(deleteGeneralTemplateActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: generalTemplateDeleteTypes.RESPONSE, data })
  }
}

export function * fetchMerchantSMTPWatcher () {
  yield all([
    yield takeLatest(addMerchantSMTPTypes.REQUEST, addMerchantSMTP),
    yield takeLatest(getMerchantSMTPDetailsActionsTypes.REQUEST, fetchMerchantSMTPDetails),
    yield takeLatest(addClientSMTPTypes.REQUEST, addClientSMTP),
    yield takeLatest(getClientSMTPActionsTypes.REQUEST, fetchClientSMTP),
    yield takeLatest(getClientSMTPDetailsActionsTypes.REQUEST, fetchClientSMTPDetails),
    yield takeLatest(variableActionsTypes.REQUEST, fetchVariables),
    yield takeLatest(addMerchantTemplateTypes.REQUEST, addMerchantTemplate),
    yield takeLatest(templateActionsTypes.REQUEST, fetchTemplate),
    yield takeLatest(templateGetDetailsTypes.REQUEST, getTemplateDetails),
    yield takeLatest(editTemplateTypes.REQUEST, editTemplate),
    yield takeLatest(templateDeleteTypes.REQUEST, deleteTemplate),
    yield takeLatest(addGeneralTemplateTypes.REQUEST, addGeneralTemplate),
    yield takeLatest(generalTemplateActionsTypes.REQUEST, fetchgeneralTemplate),
    yield takeLatest(generalTemplateGetDetailsTypes.REQUEST, getGeneralTemplateDetails),
    yield takeLatest(editGeneralTemplateTypes.REQUEST, editGeneralTemplate),
    yield takeLatest(generalTemplateDeleteTypes.REQUEST, deletegeneralTemplate)
  ])
}
