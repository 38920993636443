import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Step1 from "./step1";
import MidForm from "./MidForm";
import _, { update } from "lodash";
import { KTSVG } from "../../../theme/helpers";
import { useHistory, useLocation } from "react-router-dom";
import AddCrmForm from "../onboarding/merchantForm/AddCrmForm";
import AddProcessorForm from "../onboarding/merchantForm/AddProcessorForm";
import AddPayment from "../onboarding/merchantForm/AddPayment";
import ReactPaginate from "react-paginate";
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  headersWithAuth,
} from "../../../utils/helper";
import {
  midCRMActions,
  deletemidCRMActions,
  addMerchantSMTPActions,
  getMerchantSMTPDetailsActions,
  merchantBillingDetailsActions,
} from "../../../store/actions";
import {
  confirmationAlert,
  warningAlert,
  confirmAlert,
} from "../../../utils/alerts";
import {
  SWEET_ALERT_MSG,
  STATUS_RESPONSE,
  SAVE_CURRENT,
  REGEX,
  SESSION,
  API,
} from "../../../utils/constants";
import { Modal } from "../../../theme/layout/components/modal";
import { settingValidation } from "./validation";
import ShowFields from "../onboarding/ShowFields";
import axios from "axios";
import serviceList from "../../../services/serviceList";
import merchantDetails from "../../../containers/OnboardClient/clientSignUp/merchantDetails";

function AddMerchant(props) {
  const {
    loading,
    mids,
    getmidCRM,
    getMidCrmDispatch,
    deletemidCRMDispatch,
    deletemidCRMStatus,
    deletemidCRMMessage,
    clearDeletemidCRMDispatch,
    clearMerchantBillingDispatch,
    addMerchantSMTPDispatch,
    clearaddMerchantSMTPDispatch,
    loadingAMSMTP,
    statusAMSMTP,
    messageAMSMTP,
    getMerchantSMTPDetailsDispatch,
    clearMerchantSMTPDetailsDispatch,
    getDetailsMerchantSMTP,
    loadingGetDetailsMerchantSMTP,
    statusGetDetailsMerchantSMTP,
    merchantBillingDetails,
    billing,
    getBillingDispatch,
    merchantBillingDetailsLoading,
    getMerchantBillingDetailsDispatch,
    postToggleChangeDispatch,
    postPriceDetailsChangeDispatch,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };
  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update-merchant/");
  const id = url && url[1];
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("MID_FORM");
  const [merchantForm, setMerchantForm] = useState(false);
  const [settingModalShow, setSettingModalShow] = useState(false);
  const [table, setTable] = useState(true);
  const [limit, setLimit] = useState(25);
  const [currentId, setCurrentMidId] = useState();
  const [setActivePageNumber] = useState(1);
  const [editMid, setEditMid] = useState(false);
  const [errors, setErrors] = useState({});
  const [billingArray, setBillingArray] = useState([]);
  const [billingCheck, setBillingCheck] = useState(false);
  const [clientCurrentTab, setClientCurrentTab] = useState("BILLING");
  const [priceChange, setPriceChange] = useState({
    flag: false,
    priceChangeDt: "",
  });
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const currentClientID = getLocalStorage(SAVE_CURRENT.CLIENT_ID);
  const [price, setPrice] = useState(0);
  const [billingFormData, setBillingFormData] = useState({
    cbGuaranteePremium: 0,
    riskLevel: "Low",
    orderSitePrice: 0,
    rdrPrice: 0,
    cdrnPrice: 0,
    ethocaAlertPrice: 0,
    successBasedBillingPrice: 0,
    perChargebackPrice: 0,
    hybridSuccessPrice: 0,
    hybridPerChargebackPrice: 0,
  });
  const [formSettingData, setFormSettingData] = useState({
    merchantId: id,
    remainderDay: "",
    remainderEndDay: "",
    expiryDay: "",
    DebitNotification: false,
    HoldNotification: false,
    SettlementNotification: false,
    FundreleaseNotification: false,
    UploadMissingEvidenceNotification: false,
  });

  const resetSettingState = (e) => {
    setFormSettingData({
      remainderDay: "",
      remainderEndDay: "",
      DebitNotification: "",
      HoldNotification: "",
      SettlementNotification: "",
      FundreleaseNotification: "",
      UploadMissingEvidenceNotification: "",
    });
    setErrors(null);
  };

  const handleTabs = (tab) => {
    setActiveTab(tab);
  };

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
  };
  const handleChangeBilling = (e) => {
    e.persist();
    const { value, name } = e.target;
    setBillingFormData((values) => ({ ...values, [name]: value }));
  };
  const handleChange = (e) => {
    e.persist();
    setFormSettingData((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSettingSubmit = () => {
    const errorMsg = settingValidation(formSettingData, setErrors);
    if (_.isEmpty(errorMsg)) {
      addMerchantSMTPDispatch(
        formSettingData,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    }
  };

  const handleSwitch = (el, name) => {
    setFormSettingData((values) => ({ ...values, [name]: el }));
  };

  const onMailConfirm = () => {
    setSettingModalShow(false);
    resetSettingState();
  };

  useEffect(() => {
    if (statusAMSMTP === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAMSMTP,
        "success",
        "ok",
        () => {
          onMailConfirm();
        },
        () => {
          onMailConfirm();
        }
      );
      clearaddMerchantSMTPDispatch();
    } else if (statusAMSMTP === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAMSMTP, "", "Ok");
      clearaddMerchantSMTPDispatch();
    }
  }, [statusAMSMTP]);

  useEffect(() => {
    const midParam = {
      merchantId: id,
      clientId: currentClientID ? currentClientID : "",
    };
    getMidCrmDispatch(
      midParam,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    return () => {
      setActiveTab("MID_FORM");
    };
  }, []);

  const onConfirm = () => {
    const midParam = {
      merchantId: id,
      clientId: currentClientID ? currentClientID : "",
    };
    getMidCrmDispatch(
      midParam,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  function onConfirmDelete(id) {
    deletemidCRMDispatch(
      id,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_MID,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete(id);
      },
      () => {}
    );
  };

  useEffect(() => {
    if (deletemidCRMStatus === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        deletemidCRMMessage,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearDeletemidCRMDispatch();
    } else if (deletemidCRMStatus === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", deletemidCRMMessage, "", "Ok");
    }
    clearDeletemidCRMDispatch();
  }, [deletemidCRMStatus]);

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const params = {
      limit: limit,
      page: pageNumber,
      merchantId: id,
      clientId: currentClientID ? currentClientID : "",
    };
    setActivePageNumber(pageNumber);
    getMidCrmDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const totalPages =
    getmidCRM && getmidCRM.count
      ? Math.ceil(parseInt(getmidCRM && getmidCRM.count) / limit)
      : 1;

  useEffect(() => {
    if (statusGetDetailsMerchantSMTP === STATUS_RESPONSE.SUCCESS_MSG) {
      setFormSettingData({
        remainderDay: getDetailsMerchantSMTP.remainderDay,
        remainderEndDay: getDetailsMerchantSMTP.remainderEndDay,
        expiryDay: getDetailsMerchantSMTP.expiryDay,
        DebitNotification: getDetailsMerchantSMTP.DebitNotification,
        HoldNotification: getDetailsMerchantSMTP.HoldNotification,
        SettlementNotification: getDetailsMerchantSMTP.SettlementNotification,
        FundreleaseNotification: getDetailsMerchantSMTP.FundreleaseNotification,
        UploadMissingEvidenceNotification:
          getDetailsMerchantSMTP.UploadMissingEvidenceNotification,
      });
      clearMerchantSMTPDetailsDispatch();
    }
  }, [statusGetDetailsMerchantSMTP]);

  const fetchBillingDetails = async () => {
    try {
      const midParam = {
        merchantId: id,
      };
      getMerchantBillingDetailsDispatch(
        midParam,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    } catch (error) {
      console.log(error, "Fetch Merchant Billing Details Error----->");
    }
  };

  const billingDetails = async () => {
    try {
      const midParam = {
        merchantId: id,
      };
      getBillingDispatch(
        midParam,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );

      // console.log(billing, " billingValue ");
    } catch (error) {
      console.log(error, "Fetch Merchant Billing Error----->");
    }
  };

  useEffect(() => {
    billingDetails();
  }, []);
  useEffect(() => {
    if (!_.isEmpty(billing.data)) {
      setBillingArray(billing.data);
      setBillingCheck(true);
    }
    //clearMerchantBillingDispatch();
  }, [billing]);
  useEffect(() => {
    const dt = JSON.parse(getLocalStorage("ATTRIBUTE_DEATILS"));
    if (dt && dt?.role === "Admin") {
      setShowBillingDetails(true);
    } else {
      setShowBillingDetails(false);
    }

    fetchBillingDetails();
  }, []);
  useEffect(() => {
    setBillingFormData({
      cbGuaranteePremium: merchantBillingDetails?.cbGuarantee?.price,
      riskLevel: merchantBillingDetails?.cbGuarantee?.riskLevel,
      orderSitePrice: merchantBillingDetails?.orderInsights?.price,
      rdrPrice: merchantBillingDetails?.rdr?.price,
      cdrnPrice: merchantBillingDetails?.cdrn?.price,
      ethocaAlertPrice: merchantBillingDetails?.ethocaAlerts?.price,
      successBasedBillingPrice: merchantBillingDetails?.sucessBased?.price,
      perChargebackPrice: merchantBillingDetails?.perChargeBack?.price,
      hybridSuccessPrice: merchantBillingDetails?.hybrid?.sucessBasedPrice,
      hybridPerChargebackPrice:
        merchantBillingDetails?.hybrid?.perChargeBackPrice,
    });
  }, [merchantBillingDetails]);

  function billingDetailsToggleChange(name) {
    let activeStatus = !merchantBillingDetails[name]?.active;
    if (name == "sucessBased") {
      let checkSuccessBased = !merchantBillingDetails[name]?.active;
      let checkperChargeBack = merchantBillingDetails["perChargeBack"].active;
      let checkhybrid = merchantBillingDetails["hybrid"].active;
      console.log("ddd");
      if (checkperChargeBack && checkSuccessBased) {
        alert("Please choose one representment option");
        return false;
      }
      if (checkhybrid && checkSuccessBased) {
        alert("Please choose one representment option");
        return false;
      }
    }
    if (name == "perChargeBack") {
      let checkSuccessBased = merchantBillingDetails["sucessBased"].active;
      let checkperChargeBack = !merchantBillingDetails[name]?.active;
      let checkhybrid = merchantBillingDetails["hybrid"].active;
      console.log("ezzesd");

      if (checkSuccessBased && checkperChargeBack) {
        alert("Please choose one representment option");
        return false;
      }
      if (checkhybrid && checkperChargeBack) {
        alert("Please choose one representment option");
        return false;
      }
    }
    if (name == "hybrid") {
      let checkSuccessBased = merchantBillingDetails["sucessBased"].active;
      let checkperChargeBack = merchantBillingDetails["perChargeBack"].active;
      let checkhybrid = !merchantBillingDetails[name]?.active;

      console.log(checkSuccessBased, " ", checkperChargeBack, " ", checkhybrid);

      if (checkSuccessBased && checkhybrid) {
        alert("Please choose one representment option");
        return false;
      }
      if (checkperChargeBack && checkhybrid) {
        alert("Please choose one representment option");
        return false;
      }
    }

    const payload = {
      name,
      active: activeStatus,
      merchantId: id,
    };

    postToggleChangeDispatch(
      payload,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }
  async function handleBillingSubmit(name, value, riskLevel) {
    setPriceChange({
      flag: !priceChange.flag,
      priceChangeDt: name,
    });
    let payload = {};
    if (name === "cbGuarantee" && riskLevel != undefined) {
      payload = {
        newPrice: value,
        name,
        merchantId: id,
        riskLevel: billingFormData.riskLevel,
      };
    } else {
      payload = { newPrice: value, name, merchantId: id };
    }
    postPriceDetailsChangeDispatch(
      payload,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    const midParam = {
      merchantId: id,
    };
    getMerchantBillingDetailsDispatch(
      midParam,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }

  const tableStyle = {
    border: "1px solid #000",
    textAlign: "left",
    padding: "10px",
  };
  const tdStyle = {
    whiteSpace: "nowrap",
  };
  const allTdStyle = {
    width: "100px",
  };

  const tableLayout = {
    width: "100%",
    // borderCollapse: "collapse",
  };

  return (
    <>
      {settingModalShow ? (
        <Modal showModal={settingModalShow} modalWidth={750}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">Settings</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setSettingModalShow(false);
                      resetSettingState();
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body p-5">
                  <div className="container-fixed">
                    <div className="card m-2">
                      {loadingGetDetailsMerchantSMTP ? (
                        <div className="d-flex justify-content-center py-5">
                          <div
                            className="spinner-border text-primary m-5"
                            role="status"
                          />
                        </div>
                      ) : (
                        <>
                          <div className="row mt-2">
                            <div className="col-lg-4">
                              <label className="required fw-bold fs-6 mb-2">
                                Token Expiry Day
                              </label>
                              <input
                                name="expiryDay"
                                type="text"
                                className="form-control"
                                placeholder="Token Expiry Day"
                                value={formSettingData.expiryDay || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  if (!REGEX.NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={3}
                              />
                              {errors && errors.expiryDay && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.expiryDay}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <label className="required fw-bold fs-6 mb-2">
                                Remainder Day
                              </label>
                              <input
                                name="remainderDay"
                                type="text"
                                className="form-control"
                                placeholder="Remainder Day"
                                value={formSettingData.remainderDay || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  if (!REGEX.NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={3}
                              />
                              {errors && errors.remainderDay && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.remainderDay}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <label className="required fw-bold fs-6 mb-2">
                                Remainder End Day
                              </label>
                              <input
                                name="remainderEndDay"
                                type="text"
                                className="form-control"
                                placeholder="Remainder End Day"
                                value={formSettingData.remainderEndDay || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  if (!REGEX.NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={3}
                              />
                              {errors && errors.remainderEndDay && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.remainderEndDay}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label className="fw-bold fs-6 mt-2">
                                    Debit Notification
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <BootstrapSwitchButton
                                    name="DebitNotification"
                                    onlabel="On"
                                    onstyle="primary"
                                    offlabel="Off"
                                    offstyle="secondary"
                                    style="w-50 mx-3"
                                    value={
                                      formSettingData.DebitNotification || false
                                    }
                                    onChange={(el) =>
                                      handleSwitch(el, "DebitNotification")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label className="fw-bold fs-6 mt-2">
                                    Hold Notification
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <BootstrapSwitchButton
                                    name="HoldNotification"
                                    onlabel="On"
                                    onstyle="primary"
                                    offlabel="Off"
                                    offstyle="secondary"
                                    style="w-50 mx-3"
                                    value={
                                      formSettingData.HoldNotification || false
                                    }
                                    onChange={(el) =>
                                      handleSwitch(el, "HoldNotification")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label className="fw-bold fs-6 mt-2">
                                    Settlement Notification
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <BootstrapSwitchButton
                                    name="SettlementNotification"
                                    onlabel="On"
                                    onstyle="primary"
                                    offlabel="Off"
                                    offstyle="secondary"
                                    style="w-50 mx-3"
                                    value={
                                      formSettingData.SettlementNotification ||
                                      false
                                    }
                                    onChange={(el) =>
                                      handleSwitch(el, "SettlementNotification")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label className="fw-bold fs-6 mt-2">
                                    Fund Release Notification
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <BootstrapSwitchButton
                                    name="FundreleaseNotification"
                                    onlabel="On"
                                    onstyle="primary"
                                    offlabel="Off"
                                    offstyle="secondary"
                                    style="w-50 mx-3"
                                    value={
                                      formSettingData.FundreleaseNotification ||
                                      false
                                    }
                                    onChange={(el) =>
                                      handleSwitch(
                                        el,
                                        "FundreleaseNotification"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label className="fw-bold fs-6 mt-2">
                                    Upload Missing Evidence Notification
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <BootstrapSwitchButton
                                    name="UploadMissingEvidenceNotification"
                                    onlabel="On"
                                    onstyle="primary"
                                    offlabel="Off"
                                    offstyle="secondary"
                                    style="w-50 mx-3 fw-bolder fs-6"
                                    value={
                                      formSettingData.UploadMissingEvidenceNotification ||
                                      false
                                    }
                                    onChange={(el) =>
                                      handleSwitch(
                                        el,
                                        "UploadMissingEvidenceNotification"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row mt-4">
                            <div className="col-lg-5" />
                            <div className="col-lg-7">
                              <div className="col-lg-12">
                                <button
                                  className="btn btn-blue mt-7 fa-pull-right"
                                  onClick={(event) => {
                                    handleSettingSubmit(event);
                                  }}
                                >
                                  {loadingAMSMTP ? (
                                    <span
                                      className="spinner-border spinner-border-sm mx-3"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <div>
        <div>
          <div
            className="accordion md-accordion accordion-2"
            id="accordionEx7"
            role="tablist"
            aria-multiselectable="true"
          >
            <div className="card bg-skyBlue">
              <div
                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                role="tab"
                id="heading2"
              >
                <div className="col-lg-12 my-auto">
                  <div className="row">
                    <div className="col-lg-4">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordionEx7"
                        href="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapse2"
                        onClick={() => {
                          setMerchantForm((val) => !val);
                        }}
                      >
                        <h5 className="text-dark fw-bolder fs-4 my-3">
                          {merchantForm ? "Hide" : "Show"} Merchant Form{" "}
                          <i
                            className={`fas fa-angle-${
                              merchantForm ? "up" : "down"
                            } rotate-icon`}
                          />
                        </h5>
                      </a>
                    </div>
                    <div className="col-lg-8">
                      <div className="d-flex justify-content-end mb-3 mr-5">
                        <button
                          className="btn btn-sm btn-darkRed fa-pull-right m-1"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Back to Merchant
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="collapse2"
                className="collapse"
                role="tabpanel"
                aria-labelledby="heading2"
                data-parent="#accordionEx7"
              >
                <Step1 />
              </div>
            </div>
          </div>
        </div>
        {showBillingDetails && merchantBillingDetailsLoading && (
          <div className="d-flex justify-content-center py-5">
            <div className="spinner-border text-primary m-5" role="status" />
          </div>
        )}
        {showBillingDetails &&
        !merchantBillingDetailsLoading &&
        !!merchantBillingDetails ? (
          <div className="row g-0">
            <div className="col-md-12">
              <div className="card card-nav-tabs mt-10">
                <div className="card-header card-header-primary">
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <ul className="nav nav-tabs" data-tabs="tabs">
                        <li className="client-nav-item">
                          <a
                            className={`nav-link
                            ${clientCurrentTab === "BILLING" ? "active" : ""}
                            `}
                            href="#companydetails"
                            data-toggle="tab"
                            onClick={() => {
                              setClientCurrentTab("BILLING");
                            }}
                          >
                            <i className="bi bi-receipt m-2 text-white" />
                            BILLING & INVOICE
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <ShowFields>
                <div className="card">
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-2">
                          <h5 className="text-dark fw-bolder fs-4 my-3 py-2 px">
                            CB Guarantee
                          </h5>
                        </div>
                        <div className="form-check form-switch col-2 mt-5">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={
                              merchantBillingDetails?.cbGuarantee?.active
                            }
                            onClick={() =>
                              billingDetailsToggleChange("cbGuarantee")
                            }
                          />
                        </div>
                        <div className="container py-2">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <p className="text-lg-start mt-3 ms-3">
                                CB Merchant Risk Level
                              </p>
                            </div>
                            <div
                              className="col input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <select
                                name="riskLevel"
                                className="form-control"
                                value={billingFormData.riskLevel}
                                onChange={(e) => handleChangeBilling(e)}
                                disabled={
                                  !merchantBillingDetails?.cbGuarantee?.active
                                }
                              >
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="container py-2">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <p className="text-lg-start mt-3 ms-3">Premium</p>
                            </div>

                            <div
                              className="col  input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                aria-label="price"
                                name="cbGuaranteePremium"
                                disabled={
                                  !merchantBillingDetails?.cbGuarantee?.active
                                }
                                value={billingFormData.cbGuaranteePremium}
                                onChange={(e) => handleChangeBilling(e)}
                              />
                              <span
                                className="input-group-text"
                                id="cbGuarantee"
                              >
                                %
                              </span>
                              <button
                                className="btn btn-darkRed fa-pull-right"
                                type="button"
                                id="rdr"
                                disabled={
                                  !merchantBillingDetails?.cbGuarantee?.active
                                }
                                onClick={() =>
                                  handleBillingSubmit(
                                    "cbGuarantee",
                                    billingFormData.cbGuaranteePremium,
                                    billingFormData.riskLevel
                                  )
                                }
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <h5 className="text-dark fw-bolder fs-4 my-3 py-2 px">
                          Prevention
                        </h5>

                        <div className="container py-2">
                          <div className="row align-items-center">
                            <div className="col-2">
                              <p className="text-lg-start mt-3 ms-3">
                                Order Insights
                              </p>
                            </div>
                            <div className="col-1">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    merchantBillingDetails?.orderInsights
                                      ?.active
                                  }
                                  onClick={() =>
                                    billingDetailsToggleChange("orderInsights")
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Order Insights"
                                aria-label="order-insights"
                                name="orderSitePrice"
                                disabled={
                                  !merchantBillingDetails?.orderInsights?.active
                                }
                                value={billingFormData.orderSitePrice}
                                onChange={(e) => handleChangeBilling(e)}
                              />
                              <span
                                className="input-group-text"
                                id="order-insights"
                              >
                                $
                              </span>
                              <button
                                className="btn btn-darkRed fa-pull-right"
                                type="button"
                                id="order-insights"
                                disabled={
                                  !merchantBillingDetails?.orderInsights?.active
                                }
                                onClick={() =>
                                  handleBillingSubmit(
                                    "orderInsights",
                                    billingFormData.orderSitePrice
                                  )
                                }
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="container py-2">
                          <div className="row align-items-center">
                            <div className="col-2">
                              <p className="text-lg-start mt-3 ms-3">RDR</p>
                            </div>
                            <div className="col-1">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={merchantBillingDetails?.rdr?.active}
                                  onClick={() =>
                                    billingDetailsToggleChange("rdr")
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                placeholder="RDR"
                                aria-label="rdr"
                                name="rdrPrice"
                                disabled={!merchantBillingDetails?.rdr?.active}
                                value={billingFormData.rdrPrice}
                                onChange={(e) => handleChangeBilling(e)}
                              />
                              <span className="input-group-text" id="rdr">
                                $
                              </span>
                              <button
                                className="btn btn-darkRed fa-pull-right"
                                type="button"
                                id="rdr"
                                disabled={!merchantBillingDetails?.rdr?.active}
                                onClick={() =>
                                  handleBillingSubmit(
                                    "rdr",
                                    billingFormData.rdrPrice
                                  )
                                }
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="container py-2">
                          <div className="row align-items-center">
                            <div className="col-2">
                              <p className="text-lg-start mt-3 ms-3">CDRN</p>
                            </div>
                            <div className="col-1">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={merchantBillingDetails?.cdrn?.active}
                                  onClick={() =>
                                    billingDetailsToggleChange("cdrn")
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                placeholder="CDRN"
                                aria-label="cdrn"
                                name="cdrnPrice"
                                disabled={!merchantBillingDetails?.cdrn?.active}
                                value={billingFormData.cdrnPrice}
                                onChange={(e) => handleChangeBilling(e)}
                              />
                              <span
                                className="input-group-text"
                                id="sucess-based"
                              >
                                $
                              </span>
                              <button
                                className="btn btn-darkRed fa-pull-right"
                                type="button"
                                id="cdrn"
                                disabled={!merchantBillingDetails?.cdrn?.active}
                                onClick={() =>
                                  handleBillingSubmit(
                                    "cdrn",
                                    billingFormData.cdrnPrice
                                  )
                                }
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="container py-2">
                          <div className="row align-items-center">
                            <div className="col-2">
                              <p className="text-lg-start mt-3 ms-3">
                                Ethoca Alerts
                              </p>
                            </div>
                            <div className="col-1">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    merchantBillingDetails?.ethocaAlerts?.active
                                  }
                                  onClick={() =>
                                    billingDetailsToggleChange("ethocaAlerts")
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Ethoca Alerts"
                                aria-label="ethoca-alerts"
                                name="ethocaAlertPrice"
                                disabled={
                                  !merchantBillingDetails?.ethocaAlerts?.active
                                }
                                value={billingFormData.ethocaAlertPrice}
                                onChange={(e) => handleChangeBilling(e)}
                              />
                              <span
                                className="input-group-text"
                                id="ethoca-alerts"
                              >
                                $
                              </span>
                              <button
                                className="btn btn-darkRed fa-pull-right"
                                type="button"
                                id="ethoca-alerts"
                                disabled={
                                  !merchantBillingDetails?.ethocaAlerts?.active
                                }
                                onClick={() =>
                                  handleBillingSubmit(
                                    "ethocaAlerts",
                                    billingFormData.ethocaAlertPrice
                                  )
                                }
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <h5 className="text-dark fw-bolder fs-4 my-3 py-2">
                          Representment
                        </h5>
                        <div className="container py-2">
                          <div className="row align-items-center">
                            <div className="col-2">
                              <p className="text-lg-start mt-3 ms-3">
                                Sucess Based Billing
                              </p>
                            </div>
                            <div className="col-1">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    merchantBillingDetails?.sucessBased?.active
                                  }
                                  onClick={() =>
                                    billingDetailsToggleChange("sucessBased")
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Sucess Based"
                                aria-label="sucess-based"
                                name="successBasedBillingPrice"
                                disabled={
                                  !merchantBillingDetails.sucessBased?.active
                                }
                                value={billingFormData.successBasedBillingPrice}
                                onChange={(e) => handleChangeBilling(e)}
                              />
                              <span
                                className="input-group-text"
                                id="sucess-based"
                              >
                                %
                              </span>
                              <button
                                className="btn btn-darkRed fa-pull-right"
                                type="button"
                                id="sucess-based"
                                disabled={
                                  !merchantBillingDetails.sucessBased?.active
                                }
                                onClick={() =>
                                  handleBillingSubmit(
                                    "sucessBased",
                                    billingFormData.successBasedBillingPrice
                                  )
                                }
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="container py-2">
                          <div className="row align-items-center">
                            <div className="col-2">
                              <p className="text-lg-start mt-3 ms-3">
                                Per Chargeback
                              </p>
                            </div>
                            <div className="col-1">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    merchantBillingDetails?.perChargeBack
                                      ?.active
                                  }
                                  onClick={() =>
                                    billingDetailsToggleChange("perChargeBack")
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Per Chargeback"
                                aria-label="per-chargeback"
                                name="perChargebackPrice"
                                disabled={
                                  !merchantBillingDetails?.perChargeBack?.active
                                }
                                value={billingFormData.perChargebackPrice}
                                onChange={(e) => handleChangeBilling(e)}
                              />
                              <span
                                className="input-group-text"
                                id="per-chargeback"
                              >
                                $
                              </span>
                              <button
                                className="btn btn-darkRed fa-pull-right"
                                type="button"
                                id="per-chargeback"
                                disabled={
                                  !merchantBillingDetails?.perChargeBack?.active
                                }
                                onClick={() =>
                                  handleBillingSubmit(
                                    "perChargeBack",
                                    billingFormData.perChargebackPrice
                                  )
                                }
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="container py-2">
                          <div className="row align-items-center">
                            <div className="col-2">
                              <p className="text-lg-start mt-3 ms-3">Hybrid</p>
                            </div>
                            <div className="col-1">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    merchantBillingDetails?.hybrid?.active
                                  }
                                  onClick={() =>
                                    billingDetailsToggleChange("hybrid")
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Sucess Based"
                                aria-label="hybrid-sucess-based"
                                name="hybridSuccessPrice"
                                disabled={
                                  !merchantBillingDetails?.hybrid?.active
                                }
                                value={billingFormData.hybridSuccessPrice}
                                onChange={(e) => handleChangeBilling(e)}
                              />
                              <span
                                className="input-group-text"
                                id="hybrid-sucess-based"
                              >
                                %
                              </span>
                              <button
                                className="btn btn-darkRed fa-pull-right"
                                type="button"
                                id="hybrid-sucess-based"
                                disabled={
                                  !merchantBillingDetails?.hybrid?.active
                                }
                                onClick={() =>
                                  handleBillingSubmit(
                                    "hybridSucessBased",
                                    billingFormData.hybridSuccessPrice
                                  )
                                }
                              >
                                Change
                              </button>
                            </div>
                            <div
                              className="col input-group"
                              style={{ maxWidth: "280px" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Per Chargeback"
                                aria-label="hybrid-per-chargeback"
                                name="hybridPerChargebackPrice"
                                disabled={
                                  !merchantBillingDetails?.hybrid?.active
                                }
                                value={billingFormData.hybridPerChargebackPrice}
                                onChange={(e) => handleChangeBilling(e)}
                              />
                              <span
                                className="input-group-text"
                                id="hybrid-per-chargeback"
                              >
                                $
                              </span>
                              <button
                                className="btn btn-darkRed fa-pull-right"
                                type="button"
                                id="hybrid-per-chargeback"
                                disabled={
                                  !merchantBillingDetails?.hybrid?.active
                                }
                                onClick={() =>
                                  handleBillingSubmit(
                                    "hybridPerChargeBack",
                                    billingFormData.hybridPerChargebackPrice
                                  )
                                }
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ShowFields>
            </div>

            <div className="row g-0">
              {/* <div className="col-md-12">
                <div className="card card-nav-tabs mt-10">
                  <div className="card-header card-header-primary">
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <ul className="nav nav-tabs" data-tabs="tabs">
                          <li className="client-nav-item">
                            <a
                              className={`nav-link`}
                              href="#companydetails"
                              data-toggle="tab"
                            >
                              <i className="bi bi-receipt m-2 text-white" />
                              INVOICE
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="card-body">
                <ShowFields>
                  <div className="card">
                    <div className="card-body">
                      <div className="container">
                        <div className="row">
                          <div className="col-2">
                            <h5 className="text-dark fw-bolder fs-4 my-3 py-2 px">
                              {billing &&
                              billing.data &&
                              billingArray &&
                              billingCheck ? (
                                <table style={(tableStyle, tableLayout)}>
                                  <thead>
                                    <tr>
                                      <th style={tableStyle}>Month</th>
                                      <th style={tableStyle}>Year</th>
                                      <th style={tableStyle}>File</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.keys(billingArray).map((item) => {
                                      return (
                                        <tr>
                                          <td style={tableStyle}>
                                            <div>
                                              {billingArray[item].month}
                                            </div>
                                          </td>
                                          <td style={tableStyle}>
                                            <div>{billingArray[item].year}</div>
                                          </td>
                                          <td style={tableStyle}>
                                            <div style={tdStyle}>
                                              <a
                                                href={`${process.env.REACT_APP_CB}${billingArray[item].name}`}
                                                target="_blank"
                                              >
                                                Invoice Download
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              ) : (
                                "NO INVOICE FOUND"
                              )}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ShowFields>
              </div>
            </div>
          </div>
        ) : null}

        <div className="d-flex justify-content-end col-md-12  mt-5 mb-5">
          <div>
            <button
              className={`me-10 btn btn-sm btn-${
                table ? "light-primary" : "darkRed"
              }`}
              onClick={() => {
                removeLocalStorage(SAVE_CURRENT.MID_ID);
                if (table) {
                  setTable(false);
                  setEditMid(false);
                } else {
                  setTable(true);
                  const midParam = {
                    merchantId: id,
                    clientId: currentClientID ? currentClientID : "",
                  };
                  getMidCrmDispatch(
                    midParam,
                    isAuthorized ? headersWithAuth : headercurrentWithApiKey
                  );
                  setActiveTab("MID_FORM");
                }
              }}
            >
              {table ? (
                <KTSVG path="/media/icons/duotune/arrows/arr087.svg" />
              ) : null}
              {table ? "Add MID" : "Back to MID"}
            </button>
          </div>
        </div>
        {table ? (
          <>
            <div className="table-responsive bg-white px-5 mt-8">
              <div className="col-md-7 d-flex">
                <label className="col-form-label text-lg-start mt-3 ms-3">
                  Record(s) per Page : &nbsp;{" "}
                </label>
                <div className="col-md-3 mt-3">
                  <select
                    className="form-select w-6rem"
                    data-control="select"
                    data-placeholder="Select an option"
                    data-allow-clear="true"
                    onChange={(e) => handleRecordPerPage(e)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
              <table className="table  table-striped border table-hover table-rounded gs-2 mt-6">
                <thead>
                  <tr className="fw-bold fs-6 text-gray-800">
                    <th className="min-w-100px text-center">Action</th>
                    <th className="min-w-225px text-start">
                      <div className="d-flex">
                        <span>MID Number</span>
                      </div>
                    </th>
                    <th className="min-w-225px text-start">
                      <div className="d-flex">
                        <span>Descriptor name</span>
                      </div>
                    </th>
                    <th className="min-w-225px text-start">
                      <div className="d-flex">
                        <span>Descriptor ID</span>
                      </div>
                    </th>
                    <th className="min-w-225px text-start">
                      <div className="d-flex">
                        <span>Doing business as</span>
                      </div>
                    </th>
                    <th className="min-w-225px text-start">
                      <div className="d-flex">
                        <span>BIN</span>
                      </div>
                    </th>
                    <th className="min-w-225px text-start">
                      <div className="d-flex">
                        <span>CAID</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading ? (
                    mids && mids.data ? (
                      mids.data.map((mid, _id) => {
                        return (
                          <tr
                            key={_id}
                            style={
                              _id === 0
                                ? { borderColor: "black" }
                                : { borderColor: "white" }
                            }
                          >
                            <td className="pb-0 pt-3 text-start">
                              <div className="my-auto d-flex">
                                <button
                                  className="btn btn-icon btn-bg-light btn-icon-warning btn-active-color-warning btn-sm"
                                  onClick={() => {
                                    setTable(false);
                                    setEditMid(true);
                                    setCurrentMidId(mid._id);
                                    setLocalStorage(
                                      SAVE_CURRENT.MID_ID,
                                      mid._id
                                    );
                                  }}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/art/art005.svg"
                                    className="svg-icon-3"
                                  />
                                </button>
                                <button
                                  className="btn btn-icon btn-bg-light btn-icon-danger btn-active-color-danger btn-sm ms-0"
                                  onClick={() => {
                                    onDeleteItem(mid._id);
                                  }}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen027.svg"
                                    className="svg-icon-3"
                                  />
                                </button>
                              </div>
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {mid.midNumber ? mid.midNumber : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {mid.descriptorName ? mid.descriptorName : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {mid.descriptorId ? mid.descriptorId : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {mid.doingBusinessAs ? mid.doingBusinessAs : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {mid.bin ? mid.bin : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {mid.caId ? mid.caId : "--"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center py-3">
                        <td colSpan="100%">No record(s) found</td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        <div
                          className="spinner-border text-primary m-5"
                          role="status"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="form-group row mb-4 mt-6">
              <div className="col-lg-12 mb-4 align-items-end d-flex">
                <div className="col-lg-12 pagination">
                  <ReactPaginate
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPages}
                    previousLabel="< Prev"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <nav className="pt-2">
              <div
                className="nav nav-tabs nav-fill"
                id="nav-tab"
                role="tablist"
              >
                <a
                  className="nav-item nav-link active"
                  id="nav-midForm-tab"
                  data-toggle="tab"
                  href="#nav-midForm"
                  role="tab"
                  aria-controls="nav-midForm"
                  aria-selected="true"
                  onClick={() => {
                    handleTabs("MID_FORM");
                  }}
                >
                  MID
                </a>
                <a
                  className="nav-item nav-link"
                  id="nav-crm-tab"
                  data-toggle="tab"
                  href="#nav-crm"
                  role="tab"
                  aria-controls="nav-crm"
                  aria-selected="false"
                  onClick={() => {
                    handleTabs("CRM");
                  }}
                >
                  CRM
                </a>
                <a
                  className="nav-item nav-link"
                  id="nav-processor-tab"
                  data-toggle="tab"
                  href="#nav-processor"
                  role="tab"
                  aria-controls="nav-processor"
                  aria-selected="false"
                  onClick={() => {
                    handleTabs("PROCESSOR");
                  }}
                >
                  Payment Processor
                </a>
                <a
                  className="nav-item nav-link"
                  id="nav-gateway-tab"
                  data-toggle="tab"
                  href="#nav-gateway"
                  role="tab"
                  aria-controls="nav-gateway"
                  aria-selected="false"
                  onClick={() => {
                    handleTabs("PAYMENT");
                  }}
                >
                  Payment Gateway
                </a>
              </div>
            </nav>
            <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-midForm"
                role="tabpanel"
                aria-labelledby="nav-midForm-tab"
              >
                {activeTab === "MID_FORM" ? (
                  <MidForm
                    currentMerchantId={id}
                    editMode={editMid}
                    currentId={currentId}
                    setTable={setTable}
                    setCurrentId={setCurrentMidId}
                  />
                ) : null}
              </div>
              <div
                className="tab-pane fade"
                id="nav-crm"
                role="tabpanel"
                aria-labelledby="nav-crm-tab"
              >
                {activeTab === "CRM" ? (
                  <AddCrmForm currentMerchantId={id} currentId={currentId} />
                ) : null}
              </div>
              <div
                className="tab-pane fade"
                id="nav-processor"
                role="tabpanel"
                aria-labelledby="nav-processor-tab"
              >
                {activeTab === "PROCESSOR" ? (
                  <AddProcessorForm
                    currentMerchantId={id}
                    currentId={currentId}
                  />
                ) : null}
              </div>
              <div
                className="tab-pane fade"
                id="nav-gateway"
                role="tabpanel"
                aria-labelledby="nav-gateway-tab"
              >
                {activeTab === "PAYMENT" ? (
                  <AddPayment currentMerchantId={id} currentId={currentId} />
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { getMerchantSMTPDetailsStore } = state;
  return {
    merchantBillingDetailsLoading:
      state &&
      state.merchantBillingDetailsStore &&
      state.merchantBillingDetailsStore.loading,
    merchantBillingDetails:
      state &&
      state.merchantBillingDetailsStore &&
      state.merchantBillingDetailsStore.billingdDetails,
    billing:
      state &&
      state.merchantBillingDetailsStore &&
      state.merchantBillingDetailsStore.getBilling,
    mids: state && state.midCRMStore && state.midCRMStore.getmidCRM,
    loading: state && state.midCRMStore && state.midCRMStore.loading,
    getDetailsMID:
      state && state.midCRMStore && state.midCRMStore.getDetailsMID,
    statusGMIDCRM:
      state && state.midCRMStore && state.midCRMStore.statusGMIDCRM,
    messagesGMIDCRM:
      state && state.midCRMStore && state.midCRMStore.messagesGMIDCRM,
    loadingGMIDCRM:
      state && state.midCRMStore && state.midCRMStore.loadingGMIDCRM,
    deletemidCRM:
      state && state.midCRMDeleteStore && state.midCRMDeleteStore.deletemidCRM,
    deletemidCRMStatus:
      state &&
      state.midCRMDeleteStore &&
      state.midCRMDeleteStore.deletemidCRMStatus,
    deletemidCRMMessage:
      state &&
      state.midCRMDeleteStore &&
      state.midCRMDeleteStore.deletemidCRMMessage,
    deletemidCRMLoading:
      state && state.midCRMDeleteStore && state.midCRMDeleteStore.loading,
    loadingAMSMTP:
      state &&
      state.addMerchantSMTPStore &&
      state.addMerchantSMTPStore.loadingAMSMTP,
    dataAMSMTP:
      state &&
      state.addMerchantSMTPStore &&
      state.addMerchantSMTPStore.dataAMSMTP,
    statusAMSMTP:
      state &&
      state.addMerchantSMTPStore &&
      state.addMerchantSMTPStore.statusAMSMTP,
    messageAMSMTP:
      state &&
      state.addMerchantSMTPStore &&
      state.addMerchantSMTPStore.messageAMSMTP,
    getDetailsMerchantSMTP:
      getMerchantSMTPDetailsStore &&
      getMerchantSMTPDetailsStore.getDetailsMerchantSMTP
        ? getMerchantSMTPDetailsStore.getDetailsMerchantSMTP
        : {},
    loadingGetDetailsMerchantSMTP:
      getMerchantSMTPDetailsStore &&
      getMerchantSMTPDetailsStore.loadingGetDetailsMerchantSMTP
        ? getMerchantSMTPDetailsStore.loadingGetDetailsMerchantSMTP
        : false,
    statusGetDetailsMerchantSMTP:
      getMerchantSMTPDetailsStore &&
      getMerchantSMTPDetailsStore.statusGetDetailsMerchantSMTP
        ? getMerchantSMTPDetailsStore.statusGetDetailsMerchantSMTP
        : "",
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMerchantBillingDetailsDispatch: (params, headers) =>
    dispatch(merchantBillingDetailsActions.get(params, headers)),
  postToggleChangeDispatch: (payload, headers) =>
    dispatch(merchantBillingDetailsActions.postToggleChange(payload, headers)),
  getBillingDispatch: (params, headers) =>
    dispatch(merchantBillingDetailsActions.getBilling(params, headers)),
  getMidCrmDispatch: (params, headers) =>
    dispatch(midCRMActions.getmidCRM(params, headers)),
  postPriceDetailsChangeDispatch: (payload, headers) =>
    dispatch(
      merchantBillingDetailsActions.postPriceDetailsChange(payload, headers)
    ),
  deletemidCRMDispatch: (data, headers) =>
    dispatch(deletemidCRMActions.deletemidCRM(data, headers)),
  clearDeletemidCRMDispatch: () =>
    dispatch(deletemidCRMActions.clearDeletemidCRM()),
  addMerchantSMTPDispatch: (data, headers) =>
    dispatch(addMerchantSMTPActions.addMerchantSMTP(data, headers)),
  clearaddMerchantSMTPDispatch: () =>
    dispatch(addMerchantSMTPActions.clearaddMerchantSMTP()),
  getMerchantSMTPDetailsDispatch: (id, headers) =>
    dispatch(getMerchantSMTPDetailsActions.getDetails(id, headers)),
  clearMerchantSMTPDetailsDispatch: () =>
    dispatch(getMerchantSMTPDetailsActions.clear()),
  clearMerchantBillingDispatch: () =>
    dispatch(merchantBillingDetailsActions.bilclear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMerchant);
