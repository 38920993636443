import {
  addCoverletterTypes,
  coverLetterActionsTypes,
  coverLetterGetDetailsTypes,
  editCoverletterTypes,
  coverLetterDeleteTypes,
  reasonGroupingActionsTypes,
  serviceTypeActionsTypes
} from '../actions'

export const addCoverletterInitialState = {
  list: null
}

export const addCoverletterStoreKey = 'addCoverletterStore'

export const addCoverletterReducer = (state = addCoverletterInitialState, action) => {
  switch (action.type) {
    case addCoverletterTypes.REQUEST:
      return { ...state, loadingACOL: true }
    case addCoverletterTypes.RESPONSE:
      return { ...state, dataACOL: action.data, statusACOL: action.data.status, messageACOL: action.data.message, loadingACOL: false }
    case addCoverletterTypes.CLEAR:
      return { ...state, dataACOL: null, messageACOL: '', statusACOL: '' }
    default:
      return state
  }
}

export const coverletterInitialState = {
  list: null
}

export const coverletterStoreKey = 'coverletterStore'

export const coverletterReducer = (state = coverletterInitialState, action) => {
  switch (action.type) {
    case coverLetterActionsTypes.GET_COVER_LETTER:
      return { ...state, loading: true }
    case coverLetterActionsTypes.SAVE_COVER_LETTER_RESPONSE:
      return { ...state, coverletters: action.data, loading: false }
    case coverLetterActionsTypes.CLEAR_COVER_LETTER:
      return { ...state, coverletters: null }

    case coverLetterGetDetailsTypes.GET_COVER_LETTER_DETAILS:
      return { ...state, loadingCVD: true }
    case coverLetterGetDetailsTypes.COVER_LETTER_DETAILS_RESPONSE:
      return {
        ...state,
        coverletterDetails: action.data && action.data.data,
        statusCVD: action.data && action.data.status,
        messagesCVD: action.data && action.data.message,
        loadingCVD: false
      }
    case coverLetterGetDetailsTypes.CLEAR_COVER_LETTER_DETAILS:
      return { ...state, statusCVD: '', messagesCVD: '' }
    default:
      return state
  }
}

export const editCoverletterStoreKey = 'editCoverletterStore'

export const editCoverletterReducer = (state = {}, action) => {
  switch (action.type) {
    case editCoverletterTypes.REQUEST:
      return { ...state, loadingECOL: true }
    case editCoverletterTypes.RESPONSE:
      return { ...state, dataECOL: action.data && action.data, statusECOL: action.data.status, messageECOL: action.data.message, loadingECOL: false }
    case editCoverletterTypes.CLEAR:
      return { ...state, dataECOL: null, messageECOL: '', statusECOL: '' }
    default:
      return state
  }
}

export const coverletterDeleteStoreKey = 'coverletterDeleteStore'

export const deleteCoverletterReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case coverLetterDeleteTypes.DELETE_COVER_LETTER:
      return { ...state, loading: true }
    case coverLetterDeleteTypes.SAVE_DELETE_COVER_LETTER_RESPONSE:
      return {
        ...state,
        deleteCoverletter: res.data,
        deleteCoverletterStatus: res.status,
        deleteCoverletterMessage: res.message,
        loading: false
      }
    case coverLetterDeleteTypes.CLEAR_DELETE_COVER_LETTER:
      return { ...state, deleteCoverletter: null, deleteCoverletterStatus: '', deleteCoverletterMessage: '' }
    default:
      return state
  }
}

export const reasonGroupingInitialState = {
  list: null
}

export const reasonGroupingStoreKey = 'reasonGroupingStore'

export const reasonGroupingReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case reasonGroupingActionsTypes.GET_REASON_GROUPING:
      return { ...state, loading: true }
    case reasonGroupingActionsTypes.SAVE_REASON_GROUPING_RESPONSE:
      return { ...state, getReasonGrouping: res.data, loading: false }
    case reasonGroupingActionsTypes.CLEAR_REASON_GROUPING:
      return { ...state, getReasonGrouping: null }
    default:
      return state
  }
}

export const serviceTypeInitialState = {
  list: null
}

export const serviceTypeStoreKey = 'serviceTypeStore'

export const serviceTypeReducer = (state = serviceTypeInitialState, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case serviceTypeActionsTypes.GET_SERVICE_TYPE:
      return { ...state, loading: true }
    case serviceTypeActionsTypes.SAVE_SERVICE_TYPE_RESPONSE:
      return { ...state, getServicetype: res.data, loading: false }
    case serviceTypeActionsTypes.CLEAR_SERVICE_TYPE:
      return { ...state, getServicetype: null }
    default:
      return state
  }
}
