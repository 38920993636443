import {
    getSubscriptionHistoryActionsTypes,
    addSubscriptionHistoryActionsTypes,
    getSubscriptionHistoryDetailsActionsTypes,
    updateSubscriptionHistoryActionsTypes,
    deleteSubscriptionHistoryActionsTypes
  } from '../actions'
  
  export const getSubscriptionHistoryStoreKey = 'getSubscriptionHistoryStore'
  
  export const getSubscriptionHistoryReducer = (state = {}, action) => {
    const res = action.data && action.data
    switch (action.type) {
      case getSubscriptionHistoryActionsTypes.REQUEST:
        return { ...state, loadingGetSubsHistory: true }
      case getSubscriptionHistoryActionsTypes.RESPONSE:
        return {
          ...state,
          getSubscriptionHistory: res.data,
          count: res.count,
          statusGetSubsHistory: res.status,
          messageGetSubsHistory: res.message,
          loadingGetSubsHistory: false
        }
      case getSubscriptionHistoryActionsTypes.CLEAR:
        return {
          ...state,
          getSubscriptionHistory: null,
          count: 0,
          statusGetSubsHistory: '',
          messageGetSubsHistory: ''
        }
      default:
        return state
    }
  }
  
  export const addSubscriptionHistoryStoreKey = 'addSubscriptionHistoryStore'
  
  export const addSubscriptionHistoryReducer = (state = {}, action) => {
    const res = action.data && action.data
    switch (action.type) {
      case addSubscriptionHistoryActionsTypes.REQUEST:
        return { ...state, loadingAddSubsHistory: true }
      case addSubscriptionHistoryActionsTypes.RESPONSE:
        return {
          ...state,
          addSubscriptionHistory: res.data,
          statusAddSubsHistory: res.status,
          messageAddSubsHistory: res.message,
          loadingAddSubsHistory: false
        }
      case addSubscriptionHistoryActionsTypes.CLEAR:
        return {
          ...state,
          addSubscriptionHistory: null,
          statusAddSubsHistory: '',
          messageAddSubsHistory: ''
        }
      default:
        return state
    }
  }
  
  export const getSubscriptionHistoryDetailsStoreKey = 'getSubscriptionHistoryDetailsStore'
  
  export const getSubscriptionHistoryDetailsReducer = (state = {}, action) => {
    const res = action.data && action.data
    switch (action.type) {
      case getSubscriptionHistoryDetailsActionsTypes.REQUEST:
        return { ...state, loadingGetIdSubsHistory: true }
      case getSubscriptionHistoryDetailsActionsTypes.RESPONSE:
        return {
          ...state,
          getDetailsSubscriptionHistory: res.data,
          statusGetIdSubsHistory: res.status,
          messageGetIdSubsHistory: res.message,
          loadingGetIdSubsHistory: false
        }
      case getSubscriptionHistoryDetailsActionsTypes.CLEAR:
        return {
          ...state,
          getDetailsSubscriptionHistory: null,
          statusGetIdSubsHistory: '',
          messageGetIdSubsHistory: ''
        }
      default:
        return state
    }
  }
  
  export const updateSubscriptionHistoryStoreKey = 'updateSubscriptionHistoryStore'
  
  export const updateSubscriptionHistoryReducer = (state = {}, action) => {
    const res = action.data && action.data
    switch (action.type) {
      case updateSubscriptionHistoryActionsTypes.REQUEST:
        return { ...state, loadingUpdateSubsHistory: true }
      case updateSubscriptionHistoryActionsTypes.RESPONSE:
        return {
          ...state,
          updateSubscriptionHistory: res.data,
          statusUpdateSubsHistory: res.status,
          messageUpdateSubsHistory: res.message,
          loadingUpdateSubsHistory: false
        }
      case updateSubscriptionHistoryActionsTypes.CLEAR:
        return {
          ...state,
          updateSubscriptionHistory: null,
          statusUpdateSubsHistory: '',
          messageUpdateSubsHistory: ''
        }
      default:
        return state
    }
  }
  
  export const deleteSubscriptionHistoryStoreKey = 'deleteSubscriptionHistoryStore'
  
  export const deleteSubscriptionHistoryReducer = (state = {}, action) => {
    const res = action.data && action.data
    switch (action.type) {
      case deleteSubscriptionHistoryActionsTypes.REQUEST:
        return { ...state, loadingDeleteSubsHistory: true }
      case deleteSubscriptionHistoryActionsTypes.RESPONSE:
        return {
          ...state,
          deleteSubscriptionHistory: res.data,
          statusDeleteSubsHistory: res.status,
          messageDeleteSubsHistory: res.message,
          loadingDeleteSubsHistory: false
        }
      case deleteSubscriptionHistoryActionsTypes.CLEAR:
        return {
          ...state,
          deleteSubscriptionHistory: null,
          statusDeleteSubsHistory: '',
          messageDeleteSubsHistory: ''
        }
      default:
        return state
    }
  }