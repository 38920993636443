import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { KTSVG } from "../../theme/helpers";
import {
  issuerbankalertlistActions,
  partnerCredFilterActions,
} from "../../store/actions";
import { exportActions } from "../../store/actions/export";
import { Drawer } from "../../theme/layout/components/drawer";
import { connect } from "react-redux";
import {
  DATE,
  AUTH_BASED_FILTER,
  API,
  SET_STORAGE,
} from "../../utils/constants";
import {
  getLocalStorage,
  headersWithAuth,
  setLocalStorage,
} from "../../utils/helper";
import Layout from "./layoutOptions";
import Export from "./export";
import SearchList from "./searchList";
import moment from "moment";
import ReactPaginate from "react-paginate";

function IssuerBankAlertsList(props) {
  const {
    getIssuerbanklistDispatch,
    className,
    issuerbanklistalerts,
    exports,
    loading,
    setFilterFunction,
    setFilterFunctionDispatch,
    setCredFilterParams,
  } = props;
  const didMount = React.useRef(false);

  const pathUrl = new URL(window.location.href);
  const query = pathUrl && pathUrl.search.split("?");
  const apiKeyParam = query && query[1];
  const apiParam = apiKeyParam && apiKeyParam.split("apiKey=");
  const apiParamId = apiParam && apiParam[1];
  apiParamId && setLocalStorage(API.API_KEY, apiParamId);
  apiParamId && setLocalStorage(SET_STORAGE.SET_USER_ROLE, "Client User");

  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": apiParamId ? apiParamId : getLocalStorage(API.API_KEY),
  };

  const searchName = useLocation().search;
  const bankId = searchName && searchName.split("bank_id=");
  const id = bankId && bankId[1];
  const [editMode, setEditMode] = useState(false);
  const total =
    exports && exports.data && exports.data.length ? exports.data.length : 0;
  const [limit, setLimit] = useState(25);
  const [columns, setColumns] = useState({});
  const [open, setOpen] = useState(false);
  const [, setData] = useState({});
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [searchColumns, setSearchColumns] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [, setExportColumns] = useState({});
  const [alert, setAlert] = useState({
    type: "success",
    message: "",
  });
  const [showItems, setShowItems] = useState({
    start: 0,
    end: 1,
  });
  const [buttons, setButton] = useState({
    prev: false,
    next: false,
  });
  const [sorting, setSorting] = useState({
    issuerBankId: false,
    clientId: false,
    merchantId: false,
    partnerId: false,
    alertId: false,
    alertDate: false,
    alertType: false,
    notificationType: false,
    transactionType: false,
    upiTransactionId: false,
    cardFirstSixDigits: false,
    cardLastFourDigits: false,
    cardBin: false,
    expirationMonth: false,
    expirationYear: false,
    transactionId: false,
    customerFirstName: false,
    customerLastName: false,
    alertDescriptor: false,
    alertDescriptorContact: false,
    refundedBeforeTime: false,
    outputResolutionDate: false,
    expirationStatus: false,
    isLastSixMonths: false,
    provider: false,
    cardNumber: false,
    salesTransactionDate: false,
    salesTransactionAmount: false,
    salesTransactionCurrency: false,
    customerAddress: false,
    acquirerReferrenceNumber: false,
    assignedToId: false,
    authCode: false,
    blacklistCustomer: false,
    cardVendor: false,
    chargeBackId: false,
    chargeBackReasonCode: false,
    chargeBackSaleDate: false,
    chargeBackStatus: false,
    city: false,
    cityOfMerchant: false,
    cardType: false,
    deflected: false,
    updatedDeflectionstatus: false,
    updatedDeflectionDate: false,
    createdBy: false,
    updatedBy: false,
    status: false,
  });

  const Next = () => {
    const limitRec =
      issuerbanklistalerts && issuerbanklistalerts.count < limit
        ? issuerbanklistalerts.count
        : limit;
    if (showItems.end < limitRec) {
      setShowItems((values) => ({
        ...values,
        start: values.start + 1,
        end: values.end + 1,
      }));
      setButton({ prev: false, next: false });
    } else {
      setAlert({
        type: "warning",
        message:
          "You have viewed all the listed records. You could go to Previous records.",
      });
    }
  };

  const Previous = () => {
    if (showItems.start > 0) {
      setShowItems((values) => ({
        ...values,
        start: values.start - 1,
        end: values.end - 1,
      }));
      setButton({ prev: false, next: false });
    } else {
      setAlert({
        type: "warning",
        message:
          "You have viewed all the listed records. You could go to Next records.",
      });
    }
  };

  const addColumns = (data) => {
    setColumns(data);
    setData({});
  };

  const addSearchColumns = (data) => {
    setSearchColumns(data);
    setData({});
  };

  const addExportColumns = (data) => {
    setExportColumns(data);
    setData({});
  };

  useEffect(() => {
    const credBasedPartnerValue = getLocalStorage(AUTH_BASED_FILTER.PARTNER);
    const credBasedClientValue = getLocalStorage(AUTH_BASED_FILTER.CLIENT);
    const credBasedMerchantValue = getLocalStorage(AUTH_BASED_FILTER.MERCHANT);
    const credBasedMidValue = getLocalStorage(AUTH_BASED_FILTER.MID);
    const credBasedParams = {
      parnerId: credBasedPartnerValue,
      clientId: credBasedClientValue,
      merchantId: credBasedMerchantValue,
      mid: credBasedMidValue,
    };
    const params = {
      limit: limit,
      page: activePageNumber,
      ...searchParams,
      ...credBasedParams,
    };
    const pickByParams = _.pickBy(params);
    getIssuerbanklistDispatch(
      pickByParams,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  }, []);

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
    setActivePageNumber(1);
    const params = {
      limit: value,
      page: 1,
      ...searchParams,
    };
    const pickByParams = _.pickBy(params);
    getIssuerbanklistDispatch(
      pickByParams,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const paginationParams = {
      limit: limit,
      page: pageNumber,
      ...searchParams,
    };
    const pickByParams = _.pickBy(paginationParams);
    setActivePageNumber(pageNumber);
    getIssuerbanklistDispatch(
      pickByParams,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const handleSorting = (name) => {
    if (sorting && sorting[name]) {
      sorting[name] = !sorting[name];
      setSorting(sorting);
      setData({});
      const params = {
        ...searchParams,
        limit: limit,
        page: activePageNumber,
        sort: name,
        order: "DESC",
      };
      const pickByParams = _.pickBy(params);
      getIssuerbanklistDispatch(
        pickByParams,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
    } else {
      const filter = _.mapValues(sorting, () => {
        return false;
      });
      filter[name] = !filter[name];
      setSorting(filter);
      setData({});
      const params = {
        ...searchParams,
        limit: limit,
        page: activePageNumber,
        sort: name,
        order: "ASC",
      };
      const pickByParams = _.pickBy(params);
      getIssuerbanklistDispatch(
        pickByParams,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
    }
  };
  const handleOpen = useCallback((id) => {
    setOpen(true);
    setShowItems({ start: id, end: id + 1 });
    const limitRec =
      issuerbanklistalerts && issuerbanklistalerts.count < limit
        ? issuerbanklistalerts.count
        : limit;
    const lastRec = limitRec - 1;
    if (id === 0) {
      setButton({ prev: true, next: false });
    }
    if (id === lastRec) {
      setButton({ prev: false, next: true });
    }
  }, []);

  const handleClose = useCallback(() => {
    setShowItems({ start: 0, end: 1 });
    setOpen(false);
    setButton({ prev: false, next: false });
  }, []);

  useEffect(() => {
    if (didMount.current && setFilterFunction) {
      const currentFilterParams = {
        partnerId: setCredFilterParams.partnerId,
        clientId: setCredFilterParams.clientId,
        merchantId: setCredFilterParams.merchantId,
        midId: setCredFilterParams.midId,
      };
      const params = {
        limit: limit,
        page: activePageNumber,
        ...setCredFilterParams,
      };
      const pickByParams = _.pickBy(params);
      getIssuerbanklistDispatch(
        pickByParams,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
      setFilterFunctionDispatch(false);
      setSearchParams(currentFilterParams);
    }
  }, [setFilterFunction, setCredFilterParams]);

  const totalPages =
    issuerbanklistalerts && issuerbanklistalerts.count
      ? Math.ceil(
          parseInt(issuerbanklistalerts && issuerbanklistalerts.count) / limit
        )
      : 1;

  useEffect(() => {
    if (id) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [id]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    }
  });

  return (
    <>
      <div className={`card ${className}`}>
        <div className="card-body py-3">
          <div className="d-flex  px - 2">
            <div className="d-flex justify-content-start col-md-6">
              <div className="col-md-3 mt-1">
                {issuerbanklistalerts && issuerbanklistalerts.count ? (
                  <span className="text-muted fw-bold d-flex fs-5 mt-2">
                    Total: &nbsp;{" "}
                    <span className="text-gray-700 fw-bolder text-hover-primary fs-5">
                      {issuerbanklistalerts.count}
                    </span>
                  </span>
                ) : null}
              </div>
              <div className="col-md-7 d-flex">
                <label className="col-form-label text-lg-start">
                  Record(s) per Page : &nbsp;{" "}
                </label>
                <div className="col-md-3">
                  <select
                    className="form-select w-6rem"
                    data-control="select"
                    data-placeholder="Select an option"
                    data-allow-clear="true"
                    onChange={(e) => handleRecordPerPage(e)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="d-flex col-md-6 justify-content-end my-auto">
              <div className="my-auto me-3">
                {!editMode ? (
                  <div className="my-auto">
                    <Link
                      to="/issuer-bank-alerts/sdk"
                      className="btn btn-sm btn-light-primary btn-responsive font-5vw"
                    >
                      {/* eslint-disable */}
                      <KTSVG path="/media/icons/duotune/files/fil003.svg" />
                      {/* eslint-disable */}
                      Generate SDK
                    </Link>
                  </div>
                ) : null}
              </div>
              <div className="my-auto">
                <SearchList
                  searchColumns={searchColumns}
                  columnss={columns}
                  setSearchParams={setSearchParams}
                  limit={limit}
                  activePageNumber={activePageNumber}
                  setActivePageNumber={setActivePageNumber}
                />
              </div>
              <div className="my-auto">
                <Layout
                  className="my-auto"
                  addColumns={(data) => addColumns(data)}
                  addSearch={(data) => addSearchColumns(data)}
                  addExport={(data) => addExportColumns(data)}
                />
              </div>
              <div className="my-auto">
                <Export searchParams={{ ...searchParams }} />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-rounded table-striped border gs-2 mt-6">
              <thead className="fw-bolder fs-8 text-gray-800">
                <tr>
                  {columns && columns.alertId && (
                    <th>
                      <div className="d-flex min-w-125px">
                        <span>Alert ID</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("alertId")}
                          >
                            <i
                              className={`bi ${
                                sorting.alertId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.issuerBankId && (
                    <th>
                      <div className="d-flex">
                        <span>Issuer Bank</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("issuerBankId")}
                          >
                            <i
                              className={`bi ${
                                sorting.issuerBankId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.alertDate && (
                    <th>
                      <div className="d-flex">
                        <span>Alert Date</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("alertDate")}
                          >
                            <i
                              className={`bi ${
                                sorting.alertDate
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.alertType && (
                    <th>
                      <div className="d-flex">
                        <span>Alert Type</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("alertType")}
                          >
                            <i
                              className={`bi ${
                                sorting.alertType
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.notificationType && (
                    <th>
                      <div className="d-flex">
                        <span>Notification Type</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("notificationType")}
                          >
                            <i
                              className={`bi ${
                                sorting.notificationType
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.transactionType && (
                    <th>
                      <div className="d-flex">
                        <span>Transaction Type</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("transactionType")}
                          >
                            <i
                              className={`bi ${
                                sorting.transactionType
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.upiTransactionId && (
                    <th>
                      <div className="d-flex">
                        <span>UPI Transaction Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("upiTransactionId")}
                          >
                            <i
                              className={`bi ${
                                sorting.upiTransactionId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardBin && (
                    <th>
                      <div className="d-flex">
                        <span>Card Bin</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardBin")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardBin
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.transactionId && (
                    <th>
                      <div className="d-flex">
                        <span>Transaction Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("transactionId")}
                          >
                            <i
                              className={`bi ${
                                sorting.transactionId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.customerFirstName && (
                    <th>
                      <div className="d-flex">
                        <span>Customer First Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("customerFirstName")}
                          >
                            <i
                              className={`bi ${
                                sorting.customerFirstName
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.customerLastName && (
                    <th>
                      <div className="d-flex">
                        <span>Customer Last Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("customerLastName")}
                          >
                            <i
                              className={`bi ${
                                sorting.customerLastName
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.alertDescriptor && (
                    <th>
                      <div className="d-flex">
                        <span>Alert Descriptor</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("alertDescriptor")}
                          >
                            <i
                              className={`bi ${
                                sorting.alertDescriptor
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}

{columns && columns.notificationType && (
                    <th>
                      <div className="d-flex">
                        <span>Notification Type</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("notificationType")}
                          >
                            <i
                              className={`bi ${
                                sorting.notificationType
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}

                  {columns && columns.createdBy && (
                    <th>
                      <div className="d-flex">
                        <span>Created By</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("createdBy")}
                          >
                            <i
                              className={`bi ${
                                sorting.createdBy
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}

                    {columns && columns.updatedBy && (
                    <th>
                      <div className="d-flex">
                        <span>Updated By</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("updatedBy")}
                          >
                            <i
                              className={`bi ${
                                sorting.updatedBy
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}

                  {columns && columns.alertDescriptorContact && (
                    <th>
                      <div className="d-flex">
                        <span>Alert Descriptor Contact</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("alertDescriptorContact")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.alertDescriptorContact
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardFirstSixDigits && (
                    <th>
                      <div className="d-flex">
                        <span>Card First 6 Digits</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardFirstSixDigits")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardFirstSixDigits
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardLastFourDigits && (
                    <th>
                      <div className="d-flex">
                        <span>Card Last 4 Digits</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardLastFourDigits")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardLastFourDigits
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.expirationMonth && (
                    <th>
                      <div className="d-flex">
                        <span>Expiration Month</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("expirationMonth")}
                          >
                            <i
                              className={`bi ${
                                sorting.expirationMonth
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.expirationYear && (
                    <th>
                      <div className="d-flex">
                        <span>Expiration Year</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("expirationYear")}
                          >
                            <i
                              className={`bi ${
                                sorting.expirationYear
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.clientId && (
                    <th>
                      <div className="d-flex">
                        <span>Client</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("clientId")}
                          >
                            <i
                              className={`bi ${
                                sorting.clientId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.merchantId && (
                    <th>
                      <div className="d-flex">
                        <span>Merchant</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("merchantId")}
                          >
                            <i
                              className={`bi ${
                                sorting.merchantId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.partnerId && (
                    <th>
                      <div className="d-flex">
                        <span>Partner</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("partnerId")}
                          >
                            <i
                              className={`bi ${
                                sorting.partnerId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.refundedBeforeTime && (
                    <th>
                      <div className="d-flex">
                        <span>Refunded Before Time</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("refundedBeforeTime")}
                          >
                            <i
                              className={`bi ${
                                sorting.refundedBeforeTime
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.outputResolutionDate && (
                    <th>
                      <div className="d-flex">
                        <span>Output Resolution Date</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("outputResolutionDate")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.outputResolutionDate
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.expirationStatus && (
                    <th>
                      <div className="d-flex">
                        <span>Expiration Status</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("expirationStatus")}
                          >
                            <i
                              className={`bi ${
                                sorting.expirationStatus
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.isLastSixMonths && (
                    <th>
                      <div className="d-flex">
                        <span>Is Last Six Months</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("isLastSixMonths")}
                          >
                            <i
                              className={`bi ${
                                sorting.isLastSixMonths
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.provider && (
                    <th>
                      <div className="d-flex">
                        <span>Provider</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("provider")}
                          >
                            <i
                              className={`bi ${
                                sorting.provider
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardNumber && (
                    <th>
                      <div className="d-flex">
                        <span>Card Number</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardNumber")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardNumber
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.salesTransactionDate && (
                    <th>
                      <div className="d-flex">
                        <span>Sales Transaction Date</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("salesTransactionDate")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.salesTransactionDate
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.salesTransactionAmount && (
                    <th className="min-w-300px text-start">
                      <div className="d-flex">
                        <span>Sales Transaction Amount</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("salesTransactionAmount")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.salesTransactionAmount
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.salesTransactionCurrency && (
                    <th>
                      <div className="d-flex">
                        <span>Sales Transaction Currency</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("salesTransactionCurrency")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.salesTransactionCurrency
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.customerAddress && (
                    <th>
                      <div className="d-flex">
                        <span>Customer Address</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("salesTransactionCurrency")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.salesTransactionCurrency
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.acquirerReferrenceNumber && (
                    <th>
                      <div className="d-flex">
                        <span>ARN</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("acquirerReferrenceNumber")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.acquirerReferrenceNumber
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.assignedToId && (
                    <th>
                      <div className="d-flex">
                        <span>Assigned ToId</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("assignedToId")}
                          >
                            <i
                              className={`bi ${
                                sorting.assignedToId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.authCode && (
                    <th>
                      <div className="d-flex">
                        <span>Auth Code</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("authCode")}
                          >
                            <i
                              className={`bi ${
                                sorting.authCode
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.blacklistCustomer && (
                    <th>
                      <div className="d-flex">
                        <span>Blacklist Customer</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("blacklistCustomer")}
                          >
                            <i
                              className={`bi ${
                                sorting.blacklistCustomer
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardVendor && (
                    <th>
                      <div className="d-flex">
                        <span>Card Vendor</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardVendor")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardVendor
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.chargeBackId && (
                    <th>
                      <div className="d-flex">
                        <span>Charge Back Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("chargeBackId")}
                          >
                            <i
                              className={`bi ${
                                sorting.chargeBackId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.chargeBackReasonCode && (
                    <th>
                      <div className="d-flex">
                        <span>Charge Back Reason Code</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("chargeBackReasonCode")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.chargeBackReasonCode
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.chargeBackSaleDate && (
                    <th>
                      <div className="d-flex">
                        <span>Charge Back Sale Date</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("chargeBackSaleDate")}
                          >
                            <i
                              className={`bi ${
                                sorting.chargeBackSaleDate
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.chargeBackStatus && (
                    <th>
                      <div className="d-flex">
                        <span>Charge Back Status</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("chargeBackStatus")}
                          >
                            <i
                              className={`bi ${
                                sorting.chargeBackStatus
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.city && (
                    <th>
                      <div className="d-flex">
                        <span>City</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("city")}
                          >
                            <i
                              className={`bi ${
                                sorting.city
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cityOfMerchant && (
                    <th>
                      <div className="d-flex">
                        <span>City Of Merchant</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cityOfMerchant")}
                          >
                            <i
                              className={`bi ${
                                sorting.cityOfMerchant
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardType && (
                    <th>
                      <div className="d-flex">
                        <span>Card Type</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardType")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardType
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.deflected && (
                    <th>
                      <div className="d-flex">
                        <span>Deflected</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("deflected")}
                          >
                            <i
                              className={`bi ${
                                sorting.deflected
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.updatedDeflectionstatus && (
                    <th>
                      <div className="d-flex">
                        <span>Updated Deflection Status</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("updatedDeflectionstatus")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.updatedDeflectionstatus
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.updatedDeflectionDate && (
                    <th>
                      <div className="d-flex">
                        <span>Updated Deflection Date</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("updatedDeflectionDate")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.updatedDeflectionDate
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.status && (
                    <th>
                      <div className="d-flex">
                        <span>Status</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("status")}
                          >
                            <i
                              className={`bi ${
                                sorting.status
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="fs-8">
                {!loading ? (
                  issuerbanklistalerts &&
                  issuerbanklistalerts.data &&
                  issuerbanklistalerts.data ? (
                    issuerbanklistalerts.data.map((issuerbanklist, _id) => {
                      return (
                        <tr
                          key={_id}
                          style={
                            _id === 0
                              ? { borderColor: "black" }
                              : { borderColor: "white" }
                          }
                        >
                          {columns && columns.alertId && (
                            <td className="ellipsis">
                              <button
                                type="button"
                                className="btn btn-link p-0 btn-sm"
                                style={{ color: "#0781d7" }}
                                onClick={() => {
                                  handleOpen(_id);
                                }}
                              >
                                <small>
                                  {issuerbanklist.alertId
                                    ? issuerbanklist.alertId
                                    : ""}
                                </small>
                              </button>
                            </td>
                          )}
                          {columns && columns.issuerBankId && (
                            <td className="ellipsis">
                              {issuerbanklist && issuerbanklist.issuerBankId
                                ? `${issuerbanklist.issuerBankId.bankName}`
                                : ""}
                            </td>
                          )}
                          {columns && columns.alertDate && (
                            <td className="ellipsis">
                              {issuerbanklist && issuerbanklist.alertDate
                                ? moment(issuerbanklist.alertDate).format(
                                    DATE.DATE_FORMAT_MINS
                                  )
                                : "--"}
                            </td>
                          )}
                          {columns && columns.alertType && (
                            <td className="ellipsis">
                              {issuerbanklist.alertType}
                            </td>
                          )}
                          {columns && columns.notificationType && (
                            <td className="ellipsis">
                              {issuerbanklist.notificationType
                                ? issuerbanklist.notificationType
                                : "--"}
                            </td>
                          )}
                          {columns && columns.transactionType && (
                            <td className="ellipsis">
                              {issuerbanklist.transactionType}
                            </td>
                          )}
                          {columns && columns.upiTransactionId && (
                            <td className="ellipsis">
                              {issuerbanklist.upiTransactionId
                                ? issuerbanklist.upiTransactionId
                                : "--"}
                            </td>
                          )}
                          {columns && columns.cardBin && (
                            <td className="ellipsis">
                              {issuerbanklist.cardBin
                                ? issuerbanklist.cardBin
                                : "--"}
                            </td>
                          )}
                          {columns && columns.transactionId && (
                            <td className="ellipsis">
                              {issuerbanklist.transactionId
                                ? issuerbanklist.transactionId
                                : "--"}
                            </td>
                          )}
                          {columns && columns.customerFirstName && (
                            <td className="ellipsis">
                              {issuerbanklist.customerFirstName}
                            </td>
                          )}
                          {columns && columns.customerLastName && (
                            <td className="ellipsis">
                              {issuerbanklist.customerLastName}
                            </td>
                          )}
                          {columns && columns.alertDescriptor && (
                            <td className="ellipsis">
                              {issuerbanklist.alertDescriptor}
                            </td>
                          )}
                          {columns && columns.alertDescriptorContact && (
                            <td className="ellipsis">
                              {issuerbanklist.alertDescriptorContact}
                            </td>
                          )}
                          {columns && columns.cardFirstSixDigits && (
                            <td className="ellipsis">
                              {issuerbanklist.cardFirstSixDigits
                                ? issuerbanklist.cardFirstSixDigits
                                : "--"}
                            </td>
                          )}
                          {columns && columns.cardLastFourDigits && (
                            <td className="ellipsis">
                              {issuerbanklist.cardLastFourDigits
                                ? issuerbanklist.cardLastFourDigits
                                : "--"}
                            </td>
                          )}
                          {columns && columns.expirationMonth && (
                            <td className="ellipsis">
                              {issuerbanklist.expirationMonth
                                ? issuerbanklist.expirationMonth
                                : "--"}
                            </td>
                          )}
                          {columns && columns.expirationYear && (
                            <td className="ellipsis">
                              {issuerbanklist.expirationYear
                                ? issuerbanklist.expirationYear
                                : "--"}
                            </td>
                          )}
                          {columns && columns.clientId && (
                            <td className="ellipsis">
                              {issuerbanklist && issuerbanklist.client
                                ? `${issuerbanklist.clientId.company}`
                                : ""}
                            </td>
                          )}
                          {columns && columns.merchantId && (
                            <td className="ellipsis">
                              {issuerbanklist && issuerbanklist.merchantId
                                ? `${issuerbanklist.merchantId.merchantFirstName} ${issuerbanklist.merchantId.merchantLastName} `
                                : ""}
                            </td>
                          )}
                          {columns && columns.partnerId && (
                            <td className="ellipsis">
                              {issuerbanklist && issuerbanklist.partnerId
                                ? `${issuerbanklist.partnerId.partnerFirstName} ${issuerbanklist.partnerId.partnerLastName} `
                                : ""}
                            </td>
                          )}
                          {columns && columns.refundedBeforeTime && (
                            <td className="ellipsis">
                              {issuerbanklist &&
                              issuerbanklist.refundedBeforeTime
                                ? moment(
                                    issuerbanklist.refundedBeforeTime
                                  ).format(DATE.DATE_FORMAT_MINS)
                                : "--"}
                            </td>
                          )}
                          {columns && columns.outputResolutionDate && (
                            <td className="ellipsis">
                              {issuerbanklist &&
                              issuerbanklist.outputResolutionDate
                                ? moment(
                                    issuerbanklist.outputResolutionDate
                                  ).format(DATE.DATE_FORMAT_MINS)
                                : "--"}
                            </td>
                          )}
                          {columns && columns.expirationStatus && (
                            <td className="ellipsis">
                              {issuerbanklist.expirationStatus}
                            </td>
                          )}
                          {columns && columns.isLastSixMonths && (
                            <td className="ellipsis">
                              {issuerbanklist.isLastSixMonths}
                            </td>
                          )}
                          {columns && columns.provider && (
                            <td className="ellipsis">
                              {issuerbanklist.provider}
                            </td>
                          )}
                          {columns && columns.cardNumber && (
                            <td className="ellipsis">
                              {issuerbanklist.cardNumber}
                            </td>
                          )}
                          {columns && columns.salesTransactionDate && (
                            <td className="ellipsis">
                              {issuerbanklist &&
                              issuerbanklist.salesTransactionDate
                                ? moment(
                                    issuerbanklist.salesTransactionDate
                                  ).format(DATE.DATE_FORMAT_MINS)
                                : "--"}
                            </td>
                          )}
                          {columns && columns.salesTransactionAmount && (
                            <td className="ellipsis">
                              {issuerbanklist.salesTransactionAmount}
                            </td>
                          )}
                          {columns && columns.salesTransactionCurrency && (
                            <td className="ellipsis">
                              {issuerbanklist.salesTransactionCurrency}
                            </td>
                          )}
                          {columns && columns.customerAddress && (
                            <td className="ellipsis">
                              {issuerbanklist.customerAddress}
                            </td>
                          )}
                          {columns && columns.acquirerReferrenceNumber && (
                            <td className="ellipsis">
                              {issuerbanklist.acquirerReferrenceNumber}
                            </td>
                          )}
                          {columns && columns.assignedToId && (
                            <td className="ellipsis">
                              {issuerbanklist.assignedToId}
                            </td>
                          )}
                          {columns && columns.authCode && (
                            <td className="ellipsis">
                              {issuerbanklist.authCode}
                            </td>
                          )}
                          {columns && columns.blacklistCustomer && (
                            <td className="ellipsis">
                              {issuerbanklist.blacklistCustomer}
                            </td>
                          )}
                          {columns && columns.cardVendor && (
                            <td className="ellipsis">
                              {issuerbanklist.cardVendor}
                            </td>
                          )}
                          {columns && columns.chargeBackId && (
                            <td className="ellipsis">
                              {issuerbanklist.chargeBackId}
                            </td>
                          )}
                          {columns && columns.chargeBackReasonCode && (
                            <td className="ellipsis">
                              {issuerbanklist.chargeBackReasonCode}
                            </td>
                          )}
                          {columns && columns.chargeBackSaleDate && (
                            <td className="ellipsis">
                              {issuerbanklist.chargeBackSaleDate}
                            </td>
                          )}
                          {columns && columns.chargeBackStatus && (
                            <td className="ellipsis">
                              {issuerbanklist.chargeBackStatus}
                            </td>
                          )}
                          {columns && columns.city && (
                            <td className="ellipsis">{issuerbanklist.city}</td>
                          )}
                          {columns && columns.cityOfMerchant && (
                            <td className="ellipsis">
                              {issuerbanklist.cityOfMerchant}
                            </td>
                          )}
                          {columns && columns.cardType && (
                            <td className="ellipsis">
                              {issuerbanklist.cardType}
                            </td>
                          )}
                          {columns && columns.deflected && (
                            <td className="ellipsis">
                              {issuerbanklist.deflected}
                            </td>
                          )}
                          {columns && columns.updatedDeflectionstatus && (
                            <td className="ellipsis">
                              {issuerbanklist.updatedDeflectionstatus}
                            </td>
                          )}
                          {columns && columns.updatedDeflectionDate && (
                            <td className="ellipsis">
                              {issuerbanklist.updatedDeflectionDate}
                            </td>
                          )}
                          {columns && columns.status && (
                            <td className="ellipsis">
                              {issuerbanklist.status}
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center py-3">
                      <td colSpan="100%">No record(s) found</td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="100%" className="text-center">
                      <div
                        className="spinner-border text-primary m-5"
                        role="status"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Drawer
              anchor="right"
              open={open}
              drawerWidth={1300}
              onClose={handleClose}
            >
              <>
                {issuerbanklistalerts &&
                  issuerbanklistalerts.data &&
                  issuerbanklistalerts.data.length > 0 &&
                  issuerbanklistalerts.data
                    .slice(showItems.start, showItems.end)
                    .map((item, i) => {
                      return (
                        <div
                          className="card shadow-none rounded-0 w-100"
                          key={i}
                        >
                          <div>
                            {alert.message ? (
                              <div className="modal-bg">
                                <div className="center-screen">
                                  <div className="card shadow-sm my-auto">
                                    <div className="card-header">
                                      <h3 className="card-title">Warning</h3>
                                      <div className="card-toolbar">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-outline-danger"
                                          onClick={() => {
                                            setAlert({
                                              type: "success",
                                              message: "",
                                            });
                                          }}
                                        >
                                          X
                                        </button>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      {alert.message}
                                    </div>
                                    <div className="card-footer py-2 d-flex justify-content-end">
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-sm btn-light"
                                        onClick={() => {
                                          setAlert({
                                            type: "success",
                                            message: "",
                                          });
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card-header" id="kt_help_header">
                            <div className="d-flex flex-column flex-xxl-row">
                              <div
                                className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4"
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover"
                                title=""
                                data-bs-original-title="Enter invoice number"
                              >
                                <span className="fs-2x fw-bolder text-gray-700">
                                  Issuer Bank #
                                </span>
                                <span className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                  {" "}
                                  {item.alertId}
                                </span>
                                <span>
                                  <button
                                    className="btn btn-sm btn-light-primary btn-responsive mb-1"
                                    rel="prev"
                                    type="button"
                                    disabled={buttons.prev}
                                    onClick={() => {
                                      Previous();
                                    }}
                                  >
                                    <span>
                                      <i className="bi bi-caret-left-fill mb-1" />
                                    </span>
                                    <span>Previous</span>
                                  </button>
                                  &nbsp; &nbsp;
                                </span>
                                <span>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-light-primary btn-responsive mb-1"
                                    rel="next"
                                    disabled={buttons.next}
                                    onClick={() => {
                                      Next();
                                    }}
                                  >
                                    <span>Next</span>
                                    &nbsp; &nbsp;
                                    <span>
                                      <i className="bi bi-caret-right-fill mb-1" />
                                    </span>
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-0 d-flex justify-content-end" />
                            <div className="card-toolbar">
                              <button
                                type="button"
                                className="btn btn-sm btn-icon btn-active-light-primary me-n5"
                                onClick={() => {
                                  handleClose();
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/arrows/arr061.svg"
                                  className="svg-icon-1"
                                />
                              </button>
                            </div>
                          </div>

                          <div className="card-body p-8">
                            <div className="p-1">
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Issuer Bank
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {`${
                                      item &&
                                      item.issuerBankId &&
                                      item.issuerBankId.bankName
                                    }`}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Alert Date
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {moment(item.alertDate).format(
                                      "yyyy-MMM-DD, hh:mm"
                                    )}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Alert Type
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.alertType ? item.alertType : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Notification Type
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.notificationType
                                      ? item.notificationType
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Transaction Type
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.transactionType
                                      ? item.transactionType
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    UPI Transaction Id
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.upiTransactionId
                                      ? item.upiTransactionId
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Card Bin
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.cardBin ? item.cardBin : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Transaction Id
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.transactionId
                                      ? item.transactionId
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Customer First Name
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.customerFirstName}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Customer Last Name
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.customerLastName}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Alert Descriptor
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.alertDescriptor}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Card First 6 Digits
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.cardFirstSixDigits
                                      ? item.cardFirstSixDigits
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Card Last 4 Digits
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.cardLastFourDigits
                                      ? item.cardLastFourDigits
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Expiration Month
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.expirationMonth
                                      ? item.expirationMonth
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Expiration Year
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.expirationYear
                                      ? item.expirationYear
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                              <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Created By
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.createdBy
                                      ? item.createdBy
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Updated By
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.udpatedBy
                                      ? item.udpatedBy
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Alert Descriptor Contact
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.alertDescriptorContact}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Refunded
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item && item.refunded
                                      ? item.refunded
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Merchant
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item && item.merchantId
                                      ? `${
                                          item &&
                                          item.merchantId &&
                                          item.merchantId.merchantFirstName
                                        } ${
                                          item &&
                                          item.merchantId &&
                                          item.merchantId.merchantLastName
                                        }`
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Partner
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item && item.partnerId
                                      ? `${
                                          item.partnerId &&
                                          item.partnerId.partnerFirstName
                                        } ${
                                          item.partnerId &&
                                          item.partnerId.partnerLastName
                                        }`
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Refunded Before Time
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item && item.refundedBeforeTime
                                      ? moment(item.refundedBeforeTime).format(
                                          DATE.DATE_FORMAT_MINS
                                        )
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Chargeback At
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item && item.outputResolutionDate
                                      ? moment(
                                          item.outputResolutionDate
                                        ).format(DATE.DATE_FORMAT_MINS)
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Expiration Status
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.expirationStatus
                                      ? item.expirationStatus
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Is Last Six Months
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.isLastSixMonths
                                      ? item.isLastSixMonths
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Provider
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.provider ? item.provider : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Card Number
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.cardNumber ? item.cardNumber : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Sales Transaction Date
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.salesTransactionDate
                                      ? moment(
                                          item.salesTransactionDate
                                        ).format("yyyy-MMM-DD")
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Sales Transaction Amount
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.salesTransactionAmount
                                      ? item.salesTransactionAmount
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Sales Transaction Currency
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.salesTransactionCurrency
                                      ? item.salesTransactionCurrency
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    CustomerAddress
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.customerAddress
                                      ? item.customerAddress
                                      : "--"}
                                  </div>
                                </td>

                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    ARN
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.acquirerReferrenceNumber
                                      ? item.acquirerReferrenceNumber
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Assigned Told
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item && item.assignedToId}
                                  </div>
                                </td>

                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Auth Code
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.authCode ? item.authCode : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Card Acceptor ID
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.cardAcceptorId
                                      ? item.cardAcceptorId
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Blacklist Customer
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.blacklistCustomer
                                      ? item.blacklistCustomer
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Card Vendor
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.cardVendor ? item.cardVendor : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Chargeback Id
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.chargeBackId
                                      ? item.chargeBackId
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Chargeback Reason Code
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.chargeBackReasonCode
                                      ? item.chargeBackReasonCode
                                      : "--"}
                                  </div>
                                </td>

                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Chargeback Sale Date
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.chargeBackSaleDate
                                      ? moment(item.chargeBackSaleDate).format(
                                          "yyyy-MMM-DD, hh:mm"
                                        )
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Chargeback Status
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.chargeBackStatus
                                      ? item.chargeBackStatus
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    City
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.city ? item.city : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    City of Merchant
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.cityOfMerchant
                                      ? item.cityOfMerchant
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Card Type
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.cardType ? item.cardType : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Deflected
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.deflected ? item.deflected : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Updated Deflection Status
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.updatedDeflectionstatus
                                      ? item.updatedDeflectionstatus
                                      : "--"}
                                  </div>
                                </td>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Updated Deflection Date
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.updatedDeflectionDate
                                      ? moment(
                                          item.updatedDeflectionDate
                                        ).format("yyyy-MMM-DD, hh:mm")
                                      : "--"}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex text-gray-700 fw-bolder my-1 fs-6">
                                    Status
                                  </div>
                                </th>
                                <td>
                                  <div className="form-control form-control-flush fw-bolder text-muted fs-6 w-250px">
                                    {item.status ? item.status : "--"}
                                  </div>
                                </td>
                              </tr>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </>
            </Drawer>
          </div>
          <div className="form-group row mb-4 mt-6">
            <div className="col-lg-12 mb-4 align-items-end d-flex">
              <div className="col-lg-12 pagination">
                <ReactPaginate
                  nextLabel="Next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  forcePage={activePageNumber - 1}
                  pageCount={totalPages}
                  previousLabel="< Prev"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    exportStore,
    issuerbankalertExportColumnStore,
    issuerbanklistalertsStore,
    partnerCrudFilterStore,
    clientCredFilterStore,
  } = state;
  return {
    exports: exportStore && exportStore.exports ? exportStore.exports : [],
    issuerBankAlertExportColumns:
      issuerbankalertExportColumnStore &&
      issuerbankalertExportColumnStore.issuerBankAlertExportColumns
        ? issuerbankalertExportColumnStore.issuerBankAlertExportColumns
        : {},
    issuerbanklistalerts:
      state &&
      state.issuerbanklistalertsStore &&
      state.issuerbanklistalertsStore.issuerbanklistalerts,
    loading:
      issuerbanklistalertsStore && issuerbanklistalertsStore.loading
        ? issuerbanklistalertsStore.loading
        : false,
    setFilterFunction:
      partnerCrudFilterStore &&
      partnerCrudFilterStore.setFilterFunction &&
      partnerCrudFilterStore.setFilterFunction
        ? partnerCrudFilterStore.setFilterFunction
        : false,
    setCredFilterParams:
      partnerCrudFilterStore && partnerCrudFilterStore.setCredFilterParams
        ? partnerCrudFilterStore.setCredFilterParams
        : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  getIssuerbanklistDispatch: (params, headers) =>
    dispatch(
      issuerbankalertlistActions.getIssuerbankalertlist(params, headers)
    ),
  clearPostsDispatch: () => dispatch(exportActions.clearPosts()),
  setFilterFunctionDispatch: (data) =>
    dispatch(partnerCredFilterActions.setFilterFunction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssuerBankAlertsList);
