import { USER_ERROR, REGEX } from '../../../../utils/constants'

export const validate = (values, setErrors) => {
  const errors = {}
  // if (!values.totalAmount) {
  //   errors.totalAmount = USER_ERROR.TRANSACTION_AMOUNT
  // }
  // if (!values.totalAmountCurrency) {
  //   errors.totalAmountCurrency = USER_ERROR.TRANSACTION_CURRENCY
  // }
  // if (!values.avsChecked) {
  //   errors.avsChecked = USER_ERROR.AVS_CHECKED
  // }
  // if (!values.avsResultCode) {
  //   errors.avsResultCode = USER_ERROR.AVS_RESULT
  // }
  // if (!values.cvvChecked) {
  //   errors.cvvChecked = USER_ERROR.CVV_CHECKED
  // }
  // if (!values.cvvResultCode) {
  //   errors.cvvResultCode = USER_ERROR.CVV_RESULT_CODE
  // }
  // if (!values.threeDSChallenged) {
  //   errors.threeDSChallenged = USER_ERROR.Three_D_SECURE
  // }
  // if (!values.threeDSTransactionId) {
  //   errors.threeDSTransactionId = USER_ERROR.Three_D_TRANSACTION
  // }
  // if (!values.threeDSAuthenticationValue) {
  //   errors.threeDSAuthenticationValue = USER_ERROR.Three_D_AUTH
  // }
  // if (!values.billingAddress) {
  //   errors.billingAddress = USER_ERROR.BILLING_ADDRESS
  // }
  // if (!values.billingAddressDetails) {
  //   errors.billingAddressDetails = USER_ERROR.BILLING_ADDRESS_DETAILS
  // }
  // if (!values.shippingAndHandlingAmount) {
  //   errors.shippingAndHandlingAmount = USER_ERROR.SHIPPING_AMOUNT
  // }
  // if (!values.shippingAndHandlingCurrency) {
  //   errors.shippingAndHandlingCurrency = USER_ERROR.SHIPPING_CURRENCY
  // }
  // if (!values.shippingAddress) {
  //   errors.shippingAddress = USER_ERROR.SHIPPING_ADDRESS
  // }
  // if (!values.shippingAddressDetails) {
  //   errors.shippingAddressDetails = USER_ERROR.SHIPPING_ADDRESS_DETAILS
  // }
  // if (!values.refundProcessed) {
  //   errors.refundProcessed = USER_ERROR.REFUND_PROCESSED
  // }
  // if (!values.refundAmount) {
  //   errors.refundAmount = USER_ERROR.REFUND_AMOUNT
  // }
  // if (!values.refundCurrency) {
  //   errors.refundCurrency = USER_ERROR.REFUND_CURRENCY
  // }
  // if (!values.dateOfRefund) {
  //   errors.dateOfRefund = USER_ERROR.DATE_REFUND
  // }
  // if (!values.refundPolicy) {
  //   errors.refundPolicy = USER_ERROR.REFUND_POLICY
  // }
  setErrors(errors)
  return errors
}

export const validateStep2 = (values, setErrors) => {
  const errors = {}
  // if (!values.customerSalutation) {
  //   errors.customerSalutation = USER_ERROR.CUSTOMER_SALUTATION
  // }
  // if (!values.customerFirstName) {
  //   errors.customerFirstName = USER_ERROR.CUS_FNAME
  // }
  // if (!values.customerLastName) {
  //   errors.customerLastName = USER_ERROR.CUS_LNAME
  // }
  // if (!values.customerAddress) {
  //   errors.customerAddress = USER_ERROR.CUS_ADDRESS
  // }
  // if (!values.customerCity) {
  //   errors.customerCity = USER_ERROR.CUSTOMER_CITY
  // }
  // if (!values.customerState) {
  //   errors.customerState = USER_ERROR.CUSTOMER_STATE
  // }
  // if (!values.customerZip) {
  //   errors.customerZip = USER_ERROR.CUSTOMER_ZIPCODE
  // }
  // if (values.billingZipcode && !REGEX.ZIP_CODE.test(values.billingZipcode)) {
  //   errors.billingZipcode = USER_ERROR.ZIPCODE_INVALID
  // }
  // if (!values.customerCountry) {
  //   errors.customerCountry = USER_ERROR.CUSTOMER_COUNTRY
  // }
  // if (!values.customerEmailName) {
  //   errors.customerEmailName = USER_ERROR.CUSTOMER_EMAIL_NAME
  // }
  // if (!values.customerEmail) {
  //   errors.customerEmail = USER_ERROR.CUS_EMAIL
  // } else if (values.customerEmail && !/\S+@\S+\.\S+/.test(values.customerEmail)) {
  //   errors.customerEmail = USER_ERROR.EMAIL_INVALID
  // }
  // if (values.customerEmail) {
  //   const getEmailName = values.customerEmail.split('@')
  //   const emailName = getEmailName && getEmailName[0]
  //   if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
  //     errors.customerEmail = USER_ERROR.EMAIL_INVALID
  //   }
  // }
  // if (!values.customerPhoneType) {
  //   errors.customerPhoneType = USER_ERROR.CUS_PHONE_TYPE
  // }
  // if (!values.customerPhoneNumber) {
  //   errors.customerPhoneNumber = USER_ERROR.CUS_PHONE_NUMBER
  // } else if (!REGEX.NUMERIC.test(values.customerPhoneNumber)) {
  //   errors.customerPhoneNumber = USER_ERROR.PHONE_NUMBER_INVALID
  // }
  // if (!values.dateOfBirth) {
  //   errors.dateOfBirth = USER_ERROR.DOB
  // }
  // if (!values.firstTransactionDate) {
  //   errors.firstTransactionDate = USER_ERROR.FIRST_TD_DATE
  // }
  // if (!values.lengthOfRelationship) {
  //   errors.lengthOfRelationship = USER_ERROR.LENGTH
  // }
  // if (!values.authenticationUsedForIdentification) {
  //   errors.authenticationUsedForIdentification = USER_ERROR.AUTH_IDENTIFICATION
  // }
  // if (!values.notes) {
  //   errors.notes = USER_ERROR.NOTES
  // }
  setErrors(errors)
  return errors
}

export const validateStep3 = (values, setErrors) => {
  const errors = {}
  // if (!values.productName) {
  //   errors.productName = USER_ERROR.PRODUCT_NAME
  // }
  // if (!values.productModel) {
  //   errors.productModel = USER_ERROR.PRO_MODEL
  // }
  // if (!values.productSku) {
  //   errors.productSku = USER_ERROR.PRO_SKEW
  // }
  // if (!values.productDescription) {
  //   errors.productDescription = USER_ERROR.PRO_DES
  // }
  // if (!values.productUrl) {
  //   errors.productUrl = USER_ERROR.PRO_URL
  // }
  // if (!values.productImageUrl) {
  //   errors.productImageUrl = USER_ERROR.PRO_IMAGE_URL
  // }
  // if (!values.unitPriceCurrency) {
  //   errors.unitPriceCurrency = USER_ERROR.UNIT_CURRENCY
  // }
  // if (!values.unitPriceAmount) {
  //   errors.unitPriceAmount = USER_ERROR.UNIT_AMOUNT
  // }
  // if (!values.quantity) {
  //   errors.quantity = USER_ERROR.QUANTITY
  // }
  // if (!values.notes) {
  //   errors.notes = USER_ERROR.NOTES
  // }
  // if (!values.industryDetails) {
  //   errors.industryDetails = USER_ERROR.INDUSTRY_DETAILS
  // }
  setErrors(errors)
  return errors
}

export const validateDelivery = (values, setErrors) => {
  const errors = {}
  // if (!values.shippingCarrierUrl) {
  //   errors.shippingCarrierUrl = USER_ERROR.SHIPPING_URL
  // }
  // if (!values.shippingCarrierPhone) {
  //   errors.shippingCarrierPhone = USER_ERROR.SHIPPING_PHONE_NUMBER
  // } else if (!REGEX.NUMERIC.test(values.shippingCarrierPhone)) {
  //   errors.shippingCarrierPhone = USER_ERROR.PHONE_NUMBER_INVALID
  // }
  // if (!values.trackingNumber) {
  //   errors.trackingNumber = USER_ERROR.TRACKING_NUMBER
  // }
  // if (!values.dateOfShipment) {
  //   errors.dateOfShipment = USER_ERROR.DATE_SHIPMENT
  // }
  // if (!values.dateOfDelivery) {
  //   errors.dateOfDelivery = USER_ERROR.DATE_DELIVERY
  // }
  // if (!values.signatureConfirmation) {
  //   errors.signatureConfirmation = USER_ERROR.SIGN_CONFIRM
  // }
  // if (!values.signedBy) {
  //   errors.signedBy = USER_ERROR.SIGN_BY
  // }
  // if (!values.exceptionNotes) {
  //   errors.exceptionNotes = USER_ERROR.EX_NOTES
  // }
  // if (!values.physicalFulfillmentNotes) {
  //   errors.physicalFulfillmentNotes = USER_ERROR.PHYSICAL_NOTES
  // }
  // if (!values.proofOfDelivery) {
  //   errors.proofOfDelivery = USER_ERROR.DELIVERY_PROOF
  // }
  // if (!values.proofOfUsage) {
  //   errors.proofOfUsage = USER_ERROR.USAGE_PROOF
  // }
  // if (!values.locationOfUsage) {
  //   errors.locationOfUsage = USER_ERROR.LOCATION_PROOF
  // }
  // if (!values.digitalServiceDeliveryNotes) {
  //   errors.digitalServiceDeliveryNotes = USER_ERROR.SERVICE_PROOF
  // }
  setErrors(errors)
  return errors
}

export const validateStep5 = (values, setErrors) => {
  const errors = {}
  if (!values.type) {
    errors.type = USER_ERROR.TYPE
  }
  if (!values.file_to_upload) {
    errors.file_to_upload = USER_ERROR.FILE
  }
  setErrors(errors)
  return errors
}

export const validateStep6 = (values, setErrors) => {
  const errors = {}
  if (!values.coverLetterContent) {
    errors.coverLetterContent = USER_ERROR.CONTENT
  }
  setErrors(errors)
  return errors
}

export const validateStep7 = (values, setErrors) => {
  const errors = {}
  if (!values.email) {
    errors.email = USER_ERROR.EMAIL
  }
  // if (values.email && !REGEX.EMAIL.test(values.email)) {
  //   errors.email = USER_ERROR.EMAIL_INVALID
  // }
  // if (values.email) {
  //   const getEmailName = values.email.split('@')
  //   const emailName = getEmailName && getEmailName[0]
  //   if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
  //     errors.email = USER_ERROR.EMAIL_INVALID
  //   }
  // }
  setErrors(errors)
  return errors
}

export default function Sdkvalidate(values, setErrors) {
  const errors = {}
  if (!values.clientId) {
    errors.clientId = USER_ERROR.CLIENT_REQUIRED
  }
  setErrors(errors)
  return errors
}

export const emailTemplateValidation = (values, setErrors) => {
  const errors = {}
  if (!values.toAddress) {
    errors.toAddress = USER_ERROR.NOT_TYPE
  }
  if (!values.subject) {
    errors.subject = USER_ERROR.SUBJECT
  }
  if (!values.emailtemplates) {
    errors.emailtemplates = USER_ERROR.EMAIL_TEMPLATE
  }
  setErrors(errors)
  return errors
}

export const customMailValidation = (values, setErrors) => {
  const errors = {}
  if (!values.subject) {
    errors.subject = USER_ERROR.SUBJECT
  }
  if (!values.mailContent) {
    errors.mailContent = USER_ERROR.MAIL_CONTENT
  }
  setErrors(errors)
  return errors
}