import _ from "lodash";

export const setTemplateData = (data) => {
  if (!_.isEmpty(data)) {
    return {
      notificationType: data.notificationType,
      subject: data.subject,
      emailTemplate: data.emailTemplate,
    };
  }
};
