import React, { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { getOrderInsightColumnsActions } from '../../store/actions/orderInsight'
import { connect } from 'react-redux'
import { setLocalStorage, getLocalStorage } from '../../utils/helper'
import { KTSVG } from '../../theme/helpers'
import { exportActions } from '../../store/actions/export'
import { CHARGEBACK_LAYOUT } from '../../utils/constants'
import _ from 'lodash'

function ExportLayout(props) {
  const {
    addExportColumns,
    clearExportDispatch,
    addColumnsIbaDispatch,
    showModal,
    show
  } = props

  const didMount = React.useRef(false)
  const d = new Date()
  const ms = d.getMilliseconds()

  const [columns, setColumns] = useState({
    chargebackStatus: true,
    clientId: true,
    merchantId: true,
    productName: true,
    chargebackDate: true,
    chargebackAmount: true,
    DBAName: true,
    cardType: true,
    transactionAmount: true,
    chargebackReason: true,
    transactionDate: true,
    transactionId: true,
    acquirerName: false,
    affiliateId: false,
    arn: true,
    authCode: true,
    avs: false,
    blacklisted: false,
    cardCountry: false,
    cardFirstSix: false,
    cardLastFour: false,
    cardNetwork: false,
    caseId: false,
    chargebackCurrency: false,
    chargeBackType: false,
    companyName: false,
    country: false,
    customerEmail: false,
    customerIP: false,
    customerName: false,
    customerPhone: false,
    cvv: false,
    deviceId: false,
    disputeFiledDate: false,
    disputeMethod: false,
    functionalArea: false,
    gateway: false,
    issuer: false,
    merchantName: false,
    mid: false,
    nonUs: false,
    orderId: true,
    paymentId: false,
    paymentProcessor: false,
    paymentMethod: false,
    reasonCodeGrouping: false,
    reasonForLegitimateChargeback: false,
    refundIssued: false,
    rootCause: false,
    serviceType: false,
    saleType: false,
    subFunctionalArea: false,
    upiId: true,
    batchId: true,
    createdBy: false,
    updatedBy: false


  })

  const [, setData] = useState({})

  useEffect(() => {
    const localExportData = JSON.parse(getLocalStorage(CHARGEBACK_LAYOUT.EXPORT))
    if (show === 'exportlayout') {
      if (!_.isEmpty(localExportData)) {
        addExportColumns(localExportData)
        setColumns(localExportData)
      } else {
        setLocalStorage(CHARGEBACK_LAYOUT.EXPORT, JSON.stringify(columns))
        addExportColumns(columns)
        setColumns(columns)
      }
    }
  }, [showModal, show])

  useEffect(() => {
    if (didMount.current && !showModal) {
      setLocalStorage(CHARGEBACK_LAYOUT.EXPORT, JSON.stringify(columns))
      addExportColumns(columns)
      setColumns(columns)
      const data = { hasColChanged: ms }
      addColumnsIbaDispatch(data)
    }
  }, [showModal])

  const handleAddColumnsChange = (e) => {
    const { name, checked } = e.target
    setColumns((values) => ({ ...values, [name]: checked }))
    setData({})
    clearExportDispatch()
  }

  const handleSave = () => {
    const validate = Object.values(columns).every(item => item === false)
    if (validate) {
      handleReset()
    } else {
      setColumns(columns)
      setLocalStorage(CHARGEBACK_LAYOUT.EXPORT, JSON.stringify(columns))
      addExportColumns(columns)
      setData({})
    }
    const data = { hasColChanged: ms }
    addColumnsIbaDispatch(data)
  }

  const handleReset = () => {
    columns.chargebackStatus = true
    columns.clientId = true
    columns.merchantId = true
    columns.productName = true
    columns.chargebackDate = true
    columns.chargebackAmount = true
    columns.DBAName = true
    columns.cardType = true
    columns.transactionAmount = true
    columns.transactionDate = true
    columns.transactionId = true
    columns.chargebackReason = true
    columns.acquirerName = false
    columns.affiliateId = false
    columns.arn = true
    columns.authCode = true
    columns.avs = false
    columns.blacklisted = false
    columns.cardCountry = false
    columns.cardFirstSix = false
    columns.cardLastFour = false
    columns.cardNetwork = false
    columns.caseId = false
    columns.chargebackCurrency = false
    columns.chargeBackType = false
    columns.companyName = false
    columns.country = false
    columns.customerEmail = false
    columns.customerIP = false
    columns.customerName = false
    columns.customerPhone = false
    columns.cvv = false
    columns.deviceId = false
    columns.disputeFiledDate = false
    columns.disputeMethod = false
    columns.functionalArea = false
    columns.gateway = false
    columns.issuer = false
    columns.merchantName = false
    columns.mid = false
    columns.nonUs = false
    columns.orderId = true
    columns.paymentId = false
    columns.paymentProcessor = false
    columns.paymentMethod = false
    columns.reasonCodeGrouping = false
    columns.reasonForLegitimateChargeback = false
    columns.refundIssued = false
    columns.rootCause = false
    columns.serviceType = false
    columns.saleType = false
    columns.subFunctionalArea = false
    columns.upiId = true
    columns.batchId = true
    columns.createdBy = false
    columns.updatedBy = false

    setLocalStorage(CHARGEBACK_LAYOUT.EXPORT, JSON.stringify(columns))
    setColumns(columns)
    setData({})
    addExportColumns(columns)
    const data = { hasColChanged: ms }
    addColumnsIbaDispatch(data)
  }

  useEffect(() => {
    if (!didMount.current) { didMount.current = true }
  })

  return (
    <>
      <div className='d-flex flex-row-reverse bd-highlight tab-pane' id='exportlayout'>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='pt-1'>
            <div className='row'>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='chargebackStatus'
                        value={columns.chargebackStatus}
                        checked={columns.chargebackStatus}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Status
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='clientId'
                        value={columns.clientId}
                        checked={columns.clientId}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Client
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='merchantId'
                        value={columns.merchantId}
                        checked={columns.merchantId}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Merchant
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='productName'
                        value={columns.productName}
                        checked={columns.productName}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Product Name
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='chargebackDate'
                        value={columns.chargebackDate}
                        checked={columns.chargebackDate}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Chargeback Date
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='chargebackAmount'
                        value={columns.chargebackAmount}
                        checked={columns.chargebackAmount}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Chargeback Amount
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='chargebackReason'
                        value={columns.chargebackReason}
                        checked={columns.chargebackReason}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Chargeback Reason
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='authCode'
                        value={columns.authCode}
                        checked={columns.authCode}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Auth Code
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='DBAName'
                        value={columns.DBAName}
                        checked={columns.DBAName}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        DBA Name
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='cardType'
                        value={columns.cardType}
                        checked={columns.cardType}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Card Type
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='transactionAmount'
                        value={columns.transactionAmount}
                        checked={columns.transactionAmount}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Transaction Amount
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='transactionDate'
                        value={columns.transactionDate}
                        checked={columns.transactionDate}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Transaction Date
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='transactionId'
                        value={columns.transactionId}
                        checked={columns.transactionId}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Transaction Id
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='acquirerName'
                        value={columns.acquirerName}
                        checked={columns.acquirerName}
                        onChange={(e) => handleAddColumnsChange(e)}
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Acquirer Name
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='mb-5'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='affiliateId'
                      value={columns.affiliateId}
                      checked={columns.affiliateId}
                      onChange={(e) => handleAddColumnsChange(e)}
                    />
                    <span
                      className='form-check-label'
                      htmlFor='flexCheckChecked'
                    >
                      Affiliate Id
                    </span>
                  </label>
                </div>
              </div>
              <div className='pt-1'>
                <div className='row'>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='arn'
                            value={columns.arn}
                            checked={columns.arn}
                            onChange={(e) => handleAddColumnsChange(e)}
                            disabled
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            ARN
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='customerPhone'
                            value={columns.customerPhone}
                            checked={columns.customerPhone}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Customer Phone
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='avs'
                            value={columns.avs}
                            checked={columns.avs}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            AVS
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='blacklisted'
                            value={columns.blacklisted}
                            checked={columns.blacklisted}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Blacklisted
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='chargeBackType'
                            value={columns.chargeBackType}
                            checked={columns.chargeBackType}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Chargeback Type
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='customerEmail'
                            value={columns.customerEmail}
                            checked={columns.customerEmail}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Customer Email
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='cardCountry'
                            value={columns.cardCountry}
                            checked={columns.cardCountry}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Card Country
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='cardFirstSix'
                            value={columns.cardFirstSix}
                            checked={columns.cardFirstSix}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Credit Card First 6
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='cardLastFour'
                            value={columns.cardLastFour}
                            checked={columns.cardLastFour}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Credit Card Last 4
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='cardNetwork'
                            value={columns.cardNetwork}
                            checked={columns.cardNetwork}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Card Network
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='companyName'
                            value={columns.companyName}
                            checked={columns.companyName}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Company Name
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='customerIP'
                            value={columns.customerIP}
                            checked={columns.customerIP}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Customer IP
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='caseId'
                            value={columns.caseId}
                            checked={columns.caseId}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Case Id
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='chargebackCurrency'
                            value={columns.chargebackCurrency}
                            checked={columns.chargebackCurrency}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Chargeback Currency
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='country'
                            value={columns.country}
                            checked={columns.country}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Country
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='customerName'
                            value={columns.customerName}
                            checked={columns.customerName}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Customer Name
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='subFunctionalArea'
                            value={columns.subFunctionalArea}
                            checked={columns.subFunctionalArea}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Sub Functional Area
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='rootCause'
                            value={columns.rootCause}
                            checked={columns.rootCause}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Root Cause
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='cvv'
                            value={columns.cvv}
                            checked={columns.cvv}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            CVV
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='disputeMethod'
                            value={columns.disputeMethod}
                            checked={columns.disputeMethod}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Dispute Method
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='issuer'
                            value={columns.issuer}
                            checked={columns.issuer}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Issuer
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='nonUs'
                            value={columns.nonUs}
                            checked={columns.nonUs}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Non US
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='paymentProcessor'
                            value={columns.paymentProcessor}
                            checked={columns.paymentProcessor}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Payment Processor
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='paymentMethod'
                            value={columns.paymentMethod}
                            checked={columns.paymentMethod}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Payment Method
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='reasonCodeGrouping'
                            value={columns.reasonCodeGrouping}
                            checked={columns.reasonCodeGrouping}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Reason Code Grouping
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='updatedAtFrom'
                            value={columns.updatedAtFrom}
                            checked={columns.updatedAtFrom}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Updated At
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='deviceId'
                            value={columns.deviceId}
                            checked={columns.deviceId}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Device Id
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='functionalArea'
                            value={columns.functionalArea}
                            checked={columns.functionalArea}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Functional Area
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='merchantName'
                            value={columns.merchantName}
                            checked={columns.merchantName}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Merchant Name
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='orderId'
                            value={columns.orderId}
                            checked={columns.orderId}
                            onChange={(e) => handleAddColumnsChange(e)}
                            disabled
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Order Id
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='reasonForLegitimateChargeback'
                            value={columns.reasonForLegitimateChargeback}
                            checked={columns.reasonForLegitimateChargeback}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Reason For Legitimate Chargeback
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='refundIssued'
                            value={columns.refundIssued}
                            checked={columns.refundIssued}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Refund Issued
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='upiId'
                            value={columns.upiId}
                            checked={columns.upiId}
                            onChange={(e) => handleAddColumnsChange(e)}
                            disabled
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            UPI Id
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='batchId'
                            value={columns.batchId}
                            checked={columns.batchId}
                            onChange={(e) => handleAddColumnsChange(e)}
                            disabled
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Batch Id
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='disputeFiledDate'
                            value={columns.disputeFiledDate}
                            checked={columns.disputeFiledDate}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Dispute Filed Date
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='gateway'
                            value={columns.gateway}
                            checked={columns.gateway}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Gateway
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='mid'
                            value={columns.mid}
                            checked={columns.mid}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            MID
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='paymentId'
                            value={columns.paymentId}
                            checked={columns.paymentId}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Payment Id
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='serviceType'
                            value={columns.serviceType}
                            checked={columns.serviceType}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Service Type
                          </span>
                        </label>
                      </div>

                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='createdBy'
                            value={columns.createdBy}
                            checked={columns.createdBy}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Created By
                          </span>
                        </label>
                      </div>

                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='updatedBy'
                            value={columns.updatedBy}
                            checked={columns.updatedBy}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Updated By
                          </span>
                        </label>
                      </div>

                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='saleType'
                            value={columns.saleType}
                            checked={columns.saleType}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Sale Type
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='createdAtFrom'
                            value={columns.createdAtFrom}
                            checked={columns.createdAtFrom}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Created At
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group row mb-4'>
                  <div className='col-lg-6 d-flex justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-lg btn-icon btn-active-light-primary close'
                      data-dismiss='modal'
                    >
                      {/* eslint-disable */}
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                      {/* eslint-disable */}
                    </button>
                  </div>
                  <div className='col-lg-6 d-flex justify-content-end'>
                    <button
                      className='btn btn-sm btn-light-danger me-3'
                      onClick={() => handleReset()}
                    >
                      Reset
                    </button>
                    <button
                      className='btn btn-sm btn-light-primary close'
                      onClick={() => handleSave()}
                      data-dismiss='modal'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { orderinsightExportColumnStore } = state
  return {
    orderInsightExportColumns: orderinsightExportColumnStore && orderinsightExportColumnStore.orderInsightExportColumns ? orderinsightExportColumnStore.orderInsightExportColumns : {}
  }
}

const mapDispatchToProps = dispatch => ({
  clearExportDispatch: () => dispatch(getOrderInsightColumnsActions.clearOrderInsightColumns()),
  addColumnsIbaDispatch: (data) => dispatch(exportActions.addColumnsIba(data)),
  clearColsIbaDispatch: () => dispatch(exportActions.clearColsIba())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportLayout)
