import React, { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { pickBy } from "lodash";
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import { exportActions } from '../../store/actions/export'
import serviceList from '../../services/serviceList'
import { exportLayoutDefaultCols } from './formData/exportLayoutDefaultCols'
import { getLocalStorage, headersWithAuth, headerWithApiKey } from '../../utils/helper'
import { CHARGEBACK_LAYOUT, DATE, SESSION } from '../../utils/constants'
import moment from 'moment'

const Export = (props) => {
  const {
    getExportDispatch, clearPostsDispatch, data, status, exportLoading,
    searchParams
  } = props
  const [list, setList] = useState(null)
  const csvLink = React.useRef()
  const localExportData = JSON.parse(getLocalStorage(CHARGEBACK_LAYOUT.EXPORT))
  const valid = Object.values(localExportData).some(el => el === true)
  const selectdCols = valid ? localExportData : exportLayoutDefaultCols

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const getselectedExportCols = (arr, selectedCol) => {
    const newArray = []
    arr &&
      arr.length > 0 &&
      arr.forEach((element) => {
        const obj = {}
        if (element.chargebackStatus && selectedCol.chargebackStatus) {
          obj.chargebackStatus = element.chargebackStatus
        }
        if (element.clientId && selectedCol.clientId) {
          obj.clientId = element.clientId
            ? `${element &&
            element.clientId &&
            element.clientId.company
            }`
            : '--'
        }
        if (element.merchantId && selectedCol.merchantId) {
          obj.merchantId = element.merchantId
            ? `${element &&
            element.merchantId &&
            element.merchantId.merchantName
            }`
            : '--'
        }
        if (element.productName && selectedCol.productName) {
          obj.productName = element.productName
        }
        if (element.chargebackDate && selectedCol.chargebackDate) {
          obj.chargebackDate = moment(
            element.chargebackDate
          ).format(DATE.DATE_FORMAT_MINS)
        }
        if (element.chargebackAmount && selectedCol.chargebackAmount) {
          obj.chargebackAmount = element.chargebackAmount
        }
        if (element.DBAName && selectedCol.DBAName) {
          obj.DBAName = element.DBAName
        }
        if (element.cardType && selectedCol.cardType) {
          obj.cardType = element.cardType
        }
        if (element.upiId && selectedCol.upiId) {
          obj.upiId = element.upiId
        }
        if (element.transactionAmount && selectedCol.transactionAmount) {
          obj.transactionAmount = element.transactionAmount
        }
        if (element.transactionDate && selectedCol.transactionDate) {
          obj.transactionDate = moment(
            element.transactionDate
          ).format(DATE.DATE_FORMAT_MINS)
        }
        if (element.transactionId && selectedCol.transactionId) {
          obj.transactionId = element.transactionId
        }
        if (element.acquirerName && selectedCol.acquirerName) {
          obj.acquirerName = element.acquirerName
        }
        if (element.affiliateId && selectedCol.affiliateId) {
          obj.affiliateId = element.affiliateId
        }
        if (element.arn && selectedCol.arn) {
          obj.arn = element.arn
        }
        if (element.authCode && selectedCol.authCode) {
          obj.authCode = element.authCode
        }
        if (element.avs && selectedCol.avs) {
          obj.avs = element.avs
        }
        if (element.blacklisted && selectedCol.blacklisted) {
          obj.blacklisted = element.blacklisted
        }
        if (element.cardCountry && selectedCol.cardCountry) {
          obj.cardCountry = element.cardCountry
            ? `${element &&
            element.cardCountry &&
            element.cardCountry.name
            }`
            : '--'
        }
        if (element.cardFirstSix && selectedCol.cardFirstSix) {
          obj.cardFirstSix = element.cardFirstSix
        }
        if (element.cardLastFour && selectedCol.cardLastFour) {
          obj.cardLastFour = element.cardLastFour
        }
        if (element.cardNetwork && selectedCol.cardNetwork) {
          obj.cardNetwork = element.cardNetwork
        }
        if (element.caseId && selectedCol.caseId) {
          obj.caseId = element.caseId
        }
        if (element.chargebackReason && selectedCol.chargebackReason) {
          obj.chargebackReason = element.chargebackReason
        }
        if (element.chargebackCurrency && selectedCol.chargebackCurrency) {
          obj.chargebackCurrency = element.chargebackCurrency
        }
        if (element.chargebackType && selectedCol.chargebackType) {
          obj.chargebackType = element.chargebackType
        }
        if (element.companyName && selectedCol.companyName) {
          obj.companyName = element.companyName
        }
        if (element.country && selectedCol.country) {
          obj.country = element.country
        }
        if (element.customerEmail && selectedCol.customerEmail) {
          obj.customerEmail = element.customerEmail
        }
        if (element.customerIP && selectedCol.customerIP) {
          obj.customerIP = element.customerIP
        }
        if (element.customerName && selectedCol.customerName) {
          obj.customerName = element.customerName
        }
        if (element.customerPhone && selectedCol.customerPhone) {
          obj.customerPhone = element.customerPhone
        }
        if (element.cvv && selectedCol.cvv) {
          obj.cvv = element.cvv
        }
        if (element.deviceId && selectedCol.deviceId) {
          obj.deviceId = element.deviceId
        }
        if (element.disputeFiledDate && selectedCol.disputeFiledDate) {
          obj.disputeFiledDate = moment(
            element.disputeFiledDate
          ).format(DATE.DATE_FORMAT_MINS)
        }
        if (element.disputeMethod && selectedCol.disputeMethod) {
          obj.disputeMethod = element.disputeMethod
        }
        if (element.functionalArea && selectedCol.functionalArea) {
          obj.functionalArea = element.functionalArea
        }
        if (element.gateway && selectedCol.gateway) {
          obj.gateway = element.gateway
        }
        if (element.issuer && selectedCol.issuer) {
          obj.issuer = element.issuer
        }
        if (element.merchantName && selectedCol.merchantName) {
          obj.merchantName = element.merchantName
        }
        if (element.mid && selectedCol.mid) {
          obj.mid = element.mid
        }
        if (element.nonUs && selectedCol.nonUs) {
          obj.nonUs = element.nonUs
        }
        if (element.orderId && selectedCol.orderId) {
          obj.orderId = element.orderId
        }
        if (element.paymentId && selectedCol.paymentId) {
          obj.paymentId = element.paymentId
        }
        if (element.paymentProcessor && selectedCol.paymentProcessor) {
          obj.paymentProcessor = element.paymentProcessor
            ? `${element &&
            element.paymentProcessor &&
            element.paymentProcessor.processorName
            }`
            : '--'
        }
        if (element.paymentMethod && selectedCol.paymentMethod) {
          obj.paymentMethod = element.paymentMethod
        }
        if (element.reasonCodeGrouping && selectedCol.reasonCodeGrouping) {
          obj.reasonCodeGrouping = element.reasonCodeGrouping
            ? `${element &&
            element.reasonCodeGrouping &&
            element.reasonCodeGrouping.reasonCodeGrouping
            }`
            : '--'
        }
        if (element.reasonForLegitimateChargeback && selectedCol.reasonForLegitimateChargeback) {
          obj.reasonForLegitimateChargeback = element.reasonForLegitimateChargeback
        }
        if (element.refundIssued && selectedCol.refundIssued) {
          obj.refundIssued = element.refundIssued
        }
        if (element.rootCause && selectedCol.rootCause) {
          obj.rootCause = element.rootCause
        }
        if (element.serviceType && selectedCol.serviceType) {
          obj.serviceType = element.serviceType
            ? `${element &&
            element.serviceType &&
            element.serviceType.serviceType
            }`
            : '--'
        }
        if (element.saleType && selectedCol.saleType) {
          obj.saleType = element.saleType
        }
        if (element.subFunctionalArea && selectedCol.subFunctionalArea) {
          obj.subFunctionalArea = element.subFunctionalArea
        }
        if (element.createdAtFrom && selectedCol.createdAtFrom) {
          obj.createdAtFrom = element.createdAtFrom
        }
        if (element.createdAtTo && selectedCol.createdAtTo) {
          obj.createdAtTo = element.createdAtTo
        }
        if (element.updatedAtFrom && selectedCol.updatedAtFrom) {
          obj.updatedAtFrom = element.updatedAtFrom
        }
        if (element.updatedAtTo && selectedCol.updatedAtTo) {
          obj.updatedAtTo = element.updatedAtTo
        }
        if (element.batchId && selectedCol.batchId) {
          obj.batchId = element.batchId
        }
        newArray.push(obj)
        setList(newArray)
      })
  }

  const getData = () => {
    setList(null)
    const url = `${serviceList.chargeback}?skipPagination=true`
    const params = {
      ...searchParams
    }
    getExportDispatch(url, pickBy(params), isAuthorized ? headersWithAuth : headerWithApiKey)
  }

  useEffect(() => {
    if (status === 'ok') {
      getselectedExportCols(data, selectdCols)
    }
  }, [status])

  useEffect(() => {
    if (list && list.length > 0) {
      csvLink.current.link.click()
      setTimeout(() => {
        clearPostsDispatch()
        setList([])
      }, 300)
    }
  }, [list])

  return (
    <>
      <div>
        <button
          onClick={() => { getData() }}
          className='btn btn-sm btn-light-primary m-1'
        >
          <i className='bi bi-file-earmark-arrow-up' />
          {exportLoading ? 'Loading...' : 'Export List'}
        </button>
        <CSVLink
          data={list && list.length > 0 ? list : 'Preparing Data...'}
          type='button'
          className='d-none'
          filename='Chargeback_data.csv'
          onClick={() => { }}
          ref={csvLink}
          target='_blank'
          id='csvLink'
        >
          <i className='bi bi-file-earmark-arrow-down' />
          Export List
        </CSVLink>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { exportStore } = state
  return {
    data: exportStore && exportStore.exports && exportStore.exports.data ? exportStore.exports.data : [],
    status: exportStore && exportStore.exports && exportStore.exports.status ? exportStore.exports.status : '',
    count: exportStore && exportStore.exports && exportStore.exports.count ? exportStore.exports.count : 0,
    exportLoading: exportStore && exportStore.exportLoading ? exportStore.exportLoading : false
  }
}

const mapDispatchToProps = dispatch => ({
  getExportDispatch: (url, params, headers) => dispatch(exportActions.getExport(url, params, headers)),
  clearPostsDispatch: () => dispatch(exportActions.clearPosts())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Export)
