export const addGatewaySettingsActionsTypes = {
  REQUEST: 'ADD_GATEWAY_SETTINGS_REQUEST',
  RESPONSE: 'ADD_GATEWAY_SETTINGS_RESPONSE',
  ERROR: 'ADD_GATEWAY_SETTINGS_ERROR',
  CLEAR: 'ADD_GATEWAY_SETTINGS_CLEAR'
}

export const addGatewaySettingsActions = {
  add: (data, headers) => {
    return {
      type: addGatewaySettingsActionsTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addGatewaySettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addGatewaySettingsActionsTypes.CLEAR
  })
}

export const getGatewaySettingsDetailsActionsTypes = {
  REQUEST: 'GET_GATEWAY_DETAILS_REQUEST',
  RESPONSE: 'GET_GATEWAY_DETAILS_RESPONSE',
  ERROR: 'GET_GATEWAY_DETAILS_ERROR',
  CLEAR: 'GET_GATEWAY_DETAILS_CLEAR'
}
export const getGatewaySettingsDetailsActions = {
  getDetails: (id, headers) => ({
    type: getGatewaySettingsDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getGatewaySettingsDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getGatewaySettingsDetailsActionsTypes.CLEAR
  })
}

export const updateGatewaySettingsActionsTypes = {
  REQUEST: 'UPDATE_GATEWAY_SETTINGS_REQUEST',
  RESPONSE: 'UPDATE_GATEWAY_SETTINGS_RESPONSE',
  ERROR: 'UPDATE_GATEWAY_SETTINGS_ERROR',
  CLEAR: 'UPDATE_GATEWAY_SETTINGS_CLEAR'
}

export const updateGatewaySettingsActions = {
  update: (id, params, headers) => {
    return {
      type: updateGatewaySettingsActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updateGatewaySettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updateGatewaySettingsActionsTypes.CLEAR
  })
}

export const deleteGatewaySettingsActionsTypes = {
  REQUEST: 'DELETE_GATEWAY_SETTINGS_REQUEST',
  RESPONSE: 'DELETE_GATEWAY_SETTINGS_RESPONSE',
  ERROR: 'DELETE_GATEWAY_SETTINGS_ERROR',
  CLEAR: 'DELETE_GATEWAY_SETTINGS_CLEAR'
}

export const deleteGatewaySettingsActions = {
  delete: (params, headers) => ({
    type: deleteGatewaySettingsActionsTypes.REQUEST,
    params, headers
  }),
  savemResponse: (data) => ({
    type: deleteGatewaySettingsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteGatewaySettingsActionsTypes.CLEAR
  })
}
