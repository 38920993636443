import {
  addUploadsCBTypes,
  addUploadsCBstatusTypes
} from '../actions'

export const addUploadsCBStoreKey = 'addUploadsCBStore'

export const addUploadsCBReducer = (state = {}, action) => {
  switch (action.type) {
    case addUploadsCBTypes.REQUEST:
      return { ...state, loadingAUCB: true }
    case addUploadsCBTypes.RESPONSE:
      return {
        ...state,
        dataAUCB: action.data && action.data.data,
        statusAUCB: action.data.status,
        messageAUCB: action.data.message,
        loadingAUCB: false
      }
    case addUploadsCBTypes.CLEAR:
      return { ...state, dataAUCB: null, messageAUCB: '', statusAUCB: '' }
    default:
      return state
  }
}

export const addUploadsCBStatusStoreKey = 'addUploadsCBStatusStore'

export const addUploadsCBStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case addUploadsCBstatusTypes.REQUEST:
      return { ...state, loadingAUCBS: true }
    case addUploadsCBstatusTypes.RESPONSE:
      return {
        ...state,
        dataAUCBS: action.data && action.data.data,
        statusAUCBS: action.data.status,
        messageAUCBS: action.data.message,
        loadingAUCBS: false
      }
    case addUploadsCBstatusTypes.CLEAR:
      return { ...state, dataAUCBS: null, messageAUCBS: '', statusAUCBS: '' }
    default:
      return state
  }
}
