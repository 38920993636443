import { merchantBillingDetailsTypes } from "../actions/merchantBillingDetails";

export const merchantBillingDetailsStoreKey = "merchantBillingDetailsStore";

export const merchantBillingDetailsInitialState = {
  billingdDetails: {},
  getBilling: {},
  loading: false,
};

export const merchantBillingDetailsReducer = (
  state = merchantBillingDetailsInitialState,
  action
) => {
  switch (action.type) {
    case merchantBillingDetailsTypes.REQUEST:
      return { ...state, loading: true };
    case merchantBillingDetailsTypes.BILLING:
      return { ...state, loading: true };
    case merchantBillingDetailsTypes.BILLINGRES:
      return { ...state, getBilling: action.data, loading: false };
    case merchantBillingDetailsTypes.POST_TOGGLE_CHANGE:
      return { ...state, loading: true };
    case merchantBillingDetailsTypes.POST_PRICE_DETAILS_CHANGE:
      return { ...state, loading: true };
    case merchantBillingDetailsTypes.BILCLEAR:
      return { ...state, getBilling: null, loading: false };
    case merchantBillingDetailsTypes.RESPONSE:
      return { ...state, billingdDetails: action.data.data, loading: false };
    case merchantBillingDetailsTypes.PRICE_CHANGE:
      return { ...state, billingdDetails: action.data };
    case merchantBillingDetailsTypes.CLEAR:
      return { ...state, billingdDetails: null, loading: false };
    default:
      return state;
  }
};
