import {
  issuerbankCreateCaseTypes,
  importInquiryAlertTypes,
  issuerbankAlertCaseTypes,
  importAlertTypes
} from '../actions'

export const issuerbankInitialState = {
  list: null
}

export const issuerbankStoreKey = 'issuerbankStore'
export const issuerbankReducer = (state = issuerbankInitialState, action) => {
  switch (action.type) {
    case issuerbankCreateCaseTypes.REQUEST:
      return { ...state, loadingIBPC: true }
    case issuerbankCreateCaseTypes.RESPONSE:
      return { ...state, dataIBPC: action.data, statusIBPC: action.data.status, messageIBPC: action.data.message, loadingIBPC: false }
    case issuerbankCreateCaseTypes.CLEAR:
      return { ...state, dataIBPC: null, messageIBPC: '', statusIBPC: '' }

    case importInquiryAlertTypes.REQUEST:
      return { ...state, loadingIAI: true }
    case importInquiryAlertTypes.RESPONSE:
      return { ...state, dataIAI: action.data, statusIAI: action.data.status, messageIAI: action.data.message, loadingIAI: false }
    case importInquiryAlertTypes.CLEAR:
      return { ...state, dataIAI: null, messageIAI: '', statusIAI: '' }
    default:
      return state
  }
}

export const issuerbankAlertInitialState = {
  list: null
}

export const issuerbankAlertStoreKey = 'issuerbankAlertStore'
export const issuerbankAlertReducer = (state = issuerbankAlertInitialState, action) => {
  switch (action.type) {
    case issuerbankAlertCaseTypes.REQUEST:
      return { ...state, loadingIBAC: true }
    case issuerbankAlertCaseTypes.RESPONSE:
      return { ...state, dataIBAC: action.data, statusIBAC: action.data.status, messageIBAC: action.data.message, loadingIBAC: false }
    case issuerbankAlertCaseTypes.CLEAR:
      return { ...state, dataIBAC: null, messageIBAC: '', statusIBAC: '' }

    case importAlertTypes.REQUEST:
      return { ...state, loadingIAP: true }
    case importAlertTypes.RESPONSE:
      return { ...state, dataIAP: action.data, statusIAP: action.data.status, messageIAP: action.data.message, loadingIAP: false }
    case importAlertTypes.CLEAR:
      return { ...state, dataIAP: null, messageIAP: '', statusIAP: '' }
    default:
      return state
  }
}
