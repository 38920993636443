import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES, HEADER } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  getPaypalActionsTypes,
  getPaypalActions,
  addPaypalActionsTypes,
  addPaypalActions,
  deleteSubscriptionActionsTypes,
  deleteSubscriptionActions,
  addPaypalSubscriptionHistoryActionsTypes,
  addPaypalSubscriptionHistoryAction,
  getPaypalSubscriptionHistoryTypes,
  getPaypalSubscriptionHistoryAction,
  addPayConfigActionsTypes,
  addPayConfigActions,
  getPayConfigActionsTypes,
  getPayConfigActions,
  getPayConfigDetailsActionsTypes,
  getPayConfigDetailsActions,
  updatePayConfigActionsTypes,
  updatePayConfigActions
} from '../actions'
import serviceList from '../../services/serviceList'

function* fetchPaypal(actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.PaypalSettings, { params, headers: headers })
    if (json.status === 200) {
      yield put(getPaypalActions.saveResponse(json.data))
    } else {
      yield put(getPaypalActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPaypalActionsTypes.RESPONSE, data })
  }
}

function* addPaypal(action) {
  const { payload, url } = action
  const token = 'A21AAKDJmoNsS7fNHLIHi0dxeD7_ALqV3P2TBVUYqZsW9oJSLScg-7I8IrTNyRGBYOskf3mR9oMFusAkesbbeHyfZjyIEIgyQ'
  const headers = {
    'Content-Type': 'application/json',
    [`${HEADER.TOKEN}`]: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
  }
  // const url = `${serviceList.paypalSubscription}?plan_id=P-5ML4271244454362WXNWU5NQ`
  // const gatewayUrl = `${serviceList.paypalSubscription}`

  const gatewayUrl = `${url}/v1/billing/subscriptions`
  try {
    const json = yield axiosInstance.post(gatewayUrl, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addPaypalActions.saveResponse(json.data))
    } else {
      yield put(addPaypalActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addPaypalActionsTypes.RESPONSE, data })
  }
}

function* deleteSubscription(actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.Paypal}/${params}`
  try {
    const json = yield axiosInstance.delete(deleteUrl ,{headers: headers})
    if (json.status === 200) {
      yield put(deleteSubscriptionActions.saveResponse(json.data))
    } else {
      yield put(deleteSubscriptionActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteSubscriptionActionsTypes.RESPONSE, data })
  }
}

function* addPaypalSubscriptionHistorySaga(action) {
  const { payload, headers } = action
  const addUrl = `${serviceList.clientPackages}`
  try {
    const json = yield axiosInstance.post(addUrl, payload, {headers: headers})
    if (json.status === 200) {
      yield put(addPaypalSubscriptionHistoryAction.saveResponse(json.data))
    } else {
      yield put(addPaypalSubscriptionHistoryAction.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addPaypalSubscriptionHistoryActionsTypes.RESPONSE, data })
  }
}

function* getPaypalSubscriptionHistorySaga(actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.clientPackages, { params, headers: headers })
    if (json.status === 200) {
      yield put(getPaypalSubscriptionHistoryAction.saveResponse(json.data))
    } else {
      yield put(getPaypalSubscriptionHistoryAction.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPaypalSubscriptionHistoryTypes.RESPONSE, data })
  }
}

function* addPayConfig(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.PayConfig, payload, {headers: headers})
    if (json.status === 200) {
      yield put(addPayConfigActions.saveResponse(json.data))
    } else {
      yield put(addPayConfigActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addPayConfigActionsTypes.RESPONSE, data })
  }
}

function* fetchPayConfig(action) {
  const { headers } = action
  try {
    const json = yield axiosInstance.get(serviceList.PayConfig, {headers: headers})
    if (json.status === 200) {
      yield put(getPayConfigActions.saveResponse(json.data))
    } else {
      yield put(getPayConfigActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPayConfigActionsTypes.RESPONSE, data })
  }
}

function* getPayConfigDetails(actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.PayConfig}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers: headers})
    if (json.status === 200) {
      yield put(
        getPayConfigDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getPayConfigDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPayConfigDetailsActionsTypes.RESPONSE, data })
  }
}

function* updatePayConfig(actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.PayConfig}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers: headers})
    if (json.status === 200) {
      yield put(updatePayConfigActions.saveResponse(json.data))
    } else {
      yield put(updatePayConfigActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updatePayConfigActionsTypes.RESPONSE, data })
  }
}

export function* fetchPaypalWatcher() {
  yield all([
    yield takeLatest(getPaypalActionsTypes.REQUEST, fetchPaypal),
    yield takeLatest(addPaypalActionsTypes.REQUEST, addPaypal),
    yield takeLatest(deleteSubscriptionActionsTypes.REQUEST, deleteSubscription),
    yield takeLatest(addPaypalSubscriptionHistoryActionsTypes.REQUEST, addPaypalSubscriptionHistorySaga),
    yield takeLatest(getPaypalSubscriptionHistoryTypes.REQUEST, getPaypalSubscriptionHistorySaga),
    yield takeLatest(addPayConfigActionsTypes.REQUEST, addPayConfig),
    yield takeLatest(getPayConfigActionsTypes.REQUEST, fetchPayConfig),
    yield takeLatest(getPayConfigDetailsActionsTypes.REQUEST, getPayConfigDetails),
    yield takeLatest(updatePayConfigActionsTypes.REQUEST, updatePayConfig),
  ])
}