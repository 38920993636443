import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import routeConfig from "../../routing/routeConfig";
import { LoginActions } from "../../store/actions";
import {
  USER_ERROR,
  REGEX,
  RESPONSE_STATUS,
  SESSION,
  SET_STORAGE,
  REASON
} from "../../utils/constants";
import _ from "lodash";
import { setLocalStorage, removeLocalStorage, headersWithAuth } from "../../utils/helper";
import ReCAPTCHA from "react-google-recaptcha";

const Login = (props) => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramPlatform = query.get('v');
  const paramDomain = query.get('shop');
  const paramShopName = query.get('shopname');
  const { loginDispatch, loading, loginData, clearLogin } = props;
  // const recaptchaRef = React.createRef()
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    // captcha: ''
  });
  const [showBanner, setShowBanner] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const removeShopifyData = () => {
    removeLocalStorage(SET_STORAGE.SHOPIFY_PLATFORM)
    removeLocalStorage(SET_STORAGE.SHOPIFY_DOMAIN)
    removeLocalStorage(REASON.TO_LOGOUT)
  }

  // const handleSubmit = (e) => {

  //   const errors = {};
  //   if (_.isEmpty(formData.email)) {
  //     errors.email = USER_ERROR.EMAIL_REQUIRED;
  //   } else if (formData.email && !REGEX.EMAIL.test(formData.email)) {
  //     errors.email = USER_ERROR.EMAIL_INVALID;
  //   }
  //   if (_.isEmpty(formData.password)) {
  //     errors.password = USER_ERROR.PASSWORD_REQUIRED;
  //   }
  //   setErrors(errors);
  //   const captchaToken = recaptchaRef.current.getValue();
  //   recaptchaRef.current.reset();
  //   if (!captchaToken) {
  //     setErrors({ ...errors, captcha: "Captcha is Required" });
  //   }
  //   if (_.isEmpty(errors) && captchaToken) {
  //     setErrors({ ...errors, captcha: "" });
  //     removeShopifyData()
  //     loginDispatch(formData, captchaToken, headersWithAuth);
  //   }
  // };

const handleSubmit = async (e) => {
  e.preventDefault();
  const errors = {};
  if (_.isEmpty(formData.email)) {
    errors.email = USER_ERROR.EMAIL_REQUIRED;
  } else if (formData.email && !REGEX.EMAIL.test(formData.email)) {
    errors.email = USER_ERROR.EMAIL_INVALID;
  }
  if (_.isEmpty(formData.password)) {
    errors.password = USER_ERROR.PASSWORD_REQUIRED;
  }
  setErrors(errors);
  // const captchaToken = recaptchaRef.current.getValue();
  // if (!captchaToken) {
  //   setErrors({ ...errors, captcha: "Captcha is Required" });
  // }
  if (_.isEmpty(errors) ) {
    setErrors({ ...errors, captcha: "" });
    removeShopifyData();
    try {
      const loginResponse = await loginDispatch(
        formData,
        // captchaToken,
        headersWithAuth
      );
      // recaptchaRef.current.execute(); // Refresh Recaptcha after form submission
      if (
        loginResponse &&
        loginResponse.data &&
        loginResponse.data.token &&
        loginResponse.status === RESPONSE_STATUS.SUCCESS
      ) {
        if (paramDomain && !loginResponse.data.clientId) {
          setErrors({ ...errors, captcha: USER_ERROR.NOT_AUTHORIZED_USER });

        } else {
          authSuccess(loginResponse);
        }
      } else if (
        loginResponse &&
        loginResponse.status === RESPONSE_STATUS.FAILURE
      ) {
        setErrors({ ...errors, captcha: loginResponse.message });

      }
    } catch (error) {
      console.error(error);
      setErrors({ ...errors, captcha: USER_ERROR.LOGIN_FAILED });

    }
  }
};



  const handleChange = (e) => {
    const { name, value } = e.target;
    formData[name] = value;
    setFormData(formData);
    setErrors({ ...errors, [name]: "" });
  };

  const handleErrors = (messages) => {
    setShowBanner(true);
    setErrorMsg(messages)
    setTimeout(() => {
      setShowBanner(false);
      setErrorMsg('')
      clearLogin();
    }, 3000);
  }

  const authSuccess = (responseData) => {
    setLocalStorage(SESSION.TOKEN, responseData.data.token);
    setLocalStorage(SET_STORAGE.SET_USER_ROLE, responseData.data.role);
    setLocalStorage(SET_STORAGE.SHOPIFY_PLATFORM, paramPlatform);
    setLocalStorage(SET_STORAGE.SHOPIFY_DOMAIN, paramDomain);
    const menuList =
      responseData && responseData.menulist && responseData.menulist
        ? responseData.menulist
        : [];
    setLocalStorage(SET_STORAGE.USER_MENU_DETAILS, JSON.stringify(menuList));
    const authDetails = responseData && responseData.data ? responseData.data : {};
    setLocalStorage(
      SET_STORAGE.ATTRIBUTE_DEATILS,
      JSON.stringify(authDetails)
    );
    setLocalStorage(
      SET_STORAGE.IS_FIRST_LOGIN,
      responseData && responseData.data && responseData.data.isFirstLogin
    );
    if (responseData && responseData.data && responseData.data.isFirstLogin === true) {
      window.location = "/session-expired";
    } else if (
      responseData &&
      responseData.data &&
      responseData.data.isFirstLogin === false
    ) {
      let redirectTo = "";
      if (menuList.length > 0) {
        if (
          menuList[0] &&
          menuList[0].submenu &&
          menuList[0].submenu.length > 0
        ) {
          redirectTo = menuList[0].submenu && menuList[0].submenu[0].slug;
          if (
            menuList[0].submenu[0] &&
            menuList[0].submenu[0].submenu && menuList[0].submenu[0].submenu.length > 0
          ) {
            redirectTo =
              menuList[0].submenu[0].submenu &&
              menuList[0].submenu[0].submenu[0].slug;
          }
        }
      }

      const routeConfigShopifyTopLevelAuth = `/auth/toplevel?shop=${paramShopName}`;
      //const shopifyDomainUrl = `${paramDomain}${routeConfig.shopifyTopLevelAuth}&email=${formData.email}&type=login&clientId=${loginData.data.clientId}`
      const shopifyDomainUrl = `${paramDomain}${routeConfigShopifyTopLevelAuth}&email=${formData.email}&type=login&clientId=${loginData.data.clientId}`
      console.log(shopifyDomainUrl, ' shopifyDomainUrl ')
      window.location = paramDomain !== null ? shopifyDomainUrl : redirectTo ? redirectTo : "/perform-summary";
    }
    clearLogin();
  }


  useEffect(() => {
    if (
      loginData &&
      loginData.data &&
      loginData.data.token &&
      loginData.status === RESPONSE_STATUS.SUCCESS
    ) {
      if (paramDomain && !loginData.data.clientId) {
        handleErrors(USER_ERROR.NOT_AUTHORIZED_USER)
      } else {
        authSuccess(loginData)
      }
    } else if (loginData.status === RESPONSE_STATUS.ERROR) {
      handleErrors(loginData.message)
    }
  }, [loginData]);

  return (
    <>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Sign In to CBZero</h1>
      </div>
      {showBanner && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-center text-danger">{errorMsg}</div>
        </div>
      )}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formData.email && errors.email },
            {
              "is-valid": formData.email && !errors.email,
            }
          )}
          onChange={(e) => handleChange(e)}
          type="email"
          name="email"
          autoComplete="off"
        />
        {errors.email && (
          <div className="fv-plugins-message-container text-danger">
            <span role="alert text-danger">{errors.email}</span>
          </div>
        )}
      </div>
      <div className="fv-row mb-5">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Password
            </label>
            <Link
              to={routeConfig.forgotPassword}
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Forgot Password ?
            </Link>
          </div>
        </div>
        <input
          type="password"
          placeholder="Password"
          autoComplete="off"
          onChange={(e) => handleChange(e)}
          name="password"
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formData.password && errors.password,
            },
            {
              "is-valid": formData.password && !errors.password,
            }
          )}
        />
        {errors.password && (
          <div className="fv-plugins-message-container text-danger">
            <div className="fv-help-block">
              <span role="alert">{errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* <div className="p-3 ps-15">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={`${process.env.REACT_APP_SITE_KEY}`}

        />
        {errors.captcha && (
          <div className="fv-plugins-message-container text-danger">
            <span role="alert text-danger">{errors.captcha}</span>
          </div>
        )}
      </div> */}
      <div className="text-center">
        <button
          type="button"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-blue w-100 mb-5"
          onClick={(e) => { handleSubmit(e) }}
          disabled={loading}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
        <Link
          to="/client-signup-page"
          className="link-primary fs-6 fw-bolder"
          onClick={() => { removeShopifyData() }}
        >
          Sign Up
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { loginStore } = state;
  return {
    loginData: loginStore && loginStore.login ? loginStore.login : {},
    loading: loginStore && loginStore.loading ? loginStore.loading : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginDispatch: (data, headers) => dispatch(LoginActions.login(data, headers)),
  clearLogin: () => dispatch(LoginActions.clearLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);