import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CrmModal from "./CrmModal";
import ReactSelect from "../../../../../theme/layout/components/ReactSelect";
import _ from "lodash";
import { KTSVG } from "../../../../../theme/helpers";
import color from "../../../../../utils/colors";
import {
  templateActions,
  deleteTemplateActions,
  addClientSMTPActions,
  getClientSMTPDetailsActions,
  processorActions,
  getClientProcessorDetailsActions,
  checkProcessorActions,
  addclientProcessorActions,
  editClientProcessorActions,
} from "../../../../../store/actions";
import { connect } from "react-redux";
import {
  STATUS_RESPONSE,
  SWEET_ALERT_MSG,
  STATUS_BADGES,
  REGEX,
  SESSION,
  API
} from "../../../../../utils/constants";
import { getLocalStorage, headersWithAuth } from "../../../../../utils/helper";
import {
  warningAlert,
  confirmationAlert,
  confirmAlert,
} from "../../../../../utils/alerts";
import { Validation, validationImap, emailValidation, ValidationDays } from "./validation";
import {
  processorUpdateValidation,
  addProcessorlValidation,
} from "../validation";
import { Modal } from "../../../../../theme/layout/components/modal";

const EmailTemplateList = (props) => {
  const {
    className,
    getTemplateDispatch,
    templateData,
    loadingTEM,
    messageDTEM,
    statusDTEM,
    deleteTemplateDispatch,
    clearDeleteTemplateDispatch,
    addClientSMTPDispatch,
    clearaddClientSMTPDispatch,
    loadingACSMTP,
    statusACSMTP,
    messageACSMTP,
    getClientSMTPDetailsDispatch,
    clearClientSMTPDetailsDispatch,
    getDetailsClientSMTP,
    loadingGetDetailsClientSMTP,
    statusGetDetailsClientSMTP,
    processorData,
    getProcessorDispatch,
    clientProcessorLoading,
    clientProcessorData,
    getClientProcessorDispatch,
    addclientProcessorDispatch,
    editClientProcessorDispatch,
    checkProcessorDispatch,
    clearcheckProcessorDispatch,
    statusCPR,
    messageCPR,
    loadingCPR,
    statusACPR,
    messageACPR,
    loadingACPR,
    clearaddclientProcessorDispatch,
    statusECP,
    messageECP,
    loadingECP,
    cleareditClientProcessorDispatch,
    processorLoading
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }

  const history = useHistory();
  const url = useLocation().pathname;
  const fields = url && url.split("update/");
  const prevId = fields && fields[1];
  const currenId = prevId && prevId.split("/email-template");
  const id = currenId && currenId[0];
  const [smtpModalShow, setSmtpModalShow] = useState(false);
  const [smtpDaysModalShow, setSmtpDaysModalShow] = useState(false);
  const [ImapModalShow, setImapModalShow] = useState(false);
  const [EmailModalShow, setEmailModalShow] = useState(false);
  const [ProcessorModalShow, setProcessorModalShow] = useState(false);
  const [crmModalShow, setCrmModalShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentId, setCurrentId] = useState();
  const [showSFTPTable, setShowSFTPTable] = useState(true);
  const [showAddProcessor, setShowAddProcessor] = useState(false);
  const [selectedProcessorOption, setSelectedProcessorOption] = useState("");
  const [processorOption, setProcessorOption] = useState();
  const [limit, setLimit] = useState(25);
  const [editMode, setEditMode] = useState(false);
  const [editPassMode, setEditPassMode] = useState(false);
  const [setActivePageNumber] = useState(1);
  const [formData, setFormData] = useState({
    clientId: prevId,
    userName: "",
    smtpPort: 0,
    smtpHostAddress: "",
    fromEmail: "",
    password: ""
  });

  const [formDayData, setFormDayData] = useState({
    clientId: prevId,
    remainderDay: "",
    remainderEndDay: "",
    expiryDay: "",
  });

  const [formImapData, setImapFormData] = useState({
    clientId: prevId,
    imapHostAddress: "",
    imapPort: 0,
    imapUserName: "",
    imapPassword: "",
    imap: false,
  });

  const [formEmailData, setEmailFormData] = useState({
    clientId: prevId,
    errorNotificationEmails: "",
  });

  const [formProcessorData, setFormProcessorData] = useState({
    paymentProcessorId: "",
  });

  const [paymentProcesserDetails, setPaymentProcessorDetails] = useState({
    paymentProcessorId: "",
    processorLabel: "",
    disputeSubmissionMode: "",
    method: "",
    oAuthUrl: "",
    oAuthUserName: "",
    oAuthPassword: "",
    hostName: "",
    portNumber: "22",
    username: "",
    password: "",
    faxNumber: "",
    email: "",
    emailBcc: "",
    emailCc: "",
    apiKey: "",
    apiSecretKey: "",
    apiEndPoint: "",
    requestApiKey: "",
    requestApiSecret: "",
    requestApiEndPoint: "",
  });

  const resetState = (e) => {
    setFormData({
      userName: "",
      smtpPort: "",
      smtpHostAddress: "",
      fromEmail: "",
      password: ""
    });
    setErrors(null);
    setEditPassMode(false)
  };

  const resetDayState = (e) => {
    setFormData({
      remainderDay: "",
      remainderEndDay: "",
      expiryDay: "",
    });
    setErrors(null)
  };

  const resetImapState = (e) => {
    setImapFormData({
      imapHostAddress: "",
      imapPort: 0,
      imapUserName: "",
      imapPassword: "",
      imap: false,
    });
    setErrors(null);
    setEditPassMode(false)
  };

  const resetEmailState = (e) => {
    setEmailFormData({
      errorNotificationEmails: "",
    });
    setErrors(null);
  };

  useEffect(() => {
    const params = {
      clientId: id,
    };
    getTemplateDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  }, []);

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setFormDayData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setEmailFormData((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = () => {
    const errorMsg = Validation(formData, setErrors, editPassMode);
    if (_.isEmpty(errorMsg)) {
      addClientSMTPDispatch(formData, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  };

  const handleSMTPDaySubmit = () => {
    const errorMsg = ValidationDays(formDayData, setErrors);
    if (_.isEmpty(errorMsg)) {
      addClientSMTPDispatch(formDayData, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  };

  const handleEmailSubmit = () => {
    const errorMsg = emailValidation(formEmailData, setErrors);
    if (_.isEmpty(errorMsg)) {
      addClientSMTPDispatch(formEmailData, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  };

  const handleImapChange = (e) => {
    e.persist();
    setImapFormData((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSwitchChange = (e) => {
    const { checked, name } = e.target;
    const collapseBtnRef = document.getElementById("collapseBtn");
    collapseBtnRef.click();
    setImapFormData((values) => ({ ...values, [name]: checked }));
    setErrors({ ...errors, [name]: "" });
  };

  const handleImapSubmit = () => {
    const errorMsg = validationImap(formImapData, setErrors, editPassMode);
    if (_.isEmpty(errorMsg)) {
      const payload = {
        ...formImapData,
        imap: formImapData.imap ? "YES" : "NO",
      };
      addClientSMTPDispatch(payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  };

  useEffect(() => {
    if (statusGetDetailsClientSMTP === STATUS_RESPONSE.SUCCESS_MSG) {
      setFormData({
        clientId: prevId,
        userName: getDetailsClientSMTP.userName,
        smtpPort: getDetailsClientSMTP.smtpPort
          ? getDetailsClientSMTP.smtpPort
          : "0",
        smtpHostAddress: getDetailsClientSMTP.smtpHostAddress,
        fromEmail: getDetailsClientSMTP.fromEmail
      });
      setFormDayData({
        clientId: prevId,
        remainderDay: getDetailsClientSMTP.remainderDay,
        remainderEndDay: getDetailsClientSMTP.remainderEndDay,
        expiryDay: getDetailsClientSMTP.expiryDay,
      });
      setImapFormData({
        clientId: prevId,
        imapHostAddress: getDetailsClientSMTP.imapHostAddress,
        imapPort:
          getDetailsClientSMTP.imapPort == 0
            ? "0"
            : getDetailsClientSMTP.imapPort,
        imapUserName: getDetailsClientSMTP.imapUserName,
        imap: getDetailsClientSMTP.imap === "YES" ? true : false,
      });
      setEditPassMode(true)
      if (getDetailsClientSMTP.imap === "YES") {
        const collapseBtnRef = document.getElementById("collapseBtn");
        collapseBtnRef && collapseBtnRef.click();
      }
      setEmailFormData({
        clientId: prevId,
        errorNotificationEmails: getDetailsClientSMTP.errorNotificationEmails,
      });
      clearClientSMTPDetailsDispatch();
    }
  }, [statusGetDetailsClientSMTP]);

  const onMailConfirm = () => {
    setSmtpModalShow(false);
    setImapModalShow(false);
    setEmailModalShow(false);
    setSmtpDaysModalShow(false);
    resetState();
    resetDayState();
    resetImapState();
    resetEmailState();
  };

  useEffect(() => {
    if (statusACSMTP === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageACSMTP,
        "success",
        "Ok",
        () => {
          onMailConfirm();
        },
        () => {
          onMailConfirm();
        }
      );
      clearaddClientSMTPDispatch();
    } else if (statusACSMTP === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageACSMTP, "", "Ok");
      clearaddClientSMTPDispatch();
    }
  }, [statusACSMTP]);

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_TEMPLATE,
      "warning",
      "Yes",
      "No",
      () => {
        deleteTemplateDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
      },
      () => { }
    );
  };

  const onConfirm = () => {
    const params = {
      clientId: id,
    };
    getTemplateDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  useEffect(() => {
    if (statusDTEM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageDTEM,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearDeleteTemplateDispatch();
    } else if (statusDTEM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        "Error",
        messageDTEM,
        "error",
        "Close",
        "Ok",
        () => {
          onConfirm();
        },
        () => { }
      );
      clearDeleteTemplateDispatch();
    }
  }, [statusDTEM]);

  useEffect(() => {
    getProcessorDispatch(null, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  }, []);

  const handleInputChange = (e, type) => {
    e.preventDefault();
    if (type === "setFormProcessorData") {
      setFormProcessorData((values) => ({
        ...values,
        [e.target.name]: e.target.value,
      }));
    }
    if (type === "setPaymentProcessorDetails") {
      setPaymentProcessorDetails((values) => ({
        ...values,
        [e.target.name]: e.target.value,
      }));
    }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const getDefaultProcessorOptions = (getData) => {
    const defaultOptions = [];
    if (!_.isEmpty(getData)) {
      getData &&
        getData.map((item) => {
          defaultOptions.push({
            label: `${item.processorName ? item.processorName : ""}`,
            value: item._id,
          });
        });
      return defaultOptions;
    }
  };

  const handleChangeProcessor = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedProcessorOption(selectedOption);
      setFormProcessorData((values) => ({
        ...values,
        paymentProcessorId: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, paymentProcessorId: "" }));
    } else {
      setSelectedProcessorOption();
      setFormProcessorData((values) => ({ ...values, paymentProcessorId: "" }));
      setPaymentProcessorDetails({
        hostName: "",
        disputeSubmissionMode: "",
        method: "",
        oAuthUrl: "",
        oAuthUserName: "",
        oAuthPassword: "",
        portNumber: "22",
        username: "",
        password: "",
        faxNumber: "",
        email: "",
        emailBcc: "",
        emailCc: "",
        apiKey: "",
        apiSecretKey: "",
        apiEndPoint: "",
        requestApiKey: "",
        requestApiSecret: "",
        requestApiEndPoint: "",
      });
      setFormProcessorData({ paymentProcessorId: "" });
      setShowAddProcessor(false);
    }
    showAddProcessor && setShowAddProcessor(false)
  };

  useEffect(() => {
    const data = getDefaultProcessorOptions(processorData);
    setProcessorOption(data);
    if (!_.isEmpty(formProcessorData.paymentProcessorId)) {
      const selOption = _.filter(data, function (x) {
        if (_.includes(formProcessorData.paymentProcessorId._id, x.value)) {
          return x;
        }
      });
      setSelectedProcessorOption(selOption);
    }
  }, [processorData]);

  const handleAddClick = (type) => {
    if (type === "PAYMENT_PROCESSOR") {
      const errorMsg = processorUpdateValidation(formProcessorData, setErrors);
      if (_.isEmpty(errorMsg)) {
        setShowAddProcessor(true);
      }
    }
  };

  const onAddConfirm = () => {
    handleAddClick("PAYMENT_PROCESSOR");
  };

  useEffect(() => {
    if (statusCPR === STATUS_RESPONSE.SUCCESS_MSG) {
      onAddConfirm();
      clearcheckProcessorDispatch();
    } else if (statusCPR === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageCPR, "", "Ok");
    }
    clearcheckProcessorDispatch();
  }, [statusCPR]);

  const handleCheckProcessor = () => {
    const errorMsg = processorUpdateValidation(formProcessorData, setErrors);
    if (_.isEmpty(errorMsg)) {
      const payload = {
        clientId: id,
        paymentProcessorId: formProcessorData.paymentProcessorId,
      };
      checkProcessorDispatch(payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  };

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
  };

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const params = {
      limit: limit,
      page: pageNumber,
      clientId: id,
    };
    setActivePageNumber(pageNumber);
    getClientProcessorDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  const totalPages =
    getProcessorDispatch && getProcessorDispatch.count
      ? Math.ceil(
        parseInt(getProcessorDispatch && getProcessorDispatch.count) / limit
      )
      : 1;

  const onEditProcessorClick = (item) => {
    setCurrentId(item._id);
    setShowAddProcessor(true);
    setEditMode(true);
    setFormProcessorData((values) => ({ ...values, paymentProcessorId: item.paymentProcessorId && item.paymentProcessorId._id }));
    setSelectedProcessorOption({
      label: item.paymentProcessorId && item.paymentProcessorId.processorName,
      value: item.paymentProcessorId && item.paymentProcessorId._id
    });
    setPaymentProcessorDetails({
      disputeSubmissionMode: item.disputeSubmissionMode,
      oAuthUrl: item.oAuthUrl,
      oAuthUserName: item.oAuthUserName,
      oAuthPassword: item.oAuthPassword,
      method: item.method,
      paymentProcessorId: item.paymentProcessorId,
      processorLabel: item.processorLabel,
      hostName: item.hostName,
      portNumber: item.portNumber,
      username: item.username,
      password: item.password,
      faxNumber: item.faxNumber,
      email: item.email,
      emailBcc: item.emailBcc,
      emailCc: item.emailCc,
      apiKey: item.apiKey,
      apiSecretKey: item.apiSecretKey,
      apiEndPoint: item.apiEndPoint,
      requestApiKey: item.requestApiKey,
      requestApiSecret: item.requestApiSecret,
      requestApiEndPoint: item.requestApiEndPoint,
    });
  };

  const handleAddProcessor = () => {
    const errorMsg = addProcessorlValidation(
      paymentProcesserDetails,
      setErrors
    );
    if (_.isEmpty(errorMsg)) {
      if (editMode) {
        const payload = {
          paymentProcessorId: paymentProcesserDetails.paymentProcessorId,
          processorLabel: paymentProcesserDetails.processorLabel,
          method: paymentProcesserDetails.method,
          hostName: paymentProcesserDetails.hostName,
          portNumber: paymentProcesserDetails.portNumber,
          username: paymentProcesserDetails.username,
          password: paymentProcesserDetails.password,
          faxNumber: paymentProcesserDetails.faxNumber,
          email: paymentProcesserDetails.email,
          emailBcc: paymentProcesserDetails.emailBcc,
          emailCc: paymentProcesserDetails.emailCc,
          disputeSubmissionMode: paymentProcesserDetails.disputeSubmissionMode,
          oAuthUrl: paymentProcesserDetails.oAuthUrl,
          oAuthUserName: paymentProcesserDetails.oAuthUserName,
          oAuthPassword: paymentProcesserDetails.oAuthPassword,
          apiKey: paymentProcesserDetails.apiKey,
          apiSecretKey: paymentProcesserDetails.apiSecretKey,
          apiEndPoint: paymentProcesserDetails.apiEndPoint,
          requestApiKey: paymentProcesserDetails.requestApiKey,
          requestApiSecret: paymentProcesserDetails.requestApiSecret,
          requestApiEndPoint: paymentProcesserDetails.requestApiEndPoint,
        };
        editClientProcessorDispatch(currentId, payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
      } else {
        const payload = {
          paymentProcessorId: formProcessorData.paymentProcessorId,
          processorLabel: formProcessorData.processorLabel,
          method: paymentProcesserDetails.method,
          hostName: paymentProcesserDetails.hostName,
          portNumber: paymentProcesserDetails.portNumber,
          username: paymentProcesserDetails.username,
          password: paymentProcesserDetails.password,
          faxNumber: paymentProcesserDetails.faxNumber,
          email: paymentProcesserDetails.email,
          emailBcc: paymentProcesserDetails.emailBcc,
          emailCc: paymentProcesserDetails.emailCc,
          disputeSubmissionMode: paymentProcesserDetails.disputeSubmissionMode,
          oAuthUrl: paymentProcesserDetails.oAuthUrl,
          oAuthUserName: paymentProcesserDetails.oAuthUserName,
          oAuthPassword: paymentProcesserDetails.oAuthPassword,
          apiKey: paymentProcesserDetails.apiKey,
          apiSecretKey: paymentProcesserDetails.apiSecretKey,
          apiEndPoint: paymentProcesserDetails.apiEndPoint,
          requestApiKey: paymentProcesserDetails.requestApiKey,
          requestApiSecret: paymentProcesserDetails.requestApiSecret,
          requestApiEndPoint: paymentProcesserDetails.requestApiEndPoint,
          clientId: id,
        };
        addclientProcessorDispatch(payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
      }
      setShowSFTPTable(true);
    }
  };

  const handleAddProcessorTable = () => {
    const errorMsg = addProcessorlValidation(
      paymentProcesserDetails,
      setErrors
    );
    if (_.isEmpty(errorMsg)) {
      setSelectedProcessorOption();
      setPaymentProcessorDetails({
        hostName: "",
        disputeSubmissionMode: "",
        method: "",
        oAuthUrl: "",
        oAuthUserName: "",
        oAuthPassword: "",
        portNumber: "22",
        username: "",
        password: "",
        faxNumber: "",
        email: "",
        emailBcc: "",
        emailCc: "",
        apiKey: "",
        apiSecretKey: "",
        apiEndPoint: "",
        requestApiKey: "",
        requestApiSecret: "",
        requestApiEndPoint: "",
      });
      setFormProcessorData({ paymentProcessorId: "" });
      setShowAddProcessor(false);
      setEditMode(false);
    }
  };

  const resetProcessorState = () => {
    setSelectedProcessorOption();
    setPaymentProcessorDetails({
      hostName: "",
      disputeSubmissionMode: "",
      method: "",
      oAuthUrl: "",
      oAuthUserName: "",
      oAuthPassword: "",
      portNumber: "22",
      username: "",
      password: "",
      faxNumber: "",
      email: "",
      emailBcc: "",
      emailCc: "",
      apiKey: "",
      apiSecretKey: "",
      apiEndPoint: "",
      requestApiKey: "",
      requestApiSecret: "",
      requestApiEndPoint: "",
    });
    setFormProcessorData({ paymentProcessorId: "" });
    setShowAddProcessor(false);
  };

  const onProcessorConfirm = () => {
    handleAddProcessorTable();
    getClientProcessorDispatch(prevId, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  useEffect(() => {
    if (statusACPR === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageACPR,
        "success",
        "ok",
        () => {
          onProcessorConfirm();
        },
        () => {
          onProcessorConfirm();
        }
      );
      clearaddclientProcessorDispatch();
    } else if (statusACPR === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageACPR, "", "Ok");
      clearaddclientProcessorDispatch();
    }
  }, [statusACPR]);

  useEffect(() => {
    if (statusECP === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageECP,
        "success",
        "ok",
        () => {
          onProcessorConfirm();
        },
        () => {
          onProcessorConfirm();
        }
      );
      cleareditClientProcessorDispatch();
    } else if (statusECP === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageECP, "", "Ok");
      cleareditClientProcessorDispatch();
    }
  }, [statusECP]);

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  return (
    <>
      {smtpDaysModalShow ? (
        <Modal showModal={smtpDaysModalShow} modalWidth={750}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">Reminder Interval</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setSmtpDaysModalShow(false);
                      resetEmailState();
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body p-8">
                  <div className="container-fixed">
                    <div className="card m-2">
                      {loadingGetDetailsClientSMTP ? (
                        <div className="d-flex justify-content-center py-5">
                          <div
                            className="spinner-border text-primary m-5"
                            role="status"
                          />
                        </div>
                      ) : (
                        <>

                          <div className="row">
                            <div className="col-lg-6">
                              <label className="required fw-bold fs-6 mb-2">
                                Reminder Interval [Days]
                              </label>
                              <input
                                name="remainderDay"
                                type="text"
                                className="form-control"
                                placeholder="Reminder Interval [Days]"
                                value={formDayData.remainderDay || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  if (!REGEX.NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={3}
                              />
                              {errors && errors.remainderDay && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.remainderDay}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <label className="required fw-bold fs-6 mb-2">
                                Due Date [Days]
                              </label>
                              <input
                                name="remainderEndDay"
                                type="text"
                                className="form-control"
                                placeholder="Due Date [Days]"
                                value={formDayData.remainderEndDay || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  if (!REGEX.NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={3}
                              />
                              {errors && errors.remainderEndDay && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.remainderEndDay}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-lg-6">
                              <label className="fw-bold fs-6 mb-2">
                                Token Expiry Day
                              </label>
                              <input
                                name="remainderEndDay"
                                type="text"
                                className="form-control"
                                placeholder="Token Expiry Day"
                                value={formDayData.remainderEndDay || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                disabled
                              />
                              {errors && errors.remainderEndDay && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.remainderEndDay}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="form-group row mt-4">
                      <div className="col-lg-5" />
                      <div className="col-lg-7">
                        <div className="col-lg-12">
                          <button
                            className="btn btn-blue mt-7 fa-pull-right"
                            onClick={(event) => {
                              handleSMTPDaySubmit(event);
                            }}
                          >
                            {loadingACSMTP ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {crmModalShow ? (
        <Modal showModal={crmModalShow} modalWidth={1000}>
          <div className="" id="crmModal">
            <div className="">
              <div className="p-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="me-8">Add CRM</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    onClick={() => {
                      setCrmModalShow(false);
                    }}
                  >
                    {/* eslint-disable */}
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                    {/* eslint-disable */}
                  </button>
                </div>
                <CrmModal />
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {ProcessorModalShow ? (
        <Modal showModal={ProcessorModalShow} modalWidth={1200}>
          <div className="min-h-400px" id="uploadModal">
            <div className="scroll h-500px">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">Add Payment Processor</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setProcessorModalShow(false);
                      resetProcessorState();
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body bg-skyBlue p-8">
                  <div className="mb-0 pt-5 p-10">
                    <div className="row col-lg-12">
                      <div className="col-lg-3 mt-3">
                        <label className="font-size-xs font-weight-bold mb-3  form-label">
                          Payment Processor:
                        </label>
                      </div>
                      <div className="col-lg-7">
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name="crmId"
                          className="basic-single"
                          classNamePrefix="select"
                          handleChangeReactSelect={handleChangeProcessor}
                          options={processorOption}
                          value={selectedProcessorOption}
                          isDisabled={clientProcessorLoading || loadingCPR || processorLoading}
                        />
                        {errors && errors.paymentProcessorId && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red}"}</style>
                            {errors.paymentProcessorId}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-2">
                        <button
                          className="btn btn-green mb-3 py-2"
                          disabled={loadingCPR || processorLoading || clientProcessorLoading || showAddProcessor}
                          onClick={() => {
                            handleCheckProcessor();
                          }}
                        >
                          {loadingCPR ? (
                            <span
                              className="spinner-border spinner-border-sm mx-3"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Add"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  {showAddProcessor ? (
                    <>
                      <div className="mb-0 pt-5 p-10">
                        <div className="row">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-3 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  Method:
                                </label>
                              </div>
                              <div className="col-lg-7">
                                <select
                                  name="method"
                                  className="form-select form-select-solid bg-secondary "
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  value={paymentProcesserDetails.method || ""}
                                >
                                  <option value="">Select...</option>
                                  <option value="API">API</option>
                                  <option value="OAUTH">OAuth</option>
                                </select>
                                {errors && errors.method && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.method}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {paymentProcesserDetails &&
                            paymentProcesserDetails.method === "API" ? (
                            <>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      API End Point :
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="apiEndPoint"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="API End Point"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.apiEndPoint ||
                                        ""
                                      }
                                      maxLength={42}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.apiEndPoint && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.apiEndPoint}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      API Key :
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="apiKey"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="API Key"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.apiKey || ""
                                      }
                                      maxLength={42}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.apiKey && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.apiKey}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      API Secret Key:
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="apiSecretKey"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="API Secret Key"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.apiSecretKey ||
                                        ""
                                      }
                                      maxLength={42}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.apiSecretKey && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.apiSecretKey}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="mb-3">
                            {paymentProcesserDetails &&
                              paymentProcesserDetails.method === "OAUTH" ? (
                              <>
                                <div className="row mb-3">
                                  <div className="col-lg-3 mt-0">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      OAuth Url:
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="oAuthUrl"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="OAuth Url"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.oAuthUrl || ""
                                      }
                                      maxLength={42}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.oAuthUrl && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.oAuthUrl}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-lg-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      User Name :
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="oAuthUserName"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="User Name"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.oAuthUserName ||
                                        ""
                                      }
                                      maxLength={42}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.oAuthUserName && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.oAuthUserName}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-lg-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      Password :
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="oAuthPassword"
                                      type="password"
                                      className="form-control bg-secondary"
                                      placeholder="Password"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.oAuthPassword ||
                                        ""
                                      }
                                      maxLength={42}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.oAuthPassword && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.oAuthPassword}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="col-lg-3 mt-0">
                            <label className="font-size-xs font-weight-bold mb-3  form-label">
                              Mode of Dispute submission:
                            </label>
                          </div>
                          <div className="col-lg-7">
                            <select
                              name="disputeSubmissionMode"
                              className="form-select form-select-solid bg-secondary "
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "setPaymentProcessorDetails"
                                )
                              }
                              value={
                                paymentProcesserDetails.disputeSubmissionMode ||
                                ""
                              }
                            >
                              <option value="">
                                Select submission mode...
                              </option>
                              <option value="SFTP">SFTP</option>
                              <option value="FAX">Fax</option>
                              <option value="EMAIL">Email</option>
                              <option value="API">API</option>
                            </select>
                            {errors && errors.disputeSubmissionMode && (
                              <div className="rr mt-1">
                                <style>{".rr{color:red}"}</style>
                                {errors.disputeSubmissionMode}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          {paymentProcesserDetails.disputeSubmissionMode ===
                            "SFTP" ? (
                            <>
                              <div className="row mt-5">
                                <div className="col-lg-3" />
                                <div className="col-lg-7">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <label className="font-size-xs font-weight-bold mb-3  form-label">
                                        Host Name :
                                      </label>
                                      <input
                                        name="hostName"
                                        type="text"
                                        className="form-control bg-secondary"
                                        placeholder="Host Name"
                                        onBlur={(e) =>
                                          handleTrimWhiteSpace(
                                            e,
                                            setPaymentProcessorDetails
                                          )
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            "setPaymentProcessorDetails"
                                          )
                                        }
                                        autoComplete="off"
                                        value={
                                          paymentProcesserDetails.hostName || ""
                                        }
                                        maxLength={42}
                                        onKeyPress={(e) => {
                                          if (
                                            !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                              e.key
                                            )
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                      {errors && errors.hostName && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red}"}</style>
                                          {errors.hostName}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="font-size-xs font-weight-bold mb-3  form-label">
                                        Port Number :
                                      </label>
                                      <input
                                        name="portNumber"
                                        type="text"
                                        className="form-control bg-secondary"
                                        placeholder="Port Number"
                                        onBlur={(e) =>
                                          handleTrimWhiteSpace(
                                            e,
                                            setPaymentProcessorDetails
                                          )
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            "setPaymentProcessorDetails"
                                          )
                                        }
                                        autoComplete="off"
                                        value={
                                          paymentProcesserDetails.portNumber ||
                                          ""
                                        }
                                        maxLength={10}
                                        onKeyPress={(e) => {
                                          if (!REGEX.NUMERIC.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                      {errors && errors.portNumber && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red}"}</style>
                                          {errors.portNumber}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row mt-5">
                                    <div className="col-lg-6">
                                      <label className="font-size-xs font-weight-bold mb-3  form-label">
                                        User Name :
                                      </label>
                                      <input
                                        name="username"
                                        type="text"
                                        className="form-control bg-secondary"
                                        placeholder="User Name"
                                        onBlur={(e) =>
                                          handleTrimWhiteSpace(
                                            e,
                                            setPaymentProcessorDetails
                                          )
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            "setPaymentProcessorDetails"
                                          )
                                        }
                                        autoComplete="off"
                                        value={
                                          paymentProcesserDetails.username || ""
                                        }
                                        maxLength={42}
                                        onKeyPress={(e) => {
                                          if (
                                            !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                              e.key
                                            )
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                      {errors && errors.username && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red}"}</style>
                                          {errors.username}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="font-size-xs font-weight-bold mb-3  form-label">
                                        Password :
                                      </label>
                                      <input
                                        name="password"
                                        type="password"
                                        className="form-control bg-secondary"
                                        placeholder="Password"
                                        onBlur={(e) =>
                                          handleTrimWhiteSpace(
                                            e,
                                            setPaymentProcessorDetails
                                          )
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            "setPaymentProcessorDetails"
                                          )
                                        }
                                        autoComplete="off"
                                        value={
                                          paymentProcesserDetails.password || ""
                                        }
                                        maxLength={42}
                                        onKeyPress={(e) => {
                                          if (
                                            !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                              e.key
                                            )
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                      {errors && errors.password && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red}"}</style>
                                          {errors.password}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {paymentProcesserDetails.disputeSubmissionMode ===
                            "FAX" ? (
                            <div className="row mt-5">
                              <div className="col-lg-3 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  Fax Number :
                                </label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  name="faxNumber"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="Fax Number"
                                  onBlur={(e) =>
                                    handleTrimWhiteSpace(
                                      e,
                                      setPaymentProcessorDetails
                                    )
                                  }
                                  maxLength={10}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={
                                    paymentProcesserDetails.faxNumber || ""
                                  }
                                />
                                {errors && errors.faxNumber && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.faxNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                          {paymentProcesserDetails.disputeSubmissionMode ===
                            "API" ? (
                            <>
                              <div className="mb-3 mt-5">
                                <div className="row">
                                  <div className="col-lg-3 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      API End Point :
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="requestApiEndPoint"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="API End Point"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.requestApiEndPoint ||
                                        ""
                                      }
                                      maxLength={42}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.requestApiEndPoint && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.requestApiEndPoint}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      API Key :
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="requestApiKey"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="API Key"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.requestApiKey ||
                                        ""
                                      }
                                      maxLength={42}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.requestApiKey && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.requestApiKey}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      API Secret Key:
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="requestApiSecret"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="API Secret Key"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.requestApiSecret ||
                                        ""
                                      }
                                      maxLength={42}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.requestApiSecret && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.requestApiSecret}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {paymentProcesserDetails.disputeSubmissionMode ===
                            "EMAIL" ? (
                            <>
                              <div className="mb-3 mt-5">
                                <div className="row">
                                  <div className="col-lg-3 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      Email :
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="email"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="Email"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.email || ""
                                      }
                                    />
                                    {errors && errors.email && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.email}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      BCC :
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="emailBcc"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="BCC"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.emailBcc || ""
                                      }
                                    />
                                    {errors && errors.emailBcc && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.emailBcc}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      CC :
                                    </label>
                                  </div>
                                  <div className="col-lg-7">
                                    <input
                                      name="emailCc"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="CC"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(
                                          e,
                                          setPaymentProcessorDetails
                                        )
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.emailCc || ""
                                      }
                                    />
                                    {errors && errors.emailCc && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.emailCc}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row pb-2">
                        <div className="col-lg-6" />
                        <div className="col-lg-6">
                          <div className="col-lg-11">
                            <button
                              type="button"
                              className="btn btn-sm btn-green m-2 fa-pull-right"
                              disabled={loadingACPR || loadingECP}
                              onClick={() => {
                                handleAddProcessor();
                              }}
                            >
                              {loadingACPR || loadingECP ? (
                                <span
                                  className="spinner-border spinner-border-sm mx-3"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {showSFTPTable ? (
                    <>
                      <div className="w-100 d-flex justify-content-start mt-2">
                        <div className={`card ${className}`}>
                          <div className="card-body py-3">
                            <div className="table-responsive">
                              <div className="col-md-7 d-flex">
                                <label className="col-form-label text-lg-start mt-3 ms-3">
                                  Record(s) per Page : &nbsp;{" "}
                                </label>
                                <div className="col-md-3 mt-3">
                                  <select
                                    className="form-select w-6rem"
                                    data-control="select"
                                    data-placeholder="Select an option"
                                    data-allow-clear="true"
                                    onChange={(e) => handleRecordPerPage(e)}
                                  >
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                  </select>
                                </div>
                              </div>

                              <table className="table table-hover table-rounded table-striped border w-100 gs-2 mt-2">
                                <thead>
                                  <tr className="fw-bold fs-6 text-gray-800">
                                    <th className="min-w-80px text-start">
                                      <div className="d-flex">
                                        <span>S.No</span>
                                      </div>
                                    </th>
                                    <th className="min-w-200px text-start ps-6">
                                      Action
                                    </th>
                                    <th className="min-w-200px text-start">
                                      <div className="d-flex">
                                        <span>Payment Processor</span>
                                      </div>
                                    </th>
                                    <th className="min-w-200px text-start">
                                      <div className="d-flex">
                                        <span>Submission mode</span>
                                      </div>
                                    </th>
                                    <th className="min-w-200px text-start">
                                      <div className="d-flex">
                                        <span>API End Point</span>
                                      </div>
                                    </th>
                                    <th className="min-w-200px text-start">
                                      <div className="d-flex">
                                        <span>API Key</span>
                                      </div>
                                    </th>
                                    <th className="min-w-200px text-start">
                                      <div className="d-flex">
                                        <span>API Secret</span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {clientProcessorLoading ? (
                                    <tr>
                                      <td colSpan="6" className="text-center">
                                        <div
                                          className="spinner-border text-primary m-5"
                                          role="status"
                                        />
                                      </td>
                                    </tr>
                                  ) : clientProcessorData &&
                                    clientProcessorData.length > 0 ? (
                                    clientProcessorData.map((item, i) => {
                                      return (
                                        <tr
                                          key={i}
                                          style={
                                            i === 0
                                              ? { borderColor: "black" }
                                              : { borderColor: "white" }
                                          }
                                        >
                                          <td className="pb-0 pt-5  text-start">
                                            {i + 1}
                                          </td>
                                          <td className="pb-0 pt-3 text-start">
                                            <div className="my-auto d-flex">
                                              <button
                                                className="btn btn-icon btn-bg-light btn-icon-warning btn-active-color-warning btn-sm"
                                                onClick={() => {
                                                  onEditProcessorClick(item);
                                                }}
                                              >
                                                <KTSVG
                                                  path="/media/icons/duotune/art/art005.svg"
                                                  className="svg-icon-3"
                                                />
                                              </button>
                                              <button
                                                className="btn btn-icon btn-bg-light btn-icon-danger btn-active-color-danger btn-sm"
                                                onClick={() => {
                                                  onDeleteItem(item._id);
                                                }}
                                              >
                                                <KTSVG
                                                  path="/media/icons/duotune/general/gen027.svg"
                                                  className="svg-icon-3"
                                                />
                                              </button>
                                            </div>
                                          </td>
                                          <td className="pb-0 pt-5  text-start">
                                            {item.paymentProcessorId &&
                                              item.paymentProcessorId &&
                                              item.paymentProcessorId
                                                .processorName}
                                          </td>
                                          <td className="pb-0 pt-5 text-start">
                                            {item.disputeSubmissionMode
                                              ? item.disputeSubmissionMode
                                              : "--"}
                                          </td>
                                          <td className="pb-0 pt-5  text-start">
                                            {item.apiEndPoint
                                              ? item.apiEndPoint
                                              : "--"}
                                          </td>
                                          <td className="pb-0 pt-5  text-start">
                                            {item.apiKey ? item.apiKey : "--"}
                                          </td>
                                          <td className="pb-0 pt-5  text-start">
                                            {item.apiSecretKey
                                              ? item.apiSecretKey
                                              : "--"}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr className="text-center py-3">
                                      <td colSpan="100%">No record(s) found</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="form-group row mb-4 mt-6">
                              <div className="col-lg-12 mb-4 align-items-end d-flex">
                                <div className="col-lg-12 pagination">
                                  <ReactPaginate
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={totalPages}
                                    previousLabel="< Prev"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {EmailModalShow ? (
        <Modal showModal={EmailModalShow} modalWidth={750}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">Error Handling Email Id's</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setEmailModalShow(false);
                      resetEmailState();
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body p-8">
                  <div className="container-fixed">
                    <div className="card m-2">
                      {loadingGetDetailsClientSMTP ? (
                        <div className="d-flex justify-content-center py-5">
                          <div
                            className="spinner-border text-primary m-5"
                            role="status"
                          />
                        </div>
                      ) : (
                        <>
                          <div className="row mt-2">
                            <div className="col-lg-10">
                              <label className="required fw-bolder fs-4 mb-2">
                                Email Id's
                              </label>
                              <input
                                name="errorNotificationEmails"
                                type="text"
                                className="form-control"
                                placeholder="Email Id's"
                                value={
                                  formEmailData.errorNotificationEmails || ""
                                }
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                              />
                              {errors && errors.errorNotificationEmails && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.errorNotificationEmails}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-2 px-2">
                            <div className="fs-5 fw-bolder">
                              Email Id's should be seperated with commas
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="form-group row mt-4">
                      <div className="col-lg-5" />
                      <div className="col-lg-7">
                        <div className="col-lg-12">
                          <button
                            className="btn btn-blue mt-7 fa-pull-right"
                            onClick={(event) => {
                              handleEmailSubmit(event);
                            }}
                          >
                            {loadingACSMTP ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {ImapModalShow ? (
        <Modal showModal={ImapModalShow} modalWidth={750}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">IMap Configuration</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setImapModalShow(false);
                      resetImapState();
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body p-8">
                  <div className="container-fixed">
                    <div className="card m-2">
                      <div className="row mt-2">
                        <div className="col-lg-6 my-auto">
                          <div className="row">
                            <div className="col-lg-5">
                              <div className="my-2">
                                <label className="form-check form-switch form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="imap"
                                    checked={formImapData.imap}
                                    onClick={(e) => {
                                      handleSwitchChange(e);
                                    }}
                                    onChange={(e) => {
                                      handleSwitchChange(e);
                                    }}
                                  />
                                  <span className="form-check-label fw-bold">
                                    Imap
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {errors && errors.imap && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red}"}</style>
                            {errors.imap}
                          </div>
                        )}
                      </div>
                      <button
                        className="btn btn-primary d-none"
                        id="collapseBtn"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Button
                      </button>
                      {loadingGetDetailsClientSMTP ? (
                        <div className="d-flex justify-content-center py-5">
                          <div
                            className="spinner-border text-primary m-5"
                            role="status"
                          />
                        </div>
                      ) : null}
                      <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                          <div className="row mt-2">
                            <div className="col-lg-6">
                              <label className="required fw-bold fs-6 mb-2">
                                IMap Host Address
                              </label>
                              <input
                                name="imapHostAddress"
                                type="text"
                                className="form-control"
                                placeholder="IMap Host Address"
                                value={formImapData.imapHostAddress || ""}
                                onChange={(e) => handleImapChange(e)}
                                autoComplete="off"
                              />
                              {errors && errors.imapHostAddress && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.imapHostAddress}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <label className="fw-bold fs-6 mb-2">
                                IMap Port
                              </label>
                              <input
                                name="imapPort"
                                type="text"
                                className="form-control"
                                placeholder="IMap Port"
                                value={formImapData.imapPort || ""}
                                onChange={(e) => handleImapChange(e)}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  if (!REGEX.NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors && errors.imapPort && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.imapPort}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-lg-6">
                              <label className="required fw-bold fs-6 mb-2">
                                IMap User Name
                              </label>
                              <input
                                name="imapUserName"
                                type="text"
                                className="form-control"
                                placeholder="User Name"
                                value={formImapData.imapUserName || ""}
                                onChange={(e) => handleImapChange(e)}
                                autoComplete="off"
                              />
                              {errors && errors.imapUserName && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.imapUserName}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              {/* <label className="required fw-bold fs-6 mb-2"> */}
                              <label className={` ${!editPassMode ? 'required' : ''} fw-bold fs-6 mb-2`}>
                                IMap Password
                              </label>
                              <input
                                name="imapPassword"
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                value={formImapData.imapPassword || ""}
                                onChange={(e) => handleImapChange(e)}
                                autoComplete="off"
                              />
                              {errors && errors.imapPassword && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.imapPassword}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-2">
                            {/* <div className="col-lg-6">
                              <label className="required fw-bold fs-6 mb-2">
                                Password
                              </label>
                              <input
                                name="imapPassword"
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                value={formImapData.imapPassword || ""}
                                onChange={(e) => handleImapChange(e)}
                                autoComplete="off"
                              />
                              {errors && errors.imapPassword && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.imapPassword}
                                </div>
                              )}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mt-4">
                      <div className="col-lg-5" />
                      <div className="col-lg-7">
                        <div className="col-lg-12">
                          <button
                            className="btn btn-blue mt-7 fa-pull-right"
                            onClick={(event) => {
                              handleImapSubmit(event);
                            }}
                          >
                            {loadingACSMTP ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {smtpModalShow ? (
        <Modal showModal={smtpModalShow} modalWidth={750}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">SMTP Configuration</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setSmtpModalShow(false);
                      resetState();
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body p-8">
                  <div className="container-fixed">
                    <div className="card m-2">
                      {loadingGetDetailsClientSMTP ? (
                        <div className="d-flex justify-content-center py-5">
                          <div
                            className="spinner-border text-primary m-5"
                            role="status"
                          />
                        </div>
                      ) : (
                        <>
                          <div className="row mt-2">
                            <div className="col-lg-6">
                              <label className="fw-bold fs-6 mb-2">
                                SMTP Port
                              </label>
                              <input
                                name="smtpPort"
                                type="test"
                                className="form-control"
                                placeholder="SMTP Port"
                                value={formData.smtpPort || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  if (!REGEX.NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label className="required fw-bold fs-6 mb-2">
                                SMTP Host Address
                              </label>
                              <input
                                name="smtpHostAddress"
                                type="text"
                                className="form-control"
                                placeholder="SMTP Host Address"
                                value={formData.smtpHostAddress || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                              />
                              {errors && errors.smtpHostAddress && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.smtpHostAddress}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-lg-6">
                              <label className="required fw-bold fs-6 mb-2">
                                User Name
                              </label>
                              <input
                                name="userName"
                                type="text"
                                className="form-control"
                                placeholder="User Name"
                                value={formData.userName || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                              />
                              {errors && errors.userName && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.userName}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <label className={` ${!editPassMode ? 'required' : ''} fw-bold fs-6 mb-2`}>
                                Password
                              </label>
                              <input
                                name="password"
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                value={formData.password || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                              />
                              {errors && errors.password && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.password}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-lg-6">
                              <label className="required fw-bold fs-6 mb-2">
                                From Email
                              </label>
                              <input
                                name="fromEmail"
                                type="text"
                                className="form-control"
                                placeholder="From Email"
                                value={formData.fromEmail || ""}
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                              />
                              {errors && errors.fromEmail && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.fromEmail}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="form-group row mt-4">
                      <div className="col-lg-5" />
                      <div className="col-lg-7">
                        <div className="col-lg-12">
                          <button
                            className="btn btn-blue mt-7 fa-pull-right"
                            onClick={(event) => {
                              handleSubmit(event);
                            }}
                          >
                            {loadingACSMTP ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className={`card ${className}`}>
        <div className="card-body py-3">
          <div className="d-flex  px - 2">
            <div className="d-flex justify-content-start col-md-2" />
            <div className="d-flex col-md-10 justify-content-end my-auto">
              <div className="col-lg-10">
                <div className="col-lg-12 d-flex  justify-content-end">
                  <button
                    className="btn btn-sm btn-light-primary mx-2"
                    onClick={() => {
                      setCrmModalShow(true);
                    }}
                  >
                    <i className="bi bi-building mb-1" />
                    CRM
                  </button>
                  <button
                    className="btn btn-sm btn-light-primary mx-2"
                    onClick={() => {
                      setProcessorModalShow(true);
                      getClientProcessorDispatch(prevId, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
                    }}
                  >
                    <i className="bi bi-bank2 mb-1" />
                    Payment Processor
                  </button>
                  <Link
                    to={`/client-onboarding/update/${id}/add-template`}
                    className="btn btn-sm btn-light-primary fa-pull-right mx-2"
                  >
                    <KTSVG path="/media/icons/duotune/arrows/arr087.svg" />
                    Add Email Template
                  </Link>
                  &nbsp;
                  <button
                    className="btn btn-sm btn-light-primary mx-2"
                    onClick={() => {
                      setSmtpModalShow(true);
                      getClientSMTPDetailsDispatch(prevId, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
                    }}
                  >
                    <i className="bi bi-gear-fill mb-1" />
                    SMTP Configuration
                  </button>
                  <button
                    className="btn btn-sm btn-light-primary mx-2"
                    onClick={() => {
                      setSmtpDaysModalShow(true);
                      getClientSMTPDetailsDispatch(prevId, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
                    }}
                  >
                    <i className="bi bi-alarm-fill mb-1" />
                    Reminder Interval
                  </button>
                  <button
                    className="btn btn-sm btn-light-primary mx-2"
                    onClick={() => {
                      setImapModalShow(true);
                      getClientSMTPDetailsDispatch(prevId, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
                    }}
                  >
                    <i className="fa fa-cogs mb-1" />
                    Imap Configuration
                  </button>
                  <button
                    className="btn btn-sm btn-light-primary mx-2"
                    onClick={() => {
                      setEmailModalShow(true);
                      getClientSMTPDetailsDispatch(prevId, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
                    }}
                  >
                    <i className="bi bi-envelope-exclamation-fill mb-1" />
                    Error Notification
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-rounded table-striped border gs-2 mt-6">
              <thead className="fw-bolder fs-8 text-gray-800">
                <tr>
                  <th>
                    <div className="d-flex">
                      <span>Action</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>NotificationType</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Subject</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Status</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-8">
                {!loadingTEM ? (
                  templateData && templateData && templateData.length > 0 ? (
                    templateData.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          style={
                            i === 0
                              ? { borderColor: "black" }
                              : { borderColor: "white" }
                          }
                        >
                          <td className="pb-0 pt-3 text-start my-auto">
                            <button
                              className="btn btn-icon btn-bg-light btn-active-color-warning btn-icon-warning btn-sm"
                              onClick={() => {
                                history.push(
                                  `/client-onboarding/update/${id}/update-template/${item._id}`
                                );
                              }}
                            >
                              <KTSVG
                                path="/media/icons/duotune/art/art005.svg"
                                className="svg-icon-3"
                              />
                            </button>
                            <button
                              className="btn btn-icon btn-bg-light btn-active-color-danger btn-icon-danger btn-sm"
                              onClick={() => {
                                onDeleteItem(item._id);
                              }}
                            >
                              <KTSVG
                                path="/media/icons/duotune/general/gen027.svg"
                                className="svg-icon-3"
                              />
                            </button>
                          </td>
                          <td className="ellipsis">
                            {item && item.notificationType
                              ? item.notificationType
                              : "--"}
                          </td>
                          <td className="ellipsis">
                            {item && item.subject ? item.subject : "--"}
                          </td>
                          <td>
                            <span
                              className={`badge ${STATUS_BADGES[item.status]}`}
                            >
                              {item.status ? item.status : "--"}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center py-3">
                      <td colSpan="100%">No record(s) found</td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="100%" className="text-center">
                      <div
                        className="spinner-border text-primary m-5"
                        role="status"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { templateStore, templateDeleteStore, getClientSMTPDetailsStore } =
    state;
  return {
    templateData:
      templateStore && templateStore.templateData
        ? templateStore.templateData
        : [],
    loadingTEM:
      templateStore && templateStore.loadingTEM
        ? templateStore.loadingTEM
        : false,
    count: templateStore && templateStore.count ? templateStore.count : 0,
    statusDTEM:
      templateDeleteStore && templateDeleteStore.statusDTEM
        ? templateDeleteStore.statusDTEM
        : "",
    messageDTEM:
      templateDeleteStore && templateDeleteStore.messageDTEM
        ? templateDeleteStore.messageDTEM
        : "",
    loadingACSMTP:
      state &&
      state.addClientSMTPStore &&
      state.addClientSMTPStore.loadingACSMTP,
    dataACSMTP:
      state && state.addClientSMTPStore && state.addClientSMTPStore.dataACSMTP,
    statusACSMTP:
      state &&
      state.addClientSMTPStore &&
      state.addClientSMTPStore.statusACSMTP,
    messageACSMTP:
      state &&
      state.addClientSMTPStore &&
      state.addClientSMTPStore.messageACSMTP,
    getDetailsClientSMTP:
      getClientSMTPDetailsStore &&
        getClientSMTPDetailsStore.getDetailsClientSMTP
        ? getClientSMTPDetailsStore.getDetailsClientSMTP
        : {},
    loadingGetDetailsClientSMTP:
      getClientSMTPDetailsStore &&
        getClientSMTPDetailsStore.loadingGetDetailsClientSMTP
        ? getClientSMTPDetailsStore.loadingGetDetailsClientSMTP
        : false,
    statusGetDetailsClientSMTP:
      getClientSMTPDetailsStore &&
        getClientSMTPDetailsStore.statusGetDetailsClientSMTP
        ? getClientSMTPDetailsStore.statusGetDetailsClientSMTP
        : "",
    processorLoading:
      state && state.processorStore && state.processorStore.processorLoading,
    processorData:
      state && state.processorStore && state.processorStore.processorData,
    clientProcessorLoading:
      state &&
      state.getClientProcessorDetailsStore &&
      state.getClientProcessorDetailsStore.loadingCPDetails,
    clientProcessorData:
      state &&
      state.getClientProcessorDetailsStore &&
      state.getClientProcessorDetailsStore.getCPDetails,
    statusCPR:
      state && state.checkProcessorStore && state.checkProcessorStore.statusCPR,
    messageCPR:
      state &&
      state.checkProcessorStore &&
      state.checkProcessorStore.messageCPR,
    statusACPR:
      state &&
      state.addclientProcessorStore &&
      state.addclientProcessorStore.statusACPR,
    messageACPR:
      state &&
      state.addclientProcessorStore &&
      state.addclientProcessorStore.messageACPR,
    loadingACPR:
      state &&
      state.addclientProcessorStore &&
      state.addclientProcessorStore.loadingACPR,
    loadingCPR:
      state &&
      state.checkProcessorStore &&
      state.checkProcessorStore.loadingCPR,
    statusECP:
      state &&
      state.editClientProcessorStore &&
      state.editClientProcessorStore.statusECP,
    messageECP:
      state &&
      state.editClientProcessorStore &&
      state.editClientProcessorStore.messageECP,
    loadingECP:
      state &&
      state.editClientProcessorStore &&
      state.editClientProcessorStore.loadingECP,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTemplateDispatch: (params, headers) =>
    dispatch(templateActions.getTemplate(params, headers)),
  deleteTemplateDispatch: (params, headers) =>
    dispatch(deleteTemplateActions.deleteTemplate(params, headers)),
  clearDeleteTemplateDispatch: () =>
    dispatch(deleteTemplateActions.clearDeleteTemplate()),
  addClientSMTPDispatch: (data, headers) =>
    dispatch(addClientSMTPActions.addClientSMTP(data, headers)),
  clearaddClientSMTPDispatch: () =>
    dispatch(addClientSMTPActions.clearaddClientSMTP()),
  getClientSMTPDetailsDispatch: (id, headers) =>
    dispatch(getClientSMTPDetailsActions.getDetails(id, headers)),
  clearClientSMTPDetailsDispatch: () =>
    dispatch(getClientSMTPDetailsActions.clear()),
  getClientProcessorDispatch: (id, headers) =>
    dispatch(getClientProcessorDetailsActions.getDetails(id, headers)),
  clearClientProcessorDispatch: () =>
    dispatch(getClientProcessorDetailsActions.clear()),
  getProcessorDispatch: (params, headers) => dispatch(processorActions.getProcessor(params, headers)),
  checkProcessorDispatch: (data, headers) => dispatch(checkProcessorActions.add(data, headers)),
  clearcheckProcessorDispatch: () => dispatch(checkProcessorActions.clear()),
  addclientProcessorDispatch: (data, headers) =>
    dispatch(addclientProcessorActions.addclientProcessor(data, headers)),
  clearaddclientProcessorDispatch: () =>
    dispatch(addclientProcessorActions.clearaddclientProcessor()),
  editClientProcessorDispatch: (id, params, headers) =>
    dispatch(editClientProcessorActions.editClientProcessor(id, params, headers)),
  cleareditClientProcessorDispatch: () =>
    dispatch(editClientProcessorActions.cleareditClientProcessor()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateList);
