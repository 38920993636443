import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from '../../theme/helpers'
import { colors, DROPZONE_MESSAGES, FILE_CB_DOCUMENT, DATE, STATUS_RESPONSE, API_URL } from '../../utils/constants'
import Dropzone, { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from "moment";
import { merchantEvidenceActions, addMerchantEvidenceActions } from '../../store/actions'
import { confirmAlert, warningAlert } from "../../utils/alerts";
import { headersWithAuth } from '../../utils/helper'

const StyledDiv = styled.div`
    .container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px;
        width: 500px;
        height: 33px;
        border-width: 2px;
        border-radius: 2px;
        border-style: dashed;
        background-color: #fafafa;
        color:#9ab6d9;
        outline: none;
        transition: border .24s ease-in-out;
    }
`

const ChargebackEvidence = (props) => {
  const {
    className,
    getCBDetailsDispatch,
    getMerchantEvidence,
    loadingCBD,
    addDocumentDispatch,
    clearaddDocumentDispatch,
    statusAME,
    messageAME
  } = props

  const url = useLocation().pathname;
  const fields = url && url.split("/");
  const id = fields && fields[3];

  useEffect(() => {
    getCBDetailsDispatch(id, headersWithAuth)
  }, [])

  const { getRootProps } = useDropzone()
  const [errors, setErrors] = useState({})
  const [, setFileName] = useState()

  const handleFileUpload = (files, name, documentName) => {
    let isValidFileFormat = true;
    const maxFileSize = 5;
    files.forEach((item) => {
      const fileType = item && item.type;
      const uploadedFileSize = item && item.size;
      isValidFileFormat = _.includes(FILE_CB_DOCUMENT, fileType);
      const fileSize = Number(maxFileSize) * 1024 * 1024;
      if (isValidFileFormat) {
        if (uploadedFileSize < fileSize) {
          setFileName(item && item.name);
          const ChargebackId = getMerchantEvidence &&
            getMerchantEvidence.chargeBackDetails &&
            getMerchantEvidence.chargeBackDetails._id
          let docType = ''
          getMerchantEvidence && getMerchantEvidence.pendingDocuments.forEach((docs) => {
            if (docs.documentName === documentName) {
              docType = docs.documentName
            }

          })
          const data = new FormData();
          data.append("type", docType);
          data.append("file_to_upload", item);
          data.append("fileType", "document");
          data.append("chargebackId", ChargebackId);
          addDocumentDispatch(data);
        } else {
          setErrors({
            ...errors,
            [name]: `File size must below ${fileSize / 1048576
              } MB!. You file size is : ${Math.round(uploadedFileSize / 1024)}KB`,
          });
        }
      } else {
        setErrors({ ...errors, [name]: DROPZONE_MESSAGES.PDF_INVALID });
      }
    })
  };

  const onConfirm = () => {
    getCBDetailsDispatch(id, headersWithAuth)
  }

  useEffect(() => {
    if (statusAME === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAME,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearaddDocumentDispatch();
    } else if (statusAME === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAME, "", "Ok");
    }
    clearaddDocumentDispatch();
  }, [statusAME, messageAME]);

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/illustrations/sketchy-1/14.png"
          )})`,
          backgroundColor: colors.oxfordBlue,
        }}
      >
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {!loadingCBD &&
            <a href="#" className="mb-12">
              <img
                alt="Logo"
                className="h-65px"
                src={
                  getMerchantEvidence.chargeBackDetails &&
                    getMerchantEvidence.chargeBackDetails.clientId &&
                    getMerchantEvidence.chargeBackDetails.clientId.logo ?
                    `${API_URL}/uploads/${getMerchantEvidence.chargeBackDetails &&
                    getMerchantEvidence.chargeBackDetails.clientId &&
                    getMerchantEvidence.chargeBackDetails.clientId.logo}` :
                    toAbsoluteUrl("/media/logos/logo-1.svg")
                }
              />
            </a>
          }
          <div className="w-lg-1500px rounded shadow-sm p-5 p-lg-12 mx-auto" style={{ backgroundColor: '#9eaebb' }}>
            <div className="text-center mb-10">
              <h1 className="text-dark mb-3">Chargeback Details</h1>
            </div>
            {
              loadingCBD
                ? (
                  <div className='d-flex justify-content-center py-5'>
                    <div className='spinner-border text-primary m-5' role='status' />
                  </div>
                )
                : (
                  <>
                    <div className='scroll h-600px'>
                      <div className="row g-5 g-xl-6 mb-4">
                        <div className="col-xl-12 col-sm-10 col-md-10  mx-auto">
                          <div
                            className={`card ${className} min-cardH-auto`}
                          >
                            <div className="card-header border-0 pt-5">
                              <div className="card-title align-items-start flex-column text-primary fw-bolder">
                                UPLOAD EVIDENCES
                              </div>
                            </div>
                            {getMerchantEvidence && getMerchantEvidence.pendingDocuments &&
                              getMerchantEvidence.pendingDocuments ? (
                              getMerchantEvidence.pendingDocuments.map((item, i) =>
                                <>
                                  <div className="d-flex" key={i}>
                                    <div className="col-lg-3 text-gray-700 fw-bolder fs-6 ms-15 mt-3">
                                      {_.startCase(item.documentName)}
                                    </div>
                                    {item.upload === 'YES' &&
                                      <div className='col-lg-4 m-3'>
                                        <StyledDiv {...getRootProps({ refKey: 'innerRef' })}>
                                          <Dropzone
                                            onDrop={acceptedFiles => { handleFileUpload(acceptedFiles, 'file_to_upload', null) }}
                                          >
                                            {({ getRootProps, getInputProps }) => (
                                              <div className='container w-25rem'>
                                                <div {...getRootProps()}>
                                                  <input {...getInputProps()} name='file_to_upload' disabled={true} />
                                                  <p className='fw-bolder fs-6' style={{ color: "#b6c7d1", cursor: "context-menu" }}>Click here to select files</p>
                                                </div>
                                              </div>
                                            )}
                                          </Dropzone>
                                        </StyledDiv>
                                      </div>
                                    }
                                    {item.upload === 'NO' &&
                                      <div className='col-lg-4 m-3'>
                                        <StyledDiv {...getRootProps({ refKey: 'innerRef' })}>
                                          <Dropzone
                                            onDrop={acceptedFiles => { handleFileUpload(acceptedFiles, 'file_to_upload', item.documentName) }}
                                          >
                                            {({ getRootProps, getInputProps }) => (
                                              <div className='container w-25rem'>
                                                <div {...getRootProps()}>
                                                  <input {
                                                    ...getInputProps()
                                                  }
                                                    name='file_to_upload'
                                                    type="file"
                                                    id="file_to_upload"
                                                    multiple={false}
                                                  />
                                                  <p className='text-primary fw-bolder fs-6'>
                                                    Click here to select files
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                          </Dropzone>
                                        </StyledDiv>
                                      </div>
                                    }
                                    {item.upload === 'YES' &&
                                      <div className='col-lg-2 d-flex justify-content-center m-2'>
                                        <span className="svg-icon svg-icon-3x svg-icon-success">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="#199d55"></rect>
                                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
                                          </svg>
                                        </span>
                                      </div>
                                    }
                                    {item.upload === 'NO' &&
                                      <div className='col-lg-2 d-flex justify-content-center m-2'>
                                        <span className="svg-icon svg-icon-3x svg-icon-danger">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor" />
                                            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor" />
                                          </svg>
                                        </span>
                                      </div>
                                    }
                                  </div>
                                </>
                              )) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row g-5 g-xl-6 mb-4">
                        <div className="col-xl-6 col-sm-10 col-md-10 mx-auto">
                          <div
                            className={`card ${className} min-cardH-auto`}
                          >
                            <div className="card-header border-0 pt-5">
                              <div className="card-title align-items-start flex-column text-primary fw-bolder">
                                CHARGEBACK INFORMATION
                              </div>
                            </div>
                            <div className="mx-7 mb-5">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Acquirer Name :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-3">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.acquirerName ?
                                        getMerchantEvidence.chargeBackDetails.acquirerName : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Client :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-3">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.companyName ?
                                        `${getMerchantEvidence.chargeBackDetails.companyName}` : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Merchant :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-3">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.merchantName ?
                                        `${getMerchantEvidence.chargeBackDetails.merchantName}` : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      MID Number :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-3">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.mid ?
                                        `${getMerchantEvidence.chargeBackDetails.mid.midNumber}` : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      DBA Name :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-3">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.DBAName ?
                                        getMerchantEvidence.chargeBackDetails.DBAName : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Chargeback Type :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-3">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.chargeBackType ?
                                        getMerchantEvidence.chargeBackDetails.chargeBackType : "--"}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Chargeback Date :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-3">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.chargebackDate ?
                                        moment(getMerchantEvidence.chargeBackDetails.chargebackDate).format(
                                          DATE.DATE_FORMAT
                                        ) : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Chargeback Amount :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-3">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.chargebackAmount ?
                                        getMerchantEvidence.chargeBackDetails.chargebackAmount : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Chargeback Currency :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-3">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.chargebackCurrency ?
                                        getMerchantEvidence.chargeBackDetails.chargebackCurrency : "--"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-10 col-md-10  mx-auto">
                          <div
                            className={`card ${className} min-cardH-auto `}
                          >
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column text-primary fw-bolder">
                                TRANSACTION INFORMATION
                              </h3>
                            </div>
                            <div className="mx-7 mb-5">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Transaction Number:
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.transactionId ?
                                        getMerchantEvidence.chargeBackDetails.transactionId : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Payment Processor:
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.paymentProcessor ?
                                        `${getMerchantEvidence.chargeBackDetails.paymentProcessor.processorName}` : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Card Bin :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.cardFirstSix ?
                                        getMerchantEvidence.chargeBackDetails.cardFirstSix : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Card Last 4 Digit :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.cardLastFour ?
                                        getMerchantEvidence.chargeBackDetails.cardLastFour : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Transaction Amount:
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.transactionAmount ?
                                        getMerchantEvidence.chargeBackDetails.transactionAmount : "--"}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Transaction Date :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.transactionDate ?
                                        moment(getMerchantEvidence.chargeBackDetails.transactionDate).format(
                                          DATE.DATE_FORMAT
                                        ) : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Payment Method :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.paymentMethod ?
                                        getMerchantEvidence.chargeBackDetails.paymentMethod : "--"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-5 g-xl-6 mb-4">
                        <div className="col-xl-6 col-sm-10 col-md-10  mx-auto">
                          <div
                            className={`card ${className} min-cardH-auto min-cardW-auto`}
                          >
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column text-primary fw-bolder">
                                ORDER INFORMATION
                              </h3>
                            </div>
                            <div className="mx-7 mb-5">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Order Id :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.chargeBackDetails &&
                                        getMerchantEvidence.chargeBackDetails.orderId ?
                                        getMerchantEvidence.chargeBackDetails.orderId : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Billing Address :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.transactionDetails &&
                                        getMerchantEvidence.transactionDetails.billingAddress ?
                                        getMerchantEvidence.transactionDetails.billingAddress : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Billing City :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.transactionDetails &&
                                        getMerchantEvidence.transactionDetails.shippingAddressDetails ?
                                        getMerchantEvidence.transactionDetails.shippingAddressDetails : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Shipping Amount :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.transactionDetails &&
                                        getMerchantEvidence.transactionDetails.shippingAndHandlingAmount ?
                                        getMerchantEvidence.transactionDetails.shippingAndHandlingAmount : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Shipping Currency :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.transactionDetails &&
                                        getMerchantEvidence.transactionDetails.shippingAndHandlingCurrency ?
                                        getMerchantEvidence.transactionDetails.shippingAndHandlingCurrency : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Shipping Address :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.transactionDetails &&
                                        getMerchantEvidence.transactionDetails.shippingAddress ?
                                        getMerchantEvidence.transactionDetails.shippingAddress : "--"}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Shipping City :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.transactionDetails &&
                                        getMerchantEvidence.transactionDetails.shippingAddressDetails ?
                                        getMerchantEvidence.transactionDetails.shippingAddressDetails : "--"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-10 col-md-10  mx-auto">
                          <div
                            className={`card ${className} min-cardH-auto`}
                          >
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column text-primary fw-bolder">
                                CUSTOMER INFORMATION
                              </h3>
                            </div>
                            <div className="mx-7 mb-5">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Customer First Name :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.customerData &&
                                        getMerchantEvidence.customerData.customerFirstName ?
                                        getMerchantEvidence.customerData.customerFirstName : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Customer Last Name :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.customerData &&
                                        getMerchantEvidence.customerData.customerLastName ?
                                        getMerchantEvidence.customerData.customerLastName : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Customer Country :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.customerData &&
                                        getMerchantEvidence.customerData.customerCountry ?
                                        getMerchantEvidence.customerData.customerCountry : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Account Name :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.customerData &&
                                        getMerchantEvidence.customerData.customerEmailName ?
                                        getMerchantEvidence.customerData.customerEmailName : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Customer Email :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.customerData &&
                                        getMerchantEvidence.customerData.customerEmail ?
                                        getMerchantEvidence.customerData.customerEmail : "--"}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <div className="text-gray-700 fw-bolder fs-6 pl-3">
                                      Phone Number :
                                    </div>
                                    <div className="fw-bolder text-muted fs-6 w-120px mx-5">
                                      {getMerchantEvidence &&
                                        getMerchantEvidence.customerData &&
                                        getMerchantEvidence.customerData.customerPhoneNumber ?
                                        getMerchantEvidence.customerData.customerPhoneNumber : "--"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-10 col-md-10  mx-auto"></div>
                      </div>
                    </div>
                  </>
                )
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { merchantEvidenceStore } = state
  return {
    getMerchantEvidence: merchantEvidenceStore && merchantEvidenceStore.getMerchantEvidence ? merchantEvidenceStore.getMerchantEvidence : {},
    loadingCBD: merchantEvidenceStore && merchantEvidenceStore.loading ? merchantEvidenceStore.loading : false,
    loadingAME:
      state && state.addMerchantEvidenceStore && state.addMerchantEvidenceStore.loadingAME,
    statusAME: state && state.addMerchantEvidenceStore && state.addMerchantEvidenceStore.statusAME,
    messageAME:
      state && state.addMerchantEvidenceStore && state.addMerchantEvidenceStore.messageAME,
    dataAME: state && state.addMerchantEvidenceStore && state.addMerchantEvidenceStore.dataAME,
  }
}

const mapDispatchToProps = dispatch => ({
  getCBDetailsDispatch: (id, headers) =>
    dispatch(merchantEvidenceActions.getMerchantEvidence(id, headers)),
  addDocumentDispatch: (data, headers) => dispatch(addMerchantEvidenceActions.addMerchantEvidence(data, headers)),
  clearaddDocumentDispatch: () =>
    dispatch(addMerchantEvidenceActions.clearaddMerchantEvidence()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChargebackEvidence)
