export const addUsertypeTypes = {
  REQUEST: 'ADD_USER_TYPE_REQUEST',
  RESPONSE: 'ADD_USER_TYPE_RESPONSE',
  ERROR: 'ADD_USER_TYPE_ERROR',
  CLEAR: 'ADD_USER_TYPE_CLEAR'
}

export const addUsertypeActions = {
  addUsertype: (data, headers) => {
    return {
      type: addUsertypeTypes.REQUEST,
      payload: data, headers
    }
  },
  saveaddUsertypeResponse: (data) => {
    return {
      type: addUsertypeTypes.RESPONSE,
      data
    }
  },
  clearaddUsertype: () => ({
    type: addUsertypeTypes.CLEAR
  })
}

export const fetchUserTypeActionsTypes = {
  REQUEST: 'FETCH_USER_TYPE',
  RESPONSE: 'SAVE_FETCHED_USER_TYPE_RESPONSE',
  CLEAR: 'CLEAR_FETCHED_USER_TYPE'
}

export const fetchUserTypesActions = {
  getUserType: (params, headers) => ({
    type: fetchUserTypeActionsTypes.REQUEST,
    params, headers
  }),
  saveUserTypeResponse: data => ({
    type: fetchUserTypeActionsTypes.RESPONSE,
    data
  }),
  clearUserType: () => ({
    type: fetchUserTypeActionsTypes.CLEAR
  })
}

export const userTypeGetDetailsTypes = {
  GET_USER_TYPE_DETAILS: 'GET_USER_TYPE_DETAILS',
  USER_TYPE_DETAILS_RESPONSE: 'USER_TYPE_DETAILS_RESPONSE',
  CLEAR_USER_TYPE_DETAILS: 'CLEAR_USER_TYPE_DETAILS'
}
export const userTypeDetailsActions = {
  getUsertypeDetails: (id, headers) => ({
    type: userTypeGetDetailsTypes.GET_USER_TYPE_DETAILS,
    id, headers
  }),
  saveUsertypeDetailsResponse: data => ({
    type: userTypeGetDetailsTypes.USER_TYPE_DETAILS_RESPONSE,
    data
  }),
  clearUsertypeDetails: () => ({
    type: userTypeGetDetailsTypes.CLEAR_USER_TYPE_DETAILS
  })
}

export const editUsertypeTypes = {
  REQUEST: 'EDIT_USER_TYPE_REQUEST',
  RESPONSE: 'EDIT_USER_TYPE_RESPONSE',
  ERROR: 'EDIT_USER_TYPE_ERROR',
  CLEAR: 'EDIT_USER_TYPE_CLEAR'
}

export const editUsertypeActions = {
  editUsertypes: (id, data, headers) => {
    return {
      type: editUsertypeTypes.REQUEST,
      payload: { id, data },
      headers
    }
  },
  saveeditUsertypesResponse: (data) => {
    return {
      type: editUsertypeTypes.RESPONSE,
      data
    }
  },
  cleareditUsertypes: () => ({
    type: editUsertypeTypes.CLEAR
  })
}

export const userTypeDeleteTypes = {
  DELETE_USER_TYPE: 'DELETE_USER_TYPE',
  SAVE_DELETE_USER_TYPE_RESPONSE: 'SAVE_DELETE_USER_TYPE_RESPONSE',
  CLEAR_DELETE_USER_TYPE: 'CLEAR_DELETE_USER_TYPE'
}

export const deleteUsertypeActions = {
  deleteUsertype: (params, headers) => ({
    type: userTypeDeleteTypes.DELETE_USER_TYPE,
    params, headers
  }),
  savemResponseDeleteUsertype: data => ({
    type: userTypeDeleteTypes.SAVE_DELETE_USER_TYPE_RESPONSE,
    data
  }),
  clearDeleteUsertype: () => ({
    type: userTypeDeleteTypes.CLEAR_DELETE_USER_TYPE
  })
}
