import React, { useEffect, useState } from "react";
import "../../styles/dashboard/posts.scss";
import axiosInstance from "../../services";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import serviceList from "../../services/serviceList";
import { headersWithAuth } from "../../utils/helper";

function PerformanceAnalyzerAWS(props) {
  const [source, setSource] = useState();

  let dashboard;
  const options = {
    url: source,
    container: document.getElementById("embeddingContainer"),
  };

  useEffect(() => {
    getUrl();
    return () => {
      props.clearPostsDispatch();
      setSource(null);
    };
  }, []);

  const getUrl = async () => {
    console.log("Inside getUrl", this);
    const url = serviceList.performanceanalyzer;
    axiosInstance.get(url, { headers: headersWithAuth }).then((response) => {
      if (response.status === 200) {
        const src =
          response.data && response.data.data && response.data.data.EmbedUrl
            ? response.data.data.EmbedUrl
            : "";
        setSource(src);
      }
    });
  };

  useEffect(() => {
    if (source) {
      // QuickSightEmbedding.embedDashboard(options)
      dashboard = QuickSightEmbedding.embedDashboard(options);
    }
  }, [source]);

  return (
    <div>
      <div id="embeddingContainer" style={{ height: "100vh" }} />
    </div>
  );
}

export default PerformanceAnalyzerAWS;
