import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  preventionAlertActionsTypes,
  preventionAlertActions,
  preventionAlertGetDetailsTypes,
  preventionAlertDetailsActions,
  editAlertsTypes,
  editAlertsActions,
  outcomeAlertActionsTypes,
  outcomeAlertActions,
  refundAlertActionsTypes,
  refundAlertActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchPrevention (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.preventionAlert, { params, headers: headers })
    if (json.status === 200) {
      yield put(preventionAlertActions.savePreventionAlertResponse(json.data))
    } else {
      yield put(preventionAlertActions.savePreventionAlertResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: preventionAlertActionsTypes.SAVE_PREVENTIONALERT_RESPONSE, data })
  }
}

function * getPreventionDetails (actions) {
  const { params, headers } = actions
  const endPointUrl = `${serviceList.preventionAlert}/${params}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers: headers})
    if (json.status === 200) {
      yield put(preventionAlertDetailsActions.savePrevAlertDetailsResponse(json.data))
    } else {
      yield put(preventionAlertDetailsActions.savePrevAlertDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: preventionAlertGetDetailsTypes.PREVENTION_ALERT_DETAILS_RESPONSE, data })
  }
}

function * editAlerts (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.preventionAlert}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers: headers})
    if (json.status === 200) {
      yield put(editAlertsActions.saveeditAlertsResponse(json.data))
    } else {
      yield put(editAlertsActions.saveeditAlertsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editAlertsTypes.RESPONSE, data })
  }
}

function * fetchOutcome (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.outcomeAlert, { params, headers: headers })
    if (json.status === 200) {
      yield put(outcomeAlertActions.saveOutcomeAlertResponse(json.data))
    } else {
      yield put(outcomeAlertActions.saveOutcomeAlertResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: outcomeAlertActionsTypes.SAVE_OUTCOME_ALERT_RESPONSE, data })
  }
}

function * fetchRefund (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.refundAlert, { params, headers: headers })
    if (json.status === 200) {
      yield put(refundAlertActions.saveRefundAlertResponse(json.data))
    } else {
      yield put(refundAlertActions.saveRefundAlertResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: refundAlertActionsTypes.SAVE_REFUND_ALERT_RESPONSE, data })
  }
}

export function * fetchPreventionWatcher () {
  yield all([
    yield takeLatest(preventionAlertActionsTypes.GET_PREVENTIONALERT, fetchPrevention),
    yield takeLatest(preventionAlertGetDetailsTypes.GET_PREVENTION_ALERT_DETAILS, getPreventionDetails),
    yield takeLatest(editAlertsTypes.REQUEST, editAlerts),
    yield takeLatest(outcomeAlertActionsTypes.GET_OUTCOME_ALERT, fetchOutcome),
    yield takeLatest(refundAlertActionsTypes.GET_REFUND_ALERT, fetchRefund)
  ])
}
