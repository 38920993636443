import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  clientCRMActionsTypes,
  clientCRMActions,
  clientProcessorActionsTypes,
  clientProcessorActions,
  clientGatewayActionsTypes,
  clientGatewayActions,
  editClientCRMActionsTypes,
  editClientCrmActions,
  editClientProcessorActionsTypes,
  editClientProcessorActions,
  editClientPaymentActionsTypes,
  editClientPaymentActions,
  addclientCRMTypes,
  addclientCRMActions,
  clientCRMDeleteTypes,
  deleteclientCRMActions,
  addclientProcessorTypes,
  addclientProcessorActions,
  clientProcessorDeleteTypes,
  deleteclientProcessorActions,
  addclientGatewayTypes,
  addclientGatewayActions,
  clientGatewayDeleteTypes,
  deleteclientGatewayActions,
  midCRMActionsTypes,
  midCRMActions,
  midCRMDeleteTypes,
  deletemidCRMActions,
  addmidCRMActions,
  addmidCRMTypes,
  midCRMGetDetailsTypes,
  midCRMGetDetailsActions,
  editmidCRMActionsTypes,
  editmidCRMActions,
  checkProcessorTypes,
  checkProcessorActions,
  getClientProcessorDetailsActionsTypes,
  getClientProcessorDetailsActions,
  checkCrmTypes,
  checkCrmActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addclientCRM (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.addclientCRM, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addclientCRMActions.saveaddclientCRMResponse(json.data))
    } else {
      yield put(addclientCRMActions.clearaddclientCRM([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addclientCRMTypes.RESPONSE, data })
  }
}

function * fetchCLientCRM (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.ClientCRM, { params, headers:headers })
    if (json.status === 200) {
      yield put(clientCRMActions.saveClientCRMResponse(json.data))
    } else {
      yield put(clientCRMActions.saveClientCRMResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientCRMActionsTypes.SAVE_CLIENT_CRM_RESPONSE, data })
  }
}

function * editClientCrm (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.ClientCRM}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(editClientCrmActions.saveeditClientCrmResponse(json.data))
    } else {
      yield put(editClientCrmActions.saveeditClientCrmResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editClientCRMActionsTypes.RESPONSE, data })
  }
}

function * deleteclientCRM (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.deleteclientCRM}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deleteclientCRMActions.savemResponseDeleteclientCRM(json.data))
    } else {
      yield put(deleteclientCRMActions.savemResponseDeleteclientCRM([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientCRMDeleteTypes.SAVE_DELETE_CLIENT_CRM_RESPONSE, data })
  }
}

function * addclientProcessor (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.addclientProcessor, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addclientProcessorActions.saveaddclientProcessorResponse(json.data))
    } else {
      yield put(addclientProcessorActions.clearaddclientProcessor([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addclientProcessorTypes.RESPONSE, data })
  }
}

function * fetchClientProcessor (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.clientProcessor, { params, headers:headers })
    if (json.status === 200) {
      yield put(clientProcessorActions.saveClientProcessorResponse(json.data))
    } else {
      yield put(clientProcessorActions.saveClientProcessorResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientProcessorActionsTypes.SAVE_CLIENT_PROCESSOR_RESPONSE, data })
  }
}

function * editClientProcessor (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.clientProcessor}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(editClientProcessorActions.saveeditClientProcessorResponse(json.data))
    } else {
      yield put(editClientProcessorActions.saveeditClientProcessorResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editClientProcessorActionsTypes.RESPONSE, data })
  }
}

function * deleteclientProcessor (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.deleteclientProcessor}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deleteclientProcessorActions.saveResponseDeleteclientProcessor(json.data))
    } else {
      yield put(deleteclientProcessorActions.saveResponseDeleteclientProcessor([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientProcessorDeleteTypes.SAVE_DELETE_CLIENT_PROCESSOR_RESPONSE, data })
  }
}

function * addclientGateway (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.addclientGateway, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addclientGatewayActions.saveaddclientGatewayResponse(json.data))
    } else {
      yield put(addclientGatewayActions.clearaddclientGateway([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addclientGatewayTypes.RESPONSE, data })
  }
}

function * fetchClientGateway (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.clientGateway, { params, headers:headers })
    if (json.status === 200) {
      yield put(clientGatewayActions.saveClientPaymentResponse(json.data))
    } else {
      yield put(clientGatewayActions.saveClientPaymentResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientGatewayActionsTypes.SAVE_CLIENT_GATEWAY_RESPONSE, data })
  }
}

function * editClientGateway (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.clientGateway}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(editClientPaymentActions.saveeditClientPaymentResponse(json.data))
    } else {
      yield put(editClientPaymentActions.saveeditClientPaymentResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editClientPaymentActionsTypes.RESPONSE, data })
  }
}

function * deleteclientGateway (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.deleteclientGateway}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deleteclientGatewayActions.saveResponseDeleteclientGateway(json.data))
    } else {
      yield put(deleteclientGatewayActions.saveResponseDeleteclientGateway([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientGatewayDeleteTypes.SAVE_DELETE_CLIENT_GATEWAY_RESPONSE, data })
  }
}

function * fetchmidCRM (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.MidCRM, { params, headers:headers })
    if (json.status === 200) {
      yield put(midCRMActions.savemidCRMResponse(json.data))
    } else {
      yield put(midCRMActions.savemidCRMResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: midCRMActionsTypes.SAVE_MID_CRM_RESPONSE, data })
  }
}

function * deletemidCRM (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.deletemidCRM}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deletemidCRMActions.savemResponseDeletemidCRM(json.data))
    } else {
      yield put(deletemidCRMActions.savemResponseDeletemidCRM([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: midCRMDeleteTypes.SAVE_DELETE_MID_CRM_RESPONSE, data })
  }
}

function * addmidCRM (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.addMidCRM, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addmidCRMActions.saveaddmidCRMResponse(json.data))
    } else {
      yield put(addmidCRMActions.clearaddmidCRM([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addmidCRMTypes.RESPONSE, data })
  }
}

function * getmidCRMDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.getMIDCRMDetails}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(midCRMGetDetailsActions.savemidCRMDetailsResponse(json.data))
    } else {
      yield put(midCRMGetDetailsActions.savemidCRMDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: midCRMGetDetailsTypes.MID_CRM_DETAILS_RESPONSE, data })
  }
}

function * editmidCRM (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.editMidCRM}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(editmidCRMActions.saveeditmidCRMResponse(json.data))
    } else {
      yield put(editmidCRMActions.saveeditmidCRMResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editmidCRMActionsTypes.RESPONSE, data })
  }
}

function * checkProcessor (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.checkProcessor, payload, {headers:headers})
    if (json.status === 200) {
      yield put(checkProcessorActions.saveResponse(json.data))
    } else {
      yield put(checkProcessorActions.clear([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: checkProcessorTypes.RESPONSE, data })
  }
}

function * getClientProcessorDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.ClientProcessorDetails}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(
        getClientProcessorDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getClientProcessorDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getClientProcessorDetailsActionsTypes.RESPONSE, data })
  }
}

function * checkCrm (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.checkCrm, payload, {headers:headers})
    if (json.status === 200) {
      yield put(checkCrmActions.saveResponse(json.data))
    } else {
      yield put(checkCrmActions.clear([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: checkCrmTypes.RESPONSE, data })
  }
}

export function * fetchClientCRMWatcher () {
  yield all([
    yield takeLatest(clientCRMActionsTypes.GET_CLIENT_CRM, fetchCLientCRM),
    yield takeLatest(clientProcessorActionsTypes.GET_CLIENT_PROCESSOR, fetchClientProcessor),
    yield takeLatest(clientGatewayActionsTypes.GET_CLIENT_GATEWAY, fetchClientGateway),
    yield takeLatest(editClientCRMActionsTypes.REQUEST, editClientCrm),
    yield takeLatest(editClientProcessorActionsTypes.REQUEST, editClientProcessor),
    yield takeLatest(editClientPaymentActionsTypes.REQUEST, editClientGateway),
    yield takeLatest(addclientCRMTypes.REQUEST, addclientCRM),
    yield takeLatest(addclientProcessorTypes.REQUEST, addclientProcessor),
    yield takeLatest(addclientGatewayTypes.REQUEST, addclientGateway),
    yield takeLatest(clientCRMDeleteTypes.DELETE_CLIENT_CRM, deleteclientCRM),
    yield takeLatest(clientProcessorDeleteTypes.DELETE_CLIENT_PROCESSOR, deleteclientProcessor),
    yield takeLatest(clientGatewayDeleteTypes.DELETE_CLIENT_GATEWAY, deleteclientGateway),
    yield takeLatest(midCRMActionsTypes.GET_MID_CRM, fetchmidCRM),
    yield takeLatest(midCRMDeleteTypes.DELETE_MID_CRM, deletemidCRM),
    yield takeLatest(addmidCRMTypes.REQUEST, addmidCRM),
    yield takeLatest(editmidCRMActionsTypes.REQUEST, editmidCRM),
    yield takeLatest(midCRMGetDetailsTypes.GET_MID_CRM_DETAILS, getmidCRMDetails),
    yield takeLatest(checkProcessorTypes.REQUEST, checkProcessor),
    yield takeLatest(getClientProcessorDetailsActionsTypes.REQUEST, getClientProcessorDetails),
    yield takeLatest(checkCrmTypes.REQUEST, checkCrm),
  ])
}
