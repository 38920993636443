import React, { useEffect } from "react";
import { AnyIfEmpty, connect } from "react-redux";
import moment from "moment";
import { LoginActions, siteConfigActions } from "../../store/actions";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import HeaderWrapper from "./components/header/HeaderWrapper";
import { Toolbar } from "./components/toolbar/Toolbar";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
import { useLocation } from "react-router-dom";
import {
  DrawerMessenger,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from "../partials";
import { MenuComponent } from "../assets/ts/components";
import { SESSION, SET_STORAGE } from "../../utils/constants";
import {
  getLocalStorage,
  setLocalStorage,
  getLastInteractionTime,
  setLastInteractionTime,
  addEvents,
  removeEvents,
  headersWithAuth
} from "../../utils/helper";
import { setTimeout } from "timers";

interface MasterLayoutOptions {
  children?: React.ReactNode | React.ReactNode[]
  continueSession?: any;
  setTimerDispatch?: any;
  getSiteconfigDispatch?: any;
  siteConfigs?: any;
  statusGSC?: any;
  loadingGSC?: any;
}

const MasterLayout: React.FC<MasterLayoutOptions> = ({
  children,
  getSiteconfigDispatch,
  siteConfigs,
}) => {
  const location = useLocation();
  const didMount = React.useRef(false);
  const isAuthorized = getLocalStorage(SESSION.TOKEN);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
      const param = {
        null: null
      }
      getSiteconfigDispatch(param, headersWithAuth)
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  const [counter, setCounter] = React.useState<any>(0);

  const eventHandler = () => {
    if (isAuthorized) {
      setLastInteractionTime(SESSION.LAST_INTERACTION_TIME, moment());
    }
  };

  const lastInteractionTime = getLastInteractionTime(
    SESSION.LAST_INTERACTION_TIME
  );

  const locate = location.pathname

  useEffect(() => {
    let now = moment(new Date());
    let end = moment(lastInteractionTime);
    let duration = moment.duration(now.diff(end));
    let timeInteractionMins = duration.asMinutes();
    const getIdleTimeOut = siteConfigs && siteConfigs.sessionTimeOut ?
      siteConfigs.sessionTimeOut : SESSION.IDLE_TIME;
    const checkSession = Math.floor(timeInteractionMins) >= getIdleTimeOut;
    // const fifteenMinsInsecs = 900000
    if (checkSession) {
      setLocalStorage(SET_STORAGE.LAST_LOCATION, locate)
      window.location.href = "/session-timeout";
    } else {
      setTimeout(() => {
        setCounter((val: any) => val + 60);
      }, 60000);
    }
  }, [counter]);

  useEffect(() => {
    addEvents(eventHandler);
    return () => {
      removeEvents(eventHandler);
    };
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    }
  });

  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <HeaderWrapper />
          <div
            id="kt_content"
            className="content d-flex flex-column flex-column-fluid"
          >
            <Toolbar />
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}
      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  );
};

const mapStateToProps = (state) => {
  const { loginStore, siteConfigStore } = state;
  return {
    continueSession:
      loginStore && loginStore.continueSession
        ? loginStore.continueSession
        : false,
    siteConfigs:
      siteConfigStore &&
        siteConfigStore.siteConfigs
        ? siteConfigStore.siteConfigs
        : {}
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTimerDispatch: (data) => dispatch(LoginActions.setTimer(data)),
  getSiteconfigDispatch: (params, headers) => dispatch(siteConfigActions.getSiteConfig(params, headers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MasterLayout);
