import {
  USER_ERROR,
  USER_MANAGEMENT_ERROR,
  REGEX,
  dynamicError,
} from "../../../../../utils/constants";

export const emailTemplateValidation = (
  values,
  setErrors,
  othersNotificationType
) => {
  const errors = {};
  if (!values.notificationType) {
    errors.notificationType = USER_ERROR.NOT_TYPE;
  }
  if (!values.subject) {
    errors.subject = USER_ERROR.SUBJECT;
  }
  if (!values.emailTemplate) {
    errors.emailTemplate = USER_ERROR.EMAIL_TEMPLATE;
  }
  if (values.notificationType === "others" && !othersNotificationType) {
    errors.othersNotificationType =
      USER_ERROR.OTHERS_NOTIFICATION_TYPE_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const Validation = (values, setErrors, editMode) => {
  const errors = {};
  if (!values.userName) {
    errors.userName = USER_ERROR.USER_NAME;
  }
  if (!values.smtpHostAddress) {
    errors.smtpHostAddress = USER_ERROR.SMTP_HOST;
  }
  if (!values.fromEmail) {
    errors.fromEmail = USER_MANAGEMENT_ERROR.USER_EMAIL;
  }
  if (values.fromEmail && !REGEX.EMAIL.test(values.fromEmail)) {
    errors.fromEmail = USER_ERROR.EMAIL_INVALID;
  }
  if (values.fromEmail) {
    const getEmailName = values.fromEmail.split("/");
    const emailName = getEmailName && getEmailName[1];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.fromEmail = USER_ERROR.EMAIL_INVALID;
    }
  }
  if (!editMode && !values.password) {
    errors.password = USER_ERROR.PASSWORD_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const ValidationDays = (values, setErrors) => {
  const errors = {};
  if (!values.remainderDay) {
    errors.remainderDay = USER_ERROR.REMAINDER_DAY;
  }
  if (!values.remainderEndDay) {
    errors.remainderEndDay = USER_ERROR.REMAINDER_END_DAY;
  }
  setErrors(errors);
  return errors;
};
export const validationImap = (values, setErrors, editMode) => {
  const errors = {};
  if (!values.imap) {
    errors.imap = USER_ERROR.IMAP_ERR;
  }
  if (!values.imapUserName) {
    errors.imapUserName = USER_ERROR.USER_NAME;
  }
  if (!values.imapHostAddress) {
    errors.imapHostAddress = USER_ERROR.IMAP_HOST_REQUIRED;
  }
  if (!editMode && !values.imapPassword) {
    errors.imapPassword = USER_MANAGEMENT_ERROR.USER_PASSWORD;
  }
  if (!values.imap) {
    errors.imap = USER_ERROR.IMAP_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const settingValidation = (values, setErrors) => {
  const errors = {};
  if (!values.remainderDay) {
    errors.remainderDay = USER_ERROR.REMAINDER_DAY;
  }
  if (!values.expiryDay) {
    errors.expiryDay = USER_ERROR.REMAINDER_DAY;
  }
  if (!values.remainderEndDay) {
    errors.remainderEndDay = USER_ERROR.REMAINDER_END_DAY;
  }
  setErrors(errors);
  return errors;
};

export const emailValidation = (values, setErrors) => {
  const errors = {};
  if (!values.errorNotificationEmails) {
    errors.errorNotificationEmails = USER_ERROR.EMAIL_ID;
  }
  setErrors(errors);
  return errors;
};
