const color = {
  tangerine: '#00a2ff',
  peach: '#00a2ff',
  redSand: '#00a2ff',
  black: '#000000',
  gray: 'gray',
  white: '#ffffff',
  bgGray: '#f8fafb',
  outerSpace: '#414a4c',
  ebony: '#1b1b1b',
  purple: '#800080',
  iris: '#5D3FD3',
  lightPurple: '#CF9FFF',
  violet: '#89769f'
}

export default color
