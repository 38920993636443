import React, { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { Link, useHistory } from 'react-router-dom'
import Dropzone, { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { FILE_CB_DOCUMENT, STATUS_RESPONSE, DROPZONE_MESSAGES, SESSION } from '../../../utils/constants'
import { warningAlert, confirmAlert } from '../../../utils/alerts'
import { validate } from './validation'
import { clientActions, addUploadsCBActions } from '../../../store/actions'
import {
  headersWithAuth,
  headerWithApiKey,
  getLocalStorage,
} from "../../../utils/helper";
import _ from 'lodash'

const StyledDiv = styled.div`
    .container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-style: dashed;
        background-color: #fafafa;
        color:#9ab6d9;
        outline: none;
        transition: border .24s ease-in-out;
    }
`
const fileHeaders = [
  { label: 'DBA Name', key: 'DBAName' },
  { label: 'Acquirer Name', key: 'acquirerName' },
  { label: 'Affiliate Id', key: 'affiliateId' },
  { label: 'ARN', key: 'arn' },
  { label: 'Authorization Code', key: 'authCode' },
  { label: 'Card Network', key: 'cardNetwork' },
  { label: 'Chargeback Reason', key: 'chargebackReason' },
  { label: 'AVS Match', key: 'avs' },
  { label: 'Blacklisted', key: 'blacklisted' },
  { label: 'Card Country', key: 'cardCountry' },
  { label: 'Card Bin', key: 'cardBin' },
  { label: 'Credit Card Last Four', key: 'cardLastFour' },
  { label: 'Card Type', key: 'cardType' },
  { label: 'Case Id', key: 'caseId' },
  { label: 'Chargeback Amount', key: 'chargebackAmount' },
  { label: 'Chargeback Currency', key: 'chargebackCurrency' },
  { label: 'Client', key: 'client' },
  { label: 'Company Name', key: 'companyName' },
  { label: 'Country', key: 'country' },
  { label: 'Customer Email', key: 'customerEmail' },
  { label: 'Customer IP', key: 'customerIP' },
  { label: 'Customer Name', key: 'customerName' },
  { label: 'Customer Phone', key: 'customerPhone' },
  { label: 'Dispute Filed Date', key: 'disputeFiledDate' },
  { label: 'Dispute Method', key: 'disputeMethod' },
  { label: 'Merchant Name', key: 'merchantName' },
  { label: 'MID', key: 'mid' },
  { label: 'Non Us', key: 'nonUs' },
  { label: 'Order Id', key: 'orderId' },
  { label: 'Transaction Id', key: 'transactionId' },
  { label: 'Payment Method', key: 'paymentMethod' },
  { label: 'Payment Processor', key: 'paymentProcessor' },
  { label: 'Reason For Legitimate Chargeback', key: 'reasonForLegitimateChargeback' },
  { label: 'Refund Issued', key: 'refundIssued' },
  { label: 'Transaction Amount', key: 'transactionAmount' },
  { label: 'Transaction Date', key: 'transactionDate' },
  { label: 'Service Type', key: 'serviceType' },
]

const csvData = [
  {
    "DBAName": "Hybrid",
    "acquirerName": "Shenll",
    "affiliateId": "H009123AB123",
    "arn": "ARNH009",
    "authCode": "Missing refund or credit",
    "cardNetwork": "Paypal",
    "chargebackReason": "Missing refund or credit",
    "avs": "Yes",
    "blacklisted": "No",
    "cardCountry": "United States",
    "cardBin": 41111111,
    "cardLastFour": 1234,
    "cardType": "Credit Card",
    "caseId": "IRM2534",
    "chargebackAmount": 28,
    "chargebackCurrency": "USD",
    "client": "System institute",
    "companyName": "System institute",
    "country": "United States",
    "customerEmail": "sundar.shenll@gmail.com",
    "customerIP": "49.125.242.25",
    "customerName": "Sundar",
    "customerPhone": 9003521833,
    "disputeFiledDate": "2022-03-10",
    "disputeMethod": "Online",
    "merchantName": "jackson",
    "mid": ('51948e86c25f4b1d1c0d303c'),
    "nonUs": "",
    "orderId": "AMD121",
    "transactionId": "T454501",
    "paymentMethod": "Online",
    "paymentProcessor": "Square",
    "reasonForLegitimateChargeback": "Fraud",
    "refundIssued": "No",
    "transactionAmount": 28,
    "transactionDate": "2022-09-02T18:20:29.000+00:00",
    "serviceType": "Digital Service or Product"
  },
  {
    "DBAName": "Keystone",
    "acquirerName": "Shenll",
    "affiliateId": "K1009CG54879",
    "arn": "ARNK1009",
    "authCode": 4513,
    "cardNetwork": "Amex",
    "chargebackReason": "Credit ROC Not Presented",
    "avs": "Yes",
    "blacklisted": "No",
    "cardCountry": "United States",
    "cardBin": 641541,
    "cardLastFour": 5263,
    "cardType": "Credit Card",
    "caseId": "IRM26523",
    "chargebackAmount": 32,
    "chargebackCurrency": "USD",
    "client": "System institute",
    "companyName": "System institute",
    "country": "United States",
    "customerEmail": "ajay.shenll@gmail.com",
    "customerIP": "152.168.147.252",
    "customerName": "Ajay",
    "customerPhone": 9942422252,
    "disputeFiledDate": "2022-03-11",
    "disputeMethod": "Online",
    "merchantName": "jackson",
    "mid": ('51948e86c25f4b1d1c0d303c'),
    "nonUs": "",
    "orderId": "AMD122",
    "transactionId": "T454502",
    "paymentMethod": "Online",
    "paymentProcessor": "Square",
    "reasonForLegitimateChargeback": "Fraud",
    "refundIssued": "No",
    "transactionAmount": 32,
    "transactionDate": "2022-09-02T18:20:29.000+00:00",
    "serviceType": "Digital Service or Product"
  },
  {
    "DBAName": "OneStepMove",
    "acquirerName": "Shenll",
    "affiliateId": "OSM85241769",
    "arn": "ARNOSM852",
    "authCode": "8002/RN2",
    "cardNetwork": "Discover",
    "chargebackReason": "Credit not processed / Credit not received - CB",
    "avs": "Yes",
    "blacklisted": "No",
    "cardCountry": "United States",
    "cardBin": 601111,
    "cardLastFour": 8796,
    "cardType": "Credit Card",
    "caseId": "IRM58976",
    "chargebackAmount": 16,
    "chargebackCurrency": "USD",
    "client": "System institute",
    "companyName": "System institute",
    "country": "United States",
    "customerEmail": "sundar.shenll@gmail.com",
    "customerIP": "10.15.26.54",
    "customerName": "Sundar",
    "customerPhone": 9003521833,
    "disputeFiledDate": "2022-09-02T18:20:29.000+00:00",
    "disputeMethod": "Online",
    "merchantName": "jackson",
    "mid": ('51948e86c25f4b1d1c0d303c'),
    "nonUs": "",
    "orderId": "AMD123",
    "transactionId": "T454503",
    "paymentMethod": "Online",
    "paymentProcessor": "Square",
    "reasonForLegitimateChargeback": "Fraud",
    "refundIssued": "No",
    "transactionAmount": 16,
    "transactionDate": "2022-03-01",
    "serviceType": "Digital Service or Product"
  },
  {
    "DBAName": "Jerry",
    "acquirerName": "Shenll",
    "affiliateId": "JY25145263933",
    "arn": "JYARN2514",
    "authCode": 4853,
    "cardNetwork": "Master",
    "chargebackReason": "Cardholder disputes - Credit not processed",
    "avs": "Yes",
    "blacklisted": "No",
    "cardCountry": "United States",
    "cardBin": 22210000,
    "cardLastFour": 1006,
    "cardType": "Credit Card",
    "caseId": "IRM369258",
    "chargebackAmount": 52,
    "chargebackCurrency": "USD",
    "client": "System institute",
    "companyName": "System institute",
    "country": "United States",
    "customerEmail": "ajay.shenll@gmail.com",
    "customerIP": "184.236.235.22",
    "customerName": "Ajay",
    "customerPhone": 9942422252,
    "disputeFiledDate": "2022-03-13",
    "disputeMethod": "Offline",
    "merchantName": "jackson",
    "mid": ('51948e86c25f4b1d1c0d303c'),
    "nonUs": "",
    "orderId": "AMD124",
    "transactionId": "T454504",
    "paymentMethod": "Online",
    "paymentProcessor": "Square",
    "reasonForLegitimateChargeback": "Fraud",
    "refundIssued": "No",
    "transactionAmount": 52,
    "transactionDate": "2022-09-02T18:20:29.000+00:00",
    "serviceType": "Digital Service or Product"
  }
]


const UploadChargeback = (props) => {
  const {
    addUploadsCBDispatch,
    clearaddUploadsCBDispatch,
    statusAUCB,
    messageAUCB,
    loadingAUCB,
  } = props

  const history = useHistory()
  const [errors, setErrors] = useState({})
  const { getRootProps } = useDropzone()
  const isAuthorized = getLocalStorage(SESSION.TOKEN);

  const [formData, setFormData] = useState({
    companyName: '',
    merchantId: '',
    fileUpload: null,
    midUpload: null,
    atUpload: '',
    fileName: ''
  })

  const handleSubmit = () => {
    const errorMsg = validate(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      addUploadsCBDispatch(formData.fileUpload, null, isAuthorized ? headersWithAuth : headerWithApiKey)
    }
  }

  const handleFileUpload = (files, name) => {
    setErrors({ ...errors, [name]: '' })
    if (name === 'fileUpload') {
      validate(formData, setErrors)
    }
    const maxFileSize = 5
    let isValidFileFormat = false
    let currentFileName = ''
    const data = new FormData()
    files.forEach((item) => {
      const fileType = item && item.type
      const uploadedFileSize = item && item.size
      currentFileName = item && item.name
      isValidFileFormat = _.includes(FILE_CB_DOCUMENT, fileType)
      const fileSize = Number(maxFileSize) * 1024 * 1024
      if (isValidFileFormat) {
        if (uploadedFileSize < fileSize) {
          setErrors({ ...errors, [name]: '' })
          data.append('file', item)
        } else {
          setErrors({
            ...errors,
            [name]:
              `File size must below ${fileSize / 1048576
              } MB!. You file size is : ${Math.round(uploadedFileSize / 1024)}KB`
          })
        }
      } else {
        setErrors({ ...errors, [name]: DROPZONE_MESSAGES.DOCUMENT_INVALID })
      }
    })
    if (isValidFileFormat) {
      setFormData((values) => ({
        ...values,
        [name]: data,
        fileName: currentFileName
      }))
    }
  }

  const onConfirm = () => {
    history.push('/chargeback-management')
  }

  useEffect(() => {
    if (statusAUCB === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAUCB,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearaddUploadsCBDispatch()
    } else if (statusAUCB === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'Error',
        messageAUCB,
        'error',
        'Close',
        'Ok',
        () => {
          onConfirm()
        },
        () => { }
      )
      clearaddUploadsCBDispatch()
    }
  }, [statusAUCB])

  return (
    <>
      <div className='card-header bg-skyBlue py-10'>
        <div className='card-body'>
          <div className='row my-7'>
            <div className='col-lg-4 text-end'>
              <label className='font-size-xs col-lg-6 col-form-label'>Upload File:</label>
            </div>
            <div className='col-lg-8'>
              <div className='col-lg-6'>
                <StyledDiv {...getRootProps({ refKey: 'innerRef' })}>
                  <Dropzone onDrop={acceptedFiles => handleFileUpload(acceptedFiles, 'fileUpload')}>
                    {({ getRootProps, getInputProps }) => (
                      <div className='container'>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name='fileUpload' />
                          <p className='text-primary fw-bolder fs-6'>Click here to select files</p>
                          <aside>
                            <h4 className='text-dark fw-bolder fs-5 mt-6'>Files</h4>
                            {formData && formData.fileName
                              ? (
                                <div>{formData.fileName}</div>
                              )
                              : null}
                          </aside>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDiv>
                {errors && errors.fileUpload && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.fileUpload}</div>)}
              </div>
              <div className='col-lg-6 mt-6 d-flex justify-content-end'>
                <Link
                  to='/chargeback-management/select-chargeback'
                  className='btn btn-sm btn-darkRed m-1 fa-pull-right'>
                  Cancel
                </Link>
                {csvData?.length &&
                  <CSVLink
                    headers={fileHeaders}
                    data={csvData}
                    filename='Sample_file.csv'
                    target='_blank'
                    className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm m-1'
                  >
                    Download Sample File
                  </CSVLink>}
                <button
                  className='btn btn-blue btn-sm m-1'
                  onClick={handleSubmit}
                >
                  {
                    loadingAUCB
                      ? (
                        <span className='spinner-border spinner-border-sm mx-5' role='status' aria-hidden='true' />
                      )
                      : 'Export Chargeback'
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  getClient: state && state.clientStore && state.clientStore.getClient &&
    state.clientStore.getClient.data
    ? state.clientStore.getClient.data.result
    : null,
  dataAUCB: state && state.addUploadsCBStore && state.addUploadsCBStore.dataAUCB,
  statusAUCB: state && state.addUploadsCBStore && state.addUploadsCBStore.statusAUCB,
  loadingAUCB: state && state.addUploadsCBStore && state.addUploadsCBStore.loadingAUCB,
  messageAUCB: state && state.addUploadsCBStore && state.addUploadsCBStore.messageAUCB
})

const mapDispatchToProps = dispatch => ({
  getClientDispatch: () => dispatch(clientActions.getClient()),
  addUploadsCBDispatch: (params, url, headers) => dispatch(addUploadsCBActions.addUploadsCB(params, url, headers)),
  clearaddUploadsCBDispatch: () => dispatch(addUploadsCBActions.clearaddUploadsCB())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadChargeback)
