import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import ShowFields from "../../ShowFields";
import { Stepper } from "../../../../../theme/layout/components/stepper/Stepper";
import {
  CRM_FORM,
  SET_STORAGE,
  SESSION,
  API,
} from "../../../../../utils/constants";
import {
  setLocalStorage,
  getLocalStorage,
  headersWithAuth,
  headerWithApiKey,
} from "../../../../../utils/helper";
import { CRMStep1, CRMStep2, CRMStep3 } from "./CRMSteps";

function CRMStep(props) {
  const { setConnectedStatus } = props;
  const [completedSteps, setCompletedSteps] = useState([-1]);
  const [activeStep, setActiveStep] = useState(0);
  const [array, setArray] = useState([]);
  const active = getLocalStorage(CRM_FORM.ACTIVE_STEP);
  const stepperArray = [
    {
      stepperLabel: "Connect CRM",
      Optional: "",
    },
    {
      stepperLabel: "Login to Shopify",
      Optional: "",
    },
    {
      stepperLabel: "Login to Portal",
      Optional: "",
    },
  ];

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const redirectURL = getLocalStorage(API.API_KEY);

  const userType = getLocalStorage(SET_STORAGE.SET_USER_ROLE);
  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update");
  const id = url && url[1];
  const urlName = pathName && pathName.split("update/");
  const clientId = urlName && urlName[1];

  const onClickNext = (currentId) => {
    const id = currentId - 1;
    setActiveStep(currentId);
    if (completedSteps.length === currentId) {
      if (completedSteps && !completedSteps.includes(id)) {
        setCompletedSteps((values) => [...values, id]);
        setLocalStorage(CRM_FORM.ACTIVE_STEP, currentId);
      }
    }
  };

  const goBack = (id) => {
    setActiveStep(id);
  };

  const getDescendants = (arr, step) => {
    const val = [];
    arr.forEach((item, i) => {
      if (step > i) {
        val.push(i);
      }
    });
    return val;
  };

  const d = getDescendants(array, active);

  useEffect(() => {
    if (active) {
      setActiveStep(Number(active));
      setCompletedSteps([-1, ...d]);
    } else {
      setActiveStep(0);
      setCompletedSteps([-1]);
    }
  }, [active]);

  useEffect(() => {
    setArray(stepperArray);
  }, []);

  return (
    <>
      <Stepper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        completedSteps={completedSteps}
        stepperArr={array}
        setCompletedSteps={setCompletedSteps}
        onStepperClick={onClickNext}
      />
      <ShowFields>
        {activeStep === 0 ? (
          <CRMStep1 onClickNext={onClickNext} goBack={goBack} />
        ) : null}
        {activeStep === 1 ? (
          <CRMStep2 onClickNext={onClickNext} goBack={goBack} />
        ) : null}
        {activeStep === 2 ? (
          <CRMStep3
            onClickNext={onClickNext}
            goBack={goBack}
            setConnectedStatus={setConnectedStatus}
          />
        ) : null}
      </ShowFields>
    </>
  );
}

export default CRMStep;
