import {
  addApiKeyTypes,
  apiKeyActionsTypes
} from '../actions'

export const addApiKeyInitialState = {
  list: null
}
export const addApiKeyStoreKey = 'addApiKeyStore'

export const addApiKeyReducer = (state = addApiKeyInitialState, action) => {
  switch (action.type) {
    case addApiKeyTypes.REQUEST:
      return { ...state, loadingAAK: true }
    case addApiKeyTypes.RESPONSE:
      return { ...state, dataAAK: action.data, statusAAK: action.data.status, messageAAK: action.data.message, loadingAAK: false }
    case addApiKeyTypes.CLEAR:
      return { ...state, dataAAK: null, messageAAK: '', statusAAK: '' }
    default:
      return state
  }
}

export const apiKeyInitialState = {
  list: null
}

export const apiKeyStoreKey = 'apiKeyStore'

export const apiKeyReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case apiKeyActionsTypes.REQUEST:
      return { ...state, loading: true }
    case apiKeyActionsTypes.RESPONSE:
      return { ...state, getApiKey: res.data, statusGAK: res.status, messageGAK: res.message, loading: false }
    case apiKeyActionsTypes.CLEAR:
      return { ...state, getApiKey: null, statusGAK: '', messageGAK: '' }
    default:
      return state
  }
}
