import _ from "lodash";

const getDefaultOptions = (rawData) => {
  const defaultOptions = [];
  for (const item in rawData) {
    defaultOptions.push({
      label: rawData[item].name,
      value: rawData[item]._id,
    });
  }
  return defaultOptions;
};

const getDefaultRoleOptions = (rawData) => {
  const defaultOptions = [];
  for (const item in rawData) {
    defaultOptions.push({
      label: rawData[item].role,
      value: rawData[item]._id,
    });
  }
  return defaultOptions;
};

export const setAddressDetails = (
  getCountrys,
  getStates,
  getCitys,
  selCountry,
  selState,
  selCity,
  setSelectedCountryOption,
  setSelectedStateOption,
  setSelectedCityOption
) => {
  if (selCountry) {
    const country = getDefaultOptions(getCountrys);
    const selOption = _.filter(country, function (x) {
      if (_.includes(selCountry, x.value)) {
        return x;
      }
    });
    if (selOption.length > 0) {
      setSelectedCountryOption(selOption);
    }
  }
  if (selState) {
    const state = getDefaultOptions(getStates);
    const selOption = _.filter(state, function (x) {
      if (_.includes(selState, x.value)) {
        return x;
      }
    });
    if (selOption.length > 0) {
      setSelectedStateOption(selOption);
    }
  }
  if (selCity) {
    const city = getDefaultOptions(getCitys);
    const selOption = _.filter(city, function (x) {
      if (_.includes(selCity, x.value)) {
        return x;
      }
    });
    if (selOption.length > 0) {
      setSelectedCityOption(selOption);
    }
  }
};

export const getCrmLables = (tableData) => {
  const value = tableData.map(({ crmLabel }) => ({
    crmLabel,
  }));
  return value;
};
export const getProcessorLabels = (processorTableData) => {
  const value = processorTableData.map(({ processorLabel }) => ({
    processorLabel,
  }));
  return value;
};
export const getPaymentLabels = (paymentTableData) => {
  const value = paymentTableData.map(({ paymentGatewayLabel }) => ({
    paymentGatewayLabel,
  }));
  return value;
};

export const getAdressValues = (getValues, selected) => {
  if (selected) {
    const value = getDefaultOptions(getValues);
    const selOption = _.filter(value, function (x) {
      if (_.includes(selected, x.value)) {
        return x;
      }
    });
    if (selOption.length > 0) {
      return selOption[0];
    }
  }
};

export const getRoleValues = (getValues, selected) => {
  if (selected) {
    const value = getDefaultRoleOptions(getValues);
    const selOption = _.filter(value, function (x) {
      if (_.includes(selected, x.value)) {
        return x;
      }
    });
    if (selOption.length > 0) {
      return selOption[0];
    }
  }
};

export const handleTrimWhiteSpace = (e, setState) => {
  const { name, value } = e.target;
  if (value && value.length > 1) {
    const getData = value.replace(/ +/g, " ");
    setState((values) => ({ ...values, [name]: getData.trim() }));
  } else {
    setState((values) => ({ ...values, [name]: "" }));
  }
};

export const handleTrimSpaceOnly = (e, setState) => {
  const { name, value } = e.target;
  const getData = value.replace(/ +/g, " ");
  setState((values) => ({ ...values, [name]: getData.trim() }));
};
