import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap-v5";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { setLocalStorage, getLocalStorage } from "../../../utils/helper";
import { SHOPIFY_STORAGE } from "../../../utils/constants";
import bankLogo from "./../../../assets/bank.png";
import { relativeTimeRounding } from "moment";

export const Container = styled.div`
  background-color: #e4e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
`;

export const CardContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 2px solid #ddd;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  border-color: ${({ isSelected }) => (isSelected ? "#a2c84a" : "#ddd")};

  &:active {
    border-color: #a2c84a;
  }
`;

export const ImgLogo = styled.img`
  width: 60%;
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const CardDetails = styled.div`
  display: flex;
  gap: 60px;
  margin-top: 40px;
`;

export const CardStats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImgMap = styled.img`
  width: 60%;
`;

export const ToggleItem = styled.div`
  display: flex;
  align-items: center;
`;

export const ImgToggle = styled.img`
  width: 20%;
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`;
const StyledBootstrapSwitchButton = styled(BootstrapSwitchButton)`
  width: 30;
  margin-left: 24px;
  margin-right: 24px;
`;

const ShopifyPrevention = (props) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [formData, setFormData] = useState({
    order: false,
    rdr: false,
    cdrn: false,
    ethoca: false,
    chg: false,
    perchg: false,
    hybrid: false,
  });

  console.log(
    getLocalStorage(SHOPIFY_STORAGE.PROCESSOR),
    " shopify storage props ",
    getLocalStorage(SHOPIFY_STORAGE.SERVICE_TYPE),
    " service type ",
    getLocalStorage(SHOPIFY_STORAGE.PLAN_TYPE),
    " plan type "
  );

  const handleSubmit = (e) => {
    console.log("Hello good one");
    setLocalStorage(SHOPIFY_STORAGE.ALL_PLAN_TYPE, formData);
    console.log(
      getLocalStorage(SHOPIFY_STORAGE.PROCESSOR),
      " shopify storage props ",
      getLocalStorage(SHOPIFY_STORAGE.SERVICE_TYPE),
      " service type ",
      getLocalStorage(SHOPIFY_STORAGE.PLAN_TYPE),
      " plan type ",
      JSON.stringify(getLocalStorage(SHOPIFY_STORAGE.ALL_PLAN_TYPE, null, 4)),
      " all plan type ",
      JSON.stringify(
        getLocalStorage(SHOPIFY_STORAGE.SHP_ACCESS_TOKEN, null, 4)
      ),
      " access token "
    );

    const shop = getLocalStorage(SHOPIFY_STORAGE.SHOP_NAME);
    const shp_hmac = getLocalStorage(SHOPIFY_STORAGE.SHP_HMAC);
    const shp_access_token = getLocalStorage(SHOPIFY_STORAGE.SHP_ACCESS_TOKEN);
    const shp_service_url = getLocalStorage(SHOPIFY_STORAGE.SHP_SERVICE_URL);
    console.log(
      `${shp_service_url}/billing?shop=${shop}&host=${shp_service_url}&fromportal=1`
    );
    // return false;
    window.location.href = `${shp_service_url}/billing?shop=${shop}&access_token=${shp_access_token}&shp_hmac=${shp_hmac}&shp_service_url=${shp_service_url}&fromportal=1`;
    e.preventDefault();
  };

  const handleChange = (e) => {
    e.persist();
    const { name, checked, value } = e.target;
    console.log(name, checked, value, " name checked value ");
    setFormData((values) => ({ ...values, [e.target.name]: e.target.checked }));
    // const checked = e.target.name;
    // console.log(formData.checked ? "good" : "bad");
    // return false;

    // setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    // console.log("Hello good one");
  };

  return (
    <Container>
      <div>
        <TextCenter as="h1">
          What's the best way to fight a chargeback? Prevent it from ever
          happening.
        </TextCenter>
        <CardContainer>
          <Card
            isSelected={selectedCard === 0}
            onClick={(state) => setSelectedCard(!state)}
          >
            <Paragraph>$0.05 / ORDER</Paragraph>
            <ImgLogo src={bankLogo} alt="" />
            <Paragraph>
              Yes add fraud and order <br />
              unrecieved preventions
            </Paragraph>
          </Card>
        </CardContainer>
      </div>

      <Row>
        <Col md={6} lg={4} className="mb-4">
          <ImgMap src="map.jpeg" alt="" />
          <h4>Fulfillment Dashboard</h4>
          {/* <TextCenter as={Paragraph}>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley
          </TextCenter> */}
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <ImgMap src="map.jpeg" alt="" />
          <h4>More Accurate Fraud Detection</h4>
          {/* <TextCenter as={Paragraph}>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley
          </TextCenter> */}
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <div className="toggle-text">
            <div className="form-check form-switch">
              <h3>PREVENTION</h3>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="order"
                onClick={handleChange}
              />
              <label className="form-check-label" for="order">
                ORDER INSIGHTS
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input "
                type="checkbox"
                role="switch"
                name="rdr"
                onClick={handleChange}
              />
              <label className="form-check-label" for="rdr">
                RDR
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="cdrn"
                onClick={handleChange}
              />
              <label className="form-check-label" for="cdrn">
                CDRN
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="ethoca"
                onClick={handleChange}
              />
              <label className="form-check-label" for="ethoca">
                ETHOCA ALERTS
              </label>
            </div>
            <div>
              <h3>CHARGEBACKS</h3>
            </div>

            {formData.perchg === false && formData.hybrid === false && (
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="chg"
                  onClick={handleChange}
                />
                <label className="form-check-label" for="chg">
                  25% SUCCESS IN CHARGEBACK
                </label>
              </div>
            )}
            {formData.chg === false && formData.hybrid === false && (
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="perchg"
                  onClick={handleChange}
                />
                <label className="form-check-label" for="perchg">
                  PER CHARGEBACK
                </label>
              </div>
            )}
            {formData.chg === false && formData.perchg === false && (
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="hybrid"
                  onClick={handleChange}
                />
                <label className="form-check-label" for="hybrid">
                  HYBRID
                </label>
              </div>
            )}
          </div>
          <br />
          <h4>Fulfillment Notifications</h4>
          {/* <TextCenter as={Paragraph}>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley
          </TextCenter> */}
        </Col>
        <div className="form-group row mb-4 mt-15">
          <div className="text-center">
            <Link
              to={`/shopify-subscription`}
              className="btn btn-sm m-2 w-120px fw-bolder text-white h-40px"
              style={{ backgroundColor: "#f56c61" }}
            >
              Previous
            </Link>
            <Link
              className="btn btn-sm fw-bolder btn-primary m-2 w-120px h-40px"
              onClick={(event) => {
                handleSubmit(event);
              }}
            >
              Approve
            </Link>
          </div>
        </div>
        {JSON.stringify(formData)}
        {formData.chg}
      </Row>
    </Container>
  );
};

export default ShopifyPrevention;
