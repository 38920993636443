import {
  preventionAlertActionsTypes,
  preventionAlertGetDetailsTypes,
  editAlertsTypes,
  outcomeAlertActionsTypes,
  refundAlertActionsTypes
} from '../actions'

export const preventionInitialState = {
  list: null
}

export const preventionStoreKey = 'preventionStore'

export const preventionReducer = (state = preventionInitialState, action) => {
  switch (action.type) {
    case preventionAlertActionsTypes.GET_PREVENTIONALERT:
      return { ...state, loadingPA: true }
    case preventionAlertActionsTypes.SAVE_PREVENTIONALERT_RESPONSE:
      return {
        ...state,
        preventionAlertList: action.data && action.data.data,
        statusPA: action.data && action.data.status,
        messagesPA: action.data && action.data.message,
        count: action.data && action.data.count,
        loadingPA: false
      }
    case preventionAlertActionsTypes.CLEAR_PREVENTIONALERT:
      return { ...state, preventionAlertList: null, statusPA: '', messagesPA: '' }

    case preventionAlertGetDetailsTypes.GET_PREVENTION_ALERT_DETAILS:
      return { ...state, loadingPAD: true }
    case preventionAlertGetDetailsTypes.PREVENTION_ALERT_DETAILS_RESPONSE:
      return {
        ...state,
        preventionAlerDetails: action.data && action.data.data,
        statusPAD: action.data && action.data.status,
        messagesPAD: action.data && action.data.message,
        loadingPAD: false
      }
    case preventionAlertGetDetailsTypes.CLEAR_PREVENTION_ALERT_DETAILS:
      return { ...state, statusPAD: '', messagesPAD: '' }
    default:
      return state
  }
}

export const editAlertsStoreKey = 'editAlertsStore'

export const editAlertsReducer = (state = {}, action) => {
  switch (action.type) {
    case editAlertsTypes.REQUEST:
      return { ...state, loadingEA: true }
    case editAlertsTypes.RESPONSE:
      return { ...state, dataEA: action.data, statusEA: action.data.status, messageEA: action.data.message, loadingEA: false }
    case editAlertsTypes.CLEAR:
      return { ...state, dataEA: null, messageEA: '', statusEA: '' }
    default:
      return state
  }
}

export const outcomeInitialState = {
  list: null
}

export const outcomeStoreKey = 'outcomeStore'

export const outcomeReducer = (state = outcomeInitialState, action) => {
  switch (action.type) {
    case outcomeAlertActionsTypes.GET_OUTCOME_ALERT:
      return { ...state, loadingPOA: true }
    case outcomeAlertActionsTypes.SAVE_OUTCOME_ALERT_RESPONSE:
      return {
        ...state,
        outcomeAlertList: action.data && action.data.data ? action.data.data : [],
        statusPOA: action.data && action.data.status,
        messagesPOA: action.data && action.data.message,
        count: action.data && action.data.count,
        loadingPOA: false
      }
    case outcomeAlertActionsTypes.CLEAR_OUTCOME_ALERT:
      return { ...state, outcomeAlertList: null, statusPOA: '', messagesPOA: '' }
    default:
      return state
  }
}

export const refundInitialState = {
  list: null
}

export const refundStoreKey = 'refundStore'

export const refundReducer = (state = refundInitialState, action) => {
  switch (action.type) {
    case refundAlertActionsTypes.GET_REFUND_ALERT:
      return { ...state, loadingRPA: true }
    case refundAlertActionsTypes.SAVE_REFUND_ALERT_RESPONSE:
      return {
        ...state,
        refundAlertList: action.data && action.data.data ? action.data.data : [],
        statusRPA: action.data && action.data.status,
        messagesRPA: action.data && action.data.message,
        count: action.data && action.data.count,
        loadingRPA: false
      }
    case refundAlertActionsTypes.CLEAR_REFUND_ALERT:
      return { ...state, refundAlertList: null, statusRPA: '', messagesRPA: '' }
    default:
      return state
  }
}
