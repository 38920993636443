import {
  ShopifyConnectActionTypes,
} from '../actions'

export const ShopifyConnectStoreKey = 'ShopifyConnectStoreKey'

export const ShopifyConnectReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case ShopifyConnectActionTypes.SHOPIFY_CONNECT_INIT:
      return { ...state, isLoading: true }
    case ShopifyConnectActionTypes.SHOPIFY_CONNECT_SUCCESS:
      return { ...state, ShopifyConnectData: res && res.data, isLoading: false }
    case ShopifyConnectActionTypes.SHOPIFY_CONNECT_CLEAR:
      return { ...state, ShopifyConnectData: null, }
    default:
      return state
  }
}

