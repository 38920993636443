export const defaultExportCol = {
  issuerBankId: true,
  clientId: false,
  merchantId: false,
  partnerId: false,
  alertId: true,
  alertDate: true,
  alertType: true,
  notificationType: true,
  transactionType: true,
  upiTransactionId: true,
  cardFirstSixDigits: false,
  cardLastFourDigits: false,
  cardBin: true,
  expirationMonth: false,
  expirationYear: false,
  transactionId: true,
  customerFirstName: true,
  customerLastName: true,
  alertDescriptor: true,
  alertDescriptorContact: false,
  refundedBeforeTime: false,
  outputResolutionDate: false,
  expirationStatus: false,
  isLastSixMonths: false,
  provider: false,
  cardNumber: false,
  salesTransactionDate: false,
  salesTransactionAmount: false,
  salesTransactionCurrency: false,
  customerAddress: false,
  acquirerReferrenceNumber: false,
  assignedToId: false,
  authCode: false,
  blacklistCustomer: false,
  cardVendor: false,
  chargeBackId: false,
  chargeBackReasonCode: false,
  chargeBackSaleDate: false,
  chargeBackStatus: false,
  city: false,
  cityOfMerchant: false,
  cardType: false,
  deflected: false,
  updatedDeflectionstatus: false,
  updatedDeflectionDate: false,
  status: false
}
