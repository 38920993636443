export const addAssignUserActionsTypes = {
  REQUEST: 'ADD_ASSIGN_USER_REQUEST',
  RESPONSE: 'ADD_ASSIGN_USER_RESPONSE',
  ERROR: 'ADD_ASSIGN_USER_ERROR',
  CLEAR: 'ADD_ASSIGN_USER_CLEAR'
}

export const addAssignUserActions = {
  add: (data, headers) => {
    return {
      type: addAssignUserActionsTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addAssignUserActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addAssignUserActionsTypes.CLEAR
  })
}

export const assignUserActionsTypes = {
  GET_ASSIGN_USER: 'GET_ASSIGN_USER',
  SAVE_ASSIGN_USER_RESPONSE: 'SAVE_ASSIGN_USER_RESPONSE',
  CLEAR_ASSIGN_USER: 'CLEAR_ASSIGN_USER'
}

export const assignUserActions = {
  getAssignUser: (params, headers) => ({
    type: assignUserActionsTypes.GET_ASSIGN_USER,
    params, headers
  }),
  saveassignUserResponse: data => ({
    type: assignUserActionsTypes.SAVE_ASSIGN_USER_RESPONSE,
    data
  }),
  clearAssignUser: () => ({
    type: assignUserActionsTypes.CLEAR_ASSIGN_USER
  })
}

export const deleteAssignUserActionsTypes = {
  REQUEST: 'DELETE_ASSIGN_USER_REQUEST',
  RESPONSE: 'DELETE_ASSIGN_USER_RESPONSE',
  ERROR: 'DELETE_ASSIGN_USER_ERROR',
  CLEAR: 'DELETE_ASSIGN_USER_CLEAR'
}

export const deleteAssignUserActions = {
  delete: (id, headers) => ({
    type: deleteAssignUserActionsTypes.REQUEST,
    id, headers
  }),
  savemResponse: (data) => ({
    type: deleteAssignUserActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteAssignUserActionsTypes.CLEAR
  })
}
