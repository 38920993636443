import React, { useEffect, useState } from 'react'
import { userInfoValidation } from './validation'
import { useLocation, Link } from 'react-router-dom'
import { KTSVG } from '../../../../theme/helpers'
import { CRM_FORM, REGEX, STATUS_RESPONSE, SET_STORAGE, SESSION, API } from '../../../../utils/constants'
import { setLocalStorage, getLocalStorage, headersWithAuth } from '../../../../utils/helper'
import {
  userActions,
  userGetDetailsActions,
  editUserActions,
  userRoleActions
} from '../../../../store/actions'
import { getUserDetailsPayload } from '../functions/formData'
import { connect } from 'react-redux'
import AddUserInfo from './AddUserInfo'
import _, { add } from 'lodash'
import { confirmAlert, warningAlert } from '../../../../utils/alerts'
import ReactPaginate from 'react-paginate'

const Users = (props) => {
  const {
    loading,
    onClickNext,
    goBack,
    setClientDetails,
    getUserDispatch,
    getUser,
    userDetailsDispatch,
    editUsersDispatch,
    getUserDetails,
    clearUserDetailsDispatch,
    statusGUD,
    statusEUD,
    messageEUD,
    cleareditUserDispatch,
    getUserRoleDispatch,
    gerUserRole,
    loadingEUD,
    loadingGUD,
    clientId
  } = props

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }
  const [userId, setUserId] = useState()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    password: '',
    status: '',
    roleId: ''
  })
  const [errors, setErrors] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [setActivePageNumber] = useState(1)
  const [limit] = useState(25)
  const [addForm, setAddForm] = useState(false)
  const pathName = useLocation().pathname
  const url = pathName && pathName.split('update')
  const id = url && url[1]
  const userType = getLocalStorage(SET_STORAGE.SET_USER_ROLE);

  const handleChange = (e) => {
    e.persist()
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const handleSubmit = () => {
    const errorMsg = userInfoValidation(formData, setErrors)
    if (_.isEmpty(errorMsg)) {
      if (!editMode) {
        setClientDetails((values) => ({ ...values, user: formData }))
        setLocalStorage(CRM_FORM.USER_DETAILS, JSON.stringify(formData))
      }
    }
  }

  const handleNextClick = () => {
    const errorMsg = userInfoValidation(formData, setErrors)
    if (_.isEmpty(errorMsg)) {
      onClickNext(4)
    }
  }

  useEffect(() => {
    const localData = JSON.parse(getLocalStorage(CRM_FORM.USER_DETAILS))
    if (!_.isEmpty(localData)) {
      setFormData(localData)
    }
  }, [])

  const handleSorting = (name) => { }
  const handleRecordPerPage = (e) => { }

  useEffect(() => {
    if (id) {
      setEditMode(true)
      setShowForm(false)
      const params = {
        skipPagination: true
      }
      getUserRoleDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    } else {
      setEditMode(false)
    }
  }, [id])

  useEffect(() => {
    const params = {
      clientId: clientId
    }
    getUserDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
  }, [])

  useEffect(() => {
    if (statusGUD === STATUS_RESPONSE.SUCCESS_MSG) {
      if (getUserDetails && getUserDetails._id) {
        const getUser = getUserDetailsPayload(getUserDetails)
        setFormData(getUser)
        setUserId(getUserDetails._id)
      }
      clearUserDetailsDispatch()
    } else if (statusGUD === STATUS_RESPONSE.ERROR_MSG) {
      clearUserDetailsDispatch()
    }
  }, [statusGUD])

  const onConfirm = () => {
    setShowForm(false)
    const params = {
      clientId: clientId
    }
    getUserDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
  }

  useEffect(() => {
    if (statusEUD === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'Success',
        messageEUD,
        'success',
        'ok',
        () => { onConfirm() },
        () => { onConfirm() }
      )
      cleareditUserDispatch()
    } else if (statusEUD === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'error',
        messageEUD,
        '',
        'Cancel',
        'Ok',
        () => { },
        () => { }
      )
      cleareditUserDispatch()
    }
  }, [statusEUD, messageEUD])

  const handleSave = () => {
    const errorMsg = userInfoValidation(formData, setErrors)
    if (_.isEmpty(errorMsg)) {
      const payload = {
        ...formData
      }
      editUsersDispatch(userId, payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1
    const params = {
      limit: limit,
      page: pageNumber
    }
    setActivePageNumber(pageNumber)
    getUserDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
  }

  const totalPages =
    getUser && getUser.count
      ? Math.ceil(parseInt(getUser && getUser.count) / limit)
      : 1

  return (
    <>
      {showForm
        ? (
          <div className='card-header bg-skyBlue py-10'>
            <div className='card-body p-5'>
              <div className='row'>
                <div className='col-lg-6 my-auto'>
                  <h2 className='mb-5'>User Info</h2>
                </div>
                <div className='col-lg-6'>
                  <div className='d-flex justify-content-end'>
                    <button
                      className='btn btn-darkRed m-2 fa-pull-right'
                      onClick={() => {
                        setShowForm(false)
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
              <div className='card-header py-10'>
                <div className='card-body p-8'>
                  {loadingGUD
                    ? (
                      <div className='d-flex justify-content-center py-5'>
                        <div
                          className='spinner-border text-primary m-5'
                          role='status'
                        />
                      </div>
                    )
                    : (
                      <>
                        <div className='form-group row mb-4'>
                          <div className='col-lg-12 mb-3'>
                            <div className='row'>
                              <div className='col-lg-2' />
                              <div className='col-lg-2'>
                                <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                                  First Name:
                                </label>
                              </div>
                              <div className='col-lg-4'>
                                <div className='input-group mb-5'>
                                  <span
                                    className='input-group-text'
                                    id='basic-addon3'
                                  >
                                    <i className='bi bi-person-circle' />
                                  </span>
                                  <input
                                    name='firstName'
                                    type='text'
                                    className='form-control'
                                    id='basic-url'
                                    placeholder='First Name'
                                    aria-describedby='basic-addon3'
                                    onChange={(e) => handleChange(e)}
                                    value={formData.firstName || ''}
                                    autoComplete='off'
                                    maxLength={42}
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_CHARS_SPACE.test(e.key)
                                      ) {
                                        e.preventDefault()
                                      }
                                    }}
                                  />
                                </div>
                                {errors && errors.firstName && (
                                  <div className='rr mt-1'>
                                    <style>{'.rr{color:red;}'}</style>
                                    {errors.firstName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='form-group row mb-4'>
                          <div className='col-lg-12 mb-3'>
                            <div className='row'>
                              <div className='col-lg-2' />
                              <div className='col-lg-2'>
                                <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                                  Last Name:
                                </label>
                              </div>
                              <div className='col-lg-4'>
                                <div className='input-group mb-5'>
                                  <span
                                    className='input-group-text'
                                    id='basic-addon3'
                                  >
                                    <i className='bi bi-person-circle' />
                                  </span>
                                  <input
                                    name='lastName'
                                    type='text'
                                    className='form-control'
                                    id='basic-url'
                                    placeholder='Last Name'
                                    aria-describedby='basic-addon3'
                                    onChange={(e) => handleChange(e)}
                                    value={formData.lastName || ''}
                                    autoComplete='off'
                                    maxLength={42}
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_CHARS_SPACE.test(e.key)
                                      ) {
                                        e.preventDefault()
                                      }
                                    }}
                                  />
                                </div>
                                {errors && errors.lastName && (
                                  <div className='rr mt-1'>
                                    <style>{'.rr{color:red;}'}</style>
                                    {errors.lastName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='form-group row mb-4'>
                          <div className='col-lg-12 mb-3'>
                            <div className='row'>
                              <div className='col-lg-2' />
                              <div className='col-lg-2'>
                                <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                                  Phone Number:
                                </label>
                              </div>
                              <div className='col-lg-4'>
                                <div className='input-group mb-5'>
                                  <span
                                    className='input-group-text'
                                    id='basic-addon3'
                                  >
                                    <i className='bi bi-telephone-fill' />
                                  </span>
                                  <input
                                    name='mobile'
                                    type='text'
                                    className='form-control'
                                    id='basic-url'
                                    placeholder='Phone Number'
                                    aria-describedby='basic-addon3'
                                    onChange={(e) => handleChange(e)}
                                    value={formData.mobile || ''}
                                    autoComplete='off'
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.NUMERIC.test(e.key)
                                      ) {
                                        e.preventDefault()
                                      }
                                    }}
                                  />
                                </div>
                                {errors && errors.mobile && (
                                  <div className='rr mt-1'>
                                    <style>{'.rr{color:red;}'}</style>
                                    {errors.mobile}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='form-group row mb-4'>
                          <div className='col-lg-12 mb-3'>
                            <div className='row'>
                              <div className='col-lg-2' />
                              <div className='col-lg-2'>
                                <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                                  Email:
                                </label>
                              </div>
                              <div className='col-lg-4'>
                                <div className='input-group mb-5'>
                                  <span
                                    className='input-group-text'
                                    id='basic-addon3'
                                  >
                                    <i className='bi bi-envelope-fill' />
                                  </span>
                                  <input
                                    name='email'
                                    type='text'
                                    className='form-control'
                                    id='basic-url'
                                    placeholder='Email'
                                    aria-describedby='basic-addon3'
                                    onChange={(e) => handleChange(e)}
                                    value={formData.email || ''}
                                    autoComplete='off'
                                    onPaste={(e) => {
                                      e.preventDefault()
                                      return false
                                    }}
                                    onCopy={(e) => {
                                      e.preventDefault()
                                      return false
                                    }}
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)
                                      ) {
                                        e.preventDefault()
                                      }
                                    }}
                                  />
                                </div>
                                {errors && errors.email && (
                                  <div className='rr mt-1'>
                                    <style>{'.rr{color:red;}'}</style>
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='form-group row mb-4'>
                          <div className='col-lg-12 mb-3'>
                            <div className='row'>
                              <div className='col-lg-2' />
                              <div className='col-lg-2'>
                                <label className='font-size-xs font-weight-bold mb-2 form-label mt-4'>
                                  Password:
                                </label>
                              </div>
                              <div className='col-lg-4'>
                                <div className='input-group mb-5'>
                                  <span
                                    className='input-group-text'
                                    id='basic-addon3'
                                  >
                                    <i className='bi bi-lock-fill' />
                                  </span>
                                  <input
                                    name='password'
                                    type='password'
                                    className='form-control'
                                    id='basic-url'
                                    placeholder='Password'
                                    aria-describedby='basic-addon3'
                                    onChange={(e) => handleChange(e)}
                                    value={formData.password || ''}
                                    autoComplete='off'
                                    maxLength={42}
                                    onPaste={(e) => {
                                      e.preventDefault()
                                      return false
                                    }}
                                    onCopy={(e) => {
                                      e.preventDefault()
                                      return false
                                    }}
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)
                                      ) {
                                        e.preventDefault()
                                      }
                                    }}
                                  />
                                </div>
                                {errors && errors.password && (
                                  <div className='rr mt-1'>
                                    <style>{'.rr{color:red;}'}</style>
                                    {errors.password}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {["Admin", "Client User"].includes(userType && userType) ?
                          <div className='form-group row mb-4'>
                            <div className='col-lg-12 mb-3'>
                              <div className='row'>
                                <div className='col-lg-2' />
                                <div className='col-lg-2'>
                                  <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                                    Role:
                                  </label>
                                </div>
                                <div className='col-lg-4'>
                                  <div className='input-group mb-5'>
                                    <span
                                      className='input-group-text'
                                      id='basic-addon3'
                                    >
                                      <i className='bi bi-envelope' />
                                    </span>
                                    <select
                                      name='roleId'
                                      className='form-select form-select-solid'
                                      data-control='select'
                                      data-placeholder='Select an option'
                                      data-allow-clear='true'
                                      onChange={(e) => handleChange(e)}
                                      value={formData.roleId || ''}
                                    >
                                      <option value=''>Select Role...</option>
                                      {gerUserRole &&
                                        gerUserRole.length > 0 &&
                                        gerUserRole.map((item, i) => (
                                          <option key={i} value={item._id}>
                                            {item.role}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  {errors && errors.roleId && (
                                    <div className='rr mt-1'>
                                      <style>{'.rr{color:red}'}</style>
                                      {errors.roleId}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> : null
                        }
                      </>
                    )}
                </div>
              </div>
              {showForm &&
                !showForm &&
                !pathName.includes('client-onboarding/update')
                ? (
                  <div className='form-group row mt-4 mb-1'>
                    <div className='col-lg-6' />
                    <div className='col-lg-6'>
                      <div className='col-lg-11'>
                        <button
                          className='btn btn-orange m-2 fa-pull-right'
                          onClick={(event) => {
                            handleSubmit(event)
                            handleNextClick()
                          }}
                        >
                          {loading
                            ? (
                              <span
                                className='spinner-border spinner-border-sm mx-3'
                                role='status'
                                aria-hidden='true'
                              />
                            )
                            : (
                              'Next'
                            )}
                        </button>
                        <button
                          className='btn btn-darkRed m-2 fa-pull-right'
                          onClick={() => {
                            if (!editMode) {
                              goBack(2)
                            }
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                )
                : null}
              {editMode && pathName.includes('client-onboarding/update')
                ? (
                  <div className='form-group row mt-4'>
                    <div className='col-lg-6' />
                    <div className='col-lg-6'>
                      <div className='col-lg-11'>
                        <button
                          className='btn btn-blue m-2 fa-pull-right'
                          onClick={() => {
                            handleSave()
                          }}
                        >
                          {loadingEUD
                            ? (
                              <span
                                className='spinner-border spinner-border-sm mx-3'
                                role='status'
                                aria-hidden='true'
                              />
                            )
                            : (
                              'Save'
                            )}
                        </button>
                      </div>
                    </div>
                  </div>
                )
                : null}
            </div>
          </div>
        )
        : null}
      {editMode && !showForm
        ? (
          <>
            <div className='d-flex col-md-12 justify-content-end my-auto'>
              <div className='my-auto'>
                {["Admin", "Client User"].includes(userType && userType) ?
                  <button
                    className={`btn btn-sm m-5 mb-4 btn-${!addForm ? 'light-primary' : 'darkRed'
                      }`}
                    onClick={() => {
                      if (addForm) {
                        setAddForm(false)
                      } else {
                        setAddForm(true)
                      }
                    }}
                  >
                    {!addForm && (
                      <KTSVG path='/media/icons/duotune/arrows/arr087.svg' />
                    )}
                    {!addForm ? 'Add User' : 'Back'}
                  </button> : null
                }
              </div>
            </div>
            {addForm
              ? (
                <>
                  <AddUserInfo setAddForm={setAddForm} />
                </>
              )
              : (
                <div className='card'>
                  <div className='card-body py-3'>
                    <div className='d-flex  px - 2'>
                      <div className='d-flex justify-content-start col-md-6'>
                        <div className='col-md-3 mt-1'>
                          {getUser && getUser.count ? (
                            <span className='text-muted fw-bold d-flex fs-3 mt-2'>
                              Total: &nbsp;{' '}
                              <span className='text-gray-700 fw-bolder text-hover-primary fs-3'>
                                {getUser.count}
                              </span>
                            </span>
                          ) : null}
                        </div>
                        <div className='col-md-7 d-flex'>
                          <label className='col-form-label text-lg-start'>
                            Record(s) per Page : &nbsp;{' '}
                          </label>
                          <div className='col-md-3'>
                            <select
                              className='form-select w-6rem'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleRecordPerPage(e)}
                            >
                              <option value='25'>25</option>
                              <option value='50'>50</option>
                              <option value='75'>75</option>
                              <option value='100'>100</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table table-hover table-rounded table-striped border gs-2 mt-6'>
                        <thead className='fw-bolder fs-8 text-gray-800'>
                          <tr>
                            <th>
                              <div className='d-flex'>
                                <span>Action</span>
                              </div>
                            </th>
                            <th>
                              <div className='d-flex'>
                                <span>First Name</span>
                                <div className='min-w-25px text-end'>
                                  <div
                                    className='cursor-pointer'
                                    onClick={() => handleSorting('firstName')}
                                  />
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className='d-flex'>
                                <span>Last Name</span>
                                <div className='min-w-25px text-end'>
                                  <div
                                    className='cursor-pointer'
                                    onClick={() => handleSorting('lastName')}
                                  />
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className='d-flex'>
                                <span>Phone Number</span>
                                <div className='min-w-25px text-end'>
                                  <div
                                    className='cursor-pointer'
                                    onClick={() => handleSorting('lastName')}
                                  />
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className='d-flex'>
                                <span>Email</span>
                                <div className='min-w-25px text-end'>
                                  <div
                                    className='cursor-pointer'
                                    onClick={() => handleSorting('email')}
                                  />
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className='d-flex'>
                                <span>Status</span>
                                <div className='min-w-25px text-end'>
                                  <div
                                    className='cursor-pointer'
                                    onClick={() => handleSorting('status')}
                                  />
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className='d-flex'>
                                <span>Role</span>
                                <div className='min-w-25px text-end'>
                                  <div
                                    className='cursor-pointer'
                                    onClick={() => handleSorting('roleId')}
                                  />
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='fs-8'>
                          {!loading
                            ? (
                              getUser && getUser.result && getUser.result
                                ? (
                                  getUser.result.map((user, _id) => {
                                    return (
                                      <tr
                                        key={_id}
                                        style={
                                          _id === 0
                                            ? { borderColor: 'black' }
                                            : { borderColor: 'white' }
                                        }
                                      >
                                        <td>
                                          <Link
                                            to='#'
                                            onClick={(e) => {
                                              e.preventDefault()
                                              setShowForm(true)
                                              userDetailsDispatch(user._id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
                                            }}
                                            className='btn btn-icon btn-bg-light btn-active-color-green btn-sm w-20px h-10px'
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/art/art005.svg'
                                              className='svg-icon-3 pointer'
                                            />
                                          </Link>
                                        </td>
                                        <td>
                                          {user.firstName ? user.firstName : '--'}
                                        </td>
                                        <td>{user.lastName ? user.lastName : '--'}</td>
                                        <td>{user.mobile ? user.mobile : '--'}</td>
                                        <td>{user.email ? user.email : '--'}</td>
                                        {user.status === 'ACTIVE'
                                          ? (
                                            <td>
                                              <span className='badge badge-success'>
                                                {user.status ? user.status : '--'}
                                              </span>
                                            </td>
                                          )
                                          : user.status === 'INACTIVE'
                                            ? (
                                              <span className='badge badge-warning'>
                                                {user.status ? user.status : '--'}
                                              </span>
                                            )
                                            : (
                                              ''
                                            )}
                                        <td>
                                          {user && user.roleId
                                            ? `${user.roleId.role}`
                                            : '--'}
                                        </td>
                                      </tr>
                                    )
                                  })
                                )
                                : (
                                  <tr className='text-center py-3'>
                                    <td colSpan='100%'>No record(s) found</td>
                                  </tr>
                                )
                            )
                            : (
                              <tr>
                                <td colSpan='100%' className='text-center'>
                                  <div
                                    className='spinner-border text-primary m-5'
                                    role='status'
                                  />
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                    <div className='form-group row mb-4 mt-6'>
                      <div className='col-lg-12 mb-4 align-items-end d-flex'>
                        <div className='col-lg-12 pagination'>
                          <ReactPaginate
                            nextLabel='Next >'
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={totalPages}
                            previousLabel='< Prev'
                            pageClassName='page-item'
                            pageLinkClassName='page-link'
                            previousClassName='page-item'
                            previousLinkClassName='page-link'
                            nextClassName='page-item'
                            nextLinkClassName='page-link'
                            breakLabel='...'
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            containerClassName='pagination'
                            activeClassName='active'
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </>
        )
        : null}
    </>
  )
}

const mapStateToProps = (state) => {
  const { userStore, userRoleStore } = state
  return {
    getUser:
      state && state.userStore && state.userStore.getUser
        ? state.userStore.getUser
        : [],
    loading: state && state.userStore && state.userStore.loading,
    dataEUD: state && state.editUserStore && state.editUserStore.dataEUD,
    statusEUD: state && state.editUserStore && state.editUserStore.statusEUD,
    messageEUD: state && state.editUserStore && state.editUserStore.messageEUD,
    loadingEUD: state && state.editUserStore && state.editUserStore.loadingEUD,
    getUserDetails:
      userStore && userStore.userGetDetails ? userStore.userGetDetails : [],
    statusGUD: userStore && userStore.statusGUD ? userStore.statusGUD : '',
    messagesGUD:
      userStore && userStore.messagesGUD ? userStore.messagesGUD : '',
    loadingGUD:
      userStore && userStore.loadingGUD ? userStore.loadingGUD : false,
    gerUserRole:
      userRoleStore && userRoleStore.gerUserRole
        ? userRoleStore.gerUserRole
        : []
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserDispatch: (params, headers) => dispatch(userActions.getUser(params, headers)),
  userDetailsDispatch: (data, headers) =>
    dispatch(userGetDetailsActions.getUserDetails(data, headers)),
  clearUserDetailsDispatch: () =>
    dispatch(userGetDetailsActions.clearUserDetails()),
  editUsersDispatch: (id, params, headers) =>
    dispatch(editUserActions.editUser(id, params, headers)),
  cleareditUserDispatch: () => dispatch(editUserActions.cleareditUser()),
  getUserRoleDispatch: (data, headers) => dispatch(userRoleActions.getUserRole(data, headers))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
