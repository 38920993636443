import React, { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { connect } from 'react-redux'
import { KTSVG } from '../../../theme/helpers'
import color from '../../../utils/colors'
import _ from 'lodash'
import ReactSelect from '../../../theme/layout/components/ReactSelect'
import { REGEX, currencyOptions, API } from '../../../utils/constants'
import { headersWithAuth, getLocalStorage } from '../../../utils/helper'
import {
  countryActions,
  stateActions,
  cityActions,
  clientActions,
  industryActions
} from '../../../store/actions'

function SearchList(props) {
  const {
    getCountryDispatch,
    getCountrys,
    getStateDispatch,
    getStates,
    getCityDispatch,
    getCitys,
    getClientDispatch,
    getIndustryDispatch,
    getIndustry,
    setSearchParams,
    activePageNumber,
    setActivePageNumber,
    limit
  } = props
  const pathUrl = new URL(window.location.href);
  const query = pathUrl && pathUrl.search.split("?");
  const apiKeyParam = query && query[1];
  const apiParam = apiKeyParam && apiKeyParam.split("apiKey=")
  const apiParamId = apiParam && apiParam[1]

  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': apiParamId ? apiParamId : getLocalStorage(API.API_KEY)
  }
  const [, setShow] = useState(false)
  const [countryOption, setCountryOption] = useState()
  const [selectedCountryOption, setSelectedCountryOption] = useState('')
  const [stateOption, setStateOption] = useState()
  const [selectedStateOption, setSelectedStateOption] = useState('')
  const [cityOptions, setCityOptions] = useState()
  const [selectedCityOption, setSelectedCityOption] = useState('')
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState()
  const [selectedIndustryOption, setSelectedIndustryOption] = useState()
  const [industryOption, setIndustryOption] = useState()
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [onBlurOptions, setOnBlurOptions] = useState({
    clientState: false,
    clientCity: false
  });
  const [formData, setFormData] = useState({
    company: '',
    clientCountry: '',
    industry: '',
    customerId: '',
    clientCity: '',
    clientState: '',
    clientZip: '',
    clientEmail: '',
    clientPhoneNumber: '',
    leadSource: '',
    currency: '',
    createdBy: '',
    updatedBy: ''
  })

  const params = {
    skipPagination: true
  }

  useEffect(() => {
    getCountryDispatch(params, apiParamId ? headercurrentWithApiKey : headersWithAuth)
    getStateDispatch(params, apiParamId ? headercurrentWithApiKey : headersWithAuth)
    getCityDispatch(params, apiParamId ? headercurrentWithApiKey : headersWithAuth)
    getIndustryDispatch(null, apiParamId ? headercurrentWithApiKey : headersWithAuth)
  }, [])
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : ''
    })
  }

  const getDefaultIndustryOptions = (data, name) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ''}`,
          value: item._id
        })
      )
      return defaultOptions
    }
  }

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCountryOption(selectedOption)
      setFormData((values) => ({
        ...values,
        clientCountry: selectedOption.value,
        clientState: '',
        clientCity: ''
      }))
      setSelectedStateOption()
      setSelectedCityOption()
      getStateDispatch({
        countryId: selectedOption.value,
        skipPagination: true
      }, apiParamId ? headercurrentWithApiKey : headersWithAuth)
    } else {
      setSelectedCountryOption()
      setSelectedStateOption()
      setSelectedCityOption()
      setFormData((values) => ({
        ...values,
        clientCountry: '',
        clientState: '',
        cityclientCity: ''
      }))
    }
  }

  const handleChangeState = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedStateOption(selectedOption)
      setFormData((values) => ({
        ...values,
        clientState: selectedOption.value,
        clientCity: ''
      }))
      setSelectedCityOption()
      getCityDispatch({
        stateId: selectedOption.value,
        skipPagination: true
      }, apiParamId ? headercurrentWithApiKey : headersWithAuth)
    } else {
      setSelectedStateOption()
      setSelectedCityOption()
      setFormData((values) => ({ ...values, clientState: '', clientCity: '' }))
    }
  }

  const handleChangeCity = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCityOption(selectedOption)
      setFormData((values) => ({ ...values, clientCity: selectedOption.value }))
    } else {
      setSelectedCityOption()
      setFormData((values) => ({ ...values, clientCity: '' }))
    }
  }

  const handleSelectBlur = (e, name) => {
    typingTimeout && clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (name === 'clientState' && onBlurOptions.clientState) {
          const param = { limit: 25 };
          getStateDispatch(param, headersWithAuth);
          setOnBlurOptions((values) => ({ ...values, clientState: false }));
        }
        if (name === 'clientCity' && onBlurOptions.clientCity) {
          const param = { limit: 25 };
          getCityDispatch(param, headersWithAuth);
          setOnBlurOptions((values) => ({ ...values, clientCity: false }));
        }
      }, 1000)
    );
  }

  const handleSelectInputChange = (typedOption, name) => {
    if (typedOption.length >= 3) {
      typingTimeout && clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          if (name === 'clientState' && !(selectedCountryOption && selectedCountryOption.value)) {
            const param = { name: typedOption, skipPagination: true }
            getStateDispatch(param, headersWithAuth);
            setOnBlurOptions((values) => ({ ...values, clientState: true }));
          }
          if (name === 'clientCity' && !(selectedStateOption && selectedStateOption.value)) {
            const param = { name: typedOption, skipPagination: true }
            getCityDispatch(param, headersWithAuth);
            setOnBlurOptions((values) => ({ ...values, city: true }));
          }
        }, 2000)
      );
    }
  }


  const handleChangeIndustry = selectedOption => {
    if (selectedOption !== null) {
      setSelectedIndustryOption(selectedOption)
      setFormData(values => ({ ...values, industry: selectedOption.value }))
    } else {
      setSelectedIndustryOption()
      setFormData(values => ({ ...values, industry: '' }))
    }
  }

  const handleChangeCurrency = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCurrencyOption(selectedOption)
      setFormData((values) => ({ ...values, currency: selectedOption.value }))
    } else {
      setSelectedCurrencyOption()
      setFormData((values) => ({ ...values, currency: '' }))
    }
  }

  useEffect(() => {
    const country = getDefaultOptions(getCountrys)
    setCountryOption(country)
  }, [getCountrys])

  useEffect(() => {
    const state = getDefaultOptions(getStates)
    setStateOption(state)
  }, [getStates])

  useEffect(() => {
    const city = getDefaultOptions(getCitys)
    setCityOptions(city)
  }, [getCitys])

  useEffect(() => {
    const data = getDefaultIndustryOptions(getIndustry, 'industryType')
    setIndustryOption(data)
  }, [getIndustry])

  const resetState = () => {
    setFormData({
      company: '',
      clientCountry: '',
      industry: '',
      customerId: '',
      clientCity: '',
      clientState: '',
      clientZip: '',
      clientEmail: '',
      clientPhoneNumber: '',
      leadSource: '',
      currency: '',
      createdBy: '',
      updatdBy: ''
    })
    setSelectedCountryOption()
    setSelectedStateOption()
    setSelectedCityOption()
    setSelectedIndustryOption()
    setSelectedCurrencyOption()
  }

  const getDefaultOptions = (rawData) => {
    const defaultOptions = []
    for (const item in rawData) {
      defaultOptions.push({
        label: rawData[item].name,
        value: rawData[item]._id
      })
    }
    return defaultOptions
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'amount' || name === 'ARN' || name === 'cardNumber' || name === 'transactionAmount' || name === 'creditCardNumber') {
      if (REGEX.NUMERIC.test(value)) {
        setFormData({ ...formData, [name]: value })
      } else {
        setFormData({ ...formData, [name]: '' })
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
  }

  const handleSearch = () => {
    const params = {
      limit: limit,
      page: 1
    }
    setActivePageNumber(1)
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(formData, key) && formData[key] !== '') {
        params[key] = formData[key]
      }
    }
    const result = _.omit(params, ['limit', 'page']);
    setSearchParams(_.pickBy(result))
    setShow(false)
    getClientDispatch(params, apiParamId ? headercurrentWithApiKey : headersWithAuth)
    resetState()
  }

  const handleReset = () => {
    resetState()
    setSearchParams()
    const params = {
      limit: limit,
      page: activePageNumber,
    }
    getClientDispatch(params, apiParamId ? headercurrentWithApiKey : headersWithAuth)
  }

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  return (
    <>
      <div className='d-flex bd-highlight'>
        <button
          type='button'
          className='btn btn-sm btn-light-primary font-5vw pull-right'
          data-toggle='modal'
          data-target='#searchModal'
          onClick={() => { setShow(true) }}
        >
          {/* eslint-disable */}
          <KTSVG path='/media/icons/duotune/general/gen021.svg' />
          {/* eslint-disable */}
          Search
        </button>
      </div>
      <div
        className='modal fade'
        id='searchModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden="''"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className='modal-dialog modal-dialog-centered mw-1000px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='me-8'>Search</h2>
              <button
                type='button'
                className='btn btn-lg btn-icon btn-active-light-primary close'
                data-dismiss='modal'
                onClick={() => {
                  setShow(false)
                  resetState()
                }}
              >
                {/* eslint-disable */}
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                {/* eslint-disable */}
              </button>
            </div>
            <div className='modal-body'>
              <form className='container-fixed'>
                <div className='card-header bg-lightBlue'>
                  <div className='card-body'>
                    <div className='form-group row mb-4'>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs  font-weight-bold mb-2 form-label'>
                          Company Name:
                        </label>
                        <div className='col-lg-11'>
                          <input
                            autoComplete='off'
                            name='company'
                            type='text'
                            className='form-control'
                            placeholder='Company Name'
                            onChange={(e) => handleChange(e)}
                            value={formData.company || ''}
                            maxLength={42}
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-lg-4 mb-2'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          Customer ID (Any CRM):
                        </label>
                        <div className='col-lg-11'>
                          <input
                            autoComplete='off'
                            name='customerId'
                            type='text'
                            className='form-control'
                            placeholder='Customer ID'
                            onChange={(e) => handleChange(e)}
                            value={formData.customerId || ''}
                            maxLength={42}
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          Industry:
                        </label>
                        <div className='col-lg-11'>
                          <ReactSelect
                            isClearable
                            styles={customStyles}
                            isMulti={false}
                            name='industry'
                            className='basic-single'
                            classNamePrefix='select'
                            handleChangeReactSelect={handleChangeIndustry}
                            options={industryOption}
                            value={selectedIndustryOption}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-group row mb-4'>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          Country:
                        </label>
                        <div className='col-lg-11'>
                          <ReactSelect
                            isClearable
                            styles={customStyles}
                            isMulti={false}
                            name='clientCountry'
                            className='basic-single'
                            classNamePrefix='select'
                            handleChangeReactSelect={handleChangeCountry}
                            options={countryOption}
                            value={selectedCountryOption}
                          />
                        </div>
                      </div>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          State:
                        </label>
                        <div className='col-lg-11'>
                          <ReactSelect
                            isClearable
                            styles={customStyles}
                            isMulti={false}
                            name='clientState'
                            className='basic-single'
                            classNamePrefix='select'
                            handleChangeReactSelect={handleChangeState}
                            options={stateOption}
                            value={selectedStateOption}
                            noOptionsMessage={() => "Please start typing to search!"}
                            onBlur={(e) => handleSelectBlur(e, 'clientState')}
                            handleInputChange={(e) => handleSelectInputChange(e, "clientState")}
                          />
                        </div>
                      </div>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          City:
                        </label>
                        <div className='col-lg-11'>
                          <ReactSelect
                            isClearable
                            styles={customStyles}
                            isMulti={false}
                            name='clientCity'
                            className='basic-single'
                            classNamePrefix='select'
                            handleChangeReactSelect={handleChangeCity}
                            options={cityOptions}
                            value={selectedCityOption}
                            noOptionsMessage={() => "Please start typing to search!"}
                            onBlur={(e) => handleSelectBlur(e, 'clientCity')}
                            handleInputChange={(e) => handleSelectInputChange(e, "clientCity")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-group row mb-4'>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          Zip Code:
                        </label>
                        <div className='col-lg-11'>
                          <input
                            autoComplete='off'
                            name='clientZip'
                            type='text'
                            className='form-control'
                            placeholder='Zip'
                            onChange={(e) => handleChange(e)}
                            value={formData.clientZip || ''}
                            maxLength={6}
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          Email:
                        </label>
                        <div className='col-lg-11'>
                          <input
                            autoComplete='off'
                            name='clientEmail'
                            type='text'
                            className='form-control'
                            placeholder='Email'
                            onChange={(e) => handleChange(e)}
                            value={formData.clientEmail || ''}
                            maxLength={42}
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-lg-4 mb-2'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          Phone Number:
                        </label>
                        <div className='col-lg-11'>
                          <input
                            autoComplete='off'
                            name='clientPhoneNumber'
                            type='text'
                            className='form-control'
                            placeholder='Phone Number'
                            onChange={(e) => handleChange(e)}
                            value={formData.clientPhoneNumber || ''}
                            maxLength={12}
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onKeyPress={(e) => {
                              if (!REGEX.NUMERIC_CHARS.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-group row mb-4'>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2  form-label'>
                          Lead Source:
                        </label>
                        <div className='col-lg-11'>
                          <input
                            autoComplete='off'
                            name='leadSource'
                            type='text'
                            className='form-control'
                            placeholder='Lead Source'
                            onChange={(e) => handleChange(e)}
                            value={formData.leadSource || ''}
                            maxLength={42}
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          Currency:
                        </label>
                        <div className='col-lg-11'>
                          <ReactSelect
                            isClearable
                            styles={customStyles}
                            isMulti={false}
                            name='currency'
                            className='basic-single'
                            classNamePrefix='select'
                            handleChangeReactSelect={handleChangeCurrency}
                            options={currencyOptions}
                            value={selectedCurrencyOption}
                          />
                        </div>
                      </div>

                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          Created By:
                        </label>
                        <div className='col-lg-11'>
                          <input
                            autoComplete='off'
                            name='createdBy'
                            type='text'
                            className='form-control'
                            placeholder='Created By'
                            onChange={(e) => handleChange(e)}
                            value={formData.createdBy || ''}
                            maxLength={42}
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>          


                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs font-weight-bold mb-2 form-label'>
                          Updated By:
                        </label>
                        <div className='col-lg-11'>
                          <input
                            autoComplete='off'
                            name='updatedBy'
                            type='text'
                            className='form-control'
                            placeholder='Updated By'
                            onChange={(e) => handleChange(e)}
                            value={formData.updatedBy || ''}
                            maxLength={42}
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </div>          

                    </div>
                    <div className='form-group row mt-4'>
                      <div className='col-lg-6' />
                      <div className='col-lg-6'>
                        <div className='col-lg-12'>
                          <button
                            type='button'
                            className='btn btn-sm btn-light-primary m-2 fa-pull-right'
                            onClick={() => handleSearch()}
                            data-dismiss='modal'
                          >
                            Search
                          </button>
                          <button
                            type='button'
                            className='btn btn-sm btn-light-danger m-2 fa-pull-right close'
                            data-dismiss='modal'
                            onClick={() => handleReset()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}


const mapStateToProps = state => ({
  getCountrys: state && state.countryStore && state.countryStore.getCountrys,
  loading: state && state.countryStore && state.countryStore.loading,
  getStates: state && state.stateStore && state.stateStore.getStates,
  loading: state && state.stateStore && state.stateStore.loading,
  getCitys: state && state.cityStore && state.cityStore.getCitys,
  loading: state && state.cityStore && state.cityStore.loading,
  getIndustry: state && state.industryStore && state.industryStore.getIndustry,
})

const mapDispatchToProps = dispatch => ({
  getCountryDispatch: (params, headers) => dispatch(countryActions.getCountrys(params, headers)),
  getStateDispatch: (params, headers) => dispatch(stateActions.getStates(params, headers)),
  getCityDispatch: (params, headers) => dispatch(cityActions.getCitys(params, headers)),
  getClientDispatch: (data, headers) => dispatch(clientActions.getClient(data, headers)),
  getIndustryDispatch: (params, headers) => dispatch(industryActions.getIndustry(params, headers)),
  clearIndustryDispatch: () => dispatch(industryActions.clearIndustry())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchList)