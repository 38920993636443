import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addAcquirerSettingsActionsTypes,
  addAcquirerSettingsActions,
  getAcquirerSettingsDetailsActionsTypes,
  getAcquirerSettingsDetailsActions,
  updateAcquirerSettingsActionsTypes,
  updateAcquirerSettingsActions,
  deleteAcquirerSettingsActionsTypes,
  deleteAcquirerSettingsActions,
  acquirerActions,
  acquirerActionsTypes
} from '../actions'
import serviceList from '../../services/serviceList'

function* addAcquirerSettings(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.acquirer, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addAcquirerSettingsActions.saveResponse(json.data))
    } else {
      yield put(addAcquirerSettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addAcquirerSettingsActionsTypes.RESPONSE, data })
  }
}

function* fetchAcquirer(actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.acquirer, { params, headers: headers })
    if (json.status === 200) {
      yield put(acquirerActions.saveAcquirerResponse(json.data))
    } else {
      yield put(acquirerActions.saveAcquirerResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: acquirerActionsTypes.SAVE_ACQUIRER_RESPONSE, data })
  }
}

function* getAcquirerSettingsDetails(actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.acquirer}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(
        getAcquirerSettingsDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getAcquirerSettingsDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getAcquirerSettingsDetailsActionsTypes.RESPONSE, data })
  }
}

function* updateAcquirerSettings(actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.acquirer}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(updateAcquirerSettingsActions.saveResponse(json.data))
    } else {
      yield put(updateAcquirerSettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updateAcquirerSettingsActionsTypes.RESPONSE, data })
  }
}

function* deleteAcquirerSettings(actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.acquirer}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteAcquirerSettingsActions.savemResponse(json.data))
    } else {
      yield put(deleteAcquirerSettingsActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteAcquirerSettingsActionsTypes.RESPONSE, data })
  }
}

export function* fetchAcquirerSettingsWatcher() {
  yield all([
    yield takeLatest(addAcquirerSettingsActionsTypes.REQUEST, addAcquirerSettings),
    yield takeLatest(getAcquirerSettingsDetailsActionsTypes.REQUEST, getAcquirerSettingsDetails),
    yield takeLatest(updateAcquirerSettingsActionsTypes.REQUEST, updateAcquirerSettings),
    yield takeLatest(deleteAcquirerSettingsActionsTypes.REQUEST, deleteAcquirerSettings),
    yield takeLatest(acquirerActionsTypes.GET_ACQUIRER, fetchAcquirer)
  ])
}
