import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import _ from "lodash";
import ShowFields from "./ShowFields";
import { Stepper } from "../../../theme/layout/components/stepper/Stepper";
import CompanyDetails from "./userDetails/companyDetails";
import MerchantInfo from "./userDetails/merchantInfo";
import MerchantFrom from "./userDetails/MerchantForm";
import UserInfo from "./userDetails/userInfo";
import Users from "./userDetails/Users";
import { clientDetailsActions } from "../../../store/actions";
import Package from "./userDetails/Package";
import Template from "./userDetails/EmailTemplate/Template";
import CrmForm from "./userDetails/crmForm";
import Checkout from "./userDetails/checkoutPage";
import { CRM_FORM, SET_STORAGE, SESSION, API } from "../../../utils/constants";
import {
  setLocalStorage,
  getLocalStorage,
  headersWithAuth,
  headerWithApiKey,
} from "../../../utils/helper";

function ClientOnboarding(props) {
  const { clientDetailsDispatch, clientCurrentTabDispatch, clientCurrentTab } =
    props;
  const [completedSteps, setCompletedSteps] = useState([-1]);
  const [activeStep, setActiveStep] = useState(0);
  const [array, setArray] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [summary, setSummary] = useState({});
  const [editMode, setEditMode] = useState(false);
  const active = getLocalStorage(CRM_FORM.ACTIVE_STEP);
  const stepperArray = [
    {
      stepperLabel: "Client Details",
      Optional: "",
    },
    {
      stepperLabel: "Merchants",
      Optional: "",
    },
    {
      stepperLabel: "Integrations",
      Optional: "",
    },
    {
      stepperLabel: "Login Info",
      Optional: "",
    },
    // {
    //   stepperLabel: 'Subscriptions',
    //   Optional: ''
    // },
    {
      stepperLabel: "Checkout",
      Optional: "",
    },
  ];

  const stepperEditArray = [
    {
      stepperLabel: "Client Details",
      Optional: "",
    },
    {
      stepperLabel: "Merchants",
      Optional: "",
    },
    {
      stepperLabel: "Integrations",
      Optional: "",
    },
    {
      stepperLabel: "Users",
      Optional: "",
    },
    // {
    //   stepperLabel: 'Packages',
    //   Optional: ''
    // },
    {
      stepperLabel: "Checkout",
      Optional: "",
    },
  ];

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const redirectURL = getLocalStorage(API.API_KEY);

  const userType = getLocalStorage(SET_STORAGE.SET_USER_ROLE);
  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update");
  const id = url && url[1];
  const urlName = pathName && pathName.split("update/");
  const clientId = urlName && urlName[1];

  const onClickNext = (currentId) => {
    const id = currentId - 1;
    setActiveStep(currentId);
    if (completedSteps.length === currentId) {
      if (completedSteps && !completedSteps.includes(id)) {
        setCompletedSteps((values) => [...values, id]);
        setLocalStorage(CRM_FORM.ACTIVE_STEP, currentId);
      }
    }
  };

  const goBack = (id) => {
    setActiveStep(id);
  };

  const getDescendants = (arr, step) => {
    const val = [];
    arr.forEach((item, i) => {
      if (step > i) {
        val.push(i);
      }
    });
    return val;
  };
  const d = getDescendants(array, active);
  const editstepper = getDescendants(array, array.length);

  useEffect(() => {
    if (active) {
      setActiveStep(Number(active));
      setCompletedSteps([-1, ...d]);
    } else {
      setActiveStep(0);
      setCompletedSteps([-1]);
    }
  }, [active]);

  useEffect(() => {
    if (id) {
      setEditMode(true);
      setArray(stepperEditArray);
      clientDetailsDispatch(
        id,
        isAuthorized ? headersWithAuth : headerWithApiKey
      );
    } else {
      setEditMode(false);
      setArray(stepperArray);
    }
  }, [id]);

  useEffect(() => {
    if (editMode) {
      setActiveStep(0);
      setCompletedSteps([-1, ...editstepper]);
    }
  }, [editMode]);

  const showTabDetails = (type) => {
    switch (type) {
      case "COMPANY":
        return <CompanyDetails clientId={clientId} />;
      case "MERCHANT":
        return <MerchantInfo clientId={clientId} />;
      case "USER":
        return <Users clientId={clientId} />;
      case "PACKAGE":
        return <Package clientId={clientId} />;
      case "EMAIL":
        return <Template clientId={clientId} />;
    }
  };

  const handleTabs = (name) => {
    clientCurrentTabDispatch(name);
  };
  return (
    <>
      {editMode ? (
        <>
          <div className="row g-0">
            <div className="col-md-12">
              <div className="card card-nav-tabs mt-10">
                <div className="card-header card-header-primary">
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <ul className="nav nav-tabs" data-tabs="tabs">
                        <li className="client-nav-item">
                          <a
                            className={`nav-link
                              ${clientCurrentTab === "COMPANY" ? "active" : ""}
                              `}
                            href="#companydetails"
                            data-toggle="tab"
                            onClick={() => {
                              handleTabs("COMPANY");
                            }}
                          >
                            <i className="bi bi-building m-2 text-white" />
                            Company Details
                          </a>
                        </li>
                        <li className="client-nav-item">
                          <a
                            className={`nav-link
                              ${clientCurrentTab === "MERCHANT" ? "active" : ""}
                              `}
                            href="#merchantinfo"
                            data-toggle="tab"
                            onClick={() => {
                              handleTabs("MERCHANT");
                            }}
                          >
                            <i className="bi bi-person-plus-fill m-2 text-white" />
                            Merchants
                          </a>
                        </li>
                        <li className="client-nav-item">
                          <a
                            className={`nav-link
                              ${clientCurrentTab === "USER" ? "active" : ""}
                              `}
                            href="#userinfo"
                            data-toggle="tab"
                            onClick={() => {
                              handleTabs("USER");
                            }}
                          >
                            <i className="bi bi-person-circle m-2 text-white" />
                            Users
                          </a>
                        </li>
                        {/* {userType && !userType.includes('Client User') &&
                            <li className='client-nav-item'>
                              <a
                                className={`nav-link
                                ${clientCurrentTab === 'PACKAGE' ? 'active' : ''}
                                `}
                                href='#package' data-toggle='tab'
                                onClick={() => { handleTabs('PACKAGE') }}
                              >
                                <i className='bi bi-cart-check-fill m-2 text-white' />
                                Subscription Details
                              </a>
                            </li>
                          } */}
                        <li className="client-nav-item">
                          <a
                            className={`nav-link
                              ${clientCurrentTab === "EMAIL" ? "active" : ""}
                              `}
                            href="#email"
                            data-toggle="tab"
                            onClick={() => {
                              handleTabs("EMAIL");
                            }}
                          >
                            <i className="fa fa-cog m-2 text-white" />
                            Settings
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Link
                    to={
                      isAuthorized
                        ? "/client-management"
                        : `/manage-client?apiKey=${redirectURL}`
                    }
                    className="btn btn-sm btn-darkRed mt-3 fa-pull-right"
                  >
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body">
              <ShowFields>{showTabDetails(clientCurrentTab)}</ShowFields>
            </div>
          </div>
        </>
      ) : (
        <>
          <Stepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            completedSteps={completedSteps}
            stepperArr={array}
            setCompletedSteps={setCompletedSteps}
            onStepperClick={onClickNext}
          />
          <div className="d-flex justify-content-end bg-skyBlue">
            <Link
              to={
                isAuthorized
                  ? "/client-management"
                  : `/manage-client?apiKey=${redirectURL}`
              }
              className="btn btn-darkRed fa-pull-right m-5"
            >
              Back to Clients
            </Link>
          </div>
          <ShowFields>
            {activeStep === 0 ? (
              <CompanyDetails
                onClickNext={onClickNext}
                goBack={goBack}
                setClientDetails={setClientDetails}
                setSummary={setSummary}
              />
            ) : null}
            {activeStep === 1 ? (
              <MerchantFrom
                onClickNext={onClickNext}
                goBack={goBack}
                setClientDetails={setClientDetails}
                setSummary={setSummary}
              />
            ) : null}
            {activeStep === 2 ? (
              <CrmForm
                onClickNext={onClickNext}
                goBack={goBack}
                setClientDetails={setClientDetails}
                setSummary={setSummary}
              />
            ) : null}
            {activeStep === 3 ? (
              <UserInfo
                onClickNext={onClickNext}
                goBack={goBack}
                setClientDetails={setClientDetails}
                setSummary={setSummary}
              />
            ) : null}
            {/* {activeStep === 4 ? <Package onClickNext={onClickNext} goBack={goBack} setClientDetails={setClientDetails} /> : null} */}
            {activeStep === 4 ? (
              <Checkout
                summary={summary}
                onClickNext={onClickNext}
                goBack={goBack}
                setClientDetails={setClientDetails}
                clientDetails={clientDetails}
              />
            ) : null}
          </ShowFields>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  const { clientDetailsStore } = state;
  return {
    clientCurrentTab:
      clientDetailsStore && clientDetailsStore.clientCurrentTab
        ? clientDetailsStore.clientCurrentTab
        : "COMPANY",
  };
};

const mapDispatchToProps = (dispatch) => ({
  clientDetailsDispatch: (data, headers) =>
    dispatch(clientDetailsActions.getClientDetails(data, headers)),
  clientCurrentTabDispatch: (data) =>
    dispatch(clientDetailsActions.clientCurrentTabValue(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientOnboarding);
