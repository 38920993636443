import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  partnerActions,
  partnerActionsTypes,
  addPartnerActionsType,
  addPartnerActions,
  getPartnersDetailsActionsTypes,
  getPartnersDetailsActions,
  updatePartnersActionsTypes,
  updatePartnersActions,
  deletePartnersActionsTypes,
  deletePartnersActions,
  getPartnersClientActionsTypes,
  getPartnersClientActions
} from '../actions'
import serviceList from '../../services/serviceList'

function* fetchPartner(actions) {
  try {
    const { params, headers } = actions
    const json = yield axiosInstance.get(serviceList.getPartner, { params, headers: headers })
    if (json.status === 200) {
      yield put(partnerActions.savepartnerResponse(json.data))
    } else {
      yield put(partnerActions.savepartnerResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: partnerActionsTypes.SAVE_PARTNER_RESPONSE, data })
  }
}

function* addPartner(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.getPartner, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addPartnerActions.saveResponse(json.data))
    } else {
      yield put(addPartnerActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addPartnerActionsType.RESPONSE, data })
  }
}

function* getPartnerDetails(actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.getPartner}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(
        getPartnersDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getPartnersDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPartnersDetailsActionsTypes.RESPONSE, data })
  }
}

function* updatePartner(actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.getPartner}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(updatePartnersActions.saveResponse(json.data))
    } else {
      yield put(updatePartnersActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPartnersDetailsActionsTypes.RESPONSE, data })
  }
}

function* deletePartner(actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.getPartner}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deletePartnersActions.saveResponse(json.data))
    } else {
      yield put(deletePartnersActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deletePartnersActionsTypes.RESPONSE, data })
  }
}

function* getPartnerUsers(actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.getPartnerUsers}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(
        getPartnersClientActions.saveResponse(json.data)
      )
    } else {
      yield put(getPartnersClientActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPartnersClientActionsTypes.RESPONSE, data })
  }
}

export function* fetchPartnerWatcher() {
  yield all([
    yield takeLatest(partnerActionsTypes.GET_PARTNER, fetchPartner),
    yield takeLatest(addPartnerActionsType.REQUEST, addPartner),
    yield takeLatest(getPartnersDetailsActionsTypes.REQUEST, getPartnerDetails),
    yield takeLatest(updatePartnersActionsTypes.REQUEST, updatePartner),
    yield takeLatest(deletePartnersActionsTypes.REQUEST, deletePartner),
    yield takeLatest(getPartnersClientActionsTypes.REQUEST, getPartnerUsers)
  ])
}
