import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  userProfileActionsTypes,
  userProfilesActions,
  editUserProfileTypes,
  editUserProfileActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchUserProfile (actions) {
  const { headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userProfile, { headers: headers })
    if (json.status === 200) {
      yield put(userProfilesActions.saveUserProfileResponse(json.data))
    } else {
      yield put(userProfilesActions.saveUserProfileResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: userProfileActionsTypes.SAVE_USER_PROFILE_RESPONSE, data })
  }
}

function * editUserProfile (actions) {
  const { payload, headers } = actions
  const { params } = payload && payload
  const endPointUrl = `${serviceList.userProfile}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(editUserProfileActions.saveeditUserProfileResponse(json.data))
    } else {
      yield put(editUserProfileActions.saveeditUserProfileResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editUserProfileTypes.RESPONSE, data })
  }
}

export function * fetchUserProfileWatcher () {
  yield all([
    yield takeLatest(userProfileActionsTypes.GET_USER_PROFILE, fetchUserProfile),
    yield takeLatest(editUserProfileTypes.REQUEST, editUserProfile)
  ])
}
