import _ from "lodash";

export const setCompanyDetails = (data) => {
  if (!_.isEmpty(data)) {
    return {
      company: data.company,
      clientCountry: data.clientCountry,
      industry: data.industry && data.industry._id && data.industry._id,
      customerId: data.customerId,
      clientAddress: data.clientAddress,
      clientCity: data.clientCity,
      clientState: data.clientState,
      clientZip: data.clientZip,
      clientEmail: data.clientEmail,
      clientPhoneNumber: data.clientPhoneNumber,
      phoneNumberExtension: data.phoneNumberExtension,
      clientURL: data.clientURL,
      leadSource: data.leadSource,
      clientComplexity: data.clientComplexity,
      currency: data.currency,
      clientDescription: data.clientDescription,
      logo: data.logo,
    };
  }
};

export const getMerchantPayload = (data) => {
  if (!_.isEmpty(data)) {
    return {
      merchantName: data.merchantName,
      merchantCountry: data.country,
      industry: data.industry,
      merchantAddress: data.address,
      merchantCity: data.city,
      merchantState: data.state,
      merchantZip: data.zip,
      merchantEmail: data.email,
      merchantPhoneNumber: data.phoneNumber,
      phoneNumberExtension: data.phoneNumberExtension,
      externalId: data.externalId,
      companyDescription: data.companyDescription,
      mid: data.midNumber,
      descriptorName: data.descriptorName,
      descriptorId: data.descriptorId,
      doingBusinessAs: data.doingBusinessAs,
      acquirerBin: data.acquirerBin,
      caid: data.caid,
      storeName: data.storeName,
      storeType: data.storeType,
      signupUrl: data.signupUrl,
      tosUrl: data.tosUrl,
      checkoutUrl: data.checkoutUrl,
      returnPolicyUrl: data.returnPolicyUrl,
      tosPolicyScreenshot: data.tosPolicyScreenshot,
      refundPolicyScreenshot: data.refundPolicyScreenshot,
      checkoutScreenshot: data.checkoutScreenshot,
      shippingPolicyScreenshot: data.shippingPolicyScreenshot,
    };
  }
};

export const setMerchantData = (data) => {
  if (!_.isEmpty(data)) {
    return {
      merchantName: data.merchantName,
      country: data.merchantCountry,
      industry: data.industry,
      address: data.merchantAddress,
      city: data.merchantCity,
      state: data.merchantState,
      zip: data.merchantZip,
      email: data.merchantEmail,
      phoneNumber: data.merchantPhoneNumber,
      phoneNumberExtension: data.phoneNumberExtension,
      externalId: data.externalId,
      companyDescription: data.companyDescription,
      midNumber: data.mid,
      descriptorName: data.descriptorName,
      descriptorId: data.descriptorId,
      doingBusinessAs: data.doingBusinessAs,
      acquirerBin: data.acquirerBin,
      caid: data.caid,
      storeName: data.storeName,
      storeType: data.storeType,
      signupUrl: data.signupUrl,
      tosUrl: data.tosUrl,
      checkoutUrl: data.checkoutUrl,
      returnPolicyUrl: data.returnPolicyUrl,
      tosPolicyScreenshot: data.tosPolicyScreenshot,
      refundPolicyScreenshot: data.refundPolicyScreenshot,
      checkoutScreenshot: data.checkoutScreenshot,
      shippingPolicyScreenshot: data.shippingPolicyScreenshot,
    };
  }
};

export const getCompanyDetailsUpdatePayload = (data) => {
  if (!_.isEmpty(data)) {
    return {
      company: data.company,
      clientCountry: data.clientCountry,
      industry: data.industry,
      customerId: data.customerId,
      clientAddress: data.clientAddress,
      clientCity: data.clientCity,
      clientState: data.clientState,
      clientZip: data.clientZip,
      clientEmail: data.clientEmail,
      clientPhoneNumber: data.clientPhoneNumber,
      phoneNumberExtension: data.phoneNumberExtension,
      clientURL: data.clientURL,
      leadSource: data.leadSource,
      clientComplexity: data.clientComplexity,
      currency: data.currency,
      clientDescription: data.clientDescription,
      logo: data.logo,
    };
  }
};

export const getUserDetailsPayload = (data) => {
  if (!_.isEmpty(data)) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      status: data.status,
      roleId: data.roleId && data.roleId._id && data.roleId._id,
    };
  }
};

export const merchantPayload = (data, id) => {
  if (!_.isEmpty(data)) {
    return {
      merchantName: data.merchantName,
      merchantCountry: data.merchantCountry,
      industry: data.industry,
      merchantAddress: data.merchantAddress,
      merchantCity: data.merchantCity,
      merchantState: data.merchantState,
      merchantZip: data.merchantZip,
      merchantEmail: data.merchantEmail,
      merchantPhoneNumber: data.merchantPhoneNumber,
      phoneNumberExtension: data.phoneNumberExtension,
      externalId: data.externalId,
      company: data.company,
      clientId: id,
      companyDescription: data.companyDescription,
      mid: data.mid,
      descriptorName: data.descriptorName,
      descriptorId: data.descriptorId,
      doingBusinessAs: data.doingBusinessAs,
      acquirerBin: data.bin,
      caid: data.caId,
      storeName: data.storeName,
      storeType: data.storeType,
      signupUrl: data.signupUrl,
      tosUrl: data.tosUrl,
      checkoutUrl: data.checkoutUrl,
      returnPolicyUrl: data.returnPolicyUrl,
      tosPolicyScreenshot: data.tosPolicyScreenshot,
      refundPolicyScreenshot: data.refundPolicyScreenshot,
      checkoutScreenshot: data.checkoutScreenshot,
      shippingPolicyScreenshot: data.shippingPolicyScreenshot,
    };
  }
};

export const getMIDCRMPayload = (data) => {
  if (!_.isEmpty(data)) {
    return {
      midNumber: data.midNumber,
      descriptorName: data.descriptorName,
      descriptorId: data.descriptorId,
      doingBusinessAs: data.doingBusinessAs,
      bin: data.bin,
      caId: data.caId,
      storeName: data.storeName,
      storeType: data.storeType,
      signupUrl: data.signupUrl,
      tosUrl: data.tosUrl,
      checkoutUrl: data.checkoutUrl,
      returnPolicyUrl: data.returnPolicyUrl,
      tosPolicyScreenshot: data.tosPolicyScreenshot,
      refundPolicyScreenshot: data.refundPolicyScreenshot,
      checkoutScreenshot: data.checkoutScreenshot,
      shippingPolicyScreenshot: data.shippingPolicyScreenshot,
    };
  }
};

export const getProcessorPayload = (data) => {
  if (!_.isEmpty(data)) {
    const merchant = {
      merchantName: data.merchantName,
      merchantCountry: data.merchantCountry,
      industry: data.industry,
      merchantAddress: data.merchantAddress,
      merchantCity: data.merchantCity,
      merchantState: data.merchantState,
      merchantZip: data.merchantZip,
      merchantEmail: data.merchantEmail,
      merchantPhoneNumber: data.merchantPhoneNumber,
      phoneNumberExtension: data.phoneNumberExtension,
      externalId: data.externalId,
      companyDescription: data.companyDescription,
    };
    const mid = {
      mid: data.mid,
      descriptorName: data.descriptorName,
      descriptorId: data.descriptorId,
      doingBusinessAs: data.doingBusinessAs,
      acquirerBin: data.acquirerBin,
      caid: data.caid,
      storeName: data.storeName,
      storeType: data.storeType,
      signupUrl: data.signupUrl,
      tosUrl: data.tosUrl,
      checkoutUrl: data.checkoutUrl,
      returnPolicyUrl: data.returnPolicyUrl,
      tosPolicyScreenshot: data.tosPolicyScreenshot,
      refundPolicyScreenshot: data.refundPolicyScreenshot,
      checkoutScreenshot: data.checkoutScreenshot,
      shippingPolicyScreenshot: data.shippingPolicyScreenshot,
    };
    return { merchant, mid };
  }
};
