import { put, takeLatest, all } from "redux-saga/effects";
import { API_MESSAGES, SESSION, HEADER, API } from "../../utils/constants";
import { getLocalStorage } from "../../utils/helper";
import axiosInstance from "../../services";
import {
  merchantBillingDetailsActions,
  merchantBillingDetailsTypes,
} from "../actions/merchantBillingDetails";
import serviceList from "../../services/serviceList";

function* getMerchantBillingDetails(action) {
  const { params, headers } = action;
  try {
    const json = yield axiosInstance.get(serviceList.billingDetails, {
      params,
      headers: headers,
    });
    if (json.status === 200) {
      yield put(merchantBillingDetailsActions.saveResponse(json.data));
    } else {
      yield put(merchantBillingDetailsActions.saveResponse({}));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: merchantBillingDetailsTypes.RESPONSE, data });
  }
}

function* getBillingMerchantBillingDetails(action) {
  const { params, headers } = action;
  try {
    const json = yield axiosInstance.get(serviceList.billingData, {
      params,
      headers: headers,
    });
    if (json.status === 200) {
      yield put(merchantBillingDetailsActions.getBillingResponse(json.data));
    } else {
      yield put(merchantBillingDetailsActions.getBillingResponse({}));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: merchantBillingDetailsTypes.BILLINGRES, data });
  }
}

function* postToggleMerchantBillingDetails(action) {
  const { payload, headers } = action;
  try {
    const json = yield axiosInstance.post(
      serviceList.toggleBillingChange,
      payload,
      { headers: headers }
    );
    if (json.status === 200) {
      yield put(merchantBillingDetailsActions.saveResponse(json.data));
    } else {
      yield put(merchantBillingDetailsActions.saveResponse({}));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: merchantBillingDetailsTypes.RESPONSE, data });
  }
}

function* postNewPriceDetails(action) {
  const { payload, headers } = action;
  try {
    const json = yield axiosInstance.post(
      serviceList.postBillingPrice,
      payload,
      { headers: headers }
    );
    if (json.status === 200) {
      yield put(merchantBillingDetailsActions.saveResponse(json.data));
    } else {
      yield put(merchantBillingDetailsActions.saveResponse({}));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: merchantBillingDetailsTypes.RESPONSE, data });
  }
}

export function* fetchMerchantBillingDetailsWatcher() {
  yield all([
    yield takeLatest(
      merchantBillingDetailsTypes.REQUEST,
      getMerchantBillingDetails
    ),
    yield takeLatest(
      merchantBillingDetailsTypes.BILLING,
      getBillingMerchantBillingDetails
    ),
    yield takeLatest(
      merchantBillingDetailsTypes.POST_TOGGLE_CHANGE,
      postToggleMerchantBillingDetails
    ),
    yield takeLatest(
      merchantBillingDetailsTypes.POST_PRICE_DETAILS_CHANGE,
      postNewPriceDetails
    ),
  ]);
}
