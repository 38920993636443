import {
  getRootCauseActionsTypes,
  getFunctionalActionsTypes,
  getSubFunactionalActionsTypes,
  getOperationalTypeActionsTypes
} from '../actions'

export const rootCauseInitialState = {
  list: null
}

export const rootCauseStoreKey = 'rootCauseStore'

export const rootCauseReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getRootCauseActionsTypes.REQUEST:
      return { ...state, loading: true }
    case getRootCauseActionsTypes.RESPONSE:
      return { ...state, getRootCause: res.data, loading: false }
    case getRootCauseActionsTypes.CLEAR:
      return { ...state, getRootCause: null }
    default:
      return state
  }
}

export const functionalInitialState = {
  list: null
}

export const functionalStoreKey = 'functionalStore'

export const functionalReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getFunctionalActionsTypes.REQUEST:
      return { ...state, loading: true }
    case getFunctionalActionsTypes.RESPONSE:
      return { ...state, getFunctional: res.data, loading: false }
    case getFunctionalActionsTypes.CLEAR:
      return { ...state, getFunctional: null }
    default:
      return state
  }
}

export const subFunctionalInitialState = {
  list: null
}

export const subFunctionalStoreKey = 'subFunctionalStore'

export const subFunctionalReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getSubFunactionalActionsTypes.REQUEST:
      return { ...state, loading: true }
    case getSubFunactionalActionsTypes.RESPONSE:
      return { ...state, getSubFunctional: res.data, loading: false }
    case getSubFunactionalActionsTypes.CLEAR:
      return { ...state, getSubFunctional: null }
    default:
      return state
  }
}

export const operationalTypeInitialState = {
  list: null
}

export const operationalTypeStoreKey = 'operationalTypeStore'

export const operationalTypeReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getOperationalTypeActionsTypes.REQUEST:
      return { ...state, loading: true }
    case getOperationalTypeActionsTypes.RESPONSE:
      return { ...state, getOperationalType: res.data, loading: false }
    case getOperationalTypeActionsTypes.CLEAR:
      return { ...state, getOperationalType: null }
    default:
      return state
  }
}
