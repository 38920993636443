import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../theme/helpers";
import { useHistory, Link } from "react-router-dom";
import color from "../../../utils/colors";
import {
  crmActions,
  getClientSignupActions,
  postClientSignUpActions,
} from "../../../store/actions";
import ReactSelect from "../../../theme/layout/components/ReactSelect";
import { CLIENT_SESSION, SHOPIFY_STORAGE } from "../../../utils/constants";
import { setLocalStorage, getLocalStorage } from "../../../utils/helper";
import { warningAlert } from "../../../utils/alerts";
import { setCrmData } from "./FormData/formData";
import { crmValidation } from "./validate/validation";
import axios from "axios";

const ShopifySelectCrm = (props) => {
  const {
    getCRMDispatch,
    getCRM,
    getClientSignupDispatch,
    statusClientSignup,
    getClientSignup,
    addClientSignupDispatch,
    clearaddClientSignupDispatch,
    statusAddClientSignup,
    messageAddClientSignup,
    loadingAddClientSignup,
  } = props;

  const history = useHistory();
  const clientSignupId = getLocalStorage(CLIENT_SESSION.CLIENT_TOKEN);
  const [selectedCrmOption, setSelectedCrmOption] = useState("");
  const [crmOption, setCrmOption] = useState();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    crmName: "",
    storeName: "",
  });

  // const handleChange = (e) => {
  //   e.persist();
  //   setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
  //   setErrors({ ...errors, [e.target.name]: "" });
  //   e.preventDefault();
  // };

  useEffect(async () => {
    const getProcessor = await axios
      .get(`${process.env.REACT_APP_API_URL}/paymentprocessor`)
      .catch((error) => {
        console.log(error);
      });
    const processorData = getDefaultOptions(
      getProcessor && getProcessor.data.data,
      "processorName"
    );
    setSelectedCrmOption(processorData);
  }, []);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      alignItems: "baseline",
      background: "#fff",
      minHeight: "35px",
      boxShadow: "0 0 0 1px #fff",
      borderColor: "#a888cb",
      borderStyle: "dashed",
      fontWeight: "bolder",
      borderWidth: "3px",
      borderRadius: "18px",
      "&:hover": {
        borderColor: "#89769f",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.white : color.black,
      fontWeight: "bolder",
      backgroundColor: state.isSelected ? "#A194AB" : "",
      "&:hover": {
        backgroundColor: "#A194AB",
      },
    }),
  };

  const getDefaultOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item[name],
        })
      );
      return defaultOptions;
    }
  };

  const handleChangeCRM = (selectedOption) => {
    console.log(selectedOption, " selectedOption ");
    // selectedOption.preventDefault();
    if (selectedOption !== null) {
      // setCrmOption((values) => ({ ...values, crmName: selectedOption.label }));
      // setSelectedCrmOption(selectedOption);
      setCrmOption(selectedOption);
      setFormData((values) => ({
        ...values,
        crmName: selectedOption.label,
      }));
      setErrors({ ...errors, processorData: "" });
    } else {
      // setSelectedCrmOption();
      setCrmOption();
      setFormData((values) => ({ ...values, crmName: "" }));
    }
  };

  const handleSubmit = (e) => {
    const errors = {};
    const processorData = formData.crmName;

    if (_.isEmpty(processorData)) {
      console.log(processorData, " formData.processorName value ");
      errors.processorData = "Please select CRM";
      setErrors(errors);
      e.preventDefault();
    } else {
      setErrors({ ...errors, processorData: "" });
      formData.crmName &&
        setLocalStorage(SHOPIFY_STORAGE.PROCESSOR, formData.crmName);
    }
    //return false;
  };

  return (
    <>
      <body className="bg-shadow">
        <div className="d-flex justify-content-center ms-10">
          <a href="#" className="mb-12 mt-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              style={{
                width: "253px",
                filter: "drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)",
              }}
            />
          </a>
        </div>
        <div className="row mb-10 gx-0">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xl-1" />
          <div className="col-lg-10 col-md-10 col-sm-10 col-xl-10 d-flex justify-content-center">
            <div
              className="card shadow-sm ms-5"
              style={{ borderRadius: "24px" }}
            >
              <div
                className="card-header  d-flex justify-content-center"
                style={{ minHeight: "49px" }}
              >
                <h2
                  className="text mb-4 mt-5 fw-bolder"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  Please select your payment processor
                </h2>
              </div>
              <div className="card-body">
                <h6
                  className="fw-bold fs-5 ms-10 text-center"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  ChargebackZero assign relevant resources and expertise from
                  and within the same CRM system
                </h6>
                <h6
                  className="fw-bold fs-5 ms-10 text-center"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  Authorize access only to the particular workforce to protect
                  client data.
                </h6>
                <div className="d-flex justify-content-center">
                  <a href="#">
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/illustrations/dozzy-1/5-dark.png"
                      )}
                      className="w-350px h-300px"
                    />
                  </a>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center mt-3 mb-3">
                    <h4 className="fw-bolder">Select Payment Processor</h4>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="col-lg-6">
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name="crmName"
                        className="basic-single"
                        classNamePrefix="select"
                        handleChangeReactSelect={handleChangeCRM}
                        options={selectedCrmOption}
                        value={crmOption}
                        // options={[
                        //   {
                        //     value: "shopify_payments",
                        //     label: "Shopify Payments",
                        //   },
                        //   { value: "stripe", label: "Stripe" },
                        //   { value: "paypal", label: "PayPal" },
                        // ]}
                        // value={selectedCrmOption}
                      />
                      {errors && errors.processorData && (
                        <div className="rr mt-1">
                          <style>{".rr{color:red;}"}</style>
                          {errors.processorData}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center  mt-3 mb-3"></div>
                  <div className="d-flex justify-content-center">
                    <div className="col-lg-6"></div>
                    <div className="d-flex justify-content-center">
                      {errors && errors.storeName && (
                        <div className="rr mt-1">
                          <style>{".rr{color:red}"}</style>
                          {errors.storeName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row mb-4 mt-15">
                    <div className="text-center">
                      <Link
                        to={`/shopify-startpage`}
                        className="btn btn-sm m-2 w-120px fw-bolder text-white h-40px"
                        style={{ backgroundColor: "#f56c61" }}
                      >
                        Previous
                      </Link>
                      <>
                        <Link
                          className="btn btn-sm fw-bolder btn-primary m-2 w-120px h-40px"
                          to={`/shopify-businessmodel`}
                          onClick={(event) => {
                            handleSubmit(event);
                          }}
                        >
                          Continue
                        </Link>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};
export default ShopifySelectCrm;
