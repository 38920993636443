import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../theme/helpers'
import { Link, useLocation } from 'react-router-dom'
import { setLocalStorage } from '../../../utils/helper'
import { CLIENT_SESSION } from '../../../utils/constants'

const GetStarted = (props) => {

  const searchName = useLocation().pathname
  const param = searchName && searchName.split('get-started/')
  const id = param && param[1]

  setLocalStorage(CLIENT_SESSION.CLIENT_TOKEN, id)

  return (
    <>
      <body
        className='bg-shadow'
      >
        <div className='d-flex justify-content-center ms-10'>
          <a href='#' className='mb-12 mt-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-1.svg')}
              style={{ width: '253px', filter: 'drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)' }}
            />
          </a>
        </div>
        <div className='row mb-10 gx-0'>
          <div className='col-lg-1 col-md-1 col-sm-1 col-xl-1' />
          <div className='col-lg-10 col-md-10 col-sm-10 col-xl-10 d-flex justify-content-center'>
            <div className="card shadow-sm ms-5">
              <div className="card-header mt-3 d-flex justify-content-center">
                <h2 className="text mb-2 mt-5 fw-bolder" style={{ color: 'rgb(25 14 70)' }}>
                  Get Started with ChargebackZero
                </h2>
              </div>
              <div className="card-body">
                <div className='d-flex justify-content-center'>
                  <h3 className="text fw-bolder fs-4 ms-10" style={{ color: 'rgb(25 14 70)' }}>Getting started is quick and simple.</h3>
                </div>
                <div className='d-flex justify-content-center'>
                  <h3 className="text mb-2 fw-bolder ms-10 fs-4" style={{ color: 'rgb(25 14 70)' }}>As soon as you're done, we'll begin responding to your chargebacks.</h3>
                </div>
                <div className='row col-lg-12 mt-3'>
                  <div className='col-lg-4'>
                    <div className="card shadow-sm ms-5">
                      <div className="card-body">
                        <div className='d-flex justify-content-center'>
                          <a href='#'>
                            <img
                              alt='Logo'
                              src={toAbsoluteUrl('/media/illustrations/dozzy-1/4.png')}
                              className='w-350px h-300px'
                            />
                          </a>
                        </div>
                        <h3 className='fw-bolder fs-4 d-flex justify-content-center' style={{ color: 'rgb(25 14 70)' }}>Choose your processor</h3>
                        <h6 className='fw-bold fs-5 ms-10 text-center' style={{ color: 'rgb(25 14 70)' }}>Choose your payment processor and we'll gather your chargeback details.</h6>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className="card shadow-sm ms-5">
                      <div className="card-body">
                        <div className='d-flex justify-content-center'>
                          <a href='#'>
                            <img
                              alt='Logo'
                              src={toAbsoluteUrl('/media/illustrations/dozzy-1/12.png')}
                              className='w-350px h-300px'
                            />
                          </a>
                        </div>
                        <h3 className='fw-bolder fs-4 d-flex justify-content-center' style={{ color: 'rgb(25 14 70)' }}>Choose your package</h3>
                        <h6 className='fw-bold fs-5 ms-10 text-center' style={{ color: 'rgb(25 14 70)' }}>Simple, transparent pricing without long contracts - simply pay as you go.</h6>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className="card shadow-sm ms-5">
                      <div className="card-body">
                        <div className='d-flex justify-content-center'>
                          <a href='#' className='mb-16'>
                            <img
                              alt='Logo'
                              src={toAbsoluteUrl('/media/illustrations/sigma-1/11.png')}
                              className='w-250px h-250px'
                            />
                          </a>
                        </div>
                        <h3 className='fw-bolder fs-4 d-flex justify-content-center' style={{ color: 'rgb(25 14 70)' }}>You are done!</h3>
                        <h6 className='fw-bold fs-5 ms-10 text-center' style={{ color: 'rgb(25 14 70)' }}>From now on, ChargebackZero will respond to your chargebacks automatically.</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <Link to='/select-crm'
                    className='btn m-2 text-white fw-bolder'
                    style={{ backgroundColor: 'rgb(57 138 119)' }}>
                    Proceed
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}

export default GetStarted