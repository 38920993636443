export const getPrivilegesActionsTypes = {
  REQUEST: 'GET_PRIVILEGES_REQUEST',
  RESPONSE: 'GET_PRIVILEGES_RESPONSE',
  ERROR: 'GET_PRIVILEGES_ERROR',
  CLEAR: 'GET_PRIVILEGES_CLEAR'
}

export const getPrivilegesActions = {
  get: (params, headers) => ({
    type: getPrivilegesActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getPrivilegesActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getPrivilegesActionsTypes.CLEAR
  })
}

export const addPrivilegesActionsTypes = {
  REQUEST: 'ADD_PRIVILEGES_REQUEST',
  RESPONSE: 'ADD_PRIVILEGES_RESPONSE',
  ERROR: 'ADD_PRIVILEGES_ERROR',
  CLEAR: 'ADD_PRIVILEGES_CLEAR'
}

export const addPrivilegesActions = {
  add: (data, headers) => {
    return {
      type: addPrivilegesActionsTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addPrivilegesActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addPrivilegesActionsTypes.CLEAR
  })
}

export const getPrivilegesDetailsActionsTypes = {
  REQUEST: 'GET_PRIVILEGES_DETAILS_REQUEST',
  RESPONSE: 'GET_PRIVILEGES_DETAILS_RESPONSE',
  ERROR: 'GET_PRIVILEGES_DETAILS_ERROR',
  CLEAR: 'GET_PRIVILEGES_DETAILS_CLEAR'
}
export const getPrivilegesDetailsActions = {
  getDetails: (id, headers) => ({
    type: getPrivilegesDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getPrivilegesDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getPrivilegesDetailsActionsTypes.CLEAR
  })
}

export const updatePrivilegesActionsTypes = {
  REQUEST: 'UPDATE_PRIVILEGES_REQUEST',
  RESPONSE: 'UPDATE_PRIVILEGES_RESPONSE',
  ERROR: 'UPDATE_PRIVILEGES_ERROR',
  CLEAR: 'UPDATE_PRIVILEGES_CLEAR'
}

export const updatePrivilegesActions = {
  update: (params, headers) => {
    return {
      type: updatePrivilegesActionsTypes.REQUEST,
      payload: { params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updatePrivilegesActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updatePrivilegesActionsTypes.CLEAR
  })
}

export const deletePrivilegesActionsTypes = {
  REQUEST: 'DELETE_PRIVILEGES_REQUEST',
  RESPONSE: 'DELETE_PRIVILEGES_RESPONSE',
  ERROR: 'DELETE_PRIVILEGES_ERROR',
  CLEAR: 'DELETE_PRIVILEGES_CLEAR'
}

export const deletePrivilegesActions = {
  delete: (params, headers) => ({
    type: deletePrivilegesActionsTypes.REQUEST,
    params, headers
  }),
  savemResponse: (data) => ({
    type: deletePrivilegesActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deletePrivilegesActionsTypes.CLEAR
  })
}

export const getCompPermissionActionsTypes = {
  REQUEST: 'GET_COMP_PERMISSION_REQUEST',
  RESPONSE: 'GET_COMP_PERMISSION_RESPONSE',
  ERROR: 'GET_COMP_PERMISSION_ERROR',
  CLEAR: 'GET_COMP_PERMISSION_CLEAR'
}

export const getCompPermissionActions = {
  get: (params, headers) => ({
    type: getCompPermissionActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getCompPermissionActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getCompPermissionActionsTypes.CLEAR
  })
}
