export const preventionAlertActionsTypes = {
  GET_PREVENTIONALERT: 'GET_PREVENTIONALERT',
  SAVE_PREVENTIONALERT_RESPONSE: 'SAVE_PREVENTIONALERT_RESPONSE',
  CLEAR_PREVENTIONALERT: 'CLEAR_PREVENTIONALERT'
}
export const preventionAlertActions = {
  getPreventionAlert: (params, headers) => ({
    type: preventionAlertActionsTypes.GET_PREVENTIONALERT,
    params, headers
  }),
  savePreventionAlertResponse: data => ({
    type: preventionAlertActionsTypes.SAVE_PREVENTIONALERT_RESPONSE,
    data
  }),
  clearPosts: () => ({
    type: preventionAlertActionsTypes.CLEAR_PREVENTIONALERT
  })
}

export const preventionAlertGetDetailsTypes = {
  GET_PREVENTION_ALERT_DETAILS: 'GET_PREVENTION_ALERT_DETAILS',
  PREVENTION_ALERT_DETAILS_RESPONSE: 'PREVENTION_ALERT_DETAILS_RESPONSE',
  CLEAR_PREVENTION_ALERT_DETAILS: 'CLEAR_PREVENTION_ALERT_DETAILS'
}
export const preventionAlertDetailsActions = {
  getPrevAlertDetails: (params, headers) => ({
    type: preventionAlertGetDetailsTypes.GET_PREVENTION_ALERT_DETAILS,
    params, headers
  }),
  savePrevAlertDetailsResponse: data => ({
    type: preventionAlertGetDetailsTypes.PREVENTION_ALERT_DETAILS_RESPONSE,
    data
  }),
  clearPrevAlertDetails: () => ({
    type: preventionAlertGetDetailsTypes.CLEAR_PREVENTION_ALERT_DETAILS
  })
}

export const editAlertsTypes = {
  REQUEST: 'EDIT_PREVENTIONALERT_REQUEST',
  RESPONSE: 'EDIT_PREVENTIONALERT_RESPONSE',
  ERROR: 'EDIT_PREVENTIONALERT_ERROR',
  CLEAR: 'EDIT_PREVENTIONALERT_CLEAR'
}

export const editAlertsActions = {
  editAlerts: (id, params, headers) => {
    return {
      type: editAlertsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditAlertsResponse: (data) => {
    return {
      type: editAlertsTypes.RESPONSE,
      data
    }
  },
  cleareditAlerts: () => ({
    type: editAlertsTypes.CLEAR
  })
}

export const outcomeAlertActionsTypes = {
  GET_OUTCOME_ALERT: 'GET_OUTCOME_ALERT',
  SAVE_OUTCOME_ALERT_RESPONSE: 'SAVE_OUTCOME_ALERT_RESPONSE',
  CLEAR_OUTCOME_ALERT: 'CLEAR_OUTCOME_ALERT'
}
export const outcomeAlertActions = {
  getOutcomeAlert: (params, headers) => ({
    type: outcomeAlertActionsTypes.GET_OUTCOME_ALERT,
    params, headers
  }),
  saveOutcomeAlertResponse: data => ({
    type: outcomeAlertActionsTypes.SAVE_OUTCOME_ALERT_RESPONSE,
    data
  }),
  clearOutcomeAlerts: () => ({
    type: outcomeAlertActionsTypes.CLEAR_OUTCOME_ALERT
  })
}

export const refundAlertActionsTypes = {
  GET_REFUND_ALERT: 'GET_REFUND_ALERT',
  SAVE_REFUND_ALERT_RESPONSE: 'SAVE_REFUND_ALERT_RESPONSE',
  CLEAR_REFUND_ALERT: 'CLEAR_REFUND_ALERT'
}
export const refundAlertActions = {
  getRefundAlert: (params, headers) => ({
    type: refundAlertActionsTypes.GET_REFUND_ALERT,
    params, headers
  }),
  saveRefundAlertResponse: data => ({
    type: refundAlertActionsTypes.SAVE_REFUND_ALERT_RESPONSE,
    data
  }),
  clearRefundAlerts: () => ({
    type: refundAlertActionsTypes.CLEAR_REFUND_ALERT
  })
}
