import {
  partnerActionsTypes,
  addPartnerActionsType,
  getPartnersDetailsActionsTypes,
  updatePartnersActionsTypes,
  deletePartnersActionsTypes,
  getPartnersClientActionsTypes
} from '../actions'

export const partnerStoreKey = 'partnerStore'

export const partnerReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case partnerActionsTypes.GET_PARTNER:
      return { ...state, loadingGetPartner: true }
    case partnerActionsTypes.SAVE_PARTNER_RESPONSE:
      return {
        ...state,
        getPartner: res.data && res.data.result,
        loadingGetPartner: false,
        count: res.data && res.data.count,
        messageGetPartner: res.message,
        statusGetPartner: res.status
      }
    case partnerActionsTypes.CLEAR_PARTNER:
      return {
        ...state,
        getPartner: null,
        count: 0,
        messageGetPartner: '',
        statusGetPartner: ''
      }
    default:
      return state
  }
}

export const addPartnerstoreKey = 'addPartnerstore'

export const addPartnersReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addPartnerActionsType.REQUEST:
      return { ...state, loadingAddPartners: true }
    case addPartnerActionsType.RESPONSE:
      return {
        ...state,
        addPartners: res.data,
        statusAddPartners: res.status,
        messageAddPartners: res.message,
        loadingAddPartners: false
      }
    case addPartnerActionsType.CLEAR:
      return {
        ...state,
        addPartners: null,
        statusAddPartners: '',
        messageAddPartners: ''
      }
    default:
      return state
  }
}

export const getPartnersDetailsStoreKey = 'getPartnersDetailsStore'

export const getPartnersDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getPartnersDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsPartners: true }
    case getPartnersDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsPartners: res.data,
        statusGetDetailsPartners: res.status,
        messageGetDetailsPartners: res.message,
        loadingGetDetailsPartners: false
      }
    case getPartnersDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsPartners: null,
        statusGetDetailsPartners: '',
        messageGetDetailsPartners: ''
      }
    default:
      return state
  }
}

export const updatePartnerstoreKey = 'updatePartnerstore'

export const updatePartnersReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updatePartnersActionsTypes.REQUEST:
      return { ...state, loadingUpdatePartners: true }
    case updatePartnersActionsTypes.RESPONSE:
      return {
        ...state,
        updatePartners: res.data,
        statusUpdatePartners: res.status,
        messageUpdatePartners: res.message,
        loadingUpdatePartners: false
      }
    case updatePartnersActionsTypes.CLEAR:
      return {
        ...state,
        updatePartners: null,
        statusUpdatePartners: '',
        messageUpdatePartners: ''
      }
    default:
      return state
  }
}

export const deletePartnerstoreKey = 'deletePartnerstore'

export const deletePartnersReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deletePartnersActionsTypes.REQUEST:
      return { ...state, loadingDeletePartners: true }
    case deletePartnersActionsTypes.RESPONSE:
      return {
        ...state,
        deletePartners: res.data,
        statusDeletePartners: res.status,
        messageDeletePartners: res.message,
        loadingDeletePartners: false
      }
    case deletePartnersActionsTypes.CLEAR:
      return {
        ...state,
        deletePartners: null,
        statusDeletePartners: '',
        messageDeletePartners: ''
      }
    default:
      return state
  }
}

export const getPartnersUsersStoreKey = 'getPartnersUsersStore'

export const getPartnersUsersReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getPartnersClientActionsTypes.REQUEST:
      return { ...state, loadingGetUsersPartners: true }
    case getPartnersClientActionsTypes.RESPONSE:
      return {
        ...state,
        getUsersPartners: res.data,
        statusGetUsersPartners: res.status,
        messageGetUsersPartners: res.message,
        loadingGetUsersPartners: false
      }
    case getPartnersClientActionsTypes.CLEAR:
      return {
        ...state,
        getUsersPartners: null,
        statusGetUsersPartners: '',
        messageGetUsersPartners: ''
      }
    default:
      return state
  }
}
