export const getSubsPackageActionsTypes = {
  REQUEST: 'GET_SUBS_PACKAGE_REQUEST',
  RESPONSE: 'GET_SUBS_PACKAGE_RESPONSE',
  ERROR: 'GET_SUBS_PACKAGE_ERROR',
  CLEAR: 'GET_SUBS_PACKAGE_CLEAR'
}

export const getSubsPackageActions = {
  get: (params, headers) => ({
    type: getSubsPackageActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getSubsPackageActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getSubsPackageActionsTypes.CLEAR
  })
}

export const addSubsPackageActionsTypes = {
  REQUEST: 'ADD_SUBS_PACKAGE_REQUEST',
  RESPONSE: 'ADD_SUBS_PACKAGE_RESPONSE',
  ERROR: 'ADD_SUBS_PACKAGE_ERROR',
  CLEAR: 'ADD_SUBS_PACKAGE_CLEAR'
}

export const addSubsPackageActions = {
  add: (data, headers) => {
    return {
      type: addSubsPackageActionsTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addSubsPackageActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addSubsPackageActionsTypes.CLEAR
  })
}

export const getSubsPackageDetailsActionsTypes = {
  REQUEST: 'GET_SUBS_PACKAGE_DETAILS_REQUEST',
  RESPONSE: 'GET_SUBS_PACKAGE_DETAILS_RESPONSE',
  ERROR: 'GET_SUBS_PACKAGE_DETAILS_ERROR',
  CLEAR: 'GET_SUBS_PACKAGE_DETAILS_CLEAR'
}
export const getSubsPackageDetailsActions = {
  getDetails: (id, headers) => ({
    type: getSubsPackageDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getSubsPackageDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getSubsPackageDetailsActionsTypes.CLEAR
  })
}

export const updateSubsPackageActionsTypes = {
  REQUEST: 'UPDATE_SUBS_PACKAGE_REQUEST',
  RESPONSE: 'UPDATE_SUBS_PACKAGE_RESPONSE',
  ERROR: 'UPDATE_SUBS_PACKAGE_ERROR',
  CLEAR: 'UPDATE_SUBS_PACKAGE_CLEAR'
}

export const updateSubsPackageActions = {
  update: (id, params, headers) => {
    return {
      type: updateSubsPackageActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updateSubsPackageActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updateSubsPackageActionsTypes.CLEAR
  })
}

export const deleteSubsActionsTypes = {
  REQUEST: 'DELETE_SUBS_REQUEST',
  RESPONSE: 'DELETE_SUBS_RESPONSE',
  ERROR: 'DELETE_SUBS_ERROR',
  CLEAR: 'DELETE_SUBS_CLEAR'
}

export const deleteSubsActions = {
  delete: (params, headers) => ({
    type: deleteSubsActionsTypes.REQUEST,
    params, headers
  }),
  savemResponse: (data) => ({
    type: deleteSubsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteSubsActionsTypes.CLEAR
  })
}

export const deleteSubsPackageActionsTypes = {
  REQUEST: 'DELETE_SUBS_PACKAGE_REQUEST',
  RESPONSE: 'DELETE_SUBS_PACKAGE_RESPONSE',
  ERROR: 'DELETE_SUBS_PACKAGE_ERROR',
  CLEAR: 'DELETE_SUBS_PACKAGE_CLEAR'
}

export const deleteSubsPackageActions = {
  delete: (params, headers) => ({
    type: deleteSubsPackageActionsTypes.REQUEST,
    params, headers
  }),
  savemResponse: (data) => ({
    type: deleteSubsPackageActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteSubsPackageActionsTypes.CLEAR
  })
}