import {
  addUsertypeTypes,
  fetchUserTypeActionsTypes,
  userTypeGetDetailsTypes,
  editUsertypeTypes,
  userTypeDeleteTypes
} from '../actions'

export const addUsertypeStoreKey = 'addUsertypeStore'

export const addUsertypeReducer = (state = {}, action) => {
  switch (action.type) {
    case addUsertypeTypes.REQUEST:
      return { ...state, loadingAddUType: true }
    case addUsertypeTypes.RESPONSE:
      return { ...state, dataAUR: action.data, statusUType: action.data.status, count: action.data.count, messageAddUsertype: action.data.message, loadingAddUType: false }
    case addUsertypeTypes.CLEAR:
      return { ...state, dataAUR: null, messageAddUsertype: '', statusUType: '' }
    default:
      return state
  }
}

export const usertypeStoreKey = 'usertypeStore'

export const usertypeReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case fetchUserTypeActionsTypes.REQUEST:
      return { ...state, loadingGetUType: true }
    case fetchUserTypeActionsTypes.RESPONSE:
      return { ...state, userTypeData: res.data && res.data, count: res.count, loadingGetUType: false }
    case fetchUserTypeActionsTypes.CLEAR:
      return { ...state, userTypeData: null, count: 0 }
    case userTypeGetDetailsTypes.GET_USER_TYPE_DETAILS:
      return { ...state, loadingUserType: true }
    case userTypeGetDetailsTypes.USER_TYPE_DETAILS_RESPONSE:
      return {
        ...state,
        usertypeDetails: res && res.data,
        statusUserType: res && res.status,
        messagesUserType: res && res.message,
        loadingUserType: false
      }
    case userTypeGetDetailsTypes.CLEAR_USER_TYPE_DETAILS:
      return { ...state, statusUserType: '', messagesUserType: '' }
    default:
      return state
  }
}

export const editUsertypeStoreKey = 'editUsertypeStore'

export const editUsertypeReducer = (state = {}, action) => {
  switch (action.type) {
    case editUsertypeTypes.REQUEST:
      return { ...state, loadingUpdateUserType: true }
    case editUsertypeTypes.RESPONSE:
      return {
        ...state,
        dataEUR: action.data && action.data,
        statusUpdateUsertype: action.data.status,
        messagesUpdateUsertype: action.data.message,
        loadingUpdateUserType: false
      }
    case editUsertypeTypes.CLEAR:
      return { ...state, dataEUR: null, messagesUpdateUsertype: '', statusUpdateUsertype: '' }
    default:
      return state
  }
}

export const usertypeDeleteStoreKey = 'usertypeDeleteStore'

export const deleteUsertypeReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case userTypeDeleteTypes.DELETE_USER_TYPE:
      return { ...state, loading: true }
    case userTypeDeleteTypes.SAVE_DELETE_USER_TYPE_RESPONSE:
      return {
        ...state,
        deleteUsertype: res.data,
        deleteUsertypeStatus: res.status,
        deleteUsertypeMessage: res.message,
        loading: false
      }
    case userTypeDeleteTypes.CLEAR_DELETE_USER_TYPE:
      return { ...state, deleteUsertype: null, deleteUsertypeStatus: '', deleteUsertypeMessage: '' }
    default:
      return state
  }
}
