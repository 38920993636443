export const orderinsightActionsTypes = {
  GET_ORDER_INSIGHT: 'GET_ORDER_INSIGHT',
  SAVE_ORDER_INSIGHT_RESPONSE: 'SAVE_ORDER_INSIGHT_RESPONSE',
  CLEAR_ORDER_INSIGHT: 'CLEAR_ORDER_INSIGHT'
}

export const orderinsightActions = {
  getOrderinsight: (params, headers) => ({
    type: orderinsightActionsTypes.GET_ORDER_INSIGHT,
    params, headers
  }),
  saveorderinsightResponse: data => ({
    type: orderinsightActionsTypes.SAVE_ORDER_INSIGHT_RESPONSE,
    data
  }),
  clearPosts: () => ({
    type: orderinsightActionsTypes.CLEAR_ORDER_INSIGHT
  })
}

export const getOrderInsightExportColumnsActionsTypes = {
  GET_EXPORT_COLUMNS: 'GET_EXPORT_COLUMNS',
  CLEAR_COLUMNS: 'CLEAR_COLUMNS'
}

export const getOrderInsightColumnsActions = {
  getOrderinsightExportColumns: (data, headers) => ({
    type: getOrderInsightExportColumnsActionsTypes.GET_EXPORT_COLUMNS,
    data, headers
  }),
  clearOrderInsightColumns: () => ({
    type: getOrderInsightExportColumnsActionsTypes.CLEAR_COLUMNS
  })
}
