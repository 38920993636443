import {
  getPartnerActionTypes,
  clientCredFilterActionsTypes,
  merchantCredFilterActionsTypes,
  midCredFilterActionsTypes
} from '../actions'

export const partnerCrudFilterStoreKey = 'partnerCrudFilterStore'

export const partnerCrudFilterReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getPartnerActionTypes.GET_FILTERS_PARTNER:
      return { ...state, loadingGetPartner: true }
    case getPartnerActionTypes.SAVE_FILTERS_PARTNER_RESPONSE:
      return {
        ...state,
        getPartner: res.data && res.data.result,
        loadingGetPartner: false,
        count: res.data && res.data.count,
        messageGetPartner: res.message,
        statusGetPartner: res.status
      }
    case getPartnerActionTypes.CLEAR_FILTERS_PARTNER:
      return {
        ...state,
        getPartner: null,
        count: 0,
        messageGetPartner: '',
        statusGetPartner: ''
      }
    case getPartnerActionTypes.SET_FILTERS_FUNCTION:
      return {
        ...state,
        setFilterFunction: action.data
      }
    case getPartnerActionTypes.SET_FILTERS_PARAMS:
      return {
        ...state,
        setCredFilterParams: action.data
      }
    default:
      return state
  }
}

export const clientCredFilterStoreKey = 'clientCredFilterStore'

export const clientCredFilterReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case clientCredFilterActionsTypes.GET_FILTER_CLIENT:
      return { ...state, loading: true }
    case clientCredFilterActionsTypes.SAVE_FILTER_CLIENT_RESPONSE:
      return { ...state, getClient: res.data, loading: false }
    case clientCredFilterActionsTypes.CLEAR_FILTER_CLIENT:
      return { ...state, getClient: null }
    default:
      return state
  }
}

export const merchantCredFilterStoreKey = 'merchantCredFilterStore'

export const merchantCredFilterReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case merchantCredFilterActionsTypes.GET_MERCHANT_FILETER:
      return { ...state, loading: true }
    case merchantCredFilterActionsTypes.SAVE_MERCHANT_FILETER_RESPONSE:
      return { ...state, getMerchant: res.data, loading: false }
    case merchantCredFilterActionsTypes.CLEAR_MERCHANT_FILETER:
      return { ...state, getMerchant: null }
    default:
      return state
  }
}

export const midCredFiltertoreKey = 'midCredFiltertore'

export const midCredFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case midCredFilterActionsTypes.GET_MID_FILTER:
      return { ...state, loading: true }
    case midCredFilterActionsTypes.SAVE_MID_FILTER_RESPONSE:
      return { ...state, getmidCRM: action.data, loading: false }
    case midCredFilterActionsTypes.CLEAR_MID_FILTER:
      return { ...state, getmidCRM: null }
    default:
      return state
  }
}
