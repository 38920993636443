import {
  userProfileActionsTypes,
  editUserProfileTypes
} from '../actions'

export const userProfileStoreKey = 'userProfileStore'

export const userProfileReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case userProfileActionsTypes.GET_USER_PROFILE:
      return { ...state, loadingGetUP: true }
    case userProfileActionsTypes.SAVE_USER_PROFILE_RESPONSE:
      return { ...state, userProfileData: res.data && res.data, statusGetUP: res.status, loadingGetUP: false }
    case userProfileActionsTypes.CLEAR_USER_PROFILE:
      return { ...state, userProfileData: null, statusGetUP: '' }
    default:
      return state
  }
}

export const editUserProfileStoreKey = 'editUserProfileStore'

export const editUserProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case editUserProfileTypes.REQUEST:
      return { ...state, loadingEUP: true }
    case editUserProfileTypes.RESPONSE:
      return { ...state, dataEUP: action.data, statusEUP: action.data.status, messageEUP: action.data.message, loadingEUP: false }
    case editUserProfileTypes.CLEAR:
      return { ...state, dataEUP: null, messageEUP: '', statusEUP: '' }
    default:
      return state
  }
}
