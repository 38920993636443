import _ from 'lodash'

export const addclientCRMTypes = {
  REQUEST: 'ADD_CLIENT_CRM_REQUEST',
  RESPONSE: 'ADD_CLIENT_CRM_RESPONSE',
  ERROR: 'ADD_CLIENT_CRM_ERROR',
  CLEAR: 'ADD_CLIENT_CRM_CLEAR'
}

export const addclientCRMActions = {
  addclientCRM: (data, headers) => {
    const pickByPayload = _.pickBy(data)
    return {
      type: addclientCRMTypes.REQUEST,
      payload: pickByPayload,
      headers
    }
  },
  saveaddclientCRMResponse: (data) => {
    return {
      type: addclientCRMTypes.RESPONSE,
      data
    }
  },
  clearaddclientCRM: () => ({
    type: addclientCRMTypes.CLEAR
  })
}

export const clientCRMActionsTypes = {
  GET_CLIENT_CRM: 'GET_CLIENT_CRM',
  SAVE_CLIENT_CRM_RESPONSE: 'SAVE_CLIENT_CRM_RESPONSE',
  CLEAR_CLIENT_CRM: 'CLEAR_CLIENT_CRM'
}

export const clientCRMActions = {
  getClientCRM: (params, headers) => ({
    type: clientCRMActionsTypes.GET_CLIENT_CRM,
    params, headers
  }),
  saveClientCRMResponse: data => ({
    type: clientCRMActionsTypes.SAVE_CLIENT_CRM_RESPONSE,
    data
  }),
  clearClientCRM: () => ({
    type: clientCRMActionsTypes.CLEAR_CLIENT_CRM
  })
}

export const editClientCRMActionsTypes = {
  REQUEST: 'EDIT_CLIENT_CRM_REQUEST',
  RESPONSE: 'EDIT_CLIENT_CRM_RESPONSE',
  ERROR: 'EDIT_CLIENT_CRM_ERROR',
  CLEAR: 'EDIT_CLIENT_CRM_CLEAR'
}

export const editClientCrmActions = {
  editClientCrm: (id, params, headers) => {
    return {
      type: editClientCRMActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditClientCrmResponse: (data) => {
    return {
      type: editClientCRMActionsTypes.RESPONSE,
      data
    }
  },
  cleareditClientCrm: () => ({
    type: editClientCRMActionsTypes.CLEAR
  })
}

export const clientCRMDeleteTypes = {
  DELETE_CLIENT_CRM: 'DELETE_CLIENT_CRM',
  SAVE_DELETE_CLIENT_CRM_RESPONSE: 'SAVE_DELETE_CLIENT_CRM_RESPONSE',
  CLEAR_DELETE_CLIENT_CRM: 'CLEAR_DELETE_CLIENT_CRM'
}

export const deleteclientCRMActions = {
  deleteclientCRM: (params, headers) => ({
    type: clientCRMDeleteTypes.DELETE_CLIENT_CRM,
    params, headers
  }),
  savemResponseDeleteclientCRM: data => ({
    type: clientCRMDeleteTypes.SAVE_DELETE_CLIENT_CRM_RESPONSE,
    data
  }),
  clearDeleteclientCRM: () => ({
    type: clientCRMDeleteTypes.CLEAR_DELETE_CLIENT_CRM
  })
}

export const addclientProcessorTypes = {
  REQUEST: 'ADD_CLIENT_PROCESSOR_REQUEST',
  RESPONSE: 'ADD_CLIENT_PROCESSOR_RESPONSE',
  ERROR: 'ADD_CLIENT_PROCESSOR_ERROR',
  CLEAR: 'ADD_CLIENT_PROCESSOR_CLEAR'
}

export const addclientProcessorActions = {
  addclientProcessor: (data, headers) => {
    const pickByPayload = _.pickBy(data)
    return {
      type: addclientProcessorTypes.REQUEST,
      payload: pickByPayload,
      headers
    }
  },
  saveaddclientProcessorResponse: (data) => {
    return {
      type: addclientProcessorTypes.RESPONSE,
      data
    }
  },
  clearaddclientProcessor: () => ({
    type: addclientProcessorTypes.CLEAR
  })
}

export const clientProcessorActionsTypes = {
  GET_CLIENT_PROCESSOR: 'GET_CLIENT_PROCESSOR',
  SAVE_CLIENT_PROCESSOR_RESPONSE: 'SAVE_CLIENT_PROCESSOR_RESPONSE',
  CLEAR_CLIENT_PROCESSOR: 'CLEAR_CLIENT_PROCESSOR'
}

export const clientProcessorActions = {
  getClientProcessor: (params, headers) => ({
    type: clientProcessorActionsTypes.GET_CLIENT_PROCESSOR,
    params, headers
  }),
  saveClientProcessorResponse: data => ({
    type: clientProcessorActionsTypes.SAVE_CLIENT_PROCESSOR_RESPONSE,
    data
  }),
  clearClientProcessor: () => ({
    type: clientProcessorActionsTypes.CLEAR_CLIENT_PROCESSOR
  })
}

export const getClientProcessorDetailsActionsTypes = {
  REQUEST: 'GET_CLIENT_PROCSSOR_DETAILS_REQUEST',
  RESPONSE: 'GET_CLIENT_PROCSSOR_DETAILS_RESPONSE',
  ERROR: 'GET_CLIENT_PROCSSOR_DETAILS_ERROR',
  CLEAR: 'GET_CLIENT_PROCSSOR_DETAILS_CLEAR'
}
export const getClientProcessorDetailsActions = {
  getDetails: (id, headers) => ({
    type: getClientProcessorDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getClientProcessorDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getClientProcessorDetailsActionsTypes.CLEAR
  })
}

export const editClientProcessorActionsTypes = {
  REQUEST: 'EDIT_CLIENT_PROCESSOR_REQUEST',
  RESPONSE: 'EDIT_CLIENT_PROCESSOR_RESPONSE',
  ERROR: 'EDIT_CLIENT_PROCESSOR_ERROR',
  CLEAR: 'EDIT_CLIENT_PROCESSOR_CLEAR'
}

export const editClientProcessorActions = {
  editClientProcessor: (id, params, headers) => {
    return {
      type: editClientProcessorActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditClientProcessorResponse: (data) => {
    return {
      type: editClientProcessorActionsTypes.RESPONSE,
      data
    }
  },
  cleareditClientProcessor: () => ({
    type: editClientProcessorActionsTypes.CLEAR
  })
}

export const clientProcessorDeleteTypes = {
  DELETE_CLIENT_PROCESSOR: 'DELETE_CLIENT_PROCESSOR',
  SAVE_DELETE_CLIENT_PROCESSOR_RESPONSE: 'SAVE_DELETE_CLIENT_PROCESSOR_RESPONSE',
  CLEAR_DELETE_CLIENT_PROCESSOR: 'CLEAR_DELETE_CLIENT_PROCESSOR'
}

export const deleteclientProcessorActions = {
  deleteclientProcessor: (params, headers) => ({
    type: clientProcessorDeleteTypes.DELETE_CLIENT_PROCESSOR,
    params, headers
  }),
  saveResponseDeleteclientProcessor: data => ({
    type: clientProcessorDeleteTypes.SAVE_DELETE_CLIENT_PROCESSOR_RESPONSE,
    data
  }),
  clearDeleteclientProcessor: () => ({
    type: clientProcessorDeleteTypes.CLEAR_DELETE_CLIENT_PROCESSOR
  })
}

export const addclientGatewayTypes = {
  REQUEST: 'ADD_CLIENT_GATEWAY_REQUEST',
  RESPONSE: 'ADD_CLIENT_GATEWAY_RESPONSE',
  ERROR: 'ADD_CLIENT_GATEWAY_ERROR',
  CLEAR: 'ADD_CLIENT_GATEWAY_CLEAR'
}

export const addclientGatewayActions = {
  addclientGateway: (data, headers) => {
    const pickByPayload = _.pickBy(data)
    return {
      type: addclientGatewayTypes.REQUEST,
      payload: pickByPayload,
      headers
    }
  },
  saveaddclientGatewayResponse: (data) => {
    return {
      type: addclientGatewayTypes.RESPONSE,
      data
    }
  },
  clearaddclientGateway: () => ({
    type: addclientGatewayTypes.CLEAR
  })
}

export const clientGatewayActionsTypes = {
  GET_CLIENT_GATEWAY: 'GET_CLIENT_GATEWAY',
  SAVE_CLIENT_GATEWAY_RESPONSE: 'SAVE_CLIENT_GATEWAY_RESPONSE',
  CLEAR_CLIENT_GATEWAY: 'CLEAR_CLIENT_GATEWAY'
}

export const clientGatewayActions = {
  getClientPayment: (params, headers) => ({
    type: clientGatewayActionsTypes.GET_CLIENT_GATEWAY,
    params, headers
  }),
  saveClientPaymentResponse: data => ({
    type: clientGatewayActionsTypes.SAVE_CLIENT_GATEWAY_RESPONSE,
    data
  }),
  clearClientPayment: () => ({
    type: clientGatewayActionsTypes.CLEAR_CLIENT_GATEWAY
  })
}

export const editClientPaymentActionsTypes = {
  REQUEST: 'EDIT_CLIENT_PAYMENT_REQUEST',
  RESPONSE: 'EDIT_CLIENT_PAYMENT_RESPONSE',
  ERROR: 'EDIT_CLIENT_PAYMENT_ERROR',
  CLEAR: 'EDIT_CLIENT_PAYMENT_CLEAR'
}

export const editClientPaymentActions = {
  editClientPayment: (id, params, headers) => {
    return {
      type: editClientPaymentActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditClientPaymentResponse: (data) => {
    return {
      type: editClientPaymentActionsTypes.RESPONSE,
      data
    }
  },
  cleareditClientPayment: () => ({
    type: editClientPaymentActionsTypes.CLEAR
  })
}

export const clientGatewayDeleteTypes = {
  DELETE_CLIENT_GATEWAY: 'DELETE_CLIENT_GATEWAY',
  SAVE_DELETE_CLIENT_GATEWAY_RESPONSE: 'SAVE_DELETE_CLIENT_GATEWAY_RESPONSE',
  CLEAR_DELETE_CLIENT_GATEWAY: 'CLEAR_DELETE_CLIENT_GATEWAY'
}

export const deleteclientGatewayActions = {
  deleteclientGateway: (params, headers) => ({
    type: clientGatewayDeleteTypes.DELETE_CLIENT_GATEWAY,
    params, headers
  }),
  saveResponseDeleteclientGateway: data => ({
    type: clientGatewayDeleteTypes.SAVE_DELETE_CLIENT_GATEWAY_RESPONSE,
    data
  }),
  clearDeleteclientGateway: () => ({
    type: clientGatewayDeleteTypes.CLEAR_DELETE_CLIENT_GATEWAY
  })
}

export const midCRMActionsTypes = {
  GET_MID_CRM: 'GET_MID_CRM',
  SAVE_MID_CRM_RESPONSE: 'SAVE_MID_CRM_RESPONSE',
  CLEAR_MID_CRM: 'CLEAR_MID_CRM'
}

export const midCRMActions = {
  getmidCRM: (params, headers) => ({
    type: midCRMActionsTypes.GET_MID_CRM,
    params, headers
  }),
  savemidCRMResponse: data => ({
    type: midCRMActionsTypes.SAVE_MID_CRM_RESPONSE,
    data
  }),
  clearmidCRM: () => ({
    type: midCRMActionsTypes.CLEAR_MID_CRM
  })
}

export const midCRMDeleteTypes = {
  DELETE_MID_CRM: 'DELETE_MID_CRM',
  SAVE_DELETE_MID_CRM_RESPONSE: 'SAVE_DELETE_MID_CRM_RESPONSE',
  CLEAR_DELETE_MID_CRM: 'CLEAR_DELETE_MID_CRM'
}

export const deletemidCRMActions = {
  deletemidCRM: (params, headers) => ({
    type: midCRMDeleteTypes.DELETE_MID_CRM,
    params, headers
  }),
  savemResponseDeletemidCRM: data => ({
    type: midCRMDeleteTypes.SAVE_DELETE_MID_CRM_RESPONSE,
    data
  }),
  clearDeletemidCRM: () => ({
    type: midCRMDeleteTypes.CLEAR_DELETE_MID_CRM
  })
}
export const addmidCRMTypes = {
  REQUEST: 'ADD_MID_CRM_REQUEST',
  RESPONSE: 'ADD_MID_CRM_RESPONSE',
  ERROR: 'ADD_MID_CRM_ERROR',
  CLEAR: 'ADD_MID_CRM_CLEAR'
}

export const addmidCRMActions = {
  addmidCRM: (data, headers) => {
    return {
      type: addmidCRMTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddmidCRMResponse: (data) => {
    return {
      type: addmidCRMTypes.RESPONSE,
      data
    }
  },
  clearaddmidCRM: () => ({
    type: addmidCRMTypes.CLEAR
  })
}

export const midCRMGetDetailsTypes = {
  GET_MID_CRM_DETAILS: 'GET_MID_CRM_DETAILS',
  MID_CRM_DETAILS_RESPONSE: 'MID_CRM_DETAILS_RESPONSE',
  CLEAR_MID_CRM_DETAILS: 'CLEAR_MID_CRM_DETAILS'
}
export const midCRMGetDetailsActions = {
  getmidCRMDetails: (id, headers) => ({
    type: midCRMGetDetailsTypes.GET_MID_CRM_DETAILS,
    id, headers
  }),
  savemidCRMDetailsResponse: data => ({
    type: midCRMGetDetailsTypes.MID_CRM_DETAILS_RESPONSE,
    data
  }),
  clearmidCRMDetails: () => ({
    type: midCRMGetDetailsTypes.CLEAR_MID_CRM_DETAILS
  })
}

export const editmidCRMActionsTypes = {
  REQUEST: 'EDIT_MID_CRM_REQUEST',
  RESPONSE: 'EDIT_MID_CRM_RESPONSE',
  ERROR: 'EDIT_MID_CRM_ERROR',
  CLEAR: 'EDIT_MID_CRM_CLEAR'
}

export const editmidCRMActions = {
  editmidCRM: (id, params, headers) => {
    return {
      type: editmidCRMActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditmidCRMResponse: (data) => {
    return {
      type: editmidCRMActionsTypes.RESPONSE,
      data
    }
  },
  cleareditmidCRM: () => ({
    type: editmidCRMActionsTypes.CLEAR
  })
}

export const checkProcessorTypes = {
  REQUEST: 'CHECK_PROCESSOR_REQUEST',
  RESPONSE: 'CHECK_PROCESSOR_RESPONSE',
  ERROR: 'CHECK_PROCESSOR_ERROR',
  CLEAR: 'CHECK_PROCESSOR_CLEAR'
}

export const checkProcessorActions = {
  add: (data, headers) => {
    return {
      type: checkProcessorTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: checkProcessorTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: checkProcessorTypes.CLEAR
  })
}

export const checkCrmTypes = {
  REQUEST: 'CHECK_CRM_REQUEST',
  RESPONSE: 'CHECK_CRM_RESPONSE',
  ERROR: 'CHECK_CRM_ERROR',
  CLEAR: 'CHECK_CRM_CLEAR'
}

export const checkCrmActions = {
  add: (data, headers) => {
    return {
      type: checkCrmTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: checkCrmTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: checkCrmTypes.CLEAR
  })
}