import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../theme/helpers";
import {
  REGEX,
  STATUS_RESPONSE,
  SWEET_ALERT_MSG,
  SAVE_CURRENT,
  SESSION,
  API,
} from "../../../../utils/constants";
import ReactSelect from "../../../../theme/layout/components/ReactSelect";
import { Modal } from "../../../../theme/layout/components/modal";
import { getLocalStorage, headersWithAuth } from "../../../../utils/helper";
import _ from "lodash";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  warningAlert,
  confirmAlert,
  confirmationAlert,
} from "../../../../utils/alerts";
import {
  paymentActions,
  clientGatewayActions,
  editClientPaymentActions,
  deleteclientGatewayActions,
  addclientGatewayActions,
} from "../../../../store/actions";
import color from "../../../../utils/colors";
import {
  addGateValidation,
  paymentUpdateValidation,
} from "../userDetails/validation";

const AddPayment = (props) => {
  const {
    className,
    getPaymentDispatch,
    getClientPaymentDispatch,
    paymentData,
    clientGatewayData,
    clientGatewayLoading,
    editClientPaymentDispatch,
    addclientGatewayDispatch,
    clearaddclientGatewayDispatch,
    statusECG,
    messageECG,
    loadingECG,
    cleareditClientPaymentDispatch,
    deleteclientGatewayDispatch,
    clearDeleteclientGatewayDispatch,
    deleteclientGatewayStatus,
    deleteclientGatewayMessage,
    statusACGA,
    messageACGA,
    currentMerchantId,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };
  const currentMidID = getLocalStorage(SAVE_CURRENT.MID_ID);
  const currentClientID = getLocalStorage(SAVE_CURRENT.CLIENT_ID);
  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update-merchant/");
  const [editMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [showGatewayTable, setShowGatewayTable] = useState(true);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [paymentOption, setPaymentOption] = useState();
  const [currentId, setCurrentId] = useState();
  const [showAddGateway, setShowAddGateway] = useState(false);
  const [limit, setLimit] = useState(25);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [formData, setFormData] = useState({
    paymentGatewayId: "",
  });

  const [paymentTableForm, setPaymentTableForm] = useState([]);
  const [paymentGatewayDetails, setPaymentGatewayDetails] = useState({
    paymentGatewayId: "",
    apiEndpoint: "",
    apiKey: "",
    apiSecretKey: "",
  });

  const handleInputChange = (e, type) => {
    e.preventDefault();
    if (type === "setFormData") {
      setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    }
    if (type === "setPaymentGatewayDetails") {
      setPaymentGatewayDetails((values) => ({
        ...values,
        [e.target.name]: e.target.value,
      }));
    }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleAddClick = (type) => {
    if (type === "PAYMENT_GATEWAY") {
      const errorMsg = paymentUpdateValidation(formData, setErrors);
      if (_.isEmpty(errorMsg)) {
        if (!paymentTableForm.includes(formData.paymentGatewayId)) {
          setShowAddGateway(true);
        }
      }
    }
  };

  const handleSaveGatewayTable = () => {
    if (!paymentTableForm.includes(formData.paymentGatewayId)) {
      const errorMsg = addGateValidation(paymentGatewayDetails, setErrors);
      if (_.isEmpty(errorMsg)) {
        if (editMode) {
          const payload = {
            paymentGatewayId: paymentGatewayDetails.paymentGatewayId,
            apiEndpoint: paymentGatewayDetails.apiEndpoint,
            apiKey: paymentGatewayDetails.apiKey,
            apiSecretKey: paymentGatewayDetails.apiSecretKey,
          };
          editClientPaymentDispatch(
            currentId,
            payload,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        } else {
          const payload = {
            paymentGatewayId: formData.paymentGatewayId,
            apiEndpoint: paymentGatewayDetails.apiEndpoint,
            apiKey: paymentGatewayDetails.apiKey,
            apiSecretKey: paymentGatewayDetails.apiSecretKey,
            clientId: currentClientID,
            merchantId: currentMerchantId,
            midId: currentMidID,
          };
          addclientGatewayDispatch(
            payload,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }
        setSelectedPaymentOption();
        setShowGatewayTable(true);
        setShowAddGateway(false);
        setEditMode(false);
        setFormData({ paymentGatewayId: "" });
        setPaymentGatewayDetails({
          apiEndpoint: "",
          apiKey: "",
          apiSecretKey: "",
        });
      }
    }
  };

  const onEditGateClick = (item) => {
    setCurrentId(item._id);
    setShowAddGateway(true);
    setEditMode(true);
    setPaymentGatewayDetails({
      paymentGatewayId: item.paymentGatewayId,
      apiEndpoint: item.apiEndpoint,
      apiKey: item.apiKey,
      apiSecretKey: item.apiSecretKey,
    });
  };

  useEffect(() => {
    if (clientGatewayData) {
      const tempArr = [];
      clientGatewayData.forEach((o) => {
        const x =
          o &&
          o.paymentGatewayId &&
          o.paymentGatewayId._id &&
          o.paymentGatewayId._id;
        if (x && !tempArr.includes(x)) {
          tempArr.push(...tempArr, x);
        }
      });
      setPaymentTableForm(tempArr);
    }
  }, [clientGatewayData]);

  useEffect(() => {
    getPaymentDispatch(
      null,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    const params = {
      limit: limit,
      page: activePageNumber,
      merchantId: currentMerchantId,
      clientId: currentClientID,
      midId: currentMidID,
    };
    getClientPaymentDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const getDefaultPaymentOptions = (getData) => {
    const defaultOptions = [];
    if (!_.isEmpty(getData)) {
      getData &&
        getData.map((item) => {
          defaultOptions.push({
            label: `${item.gatewayName ? item.gatewayName : ""}`,
            value: item._id,
          });
        });
      return defaultOptions;
    }
  };

  const handleChangePayment = (selectedOption) => {
    if (
      selectedOption !== null &&
      !paymentTableForm.includes(selectedOption.label)
    ) {
      setSelectedPaymentOption(selectedOption);
      setFormData((values) => ({
        ...values,
        paymentGatewayId: selectedOption.value,
      }));
    } else {
      setSelectedPaymentOption();
      setFormData((values) => ({ ...values, paymentGatewayId: "" }));
    }
    setErrors((values) => ({ ...values, crmFormErr: "" }));
  };

  useEffect(() => {
    const data = getDefaultPaymentOptions(paymentData);
    setPaymentOption(data);
    if (!_.isEmpty(formData.paymentGatewayId)) {
      const selOption = _.filter(data, function (x) {
        if (_.includes(formData.paymentGatewayId._id, x.value)) {
          return x;
        }
      });
      setSelectedPaymentOption(selOption);
    }
  }, [paymentData]);

  const onConfirm = () => {
    const params = {
      limit: limit,
      page: activePageNumber,
      merchantId: currentMerchantId,
      clientId: currentClientID,
      midId: currentMidID,
    };
    getClientPaymentDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  useEffect(() => {
    if (statusECG === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageECG,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      cleareditClientPaymentDispatch();
    } else if (statusECG === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageECG, "", "Ok");
      cleareditClientPaymentDispatch();
    }
  }, [statusECG]);

  useEffect(() => {
    if (statusACGA === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageACGA,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearaddclientGatewayDispatch();
    } else if (statusACGA === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageACGA, "", "Ok");
      clearaddclientGatewayDispatch();
    }
  }, [statusACGA]);

  function onConfirmDelete(id) {
    deleteclientGatewayDispatch(
      id,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
  };

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_PAYMENT,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete(id);
      },
      () => {}
    );
  };

  useEffect(() => {
    if (deleteclientGatewayStatus === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        deleteclientGatewayMessage,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearDeleteclientGatewayDispatch();
    } else if (deleteclientGatewayStatus === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", deleteclientGatewayMessage, "", "Ok");
    }
    clearDeleteclientGatewayDispatch();
  }, [deleteclientGatewayStatus]);

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const params = {
      limit: limit,
      page: pageNumber,
      merchantId: currentMerchantId,
      clientId: currentClientID,
      midId: currentMidID,
    };
    setActivePageNumber(pageNumber);
    getClientPaymentDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const totalPages =
    getPaymentDispatch && getPaymentDispatch.count
      ? Math.ceil(
          parseInt(getPaymentDispatch && getPaymentDispatch.count) / limit
        )
      : 1;

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  return (
    <>
      <Modal showModal={showAddGateway} modalWidth={550}>
        <div className="" id="gatewayModal">
          <div className="">
            <div className="p-5">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="me-8">Payment Gateway Details</h2>
                <button
                  type="button"
                  className="btn btn-lg btn-icon btn-active-light-primary close"
                  onClick={() => {
                    setSelectedPaymentOption();
                    setPaymentGatewayDetails({
                      paymentGatewayId: "",
                      apiEndpoint: "",
                      apiKey: "",
                      apiSecretKey: "",
                    });
                    setErrors((values) => ({
                      ...values,
                      paymentGatewayId: "",
                      apiEndpoint: "",
                      apiKey: "",
                      apiSecretKey: "",
                    }));
                    setEditMode(false);
                    setFormData({ paymentGatewayId: "" });
                    setShowAddGateway(false);
                  }}
                >
                  {/* eslint-disable */}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon-1"
                  />
                  {/* eslint-disable */}
                </button>
              </div>
              <div className="modal-body bg-light">
                <form className="container-fixed">
                  <div className="card-body">
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API End Point :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="apiEndpoint"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API End Point"
                            onBlur={(e) =>
                              handleTrimWhiteSpace(e, setPaymentGatewayDetails)
                            }
                            onChange={(e) =>
                              handleInputChange(e, "setPaymentGatewayDetails")
                            }
                            autoComplete="off"
                            value={paymentGatewayDetails.apiEndpoint || ""}
                            maxLength={42}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.apiEndpoint && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.apiEndpoint}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API Key :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="apiKey"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API Key"
                            onBlur={(e) =>
                              handleTrimWhiteSpace(e, setPaymentGatewayDetails)
                            }
                            onChange={(e) =>
                              handleInputChange(e, "setPaymentGatewayDetails")
                            }
                            autoComplete="off"
                            value={paymentGatewayDetails.apiKey || ""}
                            maxLength={42}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.apiKey && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.apiKey}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API Secret Key:
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="apiSecretKey"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API Secret Key"
                            onBlur={(e) =>
                              handleTrimWhiteSpace(e, setPaymentGatewayDetails)
                            }
                            onChange={(e) =>
                              handleInputChange(e, "setPaymentGatewayDetails")
                            }
                            autoComplete="off"
                            value={paymentGatewayDetails.apiSecretKey || ""}
                            maxLength={42}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.apiSecretKey && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.apiSecretKey}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6" />
                    <div className="col-lg-6">
                      <div className="col-lg-11">
                        <button
                          type="button"
                          className="btn btn-sm btn-green m-2 fa-pull-right"
                          onClick={() => {
                            handleSaveGatewayTable();
                          }}
                        >
                          {loadingECG ? (
                            <span
                              className="spinner-border spinner-border-sm mx-3"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="card-header bg-skyBlue py-10">
        <div className="card-body  m-8 mt-5">
          <h2 className="mb-5">Payment Gateway</h2>
          <div className="form-group row mb-2">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="d-flex justify-content-start my-auto">
                    <div className="col-lg-5 pr-3 me-3">
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name="crmId"
                        className="basic-single"
                        classNamePrefix="select"
                        handleChangeReactSelect={handleChangePayment}
                        options={paymentOption}
                        value={selectedPaymentOption}
                      />
                      {errors && errors.paymentGatewayId && (
                        <div className="rr mt-1">
                          <style>{".rr{color:red}"}</style>
                          {errors.paymentGatewayId}
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className="btn btn-green mb-3 py-2"
                        onClick={() => {
                          handleAddClick("PAYMENT_GATEWAY");
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showGatewayTable ? (
            <div className="w-100 d-flex justify-content-start mt-2">
              <div className={`card ${className}`}>
                <div className="card-body py-3">
                  <div className="table-responsive">
                    <div className="col-md-7 d-flex">
                      <label className="col-form-label text-lg-start mt-3 ms-3">
                        Record(s) per Page : &nbsp;{" "}
                      </label>
                      <div className="col-md-3 mt-3">
                        <select
                          className="form-select w-6rem"
                          data-control="select"
                          data-placeholder="Select an option"
                          data-allow-clear="true"
                          onChange={(e) => handleRecordPerPage(e)}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>

                    <table className="table table-hover table-rounded table-striped border w-100 gs-2 mt-6">
                      <thead>
                        <tr className="fw-bold fs-6 text-gray-800">
                          <th className="min-w-80px text-start">
                            <div className="d-flex">
                              <span>S.No</span>
                            </div>
                          </th>
                          <th className="min-w-200px text-start ps-6">
                            Action
                          </th>
                          <th className="min-w-200px text-start">
                            <div className="d-flex">
                              <span>Payment Gateway</span>
                            </div>
                          </th>
                          <th className="min-w-200px text-start">
                            <div className="d-flex">
                              <span>Gateway Api Key</span>
                            </div>
                          </th>
                          <th className="min-w-200px text-start">
                            <div className="d-flex">
                              <span>Gateway End Point</span>
                            </div>
                          </th>
                          <th className="min-w-200px text-start">
                            <div className="d-flex">
                              <span>Gateway Api Secret Key</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {clientGatewayLoading ? (
                          <tr>
                            <td colSpan="6" className="text-center">
                              <div
                                className="spinner-border text-primary m-5"
                                role="status"
                              />
                            </td>
                          </tr>
                        ) : clientGatewayData &&
                          clientGatewayData.length > 0 ? (
                          clientGatewayData.map((item, i) => {
                            return (
                              <tr
                                key={i}
                                style={
                                  i === 0
                                    ? { borderColor: "black" }
                                    : { borderColor: "white" }
                                }
                              >
                                <td className="pb-0 pt-5 text-start">
                                  {i + 1}
                                </td>
                                <td className="pb-0 pt-3 text-start">
                                  <div className="my-auto d-flex">
                                    <button
                                      className="btn btn-icon btn-bg-light btn-icon-warning btn-active-color-warning btn-sm"
                                      onClick={() => {
                                        onEditGateClick(item);
                                      }}
                                    >
                                      {/* eslint-disable */}
                                      <KTSVG
                                        path="/media/icons/duotune/art/art005.svg"
                                        className="svg-icon-3"
                                      />
                                      {/* eslint-enable */}
                                    </button>
                                    <button
                                      className="btn btn-icon btn-bg-light btn-icon-danger btn-active-color-danger btn-sm"
                                      onClick={() => {
                                        onDeleteItem(item._id);
                                      }}
                                    >
                                      {/* eslint-disable */}
                                      <KTSVG
                                        path="/media/icons/duotune/general/gen027.svg"
                                        className="svg-icon-3"
                                      />
                                      {/* eslint-enable */}
                                    </button>
                                  </div>
                                </td>
                                <td className="pb-0 pt-5 text-start">
                                  {item.paymentGatewayId &&
                                    item.paymentGatewayId.gatewayName}
                                </td>
                                <td className="pb-0 pt-5 text-start">
                                  {item.apiKey}
                                </td>
                                <td className="pb-0 pt-5 text-start">
                                  {item.apiEndpoint}
                                </td>
                                <td className="pb-0 pt-5 text-start">
                                  {item.apiSecretKey}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center py-3">
                            <td colSpan="100%">No record(s) found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="form-group row mb-4 mt-6">
                    <div className="col-lg-12 mb-4 align-items-end d-flex">
                      <div className="col-lg-12 pagination">
                        <ReactPaginate
                          nextLabel="Next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={totalPages}
                          previousLabel="< Prev"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentLoading:
    state && state.paymentStore && state.paymentStore.paymentLoading,
  paymentData: state && state.paymentStore && state.paymentStore.paymentData,
  clientGatewayData:
    state &&
    state.clientGatewayStore &&
    state.clientGatewayStore.clientGatewayData,
  clientGatewayLoading:
    state &&
    state.clientGatewayStore &&
    state.clientGatewayStore.clientGatewayLoading,
  statusECG:
    state &&
    state.editClientGatewayStore &&
    state.editClientGatewayStore.statusECG,
  messageECG:
    state &&
    state.editClientGatewayStore &&
    state.editClientGatewayStore.messageECG,
  loadingECG:
    state &&
    state.editClientGatewayStore &&
    state.editClientGatewayStore.loadingECG,
  statusACGA:
    state &&
    state.addclientGatewayStore &&
    state.addclientGatewayStore.statusACGA,
  messageACGA:
    state &&
    state.addclientGatewayStore &&
    state.addclientGatewayStore.messageACGA,
  loadingACGA:
    state &&
    state.addclientGatewayStore &&
    state.addclientGatewayStore.loadingACGA,
  deleteclientGateway:
    state &&
    state.clientGatewayDeleteStore &&
    state.clientGatewayDeleteStore.deleteclientGateway,
  deleteclientGatewayStatus:
    state &&
    state.clientGatewayDeleteStore &&
    state.clientGatewayDeleteStore.deleteclientGatewayStatus,
  deleteclientGatewayMessage:
    state &&
    state.clientGatewayDeleteStore &&
    state.clientGatewayDeleteStore.deleteclientGatewayMessage,
  deleteclientGatewayLoading:
    state &&
    state.clientGatewayDeleteStore &&
    state.clientGatewayDeleteStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentDispatch: (params, headers) =>
    dispatch(paymentActions.getPayment(params, headers)),
  getClientPaymentDispatch: (params, headers) =>
    dispatch(clientGatewayActions.getClientPayment(params, headers)),
  clearClientPaymentDispatch: () =>
    dispatch(clientGatewayActions.clearClientPayment()),
  editClientPaymentDispatch: (id, params, headers) =>
    dispatch(editClientPaymentActions.editClientPayment(id, params, headers)),
  cleareditClientPaymentDispatch: () =>
    dispatch(editClientPaymentActions.cleareditClientPayment()),
  deleteclientGatewayDispatch: (data, headers) =>
    dispatch(deleteclientGatewayActions.deleteclientGateway(data, headers)),
  clearDeleteclientGatewayDispatch: () =>
    dispatch(deleteclientGatewayActions.clearDeleteclientGateway()),
  addclientGatewayDispatch: (data, headers) =>
    dispatch(addclientGatewayActions.addclientGateway(data, headers)),
  clearaddclientGatewayDispatch: () =>
    dispatch(addclientGatewayActions.clearaddclientGateway()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);
