import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import _ from 'lodash'
import {
  REGEX,
  currencyOptions,
  STATUS_RESPONSE,
  CLIENT_SESSION
} from '../../../utils/constants'
import color from '../../../utils/colors'
import ReactSelect from '../../../theme/layout/components/ReactSelect'
import {
  countryActions,
  stateActions,
  cityActions,
  industryActions,
  getClientSignupActions,
  postClientSignUpActions
} from '../../../store/actions'
import { clientDetailValidation } from './validate/validation'
import { setClientData } from './FormData/formData'
import { getLocalStorage, headersWithAuth } from "../../../utils/helper";

const ClientDetails = (props) => {
  const {
    getCountryDispatch,
    getCountrys,
    loadingCountry,
    getStateDispatch,
    getStates,
    loadingState,
    getCityDispatch,
    getCitys,
    loadingCity,
    getIndustryDispatch,
    getIndustry,
    siteConfigs,
    getClientSignupDispatch,
    clearClientSignupDispatch,
    loadingClientSignup,
    statusClientSignup,
    getClientSignup,
    addClientSignupDispatch,
    loadingAddClientSignup,
  } = props

  const clientSignupId = getLocalStorage(CLIENT_SESSION.CLIENT_TOKEN);
  const [countryOption, setCountryOption] = useState()
  const [selectedCountryOption, setSelectedCountryOption] = useState('')
  const [stateOption, setStateOption] = useState()
  const [selectedStateOption, setSelectedStateOption] = useState('')
  const [cityOptions, setCityOptions] = useState()
  const [selectedCityOption, setSelectedCityOption] = useState('')
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState()
  const [selectedIndustryOption, setSelectedIndustryOption] = useState('')
  const [industryOption, setIndustryOption] = useState()
  const [typingTimeout, setTypingTimeout] = useState(0)
  const [errors, setErrors] = useState({})
  const [onBlurOptions, setOnBlurOptions] = useState({
    clientState: false,
    clientCity: false
  });
  const [formData, setFormData] = useState({
    company: '',
    clientCountry: '',
    industry: '',
    customerId: '',
    clientAddress: '',
    clientCity: '',
    clientState: '',
    clientZip: '',
    clientEmail: '',
    clientPhoneNumber: '',
    phoneNumberExtension: '',
    clientURL: '',
    leadSource: '',
    clientComplexity: '',
    currency: '',
    clientDescription: ''
  })

  const param = {
    skipPagination: true
  }

  useEffect(() => {
    getCountryDispatch(param, headersWithAuth)
    getIndustryDispatch(null, headersWithAuth)
    getClientSignupDispatch(clientSignupId)
  }, [])

  const handleChange = (e) => {
    e.persist()
    setFormData(values => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
  }

  useEffect(() => {
    if (statusClientSignup === STATUS_RESPONSE.SUCCESS_MSG) {
      if (getClientSignup.Client !== null && getClientSignup.Client !== undefined) {
        const setData = setClientData(getClientSignup)
        setFormData(setData)
        if (getClientSignup.Client.clientCountry !== null) {
          const country = getDefaultOptions(getCountrys)
          const selOption = _.filter(country, function (x) {
            if (getClientSignup.Client.clientCountry === x.value) {
              return x
            }
          })
          setSelectedCountryOption(selOption)
        }
        if (getClientSignup.Client.clientState !== null) {
          const state = getDefaultOptions(getStates)
          const selOption = _.filter(state, function (x) {
            if (getClientSignup.Client.clientState === x.value) {
              return x
            }
          })
          setSelectedStateOption(selOption)
        }
        if (getClientSignup.Client.clientCity !== null) {
          const city = getDefaultOptions(getCitys)
          const selOption = _.filter(city, function (x) {
            if (getClientSignup.Client.clientCity === x.value) {
              return x
            }
          })
          setSelectedCityOption(selOption)
        }
        if (getClientSignup.Client.industry !== null) {
          const data = getDefaultIndustryOptions(getIndustry, 'industryType')
          const selOption = _.filter(data, function (x) {
            if (getClientSignup.Client.industry === x.value) {
              return x
            }
          })
          setSelectedIndustryOption(selOption)
        }
        if (getClientSignup.Client.currency) {
          const selOption = _.filter(currencyOptions, function (x) {
            if (_.includes(getClientSignup.Client.currency, x.value)) {
              return x
            }
          })
          setSelectedCurrencyOption(selOption)
        }
        clearClientSignupDispatch()
      }
    }
  }, [statusClientSignup])

  const handleSubmit = () => {
    const errorMsg = clientDetailValidation(formData, setErrors, siteConfigs)
    if (_.isEmpty(errorMsg)) {
      const client = {
        company: formData.company,
        clientCountry: formData.clientCountry,
        industry: formData.industry,
        customerId: formData.customerId,
        clientAddress: formData.clientAddress,
        clientCity: formData.clientCity,
        clientState: formData.clientState,
        clientZip: formData.clientZip,
        clientEmail: formData.clientEmail,
        clientPhoneNumber: formData.clientPhoneNumber,
        phoneNumberExtension: formData.phoneNumberExtension,
        clientURL: formData.clientURL,
        leadSource: formData.leadSource,
        clientComplexity: formData.clientComplexity,
        currency: formData.currency,
        clientDescription: formData.clientDescription
      }
      const payload = {
        signUpToken: clientSignupId,
        client: _.pickBy(client)
      }
      addClientSignupDispatch(payload)
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      alignItems: "baseline",
      background: "#fff",
      minHeight: "35px",
      boxShadow: "0 0 0 1px #fff",
      borderColor: "#a888cb",
      borderStyle: "dashed",
      fontWeight: "bolder",
      borderWidth: "3px",
      borderRadius: "18px",
      "&:hover": {
        borderColor: "#89769f",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.white : color.black,
      fontWeight: "bolder",
      backgroundColor: state.isSelected ? "#A194AB" : "",
      "&:hover": {
        backgroundColor: "#A194AB",
      },
    }),
  };

  const handleChangeCurrency = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCurrencyOption(selectedOption)
      setFormData((values) => ({ ...values, currency: selectedOption.value }))
    } else {
      setSelectedCurrencyOption()
      setFormData((values) => ({ ...values, currency: '' }))
    }
    setErrors({ ...errors, currency: '' })
  }

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getStateDispatch({ countryId: selectedOption.value, skipPagination: true })
        }, 1500)
      )
      setSelectedCountryOption(selectedOption)
      setFormData((values) => ({
        ...values,
        clientCountry: selectedOption.value,
        clientState: '',
        clientCity: ''
      }))
      setSelectedStateOption()
      setSelectedCityOption()
    } else {
      setSelectedCountryOption()
      setSelectedStateOption()
      setSelectedCityOption()
      setFormData((values) => ({
        ...values,
        clientCountry: '',
        clientState: '',
        cityclientCity: ''
      }))
    }
    setErrors({ ...errors, clientCountry: '' })
  }

  const handleChangeState = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getCityDispatch({ stateId: selectedOption.value, skipPagination: true })
        }, 2000)
      )
      setSelectedStateOption(selectedOption)
      setFormData((values) => ({
        ...values,
        clientState: selectedOption.value,
        clientCity: ''
      }))
      setSelectedCityOption()
    } else {
      setSelectedStateOption()
      setSelectedCityOption()
      setFormData((values) => ({ ...values, clientState: '', clientCity: '' }))
    }
    setErrors({ ...errors, clientState: '' })
  }

  const handleChangeCity = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCityOption(selectedOption)
      setFormData((values) => ({ ...values, clientCity: selectedOption.value }))
    } else {
      setSelectedCityOption()
      setFormData((values) => ({ ...values, clientCity: '' }))
    }
    setErrors({ ...errors, clientCity: '' })
  }

  const handleSelectBlur = (e, name) => {
    typingTimeout && clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (name === 'clientState' && onBlurOptions.clientState) {
          const param = { limit: 25 };
          getStateDispatch(param, headersWithAuth);
          setOnBlurOptions((values) => ({ ...values, clientState: false }));
        }
        if (name === 'clientCity' && onBlurOptions.clientCity) {
          const param = { limit: 25 };
          getCityDispatch(param, headersWithAuth);
          setOnBlurOptions((values) => ({ ...values, clientCity: false }));
        }
      }, 1000)
    );
  }

  const handleSelectInputChange = (typedOption, name) => {
    if (typedOption.length >= 3) {
      typingTimeout && clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          if (name === 'clientState' && !(selectedCountryOption && selectedCountryOption.value)) {
            const param = { name: typedOption, skipPagination: true }
            getStateDispatch(param, headersWithAuth);
            setOnBlurOptions((values) => ({ ...values, clientState: true }));
          }
          if (name === 'clientCity' && !(selectedStateOption && selectedStateOption.value)) {
            const param = { name: typedOption, skipPagination: true }
            getCityDispatch(param, headersWithAuth);
            setOnBlurOptions((values) => ({ ...values, clientCity: true }));
          }
        }, 2000)
      );
    }
  }

  const handleChangeIndustry = selectedOption => {
    if (selectedOption !== null) {
      setSelectedIndustryOption(selectedOption)
      setFormData(values => ({ ...values, industry: selectedOption.value }))
      setErrors({ ...errors, industry: '' })
    } else {
      setSelectedIndustryOption()
      setFormData(values => ({ ...values, industry: '' }))
    }
  }

  useEffect(() => {
    const country = getDefaultOptions(getCountrys)
    setCountryOption(country)
  }, [getCountrys])

  useEffect(() => {
    const state = getDefaultOptions(getStates)
    setStateOption(state)
  }, [getStates])

  useEffect(() => {
    const city = getDefaultOptions(getCitys)
    setCityOptions(city)
  }, [getCitys])

  useEffect(() => {
    const data = getDefaultIndustryOptions(getIndustry, 'industryType')
    setIndustryOption(data)
  }, [getIndustry])

  const getDefaultOptions = (data, name) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item.name ? item.name : ''}`,
          value: item._id
        })
      )
      return defaultOptions
    }
  }

  const getDefaultIndustryOptions = (data, name) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ''}`,
          value: item._id
        })
      )
      return defaultOptions
    }
  }

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  const handleTrimSpaceOnly = (e, setState) => {
    const { name, value } = e.target
    const getData = value.replace(/ +/g, ' ')
    setState((values) => ({ ...values, [name]: getData.trim() }))
  }

  return (
    <>
      <body className="card-body h-750px p-8">
        {loadingClientSignup
          ? (
            <div className='d-flex justify-content-center py-5'>
              <div className='spinner-border text-primary m-5' role='status' />
            </div>
          )
          : (
            <>
              <div className='form-group row mb-6'>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 required form-label'>
                    Company Name:
                  </label>
                  <div className='col-lg-11'>
                    <input
                      autoComplete='off'
                      name='company'
                      type='text'
                      className='form-control-1'
                      placeholder='Company Name'
                      onChange={(e) => handleChange(e)}
                      value={formData.company || ''}
                      maxLength={42}
                      onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                  {errors && errors.company && (
                    <div className='rr mt-1'>
                      <style>{'.rr{color:red;}'}</style>
                      {errors.company}
                    </div>
                  )}
                </div>
                <div className='col-lg-4 mb-2'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    Customer ID (Any CRM):
                  </label>
                  <div className='col-lg-11'>
                    <input
                      autoComplete='off'
                      name='customerId'
                      type='text'
                      className='form-control-1'
                      placeholder='Customer ID'
                      onChange={(e) => handleChange(e)}
                      value={formData.customerId || ''}
                      maxLength={42}
                      onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 required form-label'>
                    Industry:
                  </label>
                  <div className='col-lg-11'>
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name='industry'
                      className='basic-single'
                      classNamePrefix='select'
                      handleChangeReactSelect={handleChangeIndustry}
                      options={industryOption}
                      value={selectedIndustryOption}
                    />
                  </div>
                  {errors && errors.industry && (
                    <div className='rr mt-1'>
                      <style>{'.rr{color:red;}'}</style>
                      {errors.industry}
                    </div>
                  )}
                </div>
              </div>
              <div className='form-group row mb-6'>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    Country:
                  </label>
                  <div className='col-lg-11'>
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name='clientCountry'
                      className='basic-single'
                      classNamePrefix='select'
                      handleChangeReactSelect={handleChangeCountry}
                      options={countryOption}
                      value={selectedCountryOption}
                      isLoading={loadingCountry}
                      isDisabled={loadingCity || loadingCountry}
                    />
                  </div>
                </div>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    State:
                  </label>
                  <div className='col-lg-11'>
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name='clientState'
                      className='basic-single'
                      classNamePrefix='select'
                      handleChangeReactSelect={handleChangeState}
                      options={stateOption}
                      value={selectedStateOption}
                      isLoading={loadingState}
                      noOptionsMessage={() => "Please start typing to search!"}
                      onBlur={(e) => handleSelectBlur(e, 'clientState')}
                      handleInputChange={(e) => handleSelectInputChange(e, "clientState")}
                    />
                  </div>
                </div>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    City:
                  </label>
                  <div className='col-lg-11'>
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name='clientCity'
                      className='basic-single'
                      classNamePrefix='select'
                      handleChangeReactSelect={handleChangeCity}
                      options={cityOptions}
                      value={selectedCityOption}
                      isLoading={loadingCity}
                      isDisabled={loadingCity}
                      noOptionsMessage={() => "Please start typing to search!"}
                      onBlur={(e) => handleSelectBlur(e, 'clientCity')}
                      handleInputChange={(e) => handleSelectInputChange(e, "clientCity")}
                    />
                  </div>
                </div>
              </div>
              <div className='form-group row mb-6'>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 required form-label'>
                    Address:
                  </label>
                  <div className='col-lg-11'>
                    <textarea
                      autoComplete='off'
                      name='clientAddress'
                      type='text'
                      className='form-control-1'
                      placeholder='Address'
                      onChange={(e) => handleChange(e)}
                      value={formData.clientAddress || ''}
                      maxLength={500}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                  {errors && errors.clientAddress && (
                    <div className='rr mt-1'>
                      <style>{'.rr{color:red;}'}</style>
                      {errors.clientAddress}
                    </div>
                  )}
                </div>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 required form-label'>
                    Zip Code:
                  </label>
                  <div className='col-lg-11'>
                    <input
                      autoComplete='off'
                      name='clientZip'
                      type='text'
                      className='form-control-1'
                      placeholder='Zip'
                      onChange={(e) => handleChange(e)}
                      value={formData.clientZip || ''}
                      maxLength={6}
                      onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                  {errors && errors.clientZip && (
                    <div className='rr mt-1'>
                      <style>{'.rr{color:red;}'}</style>
                      {errors.clientZip}
                    </div>
                  )}
                </div>
              </div>
              <div className='form-group row mb-6'>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 required form-label'>
                    Email:
                  </label>
                  <div className='col-lg-11'>
                    <input
                      autoComplete='off'
                      name='clientEmail'
                      type='text'
                      className='form-control-1'
                      placeholder='Email'
                      onChange={(e) => handleChange(e)}
                      value={formData.clientEmail || ''}
                      maxLength={42}
                      onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                  {errors && errors.clientEmail && (
                    <div className='rr mt-1'>
                      <style>{'.rr{color:red;}'}</style>
                      {errors.clientEmail}
                    </div>
                  )}
                </div>
                <div className='col-lg-4 mb-2'>
                  <label className='font-size-xs fw-bolder mb-2 required form-label'>
                    Phone Number:
                  </label>
                  <div className='col-lg-11'>
                    <input
                      autoComplete='off'
                      name='clientPhoneNumber'
                      type='text'
                      className='form-control-1'
                      placeholder='Phone Number'
                      onChange={(e) => handleChange(e)}
                      value={formData.clientPhoneNumber || ''}
                      maxLength={12}
                      onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.NUMERIC_CHARS.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                  {errors && errors.clientPhoneNumber && (
                    <div className='rr mt-1'>
                      <style>{'.rr{color:red;}'}</style>
                      {errors.clientPhoneNumber}
                    </div>
                  )}
                </div>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    Phone Extension:
                  </label>
                  <div className='col-lg-11'>
                    <input
                      autoComplete='off'
                      name='phoneNumberExtension'
                      type='text'
                      className='form-control-1'
                      placeholder='Phone Extension'
                      onChange={(e) => handleChange(e)}
                      value={formData.phoneNumberExtension || ''}
                      maxLength={10}
                      onBlur={e => handleTrimSpaceOnly(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!/[0-9+]/.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='form-group row mb-6'>
                <div className='col-lg-4 mb-2'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    URL:
                  </label>
                  <div className='col-lg-11'>
                    <input
                      autoComplete='off'
                      name='clientURL'
                      type='text'
                      className='form-control-1'
                      placeholder='URL'
                      onChange={(e) => handleChange(e)}
                      value={formData.clientURL || ''}
                      maxLength={500}
                      onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                    />
                  </div>
                </div>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    Lead Source:
                  </label>
                  <div className='col-lg-11'>
                    <input
                      autoComplete='off'
                      name='leadSource'
                      type='text'
                      className='form-control-1'
                      placeholder='Lead Source'
                      onChange={(e) => handleChange(e)}
                      value={formData.leadSource || ''}
                      maxLength={42}
                      onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='col-lg-4 mb-2'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    Client Complexity:
                  </label>
                  <div className='col-lg-11'>
                    <input
                      autoComplete='off'
                      name='clientComplexity'
                      type='text'
                      className='form-control-1'
                      placeholder='Client Complexity'
                      onChange={(e) => handleChange(e)}
                      value={formData.clientComplexity || ''}
                      maxLength={42}
                      onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='form-group row mb-6'>
                <div className='col-lg-4 mb-3'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    Currency:
                  </label>
                  <div className='col-lg-11'>
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name='currency'
                      className='basic-single'
                      classNamePrefix='select'
                      handleChangeReactSelect={handleChangeCurrency}
                      options={currencyOptions}
                      value={selectedCurrencyOption}
                    />
                  </div>
                </div>
                <div className='col-lg-4 mb-2'>
                  <label className='font-size-xs fw-bolder mb-2 form-label'>
                    Company Description:
                  </label>
                  <div className='col-lg-11'>
                    <textarea
                      autoComplete='off'
                      name='clientDescription'
                      type='text'
                      className='form-control-1'
                      placeholder='Company Description'
                      onChange={(e) => handleChange(e)}
                      value={formData.clientDescription || ''}
                      maxLength={500}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )
        }
        <div className='form-group row mb-4'>
          <div className='col-lg-6' />
          <div className='col-lg-6'>
            <div className='col-lg-12 text-end'>
              <Link to={`/select-service`}
                className='btn btn-sm m-2 w-100px fw-bolder text-white'
                style={{ backgroundColor: '#f56c61' }}>
                Previous
              </Link>
              <button
                className="btn btn-primary btn-sm m-2 w-100px"
                onClick={(event) => {
                  handleSubmit(event);
                }}
              >
                {loadingAddClientSignup ? (
                  <span
                    className="spinner-border spinner-border-sm mx-3"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </body>
    </>
  )

}

const mapStateToProps = (state) => {
  const { addClientSignupStore, getClientSignupStore } = state;
  return {
    getCountrys: state && state.countryStore && state.countryStore.getCountrys,
    loadingCountry: state && state.countryStore && state.countryStore.loading,
    getStates: state && state.stateStore && state.stateStore.getStates,
    loadingState: state && state.stateStore && state.stateStore.loading,
    getCitys: state && state.cityStore && state.cityStore.getCitys,
    loadingCity: state && state.cityStore && state.cityStore.loading,
    getIndustry: state && state.industryStore && state.industryStore.getIndustry,
    getClientSignup: getClientSignupStore && getClientSignupStore.getClientSignup ? getClientSignupStore.getClientSignup : {},
    statusClientSignup: getClientSignupStore && getClientSignupStore.statusClientSignup ? getClientSignupStore.statusClientSignup : '',
    messageClientSignup: getClientSignupStore && getClientSignupStore.messageClientSignup ? getClientSignupStore.messageClientSignup : '',
    loadingClientSignup: getClientSignupStore && getClientSignupStore.loadingClientSignup ? getClientSignupStore.loadingClientSignup : false,
    addClientSignup: addClientSignupStore && addClientSignupStore.addClientSignup ? addClientSignupStore.addClientSignup : [],
    statusAddClientSignup: addClientSignupStore && addClientSignupStore.statusAddClientSignup ? addClientSignupStore.statusAddClientSignup : '',
    messageAddClientSignup: addClientSignupStore && addClientSignupStore.messageAddClientSignup ? addClientSignupStore.messageAddClientSignup : '',
    loadingAddClientSignup: addClientSignupStore && addClientSignupStore.loadingAddClientSignup ? addClientSignupStore.loadingAddClientSignup : false,
    didMount:
      getClientSignupStore && getClientSignupStore.didMount
        ? getClientSignupStore.didMount
        : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCountryDispatch: (params, headers) => dispatch(countryActions.getCountrys(params, headers)),
  countryActions: (data) => dispatch(countryActions.getCountrys(data)),
  getStateDispatch: (params, headers) => dispatch(stateActions.getStates(params, headers)),
  getCityDispatch: (params, headers) => dispatch(cityActions.getCitys(params, headers)),
  cityActions: (data) => dispatch(cityActions.getCitys(data)),
  getIndustryDispatch: (params, headers) => dispatch(industryActions.getIndustry(params, headers)),
  getClientSignupDispatch: (id) => dispatch(getClientSignupActions.get(id)),
  getClientSignupActions: () => dispatch(getClientSignupActions.get()),
  clearClientSignupDispatch: () =>
    dispatch(getClientSignupActions.clear()),
  addClientSignupDispatch: (payload) =>
    dispatch(postClientSignUpActions.add(payload)),
  clearaddClientSignupDispatch: () =>
    dispatch(postClientSignUpActions.clear()),
  didMountActions: (val) => dispatch(getClientSignupActions.didMount(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails)
