import React, { useEffect, useState } from "react";
import {
  CRM_FORM,
  STATUS_RESPONSE,
  SLICE_CHARACTERS,
  SESSION,
  API
} from "../../../../utils/constants";
import { useHistory } from "react-router-dom";
import { removeLocalStorage, addEllipsis, getLocalStorage, headersWithAuth } from "../../../../utils/helper";
import { connect } from "react-redux";
import _ from "lodash";
import { clientMgmtActions } from "../../../../store/actions";
import { warningAlert, confirmAlert } from "../../../../utils/alerts";
import { TooltipOnHover } from "../../../../theme/layout/components/tooltipOnHover/TooltipOnHover";

const Checkout = (props) => {
  const {
    addClientMgmtDispatch,
    clearaddClientMgmtDispatch,
    statusACL,
    messageACL,
    loadingACL,
    clientDetails,
    goBack,
    summary
  } = props;

  const redirectUrl = getLocalStorage(API.API_KEY)
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }
  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const history = useHistory();
  const [clientForm, setClientForm] = useState(false);
  const [merchantForm, setMerchantForm] = useState(false);
  const [integrationForm, setIntegrationForm] = useState(false);
  const [loginForm, setLoginForm] = useState(true);
  const [packageForm, setPackageForm] = useState(true);


  const clientData = {
    ...clientDetails,
    // package: [
    //   {
    //     _id: clientDetails.package && clientDetails.package.selectedId,
    //   },
    // ],
  };

  const removeFormStorage = () => {
    removeLocalStorage(CRM_FORM.COMPANY_DETAILS);
    removeLocalStorage(CRM_FORM.PACKAGE_DETAILS);
    removeLocalStorage(CRM_FORM.CRM_DETAILS);
    removeLocalStorage(CRM_FORM.CRM_DETAILS_TABLE);
    removeLocalStorage(CRM_FORM.PROCESSOR_DETAILS);
    removeLocalStorage(CRM_FORM.PROCESSOR_DETAILS_TABLE);
    removeLocalStorage(CRM_FORM.GATEWAY_DETAILS);
    removeLocalStorage(CRM_FORM.GATEWAY_DETAILS_TABLE);
    removeLocalStorage(CRM_FORM.MERCHANT_DETAILS);
    removeLocalStorage(CRM_FORM.USER_DETAILS);
    removeLocalStorage(CRM_FORM.ACTIVE_STEP);
    // removeLocalStorage(CRM_FORM.PACKAGE_TENURE);
    // removeLocalStorage(CRM_FORM.PACKAGE_SELECTED_ID);
    // removeLocalStorage(CRM_FORM.PACKAGE_PACK_APP_LIST);
    // removeLocalStorage(CRM_FORM.PACKAGE_CURRENT_LIST_VALUE);
    // removeLocalStorage(CRM_FORM.PACKAGE_PACK_ID);
  };

  const onConfirm = () => {
    history.push(isAuthorized ? '/client-management' : `/manage-client?apiKey=${redirectUrl}`);
    removeFormStorage();
  };

  useEffect(() => {
    if (statusACL === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageACL,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearaddClientMgmtDispatch();
    } else if (statusACL === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageACL, "", "Ok");
    }
    clearaddClientMgmtDispatch();
  }, [statusACL]);

  const handleSave = () => {
    addClientMgmtDispatch(clientData, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  return (
    <>
      <div className="card bg-skyBlue">
        <div className="col-md-12">
          <div className="row accordion">
            <div
              className="accordion md-accordion accordion-2"
              id="accordionEx7"
              role="tablist"
              aria-multiselectable="true"
            >
              <div
                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                role="tab"
                id="heading2"
              >
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordionEx7"
                        href="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapse2"
                        onClick={() => {
                          setClientForm((val) => !val);
                        }}
                      >
                        <h5 className="text-dark fw-bolder fs-4 mt-8">
                          {clientForm ? "Hide" : "Show"} Client Details{" "}
                          <i
                            className={`fas fa-angle-${clientForm ? "down" : "right"
                              } rotate-icon`}
                          />
                        </h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapse2"
              className="collapse"
              role="tabpanel"
              aria-labelledby="heading2"
              data-parent="#accordionEx7"
            >
              <div>
                <div className="row mt-3">
                  <div className="col-xl-2" />
                  <div className="col-xl-8 align-items-center">
                    <div className="w-100 h-100 rounded-3 bg-light bg-opacity-75 py-12 px-10">
                      <div className="mb-7">
                        <h2 className="text-primary text-start mb-10">
                          Client Details
                        </h2>
                      </div>
                      <div className="w-80 mb-10">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Company Name : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                <TooltipOnHover
                                  string={
                                    clientDetails &&
                                    clientDetails.client &&
                                    clientDetails.client.company
                                  }
                                  sliceUpTo={SLICE_CHARACTERS.UP_TO_40}
                                />
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Country : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {summary &&
                                  summary.client &&
                                  summary.client.country}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Industry : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {summary &&
                                  summary.client &&
                                  summary.client.industry}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Customer Id : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.customerId,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Address : &nbsp; &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.clientAddress,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                City : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {summary &&
                                  summary.client &&
                                  summary.client.city}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                State : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {summary &&
                                  summary.client &&
                                  summary.client.state}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Zip Code : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.clientZip,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Email : &nbsp;
                              </span>
                              <div className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.clientEmail,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </div>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Phone : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.clientPhoneNumber,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Extension : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.phoneNumberExtension,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                URL : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.clientURL,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Source : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.leadSource,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Client Complexity : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.clientComplexity,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Currency : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.currency,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Company Description : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.clientDescription,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Company Logo : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.client &&
                                  clientDetails.client.logo,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row accordion">
            <div
              className="accordion md-accordion accordion-2"
              id="accordionEx8"
              role="tablist"
              aria-multiselectable="true"
            >
              <div
                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                role="tab"
                id="heading2"
              >
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordionEx8"
                        href="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapse3"
                        onClick={() => {
                          setMerchantForm((val) => !val);
                        }}
                      >
                        <h5 className="text-dark fw-bolder fs-4 mt-8">
                          {merchantForm ? "Hide" : "Show"} Merchant Details{" "}
                          <i
                            className={`fas fa-angle-${merchantForm ? "down" : "right"
                              } rotate-icon`}
                          />
                        </h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapse3"
              className="collapse"
              role="tabpanel"
              aria-labelledby="heading2"
              data-parent="#accordionEx8"
            >
              <div>
                <div className="row mt-3">
                  <div className="col-xl-2" />
                  <div className="col-xl-8 align-items-center">
                    <div className="w-100 h-100 rounded-3 bg-light bg-opacity-75 py-12 px-10">
                      <div className="d-flex">
                        <div className="mb-7 col-lg-6">
                          <h2 className="text-primary text-start mb-10">
                            Merchant Details
                          </h2>
                        </div>
                        <div className="mb-7">
                          <h2 className="text-primary text-center mb-10">
                            MID Details
                          </h2>
                        </div>
                      </div>
                      <div className="w-80 mb-10">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Merchant Name : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.merchant &&
                                  clientDetails.merchant.merchantName,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Country : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {summary &&
                                  summary.merchant &&
                                  summary.merchant.country}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Industry : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {summary &&
                                  summary.merchant &&
                                  summary.merchant.industry}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Address : &nbsp; &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.merchant &&
                                  clientDetails.merchant.merchantAddress,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                City : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {summary &&
                                  summary.merchant &&
                                  summary.merchant.city}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                State : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {summary &&
                                  summary.merchant &&
                                  summary.merchant.state}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Zip Code : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.merchant &&
                                  clientDetails.merchant.merchantZip,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Email : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.merchant &&
                                  clientDetails.merchant.merchantEmail,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Phone : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.merchant &&
                                  clientDetails.merchant.merchantPhoneNumber,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Extension : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.merchant &&
                                  clientDetails.merchant.phoneNumberExtension,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Company Description : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.merchant &&
                                  clientDetails.merchant.companyDescription,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Mid : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.mid &&
                                  clientDetails.mid.mid,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Descriptor Name : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.mid &&
                                  clientDetails.mid.descriptorName,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Descriptor Id: &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.mid &&
                                  clientDetails.mid.descriptorId,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Doing Business As : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.mid &&
                                  clientDetails.mid.doingBusinessAs,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Acquirer Bin : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.mid &&
                                  clientDetails.mid.acquirerBin,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center mb-5">
                              <span className="fw-bold fs-6 text-gray-800">
                                Caid : &nbsp;
                              </span>
                              <span className="fw-bolder text-muted fs-6 w-120px text-start">
                                {addEllipsis(
                                  clientDetails &&
                                  clientDetails.mid &&
                                  clientDetails.mid.caid,
                                  SLICE_CHARACTERS.UP_TO_40
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row accordion">
            <div
              className="accordion md-accordion accordion-2"
              id="accordionEx9"
              role="tablist"
              aria-multiselectable="true"
            >
              <div
                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                role="tab"
                id="heading2"
              >
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordionEx9"
                        href="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4"
                        onClick={() => {
                          setIntegrationForm((val) => !val);
                        }}
                      >
                        <h5 className="text-dark fw-bolder fs-4 mt-8">
                          {integrationForm ? "Hide" : "Show"} Integration
                          Details{" "}
                          <i
                            className={`fas fa-angle-${integrationForm ? "down" : "right"
                              } rotate-icon`}
                          />
                        </h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapse4"
              className="collapse"
              role="tabpanel"
              aria-labelledby="heading2"
              data-parent="#accordionEx9"
            >
              <div>
                <div className="row mt-3">
                  <div className="col-xl-2" />
                  <div className="col-xl-8 align-items-center">
                    <div className="w-100 h-100 rounded-3 bg-light bg-opacity-75 py-12 px-10">
                      <div className="mb-7">
                        <h2 className="text-primary text-start mb-10">
                          Integrations
                        </h2>
                      </div>
                      <div className="w-80 mb-10">
                        <div className="d-flex align-items-center mb-5">
                          <span className="fw-bold fs-6 text-gray-800">
                            Selected CRMs : &nbsp;
                          </span>
                        </div>
                        {summary &&
                          summary.crm &&
                          summary.crm.map((item, i) => (
                            <div
                              className="my-2 fw-bolder text-muted fs-6 "
                              key={i}
                            >
                              {i + 1}. &nbsp;{item.crmLabel}
                            </div>
                          ))}
                      </div>
                      <div className="w-80 mb-10">
                        <div className="d-flex align-items-center mb-5">
                          <span className="fw-bold fs-6 text-gray-800">
                            Selected Payment Processor : &nbsp;
                          </span>
                        </div>
                        {summary &&
                          summary.processorData &&
                          summary.processorData.map((item, i) => (
                            <div
                              className="my-2 fw-bolder text-muted fs-6 "
                              key={i}
                            >
                              {i + 1}. &nbsp;{item.processorLabel}
                            </div>
                          ))}
                      </div>
                      <div className="w-80 mb-10">
                        <div className="d-flex align-items-center mb-5">
                          <span className="fw-bold fs-6 text-gray-800">
                            Selected Payment Gateway : &nbsp;
                          </span>
                        </div>
                        {summary &&
                          summary.paymentData &&
                          summary.paymentData.map((item, i) => (
                            <div
                              className="my-2 fw-bolder text-muted fs-6 "
                              key={i}
                            >
                              {i + 1}. &nbsp;{item.paymentGatewayLabel}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row accordion">
            <div
              className="accordion md-accordion accordion-2"
              id="accordionEx10"
              role="tablist"
              aria-multiselectable="true"
            >
              <div
                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                role="tab"
                id="heading2"
              >
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordionEx10"
                        href="#collapse5"
                        aria-expanded="true"
                        aria-controls="collapse5"
                        onClick={() => {
                          setLoginForm((val) => !val);
                        }}
                      >
                        <h5 className="text-dark fw-bolder fs-4 mt-8">
                          {loginForm ? "Hide" : "Show"} Login Details{" "}
                          <i
                            className={`fas fa-angle-${loginForm ? "down" : "right"
                              } rotate-icon`}
                          />
                        </h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapse5"
              className="collapse show"
              role="tabpanel"
              aria-labelledby="heading2"
              data-parent="#accordionEx10"
            >
              <div>
                <div className="row mt-3">
                  <div className="col-xl-2" />
                  <div className="col-xl-8 align-items-center">
                    <div className="w-100 h-100 rounded-3 bg-light bg-opacity-75 py-12 px-10">
                      <div className="mb-7">
                        <h2 className="text-primary text-start mb-10">
                          Login Info
                        </h2>
                      </div>
                      <div className="w-80 mb-10">
                        <div className="d-flex align-items-center mb-5">
                          <span className="fw-bold fs-6 text-gray-800">
                            First Name : &nbsp;
                          </span>
                          <span className="fw-bolder text-muted fs-6 w-120px text-start">
                            {addEllipsis(
                              clientDetails &&
                              clientDetails.user &&
                              clientDetails.user.firstName,
                              SLICE_CHARACTERS.UP_TO_40
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="w-80 mb-10">
                        <div className="d-flex align-items-center mb-5">
                          <span className="fw-bold fs-6 text-gray-800">
                            Last Name: &nbsp;
                          </span>
                          <span className="fw-bolder text-muted fs-6 w-120px text-start">
                            {addEllipsis(
                              clientDetails &&
                              clientDetails.user &&
                              clientDetails.user.lastName,
                              SLICE_CHARACTERS.UP_TO_40
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="w-80 mb-10">
                        <div className="d-flex align-items-center mb-5">
                          <span className="fw-bold fs-6 text-gray-800">
                            Email : &nbsp;
                          </span>
                          <span className="fw-bolder text-muted fs-6 w-120px text-start">
                            {addEllipsis(
                              clientDetails &&
                              clientDetails.user &&
                              clientDetails.user.email,
                              SLICE_CHARACTERS.UP_TO_40
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="w-80 mb-10">
                        <div className="d-flex align-items-center mb-5">
                          <span className="fw-bold fs-6 text-gray-800">
                            Role : &nbsp;
                          </span>
                          <span className="fw-bolder text-muted fs-6 w-120px text-start">
                            {addEllipsis(
                              summary && summary.user && summary.user.roleId,
                              SLICE_CHARACTERS.UP_TO_40
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row accordion">
            <div
              className="accordion md-accordion accordion-2"
              id="accordionEx11"
              role="tablist"
              aria-multiselectable="true"
            >
              <div
                className="card-header rgba-stylish-strong z-depth-1 mb-1"
                role="tab"
                id="heading2"
              >
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordionEx11"
                        href="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                        onClick={() => {
                          setPackageForm((val) => !val);
                        }}
                      >
                        <h5 className="text-dark fw-bolder fs-4 mt-8">
                          {packageForm ? "Hide" : "Show"} Package Details{" "}
                          <i
                            className={`fas fa-angle-${packageForm ? "down" : "right"
                              } rotate-icon`}
                          />
                        </h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="collapse6"
              className="collapse show"
              role="tabpanel"
              aria-labelledby="heading2"
              data-parent="#accordionEx11"
            >
              <div>
                <div className="row mt-3">
                  <div className="col-xl-2" />
                  <div className="col-xl-8 align-items-center">
                    <div className="w-100 h-100 rounded-3 bg-light bg-opacity-75 py-12 px-10">
                      <div className="mb-7">
                        <h2 className="text-primary text-start mb-10">
                          Package
                        </h2>
                      </div>
                      <div className="w-80 mb-10">

                        {clientDetails &&
                          clientDetails.package &&
                          clientDetails.package.package ? (
                          <div className="d-flex align-items-center mb-5">
                            <span className="fw-bold fs-6 text-gray-800">
                              Title : &nbsp;
                            </span>
                            <span className="fw-bolder text-muted fs-6 w-120px text-start">
                              {clientDetails &&
                                clientDetails.package &&
                                clientDetails.package.package}
                            </span>
                          </div>
                        ) : null}
                        {clientDetails &&
                          clientDetails.package &&
                          clientDetails.package.tenure ? (
                          <div className="d-flex align-items-center mb-5">
                            <span className="fw-bold fs-6 text-gray-800">
                              Tenure : &nbsp;
                            </span>
                            <span className="fw-bolder text-muted fs-6 w-120px text-start">
                              {clientDetails &&
                                clientDetails.package &&
                                clientDetails.package.tenure}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="form-group row mb-2 mt-3">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <div className="col-lg-11">
                <button
                  className="btn btn-success m-2 fa-pull-right"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  {loadingACL ? (
                    <span
                      className="spinner-border spinner-border-sm mx-3"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Create Client Account"
                  )}
                </button>
                <button
                  className="btn btn-darkRed m-2 fa-pull-right"
                  onClick={() => {
                    goBack(3);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loadingACL:
    state && state.addClientMgmtStore && state.addClientMgmtStore.loadingACL,
  statusACL:
    state && state.addClientMgmtStore && state.addClientMgmtStore.statusACL,
  messageACL:
    state && state.addClientMgmtStore && state.addClientMgmtStore.messageACL,
  dataACL:
    state && state.addClientMgmtStore && state.addClientMgmtStore.dataACL
});

const mapDispatchToProps = (dispatch) => ({
  addClientMgmtDispatch: (data, headers) =>
    dispatch(clientMgmtActions.addClientMgmt(data, headers)),
  clearaddClientMgmtDispatch: () =>
    dispatch(clientMgmtActions.clearaddClientMgmt())
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
