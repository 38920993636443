import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addAssignUserActionsTypes,
  addAssignUserActions,
  assignUserActionsTypes,
  assignUserActions,
  deleteAssignUserActionsTypes,
  deleteAssignUserActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addAssignUser (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.assignUser, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addAssignUserActions.saveResponse(json.data))
    } else {
      yield put(addAssignUserActions.clear([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addAssignUserActionsTypes.RESPONSE, data })
  }
}

function * fetchAssignUser (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.assignUser, { params, headers:headers })
    if (json.status === 200) {
      yield put(assignUserActions.saveassignUserResponse(json.data))
    } else {
      yield put(assignUserActions.saveassignUserResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: assignUserActionsTypes.SAVE_ASSIGN_USER_RESPONSE, data })
  }
}

function * deleteAssignUser (actions) {
  const { id, headers } = actions
  const deleteUrl = `${serviceList.assignUser}/${id} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deleteAssignUserActions.savemResponse(json.data))
    } else {
      yield put(deleteAssignUserActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteAssignUserActionsTypes.RESPONSE, data })
  }
}

export function * fetchClientFilterWatcher () {
  yield all([
    yield takeLatest(addAssignUserActionsTypes.REQUEST, addAssignUser),
    yield takeLatest(assignUserActionsTypes.GET_ASSIGN_USER, fetchAssignUser),
    yield takeLatest(deleteAssignUserActionsTypes.REQUEST, deleteAssignUser)
  ])
}
