import React, { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import { pickBy } from "lodash";
import serviceList from '../../services/serviceList'
import { exportActions } from '../../store/actions/export'
import { defaultExportCol } from './fromData/initialStateTableLayout'
import { getLocalStorage, headersWithAuth } from '../../utils/helper'
import { LAYOUT_DETAILS, DATE } from '../../utils/constants'
import moment from 'moment'

const Export = (props) => {
  const {
    getExportDispatch, clearPostsDispatch, data, status, exportLoading,
    searchParams
  } = props
  const [list, setList] = useState(null)
  const csvLink = React.useRef()
  const localExportData = JSON.parse(getLocalStorage(LAYOUT_DETAILS.EXPORT_IBA))
  const valid = Object.values(localExportData).some(el => el === true)
  const selectdCols = valid ? localExportData : defaultExportCol

  const getData = () => {
    setList(null)
    const url = `${serviceList.issuerbankInquiry}?skipPagination=true`
    const params = {
      ...searchParams
    }
    getExportDispatch(url, pickBy(params), headersWithAuth)
  }

  const getselectedExportCols = (arr, selectedCol) => {
    const newArray = []
    arr &&
      arr.length > 0 &&
      arr.forEach((element) => {
        const obj = {}
        if (element.alertId && selectedCol.alertId) {
          obj.alertId = element.alertId
        }
        if (element.alertType && selectedCol.alertType) {
          obj.alertType = element.alertType
        }
        if (element.issuerBankId && selectedCol.issuerBankId) {
          obj.issuerBankId = element.issuerBankId.bankId
        }
        if (element.alertDate && selectedCol.alertDate) {
          obj.alertDate = moment(element.alertDate).format(
            DATE.DATE_FORMAT_MINS
          )
        }
        if (element.deflected && selectedCol.deflected) {
          obj.deflected = element.deflected
        }
        if (element.cardType && selectedCol.cardType) {
          obj.cardType = element.cardType
        }
        if (element.notificationType && selectedCol.notificationType) {
          obj.notificationType = element.notificationType
        }
        if (element.transactionType && selectedCol.transactionType) {
          obj.transactionType = element.transactionType
        }
        if (element.upiTransactionId && selectedCol.upiTransactionId) {
          obj.upiTransactionId = element.upiTransactionId
        }
        if (element.cardBin && selectedCol.cardBin) {
          obj.cardBin = element.cardBin
        }
        if (element.transactionId && selectedCol.transactionId) {
          obj.transactionId = element.transactionId
        }
        if (element.customerFirstName && selectedCol.customerFirstName) {
          obj.customerFirstName = element.customerFirstName
        }
        if (element.customerLastName && selectedCol.customerLastName) {
          obj.customerLastName = element.customerLastName
        }
        if (element.alertDescriptor && selectedCol.alertDescriptor) {
          obj.alertDescriptor = element.alertDescriptor
        }
        if (
          element.alertDescriptorContact &&
          selectedCol.alertDescriptorContact
        ) {
          obj.alertDescriptorContact = element.alertDescriptorContact
        }
        if (element.cardFirstSixDigits && selectedCol.cardFirstSixDigits) {
          obj.cardFirstSixDigits = element.cardFirstSixDigits
        }
        if (element.cardLastFourDigits && selectedCol.cardLastFourDigits) {
          obj.cardLastFourDigits = element.cardLastFourDigits
        }
        if (element.expirationMonth && selectedCol.expirationMonth) {
          obj.expirationMonth = element.expirationMonth
        }
        if (element.expirationYear && selectedCol.expirationYear) {
          obj.expirationYear = element.expirationYear
        }
        if (element.clientId && selectedCol.clientId) {
          obj.clientId = element.clientId
            ? `${element &&
            element.clientId &&
            element.clientId.company
            }`
            : '--'
        }
        if (element.merchantId && selectedCol.merchantId) {
          obj.merchantId = element.merchantId
            ? `${element &&
            element.merchantId &&
            element.merchantId.merchantFirstName
            } ${element &&
            element.merchantId &&
            element.merchantId.merchantLastName
            }`
            : '--'
        }
        if (element.partnerId && selectedCol.partnerId) {
          obj.partnerId = element.partnerId
            ? `${element &&
            element.partnerId &&
            element.partnerId.partnerFirstName
            } ${element &&
            element.partnerId &&
            element.partnerId.partnerLastName
            }`
            : '--'
        }
        if (element.refundedBeforeTime && selectedCol.refundedBeforeTime) {
          obj.refundedBeforeTime = element.refundedBeforeTime
        }
        if (element.outputResolutionDate && selectedCol.outputResolutionDate) {
          obj.outputResolutionDate = moment(
            element.outputResolutionDate
          ).format(DATE.DATE_FORMAT_MINS)
        }
        if (element.expirationStatus && selectedCol.expirationStatus) {
          obj.expirationStatus = element.expirationStatus
        }
        if (element.isLastSixMonths && selectedCol.isLastSixMonths) {
          obj.isLastSixMonths = element.isLastSixMonths
        }
        if (element.provider && selectedCol.provider) {
          obj.provider = element.provider
        }
        if (element.cardNumber && selectedCol.cardNumber) {
          obj.cardNumber = element.cardNumber
        }
        if (element.salesTransactionDate && selectedCol.salesTransactionDate) {
          obj.salesTransactionDate = moment(
            element.salesTransactionDate
          ).format(DATE.DATE_FORMAT_MINS)
        }
        if (
          element.salesTransactionAmount &&
          selectedCol.salesTransactionAmount
        ) {
          obj.salesTransactionAmount = element.salesTransactionAmount
        }
        if (
          element.salesTransactionCurrency &&
          selectedCol.salesTransactionCurrency
        ) {
          obj.salesTransactionCurrency = element.salesTransactionCurrency
        }
        if (element.customerAddress && selectedCol.customerAddress) {
          obj.customerAddress = element.customerAddress
        }
        if (
          element.acquirerReferrenceNumber &&
          selectedCol.acquirerReferrenceNumber
        ) {
          obj.acquirerReferrenceNumber = element.acquirerReferrenceNumber
        }
        if (element.assignedToId && selectedCol.assignedToId) {
          obj.assignedToId = element.assignedToId
        }
        if (element.authCode && selectedCol.authCode) {
          obj.authCode = element.authCode
        }
        if (element.blacklistCustomer && selectedCol.blacklistCustomer) {
          obj.blacklistCustomer = element.blacklistCustomer
        }
        if (element.chargeBackId && selectedCol.chargeBackId) {
          obj.chargeBackId = element.chargeBackId
        }
        if (element.chargeBackId && selectedCol.chargeBackId) {
          obj.chargeBackId = element.chargeBackId
        }
        if (element.chargeBackReasonCode && selectedCol.chargeBackReasonCode) {
          obj.chargeBackReasonCode = element.chargeBackReasonCode
        }
        if (element.chargeBackSaleDate && selectedCol.chargeBackSaleDate) {
          obj.chargeBackSaleDate = moment(element.chargeBackSaleDate).format(
            DATE.DATE_FORMAT_MINS
          )
        }
        if (element.chargeBackStatus && selectedCol.chargeBackStatus) {
          obj.chargeBackStatus = element.chargeBackStatus
        }
        if (element.city && selectedCol.city) {
          obj.city = element.city
        }
        if (element.transactionAmount && selectedCol.transactionAmount) {
          obj.transactionAmount = element.transactionAmount
        }
        if (element.cityOfMerchant && selectedCol.cityOfMerchant) {
          obj.cityOfMerchant = element.cityOfMerchant
        }
        if (element.cardType && selectedCol.cardType) {
          obj.cardType = element.cardType
        }
        if (element.deflected && selectedCol.deflected) {
          obj.deflected = element.deflected
        }
        if (
          element.updatedDeflectionstatus &&
          selectedCol.updatedDeflectionstatus
        ) {
          obj.updatedDeflectionstatus = element.updatedDeflectionstatus
        }
        if (
          element.updatedDeflectionDate &&
          selectedCol.updatedDeflectionDate
        ) {
          obj.updatedDeflectionDate = moment(
            element.updatedDeflectionDate
          ).format(DATE.DATE_FORMAT_MINS)
        }
        if (element.status && selectedCol.status) {
          obj.status = element.status
        }
        newArray.push(obj)
        setList(newArray)
      })
  }

  useEffect(() => {
    if (status === 'ok') {
      getselectedExportCols(data, selectdCols)
    }
  }, [status])

  useEffect(() => {
    if (list && list.length > 0) {
      csvLink.current.link.click()
      setTimeout(() => {
        clearPostsDispatch()
        setList([])
      }, 300)
    }
  }, [list])

  return (
    <>
      <div>
        <button
          onClick={() => { getData() }}
          className='btn btn-sm btn-light-primary btn-responsive font-5vw me-3 pull-right'
        >
          <i className='bi bi-file-earmark-arrow-up' />
          {exportLoading ? 'Loading...' : 'Export List'}
        </button>
        <CSVLink
          data={list && list.length > 0 ? list : 'Preparing Data...'}
          type='button'
          className='d-none'
          filename='Issuer_bank_Alert.csv'
          onClick={() => { }}
          ref={csvLink}
          target='_blank'
          id='csvLink'
        >
          <i className='bi bi-file-earmark-arrow-down' />
          Export List
        </CSVLink>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { exportStore } = state
  return {
    data: exportStore && exportStore.exports && exportStore.exports.data ? exportStore.exports.data : [],
    status: exportStore && exportStore.exports && exportStore.exports.status ? exportStore.exports.status : '',
    count: exportStore && exportStore.exports && exportStore.exports.count ? exportStore.exports.count : 0,
    exportLoading: exportStore && exportStore.exportLoading ? exportStore.exportLoading : false
  }
}

const mapDispatchToProps = dispatch => ({
  getExportDispatch: (url, params, headers) => dispatch(exportActions.getExport(url, params, headers)),
  clearPostsDispatch: () => dispatch(exportActions.clearPosts())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Export)
