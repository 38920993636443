import React, { useEffect } from 'react'
import { useLocation, Route } from 'react-router-dom'
import { Registration } from '../../components/auth/Registration'
import ForgotPassword from '../../components/auth/ForgotPassword'
import Login from '../../components/auth/Login'
import { toAbsoluteUrl } from '../../theme/helpers'
import routeConfig from '../../routing/routeConfig'
import { colors } from '../../utils/constants'
import ResetPassword from '../../components/auth/ResetPassword'
import CreateCase from '../../containers/createCase'
import ImportCase from '../createCase/import'
import CreateCaseAlerts from '../createCase/CreateCaseAlerts'
import ImportAlertCase from '../createCase/importAlert'

export function AuthPage (props) {
  const query = useLocation().search
  const pathName = useLocation().pathname

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  const GetComponent = () => {
    const pathName = useLocation().pathname
    if (pathName === routeConfig.registration) {
      return <Registration />
    }
    if (pathName === routeConfig.forgotPassword) {
      return <ForgotPassword query={query} />
    }
    if (pathName === routeConfig.resetPassword) {
      return <ResetPassword />
    }
    return <Login />
  }

  return (
    <>
      {
        (pathName === routeConfig.userSdk) ||
         (pathName === routeConfig.import) ||
          (pathName === routeConfig.userSdkAlerts) ||
          (pathName === routeConfig.importAlert)
          ? (
            <>
              <Route path='/issuer-bank-inquiries-sdk' component={CreateCase} />
              <Route path='/issuer-bank-alerts-sdk' component={CreateCaseAlerts} />
              <Route path='/import-inquiries' component={ImportCase} />
              <Route path='/import-alert' component={ImportAlertCase} />
            </>
            ) : (
              <div
                className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                '/media/illustrations/sketchy-1/14.png'
              )})`,
                  backgroundColor: colors.oxfordBlue
                }}
              >
                {/* begin::Content */}
                <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                  {/* begin::Logo */}
                  <a href='#' className='mb-12'>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/logo-1.svg')}
                      className='h-65px'
                    />
                  </a>
                  {/* end::Logo */}
                  {/* begin::Wrapper */}
                  <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    {GetComponent()}
                  </div>
                  {/* end::Wrapper */}
                </div>
                {/* end::Content */}
              </div>
            )
      }
    </>
  )
}
