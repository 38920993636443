export const getComponentsActionsTypes = {
  REQUEST: 'GET_COMPONENTS_REQUEST',
  RESPONSE: 'GET_COMPONENTS_RESPONSE',
  ERROR: 'GET_COMPONENTS_ERROR',
  CLEAR: 'GET_COMPONENTS_CLEAR'
}

export const getComponentsActions = {
  get: (params, headers) => ({
    type: getComponentsActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getComponentsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getComponentsActionsTypes.CLEAR
  })
}

export const addComponentsActionsTypes = {
  REQUEST: 'ADD_COMPONENTS_REQUEST',
  RESPONSE: 'ADD_COMPONENTS_RESPONSE',
  ERROR: 'ADD_COMPONENTS_ERROR',
  CLEAR: 'ADD_COMPONENTS_CLEAR'
}

export const addComponentsActions = {
  add: (data, headers) => {
    return {
      type: addComponentsActionsTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addComponentsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addComponentsActionsTypes.CLEAR
  })
}

export const getComponentsDetailsActionsTypes = {
  REQUEST: 'GET_COMPONENTS_DETAILS_REQUEST',
  RESPONSE: 'GET_COMPONENTS_DETAILS_RESPONSE',
  ERROR: 'GET_COMPONENTS_DETAILS_ERROR',
  CLEAR: 'GET_COMPONENTS_DETAILS_CLEAR'
}
export const getComponentsDetailsActions = {
  getDetails: (id, headers) => ({
    type: getComponentsDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getComponentsDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getComponentsDetailsActionsTypes.CLEAR
  })
}

export const updateComponentsActionsTypes = {
  REQUEST: 'UPDATE_COMPONENTS_REQUEST',
  RESPONSE: 'UPDATE_COMPONENTS_RESPONSE',
  ERROR: 'UPDATE_COMPONENTS_ERROR',
  CLEAR: 'UPDATE_COMPONENTS_CLEAR'
}

export const updateComponentsActions = {
  update: (id, params, headers) => {
    return {
      type: updateComponentsActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updateComponentsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updateComponentsActionsTypes.CLEAR
  })
}

export const deleteComponentsActionsTypes = {
  REQUEST: 'DELETE_COMPONENTS_REQUEST',
  RESPONSE: 'DELETE_COMPONENTS_RESPONSE',
  ERROR: 'DELETE_COMPONENTS_ERROR',
  CLEAR: 'DELETE_COMPONENTS_CLEAR'
}

export const deleteComponentsActions = {
  delete: (params, headers) => ({
    type: deleteComponentsActionsTypes.REQUEST,
    params, headers
  }),
  savemResponse: (data) => ({
    type: deleteComponentsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteComponentsActionsTypes.CLEAR
  })
}
