import { put, takeLatest, all } from "redux-saga/effects";
import { API_MESSAGES } from "../../utils/constants";
import axiosInstance from "../../services";
import {
  transactionGetDetailsTypes,
  transactionDetailsActions,
  editTransactionTypes,
  editTransactionActions,
  customerGetDetailsTypes,
  customerDetailsActions,
  editCustomerTypes,
  editCustomersActions,
  purchaseGetDetailsTypes,
  purchaseDetailsActions,
  editPurchaseTypes,
  editPurchasesActions,
  merchantCBGetDetailsTypes,
  merchantCBDetailsActions,
  editMerchantCBTypes,
  editMerchantCBsActions,
  addDocumentActions,
  addDocumentTypes,
  docTypeActionsTypes,
  docTypeActions,
  documentGetDetailsTypes,
  documentDetailsActions,
  documentDeleteTypes,
  deleteDocumentActions,
  rebuttalGetDetailsTypes,
  rebuttalDetailsActions,
  addRebuttalActions,
  addRebuttalTypes,
  emailActions,
  emailActionsTypes,
  addDownloadTypes,
  addDownloadActions,
  addZipTypes,
  addZipActions,
  addEmailTypes,
  addEmailActions,
  addSendLinkTypes,
  addSendLinkActions,
  SendLinkActionsTypes,
  SendLinkActions,
  merchantEvidenceActionsTypes,
  merchantEvidenceActions,
  addMerchantEvidenceTypes,
  addMerchantEvidenceActions,
  getAnalystActionsTypes,
  getAnalystActions,
  addEvidenceTypes,
  addEvidenceActions,
  getLinkMerchantActionsTypes,
  getLinkMerchantActions,
  sendCustomMailTypes,
  sendCustomMailActions
} from "../actions";
import serviceList from "../../services/serviceList";

function* getTransactionDetails(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.transaction}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(
        transactionDetailsActions.saveTransactionDetailsResponse(json.data)
      );
    } else {
      yield put(transactionDetailsActions.saveTransactionDetailsResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({
      type: transactionGetDetailsTypes.TRANSACTION_DETAILS_RESPONSE,
      data,
    });
  }
}

function* editTransaction(actions) {
  const { payload, headers } = actions;
  try {
    const { id, params } = payload && payload;
    const endPointUrl = `${serviceList.transaction}/${id}`;
    const json = yield axiosInstance.put(endPointUrl, params, {headers: headers});
    if (json.status === 200) {
      yield put(editTransactionActions.saveeditTransactionResponse(json.data));
    } else {
      yield put(editTransactionActions.saveeditTransactionResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: editTransactionTypes.RESPONSE, data });
  }
}

function* getCustomerDetails(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.customer}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(customerDetailsActions.saveCustomerDetailsResponse(json.data));
    } else {
      yield put(customerDetailsActions.saveCustomerDetailsResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({
      type: customerGetDetailsTypes.CUSTOMER_DETAILS_RESPONSE,
      data,
    });
  }
}

function* editCustomer(actions) {
  const { payload, headers } = actions;
  try {
    const { id, params } = payload && payload;
    const endPointUrl = `${serviceList.customer}/${id}`;
    const json = yield axiosInstance.put(endPointUrl, params, {headers: headers});
    if (json.status === 200) {
      yield put(editCustomersActions.saveeditCustomersResponse(json.data));
    } else {
      yield put(editCustomersActions.saveeditCustomersResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: editCustomerTypes.RESPONSE, data });
  }
}

function* getPurchaseDetails(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.purchase}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(purchaseDetailsActions.savePurchaseDetailsResponse(json.data));
    } else {
      yield put(purchaseDetailsActions.savePurchaseDetailsResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({
      type: purchaseGetDetailsTypes.PURCHASE_DETAILS_RESPONSE,
      data,
    });
  }
}

function* editPurchase(actions) {
  const { payload, headers } = actions;
  try {
    const { id, params } = payload && payload;
    const endPointUrl = `${serviceList.purchase}/${id}`;
    const json = yield axiosInstance.put(endPointUrl, params, {headers: headers});
    if (json.status === 200) {
      yield put(editPurchasesActions.saveeditPurchasesResponse(json.data));
    } else {
      yield put(editPurchasesActions.saveeditPurchasesResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: editPurchaseTypes.RESPONSE, data });
  }
}

function* getMerchantDetails(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.merchantCB}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(
        merchantCBDetailsActions.saveMerchantDetailsResponse(json.data)
      );
    } else {
      yield put(merchantCBDetailsActions.saveMerchantDetailsResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({
      type: merchantCBGetDetailsTypes.MERCHANT_CB_DETAILS_RESPONSE,
      data,
    });
  }
}

function* editMerchant(actions) {
  const { payload, headers } = actions;
  try {
    const { id, params } = payload && payload;
    const endPointUrl = `${serviceList.merchantCB}/${id}`;
    const json = yield axiosInstance.put(endPointUrl, params, {headers: headers});
    if (json.status === 200) {
      yield put(editMerchantCBsActions.saveeditMerchantCBsResponse(json.data));
    } else {
      yield put(editMerchantCBsActions.saveeditMerchantCBsResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: editMerchantCBTypes.RESPONSE, data });
  }
}

function* addDocument(action) {
  const { payload, headers } = action;
  try {
    const json = yield axiosInstance.post(serviceList.addDocument, payload, {headers: headers});
    if (json.status === 200) {
      yield put(addDocumentActions.saveaddDocumentResponse(json.data));
    } else {
      yield put(addDocumentActions.clearaddDocument([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: addDocumentTypes.RESPONSE, data });
  }
}
function* addEvidence(action) {
  const { payload, headers } = action;
  try {
    const json = yield axiosInstance.post(serviceList.getDocType, payload, {headers: headers});
    if (json.status === 200) {
      yield put(addEvidenceActions.saveResponse(json.data));
    } else {
      yield put(addEvidenceActions.clear([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: addEvidenceTypes.RESPONSE, data });
  }
}

function* fetchDocType(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.getDocType}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(docTypeActions.savedocTypeResponse(json.data));
    } else {
      yield put(docTypeActions.savedocTypeResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: docTypeActionsTypes.SAVE_DOC_TYPE_RESPONSE, data });
  }
}

function* getDocumentDetails(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.document}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(documentDetailsActions.saveDocumentDetailsResponse(json.data));
    } else {
      yield put(documentDetailsActions.saveDocumentDetailsResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({
      type: documentDeleteTypes.SAVE_DELETE_DOCUMENT_RESPONSE,
      data,
    });
  }
}

function* deleteDocument(actions) {
  const { params, headers } = actions;
  try {
    const deleteUrl = `${serviceList.addDocument}/${params} `;
    const json = yield axiosInstance.delete(deleteUrl, {headers: headers});
    if (json.status === 200) {
      yield put(deleteDocumentActions.savemResponseDeleteDocument(json.data));
    } else {
      yield put(deleteDocumentActions.savemResponseDeleteDocument([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({
      type: documentDeleteTypes.SAVE_DELETE_DOCUMENT_RESPONSE,
      data,
    });
  }
}

function* getRebuttalDetails(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.rebuttal}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(rebuttalDetailsActions.saveRebuttalDetailsResponse(json.data));
    } else {
      yield put(rebuttalDetailsActions.saveRebuttalDetailsResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({
      type: rebuttalGetDetailsTypes.REBUTTAL_DETAILS_RESPONSE,
      data,
    });
  }
}

function* addRebuttal(actions) {
  const { payload, headers } = actions;
  try {
    const { id, params } = payload && payload;
    const endPointUrl = `${serviceList.addRebuttal}/${id}`;
    const json = yield axiosInstance.put(endPointUrl, params, {headers: headers});
    if (json.status === 200) {
      yield put(addRebuttalActions.saveaddRebuttalResponse(json.data));
    } else {
      yield put(addRebuttalActions.clearaddRebuttal([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: addRebuttalTypes.RESPONSE, data });
  }
}

function* fetchEmail(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.email}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(emailActions.saveemailResponse(json.data));
    } else {
      yield put(emailActions.saveemailResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: emailActionsTypes.SAVE_EMAIL_RESPONSE, data });
  }
}

function* addEmail(action) {
  const { payload, headers } = action;
  try {
    const json = yield axiosInstance.post(serviceList.addEmail, payload, {headers: headers});
    if (json.status === 200) {
      yield put(addEmailActions.saveaddEmailResponse(json.data));
    } else {
      yield put(addEmailActions.clearaddEmail([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: addEmailTypes.RESPONSE, data });
  }
}

function* addDownload(action) {
  const { payload, headers } = action;
  try {
    const json = yield axiosInstance.post(serviceList.addDownload, payload, {headers: headers});
    if (json.status === 200) {
      yield put(addDownloadActions.saveaddDownloadResponse(json.data));
    } else {
      yield put(addDownloadActions.clearaddDownload([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: addDownloadTypes.RESPONSE, data });
  }
}

function* addZip(action) {
  const { payload, headers } = action;
  try {
    const json = yield axiosInstance.post(serviceList.addZip, payload, {headers: headers});
    if (json.status === 200) {
      yield put(addZipActions.saveaddZipResponse(json.data));
    } else {
      yield put(addZipActions.clearaddZip([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: addZipTypes.RESPONSE, data });
  }
}

function* addSendLink(action) {
  const { payload, headers } = action;
  try {
    const json = yield axiosInstance.post(serviceList.sendLink, payload, {headers: headers});
    if (json.status === 200) {
      yield put(addSendLinkActions.saveaddSendLinkResponse(json.data));
    } else {
      yield put(addSendLinkActions.clearaddSendLink([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: addSendLinkTypes.RESPONSE, data });
  }
}

function* fetchgetLinkMerchant(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.sendLink}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(getLinkMerchantActions.saveResponse(json.data));
    } else {
      yield put(getLinkMerchantActions.saveResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: getLinkMerchantActionsTypes.RESPONSE, data });
  }
}

function* fetchMailHistory(actions) {
  const { id, headers } = actions;
  try {
    const endPointUrl = `${serviceList.MailHistory}/${id}`;
    const json = yield axiosInstance.get(endPointUrl, {headers: headers});
    if (json.status === 200) {
      yield put(SendLinkActions.saveResponse(json.data));
    } else {
      yield put(SendLinkActions.saveResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: SendLinkActionsTypes.RESPONSE, data });
  }
}

function* fetchMerchantEvidence(actions) {
  const { id, headers } = actions;
  const endPointUrl = `${serviceList.getMerchantEvidence}/${id}`;
  try {
    const json = yield axiosInstance.get(endPointUrl, {
      headers: { ...headers, Authorization: "" },
    });
    if (json.status === 200) {
      yield put(
        merchantEvidenceActions.savemerchantEvidenceResponse(json.data)
      );
    } else {
      yield put(merchantEvidenceActions.savemerchantEvidenceResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({
      type: merchantEvidenceActionsTypes.SAVE_MERCHANT_EVIDENCE_RESPONSE,
      data,
    });
  }
}

function* addMerchantEvidence(action) {
  const { payload, url, headers } = action;
  const uploadUrl = url || serviceList.addMerchantEvidence;
  try {
    const json = yield axiosInstance.post(uploadUrl, payload, {
      headers: {...headers, Authorization: "" },
    });
    if (json.status === 200) {
      yield put(
        addMerchantEvidenceActions.saveaddMerchantEvidenceResponse(json.data)
      );
    } else {
      yield put(addMerchantEvidenceActions.clearaddMerchantEvidence([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: addMerchantEvidenceTypes.RESPONSE, data });
  }
}

function* fetchAnalyst(actions) {
  const { params, headers } = actions;
  try {
    const json = yield axiosInstance.get(serviceList.analyst, { params, headers:headers });
    if (json.status === 200) {
      yield put(getAnalystActions.saveResponse(json.data));
    } else {
      yield put(getAnalystActions.saveResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: getAnalystActionsTypes.RESPONSE, data });
  }
}

function* sendCustomMail(action) {
  const { payload, headers } = action;
  try {
    const json = yield axiosInstance.post(serviceList.sendCustomMail, payload, {headers:headers});
    if (json.status === 200) {
      yield put(sendCustomMailActions.saveResponse(json.data));
    } else {
      yield put(sendCustomMailActions.clear([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: sendCustomMailTypes.RESPONSE, data });
  }
}

export function* fetchTransactionWatcher() {
  yield all([
    yield takeLatest(
      transactionGetDetailsTypes.GET_TRANSACTION_DETAILS,
      getTransactionDetails
    ),
    yield takeLatest(editTransactionTypes.REQUEST, editTransaction),
    yield takeLatest(
      customerGetDetailsTypes.GET_CUSTOMER_DETAILS,
      getCustomerDetails
    ),
    yield takeLatest(editCustomerTypes.REQUEST, editCustomer),
    yield takeLatest(
      purchaseGetDetailsTypes.GET_PURCHASE_DETAILS,
      getPurchaseDetails
    ),
    yield takeLatest(editPurchaseTypes.REQUEST, editPurchase),
    yield takeLatest(
      merchantCBGetDetailsTypes.GET_MERCHANT_CB_DETAILS,
      getMerchantDetails
    ),
    yield takeLatest(editMerchantCBTypes.REQUEST, editMerchant),
    yield takeLatest(addDocumentTypes.REQUEST, addDocument),
    yield takeLatest(addEvidenceTypes.REQUEST, addEvidence),
    yield takeLatest(docTypeActionsTypes.GET_DOC_TYPE, fetchDocType),
    yield takeLatest(
      documentGetDetailsTypes.GET_DOCUMENT_DETAILS,
      getDocumentDetails
    ),
    yield takeLatest(documentDeleteTypes.DELETE_DOCUMENT, deleteDocument),
    yield takeLatest(addRebuttalTypes.REQUEST, addRebuttal),
    yield takeLatest(
      rebuttalGetDetailsTypes.GET_REBUTTAL_DETAILS,
      getRebuttalDetails
    ),
    yield takeLatest(emailActionsTypes.GET_EMAIL, fetchEmail),
    yield takeLatest(addDownloadTypes.REQUEST, addDownload),
    yield takeLatest(addZipTypes.REQUEST, addZip),
    yield takeLatest(addEmailTypes.REQUEST, addEmail),
    yield takeLatest(addSendLinkTypes.REQUEST, addSendLink),
    yield takeLatest(getLinkMerchantActionsTypes.REQUEST, fetchgetLinkMerchant),
    yield takeLatest(SendLinkActionsTypes.REQUEST, fetchMailHistory),
    yield takeLatest(
      merchantEvidenceActionsTypes.GET_MERCHANT_EVIDENCE,
      fetchMerchantEvidence
    ),
    yield takeLatest(addMerchantEvidenceTypes.REQUEST, addMerchantEvidence),
    yield takeLatest(getAnalystActionsTypes.REQUEST, fetchAnalyst),
    yield takeLatest(sendCustomMailTypes.REQUEST, sendCustomMail),
  ]);
}
