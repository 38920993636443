import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  issuerbanklistActions,
  issuerbanklistActionsTypes,
  issuerbankalertlistActions,
  issuerbankalertlistActionsTypes
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchIssuerbanklist (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.issuerbanklist, { params, headers:headers })
    if (json.status === 200) {
      yield put(issuerbanklistActions.saveIssuerbanklistResponse(json.data))
    } else {
      yield put(issuerbanklistActions.saveIssuerbanklistResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: issuerbanklistActionsTypes.SAVE_ISSUER_BANK_LIST_RESPONSE, data })
  }
}

function * fetchIssuerbankAlertlist (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.issuerbankAlert, { params, headers:headers })
    if (json.status === 200) {
      yield put(issuerbankalertlistActions.saveIssuerbankalertlistResponse(json.data))
    } else {
      yield put(issuerbankalertlistActions.saveIssuerbankalertlistResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: issuerbankalertlistActionsTypes.SAVE_ISSUER_BANK_ALERT_LIST_RESPONSE, data })
  }
}

export function * fetchIssuerbanklistWatcher () {
  yield all([
    yield takeLatest(issuerbanklistActionsTypes.GET_ISSUER_BANK_LIST, fetchIssuerbanklist),
    yield takeLatest(issuerbankalertlistActionsTypes.GET_ISSUER_BANK_ALERT_LIST, fetchIssuerbankAlertlist)
  ])
}
