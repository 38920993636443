import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../helpers'
import { HeaderNotificationsMenu } from '../../../partials'
import HeaderUserMenu from '../../../partials/layout/header-menus/HeaderUserMenu'
import { useLayout } from '../../core'
import { connect } from 'react-redux'
import { userProfilesActions, UserNotificationActions, ShopifyConnectActions } from '../../../../store/actions'
import userPlaceholder from '../../../../assets/userPlaceholder.png'
import { API_URL } from '../../../../utils/constants'
import { headersWithAuth, setLocalStorage, getLocalStorageMoment } from '../../../../utils/helper'
import dummy from '../../../../dummy'
import { Modal } from '../../../../theme/layout/components/modal'
import CRMStep from './CRMStep/CRMStepper'
import shopify from '../../../../assets/shopify.png'
import _ from "lodash";
import moment from 'moment'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

function Topbar(props) {
  const {
    getUserProfileDispatch,
    userProfileData,
    getUserNotificationDispatch,
    userNotificationData,
    getShopifyConnectDispatch,
    getShopifyData
  } = props
  const { config } = useLayout()
  const [notify, setNotify] = useState(5)
  const [payload, setPayload] = useState({

  })
  const [raw, setRawdata] = useState<any>([])
  const [crmModalShow, setCrmModalShow] = useState(false);
  const [counter, setCounter] = useState(0);
  const [intervalTime, setIntervalTime] = useState(false)

  const setConnectedStatus = (data) => {
    data && handleTrigger()
  }
  let temp_time = new Date();
  useEffect(() => {
    setTimeout(() => {
      setRawdata(dummy)
    }, 3000)
  }, [])

  const pull_data = (data: any) => {
  }
  useEffect(() => (
    getShopifyConnectDispatch(headersWithAuth)
  ), [])

  const handleTrigger = () => {
    setIntervalTime(true)
    setLocalStorage('EXPIRED_TIME', temp_time)
    setCounter(counter + 1)
  }

  useEffect(() => {
    const timer: any = intervalTime && setTimeout(() => setCounter(counter + 1), 1000);
    temp_time.setMinutes(temp_time.getMinutes() + 1);
    const getExpiredTime = getLocalStorageMoment('EXPIRED_TIME');
    if (moment().isSameOrAfter(moment(getExpiredTime))) {
      getShopifyConnectDispatch(headersWithAuth)
      setCounter(0)
      clearTimeout(timer);
      setLocalStorage('EXPIRED_TIME', temp_time)
    }
  }, [counter, intervalTime])

  useEffect(() => {
    if (!_.isEmpty(getShopifyData)) {
      getShopifyData?.status === 'Not Connected' && setCrmModalShow(true)
    }
  }, [getShopifyData])

  return (
    <>
      <div className='d-flex align-items-stretch flex-shrink-0'>
        {/* NOTIFICATIONS */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu- wrapper */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => {
              setNotify(0)
            }}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen007.svg'
              className={toolbarButtonIconSizeClass}
            />
            {notify != 0 && <span className="position-absolute top-0 start-0 translate-middle  badge badge-circle badge-danger">{notify}</span>}
          </div>
          <HeaderNotificationsMenu userNotificationData={raw?.length !== 0 && raw} setNotifyParams={pull_data} />
          {/* end::Menu wrapper */}
        </div>


        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              src={userProfileData.image ? `${API_URL}/uploads/${userProfileData.image}` : ''}
              alt="your image"
              onError={
                (e: React.SyntheticEvent<HTMLImageElement, Event>) => (
                  (e.target as HTMLImageElement).src = userPlaceholder
                )
              }
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}

        {/* begin::Aside Toggler */}
        {config.header.left === 'menu' && (
          <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_header_menu_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
            </div>
          </div>
        )}
      </div>
      {crmModalShow ? (
        <Modal showModal={crmModalShow} modalWidth={750}>
          <div className="scroll h-500px" id="uploadModal">
            <div>
              <div className="modal-header">
                <h2 className="me-8 mt-5">Connect CRM</h2>
                <button
                  type="button"
                  className="btn btn-lg btn-icon btn-active-light-primary close"
                  data-dismiss="modal"
                  onClick={(e) => {
                    setCrmModalShow(false);
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon-1"
                  />
                </button>
              </div>
              <div className='d-flex justify-content-center p-5'>
                <img
                  style={{ width: '200px', height: '60px' }}
                  src={shopify}
                >
                </img>
              </div>
              <CRMStep setConnectedStatus={setConnectedStatus} />
            </div>
          </div>
        </Modal>
      ) : null
      }
    </>
  )
}

const mapStateToProps = (state) => {
  const { userProfileStore, userNotificationStoreKey, userStore, ShopifyConnectStoreKey } = state

  return {
    loadingGetUP: state && state.userProfileStore && state.userProfileStore.loadingGetUP,
    userProfileData:
      userProfileStore && userProfileStore.userProfileData ? userProfileStore.userProfileData : {},

    userNotificationData:
      userNotificationStoreKey
        && userNotificationStoreKey?.userNotificationData ? userNotificationStoreKey?.userNotificationData : {},
    getShopifyData: ShopifyConnectStoreKey && ShopifyConnectStoreKey?.ShopifyConnectData ? ShopifyConnectStoreKey?.ShopifyConnectData : {},
    ShopifyDataLoading: ShopifyConnectStoreKey && ShopifyConnectStoreKey?.isLoading ? ShopifyConnectStoreKey?.isLoading : {},
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserProfileDispatch: (data, headers) => dispatch(userProfilesActions.getUserProfile(data, headers)),
  getUserNotificationDispatch: () => dispatch(UserNotificationActions.UserNotificationInit()),
  getShopifyConnectDispatch: (headers) => dispatch(ShopifyConnectActions.ShopifyConnectInit(headers)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Topbar)



