export const userProfileActionsTypes = {
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  SAVE_USER_PROFILE_RESPONSE: 'SAVE_USER_PROFILE_RESPONSE',
  CLEAR_USER_PROFILE: 'CLEAR_USER_PROFILE'
}

export const userProfilesActions = {
  getUserProfile: (data, headers) => ({
    type: userProfileActionsTypes.GET_USER_PROFILE,
    data,
    headers
  }),
  saveUserProfileResponse: data => ({
    type: userProfileActionsTypes.SAVE_USER_PROFILE_RESPONSE,
    data
  }),
  clearUserProfile: () => ({
    type: userProfileActionsTypes.CLEAR_USER_PROFILE
  })
}

export const editUserProfileTypes = {
  REQUEST: 'EDIT_USER_PROFILE_REQUEST',
  RESPONSE: 'EDIT_USER_PROFILE_RESPONSE',
  ERROR: 'EDIT_USER_PROFILE_ERROR',
  CLEAR: 'EDIT_USER_PROFILE_CLEAR'
}

export const editUserProfileActions = {
  editUserProfile: (params, headers) => {
    return {
      type: editUserProfileTypes.REQUEST,
      payload: { params },
      headers
    }
  },
  saveeditUserProfileResponse: (data) => {
    return {
      type: editUserProfileTypes.RESPONSE,
      data
    }
  },
  cleareditUserProfile: () => ({
    type: editUserProfileTypes.CLEAR
  })
}
