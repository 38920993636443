import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  CRM_FORM,
  REGEX,
  STATUS_RESPONSE,
  SWEET_ALERT_MSG,
  SAVE_CURRENT,
  FILE_FORMAT_MID,
  DROPZONE_MESSAGES,
  API_URL,
  SESSION,
  API,
} from "../../../../utils/constants";
import { merchantFormValidation } from "./validation";
import ReactPaginate from "react-paginate";
import { Modal } from "../../../../theme/layout/components/modal";
import UploadMerchant from "./UploadMerchant";
import IntegrationForm from "./IntegrationFrom";
import _ from "lodash";
import { KTSVG } from "../../../../theme/helpers";
import color from "../../../../utils/colors";
import {
  countryActions,
  stateActions,
  cityActions,
  merchantActions,
  deleteMerchantActions,
  saveMerchantActions,
  merchantGetDetailsTypes,
  industryActions,
  addmidCRMActions,
  addMerchantUploadActions,
} from "../../../../store/actions";
import ReactSelect from "../../../../theme/layout/components/ReactSelect";
import { merchantPayload } from "../functions/formData";
import {
  confirmationAlert,
  warningAlert,
  confirmAlert,
} from "../../../../utils/alerts";
import {
  setLocalStorage,
  getLocalStorage,
  headersWithAuth,
} from "../../../../utils/helper";
import Dropzone, { useDropzone } from "react-dropzone";
import styled from "styled-components";

const StyledDiv = styled.div`
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
    width: 500px;
    height: 46px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #9ab6d9;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
`;

const MerchantInfo = (props) => {
  const {
    statusAMIDCRM,
    messageAMIDCRM,
    getMerchant,
    merchantLoading,
    saveMerchant,
    getCountryDispatch,
    getCountrys,
    loadingCountry,
    getStateDispatch,
    getStates,
    loadingState,
    getCityDispatch,
    getCitys,
    loadingCity,
    getMerchantDispatch,
    deleteMerchantDispatch,
    deleteMerchantStatus,
    deleteMerchantMessage,
    clearDeleteMerchantDispatch,
    goBack,
    saveMerchantDispatch,
    saveMerchantStatus,
    saveMerchantMessage,
    loadingSM,
    clearMerchantDispatch,
    setCurrentClientDispatch,
    clientId,
    addmidCRMDispatch,
    clearAddmidCRMDispatch,
    getIndustryDispatch,
    getIndustry,
    dataAMIDCRM,
    addMerchantUploadDispatch,
    clearaddMerchantUploadDispatch,
    dataAMUpload,
    loadingAMUpload,
    statusAMUpload,
  } = props;

  const { getRootProps } = useDropzone();
  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };
  const history = useHistory();
  const didMount = React.useRef(false);
  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update");
  const id = url && url[1];
  const [showForm, setShowForm] = useState(true);
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const [countryOption, setCountryOption] = useState();
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [stateOption, setStateOption] = useState();
  const [selectedStateOption, setSelectedStateOption] = useState("");
  const [cityOptions, setCityOptions] = useState();
  const [selectedIndustryOption, setSelectedIndustryOption] = useState("");
  const [industryOption, setIndustryOption] = useState();
  const [editMode, setEditMode] = useState(false);
  const [selectedCityOption, setSelectedCityOption] = useState("");
  const [errors, setErrors] = useState({});
  const [merchantID, setMerchantID] = useState();
  const [targetFileName, setTargetFileName] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [onBlurOptions, setOnBlurOptions] = useState({
    merchantState: false,
    merchantCity: false,
  });
  const [midFormData, setMidFormData] = useState({
    midNumber: "",
    descriptorName: "",
    descriptorId: "",
    doingBusinessAs: "",
    bin: "",
    caId: "",
  });

  const [formData, setFormData] = useState({
    merchantName: "",
    merchantCountry: "",
    industry: "",
    merchantAddress: "",
    merchantCity: "",
    merchantState: "",
    merchantZip: "",
    merchantEmail: "",
    merchantPhoneNumber: "",
    phoneNumberExtension: "",
    externalId: "",
    company: "",
    companyDescription: "",
  });

  const getMerchantCall = () => {
    const params = {
      limit: limit,
      page: activePageNumber,
      clientId: clientId,
    };
    getMerchantDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  useEffect(() => {
    getMerchantCall();
  }, []);

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
    getMerchantCall();
  };

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const params = {
      limit: limit,
      page: pageNumber,
      clientId: clientId,
    };
    setActivePageNumber(pageNumber);
    getMerchantDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleInputChange = (e) => {
    e.persist();
    setMidFormData((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleFileUpload = (files, name) => {
    setErrors({ ...errors, [name]: "" });
    setTargetFileName(name);
    const maxFileSize = 5;
    let isValidFileFormat = true;
    let currentFileName = "";
    const data = new FormData();
    files.forEach((item) => {
      const fileType = item && item.type;
      const uploadedFileSize = item && item.size;
      currentFileName = item && item.name;
      isValidFileFormat = _.includes(FILE_FORMAT_MID, fileType);
      const fileSize = Number(maxFileSize) * 1024 * 1024;
      if (isValidFileFormat) {
        if (uploadedFileSize < fileSize) {
          data.append("type", "screenshot");
          data.append("file_to_upload", item);
          addMerchantUploadDispatch(data);
          for (var key in data) {
          }
        } else {
          setErrors({
            ...errors,
            [name]: `File size must below ${
              fileSize / 1048576
            } MB!. You file size is : ${Math.round(uploadedFileSize / 1024)}KB`,
          });
        }
      } else {
        setErrors({ ...errors, [name]: DROPZONE_MESSAGES.IMAGE_INVALID });
      }
    });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getStateDispatch(
            {
              countryId: selectedOption.value,
              skipPagination: true,
            },
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      );
      setSelectedCountryOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantCountry: selectedOption.value,
        merchantState: "",
        merchantCity: "",
      }));
      setSelectedStateOption();
      setSelectedCityOption();
    } else {
      setSelectedCountryOption();
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({
        ...values,
        merchantCountry: "",
        merchantState: "",
        merchantCity: "",
      }));
    }
    setErrors({ ...errors, merchantCountry: "" });
  };

  const handleChangeState = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getCityDispatch(
            {
              stateId: selectedOption.value,
              skipPagination: true,
            },
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      );
      setSelectedStateOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantState: selectedOption.value,
        merchantCity: "",
      }));
      setSelectedCityOption();
    } else {
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({
        ...values,
        merchantState: "",
        merchantCity: "",
      }));
    }
    setErrors({ ...errors, merchantState: "" });
  };

  const handleChangeCity = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCityOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantCity: selectedOption.value,
      }));
    } else {
      setSelectedCityOption();
      setFormData((values) => ({ ...values, merchantCity: "" }));
    }
    setErrors({ ...errors, merchantCity: "" });
  };

  const handleSelectBlur = (e, name) => {
    typingTimeout && clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (name === "merchantState" && onBlurOptions.merchantState) {
          const param = { limit: 25 };
          getStateDispatch(
            param,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setOnBlurOptions((values) => ({ ...values, merchantState: false }));
        }
        if (name === "merchantCity" && onBlurOptions.merchantCity) {
          const param = { limit: 25 };
          getCityDispatch(
            param,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setOnBlurOptions((values) => ({ ...values, merchantCity: false }));
        }
      }, 1000)
    );
  };

  const handleSelectInputChange = (typedOption, name) => {
    if (typedOption.length >= 3) {
      typingTimeout && clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          if (
            name === "merchantState" &&
            !(selectedCountryOption && selectedCountryOption.value)
          ) {
            const param = { name: typedOption, skipPagination: true };
            getStateDispatch(
              param,
              isAuthorized ? headersWithAuth : headercurrentWithApiKey
            );
            setOnBlurOptions((values) => ({ ...values, merchantState: true }));
          }
          if (
            name === "merchantCity" &&
            !(selectedStateOption && selectedStateOption.value)
          ) {
            const param = { name: typedOption, skipPagination: true };
            getCityDispatch(
              param,
              isAuthorized ? headersWithAuth : headercurrentWithApiKey
            );
            setOnBlurOptions((values) => ({ ...values, merchantCity: true }));
          }
        }, 2000)
      );
    }
  };

  const handleChangeIndustry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedIndustryOption(selectedOption);
      setFormData((values) => ({ ...values, industry: selectedOption.value }));
      setErrors({ ...errors, industry: "" });
    } else {
      setSelectedIndustryOption();
      setFormData((values) => ({ ...values, industry: "" }));
    }
  };

  const handleSaveMerchant = () => {
    const errorMsg = merchantFormValidation(formData, midFormData, setErrors);
    if (_.isEmpty(errorMsg)) {
      const getPayload = merchantPayload(formData, clientId);
      saveMerchantDispatch(
        getPayload,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    }
  };

  useEffect(() => {
    const country = getDefaultOptions(getCountrys);
    setCountryOption(country);
  }, [getCountrys]);

  useEffect(() => {
    const state = getDefaultOptions(getStates);
    setStateOption(state);
  }, [getStates]);

  useEffect(() => {
    const city = getDefaultOptions(getCitys);
    setCityOptions(city);
  }, [getCitys]);

  useEffect(() => {
    const data = getDefaultIndustryOptions(getIndustry, "industryType");
    setIndustryOption(data);
  }, [getIndustry]);

  const getDefaultOptions = (rawData) => {
    const defaultOptions = [];
    for (const item in rawData) {
      defaultOptions.push({
        label: rawData[item].name,
        value: rawData[item]._id,
      });
    }
    return defaultOptions;
  };

  const getDefaultIndustryOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  const handleSorting = (name) => {};
  const params = {
    skipPagination: true,
  };
  useEffect(() => {
    if (id) {
      setEditMode(true);
      setShowForm(false);
      getCountryDispatch(
        params,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    } else {
      setEditMode(false);
    }
  }, [id]);

  function onConfirmDelete(id) {
    deleteMerchantDispatch(
      id,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }

  const onConfirm = () => {
    const params = {
      limit: limit,
      page: activePageNumber,
      clientId: clientId,
    };
    getMerchantDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_MERCHANT,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete(id);
      },
      () => {}
    );
  };

  useEffect(() => {
    if (didMount.current) {
      if (deleteMerchantStatus === STATUS_RESPONSE.SUCCESS_MSG) {
        confirmAlert(
          "Success",
          deleteMerchantMessage,
          "success",
          "ok",
          () => {
            onConfirm();
          },
          () => {
            onConfirm();
          }
        );
        clearDeleteMerchantDispatch();
      } else if (deleteMerchantStatus === STATUS_RESPONSE.ERROR_MSG) {
        warningAlert("Error", deleteMerchantMessage, "", "Ok");
      }
      clearDeleteMerchantDispatch();
    }
  }, [deleteMerchantStatus]);

  useEffect(() => {
    if (didMount.current) {
      if (saveMerchantStatus === STATUS_RESPONSE.SUCCESS_MSG) {
        clearMerchantDispatch();
        if (saveMerchant && saveMerchant._id) {
          const payload = {
            ...midFormData,
            merchantId: saveMerchant && saveMerchant._id,
            clientId: clientId,
          };
          addmidCRMDispatch(
            payload,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setMerchantID(saveMerchant && saveMerchant._id);
          confirmAlert(
            "Success",
            saveMerchantMessage,
            "success",
            "Ok",
            () => {
              onConfirm();
            },
            () => {
              onConfirm();
            }
          );
        }
      } else if (saveMerchantStatus === STATUS_RESPONSE.ERROR_MSG) {
        warningAlert("Error", saveMerchantMessage, "", "Ok");
      }
      clearMerchantDispatch();
    }
  }, [saveMerchantStatus]);

  useEffect(() => {
    if (statusAMIDCRM === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataAMIDCRM && dataAMIDCRM.data && dataAMIDCRM.data._id) {
        setLocalStorage(SAVE_CURRENT.ADD_MID_ID, dataAMIDCRM.data._id);
      }
      confirmAlert(
        "Success",
        messageAMIDCRM,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearAddmidCRMDispatch();
    } else if (statusAMIDCRM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        "Error",
        messageAMIDCRM,
        "error",
        "Close",
        "Ok",
        () => {
          onConfirm();
        },
        () => {}
      );
    }
    clearAddmidCRMDispatch();
  }, [statusAMIDCRM]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    }
  });

  const merchantData =
    getMerchant && getMerchant.result && getMerchant.result
      ? getMerchant.result
      : [];
  const totalPages =
    getMerchant && getMerchant.count
      ? Math.ceil(parseInt(getMerchant && getMerchant.count) / limit)
      : 1;

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  useEffect(() => {
    if (statusAMUpload === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataAMUpload && dataAMUpload.path) {
        setFormData((values) => ({
          ...values,
          [targetFileName]: dataAMUpload.path,
        }));
      }
      clearaddMerchantUploadDispatch();
      setTargetFileName();
    } else if (deleteMerchantStatus === STATUS_RESPONSE.ERROR_MSG) {
      setTargetFileName();
      clearaddMerchantUploadDispatch();
    }
  }, [statusAMUpload]);

  return (
    <>
      {uploadModalShow ? (
        <Modal showModal={uploadModalShow} modalWidth={650}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">Upload Merchant</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={() => {
                      setUploadModalShow(false);
                    }}
                  >
                    {/* eslint-disable */}
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                    {/* eslint-disable */}
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container-fixed">
                    <UploadMerchant setUploadModalShow={setUploadModalShow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {showForm ? (
        <div className="card-header bg-skyBlue py-8">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-darkRed m-0 me-5 fa-pull-right"
              onClick={() => {
                setEditMode(true);
                setShowForm(false);
                const params = {
                  limit: limit,
                  page: activePageNumber,
                  clientId: clientId,
                };
                getMerchantDispatch(
                  params,
                  isAuthorized ? headersWithAuth : headercurrentWithApiKey
                );
              }}
            >
              Back
            </button>
          </div>
          <div className="mt-5 p-5">
            <div className="p-3 bg-white rounded shadow d-flex justify-content-start">
              <ul
                id="myTab"
                role="tablist"
                className="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav col-lg-6"
              >
                <li className="nav-item flex-sm-fill">
                  <a
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    className="nav-link border-0 text-uppercase font-weight-bold active"
                  >
                    Merchant Info
                  </a>
                </li>
                <li className="nav-item flex-sm-fill">
                  <a
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    className="nav-link border-0 text-uppercase font-weight-bold"
                  >
                    Integration
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
              className="tab-pane fade px-4 py-5 show active"
            >
              <div className="card h-auto bg-skyBlue">
                <div className="card-body  m-8">
                  <h2 className="mb-5">Merchant Info</h2>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                        Merchant Name:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="merchantName"
                          type="text"
                          className="form-control"
                          placeholder="Merchant Name"
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantName || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault();
                              return false;
                            }
                          }}
                        />
                        {errors && errors.merchantName && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.merchantName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                        Industry:
                      </label>
                      <div className="col-lg-12">
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name="industry"
                          className="basic-single"
                          classNamePrefix="select"
                          handleChangeReactSelect={handleChangeIndustry}
                          options={industryOption}
                          value={selectedIndustryOption}
                        />
                        {errors && errors.industry && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.industry}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Country:
                      </label>
                      <div className="col-lg-12">
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name="merchantCountry"
                          className="basic-single"
                          classNamePrefix="select"
                          handleChangeReactSelect={handleChangeCountry}
                          options={countryOption}
                          value={selectedCountryOption}
                          isLoading={loadingCountry}
                          isDisabled={loadingCity || loadingCountry}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        State:
                      </label>
                      <div className="col-lg-12">
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name="merchantState"
                          className="basic-single"
                          classNamePrefix="select"
                          handleChangeReactSelect={handleChangeState}
                          options={stateOption}
                          value={selectedStateOption}
                          isLoading={loadingState}
                          onBlur={(e) => handleSelectBlur(e, "merchantState")}
                          noOptionsMessage={() =>
                            "Please start typing to search!"
                          }
                          handleInputChange={(e) =>
                            handleSelectInputChange(e, "merchantState")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        City:
                      </label>
                      <div className="col-lg-12">
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name="merchantCity"
                          className="basic-single"
                          classNamePrefix="select"
                          handleChangeReactSelect={handleChangeCity}
                          options={cityOptions}
                          value={selectedCityOption}
                          isLoading={loadingCity}
                          onBlur={(e) => handleSelectBlur(e, "merchantCity")}
                          noOptionsMessage={() =>
                            "Please start typing to search!"
                          }
                          handleInputChange={(e) =>
                            handleSelectInputChange(e, "merchantCity")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                        Address:
                      </label>
                      <div className="col-lg-12">
                        <textarea
                          autoComplete="off"
                          name="merchantAddress"
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantAddress || ""}
                          maxLength={500}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.merchantAddress && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.merchantAddress}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                        Zip Code:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="merchantZip"
                          type="text"
                          className="form-control"
                          placeholder="Zip Code"
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantZip || ""}
                          maxLength={6}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.merchantZip && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.merchantZip}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                        Email:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="merchantEmail"
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantEmail || ""}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                        />
                        {errors && errors.merchantEmail && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.merchantEmail}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                        Phone Number:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="merchantPhoneNumber"
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantPhoneNumber || ""}
                          maxLength={12}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC_CHARS.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.merchantPhoneNumber && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.merchantPhoneNumber}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                        Company Name:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="company"
                          type="text"
                          className="form-control"
                          placeholder="Company Name"
                          onChange={(e) => handleChange(e)}
                          value={formData.company || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault();
                              return false;
                            }
                          }}
                        />
                        {errors && errors.company && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.company}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Phone Extension:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="phoneNumberExtension"
                          type="text"
                          className="form-control"
                          placeholder="Phone Extension"
                          onChange={(e) => handleChange(e)}
                          value={formData.phoneNumberExtension || ""}
                          maxLength={10}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!/[0-9+]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.phoneNumberExtension && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.phoneNumberExtension}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        External Id:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="externalId"
                          type="text"
                          className="form-control"
                          placeholder="External Id"
                          onChange={(e) => handleChange(e)}
                          value={formData.externalId || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Company Description:
                      </label>
                      <div className="col-lg-12">
                        <textarea
                          autoComplete="off"
                          name="companyDescription"
                          type="text"
                          className="form-control"
                          placeholder="Company Description"
                          onChange={(e) => handleChange(e)}
                          value={formData.companyDescription || ""}
                          maxLength={500}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                        MID number:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="midNumber"
                          type="text"
                          className="form-control"
                          placeholder="MID number"
                          onChange={(e) => handleInputChange(e)}
                          value={midFormData.midNumber || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.midNumber && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.midNumber}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Descriptor name:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="descriptorName"
                          type="text"
                          className="form-control"
                          placeholder="Descriptor name"
                          onChange={(e) => handleInputChange(e)}
                          value={midFormData.descriptorName || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Descriptor ID:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="descriptorId"
                          type="text"
                          className="form-control"
                          placeholder="Descriptor ID"
                          onChange={(e) => handleInputChange(e)}
                          value={midFormData.descriptorId || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                        Doing business as:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="doingBusinessAs"
                          type="text"
                          className="form-control"
                          placeholder="Doing business as"
                          onChange={(e) => handleInputChange(e)}
                          value={midFormData.doingBusinessAs || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.doingBusinessAs && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.doingBusinessAs}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        BIN (Bank Identification Number):
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="bin"
                          type="text"
                          className="form-control"
                          placeholder="BIN (Bank Identification Number)"
                          onChange={(e) => handleInputChange(e)}
                          value={midFormData.bin || ""}
                          maxLength={6}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        CAID :
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="caId"
                          type="text"
                          className="form-control"
                          placeholder="CAID (Cardholder Acceptance Identification Number)"
                          onChange={(e) => handleInputChange(e)}
                          value={midFormData.caId || ""}
                          maxLength={6}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Store Name:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="storeName"
                          type="text"
                          className="form-control"
                          placeholder=" Store Name"
                          onChange={(e) => handleChange(e)}
                          value={formData.storeName || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.storeName && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.storeName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Store Type:
                      </label>
                      <div className="col-lg-12">
                        <select
                          name="storeType"
                          className="form-select form-select-solid"
                          data-control="select"
                          data-placeholder="Select an option"
                          data-allow-clear="true"
                          onChange={(e) => handleChange(e)}
                          value={formData.storeType || ""}
                        >
                          <option value="">Select...</option>
                          <option value="physicalGoods">Physical Goods</option>
                          <option value="digitalGoods">Digital Goods</option>
                          <option value="subscription">Subscription</option>
                        </select>
                        {errors && errors.storeType && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.storeType}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Sign Up URL :
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="signupUrl"
                          type="text"
                          className="form-control"
                          placeholder="Sign Up URL"
                          onChange={(e) => handleChange(e)}
                          value={formData.signupUrl || ""}
                          maxLength={500}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                        />
                        {errors && errors.signupUrl && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.signupUrl}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        TOS URL:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="tosUrl"
                          type="text"
                          className="form-control"
                          placeholder="TOS URL"
                          onChange={(e) => handleChange(e)}
                          value={formData.tosUrl || ""}
                          maxLength={500}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                        />
                        {errors && errors.tosUrl && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.tosUrl}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Checkout URL:
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="checkoutUrl"
                          type="text"
                          className="form-control"
                          placeholder="Checkout URL"
                          onChange={(e) => handleChange(e)}
                          value={formData.checkoutUrl || ""}
                          maxLength={500}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                        />
                        {errors && errors.checkoutUrl && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.checkoutUrl}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Return Policy URL :
                      </label>
                      <div className="col-lg-12">
                        <input
                          autoComplete="off"
                          name="returnPolicyUrl"
                          type="text"
                          className="form-control"
                          placeholder="Return Policy URL"
                          onChange={(e) => handleChange(e)}
                          value={formData.returnPolicyUrl || ""}
                          maxLength={500}
                          onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                        />
                        {errors && errors.returnPolicyUrl && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.returnPolicyUrl}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        TOS Policy Screenshot:
                      </label>
                      <div className="col-lg-12">
                        <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleFileUpload(
                                acceptedFiles,
                                "tosPolicyScreenshot"
                              );
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="container w-25rem">
                                <div {...getRootProps()}>
                                  <input
                                    {...getInputProps()}
                                    name="tosPolicyScreenshot"
                                  />
                                  <p className="text-primary fw-bolder fs-6">
                                    Click here to select files
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </StyledDiv>
                        {loadingAMUpload &&
                        targetFileName === "tosPolicyScreenshot" ? (
                          <div className="d-flex justify-content-center align-items-center py-3">
                            <div className="d-flex justify-content-center">
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="visually-hidden" />
                              </span>
                              <span
                                className="spinner-grow spinner-grow-sm mx-1"
                                role="status"
                              >
                                <span className="visually-hidden" />
                              </span>
                            </div>
                            <div className="mx-2">Uploading</div>
                          </div>
                        ) : null}
                        {formData && formData.tosPolicyScreenshot ? (
                          <div className="text-center mt-2 cursor-pointer">
                            <a
                              href={`${API_URL}/uploads/${formData.tosPolicyScreenshot}`}
                              target="_blank"
                            >
                              {formData.tosPolicyScreenshot.includes("pdf") ? (
                                <i
                                  className="bi bi-file-earmark-pdf-fill"
                                  style={{
                                    color: "dodgerblue",
                                    width: "75px",
                                    height: "75px",
                                    fontSize: "3rem",
                                  }}
                                />
                              ) : (
                                <img
                                  src={`${API_URL}/uploads/${formData.tosPolicyScreenshot}`}
                                  alt="your image"
                                  style={{ width: 100, height: 100 }}
                                />
                              )}
                            </a>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Refund Policy Screenshot:
                      </label>
                      <div className="col-lg-12">
                        <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleFileUpload(
                                acceptedFiles,
                                "refundPolicyScreenshot"
                              );
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="container w-25rem">
                                <div {...getRootProps()}>
                                  <input
                                    {...getInputProps()}
                                    name="refundPolicyScreenshot"
                                  />
                                  <p className="text-primary fw-bolder fs-6">
                                    Click here to select files
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </StyledDiv>
                        {loadingAMUpload &&
                        targetFileName === "refundPolicyScreenshot" ? (
                          <div className="d-flex justify-content-center align-items-center py-3">
                            <div className="d-flex justify-content-center">
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="visually-hidden" />
                              </span>
                              <span
                                className="spinner-grow spinner-grow-sm mx-1"
                                role="status"
                              >
                                <span className="visually-hidden" />
                              </span>
                            </div>
                            <div className="mx-2">Uploading</div>
                          </div>
                        ) : null}
                        {formData && formData.refundPolicyScreenshot ? (
                          <div className="text-center mt-2 cursor-pointer">
                            <a
                              href={`${API_URL}/uploads/${formData.refundPolicyScreenshot}`}
                              target="_blank"
                            >
                              {formData.refundPolicyScreenshot.includes(
                                "pdf"
                              ) ? (
                                <i
                                  className="bi bi-file-earmark-pdf-fill"
                                  style={{
                                    color: "dodgerblue",
                                    width: "75px",
                                    height: "75px",
                                    fontSize: "3rem",
                                  }}
                                />
                              ) : (
                                <img
                                  src={`${API_URL}/uploads/${formData.refundPolicyScreenshot}`}
                                  alt="your image"
                                  style={{ width: 100, height: 100 }}
                                />
                              )}
                            </a>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Checkout Screenshot:
                      </label>
                      <div className="col-lg-12">
                        <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleFileUpload(
                                acceptedFiles,
                                "checkoutScreenshot"
                              );
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="container w-25rem">
                                <div {...getRootProps()}>
                                  <input
                                    {...getInputProps()}
                                    name="checkoutScreenshot"
                                  />
                                  <p className="text-primary fw-bolder fs-6">
                                    Click here to select files
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </StyledDiv>
                        {loadingAMUpload &&
                        targetFileName === "checkoutScreenshot" ? (
                          <div className="d-flex justify-content-center align-items-center py-3">
                            <div className="d-flex justify-content-center">
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="visually-hidden" />
                              </span>
                              <span
                                className="spinner-grow spinner-grow-sm mx-1"
                                role="status"
                              >
                                <span className="visually-hidden" />
                              </span>
                            </div>
                            <div className="mx-2">Uploading</div>
                          </div>
                        ) : null}
                        {formData && formData.checkoutScreenshot ? (
                          <div className="text-center mt-2 cursor-pointer">
                            <a
                              href={`${API_URL}/uploads/${formData.checkoutScreenshot}`}
                              target="_blank"
                            >
                              {formData.checkoutScreenshot.includes("pdf") ? (
                                <i
                                  className="bi bi-file-earmark-pdf-fill"
                                  style={{
                                    color: "dodgerblue",
                                    width: "75px",
                                    height: "75px",
                                    fontSize: "3rem",
                                  }}
                                />
                              ) : (
                                <img
                                  src={`${API_URL}/uploads/${formData.checkoutScreenshot}`}
                                  alt="your image"
                                  style={{ width: 100, height: 100 }}
                                />
                              )}
                            </a>
                          </div>
                        ) : null}
                        {errors && errors.checkoutScreenshot && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.checkoutScreenshot}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-4 mb-3">
                      <label className="font-size-xs  font-weight-bold mb-2 form-label">
                        Shipping Policy Screenshot:
                      </label>
                      <div className="col-lg-12">
                        <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleFileUpload(
                                acceptedFiles,
                                "shippingPolicyScreenshot"
                              );
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="container w-25rem">
                                <div {...getRootProps()}>
                                  <input
                                    {...getInputProps()}
                                    name="shippingPolicyScreenshot"
                                  />
                                  <p className="text-primary fw-bolder fs-6">
                                    Click here to select files
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </StyledDiv>
                        {loadingAMUpload &&
                        targetFileName === "shippingPolicyScreenshot" ? (
                          <div className="d-flex justify-content-center align-items-center py-3">
                            <div className="d-flex justify-content-center">
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="visually-hidden" />
                              </span>
                              <span
                                className="spinner-grow spinner-grow-sm mx-1"
                                role="status"
                              >
                                <span className="visually-hidden" />
                              </span>
                            </div>
                            <div className="mx-2">Uploading</div>
                          </div>
                        ) : null}
                        {formData && formData.shippingPolicyScreenshot ? (
                          <div className="text-center mt-2 cursor-pointer">
                            <a
                              href={`${API_URL}/uploads/${formData.shippingPolicyScreenshot}`}
                              target="_blank"
                            >
                              {formData.shippingPolicyScreenshot.includes(
                                "pdf"
                              ) ? (
                                <i
                                  className="bi bi-file-earmark-pdf-fill"
                                  style={{
                                    color: "dodgerblue",
                                    width: "75px",
                                    height: "75px",
                                    fontSize: "3rem",
                                  }}
                                />
                              ) : (
                                <img
                                  src={`${API_URL}/uploads/${formData.shippingPolicyScreenshot}`}
                                  alt="your image"
                                  style={{ width: 100, height: 100 }}
                                />
                              )}
                            </a>
                          </div>
                        ) : null}
                        {errors && errors.shippingPolicyScreenshot && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.shippingPolicyScreenshot}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {pathName.includes("client-onboarding/update") ? (
                    <div className="form-group row mt-4">
                      <div className="col-lg-6" />
                      <div className="col-lg-6">
                        <div className="col-lg-11">
                          <button
                            className="btn btn-primary m-2 fa-pull-right"
                            onClick={() => {
                              handleSaveMerchant();
                            }}
                          >
                            {loadingSM ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
              className="tab-pane fade px-4 py-5"
            >
              <div className=" h-auto d-flex flex-column">
                <IntegrationForm merchantID={merchantID} clientID={clientId} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {editMode && !showForm ? (
        <div className="card">
          <div className="card-body py-3">
            <div className="d-flex  px - 2">
              <div className="d-flex justify-content-start col-md-6">
                <div className="col-md-3 mt-1">
                  {merchantData.length > 0 ? (
                    <span className="text-muted fw-bold d-flex fs-3 mt-2">
                      Total: &nbsp;{" "}
                      <span className="text-gray-700 fw-bolder text-hover-primary fs-3">
                        {merchantData.length}
                      </span>
                    </span>
                  ) : null}
                </div>
                <div className="col-md-9 d-flex">
                  <label className="col-form-label text-lg-start">
                    Record(s) per Page : &nbsp;{" "}
                  </label>
                  <div className="col-md-3">
                    <select
                      className="form-select w-6rem"
                      data-control="select"
                      data-placeholder="Select an option"
                      data-allow-clear="true"
                      onChange={(e) => handleRecordPerPage(e)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex col-md-12 justify-content-end my-auto">
                  <div className="my-auto">
                    <button
                      className="btn btn-sm btn-light-primary"
                      onClick={() => {
                        setEditMode(false);
                        setShowForm(true);
                        setFormData({
                          merchantName: "",
                          merchantCountry: "",
                          industry: "",
                          merchantAddress: "",
                          merchantCity: "",
                          merchantState: "",
                          merchantZip: "",
                          merchantEmail: "",
                          merchantPhoneNumber: "",
                          phoneNumberExtension: "",
                          externalId: "",
                          companyDescription: "",
                          mid: "",
                          descriptorName: "",
                          descriptorId: "",
                          doingBusinessAs: "",
                          acquirerBin: "",
                          caid: "",
                        });
                      }}
                    >
                      <KTSVG path="/media/icons/duotune/arrows/arr087.svg" />
                      Add Merchant
                    </button>

                    <button
                      className="btn btn-sm btn-light-primary mx-5"
                      onClick={() => {
                        setUploadModalShow(true);
                      }}
                    >
                      <KTSVG path="/media/icons/duotune/arrows/arr045.svg" />
                      Upload Merchant
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-rounded table-striped border gs-2 mt-6">
                <thead>
                  <tr className="fw-bold fs-6 text-gray-800">
                    <th className="min-w-200px text-center">
                      <div className="d-flex">
                        <span>Action</span>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Merchant Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Country</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Industry</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Address</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>City</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>State</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Zip Code</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Email</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Phone Number</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Phone Extension</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>External ID</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Company Description</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>MID Number</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Descriptor Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Descriptor Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Doing Business As</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>Bin</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="min-w-200px text-start">
                      <div className="d-flex">
                        <span>CAID</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          />
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!merchantLoading ? (
                    merchantData && merchantData.length > 0 ? (
                      merchantData.map((merchant, _id) => {
                        return (
                          <tr
                            key={_id}
                            style={
                              _id === 0
                                ? { borderColor: "black" }
                                : { borderColor: "white" }
                            }
                            onClick={() => {}}
                          >
                            <td className="pb-0 pt-5  text-center">
                              <div className="d-flex">
                                <button
                                  className="btn btn-icon btn-icon-warning btn-sm w-20px h-10px"
                                  onClick={() => {
                                    history.push(
                                      `/client-onboarding/update-merchant/${merchant._id}`
                                    );
                                    setCurrentClientDispatch(clientId);
                                  }}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/art/art005.svg"
                                    className="svg-icon-3"
                                  />
                                </button>
                                <button
                                  className="btn btn-icon btn-icon-danger btn-sm w-20px h-10px"
                                  onClick={() => {
                                    onDeleteItem(merchant._id);
                                  }}
                                >
                                  {/* {/ eslint-disable /} */}
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen027.svg"
                                    className="svg-icon-3"
                                  />
                                  {/* {/ eslint-enable /} */}
                                </button>
                              </div>
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.merchantName
                                ? merchant.merchantName
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.merchantCountry &&
                              merchant.merchantCountry.name &&
                              merchant.merchantCountry.name
                                ? merchant.merchantCountry.name
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.industry &&
                              merchant.industry.industryType &&
                              merchant.industry.industryType
                                ? merchant.industry.industryType
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.merchantAddress
                                ? merchant.merchantAddress
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.merchantCity &&
                              merchant.merchantCity.name &&
                              merchant.merchantCity.name
                                ? merchant.merchantCity.name
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.merchantState &&
                              merchant.merchantState.name &&
                              merchant.merchantState.name
                                ? merchant.merchantState.name
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.merchantZip
                                ? merchant.merchantZip
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.merchantEmail
                                ? merchant.merchantEmail
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.merchantPhoneNumber
                                ? merchant.merchantPhoneNumber
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.phoneNumberExtension
                                ? merchant.phoneNumberExtension
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.externalId ? merchant.externalId : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.companyDescription
                                ? merchant.companyDescription
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.mid ? merchant.mid : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.descriptorName
                                ? merchant.descriptorName
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.descriptorId
                                ? merchant.descriptorId
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.doingBusinessAs
                                ? merchant.doingBusinessAs
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.acquirerBin
                                ? merchant.acquirerBin
                                : "--"}
                            </td>
                            <td className="pb-0 pt-5  text-start">
                              {merchant.caid ? merchant.caid : "--"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center py-3">
                        <td colSpan="100%">No record(s) found</td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <div
                          className="spinner-border text-primary m-5"
                          role="status"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="form-group row mb-4 mt-6">
                <div className="col-lg-12 mb-4 align-items-end d-flex">
                  <div className="col-lg-12 pagination">
                    <ReactPaginate
                      nextLabel="Next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={totalPages}
                      previousLabel="< Prev"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  getCountrys: state && state.countryStore && state.countryStore.getCountrys,
  loadingCountry: state && state.countryStore && state.countryStore.loading,
  getStates: state && state.stateStore && state.stateStore.getStates,
  loadingState: state && state.stateStore && state.stateStore.loading,
  getCitys: state && state.cityStore && state.cityStore.getCitys,
  loadingCity: state && state.cityStore && state.cityStore.loading,
  getMerchant: state && state.merchantStore && state.merchantStore.getMerchant,
  merchantLoading: state && state.merchantStore && state.merchantStore.loading,
  getIndustry: state && state.industryStore && state.industryStore.getIndustry,
  deleteMerchant:
    state &&
    state.merchantDeleteStore &&
    state.merchantDeleteStore.deleteMerchant,
  deleteMerchantStatus:
    state &&
    state.merchantDeleteStore &&
    state.merchantDeleteStore.deleteMerchantStatus,
  deleteMerchantMessage:
    state &&
    state.merchantDeleteStore &&
    state.merchantDeleteStore.deleteMerchantMessage,
  deleteMerchantLoading:
    state && state.merchantDeleteStore && state.merchantDeleteStore.loading,
  saveMerchant:
    state && state.merchantSaveStore && state.merchantSaveStore.saveMerchant,
  saveMerchantStatus:
    state &&
    state.merchantSaveStore &&
    state.merchantSaveStore.saveMerchantStatus,
  saveMerchantMessage:
    state &&
    state.merchantSaveStore &&
    state.merchantSaveStore.saveMerchantMessage,
  loadingSM:
    state && state.merchantSaveStore && state.merchantSaveStore.loadingSM,
  statusAMIDCRM:
    state && state.addmidCRMStore && state.addmidCRMStore.statusAMIDCRM,
  messageAMIDCRM:
    state && state.addmidCRMStore && state.addmidCRMStore.messageAMIDCRM,
  dataAMIDCRM:
    state && state.addmidCRMStore && state.addmidCRMStore.dataAMIDCRM,
  loadingAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.loadingAMUpload,
  dataAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.dataAMUpload,
  statusAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.statusAMUpload,
  messageAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.messageAMUpload,
});

const mapDispatchToProps = (dispatch) => ({
  getCountryDispatch: (params, headers) =>
    dispatch(countryActions.getCountrys(params, headers)),
  getStateDispatch: (params, headers) =>
    dispatch(stateActions.getStates(params, headers)),
  getCityDispatch: (params, headers) =>
    dispatch(cityActions.getCitys(params, headers)),
  getIndustryDispatch: (params, headers) =>
    dispatch(industryActions.getIndustry(params, headers)),
  getMerchantDispatch: (data, headers) =>
    dispatch(merchantActions.getMerchant(data, headers)),
  deleteMerchantDispatch: (data, headers) =>
    dispatch(deleteMerchantActions.deleteMerchant(data, headers)),
  clearDeleteMerchantDispatch: () =>
    dispatch(deleteMerchantActions.clearDeleteMerchant()),
  saveMerchantDispatch: (data, headers) =>
    dispatch(saveMerchantActions.saveMerchant(data, headers)),
  clearMerchantDispatch: () => dispatch(saveMerchantActions.clearMerchant()),
  setCurrentClientDispatch: (id) =>
    dispatch({
      type: merchantGetDetailsTypes.SET_CURRENT_CLIENT_ID,
      payload: id,
    }),
  addmidCRMDispatch: (data, headers) =>
    dispatch(addmidCRMActions.addmidCRM(data, headers)),
  clearAddmidCRMDispatch: () => dispatch(addmidCRMActions.clearaddmidCRM()),
  addMerchantUploadDispatch: (data) =>
    dispatch(addMerchantUploadActions.addMerchantUpload(data)),
  clearaddMerchantUploadDispatch: () =>
    dispatch(addMerchantUploadActions.clearaddMerchantUpload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantInfo);
