import { put, takeLatest, all } from 'redux-saga/effects'
import axiosInstance from '../../services'
import {
  LoginActions,
  LoginActionsTypes
} from '../actions'
import serviceList from '../../services/serviceList'

function* fetchLogin(action) {
  const { payload } = action
  try {
    const data = yield axiosInstance.post(serviceList.login, payload)
    if (data && data.data) {
      yield put(LoginActions.saveLoginResponse(data.data))
    }
  } catch (error) {
    yield put(LoginActions.saveLoginResponse(error))
  }
}

export function* fetchLoginWatcher() {
  yield all([yield takeLatest(LoginActionsTypes.LOGIN, fetchLogin)])
}
