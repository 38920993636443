export const addMerchantCLTypes = {
  REQUEST: 'ADD_MERCHANT_MGMT_REQUEST',
  RESPONSE: 'ADD_MERCHANT_MGMT_RESPONSE',
  ERROR: 'ADD_MERCHANT_MGMT_ERROR',
  CLEAR: 'ADD_MERCHANT_MGMT_CLEAR'
}

export const addMerchantCLActions = {
  addMerchantCL: (data, headers) => {
    return {
      type: addMerchantCLTypes.REQUEST,
      payload: data, headers
    }
  },
  saveaddMerchantCLResponse: (data) => {
    return {
      type: addMerchantCLTypes.RESPONSE,
      data
    }
  },
  clearaddMerchantCL: () => ({
    type: addMerchantCLTypes.CLEAR
  })
}

export const saveMerchantTypes = {
  REQUEST: 'SAVE_MERCHANT_REQUEST',
  RESPONSE: 'SAVE_MERCHANT_DETAILS_RESPONSE',
  ERROR: 'SAVE_MERCHANT_DETAILS_ERROR',
  CLEAR: 'SAVE_MERCHANT_DETAILS_CLEAR'
}

export const saveMerchantActions = {
  saveMerchant: (data, headers) => {
    return {
      type: saveMerchantTypes.REQUEST,
      payload: data, headers
    }
  },
  saveMerchantResponse: (data) => {
    return {
      type: saveMerchantTypes.RESPONSE,
      data
    }
  },
  clearMerchant: () => ({
    type: saveMerchantTypes.CLEAR
  })
}

export const merchantActionsTypes = {
  GET_MERCHANT: 'GET_MERCHANT',
  SAVE_MERCHANT_RESPONSE: 'SAVE_MERCHANT_RESPONSE',
  CLEAR_MERCHANT: 'CLEAR_MERCHANT'
}

export const merchantActions = {
  getMerchant: (params, headers) => ({
    type: merchantActionsTypes.GET_MERCHANT,
    params, headers
  }),
  savemerchantResponse: data => ({
    type: merchantActionsTypes.SAVE_MERCHANT_RESPONSE,
    data
  }),
  clearMerchant: () => ({
    type: merchantActionsTypes.CLEAR_MERCHANT
  })
}

export const merchantDeleteTypes = {
  DELETE_MERCHANT: 'DELETE_MERCHANT',
  SAVE_DELETE_MERCHANT_RESPONSE: 'SAVE_DELETE_MERCHANT_RESPONSE',
  CLEAR_DELETE_MERCHANT: 'CLEAR_DELETE_MERCHANT'
}

export const deleteMerchantActions = {
  deleteMerchant: (params, headers) => ({
    type: merchantDeleteTypes.DELETE_MERCHANT,
    params, headers
  }),
  savemResponseDeleteMerchant: data => ({
    type: merchantDeleteTypes.SAVE_DELETE_MERCHANT_RESPONSE,
    data
  }),
  clearDeleteMerchant: () => ({
    type: merchantDeleteTypes.CLEAR_DELETE_MERCHANT
  })
}

export const merchantGetDetailsTypes = {
  GET_MERCHANT_DETAILS: 'GET_MERCHANT_DETAILS',
  MERCHANT_DETAILS_RESPONSE: 'MERCHANT_DETAILS_RESPONSE',
  CLEAR_MERCHANT_DETAILS: 'CLEAR_MERCHANT_DETAILS',
  SET_CURRENT_CLIENT_ID: 'SET_CURRENT_CLIENT_ID'
}

export const merchantGetDetailsActions = {
  getMerchantDetails: (id, headers) => ({
    type: merchantGetDetailsTypes.GET_MERCHANT_DETAILS,
    id, headers
  }),
  saveMerchantDetailsResponse: data => ({
    type: merchantGetDetailsTypes.MERCHANT_DETAILS_RESPONSE,
    data
  }),
  clearMerchantDetails: () => ({
    type: merchantGetDetailsTypes.CLEAR_MERCHANT_DETAILS
  })
}

export const editMerchantTypes = {
  REQUEST: 'EDIT_MERCHANT_REQUEST',
  RESPONSE: 'EDIT_MERCHANT_RESPONSE',
  ERROR: 'EDIT_MERCHANT_ERROR',
  CLEAR: 'EDIT_MERCHANT_CLEAR'
}

export const editMerchantActions = {
  editMerchant: (id, params, headers) => {
    return {
      type: editMerchantTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditMerchantResponse: (data) => {
    return {
      type: editMerchantTypes.RESPONSE,
      data
    }
  },
  cleareditMerchant: () => ({
    type: editMerchantTypes.CLEAR
  })
}

export const addMerchantUploadTypes = {
  REQUEST: 'ADD_MERCHANT_UPLOAD_REQUEST',
  RESPONSE: 'ADD_MERCHANT_UPLOAD_RESPONSE',
  ERROR: 'ADD_MERCHANT_UPLOAD_ERROR',
  CLEAR: 'ADD_MERCHANT_UPLOAD_CLEAR'
}

export const addMerchantUploadActions = {
  addMerchantUpload: (data) => {
    return {
      type: addMerchantUploadTypes.REQUEST,
      payload: data
    }
  },
  saveaddMerchantUploadResponse: (data) => {
    return {
      type: addMerchantUploadTypes.RESPONSE,
      data
    }
  },
  clearaddMerchantUpload: () => ({
    type: addMerchantUploadTypes.CLEAR
  })
}
