import {
  UserNotificationActionsTypes,
} from '../actions'

export const userNotificationStoreKey = 'userNotificationStoreKey'

export const userNotificationReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case UserNotificationActionsTypes.USER_NOTIFICATION_INIT:
      return { ...state, isLoading: true }
    case UserNotificationActionsTypes.USER_NOTIFICATION_SUCCESS:
      return { ...state, userNotificationData:res && res.data , isLoading: false }
    case UserNotificationActionsTypes.USER_NOTIFICATION_CLEAR:
      return { ...state, userNotificationData: null,  }
    default:
      return state
  }
}

