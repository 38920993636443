import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  getClientSignupActionsTypes,
  getClientSignupActions,
  postClientSignupActionsTypes,
  postClientSignUpActions,
  postClientPackageActionsTypes,
  postClientPackageActions
} from '../actions'
import serviceList from '../../services/serviceList'

function* getClientSignup(actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.clientSignup}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(getClientSignupActions.saveResponse(json.data))
    } else {
      yield put(getClientSignupActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getClientSignupActionsTypes.RESPONSE, data })
  }
}

function* addClientSignup(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.clientSignup, payload, {headers:headers})
    if (json.status === 200) {
      yield put(postClientSignUpActions.saveResponse(json.data))
    } else {
      yield put(postClientSignUpActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: postClientSignupActionsTypes.RESPONSE, data })
  }
}

function* addClientPackage(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.unauthCreateClientPackage, payload, {headers:headers})
    if (json.status === 200) {
      yield put(postClientPackageActions.saveResponse(json.data))
    } else {
      yield put(postClientPackageActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: postClientPackageActionsTypes.RESPONSE, data })
  }
}

export function* fetchClientSignupWatcher() {
  yield all([
    yield takeLatest(getClientSignupActionsTypes.REQUEST, getClientSignup),
    yield takeLatest(postClientSignupActionsTypes.REQUEST, addClientSignup),
    yield takeLatest(postClientPackageActionsTypes.REQUEST, addClientPackage),
  ])
}