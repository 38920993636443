import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthFailedWarning from "../../theme/layout/components/authFailedWarning";
import { unsetLocalStorage, removeLocalStorage } from "../../utils/helper";
import { API, USER_ERROR } from "../../utils/constants";

const AuthFail = () => {
  const history = useHistory();
  const pathName = useLocation().pathname;

  const handleSubmit = () => {
    removeLocalStorage(API.API_KEY);
    history.goBack();
  };

  const handleBackToLogin = () => {
    unsetLocalStorage();
    history.push("/");
  };

  const initState = {};

  const checkCase = (state = initState, type) => {
    switch (type) {
      case "/authorization-failed":
        return {
          ...state,
          warningText: USER_ERROR.NOT_AUTHORIZED,
          message: USER_ERROR.NOT_AUTHORIZED_TO_REQUEST_PAGE,
          hideBtnA: true,
          handleBtnA: handleBackToLogin,
          btnAText: "Go to Login",
          hideBtnB: false,
          handleBtnB: handleSubmit,
          btnBText: "Go Back",
        };
      case "/auth-failed":
        return {
          ...state,
          warningText: USER_ERROR.NOT_AUTHORIZED,
          message: USER_ERROR.NOT_AUTHORIZED_TO_REQUEST_PAGE,
          hideBtnA: false,
          handleBtnA: handleBackToLogin,
          btnAText: "Go to Login",
          hideBtnB: true,
          handleBtnB: handleSubmit,
          btnBText: "Ok",
        };
      case "/session-exist":
        return {
          ...state,
          warningText: USER_ERROR.SESSION_ALREADY_EXISTS,
          message: USER_ERROR.SESSION_ALREADY_EXISTS_MESSAGE,
          hideBtnA: true,
          handleBtnA: handleBackToLogin,
          btnAText: "Go to Login",
          hideBtnB: false,
          handleBtnB: handleSubmit,
          btnBText: "Ok",
        };
      default:
        return {
          ...state,
          warningText: USER_ERROR.SOURCE_NOT_FOUND,
          message: USER_ERROR.SOURCE_NOT_FOUND_MESSAGE,
          hideBtnA: false,
          handleBtnA: handleBackToLogin,
          btnAText: "Go to Login",
          hideBtnB: true,
          handleBtnB: handleSubmit,
          btnBText: "Ok",
        };
    }
  };

  return (
    <>
      <AuthFailedWarning
        warningText={checkCase("", pathName && pathName).warningText}
        message={checkCase("", pathName && pathName).message}
        hideBtnA={checkCase("", pathName && pathName).hideBtnA}
        handleBtnA={checkCase("", pathName && pathName).handleBtnA}
        btnAText={checkCase("", pathName && pathName).btnAText}
        hideBtnB={checkCase("", pathName && pathName).hideBtnB}
        handleBtnB={checkCase("", pathName && pathName).handleBtnB}
        btnBText={checkCase("", pathName && pathName).btnBText}
      />
    </>
  );
};

export default AuthFail;
