import { orderinsightActionsTypes, getOrderInsightExportColumnsActionsTypes } from '../actions'

export const orderinsightInitialState = {
  list: null
}

export const orderinsightStoreKey = 'orderinsightStore'

export const orderinsightReducer = (state = orderinsightInitialState, action) => {
  switch (action.type) {
    case orderinsightActionsTypes.GET_ORDER_INSIGHT:
      return { ...state, loading: true }
    case orderinsightActionsTypes.SAVE_ORDER_INSIGHT_RESPONSE:
      return { ...state, orderinsights: action.data, loading: false }
    case orderinsightActionsTypes.CLEAR_ORDER_INSIGHT:
      return { ...state, orderinsights: null }
    default:
      return state
  }
}

export const orderinsightExportColumnStoreKey = 'orderinsightExportColumnStore'

export const orderinsightExportColumnReducer = (state = orderinsightInitialState, action) => {
  switch (action.type) {
    case getOrderInsightExportColumnsActionsTypes.GET_EXPORT_COLUMNS:
      return { ...state, orderInsightExportColumns: action.data }
    case getOrderInsightExportColumnsActionsTypes.CLEAR_COLUMNS:
      return { ...state, orderInsightExportColumns: null }
    default:
      return state
  }
}
