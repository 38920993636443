export const addApiKeyTypes = {
  REQUEST: 'ADD_API_KEY_REQUEST',
  RESPONSE: 'ADD_API_KEY_RESPONSE',
  ERROR: 'ADD_API_KEY_ERROR',
  CLEAR: 'ADD_API_KEY_CLEAR'
}

export const addApiKeyActions = {
  addApiKey: (data, headers) => {
    return {
      type: addApiKeyTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddApiKeyResponse: (data) => {
    return {
      type: addApiKeyTypes.RESPONSE,
      data
    }
  },
  clearaddApiKey: () => ({
    type: addApiKeyTypes.CLEAR
  })
}

export const apiKeyActionsTypes = {
  REQUEST: 'GET_API_KEY',
  RESPONSE: 'SAVE_API_KEY_RESPONSE',
  CLEAR: 'CLEAR_API_KEY'
}

export const apiKeyActions = {
  getApiKey: (id, headers) => ({
    type: apiKeyActionsTypes.REQUEST,
    id,
    headers
  }),
  saveapiKeyResponse: data => ({
    type: apiKeyActionsTypes.RESPONSE,
    data
  }),
  clearApiKey: () => ({
    type: apiKeyActionsTypes.CLEAR
  })
}
