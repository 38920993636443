import {
  USER_ERROR,
  USER_MANAGEMENT_ERROR,
  REGEX,
} from "../../../utils/constants";

export const Validation = (values, setErrors) => {
  const errors = {};
  if (!values.userName) {
    errors.userName = USER_ERROR.USER_NAME;
  }
  if (!values.smtpHostAddress) {
    errors.smtpHostAddress = USER_ERROR.SMTP_HOST;
  }
  if (!values.fromEmail) {
    errors.fromEmail = USER_MANAGEMENT_ERROR.USER_EMAIL;
  }
  if (values.fromEmail && !REGEX.EMAIL.test(values.fromEmail)) {
    errors.fromEmail = USER_ERROR.EMAIL_INVALID;
  }
  if (values.fromEmail) {
    const getEmailName = values.fromEmail.split("/");
    const emailName = getEmailName && getEmailName[1];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.fromEmail = USER_ERROR.EMAIL_INVALID;
    }
  }
  if (!values.password) {
    errors.password = USER_MANAGEMENT_ERROR.USER_PASSWORD;
  }
  setErrors(errors);
  return errors;
};

export const settingValidation = (values, setErrors) => {
  const errors = {};
  if (!values.remainderDay) {
    errors.remainderDay = USER_ERROR.REMAINDER_DAY;
  }
  if (!values.expiryDay) {
    errors.expiryDay = USER_ERROR.REMAINDER_DAY;
  }
  if (!values.remainderEndDay) {
    errors.remainderEndDay = USER_ERROR.REMAINDER_END_DAY;
  }
  setErrors(errors);
  return errors;
};
