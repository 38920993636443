import {
  clientCRMActionsTypes,
  clientProcessorActionsTypes,
  clientGatewayActionsTypes,
  editClientCRMActionsTypes,
  editClientProcessorActionsTypes,
  editClientPaymentActionsTypes,
  addclientCRMTypes,
  addclientProcessorTypes,
  addclientGatewayTypes,
  clientCRMDeleteTypes,
  clientProcessorDeleteTypes,
  clientGatewayDeleteTypes,
  addmidCRMTypes,
  midCRMGetDetailsTypes,
  midCRMActionsTypes,
  midCRMDeleteTypes,
  editmidCRMActionsTypes,
  checkProcessorTypes,
  getClientProcessorDetailsActionsTypes,
  checkCrmTypes
} from '../actions'

export const addclientCRMInitialState = {
  list: null
}

export const addclientCRMStoreKey = 'addclientCRMStore'

export const addclientCRMReducer = (state = addclientCRMInitialState, action) => {
  switch (action.type) {
    case addclientCRMTypes.REQUEST:
      return { ...state, loadingACCRM: true }
    case addclientCRMTypes.RESPONSE:
      return { ...state, dataACCRM: action.data, statusACCRM: action.data.status, messageACCRM: action.data.message, loadingACCRM: false }
    case addclientCRMTypes.CLEAR:
      return { ...state, dataACCRM: null, messageACCRM: '', statusACCRM: '' }
    default:
      return state
  }
}

export const clientCRMInitialState = {
  list: null
}

export const clientCRMStoreKey = 'clientCRMStore'

export const clientCRMReducer = (state = {}, action) => {
  switch (action.type) {
    case clientCRMActionsTypes.GET_CLIENT_CRM:
      return { ...state, loading: true }
    case clientCRMActionsTypes.SAVE_CLIENT_CRM_RESPONSE:
      return { ...state, getClientCRM: action.data, loading: false }
    case clientCRMActionsTypes.CLEAR_CLIENT_CRM:
      return { ...state, getClientCRM: null }
    default:
      return state
  }
}

export const editClientCrmStoreKey = 'editClientCrmStore'

export const editClientCrmReducer = (state = {}, action) => {
  switch (action.type) {
    case editClientCRMActionsTypes.REQUEST:
      return { ...state, loadingECCRM: true }
    case editClientCRMActionsTypes.RESPONSE:
      return {
        ...state,
        dataCCRM: action.data,
        statusECCRM: action.data.status,
        messageECCRM: action.data.message,
        loadingECCRM: false
      }
    case editClientCRMActionsTypes.CLEAR:
      return { ...state, dataCCRM: null, messageECCRM: '', statusECCRM: '' }
    default:
      return state
  }
}

export const clientCRMDeleteStoreKey = 'clientCRMDeleteStore'

export const deleteclientCRMReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case clientCRMDeleteTypes.DELETE_CLIENT_CRM:
      return { ...state, loading: true }
    case clientCRMDeleteTypes.SAVE_DELETE_CLIENT_CRM_RESPONSE:
      return {
        ...state,
        deleteclientCRM: res.data,
        deleteclientCRMStatus: res.status,
        deleteclientCRMMessage: res.message,
        loading: false
      }
    case clientCRMDeleteTypes.CLEAR_DELETE_CLIENT_CRM:
      return { ...state, deleteclientCRM: null, deleteclientCRMStatus: '', deleteclientCRMMessage: '' }
    default:
      return state
  }
}

export const addclientProcessorInitialState = {
  list: null
}

export const addclientProcessorStoreKey = 'addclientProcessorStore'

export const addclientProcessorReducer = (state = addclientProcessorInitialState, action) => {
  switch (action.type) {
    case addclientProcessorTypes.REQUEST:
      return { ...state, loadingACPR: true }
    case addclientProcessorTypes.RESPONSE:
      return { ...state, dataACPR: action.data, statusACPR: action.data.status, messageACPR: action.data.message, loadingACPR: false }
    case addclientProcessorTypes.CLEAR:
      return { ...state, dataACPR: null, messageACPR: '', statusACPR: '' }
    default:
      return state
  }
}

export const clientProcessorStoreKey = 'clientProcessorStore'

export const clientProcesorReducer = (state = {}, action) => {
  switch (action.type) {
    case clientProcessorActionsTypes.GET_CLIENT_PROCESSOR:
      return { ...state, clientProcessorLoading: true }
    case clientProcessorActionsTypes.SAVE_CLIENT_PROCESSOR_RESPONSE:
      return {
        ...state,
        clientProcessorData: action.data && action.data.data,
        statusCLPD: action.data && action.data.status,
        messageCLPD: action.data && action.data.message,
        clientProcessorLoading: false
      }
    case clientProcessorActionsTypes.CLEAR_CLIENT_PROCESSOR:
      return { ...state, clientProcessorData: null }
    default:
      return state
  }
}

export const getClientProcessorDetailsStoreKey = 'getClientProcessorDetailsStore'

export const getClientProcessorDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getClientProcessorDetailsActionsTypes.REQUEST:
      return { ...state, loadingCPDetails: true }
    case getClientProcessorDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getCPDetails: res.data,
        statusCPDetails: res.status,
        messageCPDetails: res.message,
        loadingCPDetails: false
      }
    case getClientProcessorDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getCPDetails: null,
        statusCPDetails: '',
        messageCPDetails: ''
      }
    default:
      return state
  }
}

export const editClientProcessorStoreKey = 'editClientProcessorStore'

export const editClientProcessorReducer = (state = {}, action) => {
  switch (action.type) {
    case editClientProcessorActionsTypes.REQUEST:
      return { ...state, loadingECP: true }
    case editClientProcessorActionsTypes.RESPONSE:
      return {
        ...state,
        dataECP: action.data,
        statusECP: action.data.status,
        messageECP: action.data.message,
        loadingECP: false
      }
    case editClientProcessorActionsTypes.CLEAR:
      return { ...state, dataECP: null, messageECP: '', statusECP: '' }
    default:
      return state
  }
}

export const clientProcessorDeleteStoreKey = 'clientProcessorDeleteStore'

export const deleteclientProcessorReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case clientProcessorDeleteTypes.DELETE_CLIENT_PROCESSOR:
      return { ...state, loading: true }
    case clientProcessorDeleteTypes.SAVE_DELETE_CLIENT_PROCESSOR_RESPONSE:
      return {
        ...state,
        deleteclientProcessor: res.data,
        deleteclientProcessorStatus: res.status,
        deleteclientProcessorMessage: res.message,
        loading: false
      }
    case clientProcessorDeleteTypes.CLEAR_DELETE_CLIENT_PROCESSOR:
      return { ...state, deleteclientProcessor: null, deleteclientProcessorStatus: '', deleteclientProcessorMessage: '' }
    default:
      return state
  }
}

export const addclientGatewayInitialState = {
  list: null
}

export const addclientGatewayStoreKey = 'addclientGatewayStore'

export const addclientGatewayReducer = (state = addclientGatewayInitialState, action) => {
  switch (action.type) {
    case addclientGatewayTypes.REQUEST:
      return { ...state, loadingACGA: true }
    case addclientGatewayTypes.RESPONSE:
      return { ...state, dataACGA: action.data, statusACGA: action.data.status, messageACGA: action.data.message, loadingACGA: false }
    case addclientGatewayTypes.CLEAR:
      return { ...state, dataACGA: null, messageACGA: '', statusACGA: '' }
    default:
      return state
  }
}

export const clientGatewayStoreKey = 'clientGatewayStore'

export const clientGatewayReducer = (state = {}, action) => {
  switch (action.type) {
    case clientGatewayActionsTypes.GET_CLIENT_GATEWAY:
      return { ...state, clientGatewayLoading: true }
    case clientGatewayActionsTypes.SAVE_CLIENT_GATEWAY_RESPONSE:
      return { ...state, clientGatewayData: action.data && action.data.data, clientGatewayLoading: false }
    case clientGatewayActionsTypes.CLEAR_CLIENT_GATEWAY:
      return { ...state, clientGatewayData: null }
    default:
      return state
  }
}

export const editClientGatewayStoreKey = 'editClientGatewayStore'

export const editClientGatewayReducer = (state = {}, action) => {
  switch (action.type) {
    case editClientPaymentActionsTypes.REQUEST:
      return { ...state, loadingECG: true }
    case editClientPaymentActionsTypes.RESPONSE:
      return {
        ...state,
        dataECG: action.data,
        statusECG: action.data.status,
        messageECG: action.data.message,
        loadingECG: false
      }
    case editClientPaymentActionsTypes.CLEAR:
      return { ...state, dataECG: null, messageECG: '', statusECG: '' }
    default:
      return state
  }
}

export const clientGatewayDeleteStoreKey = 'clientGatewayDeleteStore'

export const deleteclientGatewayReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case clientGatewayDeleteTypes.DELETE_CLIENT_GATEWAY:
      return { ...state, loading: true }
    case clientGatewayDeleteTypes.SAVE_DELETE_CLIENT_GATEWAY_RESPONSE:
      return {
        ...state,
        deleteclientGateway: res.data,
        deleteclientGatewayStatus: res.status,
        deleteclientGatewayMessage: res.message,
        loading: false
      }
    case clientGatewayDeleteTypes.CLEAR_DELETE_CLIENT_GATEWAY:
      return { ...state, deleteclientGateway: null, deleteclientGatewayStatus: '', deleteclientGatewayMessage: '' }
    default:
      return state
  }
}

export const midCRMInitialState = {
  list: null
}

export const midCRMStoreKey = 'midCRMStore'

export const midCRMReducer = (state = {}, action) => {
  switch (action.type) {
    case midCRMActionsTypes.GET_MID_CRM:
      return { ...state, loading: true }
    case midCRMActionsTypes.SAVE_MID_CRM_RESPONSE:
      return { ...state, getmidCRM: action.data, loading: false }
    case midCRMActionsTypes.CLEAR_MID_CRM:
      return { ...state, getmidCRM: null }

    case midCRMGetDetailsTypes.GET_MID_CRM_DETAILS:
      return { ...state, loadingGMIDCRM: true }
    case midCRMGetDetailsTypes.MID_CRM_DETAILS_RESPONSE:
      return {
        ...state,
        getDetailsMID: action.data && action.data.data,
        statusGMIDCRM: action.data && action.data.status,
        messagesGMIDCRM: action.data && action.data.message,
        loadingGMIDCRM: false
      }
    case midCRMGetDetailsTypes.CLEAR_MID_CRM_DETAILS:
      return { ...state, statusGMIDCRM: '', messagesGMIDCRM: '' }
    default:
      return state
  }
}
export const addmidCRMInitialState = {
  list: null
}

export const addmidCRMStoreKey = 'addmidCRMStore'

export const addmidCRMReducer = (state = addmidCRMInitialState, action) => {
  switch (action.type) {
    case addmidCRMTypes.REQUEST:
      return { ...state, loadingAMIDCRM: true }
    case addmidCRMTypes.RESPONSE:
      return { ...state, dataAMIDCRM: action.data, statusAMIDCRM: action.data.status, messageAMIDCRM: action.data.message, loadingAMIDCRM: false }
    case addmidCRMTypes.CLEAR:
      return { ...state, dataAMIDCRM: null, messageAMIDCRM: '', statusAMIDCRM: '' }
    default:
      return state
  }
}

export const midCRMDeleteStoreKey = 'midCRMDeleteStore'

export const deletemidCRMReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case midCRMDeleteTypes.DELETE_MID_CRM:
      return { ...state, loading: true }
    case midCRMDeleteTypes.SAVE_DELETE_MID_CRM_RESPONSE:
      return {
        ...state,
        deletemidCRM: res.data,
        deletemidCRMStatus: res.status,
        deletemidCRMMessage: res.message,
        loading: false
      }
    case midCRMDeleteTypes.CLEAR_DELETE_MID_CRM:
      return { ...state, deletemidCRM: null, deletemidCRMStatus: '', deletemidCRMMessage: '' }
    default:
      return state
  }
}
export const editMIDCRMStoreKey = 'editMIDCRMStore'

export const editMIDCRMReducer = (state = {}, action) => {
  switch (action.type) {
    case editmidCRMActionsTypes.REQUEST:
      return { ...state, loadingEMIDCRM: true }
    case editmidCRMActionsTypes.RESPONSE:
      return {
        ...state,
        dataEMIDCRM: action.data,
        statusEMIDCRM: action.data.status,
        messageEMIDCRM: action.data.message,
        loadingEMIDCRM: false
      }
    case editmidCRMActionsTypes.CLEAR:
      return { ...state, dataEMIDCRM: null, messageEMIDCRM: '', statusEMIDCRM: '' }
    default:
      return state
  }
}

export const checkProcessorInitialState = {
  list: null
}

export const checkProcessorStoreKey = 'checkProcessorStore'

export const checkProcessorReducer = (state = checkProcessorInitialState, action) => {
  switch (action.type) {
    case checkProcessorTypes.REQUEST:
      return { ...state, loadingCPR: true }
    case checkProcessorTypes.RESPONSE:
      return { ...state, dataCPR: action.data, statusCPR: action.data.status, messageCPR: action.data.message, loadingCPR: false }
    case checkProcessorTypes.CLEAR:
      return { ...state, dataCPR: null, messageCPR: '', statusCPR: '' }
    default:
      return state
  }
}

export const checkCrmInitialState = {
  list: null
}

export const checkCrmStoreKey = 'checkCrmStore'

export const checkCrmReducer = (state = checkCrmInitialState, action) => {
  switch (action.type) {
    case checkCrmTypes.REQUEST:
      return { ...state, loadingCCRM: true }
    case checkCrmTypes.RESPONSE:
      return { ...state, dataCCRM: action.data, statusCCRM: action.data.status, messageCCRM: action.data.message, loadingCCRM: false }
    case checkCrmTypes.CLEAR:
      return { ...state, dataCCRM: null, messageCCRM: '', statusCCRM: '' }
    default:
      return state
  }
}