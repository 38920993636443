import _ from 'lodash'

export const setCustomerData = (data) => {
  if (!_.isEmpty(data)) {
    return {
      customerSalutation: data.customerSalutation,
      customerFirstName: data.customerFirstName,
      customerLastName: data.customerLastName,
      customerAddress: data.customerAddress,
      customerCity: data.customerCity,
      customerState: data.customerState,
      customerZip: data.customerZip,
      customerCountry: data.customerCountry,
      customerEmailName: data.customerEmailName,
      customerEmail: data.customerEmail,
      customerPhoneType: data.customerPhoneType,
      customerPhoneNumber: data.customerPhoneNumber,
      firstTransactionDate: new Date(data.firstTransactionDate),
      dateOfBirth: new Date(data.dateOfBirth),
      lengthOfRelationship: data.lengthOfRelationship,
      authenticationUsedForIdentification: data.authenticationUsedForIdentification,
      notes: data.notes
    }
  }
}

export const setPurchaseData = (data) => {
  if (!_.isEmpty(data)) {
    return {
      productName: data.productPurchased.productName,
      productModel: data.productPurchased.productModel,
      productSku: data.productPurchased.productSku,
      productDescription: data.productPurchased.productDescription,
      productUrl: data.productPurchased.productUrl,
      productImageUrl: data.productPurchased.productImageUrl,
      unitPriceCurrency: data.productPurchased.unitPriceCurrency,
      unitPriceAmount: data.productPurchased.unitPriceAmount,
      quantity: data.productPurchased.quantity,
      notes: data.productPurchased.notes,
      industryDetails: data.productPurchased.industryDetails,
      shippingCarrierUrl: data.delivery.shippingCarrierUrl,
      shippingCarrierPhone: data.delivery.shippingCarrierPhone,
      trackingNumber: data.delivery.trackingNumber,
      dateOfShipment: new Date(data.delivery.dateOfShipment),
      dateOfDelivery: new Date(data.delivery.dateOfDelivery),
      signatureConfirmation: data.delivery.signatureConfirmation,
      signedBy: data.delivery.signedBy,
      exceptionNotes: data.delivery.exceptionNotes,
      physicalFulfillmentNotes: data.delivery.physicalFulfillmentNotes,
      proofOfDelivery: data.delivery.proofOfDelivery,
      proofOfUsage: data.delivery.proofOfUsage,
      locationOfUsage: data.delivery.locationOfUsage,
      digitalServiceDeliveryNotes: data.delivery.digitalServiceDeliveryNotes
    }
  }
}

export const setEmailData = (data) => {
  if (data && data.length > 0) {
    return {
      email: data && data[0].email && data[0].email,
      bccemail: data && data[0].emailBcc && data[0].emailBcc,
      ccemail: data && data[0].emailCc && data[0].emailCc
    }
  }
}

export const setCBDetails = (data) => {
  if (!_.isEmpty(data)) {
    return {
      acquirerName: data.acquirerName,
      affiliateId: data.affiliateId,
      arn: data.arn,
      authCode: data.authCode,
      avs: data.avs,
      blacklisted: data.blacklisted,
      cardCountry: data.cardCountry && data.cardCountry._id ? data.cardCountry._id : '',
      cardFirstSix: data.cardFirstSix,
      cardLastFour: data.cardLastFour,
      cardNetwork: data.cardNetwork,
      cardType: data.cardType,
      caseId: data.caseId,
      chargebackAmount: data.chargebackAmount,
      chargebackCurrency: data.chargebackCurrency,
      chargebackDate: new Date(data.chargebackDate),
      chargebackReason: data.chargebackReason,
      chargeBackType: data.chargeBackType,
      clientId: data.clientId && data.clientId._id ? data.clientId._id : '',
      companyName: data.companyName,
      country: data.country,
      customerEmail: data.customerEmail,
      customerIP: data.customerIP,
      customerName: data.customerName,
      customerPhone: data.customerPhone,
      cvv: data.cvv,
      DBAName: data.DBAName,
      deviceId: data.deviceId,
      disputeFiledDate: data.disputeFiledDate ? new Date(data.disputeFiledDate) : '',
      disputeMethod: data.disputeMethod,
      functionalArea: data.functionalArea,
      gateway: data.gateway,
      issuer: data.issuer,
      merchantId: data.merchantId && data.merchantId._id ? data.merchantId._id : '',
      merchantName: data.merchantName,
      mid: data.mid,
      nonUs: data.nonUs && data.nonUs._id ? data.nonUs._id : '',
      orderId: data.orderId,
      paymentId: data.paymentId,
      paymentProcessor: data.paymentProcessor && data.paymentProcessor._id ? data.paymentProcessor._id : '',
      paymentMethod: data.paymentMethod,
      productName: data.productName,
      reasonForLegitimateChargeback: data.reasonForLegitimateChargeback,
      refundIssued: data.refundIssued,
      rootCause: data.rootCause && data.rootCause._id ? data.rootCause._id : '',
      serviceType: data.serviceType && data.serviceType._id ? data.serviceType._id : '',
      saleType: data.saleType,
      subFunctionalArea: data.subFunctionalArea && data.subFunctionalArea._id ? data.subFunctionalArea._id : '',
      transactionAmount: data.transactionAmount,
      transactionDate: new Date(data.transactionDate),
      transactionId: data.transactionId,
      chargeBackStatus: data.chargeBackStatus,
      upiId: data.upiId,
      assignedTo: data.assignedTo && data.assignedTo._id ? data.assignedTo._id : '',
    }
  }
}
