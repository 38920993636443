import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  getMenuCompActionsTypes,
  getMenuCompActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchMenuComp (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userMenuComp, { params, headers: headers })
    if (json.status === 200) {
      yield put(getMenuCompActions.saveResponse(json.data))
    } else {
      yield put(getMenuCompActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getMenuCompActionsTypes.RESPONSE, data })
  }
}

export function * fetchMenuCompWatcher () {
  yield all([
    yield takeLatest(getMenuCompActionsTypes.REQUEST, fetchMenuComp)
  ])
}
