import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  ForgotActions,
  ForgotPasswordActionsTypes,
  VerifyUserActionsTypes,
  VerifyActions,
  ResetPasswordActionsTypes,
  ResetPasswordActions,
  ChangePasswordActionsTypes,
  ChangePasswordActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchForgotPassword (action) {
  const { payload, headers } = action
  try {
    const data = yield axiosInstance.put(serviceList.forgotPassword, payload, {headers:headers})
    if (data && data.data) {
      yield put(ForgotActions.saveForgotPasswordResponse(data.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: ForgotPasswordActionsTypes.SAVE_FORGOT_PASSWORD_RESPONSE, data })
  }
}

function * fetchVerifyUser (action) {
  const { payload, headers } = action
  try {
    const data = yield axiosInstance.post(serviceList.verifyUser, payload, {headers:headers})
    if (data && data.data) {
      yield put(VerifyActions.saveVerifyUserResponse(data.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: VerifyUserActionsTypes.SAVE_VERIFY_USER_RESPONSE, data })
  }
}

function * fetchResetPassword (action) {
  const { payload, headers } = action
  try {
    const data = yield axiosInstance.put(serviceList.resetPassword, payload, {headers:headers})
    if (data && data.data) {
      yield put(ResetPasswordActions.saveResetPasswordResponse(data.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: ResetPasswordActionsTypes.SAVE_RESET_PASSWORD_RESPONSE, data })
  }
}

function * fetchChangePassword (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.changePassword, payload, {headers:headers})
    if (json.status === 200) {
      yield put(ChangePasswordActions.saveChangePasswordResponse(json.data))
    } else {
      yield put(ChangePasswordActions.saveChangePasswordResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: ChangePasswordActionsTypes.SAVE_CHANGE_PASSWORD_RESPONSE, data })
  }
}

export function * fetchForgotWatcher () {
  yield all([
    yield takeLatest(
      ForgotPasswordActionsTypes.FORGOT_PASSWORD,
      fetchForgotPassword
    ),
    yield takeLatest(VerifyUserActionsTypes.VERIFY_USER, fetchVerifyUser),
    yield takeLatest(ResetPasswordActionsTypes.RESET_PASSWORD, fetchResetPassword),
    yield takeLatest(ChangePasswordActionsTypes.CHANGE_PASSWORD, fetchChangePassword)
  ])
}
