/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef ,useState} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl,defaultAlerts} from '../../../helpers'
import _ from 'lodash'
import {ListGroup} from 'react-bootstrap-v5';

interface IHeaderNotificationsMenu {
  userNotificationData: any;
  setNotifyParams:any
}

export const HeaderNotificationsMenu: React.FC<IHeaderNotificationsMenu> = ({
  userNotificationData,
  setNotifyParams
    }) => {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [params,setParams] = useState([])
  const [notification,setNotification] = useState<any>([])

  
  var debounce_fun =  _.debounce(function (user_value) {
    const temp : any = []
    user_value.forEach(element => 
      {  temp.push(element._id)
        
      });  
      setNotification(temp.length !== 0 && temp)
    }, 2000);
  const handleOnScroll = () => {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
            // setParams((e:any)=>(e+1))    
            debounce_fun(userNotificationData);
        }
      }
  };
useEffect(()=>{
  setNotifyParams(notification)
},[notification])




  return (
  <div
        className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
        data-kt-menu='true'
      >
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top'
          style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
        >
          <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
            Notifications <span className='fs-8 opacity-75 ps-3'>{userNotificationData.length}  reports</span>
          </h3>

          <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
            <li className='nav-item'>
              <a
                className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_1'
              >
                Alerts
              </a>
            </li>
           
          </ul>
        </div>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
            <div ref={listInnerRef} className='scroll-y mh-200px my-5 px-8'
              onScroll={()=> { handleOnScroll()}}
            >
          
              {!_.isEmpty(userNotificationData)   && typeof userNotificationData !== 'undefined' && userNotificationData?.map((alert, index) => (
                <div key={`alert${index}`} className='d-flex flex-stack py-4'>
               <div className='d-flex align-items-center'>
                    <div className='symbol symbol-35px me-4'>
                      <span className={clsx('symbol-label', `bg-light-${alert.notificationType}`)}>
                        {' '}
                        <KTSVG
                          path={`/media/${alert.icon}`}
                          className={`svg-icon-2 svg-icon-${alert.notificationType
        }`} />
                      </span>
                    </div>
                    <div className='mb-0 me-2'>
                      <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                        {alert?.title}
                      </a>
                      <div className='text-gray-500 fs-7'>{alert?.message}</div>
                    </div>
                  </div>
                  {/* <span className='badge badge-light fs-8'>{alert?.createdAt}</span> */}
                  <span className='fs-8 text-info'>{alert?.createdAt}</span>

                 </div>
              ))}
            </div>
            <div className='py-3 text-center border-top'>
              <button
                className='btn btn-color-gray-600 btn-active-color-primary'
                onClick={() => {}}
              >
                View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
              </button>
            </div>
          </div>
        </div>
  </div>
)}