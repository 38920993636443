export const getMenuCompActionsTypes = {
  REQUEST: 'GET_MENU_COMP_REQUEST',
  RESPONSE: 'GET_MENU_COMP_RESPONSE',
  ERROR: 'GET_MENU_COMP_ERROR',
  CLEAR: 'GET_MENU_COMP_CLEAR'
}

export const getMenuCompActions = {
  get: (params, headers) => ({
    type: getMenuCompActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getMenuCompActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getMenuCompActionsTypes.CLEAR
  })
}
