import {
  chargebackActionsTypes,
  chargeBackGetDetailsTypes,
  addChargebackTypes,
  editChargebackTypes,
  chargebackDeleteTypes,
  approveCBTypes,
  approveCBMActionsTypes,
  addNotificationToMerchantTypes,
  approveALLCBMTypes,
  updateCBApproveTypes,
  singleNotifyToMerchantTypes,
  singleApproveMerchantTypes,
  downloadInvoiceTypes
} from '../actions'

export const chargebackInitialState = {
  list: null
}

export const chargebackStoreKey = 'chargebackStore'

export const chargebackReducer = (state = chargebackInitialState, action) => {
  switch (action.type) {
    case chargebackActionsTypes.GET_CHARGEBACK:
      return { ...state, loading: true }
    case chargebackActionsTypes.SAVE_CHARGEBACK_RESPONSE:
      return { ...state, chargebacks: action.data && action.data, loading: false }
    case chargebackActionsTypes.CLEAR_CHARGEBACK:
      return { ...state, chargebacks: null }

    case chargeBackGetDetailsTypes.GET_CHARGEBACK_DETAILS:
      return { ...state, loadingCBD: true }
    case chargeBackGetDetailsTypes.CHARGEBACK_DETAILS_RESPONSE:
      return {
        ...state,
        ChargebackDetails: action.data && action.data.data,
        statusCBD: action.data && action.data.status,
        messagesCBD: action.data && action.data.message,
        loadingCBD: false
      }
    case chargeBackGetDetailsTypes.CLEAR_CHARGEBACK_DETAILS:
      return { ...state, statusCBD: '', messagesCBD: '' }
    default:
      return state
  }
}

export const addChargebackInitialState = {
  list: null
}

export const addChargebackStoreKey = 'addChargebackStore'

export const addChargebackReducer = (state = addChargebackInitialState, action) => {
  switch (action.type) {
    case addChargebackTypes.REQUEST:
      return { ...state, loadingAC: true }
    case addChargebackTypes.RESPONSE:
      return { ...state, dataAC: action.data, statusAC: action.data.status, messageAC: action.data.message, loadingAC: false }
    case addChargebackTypes.CLEAR:
      return { ...state, dataAC: null, messageAC: '', statusAC: '' }
    default:
      return state
  }
}

export const editChargebacksStoreKey = 'editChargebacksStore'

export const editChargebacksReducer = (state = {}, action) => {
  switch (action.type) {
    case editChargebackTypes.REQUEST:
      return { ...state, loadingEC: true }
    case editChargebackTypes.RESPONSE:
      return { ...state, dataEC: action.data && action.data, statusEC: action.data.status, messageEC: action.data.message, loadingEC: false }
    case editChargebackTypes.CLEAR:
      return { ...state, dataEC: null, messageEC: '', statusEC: '' }
    default:
      return state
  }
}

export const chargebackDeleteStoreKey = 'chargebackDeleteStore'

export const deleteChargebackReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case chargebackDeleteTypes.DELETE_CHARGEBACK:
      return { ...state, loading: true }
    case chargebackDeleteTypes.SAVE_DELETE_CHARGEBACK_RESPONSE:
      return {
        ...state,
        deleteChargeback: res.data,
        deleteChargebackStatus: res.status,
        deleteChargebackMessage: res.message,
        loading: false
      }
    case chargebackDeleteTypes.CLEAR_DELETE_CHARGEBACK:
      return { ...state, deleteChargeback: null, deleteChargebackStatus: '', deleteChargebackMessage: '' }
    default:
      return state
  }
}

export const approveCBInitialState = {
  list: null
}

export const approveCBStoreKey = 'approveCBStore'

export const approveCBReducer = (state = approveCBInitialState, action) => {
  switch (action.type) {
    case approveCBTypes.REQUEST:
      return { ...state, loadingAPCB: true }
    case approveCBTypes.RESPONSE:
      return { ...state, dataAPCB: action.data, statusAPCB: action.data.status, messageAPCB: action.data.message, loadingAPCB: false }
    case approveCBTypes.CLEAR:
      return { ...state, dataAPCB: null, messageAPCB: '', statusAPCB: '' }
    default:
      return state
  }
}

export const approveAllCBMInitialState = {
  list: null
}

export const approveAllCBMStoreKey = 'approveAllCBMStore'

export const approveAllCBMReducer = (state = approveAllCBMInitialState, action) => {
  switch (action.type) {
    case approveALLCBMTypes.REQUEST:
      return { ...state, loadingAPCBME: true }
    case approveALLCBMTypes.RESPONSE:
      return { ...state, dataAPCBME: action.data, statusAPCBME: action.data.status, messageAPCBME: action.data.message, loadingAPCBME: false }
    case approveALLCBMTypes.CLEAR:
      return { ...state, dataAPCBME: null, messageAPCBME: '', statusAPCBME: '' }
    default:
      return state
  }
}

export const addNotificationToMerchantStoreKey = 'addNotificationToMerchantStore'

export const addNotificationToMerchantReducer = (state = {}, action) => {
  switch (action.type) {
    case addNotificationToMerchantTypes.REQUEST:
      return { ...state, loadingNotifyToMerchant: true }
    case addNotificationToMerchantTypes.RESPONSE:
      return { ...state, dataNotifyMerchant: action.data.data, statusNotifyMerchant: action.data.status, messageNotifyMerchant: action.data.message, loadingNotifyToMerchant: false }
    case addNotificationToMerchantTypes.CLEAR:
      return { ...state, dataNotifyMerchant: null, messageNotifyMerchant: '', statusNotifyMerchant: '' }
    default:
      return state
  }
}

export const singleNotifyToMerchantStoreKey = 'singleNotifyToMerchantStore'

export const singleNotifyToMerchantReducer = (state = {}, action) => {
  switch (action.type) {
    case singleNotifyToMerchantTypes.REQUEST:
      return { ...state, loadingSingleNotifyToMerchant: true }
    case singleNotifyToMerchantTypes.RESPONSE:
      return {
        ...state,
        dataSingleNotifyMerchant: action.data.data,
        statusSingleNotifyMerchant: action.data.status,
        messageSingleNotifyMerchant: action.data.message,
        loadingSingleNotifyToMerchant: false
      }
    case singleNotifyToMerchantTypes.CLEAR:
      return {
        ...state,
        dataSingleNotifyMerchant: null,
        messageSingleNotifyMerchant: '',
        statusSingleNotifyMerchant: ''
      }
    default:
      return state
  }
}

export const singleApproveMerchantStoreKey = 'singleApproveMerchantStore'

export const singleApproveMerchantReducer = (state = {}, action) => {
  switch (action.type) {
    case singleApproveMerchantTypes.REQUEST:
      return { ...state, loadingSingleApproveMerchant: true }
    case singleApproveMerchantTypes.RESPONSE:
      return {
        ...state,
        dataSingleApproveMerchant: action.data.data,
        statusSingleApproveMerchant: action.data.status,
        messageSingleApproveMerchant: action.data.message,
        loadingSingleApproveMerchant: false
      }
    case singleApproveMerchantTypes.CLEAR:
      return {
        ...state,
        dataSingleApproveMerchant: null,
        messageSingleApproveMerchant: '',
        statusSingleApproveMerchant: ''
      }
    default:
      return state
  }
}

export const approveCBMInitialState = {
  list: null
}
export const approveCBMStoreKey = 'approveCBMStore'

export const approveCBMReducer = (state = approveCBMInitialState, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case approveCBMActionsTypes.REQUEST:
      return { ...state, loading: true }
    case approveCBMActionsTypes.RESPONSE:
      return { ...state, approveCBMs: res.data, loading: false }
    case approveCBMActionsTypes.CLEAR:
      return { ...state, approveCBMs: null }
    default:
      return state
  }
}

export const updateCBApproveStoreKey = 'updateCBApproveStore'

export const updateCBApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case updateCBApproveTypes.REQUEST:
      return { ...state, loadingUPCBA: true }
    case updateCBApproveTypes.RESPONSE:
      return { ...state, updateCBApprove: action.data, statusUPCBA: action.data.status, messageUPCBA: action.data.message, loadingUPCBA: false }
    case updateCBApproveTypes.CLEAR:
      return { ...state, updateCBApprove: null, messageUPCBA: '', statusUPCBA: '' }
    default:
      return state
  }
}

export const downloadInvoiceStoreKey = 'downloadInvoiceStore'

export const downloadInvoiceReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case downloadInvoiceTypes.REQUEST:
      return { ...state, loadingADI: true }
    case downloadInvoiceTypes.RESPONSE:
      return { ...state, dataADI: res.data, statusADI: res.status, messageADI: res.message, loadingADI: false }
    case downloadInvoiceTypes.CLEAR:
      return { ...state, dataADI: null, messageADI: '', statusADI: '' }
    default:
      return state
  }
}