import { bankActionsTypes } from '../actions'

export const bankInitialState = {
  list: null
}

export const bankStoreKey = 'bankStore'

const bankReducer = (state = bankInitialState, action) => {
  switch (action.type) {
    case bankActionsTypes.GET_BANK:
      return { ...state, loading: true }
    case bankActionsTypes.SAVE_BANK_RESPONSE:
      return { ...state, getBank: action.data, loading: false }
    case bankActionsTypes.CLEAR_BANK:
      return { ...state, getBank: null }
    default:
      return state
  }
}

export default bankReducer
