export const addIndustrySettingsActionsTypes = {
  REQUEST: 'ADD_INDUSTRY_SETTINGS_REQUEST',
  RESPONSE: 'ADD_INDUSTRY_SETTINGS_RESPONSE',
  ERROR: 'ADD_INDUSTRY_SETTINGS_ERROR',
  CLEAR: 'ADD_INDUSTRY_SETTINGS_CLEAR'
}

export const addIndustrySettingsActions = {
  add: (data, headers) => {
    return {
      type: addIndustrySettingsActionsTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addIndustrySettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addIndustrySettingsActionsTypes.CLEAR
  })
}

export const getIndustrySettingsDetailsActionsTypes = {
  REQUEST: 'GET_INDUSTRY_DETAILS_REQUEST',
  RESPONSE: 'GET_INDUSTRY_DETAILS_RESPONSE',
  ERROR: 'GET_INDUSTRY_DETAILS_ERROR',
  CLEAR: 'GET_INDUSTRY_DETAILS_CLEAR'
}
export const getIndustrySettingsDetailsActions = {
  getDetails: (id, headers) => ({
    type: getIndustrySettingsDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getIndustrySettingsDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getIndustrySettingsDetailsActionsTypes.CLEAR
  })
}

export const updateIndustrySettingsActionsTypes = {
  REQUEST: 'UPDATE_INDUSTRY_SETTINGS_REQUEST',
  RESPONSE: 'UPDATE_INDUSTRY_SETTINGS_RESPONSE',
  ERROR: 'UPDATE_INDUSTRY_SETTINGS_ERROR',
  CLEAR: 'UPDATE_INDUSTRY_SETTINGS_CLEAR'
}

export const updateIndustrySettingsActions = {
  update: (id, params, headers) => {
    return {
      type: updateIndustrySettingsActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updateIndustrySettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updateIndustrySettingsActionsTypes.CLEAR
  })
}

export const deleteIndustrySettingsActionsTypes = {
  REQUEST: 'DELETE_INDUSTRY_SETTINGS_REQUEST',
  RESPONSE: 'DELETE_INDUSTRY_SETTINGS_RESPONSE',
  ERROR: 'DELETE_INDUSTRY_SETTINGS_ERROR',
  CLEAR: 'DELETE_INDUSTRY_SETTINGS_CLEAR'
}

export const deleteIndustrySettingsActions = {
  delete: (params, headers) => ({
    type: deleteIndustrySettingsActionsTypes.REQUEST,
    params, headers
  }),
  savemResponse: (data) => ({
    type: deleteIndustrySettingsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteIndustrySettingsActionsTypes.CLEAR
  })
}
