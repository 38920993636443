export const LoginActionsTypes = {
  LOGIN: 'LOGIN',
  SAVE_LOGIN_RESPONSE: 'SAVE_LOGIN_RESPONSE',
  CLEAR_LOGIN: 'CLEAR_LOGIN',
  SET_TIMER: 'SET_TIMER'
}

export const LoginActions = {
  login: (data, headers) => {
    return {
      type: LoginActionsTypes.LOGIN,
      payload: data, headers
    }
  },
  saveLoginResponse: data => ({
    type: LoginActionsTypes.SAVE_LOGIN_RESPONSE,
    data
  }),
  clearLogin: () => ({
    type: LoginActionsTypes.CLEAR_LOGIN
  }),
  setTimer: (data) => ({
    type: LoginActionsTypes.SET_TIMER,
    data
  })
}
