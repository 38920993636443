import React from 'react'
import DatePicker from 'react-datepicker'
import { DATE } from '../../../utils/constants'
import 'react-datepicker/src/stylesheets/datepicker.scss'

export const DateSelector = (props) => {
  const {
    selected,
    onChange,
    name,
    className,
    placeholder,
    maxDate,
    minDate,
    showTimeSelect,
    showTimeSelectOnly,
    dateFormat,
    minTime,
    maxTime,
    readOnly,
    showMonthYearPicker,
    showYearPicker,
    yearRange
  } = props
  return (
    <DatePicker
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly || false}
      timeIntervals={DATE.TIME_INTERVAL}
      timeCaption={showTimeSelect ? DATE.TIME : false}
      className={className}
      name={name}
      readOnly={readOnly}
      selected={selected}
      onChange={date => onChange(date)}
      placeholderText={placeholder}
      dateFormat={dateFormat}
      maxDate={maxDate}
      minDate={minDate || null}
      maxTime={maxTime}
      minTime={minTime || null}
      showMonthYearPicker={showMonthYearPicker}
      showYearPicker={showYearPicker}
      show
      autoComplete='off'
      yearRange={yearRange}
      onChangeRaw={(e) => { e.preventDefault() }}
    />
  )
}

export const DateTimeSelector = (props) => {
  const {
    selected,
    onChange,
    name,
    className,
    placeholder,
    maxDate,
    minDate
  } = props
  return (
    <DatePicker
      className={className}
      name={name}
      selected={selected}
      onChange={date => onChange(date)}
      placeholderText={placeholder}
      dateFormat='dd-MM-yyyy hh:mm a'
      maxDate={maxDate}
      minDate={minDate || null}
      showTimeSelect
      timeFormat='hh:mm'
      timeIntervals={15}
      timeCaption='time'
      autoComplete='off'
      onChangeRaw={(e) => { e.preventDefault() }}
    />
  )
}
