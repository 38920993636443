/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill'

// Import all the third party stuff
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { LayoutProvider, LayoutSplashScreen } from './theme/layout/core/index'

// Import root app
import { Routes } from './routing/Routes'
import './theme/assets/sass/style.scss'
import './theme/assets/sass/style.react.scss'
import store from './store'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <Suspense fallback={<LayoutSplashScreen />}>
    <Provider store={store}>
      <Router>
        <LayoutProvider>
          <Routes />
        </LayoutProvider>
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
