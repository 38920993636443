import _ from 'lodash'

export const setCrmData = (data) => {
  if (!_.isEmpty(data) && data.CRM !== undefined) {
    return {
      crmName: data.CRM && data.CRM.crmId ? data.CRM.crmId.crmName : '',
      storeName: data.CRM && data.CRM.storeName ? data.CRM.storeName : ''
    }
  }
}

export const setProcessorData = (data) => {
  if (!_.isEmpty(data)) {
    return {
      processorName: data.processor && data.processor.paymentProcessorId.processorName ? data.processor.paymentProcessorId.processorName : ''
    }
  }
}

export const setClientData = (data) => {
  if (!_.isEmpty(data) && data.Client !== undefined) {
    return {
      company: data.Client.company ? data.Client.company : '',
      clientCountry: data.Client && data.Client.clientCountry ? data.Client.clientCountry : '',
      industry: data.Client && data.Client.industry ? data.Client.industry : '',
      customerId: data.Client.customerId ? data.Client.customerId : '',
      clientAddress: data.Client.clientAddress ? data.Client.clientAddress : '',
      clientCity: data.Client && data.Client.clientCity ? data.Client.clientCity : '',
      clientState: data.Client && data.Client.clientState ? data.Client.clientState : '',
      clientZip: data.Client.clientZip ? data.Client.clientZip : '',
      clientEmail: data.Client.clientEmail ? data.Client.clientEmail : '',
      clientPhoneNumber: data.Client.clientPhoneNumber ? data.Client.clientPhoneNumber : '',
      phoneNumberExtension: data.Client.phoneNumberExtension ? data.Client.phoneNumberExtension : '',
      clientURL: data.Client.clientURL ? data.Client.clientURL : '',
      leadSource: data.Client.leadSource ? data.Client.leadSource : '',
      clientComplexity: data.Client.clientComplexity ? data.Client.clientComplexity : '',
      currency: data.Client.currency ? data.Client.currency : '',
      clientDescription: data.Client.clientDescription ? data.Client.clientDescription : ''
    }
  }
}

export const setMerchantData = (data) => {
  if (!_.isEmpty(data) && data.Merchant !== null && data.Merchant !== undefined) {
    return {
      merchantName: data.Merchant.merchantName ? data.Merchant.merchantName : '',
      merchantCountry: data.Merchant && data.Merchant.merchantCountry ? data.Merchant.merchantCountry : '',
      industry: data.Merchant && data.Merchant ? data.Merchant.industry : '',
      merchantAddress: data.Merchant.merchantAddress ? data.Merchant.merchantAddress : '',
      merchantCity: data.Merchant && data.Merchant.merchantCity ? data.Merchant.merchantCity : '',
      merchantState: data.Merchant && data.Merchant.merchantState ? data.Merchant.merchantState : '',
      merchantZip: data.Merchant.merchantZip ? data.Merchant.merchantZip : '',
      merchantEmail: data.Merchant.merchantEmail ? data.Merchant.merchantEmail : '',
      merchantPhoneNumber: data.Merchant.merchantPhoneNumber ? data.Merchant.merchantPhoneNumber : '',
      phoneNumberExtension: data.Merchant.phoneNumberExtension ? data.Merchant.phoneNumberExtension : '',
      externalId: data.Merchant.externalId ? data.Merchant.externalId : '',
      companyDescription: data.Merchant.companyDescription ? data.Merchant.companyDescription : ''
    }
  }
}