import React from "react";
import PropTypes from 'prop-types'
import { PayPalButton } from "react-paypal-button-v2";

export const PayPalBtn = (props) => {
  const {
    amount,
    currency,
    createSubscription,
    onApprove,
    catchError,
    onError,
    onCancel,
    paypalKey
  } = props;
  return (
      <>
        <PayPalButton
            forceRerender={amount}
            amount={amount}
            currency={currency}
            createSubscription={(data, details) => createSubscription(data, details)}
            onApprove={(data, details) => onApprove(data, details)}
            // onSuccess={onSuccess}
            onError={(err) => onError(err)}
            catchError={(err) => catchError(err)}
            onCancel={(err) => onCancel(err)}
            options={{
                clientId: paypalKey,
                vault: true,
            }}
            style={{
                shape: "rect",
                color: "gold",
                layout: "horizontal",
                label: "subscribe",
            }}
        />
    </>
  );
}

PayPalBtn.propTypes = {
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  createSubscription: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  catchError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  paypalKey: PropTypes.string.isRequired,
  id: PropTypes.string,
  ref: PropTypes.any,
}
