import React, { useState, useEffect } from 'react'
import KTStepper from './chargebackDetails'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import color from '../../../utils/colors'
import { DATE, STATUS_RESPONSE, REGEX, currencyOptions, SESSION, API } from '../../../utils/constants'
import { getLocalStorage, headersWithAuth } from '../../../utils/helper'
import { DateSelector } from '../../../theme/layout/components/DateSelector'
import ReactSelect from '../../../theme/layout/components/ReactSelect'
import { checkKeyshasValues } from '../functions/function'
import moment from 'moment'
import {
  clientActions,
  merchantActions,
  chargeBackDetailsActions,
  editChargebacksActions,
  ReasoncodesActions,
  countryActions,
  getFunctionalActions,
  getSubFunactionalActions,
  getRootCauseActions,
  serviceTypeActions,
  clientProcessorActions,
  midCRMActions,
  getAnalystActions
} from '../../../store/actions'
import validate from '../addChargeback/validation'
import { confirmAlert, warningAlert } from '../../../utils/alerts'

function CBData(props) {
  const {
    getCBDetailsDispatch,
    clearCBDetailsDispatch,
    statusCBD,
    loadingCBD,
    ChargebackDetails,
    editChargebacksDispatch,
    getClientDispatch,
    statusEC,
    messageEC,
    cleareditChargebacksDispatch,
    getClient,
    getMerchantDispatch,
    getMerchant,
    getCountryDispatch,
    getCountrys,
    getReasonCodeDispatch,
    reasonCode,
    getFunctionalDispatch,
    getFunctional,
    getRootCauseDispatch,
    getRootCause,
    getSubFuncDispatch,
    getSubFunctional,
    getServicetypeDispatch,
    getServicetype,
    getProcessorDispatch,
    clientProcessorData,
    loadingGRC,
    loadingEC,
    getMIDCodeDispatch,
    getmidCRM,
    loadingClient,
    loadingMerchant,
    loadingMID,
    loadingProcessor,
    getAnalystDispatch,
    getAnalyst
  } = props

  const RouteUrl = getLocalStorage(API.API_KEY);
  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const pathUrl = new URL(window.location.href);
  const query = pathUrl && pathUrl.search.split("?");
  const apiKeyParam = query && query[1];
  const apiParam = apiKeyParam && apiKeyParam.split("apiKey=")
  const apiParamId = apiParam && apiParam[1]
  const history = useHistory()
  const url = useLocation().pathname
  const fields = url && url.split('/')
  const id = fields && fields[3]
  const [editForm, setEditForm] = useState(false)
  const [errors, setErrors] = useState({})
  const [cbForm, setCBForm] = useState(false)
  const [reviewForm, setReviewForm] = useState(true)
  const [selectedClientOption, setSelectedClientOption] = useState('')
  const [clientOption, setClientOption] = useState()
  const [selectedMerchantOption, setSelectedMerchantOption] = useState('')
  const [merchantOption, setMerchantOption] = useState()
  const [countryOption, setCountryOption] = useState()
  const [selectedCountryOption, setSelectedCountryOption] = useState('')
  const [cardCountryOption, setCardCountryOption] = useState()
  const [selectedCardCountryOption, setSelectedCardCountryOption] = useState('')
  const [functionalOption, setFunctionalOption] = useState()
  const [selectedFunctionalOption, setSelectedFunctionalOption] = useState('')
  const [rootCauseOption, setRootCauseOption] = useState()
  const [selectedRootCauseOption, setSelectedRootCauseOption] = useState('')
  const [subFuncOption, setsubFuncOption] = useState()
  const [selectedsubFuncOption, setSelectedsubFuncOption] = useState('')
  const [serviceOption, setServiceOption] = useState()
  const [selectedServiceOption, setSelectedServiceOption] = useState('')
  const [processorOption, setProcessorOption] = useState()
  const [selectedProcessorOption, setSelectedProcessorOption] = useState('')
  const [midOption, setMIDOption] = useState()
  const [selectedMIDOption, setSelectedMIDOption] = useState('')
  const [authCodeOption, setAuthCodeOption] = useState()
  const [selectedAuthCodeOption, setSelectedAuthCodeOption] = useState('')
  const [cbReasonOption, setCBReasonOption] = useState()
  const [selectedCBReasonOption, setSelectedCBReasonOption] = useState('')
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState()
  const [, setAuthCodeLabel] = useState('')
  const [selectedAnalystOption, setSelectedAnalystOption] = useState('')
  const [analystOption, setAnalystOption] = useState()
  const [typingTimeout, setTypingTimeout] = useState(0)
  const [error, setError] = useState('')
  const [formData, setFormData] = useState({
    assignedTo: '',
    acquirerName: '',
    affiliateId: '',
    arn: '',
    authCode: '',
    avs: '',
    blacklisted: '',
    cardCountry: '',
    cardFirstSix: '',
    cardLastFour: '',
    cardNetwork: '',
    cardType: '',
    caseId: '',
    chargebackAmount: '',
    chargebackCurrency: '',
    chargebackDate: null,
    chargebackReason: '',
    chargeBackType: '',
    clientId: '',
    companyName: '',
    country: '',
    customerEmail: '',
    customerIP: '',
    customerName: '',
    customerPhone: '',
    cvv: '',
    DBAName: '',
    deviceId: '',
    disputeFiledDate: null,
    disputeMethod: '',
    functionalArea: '',
    gateway: '',
    issuer: '',
    merchantId: '',
    merchantName: '',
    mid: '',
    nonUs: '',
    orderId: '',
    paymentId: '',
    paymentProcessor: '',
    paymentMethod: '',
    productName: '',
    reasonForLegitimateChargeback: '',
    refundIssued: '',
    rootCause: '',
    serviceType: '',
    saleType: '',
    subFunctionalArea: '',
    transactionAmount: '',
    transactionDate: null,
    transactionId: '',
    chargeBackStatus: '',
    upiId: '',
    chargebackDeadlineDate: null
  })
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': apiParamId ? apiParamId : getLocalStorage(API.API_KEY)
  }

  const params = {
    skipPagination: true
  }

  const handleChange = (e) => {
    e.persist()
    setFormData(values => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
    e.preventDefault()
    const { value, name } = e.target
    setErrors({ ...errors, [e.target.name]: '' })
    if (name === 'country') {
      if (value === 'US') {
        setFormData({
          ...formData,
          [name]: value,
          nonUs: ''
        })
        setErrors({ ...errors, nonUs: '', country: '' })
      } else {
        setFormData({ ...formData, [name]: value })
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
  }

  const handleAuthCodeChange = (e) => {
    e.preventDefault()
    const { value, name } = e.target
    setErrors({ ...errors, [name]: '' })
    if (value === 'paypal' || value === 'visa' || value === 'amex' || value === 'master'||value ==='customerIP') {
      setFormData({
        ...formData,
        [name]: value
      })
      const authParams = {
        skipPagination: true,
        cardNetwork: value
      }
      getReasonCodeDispatch(authParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      setSelectedAuthCodeOption()
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  const handleIPValidation = (e) => {
    if (!/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?$/.test(formData.customerIP)) {
      setError('Invalid IP Address');
    } else {
      setError('');
    }
  };


  const handleEdit = () => {
    setEditForm(val => !val)
  }

  const handleSubmit = () => {
    const errorMsg = validate(formData, setErrors)
    if (_.isEmpty(errorMsg)) {
      editChargebacksDispatch(id, _.pickBy(formData), isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }

  const onConfirm = () => {
    history.push(isAuthorized ? '/chargeback-management' : `/manage-chargeback?apiKey=${RouteUrl}`)
  }

  useEffect(() => {
    if (statusEC === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'success',
        messageEC,
        'success',
        'Ok',
        () => { onConfirm() },
        () => { onConfirm() }
      )
      cleareditChargebacksDispatch()
    } else if (statusEC === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'Error',
        messageEC,
        '',
        'Ok'
      )
      cleareditChargebacksDispatch()
    }
  }, [statusEC])

  useEffect(() => {
    getClientDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getCountryDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getFunctionalDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getRootCauseDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getSubFuncDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getServicetypeDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getReasonCodeDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getAnalystDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
  }, [])

  useEffect(() => {
    if (ChargebackDetails && ChargebackDetails.clientId) {
      const InitialParam = {
        clientId: ChargebackDetails.clientId ? ChargebackDetails.clientId._id : formData.clientId
      }
      getMerchantDispatch(InitialParam, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      getProcessorDispatch(InitialParam, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      getMIDCodeDispatch(InitialParam, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }, [ChargebackDetails])

  useEffect(() => {
    if (id) {
      getCBDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }, [id])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : ''
    })
  }

  const getDefaultOptions = (data, name) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ''}`,
          value: item._id
        })
      )
      return defaultOptions
    }
  }

  const getDefaultCBOptions = (data, name) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ''}`,
          value: item[name]
        })
      )
      return defaultOptions
    }
  }

  const getDefaultMerchantOptions = (data, name) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ''}`,
          value: item._id
        })
      )
      return defaultOptions
    }
  }

  const getDefaultProcessorOptions = (data) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) => {
        if (item.paymentProcessorId && item.paymentProcessorId.processorName) {
          defaultOptions.push({
            label: item.paymentProcessorId && item.paymentProcessorId.processorName ? item.paymentProcessorId.processorName : '',
            value: item.paymentProcessorId && item.paymentProcessorId._id ? item.paymentProcessorId._id : ''
          })
        }
      }
      )
      return defaultOptions
    }
  }

  const getDefaultAuthCodeOptions = (data) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) => {
        if (formData.cardNetwork === 'paypal') {
          if (item.paypalCode) {
            defaultOptions.push({
              label: `${item.paypalCode}`,
              value: item.paypalCode
            })
          }
        } else if (formData.cardNetwork === 'visa') {
          if (item.visaCodeOld || item.visaCodeNew) {
            defaultOptions.push({
              label: `${item.visaCodeOld} || ${item.visaCodeNew}`,
              value: `${item.visaCodeOld} ${item.visaCodeNew}`
            })
          }
        } else if (formData.cardNetwork === 'amex') {
          if (item.amexCode) {
            defaultOptions.push({
              label: `${item.amexCode}`,
              value: item.amexCode
            })
          }
        } else if (formData.cardNetwork === 'master') {
          if (item.masterCode) {
            defaultOptions.push({
              label: `${item.masterCode}`,
              value: item.masterCode
            })
          }
        }
      })
      return defaultOptions
    }
  }

  const getDefaultAnalystOptions = (data) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item.firstName ? _.startCase(item.firstName) : '--'}
          ${item.lastName ? _.startCase(item.lastName) : '--'}`,
          value: item._id
        })
      )
      return defaultOptions
    }
  }

  useEffect(() => {
    const data = getDefaultOptions(getClient && getClient.result, 'company')
    setClientOption(data)
  }, [getClient])

  useEffect(() => {
    const data = getDefaultMerchantOptions(getMerchant && getMerchant.result, 'merchantName')
    setMerchantOption(data)
  }, [getMerchant])

  useEffect(() => {
    const nonUs = getDefaultOptions(getCountrys, 'name')
    setCountryOption(nonUs)
  }, [getCountrys])

  useEffect(() => {
    const cardCountry = getDefaultOptions(getCountrys, 'name')
    setCardCountryOption(cardCountry)
  }, [getCountrys])

  useEffect(() => {
    const data = getDefaultOptions(getRootCause, 'rootCauseType')
    setRootCauseOption(data)
  }, [getRootCause])

  useEffect(() => {
    const data = getDefaultOptions(getFunctional, 'functionalType')
    setFunctionalOption(data)
  }, [getFunctional])

  useEffect(() => {
    const data = getDefaultOptions(getSubFunctional, 'subFunctionalType')
    setsubFuncOption(data)
  }, [getSubFunctional])

  useEffect(() => {
    const data = getDefaultOptions(getServicetype, 'serviceType')
    setServiceOption(data)
  }, [getServicetype])

  useEffect(() => {
    const data = getDefaultProcessorOptions(clientProcessorData, 'processorName')
    setProcessorOption(data)
  }, [clientProcessorData])

  useEffect(() => {
    const data = getDefaultOptions(getmidCRM && getmidCRM.data, 'midNumber')
    setMIDOption(data)
  }, [getmidCRM])

  useEffect(() => {
    const getReasonCode = reasonCode && reasonCode.data && reasonCode.data.result
      ? reasonCode.data.result
      : []
    const data = getDefaultAuthCodeOptions(getReasonCode)
    setAuthCodeOption(data)
  }, [reasonCode])

  useEffect(() => {
    const getReasonCode = reasonCode && reasonCode.data && reasonCode.data.result
      ? reasonCode.data.result
      : []
    const data = getDefaultCBOptions(getReasonCode, 'chargebackReason')
    setCBReasonOption(data)
  }, [reasonCode])

  useEffect(() => {
    const data = getDefaultAnalystOptions(getAnalyst && getAnalyst.result)
    setAnalystOption(data)
  }, [getAnalyst])

  const handleChangeClient = selectedOption => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }
      setTypingTimeout(
        setTimeout(() => {
          const merchantParams = {
            skipPagination: true,
            clientId: selectedOption.value
          }
          getMerchantDispatch(merchantParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
          const midParams = {
            skipPagination: true,
            clientId: selectedOption.value,
          };
          getMIDCodeDispatch(
            midParams,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          const processorParams = {
            skipPagination: true,
            clientId: selectedOption.value,
          }
          getProcessorDispatch(
            processorParams,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      )
      setSelectedClientOption(selectedOption)
      setFormData(values => ({ ...values, clientId: selectedOption.value, companyName: selectedOption.label }))
      setErrors({ ...errors, clientId: '', companyName: '' })
    } else {
      setSelectedClientOption()
      setSelectedMerchantOption()
      setSelectedMIDOption()
      setSelectedProcessorOption()
      setFormData(values => ({ ...values, clientId: '', companyName: '' }))
    }
  }

  const handleChangeMerchant = selectedOption => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }
      setTypingTimeout(
        setTimeout(() => {
          const midParams = {
            skipPagination: true,
            clientId: ChargebackDetails.clientId ? ChargebackDetails.clientId._id : formData.clientId,
            merchantId: selectedOption.value
          }
          getMIDCodeDispatch(midParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
          const processorParams = {
            skipPagination: true,
            merchantId: selectedOption.value,
          }
          getProcessorDispatch(
            processorParams,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      )
      setSelectedMerchantOption(selectedOption)
      setFormData(values => ({ ...values, merchantId: selectedOption.value, merchantName: selectedOption.label }))
      setErrors({ ...errors, merchantId: '', merchantName: '' })
    } else {
      setSelectedMerchantOption()
      setSelectedMIDOption()
      setSelectedProcessorOption()
      setFormData(values => ({ ...values, merchantId: '', merchantName: '' }))
    }
  }

  const handleChangeMID = selectedOption => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }
      setTypingTimeout(
        setTimeout(() => {
          const processorParams = {
            skipPagination: true,
            clientId: ChargebackDetails.clientId ? ChargebackDetails.clientId._id : formData.clientId,
            merchantId: ChargebackDetails.merchantId ? ChargebackDetails.merchantId._id : '' || formData.merchantId ? formData.merchantId : '',
            mid: selectedOption.value
          }
          getProcessorDispatch(processorParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
        }, 1500)
      )
      setSelectedMIDOption(selectedOption)
      setFormData(values => ({ ...values, mid: selectedOption.value }))
      setErrors({ ...errors, mid: '' })
    } else {
      setSelectedMIDOption()
      setSelectedProcessorOption()
      setFormData(values => ({ ...values, mid: '' }))
    }
  }

  const handleChangeCountry = selectedOption => {
    if (selectedOption !== null) {
      setSelectedCountryOption(selectedOption)
      setFormData(values => ({ ...values, nonUs: selectedOption.value }))
      setErrors({ ...errors, nonUs: '' })
    } else {
      setSelectedCountryOption()
      setFormData(values => ({ ...values, nonUs: '' }))
    }
  }

  const handleChangeCardCountry = selectedOption => {
    if (selectedOption !== null) {
      setSelectedCardCountryOption(selectedOption)
      setFormData(values => ({ ...values, cardCountry: selectedOption.value }))
      setErrors({ ...errors, cardCountry: '' })
    } else {
      setSelectedCardCountryOption()
      setFormData(values => ({ ...values, cardCountry: '' }))
    }
  }

  const handleChangeFunctional = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedFunctionalOption(selectedOption)
      setFormData((values) => ({
        ...values,
        functionalArea: selectedOption.value
      }))
      setErrors((values) => ({ ...values, functionalArea: '' }))
    } else {
      setSelectedFunctionalOption()
      setFormData((values) => ({ ...values, functionalArea: '' }))
    }
  }

  const handleChangeRootCause = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedRootCauseOption(selectedOption)
      setFormData((values) => ({
        ...values,
        rootCause: selectedOption.value
      }))
      setErrors((values) => ({ ...values, rootCause: '' }))
    } else {
      setSelectedRootCauseOption()
      setFormData((values) => ({ ...values, rootCause: '' }))
    }
  }

  const handleChangeSubFunc = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedsubFuncOption(selectedOption)
      setFormData((values) => ({
        ...values,
        subFunctionalArea: selectedOption.value
      }))
      setErrors((values) => ({ ...values, subFunctionalArea: '' }))
    } else {
      setSelectedsubFuncOption()
      setFormData((values) => ({ ...values, subFunctionalArea: '' }))
    }
  }

  const handleChangeService = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedServiceOption(selectedOption)
      setFormData((values) => ({
        ...values,
        serviceType: selectedOption.value
      }))
      setErrors((values) => ({ ...values, serviceType: '' }))
    } else {
      setSelectedServiceOption()
      setFormData((values) => ({ ...values, serviceType: '' }))
    }
  }

  const handleChangeProcessor = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedProcessorOption(selectedOption)
      setFormData((values) => ({
        ...values,
        paymentProcessor: selectedOption.value
      }))
      setErrors((values) => ({ ...values, paymentProcessor: '' }))
    } else {
      setSelectedProcessorOption()
      setFormData((values) => ({ ...values, paymentProcessor: '' }))
    }
  }

  const handleChangeAuthcode = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedAuthCodeOption(selectedOption)
      setFormData((values) => ({
        ...values,
        authCode: selectedOption.value
      }))
      setAuthCodeLabel(selectedOption.label)
      setSelectedCBReasonOption()
      if (formData.cardNetwork === 'paypal') {
        const authParams = {
          skipPagination: true,
          cardNetwork: formData.cardNetwork,
          authCode: selectedOption.value
        }
        getReasonCodeDispatch(authParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      }
      if (formData.cardNetwork === 'visa') {
        const visaParams = {
          skipPagination: true,
          cardNetwork: formData.cardNetwork,
          authCode: selectedOption.value
        }
        getReasonCodeDispatch(visaParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      }
      if (formData.cardNetwork === 'amex') {
        const amexParams = {
          skipPagination: true,
          cardNetwork: formData.cardNetwork,
          authCode: selectedOption.value
        }
        getReasonCodeDispatch(amexParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      }
      if (formData.cardNetwork === 'master') {
        const masterParams = {
          skipPagination: true,
          cardNetwork: formData.cardNetwork,
          authCode: selectedOption.value
        }
        getReasonCodeDispatch(masterParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      }
      setErrors((values) => ({ ...values, authCode: '' }))
    } else {
      setSelectedAuthCodeOption()
      setFormData((values) => ({ ...values, authCode: '' }))
    }
  }

  const handleChangeCBReason = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCBReasonOption(selectedOption)
      setFormData((values) => ({
        ...values,
        chargebackReason: selectedOption.value
      }))
      setErrors((values) => ({ ...values, chargebackReason: '' }))
    } else {
      setSelectedCBReasonOption()
      setFormData((values) => ({ ...values, chargebackReason: '' }))
    }
  }

  const handleChangeCurrency = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCurrencyOption(selectedOption)
      setFormData((values) => ({ ...values, chargebackCurrency: selectedOption.value }))
    } else {
      setSelectedCurrencyOption()
      setFormData((values) => ({ ...values, chargebackCurrency: '' }))
    }
    setErrors({ ...errors, chargebackCurrency: '' })
  }

  const handleChangeAnalyst = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedAnalystOption(selectedOption)
      setFormData((values) => ({
        ...values,
        assignedTo: selectedOption.value
      }))
      setErrors((values) => ({ ...values, assignedTo: '' }))
    } else {
      setSelectedAnalystOption()
      setFormData((values) => ({ ...values, assignedTo: '' }))
    }
  }

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  useEffect(() => {
    if (statusCBD === STATUS_RESPONSE.SUCCESS_MSG) {
      if (ChargebackDetails && ChargebackDetails._id) {
        setFormData(values => ({
          ...values,
          ...ChargebackDetails,
          chargebackDate: ChargebackDetails && ChargebackDetails.chargebackDate ? new Date(ChargebackDetails.chargebackDate) : null,
          disputeFiledDate: ChargebackDetails && ChargebackDetails.disputeFiledDate ? new Date(ChargebackDetails.disputeFiledDate) : null,
          transactionDate: ChargebackDetails && ChargebackDetails.transactionDate ? new Date(ChargebackDetails.transactionDate) : null,
          createdAt: ChargebackDetails && ChargebackDetails.createdAt ? new Date(ChargebackDetails.createdAt) : null,
          updatedAt: ChargebackDetails && ChargebackDetails.updatedAt ? new Date(ChargebackDetails.updatedAt) : null,
          chargebackDeadlineDate: ChargebackDetails && ChargebackDetails.chargebackDeadlineDate ? new Date(ChargebackDetails.chargebackDeadlineDate) : null,
        }))
        if (ChargebackDetails.clientId) {
          const data = getDefaultOptions(getClient && getClient.result, 'company')
          const selOption = _.filter(data, function (x) {
            if (_.includes(ChargebackDetails.clientId._id, x.value)) {
              return x
            }
          })
          setSelectedClientOption(selOption)
        }
        if (ChargebackDetails.merchantId) {
          const data = getDefaultOptions(getMerchant && getMerchant.result, 'merchantName')
          const selOption = _.filter(data, function (x) {
            if (_.includes(ChargebackDetails.merchantId, x.value)) {
              return x
            }
          })
          setSelectedMerchantOption(selOption)
        }
        if (ChargebackDetails.rootCause) {
          const data = getDefaultOptions(getRootCause, 'rootCauseType')
          const selOption = _.filter(data, function (x) {
            if (_.includes(ChargebackDetails.rootCause, x.value)) {
              return x
            }
          })
          setSelectedRootCauseOption(selOption)
        }
        if (ChargebackDetails.functionalArea) {
          const data = getDefaultOptions(getFunctional, 'functionalType')
          const selOption = _.filter(data, function (x) {
            if (ChargebackDetails.functionalArea === x.value) {
              return x
            }
          })
          setSelectedFunctionalOption(selOption)
        }
        if (ChargebackDetails.subFunctionalArea) {
          const data = getDefaultOptions(getSubFunctional, 'subFunctionalType')
          const selOption = _.filter(data, function (x) {
            if (ChargebackDetails.subFunctionalArea._id === x.value) {
              return x
            }
          })
          setSelectedsubFuncOption(selOption)
        }
        if (ChargebackDetails.nonUs) {
          const data = getDefaultOptions(getCountrys, 'name')
          const selOption = _.filter(data, function (x) {
            if (ChargebackDetails.nonUs._id === x.value) {
              return x
            }
          })
          setSelectedCountryOption(selOption)
        }
        if (ChargebackDetails.cardCountry) {
          const data = getDefaultOptions(getCountrys, 'name')
          const selOption = _.filter(data, function (x) {
            if (ChargebackDetails.cardCountry._id === x.value) {
              return x
            }
          })
          setSelectedCardCountryOption(selOption)
        }
        if (ChargebackDetails.serviceType) {
          const data = getDefaultOptions(getServicetype, 'serviceType')
          const selOption = _.filter(data, function (x) {
            if (ChargebackDetails.serviceType._id === x.value) {
              return x
            }
          })
          setSelectedServiceOption(selOption)
        }
        if (ChargebackDetails.paymentProcessor) {
          const data = getDefaultProcessorOptions(clientProcessorData, 'processorName')
          const selOption = _.filter(data, function (x) {
            if (ChargebackDetails.paymentProcessor, x.value) {
              return x
            }
          })
          setSelectedProcessorOption(selOption)
        }
        if (ChargebackDetails.mid) {
          const data = getDefaultOptions(getmidCRM && getmidCRM.data, 'midNumber')
          const selOption = _.filter(data, function (x) {
            if (ChargebackDetails.mid === x.value) {
              return x
            }
          })
          setSelectedMIDOption(selOption)
        }
        if (ChargebackDetails.authCode) {
          const getAuthCodeOptions = [
            { label: ChargebackDetails.authCode, value: ChargebackDetails.authCode }
          ]
          setSelectedAuthCodeOption(getAuthCodeOptions)
        }
        if (ChargebackDetails.chargebackReason) {
          const getReasonOptions = [
            { label: ChargebackDetails.chargebackReason, value: ChargebackDetails.chargebackReason }
          ]
          setSelectedCBReasonOption(getReasonOptions)
        }
        if (ChargebackDetails.chargebackCurrency) {
          const selOption = _.filter(currencyOptions, function (x) {
            if (_.includes(ChargebackDetails.chargebackCurrency, x.value)) {
              return x
            }
          })
          setSelectedCurrencyOption(selOption)
        }
        if (ChargebackDetails.assignedTo) {
          const data = getDefaultAnalystOptions(getAnalyst && getAnalyst.result)
          const selOption = _.filter(data, function (x) {
            if (_.includes(ChargebackDetails.assignedTo, x.value)) {
              return x
            }
          })
          setSelectedAnalystOption(selOption)
        }
        const checkKeysVals = checkKeyshasValues(ChargebackDetails)
        if (checkKeysVals) {
          const cbrBtn = document.getElementById('chargeBackReviewBtn')
          cbrBtn && cbrBtn.click()
        }
      }
      clearCBDetailsDispatch()
    } else if (statusCBD === STATUS_RESPONSE.ERROR_MSG) {
      clearCBDetailsDispatch()
    }
  }, [statusCBD])

  useEffect(() => {
    return () => {
      setSelectedClientOption()
      setSelectedMerchantOption()
      setSelectedCardCountryOption()
      setSelectedCountryOption()
      setSelectedServiceOption()
      setSelectedProcessorOption()
      setSelectedAuthCodeOption()
      clearCBDetailsDispatch()
    }
  }, [])

  return (
    <div className='container-fixed'>
      <div className='row accordion'>
        <div className='col-md-12'>
          <div
            className='accordion md-accordion accordion-2'
            id='accordionEx7'
            role='tablist'
            aria-multiselectable='true'
          >
            <div className='card bg-skyBlue'>
              <div className='card-header rgba-stylish-strong z-depth-1 mb-1' role='tab' id='heading2'>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <a
                        className='collapsed'
                        data-toggle='collapse'
                        data-parent='#accordionEx7'
                        href='#collapse2'
                        aria-expanded='false'
                        aria-controls='collapse2'
                        onClick={() => { setCBForm(val => !val) }}
                      >
                        <h5 className='text-dark fw-bolder fs-4 mt-8'>
                          {cbForm ? 'Hide' : 'Show'}  Chargeback Details{' '}
                          <i className={`fas fa-angle-${cbForm ? 'up' : 'down'} rotate-icon`} />
                        </h5>
                      </a>
                    </div>
                    <div className='col-lg-2 mt-7'>
                      {
                        editForm
                          ? (
                            <span className='badge badge-dark'>Edit Mode On</span>
                          )
                          : (
                            <span className='badge badge-light-dark'>Edit Mode Off</span>
                          )
                      }
                    </div>
                    <div className='col-lg-4 d-flex'>
                      {ChargebackDetails.createdAtFrom &&
                        <div className='col-lg-6'>
                          <div className='me-2 mt-8'>
                            <div className='text-muted fs-7 fw-bold'>Created At</div>
                            <p className='text-dark fw-bolder ms-5 fs-5'>
                              {ChargebackDetails.createdAtFrom
                                ? moment(ChargebackDetails.createdAtFrom).format(DATE.DATE_FORMAT)
                                : '--'}
                            </p>
                          </div>
                        </div>
                      }
                      {ChargebackDetails.updatedAtFrom &&
                        <div className='col-lg-6'>
                          <div className='me-2 mt-8'>
                            <div className='text-muted fs-7 fw-bold'>Updated At</div>
                            <p className='text-dark fw-bolder ms-5 fs-5'>
                              {ChargebackDetails.updatedAtFrom
                                ? moment(ChargebackDetails.updatedAtFrom).format(DATE.DATE_FORMAT)
                                : '--'}
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='col-lg-12 mt-4'>
                    <Link
                      to={isAuthorized ? '/chargeback-management' : `/manage-chargeback?apiKey=${RouteUrl}`}
                      className='btn btn-darkRed fa-pull-right m-1'
                    >
                      Back
                    </Link>
                    {!['WON', 'LOST', 'NOT FAUGHT', 'LOST REFUNDED'].includes(ChargebackDetails.chargeBackStatus) &&
                      !loadingCBD &&
                      <button
                        className='btn fa-pull-right m-1'
                        style={{ backgroundColor: '#6c54a5', color: '#fff' }}
                        onClick={() => { handleEdit() }}
                      >
                        <span className='svg-icon svg-icon-white svg-icon-3'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                            <path opacity='0.3' fillRule='evenodd' clipRule='evenodd' d='M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z' fill='black' />
                            <path d='M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z' fill='black' />
                            <path d='M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z' fill='black' />
                          </svg>
                        </span>
                        Edit
                      </button>
                    }
                  </div>
                </div>
              </div>
              <div
                id='collapse2'
                className='collapse'
                role='tabpanel'
                aria-labelledby='heading2'
                data-parent='#accordionEx7'
              >
                <div className='col-lg-12'>
                  <div className='card-header bg-skyBlue py-10 mb-8'>
                    <div className='card-body'>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bolder mb-2 form-label'>Assigned To:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='assignedTo'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeAnalyst}
                              options={analystOption}
                              value={selectedAnalystOption}
                              isDisabled={!editForm}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Affiliate Id:</label>
                          <div className='col-lg-11'>
                            <input
                              name='affiliateId'
                              type='text'
                              className='form-control'
                              placeholder='Affiliate Id'
                              onChange={(e) => handleChange(e)}
                              value={formData.affiliateId || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              maxLength={15}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>ARN:</label>
                          <div className='col-lg-11'>
                            <input
                              name='arn'
                              type='text'
                              className='form-control'
                              placeholder='ARN'
                              onChange={(e) => handleChange(e)}
                              value={formData.arn || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              maxLength={15}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>AVS Match:</label>
                          <div className='col-lg-11'>
                            <select
                              name='avs'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              disabled={!editForm}
                              value={formData.avs || ''}
                            >
                              <option value=''>Select...</option>
                              <option value='Yes'>Yes</option>
                              <option value='No'>No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Client:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='clientId'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeClient}
                              options={clientOption}
                              value={selectedClientOption}
                              isDisabled={!editForm}
                              isLoading={loadingClient}
                            />
                          </div>
                          {errors && errors.clientId && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.clientId}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Company Name:</label>
                          <div className='col-lg-11'>
                            <input
                              name='companyName'
                              type='text'
                              className='form-control'
                              placeholder='Company Name'
                              onChange={(e) => handleChange(e)}
                              value={formData.companyName || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.TEXT.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.companyName && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.companyName}</div>)}
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Chargeback Status:</label>
                          <div className='col-lg-11'>
                            <select
                              name='chargeBackStatus'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.chargeBackStatus || ''}
                              disabled={!editForm}
                            >
                              <option value=''>Select...</option>
                              <option value='NEW'>NEW</option>
                              <option value='PENDING'>PENDING</option>
                              <option value='ONGOING'>ONGOING</option>
                              <option value='WON'>WON</option>
                              <option value='LOST'>LOST</option>
                              <option value='NOT FAUGHT'>NOT FOUGHT</option>
                              <option value='DISPUTED'>DISPUTED</option>
                              <option value='DUPLICATE'>DUPLICATE</option>
                              <option value='OPENED'>OPENED</option>
                              <option value='WAITING FOR EVIDENCE'>WAITING FOR EVIDENCE</option>
                              <option value='LOST REFUNDED'>LOST REFUNDED</option>
                              <option value='REJECTION DUE TO REFUND'>REJECTION DUE TO REFUND</option>
                            </select>
                          </div>
                          {errors && errors.chargeBackStatus && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.chargeBackStatus}</div>)}
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Blacklisted:</label>
                          <div className='col-lg-11'>
                            <select
                              name='blacklisted'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              disabled={!editForm}
                              value={formData.blacklisted || ''}
                            >
                              <option value=''>Select...</option>
                              <option value='Yes'>Yes</option>
                              <option value='No'>No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Merchant:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='merchantId'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeMerchant}
                              options={merchantOption}
                              noOptionsMessage={() => 'No Records Found!'}
                              value={selectedMerchantOption}
                              isDisabled={!editForm}
                              isLoading={loadingMerchant}
                            />
                          </div>
                          {errors && errors.merchantId && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.merchantId}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Merchant Name:</label>
                          <div className='col-lg-11'>
                            <input
                              name='merchantName'
                              type='text'
                              className='form-control'
                              placeholder='Merchant Name'
                              onChange={(e) => handleChange(e)}
                              value={formData.merchantName || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.TEXT.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.merchantName && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.merchantName}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>MID Number:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='mid'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeMID}
                              options={midOption}
                              noOptionsMessage={() => 'No Records Found!'}
                              value={selectedMIDOption}
                              isDisabled={!editForm}
                              isLoading={loadingMID}
                            />
                          </div>
                          {errors && errors.mid && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.mid}</div>)}
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>DBA Name:</label>
                          <div className='col-lg-11'>
                            <input
                              name='DBAName'
                              type='text'
                              className='form-control'
                              placeholder='DBA Name'
                              onChange={(e) => handleChange(e)}
                              value={formData.DBAName || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Chargeback Type:</label>
                          <div className='col-lg-11'>
                            <select
                              name='chargeBackType'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.chargeBackType || ''}
                              disabled={!editForm}
                            >
                              <option value=''>Select...</option>
                              <option value='Retrieval Request'>Retrieval Request</option>
                              <option value='1st Chargeback'>1st Chargeback</option>
                              <option value='Pre-Arbitration'>Pre-Arbitration</option>
                              <option value='Arbitration'>Arbitration</option>
                            </select>
                          </div>
                          {errors && errors.chargeBackType && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.chargeBackType}</div>)}
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Chargeback Date:</label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='chargebackDate'
                              placeholder='Chargeback Date'
                              className='form-control'
                              selected={formData.chargebackDate || null}
                               maxDate={moment().toDate()}
                              onChange={(date) => {
                                setErrors({ ...errors, chargebackDate: '' })
                                setFormData((values) => ({
                                  ...values,
                                  chargebackDate: date
                                }))
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                              readOnly={!editForm}
                            />
                          </div>
                          {errors && errors.chargebackDate && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.chargebackDate}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Chargeback Amount:</label>
                          <div className='col-lg-11'>
                            <input
                              name='chargebackAmount'
                              type='text'
                              className='form-control'
                              placeholder='Chargeback Amount'
                              onChange={(e) => handleChange(e)}
                              value={formData.chargebackAmount || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!/^[0-9.]*$/.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.chargebackAmount && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.chargebackAmount}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Chargeback Currency:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='chargebackCurrency'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeCurrency}
                              options={currencyOptions}
                              value={selectedCurrencyOption}
                              isDisabled={!editForm}
                            />
                          </div>
                          {errors && errors.chargebackCurrency && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.chargebackCurrency}</div>)}
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Service Type:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='serviceType'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeService}
                              options={serviceOption}
                              value={selectedServiceOption}
                              isDisabled={!editForm}
                            />
                          </div>
                          {errors && errors.serviceType && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.serviceType}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Payment Id:</label>
                          <div className='col-lg-11'>
                            <input
                              name='paymentId'
                              type='text'
                              className='form-control'
                              placeholder='Payment Id'
                              onChange={(e) => handleChange(e)}
                              value={formData.paymentId || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC_CHARS.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Payment Processor:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='paymentProcessor'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeProcessor}
                              options={processorOption}
                              noOptionsMessage={() => 'No Records Found!'}
                              value={selectedProcessorOption}
                              isDisabled={!editForm}
                              isLoading={loadingProcessor}
                            />
                          </div>
                          {errors && errors.paymentProcessor && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.paymentProcessor}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Payment Method:</label>
                          <div className='col-lg-11'>
                            <input
                              name='paymentMethod'
                              type='text'
                              className='form-control'
                              placeholder='Payment Method'
                              onChange={(e) => handleChange(e)}
                              value={formData.paymentMethod || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.paymentMethod && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.paymentMethod}</div>)}
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xsfw-bold mb-2 form-label'>Card Bin:</label>
                          <div className='col-lg-11'>
                            <input
                              name='cardFirstSix'
                              type='text'
                              className='form-control'
                              placeholder='Card Bin'
                              onChange={(e) => handleChange(e)}
                              value={formData.cardFirstSix || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              maxLength={8}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.cardFirstSix && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.cardFirstSix}</div>)}
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Credit card Last four:</label>
                          <div className='col-lg-11'>
                            <input
                              name='cardLastFour'
                              type='text'
                              className='form-control'
                              placeholder='Credit card Last four'
                              onChange={(e) => handleChange(e)}
                              value={formData.cardLastFour || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              maxLength={4}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.cardLastFour && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.cardLastFour}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>CVV:</label>
                          <div className='col-lg-11'>
                            <input
                              name='cvv'
                              type='text'
                              className='form-control'
                              placeholder='CVV'
                              onChange={(e) => handleChange(e)}
                              value={formData.cvv || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              maxLength={4}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Card Country:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='cardCountry'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeCardCountry}
                              options={cardCountryOption}
                              value={selectedCardCountryOption}
                              isDisabled={!editForm}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Card Network:</label>
                          <div className='col-lg-11'>
                            <select
                              name='cardNetwork'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleAuthCodeChange(e)}
                              value={formData.cardNetwork || ''}
                              disabled={!editForm}
                            >
                              <option value=''>Select...</option>
                              <option value='paypal'>Paypal</option>
                              <option value='amex'>Amex</option>
                              <option value='visa'>Visa</option>
                              <option value='master'>Master Card</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Authorization Code:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='authCode'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeAuthcode}
                              options={authCodeOption}
                              value={selectedAuthCodeOption}
                              isLoading={loadingGRC}
                              isDisabled={!editForm}
                            />
                          </div>
                          {errors && errors.authCode && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.authCode}</div>)}
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xsfw-bold mb-2 form-label'>Chargeback Reason:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='chargebackReason'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeCBReason}
                              options={cbReasonOption}
                              value={selectedCBReasonOption}
                              isLoading={loadingGRC}
                              isDisabled={!editForm}
                            />
                          </div>
                          {errors && errors.chargebackReason && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.chargebackReason}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Order Id:</label>
                          <div className='col-lg-11'>
                            <input
                              name='orderId'
                              type='text'
                              className='form-control'
                              placeholder='Order Id'
                              onChange={(e) => handleChange(e)}
                              value={formData.orderId || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.orderId && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.orderId}</div>)}
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Case Id:</label>
                          <div className='col-lg-11'>
                            <input
                              name='caseId'
                              type='text'
                              className='form-control'
                              placeholder='Case Id'
                              onChange={(e) => handleChange(e)}
                              value={formData.caseId || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.caseId && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.caseId}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Gateway:</label>
                          <div className='col-lg-11'>
                            <input
                              name='gateway'
                              type='text'
                              className='form-control'
                              placeholder='Gateway'
                              onChange={(e) => handleChange(e)}
                              value={formData.gateway || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Issuer:</label>
                          <div className='col-lg-11'>
                            <input
                              name='issuer'
                              type='text'
                              className='form-control'
                              placeholder='Issuer'
                              onChange={(e) => handleChange(e)}
                              value={formData.issuer || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xsfw-bold mb-2 form-label'>Product Name:</label>
                          <div className='col-lg-11'>
                            <input
                              name='productName'
                              type='text'
                              className='form-control'
                              placeholder='Product Name'
                              onChange={(e) => handleChange(e)}
                              value={formData.productName || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.productName && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.productName}</div>)}
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Refund Issued:</label>
                          <div className='col-lg-11'>
                            <select
                              name='refundIssued'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.refundIssued || ''}
                              disabled={!editForm}
                            >
                              <option value=''>Select...</option>
                              <option value='Yes'>Yes</option>
                              <option value='No'>No</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Transaction Amount:</label>
                          <div className='col-lg-11'>
                            <input
                              name='transactionAmount'
                              type='text'
                              className='form-control'
                              placeholder='Transaction Amount'
                              onChange={(e) => handleChange(e)}
                              value={formData.transactionAmount || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!/^[0-9.]*$/.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.transactionAmount && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.transactionAmount}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Transaction Date:</label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='transactionDate'
                              placeholder='Transaction Date'
                              className='form-control'
                              selected={formData.transactionDate || null}
                              maxDate={moment().toDate()}
                              onChange={(date) => {
                                setErrors({ ...errors, transactionDate: '' })
                                setFormData((values) => ({
                                  ...values,
                                  transactionDate: date
                                }))
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                              readOnly={!editForm}
                            />
                          </div>
                          {errors && errors.transactionDate && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.transactionDate}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 required form-label'>Transaction Number:</label>
                          <div className='col-lg-11'>
                            <input
                              name='transactionId'
                              type='text'
                              className='form-control'
                              placeholder='Transaction Number'
                              onChange={(e) => handleChange(e)}
                              value={formData.transactionId || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              maxLength={15}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.transactionId && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.transactionId}</div>)}
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Customer Name:</label>
                          <div className='col-lg-11'>
                            <input
                              name='customerName'
                              type='text'
                              className='form-control'
                              placeholder='Customer Name'
                              onChange={(e) => handleChange(e)}
                              value={formData.customerName || ''}
                              maxLength={42}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.TEXT.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.customerName && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerName}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Customer Email:</label>
                          <div className='col-lg-11'>
                            <input
                              name='customerEmail'
                              type='text'
                              className='form-control'
                              placeholder='Customer Email'
                              onChange={(e) => handleChange(e)}
                              value={formData.customerEmail || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.customerEmail && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerEmail}</div>)}
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Customer Phone:</label>
                          <div className='col-lg-11'>
                            <input
                              name='customerPhone'
                              type='text'
                              className='form-control'
                              placeholder='Customer Phone'
                              onChange={(e) => handleChange(e)}
                              value={formData.customerPhone || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              maxLength={10}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.customerPhone && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerPhone}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Customer IP:</label>
                          <div className='col-lg-11'>
                            <input
                              name='customerIP'
                              type='text'
                              className='form-control'
                              placeholder='Customer IP'
                              onChange={(e) => handleChange(e)}
                              value={formData.customerIP || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              // onKeyPress={(e) => {
                              //   if (!/^[0-9.]*$/.test(e.key)) {
                              //     e.preventDefault()
                              //   }
                              // }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              onKeyUp={handleIPValidation}
                            />
                             {error && <div  className='error-message rr mt-1'><style>{'.rr{color:red;}'}</style>{error}</div>}
                          </div>
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Dispute Filed Date:</label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='disputeFiledDate'
                              placeholder='Dispute Filed Date'
                              className='form-control'
                              selected={formData.disputeFiledDate || null}
                              maxDate={moment().toDate()}
                              onChange={(date) => {
                                setErrors({ ...errors, disputeFiledDate: '' })
                                setFormData((values) => ({
                                  ...values,
                                  disputeFiledDate: date
                                }))
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                              readOnly={!editForm}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Dispute Method:</label>
                          <div className='col-lg-11'>
                            <input
                              name='disputeMethod'
                              type='text'
                              className='form-control'
                              placeholder='Dispute Method'
                              onChange={(e) => handleChange(e)}
                              value={formData.disputeMethod || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                            />
                          </div>
                          {errors && errors.disputeMethod && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.disputeMethod}</div>)}
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Country:</label>
                          <div className='col-lg-11'>
                            <select
                              name='country'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.country || ''}
                              disabled={!editForm}
                            >
                              <option value=''>Select...</option>
                              <option value='US'>US</option>
                              <option value='nonUs'>NON_US</option>
                            </select>
                          </div>
                        </div>
                        {formData && formData.country === 'nonUs'
                          ? (
                            <div className='col-lg-3 mb-3'>
                              <label className='fs-xs fw-bold mb-2 required form-label'>Non US Countries:</label>
                              <div className='col-lg-11'>
                                <ReactSelect
                                  isClearable
                                  styles={customStyles}
                                  isMulti={false}
                                  name='nonUs'
                                  className='basic-single'
                                  classNamePrefix='select'
                                  handleChangeReactSelect={handleChangeCountry}
                                  options={countryOption}
                                  value={selectedCountryOption}
                                  isDisabled={!editForm}
                                />
                              </div>
                              {errors && errors.nonUs && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.nonUs}</div>)}
                            </div>
                          )
                          : null}
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Root Cause:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='rootCause'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeRootCause}
                              options={rootCauseOption}
                              value={selectedRootCauseOption}
                              isDisabled={!editForm}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Functional Area:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='functionalArea'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeFunctional}
                              options={functionalOption}
                              value={selectedFunctionalOption}
                              isDisabled={!editForm}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Sub Functional:</label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='subFunctionalArea'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeSubFunc}
                              options={subFuncOption}
                              value={selectedsubFuncOption}
                              isDisabled={!editForm}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Sale Type:</label>
                          <div className='col-lg-11'>
                            <select
                              name='saleType'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.saleType || ''}
                              disabled={!editForm}
                            >
                              <option value=''>Select</option>
                              <option value='trialSale'>Trial Sale</option>
                              <option value='firstRecurringDate'>First Recurring Sale</option>
                              <option value='straightSale'>Straight Sale</option>
                              <option value='subssequentRecurring'>Subsequent Recurring</option>
                              <option value='notFought'>Not Fought</option>
                              <option value='parentOrder'>Parent Order</option>
                              <option value='notFoughtTrialSale'>Not Fought - Trial Sale</option>
                              <option value='notFoughtFirstRecurringOrder'>Not Fought - First Recurring Order</option>
                              <option value='notFoughtSubsequentRecurringOrder'>Not Fought - Subsequent Recurring Order</option>
                              <option value='notFoughtParentOrder'>Not Fought - Parent Order</option>
                              <option value='notFoughtStraightSale'>Not Fought - Straight Sale</option>
                            </select>
                          </div>
                          {errors && errors.saleType && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.saleType}</div>)}
                        </div>
                      </div>
                      <div className='form-group row mb-6'>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Device Id:</label>
                          <div className='col-lg-11'>
                            <input
                              name='deviceId'
                              type='text'
                              className='form-control'
                              placeholder='Device Id'
                              onChange={(e) => handleChange(e)}
                              value={formData.deviceId || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Reason for Legitimate Chargeback:</label>
                          <div className='col-lg-11'>
                            <select
                              name='reasonForLegitimateChargeback'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              disabled={!editForm}
                              value={formData.reasonForLegitimateChargeback || ''}
                            >
                              <option value=''>Select</option>
                              <option value='clientRuleSet'>Client ruleset</option>
                              <option value='disputeExpired'>Dispute expired</option>
                              <option value='lackOfEvidence'>Lack of evidence</option>
                              <option value='legacyDisputes'>Legacy disputes</option>
                              <option value='lowNoRecoveryRates'>Low/No recovery rate</option>
                              <option value='merchantError'>Merchant error</option>
                              <option value='other'>Other</option>
                              <option value='representedPrior'>Represented prior</option>
                              <option value='reversalCase'>Reversal case</option>
                            </select>
                          </div>
                          {errors && errors.reasonForLegitimateChargeback && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.reasonForLegitimateChargeback}</div>)}
                        </div>
                        <div className='col-lg-3 mb-3'>
                          <label className='fs-xs fw-bold mb-2 form-label'>Card Type:</label>
                          <div className='col-lg-11'>
                            <select
                              name='cardType'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.cardType || ''}
                              disabled={editForm}
                            >
                              <option value=''>Select...</option>
                              <option value='creditCard'>Credit Card</option>
                              <option value='Debit Card'>Debit Card</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-3 mb-2'>
                          <label className='fs-xsfw-bold mb-2 form-label'>UPI Id:</label>
                          <div className='col-lg-11'>
                            <input
                              name='upiId'
                              type='text'
                              className='form-control'
                              placeholder='UIP Id'
                              onChange={(e) => handleChange(e)}
                              value={formData.upiId || ''}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                          {errors && errors.upiId && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.upiId}</div>)}
                        </div>
                      </div>
                      {
                        editForm
                          ? (
                            <div className='form-group row mb-4 mt-6'>
                              <div className='col-lg-6' />
                              <div className='col-lg-6'>
                                <div className='col-lg-12'>
                                  <button
                                    className='btn btn-blue mt-5 mx-2 fa-pull-right'
                                    onClick={(event) => {
                                      handleSubmit(event)
                                    }}
                                  >
                                    {loadingEC
                                      ? (
                                        <span
                                          className='spinner-border spinner-border-sm mx-3'
                                          role='status'
                                          aria-hidden='true'
                                        />
                                      )
                                      : (
                                        'Update'
                                      )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                          : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='accordion md-accordion accordion-2'
        id='accordionEx8'
        role='tablist'
        aria-multiselectable='true'
      >
        <div className='px-8 border'>
          <a
            className='collapsed'
            data-toggle='collapse'
            data-parent='#accordionEx8'
            href='#collapse3'
            aria-expanded='true'
            aria-controls='collapse3'
            onClick={() => { setReviewForm(val => !val) }}
          >
            <h5 className='text-dark fw-bolder fs-4 mt-8'>
              Chargeback Review <i className={`fas fa-angle-${reviewForm ? 'up' : 'down'} rotate-icon`} />
            </h5>
          </a>
        </div>
        <div
          id='collapse3'
          className='collapse show'
          role='tabpanel'
          aria-labelledby='heading2'
          data-parent='#accordionEx8'
        >
          <KTStepper />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { chargebackStore, editChargebacksStore, rootCauseStore, functionalStore, subFunctionalStore, serviceTypeStore, clientProcessorStore, midCRMStore } = state
  return {
    ChargebackDetails: chargebackStore && chargebackStore.ChargebackDetails ? chargebackStore.ChargebackDetails : {},
    statusCBD: chargebackStore && chargebackStore.statusCBD ? chargebackStore.statusCBD : '',
    messagesCBD: chargebackStore && chargebackStore.messagesCBD ? chargebackStore.messagesCBD : '',
    loadingCBD: chargebackStore && chargebackStore.loadingCBD ? chargebackStore.loadingCBD : false,
    editChargebacks: editChargebacksStore && editChargebacksStore.dataEC ? editChargebacksStore.dataEC : [],
    statusEC: editChargebacksStore && editChargebacksStore.statusEC ? editChargebacksStore.statusEC : '',
    messageEC: editChargebacksStore && editChargebacksStore.messageEC ? editChargebacksStore.messageEC : '',
    loadingEC: editChargebacksStore && editChargebacksStore.loadingEC ? editChargebacksStore.loadingEC : false,
    getRootCause: rootCauseStore && rootCauseStore.getRootCause ? rootCauseStore.getRootCause : {},
    getFunctional: functionalStore && functionalStore.getFunctional ? functionalStore.getFunctional : {},
    getSubFunctional: subFunctionalStore && subFunctionalStore.getSubFunctional ? subFunctionalStore.getSubFunctional : {},
    getServicetype: serviceTypeStore && serviceTypeStore.getServicetype ? serviceTypeStore.getServicetype : {},
    clientProcessorData: clientProcessorStore && clientProcessorStore.clientProcessorData ? clientProcessorStore.clientProcessorData : {},
    loadingProcessor: state && state.clientProcessorStore && state.clientProcessorStore.clientProcessorLoading,
    reasonCode: state && state.ReasoncodeStore && state.ReasoncodeStore.reasonCode,
    loadingGRC: state && state.ReasoncodeStore && state.ReasoncodeStore.loadingGRC,
    getmidCRM: midCRMStore && midCRMStore.getmidCRM ? midCRMStore.getmidCRM : {},
    loadingMID: state && state.midCRMStore && state.midCRMStore.loading,
    getCountrys: state && state.countryStore && state.countryStore.getCountrys,
    loading: state && state.countryStore && state.countryStore.loading,
    getClient: state && state.clientStore && state.clientStore.getClient,
    loadingClient: state && state.clientStore && state.clientStore.loading,
    getMerchant: state && state.merchantStore && state.merchantStore.getMerchant,
    loadingMerchant: state && state.merchantStore && state.merchantStore.loading,
    getAnalyst: state && state.getAnalystStore && state.getAnalystStore.getAnalyst,
    loadingGA: state && state.getAnalystStore && state.getAnalystStore.loadingGA
  }
}

const mapDispatchToProps = dispatch => ({
  getCBDetailsDispatch: (id, headers) =>
    dispatch(chargeBackDetailsActions.getChargebackDetails(id, headers)),
  clearCBDetailsDispatch: () =>
    dispatch(chargeBackDetailsActions.clearChargebackDetails()),
  editChargebacksDispatch: (id, params, headers) =>
    dispatch(editChargebacksActions.editChargebacks(id, params, headers)),
  cleareditChargebacksDispatch: () =>
    dispatch(editChargebacksActions.cleareditChargebacks()),
  getCountryDispatch: (params, headers) => dispatch(countryActions.getCountrys(params, headers)),
  countryActions: () => dispatch(countryActions.getCountrys()),
  getClientDispatch: (params, headers) => dispatch(clientActions.getClient(params, headers)),
  clientActions: () => dispatch(clientActions.getClient()),
  getMerchantDispatch: (params, headers) => dispatch(merchantActions.getMerchant(params, headers)),
  merchantActions: () => dispatch(merchantActions.getMerchant()),
  getFunctionalDispatch: (params, headers) =>
    dispatch(getFunctionalActions.get(params, headers)),
  getFunctionalActions: () =>
    dispatch(getFunctionalActions.get()),
  getRootCauseDispatch: (params, headers) =>
    dispatch(getRootCauseActions.get(params, headers)),
  getRootCauseActions: () =>
    dispatch(getRootCauseActions.get()),
  getSubFuncDispatch: (params, headers) =>
    dispatch(getSubFunactionalActions.get(params, headers)),
  getSubFunactionalActions: () =>
    dispatch(getSubFunactionalActions.get()),
  getServicetypeDispatch: (params, headers) =>
    dispatch(serviceTypeActions.getServicetype(params, headers)),
  serviceTypeActions: () =>
    dispatch(serviceTypeActions.getServicetype()),
  getProcessorDispatch: (params, headers) =>
    dispatch(clientProcessorActions.getClientProcessor(params, headers)),
  clientProcessorActions: () =>
    dispatch(clientProcessorActions.getClientProcessor()),
  getReasonCodeDispatch: (params, headers) =>
    dispatch(ReasoncodesActions.reasonCode(params, headers)),
  ReasoncodesActions: () =>
    dispatch(ReasoncodesActions.reasonCode()),
  getMIDCodeDispatch: (params, headers) =>
    dispatch(midCRMActions.getmidCRM(params, headers)),
  midCRMActions: () =>
    dispatch(midCRMActions.getmidCRM()),
  getAnalystDispatch: (params, headers) =>
    dispatch(getAnalystActions.get(params, headers)),
  getAnalystActions: () =>
    dispatch(getAnalystActions.get()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CBData)
