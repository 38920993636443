import {
  addIndustrySettingsActionsTypes,
  getIndustrySettingsDetailsActionsTypes,
  updateIndustrySettingsActionsTypes,
  deleteIndustrySettingsActionsTypes
} from '../actions'

export const addIndustrySettingsStoreKey = 'addIndustrySettingsStore'

export const addIndustrySettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addIndustrySettingsActionsTypes.REQUEST:
      return { ...state, loadingAddIndustry: true }
    case addIndustrySettingsActionsTypes.RESPONSE:
      return {
        ...state,
        addIndustrySettings: res.data,
        statusAddIndustry: res.status,
        messageAddIndustry: res.message,
        loadingAddIndustry: false
      }
    case addIndustrySettingsActionsTypes.CLEAR:
      return {
        ...state,
        addIndustrySettings: null,
        statusAddIndustry: '',
        messageAddIndustry: ''
      }
    default:
      return state
  }
}

export const getIndustrySettingsDetailsStoreKey = 'getIndustrySettingsDetailsStore'

export const getIndustrySettingsDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getIndustrySettingsDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsIndustry: true }
    case getIndustrySettingsDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsIndustrySettings: res.data,
        statusGetDetailsIndustry: res.status,
        messageGetDetailsIndustry: res.message,
        loadingGetDetailsIndustry: false
      }
    case getIndustrySettingsDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsIndustrySettings: null,
        statusGetDetailsIndustry: '',
        messageGetDetailsIndustry: ''
      }
    default:
      return state
  }
}

export const updateIndustrySettingsStoreKey = 'updateIndustrySettingsStore'

export const updateIndustrySettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updateIndustrySettingsActionsTypes.REQUEST:
      return { ...state, loadingUpdateIndustry: true }
    case updateIndustrySettingsActionsTypes.RESPONSE:
      return {
        ...state,
        updateIndustrySettings: res.data,
        statusUpdateIndustry: res.status,
        messageUpdateIndustry: res.message,
        loadingUpdateIndustry: false
      }
    case updateIndustrySettingsActionsTypes.CLEAR:
      return {
        ...state,
        updateIndustrySettings: null,
        statusUpdateIndustry: '',
        messageUpdateIndustry: ''
      }
    default:
      return state
  }
}

export const deleteIndustrySettingsStoreKey = 'deleteIndustrySettingsStore'

export const deleteIndustrySettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteIndustrySettingsActionsTypes.REQUEST:
      return { ...state, loadingDeleteIndustry: true }
    case deleteIndustrySettingsActionsTypes.RESPONSE:
      return {
        ...state,
        deleteIndustrySettings: res.data,
        statusDeleteIndustry: res.status,
        messageDeleteIndustry: res.message,
        loadingDeleteIndustry: false
      }
    case deleteIndustrySettingsActionsTypes.CLEAR:
      return {
        ...state,
        deleteIndustrySettings: null,
        statusDeleteIndustry: '',
        messageDeleteIndustry: ''
      }
    default:
      return state
  }
}
