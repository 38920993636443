import {
  getPrivilegesActionsTypes,
  addPrivilegesActionsTypes,
  getPrivilegesDetailsActionsTypes,
  updatePrivilegesActionsTypes,
  deletePrivilegesActionsTypes,
  getCompPermissionActionsTypes
} from '../actions'

export const getPrivilegesStoreKey = 'getPrivilegesStore'

export const getPrivilegesReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getPrivilegesActionsTypes.REQUEST:
      return { ...state, loadingGP: true }
    case getPrivilegesActionsTypes.RESPONSE:
      return {
        ...state,
        getPrivileges: res.data,
        count: res.count,
        statusGP: res.status,
        messageGP: res.message,
        loadingGP: false
      }
    case getPrivilegesActionsTypes.CLEAR:
      return {
        ...state,
        getPrivileges: null,
        count: 0,
        statusGP: '',
        messageGP: ''
      }
    default:
      return state
  }
}

export const addPrivilegesStoreKey = 'addPrivilegesStore'

export const addPrivilegesReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addPrivilegesActionsTypes.REQUEST:
      return { ...state, loadingAddPrivi: true }
    case addPrivilegesActionsTypes.RESPONSE:
      return {
        ...state,
        addPrivileges: res.data,
        statusAddPrivi: res.status,
        messageAddPrivi: res.message,
        loadingAddPrivi: false
      }
    case addPrivilegesActionsTypes.CLEAR:
      return {
        ...state,
        addPrivileges: null,
        statusAddPrivi: '',
        messageAddPrivi: ''
      }
    default:
      return state
  }
}

export const getPrivilegesDetailsStoreKey = 'getPrivilegesDetailsStore'

export const getPrivilegesDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getPrivilegesDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsPrivi: true }
    case getPrivilegesDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsPrivileges: res.data,
        statusGetDetailsPrivi: res.status,
        messageGetDetailsPrivi: res.message,
        loadingGetDetailsPrivi: false
      }
    case getPrivilegesDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsPrivileges: null,
        statusGetDetailsPrivi: '',
        messageGetDetailsPrivi: ''
      }
    default:
      return state
  }
}

export const updatePrivilegesStoreKey = 'updatePrivilegesStore'

export const updatePrivilegesReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updatePrivilegesActionsTypes.REQUEST:
      return { ...state, loadingUpdatePrivi: true }
    case updatePrivilegesActionsTypes.RESPONSE:
      return {
        ...state,
        updatePrivileges: res.data,
        statusUpdatePrivi: res.status,
        messageUpdatePrivi: res.message,
        loadingUpdatePrivi: false
      }
    case updatePrivilegesActionsTypes.CLEAR:
      return {
        ...state,
        updatePrivileges: null,
        statusUpdatePrivi: '',
        messageUpdatePrivi: ''
      }
    default:
      return state
  }
}

export const deletePrivilegesStoreKey = 'deletePrivilegesStore'

export const deletePrivilegesReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deletePrivilegesActionsTypes.REQUEST:
      return { ...state, loadingDeletePrivi: true }
    case deletePrivilegesActionsTypes.RESPONSE:
      return {
        ...state,
        deletePrivileges: res.data,
        statusDeletePrivi: res.status,
        messageDeletePrivi: res.message,
        loadingDeletePrivi: false
      }
    case deletePrivilegesActionsTypes.CLEAR:
      return {
        ...state,
        deletePrivileges: null,
        statusDeletePrivi: '',
        messageDeletePrivi: ''
      }
    default:
      return state
  }
}

export const getCompPermissionStoreKey = 'getCompPermissionStore'

export const getCompPermissionReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getCompPermissionActionsTypes.REQUEST:
      return { ...state, loadingGCP: true }
    case getCompPermissionActionsTypes.RESPONSE:
      return {
        ...state,
        getCompPermission: res.data,
        count: res.count,
        statusGCP: res.status,
        messageGCP: res.message,
        loadingGCP: false
      }
    case getCompPermissionActionsTypes.CLEAR:
      return {
        ...state,
        getCompPermission: null,
        count: 0,
        statusGCP: '',
        messageGCP: ''
      }
    default:
      return state
  }
}
