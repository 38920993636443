export const transactionGetDetailsTypes = {
  GET_TRANSACTION_DETAILS: 'GET_TRANSACTION_DETAILS',
  TRANSACTION_DETAILS_RESPONSE: 'TRANSACTION_DETAILS_RESPONSE',
  CLEAR_TRANSACTION_DETAILS: 'CLEAR_TRANSACTION_DETAILS'
}
export const transactionDetailsActions = {
  getTransactionDetails: (id, headers) => ({
    type: transactionGetDetailsTypes.GET_TRANSACTION_DETAILS,
    id,
    headers
  }),
  saveTransactionDetailsResponse: data => ({
    type: transactionGetDetailsTypes.TRANSACTION_DETAILS_RESPONSE,
    data
  }),
  clearTransactionDetails: () => ({
    type: transactionGetDetailsTypes.CLEAR_TRANSACTION_DETAILS
  })
}

export const editTransactionTypes = {
  REQUEST: 'EDIT_TRANSACTION_REQUEST',
  RESPONSE: 'EDIT_TRANSACTION_RESPONSE',
  ERROR: 'EDIT_TRANSACTION_ERROR',
  CLEAR: 'EDIT_TRANSACTION_CLEAR'
}

export const editTransactionActions = {
  editTransactions: (id, params, headers) => {
    return {
      type: editTransactionTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditTransactionResponse: (data) => {
    return {
      type: editTransactionTypes.RESPONSE,
      data
    }
  },
  cleareditTransactions: () => ({
    type: editTransactionTypes.CLEAR
  })
}

export const customerGetDetailsTypes = {
  GET_CUSTOMER_DETAILS: 'GET_CUSTOMER_DETAILS',
  CUSTOMER_DETAILS_RESPONSE: 'CUSTOMER_DETAILS_RESPONSE',
  CLEAR_CUSTOMER_DETAILS: 'CLEAR_CUSTOMER_DETAILS'
}
export const customerDetailsActions = {
  getCustomerDetails: (id, headers) => ({
    type: customerGetDetailsTypes.GET_CUSTOMER_DETAILS,
    id,
    headers
  }),
  saveCustomerDetailsResponse: data => ({
    type: customerGetDetailsTypes.CUSTOMER_DETAILS_RESPONSE,
    data
  }),
  clearCustomerDetails: () => ({
    type: customerGetDetailsTypes.CLEAR_CUSTOMER_DETAILS
  })
}

export const editCustomerTypes = {
  REQUEST: 'EDIT_CUSTOMER_REQUEST',
  RESPONSE: 'EDIT_CUSTOMER_RESPONSE',
  ERROR: 'EDIT_CUSTOMER_ERROR',
  CLEAR: 'EDIT_CUSTOMER_CLEAR'
}

export const editCustomersActions = {
  editCustomers: (id, params, headers) => {
    return {
      type: editCustomerTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditCustomersResponse: (data) => {
    return {
      type: editCustomerTypes.RESPONSE,
      data
    }
  },
  cleareditCustomers: () => ({
    type: editCustomerTypes.CLEAR
  })
}

export const purchaseGetDetailsTypes = {
  GET_PURCHASE_DETAILS: 'GET_PURCHASE_DETAILS',
  PURCHASE_DETAILS_RESPONSE: 'PURCHASE_DETAILS_RESPONSE',
  CLEAR_PURCHASE_DETAILS: 'CLEAR_PURCHASE_DETAILS'
}
export const purchaseDetailsActions = {
  getPurchaseDetails: (id, headers) => ({
    type: purchaseGetDetailsTypes.GET_PURCHASE_DETAILS,
    id,
    headers
  }),
  savePurchaseDetailsResponse: data => ({
    type: purchaseGetDetailsTypes.PURCHASE_DETAILS_RESPONSE,
    data
  }),
  clearPurchaseDetails: () => ({
    type: purchaseGetDetailsTypes.CLEAR_PURCHASE_DETAILS
  })
}

export const editPurchaseTypes = {
  REQUEST: 'EDIT_PURCHASE_REQUEST',
  RESPONSE: 'EDIT_PURCHASE_RESPONSE',
  ERROR: 'EDIT_PURCHASE_ERROR',
  CLEAR: 'EDIT_PURCHASE_CLEAR'
}

export const editPurchasesActions = {
  editPurchases: (id, params, headers) => {
    return {
      type: editPurchaseTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditPurchasesResponse: (data) => {
    return {
      type: editPurchaseTypes.RESPONSE,
      data
    }
  },
  cleareditPurchases: () => ({
    type: editPurchaseTypes.CLEAR
  })
}

export const merchantCBGetDetailsTypes = {
  GET_MERCHANT_CB_DETAILS: 'GET_MERCHANT_CB_DETAILS',
  MERCHANT_CB_DETAILS_RESPONSE: 'MERCHANT_CB_DETAILS_RESPONSE',
  CLEAR_MERCHANT_CB_DETAILS: 'CLEAR_MERCHANT_CB_DETAILS'
}
export const merchantCBDetailsActions = {
  getMerchantDetails: (id, headers) => ({
    type: merchantCBGetDetailsTypes.GET_MERCHANT_CB_DETAILS,
    id,
    headers
  }),
  saveMerchantDetailsResponse: data => ({
    type: merchantCBGetDetailsTypes.MERCHANT_CB_DETAILS_RESPONSE,
    data
  }),
  clearMerchantDetails: () => ({
    type: merchantCBGetDetailsTypes.CLEAR_MERCHANT_CB_DETAILS
  })
}

export const editMerchantCBTypes = {
  REQUEST: 'EDIT_MERCHANT_CB_REQUEST',
  RESPONSE: 'EDIT_MERCHANT_CB_RESPONSE',
  ERROR: 'EDIT_MERCHANT_CB_ERROR',
  CLEAR: 'EDIT_MERCHANT_CB_CLEAR'
}

export const editMerchantCBsActions = {
  editMerchantCBs: (id, params, headers) => {
    return {
      type: editMerchantCBTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditMerchantCBsResponse: (data) => {
    return {
      type: editMerchantCBTypes.RESPONSE,
      data
    }
  },
  cleareditMerchantCBs: () => ({
    type: editMerchantCBTypes.CLEAR
  })
}

export const addDocumentTypes = {
  REQUEST: 'ADD_DOCUMENT_REQUEST',
  RESPONSE: 'ADD_DOCUMENT_RESPONSE',
  ERROR: 'ADD_DOCUMENT_ERROR',
  CLEAR: 'ADD_DOCUMENT_CLEAR'
}

export const addDocumentActions = {
  addDocument: (data, headers) => {
    return {
      type: addDocumentTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddDocumentResponse: (data) => {
    return {
      type: addDocumentTypes.RESPONSE,
      data
    }
  },
  clearaddDocument: () => ({
    type: addDocumentTypes.CLEAR
  })
}

export const addEvidenceTypes = {
  REQUEST: 'ADD_EVIDENCE_REQUEST',
  RESPONSE: 'ADD_EVIDENCE_RESPONSE',
  ERROR: 'ADD_EVIDENCE_ERROR',
  CLEAR: 'ADD_EVIDENCE_CLEAR'
}

export const addEvidenceActions = {
  add: (data, headers) => {
    return {
      type: addEvidenceTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addEvidenceTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addEvidenceTypes.CLEAR
  })
}

export const docTypeActionsTypes = {
  GET_DOC_TYPE: 'GET_DOC_TYPE',
  SAVE_DOC_TYPE_RESPONSE: 'SAVE_DOC_TYPE_RESPONSE',
  CLEAR_DOC_TYPE: 'CLEAR_DOC_TYPE'
}

export const docTypeActions = {
  getDocType: (id, headers) => ({
    type: docTypeActionsTypes.GET_DOC_TYPE,
    id,
    headers
  }),
  savedocTypeResponse: data => ({
    type: docTypeActionsTypes.SAVE_DOC_TYPE_RESPONSE,
    data
  }),
  clearDocType: () => ({
    type: docTypeActionsTypes.CLEAR_DOC_TYPE
  })
}

export const documentGetDetailsTypes = {
  GET_DOCUMENT_DETAILS: 'GET_DOCUMENT_DETAILS',
  DOCUMENT_DETAILS_RESPONSE: 'DOCUMENT_DETAILS_RESPONSE',
  CLEAR_DOCUMENT_DETAILS: 'CLEAR_DOCUMENT_DETAILS'
}
export const documentDetailsActions = {
  getDocumentDetails: (id, headers) => ({
    type: documentGetDetailsTypes.GET_DOCUMENT_DETAILS,
    id,
    headers
  }),
  saveDocumentDetailsResponse: data => ({
    type: documentGetDetailsTypes.DOCUMENT_DETAILS_RESPONSE,
    data
  }),
  clearDocumentDetails: () => ({
    type: documentGetDetailsTypes.CLEAR_DOCUMENT_DETAILS
  })
}

export const documentDeleteTypes = {
  DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  SAVE_DELETE_DOCUMENT_RESPONSE: 'SAVE_DELETE_DOCUMENT_RESPONSE',
  CLEAR_DELETE_DOCUMENT: 'CLEAR_DELETE_DOCUMENT'
}

export const deleteDocumentActions = {
  deleteDocument: (params, headers) => ({
    type: documentDeleteTypes.DELETE_DOCUMENT,
    params,
    headers
  }),
  savemResponseDeleteDocument: data => ({
    type: documentDeleteTypes.SAVE_DELETE_DOCUMENT_RESPONSE,
    data
  }),
  clearDeleteDocument: () => ({
    type: documentDeleteTypes.CLEAR_DELETE_DOCUMENT
  })
}

export const addRebuttalTypes = {
  REQUEST: 'ADD_REBUTTAL_REQUEST',
  RESPONSE: 'ADD_REBUTTAL_RESPONSE',
  ERROR: 'ADD_REBUTTAL_ERROR',
  CLEAR: 'ADD_REBUTTAL_CLEAR'
}

export const addRebuttalActions = {
  addRebuttal: (id, params, headers) => {
    return {
      type: addRebuttalTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveaddRebuttalResponse: (data) => {
    return {
      type: addRebuttalTypes.RESPONSE,
      data
    }
  },
  clearaddRebuttal: () => ({
    type: addRebuttalTypes.CLEAR
  })
}

export const rebuttalGetDetailsTypes = {
  GET_REBUTTAL_DETAILS: 'GET_REBUTTAL_DETAILS',
  REBUTTAL_DETAILS_RESPONSE: 'REBUTTAL_DETAILS_RESPONSE',
  CLEAR_REBUTTAL_DETAILS: 'CLEAR_REBUTTAL_DETAILS'
}
export const rebuttalDetailsActions = {
  getRebuttalDetails: (id, headers) => ({
    type: rebuttalGetDetailsTypes.GET_REBUTTAL_DETAILS,
    id,
    headers
  }),
  saveRebuttalDetailsResponse: data => ({
    type: rebuttalGetDetailsTypes.REBUTTAL_DETAILS_RESPONSE,
    data
  }),
  clearRebuttalDetails: () => ({
    type: rebuttalGetDetailsTypes.CLEAR_REBUTTAL_DETAILS
  })
}

export const emailActionsTypes = {
  GET_EMAIL: 'GET_EMAIL',
  SAVE_EMAIL_RESPONSE: 'SAVE_EMAIL_RESPONSE',
  CLEAR_EMAIL: 'CLEAR_EMAIL'
}

export const emailActions = {
  getEmail: (id, headers) => ({
    type: emailActionsTypes.GET_EMAIL,
    id,
    headers
  }),
  saveemailResponse: data => ({
    type: emailActionsTypes.SAVE_EMAIL_RESPONSE,
    data
  }),
  clearEmail: () => ({
    type: emailActionsTypes.CLEAR_EMAIL
  })
}

export const addEmailTypes = {
  REQUEST: 'ADD_EMAIL_REQUEST',
  RESPONSE: 'ADD_EMAIL_RESPONSE',
  ERROR: 'ADD_EMAIL_ERROR',
  CLEAR: 'ADD_EMAIL_CLEAR'
}

export const addEmailActions = {
  addEmail: (data, headers) => {
    return {
      type: addEmailTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddEmailResponse: (data) => {
    return {
      type: addEmailTypes.RESPONSE,
      data
    }
  },
  clearaddEmail: () => ({
    type: addEmailTypes.CLEAR
  })
}

export const addDownloadTypes = {
  REQUEST: 'ADD_DOWNLOAD_REQUEST',
  RESPONSE: 'ADD_DOWNLOAD_RESPONSE',
  ERROR: 'ADD_DOWNLOAD_ERROR',
  CLEAR: 'ADD_DOWNLOAD_CLEAR'
}

export const addDownloadActions = {
  addDownload: (data, headers) => {
    return {
      type: addDownloadTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddDownloadResponse: (data) => {
    return {
      type: addDownloadTypes.RESPONSE,
      data
    }
  },
  clearaddDownload: () => ({
    type: addDownloadTypes.CLEAR
  })
}

export const addZipTypes = {
  REQUEST: 'ADD_ZIP_REQUEST',
  RESPONSE: 'ADD_ZIP_RESPONSE',
  ERROR: 'ADD_ZIP_ERROR',
  CLEAR: 'ADD_ZIP_CLEAR'
}

export const addZipActions = {
  addZip: (data, headers) => {
    return {
      type: addZipTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddZipResponse: (data) => {
    return {
      type: addZipTypes.RESPONSE,
      data
    }
  },
  clearaddZip: () => ({
    type: addZipTypes.CLEAR
  })
}

export const addSendLinkTypes = {
  REQUEST: 'ADD_SEND_LINK_REQUEST',
  RESPONSE: 'ADD_SEND_LINK_RESPONSE',
  ERROR: 'ADD_SEND_LINK_ERROR',
  CLEAR: 'ADD_SEND_LINK_CLEAR'
}

export const addSendLinkActions = {
  addSendLink: (data, headers) => {
    return {
      type: addSendLinkTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddSendLinkResponse: (data) => {
    return {
      type: addSendLinkTypes.RESPONSE,
      data
    }
  },
  clearaddSendLink: () => ({
    type: addSendLinkTypes.CLEAR
  })
}

export const getLinkMerchantActionsTypes = {
  REQUEST: 'GET_SEND_MERCHANT',
  RESPONSE: 'SAVE_SEND_MERCHANT_RESPONSE',
  CLEAR: 'CLEAR_SEND_MERCHANT'
}

export const getLinkMerchantActions = {
  get: (id, headers) => ({
    type: getLinkMerchantActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: data => ({
    type: getLinkMerchantActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getLinkMerchantActionsTypes.CLEAR
  })
}

export const SendLinkActionsTypes = {
  REQUEST: 'GET_SEND_LINK',
  RESPONSE: 'SAVE_SEND_LINK_RESPONSE',
  CLEAR: 'CLEAR_SEND_LINK'
}

export const SendLinkActions = {
  get: (id, headers) => ({
    type: SendLinkActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: data => ({
    type: SendLinkActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: SendLinkActionsTypes.CLEAR
  })
}

export const merchantEvidenceActionsTypes = {
  GET_MERCHANT_EVIDENCE: 'GET_MERCHANT_EVIDENCE',
  SAVE_MERCHANT_EVIDENCE_RESPONSE: 'SAVE_MERCHANT_EVIDENCE_RESPONSE',
  CLEAR_MERCHANT_EVIDENCE: 'CLEAR_MERCHANT_EVIDENCE'
}

export const merchantEvidenceActions = {
  getMerchantEvidence: (id, headers) => ({
    type: merchantEvidenceActionsTypes.GET_MERCHANT_EVIDENCE,
    id, headers
  }),
  savemerchantEvidenceResponse: data => ({
    type: merchantEvidenceActionsTypes.SAVE_MERCHANT_EVIDENCE_RESPONSE,
    data
  }),
  clearMerchantEvidence: () => ({
    type: merchantEvidenceActionsTypes.CLEAR_MERCHANT_EVIDENCE
  })
}

export const addMerchantEvidenceTypes = {
  REQUEST: 'ADD_MERCHANT_EVIDENCE_REQUEST',
  RESPONSE: 'ADD_MERCHANT_EVIDENCE_RESPONSE',
  ERROR: 'ADD_MERCHANT_EVIDENCE_ERROR',
  CLEAR: 'ADD_MERCHANT_EVIDENCE_CLEAR'
}

export const addMerchantEvidenceActions = {
  addMerchantEvidence: (data, url, headers) => {
    return {
      type: addMerchantEvidenceTypes.REQUEST,
      payload: data,
      url, headers
    }
  },
  saveaddMerchantEvidenceResponse: (data) => {
    return {
      type: addMerchantEvidenceTypes.RESPONSE,
      data
    }
  },
  clearaddMerchantEvidence: () => ({
    type: addMerchantEvidenceTypes.CLEAR
  })
}

export const getAnalystActionsTypes = {
  REQUEST: 'GET_ANALYST_REQUEST',
  RESPONSE: 'GET_ANALYST_RESPONSE',
  ERROR: 'GET_ANALYST_ERROR',
  CLEAR: 'GET_ANALYST_CLEAR'
}

export const getAnalystActions = {
  get: (params, headers) => ({
    type: getAnalystActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getAnalystActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getAnalystActionsTypes.CLEAR
  })
}

export const sendCustomMailTypes = {
  REQUEST: 'ADD_SEND_CUSTOM_MAIL_REQUEST',
  RESPONSE: 'ADD_SEND_CUSTOM_MAIL_RESPONSE',
  ERROR: 'ADD_SEND_CUSTOM_MAIL_ERROR',
  CLEAR: 'ADD_SEND_CUSTOM_MAIL_CLEAR'
}

export const sendCustomMailActions = {
  add: (data, headers) => {
    return {
      type: sendCustomMailTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: sendCustomMailTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: sendCustomMailTypes.CLEAR
  })
}