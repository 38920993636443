export const issuerbankCreateCaseTypes = {
  REQUEST: 'ADD_NEW_CASES_REQUEST',
  RESPONSE: 'ADD_NEW_CASE_RESPONSE',
  ERROR: 'ADD_NEW_CASE_ERROR',
  CLEAR: 'ADD_NEW_CASE_CLEAR'
}

export const issuerbankPostCaseActions = {
  postCase: (data, headers) => {
    return {
      type: issuerbankCreateCaseTypes.REQUEST,
      payload: data, headers
    }
  },
  savePostCaseResponse: (data) => {
    return {
      type: issuerbankCreateCaseTypes.RESPONSE,
      data
    }
  },
  clearPostCase: () => ({
    type: issuerbankCreateCaseTypes.CLEAR
  })
}

export const importInquiryAlertTypes = {
  REQUEST: 'IMPORT_INQUIRY_ALERT_REQUEST',
  RESPONSE: 'IMPORT_INQUIRY_ALERT_RESPONSE',
  ERROR: 'IMPORT_INQUIRY_ALERT_ERROR',
  CLEAR: 'IMPORT_INQUIRY_ALERT_CLEAR'
}

export const importInquiryAlertActions = {
  importInquiryAlert: (data, config, headers) => {
    return {
      type: importInquiryAlertTypes.REQUEST,
      payload: data,
      config, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: importInquiryAlertTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: importInquiryAlertTypes.CLEAR
  })
}

export const issuerbankAlertCaseTypes = {
  REQUEST: 'ADD_NEW_Alert_REQUEST',
  RESPONSE: 'ADD_NEW_ALERT_RESPONSE',
  ERROR: 'ADD_NEW_ALERT_ERROR',
  CLEAR: 'ADD_NEW_ALERT_CLEAR'
}

export const issuerbankpostAlertCaseActions = {
  postAlertCase: (data, headers) => {
    return {
      type: issuerbankAlertCaseTypes.REQUEST,
      payload: data, headers
    }
  },
  savepostAlertCaseResponse: (data) => {
    return {
      type: issuerbankAlertCaseTypes.RESPONSE,
      data
    }
  },
  clearpostAlertCase: () => ({
    type: issuerbankAlertCaseTypes.CLEAR
  })
}

export const importAlertTypes = {
  REQUEST: 'IMPORT_ALERT_REQUEST',
  RESPONSE: 'IMPORT_ALERT_RESPONSE',
  ERROR: 'IMPORT_ALERT_ERROR',
  CLEAR: 'IMPORT_ALERT_CLEAR'
}

export const importAlertActions = {
  importAlert: (data, config, headers) => {
    return {
      type: importAlertTypes.REQUEST,
      payload: data,
      config, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: importAlertTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: importAlertTypes.CLEAR
  })
}
