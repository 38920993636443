import {
  addAssignUserActionsTypes,
  assignUserActionsTypes,
  deleteAssignUserActionsTypes
} from '../actions'

export const addAssignUserInitialState = {
  list: null
}

export const addAssignUserStoreKey = 'addAssignUserStore'

export const addAssignUserReducer = (state = addAssignUserInitialState, action) => {
  switch (action.type) {
    case addAssignUserActionsTypes.REQUEST:
      return { ...state, loadingAAU: true }
    case addAssignUserActionsTypes.RESPONSE:
      return { ...state, dataAAU: action.data, statusAAU: action.data.status, messageAAU: action.data.message, loadingAAU: false }
    case addAssignUserActionsTypes.CLEAR:
      return { ...state, dataAAU: null, messageAAU: '', statusAAU: '' }
    default:
      return state
  }
}

export const assignUserInitialState = {
  list: null
}

export const assignUserStoreKey = 'assignUserStore'

export const assignUserReducer = (state = assignUserInitialState, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case assignUserActionsTypes.GET_ASSIGN_USER:
      return { ...state, loading: true }
    case assignUserActionsTypes.SAVE_ASSIGN_USER_RESPONSE:
      return { ...state, getAssignUser: res.data, loading: false }
    case assignUserActionsTypes.CLEAR_ASSIGN_USER:
      return { ...state, getAssignUser: null }
    default:
      return state
  }
}

export const deleteAssignUserStoreKey = 'deleteAssignUserStore'

export const deleteAssignUserReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteAssignUserActionsTypes.REQUEST:
      return { ...state, loadingDeleteAssignUser: true }
    case deleteAssignUserActionsTypes.RESPONSE:
      return {
        ...state,
        deleteAssignUser: res.data,
        statusDeleteAssignUser: res.status,
        messageDeleteAssignUser: res.message,
        loadingDeleteAssignUser: false
      }
    case deleteAssignUserActionsTypes.CLEAR:
      return {
        ...state,
        deleteAssignUser: null,
        statusDeleteAssignUser: '',
        messageDeleteAssignUser: ''
      }
    default:
      return state
  }
}
