import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES, SESSION, HEADER, API } from '../../utils/constants'
import { getLocalStorage } from '../../utils/helper'
import axiosInstance from '../../services'
import {
  addMerchantCLTypes,
  addMerchantCLActions,
  saveMerchantTypes,
  saveMerchantActions,
  merchantActions,
  merchantActionsTypes,
  merchantDeleteTypes,
  deleteMerchantActions,
  merchantGetDetailsTypes,
  merchantGetDetailsActions,
  editMerchantTypes,
  editMerchantActions,
  addMerchantUploadTypes,
  addMerchantUploadActions
} from '../actions'
import serviceList from '../../services/serviceList'

const isAuthorized = getLocalStorage(SESSION.TOKEN);

function* addMerchant(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.saveMerchant, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addMerchantCLActions.saveaddMerchantCLResponse(json.data))
    } else {
      yield put(addMerchantCLActions.saveaddMerchantCLResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addMerchantCLTypes.RESPONSE, data })
  }
}

function* saveMerchant(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.saveMerchant, payload, { headers: headers })
    if (json.status === 200) {
      yield put(saveMerchantActions.saveMerchantResponse(json.data))
    } else {
      yield put(saveMerchantActions.saveMerchantResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: saveMerchantTypes.RESPONSE, data })
  }
}

function* fetchMerchant(actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.getMerchant, { params, headers: headers })
    if (json.status === 200) {
      yield put(merchantActions.savemerchantResponse(json.data))
    } else {
      yield put(merchantActions.savemerchantResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: saveMerchantTypes.RESPONSE, data })
  }
}

function* deleteMerchant(actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.getMerchant}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteMerchantActions.savemResponseDeleteMerchant(json.data))
    } else {
      yield put(deleteMerchantActions.savemResponseDeleteMerchant([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: merchantDeleteTypes.SAVE_DELETE_MERCHANT_RESPONSE, data })
  }
}
function* merchantGetDetailsDetails(actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.getMerchant}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(merchantGetDetailsActions.saveMerchantDetailsResponse(json.data))
    } else {
      yield put(merchantGetDetailsActions.saveMerchantDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: merchantGetDetailsTypes.MERCHANT_DETAILS_RESPONSE, data })
  }
}
function* editMerchant(actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.editMerchant}${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(editMerchantActions.saveeditMerchantResponse(json.data))
    } else {
      yield put(editMerchantActions.saveeditMerchantResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editMerchantTypes.RESPONSE, data })
  }
}

export const headersWithAuth = {
  'Content-Type': 'multipart/form-data',
  [`${HEADER.TOKEN}`]: `Bearer ${getLocalStorage(SESSION.TOKEN)}`
}

export const headerWithApiKey = {
  'Content-Type': 'multipart/form-data',
  'api-key': getLocalStorage(API.API_KEY)
}

function* addMerchantUpload(action) {
  const { payload } = action
  try {
    const json = yield axiosInstance.post(serviceList.addMerchantUpload, payload, { headers: isAuthorized ? headersWithAuth : headerWithApiKey })
    if (json.status === 200) {
      yield put(addMerchantUploadActions.saveaddMerchantUploadResponse(json.data))
    } else {
      yield put(addMerchantUploadActions.saveaddMerchantUploadResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addMerchantUploadTypes.RESPONSE, data })
  }
}

export function* fetchMerchantWatcher() {
  yield all([
    yield takeLatest(merchantActionsTypes.GET_MERCHANT, fetchMerchant),
    yield takeLatest(merchantDeleteTypes.DELETE_MERCHANT, deleteMerchant),
    yield takeLatest(addMerchantCLTypes.REQUEST, addMerchant),
    yield takeLatest(merchantActionsTypes.GET_MERCHANT, fetchMerchant),
    yield takeLatest(merchantGetDetailsTypes.GET_MERCHANT_DETAILS, merchantGetDetailsDetails),
    yield takeLatest(editMerchantTypes.REQUEST, editMerchant),
    yield takeLatest(saveMerchantTypes.REQUEST, saveMerchant),
    yield takeLatest(addMerchantUploadTypes.REQUEST, addMerchantUpload)
  ])
}
