import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../theme/helpers'
import { STATUS_RESPONSE, REGEX, SAVE_CURRENT } from '../../../../utils/constants'
import ReactSelect from '../../../../theme/layout/components/ReactSelect'
import { getLocalStorage, headersWithAuth } from '../../../../utils/helper'
import { Modal } from '../../../../theme/layout/components/modal'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  crmActions,
  processorActions,
  paymentActions,
  addclientCRMActions,
  addclientProcessorActions,
  addclientGatewayActions
} from '../../../../store/actions'
import {
  warningAlert,
  confirmAlert,
  confirmationAlert
} from '../../../../utils/alerts'
import color from '../../../../utils/colors'
import {
  crmValidation,
  addCrmValidation,
  addGateValidation,
  processorValidation,
  addProcessorValidation,
  paymentValidation,
  addPaymentGateValidation
} from './validation'

const IntegrationForm = (props) => {
  const {
    className,
    getCRM,
    getCRMDispatch,
    getPaymentDispatch,
    getProcessorDispatch,
    processorData,
    paymentData,
    addclientCRMDispatch,
    addclientGatewayDispatch,
    addclientProcessorDispatch,
    clearaddclientCRMDispatch,
    clearaddclientProcessorDispatch,
    clearaddclientGatewayDispatch,
    statusACCRM,
    messageACCRM,
    statusACPR,
    messageACPR,
    statusACGA,
    messageACGA,
    merchantID,
    clientID
  } = props
  const getAddedMidId = getLocalStorage(SAVE_CURRENT.ADD_MID_ID)
  const [show, setShow] = useState(false)
  const [showAddProcessor, setShowAddProcessor] = useState(false)
  const [showAddGateway, setShowAddGateway] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [errors, setErrors] = useState({})
  const [showTable, setShowTable] = useState(false)
  const [showSFTPTable, setShowSFTPTable] = useState(false)
  const [showGatewayTable, setShowGatewayTable] = useState(false)
  const [selectedCrmOption, setSelectedCrmOption] = useState('')
  const [crmOption, setCrmOption] = useState()
  const [selectedProcessorOption, setSelectedProcessorOption] = useState('')
  const [processorOption, setProcessorOption] = useState()
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('')
  const [paymentOption, setPaymentOption] = useState()
  const [formData, setFormData] = useState({
    crmId: '',
    crmLabel: '',
    processorType: '',
    processorLabel: '',
    paymentGateway: '',
    paymentGatewayLabel: ''
  })
  const [form, setForm] = useState({
    crmId: '',
    crmLabel: '',
    apiKey: '',
    apiSecretKey: '',
    apiEndPoint: '',
    requestApiKey: '',
    requestApiSecret: '',
    requestApiEndPoint: '',
    OAuthUrl: '',
    OAuthUserName: '',
    OAuthPassword: ''
  })
  const [paymentProcesserDetails, setPaymentProcessorDetails] = useState({
    paymentProcessorId: '',
    processorLabel: '',
    disputeSubmissionMode: '',
    method: "",
    oAuthUrl: '',
    oAuthUserName: '',
    oAuthPassword: '',
    hostName: '',
    portNumber: '22',
    username: '',
    password: '',
    faxNumber: '',
    email: '',
    emailBcc: "",
    emailCc: "",
    apiKey: '',
    apiSecretKey: '',
    apiEndPoint: '',
    requestApiKey: '',
    requestApiSecret: '',
    requestApiEndPoint: ''
  })
  const [tableData, setTableData] = useState([])
  const [tableForm, setTableForm] = useState([])
  const [processorTableData, setProcessorTableData] = useState([])
  const [processorTableForm, setProcessorTableForm] = useState([])
  const [paymentTableData, setPaymentTableData] = useState([])
  const [paymentTableForm, setPaymentTableForm] = useState([])
  const [paymentGatewayDetails, setPaymentGatewayDetails] = useState({
    paymentGateway: '',
    paymentGatewayLabel: '',
    gatewayEndPoint: '',
    gatewayApiKey: '',
    gatewayApiSecretKey: ''
  })

  const handleInputChange = (e, type) => {
    e.preventDefault()
    if (type === 'setForm') {
      setForm((values) => ({ ...values, [e.target.name]: e.target.value }))
    }
    if (type === 'setFormData') {
      setFormData((values) => ({ ...values, [e.target.name]: e.target.value }))
    }
    if (type === 'setPaymentProcessorDetails') {
      setPaymentProcessorDetails((values) => ({
        ...values,
        [e.target.name]: e.target.value
      }))
    }
    if (type === 'setPaymentGatewayDetails') {
      setPaymentGatewayDetails((values) => ({
        ...values,
        [e.target.name]: e.target.value
      }))
    }
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const handleAddClick = (type) => {
    if (type === 'CRM') {
      const errorMsg = crmValidation(formData, setErrors)
      if (_.isEmpty(errorMsg)) {
        if (!tableForm.includes(formData.crmId)) {
          setShow(true)
        }
      }
    }
    if (type === 'PAYMENT_PROCESSOR') {
      const errorMsg = processorValidation(formData, setErrors)
      if (_.isEmpty(errorMsg)) {
        if (!processorTableForm.includes(formData.processorType)) {
          setShowAddProcessor(true)
        }
      }
    }
    if (type === 'PAYMENT_GATEWAY') {
      const errorMsg = paymentValidation(formData, setErrors)
      if (_.isEmpty(errorMsg)) {
        if (!paymentTableData.includes(formData.paymentGateway)) {
          setShowAddGateway(true)
        }
      }
    }
  }

  const handleSaveGatewayTable = () => {
    if (!paymentTableForm.includes(formData.paymentGateway)) {
      const errorMsg = addPaymentGateValidation(paymentGatewayDetails, setErrors)
      if (_.isEmpty(errorMsg)) {
        const payload = {
          paymentGateway: formData.paymentGateway,
          apiEndpoint: paymentGatewayDetails.apiEndpoint,
          apiKey: paymentGatewayDetails.apiKey,
          apiSecretKey: paymentGatewayDetails.apiSecretKey,
          clientId: clientID,
          merchantId: merchantID,
          midId: getAddedMidId,
        }
        addclientGatewayDispatch(payload, headersWithAuth)
      }
      setSelectedPaymentOption()
      setShowAddGateway(false)
      setEditMode(false)
      setFormData({ paymentGateway: '' })
      setPaymentGatewayDetails({
        apiEndpoint: '',
        apiKey: '',
        apiSecretKey: ''
      })
    }
  }

  const handleAddTable = () => {
    if (!tableForm.includes(formData.crmId)) {
      const errorMsg = addCrmValidation(form, setErrors)
      if (_.isEmpty(errorMsg)) {
        addclientCRMDispatch({
          clientId: clientID,
          merchantId: merchantID,
          midId: getAddedMidId,
          crmId: formData.crmId,
          apiKey: form.apiKey,
          apiSecretKey: form.apiSecretKey,
          apiEndPoint: form.apiEndPoint,
          requestApiKey: form.requestApiKey,
          requestApiSecret: form.requestApiSecret,
          requestApiEndPoint: form.requestApiEndPoint,
          OAuthUrl: form.OAuthUrl,
          OAuthUserName: form.OAuthUserName,
          OAuthUserName: form.OAuthUserName
        }, headersWithAuth)
        setSelectedCrmOption()
        setForm({
          apiKey: '',
          apiSecretKey: '',
          apiEndPoint: '',
          crmId: '',
          crmLabel: '',
          requestApiKey: "",
          requestApiSecret: "",
          requestApiEndPoint: "",
          OAuthUrl: '',
          OAuthUserName: '',
          OAuthPassword: '',
        })
        setFormData({ crmId: '' })
        setShow(false)
        setEditMode(false)
      }
    }
  }

  const handleAddProcessor = () => {
    const payload = {
      paymentProcessorId: paymentProcesserDetails.paymentProcessorId,
      processorLabel: paymentProcesserDetails.processorLabel,
      method: paymentProcesserDetails.method,
      oAuthUrl: paymentProcesserDetails.oAuthUrl,
      oAuthUserName: paymentProcesserDetails.oAuthUserName,
      oAuthPassword: paymentProcesserDetails.oAuthPassword,
      hostName: paymentProcesserDetails.hostName,
      portNumber: paymentProcesserDetails.portNumber,
      username: paymentProcesserDetails.username,
      password: paymentProcesserDetails.password,
      faxNumber: paymentProcesserDetails.faxNumber,
      email: paymentProcesserDetails.email,
      emailBcc: paymentProcesserDetails.emailBcc,
      emailCc: paymentProcesserDetails.emailCc,
      disputeSubmissionMode: paymentProcesserDetails.disputeSubmissionMode,
      apiKey: paymentProcesserDetails.apiKey,
      apiSecretKey: paymentProcesserDetails.apiSecretKey,
      apiEndPoint: paymentProcesserDetails.apiEndPoint,
      requestApiKey: paymentProcesserDetails.requestApiKey,
      requestApiSecret: paymentProcesserDetails.requestApiSecret,
      requestApiEndPoint: paymentProcesserDetails.requestApiEndPoint,
      clientId: clientID,
      merchantId: merchantID,
      midId: getAddedMidId,
    }
    addclientProcessorDispatch(payload, headersWithAuth)
  }

  const handleAddProcessorTable = () => {
    const errorMsg = addProcessorValidation(paymentProcesserDetails, setErrors)
    if (_.isEmpty(errorMsg)) {
      if (
        processorTableForm &&
        !processorTableForm.includes(formData.processorType)
      ) {
        handleAddProcessor()
        setPaymentProcessorDetails({
          hostName: '',
          oAuthUrl: '',
          oAuthUserName: '',
          oAuthPassword: '',
          disputeSubmissionMode: '',
          method: "",
          portNumber: '22',
          username: '',
          password: '',
          faxNumber: '',
          email: '',
          apiKey: '',
          apiSecretKey: '',
          apiEndPoint: '',
          requestApiKey: '',
          requestApiSecret: '',
          requestApiEndPoint: ''
        })
        setSelectedProcessorOption()
        setFormData({ processorType: '' })
        setShowAddProcessor(false)
        setEditMode(false)
      }
    }
  }

  const onCloseProcessor = () => {
    setShowAddProcessor(false)
    setSelectedProcessorOption()
    setFormData({ processorType: '', processorLabel: '' })
    setPaymentProcessorDetails({
      disputeSubmissionMode: '',
      hostName: '',
      method: "",
      portNumber: '22',
      username: '',
      password: '',
      faxNumber: '',
      email: '',
      apiKey: '',
      apiSecretKey: '',
      apiEndPoint: '',
      requestApiKey: '',
      requestApiSecret: '',
      requestApiEndPoint: '',
      OAuthUrl: '',
      OAuthUserName: '',
      OAuthPassword: '',
    })
    setErrors((values) => ({
      ...values,
      processorType: '',
      processorLabel: '',
      disputeSubmissionMode: '',
      hostName: '',
      portNumber: '22',
      username: '',
      password: '',
      faxNumber: '',
      email: '',
      apiKey: '',
      apiSecretKey: '',
      apiEndPoint: '',
      requestApiKey: '',
      requestApiSecret: '',
      requestApiEndPoint: '',
      OAuthUrl: '',
      OAuthUserName: '',
      OAuthPassword: '',
    }))
    setEditMode(false)
  }

  const onEditClick = (item) => {
    setShow(true)
    setEditMode(true)
    setForm({
      crmLabel: item.crmLabel,
      crmId: item.crmId,
      apiKey: item.apiKey,
      apiSecretKey: item.apiSecretKey,
      apiEndPoint: item.apiEndPoint,
      requestApiKey: item.requestApiKey,
      requestApiSecret: item.requestApiSecret,
      requestApiEndPoint: item.requestApiEndPoint,
      OAuthUrl: item.OAuthUrl,
      OAuthUserName: item.OAuthUserName,
      OAuthPassword: item.OAuthPassword
    })
  }

  const onEditProcessorClick = (item) => {
    setShowAddProcessor(true)
    setEditMode(true)
    setPaymentProcessorDetails({
      disputeSubmissionMode: item.disputeSubmissionMode,
      processorType: item.processorType,
      processorLabel: item.processorLabel,
      method: item.method,
      oAuthUrl: item.oAuthUrl,
      oAuthUserName: item.oAuthUserName,
      oAuthPassword: item.oAuthPassword,
      hostName: item.hostName,
      portNumber: item.portNumber,
      username: item.username,
      password: item.password,
      faxNumber: item.faxNumber,
      email: item.email,
      apiKey: item.apiKey,
      apiSecretKey: item.apiSecretKey,
      apiEndPoint: item.apiEndPoint,
      requestApiKey: item.requestApiKey,
      requestApiSecret: item.requestApiSecret,
      requestApiEndPoint: item.requestApiEndPoint,
      OAuthUrl: item.OAuthUrl,
      OAuthUserName: item.OAuthUserName,
      OAuthPassword: item.OAuthPassword
    })
  }

  const onEditGateClick = (item) => {
    setShowAddGateway(true)
    setEditMode(true)
    setPaymentGatewayDetails({
      paymentGateway: item.paymentGateway,
      paymentGatewayLabel: item.paymentGatewayLabel,
      gatewayEndPoint: item.gatewayEndPoint,
      gatewayApiKey: item.gatewayApiKey,
      gatewayApiSecretKey: item.gatewayApiSecretKey
    })
  }

  const onDeleteGateClick = (item) => {
    setPaymentTableData((prevState) =>
      prevState.filter((obj) =>
        obj.paymentGateway !== item.paymentGateway ? obj : null
      )
    )
    setPaymentTableForm((prevState) =>
      prevState.filter((obj) => (obj !== item.paymentGateway ? obj : null))
    )
  }

  const onDeleteClick = (item) => {
    setTableData((prevState) =>
      prevState.filter((obj) => (obj.crmId !== item.crmId ? obj : null))
    )
    setTableForm((prevState) =>
      prevState.filter((obj) => (obj !== item.crmId ? obj : null))
    )
  }

  const onDeleteProcessorClick = (item) => {
    setProcessorTableData((prevState) =>
      prevState.filter((obj) =>
        obj.processorType !== item.processorType ? obj : null
      )
    )
    setProcessorTableForm((prevState) =>
      prevState.filter((obj) => (obj !== item.processorType ? obj : null))
    )
  }

  useEffect(() => {
    getCRMDispatch(null, headersWithAuth)
    getPaymentDispatch(null, headersWithAuth)
    getProcessorDispatch(null, headersWithAuth)
  }, [])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : ''
    })
  }

  const handleChangeCRM = (selectedOption) => {
    if (selectedOption !== null && !tableForm.includes(selectedOption.label)) {
      setSelectedCrmOption(selectedOption)
      setFormData((values) => ({
        ...values,
        crmId: selectedOption.value,
        crmLabel: selectedOption.label
      }))
    } else {
      setSelectedCrmOption()
      setFormData((values) => ({ ...values, crmId: '', crmLabel: '' }))
    }
    setErrors((values) => ({ ...values, crmId: '' }))
  }

  useEffect(() => {
    const crmId = getDefaultOptions(getCRM)
    setCrmOption(crmId)
    if (!_.isEmpty(formData.crmId)) {
      const selOption = _.filter(crmId, function (x) {
        if (_.includes(formData.crmId._id, x.value)) {
          return x
        }
      })
      setSelectedCrmOption(selOption)
    }
  }, [getCRM])

  const getDefaultProcessorOptions = (getData) => {
    const defaultOptions = []
    if (!_.isEmpty(getData)) {
      getData && getData.map((item) => {
        if (item && item.processorName) {
          defaultOptions.push({
            label: `${item.processorName ? item.processorName : ''}`,
            value: item._id
          })
        }
      })
      return defaultOptions
    }
  }

  const handleChangeProcessor = (selectedOption) => {
    if (selectedOption !== null && !processorTableForm.includes(selectedOption.label)) {
      setSelectedProcessorOption(selectedOption)
      setFormData((values) => ({
        ...values,
        paymentProcessorId: selectedOption.value,
        processorLabel: selectedOption.label
      }))
      setErrors((values) => ({ ...values, processorLabel: '', paymentProcessorId: '' }))
    } else {
      setSelectedProcessorOption()
      setFormData((values) => ({ ...values, paymentProcessorId: '', processorLabel: '' }))
    }
  }

  useEffect(() => {
    const data = getDefaultProcessorOptions(processorData)
    setProcessorOption(data)
    if (!_.isEmpty(formData.processorType)) {
      const selOption = _.filter(data, function (x) {
        if (_.includes(formData.processorType._id, x.value)) {
          return x
        }
      })
      setSelectedProcessorOption(selOption)
    }
  }, [processorData])

  const getDefaultOptions = (getCRM) => {
    const defaultOptions = []
    if (!_.isEmpty(getCRM)) {
      const { data } = getCRM
      if (!_.isEmpty(data)) {
        data.map((item) =>
          defaultOptions.push({
            label: `${item.crmName ? item.crmName : ''}`,
            value: item._id
          })
        )
      }
      return defaultOptions
    }
  }

  const getDefaultPaymentOptions = (getData) => {
    const defaultOptions = []
    if (!_.isEmpty(getData)) {
      getData && getData.map((item) => {
        if (item && item.gatewayName) {
          defaultOptions.push({
            label: `${item.gatewayName ? item.gatewayName : ''}`,
            value: item._id
          })
        }
      })
      return defaultOptions
    }
  }

  const handleChangePayment = (selectedOption) => {
    if (selectedOption !== null && !paymentTableForm.includes(selectedOption.label)) {
      setSelectedPaymentOption(selectedOption)
      setFormData((values) => ({
        ...values,
        paymentGateway: selectedOption.value,
        paymentGatewayLabel: selectedOption.label
      }))
      setErrors((values) => ({ ...values, paymentGateway: '', paymentGatewayLabel: '' }))
    } else {
      setSelectedPaymentOption()
      setFormData((values) => ({ ...values, paymentGateway: '', paymentGatewayLabel: '' }))
    }
  }

  useEffect(() => {
    const data = getDefaultPaymentOptions(paymentData)
    setPaymentOption(data)
    if (!_.isEmpty(formData.paymentGateway)) {
      const selOption = _.filter(data, function (x) {
        if (_.includes(formData.paymentGateway._id, x.value)) {
          return x
        }
      })
      setSelectedPaymentOption(selOption)
    }
  }, [paymentData])

  const onPostConfirm = () => {
  }

  useEffect(() => {
    if (statusACCRM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'Success',
        messageACCRM,
        'success',
        'ok',
        () => {
          onPostConfirm()
        },
        () => {
          onPostConfirm()
        }
      )
      clearaddclientCRMDispatch()
    } else if (statusACCRM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert('Error', messageACCRM, '', 'Ok')
    }
    clearaddclientCRMDispatch()
  }, [statusACCRM])

  const onConfirm = () => { }

  useEffect(() => {
    if (statusACPR === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'Success',
        messageACPR,
        'success',
        'ok',
        () => {
          onConfirm()
        },
        () => {
          onConfirm()
        }
      )
      clearaddclientProcessorDispatch()
    } else if (statusACPR === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert('Error', messageACPR, '', 'Ok')
      clearaddclientProcessorDispatch()
    }
  }, [statusACPR])

  useEffect(() => {
    if (statusACGA === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'Success',
        messageACGA,
        'success',
        'ok',
        () => {
          onConfirm()
        },
        () => {
          onConfirm()
        }
      )
      clearaddclientGatewayDispatch()
    } else if (statusACGA === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert('Error', messageACGA, '', 'Ok')
      clearaddclientGatewayDispatch()
    }
  }, [statusACGA])

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  return (
    <>
      <Modal showModal={showAddProcessor} modalWidth={550}>
        <div className=''>
          <div className=''>
            <div className='p-5'>
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='me-8'>Payment Processor Details</h2>
                <button
                  type='button'
                  className='btn btn-lg btn-icon btn-active-light-primary close'
                  onClick={() => {
                    onCloseProcessor()
                  }}
                >
                  {/* eslint-disable */}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon-1"
                  />
                  {/* eslint-disable */}
                </button>
              </div>
              <div className="bg-light">
                <form className="container-fixed">
                  <div className="card-body p-8">
                    <div className="mb-0 pt-5">
                      <div className="row">
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-lg-4 mt-3">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                Method:
                              </label>
                            </div>
                            <div className="col-lg-8">
                              <select
                                name="method"
                                className="form-select form-select-solid bg-secondary "
                                onChange={(e) =>
                                  handleInputChange(e, "setPaymentProcessorDetails")
                                }
                                value={
                                  paymentProcesserDetails.method || ""
                                }
                              >
                                <option value=''>Select...</option>
                                <option value='API'>API</option>
                                <option value='OAUTH'>OAuth</option>
                              </select>
                              {errors && errors.method && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.method}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {paymentProcesserDetails && paymentProcesserDetails.method === 'API'
                          ? (
                            <>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-4 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      API End Point :
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <input
                                      name="apiEndPoint"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="API End Point"
                                      onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.apiEndPoint || ""
                                      }
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.apiEndPoint && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.apiEndPoint}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-4 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      API Key :
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <input
                                      name="apiKey"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="API Key"
                                      onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={paymentProcesserDetails.apiKey || ""}
                                      maxLength={100}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.apiKey && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.apiKey}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-4 mt-3">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      API Secret Key:
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <input
                                      name="apiSecretKey"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="API Secret Key"
                                      onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={
                                        paymentProcesserDetails.apiSecretKey || ""
                                      }
                                      maxLength={100}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.apiSecretKey && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.apiSecretKey}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                          : null}
                        <div className="mb-3">
                          {paymentProcesserDetails && paymentProcesserDetails.method === 'OAUTH'
                            ? (
                              <>
                                <div className="row my-6">
                                  <div className="col-lg-4 mt-0">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      OAuth Url:
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <input
                                      name="oAuthUrl"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="OAuth Url"
                                      onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={paymentProcesserDetails.oAuthUrl || ""}
                                      maxLength={42}
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.oAuthUrl && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.oAuthUrl}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-lg-6">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      User Name :
                                    </label>
                                    <input
                                      name="oAuthUserName"
                                      type="text"
                                      className="form-control bg-secondary"
                                      placeholder="User Name"
                                      onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={paymentProcesserDetails.oAuthUserName || ""}
                                      maxLength={42}
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.oAuthUserName && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.oAuthUserName}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-6">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label">
                                      Password :
                                    </label>
                                    <input
                                      name="oAuthPassword"
                                      type="password"
                                      className="form-control bg-secondary"
                                      placeholder="Password"
                                      onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          "setPaymentProcessorDetails"
                                        )
                                      }
                                      autoComplete="off"
                                      value={paymentProcesserDetails.oAuthPassword || ""}
                                      maxLength={42}
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onKeyPress={(e) => {
                                        if (
                                          !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                            e.key
                                          )
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errors && errors.oAuthPassword && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red}"}</style>
                                        {errors.oAuthPassword}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : null}
                        </div>
                        <div className="col-lg-4 mt-0">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            Mode of Dispute submission:
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <select
                            name="disputeSubmissionMode"
                            className="form-select form-select-solid bg-secondary "
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                "setPaymentProcessorDetails"
                              )
                            }
                            value={
                              paymentProcesserDetails.disputeSubmissionMode ||
                              ""
                            }
                          >
                            <option value="">
                              Select submission mode...
                            </option>
                            <option value="SFTP">SFTP</option>
                            <option value="FAX">Fax</option>
                            <option value="EMAIL">Email</option>
                            <option value="API">API</option>
                          </select>
                          {errors && errors.disputeSubmissionMode && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.disputeSubmissionMode}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      {paymentProcesserDetails.disputeSubmissionMode ===
                        "SFTP" ? (
                        <>
                          <div className="row">
                            <div className="col-lg-8">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                Host Name :
                              </label>
                              <input
                                name="hostName"
                                type="text"
                                className="form-control bg-secondary"
                                placeholder="Host Name"
                                onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "setPaymentProcessorDetails"
                                  )
                                }
                                autoComplete="off"
                                value={paymentProcesserDetails.hostName || ""}
                                maxLength={42}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onKeyPress={(e) => {
                                  if (
                                    !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                      e.key
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors && errors.hostName && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.hostName}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                Port Number :
                              </label>
                              <input
                                name="portNumber"
                                type="text"
                                className="form-control bg-secondary"
                                placeholder="Port Number"
                                onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "setPaymentProcessorDetails"
                                  )
                                }
                                autoComplete="off"
                                value={
                                  paymentProcesserDetails.portNumber || ""
                                }
                                maxLength={10}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onKeyPress={(e) => {
                                  if (!REGEX.NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors && errors.portNumber && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.portNumber}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col-lg-6">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                User Name :
                              </label>
                              <input
                                name="username"
                                type="text"
                                className="form-control bg-secondary"
                                placeholder="User Name"
                                onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "setPaymentProcessorDetails"
                                  )
                                }
                                autoComplete="off"
                                value={paymentProcesserDetails.username || ""}
                                maxLength={42}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onKeyPress={(e) => {
                                  if (
                                    !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                      e.key
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors && errors.username && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.username}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                Password :
                              </label>
                              <input
                                name="password"
                                type="password"
                                className="form-control bg-secondary"
                                placeholder="Password"
                                onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "setPaymentProcessorDetails"
                                  )
                                }
                                autoComplete="off"
                                value={paymentProcesserDetails.password || ""}
                                maxLength={42}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onKeyPress={(e) => {
                                  if (
                                    !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                      e.key
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors && errors.password && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.password}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : null}
                      {paymentProcesserDetails.disputeSubmissionMode ===
                        "FAX" ? (
                        <div className="row">
                          <div className="col-lg-4 mt-3">
                            <label className="font-size-xs font-weight-bold mb-3  form-label">
                              Fax Number :
                            </label>
                          </div>
                          <div className="col-lg-8">
                            <input
                              name="faxNumber"
                              type="text"
                              className="form-control bg-secondary"
                              placeholder="Fax Number"
                              onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                              maxLength={10}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "setPaymentProcessorDetails"
                                )
                              }
                              autoComplete="off"
                              value={paymentProcesserDetails.faxNumber || ""}
                            />
                            {errors && errors.faxNumber && (
                              <div className="rr mt-1">
                                <style>{".rr{color:red}"}</style>
                                {errors.faxNumber}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      {paymentProcesserDetails.disputeSubmissionMode ===
                        "API" ? (
                        <div className="card-body">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  API End Point :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="requestApiEndPoint"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="API End Point"
                                  onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={
                                    paymentProcesserDetails.requestApiEndPoint || ""
                                  }
                                  maxLength={42}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onKeyPress={(e) => {
                                    if (
                                      !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                        e.key
                                      )
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors && errors.requestApiEndPoint && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.requestApiEndPoint}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  API Key :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="requestApiKey"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="API Key"
                                  onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={paymentProcesserDetails.requestApiKey || ""}
                                  maxLength={42}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onKeyPress={(e) => {
                                    if (
                                      !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                        e.key
                                      )
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors && errors.requestApiKey && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.requestApiKey}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  API Secret Key:
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="requestApiSecret"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="API Secret Key"
                                  onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={
                                    paymentProcesserDetails.requestApiSecret || ""
                                  }
                                  maxLength={42}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onKeyPress={(e) => {
                                    if (
                                      !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                        e.key
                                      )
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors && errors.requestApiSecret && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.requestApiSecret}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {paymentProcesserDetails.disputeSubmissionMode ===
                        "EMAIL" ? (
                        <>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  Email :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="email"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="Email"
                                  onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                  onChange={(e) =>
                                    handleInputChange(e, "setPaymentProcessorDetails")
                                  }
                                  autoComplete="off"
                                  value={paymentProcesserDetails.email || ""}
                                />
                                {errors && errors.email && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  BCC :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="emailBcc"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="BCC"
                                  onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                  onChange={(e) =>
                                    handleInputChange(e, "setPaymentProcessorDetails")
                                  }
                                  autoComplete="off"
                                  value={paymentProcesserDetails.emailBcc || ""}
                                />
                                {errors && errors.emailBcc && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.emailBcc}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  CC :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="emailCc"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="CC"
                                  onBlur={e => handleTrimWhiteSpace(e, setPaymentProcessorDetails)}
                                  onChange={(e) =>
                                    handleInputChange(e, "setPaymentProcessorDetails")
                                  }
                                  autoComplete="off"
                                  value={paymentProcesserDetails.emailCc || ""}
                                />
                                {errors && errors.emailCc && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.emailCc}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row pb-2">
                    <div className="col-lg-6" />
                    <div className="col-lg-6">
                      <div className="col-lg-11">
                        <button
                          type="button"
                          className="btn btn-sm btn-green m-2 fa-pull-right"
                          onClick={() => {
                            handleAddProcessorTable();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal showModal={show} modalWidth={550}>
        <div
          className=""
          id="crmModal"
        >
          <div className="">
            <div className="p-5">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="me-8">Add CRM</h2>
                <button
                  type="button"
                  className="btn btn-lg btn-icon btn-active-light-primary close"
                  onClick={() => {
                    setShow(false);
                    setSelectedCrmOption()
                    setForm({
                      apiKey: "",
                      apiSecretKey: "",
                      apiEndPoint: "",
                      crmId: "",
                      crmLabel: "",
                      requestApiKey: '',
                      requestApiSecret: '',
                      requestApiEndPoint: '',
                      OAuthUrl: '',
                      OAuthUserName: '',
                      OAuthPassword: ''
                    });
                    setErrors((values) => ({
                      ...values,
                      apiKey: "",
                      apiSecretKey: "",
                      apiEndPoint: "",
                      crmId: "",
                      crmLabel: "",
                      requestApiKey: '',
                      requestApiSecret: '',
                      requestApiEndPoint: '',
                      OAuthUrl: '',
                      OAuthUserName: '',
                      OAuthPassword: ''
                    }))
                    setEditMode(false);
                    setFormData({ crmId: "", crmLabel: "" });
                  }}
                >
                  {/* eslint-disable */}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon-1"
                  />
                  {/* eslint-disable */}
                </button>
              </div>
              <div className="bg-light">
                <form className="container-fixed">
                  <div className="card-body p-8">
                    {form.crmLabel ? (
                      <h4 className="me-8 mb-2">{form.crmLabel}</h4>
                    ) : null}
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API End Point :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="apiEndPoint"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API End Point"
                            onChange={(e) => handleInputChange(e, "setForm")}
                            autoComplete="off"
                            value={form.apiEndPoint || ""}
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.apiEndPoint && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.apiEndPoint}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API Key :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="apiKey"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API Key"
                            onChange={(e) => handleInputChange(e, "setForm")}
                            autoComplete="off"
                            value={form.apiKey || ""}
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.apiKey && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.apiKey}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API Secret :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="apiSecretKey"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API Secret"
                            onChange={(e) => handleInputChange(e, "setForm")}
                            autoComplete="off"
                            value={form.apiSecretKey || ""}
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.apiSecretKey && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.apiSecretKey}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6" />
                    <div className="col-lg-6">
                      <div className="col-lg-11">
                        <button
                          type="button"
                          className="btn btn-sm btn-green m-2 fa-pull-right"
                          onClick={() => {
                            handleAddTable();
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal showModal={showAddGateway} modalWidth={550}>
        <div
          className=''
          id='gatewayModal'
        >
          <div className=''>
            <div className='p-5'>
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='me-8'>Payment Gateway Details</h2>
                <button
                  type='button'
                  className='btn btn-lg btn-icon btn-active-light-primary close'
                  onClick={() => {
                    setSelectedPaymentOption()
                    setShowAddGateway(false)
                    setPaymentGatewayDetails({
                      paymentGatewayLabel: '',
                      paymentGateway: '',
                      gatewayEndPoint: '',
                      gatewayApiKey: '',
                      gatewayApiSecretKey: '',
                      disputeSubmissionMode: ''
                    })
                    setErrors((values) => ({
                      ...values,
                      paymentGatewayLabel: '',
                      paymentGateway: '',
                      gatewayEndPoint: '',
                      gatewayApiKey: '',
                      gatewayApiSecretKey: '',
                      disputeSubmissionMode: ''
                    }))
                    setEditMode(false)
                    setFormData({ paymentGateway: '', paymentGatewayLabel: '' })
                  }}
                >
                  {/* eslint-disable */}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon-1"
                  />
                  {/* eslint-disable */}
                </button>
              </div>
              <div className="bg-light">
                <form className="container-fixed">
                  <div className="card-body p-8">
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API End Point :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="gatewayEndPoint"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API End Point"
                            onChange={(e) =>
                              handleInputChange(e, "setPaymentGatewayDetails")
                            }
                            autoComplete="off"
                            value={paymentGatewayDetails.gatewayEndPoint || ""}
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.gatewayEndPoint && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.gatewayEndPoint}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API Key :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="gatewayApiKey"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API Key"
                            onChange={(e) =>
                              handleInputChange(e, "setPaymentGatewayDetails")
                            }
                            autoComplete="off"
                            value={paymentGatewayDetails.gatewayApiKey || ""}
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.gatewayApiKey && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.gatewayApiKey}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API Secret Key:
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="gatewayApiSecretKey"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API Secret Key"
                            onChange={(e) =>
                              handleInputChange(e, "setPaymentGatewayDetails")
                            }
                            autoComplete="off"
                            value={
                              paymentGatewayDetails.gatewayApiSecretKey || ""
                            }
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.gatewayApiSecretKey && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.gatewayApiSecretKey}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row pb-5">
                    <div className="col-lg-6" />
                    <div className="col-lg-6">
                      <div className="col-lg-11">
                        <button
                          type="button"
                          className="btn btn-sm btn-green m-2 fa-pull-right"
                          onClick={() => {
                            handleSaveGatewayTable();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="card-header bg-skyBlue py-10">
        <div className="card-body m-5">
          <h2 className="mb-5">CRM</h2>
          <div className="form-group row mb-2">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="d-flex justify-content-start my-auto">
                    <div className="col-lg-5 pr-3 me-3">
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name="crmId"
                        className="basic-single"
                        classNamePrefix="select"
                        handleChangeReactSelect={handleChangeCRM}
                        options={crmOption}
                        value={selectedCrmOption}
                      />
                      {errors && errors.crmId && (
                        <div className="rr mt-1">
                          <style>{".rr{color:red}"}</style>
                          {errors.crmId}
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className="btn btn-green mb-3 py-2"
                        onClick={() => {
                          handleAddClick("CRM");
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showTable ? (
            <>
              <div className="w-100 d-flex justify-content-center mt-2">
                <div className={`card ${className}`}>
                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-hover table-rounded table-striped border gs-2 mt-6">
                        <thead>
                          <tr className="fw-bold fs-6 text-gray-800">
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>S.No</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>CRM</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>API Key</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>API Secret</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>API End Point</span>
                              </div>
                            </th>
                            <th className="min-w-80px text-start">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.length > 0 ? (
                            tableData.map((item, i) => (
                              <tr key={i} className="px-2">
                                <td className="pb-0 pt-5  text-start">
                                  {i + 1}
                                </td>
                                <td className="pb-0 pt-5  text-start">
                                  {item.crmLabel}
                                </td>
                                <td className="pb-0 pt-5  text-start">
                                  {item.apiKey}
                                </td>
                                <td className="pb-0 pt-5  text-start">
                                  {item.apiSecretKey}
                                </td>
                                <td className="pb-0 pt-5  text-start">
                                  {item.apiEndPoint}
                                </td>
                                <td className="pb-0 pt-3 text-start">
                                  <div className="my-auto d-flex">
                                    <button
                                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                      onClick={() => {
                                        onEditClick(item);
                                      }}
                                    >
                                      {/* eslint-disable */}
                                      <KTSVG
                                        path="/media/icons/duotune/art/art005.svg"
                                        className="svg-icon-3"
                                      />
                                      {/* eslint-enable */}
                                    </button>
                                    <button
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                      onClick={() => {
                                        onDeleteClick(item)
                                      }}
                                    >
                                      {/* eslint-disable */}
                                      <KTSVG
                                        path="/media/icons/duotune/general/gen027.svg"
                                        className="svg-icon-3"
                                      />
                                      {/* eslint-enable */}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className='text-center py-3'>
                              <td colSpan='100%'>No record(s) found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {errors && errors.crmForm && (
            <div className='rr mt-0 text-danger'>
              <style>{'.rr{color:red}'}</style>
              {errors.crmForm}
            </div>
          )}
        </div>
        <div className='card-body mt-5 m-5'>
          <h2 className='mb-5'>Payment Processor</h2>
          <div className='form-group row mb-2'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='d-flex justify-content-start my-auto'>
                    <div className='col-lg-5 pr-3 me-3'>
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name='paymentProcessorId'
                        className='basic-single'
                        classNamePrefix='select'
                        handleChangeReactSelect={handleChangeProcessor}
                        options={processorOption}
                        value={selectedProcessorOption}
                      />
                      {errors && errors.paymentProcessorId && (
                        <div className='rr mt-1'>
                          <style>{'.rr{color:red}'}</style>
                          {errors.paymentProcessorId}
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className='btn btn-green mb-3 py-2'
                        onClick={() => {
                          handleAddClick('PAYMENT_PROCESSOR')
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errors && errors.crmForm && (
            <div className='rr mt-0 text-danger'>
              <style>{'.rr{color:red}'}</style>
              {errors.crmForm}
            </div>
          )}
          {showSFTPTable ? (
            <>
              <div className='w-100 d-flex justify-content-start mt-2'>
                <div className={`card ${className}`}>
                  <div className='card-body py-3'>
                    <div className='table-responsive'>
                      <table className='table table-hover table-rounded table-striped border w-100 gs-2 mt-2'>
                        <thead>
                          <tr className='fw-bold fs-6 text-gray-800'>
                            <th className='min-w-200px text-start'>
                              <div className='d-flex'>
                                <span>S.No</span>
                              </div>
                            </th>
                            <th className='min-w-200px text-start'>
                              <div className='d-flex'>
                                <span>Payment Processor</span>
                              </div>
                            </th>
                            <th className='min-w-200px text-start'>
                              <div className='d-flex'>
                                <span>Submission mode</span>
                              </div>
                            </th>
                            <th className='min-w-200px text-start'>
                              <div className='d-flex'>
                                <span>API End Point</span>
                              </div>
                            </th>
                            <th className='min-w-200px text-start'>
                              <div className='d-flex'>
                                <span>API Key</span>
                              </div>
                            </th>
                            <th className='min-w-200px text-start'>
                              <div className='d-flex'>
                                <span>API Secret</span>
                              </div>
                            </th>
                            <th className='min-w-80px text-start'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {processorTableData.length > 0 ? (
                            processorTableData.map((item, i) => {
                              return (
                                <tr key={i} className='px-2'>
                                  <td className='pb-0 pt-5  text-start'>
                                    {i + 1}
                                  </td>
                                  <td className='pb-0 pt-5  text-start'>
                                    {item.processorLabel}
                                  </td>
                                  <td className='pb-0 pt-5 text-start'>
                                    {item.disputeSubmissionMode}
                                  </td>
                                  <td className='pb-0 pt-5  text-start'>
                                    {item.apiKey}
                                  </td>
                                  <td className='pb-0 pt-5  text-start'>
                                    {item.apiSecretKey}
                                  </td>
                                  <td className='pb-0 pt-5  text-start'>
                                    {item.apiEndPoint}
                                  </td>
                                  <td className='pb-0 pt-3 text-start'>
                                    <div className='my-auto d-flex'>
                                      <button
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        onClick={() => {
                                          onEditProcessorClick(item)
                                        }}
                                      >
                                        {/* eslint-disable */}
                                        <KTSVG
                                          path="/media/icons/duotune/art/art005.svg"
                                          className="svg-icon-3"
                                        />
                                        {/* eslint-enable */}
                                      </button>
                                      <button
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        onClick={() => {
                                          onDeleteProcessorClick(item)
                                        }}
                                      >
                                        {/* eslint-disable */}
                                        <KTSVG
                                          path="/media/icons/duotune/general/gen027.svg"
                                          className="svg-icon-3"
                                        />
                                        {/* eslint-enable */}
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr className='text-center py-3'>
                              <td colSpan='100%'>No record(s) found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className='card-body mt-5 m-5'>
          <h2 className='mb-5'>Payment Gateway</h2>
          <div className='form-group row mb-2'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='d-flex justify-content-start my-auto'>
                    <div className='col-lg-5 pr-3 me-3'>
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name='crmId'
                        className='basic-single'
                        classNamePrefix='select'
                        handleChangeReactSelect={handleChangePayment}
                        options={paymentOption}
                        value={selectedPaymentOption}
                      />
                      {errors && errors.paymentGateway && (
                        <div className='rr mt-1'>
                          <style>{'.rr{color:red}'}</style>
                          {errors.paymentGateway}
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className='btn btn-green mb-3 py-2'
                        onClick={() => {
                          handleAddClick('PAYMENT_GATEWAY')
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showGatewayTable ? (
            <div className='w-100 d-flex justify-content-start mt-2'>
              <div className={`card ${className}`}>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <table className='table table-hover table-rounded table-striped border w-100 gs-2 mt-6'>
                      <thead>
                        <tr className='fw-bold fs-6 text-gray-800'>
                          <th className='min-w-200px text-start'>
                            <div className='d-flex'>
                              <span>S.No</span>
                            </div>
                          </th>
                          <th className='min-w-200px text-start'>
                            <div className='d-flex'>
                              <span>Gateway Api Key</span>
                            </div>
                          </th>
                          <th className='min-w-200px text-start'>
                            <div className='d-flex'>
                              <span>Gateway End Point</span>
                            </div>
                          </th>
                          <th className='min-w-200px text-start'>
                            <div className='d-flex'>
                              <span>Gateway Api Secret Key</span>
                            </div>
                          </th>
                          <th className='min-w-80px text-start'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentTableData.length > 0 ? (
                          paymentTableData.map((item, i) => (
                            <tr key={i} className='px-2'>
                              <td className='pb-0 pt-5 text-start'>{i + 1}</td>
                              <td className='pb-0 pt-5 text-start'>
                                {item.gatewayApiKey}
                              </td>
                              <td className='pb-0 pt-5 text-start'>
                                {item.gatewayEndPoint}
                              </td>
                              <td className='pb-0 pt-5 text-start'>
                                {item.gatewayApiSecretKey}
                              </td>
                              <td className='pb-0 pt-3 text-start'>
                                <div className='my-auto d-flex'>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={() => {
                                      onEditGateClick(item)
                                    }}
                                  >
                                    {/* eslint-disable */}
                                    <KTSVG
                                      path="/media/icons/duotune/art/art005.svg"
                                      className="svg-icon-3"
                                    />
                                    {/* eslint-enable */}
                                  </button>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={() => {
                                      onDeleteGateClick(item)
                                    }}
                                  >
                                    {/* eslint-disable */}
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen027.svg"
                                      className="svg-icon-3"
                                    />
                                    {/* eslint-enable */}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className='text-center py-3'>
                            <td colSpan='100%'>No record(s) found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  getCRM: state && state.crmStore && state.crmStore.getCRM,
  loading: state && state.crmStore && state.crmStore.loading,
  processorLoading: state && state.processorStore && state.processorStore.processorLoading,
  processorData: state && state.processorStore && state.processorStore.processorData,
  paymentLoading: state && state.paymentStore && state.paymentStore.paymentLoading,
  paymentData: state && state.paymentStore && state.paymentStore.paymentData,

  statusACCRM:
    state && state.addclientCRMStore && state.addclientCRMStore.statusACCRM,
  messageACCRM:
    state && state.addclientCRMStore && state.addclientCRMStore.messageACCRM,
  statusACPR:
    state &&
    state.addclientProcessorStore &&
    state.addclientProcessorStore.statusACPR,
  messageACPR:
    state &&
    state.addclientProcessorStore &&
    state.addclientProcessorStore.messageACPR,
  statusACGA:
    state &&
    state.addclientGatewayStore &&
    state.addclientGatewayStore.statusACGA,
  messageACGA:
    state &&
    state.addclientGatewayStore &&
    state.addclientGatewayStore.messageACGA

})

const mapDispatchToProps = (dispatch) => ({
  getCRMDispatch: (params, headers) => dispatch(crmActions.getCRM(params, headers)),
  getProcessorDispatch: (params, headers) => dispatch(processorActions.getProcessor(params, headers)),
  getPaymentDispatch: (params, headers) => dispatch(paymentActions.getPayment(params, headers)),
  addclientCRMDispatch: (data, headers) =>
    dispatch(addclientCRMActions.addclientCRM(data, headers)),
  clearaddclientCRMDispatch: () =>
    dispatch(addclientCRMActions.clearaddclientCRM()),
  addclientGatewayDispatch: (data, headers) =>
    dispatch(addclientGatewayActions.addclientGateway(data, headers)),
  clearaddclientGatewayDispatch: () =>
    dispatch(addclientGatewayActions.clearaddclientGateway()),
  addclientProcessorDispatch: (data, headers) =>
    dispatch(addclientProcessorActions.addclientProcessor(data, headers)),
  clearaddclientProcessorDispatch: () =>
    dispatch(addclientProcessorActions.clearaddclientProcessor())
})

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationForm)