import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "../../styles/dashboard/posts.scss";
import {
  DATE,
  STATUS_RESPONSE,
  REGEX,
  ALERT_TYPE,
  NOTIFICATION_TYPE,
  API,
  SET_STORAGE,
  ISSUER_URL,
  ISSUER_ALERT_URL
} from "../../utils/constants";
import { DateSelector } from "../../theme/layout/components/DateSelector";
import { connect } from "react-redux";
import { issuerbankPostCaseActions } from "../../store/actions/issuerBank";
import validate from "./validation";
import moment from "moment";
import _ from "lodash";
import IssuerBankList from "../../components/issuerBank/issuerBank";
import {
  warningAlert,
  confirmAlert
} from "../../utils/alerts"
import { setLocalStorage, getLocalStorage } from '../../utils/helper'

const CreateCase = (props) => {
  const {
    postCaseDispatch,
    className,
    statusIBPC,
    messageIBPC,
    loadingIBPC,
    dataIBPC,
    clearPostCaseDispatch,
  } = props;

  const pathUrl = new URL(window.location.href);
  const query = pathUrl && pathUrl.search.split("?");
  const apiKeyParam = query && query[1];
  const apiParam = apiKeyParam && apiKeyParam.split("apiKey=")
  const apiParamId = apiParam && apiParam[1]
  apiParamId && setLocalStorage(API.API_KEY, apiParamId);
  apiParamId && setLocalStorage(SET_STORAGE.SET_USER_ROLE, 'Client User');

  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': apiParamId ? apiParamId : getLocalStorage(API.API_KEY)
  }

  const search = useLocation().search;
  const bankQuery = new URLSearchParams(search);
  const paramField = bankQuery.get("bank_id");
  const [show, setShow] = useState(false);
  const [showList, setShowList] = useState(true);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({
    issuerBankId: paramField,
    alertDate: null,
    alertType: "",
    notificationType: "",
    transactionType: "",
    upiTransactionId: "",
    cardFirstSixDigits: "",
    cardLastFourDigits: "",
    customerFirstName: "",
    customerLastName: "",
    alertDescriptor: "",
    alertDescriptorContact: "",
  });
  const [displayInvidualInquiryDetails, setDisplayInvidualInquiryDetails] = useState(false);

  const handleSubmit = () => {
    const errorMsg = validate(formData, setErrors);
    const getPaylod = {
      alertDate: moment(formData.alertDate).format(DATE.DATE_FORMAT),
      issuerBankId: paramField,
      alertType: formData.alertType,
      notificationType: formData.notificationType,
      transactionType: formData.transactionType,
      upiTransactionId: formData.upiTransactionId,
      cardFirstSixDigits: formData.cardFirstSixDigits,
      cardLastFourDigits: formData.cardLastFourDigits,
      customerFirstName: formData.customerFirstName,
      customerLastName: formData.customerLastName,
      alertDescriptor: formData.alertDescriptor,
      alertDescriptorContact: formData.alertDescriptorContact,
    };
    if (_.isEmpty(errorMsg)) {
      const data = _.pickBy(getPaylod);
      postCaseDispatch(data, headercurrentWithApiKey);
    }
  };

  const handleReset = () => {
    setFormData({
      alertDate: null,
      alertType: "",
      notificationType: "",
      transactionType: "",
      upiTransactionId: "",
      cardFirstSixDigits: "",
      cardLastFourDigits: "",
      customerFirstName: "",
      customerLastName: "",
      alertDescriptor: "",
      alertDescriptorContact: "",
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setErrors({ ...errors, [e.target.name]: "" });
    if (name === "transactionType") {
      if (value === "UPI") {
        setFormData({
          ...formData,
          [name]: value,
          cardFirstSixDigits: "",
          cardLastFourDigits: "",
        });
        setErrors({
          ...errors,
          [name]: "",
          cardFirstSixDigits: "",
          cardLastFourDigits: "",
        });
      } else if (value === "Card") {
        setFormData({ ...formData, [name]: value, upiTransactionId: "" });
        setErrors({ ...errors, [name]: "", upiTransactionId: "" });
      }
    } else if (name === "alertType") {
      if (value === "Inquiry") {
        setFormData({
          ...formData,
          [name]: value,
          notificationType: "",
        });
        setErrors({ ...errors, notificationType: "", alertType: "" });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onConfirm = () => {
    const data = dataIBPC && dataIBPC.data ? dataIBPC.data : {};
    setShowList(false)
    setShow(true);
    setData(data);
    handleReset();
  }

  useEffect(() => {
    if (statusIBPC === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'Success',
        messageIBPC,
        'success',
        'Ok',
        () => { onConfirm() },
        () => { onConfirm() },
      )
      clearPostCaseDispatch();
    } else if (statusIBPC === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        "Error",
        messageIBPC,
        "error",
        "Close",
        "Ok",
        () => {
          onConfirm();
        },
        () => { }
      );
      clearPostCaseDispatch();
    }
  }, [statusIBPC]);

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  const handleShow = () => {
    // setShow(false)
    // setShowList(true)
    // window.location.reload();
    window.open(`${ISSUER_URL}/chargeback-management/add-chargeback`,'_blank')
  }

  const handleResolve = () => {
    window.open(`${ISSUER_ALERT_URL}`,'_blank')
  }

  const createInquiryAlert = () => {
    if (show) {
      setShow(!show);
      setShowList(!showList);
    }
    else {
      setDisplayInvidualInquiryDetails(!displayInvidualInquiryDetails);
    }
  }

  return (
    <>
      <div className="container-fixed">
        <div className="row accordion">
          <div className="col-md-12">
            <div
              className="accordion md-accordion accordion-2"
              id="accordionEx7"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="card bg-skyBlue">
                <div
                  className="card-header rgba-stylish-strong z-depth-1 mb-1"
                  role="tab"
                  id="heading2"
                >
                  <div className="col-lg-6">
                    <h5 className="text-dark fw-bolder fs-3 mt-8">Inquiries</h5>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordionEx7"
                          href="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        > */}
                          <button className="btn btn-dark btn-sm mt-5" onClick={createInquiryAlert}>
                            Create Inquiry Alert &nbsp;
                            <i className="fas fa-angle-down rotate-icon" />
                          </button>
                        {/* </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                {displayInvidualInquiryDetails ? (
                  <div
                    // id="collapse2"
                    // className="collapse"
                    // role="tabpanel"
                    aria-labelledby="heading2"
                    data-parent="#accordionEx7"
                  >
                    <div className="App h-100">
                      <div className="card-header bg-skyBlue py-10 px-5 h-100">
                        <div className="card-body px-5">
                          <div className="w-90 mx-auto">
                            {!show ? (
                              <>
                                <div className="form-group row mx-auto">
                                  <h2 className="mb-10">Create Inquiry Alert</h2>
                                  <div className="me-2">
                                    <p className="text-dark fw-bolder fs-4">
                                      ALERT INFORMATION:
                                    </p>
                                  </div>
                                  <div className="form-group row mb-6">
                                    <div className="col-lg-4 mb-3">
                                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                        Alert Date :
                                      </label>
                                      <div className="col-lg-12">
                                        <DateSelector
                                          name="alertDate"
                                          placeholder="Alert Date"
                                          className="form-control"
                                          selected={formData.alertDate || ""}
                                          maxDate={new Date()}
                                          onChange={(date) => {
                                            setErrors({
                                              ...errors,
                                              alertDate: "",
                                            });
                                            setFormData((values) => ({
                                              ...values,
                                              alertDate: date,
                                            }));
                                          }}
                                          dateFormat={DATE.DATE_FOR_PICKER}
                                        />
                                      </div>
                                      {errors && errors.alertDate && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red;}"}</style>
                                          {errors.alertDate}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                        Alert Type:
                                      </label>
                                      <div className="col-lg-12">
                                        <select
                                          name="alertType"
                                          className="form-select form-select-solid"
                                          data-control="select"
                                          data-placeholder="Select an option"
                                          data-allow-clear="true"
                                          onChange={(e) => handleChange(e)}
                                          value={formData.alertType || ""}
                                        >
                                          <option value={ALERT_TYPE.SELECT}>
                                            Select...{" "}
                                          </option>
                                          <option value={ALERT_TYPE.INQUIERY}>
                                            Inquiry
                                          </option>
                                          <option value={ALERT_TYPE.NOTIFICATION}>
                                            Notification
                                          </option>
                                        </select>
                                      </div>
                                      {errors && errors.alertType && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red;}"}</style>
                                          {errors.alertType}
                                        </div>
                                      )}
                                    </div>
                                    {formData &&
                                      formData.alertType === "Notification" ? (
                                      <div className="col-lg-4 mb-3">
                                        <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                          Notification Type:
                                        </label>
                                        <div className="col-lg-12">
                                          <select
                                            name="notificationType"
                                            className="form-select form-select-solid"
                                            data-control="select"
                                            data-placeholder="Select an option"
                                            data-allow-clear="true"
                                            onChange={handleChange}
                                            value={formData.notificationType}
                                          >
                                            <option
                                              value={NOTIFICATION_TYPE.SELECT}
                                            >
                                              Select...{" "}
                                            </option>
                                            <option
                                              value={
                                                NOTIFICATION_TYPE.BLANK_SPACE
                                              }
                                            >
                                              {NOTIFICATION_TYPE.BLANK_SPACE}
                                            </option>
                                            <option
                                              value={
                                                NOTIFICATION_TYPE.CANCEL_REQURRING
                                              }
                                            >
                                              {NOTIFICATION_TYPE.CANCEL_REQURRING}
                                            </option>
                                            <option
                                              value={NOTIFICATION_TYPE.CHARGEBACK}
                                            >
                                              {NOTIFICATION_TYPE.CHARGEBACK}
                                            </option>
                                            <option
                                              value={
                                                NOTIFICATION_TYPE.EXCEPTION_UNKOWN
                                              }
                                            >
                                              {NOTIFICATION_TYPE.EXCEPTION_UNKOWN}
                                            </option>
                                            <option
                                              value={
                                                NOTIFICATION_TYPE.FRAUD_ALERT
                                              }
                                            >
                                              {NOTIFICATION_TYPE.FRAUD_ALERT}
                                            </option>
                                          </select>
                                        </div>
                                        {errors && errors.notificationType && (
                                          <div className="rr mt-1">
                                            <style>{".rr{color:red;}"}</style>
                                            {errors.notificationType}
                                          </div>
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                  <p className="text-dark fw-bolder fs-4">
                                    TRANSACTION INFORMATION:
                                  </p>
                                  <div className="form-group row mb-6">
                                    <div className="col-lg-4 mb-3">
                                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                        Transaction Type:
                                      </label>
                                      <div className="col-lg-12">
                                        <select
                                          name="transactionType"
                                          className="form-select form-select-solid"
                                          data-control="select"
                                          data-placeholder="Select an option"
                                          data-allow-clear="true"
                                          onChange={(e) => handleChange(e)}
                                          value={formData.transactionType}
                                        >
                                          <option value="">Select...</option>
                                          <option value="UPI">
                                            UPI Transaction
                                          </option>
                                          <option value="Card">
                                            Card Transaction
                                          </option>
                                        </select>
                                      </div>
                                      {errors && errors.transactionType && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red;}"}</style>
                                          {errors.transactionType}
                                        </div>
                                      )}
                                    </div>
                                    {formData &&
                                      formData.transactionType === "UPI" ? (
                                      <div className="col-lg-4 mb-3">
                                        <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                          UPI Transaction ID :
                                        </label>
                                        <div className="col-lg-12">
                                          <input
                                            name="upiTransactionId"
                                            value={
                                              formData.upiTransactionId || ""
                                            }
                                            type="text"
                                            className="form-control"
                                            placeholder="UPI Transaction ID"
                                            autoComplete="off"
                                            onBlur={(e) =>
                                              handleTrimWhiteSpace(e, setFormData)
                                            }
                                            onChange={(e) => handleChange(e)}
                                            maxLength={42}
                                            onPaste={(e) => {
                                              e.preventDefault();
                                              return false;
                                            }}
                                            onCopy={(e) => {
                                              e.preventDefault();
                                              return false;
                                            }}
                                            onKeyPress={(e) => {
                                              if (
                                                !REGEX.ALPHA_NUMERIC_CHARS.test(
                                                  e.key
                                                )
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                        </div>
                                        {errors && errors.upiTransactionId && (
                                          <div className="rr mt-1">
                                            <style>{".rr{color:red;}"}</style>
                                            {errors.upiTransactionId}
                                          </div>
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                  {formData &&
                                    formData.transactionType === "Card" ? (
                                    <div className="form-group row mb-6">
                                      <div className="col-lg-4 mb-5">
                                        <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                          Card First 6 Digit:
                                        </label>
                                        <div className="col-lg-12">
                                          <input
                                            name="cardFirstSixDigits"
                                            value={
                                              formData.cardFirstSixDigits || ""
                                            }
                                            type="text"
                                            className="form-control"
                                            placeholder="Card First 6 Digit"
                                            autoComplete="off"
                                            onBlur={(e) =>
                                              handleTrimWhiteSpace(e, setFormData)
                                            }
                                            maxLength={6}
                                            onChange={(e) => handleChange(e)}
                                            onPaste={(e) => {
                                              e.preventDefault();
                                              return false;
                                            }}
                                            onCopy={(e) => {
                                              e.preventDefault();
                                              return false;
                                            }}
                                            onKeyPress={(e) => {
                                              if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                        </div>
                                        {errors && errors.cardFirstSixDigits && (
                                          <div className="rr mt-1">
                                            <style>{".rr{color:red;}"}</style>
                                            {errors.cardFirstSixDigits}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-4 mb-5">
                                        <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                          Card Last 4 Digit:
                                        </label>
                                        <div className="col-lg-12">
                                          <input
                                            name="cardLastFourDigits"
                                            value={
                                              formData.cardLastFourDigits || ""
                                            }
                                            type="text"
                                            className="form-control"
                                            placeholder="Card Last 4 Digit"
                                            autoComplete="off"
                                            onBlur={(e) =>
                                              handleTrimWhiteSpace(e, setFormData)
                                            }
                                            maxLength={4}
                                            onChange={(e) => handleChange(e)}
                                            onPaste={(e) => {
                                              e.preventDefault();
                                              return false;
                                            }}
                                            onCopy={(e) => {
                                              e.preventDefault();
                                              return false;
                                            }}
                                            onKeyPress={(e) => {
                                              if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                          {errors && errors.cardLastFourDigits && (
                                            <div className="rr mt-1">
                                              <style>{".rr{color:red;}"}</style>
                                              {errors.cardLastFourDigits}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="me-2">
                                    <p className="text-dark fw-bolder fs-4">
                                      CUSTOMER INFORMATION:
                                    </p>
                                  </div>
                                  <div className="form-group row mb-6">
                                    <div className="col-lg-4 mb-3">
                                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                        Customer First Name :
                                      </label>
                                      <div className="col-lg-12">
                                        <input
                                          name="customerFirstName"
                                          value={formData.customerFirstName || ""}
                                          type="text"
                                          className="form-control"
                                          placeholder="Customer First Name"
                                          onBlur={(e) =>
                                            handleTrimWhiteSpace(e, setFormData)
                                          }
                                          onChange={(e) => handleChange(e)}
                                          maxLength={42}
                                          autoComplete="off"
                                          onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onCopy={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onKeyPress={(e) => {
                                            if (!REGEX.TEXT.test(e.key)) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                      {errors && errors.customerFirstName && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red;}"}</style>
                                          {errors.customerFirstName}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                        Customer Last Name :
                                      </label>
                                      <div className="col-lg-12">
                                        <input
                                          name="customerLastName"
                                          value={formData.customerLastName || ""}
                                          type="text"
                                          className="form-control"
                                          placeholder="Customer Last Name"
                                          onBlur={(e) =>
                                            handleTrimWhiteSpace(e, setFormData)
                                          }
                                          onChange={(e) => handleChange(e)}
                                          maxLength={42}
                                          autoComplete="off"
                                          onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onCopy={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onKeyPress={(e) => {
                                            if (!REGEX.TEXT.test(e.key)) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                      {errors && errors.customerLastName && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red;}"}</style>
                                          {errors.customerLastName}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="me-2">
                                    <p className="text-dark fw-bolder fs-4">
                                      DESCRIPTOR INFORMATION:
                                    </p>
                                  </div>
                                  <div className="form-group row mb-6">
                                    <div className="col-lg-4 mb-3">
                                      <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                                        Descriptor :
                                      </label>
                                      <div className="col-lg-12">
                                        <input
                                          name="alertDescriptor"
                                          value={formData.alertDescriptor || ""}
                                          type="text"
                                          className="form-control"
                                          placeholder="Descriptor"
                                          autoComplete="off"
                                          onBlur={(e) =>
                                            handleTrimWhiteSpace(e, setFormData)
                                          }
                                          onChange={(e) => handleChange(e)}
                                          maxLength={15}
                                          onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onCopy={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onKeyPress={(e) => {
                                            if (
                                              !REGEX.ALPHA_NUMERIC_CHARS.test(
                                                e.key
                                              )
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                      {errors && errors.alertDescriptor && (
                                        <div className="rr mt-1">
                                          <style>{".rr{color:red;}"}</style>
                                          {errors.alertDescriptor}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                      <label className="font-size-xs font-weight-bold mb-2 form-label">
                                        Descriptor Contact Number :
                                      </label>
                                      <div className="col-lg-12">
                                        <input
                                          name="alertDescriptorContact"
                                          value={
                                            formData.alertDescriptorContact || ""
                                          }
                                          type="text"
                                          className="form-control"
                                          placeholder="Descriptor Contact Number"
                                          autoComplete="off"
                                          onBlur={(e) =>
                                            handleTrimWhiteSpace(e, setFormData)
                                          }
                                          onChange={(e) => handleChange(e)}
                                          maxLength={10}
                                          onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onCopy={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onKeyPress={(e) => {
                                            if (!REGEX.NUMERIC.test(e.key)) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-end mb-10">
                                    <button
                                      className="btn btn-darkRed mt-5 mx-2 fa-pull-right"
                                      type="reset"
                                      onClick={() => {
                                        handleReset();
                                      }}
                                    >
                                      Reset
                                    </button>
                                    <button
                                      className="btn btn-blue mt-5 mx-2 fa-pull-right"
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      {loadingIBPC ? (
                                        <span
                                          className="spinner-border spinner-border-sm mx-3"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        "Submit"
                                      )}
                                    </button>
                                  </div>
                                </div>
                                <div className="bg-dark p-015rem" />
                                <div className="mt-5">
                                  <h6 className="mb-2">
                                    Would you like to send a Batch file to
                                    Chargebackzero? Please click the below "Export
                                    Inquiry Alert" button.
                                  </h6>
                                  <div className="d-flex justify-content-start mb-10 mt-5">
                                    <Link
                                      to={`import-inquiries?bank_id=${paramField}`}
                                      className="btn btn-blue m-2 fa-pull-right"
                                    >
                                      Export Inquiry Alert
                                    </Link>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            {show ? (
                              <div>
                                <div>
                                  <div className="row g-5 g-xl-8">
                                    <div className="col-xl-4">
                                      <div
                                        className={`card ${className} min-cardH-2`}
                                      >
                                        <div className="card-header border-0 pt-5">
                                          <h3 className="card-title align-items-start flex-column">
                                            ALERT INFORMATION
                                          </h3>
                                        </div>
                                        <div className="mx-auto mb-5">
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Alert Date : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              {data &&
                                                data.alertDetails &&
                                                data.alertDetails.alertDate
                                                ? `${moment(
                                                  data.alertDetails.alertDate
                                                ).format(DATE.DATE_FORMAT)} `
                                                : "--"}
                                            </span>
                                          </div>
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Alert Type : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              {data &&
                                                data.alertDetails &&
                                                data.alertDetails.alertType}
                                            </span>
                                          </div>
                                          {data &&
                                            data.alertDetails &&
                                            data.alertDetails &&
                                            data.alertDetails.notificationType ? (
                                            <div className="mb-4">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                Notification Type : &nbsp;
                                              </span>
                                              <span className="fw-bolder text-muted fs-6 w-120px">
                                                {data &&
                                                  data.alertDetails &&
                                                  data.alertDetails &&
                                                  data.alertDetails
                                                    .notificationType}
                                              </span>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-4">
                                      <div
                                        className={`card ${className} min-cardH-2 `}
                                      >
                                        <div className="card-header border-0 pt-5">
                                          <h3 className="card-title align-items-start flex-column">
                                            TRANSACTION INFORMATION
                                          </h3>
                                        </div>
                                        <div className="mx-auto mb-5">
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Transaction Type : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              {data &&
                                                data.transactionDetails &&
                                                data.transactionDetails &&
                                                data.transactionDetails
                                                  .transactionType}
                                            </span>
                                          </div>
                                          {data &&
                                            data.transactionDetails &&
                                            data.transactionDetails
                                              .transactionType &&
                                            data.transactionDetails
                                              .transactionType === "Card" ? (
                                            <>
                                              <div className="mb-4">
                                                <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                  Card First 6 Digit : &nbsp;
                                                </span>
                                                <span className="fw-bolder text-muted fs-6 w-120px">
                                                  {data &&
                                                    data.cardDetails &&
                                                    data.cardDetails &&
                                                    data.cardDetails
                                                      .cardFirstSixDigits}
                                                </span>
                                              </div>
                                              <div className="mb-4">
                                                <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                  Card Last 4 Digit : &nbsp;
                                                </span>
                                                <span className="fw-bolder text-muted fs-6 w-120px">
                                                  {data &&
                                                    data.cardDetails &&
                                                    data.cardDetails &&
                                                    data.cardDetails
                                                      .cardLastFourDigits}
                                                </span>
                                              </div>
                                            </>
                                          ) : (
                                            <div className="mb-4">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                UPI Transaction ID : &nbsp;
                                              </span>
                                              <span className="fw-bolder text-muted fs-6 w-120px">
                                                {data &&
                                                  data.transactionDetails &&
                                                  data.transactionDetails &&
                                                  data.transactionDetails
                                                    .upiTransactionId}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-4">
                                      <div
                                        className={`card ${className} min-cardH-2 `}
                                      >
                                        <div className="card-header border-0 pt-5">
                                          <h3 className="card-title align-items-start flex-column">
                                            CUSTOMER INFORMATION
                                          </h3>
                                        </div>
                                        <div className="mx-auto mb-5">
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Customer First Name : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              {data &&
                                                data.customer &&
                                                data.customer &&
                                                data.customer.customerFirstName}
                                            </span>
                                          </div>
                                          <div className="mb-15">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Customer Last Name : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              {data &&
                                                data.customer &&
                                                data.customer &&
                                                data.customer.customerLastName}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-4">
                                      <div
                                        className={`card ${className} min-cardH-12 h-scroll`}
                                      >
                                        <div className="card-header border-0 pt-5">
                                          <h3 className="card-title align-items-start flex-column text-uppercase">
                                            Delivery Information
                                          </h3>
                                        </div>
                                        <div className="mx-5 mb-5 p-7 pt-1">
                                          <h6 className="card-title align-items-start flex-column text-uppercase mb-2">
                                            Billing Details
                                          </h6>
                                          <div className="m-3 ">
                                            <div className="mb-4 d-flex">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                Address: &nbsp;
                                              </span>
                                              <span
                                                className="fw-bolder text-muted fs-6 w-120px"
                                                style={{ paddingLeft: 5 }}
                                              >
                                                Ms Alice Smith Apartment 1c 213
                                                Derrick Street Boston, MA 02130
                                                USA
                                              </span>
                                            </div>
                                          </div>
                                          <h6 className="card-title align-items-start flex-column text-uppercase mb-2">
                                            Shipping Details
                                          </h6>
                                          <div className="m-3 ">
                                            <div className="mb-4 d-flex">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3 pr-3">
                                                Address: &nbsp;
                                              </span>
                                              <span
                                                className="fw-bolder text-muted w-120px"
                                                style={{ paddingLeft: 5 }}
                                              >
                                                Mr James Smith Flat 7 118
                                                Blackhorse Grove London W6 7HB UK
                                              </span>
                                            </div>
                                          </div>

                                          <h6 className="card-title align-items-start flex-column text-uppercase mb-2">
                                            Physical Fullfillment
                                          </h6>
                                          <div className="m-3">
                                            <div className="mb-4">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                Shipping Corrier URL : &nbsp;
                                              </span>
                                              <span className="fw-bolder text-muted fs-6 w-120px">
                                                FedEx Corporation
                                              </span>
                                            </div>
                                            <div className="mb-4">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                Tracking Number : &nbsp;
                                              </span>
                                              <span className="fw-bolder text-muted fs-6 w-120px">
                                                AWS133.2323.234
                                              </span>
                                            </div>
                                            <div className="mb-4">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                Date of Shipment : &nbsp;
                                              </span>
                                              <span className="fw-bolder text-muted fs-6 w-120px">
                                                2022-01-10
                                              </span>
                                            </div>
                                          </div>
                                          <h6 className="card-title align-items-start flex-column text-uppercase mb-2">
                                            Digital Service
                                          </h6>
                                          <div className="m-3">
                                            <div className="mb-4">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                Proof of Delivery : &nbsp;
                                              </span>
                                              <span className="fw-bolder text-muted fs-6 w-120px">
                                                N/A
                                              </span>
                                            </div>
                                            <div className="mb-4">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                Proof of Usage: &nbsp;
                                              </span>
                                              <span className="fw-bolder text-muted fs-6 w-120px">
                                                N/A
                                              </span>
                                            </div>
                                            <div className="mb-4">
                                              <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                                Location of Usage: &nbsp;
                                              </span>
                                              <span className="fw-bolder text-muted fs-6 w-120px">
                                                192.000.123123
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-4">
                                      <div
                                        className={`card ${className} min-cardH-12 `}
                                      >
                                        <div className="card-header border-0 pt-5">
                                          <h3 className="card-title align-items-start flex-column text-uppercase">
                                            Product Information
                                          </h3>
                                        </div>
                                        <div className="mx-5 mb-5 p-7 pt-1">
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Product Name : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              GoPro HERO10 Black
                                            </span>
                                          </div>
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Product Description : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              For 1 Year extended warranty please
                                              visit gopro.luxurypersonified.co.in/
                                              Revolutionary Processor: Faster.
                                              Smoother. Better. The powerful new
                                              GP2 engine changes the...
                                            </span>
                                          </div>
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Product URL : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              https://www.amazon.in/GoPro-HERO10-Black-Waterproof-Stabilization/
                                            </span>
                                          </div>
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Currency : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              USD
                                            </span>
                                          </div>
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Amount : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              1500
                                            </span>
                                          </div>
                                          <div className="mb-15">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Qty: &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              1
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-4">
                                      <div
                                        className={`card ${className} min-cardH-12 `}
                                      >
                                        <div className="card-header border-0 pt-5">
                                          <h3 className="card-title align-items-start flex-column text-uppercase">
                                            Past Transaction Information
                                          </h3>
                                        </div>
                                        <div className="mx-5  mb-5 p-7 pt-1">
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Date of Purchase: &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              2022-01-09
                                            </span>
                                          </div>
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Amount Of Purchase : &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              150
                                            </span>
                                          </div>
                                          <div className="mb-4">
                                            <span className="text-gray-700 fw-bolder fs-5 pl-3">
                                              Credit Card Bin: &nbsp;
                                            </span>
                                            <span className="fw-bolder text-muted fs-6 w-120px">
                                              42863xxxxxxx2569
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-end mb-10 mt-5">
                                    <button
                                      className="btn btn-success fw-bolder m-2 fa-pull-right"
                                      onClick={() => {
                                        handleResolve()
                                      }}
                                    >
                                      Resolve
                                    </button>
                                    <button
                                      className="btn btn-blue fw-bolder m-2 fa-pull-right"
                                      onClick={() => {
                                        handleShow()
                                      }}
                                    >
                                      File Chargeback
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ): null }
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          {showList &&
            <IssuerBankList />
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state && state.issuerbankStore && state.issuerbankStore.loading,
    statusIB: state && state.issuerbankStore && state.issuerbankStore.statusIB,
    messageIB:
      state && state.issuerbankStore && state.issuerbankStore.messageIB,
    loadingIBPC:
      state && state.issuerbankStore && state.issuerbankStore.loadingIBPC,
    statusIBPC:
      state && state.issuerbankStore && state.issuerbankStore.statusIBPC,
    messageIBPC:
      state && state.issuerbankStore && state.issuerbankStore.messageIBPC,
    dataIBPC: state && state.issuerbankStore && state.issuerbankStore.dataIBPC,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postCaseDispatch: (data, headers) =>
    dispatch(issuerbankPostCaseActions.postCase(data, headers)),
  clearPostCaseDispatch: () =>
    dispatch(issuerbankPostCaseActions.clearPostCase()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCase);
