import {
  addCrmSettingsActionsTypes,
  getCrmSettingsDetailsActionsTypes,
  updateCrmSettingsActionsTypes,
  deleteCrmSettingsActionsTypes
} from '../actions'

export const addCrmSettingsStoreKey = 'addCrmSettingsStore'

export const addCrmSettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addCrmSettingsActionsTypes.REQUEST:
      return { ...state, loadingAddCrm: true }
    case addCrmSettingsActionsTypes.RESPONSE:
      return {
        ...state,
        addCrmSettings: res.data,
        statusAddCrm: res.status,
        messageAddCrm: res.message,
        loadingAddCrm: false
      }
    case addCrmSettingsActionsTypes.CLEAR:
      return {
        ...state,
        addCrmSettings: null,
        statusAddCrm: '',
        messageAddCrm: ''
      }
    default:
      return state
  }
}

export const getCrmSettingsDetailsStoreKey = 'getCrmSettingsDetailsStore'

export const getCrmSettingsDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getCrmSettingsDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsCrm: true }
    case getCrmSettingsDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsCrmSettings: res.data,
        statusGetDetailsCrm: res.status,
        messageGetDetailsCrm: res.message,
        loadingGetDetailsCrm: false
      }
    case getCrmSettingsDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsCrmSettings: null,
        statusGetDetailsCrm: '',
        messageGetDetailsCrm: ''
      }
    default:
      return state
  }
}

export const updateCrmSettingsStoreKey = 'updateCrmSettingsStore'

export const updateCrmSettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updateCrmSettingsActionsTypes.REQUEST:
      return { ...state, loadingUpdateCrm: true }
    case updateCrmSettingsActionsTypes.RESPONSE:
      return {
        ...state,
        updateCrmSettings: res.data,
        statusUpdateCrm: res.status,
        messageUpdateCrm: res.message,
        loadingUpdateCrm: false
      }
    case updateCrmSettingsActionsTypes.CLEAR:
      return {
        ...state,
        updateCrmSettings: null,
        statusUpdateCrm: '',
        messageUpdateCrm: ''
      }
    default:
      return state
  }
}

export const deleteCrmSettingsStoreKey = 'deleteCrmSettingsStore'

export const deleteCrmSettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteCrmSettingsActionsTypes.REQUEST:
      return { ...state, loadingDeleteCrm: true }
    case deleteCrmSettingsActionsTypes.RESPONSE:
      return {
        ...state,
        deleteCrmSettings: res.data,
        statusDeleteCrm: res.status,
        messageDeleteCrm: res.message,
        loadingDeleteCrm: false
      }
    case deleteCrmSettingsActionsTypes.CLEAR:
      return {
        ...state,
        deleteCrmSettings: null,
        statusDeleteCrm: '',
        messageDeleteCrm: ''
      }
    default:
      return state
  }
}
