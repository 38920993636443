import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, Link } from "react-router-dom";
import _ from 'lodash'
import { toAbsoluteUrl } from '../../../theme/helpers'
import { warningAlert, confirmAlert } from "../../../utils/alerts";
import { STATUS_RESPONSE, CLIENT_SESSION } from '../../../utils/constants'
import { getLocalStorage } from "../../../utils/helper";
import ClientDetails from './clientDetails'
import MerchantDetails from './merchantDetails'
import {
  getClientSignupActions,
  postClientSignUpActions,
  clientcrmActions
} from "../../../store/actions";
import { Modal } from '../../../theme/layout/components/modal'

const ContactDetails = (props) => {
  const {
    clearaddClientSignupDispatch,
    statusAddClientSignup,
    messageAddClientSignup,
    getClientSignupDispatch,
    getClientCrmActions,
    getClientSignup,
    getClientCrmDetails
  } = props

  const [show, setShow] = useState(false)
  const clientSignupId = getLocalStorage(CLIENT_SESSION.CLIENT_TOKEN);
  const onConfirm = () => {
    getClientSignupDispatch(clientSignupId)
    const collapseProfileBtnRef = document.getElementById("navProfileTab");
    collapseProfileBtnRef && collapseProfileBtnRef.click();
    if (messageAddClientSignup === "Registration completed") {
      setShow(true)
    }
  }
  let clientId = getClientSignup?.Client?._id
  let {storeName,crmId} = getClientCrmDetails
  useEffect(()=>{
    clientId && getClientCrmActions(clientId)
  },[clientId])
  useEffect(() => {
    if (statusAddClientSignup === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'Success',
        messageAddClientSignup,
        'success',
        'Ok',
        () => { onConfirm() },
        () => { onConfirm() }
      )
      clearaddClientSignupDispatch()
    } else if (statusAddClientSignup === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'Error',
        messageAddClientSignup,
        '',
        'Ok'
      )
      clearaddClientSignupDispatch()
      getClientSignupDispatch(clientSignupId)
    }
  }, [statusAddClientSignup])
  return (
    <>
      {show ? (
        <>
          <Modal showModal={show} modalWidth={600}>
            <div
              className=''
              id='processorModal'
            >
              <div className="modal-header text-center" >
                <p className="mt-5 fw-bolder fs-2 w-100">
                  Welcome to ChargebackZero
                </p>
              </div>
              <div className="modal-body p-5">
                <div className="d-flex justify-content-center">
                  <a href="#">
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/illustrations/sigma-1/7-dark.png"
                      )}
                      className="w-350px h-300px"
                    />
                  </a>
                </div>
                <div className="row">
                  <div className="col-3">
                    <p></p>
                    <p className="text-center">
                      <i className="fas fa-gift fa-5x fw-bolder" style={{ color: '#50cd89' }}></i>
                    </p>
                  </div>
                  <div className="col-9 mt-6">
                    <h3 className='fw-bolder'>You have now become a part of ChargebackZero.</h3>
                    <h3 className='mt-3'>
                      <strong>Happy to Co-ordinate and happy to Onboard you.</strong>
                    </h3>
                  </div>
                </div>
                <div className="modal-footer flex-center mt-2 mb-3">
                  <Link  to={`/login?v=${crmId&&crmId.crmName.toString().toLowerCase()}&shop=${process.env.REACT_APP_SHOPIFY_SERVICE_URL}&shopname=${storeName}`} className="btn btn-success">
                    Let's Go
                  </Link>
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : null}
      <body
        className='d-flex flex-column flex-column-fluid bg-shadow'
      >
        <div className='d-flex justify-content-center ms-10'>
          <a href='#' className='mb-5 mt-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-1.svg')}
              style={{ width: '253px', filter: 'drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)' }}
            />
          </a>
        </div>
        <div className='row mb-20 d-flex justify-content-center gx-0'>
          <div className="col-lg-10">
            <div className="row">
              <div className="col-xs-6">
                <nav>
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a className="nav-item nav-link active fw-bolder fs-4" id="navHomeTab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Contact Details</a>
                    <a className="nav-item nav-link fw-bolder fs-4" id="navProfileTab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Merchant Details</a>
                  </div>
                </nav>
                <div className="tab-content tab-css py-3 px-10 mt-5" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="navHomeTab">
                    <ClientDetails />
                  </div>
                  <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="navProfileTab">
                    <MerchantDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )

}

const mapStateToProps = (state) => {
  const { addClientSignupStore, getClientSignupStore,clientCrmDetailStore } = state;
  return {
    getClientCrmDetails : clientCrmDetailStore && clientCrmDetailStore.clientcrmDetails ? clientCrmDetailStore.clientcrmDetails:{},
    getClientSignup: getClientSignupStore && getClientSignupStore.getClientSignup ? getClientSignupStore.getClientSignup : {},
    statusClientSignup: getClientSignupStore && getClientSignupStore.statusClientSignup ? getClientSignupStore.statusClientSignup : '',
    messageClientSignup: getClientSignupStore && getClientSignupStore.messageClientSignup ? getClientSignupStore.messageClientSignup : '',
    loadingClientSignup: getClientSignupStore && getClientSignupStore.loadingClientSignup ? getClientSignupStore.loadingClientSignup : false,
    addClientSignup: addClientSignupStore && addClientSignupStore.addClientSignup ? addClientSignupStore.addClientSignup : [],
    statusAddClientSignup: addClientSignupStore && addClientSignupStore.statusAddClientSignup ? addClientSignupStore.statusAddClientSignup : '',
    messageAddClientSignup: addClientSignupStore && addClientSignupStore.messageAddClientSignup ? addClientSignupStore.messageAddClientSignup : '',
    loadingAddClientSignup: addClientSignupStore && addClientSignupStore.loadingAddClientSignup ? addClientSignupStore.loadingAddClientSignup : false,
    didMount:
      getClientSignupStore && getClientSignupStore.didMount
        ? getClientSignupStore.didMount
        : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientSignupDispatch: (id) => dispatch(getClientSignupActions.get(id)),
  getClientSignupActions: () => dispatch(getClientSignupActions.get()),
  getClientCrmActions:(id)=>dispatch(clientcrmActions.getClientCrm(id)),
  clearClientSignupDispatch: () =>
    dispatch(getClientSignupActions.clear()),
  addClientSignupDispatch: (payload) =>
    dispatch(postClientSignUpActions.add(payload)),
  clearaddClientSignupDispatch: () =>
    dispatch(postClientSignUpActions.clear()),
  didMountActions: (val) => dispatch(getClientSignupActions.didMount(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails)