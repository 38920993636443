import {
  getMenuCompActionsTypes
} from '../actions'

export const getMenuCompStoreKey = 'getMenuCompStore'

export const getMenuCompReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getMenuCompActionsTypes.REQUEST:
      return { ...state, loadingGMC: true }
    case getMenuCompActionsTypes.RESPONSE:
      return {
        ...state,
        getMenuComp: res.data,
        count: res.count,
        statusGMC: res.status,
        messageGMC: res.message,
        loadingGMC: false
      }
    case getMenuCompActionsTypes.CLEAR:
      return {
        ...state,
        getMenuComp: null,
        count: 0,
        statusGMC: '',
        messageGMC: ''
      }
    default:
      return state
  }
}
