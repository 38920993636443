import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  getPartnerActionTypes,
  partnerCredFilterActions,
  clientCredFilterActionsTypes,
  clientCredFilterActions,
  merchantCredFilterActionsTypes,
  merchantCredFilterActions,
  midCredFilterActionsTypes,
  midCredFilterActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchFilterPartner (actions) {
  try {
    const { params, headers } = actions
    const json = yield axiosInstance.get(serviceList.getPartner, { params, headers: headers })
    if (json.status === 200) {
      yield put(partnerCredFilterActions.saveAuthPartnerResponse(json.data))
    } else {
      yield put(partnerCredFilterActions.saveAuthPartnerResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPartnerActionTypes.SAVE_FILTERS_PARTNER_RESPONSE, data })
  }
}

function * fetchCredFilterClient (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.getClient, { params, headers: headers  })
    if (json.status === 200) {
      yield put(clientCredFilterActions.saveFilterClientResponse(json.data))
    } else {
      yield put(clientCredFilterActions.saveFilterClientResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientCredFilterActionsTypes.SAVE_FILTER_CLIENT_RESPONSE, data })
  }
}

function * fetchCredFilterMerchant (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.getMerchant, { params, headers: headers  })
    if (json.status === 200) {
      yield put(merchantCredFilterActions.savemerchantFilterResponse(json.data))
    } else {
      yield put(merchantCredFilterActions.savemerchantFilterResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: merchantCredFilterActionsTypes.SAVE_MERCHANT_FILETER_RESPONSE, data })
  }
}

function * fetchCredFilterMidCRM (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.MidCRM, { params, headers: headers  })
    if (json.status === 200) {
      yield put(midCredFilterActions.savemidFilterResponse(json.data))
    } else {
      yield put(midCredFilterActions.savemidFilterResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: midCredFilterActionsTypes.SAVE_MID_FILTER_RESPONSE, data })
  }
}

export function * fetchFilterPartnerWatcher () {
  yield all([
    yield takeLatest(getPartnerActionTypes.GET_FILTERS_PARTNER, fetchFilterPartner),
    yield takeLatest(clientCredFilterActionsTypes.GET_FILTER_CLIENT, fetchCredFilterClient),
    yield takeLatest(merchantCredFilterActionsTypes.GET_MERCHANT_FILETER, fetchCredFilterMerchant),
    yield takeLatest(midCredFilterActionsTypes.GET_MID_FILTER, fetchCredFilterMidCRM)
  ])
}
