import {
  addMerchantSMTPTypes,
  getMerchantSMTPDetailsActionsTypes,
  addClientSMTPTypes,
  getClientSMTPActionsTypes,
  getClientSMTPDetailsActionsTypes,
  addMerchantTemplateTypes,
  variableActionsTypes,
  templateActionsTypes,
  templateGetDetailsTypes,
  editTemplateTypes,
  templateDeleteTypes,
  addGeneralTemplateTypes,
  generalTemplateActionsTypes,
  generalTemplateGetDetailsTypes,
  editGeneralTemplateTypes,
  generalTemplateDeleteTypes,
} from '../actions'

export const addMerchantSMTPInitialState = {
  list: null
}
export const addMerchantSMTPStoreKey = 'addMerchantSMTPStore'

export const addMerchantSMTPReducer = (state = addMerchantSMTPInitialState, action) => {
  switch (action.type) {
    case addMerchantSMTPTypes.REQUEST:
      return { ...state, loadingAMSMTP: true }
    case addMerchantSMTPTypes.RESPONSE:
      return { ...state, dataAMSMTP: action.data, statusAMSMTP: action.data.status, messageAMSMTP: action.data.message, loadingAMSMTP: false }
    case addMerchantSMTPTypes.CLEAR:
      return { ...state, dataAMSMTP: null, messageAMSMTP: '', statusAMSMTP: '' }
    default:
      return state
  }
}

export const getMerchantSMTPDetailsStoreKey = 'getMerchantSMTPDetailsStore'

export const getMerchantSMTPDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getMerchantSMTPDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsMerchantSMTP: true }
    case getMerchantSMTPDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsMerchantSMTP: res.data,
        statusGetDetailsMerchantSMTP: res.status,
        messageGetDetailsMerchantSMTP: res.message,
        loadingGetDetailsMerchantSMTP: false
      }
    case getMerchantSMTPDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsMerchantSMTP: null,
        statusGetDetailsMerchantSMTP: '',
        messageGetDetailsMerchantSMTP: ''
      }
    default:
      return state
  }
}

export const addClientSMTPInitialState = {
  list: null
}
export const addClientSMTPStoreKey = 'addClientSMTPStore'

export const addClientSMTPReducer = (state = addClientSMTPInitialState, action) => {
  switch (action.type) {
    case addClientSMTPTypes.REQUEST:
      return { ...state, loadingACSMTP: true }
    case addClientSMTPTypes.RESPONSE:
      return { ...state, dataACSMTP: action.data, statusACSMTP: action.data.status, messageACSMTP: action.data.message, loadingACSMTP: false }
    case addClientSMTPTypes.CLEAR:
      return { ...state, dataACSMTP: null, messageACSMTP: '', statusACSMTP: '' }
    default:
      return state
  }
}

export const getClientSMTPStoreKey = 'getClientSMTPStore'

export const getClientSMTPReducer = (state = {}, action) => {
  switch (action.type) {
    case getClientSMTPActionsTypes.REQUEST:
      return { ...state, getClientSMTPLoading: true }
    case getClientSMTPActionsTypes.RESPONSE:
      return { ...state, getClientSMTPStatus: action.status && action.status, getClientSMTPData: action.data && action.data, getClientSMTPLoading: false }
    case getClientSMTPActionsTypes.CLEAR:
      return { ...state, getClientSMTPData: null, getClientSMTPStatus: '' }
    default:
      return state
  }
}

export const getClientSMTPDetailsStoreKey = 'getClientSMTPDetailsStore'

export const getClientSMTPDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getClientSMTPDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsClientSMTP: true }
    case getClientSMTPDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsClientSMTP: res.data,
        statusGetDetailsClientSMTP: res.status,
        messageGetDetailsClientSMTP: res.message,
        loadingGetDetailsClientSMTP: false
      }
    case getClientSMTPDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsClientSMTP: null,
        statusGetDetailsClientSMTP: '',
        messageGetDetailsClientSMTP: ''
      }
    default:
      return state
  }
}

export const variableStoreKey = 'variableStore'

export const variableReducer = (state = {}, action) => {
  switch (action.type) {
    case variableActionsTypes.REQUEST:
      return { ...state, variableLoading: true }
    case variableActionsTypes.RESPONSE:
      return { ...state, variableData: action.data && action.data, variableLoading: false }
    case variableActionsTypes.CLEAR:
      return { ...state, variableData: null }
    default:
      return state
  }
}

export const addMerchantTemplateInitialState = {
  list: null
}
export const addMerchantTemplateStoreKey = 'addMerchantTemplateStore'

export const addMerchantTemplateReducer = (state = addMerchantTemplateInitialState, action) => {
  switch (action.type) {
    case addMerchantTemplateTypes.REQUEST:
      return { ...state, loadingAMTemplate: true }
    case addMerchantTemplateTypes.RESPONSE:
      return { ...state, dataAMTemplate: action.data, statusAMTemplate: action.data.status, messageAMTemplate: action.data.message, loadingAMTemplate: false }
    case addMerchantTemplateTypes.CLEAR:
      return { ...state, dataAMTemplate: null, messageAMTemplate: '', statusAMTemplate: '' }
    default:
      return state
  }
}

export const templateStoreKey = 'templateStore'

export const templateReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case templateActionsTypes.REQUEST:
      return { ...state, loadingTEM: true }
    case templateActionsTypes.RESPONSE:
      return { ...state, templateData: res.data && res.data, count: res.count, loadingTEM: false }
    case templateActionsTypes.CLEAR:
      return { ...state, templateData: null, count: 0 }
    case templateGetDetailsTypes.REQUEST:
      return { ...state, loadingTEMD: true }
    case templateGetDetailsTypes.RESPONSE:
      return {
        ...state,
        templateDetails: res && res.data,
        statusTEMD: res && res.status,
        messagesTEMD: res && res.message,
        loadingTEMD: false
      }
    case templateGetDetailsTypes.CLEAR:
      return { ...state, statusTEMD: '', messagesTEMD: '', templateDetails: null }
    default:
      return state
  }
}

export const editTemplateStoreKey = 'editTemplateStore'

export const editTemplateReducer = (state = {}, action) => {
  switch (action.type) {
    case editTemplateTypes.REQUEST:
      return { ...state, loadingETEM: true }
    case editTemplateTypes.RESPONSE:
      return { ...state, dataETEM: action.data && action.data, statusETEM: action.data.status, messageETEM: action.data.message, loadingETEM: false }
    case editTemplateTypes.CLEAR:
      return { ...state, dataETEM: null, messageETEM: '', statusETEM: '' }
    default:
      return state
  }
}

export const templateDeleteStoreKey = 'templateDeleteStore'

export const deleteTemplateReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case templateDeleteTypes.REQUEST:
      return { ...state, loadingDTEM: true }
    case templateDeleteTypes.RESPONSE:
      return {
        ...state,
        deleteTemplate: res.data,
        statusDTEM: res.status,
        messageDTEM: res.message,
        loadingDTEM: false
      }
    case templateDeleteTypes.CLEAR:
      return { ...state, deleteTemplate: null, statusDTEM: '', messageDTEM: '' }
    default:
      return state
  }
}

export const addGeneralTemplateInitialState = {
  list: null
}
export const addGeneralTemplateStoreKey = 'addGeneralTemplateStore'

export const addGeneralTemplateReducer = (state = addGeneralTemplateInitialState, action) => {
  switch (action.type) {
    case addGeneralTemplateTypes.REQUEST:
      return { ...state, loadingAGT: true }
    case addGeneralTemplateTypes.RESPONSE:
      return { ...state, dataAGT: action.data, statusAGT: action.data.status, messageAGT: action.data.message, loadingAGT: false }
    case addGeneralTemplateTypes.CLEAR:
      return { ...state, dataAGT: null, messageAGT: '', statusAGT: '' }
    default:
      return state
  }
}

export const generalTemplateStoreKey = 'generalTemplateStore'

export const generalTemplateReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case generalTemplateActionsTypes.REQUEST:
      return { ...state, loadingGT: true }
    case generalTemplateActionsTypes.RESPONSE:
      return { ...state, generalTemplateData: res.data && res.data, count: res.count, loadingGT: false }
    case generalTemplateActionsTypes.CLEAR:
      return { ...state, generalTemplateData: null, count: 0 }
    case generalTemplateGetDetailsTypes.REQUEST:
      return { ...state, loadingGT: true }
    case generalTemplateGetDetailsTypes.RESPONSE:
      return {
        ...state,
        generalTemplateDetails: res && res.data,
        statusGTD: res && res.status,
        messagesGTD: res && res.message,
        loadingGTD: false
      }
    case generalTemplateGetDetailsTypes.CLEAR:
      return { ...state, statusGTD: '', messagesGTD: '', generalTemplateDetails: '' }
    default:
      return state
  }
}

export const editGeneralTemplateStoreKey = 'editGeneralTemplateStore'

export const editGeneralTemplateReducer = (state = {}, action) => {
  switch (action.type) {
    case editGeneralTemplateTypes.REQUEST:
      return { ...state, loadingEGT: true }
    case editGeneralTemplateTypes.RESPONSE:
      return { ...state, dataEGT: action.data && action.data, statusEGT: action.data.status, messageEGT: action.data.message, loadingEGT: false }
    case editGeneralTemplateTypes.CLEAR:
      return { ...state, dataEGT: null, messageEGT: '', statusEGT: '' }
    default:
      return state
  }
}

export const generalTemplateDeleteStoreKey = 'generalTemplateDeleteStore'

export const deletegeneralTemplateReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case generalTemplateDeleteTypes.REQUEST:
      return { ...state, loadingDGT: true }
    case generalTemplateDeleteTypes.RESPONSE:
      return {
        ...state,
        deleteGeneralTemplate: res.data,
        statusDGT: res.status,
        messageDGT: res.message,
        loadingDGT: false
      }
    case generalTemplateDeleteTypes.CLEAR:
      return { ...state, deleteGeneralTemplate: null, statusDGT: '', messageDGT: '' }
    default:
      return state
  }
}