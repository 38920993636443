import {
  getPaypalActionsTypes,
  addPaypalActionsTypes,
  deleteSubscriptionActionsTypes,
  addPaypalSubscriptionHistoryActionsTypes,
  getPaypalSubscriptionHistoryTypes,
  addPayConfigActionsTypes,
  getPayConfigActionsTypes,
  getPayConfigDetailsActionsTypes,
  updatePayConfigActionsTypes
} from '../actions'

export const getPaypalStoreKey = 'getPaypalStore'

export const getPaypalReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getPaypalActionsTypes.REQUEST:
      return { ...state, loadingPay: true }
    case getPaypalActionsTypes.RESPONSE:
      return {
        ...state,
        getPaypal: res.data,
        count: res.count,
        statusPay: res.status,
        messagePay: res.message,
        loadingPay: false
      }
    case getPaypalActionsTypes.CLEAR:
      return {
        ...state,
        getPaypal: null,
        count: 0,
        statusPay: '',
        messagePay: ''
      }
    default:
      return state
  }
}

export const addPaypalStoreKey = 'addPaypalStore'

export const addPaypalReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addPaypalActionsTypes.REQUEST:
      return { ...state, loadingAddPrivi: true }
    case addPaypalActionsTypes.RESPONSE:
      return {
        ...state,
        addPaypal: res.data,
        statusAddPrivi: res.status,
        messageAddPrivi: res.message,
        loadingAddPrivi: false
      }
    case addPaypalActionsTypes.CLEAR:
      return {
        ...state,
        addPaypal: null,
        statusAddPrivi: '',
        messageAddPrivi: ''
      }
    default:
      return state
  }
}

export const deleteSubscriptionStoreKey = 'deleteSubscriptionStore'

export const deleteSubscriptionReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteSubscriptionActionsTypes.REQUEST:
      return { ...state, loadingDeleteSubs: true }
    case deleteSubscriptionActionsTypes.RESPONSE:
      return {
        ...state,
        deletSubscription: res.data,
        statusDeleteSubs: res.status,
        messageDeleteSubs: res.message,
        loadingDeleteSubs: false
      }
    case deleteSubscriptionActionsTypes.CLEAR:
      return {
        ...state,
        deletSubscription: null,
        statusDeleteSubs: '',
        messageDeleteSubs: ''
      }
    default:
      return state
  }
}

export const addPaypalSubscriptionHistoryStoreKey = 'addPaypalSubscriptionHistoryStore'

export const addPaypalSubscriptionHistoryReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addPaypalSubscriptionHistoryActionsTypes.REQUEST:
      return { ...state, loadingAddPaypalSubsHistory: true }
    case addPaypalSubscriptionHistoryActionsTypes.RESPONSE:
      return {
        ...state,
        addPaypalSubsHistory: res.data,
        statusAddPaypalSubsHistory: res.status,
        messageAddPaypalSubsHistory: res.message,
        loadingAddPaypalSubsHistory: false
      }
    case addPaypalSubscriptionHistoryActionsTypes.CLEAR:
      return {
        ...state,
        addPaypalSubsHistory: null,
        statusAddPaypalSubsHistory: '',
        messageAddPaypalSubsHistory: ''
      }
    default:
      return state
  }
}

export const getPaypalSubscriptionHistoryStoreKey = 'getPaypalSubscriptionHistoryStore'

export const getPaypalSubscriptionHistoryReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getPaypalSubscriptionHistoryTypes.REQUEST:
      return { ...state, loadingPaypalGetHistory: true }
    case getPaypalSubscriptionHistoryTypes.RESPONSE:
      return {
        ...state,
        getPaypalHistory: res.data,
        count: res.count,
        statusGetPaypalHistory: res.status,
        messageGetPaypalHistory: res.message,
        loadingPaypalGetHistory: false
      }
    case getPaypalSubscriptionHistoryTypes.CLEAR:
      return {
        ...state,
        getPaypalHistory: null,
        count: 0,
        statusGetPaypalHistory: '',
        messageGetPaypalHistory: ''
      }
    default:
      return state
  }
}

export const addPayConfigStoreKey = 'addPayConfigStore'

export const addPayConfigReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addPayConfigActionsTypes.REQUEST:
      return { ...state, loadingAPC: true }
    case addPayConfigActionsTypes.RESPONSE:
      return {
        ...state,
        addPayConfig: res.data,
        statusAPC: res.status,
        messageAPC: res.message,
        loadingAPC: false
      }
    case addPayConfigActionsTypes.CLEAR:
      return {
        ...state,
        addPayConfig: null,
        statusAPC: '',
        messageAPC: ''
      }
    default:
      return state
  }
}

export const getPayConfigStoreKey = 'getPayConfigStore'

export const getPayConfigReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getPayConfigActionsTypes.REQUEST:
      return { ...state, loadingGPC: true }
    case getPayConfigActionsTypes.RESPONSE:
      return {
        ...state,
        getPayConfig: res.data,
        count: res.count,
        statusGPC: res.status,
        messageGPC: res.message,
        loadingGPC: false
      }
    case getPayConfigActionsTypes.CLEAR:
      return {
        ...state,
        getPayConfig: null,
        count: 0,
        statusGPC: '',
        messageGPC: ''
      }
    default:
      return state
  }
}

export const getPayConfigDetailsStoreKey = 'getPayConfigDetailsStore'

export const getPayConfigDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getPayConfigDetailsActionsTypes.REQUEST:
      return { ...state, loadingPCD: true }
    case getPayConfigDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsPayConfig: res.data,
        statusPCD: res.status,
        messagePCD: res.message,
        loadingPCD: false
      }
    case getPayConfigDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsPayConfig: null,
        statusPCD: '',
        messagePCD: ''
      }
    default:
      return state
  }
}

export const updatePayConfigStoreKey = 'updatePayConfigStore'

export const updatePayConfigReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updatePayConfigActionsTypes.REQUEST:
      return { ...state, loadingUPC: true }
    case updatePayConfigActionsTypes.RESPONSE:
      return {
        ...state,
        updatePayConfig: res.data,
        statusUPC: res.status,
        messageUPC: res.message,
        loadingUPC: false
      }
    case updatePayConfigActionsTypes.CLEAR:
      return {
        ...state,
        updatePayConfig: null,
        statusUPC: '',
        messageUPC: ''
      }
    default:
      return state
  }
}