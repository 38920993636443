const dummy = [{
    "_id": 1,
    "isViewed": false,
    "notificationType": "info",
    "icon" : "icons/duotune/general/gen045.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "21-09-2022",
    
  },
  {
    "_id": 2,
    "isViewed": false,
    "notificationType": "warning",
    "title": "info",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "21-09-2022"
  },
  {
    "_id": 3,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts success",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "21-09-2022"
  },
  {
    "_id": 4,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "21-09-2022"
  },
  {
    "_id": 5,
    "isViewed": false,
    "notificationType": "danger",
    "icon" : "icons/duotune/general/gen040.svg",
    "title": "Alerts danger",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "21-09-2022"
  },
  {
    "_id": 6,
    "isViewed": false,
    "notificationType": "info",
    "icon" : "icons/duotune/general/gen045.svg",
    "title": "Alerts info",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "19-09-2022"
  },
  {
    "_id": 7,
    "isViewed": false,
    "notificationType": "warning",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "19-09-2022"
  },
  {
    "_id": 8,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "19-09-2022"
  },
  {
    "_id": 9,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "19-09-2022"
  },
  {
    "_id": 10,
    "isViewed": false,
    "notificationType": "danger",
    "icon" : "icons/duotune/general/gen040.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "19-09-2022"
  },
  {
    "_id": 11,
    "isViewed": false,
    "notificationType": "info",
    "icon" : "icons/duotune/general/gen045.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "18-09-2022"
  },
  {
    "_id": 12,
    "isViewed": false,
    "notificationType": "warning",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "18-09-2022"
  },
  {
    "_id": 13,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "18-09-2022"
  },
  {
    "_id": 14,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "18-09-2022"
  },
  {
    "_id": 15,
    "isViewed": false,
    "notificationType": "danger",
    "icon" : "icons/duotune/general/gen040.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "18-09-2022"
  },
  {
    "_id": 16,
    "isViewed": false,
    "notificationType": "info",
    "icon" : "icons/duotune/general/gen045.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "18-09-2022"
  },
  {
    "_id": 17,
    "isViewed": false,
    "notificationType": "warning",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "18-09-2022"
  },
  {
    "_id": 18,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "18-09-2022"
  },
  {
    "_id": 19,
    "isViewed": false,
    "notificationType": "ALERT",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "15-09-2022"
  },
  {
    "_id": 20,
    "isViewed": false,
    "notificationType": "danger",
    "icon" : "icons/duotune/general/gen040.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "15-09-2022"
  },
  {
    "_id": 21,
    "isViewed": false,
    "notificationType": "info",
    "icon" : "icons/duotune/general/gen045.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 22,
    "isViewed": false,
    "notificationType": "warning",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 23,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 24,
    "isViewed": false,
    "notificationType": "ALERT",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 25,
    "isViewed": false,
    "notificationType": "danger",
    "icon" : "icons/duotune/general/gen040.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 26,
    "isViewed": false,
    "notificationType": "info",
    "icon" : "icons/duotune/general/gen045.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 27,
    "isViewed": false,
    "notificationType": "warning",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 28,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 29,
    "isViewed": false,
    "notificationType": "danger",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 30,
    "isViewed": false,
    "notificationType": "danger",
    "icon" : "icons/duotune/general/gen040.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "12-09-2022"
  },
  {
    "_id": 31,
    "isViewed": false,
    "notificationType": "info",
    "icon" : "icons/duotune/general/gen045.svg",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "10-09-2022"
  },
  {
    "_id": 32,
    "isViewed": false,
    "notificationType": "warning",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "10-09-2022"
  },
  {
    "_id": 33,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts success",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "10-09-2022"
  },
  {
    "_id": 34,
    "isViewed": false,
    "notificationType": "ALERT",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "10-09-2022"
  },
  {
    "_id": 35,
    "isViewed": false,
    "notificationType": "danger",
    "icon" : "icons/duotune/general/gen040.svg",
    "title": "Alerts danger",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "10-09-2022"
  },
  {
    "_id": 36,
    "isViewed": false,
    "notificationType": "info",
    "icon" : "icons/duotune/general/gen045.svg",
    "title": "Alerts info",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "07-09-2022"
  },
  {
    "_id": 37,
    "isViewed": false,
    "notificationType": "warning",
    "icon" : "icons/duotune/general/gen044.svg",
    "title": "Alerts warning",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "07-09-2022"
  },
  {
    "_id": 38,
    "isViewed": false,
    "notificationType": "success",
    "icon" : "icons/duotune/general/gen037.svg",
    "title": "Alerts success",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "07-09-2022"
  },
  {
    "_id": 39,
    "isViewed": false,
    "notificationType": "ALERT",
    "title": "Alerts",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "07-09-2022"
  },
  {
    "_id": 40,
    "isViewed": false,
    "notificationType": "danger",
    "icon" : "icons/duotune/general/gen040.svg",
    "title": "Alerts danger",
    "message": "Your Alerts has been Reinitiated",
    "createdAt": "07-09-2022"
  }]

  export default dummy;