export const getClientSignupActionsTypes = {
  REQUEST: "GET_CLIENT_SIGNUP",
  RESPONSE: "SAVE_CLIENT_SIGNUP_RESPONSE",
  CLEAR: "CLEAR_CLIENT_SIGNUP",
  DID_MOUNT: "DID_MOUNT"
};

export const getClientSignupActions = {
  get: (id, headers) => ({
    type: getClientSignupActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getClientSignupActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getClientSignupActionsTypes.CLEAR
  }),
  didMount: (didMount) => ({
    type: getClientSignupActionsTypes.DID_MOUNT,
    didMount
  })
};

export const postClientSignupActionsTypes = {
  REQUEST: 'ADD_CLIENT_SIGNUP_REQUEST',
  RESPONSE: 'ADD_CLIENT_SIGNUP_RESPONSE',
  CLEAR: 'ADD_CLIENT_SIGNUP_CLEAR'
}

export const postClientSignUpActions = {
  add: (payload, headers) => {
    return {
      type: postClientSignupActionsTypes.REQUEST,
      payload, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: postClientSignupActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: postClientSignupActionsTypes.CLEAR
  })
}

export const postClientPackageActionsTypes = {
  REQUEST: 'ADD_CLIENT_PACKAGE_REQUEST',
  RESPONSE: 'ADD_CLIENT_PACKAGE_RESPONSE',
  CLEAR: 'ADD_CLIENT_PACKAGE_CLEAR'
}

export const postClientPackageActions = {
  add: (payload, headers) => {
    return {
      type: postClientPackageActionsTypes.REQUEST,
      payload, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: postClientPackageActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: postClientPackageActionsTypes.CLEAR
  })
}