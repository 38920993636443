import axios from 'axios'
import { HEADER, SESSION, API, REASON, SET_STORAGE } from '../utils/constants'
import { getLocalStorage, handleAuthFail } from '../utils/helper'

const apiToken = getLocalStorage(API.API_KEY);
const headers = {
  'X-Custom-Header': 'foobar',
  [`${HEADER.TOKEN}`]: `Bearer ${getLocalStorage(SESSION.TOKEN)}`,
  'api-key': apiToken
}

const instance = axios.create({
  timeout: 36000000
})

// Add a request interceptor
// instance.interceptors.request.use(
//   function (config) {
//     const hasApiKey = config && config.headers && config.headers[HEADER.API_KEY]
//     const isAuthorized = getLocalStorage(SESSION.TOKEN);
//     const reasonToLogout = getLocalStorage(REASON.TO_LOGOUT);
//     const getCurrentRoute = getLocalStorage(SET_STORAGE.SET_CURRENT_ROUTE);
//     const apiToken = getLocalStorage(API.API_KEY);
//     if ((isAuthorized && apiToken) || (isAuthorized && hasApiKey)) {
//       handleAuthFail(SESSION.AUTH_RESTRICTED)
//     }
//     if (getCurrentRoute !== '/login' && reasonToLogout === REASON.SESSION_NOT_EXIST) {
//       handleAuthFail(SESSION.NOT_FOUND)
//     }
//     return config
//   },
//   function (error) {
//     return Promise.reject(error)
//   }
// )

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const { data, status } = response
    // handleSessionExpiration(data)
    handleAuthFail(data && data.message)
    return { data, status }
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default instance
