import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../theme/helpers";
import { useHistory, Link } from "react-router-dom";
import color from "../../../utils/colors";
import {
  processorActions,
  getClientSignupActions,
  postClientSignUpActions,
} from "../../../store/actions";
import { setProcessorData } from "./FormData/formData";
import { processorDetailValidation } from "./validate/validation";
import ReactSelect from "../../../theme/layout/components/ReactSelect";
import {
  STATUS_RESPONSE,
  CLIENT_SESSION,
  SWEET_ALERT_MSG,
  SESSION,
} from "../../../utils/constants";
import { getLocalStorage, headersWithAuth } from "../../../utils/helper";
import { warningAlert, confirmationAlert } from "../../../utils/alerts";

const SelectProcessor = (props) => {
  const {
    getProcessorDispatch,
    processorData,
    getClientSignupDispatch,
    statusClientSignup,
    getClientSignup,
    addClientSignupDispatch,
    clearaddClientSignupDispatch,
    statusAddClientSignup,
    messageAddClientSignup,
    loadingAddClientSignup,
    didMountActions,
    didMount,
  } = props;

  const history = useHistory();
  const clientSignupId = getLocalStorage(CLIENT_SESSION.CLIENT_TOKEN);
  const [selectedProcessorOption, setSelectedProcessorOption] = useState("");
  const [processorOption, setProcessorOption] = useState();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    processorName: "",
  });

  useEffect(() => {
    getProcessorDispatch(null, headersWithAuth);
    getClientSignupDispatch(clientSignupId);
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      alignItems: "baseline",
      background: "#fff",
      minHeight: "35px",
      boxShadow: "0 0 0 1px #fff",
      borderColor: "#a888cb",
      borderStyle: "dashed",
      fontWeight: "bolder",
      borderWidth: "3px",
      borderRadius: "18px",
      "&:hover": {
        borderColor: "#89769f",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.white : color.black,
      fontWeight: "bolder",
      backgroundColor: state.isSelected ? "#A194AB" : "",
      "&:hover": {
        backgroundColor: "#A194AB",
      },
    }),
  };

  const getDefaultOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item[name],
        })
      );
      return defaultOptions;
    }
  };

  useEffect(() => {
    const data = getDefaultOptions(processorData, "processorName");
    setProcessorOption(data);
  }, [processorData]);

  const handleChangeProcessor = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedProcessorOption(selectedOption);
      setFormData((values) => ({
        ...values,
        processorName: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, processorName: "" }));
    } else {
      setSelectedProcessorOption();
      setFormData((values) => ({ ...values, processorName: "" }));
    }
  };

  useEffect(() => {
    if (statusClientSignup === STATUS_RESPONSE.SUCCESS_MSG) {
      const setData = setProcessorData(getClientSignup);
      setFormData(setData);
    }
    if (
      getClientSignup.processor &&
      getClientSignup.processor.paymentProcessorId &&
      getClientSignup.processor.paymentProcessorId.processorName !== null
    ) {
      const data = getDefaultOptions(processorData, "processorName");
      const selOption = _.filter(data, function (x) {
        if (
          getClientSignup.processor &&
          getClientSignup.processor.paymentProcessorId &&
          getClientSignup.processor.paymentProcessorId.processorName === x.value
        ) {
          return x;
        }
      });
      setSelectedProcessorOption(selOption);
    }
  }, [statusClientSignup]);

  const onErrorConfirm = () => {
    history.push("/client-signup");
  };

  useEffect(() => {
    if (statusAddClientSignup === STATUS_RESPONSE.SUCCESS_MSG) {
      clearaddClientSignupDispatch();
      history.push("/select-service");
    } else if (messageAddClientSignup === SESSION.EXPIRED_MESSAGE) {
      warningAlert(
        "Error",
        messageAddClientSignup,
        "",
        "Retrieve Token",
        () => {
          onErrorConfirm();
        },
        () => {
          onErrorConfirm();
        }
      );
      clearaddClientSignupDispatch();
    } else if (statusAddClientSignup === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAddClientSignup, "", "Ok");
      clearaddClientSignupDispatch();
    }
  }, [statusAddClientSignup]);

  const handleSubmit = () => {
    const errorMsg = processorDetailValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      const payload = {
        signUpToken: clientSignupId,
        processor: {
          processorName: formData.processorName,
        },
      };
      addClientSignupDispatch(payload);
    }
  };
  function onConfirmDelete() {
    history.push("/select-service");
  }

  const onDeleteItem = () => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.AVOID_PROCESSOR,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete();
      },
      () => {}
    );
  };

  return (
    <>
      <div className="bg-shadow">
        <div className="d-flex justify-content-center ms-10">
          <a href="#" className="mb-12 mt-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              style={{
                width: "253px",
                filter: "drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)",
              }}
            />
          </a>
        </div>
        <div className="row mb-10 gx-0">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xl-1" />
          <div className="col-lg-10 col-md-10 col-sm-10 col-xl-10 d-flex justify-content-center">
            <div className="card shadow-sm ms-5">
              <div
                className="card-header mt-5 d-flex justify-content-center"
                style={{ minHeight: "49px" }}
              >
                <h2
                  className="text mb-2 mt-5 fw-bolder"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  Choose Processor
                </h2>
              </div>
              <div className="card-body">
                <h6
                  className="fw-bold fs-5 ms-10 text-center"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  ChargebackZero uses bank-grade security to connect to your
                  payment processor
                </h6>
                <h6
                  className="fw-bold fs-5 ms-10 text-center"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  and gather your transaction data so we can win your
                  chargebacks.
                </h6>
                <div className="d-flex justify-content-center mb-3">
                  <a href="#">
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/illustrations/dozzy-1/17-dark.png"
                      )}
                      className="w-350px h-300px"
                    />
                  </a>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center">
                    <h4 className="fw-bolder">Select Processor</h4>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="col-lg-6">
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name="processorName"
                        className="basic-single"
                        classNamePrefix="select"
                        handleChangeReactSelect={handleChangeProcessor}
                        options={processorOption}
                        value={selectedProcessorOption}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4 mt-15">
                  <div className="text-center">
                    <Link
                      to={`/select-crm`}
                      className="btn btn-sm m-2 w-120px fw-bolder text-white h-40px"
                      style={{ backgroundColor: "#f56c61" }}
                    >
                      Previous
                    </Link>{" "}
                    {""}
                    {formData.processorName === "" ? (
                      <>
                        <button
                          className="btn btn-sm fw-bolder btn-primary m-2 w-120px h-40px"
                          onClick={() => {
                            onDeleteItem();
                          }}
                        >
                          {loadingAddClientSignup ? (
                            <span
                              className="spinner-border spinner-border-sm mx-3"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Save and Continue"
                          )}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-sm fw-bolder btn-primary m-2 w-120px h-40px"
                          onClick={(event) => {
                            handleSubmit(event);
                          }}
                        >
                          {loadingAddClientSignup ? (
                            <span
                              className="spinner-border spinner-border-sm mx-3"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Save and Continue"
                          )}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { addClientSignupStore, getClientSignupStore } = state;
  return {
    processorLoading:
      state && state.processorStore && state.processorStore.processorLoading,
    processorData:
      state && state.processorStore && state.processorStore.processorData,
    getClientSignup:
      getClientSignupStore && getClientSignupStore.getClientSignup
        ? getClientSignupStore.getClientSignup
        : {},
    statusClientSignup:
      getClientSignupStore && getClientSignupStore.statusClientSignup
        ? getClientSignupStore.statusClientSignup
        : "",
    messageClientSignup:
      getClientSignupStore && getClientSignupStore.messageClientSignup
        ? getClientSignupStore.messageClientSignup
        : "",
    loadingClientSignup:
      getClientSignupStore && getClientSignupStore.loadingClientSignup
        ? getClientSignupStore.loadingClientSignup
        : false,
    addClientSignup:
      addClientSignupStore && addClientSignupStore.addClientSignup
        ? addClientSignupStore.addClientSignup
        : [],
    statusAddClientSignup:
      addClientSignupStore && addClientSignupStore.statusAddClientSignup
        ? addClientSignupStore.statusAddClientSignup
        : "",
    messageAddClientSignup:
      addClientSignupStore && addClientSignupStore.messageAddClientSignup
        ? addClientSignupStore.messageAddClientSignup
        : "",
    loadingAddClientSignup:
      addClientSignupStore && addClientSignupStore.loadingAddClientSignup
        ? addClientSignupStore.loadingAddClientSignup
        : false,
    didMount:
      getClientSignupStore && getClientSignupStore.didMount
        ? getClientSignupStore.didMount
        : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProcessorDispatch: (data, headers) =>
    dispatch(processorActions.getProcessor(data, headers)),
  processorActions: (data) => dispatch(processorActions.getCRM(data)),
  getClientSignupDispatch: (id) => dispatch(getClientSignupActions.get(id)),
  getClientSignupActions: () => dispatch(getClientSignupActions.get()),
  addClientSignupDispatch: (payload) =>
    dispatch(postClientSignUpActions.add(payload)),
  clearaddClientSignupDispatch: () => dispatch(postClientSignUpActions.clear()),
  didMountActions: (val) => dispatch(getClientSignupActions.didMount(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectProcessor);
