import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  clientMgmtActions,
  addClientMgmtTypes,
  clientDetailsTypes,
  clientDetailsActions,
  clientGetDetailsTypes,
  clientGetDetailsActions,
  editClientTypes,
  editClientsActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addClientManagement (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.addClientManagement, payload, {headers:headers})
    if (json.status === 200) {
      yield put(clientMgmtActions.saveaddClientMgmtResponse(json.data))
    } else {
      yield put(clientMgmtActions.saveaddClientMgmtResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addClientMgmtTypes.RESPONSE, data })
  }
}
function * getClientDetails (action) {
  const { payload, headers } = action
  const url = `${serviceList.getClient}${payload}`
  try {
    const json = yield axiosInstance.get(url, {headers:headers})
    if (json.status === 200) {
      yield put(clientDetailsActions.saveClientDetailsResponse(json.data))
    } else {
      yield put(clientDetailsActions.saveClientDetailsResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientDetailsTypes.RESPONSE, data })
  }
}

function * clientGetDetailsDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.getClient}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(clientGetDetailsActions.saveClientDetailsResponse(json.data))
    } else {
      yield put(clientGetDetailsActions.saveClientDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientGetDetailsTypes.CLIENT_DETAILS_RESPONSE, data })
  }
}

function * editClient (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.editClient}${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(editClientsActions.saveeditClientsResponse(json.data))
    } else {
      yield put(editClientsActions.saveeditClientsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editClientTypes.RESPONSE, data })
  }
}

export function * fetchaddClientWatcher () {
  yield all([
    yield takeLatest(addClientMgmtTypes.REQUEST, addClientManagement),
    yield takeLatest(clientDetailsTypes.REQUEST, getClientDetails),
    yield takeLatest(clientGetDetailsTypes.GET_CLIENT_DETAILS, clientGetDetailsDetails),
    yield takeLatest(editClientTypes.REQUEST, editClient)
  ])
}
