import React, { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { useLocation } from 'react-router-dom'
import Dropzone, { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import {
  STATUS_RESPONSE,
  FILE_FORMAT_CB_EVIDENCE,
  DROPZONE_MESSAGES,
  API_URL,
  SESSION,
  API
} from '../../../../utils/constants'
import { addUploadsMerchantActions } from '../../../../store/actions'
import { warningAlert, confirmAlert } from '../../../../utils/alerts'
import { getLocalStorage } from '../../../../utils/helper'
import _ from 'lodash'

const StyledDiv = styled.div`
    .container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-style: dashed;
        background-color: #fafafa;
        color:#9ab6d9;
        outline: none;
        transition: border .24s ease-in-out;
    }
`
const fileHeaders = [
  { label: 'Client Name', key: 'Client Name' },
  { label: 'Merchant Name', key: 'Merchant Name' },
  { label: 'Address', key: 'Address' },
  { label: 'City', key: 'City' },
  { label: 'State', key: 'State' },
  { label: 'Country', key: 'Country' },
  { label: 'Zip', key: 'Zip' },
  { label: 'Email', key: 'Email' },
  { label: 'Phone', key: 'Phone' },
  { label: 'Contact First Name', key: 'Contact First Name' },
  { label: 'Contact Last Name', key: 'Contact Last Name' },
  { label: 'Contact Email', key: 'Contact Email' },
  { label: 'Contact Phone', key: 'Contact Phone' },
  { label: 'Mid Number', key: 'Mid Number' },
  { label: 'Descriptor Name', key: 'Descriptor Name' },
  { label: 'Descriptor Id', key: 'Descriptor Id' },
  { label: 'Bin', key: 'Bin' },
  { label: 'CA Id', key: 'CA Id' },
  { label: 'Doing Business As', key: 'Doing Business As' },
  { label: 'Store Name', key: 'Store Name' },
  { label: 'Store Type', key: 'Store Type' },
  { label: 'Signup Url', key: 'Signup Url' },
  { label: 'TOS Url', key: 'TOS Url' },
  { label: 'Checkout Url', key: 'Checkout Url' },
  { label: 'Return Policy Url', key: 'Return Policy Url' }
]

const csvData = [
  {
    "Client Name": "HSBC",
    "Merchant Name": "Global Payment Solutions",
    "Address": "1385 Vesta Drive",
    "City": "Chicago",
    "State": "Illinois",
    "Country": "United States",
    "Zip": 60636,
    "Email": "global@xyz.com",
    "Phone": 9159985533,
    "Contact First Name": "PRASANTH ANTO",
    "Contact Last Name": "Antony",
    "Contact Email": "Antony@gmail.com",
    "Contact Phone": 9585208080,
    "Mid Number": 11123,
    "Descriptor Name": "HSBC",
    "Descriptor Id": 34,
    "Bin": 34325,
    "CA Id": 12323,
    "Doing Business As": "Software industry",
    "Store Name": "GlobalHsbcService",
    "Store Type": "Service",
    "Signup Url": "https://xyz.com",
    "TOS Url": "https://xyz.com",
    "Checkout Url": "https://xyz.com",
    "Return Policy Url": "https://xyz.com"
  },
  {
    "Client Name": "Verse",
    "Merchant Name": "India Payment Solution",
    "Address": "3148 John Daniel Drive",
    "City": "Woodbury",
    "State": "Connecticut",
    "Country": "United States",
    "Zip": 6798,
    "Email": "payment@abc.com",
    "Phone": 8124301671,
    "Contact First Name": "kumutha",
    "Contact Last Name": "kala",
    "Contact Email": "kala@gmail.com",
    "Contact Phone": 9585208080,
    "Mid Number": 14112,
    "Descriptor Name": "HSBC",
    "Descriptor Id": 34,
    "Bin": 43434,
    "CA Id": 32323,
    "Doing Business As": "wepon manufacturing",
    "Store Name": "Stark Solution",
    "Store Type": "Service",
    "Signup Url": "https://abc.com",
    "TOS Url": "https://abc.com",
    "Checkout Url": "https://abc.com",
    "Return Policy Url": "https://abc.com"
  },
  {
    "Client Name": "HSBC",
    "Merchant Name": "NEFT Service",
    "Address": "4440 Lynn Street",
    "City": "Boston",
    "State": "Massachusetts",
    "Country": "United States",
    "Zip": 2110,
    "Email": "neft@service.com",
    "Phone": 9585208080,
    "Contact First Name": "sara",
    "Contact Last Name": "laxmi",
    "Contact Email": "laxmi@gmail.com",
    "Contact Phone": 9585208080,
    "Mid Number": 41123,
    "Descriptor Name": "HSBC",
    "Descriptor Id": 55,
    "Bin": 65656,
    "CA Id": 32321,
    "Doing Business As": "tailors",
    "Store Name": "Park Solution",
    "Store Type": "Service",
    "Signup Url": "https://rtz.com",
    "TOS Url": "https://rtz.com",
    "Checkout Url": "https://rtz.com",
    "Return Policy Url": "https://rtz.com"
  },
  {
    "Client Name": "HSBC",
    "Merchant Name": "Global Service",
    "Address": "1430 New Creek Road",
    "City": "Fort Payne",
    "State": "Alabama",
    "Country": "United States",
    "Zip": 35967,
    "Email": "hsbc@hsyc.com",
    "Phone": 9585268080,
    "Contact First Name": "kira",
    "Contact Last Name": "murugan",
    "Contact Email": "laxmi@gmail.com",
    "Contact Phone": 9585208080,
    "Mid Number": 11153,
    "Descriptor Name": "HSBC",
    "Descriptor Id": 56,
    "Bin": 65656,
    "CA Id": 12323,
    "Doing Business As": "communication",
    "Store Name": "Mirchi Services",
    "Store Type": "Service",
    "Signup Url": "https://myz.com",
    "TOS Url": "https://myz.com",
    "Checkout Url": "https://myz.com",
    "Return Policy Url": "https://myz.com"
  }
]

const UploadMerchant = (props) => {
  const {
    addUploadsMerchantDispatch,
    clearAddUploadsMerchantDispatch,
    statusAUMerchant,
    messageAUMerchant,
    loadingAUMerchant,
    successAUMerchant,
    failureAUMerchant,
    dataAUMerchant,
    setUploadModalShow
  } = props

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const [errors, setErrors] = useState({})
  const pathName = useLocation().pathname
  const url = pathName && pathName.split('update/')
  const id = url && url[1]
  const { getRootProps } = useDropzone()

  const [formData, setFormData] = useState({
    clientId: id,
    file: null
  })

  const handleSubmit = () => {
    addUploadsMerchantDispatch(formData.file, null)
  }

  const handleFileUpload = (files, name) => {
    setErrors({ ...errors, [name]: '' })
    let isValidFileFormat = false
    const maxFileSize = 5
    let currentFileName = ''
    const data = new FormData()
    files.forEach((item) => {
      const fileType = item && item.type
      const uploadedFileSize = item && item.size
      currentFileName = item && item.name
      isValidFileFormat = _.includes(FILE_FORMAT_CB_EVIDENCE, fileType)
      const fileSize = Number(maxFileSize) * 1024 * 1024
      if (isValidFileFormat) {
        if (uploadedFileSize < fileSize) {
          data.append('clientId', id)
          data.append('file', item)
        } else {
          setErrors({
            ...errors,
            [name]:
              `File size must below ${fileSize / 1048576
              } MB!. You file size is : ${Math.round(uploadedFileSize / 1024)}KB`
          })
        }

      } else {
        setErrors({ ...errors, [name]: DROPZONE_MESSAGES.CSV_INVALID });
      }
    })
    setFormData((values) => ({
      ...values,
      [name]: data,
      fileName: currentFileName
    }))
  }

  const downloadFiles = (uri) => {
    const link = document.createElement("a");
    const url = window.URL || window.webkitURL;
    const revokeUrlAfterSec = 1000;
    link.href = uri;
    link.download = true;
    link.target = "_self";
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => url.revokeObjectURL(link.href), revokeUrlAfterSec);
  };

  const onConfirm = () => { setUploadModalShow(false) }

  const onConfirmDownload = (failure) => {
    if (failure) {
      const downloadUrl = `${API_URL}${dataAUMerchant}`
      downloadFiles(downloadUrl)
    }
    setUploadModalShow(false)
  }

  useEffect(() => {
    if (statusAUMerchant === STATUS_RESPONSE.SUCCESS_MSG) {
      const message = `${successAUMerchant ? successAUMerchant : ''}
       ${successAUMerchant ? 'record(s) uploaded successfully!' : ''} 
       ${failureAUMerchant ? failureAUMerchant : ''} ${messageAUMerchant}`

      if (successAUMerchant) {
        confirmAlert(
          'Success',
          message,
          'success',
          `${failureAUMerchant ? 'Download' : 'OK'}`,
          () => { onConfirmDownload(failureAUMerchant) },
          () => { onConfirmDownload(failureAUMerchant) }
        )
      } else {
        warningAlert(
          'Error',
          message,
          'error',
          'Download',
          '',
          () => {
            onConfirmDownload(failureAUMerchant)
          },
          () => { }
        )
      }
      clearAddUploadsMerchantDispatch()
    } else if (statusAUMerchant === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'Error',
        messageAUMerchant,
        'error',
        'Close',
        '',
        () => {
          onConfirm()
        },
        () => { }
      )
      clearAddUploadsMerchantDispatch()
    }
  }, [statusAUMerchant])

  return (
    <>
      <div className='card-header bg-skyBlue py-10'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-lg-10 mx-auto'>
              <label className='font-size-xs col-form-label'>Upload File:</label>
              <StyledDiv {...getRootProps({ refKey: 'innerRef' })}>
                <Dropzone onDrop={acceptedFiles => handleFileUpload(acceptedFiles, 'file')}>
                  {({ getRootProps, getInputProps }) => (
                    <div className='container'>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} name='file' />
                        <p className='text-primary fw-bolder fs-6'>Click here to select files</p>
                        <aside>
                          <h4 className='text-dark fw-bolder fs-5 mt-6'>Files</h4>
                          {formData && formData.fileName
                            ? (
                              <div>{formData.fileName}</div>
                            )
                            : null}
                        </aside>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </StyledDiv>
              {errors && errors.file && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.file}</div>)}
              <div className='mt-6 d-flex justify-content-end'>
                {csvData?.length &&
                  <CSVLink
                    headers={fileHeaders}
                    data={csvData}
                    filename='Sample_file.csv'
                    target='_blank'
                    className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm m-1'
                  >
                    Download Sample File
                  </CSVLink>}
                <button className='btn btn-blue btn-sm m-1' onClick={handleSubmit}>
                  {loadingAUMerchant
                    ? (
                      <span
                        className='spinner-border spinner-border-sm mx-3'
                        role='status'
                        aria-hidden='true'
                      />
                    )
                    : (
                      'Import Merchant'
                    )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  dataAUMerchant: state && state.addUploadsMerchantStore && state.addUploadsMerchantStore.dataAUMerchant,
  statusAUMerchant: state && state.addUploadsMerchantStore && state.addUploadsMerchantStore.statusAUMerchant,
  loadingAUMerchant: state && state.addUploadsMerchantStore && state.addUploadsMerchantStore.loadingAUMerchant,
  messageAUMerchant: state && state.addUploadsMerchantStore && state.addUploadsMerchantStore.messageAUMerchant,
  failureAUMerchant: state && state.addUploadsMerchantStore && state.addUploadsMerchantStore.failureAUMerchant,
  successAUMerchant: state && state.addUploadsMerchantStore && state.addUploadsMerchantStore.successAUMerchant
})

const mapDispatchToProps = dispatch => ({
  addUploadsMerchantDispatch: (params, url) => dispatch(addUploadsMerchantActions.addUploadsMerchant(params, url)),
  clearAddUploadsMerchantDispatch: () => dispatch(addUploadsMerchantActions.clearaddUploadsMerchant())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadMerchant)
