import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../theme/helpers";
import { useHistory, Link } from "react-router-dom";
import { PayPalBtn } from "../../../theme/layout/components/paypalBtn";
import {
  getPaypalActions,
  addPaypalActions,
  addPaypalSubscriptionHistoryAction,
  getClientSignupActions,
  postClientSignUpActions,
  postClientPackageActions,
} from "../../../store/actions";
import {
  STATUS_RESPONSE,
  CLIENT_SESSION,
  SUBSCRIPTION_PACKAGE_PLAN,
  SESSION,
} from "../../../utils/constants";
import { getLocalStorage } from "../../../utils/helper";
import { warningAlert, confirmAlert } from "../../../utils/alerts";

const PackageSubs = (props) => {
  const {
    getPaypalDispatch,
    getPackages,
    getPaypal,
    getClientSignupDispatch,
    statusClientSignup,
    getClientSignup,
    clearaddClientSignupDispatch,
    statusAddClientSignup,
    messageAddClientSignup,
    postClientPackageActionsDispatch,
    clearClientPackageActionsDispatch,
    loadingAddClientSignup,
    loadingAddClientPackage,
    statusAddClientPackage,
    messageAddClientPackage,
  } = props;

  const history = useHistory();
  const clientSignupId = getLocalStorage(CLIENT_SESSION.CLIENT_TOKEN);
  const subscriptionPackagePlan = getLocalStorage(
    CLIENT_SESSION.SELECTED_SUBSCRIPTION_PACKAGE_PLAN
  );
  const selectedPackageId = getLocalStorage(
    CLIENT_SESSION.SELECTED_SUBSCRIPTION_PACKAGE
  );
  const [paypalClientId, setPaypalClientId] = useState();
  const [clientId, setClientId] = useState();
  const [show, setShow] = useState(true);
  useEffect(() => {
    getPaypalDispatch();
    getClientSignupDispatch(clientSignupId);
  }, []);

  useEffect(() => {
    if (getPaypal && !_.isEmpty(getPaypal)) {
      const getClientId =
        getPaypal &&
          getPaypal[0].credentials &&
          getPaypal[0].credentials.clientId
          ? getPaypal[0].credentials.clientId
          : "";
      setPaypalClientId(getClientId);
    }
  }, [getPaypal]);

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: clientId ? selectedPackageId : ""
    });
  };

  const paypalOnError = (err) => {
    warningAlert("Error", "An Error occured with your process", "", "Ok");
  };

  const onPayConfirms = () => {
    history.push("/contact-details");
  };

  const successAlert = () => {
    confirmAlert(
      "Success",
      "Your Subscription has been successfully completed.",
      "success",
      "Ok",
      () => {
        onPayConfirms();
      },
      () => {
        onPayConfirms();
      }
    );
  };

  const paypalOnApprove = (data, detail) => {
    const packdata = getPackages && getPackages[0] ? getPackages[0] : {};
    const payload = {
      ...data,
      ...packdata,
      clientId: clientId,
      appName: subscriptionPackagePlan,
      signupToken: clientSignupId,
    };
    if (data.subscriptionID) {
      postClientPackageActionsDispatch(payload);
    }
  };

  const handleSubscribeFreemium = () => {
    const packdata = getPackages && getPackages[0] ? getPackages[0] : {};
    const payload = {
      ...packdata,
      clientId: clientId,
      appName: subscriptionPackagePlan,
      signupToken: clientSignupId,
    };
    postClientPackageActionsDispatch(payload);
  };

  useEffect(() => {
    if (statusClientSignup === STATUS_RESPONSE.SUCCESS_MSG) {
      const currentClientId =
        getClientSignup && getClientSignup.clientId
          ? getClientSignup.clientId
          : "";

      setClientId(currentClientId);
    }
  }, [statusClientSignup]);

  const onConfirm = () => {
    history.push("/contact-details");
  };

  const onErrorConfirm = () => {
    history.push("/client-signup");
  };

  useEffect(() => {
    if (statusAddClientPackage === STATUS_RESPONSE.SUCCESS_MSG) {
      successAlert();
      clearClientPackageActionsDispatch();
    }
    else if (messageAddClientPackage === SESSION.EXPIRED_MESSAGE) {
      warningAlert(
        "Error",
        messageAddClientPackage,
        "",
        "Retrieve Token",
        () => {
          onErrorConfirm();
        },
        () => {
          onErrorConfirm();
        }
      );
      clearClientPackageActionsDispatch();
    }
    else if (statusAddClientPackage === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAddClientPackage, "", "Ok");
      clearClientPackageActionsDispatch();
    }
  }, [statusAddClientPackage]);

  useEffect(() => {
    if (statusAddClientSignup === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAddClientSignup,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      setShow(false);
      clearaddClientSignupDispatch();
    } else if (messageAddClientSignup === SESSION.EXPIRED_MESSAGE) {
      warningAlert(
        "Error",
        messageAddClientSignup,
        "",
        "Retrieve Token",
        () => {
          onErrorConfirm();
        },
        () => {
          onErrorConfirm();
        }
      )
      clearaddClientSignupDispatch();
    } else if (statusAddClientSignup === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAddClientSignup, "", "Ok");
      clearaddClientSignupDispatch();
    }
  }, [statusAddClientSignup]);

  return (
    <>
      <div
        className="bg-shadow"
      >
        <div className="d-flex justify-content-center ms-10">
          <a href="#" className="mb-12 mt-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              style={{ width: '253px', filter: 'drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)' }}
            />
          </a>
        </div>
        <div className="row mb-10 gx-0">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xl-1" />
          <div className="col-lg-10 col-md-10 col-sm-10 col-xl-10 d-flex justify-content-center">
            <div className="card shadow-sm ms-5">
              <div
                className="card-header mt-5 d-flex justify-content-center"
                style={{ minHeight: "49px" }}
              >
                <h2
                  className="text mb-2 mt-5 fw-bolder"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  Subscription Pay
                </h2>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-center mb-5">
                  <a href="#">
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/illustrations/dozzy-1/3-dark.png"
                      )}
                      className="w-350px h-300px"
                    />
                  </a>
                </div>
                <div className="d-flex justify-content-center bg-secondary">
                  <div className="fs-4 fw-bolder mt-5">
                    {subscriptionPackagePlan ===
                      SUBSCRIPTION_PACKAGE_PLAN.FREEMIUM
                      ? "Please click here to subscribe"
                      : "Please add your payment details here"}
                  </div>
                </div>
                <div className="d-flex justify-content-center bg-secondary">
                  <div className="w-550px h-100 rounded-3 bg-opacity-75 py-2 px-10 mt-2 mb-5">
                    {subscriptionPackagePlan ===
                      SUBSCRIPTION_PACKAGE_PLAN.FREEMIUM ? (
                      <div className="ms-10 text-center">
                        <button
                          type="button"
                          className="btn btn-warning btn-lg btn-block text-dark fw-bolder"
                          onClick={() => {
                            handleSubscribeFreemium();
                          }}
                        >
                          {loadingAddClientPackage ? (
                            <span
                              className="spinner-border spinner-border-sm mx-3"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Subscribe Freemium"
                          )}
                        </button>
                      </div>
                    ) : (
                      <div className="ms-10">
                        {paypalClientId && show ? (
                          <PayPalBtn
                            // amount="0.01"
                            currency="USD"
                            createSubscription={paypalSubscribe}
                            onApprove={paypalOnApprove}
                            catchError={paypalOnError}
                            onError={paypalOnError}
                            onCancel={paypalOnError}
                            paypalKey={paypalClientId}
                          />
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-10 mb-2">
                  <div className="col-lg-2 d-flex justify-content-end">
                    <span className="svg-icon svg-icon-success svg-icon-2hx">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                          fill="#00A3FF"
                        />
                        <path
                          className="permanent"
                          d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="col-lg-9">
                    <div>
                      <h3
                        className="fw-bold"
                        style={{ color: "rgb(7 129 215)" }}
                      >
                        No-Risk Money back guarentee
                      </h3>
                    </div>
                    <p className="fw-bold text-dark fs-5">
                      Our service is completely risk-free! If we won't win you
                      the price of your subscription, you get your money back
                    </p>
                  </div>
                </div>
                <div className="border-bottom border-primary mw-500 w-500 mb-5" />
                <div className="row mt-5 mb-2">
                  <div className="col-lg-2 d-flex justify-content-end">
                    <span className="svg-icon svg-icon-success svg-icon-2hx">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                          fill="#00A3FF"
                        />
                        <path
                          className="permanent"
                          d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="col-lg-9">
                    <div>
                      <h3
                        className="fw-bold"
                        style={{ color: "rgb(7 129 215)" }}
                      >
                        ChargebackZero is truly-fully automated
                      </h3>
                    </div>
                    <p className="fw-bold text-dark fs-5">
                      Sit back, relax and ChargebackZero will respond to your
                      chargebacks. You don't need to do anything else! Yes,
                      really.
                    </p>
                  </div>
                </div>
                <div className="border-bottom border-primary mw-500 w-500 mb-5" />
                <div className="row mt-5 mb-2">
                  <div className="col-lg-2 d-flex justify-content-end">
                    <span className="svg-icon svg-icon-success svg-icon-2hx">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                          fill="#00A3FF"
                        />
                        <path
                          className="permanent"
                          d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="col-lg-9">
                    <div>
                      <h3
                        className="fw-bold"
                        style={{ color: "rgb(7 129 215)" }}
                      >
                        You are in good company
                      </h3>
                    </div>
                    <p className="fw-bold text-dark fs-5">
                      Thousands of merchants around the world, just like you,
                      trust ChargebackZero to respond to and win their
                      chargebacks.
                    </p>
                  </div>
                </div>
                <div className="border-bottom border-primary mw-500 w-500 mb-5" />
                <div className="form-group row mb-4 mt-15">
                  <div className="text-center">
                    <Link
                      to={`/select-package`}
                      className="btn btn-sm m-2 w-120px fw-bolder text-white h-40px"
                      style={{ backgroundColor: "#f56c61" }}
                    >
                      Previous
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { addClientSignupStore, getClientSignupStore, addClientPackageStore } = state;
  return {
    getPackages:
      state && state.packageStore && state.packageStore.getPackages
        ? state.packageStore.getPackages
        : [],
    getPaypal:
      state && state.getPaypalStore && state.getPaypalStore
        ? state.getPaypalStore.getPaypal
        : [],
    getClientSignup:
      getClientSignupStore && getClientSignupStore.getClientSignup
        ? getClientSignupStore.getClientSignup
        : {},
    statusClientSignup:
      getClientSignupStore && getClientSignupStore.statusClientSignup
        ? getClientSignupStore.statusClientSignup
        : "",
    loadingAddClientPackage:
      addClientPackageStore && addClientPackageStore.loadingAddClientPackage
        ? addClientPackageStore.loadingAddClientPackage
        : false,
    statusAddClientPackage:
      addClientPackageStore && addClientPackageStore.statusAddClientPackage
        ? addClientPackageStore.statusAddClientPackage
        : "",
    messageAddClientPackage:
      addClientPackageStore && addClientPackageStore.messageAddClientPackage
        ? addClientPackageStore.messageAddClientPackage
        : "",
    statusAddClientSignup:
      addClientSignupStore && addClientSignupStore.statusAddClientSignup
        ? addClientSignupStore.statusAddClientSignup
        : "",
    messageAddClientSignup:
      addClientSignupStore && addClientSignupStore.messageAddClientSignup
        ? addClientSignupStore.messageAddClientSignup
        : "",
    loadingAddClientSignup:
      addClientSignupStore && addClientSignupStore.loadingAddClientSignup
        ? addClientSignupStore.loadingAddClientSignup
        : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPaypalDispatch: (params) => dispatch(getPaypalActions.get(params)),
  addPaypalActionDispatch: (data, currentUrl) =>
    dispatch(addPaypalActions.add(data, currentUrl)),
  addPaypalSubscriptionHistoryDispatch: (data) =>
    dispatch(addPaypalSubscriptionHistoryAction.add(data)),
  postClientPackageActionsDispatch: (data, headers) =>
    dispatch(postClientPackageActions.add(data, headers)),
  clearClientPackageActionsDispatch: () =>
    dispatch(postClientPackageActions.clear()),
  getClientSignupDispatch: (id) => dispatch(getClientSignupActions.get(id)),
  getClientSignupActions: () => dispatch(getClientSignupActions.get()),
  addClientSignupDispatch: (payload) =>
    dispatch(postClientSignUpActions.add(payload)),
  clearaddClientSignupDispatch: () => dispatch(postClientSignUpActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageSubs);
