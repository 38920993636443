import { LoginActionsTypes } from '../actions'

export const loginInitialState = {
  data: null
}

export const loginStoreKey = 'loginStore'

const loginReducer = (state = loginInitialState, action) => {
  switch (action.type) {
    case LoginActionsTypes.LOGIN:
      return { ...state, loading: true }
    case LoginActionsTypes.SAVE_LOGIN_RESPONSE:
      return { ...state, login: action.data, loading: false }
    case LoginActionsTypes.CLEAR_LOGIN:
      return { ...state, login: null }
    case LoginActionsTypes.SET_TIMER:
      return { ...state, continueSession: action.data }
    default:
      return state
  }
}

export default loginReducer
