import React, { useState, useEffect } from 'react'
import { validateStep2 } from './validation'
import _ from 'lodash'
import { connect } from 'react-redux'
import { STATUS_RESPONSE, REGEX, DATE, SESSION, API } from '../../../../utils/constants'
import {
  customerDetailsActions,
  editCustomersActions,
  chargeBackDetailsActions
} from '../../../../store/actions'
import { DateSelector } from '../../../../theme/layout/components/DateSelector'
import { setCustomerData } from './formDataCB'
import { useLocation } from 'react-router-dom'
import { confirmAlert, warningAlert } from '../../../../utils/alerts'
import { getLocalStorage, headersWithAuth } from '../../../../utils/helper'

function Step2(props) {
  const {
    onClickNext,
    goBack,
    getCustomerDetailsDispatch,
    clearCustomerDetailsDispatch,
    statusCDG,
    loadingCDG,
    CustomerDetails,
    editCustomerDispatch,
    cleareditCustomerDispatch,
    statusECCB,
    messageECCB,
    loadingECCB,
    getCBDetailsDispatch,
    ChargebackDetails
  } = props

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }

  const url = useLocation().pathname
  const fields = url && url.split('/')
  const id = fields && fields[3]
  const [errors, setErrors] = useState({})
  const [editForm, setEditForm] = useState(false)
  const [formData, setFormData] = useState({
    customerSalutation: '',
    customerFirstName: '',
    customerLastName: '',
    customerAddress: '',
    customerCity: '',
    customerState: '',
    customerZip: '',
    customerCountry: '',
    customerEmailName: '',
    customerEmail: '',
    customerPhoneType: '',
    customerPhoneNumber: '',
    dateOfBirth: '',
    firstTransactionDate: null,
    lengthOfRelationship: '',
    authenticationUsedForIdentification: '',
    notes: ''
  })

  const handleChange = (e) => {
    e.persist()
    setFormData(values => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const handleSubmit = () => {
    const errorMsg = validateStep2(formData, setErrors)
    if (_.isEmpty(errorMsg)) {
      const payload = setCustomerData(formData)
      editCustomerDispatch(id, payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }

  const onConfirm = () => {
  }

  useEffect(() => {
    if (statusECCB === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'success',
        messageECCB,
        'success',
        'Ok',
        () => { onConfirm() },
        () => { onClickNext(2) }
      )
      cleareditCustomerDispatch()
    } else if (statusECCB === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'error',
        messageECCB,
        '',
        'Ok'
      )
      cleareditCustomerDispatch()
    }
  }, [statusECCB])

  useEffect(() => {
    if (id) {
      getCBDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      getCustomerDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }, [id])

  useEffect(() => {
    if (statusCDG === STATUS_RESPONSE.SUCCESS_MSG) {
      if (CustomerDetails && CustomerDetails._id) {
        setFormData(values => ({
          ...values,
          ...CustomerDetails,
          firstTransactionDate: CustomerDetails.firstTransactionDate ? new Date(CustomerDetails.firstTransactionDate) : null,
          dateOfBirth: CustomerDetails.dateOfBirth ? new Date(CustomerDetails.dateOfBirth) : null,
        }))
      }
      clearCustomerDetailsDispatch()
    } else if (statusCDG === STATUS_RESPONSE.ERROR_MSG) {
      clearCustomerDetailsDispatch()
    }
  }, [statusCDG])

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  const handleEdit = () => {
    setEditForm(val => !val)
  }

  return (
    <div className='container-fixed'>
      <div className='card-header bg-skyBlue py-10'>
        <div className='card-body  m-8'>
          {
            loadingCDG
              ? (
                <div className='d-flex justify-content-center py-5'>
                  <div className='spinner-border text-primary m-5' role='status' />
                </div>
              )
              : (
                <>
                  <div className='col-lg-12'>
                    <div className='col-lg-12 mt-4'>
                      {!['WON', 'LOST', 'NOT FAUGHT', 'LOST REFUNDED'].includes(ChargebackDetails.chargeBackStatus) && !loadingCDG &&
                        <button
                          className='btn btn-sm fa-pull-right m-1'
                          style={{ backgroundColor: '#6c54a5', color: '#fff' }}
                          onClick={() => { handleEdit() }}
                        >
                          <span className='svg-icon svg-icon-white svg-icon-3'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                              <path opacity='0.3' fillRule='evenodd' clipRule='evenodd' d='M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z' fill='black' />
                              <path d='M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z' fill='black' />
                              <path d='M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z' fill='black' />
                            </svg>
                          </span>
                          Edit
                        </button>
                      }
                    </div>
                  </div>
                  <div className='me-2'>
                    <p className='text-dark fw-bolder fs-3'>
                      BILLING INFORMATION
                    </p>
                  </div>
                  <div className='row mb-4 col-lg-12'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer Salutation:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerSalutation'
                          type='text'
                          className='form-control'
                          placeholder='Customer Salutation'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerSalutation || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.TEXT.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerSalutation && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerSalutation}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer First Name:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerFirstName'
                          type='text'
                          className='form-control'
                          placeholder='Customer First Name'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerFirstName || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_CHARS_SPACE.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerFirstName && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerFirstName}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer Last Name:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerLastName'
                          type='text'
                          className='form-control'
                          placeholder='Customer Last Name'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          disabled={!editForm}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerLastName || ''}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_CHARS_SPACE.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerLastName && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerLastName}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer Country:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerCountry'
                          type='text'
                          className='form-control'
                          placeholder='Customer Country'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerCountry || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.TEXT.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerCountry && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerCountry}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer State:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerState'
                          type='text'
                          className='form-control'
                          placeholder='Customer State'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerState || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.TEXT.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerState && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerState}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer City:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerCity'
                          type='text'
                          className='form-control'
                          placeholder='Customer City'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerCity || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.TEXT.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerCity && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerCity}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer Address:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='customerAddress'
                          type='text'
                          className='form-control'
                          placeholder='Customer Address'
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.customerAddress || ''}
                        />
                        {errors && errors.customerAddress && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerAddress}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer Zipcode:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerZip'
                          type='text'
                          className='form-control'
                          placeholder='Customer Zipcode'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerZip || ''}
                          disabled={!editForm}
                          maxLength={6}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerZip && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerZip}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Account Name:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerEmailName'
                          type='text'
                          className='form-control'
                          placeholder='Account Name'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerEmailName || ''}
                          disabled={!editForm}
                          maxLength={42}
                          onKeyPress={(e) => {
                            if (!REGEX.TEXT.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerEmailName && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerEmailName}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer Email:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerEmail'
                          type='text'
                          className='form-control'
                          placeholder='Customer Email'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          disabled={!editForm}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerEmail || ''}
                          maxLength={42}
                        />
                        {errors && errors.customerEmail && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerEmail}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Phone Number:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerPhoneNumber'
                          type='text'
                          className='form-control'
                          placeholder='Phone Number'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerPhoneNumber || ''}
                          disabled={!editForm}
                          maxLength={10}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerPhoneNumber && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerPhoneNumber}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Customer Phone Type:</label>
                      <div className='col-lg-11'>
                        <input
                          name='customerPhoneType'
                          type='text'
                          className='form-control'
                          placeholder='Customer Phone Type'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          disabled={!editForm}
                          onChange={(e) => handleChange(e)}
                          value={formData.customerPhoneType || ''}
                          maxLength={10}
                          onKeyPress={(e) => {
                            if (!REGEX.TEXT.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.customerPhoneType && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.customerPhoneType}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>First Transaction Date:</label>
                      <div className='col-lg-11'>
                        <DateSelector
                          name='firstTransactionDate'
                          placeholder='First Transaction Date'
                          className='form-control'
                          selected={formData.firstTransactionDate || ''}
                          readOnly={!editForm}
                          onChange={(date) => {
                            setErrors({ ...errors, firstTransactionDate: '' })
                            setFormData((values) => ({
                              ...values,
                              firstTransactionDate: date
                            }))
                          }}
                          dateFormat={DATE.DATE_FOR_PICKER}
                        />
                        {errors && errors.firstTransactionDate && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.firstTransactionDate}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Date of Birth:</label>
                      <div className='col-lg-11'>
                        <DateSelector
                          name='dateOfBirth'
                          placeholder='Date of Birth'
                          className='form-control'
                          selected={formData.dateOfBirth || ''}
                          readOnly={!editForm}
                          onChange={(date) => {
                            setErrors({ ...errors, dateOfBirth: '' })
                            setFormData((values) => ({
                              ...values,
                              dateOfBirth: date
                            }))
                          }}
                          dateFormat={DATE.DATE_FOR_PICKER}
                        />
                        {errors && errors.dateOfBirth && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.dateOfBirth}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Length of Relationship:</label>
                      <div className='col-lg-11'>
                        <input
                          name='lengthOfRelationship'
                          type='text'
                          className='form-control'
                          placeholder='Length of Relationship'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.lengthOfRelationship || ''} KeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.lengthOfRelationship && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.lengthOfRelationship}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Auth Used for Identification:</label>
                      <div className='col-lg-11'>
                        <input
                          name='authenticationUsedForIdentification'
                          type='text'
                          className='form-control'
                          placeholder='Auth Used for Identification'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.authenticationUsedForIdentification || ''}
                          maxLength={10}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.authenticationUsedForIdentification && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.authenticationUsedForIdentification}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Notes:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='notes'
                          type='text'
                          className='form-control'
                          placeholder='Notes'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.notes || ''}
                        />
                        {errors && errors.notes && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.notes}</div>)}
                      </div>
                    </div>
                  </div>
                </>
              )
          }
        </div>
        <div className='form-group row mb-4'>
          <div className='col-lg-6' />
          <div className='col-lg-6'>
            <div className='col-lg-11'>
              {editForm ? (
                <button
                  className='btn btn-primary m-2 fa-pull-right'
                  id='submitBtn'
                  onClick={(event) => {
                    handleSubmit(event)
                  }}
                >
                  {loadingECCB
                    ? (
                      <span
                        className='spinner-border spinner-border-sm mx-3'
                        role='status'
                        aria-hidden='true'
                      />
                    )
                    : (
                      <>
                        <span>Save and Continue</span>  &nbsp;
                        <span className="bi bi-caret-right-fill mt-2" />
                      </>
                    )}
                </button>
              )
                : (
                  <button
                    className='btn btn-primary m-2 fa-pull-right'
                    onClick={() => {
                      onClickNext(2)
                    }}
                  >
                    <span>Continue</span>  &nbsp;
                    <span className="bi bi-caret-right-fill mt-2" />
                  </button>
                )}
              <button
                className='btn btn-darkRed m-2 fa-pull-right'
                onClick={() => { goBack(0) }}
              >
                <span className="bi bi-caret-left-fill mt-2" />&nbsp;
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state) => {
  const { customerStore, editCustomerStore, chargebackStore } = state
  return {
    CustomerDetails: customerStore && customerStore.CustomerDetails ? customerStore.CustomerDetails : [],
    statusCDG: customerStore && customerStore.statusCDG ? customerStore.statusCDG : '',
    messagesCDG: customerStore && customerStore.messagesCDG ? customerStore.messagesCDG : '',
    loadingCDG: customerStore && customerStore.loadingCDG ? customerStore.loadingCDG : false,
    editCustomers: editCustomerStore && editCustomerStore.dataECCB ? editCustomerStore.dataECCB : [],
    statusECCB: editCustomerStore && editCustomerStore.statusECCB ? editCustomerStore.statusECCB : '',
    messageECCB: editCustomerStore && editCustomerStore.messageECCB ? editCustomerStore.messageECCB : '',
    loadingECCB: editCustomerStore && editCustomerStore.loadingECCB ? editCustomerStore.loadingECCB : false,
    ChargebackDetails: chargebackStore && chargebackStore.ChargebackDetails ? chargebackStore.ChargebackDetails : {},
  }
}

const mapDispatchToProps = dispatch => ({
  getCustomerDetailsDispatch: (id, headers) =>
    dispatch(customerDetailsActions.getCustomerDetails(id, headers)),
  clearCustomerDetailsDispatch: () =>
    dispatch(customerDetailsActions.clearCustomerDetails()),
  editCustomerDispatch: (id, params, headers) =>
    dispatch(editCustomersActions.editCustomers(id, params, headers)),
  cleareditCustomerDispatch: () =>
    dispatch(editCustomersActions.cleareditCustomers()),
  getCBDetailsDispatch: (id, headers) =>
    dispatch(chargeBackDetailsActions.getChargebackDetails(id, headers)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2)
