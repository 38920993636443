import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  siteConfigActions,
  siteConfigActionsTypes,
  addSiteConfigActions,
  addSiteConfigTypes,
  siteConfigGetDetailsTypes,
  siteConfigDetailsActions,
  editSiteConfigTypes,
  editSiteConfigsActions,
  siteConfigDeleteTypes,
  deleteSiteConfigActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchSiteConfig (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.siteConfig, { params, headers: headers })
    if (json.status === 200) {
      yield put(siteConfigActions.saveSiteConfigResponse(json.data))
    } else {
      yield put(siteConfigActions.saveSiteConfigResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: siteConfigActionsTypes.SAVE_SITE_CONFIG_RESPONSE, data })
  }
}

function * getSiteConfigDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.siteConfig}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers: headers})
    if (json.status === 200) {
      yield put(siteConfigDetailsActions.saveSiteConfigDetailsResponse(json.data))
    } else {
      yield put(siteConfigDetailsActions.saveSiteConfigDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: siteConfigGetDetailsTypes.SITE_CONFIG_DETAILS_RESPONSE, data })
  }
}

function * addSiteConfig (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.siteConfig, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addSiteConfigActions.saveaddSiteConfigResponse(json.data))
    } else {
      yield put(addSiteConfigActions.clearaddSiteConfig([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addSiteConfigTypes.RESPONSE, data })
  }
}

function * editSiteConfig (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.siteConfig}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(editSiteConfigsActions.saveeditSiteConfigsResponse(json.data))
    } else {
      yield put(editSiteConfigsActions.saveeditSiteConfigsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editSiteConfigTypes.RESPONSE, data })
  }
}

function * deleteSiteConfig (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.siteConfig}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteSiteConfigActions.savemResponseDeleteSiteConfig(json.data))
    } else {
      yield put(deleteSiteConfigActions.savemResponseDeleteSiteConfig([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: siteConfigDeleteTypes.SAVE_DELETE_SITE_CONFIG_RESPONSE, data })
  }
}

export function * fetchSiteConfigWatcher () {
  yield all([
    yield takeLatest(siteConfigActionsTypes.GET_SITE_CONFIG, fetchSiteConfig),
    yield takeLatest(siteConfigGetDetailsTypes.GET_SITE_CONFIG_DETAILS, getSiteConfigDetails),
    yield takeLatest(addSiteConfigTypes.REQUEST, addSiteConfig),
    yield takeLatest(editSiteConfigTypes.REQUEST, editSiteConfig),
    yield takeLatest(siteConfigDeleteTypes.DELETE_SITE_CONFIG, deleteSiteConfig)
  ])
}
