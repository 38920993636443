import { put, takeLatest } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import { postActions, postActionTypes } from '../actions'
import serviceList from '../../services/serviceList'

function * fetchPosts (actions) {
  const { headers } = actions
  const json = yield axiosInstance.get(serviceList.posts, {headers: headers})
  try {
    if (json.status === 200) {
      yield put(postActions.savePostsResponse(json.data))
    } else {
      yield put(postActions.savePostsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: postActionTypes.SAVE_POSTS_RESPONSE, data })
  }
}

export function * fetchPostsWatcher () {
  yield takeLatest(postActionTypes.GET_POSTS, fetchPosts)
}
