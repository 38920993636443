// const API_URL = "https://staging-idpms.chargebackzero.com/api/v1/user";
// const USER_SERVICES = "https://staging-idpms-inquiry.chargebackzero.com/api/v1";
// const ISSUER_BANK = "https://staging-idpms-issuer.chargebackzero.com/api/v1";
// const ALERT_SERVICE = "https://alert.staging.idpms.chargebackzero.com/api/v1";
// const CHARGEBACK = "https://staging-idpms-chargeback.chargebackzero.com/api/v1/cb";

// const API_URL = "http://99c0-49-37-192-236.ngrok-free.app/api/v1/user";
// const USER_SERVICES = "http://localhost:3020/api/v1";
// const ISSUER_BANK = "http://localhost:3040/api/v1";
// const ALERT_SERVICE = "http://localhost:3030/api/v1";
// const CHARGEBACK = "http://localhost:3050/api/v1/cb";
// const API_URL = "http://192.168.0.7:3010/api/v1";
// const USER_SERVICES = "http://192.168.0.7:3010/api/v1/api/v1";
// const API_URL = "https://chargebackzero.shenll.com/userservice/api/v1";
// const USER_SERVICES = "https://chargebackzero.shenll.com/userservice/api/v1";
// const ISSUER_BANK = "http:///192.168.0.7/api/v1";
// const ALERT_SERVICE = "http:///192.168.0.7/api/v1";
// const CHARGEBACK = "http:///192.168.0.7:3050/api/v1/cb";



const API_URL = `${process.env.REACT_APP_API_URL}`;
const USER_SERVICES = `${process.env.REACT_APP_INQUIRY_SERVICES}`;
const ISSUER_BANK = `${process.env.REACT_APP_ISSUER_BANK}`;
const ALERT_SERVICE = `${process.env.REACT_APP_ALERT_SERVICE}`;
const CHARGEBACK = `${process.env.REACT_APP_CHARGEBACK}`;

const serviceList = {
  login: `${API_URL}/user/login`,
  chargebacksummary: `${API_URL}/dashboard/chargebacksummary`,
  fraudanalyzer: `${API_URL}/dashboard/fraudanalyzer`,
  performanceanalyzer: `${API_URL}/dashboard/performanceanalyzer`,
  preventionalertsdashboard: `${API_URL}/dashboard/preventionalertsdashboard`,
  preventioninquiriesintelligence: `${API_URL}/dashboard/preventioninquiriesintelligence`,
  rootcauseanalyzer: `${API_URL}/dashboard/rootcauseanalyzer`,
  forgotPassword: `${API_URL}/user/forgotPassword`,
  verifyUser: `${API_URL}/user/checkForgotToken`,
  resetPassword: `${API_URL}/user/resetPassword`,
  changePassword: `${API_URL}/user/changePassword`,
  posts: "/posts",
  quickSight: "/quicksight",
  chargeback: `${CHARGEBACK}/chargebacks`,
  approveAll: `${CHARGEBACK}/approveAll`,
  approveAll: `${CHARGEBACK}/approveAll`,
  updatechargeback: `${CHARGEBACK}/chargebacks/updatechargeback`,
  uploadChargeback: `${CHARGEBACK}/chargebacks/bulkimport`,
  bulkUploadChargeback: `${CHARGEBACK}/chargebacks/uploads/csv`,
  preventionAlert: `${ALERT_SERVICE}/alerts`,
  outcomeAlert: `${ALERT_SERVICE}/alerts/outcomestatus`,
  refundAlert: `${ALERT_SERVICE}/alerts/refundstatus`,
  orderinsight: `${USER_SERVICES}/inquiryAlerts`,
  export: `${ISSUER_BANK}/issuerbankalerts?skipPagination=true`,
  addClientManagement: `${API_URL}/clients/save`,
  userTypes: `${API_URL}/usertypes`,
  getClient: `${API_URL}/clients`,
  editClient: `${API_URL}/clients`,
  getPartner: `${API_URL}/partners`,
  getPartnerUsers: `${API_URL}/partners/clientlist`,
  saveMerchant: `${API_URL}/merchants`,
  getMerchant: `${API_URL}/merchants`,
  editMerchant: `${API_URL}/merchants`,
  getBank: `${API_URL}/banks`,
  issuerbankInquiry: `${ISSUER_BANK}/issuerbankinquiry`,
  issuerbanklist: `${ISSUER_BANK}/issuerbankinquiry`,
  issuerbankInquiryupload: `${ISSUER_BANK}/issuerbankinquiry/uploads`,
  issuerbankAlert: `${ISSUER_BANK}/issuerbankalerts`,
  issuerbankPostAlert: `${ISSUER_BANK}/issuerbankalerts`,
  issuerbankAlertUpload: `${ISSUER_BANK}/issuerbankalerts/uploads`,
  reasonGrouping: `${CHARGEBACK}/reasoncodegrouping`,
  country: `${API_URL}/Country`,
  state: `${API_URL}/state`,
  city: `${API_URL}/city`,
  CRM: `${API_URL}/crm`,
  package: `${API_URL}/package/list`,
  packageList: `${API_URL}/package/getPackageByAppname`,
  getPackage: `${API_URL}/package`,
  editPackage: `${API_URL}/package`,
  paymentprocessor: `${API_URL}/paymentprocessor`,
  paymentgateway: `${API_URL}/paymentgateway`,
  user: `${API_URL}/user`,
  userRegister: `${API_URL}/user/register`,
  getUser: `${API_URL}/user`,
  editUser: `${API_URL}/user`,
  packPayment: `${API_URL}/payment`,
  ClientCRM: `${API_URL}/clientCrm`,
  clientProcessor: `${API_URL}/clientpaymentprocessor`,
  clientGateway: `${API_URL}/clientpaymentgateway`,
  addclientCRM: `${API_URL}/clientCrm`,
  deleteclientCRM: `${API_URL}/clientCrm`,
  checkCrm: `${API_URL}/crm/checkClientcrm`,
  clientcrmdetails: `${API_URL}/crm/clientcrmdetails`,
  addclientProcessor: `${API_URL}/clientpaymentprocessor`,
  deleteclientProcessor: `${API_URL}/clientpaymentprocessor`,
  addclientGateway: `${API_URL}/clientpaymentgateway`,
  deleteclientGateway: `${API_URL}/clientpaymentgateway`,
  clientPackages: `${API_URL}/clientPackage`,
  unauthCreateClientPackage: `${API_URL}/clientPackage/unauthCreateClientPackage`,
  addMidCRM: `${API_URL}/mid`,
  editMidCRM: `${API_URL}/mid`,
  getMIDCRMDetails: `${API_URL}/mid`,
  MidCRM: `${API_URL}/mid`,
  deletemidCRM: `${API_URL}/mid`,
  coverletter: `${CHARGEBACK}/coverletter`,
  reasonCode: `${CHARGEBACK}/reasoncode`,
  serviceType: `${CHARGEBACK}/servicetype`,
  RootCause: `${CHARGEBACK}/rootcause`,
  functional: `${CHARGEBACK}/functionaltype`,
  SubFunctional: `${CHARGEBACK}/subfunctionaltype`,
  OperationalType: `${CHARGEBACK}/operationaltype`,
  userComponents: `${API_URL}/components`,
  userSubComponents: `${API_URL}/subcomponents`,
  userType: `${API_URL}/usertypes`,
  addUserrole: `${API_URL}/userroles`,
  userRole: `${API_URL}/userroles`,
  editUserrole: `${API_URL}/userroles`,
  deleteUserrole: `${API_URL}/userroles`,
  userPrivileges: `${API_URL}/userprivileges`,
  transaction: `${CHARGEBACK}/chargebacks/transaction`,
  customer: `${CHARGEBACK}/chargebacks/customer`,
  purchase: `${CHARGEBACK}/chargebacks/purchase`,
  merchantCB: `${CHARGEBACK}/chargebacks/merchant`,
  addDocument: `${CHARGEBACK}/chargebacks/upload`,
  getDocType: `${CHARGEBACK}/chargebacks/evidence`,
  document: `${CHARGEBACK}/chargebacks/uploaddocuments`,
  rebuttal: `${CHARGEBACK}/chargebacks/coverletter`,
  addRebuttal: `${CHARGEBACK}/chargebacks/coverletter`,
  addDownload: `${CHARGEBACK}/chargebacks/merge`,
  addEmail: `${CHARGEBACK}/chargebacks/sendmail`,
  addZip: `${CHARGEBACK}/chargebacks/zip`,
  email: `${CHARGEBACK}/chargebacks/sendmail`,
  sendLink: `${CHARGEBACK}/chargebacks/sentlink`,
  MailHistory: `${CHARGEBACK}/chargebacks/sentLinkHistory`,
  getMerchantEvidence: `${CHARGEBACK}/chargebacks/getCBTokendata`,
  addMerchantEvidence: `${CHARGEBACK}/chargebacks/uploadDOC`,
  Industry: `${API_URL}/industry?skipPagination=true`,
  partnerClient: `${API_URL}/clients/clientswithoutpartners`,
  addPartnerClient: `${API_URL}/partners/partnerclientsave`,
  deletePartnerClient: `${API_URL}/partners/partnerclientsave?remove`,
  addMerchantUpload: `${API_URL}/fileuploads`,
  CrmSettings: `${API_URL}/crm`,
  ProcessorSettings: `${API_URL}/paymentprocessor`,
  GatewaySettings: `${API_URL}/paymentgateway`,
  IndustrySettings: `${API_URL}/industry`,
  userMenuComp: `${API_URL}/components/menuitems`,
  acquirer: `${API_URL}/acquirer`,
  userCompPermission: `${API_URL}/userprivileges/getComponentPermissionList`,
  uploadMerchant: `${API_URL}/merchants/bulkimport`,
  siteConfig: `${API_URL}/siteConfig`,
  assignUser: `${API_URL}/assignuser`,
  apiKey: `${API_URL}/apikey`,
  userProfile: `${API_URL}/user/profile`,
  merchantSmtp: `${API_URL}/merchantConfiguration`,
  clientSmtp: `${API_URL}/clientConfiguration`,
  merchantTemplate: `${API_URL}/emailtemplates`,
  Variable: `${API_URL}/emailTemplateVariable`,
  subsPackage: `${API_URL}/package`,
  analyst: `${API_URL}/user/analystUsers`,
  Paypal: `${API_URL}/paymentSettings/paypalAccessToken`,
  PaypalSettings: `${API_URL}/paymentSettings`,
  PayConfig: `${API_URL}/paymentSettings`,
  paypalSubscription: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions`,
  generalTemplate: `${API_URL}/generalEmailTemplate`,
  DeletesubsPackage: `${API_URL}/package/cancelSubscription`,
  clientSignup: `${API_URL}/user/clientOnboardingRegister`,
  notificationType: `${API_URL}/templateNotificationType`,
  emailResend: `${API_URL}/user/resendVerificationmail`,
  approveCB: `${CHARGEBACK}/chargebacks/sendstatusacquirer`,
  addMerchantNotification: `${CHARGEBACK}/chargebacks/merchantMailNotification`,
  merchantMailNotificationCount: `${CHARGEBACK}/chargebacks/merchantMailNotificationcount`,
  approveCBM: `${CHARGEBACK}/approveChargebackBatchId/merchantChargeback`,
  approveAllCBM: `${CHARGEBACK}/chargebacks/merchantCharegebackApprove`,
  addupdateCBApprove: `${CHARGEBACK}/chargebacks/updatechargeback`,
  sendCustomMail: `${CHARGEBACK}/chargebacks/customMail`,
  checkProcessor: `${API_URL}/paymentprocessor/checkClientPaymentProcessor`,
  ClientProcessorDetails: `${API_URL}/clientpaymentprocessor/client`,
  uploadChargebackStatus: `${CHARGEBACK}/chargebacks/bulkupdatestatus`,
  downloadInvoice: `${CHARGEBACK}/chargebacks/invoiceZip`,
  shopifyConnect: `${API_URL}/clientcrm/checkshopify`,
  toggleBillingChange: `${CHARGEBACK}/chargebacks/merchant-billing-details`,
  postBillingPrice: `${CHARGEBACK}/chargebacks/merchant-price-details`,
  billingDetails: `${CHARGEBACK}/chargebacks/merchant-billing-details`,
  // mock api for notification
  getNotification: `https://notification.free.beeceptor.com/notifications`,
};

export default serviceList;
