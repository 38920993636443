export const checkKeyshasValues = (data) => {
  let valid = true
  if (!data.acquirerName) {
    valid = false
  }
  if (!data.arn) {
    valid = false
  }
  if (!data.authCode) {
    valid = false
  }
  if (!data.cardFirstSix) {
    valid = false
  }
  if (!data.cardLastFour) {
    valid = false
  }
  if (!data.cardNetwork) {
    valid = false
  }
  if (!data.cardType) {
    valid = false
  }
  if (!data.caseId) {
    valid = false
  }
  if (!data.chargebackAmount) {
    valid = false
  }
  if (!data.chargebackCurrency) {
    valid = false
  }
  if (!data.chargebackDate) {
    valid = false
  }
  if (!data.chargebackReason) {
    valid = false
  }
  if (!data.chargeBackType) {
    valid = false
  }
  if (!data.clientId) {
    valid = false
  }
  if (!data.companyName) {
    valid = false
  }
  if (!data.customerEmail) {
    valid = false
  }
  if (!data.customerName) {
    valid = false
  }
  if (!data.customerPhone) {
    valid = false
  }
  if (!data.DBAName) {
    valid = false
  }
  if (!data.disputeMethod) {
    valid = false
  }
  if (!data.merchantId) {
    valid = false
  }
  if (!data.merchantName) {
    valid = false
  }
  if (!data.mid) {
    valid = false
  }
  if (!data.nonUs) {
    valid = false
  }
  if (!data.orderId) {
    valid = false
  }
  if (!data.paymentProcessor) {
    valid = false
  }
  if (!data.paymentMethod) {
    valid = false
  }
  if (!data.productName) {
    valid = false
  }
  if (!data.reasonForLegitimateChargeback) {
    valid = false
  }
  if (!data.serviceType) {
    valid = false
  }
  if (!data.saleType) {
    valid = false
  }
  if (!data.transactionAmount) {
    valid = false
  }
  if (!data.transactionId) {
    valid = false
  }
  if (!data.transactionDate) {
    valid = false
  }
  return valid
}
