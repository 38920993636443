import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  chargebackActions,
  chargebackActionsTypes,
  addChargebackActions,
  addChargebackTypes,
  chargeBackGetDetailsTypes,
  chargeBackDetailsActions,
  editChargebackTypes,
  editChargebacksActions,
  chargebackDeleteTypes,
  deleteChargebackActions,
  approveCBTypes,
  approveCBActions,
  addNotificationToMerchantTypes,
  addNotificationToMerchant,
  singleNotifyToMerchantTypes,
  singleNotifyToMerchant,
  singleApproveMerchantTypes,
  singleApproveMerchant,
  approveCBMActionsTypes,
  approveCBMActions,
  approveALLCBMTypes,
  approveALLCBMActions,
  updateCBApproveTypes,
  updateCBApprove,
  downloadInvoiceTypes,
  downloadInvoiceActions
} from '../actions'
import serviceList from '../../services/serviceList'

function* fetchChargeback(actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.chargeback, {
      params,
      headers: headers
    })
    if (json.status === 200) {
      yield put(chargebackActions.saveChargebackResponse(json.data))
    } else {
      yield put(chargebackActions.saveChargebackResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: chargebackActionsTypes.SAVE_CHARGEBACK_RESPONSE, data })
  }
}

function* getChargebackDetails(actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.chargeback}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(chargeBackDetailsActions.saveChargebackDetailsResponse(json.data))
    } else {
      yield put(chargeBackDetailsActions.saveChargebackDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: chargeBackGetDetailsTypes.CHARGEBACK_DETAILS_RESPONSE, data })
  }
}

function* addChargeback(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.chargeback, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addChargebackActions.saveaddChargebackResponse(json.data))
    } else {
      yield put(addChargebackActions.saveaddChargebackResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addChargebackTypes.RESPONSE, data })
  }
}

function* editChargeback(actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.chargeback}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(editChargebacksActions.saveeditChargebacksResponse(json.data))
    } else {
      yield put(editChargebacksActions.saveeditChargebacksResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editChargebackTypes.RESPONSE, data })
  }
}

function* deleteChargeback(actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.chargeback}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteChargebackActions.savemResponseDeleteChargeback(json.data))
    } else {
      yield put(deleteChargebackActions.savemResponseDeleteChargeback([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: chargebackDeleteTypes.SAVE_DELETE_CHARGEBACK_RESPONSE, data })
  }
}

function* approveCB(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.approveCB, payload, { headers: headers })
    if (json.status === 200) {
      yield put(approveCBActions.saveResponse(json.data))
    } else {
      yield put(approveCBActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: approveCBTypes.RESPONSE, data })
  }
}

function* approveALLCBM(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.approveAllCBM, payload, { headers: headers })
    if (json.status === 200) {
      yield put(approveALLCBMActions.saveResponse(json.data))
    } else {
      yield put(approveALLCBMActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: approveALLCBMTypes.RESPONSE, data })
  }
}

function* addNotififyToMerchant(action) {
  const { payload, url, headers } = action
  try {
    const json = yield axiosInstance.post(url, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addNotificationToMerchant.saveResponse(json.data))
    } else {
      yield put(addNotificationToMerchant.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addNotificationToMerchantTypes.RESPONSE, data })
  }
}

function* singleNotifyToMerchantSaga(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.addMerchantNotification, payload, { headers: headers })
    if (json.status === 200) {
      yield put(singleNotifyToMerchant.saveResponse(json.data))
    } else {
      yield put(singleNotifyToMerchant.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: singleNotifyToMerchantTypes.RESPONSE, data })
  }
}

function* singleApproveMerchantSaga(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.put(serviceList.addupdateCBApprove, payload, { headers: headers })
    if (json.status === 200) {
      yield put(singleApproveMerchant.saveResponse(json.data))
    } else {
      yield put(singleApproveMerchant.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: singleApproveMerchantTypes.RESPONSE, data })
  }
}

function* fetchapproveCBM(actions) {
  const { id, headers } = actions;
  const endPointUrl = `${serviceList.approveCBM}/${id}`;
  try {
    const json = yield axiosInstance.get(endPointUrl, {
      headers: { ...headers, Authorization: "" },
    });
    if (json.status === 200) {
      yield put(
        approveCBMActions.saveResponse(json.data)
      );
    } else {
      yield put(approveCBMActions.saveResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({
      type: approveCBMActionsTypes.RESPONSE,
      data,
    });
  }
}

function* addupdateCBApprove(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.put(serviceList.addupdateCBApprove, payload, { headers: headers })
    if (json.status === 200) {
      yield put(updateCBApprove.saveResponse(json.data))
    } else {
      yield put(updateCBApprove.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updateCBApproveTypes.RESPONSE, data })
  }
}

function* downloadInvoice(actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.downloadInvoice, { params, headers: headers })
    if (json.status === 200) {
      yield put(downloadInvoiceActions.saveResponse(json.data));
    } else {
      yield put(downloadInvoiceActions.saveResponse([]));
    }
  } catch (error) {
    const data = { status: "error", message: API_MESSAGES.SOMETHING_WRONG };
    yield put({ type: downloadInvoiceTypes.RESPONSE, data });
  }
}

export function* fetchChargebackWatcher() {
  yield all([
    yield takeLatest(chargebackActionsTypes.GET_CHARGEBACK, fetchChargeback),
    yield takeLatest(chargeBackGetDetailsTypes.GET_CHARGEBACK_DETAILS, getChargebackDetails),
    yield takeLatest(addChargebackTypes.REQUEST, addChargeback),
    yield takeLatest(editChargebackTypes.REQUEST, editChargeback),
    yield takeLatest(chargebackDeleteTypes.DELETE_CHARGEBACK, deleteChargeback),
    yield takeLatest(approveCBTypes.REQUEST, approveCB),
    yield takeLatest(approveALLCBMTypes.REQUEST, approveALLCBM),
    yield takeLatest(addNotificationToMerchantTypes.REQUEST, addNotififyToMerchant),
    yield takeLatest(singleNotifyToMerchantTypes.REQUEST, singleNotifyToMerchantSaga),
    yield takeLatest(singleApproveMerchantTypes.REQUEST, singleApproveMerchantSaga),
    yield takeLatest(approveCBMActionsTypes.REQUEST, fetchapproveCBM),
    yield takeLatest(updateCBApproveTypes.REQUEST, addupdateCBApprove),
    yield takeLatest(downloadInvoiceTypes.REQUEST, downloadInvoice)
  ])
}
