import {
  addClientMgmtTypes,
  clientGetDetailsTypes,
  clientDetailsTypes,
  editClientTypes
} from '../actions'

export const addClientMgmtInitialState = {
  list: null
}

export const addClientMgmtStoreKey = 'addClientMgmtStore'

export const addClientMgmtReducer = (state = addClientMgmtInitialState, action) => {
  switch (action.type) {
    case addClientMgmtTypes.REQUEST:
      return { ...state, loadingACL: true }
    case addClientMgmtTypes.RESPONSE:
      return { ...state, dataACL: action.data, statusACL: action.data.status, messageACL: action.data.message, loadingACL: false }
    case addClientMgmtTypes.CLEAR:
      return { ...state, dataACL: null, messageACL: '', statusACL: '' }
    default:
      return state
  }
}

export const clientDetailsStoreKey = 'clientDetailsStore'

export const clientDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case clientDetailsTypes.REQUEST:
      return { ...state, loadingClientDetails: true }
    case clientDetailsTypes.RESPONSE:
      return { ...state, dataCD: action.data && action.data.data, statusCD: action.data.status, messageCD: action.data.message, loadingClientDetails: false }
    case clientDetailsTypes.CLEAR:
      return { ...state, dataCD: null, messageCD: '', statusCD: '' }
      case clientDetailsTypes.CURRENT_TABS_VALUE:
        return { ...state, clientCurrentTab: action.currentTab }
    case clientGetDetailsTypes.GET_CLIENT_DETAILS:
      return { ...state, loadingGCLD: true }
    case clientGetDetailsTypes.CLIENT_DETAILS_RESPONSE:
      return {
        ...state,
        clientGetDetails: action.data && action.data.data,
        statusGCLD: action.data && action.data.status,
        messagesGCLD: action.data && action.data.message,
        loadingGCLD: false
      }
    case clientGetDetailsTypes.CLEAR_CLIENT_DETAILS:
      return { ...state, statusGCLD: '', messagesGCLD: '', clientGetDetails: null }
    default:
      return state
  }
}

export const editClientStoreKey = 'editClientStore'

export const editClientReducer = (state = {}, action) => {
  switch (action.type) {
    case editClientTypes.REQUEST:
      return { ...state, loadingECL: true }
    case editClientTypes.RESPONSE:
      return { ...state, dataECL: action.data && action.data.data, statusECL: action.data.status, messageECL: action.data.message, loadingECL: false }
    case editClientTypes.CLEAR:
      return { ...state, dataECL: null, messageECL: '', statusECL: '' }
    default:
      return state
  }
}
