export const actionTypes = {
  GET_POSTS: 'GET_POSTS',
  SAVE_POSTS_RESPONSE: 'SAVE_POSTS_RESPONSE',
  CLEAR_POSTS: 'CLEAR_POSTS'
}

export const actions = {
  getPosts: (headers) => ({
    type: actionTypes.GET_POSTS,
    headers
  }),
  savePostsResponse: data => ({
    type: actionTypes.SAVE_POSTS_RESPONSE,
    data
  }),
  clearPosts: () => ({
    type: actionTypes.CLEAR_POSTS
  })
}
