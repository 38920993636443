import {
  USER_MANAGEMENT_ERROR,
  REGEX,
  USER_ERROR,
} from "../../../../utils/constants";

export const crmValidation = (values, setErrors) => {
  const errors = {};
  // if (!values.crmName) {
  //   errors.crmName = USER_ERROR.CRM_REQUIRED
  // }
  if (!values.storeName) {
    errors.storeName = USER_ERROR.STORE_NAME;
  }
  setErrors(errors);
  return errors;
};

export const clientSignUpValidation = (values, setErrors) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = USER_MANAGEMENT_ERROR.USER_FNAME;
  }
  if (!values.lastName) {
    errors.lastName = USER_MANAGEMENT_ERROR.USER_LNAME;
  }
  if (!values.email) {
    errors.email = USER_MANAGEMENT_ERROR.USER_EMAIL;
  }
  if (values.email && !REGEX.EMAIL.test(values.email)) {
    errors.email = USER_ERROR.EMAIL_INVALID;
  }
  if (values.email) {
    const getEmailName = values.email.split("/");
    const emailName = getEmailName && getEmailName[1];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.email = USER_ERROR.EMAIL_INVALID;
    }
  }
  if (!values.password) {
    errors.password = USER_ERROR.PASSWORD_REQUIRED;
  }
  if (!values.cPassword) {
    errors.cPassword = USER_ERROR.CONFIRM_PASSWORD_REQUIRED;
  }
  if (values.password !== values.cPassword) {
    errors.cPassword = USER_ERROR.PASSWORD_SAME;
  }
  setErrors(errors);
  return errors;
};

export const emailValidation = (values, setErrors) => {
  const errors = {};
  if (!values.email) {
    errors.email = USER_ERROR.EMAIL;
  }
  setErrors(errors);
  return errors;
};

export const packDetailValidation = (values, setErrors) => {
  const errors = {};
  if (!values.title) {
    errors.title = USER_ERROR.PACK_TITLE_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const packageValidation = (values, setErrors) => {
  const errors = {};
  if (!values) {
    errors.subscriptionErr = "Please Select at least one subscription!";
  }
  setErrors(errors);
  return errors;
};

export const planTypeValidation = (values, setErrors) => {
  const errors = {};
  const valuesLength = values.length;
  if (valuesLength === 0) {
    errors.planType = "Please Select at least one Plan Type!";
  }
  setErrors(errors);
  return errors;
};

export const serviceValidation = (values, setErrors) => {
  const errors = {};
  const valuesLength = values.length;
  if (valuesLength === 0) {
    errors.serviceType = "Please Select at least one Service Type!";
  }
  setErrors(errors);
  return errors;
};

export const processorDetailValidation = (values, setErrors) => {
  const errors = {};
  if (!values.processorName) {
    errors.processorName = USER_ERROR.PROCESSOR_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const clientDetailValidation = (values, setErrors) => {
  const errors = {};
  if (!values.company) {
    errors.company = USER_ERROR.COMPANYNAME;
  }
  if (!values.clientAddress) {
    errors.clientAddress = USER_ERROR.ADDRESS_REQUIRED;
  }
  if (!values.clientEmail) {
    errors.clientEmail = USER_ERROR.EMAIL;
  }
  if (values.clientEmail && !REGEX.EMAIL.test(values.clientEmail)) {
    errors.clientEmail = USER_ERROR.EMAIL_INVALID;
  }
  if (values.clientEmail) {
    const getEmailName = values.clientEmail.split("/");
    const emailName = getEmailName && getEmailName[1];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.clientEmail = USER_ERROR.EMAIL_INVALID;
    }
  }
  if (!values.clientPhoneNumber) {
    errors.clientPhoneNumber = USER_ERROR.PHONE_NUMBER;
  }
  if (
    values.clientPhoneNumber &&
    !REGEX.PHONE_NUMBER_2.test(values.clientPhoneNumber)
  ) {
    errors.clientPhoneNumber = USER_ERROR.PHONE_NUMBER_INVALID;
  }
  if (!values.clientZip) {
    errors.clientZip = USER_ERROR.ZIPCODE_REQUIRED;
  }
  if (values.clientZip && !REGEX.ZIP_CODE_1.test(values.clientZip)) {
    errors.clientZip = USER_ERROR.ZIPCODE_INVALID_1;
  }
  if (!values.industry) {
    errors.industry = USER_ERROR.INDUSTRY_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const merchantDetailsValidation = (values, setErrors) => {
  const errors = {};
  if (!values.merchantName) {
    errors.merchantName = USER_ERROR.MERCHANT_NAME_REQUIRED;
  }
  if (!values.merchantAddress) {
    errors.merchantAddress = USER_ERROR.ADDRESS_REQUIRED;
  }
  if (!values.merchantEmail) {
    errors.merchantEmail = USER_ERROR.EMAIL;
  }
  if (values.merchantEmail && !REGEX.EMAIL.test(values.merchantEmail)) {
    errors.merchantEmail = USER_ERROR.EMAIL_INVALID;
  }
  if (values.merchantEmail) {
    const getEmailName = values.merchantEmail.split("@");
    const emailName = getEmailName && getEmailName[0];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.merchantEmail = USER_ERROR.EMAIL_INVALID;
    }
  }
  if (!values.merchantPhoneNumber) {
    errors.merchantPhoneNumber = USER_ERROR.PHONE_NUMBER;
  }
  if (
    values.merchantPhoneNumber &&
    !REGEX.PHONE_NUMBER_2.test(values.merchantPhoneNumber)
  ) {
    errors.merchantPhoneNumber = USER_ERROR.PHONE_NUMBER_INVALID;
  }
  if (!values.merchantZip) {
    errors.merchantZip = USER_ERROR.ZIPCODE_REQUIRED;
  }
  if (values.merchantZip && !REGEX.ZIP_CODE_1.test(values.merchantZip)) {
    errors.merchantZip = USER_ERROR.ZIPCODE_INVALID_1;
  }
  if (!values.industry) {
    errors.industry = USER_ERROR.INDUSTRY_REQUIRED;
  }
  setErrors(errors);
  return errors;
};
