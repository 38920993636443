import {
  addUploadsMerchantTypes
} from '../actions'

export const addUploadsMerchantStoreKey = 'addUploadsMerchantStore'

export const addUploadsMerchantReducer = (state = {}, action) => {
  switch (action.type) {
    case addUploadsMerchantTypes.REQUEST:
      return { ...state, loadingAUMerchant: true }
    case addUploadsMerchantTypes.RESPONSE:
      return {
        ...state,
        dataAUMerchant: action.data && action.data.data,
        statusAUMerchant: action.data.status,
        messageAUMerchant: action.data.message,
        loadingAUMerchant: false,
        failureAUMerchant: action.data && action.data.failure,
        successAUMerchant: action.data && action.data.success
      }
    case addUploadsMerchantTypes.CLEAR:
      return {
        ...state,
        dataAUMerchant: null,
        messageAUMerchant: '',
        statusAUMerchant: '',
        failureAUMerchant: '',
        successAUMerchant: ''
      }
    default:
      return state
  }
}
