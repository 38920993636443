import * as React from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File

const defaultFonts = [
  'Arial',
  'Comic Sans MS',
  'Courier New',
  'Impact',
  'Georgia',
  'Tahoma',
  'Trebuchet MS',
  'Verdana'
]
const TextSunEditor = (props) => {
  const { setData, contentData, name, minHeight } = props

  const handleChange = (value) => {
    setData(values => ({ ...values, [name]: value }))
  }

  const handleBlur = (event, editorContents) => {
    // Get the blur event
  }

  const handleClick = (event) => {
    // Get the click event
  }

  const handleInput = (event) => {
    // Get the change event
  }

  const sortedFontOptions = [
    'Logical',
    'Salesforce Sans',
    'Garamond',
    'Sans-Serif',
    'Serif',
    'Times New Roman',
    'Helvetica',
    ...defaultFonts
  ].sort()

  return (
    <div className='App bg-dark p-10' style={{ position: 'relative' }}>
      <SunEditor
        plugin=''
        setContents={contentData}
        onBlur={handleBlur}
        onChange={(value) => {
          handleChange(value)
        }}
        onInput={handleInput}
        onClick={handleClick}
        height='100%'
        width='100%'
        setOptions={{
          buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'hiliteColor', 'textStyle'],
            ['removeFormat'],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link', 'image', 'video', 'audio'],
            ['imageGallery'],
            ['fullScreen', 'showBlocks', 'codeView'],
            ['preview', 'print'],
            ['save', 'template']
          ],
          defaultTag: 'div',
          minHeight: minHeight || '800px',
          showPathLabel: false,
          font: sortedFontOptions
        }}
      />
    </div>
  )
}

export default TextSunEditor
