import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../theme/helpers";
import { useHistory, Link } from "react-router-dom";
import color from "../../../utils/colors";
import {
  crmActions,
  getClientSignupActions,
  postClientSignUpActions,
} from "../../../store/actions";
import ReactSelect from "../../../theme/layout/components/ReactSelect";
import { STATUS_RESPONSE, CLIENT_SESSION, SESSION } from "../../../utils/constants";
import { getLocalStorage } from "../../../utils/helper";
import { warningAlert } from "../../../utils/alerts";
import { setCrmData } from "./FormData/formData";
import { crmValidation } from "./validate/validation";

const SelectCrm = (props) => {
  const {
    getCRMDispatch,
    getCRM,
    getClientSignupDispatch,
    statusClientSignup,
    getClientSignup,
    addClientSignupDispatch,
    clearaddClientSignupDispatch,
    statusAddClientSignup,
    messageAddClientSignup,
    loadingAddClientSignup,
  } = props;

  const history = useHistory();
  const clientSignupId = getLocalStorage(CLIENT_SESSION.CLIENT_TOKEN);
  const [selectedCrmOption, setSelectedCrmOption] = useState("");
  const [crmOption, setCrmOption] = useState();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    crmName: "",
    storeName: ""
  });

  useEffect(() => {
    getCRMDispatch();
    getClientSignupDispatch(clientSignupId);
  }, []);

  const handleChange = (e) => {
    e.persist()
    setFormData(values => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
    e.preventDefault()
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      alignItems: "baseline",
      background: "#fff",
      minHeight: "35px",
      boxShadow: "0 0 0 1px #fff",
      borderColor: "#a888cb",
      borderStyle: "dashed",
      fontWeight: "bolder",
      borderWidth: "3px",
      borderRadius: "18px",
      "&:hover": {
        borderColor: "#89769f",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.white : color.black,
      fontWeight: "bolder",
      backgroundColor: state.isSelected ? "#A194AB" : "",
      "&:hover": {
        backgroundColor: "#A194AB",
      },
    }),
  };

  const getDefaultOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item[name],
        })
      );
      return defaultOptions;
    }
  };

  useEffect(() => {
    const data = getDefaultOptions(getCRM && getCRM.data, "crmName");
    setCrmOption(data);
  }, [getCRM]);

  const handleChangeCRM = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCrmOption(selectedOption);
      setFormData((values) => ({
        ...values,
        crmName: selectedOption.value,
      }));
      setErrors({ ...errors, crmName: "" });
    } else {
      setSelectedCrmOption();
      setFormData((values) => ({ ...values, crmName: "" }));
    }
  };

  useEffect(() => {
    if (statusClientSignup === STATUS_RESPONSE.SUCCESS_MSG) {
      const setData = setCrmData(getClientSignup)
      setFormData(setData)
    }
    if (getClientSignup.CRM && getClientSignup.CRM.crmId && getClientSignup.CRM.crmId.crmName !== null) {
      const data = getDefaultOptions(getCRM && getCRM.data, 'crmName')
      const selOption = _.filter(data, function (x) {
        if (getClientSignup.CRM && getClientSignup.CRM.crmId && getClientSignup.CRM.crmId.crmName === x.value) {
          return x
        }
      })
      setSelectedCrmOption(selOption)
    }
  }, [statusClientSignup]);

  const onErrorConfirm = () => {
    history.push("/client-signup");
  };

  useEffect(() => {
    if (statusAddClientSignup === STATUS_RESPONSE.SUCCESS_MSG) {
      clearaddClientSignupDispatch();
      history.push("/select-processor");
    }
    else if (messageAddClientSignup === SESSION.EXPIRED_MESSAGE) {
      warningAlert(
        "Error",
        messageAddClientSignup,
        "",
        "Retrieve Token",
        () => {
          onErrorConfirm();
        },
        () => {
          onErrorConfirm();
        }
      );
      clearaddClientSignupDispatch();
    }
    else if (statusAddClientSignup === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAddClientSignup, "", "Ok");
      clearaddClientSignupDispatch();
    }
  }, [statusAddClientSignup]);

  const handleSubmit = () => {
    const payload = {
      signUpToken: clientSignupId,
      crm: {
        crmName: formData.crmName,
        storeName: formData.storeName,
      },
    };
    const errorMsg = crmValidation(formData, setErrors)
    if (_.isEmpty(errorMsg)) {
      addClientSignupDispatch(payload);
    }
  };

  return (
    <>
      <body
        className="bg-shadow"
      >
        <div className="d-flex justify-content-center ms-10">
          <a href="#" className="mb-12 mt-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              style={{ width: '253px', filter: 'drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)' }}
            />
          </a>
        </div>
        <div className="row mb-10 gx-0">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xl-1" />
          <div className="col-lg-10 col-md-10 col-sm-10 col-xl-10 d-flex justify-content-center">
            <div className="card shadow-sm ms-5" style={{ borderRadius: '24px' }}>
              <div
                className="card-header  d-flex justify-content-center"
                style={{ minHeight: "49px" }}
              >
                <h2
                  className="text mb-4 mt-5 fw-bolder"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  Choose CRM
                </h2>
              </div>
              <div className="card-body">
                <h6
                  className="fw-bold fs-5 ms-10 text-center"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  ChargebackZero assign relevant resources and expertise from
                  and within the same CRM system
                </h6>
                <h6
                  className="fw-bold fs-5 ms-10 text-center"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  Authorize access only to the particular workforce to protect
                  client data.
                </h6>
                <div className="d-flex justify-content-center">
                  <a href="#">
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "/media/illustrations/dozzy-1/5-dark.png"
                      )}
                      className="w-350px h-300px"
                    />
                  </a>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center mt-3 mb-3">
                    <h4 className="fw-bolder">Select CRM</h4>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="col-lg-6">
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name="crmName"
                        className="basic-single"
                        classNamePrefix="select"
                        handleChangeReactSelect={handleChangeCRM}
                        options={crmOption}
                        value={selectedCrmOption}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center  mt-3 mb-3">
                    <h4 className="fw-bolder">Store Name</h4>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="col-lg-6">
                      <input
                        name="storeName"
                        type="text"
                        className="form-control"
                        placeholder="Store Name"
                        onChange={(e) => handleChange(e)}
                        value={formData.storeName || ""}
                        autoComplete="off"
                        maxLength={42}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    {errors && errors.storeName && (
                      <div className='rr mt-1'>
                        <style>{'.rr{color:red}'}</style>
                        {errors.storeName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-4 mt-15">
                  <div className="text-center">
                    <Link
                      to={`/get-started/${clientSignupId}`}
                      className="btn btn-sm m-2 w-120px fw-bolder text-white h-40px"
                      style={{ backgroundColor: "#f56c61" }}
                    >
                      Previous
                    </Link>{" "}
                    {""}

                    <>
                      <button
                        className="btn btn-sm fw-bolder btn-primary m-2 w-120px h-40px"
                        onClick={(event) => {
                          handleSubmit(event);
                        }}
                      >
                        {loadingAddClientSignup ? (
                          <span
                            className="spinner-border spinner-border-sm mx-3"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Save and Continue"
                        )}
                      </button>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

const mapStateToProps = (state) => {
  const { addClientSignupStore, getClientSignupStore } = state;
  return {
    getCRM: state && state.crmStore && state.crmStore.getCRM,
    loading: state && state.crmStore && state.crmStore.loading,
    getClientSignup:
      getClientSignupStore && getClientSignupStore.getClientSignup
        ? getClientSignupStore.getClientSignup
        : {},
    statusClientSignup:
      getClientSignupStore && getClientSignupStore.statusClientSignup
        ? getClientSignupStore.statusClientSignup
        : "",
    messageClientSignup:
      getClientSignupStore && getClientSignupStore.messageClientSignup
        ? getClientSignupStore.messageClientSignup
        : "",
    loadingClientSignup:
      getClientSignupStore && getClientSignupStore.loadingClientSignup
        ? getClientSignupStore.loadingClientSignup
        : false,
    addClientSignup:
      addClientSignupStore && addClientSignupStore.addClientSignup
        ? addClientSignupStore.addClientSignup
        : [],
    statusAddClientSignup:
      addClientSignupStore && addClientSignupStore.statusAddClientSignup
        ? addClientSignupStore.statusAddClientSignup
        : "",
    messageAddClientSignup:
      addClientSignupStore && addClientSignupStore.messageAddClientSignup
        ? addClientSignupStore.messageAddClientSignup
        : "",
    loadingAddClientSignup:
      addClientSignupStore && addClientSignupStore.loadingAddClientSignup
        ? addClientSignupStore.loadingAddClientSignup
        : false,
    didMount:
      getClientSignupStore && getClientSignupStore.didMount
        ? getClientSignupStore.didMount
        : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCRMDispatch: () => dispatch(crmActions.getCRM()),
  crmActions: (data) => dispatch(crmActions.getCRM(data)),
  getClientSignupDispatch: (id) => dispatch(getClientSignupActions.get(id)),
  getClientSignupActions: () => dispatch(getClientSignupActions.get()),
  didMountActions: (val) => dispatch(getClientSignupActions.didMount(val)),
  addClientSignupDispatch: (payload) =>
    dispatch(postClientSignUpActions.add(payload)),
  clearaddClientSignupDispatch: () => dispatch(postClientSignUpActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCrm);
