import {
  addNotifyTypes,
  notifyActionsTypes
} from '../actions'

export const addNotifyInitialState = {
  list: null
}
export const addNotifyStoreKey = 'addNotifyStore'

export const addNotifyReducer = (state = addNotifyInitialState, action) => {
  switch (action.type) {
    case addNotifyTypes.REQUEST:
      return { ...state, loadingANT: true }
    case addNotifyTypes.RESPONSE:
      return { ...state, dataANT: action.data, statusANT: action.data.status, messageANT: action.data.message, loadingANT: false }
    case addNotifyTypes.CLEAR:
      return { ...state, dataANT: null, messageANT: '', statusANT: '' }
    default:
      return state
  }
}

export const notifyInitialState = {
  list: null
}

export const notifyStoreKey = 'notifyStore'

export const notifyReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case notifyActionsTypes.REQUEST:
      return { ...state, loading: true }
    case notifyActionsTypes.RESPONSE:
      return { ...state, getNotify: res.data, loading: false }
    case notifyActionsTypes.CLEAR:
      return { ...state, getNotify: null }
    default:
      return state
  }
}
