import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  getSubsPackageActions,
  getSubsPackageActionsTypes,
  addSubsPackageActions,
  addSubsPackageActionsTypes,
  getSubsPackageDetailsActions,
  getSubsPackageDetailsActionsTypes,
  updateSubsPackageActions,
  updateSubsPackageActionsTypes,
  deleteSubsPackageActions,
  deleteSubsPackageActionsTypes,
  deleteSubsActionsTypes,
  deleteSubsActions
} from '../actions'
import serviceList from '../../services/serviceList'

function* fetchSubsPackage(actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.subsPackage, { params, headers: headers })
    if (json.status === 200) {
      yield put(getSubsPackageActions.saveResponse(json.data))
    } else {
      yield put(getSubsPackageActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getSubsPackageActionsTypes.RESPONSE, data })
  }
}

function* addSubsPackage(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.subsPackage, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addSubsPackageActions.saveResponse(json.data))
    } else {
      yield put(addSubsPackageActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addSubsPackageActionsTypes.RESPONSE, data })
  }
}

function* getSubsPackageDetails(actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.subsPackage}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(
        getSubsPackageDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getSubsPackageDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getSubsPackageDetailsActionsTypes.RESPONSE, data })
  }
}

function* updateSubsPackage(actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.subsPackage}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(updateSubsPackageActions.saveResponse(json.data))
    } else {
      yield put(updateSubsPackageActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updateSubsPackageActionsTypes.RESPONSE, data })
  }
}

function* deleteSubs(actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.subsPackage}/${params}`
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteSubsActions.savemResponse(json.data))
    } else {
      yield put(deleteSubsActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteSubsActionsTypes.RESPONSE, data })
  }
}

function* deleteSubsPackage(actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.DeletesubsPackage}/${params}`
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteSubsPackageActions.savemResponse(json.data))
    } else {
      yield put(deleteSubsPackageActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteSubsPackageActionsTypes.RESPONSE, data })
  }
}

export function* fetchSubsPackageWatcher() {
  yield all([
    yield takeLatest(getSubsPackageActionsTypes.REQUEST, fetchSubsPackage),
    yield takeLatest(addSubsPackageActionsTypes.REQUEST, addSubsPackage),
    yield takeLatest(getSubsPackageDetailsActionsTypes.REQUEST, getSubsPackageDetails),
    yield takeLatest(updateSubsPackageActionsTypes.REQUEST, updateSubsPackage),
    yield takeLatest(deleteSubsActionsTypes.REQUEST, deleteSubs),
    yield takeLatest(deleteSubsPackageActionsTypes.REQUEST, deleteSubsPackage)
  ])
}
