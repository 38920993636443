import React from 'react'
import { Link } from 'react-router-dom'
import { SESSION, API } from '../../utils/constants'
import { getLocalStorage } from '../../utils/helper'

function Chargeback() {
  const isAuthorized = getLocalStorage(SESSION.TOKEN)
  const url = getLocalStorage(API.API_KEY);

  return (
    <div className='card-header py-10'>
      <form className='container-fixed '>
        <div className='card-body h-500px p-8'>
          <div className='row'>
            <div className='col-lg-1' />
            <div className='col-lg-4'>
              <div className='card-body bg-violet h-225px'>
                <div className='row'>
                  <Link
                    to='/chargeback-management/add-chargeback'
                  >
                    <span className='svg-icon svg-icon-white svg-icon-3x ms-n1 d-flex justify-content-center mt-8'>
                      <span className='svg-icon svg-icon-muted svg-icon-2hx'>
                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                          <path opacity='0.3' d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13.5L12.5 13V10C12.5 9.4 12.6 9.5 12 9.5C11.4 9.5 11.5 9.4 11.5 10L11 13L8 13.5C7.4 13.5 7 13.4 7 14C7 14.6 7.4 14.5 8 14.5H11V18C11 18.6 11.4 19 12 19C12.6 19 12.5 18.6 12.5 18V14.5L16 14C16.6 14 17 14.6 17 14C17 13.4 16.6 13.5 16 13.5Z' fill='black' />
                          <rect x='11' y='19' width='10' height='2' rx='1' transform='rotate(-90 11 19)' fill='black' />
                          <rect x='7' y='13' width='10' height='2' rx='1' fill='black' />
                          <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='black' />
                        </svg>
                      </span>
                    </span>
                    <div className='text-white fw-bolder fs-2 mt-5 d-flex justify-content-center'>Single Entry</div>
                    <div className='fw-bold text-white d-flex justify-content-center'>Press here to continue Single Entry form</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-1' />
            <div className='col-lg-4'>
              <div className='card-body bg-violet h-225px'>
                <div className='row'>
                  <Link
                    to='/chargeback-management/upload-chargeback'
                  >
                    <span className='svg-icon svg-icon-white svg-icon-3x ms-n1 d-flex justify-content-center mt-8'>
                      <span className='svg-icon svg-icon-muted svg-icon-2hx'><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                        <path opacity='0.3' d='M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z' fill='black' />
                        <path d='M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z' fill='black' />
                        <path opacity='0.3' d='M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z' fill='black' />
                      </svg>
                      </span>
                    </span>
                    <div className='text-white fw-bolder fs-2 mt-5 d-flex justify-content-center'>Bulk Upload</div>
                    <div className='fw-bold text-white d-flex justify-content-center'>Press here to continue Bulk Upload form</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Link
            to={isAuthorized ? '/chargeback-management' : `/manage-chargeback?apiKey=${url}`}
            className='btn btn-sm btn-darkRed mt-8 fa-pull-right'>
            Back
          </Link>
        </div>
      </form >
    </div >
  )
}

export default Chargeback
