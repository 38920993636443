import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  REGEX,
  STATUS_RESPONSE,
  CLIENT_SESSION,
} from "../../../utils/constants";
import { merchantDetailsValidation } from "./validate/validation";
import color from "../../../utils/colors";
import {
  countryActions,
  stateActions,
  cityActions,
  industryActions,
  getClientSignupActions,
  postClientSignUpActions,
} from "../../../store/actions";
import ReactSelect from "../../../theme/layout/components/ReactSelect";
import { setMerchantData } from "./FormData/formData";
import { getLocalStorage, headersWithAuth } from "../../../utils/helper";

const MerchantDetails = (props) => {
  const {
    getCountryDispatch,
    getCountrys,
    loadingCountry,
    getStateDispatch,
    getStates,
    loadingState,
    getCityDispatch,
    getCitys,
    loadingCity,
    getIndustryDispatch,
    getIndustry,
    siteConfigs,
    getClientSignupDispatch,
    clearClientSignupDispatch,
    loadingClientSignup,
    statusClientSignup,
    getClientSignup,
    addClientSignupDispatch,
    loadingAddClientSignup,
  } = props;

  const clientSignupId = getLocalStorage(CLIENT_SESSION.CLIENT_TOKEN);
  const [countryOption, setCountryOption] = useState();
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [stateOption, setStateOption] = useState();
  const [selectedStateOption, setSelectedStateOption] = useState("");
  const [cityOptions, setCityOptions] = useState();
  const [selectedCityOption, setSelectedCityOption] = useState("");
  const [selectedIndustryOption, setSelectedIndustryOption] = useState("");
  const [industryOption, setIndustryOption] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [errors, setErrors] = useState({});
  const [onBlurOptions, setOnBlurOptions] = useState({
    merchantState: false,
    merchantCity: false
  });
  const [formData, setFormData] = useState({
    merchantName: "",
    merchantCountry: "",
    industry: "",
    merchantAddress: "",
    merchantCity: "",
    merchantState: "",
    merchantZip: "",
    merchantEmail: "",
    merchantPhoneNumber: "",
    phoneNumberExtension: "",
    externalId: "",
    companyDescription: "",
  });

  const params = {
    skipPagination: true
  }

  useEffect(() => {
    getCountryDispatch(params, headersWithAuth);
    getIndustryDispatch(null, headersWithAuth);
    getClientSignupDispatch(clientSignupId);
  }, []);

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  useEffect(() => {
    if (statusClientSignup === STATUS_RESPONSE.SUCCESS_MSG) {
      if (getClientSignup.Merchant !== null && getClientSignup.Merchant !== undefined) {
        const setMerchantDetails = setMerchantData(getClientSignup);
        setFormData(setMerchantDetails);
        if (getClientSignup.Merchant.merchantCountry !== null) {
          const country = getDefaultOptions(getCountrys);
          const selOption = _.filter(country, function (x) {
            if (getClientSignup.Merchant.merchantCountry === x.value) {
              return x;
            }
          });
          setSelectedCountryOption(selOption);
        }
        if (getClientSignup.Merchant.merchantState !== null) {
          const state = getDefaultOptions(getStates);
          const selOption = _.filter(state, function (x) {
            if (getClientSignup.Merchant.merchantState === x.value) {
              return x;
            }
          });
          setSelectedStateOption(selOption);
        }
        if (getClientSignup.Merchant.merchantCity !== null) {
          const city = getDefaultOptions(getCitys);
          const selOption = _.filter(city, function (x) {
            if (getClientSignup.Merchant.merchantCity === x.value) {
              return x;
            }
          });
          setSelectedCityOption(selOption);
        }
        if (getClientSignup.Merchant.industry !== null) {
          const data = getDefaultIndustryOptions(getIndustry, "industryType");
          const selOption = _.filter(data, function (x) {
            if (getClientSignup.Merchant.industry === x.value) {
              return x;
            }
          });
          setSelectedIndustryOption(selOption);
        }
        clearClientSignupDispatch();
      }
    }
  }, [statusClientSignup]);

  const handleSubmit = () => {
    const errorMsg = merchantDetailsValidation(
      formData,
      setErrors,
      siteConfigs
    );
    if (_.isEmpty(errorMsg)) {
      const merchant = {
        merchantName: formData.merchantName,
        merchantCountry: formData.merchantCountry,
        industry: formData.industry,
        merchantAddress: formData.merchantAddress,
        merchantCity: formData.merchantCity,
        merchantState: formData.merchantState,
        merchantZip: formData.merchantZip,
        merchantEmail: formData.merchantEmail,
        merchantPhoneNumber: formData.merchantPhoneNumber,
        phoneNumberExtension: formData.phoneNumberExtension,
        externalId: formData.externalId,
        companyDescription: formData.companyDescription,
      };
      const payload = {
        signUpToken: clientSignupId,
        merchant: _.pickBy(merchant),
      };
      addClientSignupDispatch(payload);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      alignItems: "baseline",
      background: "#fff",
      minHeight: "35px",
      boxShadow: "0 0 0 1px #fff",
      borderColor: "#a888cb",
      borderStyle: "dashed",
      fontWeight: "bolder",
      borderWidth: "3px",
      borderRadius: "18px",
      "&:hover": {
        borderColor: "#89769f",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.white : color.black,
      fontWeight: "bolder",
      backgroundColor: state.isSelected ? "#A194AB" : "",
      "&:hover": {
        backgroundColor: "#A194AB",
      },
    }),
  };

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getStateDispatch({ countryId: selectedOption.value, skipPagination: true });
        }, 1500)
      );
      setSelectedCountryOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantCountry: selectedOption.value,
        merchantState: "",
        merchantCity: "",
      }));
      setSelectedStateOption();
      setSelectedCityOption();
    } else {
      setSelectedCountryOption();
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({
        ...values,
        merchantCountry: "",
        merchantState: "",
        merchantCity: "",
      }));
    }
    setErrors({ ...errors, merchantCountry: "" });
  };

  const handleChangeState = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getCityDispatch({ stateId: selectedOption.value, skipPagination: true });
        }, 1500)
      );
      setSelectedStateOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantState: selectedOption.value,
        merchantCity: "",
      }));
      setSelectedCityOption();
    } else {
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({
        ...values,
        merchantState: "",
        merchantCity: "",
      }));
    }
    setErrors({ ...errors, merchantState: "" });
  };

  const handleSelectBlur = (e, name) => {
    typingTimeout && clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (name === 'merchantState' && onBlurOptions.merchantState) {
          const param = { limit: 25 };
          getStateDispatch(param, headersWithAuth);
          setOnBlurOptions((values) => ({ ...values, merchantState: false }));
        }
        if (name === 'merchantCity' && onBlurOptions.merchantCity) {
          const param = { limit: 25 };
          getCityDispatch(param, headersWithAuth);
          setOnBlurOptions((values) => ({ ...values, merchantCity: false }));
        }
      }, 1000)
    );
  }

  const handleSelectInputChange = (typedOption, name) => {
    if (typedOption.length >= 3) {
      typingTimeout && clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          if (name === 'merchantState' && !(selectedCountryOption && selectedCountryOption.value)) {
            const param = { name: typedOption, skipPagination: true }
            getStateDispatch(param, headersWithAuth);
            setOnBlurOptions((values) => ({ ...values, merchantState: true }));
          }
          if (name === 'merchantCity' && !(selectedStateOption && selectedStateOption.value)) {
            const param = { name: typedOption, skipPagination: true }
            getCityDispatch(param, headersWithAuth);
            setOnBlurOptions((values) => ({ ...values, merchantCity: true }));
          }
        }, 2000)
      );
    }
  }

  const handleChangeCity = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCityOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantCity: selectedOption.value,
      }));
    } else {
      setSelectedCityOption();
      setFormData((values) => ({ ...values, merchantCity: "" }));
    }
    setErrors({ ...errors, merchantCity: "" });
  };

  const handleChangeIndustry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedIndustryOption(selectedOption);
      setFormData((values) => ({ ...values, industry: selectedOption.value }));
      setErrors({ ...errors, industry: "" });
    } else {
      setSelectedIndustryOption();
      setFormData((values) => ({ ...values, industry: "" }));
    }
  };

  useEffect(() => {
    const country = getDefaultOptions(getCountrys);
    setCountryOption(country);
  }, [getCountrys]);

  useEffect(() => {
    const state = getDefaultOptions(getStates);
    setStateOption(state);
  }, [getStates]);

  useEffect(() => {
    const city = getDefaultOptions(getCitys);
    setCityOptions(city);
  }, [getCitys]);

  useEffect(() => {
    const data = getDefaultIndustryOptions(getIndustry, "industryType");
    setIndustryOption(data);
  }, [getIndustry]);

  const getDefaultOptions = (rawData) => {
    const defaultOptions = [];
    for (const item in rawData) {
      defaultOptions.push({
        label: rawData[item].name,
        value: rawData[item]._id,
      });
    }
    return defaultOptions;
  };

  const getDefaultIndustryOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  return (
    <>
      <div className="card-body h-750px p-8 mt-15">
        {loadingClientSignup ? (
          <div className="d-flex justify-content-center py-5">
            <div className="spinner-border text-primary m-5" role="status" />
          </div>
        ) : (
          <>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 required form-label">
                  Merchant Name:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="merchantName"
                    type="text"
                    className="form-control-1"
                    placeholder="Merchant Name"
                    onChange={(e) => handleChange(e)}
                    value={formData.merchantName || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.TEXT.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.merchantName && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.merchantName}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 required form-label">
                  Industry:
                </label>
                <div className="col-lg-12">
                  <ReactSelect
                    isClearable
                    styles={customStyles}
                    isMulti={false}
                    name="industry"
                    className="basic-single"
                    classNamePrefix="select"
                    handleChangeReactSelect={handleChangeIndustry}
                    options={industryOption}
                    value={selectedIndustryOption}
                  />
                  {errors && errors.industry && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.industry}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 form-label">
                  Country:
                </label>
                <div className="col-lg-12">
                  <ReactSelect
                    isClearable
                    styles={customStyles}
                    isMulti={false}
                    name="merchantCountry"
                    className="basic-single"
                    classNamePrefix="select"
                    handleChangeReactSelect={handleChangeCountry}
                    options={countryOption}
                    value={selectedCountryOption}
                    isLoading={loadingCountry}
                    isDisabled={loadingCity || loadingCountry}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 form-label">
                  State:
                </label>
                <div className="col-lg-12">
                  <ReactSelect
                    isClearable
                    styles={customStyles}
                    isMulti={false}
                    name="merchantState"
                    className="basic-single"
                    classNamePrefix="select"
                    handleChangeReactSelect={handleChangeState}
                    options={stateOption}
                    value={selectedStateOption}
                    isLoading={loadingState}
                    noOptionsMessage={() => "Please start typing to search!"}
                    onBlur={(e) => handleSelectBlur(e, 'merchantState')}
                    handleInputChange={(e) => handleSelectInputChange(e, "merchantState")}
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 form-label">
                  City:
                </label>
                <div className="col-lg-12">
                  <ReactSelect
                    isClearable
                    styles={customStyles}
                    isMulti={false}
                    name="merchantCity"
                    className="basic-single"
                    classNamePrefix="select"
                    handleChangeReactSelect={handleChangeCity}
                    options={cityOptions}
                    value={selectedCityOption}
                    isLoading={loadingCity}
                    noOptionsMessage={() => "Please start typing to search!"}
                    onBlur={(e) => handleSelectBlur(e, 'merchantCity')}
                    handleInputChange={(e) => handleSelectInputChange(e, "merchantCity")}
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 required form-label">
                  Address:
                </label>
                <div className="col-lg-12">
                  <textarea
                    autoComplete="off"
                    name="merchantAddress"
                    type="text"
                    className="form-control-1"
                    placeholder="Address"
                    onChange={(e) => handleChange(e)}
                    value={formData.merchantAddress || ""}
                    maxLength={500}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.merchantAddress && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.merchantAddress}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 required form-label">
                  Zip Code:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="merchantZip"
                    type="text"
                    className="form-control-1"
                    placeholder="Zip Code"
                    onChange={(e) => handleChange(e)}
                    value={formData.merchantZip || ""}
                    maxLength={6}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.merchantZip && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.merchantZip}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 required form-label">
                  Email:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="merchantEmail"
                    type="text"
                    className="form-control-1"
                    placeholder="Email"
                    onChange={(e) => handleChange(e)}
                    value={formData.merchantEmail || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.merchantEmail && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.merchantEmail}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 required form-label">
                  Phone Number:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="merchantPhoneNumber"
                    type="text"
                    className="form-control-1"
                    placeholder="Phone Number"
                    onChange={(e) => handleChange(e)}
                    value={formData.merchantPhoneNumber || ""}
                    maxLength={12}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.NUMERIC_CHARS.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.merchantPhoneNumber && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.merchantPhoneNumber}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 form-label">
                  Phone Extension:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="phoneNumberExtension"
                    type="text"
                    className="form-control-1"
                    placeholder="Phone Extension"
                    onChange={(e) => handleChange(e)}
                    value={formData.phoneNumberExtension || ""}
                    maxLength={10}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!/[0-9+]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 form-label">
                  External Id:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="externalId"
                    type="text"
                    className="form-control-1"
                    placeholder="External Id"
                    onChange={(e) => handleChange(e)}
                    value={formData.externalId || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs fw-bolder mb-2 form-label">
                  Company Description:
                </label>
                <div className="col-lg-12">
                  <textarea
                    autoComplete="off"
                    name="companyDescription"
                    type="text"
                    className="form-control-1"
                    placeholder="Company Description"
                    onChange={(e) => handleChange(e)}
                    value={formData.companyDescription || ""}
                    maxLength={500}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="form-group row mb-4">
          <div className="col-lg-6" />
          <div className="col-lg-6">
            <div className="col-lg-12 text-end">
              <Link
                to={`/select-service`}
                className="btn btn-sm m-2 w-100px fw-bolder text-white"
                style={{ backgroundColor: "#f56c61" }}
              >
                Previous
              </Link>
              <button
                className="btn btn-primary btn-sm m-2 w-100px"
                onClick={(event) => {
                  handleSubmit(event);
                }}
              >
                {loadingAddClientSignup ? (
                  <span
                    className="spinner-border spinner-border-sm mx-3"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { addClientSignupStore, getClientSignupStore } = state;
  return {
    getCountrys: state && state.countryStore && state.countryStore.getCountrys,
    loadingCountry: state && state.countryStore && state.countryStore.loading,
    getStates: state && state.stateStore && state.stateStore.getStates,
    loadingState: state && state.stateStore && state.stateStore.loading,
    getCitys: state && state.cityStore && state.cityStore.getCitys,
    loadingCity: state && state.cityStore && state.cityStore.loading,
    getIndustry:
      state && state.industryStore && state.industryStore.getIndustry,
    getClientSignup:
      getClientSignupStore && getClientSignupStore.getClientSignup
        ? getClientSignupStore.getClientSignup
        : {},
    statusClientSignup:
      getClientSignupStore && getClientSignupStore.statusClientSignup
        ? getClientSignupStore.statusClientSignup
        : "",
    messageClientSignup:
      getClientSignupStore && getClientSignupStore.messageClientSignup
        ? getClientSignupStore.messageClientSignup
        : "",
    loadingClientSignup:
      getClientSignupStore && getClientSignupStore.loadingClientSignup
        ? getClientSignupStore.loadingClientSignup
        : false,
    addClientSignup:
      addClientSignupStore && addClientSignupStore.addClientSignup
        ? addClientSignupStore.addClientSignup
        : [],
    statusAddClientSignup:
      addClientSignupStore && addClientSignupStore.statusAddClientSignup
        ? addClientSignupStore.statusAddClientSignup
        : "",
    messageAddClientSignup:
      addClientSignupStore && addClientSignupStore.messageAddClientSignup
        ? addClientSignupStore.messageAddClientSignup
        : "",
    loadingAddClientSignup:
      addClientSignupStore && addClientSignupStore.loadingAddClientSignup
        ? addClientSignupStore.loadingAddClientSignup
        : false,
    didMount:
      getClientSignupStore && getClientSignupStore.didMount
        ? getClientSignupStore.didMount
        : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCountryDispatch: (params, headers) => dispatch(countryActions.getCountrys(params, headers)),
  countryActions: (data) => dispatch(countryActions.getCountrys(data)),
  getStateDispatch: (params, headers) => dispatch(stateActions.getStates(params, headers)),
  getCityDispatch: (params, headers) => dispatch(cityActions.getCitys(params, headers)),
  cityActions: (data) => dispatch(cityActions.getCitys(data)),
  getIndustryDispatch: (params, headers) =>
    dispatch(industryActions.getIndustry(params, headers)),
  getClientSignupDispatch: (id) => dispatch(getClientSignupActions.get(id)),
  getClientSignupActions: () => dispatch(getClientSignupActions.get()),
  clearClientSignupDispatch: () => dispatch(getClientSignupActions.clear()),
  addClientSignupDispatch: (payload) =>
    dispatch(postClientSignUpActions.add(payload)),
  clearaddClientSignupDispatch: () => dispatch(postClientSignUpActions.clear()),
  didMountActions: (val) => dispatch(getClientSignupActions.didMount(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDetails);
