import {
  getSubsPackageActionsTypes,
  addSubsPackageActionsTypes,
  getSubsPackageDetailsActionsTypes,
  updateSubsPackageActionsTypes,
  deleteSubsPackageActionsTypes,
  deleteSubsActionsTypes
} from '../actions'

export const getSubsPackageStoreKey = 'getSubsPackageStore'

export const getSubsPackageReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getSubsPackageActionsTypes.REQUEST:
      return { ...state, loadingGSP: true }
    case getSubsPackageActionsTypes.RESPONSE:
      return {
        ...state,
        getSubsPackage: res.data,
        count: res.count,
        statusGSP: res.status,
        messageGSP: res.message,
        loadingGSP: false
      }
    case getSubsPackageActionsTypes.CLEAR:
      return {
        ...state,
        getSubsPackage: null,
        count: 0,
        statusGSP: '',
        messageGSP: ''
      }
    default:
      return state
  }
}

export const addSubsPackageStoreKey = 'addSubsPackageStore'

export const addSubsPackageReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addSubsPackageActionsTypes.REQUEST:
      return { ...state, loadingASP: true }
    case addSubsPackageActionsTypes.RESPONSE:
      return {
        ...state,
        addSubsPackage: res.data,
        statusASP: res.status,
        messageASP: res.message,
        loadingASP: false
      }
    case addSubsPackageActionsTypes.CLEAR:
      return {
        ...state,
        addSubsPackage: null,
        statusASP: '',
        messageASP: ''
      }
    default:
      return state
  }
}

export const getSubsPackageDetailsStoreKey = 'getSubsPackageDetailsStore'

export const getSubsPackageDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getSubsPackageDetailsActionsTypes.REQUEST:
      return { ...state, loadingGSPD: true }
    case getSubsPackageDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsSubsPackage: res.data,
        statusGSPD: res.status,
        messageGSPD: res.message,
        loadingGSPD: false
      }
    case getSubsPackageDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsSubsPackage: null,
        statusGSPD: '',
        messageGSPD: ''
      }
    default:
      return state
  }
}

export const updateSubsPackageStoreKey = 'updateSubsPackageStore'

export const updateSubsPackageReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updateSubsPackageActionsTypes.REQUEST:
      return { ...state, loadingUSP: true }
    case updateSubsPackageActionsTypes.RESPONSE:
      return {
        ...state,
        updateSubsPackage: res.data,
        statusUSP: res.status,
        messageUSP: res.message,
        loadingUSP: false
      }
    case updateSubsPackageActionsTypes.CLEAR:
      return {
        ...state,
        updateSubsPackage: null,
        statusUSP: '',
        messageUSP: ''
      }
    default:
      return state
  }
}

export const deleteSubsStoreKey = 'deleteSubsStore'

export const deleteSubsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteSubsActionsTypes.REQUEST:
      return { ...state, loadingDSSP: true }
    case deleteSubsActionsTypes.RESPONSE:
      return {
        ...state,
        deleteSubs: res.data,
        statusDSSP: res.status,
        messageDSSP: res.message,
        loadingDSSP: false
      }
    case deleteSubsActionsTypes.CLEAR:
      return {
        ...state,
        deleteSubs: null,
        statusDSSP: '',
        messageDSSP: ''
      }
    default:
      return state
  }
}

export const deleteSubsPackageStoreKey = 'deleteSubsPackageStore'

export const deleteSubsPackageReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteSubsPackageActionsTypes.REQUEST:
      return { ...state, loadingDSP: true }
    case deleteSubsPackageActionsTypes.RESPONSE:
      return {
        ...state,
        deleteSubsPackage: res.data,
        statusDSP: res.status,
        messageDSP: res.message,
        loadingDSP: false
      }
    case deleteSubsPackageActionsTypes.CLEAR:
      return {
        ...state,
        deleteSubsPackage: null,
        statusDSP: '',
        messageDSP: ''
      }
    default:
      return state
  }
}