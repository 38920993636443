export const ShopifyConnectActionTypes = {
  SHOPIFY_CONNECT_INIT: 'SHOPIFY_CONNECT_INIT',
  SHOPIFY_CONNECT_SUCCESS: 'SHOPIFY_CONNECT_SUCCESS',
  SHOPIFY_CONNECT_CLEAR: 'SHOPIFY_CONNECT_CLEAR'
}

export const ShopifyConnectActions = {

  ShopifyConnectInit: (headers) => ({
    type: ShopifyConnectActionTypes.SHOPIFY_CONNECT_INIT,
    headers
  }),
  ShopifyConnectSuccess: data => ({
    type: ShopifyConnectActionTypes.SHOPIFY_CONNECT_SUCCESS,
    data
  }),
  ShopifyConnectClear: () => ({
    type: ShopifyConnectActionTypes.SHOPIFY_CONNECT_CLEAR
  })
}

