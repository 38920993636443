import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { toAbsoluteUrl } from "../../theme/helpers";
import { LoginActions } from "../../store/actions";
import { colors, SESSION, SET_STORAGE } from "../../utils/constants";
import {
  unsetLocalStorage,
  setLastInteractionTime,
  getLocalStorage,
} from "../../utils/helper";

const SessionTimeout = (props) => {
  const { setTimerDispatch } = props;
  const history = useHistory();
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter == 0) {
      clearTimeout(counter);
      unsetLocalStorage();
      history.push("/");
    }
  }, [counter]);

  const handleSubmit = () => {
    unsetLocalStorage();
    history.push("/");
  };

  const handleContinueSession = () => {
    setTimerDispatch(true);
    setLastInteractionTime(SESSION.LAST_INTERACTION_TIME, moment());
    const location = getLocalStorage(SET_STORAGE.LAST_LOCATION);
    console.log(location);
    if (location) {
      history.push(location);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/illustrations/sketchy-1/14.png"
          )})`,
          backgroundColor: colors.oxfordBlue,
        }}
      >
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {/* begin::Logo */}
          <a href="#" className="mb-12">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              className="h-65px"
            />
          </a>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <div className="text-center mb-8">
              <h1 className="text-dark mb-3">Session Timeout</h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#20c997"
                className="bi bi-clock"
                viewBox="0 0 16 16"
              >
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
              </svg>
            </div>
            <div className="fv-row mb-10">
              <p className="text-center fs-5">
                Your current session is about to expire in{" "}
                <span className="text-danger fw-bolder">{counter}s</span>
              </p>
              <p className="text-center fs-5">
                Would you like continue this session?
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-lg btn-danger w-40 mb-5 mx-3"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                <span className="indicator-label">Logout</span>
              </button>
              <button
                type="button"
                className="btn btn-lg btn-info w-40 mb-5"
                onClick={() => {
                  handleContinueSession();
                }}
              >
                <span className="indicator-label">Continue Session</span>
              </button>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTimerDispatch: (data) => dispatch(LoginActions.setTimer(data)),
});

export default connect(null, mapDispatchToProps)(SessionTimeout);
