import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { KTSVG } from "../../theme/helpers";
import { clientActions, getClientIdActions } from "../../store/actions";
import {
  setLocalStorage,
  removeLocalStorage,
  getLocalStorage,
  headersWithAuth,
} from "../../utils/helper";
import { getUserPermissions } from "../../utils/helper";
import { Can } from "../../theme/layout/components/can";
import routeConfig from "../../routing/routeConfig";
import {
  SAVE_CURRENT,
  CRM_FORM,
  CREATE_PERMISSION,
  UPDATE_PERMISSION,
  STATIC_PERMISSION,
  SET_STORAGE,
  API,
  SESSION,
} from "../../utils/constants";
import ReactPaginate from "react-paginate";
import SearchList from "./search/searchList";

function Clients(props) {
  const {
    className,
    loading,
    getClientDispatch,
    getClient,
    saveClientIdDispatch,
  } = props;

  const pathUrl = new URL(window.location.href);
  const query = pathUrl && pathUrl.search.split("?");
  const apiKeyParam = query && query[1];
  const apiParam = apiKeyParam && apiKeyParam.split("apiKey=");
  const apiParamId = apiParam && apiParam[1];
  apiParamId && setLocalStorage(API.API_KEY, apiParamId);
  apiParamId && setLocalStorage(SET_STORAGE.SET_USER_ROLE, "Client User");

  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": apiParamId ? apiParamId : getLocalStorage(API.API_KEY),
  };

  const apiToken = getLocalStorage(API.API_KEY);
  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const history = useHistory();
  const url = useLocation().pathname;
  const getUsersPermissions = getUserPermissions(url, false)
    ? getUserPermissions(url, false)
    : [];
  const currentPermissons =
    url === routeConfig.chargebackManagement
      ? getUsersPermissions
      : STATIC_PERMISSION;
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [searchParams, setSearchParams] = useState();
  const [limit, setLimit] = useState(25);
  const [sorting, setSorting] = useState({});
  const userType = getLocalStorage(SET_STORAGE.SET_USER_ROLE);

  const removeFormStorage = () => {
    removeLocalStorage(CRM_FORM.COMPANY_DETAILS);
    removeLocalStorage(CRM_FORM.PACKAGE_DETAILS);
    removeLocalStorage(CRM_FORM.CRM_DETAILS);
    removeLocalStorage(CRM_FORM.CRM_DETAILS_TABLE);
    removeLocalStorage(CRM_FORM.PROCESSOR_DETAILS);
    removeLocalStorage(CRM_FORM.PROCESSOR_DETAILS_TABLE);
    removeLocalStorage(CRM_FORM.GATEWAY_DETAILS);
    removeLocalStorage(CRM_FORM.GATEWAY_DETAILS_TABLE);
    removeLocalStorage(CRM_FORM.MERCHANT_DETAILS);
    removeLocalStorage(CRM_FORM.USER_DETAILS);
    removeLocalStorage(CRM_FORM.ACTIVE_STEP);
  };

  useEffect(() => {
    const params = {
      limit: limit,
      page: activePageNumber,
      ...searchParams,
    };
    if (!isAuthorized && !apiToken) {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
    getClientDispatch(
      params,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  }, []);

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
    setActivePageNumber(1);
    const params = {
      limit: value,
      page: 1,
      ...searchParams,
    };
    getClientDispatch(
      params,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const paginationParams = {
      limit: limit,
      page: pageNumber,
      ...searchParams,
    };
    setActivePageNumber(pageNumber);
    getClientDispatch(
      paginationParams,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const handleSorting = (name) => {
    if (sorting && sorting[name]) {
      sorting[name] = !sorting[name];
      setSorting(sorting);
      const params = {
        ...searchParams,
        limit: limit,
        page: activePageNumber,
        sort: name,
        order: "DESC",
      };
      getClientDispatch(
        params,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
    } else {
      const filter = _.mapValues(sorting, () => {
        return false;
      });
      filter[name] = !filter[name];
      setSorting(filter);
      const params = {
        ...searchParams,
        limit: limit,
        page: activePageNumber,
        sort: name,
        order: "ASC",
      };
      getClientDispatch(
        params,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
    }
  };

  const getDataLength = getClient && getClient.count ? getClient.count : 0;

  const totalPages = getDataLength
    ? Math.ceil(parseInt(getDataLength) / limit)
    : 1;

  return (
    <>
      <div className={`card ${className}`}>
        <div className="card-body py-3">
          <div className="d-flex  px - 2">
            <div className="d-flex justify-content-start col-md-6">
              <div className="col-md-3 mt-1">
                {getDataLength ? (
                  <span className="text-muted fw-bold d-flex fs-5 mt-2">
                    Total: &nbsp;{" "}
                    <span className="text-gray-700 fw-bolder text-hover-primary fs-5">
                      {getDataLength}
                    </span>
                  </span>
                ) : null}
              </div>
              <div className="col-md-7 d-flex">
                <label className="col-form-label text-lg-start">
                  Record(s) per Page : &nbsp;{" "}
                </label>
                <div className="col-md-3">
                  <select
                    className="form-select w-6rem"
                    data-control="select"
                    data-placeholder="Select an option"
                    data-allow-clear="true"
                    onChange={(e) => handleRecordPerPage(e)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="d-flex col-md-6 justify-content-end my-auto">
              {userType &&
              !userType.includes("Client User") &&
              url === routeConfig.clientManagement ? (
                <>
                  <button
                    className="btn btn-sm btn-light-primary me-3 font-5vw pull-right"
                    onClick={() => {
                      history.push("/client-management/sdk");
                    }}
                  >
                    <KTSVG path="/media/icons/duotune/files/fil003.svg" />
                    Generate SDK
                  </button>
                </>
              ) : null}
              <div className="my-auto me-3">
                <SearchList
                  setSearchParams={setSearchParams}
                  limit={limit}
                  activePageNumber={activePageNumber}
                  setActivePageNumber={setActivePageNumber}
                />
              </div>
              {isAuthorized ? (
                <Can
                  permissons={currentPermissons}
                  componentPermissions={CREATE_PERMISSION}
                >
                  <Link
                    to="/client-onboarding"
                    className="btn btn-sm btn-light-primary me-3 font-5vw pull-right"
                    onClick={() => {
                      removeLocalStorage(SAVE_CURRENT.CLIENT_ID);
                    }}
                  >
                    {/* eslint-disable */}
                    <KTSVG path="/media/icons/duotune/arrows/arr087.svg" />
                    Onboard Client
                    {/* eslint-enable */}
                  </Link>
                </Can>
              ) : null}
            </div>
          </div>
          <div className="table-responsive">
            <table className="table  table-rounded table-striped table-hover border gs-2 mt-6">
              <thead className="fw-bolder fs-8 text-gray-800">
                <tr>
                  <Can
                    permissons={currentPermissons}
                    componentPermissions={UPDATE_PERMISSION}
                  >
                    <th>
                      <div className="d-flex">
                        <span>Action</span>
                      </div>
                    </th>
                  </Can>
                  <th>
                    <div className=" d-flex">
                      <span>Company</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("companyName")}
                        >
                          <i
                            className={`bi ${
                              sorting.companyName
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Country</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("clientCountry")}
                        >
                          <i
                            className={`bi ${
                              sorting.clientCountry
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Industry</span>
                      <div className="text-end min-w-25px ">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("customerId")}
                        >
                          <i
                            className={`bi ${
                              sorting.customerId
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Cutomer ID</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("companyName")}
                        >
                          <i
                            className={`bi ${
                              sorting.companyName
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Address</span>
                      <div className="min-w-75px text-end ellipsis">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("clientAddress")}
                        >
                          <i
                            className={`bi ${
                              sorting.clientAddress
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>City</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("clientCity")}
                        >
                          <i
                            className={`bi ${
                              sorting.clientCity
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>State</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("clientState")}
                        >
                          <i
                            className={`bi ${
                              sorting.clientState
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Zip</span>
                      <div className="min-w-40px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("clientZip")}
                        >
                          <i
                            className={`bi ${
                              sorting.clientZip
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>

                  <th>
                    <div className="d-flex">
                      <span>Email</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("clientEmail")}
                        >
                          <i
                            className={`bi ${
                              sorting.clientEmail
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Phone</span>
                      <div className="min-w-40px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("clientPhoneNumber")}
                        >
                          <i
                            className={`bi ${
                              sorting.clientPhoneNumber
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Extension</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("ext")}
                        >
                          <i
                            className={`bi ${
                              sorting.ext
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>URL</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("url")}
                        >
                          <i
                            className={`bi ${
                              sorting.url
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Source</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("source")}
                        >
                          <i
                            className={`bi ${
                              sorting.source
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Client Complexity</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("clientComplexity")}
                        >
                          <i
                            className={`bi ${
                              sorting.clientComplexity
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Currency</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("currency")}
                        >
                          <i
                            className={`bi ${
                              sorting.currency
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      <span>Company Description</span>
                      <div className="min-w-25px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("companyDescription")}
                        >
                          <i
                            className={`bi ${
                              sorting.companyDescription
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>

                  <th>
                    <div className="d-flex">
                      <span>Created By</span>
                      <div className="min-w-40px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("createdBy")}
                        >
                          <i
                            className={`bi ${
                              sorting.createdBy
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>

                  <th>
                    <div className="d-flex">
                      <span>Updated By</span>
                      <div className="min-w-40px text-end">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSorting("updatedBy")}
                        >
                          <i
                            className={`bi ${
                              sorting.updatedBy
                                ? "bi-arrow-up-circle-fill"
                                : "bi-arrow-down-circle"
                            } text-primary`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-8">
                {!loading ? (
                  getDataLength ? (
                    getClient &&
                    getClient.result.map((client, _id) => {
                      return (
                        <tr
                          key={_id}
                          style={
                            _id === 0
                              ? { borderColor: "black" }
                              : { borderColor: "white" }
                          }
                        >
                          <Can
                            permissons={currentPermissons}
                            componentPermissions={UPDATE_PERMISSION}
                          >
                            <td className="text-center">
                              <Link
                                to={`/client-onboarding/update/${client._id}`}
                                onClick={() => {
                                  saveClientIdDispatch(client._id);
                                  setLocalStorage(
                                    SAVE_CURRENT.CLIENT_ID,
                                    client._id
                                  );
                                  removeFormStorage();
                                }}
                                className="btn btn-icon btn-icon-warning btn-sm w-10px h-10px "
                              >
                                <KTSVG
                                  path="/media/icons/duotune/art/art005.svg"
                                  className="svg-icon-3"
                                />
                              </Link>
                            </td>
                          </Can>
                          <td>{client.company ? client.company : "--"}</td>
                          <td>
                            {client.clientCountry && client.clientCountry.name
                              ? client.clientCountry.name
                              : "--"}
                          </td>
                          <td>
                            {client.industry &&
                            client.industry.industryType &&
                            client.industry.industryType
                              ? client.industry.industryType
                              : "--"}
                          </td>
                          <td>
                            {client.customerId ? client.customerId : "--"}
                          </td>
                          <td>
                            {client.clientAddress ? client.clientAddress : "--"}
                          </td>
                          <td>
                            {client.clientCity && client.clientCity.name
                              ? client.clientCity.name
                              : "--"}
                          </td>
                          <td>
                            {client.clientState && client.clientState.name
                              ? client.clientState.name
                              : "--"}
                          </td>
                          <td>{client.clientZip ? client.clientZip : "--"}</td>
                          <td>
                            {client.clientEmail ? client.clientEmail : "--"}
                          </td>
                          <td>
                            {client.clientPhoneNumber
                              ? client.clientPhoneNumber
                              : "--"}
                          </td>
                          <td>
                            {client.phoneNumberExtension
                              ? client.phoneNumberExtension
                              : "--"}
                          </td>
                          <td>{client.clientURL ? client.clientURL : "--"}</td>
                          <td>
                            {client.leadSource ? client.leadSource : "--"}
                          </td>
                          <td>
                            {client.clientComplexity
                              ? client.clientComplexity
                              : "--"}
                          </td>
                          <td>{client.currency ? client.currency : "--"}</td>
                          <td>
                            {client.clientDescription
                              ? client.clientDescription
                              : "--"}
                          </td>
                          <td>{client.createdBy ? client.createdBy : "--"}</td>
                          <td>{client.updatedBy ? client.updatedBy : "--"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center py-3">
                      <td colSpan="100%">No record(s) found</td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="100%" className="text-center">
                      <div
                        className="spinner-border text-primary m-5"
                        role="status"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="form-group row mb-4 mt-6">
            <div className="col-lg-12 mb-4 align-items-end d-flex">
              <div className="col-lg-12 pagination">
                <ReactPaginate
                  nextLabel="Next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  forcePage={activePageNumber - 1}
                  pageCount={totalPages}
                  previousLabel="< Prev"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { clientStore } = state;
  return {
    getClient:
      clientStore && clientStore.getClient ? clientStore.getClient : [],
    loading: clientStore && clientStore.loading ? clientStore.loading : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientDispatch: (params, headers) =>
    dispatch(clientActions.getClient(params, headers)),
  saveClientIdDispatch: (data) => dispatch(getClientIdActions.save(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
