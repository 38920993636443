export const getSubscriptionHistoryActionsTypes = {
  REQUEST: 'GET_ALL_SUBSCRIPTION_HISTORY_REQUEST',
  RESPONSE: 'GET_ALL_SUBSCRIPTION_HISTORY_RESPONSE',
  ERROR: 'GET_ALL_SUBSCRIPTION_HISTORY_ERROR',
  CLEAR: 'GET_ALL_SUBSCRIPTION_HISTORY_CLEAR'
}

export const getSubscriptionHistoryActions = {
  get: (params, headers) => ({
    type: getSubscriptionHistoryActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getSubscriptionHistoryActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getSubscriptionHistoryActionsTypes.CLEAR
  })
}

export const addSubscriptionHistoryActionsTypes = {
  REQUEST: 'ADD_ALL_SUBSCRIPTION_HISTORY_REQUEST',
  RESPONSE: 'ADD_ALL_SUBSCRIPTION_HISTORY_RESPONSE',
  ERROR: 'ADD_ALL_SUBSCRIPTION_HISTORY_ERROR',
  CLEAR: 'ADD_ALL_SUBSCRIPTION_HISTORY_CLEAR'
}

export const addSubscriptionHistoryActions = {
  add: (data, headers) => {
    return {
      type: addSubscriptionHistoryActionsTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addSubscriptionHistoryActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addSubscriptionHistoryActionsTypes.CLEAR
  })
}

export const getSubscriptionHistoryDetailsActionsTypes = {
  REQUEST: 'GET_ALL_SUBSCRIPTION_HISTORY_DETAILS_REQUEST',
  RESPONSE: 'GET_ALL_SUBSCRIPTION_HISTORY_DETAILS_RESPONSE',
  ERROR: 'GET_ALL_SUBSCRIPTION_HISTORY_DETAILS_ERROR',
  CLEAR: 'GET_ALL_SUBSCRIPTION_HISTORY_DETAILS_CLEAR'
}
export const getSubscriptionHistoryDetailsActions = {
  getDetails: (id, headers) => ({
    type: getSubscriptionHistoryDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getSubscriptionHistoryDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getSubscriptionHistoryDetailsActionsTypes.CLEAR
  })
}

export const updateSubscriptionHistoryActionsTypes = {
  REQUEST: 'UPDATE_ALL_SUBSCRIPTION_HISTORY_REQUEST',
  RESPONSE: 'UPDATE_ALL_SUBSCRIPTION_HISTORY_RESPONSE',
  ERROR: 'UPDATE_ALL_SUBSCRIPTION_HISTORY_ERROR',
  CLEAR: 'UPDATE_ALL_SUBSCRIPTION_HISTORY_CLEAR'
}

export const updateSubscriptionHistoryActions = {
  update: (id, params, headers) => {
    return {
      type: updateSubscriptionHistoryActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updateSubscriptionHistoryActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updateSubscriptionHistoryActionsTypes.CLEAR
  })
}

export const deleteSubscriptionHistoryActionsTypes = {
  REQUEST: 'DELETE_ALL_SUBSCRIPTION_HISTORY_REQUEST',
  RESPONSE: 'DELETE_ALL_SUBSCRIPTION_HISTORY_RESPONSE',
  ERROR: 'DELETE_ALL_SUBSCRIPTION_HISTORY_ERROR',
  CLEAR: 'DELETE_ALL_SUBSCRIPTION_HISTORY_CLEAR'
}

export const deleteSubscriptionHistoryActions = {
  delete: (params, headers) => ({
    type: deleteSubscriptionHistoryActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: deleteSubscriptionHistoryActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteSubscriptionHistoryActionsTypes.CLEAR
  })
}
