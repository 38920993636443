export const issuerbanklistActionsTypes = {
  GET_ISSUER_BANK_LIST: 'GET_ISSUER_BANK_LIST',
  SAVE_ISSUER_BANK_LIST_RESPONSE: 'SAVE_ISSUER_BANK_LIST_RESPONSE',
  CLEAR_ISSUER_BANK_LIST: 'CLEAR_ISSUER_BANK_LIST'
}

export const issuerbanklistActions = {
  getIssuerbanklist: (params, headers) => ({
    type: issuerbanklistActionsTypes.GET_ISSUER_BANK_LIST,
    params, headers
  }),
  saveIssuerbanklistResponse: (data) => ({
    type: issuerbanklistActionsTypes.SAVE_ISSUER_BANK_LIST_RESPONSE,
    data
  }),
  clearIssuerbanklist: () => ({
    type: issuerbanklistActionsTypes.CLEAR_ISSUER_BANK_LIST
  })
}

export const getIssuerBankExportColumnsActionsTypes = {
  GET_EXPORT_COLUMNS: 'GET_EXPORT_COLUMNS',
  CLEAR_COLUMNS: 'CLEAR_COLUMNS'
}

export const getIssuerBankColumnsActions = {
  getOrderinsightExportColumns: (data) => ({
    type: getIssuerBankExportColumnsActionsTypes.GET_EXPORT_COLUMNS,
    data
  }),
  clearIssuerBankColumns: () => ({
    type: getIssuerBankExportColumnsActionsTypes.CLEAR_COLUMNS
  })
}

export const issuerbankalertlistActionsTypes = {
  GET_ISSUER_BANK_ALERT_LIST: 'GET_ISSUER_BANK_ALERT_LIST',
  SAVE_ISSUER_BANK_ALERT_LIST_RESPONSE: 'SAVE_ISSUER_BANK_ALERT_LIST_RESPONSE',
  CLEAR_ISSUER_BANK_ALERT_LIST: 'CLEAR_ISSUER_BANK_ALERT_LIST'
}

export const issuerbankalertlistActions = {
  getIssuerbankalertlist: (params, headers) => ({
    type: issuerbankalertlistActionsTypes.GET_ISSUER_BANK_ALERT_LIST,
    params, headers
  }),
  saveIssuerbankalertlistResponse: (data) => ({
    type: issuerbankalertlistActionsTypes.SAVE_ISSUER_BANK_ALERT_LIST_RESPONSE,
    data
  }),
  clearIssuerbankalertlist: () => ({
    type: issuerbankalertlistActionsTypes.CLEAR_ISSUER_BANK_ALERT_LIST
  })
}

export const getIssuerBankAlertExportColumnsActionsTypes = {
  GET_ALERT_EXPORT_COLUMNS: 'GET_ALERT_EXPORT_COLUMNS',
  CLEAR_ALERT_COLUMNS: 'CLEAR_ALERT_COLUMNS'
}

export const getIssuerBankAlertColumnsActions = {
  getOrderinsightExportColumns: (data, headers) => ({
    type: getIssuerBankAlertExportColumnsActionsTypes.GET_ALERT_EXPORT_COLUMNS,
    data, headers
  }),
  clearIssuerBankColumns: () => ({
    type: getIssuerBankAlertExportColumnsActionsTypes.CLEAR_ALERT_COLUMNS
  })
}
