export const getPartnerActionTypes = {
  GET_FILTERS_PARTNER: 'GET_FILTERS_PARTNER',
  SAVE_FILTERS_PARTNER_RESPONSE: 'SAVE_FILTERS_PARTNER_RESPONSE',
  CLEAR_FILTERS_PARTNER: 'CLEAR_FILTERS_PARTNER',
  SET_FILTERS_FUNCTION: 'SET_FILTERS_FUNCTION',
  SET_FILTERS_PARAMS: 'SET_FILTERS_PARAMS'
}

export const partnerCredFilterActions = {
  getAuthPartner: (params, headers) => ({
    type: getPartnerActionTypes.GET_FILTERS_PARTNER,
    params,
    headers
  }),
  saveAuthPartnerResponse: data => ({
    type: getPartnerActionTypes.SAVE_FILTERS_PARTNER_RESPONSE,
    data
  }),
  clearAuthPartner: () => ({
    type: getPartnerActionTypes.CLEAR_FILTERS_PARTNER
  }),
  setFilterFunction: (data) => ({
    type: getPartnerActionTypes.SET_FILTERS_FUNCTION,
    data
  }),
  setCredFilterParams: (data) => ({
    type: getPartnerActionTypes.SET_FILTERS_PARAMS,
    data
  })
}

export const clientCredFilterActionsTypes = {
  GET_FILTER_CLIENT: 'GET_FILTER_CLIENT',
  SAVE_FILTER_CLIENT_RESPONSE: 'SAVE_FILTER_CLIENT_RESPONSE',
  CLEAR_FILTER_CLIENT: 'CLEAR_FILTER_CLIENT'
}

export const clientCredFilterActions = {
  getFilterClient: (params, headers) => ({
    type: clientCredFilterActionsTypes.GET_FILTER_CLIENT,
    params,
    headers
  }),
  saveFilterClientResponse: data => ({
    type: clientCredFilterActionsTypes.SAVE_FILTER_CLIENT_RESPONSE,
    data
  }),
  clearFilterClient: () => ({
    type: clientCredFilterActionsTypes.CLEAR_FILTER_CLIENT
  })
}

export const merchantCredFilterActionsTypes = {
  GET_MERCHANT_FILETER: 'GET_MERCHANT_FILETER',
  SAVE_MERCHANT_FILETER_RESPONSE: 'SAVE_MERCHANT_FILETER_RESPONSE',
  CLEAR_MERCHANT_FILETER: 'CLEAR_MERCHANT_FILETER'
}

export const merchantCredFilterActions = {
  getMerchant: (params, headers) => ({
    type: merchantCredFilterActionsTypes.GET_MERCHANT_FILETER,
    params,
    headers
  }),
  savemerchantFilterResponse: data => ({
    type: merchantCredFilterActionsTypes.SAVE_MERCHANT_FILETER_RESPONSE,
    data
  }),
  clearMerchant: () => ({
    type: merchantCredFilterActionsTypes.CLEAR_MERCHANT_FILETER
  })
}

export const midCredFilterActionsTypes = {
  GET_MID_FILTER: 'GET_MID_CRED_FILTER',
  SAVE_MID_FILTER_RESPONSE: 'SAVE_MID_CRED_FILTER_RESPONSE',
  CLEAR_MID_FILTER: 'CLEAR_MID_CRED_FILTER'
}

export const midCredFilterActions = {
  getmidFilter: (params, headers) => ({
    type: midCredFilterActionsTypes.GET_MID_FILTER,
    params,
    headers
  }),
  savemidFilterResponse: data => ({
    type: midCredFilterActionsTypes.SAVE_MID_FILTER_RESPONSE,
    data
  }),
  clearmidFilter: () => ({
    type: midCredFilterActionsTypes.CLEAR_MID_FILTER
  })
}
