import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from "react-redux";
import ClientSignup from './clientSignUp/ClientSignup'
import RedirectPage from './clientSignUp/redirectPageSignup'
import GetStarted from './clientSignUp/getStartedPage'
import SelectCrm from './clientSignUp/crmSelect'
import SelectProcessor from './clientSignUp/processorSelect'
import SelectService from './clientSignUp/serviceSelect'
import SelectPackage from './clientSignUp/packageSelect'
import PackageSubs from './clientSignUp/subscriptionClient'
import ContactDetails from './clientSignUp/contactDetails'
import { getClientSignupActions } from "../../store/actions";

const ClientSignupPage = (props) => {
  return (
    <Switch>
      <Route path='/contact-details'>
        <ContactDetails />
      </Route>
      <Route path='/package-subscription'>
        <PackageSubs />
      </Route>
      <Route path='/select-package'>
        <SelectPackage />
      </Route>
      <Route path='/select-service'>
        <SelectService />
      </Route>
      <Route path='/select-processor'>
        <SelectProcessor />
      </Route>
      <Route path='/select-crm'>
        <SelectCrm />
      </Route>
      <Route path='/get-started/:id'>
        <GetStarted />
      </Route>
      <Route path='/client-signup'>
        <RedirectPage />
      </Route>
      <Route path='/client-signup-page'>
        <ClientSignup />
      </Route>
    </Switch>
  )
}

const mapStateToProps = (state) => {
  const { getClientSignupStore } = state;
  return {
      didMount:
        getClientSignupStore && getClientSignupStore.didMount
          ? getClientSignupStore.didMount
          : false,
      getClientSignup:
        getClientSignupStore && getClientSignupStore.getClientSignup
          ? getClientSignupStore.getClientSignup
          : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  didMountActions: (val) => dispatch(getClientSignupActions.didMount(val)),
  getClientSignupDispatch: (id) => dispatch(getClientSignupActions.get(id)),
  clearClientSignupDispatch: () => dispatch(getClientSignupActions.clear())
});


export default connect(mapStateToProps, mapDispatchToProps)(ClientSignupPage);