export const addEmailResendActionsTypes = {
  REQUEST: 'ADD_RESEND_EMAIL_REQUEST',
  RESPONSE: 'ADD_RESEND_EMAIL_RESPONSE',
  ERROR: 'ADD_RESEND_EMAIL_ERROR',
  CLEAR: 'ADD_RESEND_EMAIL_CLEAR'
}

export const addEmailResendActions = {
  add: (data, headers) => {
    return {
      type: addEmailResendActionsTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addEmailResendActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addEmailResendActionsTypes.CLEAR
  })
}