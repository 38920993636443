import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactSelect from "../../../../../theme/layout/components/ReactSelect";
import TextSunEditor from "../../../../../theme/layout/components/SunEditor";
import color from "../../../../../utils/colors";
import { STATUS_RESPONSE, SESSION, API } from "../../../../../utils/constants";
import { getLocalStorage, headersWithAuth } from "../../../../../utils/helper";
import {
  addMerchantTemplateActions,
  variableActions,
  templateDetailsActions,
  editTemplatesActions,
  notifyActions,
  addNotifyActions,
} from "../../../../../store/actions";
import { warningAlert, confirmAlert } from "../../../../../utils/alerts";
import { setTemplateData } from "./formData";
import { emailTemplateValidation } from "./validation";
import _ from "lodash";

const AddEmailTemplate = (props) => {
  const {
    addMerchantTemplateDispatch,
    clearaddMerchantTemplateDispatch,
    getVariableDispatch,
    variableData,
    messageAMTemplate,
    statusAMTemplate,
    loadingAMTemplate,
    getTemplateDetailsDispatch,
    templateDetails,
    statusTEMD,
    loadingTEMD,
    editTemplateDispatch,
    clearEditTemplateDispatch,
    statusETEM,
    messageETEM,
    loadingETEM,
    clearTemplateDetailsDispatch,
    addNotifyDispatch,
    getNotifyDispatch,
    getNotify,
    loadingANT,
    statusANT,
    messageANT,
    loading,
    clearaddNotifyDispatch,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };

  const history = useHistory();
  const url = useLocation().pathname;
  const fields = url && url.split("update/");
  const prevId = fields && fields[1];
  const currenId = prevId && prevId.split("/add-template");
  const currentId = prevId && prevId.split("/update-template");
  const merchantId = currenId && currenId[0];
  const merchantUpdateId = currentId && currentId[0];
  const templateId = prevId && prevId.split("/update-template/");
  const updateId = templateId && templateId[1];
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [copiedText, setCopyText] = useState("");
  const [selectedNotifyOptions, setSelectedNotifyOptions] = useState();
  const [notifyOptions, setNotifyOptions] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [formData, setFormData] = useState({
    clientId: merchantId,
    notificationType: "",
    subject: "",
    emailTemplate: "",
  });

  const [othersNotificationType, setOthersNotificationType] = useState("");

  const resetState = (e) => {
    setSelectedNotifyOptions();
    setFormData({
      notificationType: "",
      subject: "",
      emailTemplate: "",
    });
  };

  const handleChange = (e) => {
    e.persist();
    e.preventDefault();
    if (e.target.name === "othersNotificationType") {
      setOthersNotificationType(e.target.value);
    } else {
      setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
      setErrors({ ...errors, [e.target.name]: "" });
    }
  };

  const handleSubmit = () => {
    const errorMsg = emailTemplateValidation(
      formData,
      setErrors,
      othersNotificationType
    );
    if (_.isEmpty(errorMsg)) {
      const payload = {
        clientId: editMode ? merchantUpdateId : merchantId,
        notificationType: othersNotificationType
          ? othersNotificationType
          : formData.notificationType,
        subject: formData.subject,
        emailTemplate: formData.emailTemplate,
      };
      if (editMode) {
        editTemplateDispatch(
          updateId,
          payload,
          isAuthorized ? headersWithAuth : headercurrentWithApiKey
        );
      } else {
        addMerchantTemplateDispatch(
          payload,
          isAuthorized ? headersWithAuth : headercurrentWithApiKey
        );
      }
    }
  };

  const handleNotify = () => {
    const data = {
      notificationType: othersNotificationType,
    };
    addNotifyDispatch(
      data,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const customStyles = {
    notifyOptions: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 25,
    }),
  };

  useEffect(() => {
    getNotifyDispatch(isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  }, []);

  const getDefaultOptions = (data, name) => {
    const defaultOptions = [{ label: "Others...", value: "others" }];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item[name],
        })
      );
      return defaultOptions;
    }
  };

  useEffect(() => {
    const data = getDefaultOptions(getNotify, "notificationType");
    setNotifyOptions(data);
  }, [getNotify]);

  const handleSelectNotify = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          const notifyParams = {
            skipPagination: true,
            notificationType: selectedOption.value,
          };
          getVariableDispatch(
            notifyParams,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      );
      setSelectedNotifyOptions(selectedOption);
      setFormData((values) => ({
        ...values,
        notificationType: selectedOption.value,
      }));
      setErrors({
        ...errors,
        notificationType: "",
        othersNotificationType: "",
      });
      setOthersNotificationType("");
    } else {
      setSelectedNotifyOptions();
      setOthersNotificationType("");
      setFormData((values) => ({ ...values, notificationType: "" }));
    }
  };

  const onConfirm = () => {
    resetState();
    history.push(
      editMode
        ? `/client-onboarding/update/${merchantUpdateId}`
        : `/client-onboarding/update/${merchantId}`
    );
  };

  const onANTConfirm = () => {};

  useEffect(() => {
    if (statusETEM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageETEM,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearEditTemplateDispatch();
    } else if (statusETEM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageETEM, "", "Ok");
      clearEditTemplateDispatch();
    }
  }, [statusETEM]);

  useEffect(() => {
    if (statusAMTemplate === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAMTemplate,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearaddMerchantTemplateDispatch();
    } else if (statusAMTemplate === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAMTemplate, "", "Ok");
      clearaddMerchantTemplateDispatch();
    }
  }, [statusAMTemplate]);

  useEffect(() => {
    if (statusANT === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageANT,
        "success",
        "Ok",
        () => {
          onANTConfirm();
        },
        () => {
          onANTConfirm();
        }
      );
      clearaddNotifyDispatch();
    } else if (statusANT === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageANT, "", "Ok");
      clearaddNotifyDispatch();
    }
  }, [statusANT]);

  useEffect(() => {
    if (statusTEMD === STATUS_RESPONSE.SUCCESS_MSG) {
      const setData = setTemplateData(templateDetails);
      setFormData(setData);
    }
    if (templateDetails.notificationType) {
      const getDefaultOptions = [
        {
          label: templateDetails.notificationType,
          value: templateDetails.notificationType,
        },
      ];
      setSelectedNotifyOptions(getDefaultOptions);
      const notifyEditParams = {
        skipPagination: true,
        notificationType: templateDetails.notificationType,
      };
      getVariableDispatch(
        notifyEditParams,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    }
    clearTemplateDetailsDispatch();
  }, [statusTEMD]);

  useEffect(() => {
    if (updateId) {
      setEditMode(true);
      getTemplateDetailsDispatch(
        updateId,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    } else {
      setEditMode(false);
    }
  }, [updateId]);

  const handleCopy = (id) => {
    const copyText = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    setCopyText(id);
    setTimeout(() => {
      setCopyText("");
    }, 1000);
  };

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  return (
    <>
      <div className="card-header bg-skyBlue py-0">
        <div className="card-body p-8">
          <div className="d-flex justify-content-end">
            {updateId ? (
              <Link
                to={`/client-onboarding/update/${merchantUpdateId}`}
                className="btn btn-darkRed fa-pull-right"
                onClick={() => {
                  resetState();
                }}
              >
                Back
              </Link>
            ) : (
              <Link
                to={`/client-onboarding/update/${merchantId}`}
                className="btn btn-darkRed fa-pull-right"
                onClick={() => {
                  resetState();
                }}
              >
                Back
              </Link>
            )}
          </div>
          {loadingTEMD ? (
            <div className="d-flex justify-content-center py-5">
              <div className="spinner-border text-primary m-5" role="status" />
            </div>
          ) : (
            <>
              <div className="card-body p-8">
                <div className="row mt-2">
                  <div className="col-lg-6">
                    <label className="required fw-bold fs-6 mb-2">
                      Notification Type
                    </label>
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name="notificationType"
                      className="basic-single"
                      classNamePrefix="select"
                      handleChangeReactSelect={handleSelectNotify}
                      options={notifyOptions}
                      value={selectedNotifyOptions}
                      isLoading={loading}
                    />
                    {errors && errors.notificationType && (
                      <div className="rr mt-1">
                        <style>{".rr{color:red}"}</style>
                        {errors.notificationType}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <label className="required fw-bold fs-6 mb-2">
                      Subject
                    </label>
                    <textarea
                      name="subject"
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                      onChange={(e) => handleChange(e)}
                      value={formData.subject || ""}
                      autoComplete="off"
                    />
                    {errors && errors.subject && (
                      <div className="rr mt-1">
                        <style>{".rr{color:red}"}</style>
                        {errors.subject}
                      </div>
                    )}
                  </div>
                </div>
                {formData.notificationType === "others" ? (
                  <div className="row mt-2 mb-5">
                    <div className="col-lg-6">
                      <label className="required fw-bold fs-6 mb-2">
                        Other Notification Type
                      </label>
                      <input
                        name="othersNotificationType"
                        type="text"
                        className="form-control"
                        placeholder="Other Notification Type"
                        autoComplete="off"
                        maxLength={42}
                        onChange={(e) => handleChange(e)}
                        value={othersNotificationType || ""}
                      />
                      {errors && errors.othersNotificationType ? (
                        <div className="rr mt-1">
                          <style>{".rr{color:red}"}</style>
                          {errors.othersNotificationType}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-2">
                      <button
                        className="btn btn-sm btn-success mt-9"
                        onClick={(e) => {
                          handleNotify(e);
                        }}
                      >
                        {loadingANT ? (
                          <span
                            className="spinner-border spinner-border-sm mx-3"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                ) : null}
                <div className="row mt-2">
                  <div className="col-lg-7">
                    <label className="required fw-bold fs-6 mb-2">
                      Template
                    </label>
                    <TextSunEditor
                      setData={setFormData}
                      contentData={formData.emailTemplate}
                      name="emailTemplate"
                      minHeight={"400px"}
                    />
                    {errors && errors.emailTemplate && (
                      <div className="rr mt-1">
                        <style>{".rr{color:red}"}</style>
                        {errors.emailTemplate}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 mt-8">
                    {variableData &&
                      variableData.data?.map((variable, i) => {
                        return (
                          <div className="card-big-shadow">
                            <div className="card min-w-300px">
                              <h5 className="text-center my-2">
                                Email Template Dynamic Variables
                              </h5>
                              <div className="scroll h-500px ms-6 mt-3" key={i}>
                                {variable.dynamicVariable?.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        className="input-group mb-5"
                                        key={index}
                                      >
                                        <input
                                          type="text"
                                          id={item}
                                          value={item}
                                          className="list-group-item list-group-item-primary rounded-0"
                                          style={{
                                            width: "225px",
                                            marginLeft: "10px",
                                          }}
                                          aria-describedby="basic-addon2"
                                        />
                                        <span
                                          className={`list-group-item rounded-0 list-group-item-${
                                            copiedText === `${item}`
                                              ? "success"
                                              : "primary"
                                          }`}
                                          id="basic-addon2"
                                        >
                                          <i
                                            className={`bi bi-clipboard${
                                              copiedText === `${item}`
                                                ? "-check"
                                                : ""
                                            } cursor-pointer`}
                                            onClick={() => {
                                              handleCopy(item);
                                            }}
                                          />
                                        </span>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="form-group row mt-4">
                  <div className="col-lg-5" />
                  <div className="col-lg-7">
                    <div className="col-lg-12">
                      <button
                        className="btn btn-blue mt-7 fa-pull-right"
                        onClick={(event) => {
                          handleSubmit(event);
                        }}
                      >
                        {loadingAMTemplate || loadingETEM ? (
                          <span
                            className="spinner-border spinner-border-sm mx-3"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    addMerchantTemplateStore,
    variableStore,
    templateStore,
    editTemplateStore,
    notifyStore,
    addNotifyStore,
  } = state;
  return {
    messageAMTemplate:
      addMerchantTemplateStore && addMerchantTemplateStore.messageAMTemplate
        ? addMerchantTemplateStore.messageAMTemplate
        : 0,
    statusAMTemplate:
      addMerchantTemplateStore && addMerchantTemplateStore.statusAMTemplate
        ? addMerchantTemplateStore.statusAMTemplate
        : {},
    loadingAMTemplate:
      addMerchantTemplateStore && addMerchantTemplateStore.loadingAMTemplate
        ? addMerchantTemplateStore.loadingAMTemplate
        : false,
    variableData:
      variableStore && variableStore.variableData
        ? variableStore.variableData
        : [],
    variableLoading:
      state && state.variableStore && state.variableStore.variableLoading,
    statusETEM:
      editTemplateStore && editTemplateStore.statusETEM
        ? editTemplateStore.statusETEM
        : "",
    messageETEM:
      editTemplateStore && editTemplateStore.messageETEM
        ? editTemplateStore.messageETEM
        : "",
    loadingETEM:
      editTemplateStore && editTemplateStore.loadingETEM
        ? editTemplateStore.loadingETEM
        : false,
    templateDetails:
      templateStore && templateStore.templateDetails
        ? templateStore.templateDetails
        : {},
    statusTEMD:
      templateStore && templateStore.statusTEMD ? templateStore.statusTEMD : "",
    messagesTEMD:
      templateStore && templateStore.messagesTEMD
        ? templateStore.messagesTEMD
        : "",
    loadingTEMD:
      templateStore && templateStore.loadingTEMD
        ? templateStore.loadingTEMD
        : false,
    getNotify: state && state.notifyStore && state.notifyStore.getNotify,
    loading: state && state.notifyStore && state.notifyStore.loading,
    statusANT:
      addNotifyStore && addNotifyStore.statusANT
        ? addNotifyStore.statusANT
        : "",
    messagesANT:
      addNotifyStore && addNotifyStore.messagesANT
        ? addNotifyStore.messagesANT
        : "",
    loadingANT:
      state && state.addNotifyStore && state.addNotifyStore.loadingANT,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addMerchantTemplateDispatch: (data, headers) =>
    dispatch(addMerchantTemplateActions.addMerchantTemplate(data, headers)),
  clearaddMerchantTemplateDispatch: () =>
    dispatch(addMerchantTemplateActions.clearaddMerchantTemplate()),
  getVariableDispatch: (data, headers) =>
    dispatch(variableActions.getVariable(data, headers)),
  editTemplateDispatch: (id, data, headers) =>
    dispatch(editTemplatesActions.editTemplates(id, data, headers)),
  clearEditTemplateDispatch: () =>
    dispatch(editTemplatesActions.cleareditTemplates()),
  getTemplateDetailsDispatch: (data, headers) =>
    dispatch(templateDetailsActions.getTemplateDetails(data, headers)),
  clearTemplateDetailsDispatch: () =>
    dispatch(templateDetailsActions.clearTemplateDetails()),
  getNotifyDispatch: (headers) => dispatch(notifyActions.get(headers)),
  notifyActions: (data, headers) => dispatch(notifyActions.get(data, headers)),
  addNotifyDispatch: (data, headers) =>
    dispatch(addNotifyActions.add(data, headers)),
  clearaddNotifyDispatch: () => dispatch(addNotifyActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmailTemplate);
