export const UserNotificationActionsTypes = {
  USER_NOTIFICATION_INIT: 'USER_NOTIFICATION_INIT',
  USER_NOTIFICATION_SUCCESS: 'USER_NOTIFICATION_SUCCESS',
  USER_NOTIFICATION_CLEAR: 'USER_NOTIFICATION_CLEAR'
}

export const UserNotificationActions = {

  UserNotificationInit: (params,headers) => ({
    type: UserNotificationActionsTypes.USER_NOTIFICATION_INIT,
    payload: { params },
    headers
  }),
  UserNotificationSuccess: data => ({
    type: UserNotificationActionsTypes.USER_NOTIFICATION_SUCCESS,
    data
  }),
  UserNotificationClear: () => ({
    type: UserNotificationActionsTypes.USER_NOTIFICATION_CLEAR
  })
}

// export const editUserProfileTypes = {
//   REQUEST: 'EDIT_USER_PROFILE_REQUEST',
//   RESPONSE: 'EDIT_USER_PROFILE_RESPONSE',
//   ERROR: 'EDIT_USER_PROFILE_ERROR',
//   CLEAR: 'EDIT_USER_PROFILE_CLEAR'
// }

// export const editUserProfileActions = {
//   editUserProfile: (params, headers) => {
//     return {
//       type: editUserProfileTypes.REQUEST,
//       payload: { params },
//       headers
//     }
//   },
//   saveeditUserProfileResponse: (data) => {
//     return {
//       type: editUserProfileTypes.RESPONSE,
//       data
//     }
//   },
//   cleareditUserProfile: () => ({
//     type: editUserProfileTypes.CLEAR
//   })
// }
