export const merchantBillingDetailsTypes = {
  REQUEST: "MERCHANT_BILLING_DETAILS_REQUEST",
  BILLING: "MERCHANT_BILLING_DETAILS_BILLING",
  BILLINGRES: "MERCHANT_BILLING_DETAILS_BILLING_RES",
  BILCLEAR: "MERCHANT_BILLING_CLEAR",
  RESPONSE: "MERCHANT_BILLING_DETAILS_RESPONSE",
  POST_TOGGLE_CHANGE: "MERCHANT_BILLING_DETAILS_POST_TOGGLE_CHANGE",
  POST_PRICE_DETAILS_CHANGE: "MERCHANT_BILLING_DETAILS_POST_CHANGE",
  PRICE_CHANGE: "MERCHANT_BILLING_DETAILS_PRICE_CHANGE",
  CLEAR: "MERCHANT_BILLING_DETAILS_CLEAR",
};

export const merchantBillingDetailsActions = {
  get: (params, headers) => ({
    type: merchantBillingDetailsTypes.REQUEST,
    params,
    headers,
  }),
  getBilling: (params, headers) => ({
    type: merchantBillingDetailsTypes.BILLING,
    params,
    headers,
  }),
  getBillingResponse: (data) => ({
    type: merchantBillingDetailsTypes.BILLINGRES,
    data,
  }),
  saveResponse: (data) => ({
    type: merchantBillingDetailsTypes.RESPONSE,
    data,
  }),
  postToggleChange: (payload, headers) => ({
    type: merchantBillingDetailsTypes.POST_TOGGLE_CHANGE,
    payload,
    headers,
  }),
  priceChange: (data) => ({
    type: merchantBillingDetailsTypes.PRICE_CHANGE,
    data,
  }),
  bilclear: () => ({
    type: merchantBillingDetailsTypes.BILCLEAR,
  }),
  postPriceDetailsChange: (payload, headers) => ({
    type: merchantBillingDetailsTypes.POST_PRICE_DETAILS_CHANGE,
    payload,
    headers,
  }),
  clear: () => ({
    type: merchantBillingDetailsTypes.CLEAR,
  }),
};
