import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import TextSunEditor from '../../../../theme/layout/components/SunEditor'
import { connect } from 'react-redux'
import { STATUS_RESPONSE, SESSION, API } from '../../../../utils/constants'
import {
  rebuttalDetailsActions,
  addRebuttalActions,
  chargeBackDetailsActions
} from '../../../../store/actions'
import { confirmAlert, warningAlert } from '../../../../utils/alerts'
import { useLocation } from 'react-router-dom'
import { validateStep6 } from './validation'
import { getLocalStorage, headersWithAuth } from '../../../../utils/helper'

const Step6 = (props) => {
  const {
    onClickNext,
    goBack,
    getRebuttalDetailsDispatch,
    clearaddRebutalDispatch,
    RebuttalDetails,
    addRebutalDispatch,
    statusGRD,
    loadingAR,
    statusAR,
    messageAR,
    getCBDetailsDispatch,
    ChargebackDetails,
    loadingGRD
  } = props

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }

  const url = useLocation().pathname
  const fields = url && url.split('/')
  const id = fields && fields[3]
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState({
    coverLetterContent: ''
  })
  const handleSubmit = () => {
    const errorMsg = validateStep6(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      const payload = {
        coverLetterContent: formData.coverLetterContent
      }
      addRebutalDispatch(id, payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }

  const onConfirm = () => {
  }

  useEffect(() => {
    if (statusAR === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'Success',
        messageAR,
        'success',
        'Ok',
        () => { onConfirm() },
        () => { onClickNext(6) }
      )
      clearaddRebutalDispatch()
      getRebuttalDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    } else if (statusAR === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'Error',
        messageAR,
        '',
        'Ok'
      )
    }
    clearaddRebutalDispatch()
  }, [statusAR])

  useEffect(() => {
    if (id) {
      getCBDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      getRebuttalDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }, [id])

  useEffect(() => {
    if (statusGRD === STATUS_RESPONSE.SUCCESS_MSG) {
      if (RebuttalDetails && RebuttalDetails) {
        setFormData(values => ({
          ...values,
          coverLetterContent: RebuttalDetails,
        }))
      }
    }
  }, [statusGRD])

  return (
    <>
      <div className='card-header bg-skyBlue py-10'>
        <div className='card-body h-1200px mx-5 bg-white'>
          <TextSunEditor
            setData={setFormData}
            contentData={formData.coverLetterContent}
            name='coverLetterContent'
          />
          {errors && errors.coverLetterContent && (
            <div className='rr mt-1'>
              <style>{'.rr{color:red;}'}</style>
              {errors.coverLetterContent}
            </div>
          )}
        </div>
        <div className='form-group row mt-4'>
          <div className='col-lg-6' />
          <div className='col-lg-6'>
            <div className='col-lg-11'>
              {!['WON', 'LOST', 'NOT FAUGHT', 'LOST REFUNDED'].includes(ChargebackDetails.chargeBackStatus) && !loadingGRD ?
                (
                  <button
                    className='btn btn-primary m-2 fa-pull-right'
                    id='submitBtn'
                    onClick={(event) => {
                      handleSubmit(event)
                    }}
                  >
                    {loadingAR
                      ? (
                        <span
                          className='spinner-border spinner-border-sm mx-3'
                          role='status'
                          aria-hidden='true'
                        />
                      )
                      : (
                        <>
                          <span>Save and Continue</span>  &nbsp;
                          <span className="bi bi-caret-right-fill mt-2" />
                        </>
                      )}
                  </button>
                ) : (
                  <button
                    className='btn btn-primary m-2 fa-pull-right'
                    onClick={() => {
                      onClickNext(6)
                    }}
                  >
                    <span>Continue</span>  &nbsp;
                    <span className="bi bi-caret-right-fill mt-2" />
                  </button>
                )}
              <button
                className='btn btn-darkRed m-2 fa-pull-right'
                onClick={() => { goBack(4) }}
              >
                <span className="bi bi-caret-left-fill mt-2" />&nbsp;
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { rebuttalStore, chargebackStore } = state
  return {
    loadingAR:
      state && state.addRebuttalStore && state.addRebuttalStore.loadingAR,
    statusAR:
      state && state.addRebuttalStore && state.addRebuttalStore.statusAR,
    messageAR:
      state && state.addRebuttalStore && state.addRebuttalStore.messageAR,
    dataAR: state && state.addRebuttalStore && state.addRebuttalStore.dataAR,
    RebuttalDetails: rebuttalStore && rebuttalStore.RebuttalDetails ? rebuttalStore.RebuttalDetails : [],
    statusGRD: rebuttalStore && rebuttalStore.statusGRD ? rebuttalStore.statusGRD : '',
    messagesGRD: rebuttalStore && rebuttalStore.messagesGRD ? rebuttalStore.messagesGRD : '',
    loadingGRD: rebuttalStore && rebuttalStore.loadingGRD ? rebuttalStore.loadingGRD : false,
    ChargebackDetails: chargebackStore && chargebackStore.ChargebackDetails ? chargebackStore.ChargebackDetails : {},
  }
}

const mapDispatchToProps = dispatch => ({
  addRebutalDispatch: (id, params, headers) =>
    dispatch(addRebuttalActions.addRebuttal(id, params, headers)),
  clearaddRebutalDispatch: () =>
    dispatch(addRebuttalActions.clearaddRebuttal()),
  getRebuttalDetailsDispatch: (id, headers) =>
    dispatch(rebuttalDetailsActions.getRebuttalDetails(id, headers)),
  clearRebuttalDetailsDispatch: () =>
    dispatch(rebuttalDetailsActions.clearRebuttalDetails()),
  getCBDetailsDispatch: (id, headers) =>
    dispatch(chargeBackDetailsActions.getChargebackDetails(id, headers)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step6)
