export const addReasoncodeTypes = {
  REQUEST: 'ADD_REASON_CODE_REQUEST',
  RESPONSE: 'ADD_REASON_CODE_RESPONSE',
  ERROR: 'ADD_REASON_CODE_ERROR',
  CLEAR: 'ADD_REASON_CODE_CLEAR'
}

export const addReasoncodeActions = {
  addReasoncode: (data, headers) => {
    return {
      type: addReasoncodeTypes.REQUEST,
      payload: data, headers
    }
  },
  saveaddReasoncodeResponse: (data) => {
    return {
      type: addReasoncodeTypes.RESPONSE,
      data
    }
  },
  clearaddReasoncode: () => ({
    type: addReasoncodeTypes.CLEAR
  })
}

export const ReasoncodeActionsTypes = {
  GET_REASON_CODE: 'GET_REASON_CODE',
  SAVE_REASON_CODE_RESPONSE: 'SAVE_REASON_CODE_RESPONSE',
  CLEAR_REASON_CODE: 'CLEAR_REASON_CODE'
}

export const ReasoncodesActions = {
  reasonCode: (params, headers) => ({
    type: ReasoncodeActionsTypes.GET_REASON_CODE,
    params, headers
  }),
  saveReasoncodeResponse: data => ({
    type: ReasoncodeActionsTypes.SAVE_REASON_CODE_RESPONSE,
    data
  }),
  clearReasoncode: () => ({
    type: ReasoncodeActionsTypes.CLEAR_REASON_CODE
  })
}

export const ReasoncodeGetDetailsTypes = {
  GET_REASON_CODE_DETAILS: 'GET_REASON_CODE_DETAILS',
  REASON_CODE_DETAILS_RESPONSE: 'REASON_CODE_DETAILS_RESPONSE',
  CLEAR_REASON_CODE_DETAILS: 'CLEAR_REASON_CODE_DETAILS'
}
export const ReasoncodeDetailsActions = {
  getReasoncodeDetails: (id, headers) => ({
    type: ReasoncodeGetDetailsTypes.GET_REASON_CODE_DETAILS,
    id, headers
  }),
  saveReasoncodeDetailsResponse: data => ({
    type: ReasoncodeGetDetailsTypes.REASON_CODE_DETAILS_RESPONSE,
    data
  }),
  clearReasoncodeDetails: () => ({
    type: ReasoncodeGetDetailsTypes.CLEAR_REASON_CODE_DETAILS
  })
}

export const editReasoncodeTypes = {
  REQUEST: 'EDIT_REASON_CODE_REQUEST',
  RESPONSE: 'EDIT_REASON_CODE_RESPONSE',
  ERROR: 'EDIT_REASON_CODE_ERROR',
  CLEAR: 'EDIT_REASON_CODE_CLEAR'
}

export const editReasoncodesActions = {
  editReasoncodes: (id, params, headers) => {
    return {
      type: editReasoncodeTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditReasoncodesResponse: (data) => {
    return {
      type: editReasoncodeTypes.RESPONSE,
      data
    }
  },
  cleareditReasoncodes: () => ({
    type: editReasoncodeTypes.CLEAR
  })
}

export const ReasoncodeDeleteTypes = {
  DELETE_REASON_CODE: 'DELETE_REASON_CODE',
  SAVE_DELETE_REASON_CODE_RESPONSE: 'SAVE_DELETE_REASON_CODE_RESPONSE',
  CLEAR_DELETE_REASON_CODE: 'CLEAR_DELETE_REASON_CODE'
}

export const deleteReasoncodeActions = {
  deleteReasoncode: (params, headers) => ({
    type: ReasoncodeDeleteTypes.DELETE_REASON_CODE,
    params, headers
  }),
  savemResponseDeleteReasoncode: data => ({
    type: ReasoncodeDeleteTypes.SAVE_DELETE_REASON_CODE_RESPONSE,
    data
  }),
  clearDeleteReasoncode: () => ({
    type: ReasoncodeDeleteTypes.CLEAR_DELETE_REASON_CODE
  })
}
