export const addMerchantSMTPTypes = {
  REQUEST: 'ADD_MERCHANT_SMTP_REQUEST',
  RESPONSE: 'ADD_MERCHANT_SMTP_RESPONSE',
  ERROR: 'ADD_MERCHANT_SMTP_ERROR',
  CLEAR: 'ADD_MERCHANT_SMTP_CLEAR'
}

export const addMerchantSMTPActions = {
  addMerchantSMTP: (data, headers) => {
    return {
      type: addMerchantSMTPTypes.REQUEST,
      payload: data, headers
    }
  },
  saveaddMerchantSMTPResponse: (data) => {
    return {
      type: addMerchantSMTPTypes.RESPONSE,
      data
    }
  },
  clearaddMerchantSMTP: () => ({
    type: addMerchantSMTPTypes.CLEAR
  })
}

export const getMerchantSMTPDetailsActionsTypes = {
  REQUEST: 'GET_MERCHANT_SMTP_DETAILS_REQUEST',
  RESPONSE: 'GET_MERCHANT_SMTP_DETAILS_RESPONSE',
  ERROR: 'GET_MERCHANT_SMTP_DETAILS_ERROR',
  CLEAR: 'GET_MERCHANT_SMTP_DETAILS_CLEAR'
}

export const getMerchantSMTPDetailsActions = {
  getDetails: (id, headers) => ({
    type: getMerchantSMTPDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getMerchantSMTPDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getMerchantSMTPDetailsActionsTypes.CLEAR
  })
}

export const addClientSMTPTypes = {
  REQUEST: 'ADD_CLIENT_SMTP_REQUEST',
  RESPONSE: 'ADD_CLIENT_SMTP_RESPONSE',
  ERROR: 'ADD_CLIENT_SMTP_ERROR',
  CLEAR: 'ADD_CLIENT_SMTP_CLEAR'
}

export const addClientSMTPActions = {
  addClientSMTP: (data, headers) => {
    return {
      type: addClientSMTPTypes.REQUEST,
      payload: data, headers
    }
  },
  saveaddClientSMTPResponse: (data) => {
    return {
      type: addClientSMTPTypes.RESPONSE,
      data
    }
  },
  clearaddClientSMTP: () => ({
    type: addClientSMTPTypes.CLEAR
  })
}

export const getClientSMTPActionsTypes = {
  REQUEST: 'GET_CLIENT_SMTP',
  RESPONSE: 'SAVE_CLIENT_SMTP_RESPONSE',
  CLEAR: 'CLEAR_CLIENT_SMTP'
}

export const getClientSMTPActions = {
  get: (params, headers) => ({
    type: getClientSMTPActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: data => ({
    type: getClientSMTPActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getClientSMTPActionsTypes.CLEAR
  })
}

export const getClientSMTPDetailsActionsTypes = {
  REQUEST: 'GET_CLIENT_SMTP_DETAILS_REQUEST',
  RESPONSE: 'GET_CLIENT_SMTP_DETAILS_RESPONSE',
  ERROR: 'GET_CLIENT_SMTP_DETAILS_ERROR',
  CLEAR: 'GET_CLIENT_SMTP_DETAILS_CLEAR'
}

export const getClientSMTPDetailsActions = {
  getDetails: (id, headers) => ({
    type: getClientSMTPDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getClientSMTPDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getClientSMTPDetailsActionsTypes.CLEAR
  })
}

export const variableActionsTypes = {
  REQUEST: 'GET_VARIABLE',
  RESPONSE: 'SAVE_VARIABLE_RESPONSE',
  CLEAR: 'CLEAR_VARIABLE'
}

export const variableActions = {
  getVariable: (params, headers) => ({
    type: variableActionsTypes.REQUEST,
    params, headers
  }),
  saveVariableResponse: data => ({
    type: variableActionsTypes.RESPONSE,
    data
  }),
  clearVariable: () => ({
    type: variableActionsTypes.CLEAR
  })
}

export const addMerchantTemplateTypes = {
  REQUEST: 'ADD_MERCHANT_TEMPLATE_REQUEST',
  RESPONSE: 'ADD_MERCHANT_TEMPLATE_RESPONSE',
  ERROR: 'ADD_MERCHANT_TEMPLATE_ERROR',
  CLEAR: 'ADD_MERCHANT_TEMPLATE_CLEAR'
}

export const addMerchantTemplateActions = {
  addMerchantTemplate: (data, headers) => {
    return {
      type: addMerchantTemplateTypes.REQUEST,
      payload: data, headers
    }
  },
  saveaddMerchantTemplateResponse: (data) => {
    return {
      type: addMerchantTemplateTypes.RESPONSE,
      data
    }
  },
  clearaddMerchantTemplate: () => ({
    type: addMerchantTemplateTypes.CLEAR
  })
}

export const templateActionsTypes = {
  REQUEST: 'GET_TEMPLATE',
  RESPONSE: 'SAVE_TEMPLATE_RESPONSE',
  CLEAR: 'CLEAR_TEMPLATE'
}

export const templateActions = {
  getTemplate: (params, headers) => ({
    type: templateActionsTypes.REQUEST,
    params, headers
  }),
  saveTemplateResponse: data => ({
    type: templateActionsTypes.RESPONSE,
    data
  }),
  clearTemplate: () => ({
    type: templateActionsTypes.CLEAR
  })
}

export const templateGetDetailsTypes = {
  REQUEST: 'GET_TEMPLATE_DETAILS',
  RESPONSE: 'TEMPLATE_DETAILS_RESPONSE',
  CLEAR: 'CLEAR_TEMPLATE_DETAILS'
}
export const templateDetailsActions = {
  getTemplateDetails: (id, headers) => ({
    type: templateGetDetailsTypes.REQUEST,
    id, headers
  }),
  saveTemplateDetailsResponse: data => ({
    type: templateGetDetailsTypes.RESPONSE,
    data
  }),
  clearTemplateDetails: () => ({
    type: templateGetDetailsTypes.CLEAR
  })
}

export const editTemplateTypes = {
  REQUEST: 'EDIT_TEMPLATE_REQUEST',
  RESPONSE: 'EDIT_TEMPLATE_RESPONSE',
  ERROR: 'EDIT_TEMPLATE_ERROR',
  CLEAR: 'EDIT_TEMPLATE_CLEAR'
}

export const editTemplatesActions = {
  editTemplates: (id, data, headers) => {
    return {
      type: editTemplateTypes.REQUEST,
      payload: { id, data },
      headers
    }
  },
  saveeditTemplatesResponse: (data) => {
    return {
      type: editTemplateTypes.RESPONSE,
      data
    }
  },
  cleareditTemplates: () => ({
    type: editTemplateTypes.CLEAR
  })
}

export const templateDeleteTypes = {
  REQUEST: 'DELETE_TEMPLATE',
  RESPONSE: 'SAVE_DELETE_TEMPLATE_RESPONSE',
  CLEAR: 'CLEAR_DELETE_TEMPLATE'
}

export const deleteTemplateActions = {
  deleteTemplate: (params, headers) => ({
    type: templateDeleteTypes.REQUEST,
    params, headers
  }),
  savemResponseDeleteTemplate: data => ({
    type: templateDeleteTypes.RESPONSE,
    data
  }),
  clearDeleteTemplate: () => ({
    type: templateDeleteTypes.CLEAR
  })
}

export const addGeneralTemplateTypes = {
  REQUEST: 'ADD_GENERAL_TEMPLATE_REQUEST',
  RESPONSE: 'ADD_GENERAL_TEMPLATE_RESPONSE',
  ERROR: 'ADD_GENERAL_TEMPLATE_ERROR',
  CLEAR: 'ADD_GENERAL_TEMPLATE_CLEAR'
}

export const addGeneralTemplateActions = {
  add: (data, headers) => {
    return {
      type: addGeneralTemplateTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addGeneralTemplateTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addGeneralTemplateTypes.CLEAR
  })
}

export const generalTemplateActionsTypes = {
  REQUEST: 'GET_GENERAL_TEMPLATE',
  RESPONSE: 'SAVE_GENERAL_TEMPLATE_RESPONSE',
  CLEAR: 'CLEAR_GENERAL_TEMPLATE'
}

export const generalTemplateActions = {
  get: (params, headers) => ({
    type: generalTemplateActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: data => ({
    type: generalTemplateActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: generalTemplateActionsTypes.CLEAR
  })
}

export const generalTemplateGetDetailsTypes = {
  REQUEST: 'GET_GENERAL_TEMPLATE_DETAILS',
  RESPONSE: 'GENERAL_TEMPLATE_DETAILS_RESPONSE',
  CLEAR: 'CLEAR_GENERAL_TEMPLATE_DETAILS'
}
export const generalTemplateDetailsActions = {
  get: (id, headers) => ({
    type: generalTemplateGetDetailsTypes.REQUEST,
    id, headers
  }),
  saveResponse: data => ({
    type: generalTemplateGetDetailsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: generalTemplateGetDetailsTypes.CLEAR
  })
}

export const editGeneralTemplateTypes = {
  REQUEST: 'EDIT_GENERAL_TEMPLATE_REQUEST',
  RESPONSE: 'EDIT_GENERAL_TEMPLATE_RESPONSE',
  ERROR: 'EDIT_GENERAL_TEMPLATE_ERROR',
  CLEAR: 'EDIT_GENERAL_TEMPLATE_CLEAR'
}

export const editGeneralTemplateActions = {
  edit: (id, data, headers) => {
    return {
      type: editGeneralTemplateTypes.REQUEST,
      payload: { id, data },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: editGeneralTemplateTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: editGeneralTemplateTypes.CLEAR
  })
}

export const generalTemplateDeleteTypes = {
  REQUEST: 'DELETE_GENERAL_TEMPLATE',
  RESPONSE: 'SAVE_DELETE_GENERAL_TEMPLATE_RESPONSE',
  CLEAR: 'CLEAR_DELETE_GENERAL_TEMPLATE'
}

export const deleteGeneralTemplateActions = {
  delete: (params, headers) => ({
    type: generalTemplateDeleteTypes.REQUEST,
    params, headers
  }),
  saveResponse: data => ({
    type: generalTemplateDeleteTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: generalTemplateDeleteTypes.CLEAR
  })
}
