import React, { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Dropzone, { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { FILE_FORMAT_CB_EVIDENCE, STATUS_RESPONSE, SESSION, DROPZONE_MESSAGES } from '../../utils/constants'
import { warningAlert, confirmAlert } from '../../utils/alerts'
import { getLocalStorage, headersWithAuth, headerWithApiKey } from '../../utils/helper'
import { clientActions, addUploadsCBActions, chargebackActions } from '../../store/actions'
import _ from 'lodash'

const StyledDiv = styled.div`
    .container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-style: dashed;
        background-color: #fafafa;
        color:#9ab6d9;
        outline: none;
        transition: border .24s ease-in-out;
    }
`
const fileHeaders = [
  { label: 'batchId', key: 'batchId' },
  { label: 'chargebackId', key:'chargebackId'},
  { label: 'chargeBackStatus', key: 'chargeBackStatus' }
]

const csvData = [
  {
    "batchId": 2022080517421818,
    "chargebackId":116773,
    "chargeBackStatus": "LOST"
  },
  {
    "batchId": 2022080517421818,
    "chargebackId":116776,
    "chargeBackStatus": "WON"
  },
  {
    "batchId": 2022080517421818,
    "chargebackId":116777,
    "chargeBackStatus": "LOST"
  },
  {
    "batchId": 2022080517421818,
    "chargebackId":116778,
    "chargeBackStatus": "WON"
  },
  {
    "batchId": 2022080517421818,
    "chargebackId":116779,
    "chargeBackStatus": "LOST"
  }
]

const UploadBulkStatus = (props) => {
  const {
    addUploadsCBDispatch,
    clearaddUploadsCBDispatch,
    statusAUCB,
    messageAUCB,
    loadingAUCB,
    setUploadModalShow,
    getChargebackDispatch
  } = props

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const [errors, setErrors] = useState({})
  const { getRootProps } = useDropzone()

  const [formData, setFormData] = useState({
    companyName: '',
    merchantId: '',
    fileUpload: null,
    midUpload: null,
    atUpload: '',
    fileName: ''
  })

  const handleSubmit = () => {
    addUploadsCBDispatch(formData.fileUpload, null, isAuthorized ? headersWithAuth : headerWithApiKey)
  }

  const handleFileUpload = (files, name) => {
    setErrors({ ...errors, [name]: '' })
    const maxFileSize = 5
    let isValidFileFormat = false
    let currentFileName = ''
    const data = new FormData()
    files.forEach((item) => {
      const fileType = item && item.type
      const uploadedFileSize = item && item.size
      currentFileName = item && item.name
      isValidFileFormat = _.includes(FILE_FORMAT_CB_EVIDENCE, fileType)
      const fileSize = Number(maxFileSize) * 1024 * 1024
      if (isValidFileFormat) {
        if (uploadedFileSize < fileSize) {
          data.append('file', item)
        } else {
          setErrors({
            ...errors,
            [name]:
              `File size must below ${fileSize / 1048576
              } MB!. You file size is : ${Math.round(uploadedFileSize / 1024)}KB`
          })
        }
      } else {
        setErrors({ ...errors, [name]: DROPZONE_MESSAGES.DOCUMENT_INVALID })
      }
    })
    if (isValidFileFormat) {
      setFormData((values) => ({
        ...values,
        [name]: data,
        fileName: currentFileName
      }))
    }
  }

  const onConfirm = () => {
    setUploadModalShow(false)
    const params = {
      null: null
    };
    getChargebackDispatch(_.pickBy(params), isAuthorized ? headersWithAuth : headersWithAuth);
  }

  useEffect(() => {
    if (statusAUCB === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAUCB,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearaddUploadsCBDispatch()
    } else if (statusAUCB === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'Error',
        messageAUCB,
        'error',
        'Close',
        'Ok',
        () => {
          onConfirm()
        },
        () => { }
      )
      clearaddUploadsCBDispatch()
    }
  }, [statusAUCB])

  return (
    <>
      <div className='card-header bg-skyBlue py-10'>
        <div className='card-body'>
          <div className='row my-7'>
            <div className='col-lg-3 text-end'>
              <label className='font-size-xs col-lg-6 col-form-label'>Upload File:</label>
            </div>
            <div className='col-lg-9'>
              <div className='col-lg-8'>
                <StyledDiv {...getRootProps({ refKey: 'innerRef' })}>
                  <Dropzone onDrop={acceptedFiles => handleFileUpload(acceptedFiles, 'fileUpload')}>
                    {({ getRootProps, getInputProps }) => (
                      <div className='container'>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name='fileUpload' />
                          <p className='text-primary fw-bolder fs-6'>Click here to select files</p>
                          <aside>
                            <h4 className='text-dark fw-bolder fs-5 mt-6'>Files</h4>
                            {formData && formData.fileName
                              ? (
                                <div>{formData.fileName}</div>
                              )
                              : null}
                          </aside>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDiv>
                {errors && errors.fileUpload && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.fileUpload}</div>)}
              </div>
              <div className='col-lg-6 mt-6 d-flex justify-content-end'>
                {csvData?.length &&
                  <CSVLink
                    headers={fileHeaders}
                    data={csvData}
                    filename='Sample_file.csv'
                    target='_blank'
                    className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm m-1'
                  >
                    Download Sample File
                  </CSVLink>}
                <button
                  className='btn btn-blue btn-sm m-1'
                  onClick={handleSubmit}
                >
                  {
                    loadingAUCB
                      ? (
                        <span className='spinner-border spinner-border-sm mx-5' role='status' aria-hidden='true' />
                      )
                      : 'Update Status'
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  getClient: state && state.clientStore && state.clientStore.getClient &&
    state.clientStore.getClient.data
    ? state.clientStore.getClient.data.result
    : null,
  dataAUCB: state && state.addUploadsCBStore && state.addUploadsCBStore.dataAUCB,
  statusAUCB: state && state.addUploadsCBStore && state.addUploadsCBStore.statusAUCB,
  loadingAUCB: state && state.addUploadsCBStore && state.addUploadsCBStore.loadingAUCB,
  messageAUCB: state && state.addUploadsCBStore && state.addUploadsCBStore.messageAUCB
})

const mapDispatchToProps = dispatch => ({
  getClientDispatch: () => dispatch(clientActions.getClient()),
  addUploadsCBDispatch: (params, url, headers) => dispatch(addUploadsCBActions.addUploadsCB(params, url, headers)),
  clearaddUploadsCBDispatch: () => dispatch(addUploadsCBActions.clearaddUploadsCB()),
  getChargebackDispatch: (params, headers) =>
    dispatch(chargebackActions.getChargeback(params, headers)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadBulkStatus)
