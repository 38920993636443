export const exportLayoutDefaultCols = {
  chargebackStatus: true,
  clientId: true,
  merchantId: true,
  productName: true,
  chargebackDate: true,
  chargebackAmount: true,
  DBAName: true,
  cardType: true,
  transactionAmount: true,
  chargebackReason: true,
  transactionDate: true,
  transactionId: true,
  chargebackDeadlineDate: false,
  acquirerName: false,
  affiliateId: false,
  arn: true,
  authCode: true,
  avs: false,
  blacklisted: false,
  cardCountry: false,
  cardFirstSix: false,
  cardLastFour: false,
  cardNetwork: false,
  caseId: false,
  chargebackCurrency: false,
  chargeBackType: false,
  companyName: false,
  country: false,
  customerEmail: false,
  customerIP: false,
  customerName: false,
  customerPhone: false,
  cvv: false,
  deviceId: false,
  disputeFiledDate: false,
  disputeMethod: false,
  functionalArea: false,
  gateway: false,
  issuer: false,
  merchantName: false,
  mid: false,
  nonUs: false,
  orderId: true,
  paymentId: false,
  paymentProcessor: false,
  paymentMethod: false,
  reasonCodeGrouping: false,
  reasonForLegitimateChargeback: false,
  refundIssued: false,
  rootCause: false,
  serviceType: false,
  saleType: false,
  subFunctionalArea: false,
  upiId: true,
  createdAtFrom: false,
  createdAtTo: false,
  updatedAtFrom: false,
  updatedAtTo: false,
  batchId: true,
}
