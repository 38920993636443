import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../theme/helpers";
import shopify from "../../../../assets/shopify.png";
import {
  REGEX,
  STATUS_RESPONSE,
  SWEET_ALERT_MSG,
  SAVE_CURRENT,
  SESSION,
  API,
} from "../../../../utils/constants";
import { getLocalStorage, headersWithAuth } from "../../../../utils/helper";
import ReactSelect from "../../../../theme/layout/components/ReactSelect";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "../../../../theme/layout/components/modal";
import {
  warningAlert,
  confirmAlert,
  confirmationAlert,
} from "../../../../utils/alerts";
import {
  crmActions,
  clientCRMActions,
  editClientCrmActions,
  deleteclientCRMActions,
  addclientCRMActions,
} from "../../../../store/actions";
import color from "../../../../utils/colors";
import { crmValidation, addCrmValidation } from "../userDetails/validation";
import ReactPaginate from "react-paginate";
import CRMStep from "./CRMStep/CRMStepper";
import { setNestedObjectValues } from "formik";

const AddCrmForm = (props) => {
  const {
    cleareditClientCrmDispatch,
    getClientCRM,
    loadingClientCRM,
    className,
    editClientCrmDispatch,
    getCRM,
    getClientCRMDispatch,
    getCRMDispatch,
    statusECCRM,
    messageECCRM,
    deleteclientCRMDispatch,
    deleteclientCRMStatus,
    deleteclientCRMMessage,
    clearDeleteclientCRMDispatch,
    clearaddclientCRMDispatch,
    addclientCRMDispatch,
    statusACCRM,
    messageACCRM,
    currentMerchantId,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };

  const [crmModalShow, setCrmModalShow] = useState(false);
  const currentMidID = getLocalStorage(SAVE_CURRENT.MID_ID);
  const [connectedStatus, setConnectedStatus] = useState(false);
  const currentClientID = getLocalStorage(SAVE_CURRENT.CLIENT_ID);
  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update-merchant/");
  const id = url && url[1];
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [errors, setErrors] = useState({});
  const [showTable, setShowTable] = useState(true);
  const [crmOption, setCrmOption] = useState();
  const [selectedCrmOption, setSelectedCrmOption] = useState("");
  const [limit] = useState(25);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [formData, setFormData] = useState({
    crmId: "",
  });
  const [form, setForm] = useState({
    crmId: "",
    crmLabel: "",
    apiKey: "",
    apiSecretKey: "",
    apiEndPoint: "",
  });
  const [tableForm, setTableForm] = useState([]);
  const handleInputChange = (e, type) => {
    e.preventDefault();
    if (type === "setForm") {
      setForm((values) => ({ ...values, [e.target.name]: e.target.value }));
    }
    if (type === "setFormData") {
      setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleAddTable = () => {
    if (!tableForm.includes(formData.crmId)) {
      const errorMsg = addCrmValidation(form, setErrors);
      if (_.isEmpty(errorMsg)) {
        if (editMode) {
          const payload = {
            crmId: form.crmId,
            apiKey: form.apiKey,
            apiSecretKey: form.apiSecretKey,
            apiEndPoint: form.apiEndPoint,
          };
          editClientCrmDispatch(
            currentId,
            payload,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        } else {
          addclientCRMDispatch(
            {
              clientId: currentClientID,
              merchantId: currentMerchantId,
              midId: currentMidID,
              crmId: formData.crmId,
              apiKey: form.apiKey,
              apiSecretKey: form.apiSecretKey,
              apiEndPoint: form.apiEndPoint,
            },
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setSelectedCrmOption();
        }
        setSelectedCrmOption();
        setShowTable(true);
        setForm({
          apiKey: "",
          apiSecretKey: "",
          apiEndPoint: "",
          crmId: "",
          crmLabel: "",
        });
        setFormData({ crmId: "" });
        setShow(false);
        setEditMode(false);
      }
    }
  };

  const handleAddClick = (type) => {
    if (type === "CRM") {
      const errorMsg = crmValidation(formData, setErrors);
      if (_.isEmpty(errorMsg)) {
        if (!tableForm.includes(formData.crmId)) {
          setShow(true);
        }
      }
    }
  };

  const onEditClick = (item) => {
    setCurrentId(item._id);
    setShow(true);
    setEditMode(true);
    setForm({
      crmId: item.crmId && item.crmId._id,
      crmLabel: item.crmLabel,
      apiKey: item.apiKey,
      apiSecretKey: item.apiSecretKey,
      apiEndPoint: item.apiEndPoint,
    });
  };

  const onConfirmDelete = (id) => {
    deleteclientCRMDispatch(
      id,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_CRM,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete(id);
      },
      () => {}
    );
  };

  const onPostConfirm = () => {
    const params = {
      limit: limit,
      page: activePageNumber,
      merchantId: id,
      clientId: currentClientID,
      midId: currentMidID,
    };
    getClientCRMDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  useEffect(() => {
    if (statusACCRM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageACCRM,
        "success",
        "ok",
        () => {
          onPostConfirm();
        },
        () => {
          onPostConfirm();
        }
      );
      clearaddclientCRMDispatch();
    } else if (statusACCRM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageACCRM, "", "Ok");
    }
    clearaddclientCRMDispatch();
  }, [statusACCRM]);

  useEffect(() => {
    if (deleteclientCRMStatus === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        deleteclientCRMMessage,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearDeleteclientCRMDispatch();
    } else if (deleteclientCRMStatus === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", deleteclientCRMMessage, "", "Ok");
    }
    clearDeleteclientCRMDispatch();
  }, [deleteclientCRMStatus]);

  useEffect(() => {
    const params = {
      limit: limit,
      page: activePageNumber,
      merchantId: id,
      clientId: currentClientID,
      midId: currentMidID,
    };
    getClientCRMDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    getCRMDispatch(
      null,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const handleChangeCRM = (selectedOption) => {
    if (selectedOption !== null && !tableForm.includes(selectedOption.value)) {
      setSelectedCrmOption(selectedOption);
      setFormData((values) => ({ ...values, crmId: selectedOption.value }));
      setErrors((values) => ({ ...values, crmId: "" }));
    } else {
      setSelectedCrmOption();
      setFormData((values) => ({ ...values, crmId: "" }));
    }
  };

  useEffect(() => {
    const crmId = getDefaultOptions(getCRM);
    setCrmOption(crmId);
    if (!_.isEmpty(formData.crmId)) {
      const selOption = _.filter(crmId, function (x) {
        if (_.includes(formData.crmId._id, x.value)) {
          return x;
        }
      });
      setSelectedCrmOption(selOption);
    }
  }, [getCRM]);

  const getDefaultOptions = (getCRM) => {
    const defaultOptions = [];
    if (!_.isEmpty(getCRM)) {
      const { data } = getCRM;
      if (!_.isEmpty(data)) {
        data.map((item) =>
          defaultOptions.push({
            label: `${item.crmName ? item.crmName : ""}`,
            value: item._id,
          })
        );
      }
      return defaultOptions;
    }
  };

  const onConfirm = () => {
    const params = {
      limit: limit,
      page: activePageNumber,
      merchantId: id,
      clientId: currentClientID,
      midId: currentMidID,
    };
    getClientCRMDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  useEffect(() => {
    if (getClientCRM && getClientCRM.data) {
      const tempArr = [];
      getClientCRM.data.forEach((o) => {
        const x = o && o.crmId && o.crmId._id && o.crmId._id;
        if (x && !tempArr.includes(x)) {
          tempArr.push(...tempArr, o.crmId._id);
        }
      });
      setTableForm(tempArr);
    }
  }, [getClientCRM && getClientCRM.data]);

  useEffect(() => {
    if (statusECCRM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageECCRM,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      cleareditClientCrmDispatch();
    } else if (statusECCRM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageECCRM, "", "Ok");
      cleareditClientCrmDispatch();
    }
  }, [statusECCRM]);

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const params = {
      limit: limit,
      page: pageNumber,
      merchantId: id,
      clientId: currentClientID,
      midId: currentMidID,
    };
    setActivePageNumber(pageNumber);
    getClientCRMDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const totalPages =
    getClientCRM && getClientCRM.count
      ? Math.ceil(parseInt(getClientCRM && getClientCRM.count) / limit)
      : 1;

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  return (
    <>
      <Modal showModal={show} modalWidth={550}>
        <div className="" id="crmModal">
          <div className="">
            <div className="p-5">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="me-8">Add CRM</h2>
                <button
                  type="button"
                  className="btn btn-lg btn-icon btn-active-light-primary close"
                  onClick={() => {
                    setShow(false);
                    setSelectedCrmOption();
                    setForm({
                      apiKey: "",
                      apiSecretKey: "",
                      apiEndPoint: "",
                      crmId: "",
                      crmLabel: "",
                    });
                    setErrors((values) => ({
                      ...values,
                      apiKey: "",
                      apiSecretKey: "",
                      apiEndPoint: "",
                      crmId: "",
                      crmLabel: "",
                    }));
                    setEditMode(false);
                    setFormData({ crmId: "" });
                    setSelectedCrmOption();
                  }}
                >
                  {/* eslint-disable */}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon-1"
                  />
                  {/* eslint-disable */}
                </button>
              </div>
              <div className="bg-light">
                <form className="container-fixed">
                  <div className="card-body">
                    {form.crmLabel ? (
                      <h4 className="me-8 mb-2">{form.crmLabel}</h4>
                    ) : null}
                    <div className="mb-3 pt-5">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API End Point :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="apiEndPoint"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API End Point"
                            onChange={(e) => handleInputChange(e, "setForm")}
                            autoComplete="off"
                            value={form.apiEndPoint || ""}
                            maxLength={42}
                            onBlur={(e) => handleTrimWhiteSpace(e, setForm)}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.apiEndPoint && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.apiEndPoint}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API Key :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="apiKey"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API Key"
                            onChange={(e) => handleInputChange(e, "setForm")}
                            autoComplete="off"
                            value={form.apiKey || ""}
                            maxLength={42}
                            onBlur={(e) => handleTrimWhiteSpace(e, setForm)}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.apiKey && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.apiKey}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-lg-4 mt-3">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            API Secret :
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            name="apiSecretKey"
                            type="text"
                            className="form-control bg-skyBlue"
                            placeholder="API Secret"
                            onChange={(e) => handleInputChange(e, "setForm")}
                            autoComplete="off"
                            value={form.apiSecretKey || ""}
                            maxLength={42}
                            onBlur={(e) => handleTrimWhiteSpace(e, setForm)}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors && errors.apiSecretKey && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.apiSecretKey}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row pb-3">
                    <div className="col-lg-6" />
                    <div className="col-lg-6">
                      <div className="col-lg-11">
                        <button
                          type="button"
                          className="btn btn-sm btn-green m-2 fa-pull-right"
                          onClick={() => {
                            handleAddTable();
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {crmModalShow ? (
        <Modal showModal={crmModalShow} modalWidth={750}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-header">
                <h2 className="me-8 mt-5">Connect CRM</h2>
                <button
                  type="button"
                  className="btn btn-lg btn-icon btn-active-light-primary close"
                  data-dismiss="modal"
                  onClick={(e) => {
                    setCrmModalShow(false);
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon-1"
                  />
                </button>
              </div>
              <div className="d-flex justify-content-center p-5">
                <img
                  style={{ width: "200px", height: "60px" }}
                  src={shopify}
                ></img>
              </div>
              <CRMStep setConnectedStatus={setConnectedStatus} />
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="card-header bg-skyBlue py-10">
        <div className="card-body m-8">
          <h2 className="mb-5">CRM</h2>
          <div className="form-group row mb-2">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="d-flex justify-content-start my-auto">
                    <div className="col-lg-5 pr-3 me-3">
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name="crmId"
                        className="basic-single"
                        classNamePrefix="select"
                        handleChangeReactSelect={handleChangeCRM}
                        options={crmOption}
                        value={selectedCrmOption}
                      />
                      {errors && errors.crmId && (
                        <div className="rr mt-1">
                          <style>{".rr{color:red}"}</style>
                          {errors.crmId}
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className="btn btn-green mb-3 py-2 m-1"
                        onClick={() => {
                          handleAddClick("CRM");
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showTable ? (
            <>
              <div className="w-100 d-flex justify-content-start mt-2">
                <div className={`card ${className}`}>
                  <div className="card-body py-3">
                    <div className="col-md-7 d-flex">
                      <label className="col-form-label text-lg-start mt-3 ms-3">
                        Record(s) per Page : &nbsp;{" "}
                      </label>
                      <div className="col-md-3 mt-3">
                        <select
                          className="form-select w-6rem"
                          data-control="select"
                          data-placeholder="Select an option"
                          data-allow-clear="true"
                          onChange={(e) => handleRecordPerPage(e)}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-hover table-rounded table-striped border gs-2 mt-6">
                        <thead>
                          <tr className="fw-bold fs-6 text-gray-800 ">
                            <th className="min-w-80px text-start">
                              <div className="d-flex">
                                <span>S.No</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start ps-6">
                              Action
                            </th>
                            <th className="min-w-200px text-start">
                              Connect CRM
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>CRM</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>API Key</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>API Secret</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>API End Point</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>Chargeback Limit</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>Last Synced</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>Status</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loadingClientCRM ? (
                            <tr>
                              <td colSpan="8" className="text-center">
                                <div
                                  className="spinner-border text-primary m-5"
                                  role="status"
                                />
                              </td>
                            </tr>
                          ) : getClientCRM &&
                            getClientCRM.data &&
                            getClientCRM.data.length > 0 ? (
                            getClientCRM.data.map((item, i) => {
                              return item.apiKey ? (
                                <tr
                                  key={i}
                                  style={
                                    i === 0
                                      ? { borderColor: "black" }
                                      : { borderColor: "white" }
                                  }
                                >
                                  <td className="pb-0 pt-5  text-start">
                                    {i + 1}
                                  </td>
                                  <td className="pb-0 pt-3 text-start">
                                    <div className="my-auto d-flex">
                                      <button
                                        className="btn btn-icon btn-bg-light btn-icon-warning btn-active-color-warning btn-sm"
                                        onClick={() => {
                                          onEditClick(item);
                                        }}
                                      >
                                        {/* eslint-disable */}
                                        <KTSVG
                                          path="/media/icons/duotune/art/art005.svg"
                                          className="svg-icon-3"
                                        />
                                        {/* eslint-enable */}
                                      </button>
                                      <button
                                        className="btn btn-icon btn-bg-light btn-icon-danger btn-active-color-danger btn-sm"
                                        onClick={() => onDeleteItem(item._id)}
                                      >
                                        {/* eslint-disable */}
                                        <KTSVG
                                          path="/media/icons/duotune/general/gen027.svg"
                                          className="svg-icon-3"
                                        />
                                        {/* eslint-enable */}
                                      </button>
                                    </div>
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    {connectedStatus ? (
                                      <td style={{ verticalAlign: "middle" }}>
                                        <span className="badge badge-success w-50px">
                                          Connected
                                        </span>
                                      </td>
                                    ) : (
                                      <button
                                        className="btn btn-sm btn-dark"
                                        onClick={() => {
                                          setCrmModalShow(true);
                                        }}
                                      >
                                        Connect
                                      </button>
                                    )}
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    {item.crmId && item.crmId.crmName
                                      ? item.crmId.crmName
                                      : "--"}
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    {item.apiKey ? item.apiKey : "--"}
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    {item.apiSecretKey
                                      ? item.apiSecretKey
                                      : "-"}
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    {item.apiEndPoint ? item.apiEndPoint : "--"}
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    100 Chargebacks
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    26-09-2022
                                  </td>
                                  {item.status === "ACTIVE" ? (
                                    <td style={{ verticalAlign: "middle" }}>
                                      <span className="badge badge-success w-50px">
                                        {item.status ? item.status : "--"}
                                      </span>
                                    </td>
                                  ) : item.status === "INACTIVE" ? (
                                    <span className="badge badge-warning w-50px">
                                      {item.status ? item.status : "--"}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              ) : null;
                            })
                          ) : (
                            <tr className="text-center py-3">
                              <td colSpan="100%">No record(s) found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group row mb-4 mt-6">
                      <div className="col-lg-12 mb-4 align-items-end d-flex">
                        <div className="col-lg-12 pagination">
                          <ReactPaginate
                            nextLabel="Next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={totalPages}
                            previousLabel="< Prev"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {errors && errors.crmForm && (
            <div className="rr mt-0 text-danger">
              <style>{".rr{color:red}"}</style>
              {errors.crmForm}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getCRM: state && state.crmStore && state.crmStore.getCRM,
  loading: state && state.crmStore && state.crmStore.loading,
  getClientCRM:
    state && state.clientCRMStore && state.clientCRMStore.getClientCRM,
  loadingClientCRM:
    state && state.clientCRMStore && state.clientCRMStore.loading,
  statusECCRM:
    state && state.editClientCrmStore && state.editClientCrmStore.statusECCRM,
  messageECCRM:
    state && state.editClientCrmStore && state.editClientCrmStore.messageECCRM,
  loadingECCRM:
    state &&
    state.editClientCrmStore &&
    state.editClientCrmStore.loadingECCRM &&
    state.editClientCrmStore.loadingECCRM,
  deleteclientCRM:
    state &&
    state.clientCRMDeleteStore &&
    state.clientCRMDeleteStore.deleteclientCRM,
  deleteclientCRMStatus:
    state &&
    state.clientCRMDeleteStore &&
    state.clientCRMDeleteStore.deleteclientCRMStatus,
  deleteclientCRMMessage:
    state &&
    state.clientCRMDeleteStore &&
    state.clientCRMDeleteStore.deleteclientCRMMessage,
  deleteclientCRMLoading:
    state && state.clientCRMDeleteStore && state.clientCRMDeleteStore.loading,
  currentClientId:
    state && state.merchantStore && state.merchantStore.currentClientId,
  statusACCRM:
    state && state.addclientCRMStore && state.addclientCRMStore.statusACCRM,
  messageACCRM:
    state && state.addclientCRMStore && state.addclientCRMStore.messageACCRM,
  loadingACCRM:
    state && state.addclientCRMStore && state.addclientCRMStore.loadingACCRM,
});

const mapDispatchToProps = (dispatch) => ({
  getCRMDispatch: (data, headers) => dispatch(crmActions.getCRM(data, headers)),
  getClientCRMDispatch: (data, headers) =>
    dispatch(clientCRMActions.getClientCRM(data, headers)),
  clearClientCRMDispatch: () => dispatch(clientCRMActions.clearClientCRM()),
  editClientCrmDispatch: (id, params, headers) =>
    dispatch(editClientCrmActions.editClientCrm(id, params, headers)),
  cleareditClientCrmDispatch: () =>
    dispatch(editClientCrmActions.cleareditClientCrm()),
  deleteclientCRMDispatch: (data, headers) =>
    dispatch(deleteclientCRMActions.deleteclientCRM(data, headers)),
  clearDeleteclientCRMDispatch: () =>
    dispatch(deleteclientCRMActions.clearDeleteclientCRM()),
  addclientCRMDispatch: (data, headers) =>
    dispatch(addclientCRMActions.addclientCRM(data, headers)),
  clearaddclientCRMDispatch: () =>
    dispatch(addclientCRMActions.clearaddclientCRM()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCrmForm);
