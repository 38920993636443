import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addApiKeyTypes,
  addApiKeyActions,
  apiKeyActionsTypes,
  apiKeyActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addApiKey (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.apiKey, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addApiKeyActions.saveaddApiKeyResponse(json.data))
    } else {
      yield put(addApiKeyActions.saveaddApiKeyResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addApiKeyTypes.RESPONSE, data })
  }
}

function * fetchApiKey (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.apiKey}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(apiKeyActions.saveapiKeyResponse(json.data))
    } else {
      yield put(apiKeyActions.saveapiKeyResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: apiKeyActionsTypes.RESPONSE, data })
  }
}

export function * fetchApiKeyWatcher () {
  yield all([
    yield takeLatest(addApiKeyTypes.REQUEST, addApiKey),
    yield takeLatest(apiKeyActionsTypes.REQUEST, fetchApiKey)
  ])
}
