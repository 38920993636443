import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addEmailResendActionsTypes,
  addEmailResendActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addEmailResend (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.emailResend, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addEmailResendActions.saveResponse(json.data))
    } else {
      yield put(addEmailResendActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addEmailResendActionsTypes.RESPONSE, data })
  }
}

export function * fetchEmailResendWatcher () {
  yield all([
    yield takeLatest(addEmailResendActionsTypes.REQUEST, addEmailResend),
  ])
}