import React, { useState, useEffect } from "react";
import moment from 'moment'
import { toAbsoluteUrl } from '../../theme/helpers'
import {
  colors, STATUS_RESPONSE, API_URL, DATE, CHARGEBACK_STATUS,
  CHARGEBACK_STATUS_LABEL,
  SWEET_ALERT_MSG
} from '../../utils/constants'
import _ from 'lodash'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { approveCBMActions, getLinkMerchantActions, approveALLCBMActions } from '../../store/actions'
import { confirmAlert, warningAlert, confirmationAlert } from "../../utils/alerts";
import { headersWithAuth } from '../../utils/helper'

const MerchantEvidence = (props) => {
  console.log(props, " props value " );
  const {
    getCBMDetailsDispatch,
    approveCBMs,
    loadingCBM,
    approveCBMActionDispatch,
    clearCBMActionDispatch,
    loadingAPCBME,
    statusAPCBME,
    messageAPCBME,
    sendLinkDispatch,
    clearsendLinkDispatch,
    statusGSELM,
    messageGSELM,
    getMailSendHistory,
  } = props

  const history = useHistory()
  const url = useLocation().pathname;
  const fields = url && url.split("/");
  const id = fields && fields[3];
  const testUrl = new URL(window.location.href);
  const query = testUrl && testUrl.search.split("?");
  const approveAllParam = query && query[1];
  const approveParam = approveAllParam && approveAllParam.split("approve=")
  const approveParamId = approveParam && approveParam[1]
  const rejectParam = approveAllParam && approveAllParam.split("reject=")
  const rejectParamId = rejectParam && rejectParam[1]

  useEffect(() => {
    getCBMDetailsDispatch(id, headersWithAuth)
  }, [])

  useEffect(() => {
    if (approveAllParam && approveAllParam === 'approve_all=1') {
      const payload = {
        type: "approve_all",
        token: id
      }
      approveCBMActionDispatch(payload, headersWithAuth);
    }
  }, [approveAllParam])

  useEffect(() => {
    if (approveParamId && approveParamId) {
      const payload = {
        type: "approve",
        token: id,
        chargeBackId: approveParamId
      }
      approveCBMActionDispatch(payload, headersWithAuth);
    }
  }, [approveParamId])

  useEffect(() => {
    if (rejectParamId && rejectParamId) {
      sendLinkDispatch(rejectParamId, headersWithAuth)
    }
  }, [rejectParamId])

  const onConfirm = () => {
    getCBMDetailsDispatch(id, headersWithAuth)
    history.push({
      pathName: "/chargeback/merchant/:id",
      search: "?"
    })
  }

  const onMultiAprrove = () => {
    const payload = {
      type: "approve_all",
      token: id
    }
    approveCBMActionDispatch(payload, headersWithAuth);
  }

  const handleApproveAll = () => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.APPROVE_ALL_EVI,
      "warning",
      "Yes",
      "No",
      () => {
        onMultiAprrove();
      },
      () => { }
    );
  };

  useEffect(() => {
    if (statusAPCBME === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAPCBME,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearCBMActionDispatch();
    } else if (statusAPCBME === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAPCBME, "", "Ok");
    }
    clearCBMActionDispatch();
  }, [statusAPCBME, messageAPCBME]);

  let clientDetails = ''
  approveCBMs && approveCBMs?.map((clientData) => {
    clientDetails = clientData.client
  })

  let merchantDetails = ''
  approveCBMs && approveCBMs?.map((clientData) => {
    merchantDetails = clientData.merchant
  })

  let CBId = ''
  approveCBMs && approveCBMs?.map((CBDaata) => {
    CBDaata.chargebacks?.map((cbdata) => {
      CBId = cbdata._id
    })
  })

  const onSingleAprrove = (CBId) => {
    const payload = {
      type: "approve",
      token: id,
      chargeBackId: CBId
    }
    approveCBMActionDispatch(payload, headersWithAuth);
  }

  const handleApprove = (itemValue) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.APPROVE_EVI,
      "warning",
      "Yes",
      "No",
      () => {
        onSingleAprrove(itemValue);
      },
      () => { }
    );
  };

  const onRedirects = () => {
    history.push({
      pathName: "/chargeback/merchant/:id",
      search: "?"
    })
    window.open(`/chargeback/evidence/${getMailSendHistory.token}`, "_blank")
  }

  useEffect(() => {
    if (statusGSELM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        "Please Click here to submit Evidences",
        "success",
        "Ok",
        () => {
          onRedirects();
        },
        () => {
          onRedirects();
        }
      );
      clearsendLinkDispatch();
    }
    else if (statusGSELM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageGSELM, "", "Ok");
    }
    clearsendLinkDispatch();
  }, [statusGSELM])

  const onAprrove = (itemValue) => {
    console.log(itemValue, " cbid ");
    sendLinkDispatch(itemValue, headersWithAuth)
  }

  const onSubmit = (itemValue) => {
    console.log(itemValue);
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.SUBMIT_EVI,
      "warning",
      "Yes",
      "No",
      () => {
        onAprrove(itemValue);
      },
      () => { }
    );
  }

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/illustrations/sketchy-1/14.png"
          )})`,
          backgroundColor: colors.oxfordBlue,
        }}
      >
        <div className="d-flex flex-center flex-column flex-column-fluid pt-20">
          {!loadingCBM &&
            <a href="#" className="mb-5">
              {
                clientDetails &&
                  clientDetails.logo
                  ?
                  (<img
                    alt="Logo"
                    src={`${API_URL}/uploads/${clientDetails &&
                      clientDetails.logo
                      }`}
                    className="h-65px"
                  />) :
                  (<img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-1.svg")}
                    className="h-65px"
                  />)
              }
            </a>
          }
          <div className="w-lg-1500px rounded shadow-sm mx-auto">
            <div className="text-center mb-10">
              <h1 className="text-dark mb-3">Chargebacks</h1>
            </div>
            <div className='scroll h-600px w-1800px'>
              <div className='card mx-auto'>
                <div className="card-body py-3">
                  <div className="d-flex  px - 2">
                    <div className="d-flex justify-content-start col-md-6">
                      <div className="col-md-6 mt-1 d-flex">
                        <div className='fs-4 fw-bolder'>Merchant: &nbsp;</div>
                        <span className='fs-4 fw-bolder text-primary'>
                          {merchantDetails && merchantDetails.merchantName}
                        </span>
                      </div>
                      <div className="col-md-3 mt-1">
                        {approveCBMs && approveCBMs.count ? (
                          <span className="text-muted fw-bold d-flex fs-5 mt-2">
                            Total: &nbsp;{" "}
                            <span className="text-gray-700 fw-bolder text-hover-primary fs-5">
                              {approveCBMs.count}
                            </span>
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex col-md-6  justify-content-end my-auto">
                      <div className="my-auto mx-3">
                        {!approveAllParam &&
                          <button
                            className="btn btn-sm btn-success btn-responsive font-5vw"
                            onClick={() => handleApproveAll()}
                          >
                            {loadingAPCBME ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              <>
                                <span>
                                  <i className="bi bi-check-circle-fill" />
                                </span>
                                <span>Accept All</span>
                              </>
                            )}
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mt-5">
                    <table className="table table-hover table-rounded table-striped border">
                      <thead className="fw-bolder fs-8 text-gray-800">
                        <tr>
                          <th className="min-w-150px">Action</th>
                          <th>
                            <div className="d-flex">
                              <span>Chargeback Status</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Batch Id</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Client</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Merchant</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Product Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Chargeback Date</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Chargeback Deadline Date</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Chargeback Amount</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>DBA Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Card Type</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>UPI Id</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Transaction Amount</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Transaction Date</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Transaction Id</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Acquirer Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Affliate Id</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>ARN</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Auth Code</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>AVS Matched</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Blacklisted</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Card Country</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Card Bin</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Card Last Four Digits</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Card Network</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Case Id</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Chargeback currency</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Chargeback Reason</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Chargeback Type</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Company Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Country</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Customer Email</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Customer IP</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Customer Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Customer Phone</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>CVV</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Device Id</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Disputed Filed Date</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Dispute Method</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Functional Area</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Gateway</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Issuer</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Merchant Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>MID</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Non US</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Order Id</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Payment Id</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Payment Processor</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Payment Method</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Reason Code Grouping</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Reason For Legitimate Chargeback</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Refund Issued</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Root Cause</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Service Type</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Sale Type</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Sub Functional Area</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Created At</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex">
                              <span>Updated At</span>
                            </div>
                          </th>
                        </tr >
                      </thead >
                      <tbody className="fs-8">
                        {!loadingCBM ? (
                          approveCBMs && approveCBMs.length > 0 ? (
                            approveCBMs?.map((cbData) => {
                              return (
                                cbData && cbData.chargebacks?.map((item, _id) => {
                                  return (
                                    <tr
                                      key={_id}
                                      style={
                                        _id === 0
                                          ? { borderColor: "black" }
                                          : { borderColor: "white" }
                                      }
                                    >
                                      {
                                        item.chargeBackStatus && !['LOST', 'LOST REFUNDED', 'DISPUTED'].includes(item.chargeBackStatus) ?
                                          (
                                            <td className="min-width-150px">
                                              <div className="d-flex">
                                                <button
                                                  className="btn btn-sm btn-success m-1"
                                                  data-toggle="tooltip"
                                                  data-placement="right"
                                                  title="Approve"
                                                  onClick={() => handleApprove(item._id)}
                                                >
                                                  Accept
                                                </button>{" "}
                                                &nbsp;&nbsp;&nbsp;
                                                <button
                                                  className="btn btn-sm btn-danger m-1"
                                                  data-toggle="tooltip"
                                                  data-placement="right"
                                                  title="Submit Evidence"
                                                  onClick={() => onSubmit(item._id)}
                                                >
                                                  Submit Evidence
                                                </button>
                                              </div>
                                            </td>
                                          ) : (
                                            <td className='text-center'>
                                              <span className={`badge badge-cb ${CHARGEBACK_STATUS_LABEL[item.chargeBackStatus]}`}>
                                                {
                                                  item.chargeBackStatus === 'LOST REFUNDED' ? "Rejection due to Refund" :
                                                    item.chargeBackStatus === 'DISPUTED' ? "Rejected" : "Accepted"
                                                }
                                              </span>
                                            </td>
                                          )
                                      }
                                      <td>
                                        <span
                                          className={`badge badge-cb ${item.chargeBackStatus &&
                                            item.chargeBackStatus === "NOT FAUGHT" ? CHARGEBACK_STATUS.NOT_FAUGHT :
                                            item.chargeBackStatus && item.chargeBackStatus === "WAITING FOR EVIDENCE" ? CHARGEBACK_STATUS.WAITING_FOR_EVIDENCE :
                                              item.chargeBackStatus && item.chargeBackStatus === "REJECTION DUE TO REFUND" ? CHARGEBACK_STATUS.REJECTION_DUE_TO_REFUND :
                                                item.chargeBackStatus && item.chargeBackStatus === "LOST REFUNDED" ? CHARGEBACK_STATUS.LOST_REFUNDED
                                                  : CHARGEBACK_STATUS[
                                                  item.chargeBackStatus
                                                  ]
                                            }`}
                                        >
                                          {item.chargeBackStatus
                                            ? item.chargeBackStatus
                                            : "--"}
                                        </span>
                                      </td>
                                      <td
                                        className="ellipsis fw-bolder"
                                        style={{ color: "#39619a" }}
                                      >
                                        {item && item.batchId
                                          ? `${item.batchId}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.companyName
                                          ? `${item.companyName}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.merchantName
                                          ? `${item.merchantName}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.productName
                                          ? item.productName
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.chargebackDate
                                          ? moment(item.chargebackDate).format(
                                            DATE.DATE_FORMAT_MINS
                                          )
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.chargebackDeadlineDate
                                          ? moment(
                                            item.chargebackDeadlineDate
                                          ).format(DATE.DATE_FORMAT_MINS)
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.chargebackAmount
                                          ? item.chargebackAmount
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.DBAName ? item.DBAName : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.cardType ? item.cardType : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.upiId ? item.upiId : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.transactionAmount
                                          ? item.transactionAmount
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.transactionDate
                                          ? moment(item.transactionDate).format(
                                            DATE.DATE_FORMAT_MINS
                                          )
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.transactionId
                                          ? item.transactionId
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.acquirerName
                                          ? item.acquirerName
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.affiliateId
                                          ? item.affiliateId
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.arn ? item.arn : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.authCode ? item.authCode : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.avs ? item.avs : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.blacklisted
                                          ? item.blacklisted
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.cardCountry
                                          ? `${item.cardCountry.name}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.cardFirstSix
                                          ? item.cardFirstSix
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.cardLastFour
                                          ? item.cardLastFour
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.cardNetwork
                                          ? item.cardNetwork
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.caseId ? item.caseId : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.chargebackCurrency
                                          ? item.chargebackCurrency
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.chargebackReason
                                          ? item.chargebackReason
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.chargeBackType
                                          ? item.chargeBackType
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.companyName
                                          ? item.companyName
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.country ? item.country : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.customerEmail
                                          ? item.customerEmail
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.customerIP
                                          ? item.customerIP
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.customerName
                                          ? item.customerName
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.customerPhone
                                          ? item.customerPhone
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.cvv ? item.cvv : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.deviceId ? item.deviceId : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.disputeFiledDate
                                          ? moment(item.disputeFiledDate).format(
                                            DATE.DATE_FORMAT_MINS
                                          )
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.disputeMethod
                                          ? item.disputeMethod
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.functionalArea
                                          ? `${item.functionalArea.functionalType}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.gateway ? item.gateway : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.issuer ? item.issuer : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.merchantName
                                          ? item.merchantName
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.mid ? item.mid : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.nonUs
                                          ? `${item.nonUs.name}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.orderId ? item.orderId : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.paymentId
                                          ? item.paymentId
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.paymentProcessor
                                          ? `${item.paymentProcessor.processorName}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.paymentMethod
                                          ? item.paymentMethod
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.reasonCodeGrouping
                                          ? `${item.reasonCodeGrouping.reasonCodeGrouping}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.reasonForLegitimateChargeback
                                          ? item.reasonForLegitimateChargeback
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.refundIssued
                                          ? item.refundIssued
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.rootCause
                                          ? `${item.rootCause.rootCauseType}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.serviceType
                                          ? `${item.serviceType.serviceType}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item.saleType ? item.saleType : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.subFunctionalArea
                                          ? `${item.subFunctionalArea.subFunctionalType}`
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.createdAt
                                          ? moment(item.createdAt).format(
                                            DATE.DATE_FORMAT_MINS
                                          )
                                          : "--"}
                                      </td>
                                      <td className="ellipsis">
                                        {item && item.updatedAt
                                          ? moment(item.updatedAt).format(
                                            DATE.DATE_FORMAT_MINS
                                          )
                                          : "--"}
                                      </td>
                                    </tr>
                                  );
                                })
                              )
                            })
                          ) : (
                            <tr className="text-center py-3">
                              <td colSpan="30%">No record(s) found</td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="30%" className="text-center">
                              <div
                                className="spinner-border text-primary m-5"
                                role="status"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table >
                  </div >
                </div >
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  );
};

const mapStateToProps = (state) => {
  const { approveCBMStore } = state
  return {
    approveCBMs: approveCBMStore && approveCBMStore.approveCBMs ? approveCBMStore.approveCBMs : [],
    loadingCBM: approveCBMStore && approveCBMStore.loading ? approveCBMStore.loading : false,
    loadingAPCBME:
      state && state.approveAllCBMStore && state.approveAllCBMStore.loadingAPCBME,
    statusAPCBME: state && state.approveAllCBMStore && state.approveAllCBMStore.statusAPCBME,
    messageAPCBME: state && state.approveAllCBMStore && state.approveAllCBMStore.messageAPCBME,
    loadingGSELM:
      state && state.getLinkMerchantStore && state.getLinkMerchantStore.loading,
    statusGSELM:
      state && state.getLinkMerchantStore && state.getLinkMerchantStore.statusGSELM,
    messageGSELM:
      state && state.getLinkMerchantStore && state.getLinkMerchantStore.messageGSELM,
    getMailSendHistory: state && state.getLinkMerchantStore && state.getLinkMerchantStore.getMailSendHistory,
  }
}

const mapDispatchToProps = dispatch => ({
  getCBMDetailsDispatch: (id, headers) =>
    dispatch(approveCBMActions.get(id, headers)),
  approveCBMActionDispatch: (data, headers) => dispatch(approveALLCBMActions.add(data, headers)),
  clearCBMActionDispatch: () => dispatch(approveALLCBMActions.clear()),
  sendLinkDispatch: (id, headers) => dispatch(getLinkMerchantActions.get(id, headers)),
  clearsendLinkDispatch: () => dispatch(getLinkMerchantActions.clear()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantEvidence)
