export const countryActionsTypes = {
  GET_COUNTRY: 'GET_COUNTRY',
  SAVE_COUNTRY_RESPONSE: 'SAVE_COUNTRY_RESPONSE',
  CLEAR_COUNTRY: 'CLEAR_COUNTRY'
}

export const countryActions = {
  getCountrys: (params, headers) => ({
    type: countryActionsTypes.GET_COUNTRY,
    params, headers
  }),
  saveCountryResponse: data => ({
    type: countryActionsTypes.SAVE_COUNTRY_RESPONSE,
    data
  }),
  clearCountry: () => ({
    type: countryActionsTypes.CLEAR_COUNTRY
  })
}

export const stateActionsTypes = {
  GET_STATE: 'GET_STATE',
  SAVE_STATE_RESPONSE: 'SAVE_STATE_RESPONSE',
  CLEAR_STATE: 'CLEAR_STATE'
}

export const stateActions = {
  getStates: (params, headers) => ({
    type: stateActionsTypes.GET_STATE,
    params, headers
  }),
  saveStateResponse: data => ({
    type: stateActionsTypes.SAVE_STATE_RESPONSE,
    data
  }),
  clearState: () => ({
    type: stateActionsTypes.CLEAR_STATE
  })
}

export const cityActionsTypes = {
  GET_CITY: 'GET_CITY',
  SAVE_CITY_RESPONSE: 'SAVE_CITY_RESPONSE',
  CLEAR_CITY: 'CLEAR_CITY'
}

export const cityActions = {
  getCitys: (params, headers) => ({
    type: cityActionsTypes.GET_CITY,
    params, headers
  }),
  saveCityResponse: data => ({
    type: cityActionsTypes.SAVE_CITY_RESPONSE,
    data
  }),
  clearCity: () => ({
    type: cityActionsTypes.CLEAR_CITY
  })
}
