import { put, takeLatest, all, fork } from 'redux-saga/effects'
import { API_MESSAGES, SESSION, HEADER } from '../../utils/constants'
import { getLocalStorage } from '../../utils/helper'
import axiosInstance from '../../services'
import {
  issuerbankPostCaseActions,
  issuerbankCreateCaseTypes,
  importInquiryAlertTypes,
  importInquiryAlertActions,
  issuerbankpostAlertCaseActions,
  issuerbankAlertCaseTypes,
  importAlertActions,
  importAlertTypes
} from '../actions'
import serviceList from '../../services/serviceList'

function * postIssuerBankCase (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.issuerbankInquiry, payload, {headers:headers})
    if (json.status === 200) {
      yield put(issuerbankPostCaseActions.savePostCaseResponse(json.data))
    } else {
      yield put(issuerbankPostCaseActions.savePostCaseResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: issuerbankCreateCaseTypes.RESPONSE, data })
  }
}

function * postImportInquiryAlert (action) {
  const { payload, config } = action
  const headers = {
    'Content-Type': 'multipart/form-data',
    [`${HEADER.TOKEN}`]: `Bearer ${getLocalStorage(SESSION.TOKEN)}`
  }
  try {
    const json = yield axiosInstance.post(serviceList.issuerbankInquiryupload, payload, config, { headers: headers })
    if (json.status === 200) {
      yield put(importInquiryAlertActions.saveResponse(json.data))
    } else {
      yield put(importInquiryAlertActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: importInquiryAlertTypes.RESPONSE, data })
  }
}

function * postAlertIssuerBankCase (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.issuerbankPostAlert, payload, {headers:headers})
    if (json.status === 200) {
      yield put(issuerbankpostAlertCaseActions.savepostAlertCaseResponse(json.data))
    } else {
      yield put(issuerbankpostAlertCaseActions.savepostAlertCaseResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: issuerbankAlertCaseTypes.RESPONSE, data })
  }
}

function * postImportAlert (action) {
  const { payload, config } = action
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*'
  }
  try {
    const json = yield axiosInstance.post(serviceList.issuerbankAlertUpload, payload, config, { headers: headers })
    if (json.status === 200) {
      yield put(importAlertActions.saveResponse(json.data))
    } else {
      yield put(importAlertActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: importAlertTypes.RESPONSE, data })
  }
}

function * fetchIssuerbankWatcher () {
  yield all([
    yield takeLatest(issuerbankCreateCaseTypes.REQUEST, postIssuerBankCase),
    yield takeLatest(importInquiryAlertTypes.REQUEST, postImportInquiryAlert),
    yield takeLatest(issuerbankAlertCaseTypes.REQUEST, postAlertIssuerBankCase),
    yield takeLatest(importAlertTypes.REQUEST, postImportAlert)
  ])
}

const creatCaseSagas = [
  fork(fetchIssuerbankWatcher)
]

export default creatCaseSagas
