import React from 'react'
// import Select from 'react-select'
import Select, { createFilter } from "react-select";
import color from '../../../utils/colors'

function ReactSelect(props) {
  const {
    styles,
    options,
    handleChangeReactSelect,
    value,
    isMulti,
    isDisabled,
    isLoading,
    noOptionsMessage,
    placeholder,
    isClearable,
    initialScrollOffset,
    handleInputChange,
    onBlur
  } = props

  return (
    <Select
      styles={styles}
      value={value || ''}
      onInputChange={(e) => { handleInputChange && handleInputChange(e) }}
      onBlur={ (e) => onBlur && onBlur(e) }
      onChange={(e) => handleChangeReactSelect(e)}
      placeholder={placeholder}
      options={options}
      isLoading={isLoading}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        borderWidth: 1,
        colors: {
          ...theme.colors,
          primary25: color.tangerine,
          primary: color.redSand,
          primary75: color.peach,
          primary50: color.redSand
        }
      })}
      isMulti={isMulti}
      noOptionsMessage={noOptionsMessage}
      isClearable={isClearable || false}
      isDisabled={isDisabled || false}
      initialScrollOffset={initialScrollOffset}
    />
  )
}

export default ReactSelect
