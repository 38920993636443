import React from 'react'
import { toAbsoluteUrl } from '../../theme/helpers'
import { colors } from '../../utils/constants'

const Logout = () => {

  const handleChangePassword = () => {
    window.location.href = '/change-password'
  }

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/illustrations/sketchy-1/14.png"
          )})`,
          backgroundColor: colors.oxfordBlue,
        }}
      >
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="#" className="mb-12">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              className="h-65px"
            />
          </a>
          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>Session Expired</h1>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#F1416C" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path
                  d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                />
              </svg>
            </div>
            <div className='fv-row mb-10'>
              <p className='text-center fs-5 m-5'>Your Session has been Expired!</p>
              <p className='text-center fs-5 m-5'>Password has to be changed</p>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-lg btn-primary w-40 mb-5"
                onClick={() => handleChangePassword()}
              >
                <span className="indicator-label">Change Password</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Logout
