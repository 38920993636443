export const partnerActionsTypes = {
  GET_PARTNER: 'GET_PARTNER',
  SAVE_PARTNER_RESPONSE: 'SAVE_PARTNER_RESPONSE',
  CLEAR_PARTNER: 'CLEAR_PARTNER'
}

export const partnerActions = {
  getPartner: (params, headers) => ({
    type: partnerActionsTypes.GET_PARTNER,
    params,
    headers
  }),
  savepartnerResponse: data => ({
    type: partnerActionsTypes.SAVE_PARTNER_RESPONSE,
    data
  }),
  clearPartner: () => ({
    type: partnerActionsTypes.CLEAR_PARTNER
  })
}

export const addPartnerActionsType = {
  REQUEST: 'ADD_PARTNERS_REQUEST',
  RESPONSE: 'ADD_PARTNERS_RESPONSE',
  ERROR: 'ADD_PARTNERS_ERROR',
  CLEAR: 'ADD_PARTNERS_CLEAR'
}

export const addPartnerActions = {
  add: (data, headers) => {
    return {
      type: addPartnerActionsType.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addPartnerActionsType.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addPartnerActionsType.CLEAR
  })
}

export const getPartnersDetailsActionsTypes = {
  REQUEST: 'GET_PARTNERS_DETAILS_REQUEST',
  RESPONSE: 'GET_PARTNERS_DETAILS_RESPONSE',
  ERROR: 'GET_PARTNERS_DETAILS_ERROR',
  CLEAR: 'GET_PARTNERS_DETAILS_CLEAR'
}
export const getPartnersDetailsActions = {
  getDetails: (id, headers) => ({
    type: getPartnersDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getPartnersDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getPartnersDetailsActionsTypes.CLEAR
  })
}

export const updatePartnersActionsTypes = {
  REQUEST: 'UPDATE_PARTNERS_REQUEST',
  RESPONSE: 'UPDATE_PARTNERS_RESPONSE',
  ERROR: 'UPDATE_PARTNERS_ERROR',
  CLEAR: 'UPDATE_PARTNERS_CLEAR'
}

export const updatePartnersActions = {
  update: (id, params, headers) => {
    return {
      type: updatePartnersActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updatePartnersActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updatePartnersActionsTypes.CLEAR
  })
}

export const deletePartnersActionsTypes = {
  REQUEST: 'DELETE_PARTNERS_REQUEST',
  RESPONSE: 'DELETE_PARTNERS_RESPONSE',
  ERROR: 'DELETE_PARTNERS_ERROR',
  CLEAR: 'DELETE_PARTNERS_CLEAR'
}

export const deletePartnersActions = {
  delete: (params, headers) => ({
    type: deletePartnersActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: deletePartnersActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deletePartnersActionsTypes.CLEAR
  })
}

export const getPartnersClientActionsTypes = {
  REQUEST: 'GET_PARTNERS_CLIENT_REQUEST',
  RESPONSE: 'GET_PARTNERS_CLIENT_RESPONSE',
  ERROR: 'GET_PARTNERS_CLIENT_ERROR',
  CLEAR: 'GET_PARTNERS_CLIENT_CLEAR'
}
export const getPartnersClientActions = {
  getDetails: (id, headers) => ({
    type: getPartnersClientActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getPartnersClientActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getPartnersClientActionsTypes.CLEAR
  })
}
