import React, { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { setLocalStorage, getLocalStorage } from '../../utils/helper'
import { KTSVG } from '../../theme/helpers'
import { LAYOUT_DETAILS } from '../../utils/constants'
import _ from 'lodash'

function Search (props) {
  const {
    addSearchColumns,
    showModal,
    show
  } = props
  const didMount = React.useRef(false)
  const [columns, setColumns] = useState({
    issuerBankId: true,
    clientId: false,
    merchantId: false,
    partnerId: false,
    alertId: true,
    alertDate: true,
    alertType: true,
    notificationType: true,
    transactionType: true,
    upiTransactionId: true,
    cardFirstSixDigits: false,
    cardLastFourDigits: false,
    cardBin: true,
    expirationMonth: false,
    expirationYear: false,
    transactionId: true,
    customerFirstName: true,
    customerLastName: true,
    alertDescriptor: true,
    alertDescriptorContact: false,
    refundedBeforeTime: false,
    outputResolutionDate: false,
    expirationStatus: false,
    isLastSixMonths: false,
    provider: false,
    cardNumber: false,
    salesTransactionDate: false,
    salesTransactionAmount: false,
    salesTransactionCurrency: false,
    customerAddress: false,
    acquirerReferrenceNumber: false,
    assignedToId: false,
    authCode: false,
    blacklistCustomer: false,
    cardVendor: false,
    chargeBackId: false,
    chargeBackReasonCode: false,
    chargeBackSaleDate: false,
    chargeBackStatus: false,
    city: false,
    cityOfMerchant: false,
    cardType: false,
    deflected: false,
    updatedDeflectionstatus: false,
    updatedDeflectionDate: false,
    createdBy: false,
    updatedBy: false,
    status: false
  })

  useEffect(() => {
    const localSearchData = JSON.parse(getLocalStorage(LAYOUT_DETAILS.SEARCH_IBA))
    if (show === 'search') {
      if (!_.isEmpty(localSearchData)) {
        addSearchColumns(localSearchData)
        setColumns(localSearchData)
      } else {
        setLocalStorage(LAYOUT_DETAILS.SEARCH_IBA, JSON.stringify(columns))
        addSearchColumns(columns)
        setColumns(columns)
      }
    }
  }, [showModal, show])

  useEffect(() => {
    if (didMount.current && !showModal) {
      setLocalStorage(LAYOUT_DETAILS.SEARCH_IBA, JSON.stringify(columns))
      addSearchColumns(columns)
      setColumns(columns)
    }
  }, [showModal])

  const handleAddColumnsChange = (e) => {
    const { name, checked } = e.target
    setColumns((values) => ({ ...values, [name]: checked }))
  }

  const handleSave = () => {
    const validate = Object.values(columns).every(item => item === false)
    if (validate) {
      handleReset()
    } else {
      setColumns(columns)
      setLocalStorage(LAYOUT_DETAILS.SEARCH_IBA, JSON.stringify(columns))
      addSearchColumns(columns)
    }
  }

  const handleReset = () => {
    columns.issuerBankId = true
    columns.clientId = false
    columns.merchantId = false
    columns.partnerId = false
    columns.alertId = true
    columns.alertDate = true
    columns.alertType = true
    columns.notificationType = true
    columns.transactionType = true
    columns.upiTransactionId = true
    columns.cardFirstSixDigits = false
    columns.cardLastFourDigits = false
    columns.cardBin = true
    columns.expirationMonth = false
    columns.expirationYear = false
    columns.transactionId = true
    columns.customerFirstName = true
    columns.customerLastName = true
    columns.alertDescriptor = true
    columns.alertDescriptorContact = false
    columns.refundedBeforeTime = false
    columns.outputResolutionDate = false
    columns.expirationStatus = false
    columns.isLastSixMonths = false
    columns.provider = false
    columns.cardNumber = false
    columns.salesTransactionDate = false
    columns.salesTransactionAmount = false
    columns.salesTransactionCurrency = false
    columns.customerAddress = false
    columns.acquirerReferrenceNumber = false
    columns.assignedToId = false
    columns.authCode = false
    columns.blacklistCustomer = false
    columns.cardVendor = false
    columns.chargeBackId = false
    columns.chargeBackReasonCode = false
    columns.chargeBackSaleDate = false
    columns.chargeBackStatus = false
    columns.city = false
    columns.cityOfMerchant = false
    columns.cardType = false
    columns.deflected = false
    columns.updatedDeflectionstatus = false
    columns.updatedDeflectionDate = false
    columns.createdBy = false
    columns.updatdBy = false    
    columns.status = false
    setLocalStorage(LAYOUT_DETAILS.SEARCH_IBA, JSON.stringify(columns))
    setColumns(columns)
    addSearchColumns(columns)
  }

  useEffect(() => {
    if (!didMount.current) { didMount.current = true }
  })

  return (
    <>
      <div className='d-flex flex-row-reverse bd-highlight tab-pane' id='search'>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='pt-1'>
            <div className='row'>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='alertId'
                        value={columns.alertId}
                        checked={columns.alertId}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Alert ID
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='issuerBankId '
                        value={columns.issuerBankId}
                        checked={columns.issuerBankId}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Issuer Bank
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='alertDate'
                        value={columns.alertDate}
                        checked={columns.alertDate}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Alert Date
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='alertType'
                        value={columns.alertType}
                        checked={columns.alertType}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Alert Type
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='notificationType'
                        value={columns.notificationType}
                        checked={columns.notificationType}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Notification Type
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='transactionType'
                        value={columns.transactionType}
                        checked={columns.transactionType}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Transaction Type
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='upiTransactionId'
                        value={columns.upiTransactionId}
                        checked={columns.upiTransactionId}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        UPI Transaction Id
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='cardBin'
                        value={columns.cardBin}
                        checked={columns.cardBin}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Card Bin
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='transactionId'
                        value={columns.transactionId}
                        checked={columns.transactionId}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Transaction Id
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='customerFirstName'
                        value={columns.customerFirstName}
                        checked={columns.customerFirstName}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Customer First Name
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='customerLastName'
                        value={columns.customerLastName}
                        checked={columns.customerLastName}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Customer Last Name
                      </span>
                    </label>
                  </div>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='alertDescriptor'
                        value={columns.alertDescriptor}
                        checked={columns.alertDescriptor}
                        onChange={(e) => handleAddColumnsChange(e)}
                        disabled
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Alert Descriptor
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='cardFirstSixDigits'
                        value={columns.cardFirstSixDigits}
                        checked={columns.cardFirstSixDigits}
                        onChange={(e) => handleAddColumnsChange(e)}
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Card First 6 Digits
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='menu-inline menu-column menu-active-bg'>
                  <div className='mb-5'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='cardLastFourDigits'
                        value={columns.cardLastFourDigits}
                        checked={columns.cardLastFourDigits}
                        onChange={(e) => handleAddColumnsChange(e)}
                      />
                      <span
                        className='form-check-label'
                        htmlFor='flexCheckChecked'
                      >
                        Card Last 4 Digits
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left-lg-1'>
                <div className='mb-5'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='expirationMonth'
                      value={columns.expirationMonth}
                      checked={columns.expirationMonth}
                      onChange={(e) => handleAddColumnsChange(e)}
                    />
                    <span
                      className='form-check-label'
                      htmlFor='flexCheckChecked'
                    >
                      Expiration Month
                    </span>
                  </label>
                </div>
              </div>
              <div className='pt-1'>
                <div className='row'>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='expirationYear'
                            value={columns.expirationYear}
                            checked={columns.expirationYear}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Expiration Year
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='alertDescriptorContact'
                            value={columns.alertDescriptorContact}
                            checked={columns.alertDescriptorContact}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Alert Descriptor Contact
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='refundedBeforeTime'
                            value={columns.refundedBeforeTime}
                            checked={columns.refundedBeforeTime}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Refunded Before Time
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='outputResolutionDate'
                            value={columns.outputResolutionDate}
                            checked={columns.outputResolutionDate}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Output Resolution Date
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='expirationStatus'
                            value={columns.expirationStatus}
                            checked={columns.expirationStatus}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Expiration Status
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='isLastSixMonths'
                            value={columns.isLastSixMonths}
                            checked={columns.isLastSixMonths}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Is Last Six Months
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='provider'
                            value={columns.provider}
                            checked={columns.provider}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Provider
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='cardNumber'
                            value={columns.cardNumber}
                            checked={columns.cardNumber}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Card Number
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='salesTransactionDate'
                            value={columns.salesTransactionDate}
                            checked={columns.salesTransactionDate}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Sales Transaction Date
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='salesTransactionAmount'
                            value={columns.salesTransactionAmount}
                            checked={columns.salesTransactionAmount}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Sales Transaction Amount
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='salesTransactionCurrency'
                            value={columns.salesTransactionCurrency}
                            checked={columns.salesTransactionCurrency}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Sales Transaction Currency
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='customerAddress'
                            value={columns.customerAddress}
                            checked={columns.customerAddress}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Customer Address
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='acquirerReferrenceNumber'
                            value={columns.acquirerReferrenceNumber}
                            checked={columns.acquirerReferrenceNumber}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            ARN
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='assignedToId'
                            value={columns.assignedToId}
                            checked={columns.assignedToId}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Assigned ToId
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='blacklistCustomer'
                            value={columns.blacklistCustomer}
                            checked={columns.blacklistCustomer}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Blacklist Customer
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='cardVendor'
                            value={columns.cardVendor}
                            checked={columns.cardVendor}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Card Vendor
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='createdBy'
                            value={columns.createdBy}
                            checked={columns.createdBy}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Created By
                          </span>
                        </label>
                      </div>

                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='updatedBy'
                            value={columns.updatedBy}
                            checked={columns.updatedBy}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Updated By
                          </span>
                        </label>
                      </div>

                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='merchantId'
                            value={columns.merchantId}
                            checked={columns.merchantId}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Merchant
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='chargeBackId'
                            value={columns.chargeBackId}
                            checked={columns.chargeBackId}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Charge Back Id
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='chargeBackReasonCode'
                            value={columns.chargeBackReasonCode}
                            checked={columns.chargeBackReasonCode}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Charge Back Reason Code
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='chargeBackSaleDate'
                            value={columns.chargeBackSaleDate}
                            checked={columns.chargeBackSaleDate}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Charge Back Sale Date
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='chargeBackStatus'
                            value={columns.chargeBackStatus}
                            checked={columns.chargeBackStatus}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Charge Back Status
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='city'
                            value={columns.city}
                            checked={columns.city}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            City
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='cityOfMerchant'
                            value={columns.cityOfMerchant}
                            checked={columns.cityOfMerchant}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            City Of Merchant
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='clientId'
                            value={columns.clientId}
                            checked={columns.clientId}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Client
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 border-left-lg-1'>
                    <div className='menu-inline menu-column menu-active-bg'>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='deflected'
                            value={columns.deflected}
                            checked={columns.deflected}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Deflected
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='updatedDeflectionstatus'
                            value={columns.updatedDeflectionstatus}
                            checked={columns.updatedDeflectionstatus}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Updated Deflection Status
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='updatedDeflectionDate'
                            value={columns.updatedDeflectionDate}
                            checked={columns.updatedDeflectionDate}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Updated Deflection Date
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='status'
                            value={columns.status}
                            checked={columns.status}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Status
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='authCode'
                            value={columns.authCode}
                            checked={columns.authCode}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Auth Code
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='cardType'
                            value={columns.cardType}
                            checked={columns.cardType}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Card Type
                          </span>
                        </label>
                      </div>
                      <div className='mb-5'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='partnerId'
                            value={columns.partnerId}
                            checked={columns.partnerId}
                            onChange={(e) => handleAddColumnsChange(e)}
                          />
                          <span
                            className='form-check-label'
                            htmlFor='flexCheckChecked'
                          >
                            Partner
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group row mb-4'>
                  <div className='col-lg-6 d-flex justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-lg btn-icon btn-active-light-primary close'
                      data-dismiss='modal'
                    >
                      {/* eslint-disable */}
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                      {/* eslint-disable */}
                    </button>
                  </div>
                  <div className='col-lg-6 d-flex justify-content-end'>
                    <button
                      className='btn btn-sm btn-light-danger me-3'
                      onClick={() => handleReset()}
                    >
                      Reset
                    </button>
                    <button
                      className='btn btn-sm btn-light-primary close'
                      onClick={() => handleSave()}
                      data-dismiss='modal'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Search
