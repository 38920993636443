import { combineReducers } from "redux";
import postsReducer, { postsStoreKey } from "./posts";
import {
  chargebackReducer,
  chargebackStoreKey,
  addChargebackReducer,
  addChargebackStoreKey,
  editChargebacksReducer,
  editChargebacksStoreKey,
  deleteChargebackReducer,
  chargebackDeleteStoreKey,
  approveCBReducer,
  approveCBStoreKey,
  approveCBMReducer,
  approveCBMStoreKey,
  addNotificationToMerchantStoreKey,
  addNotificationToMerchantReducer,
  approveAllCBMStoreKey,
  approveAllCBMReducer,
  updateCBApproveStoreKey,
  updateCBApproveReducer,
  singleNotifyToMerchantStoreKey,
  singleNotifyToMerchantReducer,
  singleApproveMerchantStoreKey,
  singleApproveMerchantReducer,
  downloadInvoiceStoreKey,
  downloadInvoiceReducer,
} from "./chargebackManagement";
import {
  preventionReducer,
  preventionStoreKey,
  editAlertsReducer,
  editAlertsStoreKey,
  outcomeReducer,
  outcomeStoreKey,
  refundReducer,
  refundStoreKey,
} from "./preventionAlert";
import {
  orderinsightReducer,
  orderinsightStoreKey,
  orderinsightExportColumnStoreKey,
  orderinsightExportColumnReducer,
} from "./orderInsight";
import loginReducer, { loginStoreKey } from "./login";
import {
  forgotPasswordReducer,
  forgotPasswordStoreKey,
  verifyUserReducer,
  verifyUserStoreKey,
  resetPasswordStoreKey,
  resetPasswordReducer,
  changePasswordStoreKey,
  changePasswordReducer,
} from "./forgotPassword";
import exportReducer, { exportStoreKey } from "./export";
import {
  clientStoreKey,
  clientReducer,
  userRoleStoreKey,
  userRoleReducer,
  industryStoreKey,
  industryReducer,
  clientIdStoreKey,
  clientIdStoreReducer,
} from "./clients";
import {
  partnerReducer,
  partnerStoreKey,
  addPartnersReducer,
  addPartnerstoreKey,
  getPartnersDetailsStoreKey,
  getPartnersDetailsReducer,
  getPartnersUsersStoreKey,
  getPartnersUsersReducer,
  updatePartnerstoreKey,
  updatePartnersReducer,
  deletePartnerstoreKey,
  deletePartnersReducer,
} from "./partners";
import {
  partnerCrudFilterStoreKey,
  partnerCrudFilterReducer,
  clientCredFilterStoreKey,
  clientCredFilterReducer,
  merchantCredFilterStoreKey,
  merchantCredFilterReducer,
  midCredFiltertoreKey,
  midCredFilterReducer,
} from "./authBasedFilter";
import {
  addMerchantCLReducer,
  addMerchantCLStoreKey,
  merchantReducer,
  merchantStoreKey,
  merchantDeleteStoreKey,
  deleteMerchantReducer,
  merchantSaveKey,
  saveMerchantReducer,
  editMerchantReducer,
  editMerchantStoreKey,
  addMerchantUploadStoreKey,
  addMerchantUploadReducer,
} from "./merchants";
import {
  issuerbankReducer,
  issuerbankStoreKey,
  issuerbankAlertReducer,
  issuerbankAlertStoreKey,
} from "./issuerBank";
import bankReducer, { bankStoreKey } from "./bank";
import {
  issuerbanklistReducer,
  issuerbanklistStoreKey,
  issuerbankExportColumnReducer,
  issuerbankExportColumnStoreKey,
  issuerbanklistalertsReducer,
  issuerbanklistalertsStoreKey,
  issuerbankalertExportColumnReducer,
  issuerbankalertExportColumnStoreKey,
} from "./issuerBankList";
import {
  transactionStoreKey,
  transactionReducer,
  editTransactionStoreKey,
  editTransactionReducer,
  customerStoreKey,
  customerReducer,
  editCustomerStoreKey,
  editCustomerReducer,
  purchaseStoreKey,
  purchaseReducer,
  editPurchaseStoreKey,
  editPurchaseReducer,
  merchantCBStoreKey,
  merchantCBReducer,
  editMerchantCBStoreKey,
  editMerchantCBReducer,
  addDocumentReducer,
  addDocumentStoreKey,
  documentStoreKey,
  documentReducer,
  docTypeStoreKey,
  docTypeReducer,
  documentDeleteStoreKey,
  deleteDocumentReducer,
  addRebuttalStoreKey,
  addRebuttalReducer,
  rebuttalStoreKey,
  rebuttalReducer,
  emailStoreKey,
  emailReducer,
  addDownloadStoreKey,
  addDownloadReducer,
  addZipStoreKey,
  addZipReducer,
  addEmailStoreKey,
  addEmailReducer,
  addSendLinkStoreKey,
  addSendLinkReducer,
  SendLinkStoreKey,
  SendLinkReducer,
  merchantEvidenceStoreKey,
  merchantEvidenceReducer,
  addMerchantEvidenceStoreKey,
  addMerchantEvidenceReducer,
  getAnalystStoreKey,
  getAnalystReducer,
  addEvidenceStoreKey,
  addEvidenceReducer,
  getLinkMerchantStoreKey,
  getLinkMerchantReducer,
  sendCustomMailStoreKey,
  sendCustomMailReducer,
} from "./CBDetails";
import {
  addClientMgmtReducer,
  addClientMgmtStoreKey,
  clientDetailsStoreKey,
  clientDetailsReducer,
  editClientStoreKey,
  editClientReducer,
} from "./clientManagement";
import {
  countryReducer,
  countryStoreKey,
  stateReducer,
  stateStoreKey,
  cityReducer,
  cityStoreKey,
} from "./country";
import {
  crmReducer,
  crmStoreKey,
  processorStoreKey,
  procesorReducer,
  paymentStoreKey,
  paymentReducer,
  packageReducer,
  packageStoreKey,
  editPackageReducer,
  editPackageStoreKey,
  addUserStoreKey,
  addUserReducer,
  userReducer,
  userStoreKey,
  editUserReducer,
  editUserStoreKey,
  packPaymentReducer,
  packPaymentStoreKey,
  clientPackagesReducer,
  clientPackagesStoreKey,
  partnerClientStoreKey,
  partnerClientReducer,
  addPartnerClientStoreKey,
  addPartnerClientReducer,
  deleteUsersStoreKey,
  deleteUsersReducer,
  listStoreKey,
  listReducer,
  clientCrmlistKey,
  clientcrmDetailReducer,
} from "./crm";
import {
  clientCRMReducer,
  clientCRMStoreKey,
  clientProcesorReducer,
  clientProcessorStoreKey,
  clientGatewayStoreKey,
  clientGatewayReducer,
  editClientCrmStoreKey,
  editClientCrmReducer,
  editClientProcessorStoreKey,
  editClientProcessorReducer,
  editClientGatewayStoreKey,
  editClientGatewayReducer,
  addclientCRMStoreKey,
  addclientCRMReducer,
  addclientProcessorStoreKey,
  addclientProcessorReducer,
  addclientGatewayStoreKey,
  addclientGatewayReducer,
  clientCRMDeleteStoreKey,
  deleteclientCRMReducer,
  clientProcessorDeleteStoreKey,
  deleteclientProcessorReducer,
  clientGatewayDeleteStoreKey,
  deleteclientGatewayReducer,
  midCRMReducer,
  midCRMStoreKey,
  deletemidCRMReducer,
  midCRMDeleteStoreKey,
  addmidCRMStoreKey,
  addmidCRMReducer,
  editMIDCRMStoreKey,
  editMIDCRMReducer,
  checkProcessorStoreKey,
  checkProcessorReducer,
  getClientProcessorDetailsStoreKey,
  getClientProcessorDetailsReducer,
  checkCrmStoreKey,
  checkCrmReducer,
} from "./clientCRM";
import {
  addCoverletterReducer,
  addCoverletterStoreKey,
  coverletterReducer,
  coverletterStoreKey,
  editCoverletterReducer,
  editCoverletterStoreKey,
  deleteCoverletterReducer,
  coverletterDeleteStoreKey,
  reasonGroupingReducer,
  reasonGroupingStoreKey,
  serviceTypeReducer,
  serviceTypeStoreKey,
} from "./manageCoverletter";
import {
  addUserroleReducer,
  addUserroleStoreKey,
  userroleReducer,
  userroleStoreKey,
  editUserroleReducer,
  editUserroleStoreKey,
  deleteUserroleReducer,
  userroleDeleteStoreKey,
  getUserTypeReducer,
  getUserTypeStoreKey,
} from "./userRole";
import {
  getComponentsStoreKey,
  getComponentsReducer,
  addComponentsStoreKey,
  addComponentsReducer,
  getComponentsDetailsStoreKey,
  getComponentsDetailsReducer,
  updateComponentsStoreKey,
  updateComponentsReducer,
  deleteComponentsStoreKey,
  deleteComponentsReducer,
} from "./userComponents";
import {
  getPrivilegesStoreKey,
  getPrivilegesReducer,
  addPrivilegesStoreKey,
  addPrivilegesReducer,
  getPrivilegesDetailsStoreKey,
  getPrivilegesDetailsReducer,
  updatePrivilegesStoreKey,
  updatePrivilegesReducer,
  deletePrivilegesStoreKey,
  deletePrivilegesReducer,
  getCompPermissionStoreKey,
  getCompPermissionReducer,
} from "./userPrevileges";
import {
  addUsertypeReducer,
  addUsertypeStoreKey,
  usertypeReducer,
  usertypeStoreKey,
  editUsertypeReducer,
  editUsertypeStoreKey,
  deleteUsertypeReducer,
  usertypeDeleteStoreKey,
} from "./userType";
import {
  addReasoncodeReducer,
  addReasoncodeStoreKey,
  ReasoncodeReducer,
  ReasoncodeStoreKey,
  editReasoncodeReducer,
  editReasoncodeStoreKey,
  deleteReasoncodeReducer,
  ReasoncodeDeleteStoreKey,
} from "./reasonCode";
import {
  rootCauseReducer,
  rootCauseStoreKey,
  functionalReducer,
  functionalStoreKey,
  subFunctionalReducer,
  subFunctionalStoreKey,
  operationalTypeReducer,
  operationalTypeStoreKey,
} from "./reasonCodeMatrix";
import {
  addUploadsCBReducer,
  addUploadsCBStoreKey,
  addUploadsCBStatusStoreKey,
  addUploadsCBStatusReducer,
} from "./uploadChargeback";
import {
  addCrmSettingsReducer,
  addCrmSettingsStoreKey,
  getCrmSettingsDetailsReducer,
  getCrmSettingsDetailsStoreKey,
  updateCrmSettingsReducer,
  updateCrmSettingsStoreKey,
  deleteCrmSettingsReducer,
  deleteCrmSettingsStoreKey,
} from "./CrmSettings";
import {
  addProcessorSettingsReducer,
  addProcessorSettingsStoreKey,
  getProcessorSettingsDetailsReducer,
  getProcessorSettingsDetailsStoreKey,
  updateProcessorSettingsReducer,
  updateProcessorSettingsStoreKey,
  deleteProcessorSettingsReducer,
  deleteProcessorSettingsStoreKey,
} from "./ProcessorSettings";
import {
  addAcquirerSettingsReducer,
  addAcquirerSettingsStoreKey,
  getAcquirerSettingsDetailsReducer,
  getAcquirerSettingsDetailsStoreKey,
  updateAcquirerSettingsReducer,
  updateAcquirerSettingsStoreKey,
  deleteAcquirerSettingsReducer,
  deleteAcquirerSettingsStoreKey,
  acquirerStoreKey,
  acquirerReducer,
} from "./AcquirerSettings";
import {
  addGatewaySettingsReducer,
  addGatewaySettingsStoreKey,
  getGatewaySettingsDetailsReducer,
  getGatewaySettingsDetailsStoreKey,
  updateGatewaySettingsReducer,
  updateGatewaySettingsStoreKey,
  deleteGatewaySettingsReducer,
  deleteGatewaySettingsStoreKey,
} from "./GatewaySettings";
import {
  addIndustrySettingsReducer,
  addIndustrySettingsStoreKey,
  getIndustrySettingsDetailsReducer,
  getIndustrySettingsDetailsStoreKey,
  updateIndustrySettingsReducer,
  updateIndustrySettingsStoreKey,
  deleteIndustrySettingsReducer,
  deleteIndustrySettingsStoreKey,
} from "./IndustrySettings";
import { getMenuCompReducer, getMenuCompStoreKey } from "./MenuComponents";
import {
  addUploadsMerchantReducer,
  addUploadsMerchantStoreKey,
} from "./uploadMerchant";

import {
  siteConfigReducer,
  siteConfigStoreKey,
  addSiteConfigReducer,
  addSiteConfigStoreKey,
  editSiteConfigsReducer,
  editSiteConfigsStoreKey,
  deleteSiteConfigReducer,
  siteConfigDeleteStoreKey,
} from "./siteConfig";
import {
  addAssignUserReducer,
  addAssignUserStoreKey,
  assignUserReducer,
  assignUserStoreKey,
  deleteAssignUserReducer,
  deleteAssignUserStoreKey,
} from "./filterDropdown";
import {
  addApiKeyReducer,
  addApiKeyStoreKey,
  apiKeyReducer,
  apiKeyStoreKey,
} from "./apiKey";
import {
  userProfileReducer,
  userProfileStoreKey,
  editUserProfileReducer,
  editUserProfileStoreKey,
} from "./userProfile";
import {
  addMerchantSMTPStoreKey,
  addMerchantSMTPReducer,
  getMerchantSMTPDetailsStoreKey,
  getMerchantSMTPDetailsReducer,
  addClientSMTPStoreKey,
  addClientSMTPReducer,
  getClientSMTPStoreKey,
  getClientSMTPReducer,
  getClientSMTPDetailsStoreKey,
  getClientSMTPDetailsReducer,
  variableStoreKey,
  variableReducer,
  addMerchantTemplateStoreKey,
  addMerchantTemplateReducer,
  templateStoreKey,
  templateReducer,
  editTemplateStoreKey,
  editTemplateReducer,
  templateDeleteStoreKey,
  deleteTemplateReducer,
  addGeneralTemplateStoreKey,
  addGeneralTemplateReducer,
  generalTemplateStoreKey,
  generalTemplateReducer,
  editGeneralTemplateStoreKey,
  editGeneralTemplateReducer,
  generalTemplateDeleteStoreKey,
  deletegeneralTemplateReducer,
} from "./SmtpConfig";
import {
  getSubsPackageStoreKey,
  getSubsPackageReducer,
  addSubsPackageStoreKey,
  addSubsPackageReducer,
  getSubsPackageDetailsStoreKey,
  getSubsPackageDetailsReducer,
  updateSubsPackageStoreKey,
  updateSubsPackageReducer,
  deleteSubsPackageStoreKey,
  deleteSubsPackageReducer,
  deleteSubsStoreKey,
  deleteSubsReducer,
} from "./subscriptionPackage";
import {
  getPaypalStoreKey,
  getPaypalReducer,
  addPaypalStoreKey,
  addPaypalReducer,
  deleteSubscriptionStoreKey,
  deleteSubscriptionReducer,
  addPaypalSubscriptionHistoryStoreKey,
  addPaypalSubscriptionHistoryReducer,
  getPaypalSubscriptionHistoryStoreKey,
  getPaypalSubscriptionHistoryReducer,
  addPayConfigStoreKey,
  addPayConfigReducer,
  getPayConfigStoreKey,
  getPayConfigReducer,
  getPayConfigDetailsStoreKey,
  getPayConfigDetailsReducer,
  updatePayConfigStoreKey,
  updatePayConfigReducer,
} from "./PaypalGateway";
import {
  getSubscriptionHistoryStoreKey,
  getSubscriptionHistoryReducer,
  addSubscriptionHistoryStoreKey,
  addSubscriptionHistoryReducer,
  getSubscriptionHistoryDetailsStoreKey,
  getSubscriptionHistoryDetailsReducer,
  updateSubscriptionHistoryStoreKey,
  updateSubscriptionHistoryReducer,
  deleteSubscriptionHistoryStoreKey,
  deleteSubscriptionHistoryReducer,
} from "./subscriptionHistory";
import {
  addUserRegisterStoreKey,
  addUserRegisterReducer,
} from "./userRegister";
import {
  getClientSignupStoreKey,
  getClientSignupReducer,
  addClientSignupStoreKey,
  addClientSignupReducer,
  addClientPackageStoreKey,
  addClientPackageReducer,
} from "./clientSignup";
import {
  addNotifyStoreKey,
  addNotifyReducer,
  notifyStoreKey,
  notifyReducer,
} from "./NotificationType";
import { addEmailResendStoreKey, addEmailResendReducer } from "./emailResend";

import {
  userNotificationReducer,
  userNotificationStoreKey,
} from "./userNotificationReducer";

import {
  ShopifyConnectReducer,
  ShopifyConnectStoreKey,
} from "./ShopifyConnectReducer";

import {
  merchantBillingDetailsStoreKey,
  merchantBillingDetailsReducer,
} from "./merchantBillingDetails";
export default function createReducer() {
  const rootReducer = combineReducers({
    [postsStoreKey]: postsReducer,
    [chargebackStoreKey]: chargebackReducer,
    [addChargebackStoreKey]: addChargebackReducer,
    [editChargebacksStoreKey]: editChargebacksReducer,
    [chargebackDeleteStoreKey]: deleteChargebackReducer,
    [approveCBStoreKey]: approveCBReducer,
    [approveCBMStoreKey]: approveCBMReducer,
    [addNotificationToMerchantStoreKey]: addNotificationToMerchantReducer,
    [approveAllCBMStoreKey]: approveAllCBMReducer,
    [updateCBApproveStoreKey]: updateCBApproveReducer,
    [singleNotifyToMerchantStoreKey]: singleNotifyToMerchantReducer,
    [singleApproveMerchantStoreKey]: singleApproveMerchantReducer,
    [downloadInvoiceStoreKey]: downloadInvoiceReducer,
    [siteConfigStoreKey]: siteConfigReducer,
    [addSiteConfigStoreKey]: addSiteConfigReducer,
    [editSiteConfigsStoreKey]: editSiteConfigsReducer,
    [siteConfigDeleteStoreKey]: deleteSiteConfigReducer,
    [preventionStoreKey]: preventionReducer,
    [outcomeStoreKey]: outcomeReducer,
    [refundStoreKey]: refundReducer,
    [editAlertsStoreKey]: editAlertsReducer,
    [orderinsightStoreKey]: orderinsightReducer,
    [loginStoreKey]: loginReducer,
    [forgotPasswordStoreKey]: forgotPasswordReducer,
    [verifyUserStoreKey]: verifyUserReducer,
    [resetPasswordStoreKey]: resetPasswordReducer,
    [changePasswordStoreKey]: changePasswordReducer,
    [exportStoreKey]: exportReducer,
    [orderinsightExportColumnStoreKey]: orderinsightExportColumnReducer,
    [clientStoreKey]: clientReducer,
    [userRoleStoreKey]: userRoleReducer,
    [industryStoreKey]: industryReducer,
    [partnerStoreKey]: partnerReducer,
    [addPartnerstoreKey]: addPartnersReducer,
    [getPartnersDetailsStoreKey]: getPartnersDetailsReducer,
    [updatePartnerstoreKey]: updatePartnersReducer,
    [deletePartnerstoreKey]: deletePartnersReducer,
    [getPartnersUsersStoreKey]: getPartnersUsersReducer,
    [addMerchantCLStoreKey]: addMerchantCLReducer,
    [merchantStoreKey]: merchantReducer,
    [merchantDeleteStoreKey]: deleteMerchantReducer,
    [merchantSaveKey]: saveMerchantReducer,
    [editMerchantStoreKey]: editMerchantReducer,
    [issuerbankStoreKey]: issuerbankReducer,
    [issuerbankAlertStoreKey]: issuerbankAlertReducer,
    [bankStoreKey]: bankReducer,
    [issuerbanklistStoreKey]: issuerbanklistReducer,
    [issuerbankExportColumnStoreKey]: issuerbankExportColumnReducer,
    [issuerbanklistalertsStoreKey]: issuerbanklistalertsReducer,
    [issuerbankalertExportColumnStoreKey]: issuerbankalertExportColumnReducer,
    [editTransactionStoreKey]: editTransactionReducer,
    [editCustomerStoreKey]: editCustomerReducer,
    [editPurchaseStoreKey]: editPurchaseReducer,
    [editMerchantCBStoreKey]: editMerchantCBReducer,
    [addDocumentStoreKey]: addDocumentReducer,
    [addClientMgmtStoreKey]: addClientMgmtReducer,
    [countryStoreKey]: countryReducer,
    [stateStoreKey]: stateReducer,
    [cityStoreKey]: cityReducer,
    [crmStoreKey]: crmReducer,
    [packageStoreKey]: packageReducer,
    [editPackageStoreKey]: editPackageReducer,
    [addUserStoreKey]: addUserReducer,
    [userStoreKey]: userReducer,
    [editUserStoreKey]: editUserReducer,
    [processorStoreKey]: procesorReducer,
    [acquirerStoreKey]: acquirerReducer,
    [clientDetailsStoreKey]: clientDetailsReducer,
    [editClientStoreKey]: editClientReducer,
    [paymentStoreKey]: paymentReducer,
    [packPaymentStoreKey]: packPaymentReducer,
    [clientIdStoreKey]: clientIdStoreReducer,
    [clientCRMStoreKey]: clientCRMReducer,
    [clientProcessorStoreKey]: clientProcesorReducer,
    [clientGatewayStoreKey]: clientGatewayReducer,
    [editClientCrmStoreKey]: editClientCrmReducer,
    [editClientProcessorStoreKey]: editClientProcessorReducer,
    [editClientGatewayStoreKey]: editClientGatewayReducer,
    [addclientCRMStoreKey]: addclientCRMReducer,
    [addclientProcessorStoreKey]: addclientProcessorReducer,
    [addclientGatewayStoreKey]: addclientGatewayReducer,
    [clientCRMDeleteStoreKey]: deleteclientCRMReducer,
    [clientProcessorDeleteStoreKey]: deleteclientProcessorReducer,
    [clientGatewayDeleteStoreKey]: deleteclientGatewayReducer,
    [clientPackagesStoreKey]: clientPackagesReducer,
    [midCRMStoreKey]: midCRMReducer,
    [midCRMDeleteStoreKey]: deletemidCRMReducer,
    [addmidCRMStoreKey]: addmidCRMReducer,
    [editMIDCRMStoreKey]: editMIDCRMReducer,
    [checkProcessorStoreKey]: checkProcessorReducer,
    [getClientProcessorDetailsStoreKey]: getClientProcessorDetailsReducer,
    [checkCrmStoreKey]: checkCrmReducer,
    [addCoverletterStoreKey]: addCoverletterReducer,
    [coverletterStoreKey]: coverletterReducer,
    [editCoverletterStoreKey]: editCoverletterReducer,
    [coverletterDeleteStoreKey]: deleteCoverletterReducer,
    [addUserroleStoreKey]: addUserroleReducer,
    [userroleStoreKey]: userroleReducer,
    [editUserroleStoreKey]: editUserroleReducer,
    [userroleDeleteStoreKey]: deleteUserroleReducer,
    [reasonGroupingStoreKey]: reasonGroupingReducer,
    [serviceTypeStoreKey]: serviceTypeReducer,
    [getUserTypeStoreKey]: getUserTypeReducer,
    [addUsertypeStoreKey]: addUsertypeReducer,
    [usertypeStoreKey]: usertypeReducer,
    [editUsertypeStoreKey]: editUsertypeReducer,
    [usertypeDeleteStoreKey]: deleteUsertypeReducer,
    [getComponentsStoreKey]: getComponentsReducer,
    [addComponentsStoreKey]: addComponentsReducer,
    [getComponentsDetailsStoreKey]: getComponentsDetailsReducer,
    [updateComponentsStoreKey]: updateComponentsReducer,
    [deleteComponentsStoreKey]: deleteComponentsReducer,
    [getPrivilegesStoreKey]: getPrivilegesReducer,
    [addPrivilegesStoreKey]: addPrivilegesReducer,
    [getPrivilegesDetailsStoreKey]: getPrivilegesDetailsReducer,
    [updatePrivilegesStoreKey]: updatePrivilegesReducer,
    [deletePrivilegesStoreKey]: deletePrivilegesReducer,
    [getCompPermissionStoreKey]: getCompPermissionReducer,
    [addReasoncodeStoreKey]: addReasoncodeReducer,
    [ReasoncodeStoreKey]: ReasoncodeReducer,
    [editReasoncodeStoreKey]: editReasoncodeReducer,
    [ReasoncodeDeleteStoreKey]: deleteReasoncodeReducer,
    [rootCauseStoreKey]: rootCauseReducer,
    [functionalStoreKey]: functionalReducer,
    [subFunctionalStoreKey]: subFunctionalReducer,
    [operationalTypeStoreKey]: operationalTypeReducer,
    [addUploadsCBStoreKey]: addUploadsCBReducer,
    [addUploadsCBStatusStoreKey]: addUploadsCBStatusReducer,
    [transactionStoreKey]: transactionReducer,
    [customerStoreKey]: customerReducer,
    [purchaseStoreKey]: purchaseReducer,
    [merchantCBStoreKey]: merchantCBReducer,
    [documentStoreKey]: documentReducer,
    [documentDeleteStoreKey]: deleteDocumentReducer,
    [docTypeStoreKey]: docTypeReducer,
    [rebuttalStoreKey]: rebuttalReducer,
    [addRebuttalStoreKey]: addRebuttalReducer,
    [emailStoreKey]: emailReducer,
    [addDownloadStoreKey]: addDownloadReducer,
    [addZipStoreKey]: addZipReducer,
    [addEmailStoreKey]: addEmailReducer,
    [addSendLinkStoreKey]: addSendLinkReducer,
    [SendLinkStoreKey]: SendLinkReducer,
    [merchantEvidenceStoreKey]: merchantEvidenceReducer,
    [addMerchantEvidenceStoreKey]: addMerchantEvidenceReducer,
    [partnerClientStoreKey]: partnerClientReducer,
    [addPartnerClientStoreKey]: addPartnerClientReducer,
    [deleteUsersStoreKey]: deleteUsersReducer,
    [listStoreKey]: listReducer,
    [addMerchantUploadStoreKey]: addMerchantUploadReducer,
    [addMerchantSMTPStoreKey]: addMerchantSMTPReducer,
    [getMerchantSMTPDetailsStoreKey]: getMerchantSMTPDetailsReducer,
    [addClientSMTPStoreKey]: addClientSMTPReducer,
    [getClientSMTPStoreKey]: getClientSMTPReducer,
    [getClientSMTPDetailsStoreKey]: getClientSMTPDetailsReducer,
    [addMerchantTemplateStoreKey]: addMerchantTemplateReducer,
    [templateStoreKey]: templateReducer,
    [editTemplateStoreKey]: editTemplateReducer,
    [templateDeleteStoreKey]: deleteTemplateReducer,
    [addCrmSettingsStoreKey]: addCrmSettingsReducer,
    [getCrmSettingsDetailsStoreKey]: getCrmSettingsDetailsReducer,
    [updateCrmSettingsStoreKey]: updateCrmSettingsReducer,
    [deleteCrmSettingsStoreKey]: deleteCrmSettingsReducer,
    [addProcessorSettingsStoreKey]: addProcessorSettingsReducer,
    [getProcessorSettingsDetailsStoreKey]: getProcessorSettingsDetailsReducer,
    [updateProcessorSettingsStoreKey]: updateProcessorSettingsReducer,
    [deleteProcessorSettingsStoreKey]: deleteProcessorSettingsReducer,
    [addAcquirerSettingsStoreKey]: addAcquirerSettingsReducer,
    [getAcquirerSettingsDetailsStoreKey]: getAcquirerSettingsDetailsReducer,
    [updateAcquirerSettingsStoreKey]: updateAcquirerSettingsReducer,
    [deleteAcquirerSettingsStoreKey]: deleteAcquirerSettingsReducer,
    [addGatewaySettingsStoreKey]: addGatewaySettingsReducer,
    [getGatewaySettingsDetailsStoreKey]: getGatewaySettingsDetailsReducer,
    [updateGatewaySettingsStoreKey]: updateGatewaySettingsReducer,
    [deleteGatewaySettingsStoreKey]: deleteGatewaySettingsReducer,
    [addIndustrySettingsStoreKey]: addIndustrySettingsReducer,
    [getIndustrySettingsDetailsStoreKey]: getIndustrySettingsDetailsReducer,
    [updateIndustrySettingsStoreKey]: updateIndustrySettingsReducer,
    [deleteIndustrySettingsStoreKey]: deleteIndustrySettingsReducer,
    [getMenuCompStoreKey]: getMenuCompReducer,
    [addUploadsMerchantStoreKey]: addUploadsMerchantReducer,
    [addAssignUserStoreKey]: addAssignUserReducer,
    [assignUserStoreKey]: assignUserReducer,
    [deleteAssignUserStoreKey]: deleteAssignUserReducer,
    [partnerCrudFilterStoreKey]: partnerCrudFilterReducer,
    [clientCredFilterStoreKey]: clientCredFilterReducer,
    [merchantCredFilterStoreKey]: merchantCredFilterReducer,
    [midCredFiltertoreKey]: midCredFilterReducer,
    [addApiKeyStoreKey]: addApiKeyReducer,
    [apiKeyStoreKey]: apiKeyReducer,
    [userProfileStoreKey]: userProfileReducer,
    [editUserProfileStoreKey]: editUserProfileReducer,
    [variableStoreKey]: variableReducer,
    [getSubsPackageStoreKey]: getSubsPackageReducer,
    [addSubsPackageStoreKey]: addSubsPackageReducer,
    [getSubsPackageDetailsStoreKey]: getSubsPackageDetailsReducer,
    [updateSubsPackageStoreKey]: updateSubsPackageReducer,
    [deleteSubsPackageStoreKey]: deleteSubsPackageReducer,
    [deleteSubsStoreKey]: deleteSubsReducer,
    [getAnalystStoreKey]: getAnalystReducer,
    [addEvidenceStoreKey]: addEvidenceReducer,
    [getLinkMerchantStoreKey]: getLinkMerchantReducer,
    [sendCustomMailStoreKey]: sendCustomMailReducer,
    [getPaypalStoreKey]: getPaypalReducer,
    [addPaypalStoreKey]: addPaypalReducer,
    [deleteSubscriptionStoreKey]: deleteSubscriptionReducer,
    [addPaypalSubscriptionHistoryStoreKey]: addPaypalSubscriptionHistoryReducer,
    [getPaypalSubscriptionHistoryStoreKey]: getPaypalSubscriptionHistoryReducer,
    [addPayConfigStoreKey]: addPayConfigReducer,
    [getPayConfigStoreKey]: getPayConfigReducer,
    [getPayConfigDetailsStoreKey]: getPayConfigDetailsReducer,
    [updatePayConfigStoreKey]: updatePayConfigReducer,
    [addGeneralTemplateStoreKey]: addGeneralTemplateReducer,
    [generalTemplateStoreKey]: generalTemplateReducer,
    [editGeneralTemplateStoreKey]: editGeneralTemplateReducer,
    [generalTemplateDeleteStoreKey]: deletegeneralTemplateReducer,
    [getSubscriptionHistoryStoreKey]: getSubscriptionHistoryReducer,
    [addSubscriptionHistoryStoreKey]: addSubscriptionHistoryReducer,
    [getSubscriptionHistoryDetailsStoreKey]:
      getSubscriptionHistoryDetailsReducer,
    [updateSubscriptionHistoryStoreKey]: updateSubscriptionHistoryReducer,
    [deleteSubscriptionHistoryStoreKey]: deleteSubscriptionHistoryReducer,
    [addUserRegisterStoreKey]: addUserRegisterReducer,
    [getClientSignupStoreKey]: getClientSignupReducer,
    [addClientSignupStoreKey]: addClientSignupReducer,
    [addClientPackageStoreKey]: addClientPackageReducer,
    [addNotifyStoreKey]: addNotifyReducer,
    [notifyStoreKey]: notifyReducer,
    [addEmailResendStoreKey]: addEmailResendReducer,
    [userNotificationStoreKey]: userNotificationReducer,
    [ShopifyConnectStoreKey]: ShopifyConnectReducer,
    [clientCrmlistKey]: clientcrmDetailReducer,
    [merchantBillingDetailsStoreKey]: merchantBillingDetailsReducer,
  });

  return rootReducer;
}
