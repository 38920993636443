import React, { useState, useEffect } from 'react'
import { validate } from './validation'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { connect } from 'react-redux'
import _ from 'lodash'
import { STATUS_RESPONSE, REGEX, DATE, currencyOptions, SESSION, API } from '../../../../utils/constants'
import { transactionDetailsActions, editTransactionActions, chargeBackDetailsActions } from '../../../../store/actions'
import { DateSelector } from '../../../../theme/layout/components/DateSelector'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { confirmAlert, warningAlert } from '../../../../utils/alerts'
import ReactSelect from '../../../../theme/layout/components/ReactSelect'
import color from '../../../../utils/colors'
import { getLocalStorage, headersWithAuth } from '../../../../utils/helper'

function Step1(props) {
  const {
    loadingTGD,
    statusTGD,
    onClickNext,
    getTransactionDetailsDispatch,
    clearTransactionDetailsDispatch,
    TransactionDetails,
    editTransactionDispatch,
    cleareditTransactionDispatch,
    statusETCB,
    messageETCB,
    loadingETCB,
    getCBDetailsDispatch,
    ChargebackDetails
  } = props

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }

  const url = useLocation().pathname
  const fields = url && url.split('/')
  const id = fields && fields[3]
  const [errors, setErrors] = useState({})
  const [editForm, setEditForm] = useState(false)
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState()

  const [formData, setFormData] = useState({
    totalAmount: '',
    totalAmountCurrency: '',
    avsChecked: '',
    avsResultCode: '',
    cvvChecked: '',
    cvvResultCode: '',
    threeDSEciCode: '',
    threeDSChallenged: '',
    threeDSTransactionId: '',
    threeDSAuthenticationValue: '',
    billingAddress: '',
    billingAddressDetails: '',
    shippingAndHandlingAmount: '',
    shippingAndHandlingCurrency: '',
    shippingAddress: '',
    shippingAddressDetails: '',
    refundProcessed: '',
    refundAmount: '',
    refundCurrency: '',
    dateOfRefund: null,
    refundPolicy: ''
  })

  const handleChange = (e) => {
    e.persist()
    setFormData(values => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : ''
    })
  }

  const handleChangeCurrency = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCurrencyOption(selectedOption)
      setFormData((values) => ({ ...values, totalAmountCurrency: selectedOption.value }))
    } else {
      setSelectedCurrencyOption()
      setFormData((values) => ({ ...values, totalAmountCurrency: '' }))
    }
    setErrors({ ...errors, totalAmountCurrency: '' })
  }

  const handleChangeShipCurrency = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCurrencyOption(selectedOption)
      setFormData((values) => ({ ...values, shippingAndHandlingCurrency: selectedOption.value }))
    } else {
      setSelectedCurrencyOption()
      setFormData((values) => ({ ...values, shippingAndHandlingCurrency: '' }))
    }
    setErrors({ ...errors, shippingAndHandlingCurrency: '' })
  }

  const handleChangeRefundCurrency = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCurrencyOption(selectedOption)
      setFormData((values) => ({ ...values, refundCurrency: selectedOption.value }))
    } else {
      setSelectedCurrencyOption()
      setFormData((values) => ({ ...values, refundCurrency: '' }))
    }
    setErrors({ ...errors, refundCurrency: '' })
  }

  const handleSubmit = () => {
    const errorMsg = validate(formData, setErrors)
    if (_.isEmpty(errorMsg)) {
      const payload = {
        ...formData,
        dateOfRefund: moment(formData.dateOfRefund).format(DATE.DATE_FORMAT)
      }
      editTransactionDispatch(id, payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }

  const onConfirm = () => {
  }

  useEffect(() => {
    if (statusETCB === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'success',
        messageETCB,
        'success',
        'Ok',
        () => { onConfirm() },
        () => { onClickNext(1) }
      )
      cleareditTransactionDispatch()
    } else if (statusETCB === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'error',
        messageETCB,
        '',
        'Ok'
      )
      cleareditTransactionDispatch()
    }
  }, [statusETCB])

  useEffect(() => {
    if (id) {
      getCBDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      getTransactionDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }, [id])

  useEffect(() => {
    if (statusTGD === STATUS_RESPONSE.SUCCESS_MSG) {
      if (TransactionDetails && TransactionDetails._id) {
        setFormData(values => ({
          ...values,
          ...TransactionDetails,
          dateOfRefund: TransactionDetails && TransactionDetails.dateOfRefund !== 'Invalid date' && TransactionDetails.dateOfRefund ? new Date(TransactionDetails.dateOfRefund) : null,
        }))
      }
      if (TransactionDetails.totalAmountCurrency) {
        const selOption = _.filter(currencyOptions, function (x) {
          if (_.includes(TransactionDetails.totalAmountCurrency, x.value)) {
            return x
          }
        })
        setSelectedCurrencyOption(selOption)
      }
      if (TransactionDetails.shippingAndHandlingCurrency) {
        const selOption = _.filter(currencyOptions, function (x) {
          if (_.includes(TransactionDetails.shippingAndHandlingCurrency, x.value)) {
            return x
          }
        })
        setSelectedCurrencyOption(selOption)
      }
      if (TransactionDetails.refundCurrency) {
        const selOption = _.filter(currencyOptions, function (x) {
          if (_.includes(TransactionDetails.refundCurrency, x.value)) {
            return x
          }
        })
        setSelectedCurrencyOption(selOption)
      }
      clearTransactionDetailsDispatch()
    } else if (statusTGD === STATUS_RESPONSE.ERROR_MSG) {
      clearTransactionDetailsDispatch()
    }
  }, [statusTGD])

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  const handleEdit = () => {
    setEditForm(val => !val)
  }

  return (
    <div className='container-fixed'>
      <div className='card-header bg-skyBlue py-10'>
        <div className='card-body m-8'>
          {
            loadingTGD
              ? (
                <div className='d-flex justify-content-center py-5'>
                  <div className='spinner-border text-primary m-5' role='status' />
                </div>
              )
              : (
                <>
                  <div className='col-lg-12 mt-4'>
                    {!['WON', 'LOST', 'NOT FAUGHT', 'LOST REFUNDED'].includes(ChargebackDetails.chargeBackStatus) && !loadingTGD &&
                      <button
                        className='btn btn-sm fa-pull-right m-1'
                        style={{ backgroundColor: '#6c54a5', color: '#fff' }}
                        onClick={() => { handleEdit() }}
                      >
                        <span className='svg-icon svg-icon-white svg-icon-3'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                            <path opacity='0.3' fillRule='evenodd' clipRule='evenodd' d='M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z' fill='black' />
                            <path d='M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z' fill='black' />
                            <path d='M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z' fill='black' />
                          </svg>
                        </span>
                        Edit
                      </button>
                    }
                  </div>
                  <div className='me-2 d-flex'>
                    <p className='text-dark fw-bolder fs-3'>
                      TRANSACTION INFORMATION
                    </p>
                  </div>
                  <div className='form-group row mb-4 col-lg-12'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Transaction Amount:</label>
                      <div className='col-lg-11'>
                        <input
                          name='totalAmount'
                          type='text'
                          className='form-control'
                          placeholder='Transaction Amount'
                          onChange={(e) => handleChange(e)}
                          value={formData.totalAmount || ''}
                          maxLength={42}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!/^[0-9.]*$/.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.totalAmount && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.totalAmount}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Transaction Currency:</label>
                      <div className='col-lg-11'>
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name='totalAmountCurrency'
                          className='basic-single'
                          classNamePrefix='select'
                          handleChangeReactSelect={handleChangeCurrency}
                          options={currencyOptions}
                          value={selectedCurrencyOption}
                          isDisabled={!editForm}
                        />
                        {errors && errors.totalAmountCurrency && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.totalAmountCurrency}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='me-2'>
                    <p className='text-dark fw-bolder fs-3'>
                      TRANSACTION CHECKS
                    </p>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>AVS Checked:</label>
                      <div className='col-lg-11'>
                        <input
                          name='avsChecked'
                          type='text'
                          className='form-control'
                          placeholder='AVS Checked'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.avsChecked || ''}
                          maxLength={42}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.avsChecked && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.avsChecked}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>AVS Code:</label>
                      <div className='col-lg-11'>
                        <input
                          name='avsResultCode'
                          type='text'
                          className='form-control'
                          placeholder='AVS Code'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.avsResultCode || ''}
                          maxLength={42}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.avsResultCode && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.avsResultCode}</div>)}
                      </div>
                    </div>
                  </div>
                  {ChargebackDetails.cardType === "creditCard" ? (
                    <>
                      <div className="form-group row mb-4">
                        <div className="col-lg-4 mb-3">
                          <label className="font-size-xs  font-weight-bold mb-2 form-label">
                            CVV Checked:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="cvvChecked"
                              type="text"
                              className="form-control"
                              placeholder="CVV Checked"
                              onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.cvvChecked || ""}
                              maxLength={4}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors && errors.cvvChecked && (
                              <div className="rr mt-1">
                                <style>{".rr{color:red;}"}</style>
                                {errors.cvvChecked}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="font-size-xs  font-weight-bold mb-2 form-label">
                            CVV Code:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="cvvResultCode"
                              type="text"
                              className="form-control"
                              placeholder="CVV Code"
                              onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.cvvResultCode || ""}
                              maxLength={4}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors && errors.cvvResultCode && (
                              <div className="rr mt-1">
                                <style>{".rr{color:red;}"}</style>
                                {errors.cvvResultCode}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="font-size-xs font-weight-bold mb-2 form-label">
                            3D Secure Code:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="threeDSEciCode"
                              type="text"
                              className="form-control"
                              placeholder="3D Secure Code"
                              onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.threeDSEciCode || ""}
                              maxLength={42}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <div className="col-lg-4 mb-3">
                          <label className="font-size-xs  font-weight-bold mb-2 form-label">
                            3D Secure Challenge:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="threeDSChallenged"
                              type="text"
                              className="form-control"
                              placeholder="3D Secure Challenge"
                              onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.threeDSChallenged || ""}
                              maxLength={4}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors && errors.threeDSChallenged && (
                              <div className="rr mt-1">
                                <style>{".rr{color:red;}"}</style>
                                {errors.threeDSChallenged}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="font-size-xs  font-weight-bold mb-2 form-label">
                            3D Transaction Id:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="threeDSTransactionId"
                              type="text"
                              className="form-control"
                              placeholder="3D Transaction Id"
                              onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.threeDSTransactionId || ""}
                              maxLength={4}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors && errors.threeDSTransactionId && (
                              <div className="rr mt-1">
                                <style>{".rr{color:red;}"}</style>
                                {errors.threeDSTransactionId}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="font-size-xs  font-weight-bold mb-2 form-label">
                            3D Auth Value:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="threeDSAuthenticationValue"
                              type="text"
                              className="form-control"
                              placeholder="3D Auth Value"
                              onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.threeDSAuthenticationValue || ""}
                              maxLength={42}
                              disabled={!editForm}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors && errors.threeDSAuthenticationValue && (
                              <div className="rr mt-1">
                                <style>{".rr{color:red;}"}</style>
                                {errors.threeDSAuthenticationValue}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className='me-2'>
                    <p className='text-dark fw-bolder fs-3'>
                      BILLING INFORMATION
                    </p>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Billing Address:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='billingAddress'
                          type='text'
                          className='form-control'
                          placeholder='Refunded'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.billingAddress || ''}
                        />
                        {errors && errors.billingAddress && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.billingAddress}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Billing Address Details:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='billingAddressDetails'
                          type='text'
                          className='form-control'
                          placeholder='Billing Address Details'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.billingAddressDetails || ''}
                        />
                        {errors && errors.billingAddressDetails && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.billingAddressDetails}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='me-2'>
                    <p className='text-dark fw-bolder fs-3'>
                      SHIPPING INFORMATION
                    </p>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Shipping Amount:</label>
                      <div className='col-lg-11'>
                        <input
                          name='shippingAndHandlingAmount'
                          type='text'
                          className='form-control'
                          placeholder='Shipping Amount'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.shippingAndHandlingAmount || ''}
                          maxLength={42}
                          onKeyPress={(e) => {
                            if (!/^[0-9.]*$/.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.shippingAndHandlingAmount && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.shippingAndHandlingAmount}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Shipping Currency:</label>
                      <div className='col-lg-11'>
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name='shippingAndHandlingCurrency'
                          className='basic-single'
                          classNamePrefix='select'
                          handleChangeReactSelect={handleChangeShipCurrency}
                          options={currencyOptions}
                          value={selectedCurrencyOption}
                          isDisabled={!editForm}
                        />
                        {errors && errors.shippingAndHandlingCurrency && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.shippingAndHandlingCurrency}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Shipping Address:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='shippingAddress'
                          type='text'
                          className='form-control'
                          placeholder='Shipping Address'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.shippingAddress || ''}
                          disabled={!editForm}
                        />
                        {errors && errors.shippingAddress && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.shippingAddress}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Shipping Address Details:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='shippingAddressDetails'
                          type='text'
                          className='form-control'
                          placeholder='Shipping Address Details'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.shippingAddressDetails || ''}
                          disabled={!editForm}
                        />
                        {errors && errors.shippingAddressDetails && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.shippingAddressDetails}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='me-2'>
                    <p className='text-dark fw-bolder fs-3'>
                      REFUND INFORMATION
                    </p>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Refund Processed:</label>
                      <div className='col-lg-11'>
                        <input
                          name='refundProcessed'
                          type='text'
                          className='form-control'
                          placeholder='Refund Processed'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.refundProcessed || ''}
                          maxLength={42}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.refundProcessed && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.refundProcessed}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Refund Amount:</label>
                      <div className='col-lg-11'>
                        <input
                          name='refundAmount'
                          type='text'
                          className='form-control'
                          placeholder='Refund Amount'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.refundAmount || ''}
                          disabled={!editForm}
                          maxLength={42}
                          onKeyPress={(e) => {
                            if (!/^[0-9.]*$/.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.refundAmount && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.refundAmount}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Refund Currency:</label>
                      <div className='col-lg-11'>
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name='refundCurrency'
                          className='basic-single'
                          classNamePrefix='select'
                          handleChangeReactSelect={handleChangeRefundCurrency}
                          options={currencyOptions}
                          value={selectedCurrencyOption}
                          isDisabled={!editForm}
                        />
                        {errors && errors.refundCurrency && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.refundCurrency}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Date Of Refund:</label>
                      <div className='col-lg-11'>
                        <DateSelector
                          name='dateOfRefund'
                          placeholder='Date Of Refund'
                          className='form-control'
                          selected={formData.dateOfRefund || null}
                          onChange={(date) => {
                            setErrors({ ...errors, dateOfRefund: '' })
                            setFormData((values) => ({
                              ...values,
                              dateOfRefund: date
                            }))
                          }}
                          dateFormat={DATE.DATE_FOR_PICKER}
                          readOnly={!editForm}
                        />
                      </div>
                      {errors && errors.dateOfRefund && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.dateOfRefund}</div>)}
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Refund Policy:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='refundPolicy'
                          type='text'
                          className='form-control'
                          placeholder='Refund Policy'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.refundPolicy || ''}
                          disabled={!editForm}
                        />
                        {errors && errors.refundPolicy && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.refundPolicy}</div>)}
                      </div>
                    </div>
                  </div>
                </>
              )
          }
        </div>
        <div className='form-group row mb-4'>
          <div className='col-lg-6' />
          <div className='col-lg-6'>
            <div className='col-lg-11'>
              {editForm ? (
                <button
                  className='btn btn-primary m-2 fa-pull-right'
                  id='submitBtn'
                  onClick={(event) => {
                    handleSubmit(event)
                  }}
                >
                  {loadingETCB
                    ? (
                      <span
                        className='spinner-border spinner-border-sm mx-3'
                        role='status'
                        aria-hidden='true'
                      />
                    )
                    : (
                      <>
                        <span>Save and Continue</span>  &nbsp;
                        <span className="bi bi-caret-right-fill mt-2" />
                      </>
                    )}
                </button>
              )
                : (
                  <button
                    className='btn btn-primary m-2 fa-pull-right'
                    onClick={() => {
                      onClickNext(1)
                    }}
                  >
                    <span>Continue</span>  &nbsp;
                    <span className="bi bi-caret-right-fill mt-2" />
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state) => {
  const { transactionStore, editTransactionStore, chargebackStore } = state
  return {
    TransactionDetails: transactionStore && transactionStore.TransactionDetails ? transactionStore.TransactionDetails : [],
    statusTGD: transactionStore && transactionStore.statusTGD ? transactionStore.statusTGD : '',
    messagesTGD: transactionStore && transactionStore.messagesTGD ? transactionStore.messagesTGD : '',
    loadingTGD: transactionStore && transactionStore.loadingTGD ? transactionStore.loadingTGD : false,
    editTransactions: editTransactionStore && editTransactionStore.dataETCB ? editTransactionStore.dataETCB : [],
    statusETCB: editTransactionStore && editTransactionStore.statusETCB ? editTransactionStore.statusETCB : '',
    messageETCB: editTransactionStore && editTransactionStore.messageETCB ? editTransactionStore.messageETCB : '',
    loadingETCB: editTransactionStore && editTransactionStore.loadingETCB ? editTransactionStore.loadingETCB : false,
    ChargebackDetails: chargebackStore && chargebackStore.ChargebackDetails ? chargebackStore.ChargebackDetails : {},
  }
}

const mapDispatchToProps = dispatch => ({
  getTransactionDetailsDispatch: (id, headers) =>
    dispatch(transactionDetailsActions.getTransactionDetails(id, headers)),
  clearTransactionDetailsDispatch: () =>
    dispatch(transactionDetailsActions.clearTransactionDetails()),
  editTransactionDispatch: (id, params, headers) =>
    dispatch(editTransactionActions.editTransactions(id, params, headers)),
  cleareditTransactionDispatch: () =>
    dispatch(editTransactionActions.cleareditTransactions()),
  getCBDetailsDispatch: (id, headers) =>
    dispatch(chargeBackDetailsActions.getChargebackDetails(id, headers)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1)
