import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  ShopifyConnectActionTypes,
  ShopifyConnectActions,
 
} from '../actions'
import serviceList from '../../services/serviceList'
function * fetchShopifyConnect (actions) {
  const {  headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.shopifyConnect, {
      headers: headers
    })
    if (json.status === 200) {
      yield put(ShopifyConnectActions.ShopifyConnectSuccess(json.data))
    } else {
      yield put(ShopifyConnectActions.ShopifyConnectSuccess([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: ShopifyConnectActionTypes.RESPONSE, data })
  }
}


export function * fetchShopifyConnectWatcher () {
  yield all([
    yield takeLatest(ShopifyConnectActionTypes.SHOPIFY_CONNECT_INIT, fetchShopifyConnect),
  ])
}
