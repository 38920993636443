import { put, takeLatest, all } from 'redux-saga/effects'
import axiosInstance from '../../services'
import { API_MESSAGES } from '../../utils/constants'
import { bankActions, bankActionsTypes } from '../actions'
import serviceList from '../../services/serviceList'

function* fetchBank(actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.getBank, { params, headers: headers })
    if (json.status === 200) {
      yield put(bankActions.savebankResponse(json.data))
    } else {
      yield put(bankActions.savebankResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: bankActionsTypes.SAVE_BANK_RESPONSE, data })
  }
}

export function* fetchBankWatcher() {
  yield all([
    yield takeLatest(bankActionsTypes.GET_BANK, fetchBank)
  ])
}
