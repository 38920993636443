export const chargebackActionsTypes = {
  GET_CHARGEBACK: 'GET_CHARGEBACK',
  SAVE_CHARGEBACK_RESPONSE: 'SAVE_CHARGEBACK_RESPONSE',
  CLEAR_CHARGEBACK: 'CLEAR_CHARGEBACK'
}

export const chargebackActions = {
  getChargeback: (params, headers) => ({
    type: chargebackActionsTypes.GET_CHARGEBACK,
    params,
    headers
  }),
  saveChargebackResponse: data => ({
    type: chargebackActionsTypes.SAVE_CHARGEBACK_RESPONSE,
    data
  }),
  clearChargeback: () => ({
    type: chargebackActionsTypes.CLEAR_CHARGEBACK
  })
}

export const addChargebackTypes = {
  REQUEST: 'ADD_CHARGEBACK_REQUEST',
  RESPONSE: 'ADD_CHARGEBACK_RESPONSE',
  ERROR: 'ADD_CHARGEBACK_ERROR',
  CLEAR: 'ADD_CHARGEBACK_CLEAR'
}

export const addChargebackActions = {
  addChargeback: (data, headers) => {
    return {
      type: addChargebackTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddChargebackResponse: (data) => {
    return {
      type: addChargebackTypes.RESPONSE,
      data
    }
  },
  clearaddChargeback: () => ({
    type: addChargebackTypes.CLEAR
  })
}

export const chargeBackGetDetailsTypes = {
  GET_CHARGEBACK_DETAILS: 'GET_CHARGEBACK_DETAILS',
  CHARGEBACK_DETAILS_RESPONSE: 'CHARGEBACK_DETAILS_RESPONSE',
  CLEAR_CHARGEBACK_DETAILS: 'CLEAR_CHARGEBACK_DETAILS'
}
export const chargeBackDetailsActions = {
  getChargebackDetails: (id, headers) => ({
    type: chargeBackGetDetailsTypes.GET_CHARGEBACK_DETAILS,
    id,
    headers
  }),
  saveChargebackDetailsResponse: data => ({
    type: chargeBackGetDetailsTypes.CHARGEBACK_DETAILS_RESPONSE,
    data
  }),
  clearChargebackDetails: () => ({
    type: chargeBackGetDetailsTypes.CLEAR_CHARGEBACK_DETAILS
  })
}

export const editChargebackTypes = {
  REQUEST: 'EDIT_CHARGEBACK_REQUEST',
  RESPONSE: 'EDIT_CHARGEBACK_RESPONSE',
  ERROR: 'EDIT_CHARGEBACK_ERROR',
  CLEAR: 'EDIT_CHARGEBACK_CLEAR'
}

export const editChargebacksActions = {
  editChargebacks: (id, params, headers) => {
    return {
      type: editChargebackTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditChargebacksResponse: (data) => {
    return {
      type: editChargebackTypes.RESPONSE,
      data
    }
  },
  cleareditChargebacks: () => ({
    type: editChargebackTypes.CLEAR
  })
}

export const chargebackDeleteTypes = {
  DELETE_CHARGEBACK: 'DELETE_CHARGEBACK',
  SAVE_DELETE_CHARGEBACK_RESPONSE: 'SAVE_DELETE_CHARGEBACK_RESPONSE',
  CLEAR_DELETE_CHARGEBACK: 'CLEAR_DELETE_CHARGEBACK'
}

export const deleteChargebackActions = {
  deleteChargeback: (params, headers) => ({
    type: chargebackDeleteTypes.DELETE_CHARGEBACK,
    params,
    headers
  }),
  savemResponseDeleteChargeback: data => ({
    type: chargebackDeleteTypes.SAVE_DELETE_CHARGEBACK_RESPONSE,
    data
  }),
  clearDeleteChargeback: () => ({
    type: chargebackDeleteTypes.CLEAR_DELETE_CHARGEBACK
  })
}

export const approveCBTypes = {
  REQUEST: 'ADD_APPROVE_CHARGEBACK_REQUEST',
  RESPONSE: 'ADD_APPROVE_CHARGEBACK_RESPONSE',
  ERROR: 'ADD_APPROVE_CHARGEBACK_ERROR',
  CLEAR: 'ADD_APPROVE_CHARGEBACK_CLEAR'
}

export const approveCBActions = {
  add: (data, headers) => {
    return {
      type: approveCBTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: approveCBTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: approveCBTypes.CLEAR
  })
}

export const approveALLCBMTypes = {
  REQUEST: 'ADD_APPROVE_ALL_CBM_REQUEST',
  RESPONSE: 'ADD_APPROVE_ALL_CBM_RESPONSE',
  ERROR: 'ADD_APPROVE_ALL_CBM_ERROR',
  CLEAR: 'ADD_APPROVE_ALL_CBM_CLEAR'
}

export const approveALLCBMActions = {
  add: (data, headers) => {
    return {
      type: approveALLCBMTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: approveALLCBMTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: approveALLCBMTypes.CLEAR
  })
}

export const addNotificationToMerchantTypes = {
  REQUEST: 'ADD_NOTIFICATION_TO_MERCHANT_REQUEST',
  RESPONSE: 'ADD_NOTIFICATION_TO_MERCHANT_RESPONSE',
  ERROR: 'ADD_NOTIFICATION_TO_MERCHANT_ERROR',
  CLEAR: 'ADD_NOTIFICATION_TO_MERCHANT_CLEAR'
}

export const addNotificationToMerchant = {
  add: (data, url, headers) => {
    return {
      type: addNotificationToMerchantTypes.REQUEST,
      payload: data,
      url,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addNotificationToMerchantTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addNotificationToMerchantTypes.CLEAR
  })
}

export const singleNotifyToMerchantTypes = {
  REQUEST: 'SINGLE_NOTIFICATION_TO_MERCHANT_REQUEST',
  RESPONSE: 'SINGLE_NOTIFICATION_TO_MERCHANT_RESPONSE',
  ERROR: 'SINGLE_NOTIFICATION_TO_MERCHANT_ERROR',
  CLEAR: 'SINGLE_NOTIFICATION_TO_MERCHANT_CLEAR'
}

export const singleNotifyToMerchant = {
  add: (data, headers) => {
    return {
      type: singleNotifyToMerchantTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: singleNotifyToMerchantTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: singleNotifyToMerchantTypes.CLEAR
  })
}

export const singleApproveMerchantTypes = {
  REQUEST: 'SINGLE_APPROVE_MERCHANT_REQUEST',
  RESPONSE: 'SINGLE_APPROVE_MERCHANT_RESPONSE',
  ERROR: 'SINGLE_APPROVE_MERCHANT_ERROR',
  CLEAR: 'SINGLE_APPROVE_MERCHANT_CLEAR',
}

export const singleApproveMerchant = {
  add: (data, headers) => {
    return {
      type: singleApproveMerchantTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: singleApproveMerchantTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: singleApproveMerchantTypes.CLEAR
  })
}

export const approveCBMActionsTypes = {
  REQUEST: 'GET_CB_MERCHANT_APPROVE_REQUEST',
  RESPONSE: 'SAVE_CB_MERCHANT_APPROVE_RESPONSE',
  CLEAR: 'CLEAR_CB_MERCHANT_APPROVE'
}

export const approveCBMActions = {
  get: (id, headers) => ({
    type: approveCBMActionsTypes.REQUEST,
    id,
    headers
  }),
  saveResponse: data => ({
    type: approveCBMActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: approveCBMActionsTypes.CLEAR
  })
}

export const updateCBApproveTypes = {
  REQUEST: 'ADD_UPDATE_CB_APPROVE_REQUEST',
  RESPONSE: 'ADD_UPDATE_CB_APPROVE_RESPONSE',
  ERROR: 'ADD_UPDATE_CB_APPROVE_ERROR',
  CLEAR: 'ADD_UPDATE_CB_APPROVE_CLEAR'
}

export const updateCBApprove = {
  add: (data, headers) => {
    return {
      type: updateCBApproveTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updateCBApproveTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updateCBApproveTypes.CLEAR
  })
}

export const downloadInvoiceTypes = {
  REQUEST: 'GET_INVOICE_REQUEST',
  RESPONSE: 'SAVE_INVOICE_RESPONSE',
  CLEAR: 'CLEAR_INVOICE_ERROR',
}

export const downloadInvoiceActions = {
  get: (params, headers) => {
    return {
      type: downloadInvoiceTypes.REQUEST,
      params,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: downloadInvoiceTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: downloadInvoiceTypes.CLEAR
  })
}