import {
  countryActionsTypes,
  stateActionsTypes,
  cityActionsTypes
} from '../actions'

export const countryInitialState = {
  list: null
}

export const countryStoreKey = 'countryStore'

export const countryReducer = (state = countryInitialState, action) => {
  switch (action.type) {
    case countryActionsTypes.GET_COUNTRY:
      return { ...state, loading: true }
    case countryActionsTypes.SAVE_COUNTRY_RESPONSE:
      return { ...state, getCountrys: action.data && action.data.data, loading: false }
    case countryActionsTypes.CLEAR_COUNTRY:
      return { ...state, getCountrys: null }
    default:
      return state
  }
}

export const stateInitialState = {
  list: null
}

export const stateStoreKey = 'stateStore'

export const stateReducer = (state = stateInitialState, action) => {
  switch (action.type) {
    case stateActionsTypes.GET_STATE:
      return { ...state, loading: true }
    case stateActionsTypes.SAVE_STATE_RESPONSE:
      return { ...state, getStates: action.data && action.data.data, loading: false }
    case stateActionsTypes.CLEAR_STATE:
      return { ...state, getStates: null }
    default:
      return state
  }
}

export const cityInitialState = {
  list: null
}

export const cityStoreKey = 'cityStore'

export const cityReducer = (city = cityInitialState, action) => {
  switch (action.type) {
    case cityActionsTypes.GET_CITY:
      return { ...city, loading: true }
    case cityActionsTypes.SAVE_CITY_RESPONSE:
      return { ...city, getCitys: action.data && action.data.data, loading: false }
    case cityActionsTypes.CLEAR_CITY:
      return { ...city, getCitys: null }
    default:
      return city
  }
}
