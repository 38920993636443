/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
// import { MasterLayout } from '../theme/layout/MasterLayout'
import MasterLayout from '../theme/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'
import { MasterInit } from '../theme/layout/MasterInit'
import { UnderConstruction } from '../containers/error'
import { PageTitle } from '../theme/layout/core'
import { SESSION, SET_STORAGE } from '../utils/constants'
import { getLocalStorage, handleShopifySession, setLocalStorage } from '../utils/helper'
import CreateCase from '../containers/createCase/index'
import CreateCaseAlerts from '../containers/createCase/CreateCaseAlerts'
import ImportCase from '../containers/createCase/import'
import ImportAlertCase from '../containers/createCase/importAlert'
import ClientSignupPage from '../containers/OnboardClient/index'
import AuthFail from '../components/auth/AuthFail'
import ChargebackEvidence from '../components/ChargebackEvidence/ChargebackEvidence'
import SessionTimeout from '../components/SessionTimeout/SessionTimeout'
import ChangePassword from '../components/Profile/ChangePassword'
import Logout from '../components/auth/Logout'
import MerchantEvidence from '../components/MerchantEvidenece/MerchantEvi'
import ChargebackList from '../components/chargebackManagement/chargebackManagement'
import CBData from '../components/chargebackManagement/chargebackDetails/cbData'
import Clients from '../components/clientManagement/clients'
import ClientOnboarding from '../components/clientManagement/onboarding/clientOnboarding'
import ChargebackSummaryAWS from '../components/chargebackSummary/chargebackSummaryAWS'
import RootCauseAnalyzerAWS from '../components/rootCauseAnalyzerAWS/rootCauseAnalyzerAWS'
import PreventionAlertAWS from '../components/preventionAlertAWS/preventionAlertAWS'
import PerformanceAnalyzerAWS from '../components/performanceAnalyzerAWS/performanceAnalyzerAWS'
import FraudAnalyzerAWS from '../components/fraudAnalyzerAWS/fraudAnalyzerAWS'
import PreventionInquiriesIntelligenceAWS from '../components/preventionInquiriesIntelligenceAWS/preventionInquiriesIntelligenceAWS'

const Routes = () => {
  const isAuthorized = getLocalStorage(SESSION.TOKEN)
  const pathName = useLocation().pathname
  const search = useLocation().search
  const query = new URLSearchParams(search);
  const paramDomain = query.get('shop');
  const path = `${useLocation().pathname}${useLocation().search}`
  paramDomain && handleShopifySession(path)
  pathName && setLocalStorage(SET_STORAGE.SET_CURRENT_ROUTE, pathName);

  const urlEndPoints = [
    '/issuerbank-inquiries-sdk',
    '/import-inquiries',
    '/client-signup-page',
    '/client-signup',
    '/get-started',
    '/select-processor',
    '/select-crm',
    '/select-service',
    '/select-package',
    '/package-subscription',
    '/contact-details',
    '/issuerbank-alerts-sdk',
    '/issuerbank-alert',
    '/import-alert',
    '/auth-failed',
    '/authorization-failed',
    '/session-exist',
    '/chargeback/evidence',
    '/chargeback/merchant',
    '/session-timeout',
    '/change-password',
    '/session-expired',
    '/manage-chargeback',
    '/chargeback-management/update-chargeback/:id',
    '/manage-client',
    '/client-onboarding/update/:id',
    '/chargeback-summary-dashboard',
    '/rootcause-analyzer-dashboard',
    '/prevention-alerts-dashboard',
    '/performance-analyzer-dashboard',
    '/fraud-analyzer-dashboard',
    '/prevention-inquiries-dashboard'
  ]

  return (
    <>
      <Switch>
        {isAuthorized
          ? (
            <>
              {
                // urlEndPoints.includes(pathName) && paramField
                urlEndPoints.includes(pathName) ||
                  pathName.includes('/chargeback/evidence') ||
                  pathName.includes('/get-started') ||
                  pathName.includes('/chargeback/merchant') ||
                  pathName.includes('/manage-chargeback') ||
                  pathName.includes('/manage-client') ||
                  pathName.includes('/chargeback-summary-dashboard') ||
                  pathName.includes('/rootcause-analyzer-dashboard') ||
                  pathName.includes('/prevention-alerts-dashboard') ||
                  pathName.includes('/performance-analyzer-dashboard') ||
                  pathName.includes('/fraud-analyzer-dashboard') ||
                  pathName.includes('/prevention-inquiries-dashboard') ||
                  pathName.includes('/issuerbank-inquiries-sdk') ||
                  pathName.includes('/issuerbank-alerts-sdk')
                  ? (
                    <>
                      <Route path='/issuerbank-inquiries-sdk' component={CreateCase} />
                      <Route path='/issuerbank-alerts-sdk' component={CreateCaseAlerts} />
                      <Route path='/import-inquiries' component={ImportCase} />
                      <Route path='/import-alert' component={ImportAlertCase} />
                      <Route path='/session-exist' component={AuthFail} />
                      <Route path='/auth-failed' component={AuthFail} />
                      <Route path='/authorization-failed' component={AuthFail} />
                      <Route path='/session-timeout' component={SessionTimeout} />
                      <Route path='/session-expired' component={Logout} />
                      <Route path='/change-password' component={ChangePassword} />
                      <Route path='/chargeback/evidence/:id' component={ChargebackEvidence} />
                      <Route path='/chargeback/merchant/:id' component={MerchantEvidence} />
                      <Route path='/client-signup-page' component={ClientSignupPage} />
                      <Route path='/client-signup' component={ClientSignupPage} />
                      <Route path='/get-started/:id' component={ClientSignupPage} />
                      <Route path='/select-processor' component={ClientSignupPage} />
                      <Route path='/select-crm' component={ClientSignupPage} />
                      <Route path='/select-service' component={ClientSignupPage} />
                      <Route path='/select-package' component={ClientSignupPage} />
                      <Route path='/package-subscription' component={ClientSignupPage} />
                      <Route path='/contact-details' component={ClientSignupPage} />
                      <Route path='/manage-chargeback' component={ChargebackList} />
                      <Route path='/chargeback-management/update-chargeback/:id' component={CBData} />
                      <Route path='/manage-client' component={Clients} />
                      <Route path='/client-onboarding/update/:id' component={ClientOnboarding} />
                      <Route path='/chargeback-summary-dashboard' component={ChargebackSummaryAWS} />
                      <Route path='/rootcause-analyzer-dashboard' component={RootCauseAnalyzerAWS} />
                      <Route path='/prevention-alerts-dashboard' component={PreventionAlertAWS} />
                      <Route path='/performance-analyzer-dashboard' component={PerformanceAnalyzerAWS} />
                      <Route path='/fraud-analyzer-dashboard' component={FraudAnalyzerAWS} />
                      <Route path='/prevention-inquiries-dashboard' component={PreventionInquiriesIntelligenceAWS} />
                    </>
                  )
                  : (
                    <MasterLayout>
                      <Route path='/upcoming'>
                        <PageTitle breadcrumbs={[]}>Under Construction</PageTitle>
                        <UnderConstruction />
                      </Route>
                      <PrivateRoutes />
                    </MasterLayout>
                  )
              }
            </>
          )
          : (
            <>
              <PublicRoutes />
            </>
          )}
      </Switch>
      <MasterInit />
    </>
  )
}

export { Routes }
