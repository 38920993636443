import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  crmActionsTypes,
  crmActions,
  packageActionsTypes,
  packageActions,
  packageGetDetailsTypes,
  packageGetDetailsActions,
  editPackageTypes,
  editPackageActions,
  userActionsTypes,
  userActions,
  userGetDetailsTypes,
  userGetDetailsActions,
  addUserTypes,
  addUserActions,
  editUserTypes,
  editUserActions,
  processorActionsTypes,
  processorActions,
  paymentActionsTypes,
  paymentActions,
  packPaymentActionTypes,
  packPaymentActions,
  clientPackagesActionsTypes,
  partnerClientActionsTypes,
  partnerClientActions,
  addPartnersClientTypes,
  addPartnersClientAction,
  deleteUsersActionsTypes,
  deleteUsersActions,
  listActionsTypes,
  listActions,
  clientcrmActions,
  clientcrmActionsTypes
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchCRM (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.CRM, { params, headers:headers })
    if (json.status === 200) {
      yield put(crmActions.saveCRMResponse(json.data))
    } else {
      yield put(crmActions.saveCRMResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: crmActionsTypes.SAVE_CRM_RESPONSE, data })
  }
}

function * fetchPackage (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.package, { params, headers:headers })
    if (json.status === 200) {
      yield put(packageActions.savePackageResponse(json.data))
    } else {
      yield put(packageActions.savePackageResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: packageActionsTypes.SAVE_PACKAGE_RESPONSE, data })
  }
}

function * fetchList (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.packageList, { params, headers:headers })
    if (json.status === 200) {
      yield put(listActions.saveResponse(json.data))
    } else {
      yield put(listActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: listActionsTypes.RESPONSE, data })
  }
}

function * fetchPackPayment (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.packPayment, { params, headers:headers })
    if (json.status === 200) {
      yield put(packPaymentActions.savePackPaymentResponse(json.data))
    } else {
      yield put(packPaymentActions.savePackPaymentResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: packPaymentActionTypes.SAVE_PACK_PAYMENT_RESPONSE, data })
  }
}

function * packageGetDetailsDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.getPackage}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(packageGetDetailsActions.savePackageDetailsResponse(json.data))
    } else {
      yield put(packageGetDetailsActions.savePackageDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: packageGetDetailsTypes.PACKAGE_DETAILS_RESPONSE, data })
  }
}

function * editPackage (actions) {
  const params = actions.payload && actions.payload.params
  const id = actions.payload && actions.payload.id
  const { headers } = actions
  const endPointUrl = `${serviceList.editPackage}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(editPackageActions.saveeditPackageResponse(json.data))
    } else {
      yield put(editPackageActions.saveeditPackageResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editPackageTypes.RESPONSE, data })
  }
}

function * fetchUser (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.user, { params, headers:headers })
    if (json.status === 200) {
      yield put(userActions.saveUserResponse(json.data))
    } else {
      yield put(userActions.saveUserResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: userActionsTypes.SAVE_USER_RESPONSE, data })
  }
}

function * userGetDetailsDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.getUser}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(userGetDetailsActions.saveUserDetailsResponse(json.data))
    } else {
      yield put(userGetDetailsActions.saveUserDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: userGetDetailsTypes.USER_DETAILS_RESPONSE, data })
  }
}

function * addUserInfo (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.getUser, payload,{headers:headers})
    if (json.status === 200) {
      yield put(addUserActions.saveaddUserResponse(json.data))
    } else {
      yield put(addUserActions.clearaddUser([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addUserTypes.RESPONSE, data })
  }
}

function * editUser (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.editUser}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(editUserActions.saveeditUserResponse(json.data))
    } else {
      yield put(editUserActions.saveeditUserResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editUserTypes.RESPONSE, data })
  }
}

function * deleteUsers (actions) {
  const { id, headers } = actions
  const deleteUrl = `${serviceList.user}/${id} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deleteUsersActions.savemResponse(json.data))
    } else {
      yield put(deleteUsersActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteUsersActionsTypes.RESPONSE, data })
  }
}

function * fetchProcessor (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.paymentprocessor, { params, headers:headers })
    if (json.status === 200) {
      yield put(processorActions.saveProcessorResponse(json.data))
    } else {
      yield put(processorActions.saveProcessorResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: processorActionsTypes.SAVE_PROCESSOR_RESPONSE, data })
  }
}

function * fetchPayment (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.paymentgateway, { params, headers:headers })
    if (json.status === 200) {
      yield put(paymentActions.savePaymentResponse(json.data))
    } else {
      yield put(paymentActions.savePaymentResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: paymentActionsTypes.SAVE_PAYMENT_RESPONSE, data })
  }
}

function * fetchPartnerClient (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.partnerClient, { params, headers:headers })
    if (json.status === 200) {
      yield put(partnerClientActions.savePartnerClientResponse(json.data))
    } else {
      yield put(partnerClientActions.savePartnerClientResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: partnerClientActionsTypes.SAVE_PARTNER_CLIENT_RESPONSE, data })
  }
}

function * addPartnersClient (action) {
  const { payload, params, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.addPartnerClient, payload, { params, headers:headers })
    if (json.status === 200) {
      yield put(addPartnersClientAction.saveAddPartnersClientResponse(json.data))
    } else {
      yield put(addPartnersClientAction.clearAddPartnersClient([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addPartnersClientTypes.RESPONSE, data })
  }
}
function * fetchClientCrmDetails (actions) {
  const { id,headers } = actions
  try {
    const json = yield axiosInstance.get(`${serviceList.clientcrmdetails}/${id}`,{headers})
    if (json.status === 200) {
      yield put(clientcrmActions.saveClientCrmResponse(json.data))
    } else {
      yield put(clientcrmActions.clearClientCrm([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: packageActionsTypes.SAVE_PACKAGE_RESPONSE, data })
  }
}

export function * fetchCRMWatcher () {
  yield all([
    yield takeLatest(crmActionsTypes.GET_CRM, fetchCRM),
    yield takeLatest(editPackageTypes.REQUEST, editPackage),
    yield takeLatest(listActionsTypes.REQUEST, fetchList),
    yield takeLatest(packageGetDetailsTypes.GET_PACKAGE_DETAILS, packageGetDetailsDetails),
    yield takeLatest(packageActionsTypes.GET_PACKAGE, fetchPackage),
    yield takeLatest(userActionsTypes.GET_USER, fetchUser),
    yield takeLatest(userGetDetailsTypes.GET_USER_DETAILS, userGetDetailsDetails),
    yield takeLatest(deleteUsersActionsTypes.REQUEST, deleteUsers),
    yield takeLatest(editUserTypes.REQUEST, editUser),
    yield takeLatest(processorActionsTypes.GET_PROCESSOR, fetchProcessor),
    yield takeLatest(paymentActionsTypes.GET_PAYMENT, fetchPayment),
    yield takeLatest(packPaymentActionTypes.GET_PACK_PAYMENT, fetchPackPayment),
    yield takeLatest(clientPackagesActionsTypes.GET_CLIENT_PACKAGES, fetchPackPayment),
    yield takeLatest(partnerClientActionsTypes.GET_PARTNER_CLIENT, fetchPartnerClient),
    yield takeLatest(addUserTypes.REQUEST, addUserInfo),
    yield takeLatest(addPartnersClientTypes.REQUEST, addPartnersClient),
    yield takeLatest(clientcrmActionsTypes.GET_CLIENT_CRM_DETAILS, fetchClientCrmDetails)

  ])
}
