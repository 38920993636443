import {
  siteConfigActionsTypes,
  siteConfigGetDetailsTypes,
  addSiteConfigTypes,
  editSiteConfigTypes,
  siteConfigDeleteTypes
} from '../actions'

export const siteConfigInitialState = {
  list: null
}

export const siteConfigStoreKey = 'siteConfigStore'

export const siteConfigReducer = (state = siteConfigInitialState, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case siteConfigActionsTypes.GET_SITE_CONFIG:
      return { ...state, loadingGSC: true }
    case siteConfigActionsTypes.SAVE_SITE_CONFIG_RESPONSE:
      return { ...state, siteConfigs: res.data, statusGSC: res.status, loadingGSC: false }
    case siteConfigActionsTypes.CLEAR_SITE_CONFIG:
      return { ...state, siteConfigs: null, statusGSC: '' }

    case siteConfigGetDetailsTypes.GET_SITE_CONFIG_DETAILS:
      return { ...state, loadingSCD: true }
    case siteConfigGetDetailsTypes.SITE_CONFIG_DETAILS_RESPONSE:
      return {
        ...state,
        SiteConfigDetails: action.data && action.data.data,
        statusSCD: action.data && action.data.status,
        messagesSCD: action.data && action.data.message,
        loadingSCD: false
      }
    case siteConfigGetDetailsTypes.CLEAR_SITE_CONFIG_DETAILS:
      return { ...state, statusSCD: '', messagesSCD: '' }
    default:
      return state
  }
}

export const addSiteConfigInitialState = {
  list: null
}

export const addSiteConfigStoreKey = 'addSiteConfigStore'

export const addSiteConfigReducer = (state = addSiteConfigInitialState, action) => {
  switch (action.type) {
    case addSiteConfigTypes.REQUEST:
      return { ...state, loadingASC: true }
    case addSiteConfigTypes.RESPONSE:
      return { ...state, dataASC: action.data, statusASC: action.data.status, messageASC: action.data.message, loadingASC: false }
    case addSiteConfigTypes.CLEAR:
      return { ...state, dataASC: null, messageASC: '', statusASC: '' }
    default:
      return state
  }
}

export const editSiteConfigsStoreKey = 'editSiteConfigsStore'

export const editSiteConfigsReducer = (state = {}, action) => {
  switch (action.type) {
    case editSiteConfigTypes.REQUEST:
      return { ...state, loadingESC: true }
    case editSiteConfigTypes.RESPONSE:
      return { ...state, dataESC: action.data && action.data, statusESC: action.data.status, messageESC: action.data.message, loadingESC: false }
    case editSiteConfigTypes.CLEAR:
      return { ...state, dataESC: null, messageESC: '', statusESC: '' }
    default:
      return state
  }
}

export const siteConfigDeleteStoreKey = 'siteConfigDeleteStore'

export const deleteSiteConfigReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case siteConfigDeleteTypes.DELETE_SITE_CONFIG:
      return { ...state, loading: true }
    case siteConfigDeleteTypes.SAVE_DELETE_SITE_CONFIG_RESPONSE:
      return {
        ...state,
        deleteSiteConfig: res.data,
        deleteSiteConfigStatus: res.status,
        deleteSiteConfigMessage: res.message,
        loading: false
      }
    case siteConfigDeleteTypes.CLEAR_DELETE_SITE_CONFIG:
      return { ...state, deleteSiteConfig: null, deleteSiteConfigStatus: '', deleteSiteConfigMessage: '' }
    default:
      return state
  }
}
