import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { STATUS_RESPONSE, REGEX, SESSION, API } from '../../../../utils/constants'
import {
  merchantCBDetailsActions,
  countryActions,
  stateActions,
  cityActions,
  industryActions
} from '../../../../store/actions'
import ReactSelect from '../../../../theme/layout/components/ReactSelect'
import color from '../../../../utils/colors'
import { useLocation } from 'react-router-dom'
import { getLocalStorage, headersWithAuth } from '../../../../utils/helper'

function Step4(props) {
  const {
    onClickNext,
    goBack,
    getMerchantDetailsDispatch,
    clearMerchantDetailsDispatch,
    MerchantCBDetails,
    statusGMCBD,
    loadingGMCBD,
    getCountryDispatch,
    getCountrys,
    getStateDispatch,
    getStates,
    getCityDispatch,
    getCitys,
    getIndustryDispatch,
    getIndustry
  } = props

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }

  const url = useLocation().pathname
  const fields = url && url.split('/')
  const id = fields && fields[3]
  const [countryOption, setCountryOption] = useState()
  const [selectedCountryOption, setSelectedCountryOption] = useState('')
  const [stateOption, setStateOption] = useState()
  const [selectedStateOption, setSelectedStateOption] = useState('')
  const [cityOptions, setCityOptions] = useState()
  const [selectedCityOption, setSelectedCityOption] = useState('')
  const [selectedIndustryOption, setSelectedIndustryOption] = useState('')
  const [industryOption, setIndustryOption] = useState()
  const [errors, setErrors] = useState({})
  const [typingTimeout, setTypingTimeout] = useState(0)
  const [formData, setFormData] = useState({
    merchantName: '',
    merchantCountry: '',
    industry: '',
    merchantAddress: '',
    merchantCity: '',
    merchantState: '',
    merchantZip: '',
    merchantEmail: '',
    merchantPhoneNumber: '',
    phoneNumberExtension: '',
    externalId: '',
    companyDescription: '',
    descriptorName: '',
    descriptorId: '',
    doingBusinessAs: '',
    acquirerBin: '',
    caid: ''
  })

  useEffect(() => {
    const params = {
      skipPagination: true
    }
    getCountryDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getStateDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getCityDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    getIndustryDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
  }, [])

  const handleChange = (e) => {
    e.persist()
    setFormData(values => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const handleNextClick = () => {
    onClickNext(4)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : ''
    })
  }

  const handleChangeCountry = selectedOption => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getStateDispatch({ country: selectedOption.value }, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
        }, 1500)
      )
      setSelectedCountryOption(selectedOption)
      setFormData(values => ({ ...values, country: selectedOption.value, state: '', city: '' }))
      setSelectedStateOption()
      setSelectedCityOption()
    } else {
      setSelectedCountryOption()
      setSelectedStateOption()
      setSelectedCityOption()
      setFormData(values => ({ ...values, country: '', state: '', city: '' }))
    }
    setErrors({ ...errors, country: '' })
  }

  const handleChangeState = selectedOption => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getCityDispatch({ state: selectedOption.value }, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
        }, 1500)
      )
      setSelectedStateOption(selectedOption)
      setFormData(values => ({ ...values, state: selectedOption.value, city: '' }))
      setSelectedCityOption()
    } else {
      setSelectedStateOption()
      setSelectedCityOption()
      setFormData(values => ({ ...values, state: '', city: '' }))
    }
    setErrors({ ...errors, state: '' })
  }

  const handleChangeCity = selectedOption => {
    if (selectedOption !== null) {
      setSelectedCityOption(selectedOption)
      setFormData(values => ({ ...values, city: selectedOption.value }))
    } else {
      setSelectedCityOption()
      setFormData(values => ({ ...values, city: '' }))
    }
    setErrors({ ...errors, city: '' })
  }

  const handleChangeIndustry = selectedOption => {
    if (selectedOption !== null) {
      setSelectedIndustryOption(selectedOption)
      setFormData(values => ({ ...values, industry: selectedOption.value }))
      setErrors({ ...errors, industry: '' })
    } else {
      setSelectedIndustryOption()
      setFormData(values => ({ ...values, industry: '' }))
    }
  }

  useEffect(() => {
    const country = getDefaultOptions(getCountrys)
    setCountryOption(country)
  }, [getCountrys])

  useEffect(() => {
    const data = getDefaultIndustryOptions(getIndustry, 'industryType')
    setIndustryOption(data)
  }, [getIndustry])

  useEffect(() => {
    if (id) {
      getMerchantDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }, [id])

  useEffect(() => {
    if (statusGMCBD === STATUS_RESPONSE.SUCCESS_MSG) {
      if (MerchantCBDetails && MerchantCBDetails._id) {
        setFormData(values => ({
          ...values,
          ...MerchantCBDetails
        }))
      }
      if (MerchantCBDetails.merchantCountry) {
        const country = getDefaultOptions(getCountrys)
        const selOption = _.filter(country, function (x) {
          if (_.includes(MerchantCBDetails.merchantCountry, x.value)) { return x }
        })
        if (selOption.length > 0) {
          setSelectedCountryOption(selOption)
        }
      }
      if (MerchantCBDetails.merchantState) {
        const state = getDefaultOptions(getStates)
        const selOption = _.filter(state, function (x) {
          if (_.includes(MerchantCBDetails.merchantState, x.value)) { return x }
        })
        if (selOption.length > 0) {
          setSelectedStateOption(selOption)
        }
      }
      if (MerchantCBDetails.merchantCity) {
        const city = getDefaultOptions(getCitys)
        const selOption = _.filter(city, function (x) {
          if (_.includes(MerchantCBDetails.merchantCity, x.value)) { return x }
        })
        if (selOption.length > 0) {
          setSelectedCityOption(selOption)
        }
      }
      if (MerchantCBDetails.industry) {
        const data = getDefaultIndustryOptions(getIndustry, 'industryType')
        const selOption = _.filter(data, function (x) {
          if (_.includes(MerchantCBDetails.industry, x.value)) {
            return x
          }
        })
        setSelectedIndustryOption(selOption)
      }
      clearMerchantDetailsDispatch()
    } else if (statusGMCBD === STATUS_RESPONSE.ERROR_MSG) {
      clearMerchantDetailsDispatch()
    }
  }, [statusGMCBD])

  useEffect(() => {
    const state = getDefaultOptions(getStates)
    setStateOption(state)
  }, [getStates])

  useEffect(() => {
    const city = getDefaultOptions(getCitys)
    setCityOptions(city)
  }, [getCitys])

  const getDefaultOptions = (rawData) => {
    const defaultOptions = []
    for (const item in rawData) {
      defaultOptions.push({ label: rawData[item].name, value: rawData[item]._id })
    }
    return defaultOptions
  }

  const getDefaultIndustryOptions = (data, name) => {
    const defaultOptions = []
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ''}`,
          value: item._id
        })
      )
      return defaultOptions
    }
  }

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  return (
    <div className='container-fixed'>
      <div className='card-header bg-skyBlue py-10'>
        <div className='card-body  m-8'>
          {
            loadingGMCBD
              ? (
                <div className='d-flex justify-content-center py-5'>
                  <div className='spinner-border text-primary m-5' role='status' />
                </div>
              )
              : (
                <>
                  <h2 className='mb-5'>Merchant Info</h2>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Merchant Name:
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='merchantName'
                          type='text'
                          className='form-control'
                          placeholder='Merchant Name'
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantName || ''}
                          disabled
                          maxLength={42}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.merchantName && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.merchantName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Industry:
                      </label>
                      <div className='col-lg-12'>
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name='industry'
                          className='basic-single'
                          classNamePrefix='select'
                          handleChangeReactSelect={handleChangeIndustry}
                          options={industryOption}
                          value={selectedIndustryOption}
                          isDisabled
                        />
                        {errors && errors.industry && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.industry}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Country:
                      </label>
                      <div className='col-lg-12'>
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name='merchantCountry'
                          className='basic-single'
                          classNamePrefix='select'
                          handleChangeReactSelect={handleChangeCountry}
                          options={countryOption}
                          value={selectedCountryOption}
                          isDisabled
                        />
                        {errors && errors.merchantCountry && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.merchantCountry}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        State:
                      </label>
                      <div className='col-lg-12'>
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name='merchantState'
                          className='basic-single'
                          classNamePrefix='select'
                          handleChangeReactSelect={handleChangeState}
                          options={stateOption}
                          value={selectedStateOption}
                          isDisabled
                        />
                        {errors && errors.merchantState && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.merchantState}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        City:
                      </label>
                      <div className='col-lg-12'>
                        <ReactSelect
                          isClearable
                          styles={customStyles}
                          isMulti={false}
                          name='merchantCity'
                          className='basic-single'
                          classNamePrefix='select'
                          handleChangeReactSelect={handleChangeCity}
                          options={cityOptions}
                          value={selectedCityOption}
                          isDisabled
                        />
                        {errors && errors.merchantCity && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.merchantCity}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Address:
                      </label>
                      <div className='col-lg-12'>
                        <textarea
                          autoComplete='off'
                          name='merchantAddress'
                          type='text'
                          className='form-control'
                          placeholder='Address'
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantAddress || ''}
                          disabled
                          maxLength={500}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.merchantAddress && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.merchantAddress}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Zip Code:
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='merchantZip'
                          type='text'
                          className='form-control'
                          placeholder='Zip Code'
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantZip || ''}
                          disabled
                          maxLength={6}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.merchantZip && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.merchantZip}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Email:
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='merchantEmail'
                          type='text'
                          className='form-control'
                          placeholder='Email'
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantEmail || ''}
                          disabled
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                        />
                        {errors && errors.merchantEmail && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.merchantEmail}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Phone Number:
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='merchantPhoneNumber'
                          type='text'
                          className='form-control'
                          placeholder='Phone Number'
                          onChange={(e) => handleChange(e)}
                          value={formData.merchantPhoneNumber || ''}
                          disabled
                          maxLength={12}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.merchantPhoneNumber && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.merchantPhoneNumber}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Phone Extension:
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='phoneNumberExtension'
                          type='text'
                          className='form-control'
                          placeholder='Phone Extension'
                          onChange={(e) => handleChange(e)}
                          value={formData.phoneNumberExtension || ''}
                          disabled
                          maxLength={10}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!/[0-9+]/.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.phoneNumberExtension && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.phoneNumberExtension}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        External Id:
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='externalId'
                          type='text'
                          className='form-control'
                          placeholder='External Id'
                          onChange={(e) => handleChange(e)}
                          value={formData.externalId || ''}
                          disabled
                          maxLength={42}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.externalId && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.externalId}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Company Description:
                      </label>
                      <div className='col-lg-12'>
                        <textarea
                          autoComplete='off'
                          name='companyDescription'
                          type='text'
                          className='form-control'
                          placeholder='Company Description'
                          onChange={(e) => handleChange(e)}
                          value={formData.companyDescription || ''}
                          disabled
                          maxLength={500}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.companyDescription && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.companyDescription}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Descriptor name:
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='descriptorName'
                          type='text'
                          className='form-control'
                          placeholder='Descriptor name'
                          onChange={(e) => handleChange(e)}
                          value={formData.descriptorName || ''}
                          disabled
                          maxLength={42}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.descriptorName && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.descriptorName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Descriptor ID:
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='descriptorId'
                          type='text'
                          className='form-control'
                          placeholder='Descriptor ID'
                          onChange={(e) => handleChange(e)}
                          value={formData.descriptorId || ''}
                          disabled
                          maxLength={42}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.descriptorId && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.descriptorId}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Doing business as:
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='doingBusinessAs'
                          type='text'
                          className='form-control'
                          placeholder='Doing business as'
                          onChange={(e) => handleChange(e)}
                          value={formData.doingBusinessAs || ''}
                          disabled
                          maxLength={42}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.doingBusinessAs && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.doingBusinessAs}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        Acquirer BIN'
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='acquirerBin'
                          type='text'
                          className='form-control'
                          placeholder='Acquirer BIN'
                          onChange={(e) => handleChange(e)}
                          value={formData.acquirerBin || ''}
                          disabled
                          maxLength={6}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.acquirerBin && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.acquirerBin}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2  form-label'>
                        CAID :
                      </label>
                      <div className='col-lg-12'>
                        <input
                          autoComplete='off'
                          name='caid'
                          type='text'
                          className='form-control'
                          placeholder='CAID (Cardholder Acceptance Identification Number)'
                          onChange={(e) => handleChange(e)}
                          disabled
                          value={formData.caid || ''}
                          maxLength={6}
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.caid && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.caid}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )
          }
        </div>
        <div className='form-group row mb-4'>
          <div className='col-lg-6' />
          <div className='col-lg-6'>
            <div className='col-lg-11'>
              <button
                className='btn btn-primary m-2 fa-pull-right'
                id='submitBtn'
                onClick={() => {
                  handleNextClick()
                }}
              >
                {''
                  ? (
                    <span
                      className='spinner-border spinner-border-sm mx-3'
                      role='status'
                      aria-hidden='true'
                    />
                  )
                  : (
                    <>
                      <span>Continue</span>  &nbsp;
                      <span className="bi bi-caret-right-fill mt-2" />
                    </>
                  )}
              </button>
              <button
                className='btn btn-darkRed m-2 fa-pull-right'
                onClick={() => { goBack(2) }}
              >
                <span className="bi bi-caret-left-fill mt-2" />&nbsp;
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state) => {
  const { merchantCBStore } = state
  return {
    MerchantCBDetails: merchantCBStore && merchantCBStore.MerchantCBDetails ? merchantCBStore.MerchantCBDetails : [],
    statusGMCBD: merchantCBStore && merchantCBStore.statusGMCBD ? merchantCBStore.statusGMCBD : '',
    messagesGMCBD: merchantCBStore && merchantCBStore.messagesGMCBD ? merchantCBStore.messagesGMCBD : '',
    loadingGMCBD: merchantCBStore && merchantCBStore.loadingGMCBD ? merchantCBStore.loadingGMCBD : false,
    getCountrys: state && state.countryStore && state.countryStore.getCountrys,
    getStates: state && state.stateStore && state.stateStore.getStates,
    getCitys: state && state.cityStore && state.cityStore.getCitys,
    getIndustry: state && state.industryStore && state.industryStore.getIndustry,
  }
}
const mapDispatchToProps = dispatch => ({
  getMerchantDetailsDispatch: (id, headers) =>
    dispatch(merchantCBDetailsActions.getMerchantDetails(id, headers)),
  clearMerchantDetailsDispatch: () =>
    dispatch(merchantCBDetailsActions.clearMerchantDetails()),
  getCountryDispatch: (params, headers) => dispatch(countryActions.getCountrys(params, headers)),
  countryActions: () => dispatch(countryActions.getCountrys()),
  getStateDispatch: (params, headers) => dispatch(stateActions.getStates(params, headers)),
  stateActions: () => dispatch(stateActions.getStates()),
  getCityDispatch: (params, headers) => dispatch(cityActions.getCitys(params, headers)),
  cityActions: () => dispatch(cityActions.getCitys()),
  getIndustryDispatch: (params, headers) => dispatch(industryActions.getIndustry(params, headers)),
  industryActions: () => dispatch(industryActions.getIndustry()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step4)
