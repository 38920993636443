import React, { useEffect, useState } from 'react'
import { userValidation } from './validation'
import { CRM_FORM, REGEX, SESSION, API } from '../../../../utils/constants'
import { setLocalStorage, getLocalStorage, headersWithAuth } from '../../../../utils/helper'
import { getRoleValues } from '../functions/functions'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import {
  userRoleActions
} from '../../../../store/actions'
import { connect } from 'react-redux'

const UserInfo = (props) => {
  const { loading, onClickNext, goBack, setClientDetails, getUserRoleDispatch, gerUserRole, setSummary } = props
  const [editMode, setEditMode] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    password: '',
    cPassword: '',
    roleId: ''
  })

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }
  const pathName = useLocation().pathname
  const url = pathName && pathName.split('update')
  const id = url && url[1]
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    e.persist()
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const handleSubmit = () => {
    const errorMsg = userValidation(formData, setErrors)
    const getRolelabel = getRoleValues(gerUserRole, formData.roleId)
    if (_.isEmpty(errorMsg)) {
      setClientDetails((values) => ({ ...values, user: formData }))
      setSummary((values) => ({
        ...values,
        user: {
          roleId: getRolelabel && getRolelabel.label
        }
      }))
      setLocalStorage(CRM_FORM.USER_DETAILS, JSON.stringify(formData))
    }
  }

  const handleNextClick = () => {
    const errorMsg = userValidation(formData, setErrors)
    if (_.isEmpty(errorMsg)) {
      onClickNext(4)
    }
  }

  useEffect(() => {
    getUserRoleDispatch(null, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
  }, [])

  useEffect(() => {
    const localData = JSON.parse(getLocalStorage(CRM_FORM.USER_DETAILS))
    if (!_.isEmpty(localData)) {
      setFormData(localData)
    }
  }, [])

  useEffect(() => {
    if (id) {
      setEditMode(true)
      setShowForm(false)
    } else {
      setEditMode(false)
    }
  }, [id])

  return (
    <>
      <div className='bg-skyBlue'>
        <div className='card-header bg-skyBlue py-10'>
          <div className='card-body p-8'>
            <h2 className='mb-5'>Login Info</h2>
            <div className='card-header py-10'>
              <div className='card-body'>
                <div className='form-group row mb-4'>
                  <div className='col-lg-12 mb-3'>
                    <div className='row'>
                      <div className='col-lg-2' />
                      <div className='col-lg-2'>
                        <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                          First Name:
                        </label>
                      </div>
                      <div className='col-lg-4'>
                        <div className='input-group mb-5'>
                          <span
                            className='input-group-text'
                            id='basic-addon3'
                          >
                            <i className='bi bi-person-circle' />
                          </span>
                          <input
                            name='firstName'
                            type='text'
                            className='form-control'
                            id='basic-url'
                            placeholder='First Name'
                            aria-describedby='basic-addon3'
                            onChange={(e) => handleChange(e)}
                            value={formData.firstName || ''}
                            autoComplete='off'
                            maxLength={42}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_CHARS_SPACE.test(e.key)
                              ) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                        {errors && errors.firstName && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.firstName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group row mb-4'>
                  <div className='col-lg-12 mb-3'>
                    <div className='row'>
                      <div className='col-lg-2' />
                      <div className='col-lg-2'>
                        <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                          Last Name:
                        </label>
                      </div>
                      <div className='col-lg-4'>
                        <div className='input-group mb-5'>
                          <span
                            className='input-group-text'
                            id='basic-addon3'
                          >
                            <i className='bi bi-person-circle' />
                          </span>
                          <input
                            name='lastName'
                            type='text'
                            className='form-control'
                            id='basic-url'
                            placeholder='Last Name'
                            aria-describedby='basic-addon3'
                            onChange={(e) => handleChange(e)}
                            value={formData.lastName || ''}
                            autoComplete='off'
                            maxLength={42}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_CHARS_SPACE.test(e.key)
                              ) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                        {errors && errors.lastName && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.lastName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group row mb-4'>
                  <div className='col-lg-12 mb-3'>
                    <div className='row'>
                      <div className='col-lg-2' />
                      <div className='col-lg-2'>
                        <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                          Phone Number:
                        </label>
                      </div>
                      <div className='col-lg-4'>
                        <div className='input-group mb-5'>
                          <span
                            className='input-group-text'
                            id='basic-addon3'
                          >
                            <i className='bi bi-telephone-fill' />
                          </span>
                          <input
                            name='mobile'
                            type='text'
                            className='form-control'
                            id='basic-url'
                            placeholder='Phone Number'
                            aria-describedby='basic-addon3'
                            onChange={(e) => handleChange(e)}
                            value={formData.mobile || ''}
                            autoComplete='off'
                            onKeyPress={(e) => {
                              if (
                                !REGEX.NUMERIC.test(e.key)
                              ) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                        {errors && errors.mobile && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.mobile}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group row mb-4'>
                  <div className='col-lg-12 mb-3'>
                    <div className='row'>
                      <div className='col-lg-2' />
                      <div className='col-lg-2'>
                        <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                          Email:
                        </label>
                      </div>
                      <div className='col-lg-4'>
                        <div className='input-group mb-5'>
                          <span className='input-group-text' id='basic-addon3'>
                            <i className='bi bi-envelope-fill' />
                          </span>
                          <input
                            name='email'
                            type='text'
                            className='form-control'
                            id='basic-url'
                            placeholder='Email'
                            aria-describedby='basic-addon3'
                            onChange={(e) => handleChange(e)}
                            value={formData.email || ''}
                            autoComplete='off'
                            maxLength={42}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                        {errors && errors.email && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group row mb-4'>
                  <div className='col-lg-12 mb-3'>
                    <div className='row'>
                      <div className='col-lg-2' />
                      <div className='col-lg-2'>
                        <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                          Password:
                        </label>
                      </div>
                      <div className='col-lg-4'>
                        <div className='input-group mb-5'>
                          <span className='input-group-text' id='basic-addon3'>
                            <i className='bi bi-lock-fill' />
                          </span>
                          <input
                            name='password'
                            type='password'
                            className='form-control'
                            id='basic-url'
                            placeholder='Password'
                            aria-describedby='basic-addon3'
                            onChange={(e) => handleChange(e)}
                            value={formData.password || ''}
                            autoComplete='off'
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault()
                              return false
                            }}
                            onCopy={(e) => {
                              e.preventDefault()
                              return false
                            }}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                        {errors && errors.password && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group row mb-4'>
                  <div className='col-lg-12 mb-3'>
                    <div className='row'>
                      <div className='col-lg-2' />
                      <div className='col-lg-2'>
                        <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                          Confirm Password:
                        </label>
                      </div>
                      <div className='col-lg-4'>
                        <div className='input-group mb-5'>
                          <span className='input-group-text' id='basic-addon3'>
                            <i className='bi bi-lock' />
                          </span>
                          <input
                            name='cPassword'
                            type='password'
                            className='form-control'
                            id='basic-url'
                            placeholder='Confirm Password'
                            aria-describedby='basic-addon3'
                            onChange={(e) => handleChange(e)}
                            value={formData.cPassword || ''}
                            autoComplete='off'
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault()
                              return false
                            }}
                            onCopy={(e) => {
                              e.preventDefault()
                              return false
                            }}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                        {errors && errors.cPassword && (
                          <div className='rr mt-1'>
                            <style>{'.rr{color:red;}'}</style>
                            {errors.cPassword}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {isAuthorized &&
                  <div className='form-group row mb-4'>
                    <div className='col-lg-12 mb-3'>
                      <div className='row'>
                        <div className='col-lg-2' />
                        <div className='col-lg-2'>
                          <label className='font-size-xs font-weight-bold mb-2 required form-label mt-4'>
                            Role:
                          </label>
                        </div>
                        <div className='col-lg-4'>
                          <div className='input-group mb-5'>
                            <span
                              className='input-group-text'
                              id='basic-addon3'
                            >
                              <i className='bi bi-envelope' />
                            </span>
                            <select
                              name='roleId'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.roleId || ''}
                            >
                              <option value=''>Select Role...</option>
                              {gerUserRole &&
                                gerUserRole.length > 0 &&
                                gerUserRole.map((item, i) => (
                                  <option key={i} value={item._id}>
                                    {item.role}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {errors && errors.roleId && (
                            <div className='rr mt-1'>
                              <style>{'.rr{color:red}'}</style>
                              {errors.roleId}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            {
              showForm
                ? (
                  <div className='form-group row mt-4 mb-1'>
                    <div className='col-lg-6' />
                    <div className='col-lg-6'>
                      <div className='col-lg-11'>
                        <button
                          className='btn btn-orange m-2 fa-pull-right'
                          onClick={(event) => {
                            handleSubmit(event)
                            handleNextClick()
                          }}
                        >
                          {loading
                            ? (
                              <span
                                className='spinner-border spinner-border-sm mx-3'
                                role='status'
                                aria-hidden='true'
                              />
                            )
                            : (
                              'Next'
                            )}
                        </button>
                        <button
                          className='btn btn-darkRed m-2 fa-pull-right'
                          onClick={() => { goBack(2) }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                )
                : null
            }
            {
              editMode && !showForm
                ? (
                  <div className='form-group row mt-4'>
                    <div className='col-lg-6' />
                    <div className='col-lg-6'>
                      <div className='col-lg-11'>
                        <button
                          className='btn btn-blue m-2 fa-pull-right'
                        >
                          {''
                            ? (
                              <span
                                className='spinner-border spinner-border-sm mx-3'
                                role='status'
                                aria-hidden='true'
                              />
                            )
                            : (
                              'Save'
                            )}
                        </button>
                      </div>
                    </div>
                  </div>
                )
                : null
            }
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { userStore, userRoleStore } = state
  return {
    gerUserRole:
      userRoleStore && userRoleStore.gerUserRole
        ? userRoleStore.gerUserRole
        : []
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserRoleDispatch: (data, headers) => dispatch(userRoleActions.getUserRole(data, headers))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)