import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  getPrivilegesActionsTypes,
  getPrivilegesActions,
  addPrivilegesActionsTypes,
  addPrivilegesActions,
  getPrivilegesDetailsActionsTypes,
  getPrivilegesDetailsActions,
  updatePrivilegesActionsTypes,
  updatePrivilegesActions,
  deletePrivilegesActionsTypes,
  deletePrivilegesActions,
  getCompPermissionActionsTypes,
  getCompPermissionActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchPrivileges (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userPrivileges, { params, headers: headers })
    if (json.status === 200) {
      yield put(getPrivilegesActions.saveResponse(json.data))
    } else {
      yield put(getPrivilegesActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPrivilegesActionsTypes.RESPONSE, data })
  }
}

function * addPrivileges (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.userPrivileges, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addPrivilegesActions.saveResponse(json.data))
    } else {
      yield put(addPrivilegesActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addPrivilegesActionsTypes.RESPONSE, data })
  }
}

function * getPrivilegesDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.userPrivileges}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(
        getPrivilegesDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getPrivilegesDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getPrivilegesDetailsActionsTypes.RESPONSE, data })
  }
}

function * updatePrivileges (actions) {
  const { payload, headers } = actions
  const { params } = payload && payload
  const endPointUrl = `${serviceList.userPrivileges}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(updatePrivilegesActions.saveResponse(json.data))
    } else {
      yield put(updatePrivilegesActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updatePrivilegesActionsTypes.RESPONSE, data })
  }
}

function * deletePrivileges (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.userPrivileges}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deletePrivilegesActions.savemResponse(json.data))
    } else {
      yield put(deletePrivilegesActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deletePrivilegesActionsTypes.RESPONSE, data })
  }
}

function * fetchCompPermission (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userCompPermission, { params, headers: headers })
    if (json.status === 200) {
      yield put(getCompPermissionActions.saveResponse(json.data))
    } else {
      yield put(getCompPermissionActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getCompPermissionActionsTypes.RESPONSE, data })
  }
}

export function * fetchPrivilegesWatcher () {
  yield all([
    yield takeLatest(getPrivilegesActionsTypes.REQUEST, fetchPrivileges),
    yield takeLatest(addPrivilegesActionsTypes.REQUEST, addPrivileges),
    yield takeLatest(getPrivilegesDetailsActionsTypes.REQUEST, getPrivilegesDetails),
    yield takeLatest(updatePrivilegesActionsTypes.REQUEST, updatePrivileges),
    yield takeLatest(deletePrivilegesActionsTypes.REQUEST, deletePrivileges),
    yield takeLatest(getCompPermissionActionsTypes.REQUEST, fetchCompPermission)
  ])
}
