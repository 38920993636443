import React, { useState, useEffect } from 'react'
import { validateStep3, validateDelivery } from './validation'
import { connect } from 'react-redux'
import { STATUS_RESPONSE, DATE, REGEX, SESSION, API } from '../../../../utils/constants'
import {
  purchaseDetailsActions,
  editPurchasesActions,
  chargeBackDetailsActions
} from '../../../../store/actions'
import { DateSelector } from '../../../../theme/layout/components/DateSelector'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { confirmAlert, warningAlert } from '../../../../utils/alerts'
import { getLocalStorage, headersWithAuth } from '../../../../utils/helper'

function Step3(props) {
  const {
    onClickNext,
    goBack,
    getPurchaseDetailsDispatch,
    clearPurchaseDetailsDispatch,
    PurchaseDetails,
    statusGPD,
    loadingGPD,
    editProductDispatch,
    cleareditProductDispatch,
    statusEPCB,
    messageEPCB,
    loadingEPCB,
    getCBDetailsDispatch,
    ChargebackDetails
  } = props

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }

  const url = useLocation().pathname
  const fields = url && url.split('/')
  const id = fields && fields[3]
  const [editForm, setEditForm] = useState(false)
  const [errors, setErrors] = useState({})

  const [formData, setFormData] = useState({
    productName: '',
    productModel: '',
    productSku: '',
    productDescription: '',
    productUrl: '',
    productImageUrl: '',
    unitPriceCurrency: '',
    unitPriceAmount: '',
    quantity: '',
    notes: '',
    industryDetails: '',
    shippingCarrierUrl: '',
    shippingCarrierPhone: '',
    trackingNumber: '',
    dateOfShipment: '',
    dateOfDelivery: '',
    signatureConfirmation: '',
    signedBy: '',
    exceptionNotes: '',
    physicalFulfillmentNotes: '',
    proofOfDelivery: '',
    proofOfUsage: '',
    locationOfUsage: '',
    digitalServiceDeliveryNotes: ''
  })

  const handleChange = (e) => {
    e.persist()
    setFormData(values => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const handleSubmit = () => {
    const errorMsg = validateStep3(formData, setErrors)
    const errorDeliveryMsg = validateDelivery(formData, setErrors)
    if (_.isEmpty(errorMsg) && _.isEmpty(errorDeliveryMsg)) {
      const payload = {
        productPurchased: {
          productName: formData.productName,
          productModel: formData.productModel,
          productSku: formData.productSku,
          productDescription: formData.productDescription,
          productUrl: formData.productUrl,
          productImageUrl: formData.productImageUrl,
          unitPriceCurrency: formData.unitPriceCurrency,
          unitPriceAmount: formData.unitPriceAmount,
          quantity: formData.quantity,
          notes: formData.notes,
          industryDetails: formData.industryDetails
        },
        delivery: {
          shippingCarrierUrl: formData.shippingCarrierUrl,
          shippingCarrierPhone: formData.shippingCarrierPhone,
          trackingNumber: formData.trackingNumber,
          dateOfShipment: moment(formData.dateOfShipment).format(DATE.DATE_FORMAT),
          dateOfDelivery: moment(formData.dateOfDelivery).format(DATE.DATE_FORMAT),
          signatureConfirmation: formData.signatureConfirmation,
          signedBy: formData.signedBy,
          exceptionNotes: formData.exceptionNotes,
          physicalFulfillmentNotes: formData.physicalFulfillmentNotes,
          proofOfDelivery: formData.proofOfDelivery,
          proofOfUsage: formData.proofOfUsage,
          locationOfUsage: formData.locationOfUsage,
          digitalServiceDeliveryNotes: formData.digitalServiceDeliveryNotes
        },
      }
      editProductDispatch(id, payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }

  const onConfirm = () => {
  }

  useEffect(() => {
    if (statusEPCB === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'success',
        messageEPCB,
        'success',
        'Ok',
        () => { onConfirm() },
        () => { onClickNext(3) }
      )
      cleareditProductDispatch()
    } else if (statusEPCB === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'error',
        messageEPCB,
        '',
        'Ok'
      )
      cleareditProductDispatch()
    }
  }, [statusEPCB])

  useEffect(() => {
    if (id) {
      getCBDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
      getPurchaseDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }, [id])

  useEffect(() => {
    if (statusGPD === STATUS_RESPONSE.SUCCESS_MSG) {
      if (PurchaseDetails.productPurchased && PurchaseDetails.delivery !== null ||
        PurchaseDetails.productPurchased && PurchaseDetails.delivery !== undefined) {
        setFormData(values => ({
          ...values,
          ...PurchaseDetails.productPurchased,
          ...PurchaseDetails.delivery,
          checkInDate: PurchaseDetails.productPurchased.checkInDate ?
            new Date(PurchaseDetails.productPurchased.checkInDate) : null,
          checkOutDate: PurchaseDetails.productPurchased.checkOutDate ?
            new Date(PurchaseDetails.productPurchased.checkOutDate) : null,
          flightDate: PurchaseDetails.productPurchased.flightDate ?
            new Date(PurchaseDetails.productPurchased.flightDate) : null,
          reservationDate: PurchaseDetails.productPurchased.reservationDate ?
            new Date(PurchaseDetails.productPurchased.reservationDate) : null,
          dateOfDelivery: PurchaseDetails.delivery.dateOfDelivery ?
            new Date(PurchaseDetails.delivery.dateOfDelivery) : null,
          dateOfShipment: PurchaseDetails.delivery.dateOfShipment ?
            new Date(PurchaseDetails.delivery.dateOfShipment) : null,
        }))
      }
      clearPurchaseDetailsDispatch()
    }
  }, [statusGPD])

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  const handleEdit = () => {
    setEditForm(val => !val)
  }

  return (
    <div className='container-fixed'>
      <div className='card-header bg-skyBlue py-10'>
        <div className='card-body  m-8'>
          {
            loadingGPD
              ? (
                <div className='d-flex justify-content-center py-5'>
                  <div className='spinner-border text-primary m-5' role='status' />
                </div>
              )
              : (
                <>
                  <div>
                    <div className='col-lg-12'>
                      <div className='col-lg-12 mt-4'>
                        {!['WON', 'LOST', 'NOT FAUGHT', 'LOST REFUNDED'].includes(ChargebackDetails.chargeBackStatus) && !loadingGPD &&
                          <button
                            className='btn btn-sm fa-pull-right m-1'
                            style={{ backgroundColor: '#6c54a5', color: '#fff' }}
                            onClick={() => { handleEdit() }}
                          >
                            <span className='svg-icon svg-icon-white svg-icon-3'>
                              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                <path opacity='0.3' fillRule='evenodd' clipRule='evenodd' d='M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z' fill='black' />
                                <path d='M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z' fill='black' />
                                <path d='M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z' fill='black' />
                              </svg>
                            </span>
                            Edit
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='me-2 mb-10'>
                    <p className='text-dark fw-bolder fs-3'>
                      PRODUCT INFORMATION
                    </p>
                  </div>
                  <div className='row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Product Name:</label>
                      <div className='col-lg-11'>
                        <input
                          name='productName'
                          type='text'
                          className='form-control'
                          placeholder='Product Name'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.productName || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.TEXT.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.productName && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.productName}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Product Model:</label>
                      <div className='col-lg-11'>
                        <input
                          name='productModel'
                          type='text'
                          className='form-control'
                          placeholder='Product Model'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.productModel || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.productModel && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.productModel}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Product Sku:</label>
                      <div className='col-lg-11'>
                        <input
                          name='productSku'
                          type='text'
                          className='form-control'
                          placeholder='Product Sku'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.productSku || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.productSku && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.productSku}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Product Description:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='productDescription'
                          type='text'
                          className='form-control'
                          placeholder='Product Description'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.productDescription || ''}
                        />
                        {errors && errors.productDescription && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.productDescription}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Product URL:</label>
                      <div className='col-lg-11'>
                        <input
                          name='productUrl'
                          type='text'
                          className='form-control'
                          placeholder='Product URL'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.productUrl || ''}
                        />
                        {errors && errors.productUrl && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.productUrl}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Product Image URL:</label>
                      <div className='col-lg-11'>
                        <input
                          name='productImageUrl'
                          type='text'
                          className='form-control'
                          placeholder='Product Image URL'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.productImageUrl || ''}
                        />
                        {errors && errors.productImageUrl && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.productImageUrl}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Unit Price Currency:</label>
                      <div className='col-lg-11'>
                        <input
                          name='unitPriceCurrency'
                          type='text'
                          className='form-control'
                          placeholder='Unit Price Currency'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.unitPriceCurrency || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.unitPriceCurrency && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.unitPriceCurrency}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Unit Price Amount:</label>
                      <div className='col-lg-11'>
                        <input
                          name='unitPriceAmount'
                          type='text'
                          className='form-control'
                          placeholder='Unit Price Amount'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.unitPriceAmount || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!/^[0-9.]*$/.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.unitPriceAmount && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.unitPriceAmount}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Quantity:</label>
                      <div className='col-lg-11'>
                        <input
                          name='quantity'
                          type='text'
                          className='form-control'
                          placeholder='Quantity'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.quantity || ''}
                          onKeyPress={(e) => {
                            if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.quantity && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.quantity}</div>)}
                      </div>
                    </div>
                  </div>

                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Notes:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='notes'
                          type='text'
                          className='form-control'
                          placeholder='Notes'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.notes || ''}
                          disabled={!editForm}
                        />
                        {errors && errors.notes && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.notes}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Industry Details:</label>
                      <div className='col-lg-11'>
                        <textarea
                          name='industryDetails'
                          type='text'
                          className='form-control'
                          placeholder='Industry Details'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.industryDetails || ''}
                          disabled={!editForm}
                        />
                        {errors && errors.industryDetails && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.industryDetails}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='me-2'>
                    <p className='text-dark fw-bolder fs-3'>
                      DELIVERY INFORMATION
                    </p>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Shipping Carrier URL:</label>
                      <div className='col-lg-11'>
                        <input
                          name='shippingCarrierUrl'
                          type='text'
                          className='form-control'
                          placeholder='Shipping Carrier URL'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.shippingCarrierUrl || ''}
                          disabled={!editForm}
                        />
                        {errors && errors.shippingCarrierUrl && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.shippingCarrierUrl}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Shipping Carrier Phone:</label>
                      <div className='col-lg-11'>
                        <input
                          name='shippingCarrierPhone'
                          type='text'
                          className='form-control'
                          placeholder='Shipping Carrier Phone'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.shippingCarrierPhone || ''}
                          disabled={!editForm}
                        />
                        {errors && errors.shippingCarrierPhone && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.shippingCarrierPhone}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Tracking Number:</label>
                      <div className='col-lg-11'>
                        <input
                          name='trackingNumber'
                          type='text'
                          className='form-control'
                          placeholder='Tracking Number'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          value={formData.trackingNumber || ''}
                          disabled={!editForm}
                          onKeyPress={(e) => {
                            if (!REGEX.NUMERIC.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                        />
                        {errors && errors.trackingNumber && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.trackingNumber}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row mb-4'>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Date of Shipment:</label>
                      <div className='col-lg-11'>
                        <DateSelector
                          name='dateOfShipment'
                          placeholder='Date of Shipment'
                          className='form-control'
                          selected={formData.dateOfShipment || ''}
                          readOnly={!editForm}
                          onChange={(date) => {
                            setErrors({ ...errors, dateOfShipment: '' })
                            setFormData((values) => ({
                              ...values,
                              dateOfShipment: date
                            }))
                          }}
                          dateFormat={DATE.DATE_FOR_PICKER}
                        />
                        {errors && errors.dateOfShipment && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.dateOfShipment}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Date of Delivery:</label>
                      <div className='col-lg-11'>
                        <DateSelector
                          name='dateOfDelivery'
                          placeholder='Date of Delivery'
                          className='form-control'
                          selected={formData.dateOfDelivery || ''}
                          readOnly={!editForm}
                          onChange={(date) => {
                            setErrors({ ...errors, dateOfDelivery: '' })
                            setFormData((values) => ({
                              ...values,
                              dateOfDelivery: date
                            }))
                          }}
                          dateFormat={DATE.DATE_FOR_PICKER}
                        />
                        {errors && errors.dateOfDelivery && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.dateOfDelivery}</div>)}
                      </div>
                    </div>
                    <div className='col-lg-4 mb-3'>
                      <label className='font-size-xs  font-weight-bold mb-2 form-label'>Signature Confirmation:</label>
                      <div className='col-lg-11'>
                        <input
                          name='signatureConfirmation'
                          type='text'
                          className='form-control'
                          placeholder='Signature Confirmation'
                          onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                          onChange={(e) => handleChange(e)}
                          disabled={!editForm}
                          value={formData.signatureConfirmation || ''}
                        />
                        {errors && errors.signatureConfirmation && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.signatureConfirmation}</div>)}
                      </div>
                    </div>

                    <div className='form-group row mb-4'>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs  font-weight-bold mb-2 form-label'>Signed By:</label>
                        <div className='col-lg-11'>
                          <input
                            name='signedBy'
                            type='text'
                            className='form-control'
                            placeholder='Signed By'
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onChange={(e) => handleChange(e)}
                            value={formData.signedBy || ''}
                            disabled={!editForm}
                            onKeyPress={(e) => {
                              if (!REGEX.TEXT.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                          />
                          {errors && errors.signedBy && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.signedBy}</div>)}
                        </div>
                      </div>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs  font-weight-bold mb-2 form-label'>Exception Notes:</label>
                        <div className='col-lg-11'>
                          <textarea
                            name='exceptionNotes'
                            type='text'
                            className='form-control'
                            placeholder='Exception Notes'
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onChange={(e) => handleChange(e)}
                            value={formData.exceptionNotes || ''}
                            disabled={!editForm}
                          />
                          {errors && errors.exceptionNotes && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.exceptionNotes}</div>)}
                        </div>
                      </div>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs  font-weight-bold mb-2 form-label'>Physical Fullfillment Notes :</label>
                        <div className='col-lg-11'>
                          <textarea
                            name='physicalFulfillmentNotes'
                            type='text'
                            className='form-control'
                            placeholder='Physical Fullfillment Notes '
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onChange={(e) => handleChange(e)}
                            value={formData.physicalFulfillmentNotes || ''}
                            disabled={!editForm}
                          />
                          {errors && errors.physicalFulfillmentNotes && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.physicalFulfillmentNotes}</div>)}
                        </div>
                      </div>
                    </div>
                    <div className='form-group row mb-4'>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs  font-weight-bold mb-2 form-label'>Proof of Delivery:</label>
                        <div className='col-lg-11'>
                          <input
                            name='proofOfDelivery'
                            type='text'
                            className='form-control'
                            placeholder='Proof of Delivery'
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onChange={(e) => handleChange(e)}
                            value={formData.proofOfDelivery || ''}
                            disabled={!editForm}
                          />
                          {errors && errors.proofOfDelivery && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.proofOfDelivery}</div>)}
                        </div>
                      </div>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs  font-weight-bold mb-2 form-label'>Proof of Usage:</label>
                        <div className='col-lg-11'>
                          <input
                            name='proofOfUsage'
                            type='text'
                            className='form-control'
                            placeholder='Proof of Usage'
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onChange={(e) => handleChange(e)}
                            disabled={!editForm}
                            value={formData.proofOfUsage || ''}
                          />
                          {errors && errors.proofOfUsage && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.proofOfUsage}</div>)}
                        </div>
                      </div>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs  font-weight-bold mb-2 form-label'>Location of Usage:</label>
                        <div className='col-lg-11'>
                          <input
                            name='locationOfUsage'
                            type='text'
                            className='form-control'
                            placeholder='Location of Usage'
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onChange={(e) => handleChange(e)}
                            value={formData.locationOfUsage || ''}
                            disabled={!editForm}
                          />
                          {errors && errors.locationOfUsage && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.locationOfUsage}</div>)}
                        </div>
                      </div>
                    </div>
                    <div className='form-group row mb-4'>
                      <div className='col-lg-4 mb-3'>
                        <label className='font-size-xs  font-weight-bold mb-2 form-label'>Digital Services Delivery Notes:</label>
                        <div className='col-lg-11'>
                          <textarea
                            name='digitalServiceDeliveryNotes'
                            type='text'
                            className='form-control'
                            placeholder='Digital Services Delivery Notes'
                            onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                            onChange={(e) => handleChange(e)}
                            value={formData.digitalServiceDeliveryNotes || ''}
                            disabled={!editForm}
                          />
                          {errors && errors.digitalServiceDeliveryNotes && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.digitalServiceDeliveryNotes}</div>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
          }
        </div>
        <div className='form-group row mb-4'>
          <div className='col-lg-6' />
          <div className='col-lg-6'>
            <div className='col-lg-11'>
              {editForm ? (
                <button
                  className='btn btn-primary m-2 fa-pull-right'
                  id='submitBtn'
                  onClick={(event) => {
                    handleSubmit(event)
                  }}
                >
                  {loadingEPCB
                    ? (
                      <span
                        className='spinner-border spinner-border-sm mx-3'
                        role='status'
                        aria-hidden='true'
                      />
                    )
                    : (
                      <>
                        <span>Save and Continue</span>  &nbsp;
                        <span className="bi bi-caret-right-fill mt-2" />
                      </>
                    )}
                </button>
              )
                : (
                  <button
                    className='btn btn-primary m-2 fa-pull-right'
                    onClick={() => {
                      onClickNext(3)
                    }}
                  >
                    <span>Continue</span>  &nbsp;
                    <span className="bi bi-caret-right-fill mt-2" />
                  </button>
                )}
              <button
                className='btn btn-darkRed m-2 fa-pull-right'
                onClick={() => { goBack(1) }}
              >
                <span className="bi bi-caret-left-fill mt-2" />&nbsp;
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { purchaseStore, editPurchaseStore, chargebackStore } = state
  return {
    PurchaseDetails: purchaseStore && purchaseStore.PurchaseDetails ? purchaseStore.PurchaseDetails : [],
    statusGPD: purchaseStore && purchaseStore.statusGPD ? purchaseStore.statusGPD : '',
    messagesGPD: purchaseStore && purchaseStore.messagesGPD ? purchaseStore.messagesGPD : '',
    loadingGPD: purchaseStore && purchaseStore.loadingGPD ? purchaseStore.loadingGPD : false,
    editCustomers: editPurchaseStore && editPurchaseStore.dataEPCB ? editPurchaseStore.dataEPCB : [],
    statusEPCB: editPurchaseStore && editPurchaseStore.statusEPCB ? editPurchaseStore.statusEPCB : '',
    messageEPCB: editPurchaseStore && editPurchaseStore.messageEPCB ? editPurchaseStore.messageEPCB : '',
    loadingEPCB: editPurchaseStore && editPurchaseStore.loadingEPCB ? editPurchaseStore.loadingEPCB : false,
    ChargebackDetails: chargebackStore && chargebackStore.ChargebackDetails ? chargebackStore.ChargebackDetails : {},
  }
}

const mapDispatchToProps = dispatch => ({
  getPurchaseDetailsDispatch: (id, headers) =>
    dispatch(purchaseDetailsActions.getPurchaseDetails(id, headers)),
  clearPurchaseDetailsDispatch: () =>
    dispatch(purchaseDetailsActions.clearPurchaseDetails()),
  editProductDispatch: (id, params, headers) =>
    dispatch(editPurchasesActions.editPurchases(id, params, headers)),
  cleareditProductDispatch: () =>
    dispatch(editPurchasesActions.cleareditPurchases()),
  getCBDetailsDispatch: (id, headers) =>
    dispatch(chargeBackDetailsActions.getChargebackDetails(id, headers)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3)
