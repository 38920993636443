import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../theme/helpers";
import { connect } from "react-redux";
import _ from "lodash";
import {
  listActions,
  getClientSignupActions,
  postClientPackageActions,
} from "../../../store/actions";
import {
  CLIENT_SESSION,
  SESSION,
  STATUS_RESPONSE
} from '../../../utils/constants'
import { getLocalStorage, setLocalStorage } from "../../../utils/helper";
import { warningAlert } from "../../../utils/alerts";
import { packageValidation } from "./validate/validation";

const SelectPackage = (props) => {
  const {
    getListDispatch,
    getList,
    loadingList,
    getClientSignupDispatch,
    loadingAddClientPackage,
    postClientPackageActionsDispatch,
    clearClientPackageActionsDispatch,
    statusAddClientPackage,
    messageAddClientPackage
  } = props;

  const history = useHistory();
  const clientSignupId = getLocalStorage(CLIENT_SESSION.CLIENT_TOKEN);
  const selectedTenure = getLocalStorage(
    CLIENT_SESSION.SELECTED_SUBSCRIPTION_TENURE) !== "undefined"
    && JSON.parse(getLocalStorage(
      CLIENT_SESSION.SELECTED_SUBSCRIPTION_TENURE)
    );
  const [planId, setPlanId] = useState();
  const [errors, setErrors] = useState({});
  const [packageAppList, setPackageAppList] = useState([]);
  const [tenure, setTenure] = useState({
    Basic: 'monthly', Freemium: 'monthly', Premium: 'monthly',
  });
  const subscriptionPackagePlan = getLocalStorage(
    CLIENT_SESSION.SELECTED_SUBSCRIPTION_PACKAGE_PLAN
  );

  const [selectedId, setSelectedId] = useState(subscriptionPackagePlan);
  const [formData, setFormData] = useState({
    appName: "",
    title: "",
    description: "",
    subTitle: "",
    price: "monthly",
    disCount: "",
    features: "",
    limit: "",
  });

  useEffect(() => {
    getListDispatch();
    getClientSignupDispatch(clientSignupId);
  }, []);

  const hasNoDuplicates = (array1, array2) => {
    let valid = true;
    array1 &&
      array1.length > 0 &&
      array1.map((o) => {
        array2 &&
          array2.length > 0 &&
          array2.map((x) => {
            if (x._id && x._id === o._id) {
              valid = false;
            }
          });
      });
    return valid;
  };

  const getSelectedPackId = (selectedId, array) => {
    if (selectedId && array && array.length > 0) {
      const getSelectedTenure = tenure[selectedId.replace(/ +/g, "")]
      let selectedPackId = ''
      _.forEach(array, (x) => {
        if (x.appName === selectedId) {
          let currenrtPackId = _.find(x.plans, (o) => {
            selectedPackId = getSelectedTenure ? o._id : ''
            return o.tenure === getSelectedTenure ? o._id : ''
          })
          return currenrtPackId && currenrtPackId._id
        }
      })
      return selectedPackId
    }
  }


  const handleChoose = (name, _id) => {
    setSelectedId(name);
    const getId = getSelectedPackId(name, packageAppList)
    setLocalStorage(CLIENT_SESSION.SELECTED_SUBSCRIPTION_PACKAGE_PLAN, name)
    setPlanId(getId)
    setErrors({ ...errors, subscriptionErr: "" });
  };

  useEffect(() => {
    if (packageAppList && packageAppList.length > 0) {
      if (hasNoDuplicates(packageAppList, getList)) {
        setPackageAppList((values) => [...values, ...getList]);
      }
    } else {
      setPackageAppList(getList);
    }
  }, [getList]);

  const handleInputChange = (e, packTitle) => {
    e.preventDefault();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setTenure((values) => ({ ...values, [packTitle]: e.target.value }));
    setLocalStorage(CLIENT_SESSION.SELECTED_SUBSCRIPTION_TENURE, JSON.stringify({ ...tenure, [packTitle]: e.target.value }))
  };

  const onErrorConfirm = () => {
    history.push("/client-signup");
  };

  useEffect(() => {
    if (statusAddClientPackage === STATUS_RESPONSE.SUCCESS_MSG) {
      clearClientPackageActionsDispatch();
      history.push("/contact-details");
    }
    else if (messageAddClientPackage === SESSION.EXPIRED_MESSAGE) {
      warningAlert(
        "Error",
        messageAddClientPackage,
        "",
        "Retrieve Token",
        () => {
          onErrorConfirm();
        },
        () => {
          onErrorConfirm();
        }
      );
      clearClientPackageActionsDispatch();
    }
    else if (statusAddClientPackage === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAddClientPackage, "", "Ok");
      clearClientPackageActionsDispatch();
    }
  }, [statusAddClientPackage]);

  const handleSubmit = () => {
    const errorMsg = packageValidation(selectedId, setErrors);
    if (_.isEmpty(errorMsg)) {
      if (planId) {
        const payload = {
          signUpToken: clientSignupId,
          appName: selectedId,
          plan_Id: planId,
        };
        postClientPackageActionsDispatch(payload);
      }
    }
  };

  return (
    <>
      <div
        className="bg-shadow"
      >
        <div className="d-flex justify-content-center ms-10">
          <a href="#" className="mb-5 mt-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              className="h-65px"
              style={{ filter: 'drop-shadow(rgb(0, 0, 0) 4px 2px 4px)' }}
            />
          </a>
        </div>
        {loadingList ? (
          <div className="d-flex justify-content-center py-5">
            <div className="spinner-border text-primary m-5" role="status" />
          </div>
        ) : (
          <div className="row mb-20 mt-10 d-flex justify-content-center gx-0">
            <div className="card shadow-sm w-1000px min-h-500px">
              <div
                className="card-header d-flex justify-content-center"
                style={{ minHeight: "49px" }}
              >
                <h2
                  className="text mb-3 mt-3 fw-bolder"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  Package Details
                </h2>
              </div>
              <div className="card-body">
                <div className="row g-10 mb-5">
                  {packageAppList &&
                    packageAppList.map((packages, i) => (
                      <div
                        className="col-xl-4 col-lg-5 col-sm-6 col-md-6"
                        key={i}
                      >
                        <div className="overflow-hidden position-relative card-rounded">
                          <div className="card card-bordered bg-secondary mb-20">
                            <div className="ribbon-wrapper">
                              <div className="ribbon" />
                            </div>
                            <div className="card-header bg-dark d-flex justify-content-center">
                              <h4 className="mt-10 fw-bolder text-white">
                                {packages && packages.appName
                                  ? _.upperCase(packages.appName)
                                  : "--"}
                              </h4>
                            </div>
                            <div className="card-body">
                              <div className="mb-4 text-center">
                                {selectedId === packages.appName ? (
                                  <i className="bi bi-check2-circle text-success font-xxxl-bold" />
                                ) : null}
                              </div>
                              <div className="row mt-7 me-2">
                                <div className="col-lg-12 d-flex justify-content-start">
                                  <p className="mt-2 fw-bolder">
                                    Select Subscription
                                    Tenure:
                                  </p>
                                </div>
                                <div className="col-lg-12 me-3">
                                  <select
                                    name="price"
                                    className="form-select form-select-solid bg-secondary"
                                    data-control="select"
                                    data-placeholder="Select an option"
                                    data-allow-clear="true"
                                    onChange={(e) => handleInputChange(e, packages.appName)}
                                    value={selectedTenure && selectedTenure[packages.appName] || ""}
                                  >
                                    <option value="monthly">
                                      Monthly{""}(Per Month)
                                    </option>
                                    <option value="quarterly">
                                      Quarterly{""}(3 Months)
                                    </option>
                                    <option value="semiannually">
                                      Semiannually{""}(9
                                      Months)
                                    </option>
                                    <option value="annually">
                                      Annually{""}(12 Months)
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <h5 className="text-dark fs-4 fw-bolder text-center mt-8 mb-2">
                                {packages && packages.subTitle
                                  ? packages.subTitle
                                  : ""}
                              </h5>
                              <h6 className="text-dark fs-6 fw-bold text-center mt-4 mb-2">
                                {packages && packages.description
                                  ? packages.description
                                  : "--"}
                              </h6>
                              <div className="text-center">
                                <span className="mb-2 text-primary">
                                  $
                                </span>
                                <span className="fs-2x fw-bolder text-primary">
                                  {["monthly", "quarterly", "semiannually", "annually"].includes(tenure && tenure[packages.appName])
                                    ? packages && packages.price && packages.price[tenure && tenure[packages.appName]]
                                      ? packages.price[tenure && tenure[packages.appName]] : "0"
                                    : null}
                                </span>
                                {["monthly", "quarterly", "semiannually", "annually"].includes(tenure && tenure[packages.appName]
                                ) ? (
                                  <span className="fs-7 fw-bold opacity-50">
                                    /
                                    <span data-kt-element="period">
                                      {_.startCase(tenure && tenure[packages.appName])}
                                    </span>
                                  </span>
                                ) : null}
                              </div>
                              <div className="w-100 mb-10 mt-8">
                                {packages.features?.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        className="d-flex align-items-center mb-5"
                                        key={index}
                                      >
                                        <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">
                                          {item}
                                        </span>
                                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <rect
                                              opacity="0.3"
                                              x="2"
                                              y="2"
                                              width="20"
                                              height="20"
                                              rx="10"
                                              fill="black"
                                            />
                                            <path
                                              d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                              fill="black"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <div className="d-flex mt-15 mb-10">
                                <div className="col-lg-12 d-flex justify-content-center">
                                  <button
                                    className={`btn btn-sm ${selectedId === packages.appName ? "bg-primary text-white" : "bg-blueDark"}`}
                                    onClick={() => { handleChoose(packages.appName, packages._id); }}
                                  >
                                    {selectedId === packages.appName ? "Selected" : "Select"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {errors && errors.subscriptionErr && (
                    <div className="rr mt-5 text-center bg-light-warning py-3">
                      <style>{".rr{color:black;}"}</style>
                      {errors.subscriptionErr}
                    </div>
                  )}
                  <div className="form-group row mb-4 mt-15">
                    <div className="text-center">
                      <Link to={`/select-service`}
                        className='btn btn-sm m-2 w-120px fw-bolder text-white h-40px'
                        style={{ backgroundColor: '#f56c61' }}>
                        Previous
                      </Link> {''}
                      <button
                        className="btn btn-sm fw-bolder btn-primary m-2 w-120px h-40px"
                        onClick={(event) => {
                          handleSubmit(event);
                        }}
                      >
                        {loadingAddClientPackage ? (
                          <span
                            className="spinner-border spinner-border-sm mx-3"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Save and Continue"
                        )}
                      </button>
                    </div>
                  </div>
                </div >
              </div >
            </div >
          </div >
        )}
      </div >
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    addClientPackageStore,
    getClientSignupStore,
  } = state;
  return {
    getList:
      state && state.listStore && state.listStore.getList
        ? state.listStore.getList
        : [],
    loadingList: state && state.listStore && state.listStore.loading,
    getClientSignup:
      getClientSignupStore && getClientSignupStore.getClientSignup
        ? getClientSignupStore.getClientSignup
        : {},
    statusClientSignup:
      getClientSignupStore && getClientSignupStore.statusClientSignup
        ? getClientSignupStore.statusClientSignup
        : "",
    messageClientSignup:
      getClientSignupStore && getClientSignupStore.messageClientSignup
        ? getClientSignupStore.messageClientSignup
        : "",
    loadingClientSignup:
      getClientSignupStore && getClientSignupStore.loadingClientSignup
        ? getClientSignupStore.loadingClientSignup
        : false,
    loadingAddClientPackage:
      addClientPackageStore && addClientPackageStore.loadingAddClientPackage
        ? addClientPackageStore.loadingAddClientPackage
        : false,
    statusAddClientPackage:
      addClientPackageStore && addClientPackageStore.statusAddClientPackage
        ? addClientPackageStore.statusAddClientPackage
        : "",
    messageAddClientPackage:
      addClientPackageStore && addClientPackageStore.messageAddClientPackage
        ? addClientPackageStore.messageAddClientPackage
        : ""
  };
};

const mapDispatchToProps = (dispatch) => ({
  getListDispatch: (params) => dispatch(listActions.get(params)),
  clearListDispatch: () => dispatch(listActions.clear()),
  getClientSignupDispatch: (id) => dispatch(getClientSignupActions.get(id)),
  getClientSignupActions: () => dispatch(getClientSignupActions.get()),
  postClientPackageActionsDispatch: (data, headers) =>
    dispatch(postClientPackageActions.add(data, headers)),
  clearClientPackageActionsDispatch: () =>
    dispatch(postClientPackageActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPackage);
