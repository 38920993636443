import {
  addDocumentTypes,
  transactionGetDetailsTypes,
  documentGetDetailsTypes,
  customerGetDetailsTypes,
  purchaseGetDetailsTypes,
  merchantCBGetDetailsTypes,
  rebuttalGetDetailsTypes,
  addRebuttalTypes,
  emailActionsTypes,
  editTransactionTypes,
  editCustomerTypes,
  editPurchaseTypes,
  editMerchantTypes,
  docTypeActionsTypes,
  documentDeleteTypes,
  addDownloadTypes,
  addZipTypes,
  addEmailTypes,
  addSendLinkTypes,
  SendLinkActionsTypes,
  merchantEvidenceActionsTypes,
  addMerchantEvidenceTypes,
  getAnalystActionsTypes,
  addEvidenceTypes,
  getLinkMerchantActionsTypes,
  sendCustomMailTypes
} from '../actions'

export const transactionInitialState = {
  list: null
}

export const transactionStoreKey = 'transactionStore'

export const transactionReducer = (state = transactionInitialState, action) => {
  switch (action.type) {
    case transactionGetDetailsTypes.GET_TRANSACTION_DETAILS:
      return { ...state, loadingTGD: true }
    case transactionGetDetailsTypes.TRANSACTION_DETAILS_RESPONSE:
      return {
        ...state,
        TransactionDetails: action.data && action.data.data,
        statusTGD: action.data && action.data.status,
        messagesTGD: action.data && action.data.message,
        loadingTGD: false
      }
    case transactionGetDetailsTypes.CLEAR_TRANSACTION_DETAILS:
      return { ...state, statusTGD: '', messagesTGD: '' }
    default:
      return state
  }
}

export const editTransactionStoreKey = 'editTransactionStore'

export const editTransactionReducer = (state = {}, action) => {
  switch (action.type) {
    case editTransactionTypes.REQUEST:
      return { ...state, loadingETCB: true }
    case editTransactionTypes.RESPONSE:
      return { ...state, dataETCB: action.data && action.data, statusETCB: action.data.status, messageETCB: action.data.message, loadingETCB: false }
    case editTransactionTypes.CLEAR:
      return { ...state, dataETCB: null, messageETCB: '', statusETCB: '' }
    default:
      return state
  }
}

export const customerInitialState = {
  list: null
}

export const customerStoreKey = 'customerStore'

export const customerReducer = (state = customerInitialState, action) => {
  switch (action.type) {
    case customerGetDetailsTypes.GET_CUSTOMER_DETAILS:
      return { ...state, loadingCDG: true }
    case customerGetDetailsTypes.CUSTOMER_DETAILS_RESPONSE:
      return {
        ...state,
        CustomerDetails: action.data && action.data.data,
        statusCDG: action.data && action.data.status,
        messagesCDG: action.data && action.data.message,
        loadingCDG: false
      }
    case customerGetDetailsTypes.CLEAR_CUSTOMER_DETAILS:
      return { ...state, statusCDG: '', messagesCDG: '' }
    default:
      return state
  }
}

export const editCustomerStoreKey = 'editCustomerStore'

export const editCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case editCustomerTypes.REQUEST:
      return { ...state, loadingECCB: true }
    case editCustomerTypes.RESPONSE:
      return { ...state, dataECCB: action.data && action.data, statusECCB: action.data.status, messageECCB: action.data.message, loadingECCB: false }
    case editCustomerTypes.CLEAR:
      return { ...state, dataECCB: null, messageECCB: '', statusECCB: '' }
    default:
      return state
  }
}

export const purchaseInitialState = {
  list: null
}

export const purchaseStoreKey = 'purchaseStore'

export const purchaseReducer = (state = purchaseInitialState, action) => {
  switch (action.type) {
    case purchaseGetDetailsTypes.GET_PURCHASE_DETAILS:
      return { ...state, loadingGPD: true }
    case purchaseGetDetailsTypes.PURCHASE_DETAILS_RESPONSE:
      return {
        ...state,
        PurchaseDetails: action.data && action.data.data,
        statusGPD: action.data && action.data.status,
        messagesGPD: action.data && action.data.message,
        loadingGPD: false
      }
    case purchaseGetDetailsTypes.CLEAR_PURCHASE_DETAILS:
      return { ...state, statusGPD: '', messagesGPD: '' }
    default:
      return state
  }
}

export const editPurchaseStoreKey = 'editPurchaseStore'

export const editPurchaseReducer = (state = {}, action) => {
  switch (action.type) {
    case editPurchaseTypes.REQUEST:
      return { ...state, loadingEPCB: true }
    case editPurchaseTypes.RESPONSE:
      return { ...state, dataEPCB: action.data && action.data, statusEPCB: action.data.status, messageEPCB: action.data.message, loadingEPCB: false }
    case editPurchaseTypes.CLEAR:
      return { ...state, dataEPCB: null, messageEPCB: '', statusEPCB: '' }
    default:
      return state
  }
}

export const merchantCBInitialState = {
  list: null
}

export const merchantCBStoreKey = 'merchantCBStore'

export const merchantCBReducer = (state = merchantCBInitialState, action) => {
  switch (action.type) {
    case merchantCBGetDetailsTypes.GET_MERCHANT_CB_DETAILS:
      return { ...state, loadingGMCBD: true }
    case merchantCBGetDetailsTypes.MERCHANT_CB_DETAILS_RESPONSE:
      return {
        ...state,
        MerchantCBDetails: action.data && action.data.data,
        statusGMCBD: action.data && action.data.status,
        messagesGMCBD: action.data && action.data.message,
        loadingGMCBD: false
      }
    case merchantCBGetDetailsTypes.CLEAR_MERCHANT_CB_DETAILS:
      return { ...state, statusGMCBD: '', messagesGPD: '' }
    default:
      return state
  }
}

export const editMerchantCBStoreKey = 'editMerchantCBStore'

export const editMerchantCBReducer = (state = {}, action) => {
  switch (action.type) {
    case editMerchantTypes.REQUEST:
      return { ...state, loadingEMCB: true }
    case editMerchantTypes.RESPONSE:
      return { ...state, dataEMCB: action.data && action.data, statusEMCB: action.data.status, messageEMCB: action.data.message, loadingEMCB: false }
    case editMerchantTypes.CLEAR:
      return { ...state, dataEMCB: null, messageEMCB: '', statusEMCB: '' }
    default:
      return state
  }
}

export const addDocumentInitialState = {
  list: null
}

export const addDocumentStoreKey = 'addDocumentStore'

export const addDocumentReducer = (state = addDocumentInitialState, action) => {
  switch (action.type) {
    case addDocumentTypes.REQUEST:
      return { ...state, loadingAD: true }
    case addDocumentTypes.RESPONSE:
      return { ...state, dataAD: action.data, statusAD: action.data.status, messageAD: action.data.message, loadingAD: false }
    case addDocumentTypes.CLEAR:
      return { ...state, dataAD: null, messageAD: '', statusAD: '' }
    default:
      return state
  }
}
export const addEvidenceInitialState = {
  list: null
}

export const addEvidenceStoreKey = 'addEvidenceStore'

export const addEvidenceReducer = (state = addEvidenceInitialState, action) => {
  switch (action.type) {
    case addEvidenceTypes.REQUEST:
      return { ...state, loadingAEP: true }
    case addEvidenceTypes.RESPONSE:
      return { ...state, dataAEP: action.data, statusAEP: action.data.status, messageAEP: action.data.message, loadingAEP: false }
    case addEvidenceTypes.CLEAR:
      return { ...state, dataAEP: null, messageAEP: '', statusAEP: '' }
    default:
      return state
  }
}

export const docTypeInitialState = {
  list: null
}

export const docTypeStoreKey = 'docTypeStore'

export const docTypeReducer = (state = docTypeInitialState, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case docTypeActionsTypes.GET_DOC_TYPE:
      return { ...state, loading: true }
    case docTypeActionsTypes.SAVE_DOC_TYPE_RESPONSE:
      return { ...state, getDocType: res.data, loading: false }
    case docTypeActionsTypes.CLEAR_DOC_TYPE:
      return { ...state, getDocType: null }
    default:
      return state
  }
}

export const documentInitialState = {
  list: null
}

export const documentStoreKey = 'documentStore'

export const documentReducer = (state = documentInitialState, action) => {
  switch (action.type) {
    case documentGetDetailsTypes.GET_DOCUMENT_DETAILS:
      return { ...state, loadingDGD: true }
    case documentGetDetailsTypes.DOCUMENT_DETAILS_RESPONSE:
      return {
        ...state,
        DoocumentDetails: action.data && action.data.data,
        statusDGD: action.data && action.data.status,
        messagesDGD: action.data && action.data.message,
        loadingDGD: false
      }
    case documentGetDetailsTypes.CLEAR_DOCUMENT_DETAILS:
      return { ...state, statusDGD: '', messagesDGD: '' }
    default:
      return state
  }
}

export const documentDeleteStoreKey = 'documentDeleteStore'

export const deleteDocumentReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case documentDeleteTypes.DELETE_DOCUMENT:
      return { ...state, loading: true }
    case documentDeleteTypes.SAVE_DELETE_DOCUMENT_RESPONSE:
      return {
        ...state,
        deleteDocument: res.data,
        deleteDocumentStatus: res.status,
        deleteDocumentMessage: res.message,
        loading: false
      }
    case documentDeleteTypes.CLEAR_DELETE_DOCUMENT:
      return { ...state, deleteDocument: null, deleteDocumentStatus: '', deleteDocumentMessage: '' }
    default:
      return state
  }
}

export const addRebuttalInitialState = {
  list: null
}

export const addRebuttalStoreKey = 'addRebuttalStore'

export const addRebuttalReducer = (state = addRebuttalInitialState, action) => {
  switch (action.type) {
    case addRebuttalTypes.REQUEST:
      return { ...state, loadingAR: true }
    case addRebuttalTypes.RESPONSE:
      return { ...state, dataAR: action.data, statusAR: action.data.status, messageAR: action.data.message, loadingAR: false }
    case addRebuttalTypes.CLEAR:
      return { ...state, dataAR: null, messageAR: '', statusAR: '' }
    default:
      return state
  }
}

export const rebuttalInitialState = {
  list: null
}

export const rebuttalStoreKey = 'rebuttalStore'

export const rebuttalReducer = (state = rebuttalInitialState, action) => {
  switch (action.type) {
    case rebuttalGetDetailsTypes.GET_REBUTTAL_DETAILS:
      return { ...state, loadingGRD: true }
    case rebuttalGetDetailsTypes.REBUTTAL_DETAILS_RESPONSE:
      return {
        ...state,
        RebuttalDetails: action.data && action.data.data,
        statusGRD: action.data && action.data.status,
        messagesGRD: action.data && action.data.message,
        loadingGRD: false
      }
    case rebuttalGetDetailsTypes.CLEAR_REBUTTAL_DETAILS:
      return { ...state, statusGRD: '', messagesGRD: '' }
    default:
      return state
  }
}

export const emailInitialState = {
  list: null
}

export const emailStoreKey = 'emailStore'

export const emailReducer = (state = emailInitialState, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case emailActionsTypes.GET_EMAIL:
      return { ...state, loadingAECB: true }
    case emailActionsTypes.SAVE_EMAIL_RESPONSE:
      return { ...state, getEmail: res.data, statusAECB: action.data.status, messageAECB: action.data.message, loadingAECB: false }
    case emailActionsTypes.CLEAR_EMAIL:
      return { ...state, getEmail: null }
    default:
      return state
  }
}

export const addEmailInitialState = {
  list: null
}

export const addEmailStoreKey = 'addEmailStore'

export const addEmailReducer = (state = addEmailInitialState, action) => {
  switch (action.type) {
    case addEmailTypes.REQUEST:
      return { ...state, loadingAECB: true }
    case addEmailTypes.RESPONSE:
      return { ...state, dataAECB: action.data && action.data.data, statusAECB: action.data.status, messageAECB: action.data.message, loadingAECB: false }
    case addEmailTypes.CLEAR:
      return { ...state, dataAECB: null, messageAECB: '', statusAECB: '' }
    default:
      return state
  }
}

export const addDownloadInitialState = {
  list: null
}

export const addDownloadStoreKey = 'addDownloadStore'

export const addDownloadReducer = (state = addDownloadInitialState, action) => {
  switch (action.type) {
    case addDownloadTypes.REQUEST:
      return { ...state, loadingADCB: true }
    case addDownloadTypes.RESPONSE:
      return { ...state, dataADCB: action.data && action.data.data, statusADCB: action.data.status, messageADCB: action.data.message, loadingADCB: false }
    case addDownloadTypes.CLEADCB:
      return { ...state, dataADCB: null, messageADCB: '', statusADCB: '' }
    default:
      return state
  }
}

export const addZipInitialState = {
  list: null
}

export const addZipStoreKey = 'addZipStore'

export const addZipReducer = (state = addZipInitialState, action) => {
  switch (action.type) {
    case addZipTypes.REQUEST:
      return { ...state, loadingAZCB: true }
    case addZipTypes.RESPONSE:
      return { ...state, dataAZCB: action.data && action.data.data, statusAZCB: action.data.status, messageAZCB: action.data.message, loadingAZCB: false }
    case addZipTypes.CLEAR:
      return { ...state, dataAZCB: null, messageAZCB: '', statusAZCB: '' }
    default:
      return state
  }
}

export const addSendLinkInitialState = {
  list: null
}

export const addSendLinkStoreKey = 'addSendLinkStore'

export const addSendLinkReducer = (state = addSendLinkInitialState, action) => {
  switch (action.type) {
    case addSendLinkTypes.REQUEST:
      return { ...state, loadingASL: true }
    case addSendLinkTypes.RESPONSE:
      return { ...state, dataASL: action.data, statusASL: action.data.status, messageASL: action.data.message, loadingASL: false }
    case addSendLinkTypes.CLEAR:
      return { ...state, dataASL: null, messageASL: '', statusASL: '' }
    default:
      return state
  }
}

export const getLinkMerchantInitialState = {
  list: null
}

export const getLinkMerchantStoreKey = 'getLinkMerchantStore'

export const getLinkMerchantReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getLinkMerchantActionsTypes.REQUEST:
      return { ...state, loading: true }
    case getLinkMerchantActionsTypes.RESPONSE:
      return { ...state, getMailSendHistory: res.data, statusGSELM: res.status, messageGSELM: res.message,loading: false }
    case getLinkMerchantActionsTypes.CLEAR:
      return { ...state, getMailSendHistory: null, statusGSELM: '', messageGSELM: '' }
    default:
      return state
  }
}

export const SendLinkInitialState = {
  list: null
}

export const SendLinkStoreKey = 'SendLinkStore'

export const SendLinkReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case SendLinkActionsTypes.REQUEST:
      return { ...state, loading: true }
    case SendLinkActionsTypes.RESPONSE:
      return { ...state, getMailHistory: res.data, loading: false }
    case SendLinkActionsTypes.CLEAR:
      return { ...state, getMailHistory: null }
    default:
      return state
  }
}

export const merchantEvidenceInitialState = {
  list: null
}

export const merchantEvidenceStoreKey = 'merchantEvidenceStore'

export const merchantEvidenceReducer = (state = merchantEvidenceInitialState, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case merchantEvidenceActionsTypes.GET_MERCHANT_EVIDENCE:
      return { ...state, loading: true }
    case merchantEvidenceActionsTypes.SAVE_MERCHANT_EVIDENCE_RESPONSE:
      return { ...state, getMerchantEvidence: res.data, loading: false }
    case merchantEvidenceActionsTypes.CLEAR_MERCHANT_EVIDENCE:
      return { ...state, getMerchantEvidence: null }
    default:
      return state
  }
}

export const addMerchantEvidenceStoreKey = 'addMerchantEvidenceStore'

export const addMerchantEvidenceReducer = (state = {}, action) => {
  switch (action.type) {
    case addMerchantEvidenceTypes.REQUEST:
      return { ...state, loadingAME: true }
    case addMerchantEvidenceTypes.RESPONSE:
      return {
        ...state,
        dataAME: action.data && action.data.data,
        statusAME: action.data.status,
        messageAME: action.data.message,
        loadingAME: false
      }
    case addMerchantEvidenceTypes.CLEAR:
      return {
        ...state,
        dataAME: null,
        messageAME: '',
        statusAME: ''
      }
    default:
      return state
  }
}

export const getAnalystStoreKey = 'getAnalystStore'

export const getAnalystReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getAnalystActionsTypes.REQUEST:
      return { ...state, loadingGA: true }
    case getAnalystActionsTypes.RESPONSE:
      return {
        ...state,
        getAnalyst: res.data,
        statusGA: res.status,
        messageGA: res.message,
        loadingGA: false
      }
    case getAnalystActionsTypes.CLEAR:
      return {
        ...state,
        getAnalyst: null,
        statusGA: '',
        messageGA: ''
      }
    default:
      return state
  }
}

export const sendCustomMailInitialState = {
  list: null
}

export const sendCustomMailStoreKey = 'sendCustomMailStore'

export const sendCustomMailReducer = (state = sendCustomMailInitialState, action) => {
  switch (action.type) {
    case sendCustomMailTypes.REQUEST:
      return { ...state, loadingSCM: true }
    case sendCustomMailTypes.RESPONSE:
      return { ...state, dataSCM: action.data, statusSCM: action.data.status, messageSCM: action.data.message, loadingSCM: false }
    case sendCustomMailTypes.CLEAR:
      return { ...state, dataSCM: null, messageSCM: '', statusSCM: '' }
    default:
      return state
  }
}