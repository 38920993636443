import {
  addUserRegisterTypes
} from '../actions'

export const addUserRegisterStoreKey = 'addUserRegisterStore'

export const addUserRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case addUserRegisterTypes.REQUEST:
      return { ...state, loadingAUR: true }
    case addUserRegisterTypes.RESPONSE:
      return { ...state, dataAUR: action.data, statusAUR: action.data.status, messageAUR: action.data.message, loadingAUR: false }
    case addUserRegisterTypes.CLEAR:
      return { ...state, dataAUR: null, messageAUR: '', statusAUR: '' }
    default:
      return state
  }
}
