import { USER_ERROR, REGEX } from '../../utils/constants'

export default function validate (values, setErrors) {
  const errors = {}
  if (!values.alertDate) {
    errors.alertDate = USER_ERROR.ALERT_DATE
  }
  if (!values.alertType) {
    errors.alertType = USER_ERROR.ALERT_TYPE
  }
  if (values.alertType === 'Notification') {
    if (!values.notificationType) {
      errors.notificationType = USER_ERROR.NOT_TYPE
    }
  }
  if (!values.transactionType) {
    errors.transactionType = USER_ERROR.TRNS_TYPE
  }
  if (values.transactionType === 'Card') {
    if (!values.cardFirstSixDigits) {
      errors.cardFirstSixDigits = USER_ERROR.CARD_SIX
    } else if (!REGEX.NUMERIC_SIX_DIGITS.test(values.cardFirstSixDigits)) {
      errors.cardFirstSixDigits = USER_ERROR.CARD_SIX_INVALID
    }
    if (!values.cardLastFourDigits) {
      errors.cardLastFourDigits = USER_ERROR.CARD_FOUR
    } else if (!REGEX.NUMERIC_FOUR_DIGITS.test(values.cardLastFourDigits)) {
      errors.cardLastFourDigits = USER_ERROR.CARD_FOUR_INVALID
    }
  }
  if (values.transactionType === 'UPI') {
    if (!values.upiTransactionId) {
      errors.upiTransactionId = USER_ERROR.UPI_ID
    }
  }
  if (!values.customerFirstName) {
    errors.customerFirstName = USER_ERROR.CUS_FNAME
  }
  if (!values.customerLastName) {
    errors.customerLastName = USER_ERROR.CUS_LNAME
  }
  if (!values.alertDescriptor) {
    errors.alertDescriptor = USER_ERROR.DES
  }
  if (values.alertDescriptorContact && !REGEX.PHONE.test(values.alertDescriptorContact)) {
    errors.alertDescriptorContact = USER_ERROR.DES_CONTACT_INVALID
  }
  setErrors(errors)
  return errors
}
