import {
  getComponentsActionsTypes,
  addComponentsActionsTypes,
  getComponentsDetailsActionsTypes,
  updateComponentsActionsTypes,
  deleteComponentsActionsTypes
} from '../actions'

export const getComponentsStoreKey = 'getComponentsStore'

export const getComponentsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getComponentsActionsTypes.REQUEST:
      return { ...state, loadingGC: true }
    case getComponentsActionsTypes.RESPONSE:
      return {
        ...state,
        getComponents: res.data,
        count: res.count,
        statusGC: res.status,
        messageGC: res.message,
        loadingGC: false
      }
    case getComponentsActionsTypes.CLEAR:
      return {
        ...state,
        getComponents: null,
        count: 0,
        statusGC: '',
        messageGC: ''
      }
    default:
      return state
  }
}

export const addComponentsStoreKey = 'addComponentsStore'

export const addComponentsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addComponentsActionsTypes.REQUEST:
      return { ...state, loadingAddComp: true }
    case addComponentsActionsTypes.RESPONSE:
      return {
        ...state,
        addComponents: res.data,
        statusAddComp: res.status,
        messageAddComp: res.message,
        loadingAddComp: false
      }
    case addComponentsActionsTypes.CLEAR:
      return {
        ...state,
        addComponents: null,
        statusAddComp: '',
        messageAddComp: ''
      }
    default:
      return state
  }
}

export const getComponentsDetailsStoreKey = 'getComponentsDetailsStore'

export const getComponentsDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getComponentsDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsComp: true }
    case getComponentsDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsComponents: res.data,
        statusGetDetailsComp: res.status,
        messageGetDetailsComp: res.message,
        loadingGetDetailsComp: false
      }
    case getComponentsDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsComponents: null,
        statusGetDetailsComp: '',
        messageGetDetailsComp: ''
      }
    default:
      return state
  }
}

export const updateComponentsStoreKey = 'updateComponentsStore'

export const updateComponentsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updateComponentsActionsTypes.REQUEST:
      return { ...state, loadingUpdateComp: true }
    case updateComponentsActionsTypes.RESPONSE:
      return {
        ...state,
        updateComponents: res.data,
        statusUpdateComp: res.status,
        messageUpdateComp: res.message,
        loadingUpdateComp: false
      }
    case updateComponentsActionsTypes.CLEAR:
      return {
        ...state,
        updateComponents: null,
        statusUpdateComp: '',
        messageUpdateComp: ''
      }
    default:
      return state
  }
}

export const deleteComponentsStoreKey = 'deleteComponentsStore'

export const deleteComponentsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteComponentsActionsTypes.REQUEST:
      return { ...state, loadingDeleteComp: true }
    case deleteComponentsActionsTypes.RESPONSE:
      return {
        ...state,
        deleteComponents: res.data,
        statusDeleteComp: res.status,
        messageDeleteComp: res.message,
        loadingDeleteComp: false
      }
    case deleteComponentsActionsTypes.CLEAR:
      return {
        ...state,
        deleteComponents: null,
        statusDeleteComp: '',
        messageDeleteComp: ''
      }
    default:
      return state
  }
}
