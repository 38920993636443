import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addCrmSettingsActionsTypes,
  addCrmSettingsActions,
  getCrmSettingsDetailsActionsTypes,
  getCrmSettingsDetailsActions,
  updateCrmSettingsActionsTypes,
  updateCrmSettingsActions,
  deleteCrmSettingsActionsTypes,
  deleteCrmSettingsActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addCrmSettings (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.CrmSettings, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addCrmSettingsActions.saveResponse(json.data))
    } else {
      yield put(addCrmSettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addCrmSettingsActionsTypes.RESPONSE, data })
  }
}

function * getCrmSettingsDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.CrmSettings}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(
        getCrmSettingsDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getCrmSettingsDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getCrmSettingsDetailsActionsTypes.RESPONSE, data })
  }
}

function * updateCrmSettings (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.CrmSettings}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(updateCrmSettingsActions.saveResponse(json.data))
    } else {
      yield put(updateCrmSettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updateCrmSettingsActionsTypes.RESPONSE, data })
  }
}

function * deleteCrmSettings (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.CrmSettings}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deleteCrmSettingsActions.savemResponse(json.data))
    } else {
      yield put(deleteCrmSettingsActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteCrmSettingsActionsTypes.RESPONSE, data })
  }
}

export function * fetchCrmSettingsWatcher () {
  yield all([
    yield takeLatest(addCrmSettingsActionsTypes.REQUEST, addCrmSettings),
    yield takeLatest(getCrmSettingsDetailsActionsTypes.REQUEST, getCrmSettingsDetails),
    yield takeLatest(updateCrmSettingsActionsTypes.REQUEST, updateCrmSettings),
    yield takeLatest(deleteCrmSettingsActionsTypes.REQUEST, deleteCrmSettings)
  ])
}
