import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { unsetLocalStorage, headersWithAuth } from '../../../../utils/helper';
import { connect } from 'react-redux'
import { userProfilesActions } from '../../../../store/actions'
import _ from 'lodash'
import userPlaceholder from '../../../../assets/userPlaceholder.png'
import { API_URL } from '../../../../utils/constants'

function HeaderUserMenu(props: any) {
  const {
    getUserProfileDispatch,
    userProfileData
  } = props

  const logout = () => {
    unsetLocalStorage()
    window.location.href = '/';
  };

  useEffect(() => {
    getUserProfileDispatch(null, headersWithAuth)
  }, [])

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            {userProfileData && userProfileData.image ? (
              <img
                src={userProfileData.image ? `${API_URL}/uploads/${userProfileData.image}` : ''}
                alt="your image"
                onError={
                  (e: React.SyntheticEvent<HTMLImageElement, Event>) => (
                    (e.target as HTMLImageElement).src = userPlaceholder
                  )
                }
              />
            ) : null}
          </div>
          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {userProfileData && userProfileData.firstName} &nbsp;
              {userProfileData && userProfileData.lastName}
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                {userProfileData && userProfileData.roleId
                  ? _.startCase(userProfileData.roleId.role)
                  : '--'}
              </span>
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {userProfileData && userProfileData.email}
            </a>
          </div>
        </div>
      </div>
      <div className="separator my-2" />
      <div className="menu-item px-5">
        <Link to='/user-profile' className="menu-link px-5">
          My Profile
        </Link>
      </div>
      <div className="menu-item px-5">
        <Link to='/user-change-password' className="menu-link px-5">
          <span className="menu-text">Change Password</span>
        </Link>
      </div>
      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { userProfileStore } = state
  return {
    loadingGetUP: state && state.userProfileStore && state.userProfileStore.loadingGetUP,
    userProfileData:
      userProfileStore && userProfileStore.userProfileData ? userProfileStore.userProfileData : {}
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  getUserProfileDispatch: (data: any, headers: any) => dispatch(userProfilesActions.getUserProfile(data, headers))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserMenu)

