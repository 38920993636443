import { USER_ERROR, REGEX } from '../../../utils/constants'

export default function validate (values, setErrors) {
  const errors = {}
  if (!values.chargebackAmount) {
    errors.chargebackAmount = USER_ERROR.CB_AMOUNT
  }
  if (!values.chargebackDate) {
    errors.chargebackDate = USER_ERROR.CB_DATE
  }
  if (!values.clientId) {
    errors.clientId = USER_ERROR.CLIENT
  }
  if (!values.companyName) {
    errors.companyName = USER_ERROR.COMPANYNAME
  }
  // if (!values.merchantId) {
  //   errors.merchantId = USER_ERROR.MERCHANT_ID
  // }
  // if (!values.merchantName) {
  //   errors.merchantName = USER_ERROR.MERCHANTNAME
  // }
  // if (!values.mid) {
  //   errors.mid = USER_ERROR.MIDNUMBER_REQUIRED
  // }
  if (values.cardType === 'upiId') {
    if (!values.upiId) {
      errors.upiId = USER_ERROR.UPI
    }
  }
  if (values.country === 'nonUs') {
    if (!values.nonUs) {
      errors.nonUs = USER_ERROR.NON_US
    }
  }
  if (!values.orderId) {
    errors.orderId = USER_ERROR.ORDER_ID
  }
  if (!values.transactionDate) {
    errors.transactionDate = USER_ERROR.TD_DATE
  }
  if (!values.transactionId) {
    errors.transactionId = USER_ERROR.TRANSACTION_NUMBER
  }
  if (!values.serviceType) {
    errors.serviceType = USER_ERROR.SERVICE_TYPE
  }
  if (!values.paymentProcessor) {
    errors.paymentProcessor = USER_ERROR.PAYMENT_PROCESSOR
  }
  setErrors(errors)
  return errors
}
