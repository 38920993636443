import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ChangePasswordActions } from '../../store/actions'
import { toAbsoluteUrl } from '../../theme/helpers'
import { colors } from '../../utils/constants'
import { confirmAlert, warningAlert } from '../../utils/alerts'
import {
  USER_ERROR,
  STATUS_RESPONSE,
  SESSION,
  dynamicError,
  SET_STORAGE
} from '../../utils/constants'
import _ from 'lodash'
import { getLocalStorage, removeLocalStorage, unsetLocalStorage, headersWithAuth, setDynamicPasswordRegex } from '../../utils/helper'

function ChangePassword(props) {
  const {
    changePasswordDispatch,
    changePasswordLoading,
    changePasswordStatus,
    changePasswordMessage,
    clearChangePassword
  } = props

  const [formData, setFormData] = useState({
    oldPassword: '',
    NewPassword: '',
    confirmPassword: ''
  })
  const [errors, setErrors] = useState({
    oldPassword: '',
    NewPassword: '',
    confirmPassword: ''
  })

  const handleSubmit = (configData) => {
    const errors = {}
    if (_.isEmpty(formData.oldPassword)) {
      errors.oldPassword = USER_ERROR.OLD_PASSWORD_REQUIRED
    }
    const getPasswordRegex = setDynamicPasswordRegex(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
    if (_.isEmpty(formData.NewPassword)) {
      errors.NewPassword = USER_ERROR.NEW_PASSWORD_REQUIRED
    } else if (formData.NewPassword && !getPasswordRegex.test(formData.NewPassword)) {
      errors.NewPassword = dynamicError(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
    }
    if (_.isEmpty(formData.confirmPassword)) {
      errors.confirmPassword = USER_ERROR.CONFIRM_PASSWORD_REQUIRED
    }
    if (formData.NewPassword !== formData.confirmPassword) {
      errors.confirmPassword = USER_ERROR.PASSWORD_SAME
    }
    setErrors(errors)
    if (_.isEmpty(errors)) {
      const changeToken = getLocalStorage(SESSION.TOKEN)
      const data = {
        oldPassword: formData.oldPassword,
        NewPassword: formData.NewPassword,
        token: changeToken
      }
      changePasswordDispatch(data, headersWithAuth)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    formData[name] = value
    setFormData(formData)
    setErrors({ ...errors, [name]: '' })
  }

  const onConfirm = () => {
    window.location.href = "/perform-summary";
    removeLocalStorage(SET_STORAGE.IS_FIRST_LOGIN)
  }

  useEffect(() => {
    if (changePasswordStatus === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'success',
        changePasswordMessage,
        'success',
        'Ok',
        () => { onConfirm() },
        () => { onConfirm() },
      )
      clearChangePassword()
    } else if (changePasswordStatus === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'Error',
        changePasswordMessage,
        '',
        'Ok'
      )
      clearChangePassword()
    }
  }, [changePasswordStatus])

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/illustrations/sketchy-1/14.png"
          )})`,
          backgroundColor: colors.oxfordBlue,
        }}
      >
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="#" className="mb-12">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              className="h-65px"
            />
          </a>
          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>Change Password</h1>
            </div>
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                    Old Password
                  </label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                type='password'
                placeholder='Old Password'
                autoComplete='off'
                onChange={(e) => handleChange(e)}
                name='oldPassword'
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formData.oldPassword && errors.oldPassword
                  },
                  {
                    'is-valid': formData.oldPassword && !errors.oldPassword
                  }
                )}
              />
              {errors.oldPassword && (
                <div className='fv-plugins-message-container text-danger'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.oldPassword}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                    New Password
                  </label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                type='password'
                placeholder='New Password'
                autoComplete='off'
                onChange={(e) => handleChange(e)}
                name='NewPassword'
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formData.NewPassword && errors.NewPassword
                  },
                  {
                    'is-valid': formData.NewPassword && !errors.NewPassword
                  }
                )}
              />
              {errors.NewPassword && (
                <div className='fv-plugins-message-container text-danger'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.NewPassword}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                Confirm Password
              </label>
              <input
                placeholder='Confirm Password'
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formData.confirmPassword && errors.confirmPassword
                  },
                  {
                    'is-valid': formData.confirmPassword && !errors.confirmPassword
                  }
                )}
                onChange={(e) => handleChange(e)}
                name='confirmPassword'
                type='password'
                autoComplete='off'
              />
              {errors.confirmPassword && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert text-danger'>{errors.confirmPassword}</span>
                </div>
              )}
            </div>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-blue w-100 mb-5'
                onClick={(e) => handleSubmit(e)}
                disabled={changePasswordLoading}
              >
                {!changePasswordLoading && (
                  <span className='indicator-label'>Submit</span>
                )}
                {changePasswordLoading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                )}
              </button>
              <Link
                to='/forgotPassword'
                className='link-primary fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
                onClick={() => { unsetLocalStorage() }}
              >
                Forgot Password ?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { changePasswordStore } = state
  return {
    changePasswordData:
      changePasswordStore && changePasswordStore.changePassword
        ? changePasswordStore.changePassword
        : {},
    changePasswordStatus:
      changePasswordStore && changePasswordStore.changePasswordStatus
        ? changePasswordStore.changePasswordStatus
        : '',
    changePasswordMessage:
      changePasswordStore && changePasswordStore.changePasswordMessage
        ? changePasswordStore.changePasswordMessage
        : '',
    changePasswordLoading:
      changePasswordStore && changePasswordStore.loading
        ? changePasswordStore.loading
        : false
  }
}

const mapDispatchToProps = (dispatch) => ({
  changePasswordDispatch: (data, headers) =>
    dispatch(ChangePasswordActions.changePassword(data, headers)),
  clearChangePassword: () => dispatch(ChangePasswordActions.clearChangePassword())
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
