import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addGatewaySettingsActionsTypes,
  addGatewaySettingsActions,
  getGatewaySettingsDetailsActionsTypes,
  getGatewaySettingsDetailsActions,
  updateGatewaySettingsActionsTypes,
  updateGatewaySettingsActions,
  deleteGatewaySettingsActionsTypes,
  deleteGatewaySettingsActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addGatewaySettings (action) {
  try {
    const { payload, headers } = action
    const json = yield axiosInstance.post(serviceList.GatewaySettings, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addGatewaySettingsActions.saveResponse(json.data))
    } else {
      yield put(addGatewaySettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addGatewaySettingsActionsTypes.RESPONSE, data })
  }
}

function * getGatewaySettingsDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.GatewaySettings}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(
        getGatewaySettingsDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getGatewaySettingsDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getGatewaySettingsDetailsActionsTypes.RESPONSE, data })
  }
}

function * updateGatewaySettings (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.GatewaySettings}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(updateGatewaySettingsActions.saveResponse(json.data))
    } else {
      yield put(updateGatewaySettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updateGatewaySettingsActionsTypes.RESPONSE, data })
  }
}

function * deleteGatewaySettings (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.GatewaySettings}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deleteGatewaySettingsActions.savemResponse(json.data))
    } else {
      yield put(deleteGatewaySettingsActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteGatewaySettingsActionsTypes.RESPONSE, data })
  }
}

export function * fetchGatewaySettingsWatcher () {
  yield all([
    yield takeLatest(addGatewaySettingsActionsTypes.REQUEST, addGatewaySettings),
    yield takeLatest(getGatewaySettingsDetailsActionsTypes.REQUEST, getGatewaySettingsDetails),
    yield takeLatest(updateGatewaySettingsActionsTypes.REQUEST, updateGatewaySettings),
    yield takeLatest(deleteGatewaySettingsActionsTypes.REQUEST, deleteGatewaySettings)
  ])
}
