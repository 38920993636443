import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES, SESSION, HEADER, API } from '../../utils/constants'
import { getLocalStorage } from '../../utils/helper'
import axiosInstance from '../../services'
import {
  addUploadsMerchantTypes,
  addUploadsMerchantActions
} from '../actions'
import serviceList from '../../services/serviceList'

const isAuthorized = getLocalStorage(SESSION.TOKEN);

export const headersWithAuth = {
  'Content-Type': 'multipart/form-data',
  [`${HEADER.TOKEN}`]: `Bearer ${getLocalStorage(SESSION.TOKEN)}`
}

export const headerWithApiKey = {
  'Content-Type': 'multipart/form-data',
  'api-key': getLocalStorage(API.API_KEY)
}

function* addMerchantUpload(action) {
  const { payload, url } = action
  const uploadUrl = url || serviceList.uploadMerchant
  try {
    const json = yield axiosInstance.post(uploadUrl, payload, { headers: isAuthorized ? headersWithAuth : headerWithApiKey })
    if (json.status === 200) {
      yield put(addUploadsMerchantActions.saveaddUploadsMerchantResponse(json.data))
    } else {
      yield put(addUploadsMerchantActions.clearaddUploadsMerchant([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addUploadsMerchantTypes.RESPONSE, data })
  }
}

export function* fetchUploadsMerchantWatcher() {
  yield all([
    yield takeLatest(addUploadsMerchantTypes.REQUEST, addMerchantUpload)
  ])
}
