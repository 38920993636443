import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextSunEditor from "../../../../theme/layout/components/SunEditor";
import {
  STATUS_RESPONSE,
  SLICE_CHARACTERS,
  SWEET_ALERT_MSG,
  DROPZONE_MESSAGES,
  FILE_CB_DOCUMENT,
  CB_URL,
  SESSION,
  CB_DOWNLOAD_URL,
  API
} from "../../../../utils/constants";
import {
  addDocumentActions,
  documentDetailsActions,
  docTypeActions,
  addDownloadActions,
  addZipActions,
  deleteDocumentActions,
  addSendLinkActions,
  chargeBackDetailsActions,
  sendCustomMailActions,
} from "../../../../store/actions";
import { TooltipOnHover } from "../../../../theme/layout/components/tooltipOnHover/TooltipOnHover";
import { KTSVG } from "../../../../theme/helpers";
import { useLocation } from "react-router-dom";
import {
  confirmAlert,
  warningAlert,
  confirmationAlert,
} from "../../../../utils/alerts";
import color from "../../../../utils/colors";
import { getLocalStorage, headersWithAuth } from '../../../../utils/helper'
import ReactSelect from "../../../../theme/layout/components/ReactSelect";
import { validateStep5, customMailValidation } from "./validation";
import { Modal } from "../../../../theme/layout/components/modal";

function Step5(props) {
  const {
    onClickNext,
    goBack,
    addDocumentDispatch,
    clearaddDocumentDispatch,
    loadingAD,
    statusAD,
    messageAD,
    getDocumentDetailsDispatch,
    DoocumentDetails,
    getDocTypeDispatch,
    getDocType,
    loading,
    clearaddDownloadDispatch,
    dataADCB,
    statusADCB,
    addZipDispatch,
    clearaddZipDispatch,
    statusAZCB,
    dataAZCB,
    loadingAZCB,
    deleteDocumentDispatch,
    deleteDocumentStatus,
    deleteDocumentMessage,
    clearDeleteDocumentDispatch,
    sendLinkDispatch,
    clearsendLinkDispatch,
    statusASL,
    messageASL,
    loadingASL,
    getCBDetailsDispatch,
    ChargebackDetails,
    loadingSCM,
    statusSCM,
    messageSCM,
    sendCustomMailDispatch,
    clearsendCustomMailDispatch,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }

  const url = useLocation().pathname;
  const fields = url && url.split("/");
  const id = fields && fields[3];
  const [show, setShow] = useState(false);
  const [showMail, setShowMail] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState();
  const [othersType, setOthersType] = useState("");
  const [formData, setFormData] = useState({
    upload: "",
    type: "",
    fileType: "document",
    file_to_upload: "",
  });
  const [mailsData, setMailsData] = useState({
    chargeBackId: id,
    subject: "",
    mailContent: "",
  });
  const [documentData] = useState({
    chargeBackId: id,
  });

  const [zipData] = useState({
    chargeBackId: id,
  });

  const [mailData] = useState({
    chargeBackId: id,
  });

  const hiddenFileInput = useRef(null);
  const [typeOption, setTypeOption] = useState();
  const [selectedTypeOption, setSelectedTypeOption] = useState("");

  useEffect(() => {
    if (id) {
      getDocTypeDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  }, [id]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const getDefaultOptions = (data) => {
    if (!_.isEmpty(data)) {
      const test = () => {
        const tempArr = [{ label: "Others...", value: "others" }];
        Object.keys(data).map((key) => {
          if (
            data[key].processor === "Yes" ||
            data[key].gateway === "Yes" ||
            data[key].merchantWebsite === "Yes" ||
            data[key].crm === "Yes"
          ) {
            tempArr.push({ label: _.startCase(key), value: key });
          }
        });
        return tempArr;
      };
      const testValue = test();
      return testValue && testValue.length > 0 ? testValue : null;
    }
  };

  useEffect(() => {
    const data = getDefaultOptions(getDocType, "type");
    setTypeOption(data);
  }, [getDocType]);

  const handleChangeDocType = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedTypeOption(selectedOption);
      setFormData((values) => ({
        ...values,
        type: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, type: "", othersType: "" }));
    } else {
      setSelectedTypeOption();
      setOthersType("");
      setFormData((values) => ({ ...values, type: "" }));
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    let isValidFileFormat = true;
    const maxFileSize = 5;
    const files = e.target.files[0];
    const fileType = files && files.type;
    const uploadedFileSize = files && files.size;
    isValidFileFormat = _.includes(FILE_CB_DOCUMENT, fileType);
    const fileSize = Number(maxFileSize) * 1024 * 1024;
    if (isValidFileFormat) {
      if (uploadedFileSize < fileSize) {
        setFormData((values) => ({
          ...values,
          file_to_upload: files,
        }));
        setErrors((values) => ({ ...values, file_to_upload: "" }));
        setFileName(files && files.name);
      } else {
        setErrors({
          ...errors,
          [name]: `File size must below ${fileSize / 1048576
            } MB!. Your file size is : ${Math.round(uploadedFileSize / (1024*1024))}MB`,
        });
      }
    } else {
      setErrors({ ...errors, [name]: DROPZONE_MESSAGES.PDF_INVALID });
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click(event);
  };

  const handleChange = (e) => {
    e.persist();
    e.preventDefault();
    if (e.target.name === "othersType") {
      setOthersType(e.target.value);
    } else {
      setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
      setErrors({ ...errors, [e.target.name]: "" });
    }
  };

  const handleMailChange = (e) => {
    e.persist();
    setMailsData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
    e.preventDefault();
  };

  const handleMailsSubmit = () => {
    const errorMsg = customMailValidation(mailsData, setErrors);
    if (_.isEmpty(errorMsg)) {
      sendCustomMailDispatch(mailsData, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  };

  const onMailsConfirm = () => {
    setShowMail(false);
    setMailsData({
      subject: "",
      mailContent: "",
    });
    getDocTypeDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  useEffect(() => {
    if (statusSCM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageSCM,
        "success",
        "Ok",
        () => {
          onMailsConfirm();
        },
        () => {
          onMailsConfirm();
        }
      );
      clearsendCustomMailDispatch();
    } else if (statusSCM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageSCM, "", "Ok");
      clearsendCustomMailDispatch();
    }
  }, [statusSCM]);

  const handleSubmit = () => {
    const errorMsg = validateStep5(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      const data = new FormData();
      data.append("type", othersType ? othersType : formData.type);
      data.append("file_to_upload", formData.file_to_upload);
      data.append("fileType", "document");
      data.append("chargebackId", id);
      addDocumentDispatch(data, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  };

  const handleZip = () => {
    addZipDispatch(zipData, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  const handleSendLink = () => {
    sendLinkDispatch(mailData, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  const onMailConfirm = () => {
    clearsendLinkDispatch();
  };

  useEffect(() => {
    if (statusASL === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageASL,
        "success",
        "Ok",
        () => {
          onMailConfirm();
        },
        () => {
          onMailConfirm();
        }
      );
      clearsendLinkDispatch();
    } else if (statusASL === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageASL, "", "Ok");
    }
    clearsendLinkDispatch();
  }, [statusASL, messageASL]);

  const downloadFiles = (uri) => {
    const link = document.createElement("a");
    const url = window.URL || window.webkitURL;
    const revokeUrlAfterSec = 1000;
    link.href = uri;
    link.download = true;
    link.target = "_blank";
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => url.revokeObjectURL(link.href), revokeUrlAfterSec);
  };

  const downloadDocumentFiles = (obj) => {
    if (!_.isEmpty(obj)) {
      const downloadUrl = `${CB_DOWNLOAD_URL}/${obj.path}`;
      downloadFiles(downloadUrl);
    }
  };

  useEffect(() => {
    if (statusADCB === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataADCB) {
        const downloadUrl = `${CB_URL}${dataADCB}`;
        downloadFiles(downloadUrl);
      }
    }
    clearaddDownloadDispatch();
  }, [statusADCB]);

  useEffect(() => {
    if (statusAZCB === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataAZCB) {
        const downloadUrl = `${CB_URL}${dataAZCB}`;
        downloadFiles(downloadUrl);
      }
      clearaddZipDispatch();
    }
  }, [statusAZCB]);

  useEffect(() => {
    if (!show) {
      setFormData(() => ({
        upload: "",
        type: "",
        fileType: "document",
        file_to_upload: null,
      }));
      setSelectedTypeOption();
    }
  }, [show]);

  const handleNextClick = () => {
    if (DoocumentDetails && DoocumentDetails.length) {
      onClickNext(5);
    } else {
      setErrors(() => ({
        upload: "Please select atleast one document to continue!",
      }));
    }
  };

  const onConfirm = () => {
    setShow(false);
  };

  useEffect(() => {
    if (statusAD === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAD,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearaddDocumentDispatch();
      getDocumentDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    } else if (statusAD === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAD, "", "Ok");
    }
    clearaddDocumentDispatch();
  }, [statusAD, messageAD]);

  function onConfirmDelete(deleteId) {
    deleteDocumentDispatch(deleteId, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    setTimeout(() => {
      getDocumentDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }, 500);
  }

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_DOCUMENT,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete(id);
      },
      () => { }
    );
  };

  useEffect(() => {
    if (deleteDocumentStatus === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        deleteDocumentMessage,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearDeleteDocumentDispatch();
    } else if (deleteDocumentStatus === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", deleteDocumentMessage, "", "Ok");
    }
    clearDeleteDocumentDispatch();
  }, [deleteDocumentStatus]);

  useEffect(() => {
    if (id) {
      getCBDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
      getDocumentDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  }, [id]);

  return (
    <>
      {showMail ? (
        <Modal
          showModal={showMail}
          modalWidth={1000}
          style={{ height: "inherit" }}
        >
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">Custom Mail</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setShowMail(false);
                      setMailsData(() => ({
                        subject: "",
                        mailContent: "",
                      }));
                      setErrors(null)
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="scroll h-600px">
                  <div className="card-body p-10 bg-skyBlue">
                    <div className="row mt-2 ms-10">
                      <div className="col-lg-6">
                        <label className="required fw-bold fs-6 mb-2">
                          Subject
                        </label>
                        <textarea
                          name="subject"
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                          onChange={(e) => handleMailChange(e)}
                          value={mailsData.subject || ""}
                          autoComplete="off"
                        />
                        {errors && errors.subject && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red}"}</style>
                            {errors.subject}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mt-5 ms-10">
                      <div className="col-lg-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Mail Content
                        </label>
                        <TextSunEditor
                          setData={setMailsData}
                          contentData={mailsData.mailContent}
                          name="mailContent"
                          minHeight={"300px"}
                        />
                        {errors && errors.mailContent && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red}"}</style>
                            {errors.mailContent}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mt-4">
                      <div className="col-lg-5" />
                      <div className="col-lg-7">
                        <div className="col-lg-12">
                          <button
                            className="btn btn-blue mt-7 fa-pull-right"
                            onClick={(event) => {
                              handleMailsSubmit(event);
                            }}
                          >
                            {loadingSCM ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="container-fixed">
        <div className="card-header bg-skyBlue py-10">
          <div className="card-body m-8">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="me-2 mt-3">
                    <p className="text-dark fw-bolder fs-3">
                      DISPUTE DOCUMENTS
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="col-lg-12  d-flex justify-content-end">
                    <button
                      style={{ backgroundColor: "#705d87", color: "#fff" }}
                      className="btn rounded-0 m-1"
                      onClick={() => {
                        handleSendLink();
                      }}
                      disabled={['WON', 'LOST', 'NOT_FAUGHT'].includes(ChargebackDetails.chargeBackStatus)}
                    >
                      {loadingASL ? (
                        <span
                          className="spinner-border spinner-border-sm mx-3"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <TooltipOnHover
                          string="Send Mail Regarding Missing documents to Merchant"
                          sliceUpTo={SLICE_CHARACTERS.UP_TO_9}
                        />
                      )}{" "}
                      &nbsp;
                      <i className="bi bi-envelope text-white" />
                    </button>
                    <button
                      className="btn rounded-0 m-1 btn-light-primary"
                      onClick={() => {
                        setShowMail(true);
                      }}
                      disabled={['WON', 'LOST', 'NOT_FAUGHT'].includes(ChargebackDetails.chargeBackStatus)}
                    >
                      Custom Mail &nbsp;
                      <i className="bi bi-envelope-open-fill mb-1" />
                    </button>
                    <Link
                      to={`/chargeback-management/update-chargeback/${id}/mail-history`}
                      className="btn rounded-0 m-1 btn-light-dark"
                      disabled={['WON', 'LOST', 'NOT_FAUGHT'].includes(ChargebackDetails.chargeBackStatus)}
                    >
                      Mail History &nbsp;
                      <i className="bi bi-clock-history mb-1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-group mb-4 mt-10">
              <button
                style={{ backgroundColor: "#fd7e14", color: "#fff" }}
                className="btn rounded-0 m-1 d-flex justify-content-center col-lg-12 col-md-12 col-sm-12 rounded-0 h-40px mb-4"
              >
                <span />
                Documents
              </button>
              <div className="d-flex justify-content-center">
                <div>
                  <input type="file" className="d-none" multiple={false} />
                  <button
                    style={{ border: "1px solid", width: "500px" }}
                    className="btn btn-dark rounded-0 m-1"
                    data-toggle="modal"
                    data-target="#searchModal"
                    name="upload"
                    value={formData.upload || ""}
                    disabled={['WON', 'LOST', 'NOT_FAUGHT'].includes(ChargebackDetails.chargeBackStatus)}
                    onClick={() => {
                      setShow(true);
                      setEditForm(true);
                    }}
                  >
                    Upload &nbsp;  <i className="bi bi-filetype-pdf" />
                    {/* <i className="bi bi-upload" />  */}
                  </button>
                  <div className="rr mt-1">
                      <style>{".rr{color:green};"}</style>
                      The file must be  &lt;5MB in size.
                    </div>
                  {errors && errors.upload && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red};"}</style>
                      {errors.upload}
                    </div>
                  )}
                </div>
                <Modal showModal={show} modalWidth={650}>
                  <div className="" id="uploadModal">
                    <div>
                      <div className="modal-content">
                        <div className="modal-header">
                          <h2 className="me-8">Add Document</h2>
                          <button
                            type="button"
                            className="btn btn-lg btn-icon btn-active-light-primary close"
                            data-dismiss="modal"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            {/* eslint-disable */}
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon-1"
                            />
                            {/* eslint-disable */}
                          </button>
                        </div>
                        <div className="modal-body">
                          <form className="container-fixed">
                            <div className="card-header bg-lightBlue">
                              <div className="card-body p-5">
                                <div className="form-group row mb-4">
                                  <div className="mb-3">
                                    <div className="row">
                                      <div className="col-lg-4 mt-3">
                                        <label className="font-size-xs font-weight-bold mb-3  form-label">
                                          Document Type :
                                        </label>
                                      </div>
                                      <div className="col-lg-8">
                                        <ReactSelect
                                          isClearable
                                          styles={customStyles}
                                          isMulti={false}
                                          name="type"
                                          className="basic-single"
                                          classNamePrefix="select"
                                          handleChangeReactSelect={
                                            handleChangeDocType
                                          }
                                          options={typeOption}
                                          value={selectedTypeOption}
                                        />
                                        {errors && errors.type && (
                                          <div className="rr mt-1">
                                            <style>{".rr{color:red}"}</style>
                                            {errors.type}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {formData.type === "others" ? (
                                    <div className="mb-3">
                                      <div className="row">
                                        <div className="col-lg-4 mt-3">
                                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                                            Other Document Type:
                                          </label>
                                        </div>
                                        <div className="col-lg-8">
                                          <input
                                            name="othersType"
                                            type="text"
                                            className="form-control"
                                            placeholder="Other Document Type"
                                            autoComplete="off"
                                            maxLength={42}
                                            onChange={(e) => handleChange(e)}
                                            value={othersType || ""}
                                          />
                                          {errors && errors.type && (
                                            <div className="rr mt-1">
                                              <style>{".rr{color:red}"}</style>
                                              {errors.type}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="mb-3">
                                    <div className="row">
                                      <div className="col-lg-4 mt-3">
                                        <label className="font-size-xs font-weight-bold mb-3  form-label">
                                          Upload Document :
                                        </label>
                                      </div>
                                      <div className="col-lg-8">
                                        <input
                                          type="file"
                                          className="d-none"
                                          name="file_to_upload"
                                          id="file_to_upload"
                                          multiple={false}
                                          ref={hiddenFileInput}
                                          onChange={handleFileChange}
                                        />
                                        <button
                                          type="button"
                                          style={{
                                            border: "1px solid",
                                            width: "350px",
                                          }}
                                          className="btn btn-light-primary rounded-0 m-1"
                                          onClick={handleClick}
                                        >
                                          <i className="bi bi-upload" /> &nbsp;
                                          Upload Document
                                        </button>
                                        {errors && errors.file_to_upload && (
                                          <div className="rr mt-1">
                                            <style>{".rr{color:red}"}</style>
                                            {errors.file_to_upload}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-4" />
                                    <div className="col-md-8 d-flex justify-content-center">
                                      {fileName && fileName}
                                    </div>
                                  </div>
                                  <div className="form-group row mb-2 mt-2">
                                    <div className="col-lg-8" />
                                    <div className="col-lg-4">
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-green m-1 fa-pull-right"
                                          id="submitBtn"
                                          onClick={(event) => {
                                            handleSubmit(event);
                                          }}
                                        >
                                          {loadingAD ? (
                                            <span
                                              className="spinner-border spinner-border-sm mx-3"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            "Save"
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            {!loading ?
              (DoocumentDetails && DoocumentDetails.length > 0 && (
                <div className="col-lg-12 mb-5 mt-10 h-auto">
                  <div className="card border-primary mb-3">
                    <div className="card-body d-flex">
                      {DoocumentDetails &&
                        DoocumentDetails.map((item, i) => (
                          <div className="rounded p-5" key={i}>
                            {_.startCase(item.type)}&nbsp; &nbsp;
                            <button
                              type="button"
                              className="btn btn-icon btn-icon-danger btn-sm w-10px h-10px"
                              onClick={() => onDeleteItem(item._id)}
                            >
                              {/* eslint-disable */}
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon-1"
                              />
                              {/* eslint-disable */}
                            </button>
                            <div className="d-flex">
                              {item.file.map((obj, index) => (
                                <div
                                  className="border border-dashed border-gray-300 rounded p-2 text-center"
                                  style={{ width: "100px", cursor: "pointer" }}
                                  key={index}
                                >
                                  <i
                                    className="bi bi-file-earmark-pdf-fill"
                                    style={{
                                      color: "dodgerblue",
                                      width: "50px",
                                      height: "50px",
                                      fontSize: "3rem",
                                    }}
                                    onClick={() => {
                                      downloadDocumentFiles(obj);
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )) :
              (
                <tr className='d-flex justify-content-center'>
                  <td colSpan="100%" className="text-center">
                    <div class="preloader-1">
                      <div className="loader">Loading...</div>
                      <span class="line line-1"></span>
                      <span class="line line-2"></span>
                      <span class="line line-3"></span>
                      <span class="line line-4"></span>
                      <span class="line line-5"></span>
                      <span class="line line-6"></span>
                      <span class="line line-7"></span>
                      <span class="line line-8"></span>
                      <span class="line line-9"></span>
                    </div>
                  </td>
                </tr>
              )}
            <div className="row form-group  mb-4 mt-10">
              <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                {/* <div className="d-flex flex-aligns-center pe-10 pe-lg-20">
                  <i
                    className="bi bi-file-arrow-down-fill"
                    style={{
                      color: "dodgerblue",
                      width: "50px",
                      height: "50px",
                      fontSize: "3rem",
                    }}
                  />
                  <div className="ms-1 fw-bold">
                    <button
                      className="btn btn-link py-0 fs-6 text-hover-primary fw-bolder"
                      onClick={(event) => {
                        handleDownload(event);
                      }}
                    >
                      {loadingADCB ? (
                        <span
                          className="spinner-border spinner-border-sm mx-3"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Download All"
                      )}
                    </button>
                  </div>
                </div> */}
                <div className="d-flex flex-aligns-center pe-10 pe-lg-20">
                  <i
                    className="bi bi-file-earmark-zip-fill icon-primary"
                    style={{
                      color: "dodgerblue",
                      width: "50px",
                      height: "50px",
                      fontSize: "3rem",
                    }}
                  />
                  <div className="ms-1 fw-bold">
                    <button
                      className="btn btn-link py-0 fs-6 text-hover-primary fw-bolder"
                      onClick={(event) => {
                        handleZip(event);
                      }}
                    >
                      {loadingAZCB ? (
                        <span
                          className="spinner-border spinner-border-sm mx-3"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Download All"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-6" />
              <div className="col-lg-6">
                <div className="col-lg-11">
                  {editForm ? (
                    <button
                      className="btn btn-primary m-2 fa-pull-right"
                      id="submitBtn"
                      onClick={() => {
                        handleNextClick();
                      }}
                    >
                      <>
                        <span>Save and Continue</span> &nbsp;
                        <span className="bi bi-caret-right-fill mt-2" />
                      </>
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary m-2 fa-pull-right"
                      onClick={() => {
                        onClickNext(5);
                      }}
                    >
                      <span>Continue</span> &nbsp;
                      <span className="bi bi-caret-right-fill mt-2" />
                    </button>
                  )}
                  <button
                    className="btn btn-darkRed m-2 fa-pull-right"
                    onClick={() => {
                      goBack(3);
                    }}
                  >
                    <span className="bi bi-caret-left-fill mt-2" />
                    &nbsp; Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { chargebackStore } = state;
  return {
    loadingAD:
      state && state.addDocumentStore && state.addDocumentStore.loadingAD,
    statusAD:
      state && state.addDocumentStore && state.addDocumentStore.statusAD,
    messageAD:
      state && state.addDocumentStore && state.addDocumentStore.messageAD,
    dataAD: state && state.addDocumentStore && state.addDocumentStore.dataAD,
    loadingADCB:
      state && state.addDownloadStore && state.addDownloadStore.loadingADCB,
    statusADCB:
      state && state.addDownloadStore && state.addDownloadStore.statusADCB,
    messageADCB:
      state && state.addDownloadStore && state.addDownloadStore.messageADCB,
    dataADCB:
      state && state.addDownloadStore && state.addDownloadStore.dataADCB,
    loadingAZCB: state && state.addZipStore && state.addZipStore.loadingAZCB,
    statusAZCB: state && state.addZipStore && state.addZipStore.statusAZCB,
    messageAZCB: state && state.addZipStore && state.addZipStore.messageAZCB,
    dataAZCB: state && state.addZipStore && state.addZipStore.dataAZCB,
    DoocumentDetails:
      state && state.documentStore && state.documentStore.DoocumentDetails,
    loadingDGD: state && state.documentStore && state.documentStore.loadingDGD,
    getDocType: state && state.docTypeStore && state.docTypeStore.getDocType,
    loading: state && state.docTypeStore && state.docTypeStore.loading,
    deleteDocument:
      state &&
      state.documentDeleteStore &&
      state.documentDeleteStore.deleteDocument,
    deleteDocumentStatus:
      state &&
      state.documentDeleteStore &&
      state.documentDeleteStore.deleteDocumentStatus,
    deleteDocumentMessage:
      state &&
      state.documentDeleteStore &&
      state.documentDeleteStore.deleteDocumentMessage,
    deleteDocumentLoading:
      state && state.documentDeleteStore && state.documentDeleteStore.loading,
    loadingASL:
      state && state.addSendLinkStore && state.addSendLinkStore.loadingASL,
    statusASL:
      state && state.addSendLinkStore && state.addSendLinkStore.statusASL,
    messageASL:
      state && state.addSendLinkStore && state.addSendLinkStore.messageASL,
    dataASL: state && state.addSendLinkStore && state.addSendLinkStore.dataASL,
    ChargebackDetails:
      chargebackStore && chargebackStore.ChargebackDetails
        ? chargebackStore.ChargebackDetails
        : {},
    loadingSCM:
      state &&
      state.sendCustomMailStore &&
      state.sendCustomMailStore.loadingSCM,
    statusSCM:
      state && state.sendCustomMailStore && state.sendCustomMailStore.statusSCM,
    messageSCM:
      state &&
      state.sendCustomMailStore &&
      state.sendCustomMailStore.messageSCM,
    dataSCM:
      state && state.sendCustomMailStore && state.sendCustomMailStore.dataSCM,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addDocumentDispatch: (data, headers) => dispatch(addDocumentActions.addDocument(data, headers)),
  clearaddDocumentDispatch: () =>
    dispatch(addDocumentActions.clearaddDocument()),
  addDownloadDispatch: (data, headers) => dispatch(addDownloadActions.addDownload(data, headers)),
  clearaddDownloadDispatch: () =>
    dispatch(addDownloadActions.clearaddDownload()),
  addZipDispatch: (data, headers) => dispatch(addZipActions.addZip(data, headers)),
  clearaddZipDispatch: () => dispatch(addZipActions.clearaddZip()),
  getDocumentDetailsDispatch: (id, headers) =>
    dispatch(documentDetailsActions.getDocumentDetails(id, headers)),
  getDocTypeDispatch: (id, headers) => dispatch(docTypeActions.getDocType(id, headers)),
  docTypeActions: (data, headers) => dispatch(docTypeActions.getDocType(data, headers)),
  deleteDocumentDispatch: (data, headers) =>
    dispatch(deleteDocumentActions.deleteDocument(data, headers)),
  clearDeleteDocumentDispatch: () =>
    dispatch(deleteDocumentActions.clearDeleteDocument()),
  sendLinkDispatch: (data, headers) => dispatch(addSendLinkActions.addSendLink(data, headers)),
  clearsendLinkDispatch: () => dispatch(addSendLinkActions.clearaddSendLink()),
  getCBDetailsDispatch: (id, headers) =>
    dispatch(chargeBackDetailsActions.getChargebackDetails(id, headers)),
  sendCustomMailDispatch: (data, headers) => dispatch(sendCustomMailActions.add(data, headers)),
  clearsendCustomMailDispatch: () => dispatch(sendCustomMailActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step5);
