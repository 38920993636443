import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _, { debounce, isEmpty } from "lodash";
import { companyValidation } from "./validation";
import ReactSelect from "../../../../theme/layout/components/ReactSelect";
import {
  CRM_FORM,
  REGEX,
  STATUS_RESPONSE,
  currencyOptions,
  FILE_FORMAT_IMAGES,
  DROPZONE_MESSAGES,
  SET_STORAGE,
  API_URL,
  SESSION,
  API,
} from "../../../../utils/constants";
import {
  setLocalStorage,
  getLocalStorage,
  headersWithAuth,
} from "../../../../utils/helper";
import { warningAlert, confirmAlert } from "../../../../utils/alerts";
import color from "../../../../utils/colors";
import { useLocation, Link } from "react-router-dom";
import {
  setAddressDetails,
  getAdressValues,
  handleTrimWhiteSpace,
  handleTrimSpaceOnly,
} from "../functions/functions";
import {
  setCompanyDetails,
  getCompanyDetailsUpdatePayload,
} from "../functions/formData";
import {
  countryActions,
  stateActions,
  cityActions,
  clientDetailsActions,
  editClientsActions,
  industryActions,
  addApiKeyActions,
  apiKeyActions,
  addMerchantUploadActions,
} from "../../../../store/actions";
import { KTSVG } from "../../../../theme/helpers";
import { Modal } from "../../../../theme/layout/components/modal";
import Dropzone, { useDropzone } from "react-dropzone";
import styled from "styled-components";

const StyledDiv = styled.div`
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
    width: 100%;
    height: 55px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #9ab6d9;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
`;

const CompanyDetails = (props) => {
  const {
    getCountryDispatch,
    getCountrys,
    loadingCountry,
    getStateDispatch,
    getStates,
    loadingState,
    getCityDispatch,
    getCitys,
    loadingCity,
    clientDetailsDispatch,
    dataCD,
    statusCD,
    loadingClientDetails,
    editClientsDispatch,
    cleareditClientsDispatch,
    clearClientDetailsDispatch,
    onClickNext,
    setSummary,
    setClientDetails,
    statusECL,
    messageECL,
    loadingECL,
    getIndustryDispatch,
    getIndustry,
    addApiKeyDispatch,
    clearAddApiKeyDispatch,
    getApiKeyDispatch,
    getApiKey,
    loadingApi,
    statusAAK,
    messageAAK,
    loadingAAK,
    addMerchantUploadDispatch,
    clearaddMerchantUploadDispatch,
    dataAMUpload,
    loadingAMUpload,
    statusAMUpload,
  } = props;

  const { getRootProps } = useDropzone();
  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };
  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update");
  const id = url && url[1];
  const [countryOption, setCountryOption] = useState();
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [stateOption, setStateOption] = useState();
  const [selectedStateOption, setSelectedStateOption] = useState("");
  const [cityOptions, setCityOptions] = useState();
  const [selectedCityOption, setSelectedCityOption] = useState("");
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState();
  const [selectedIndustryOption, setSelectedIndustryOption] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [industryOption, setIndustryOption] = useState();
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [genKeyExist, setGenKeyExist] = useState(false);
  const userType = getLocalStorage(SET_STORAGE.SET_USER_ROLE);
  const [show, setShow] = useState(false);
  const [showEye, setShowEye] = useState(false);
  const [onBlurOptions, setOnBlurOptions] = useState({
    clientState: false,
    clientCity: false,
  });
  const [formData, setFormData] = useState({
    company: "",
    clientCountry: "",
    industry: "",
    customerId: "",
    clientAddress: "",
    clientCity: "",
    clientState: "",
    clientZip: "",
    clientEmail: "",
    clientPhoneNumber: "",
    phoneNumberExtension: "",
    clientURL: "",
    leadSource: "",
    clientComplexity: "",
    currency: "",
    clientDescription: "",
    logo: "",
  });

  useEffect(() => {
    const params = { skipPagination: true };
    getCountryDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    getIndustryDispatch(
      null,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }, []);

  const handleChange = (e) => {
    e.persist();
    const { value, name } = e.target;
    setFormData((values) => ({ ...values, [name]: value }));
    setErrors({ ...errors, [name]: "" });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const handleChangeCurrency = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCurrencyOption(selectedOption);
      setFormData((values) => ({ ...values, currency: selectedOption.value }));
    } else {
      setSelectedCurrencyOption();
      setFormData((values) => ({ ...values, currency: "" }));
    }
    setErrors({ ...errors, currency: "" });
  };

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getStateDispatch(
            {
              countryId: selectedOption.value,
              skipPagination: true,
            },
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      );
      setSelectedCountryOption(selectedOption);
      setFormData((values) => ({
        ...values,
        clientCountry: selectedOption.value,
        clientState: "",
        clientCity: "",
      }));
      setSelectedStateOption();
      setSelectedCityOption();
    } else {
      setSelectedCountryOption();
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({
        ...values,
        clientCountry: "",
        clientState: "",
        cityclientCity: "",
      }));
    }
    setErrors({ ...errors, clientCountry: "" });
  };

  const handleChangeState = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getCityDispatch(
            {
              stateId: selectedOption.value,
              skipPagination: true,
            },
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 2000)
      );
      setSelectedStateOption(selectedOption);
      setFormData((values) => ({
        ...values,
        clientState: selectedOption.value,
        clientCity: "",
      }));
      setSelectedCityOption();
    } else {
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({ ...values, clientState: "", clientCity: "" }));
    }
    setErrors({ ...errors, clientState: "" });
  };

  const handleSelectBlur = (e, name) => {
    typingTimeout && clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (name === "clientState" && onBlurOptions.clientState) {
          const param = { limit: 25 };
          getStateDispatch(
            param,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setOnBlurOptions((values) => ({ ...values, clientState: false }));
        }
        if (name === "clientCity" && onBlurOptions.clientCity) {
          const param = { limit: 25 };
          getCityDispatch(
            param,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setOnBlurOptions((values) => ({ ...values, clientCity: false }));
        }
      }, 1000)
    );
  };

  const handleSelectInputChange = (typedOption, name) => {
    if (typedOption.length >= 3) {
      typingTimeout && clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          if (
            name === "clientState" &&
            !(selectedCountryOption && selectedCountryOption.value)
          ) {
            const param = { name: typedOption, skipPagination: true };
            getStateDispatch(
              param,
              isAuthorized ? headersWithAuth : headercurrentWithApiKey
            );
            setOnBlurOptions((values) => ({ ...values, clientState: true }));
          }
          if (
            name === "clientCity" &&
            !(selectedStateOption && selectedStateOption.value)
          ) {
            const param = { name: typedOption, skipPagination: true };
            getCityDispatch(
              param,
              isAuthorized ? headersWithAuth : headercurrentWithApiKey
            );
            setOnBlurOptions((values) => ({ ...values, clientCity: true }));
          }
        }, 2000)
      );
    }
  };

  const handleChangeCity = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCityOption(selectedOption);
      setFormData((values) => ({
        ...values,
        clientCity: selectedOption.value,
      }));
    } else {
      setSelectedCityOption();
      setFormData((values) => ({ ...values, clientCity: "" }));
    }
    setErrors({ ...errors, clientCity: "" });
  };

  const handleChangeIndustry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedIndustryOption(selectedOption);
      setFormData((values) => ({ ...values, industry: selectedOption.value }));
      setErrors({ ...errors, industry: "" });
    } else {
      setSelectedIndustryOption();
      setFormData((values) => ({ ...values, industry: "" }));
    }
  };

  useEffect(() => {
    const country = getDefaultOptions(getCountrys);
    setCountryOption(country);
  }, [getCountrys]);

  useEffect(() => {
    const state = getDefaultOptions(getStates);
    setStateOption(state);
  }, [getStates]);

  useEffect(() => {
    const city = getDefaultOptions(getCitys);
    setCityOptions(city);
  }, [getCitys]);

  useEffect(() => {
    const data = getDefaultIndustryOptions(getIndustry, "industryType");
    setIndustryOption(data);
  }, [getIndustry]);

  const getDefaultOptions = (rawData) => {
    const defaultOptions = [];
    for (const item in rawData) {
      defaultOptions.push({
        label: rawData[item].name,
        value: rawData[item]._id,
      });
    }
    return defaultOptions;
  };

  const getDefaultIndustryOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  const getSelectedIndustryLabel = () => {
    const getIndustryLabel = getDefaultIndustryOptions(
      getIndustry,
      "industryType"
    );
    const selIndOption = _.filter(getIndustryLabel, function (x) {
      if (_.includes(formData.industry, x.value)) {
        return x;
      }
    });
    if (selIndOption.length > 0) {
      return selIndOption[0];
    }
  };

  const handleSubmit = () => {
    const errorMsg = companyValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      if (editMode) {
        const gePayload = getCompanyDetailsUpdatePayload(formData);
        editClientsDispatch(
          id,
          gePayload,
          isAuthorized ? headersWithAuth : headercurrentWithApiKey
        );
      } else {
        onClickNext(1);
        const getCountryValue = getAdressValues(
          getCountrys,
          formData.clientCountry
        );
        const getStateValue = getAdressValues(getStates, formData.clientState);
        const gerCityValue = getAdressValues(getCitys, formData.clientCity);
        const getIndValue = getSelectedIndustryLabel();
        setSummary((values) => ({
          ...values,
          client: {
            country: getCountryValue && getCountryValue.label,
            state: getStateValue && getStateValue.label,
            city: gerCityValue && gerCityValue.label,
            industry: getIndValue && getIndValue.label,
          },
        }));
        setClientDetails((values) => ({ ...values, client: formData }));
        setLocalStorage(CRM_FORM.COMPANY_DETAILS, JSON.stringify(formData));
      }
    }
  };

  const onConfirm = () => {
    clientDetailsDispatch(
      id,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  useEffect(() => {
    if (statusECL === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageECL,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      cleareditClientsDispatch();
    } else if (statusECL === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageECL, "", "Ok");
    }
    cleareditClientsDispatch();
  }, [statusECL]);

  useEffect(() => {
    const localData = JSON.parse(getLocalStorage(CRM_FORM.COMPANY_DETAILS));
    if (!_.isEmpty(localData)) {
      if (!editMode) {
        setFormData(localData);
        if (localData.industry) {
          const data = getDefaultIndustryOptions(getIndustry, "industryType");
          const selOption = _.filter(data, function (x) {
            if (_.includes(localData.industry, x.value)) {
              return x;
            }
          });
          if (selOption.length > 0) {
            setSelectedIndustryOption(selOption);
          }
        }
        if (localData.currency) {
          const selOption = _.filter(currencyOptions, function (x) {
            if (_.includes(localData.currency, x.value)) {
              return x;
            }
          });
          setSelectedCurrencyOption(selOption);
        }
        setAddressDetails(
          getCountrys,
          getStates,
          getCitys,
          localData.clientCountry,
          localData.clientState,
          localData.clientCity,
          setSelectedCountryOption,
          setSelectedStateOption,
          setSelectedCityOption
        );
      }
    }
  }, []);

  useEffect(() => {
    if (statusCD === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataCD && dataCD._id) {
        const data = setCompanyDetails(dataCD);
        setFormData(data);
        if (dataCD && dataCD.apiKeyisGenerated) {
          setGenKeyExist(dataCD && dataCD.apiKeyisGenerated);
        }
        if (dataCD.clientCountry) {
          const country = getDefaultOptions(getCountrys);
          const selOption = _.filter(country, function (x) {
            if (_.includes(dataCD.clientCountry, x.value)) {
              return x;
            }
          });
          if (selOption.length > 0) {
            setSelectedCountryOption(selOption);
          }
        }
        if (dataCD.clientState) {
          const state = getDefaultOptions(getStates);
          const selOption = _.filter(state, function (x) {
            if (_.includes(dataCD.clientState, x.value)) {
              return x;
            }
          });
          if (selOption.length > 0) {
            setSelectedStateOption(selOption);
          }
        }
        if (dataCD.clientCity) {
          const city = getDefaultOptions(getCitys);
          const selOption = _.filter(city, function (x) {
            if (_.includes(dataCD.clientCity, x.value)) {
              return x;
            }
          });
          if (selOption.length > 0) {
            setSelectedCityOption(selOption);
          }
        }
        if (dataCD.industry) {
          const data = getDefaultIndustryOptions(getIndustry, "industryType");
          const selOption = _.filter(data, function (x) {
            if (_.includes(dataCD.industry, x.value)) {
              return x;
            }
          });
          setSelectedIndustryOption(selOption);
        }
        if (dataCD.currency) {
          const selOption = _.filter(currencyOptions, function (x) {
            if (_.includes(dataCD.currency, x.value)) {
              return x;
            }
          });
          setSelectedCurrencyOption(selOption);
        }
        clearClientDetailsDispatch();
      } else if (statusCD === STATUS_RESPONSE.ERROR_MSG) {
        clearClientDetailsDispatch();
      }
    }
  }, [statusCD]);

  useEffect(() => {
    if (id) {
      setEditMode(true);
      clientDetailsDispatch(
        id,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
      setShowForm(false);
    } else {
      setEditMode(false);
    }
  }, [id]);

  const onGenerateKey = () => {
    const currentClientId = id.replace("/", "");
    const payload = {
      clientId: currentClientId,
    };
    addApiKeyDispatch(
      payload,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  useEffect(() => {
    if (statusAAK === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAAK,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearAddApiKeyDispatch();
    } else if (statusAAK === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAAK, "", "Ok");
    }
    clearAddApiKeyDispatch();
  }, [statusAAK]);

  const onShowApiKey = () => {
    if (!showEye) {
      const currentClientId = id.replace("/", "");
      getApiKeyDispatch(
        currentClientId,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    }
  };

  const handleFileUpload = (files, name) => {
    const file = files && files[0] ? files[0] : [];
    let isValidFileFormat = true;
    const maxFileSize = 5;
    const fileType = file && file.type;
    const uploadedFileSize = file && file.size;
    isValidFileFormat = _.includes(FILE_FORMAT_IMAGES, fileType);
    const fileSize = Number(maxFileSize) * 1024 * 1024;

    if (isValidFileFormat) {
      let ext = file.name.split(".");
      if (uploadedFileSize < fileSize && ext.length === 2) {
        const data = new FormData();
        data.append("type", "logo");
        data.append("file_to_upload", file);
        addMerchantUploadDispatch(data);
        setErrors({});
      } else {
        if (ext.length > 2) {
          setErrors({ ...errors, [name]: DROPZONE_MESSAGES.DOUBLE_EXTENSION });
        } else {
          setErrors({
            ...errors,
            [name]: `File size must below ${
              fileSize / 1048576
            } MB!. You file size is : ${Math.round(uploadedFileSize / 1024)}KB`,
          });
        }
      }
    } else {
      setErrors({ ...errors, [name]: DROPZONE_MESSAGES.IMAGE_INVALID });
    }
  };

  useEffect(() => {
    if (statusAMUpload === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataAMUpload && dataAMUpload.path) {
        setFormData((values) => ({ ...values, logo: dataAMUpload.path }));
        clearaddMerchantUploadDispatch();
      }
    }
  }, [statusAMUpload]);

  return (
    <>
      <Modal showModal={show} modalWidth={550}>
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="me-8 px-5">Show API Key</h2>
            <button
              type="button"
              className="btn btn-lg btn-icon btn-active-light-primary close"
              onClick={() => {
                setShow(false);
              }}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </button>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-center align-items-center">
            {showEye ? (
              loadingApi ? (
                <div className="d-flex justify-content-center py-5">
                  <div
                    className="spinner-border text-primary m-3"
                    role="status"
                  />
                </div>
              ) : (
                <>
                  <div className="text-gray-700 fw-bolder fs-6 pl-3">
                    {getApiKey.apiKey ? getApiKey.apiKey : "--"}
                  </div>
                </>
              )
            ) : (
              <>
                <i className="bi bi-asterisk text-dark fs-5 m-1" />
                <i className="bi bi-asterisk text-dark fs-5 m-1" />
                <i className="bi bi-asterisk text-dark fs-5 m-1" />
                <i className="bi bi-asterisk text-dark fs-5 m-1" />
                <i className="bi bi-asterisk text-dark fs-5 m-1" />
                <i className="bi bi-asterisk text-dark fs-5 m-1" />
                <i className="bi bi-asterisk text-dark fs-5 m-1" />
                <i className="bi bi-asterisk text-dark fs-5 m-1" />
              </>
            )}
            <button
              className="btn btn-sm btn-white m-2 fa-pull-right"
              onClick={() => {
                setShowEye((val) => !val);
                onShowApiKey();
              }}
            >
              <i
                className={`bi bi-eye${
                  showEye ? "" : "-slash"
                } fs-5 fw-bolder text-primary`}
              />
            </button>
          </div>
        </div>
      </Modal>
      <div className="card-header bg-skyBlue py-5">
        <div className="card-body m-8">
          {loadingClientDetails ? (
            <div className="d-flex justify-content-center py-5">
              <div className="spinner-border text-primary m-5" role="status" />
            </div>
          ) : (
            <>
              <div className="d-flex">
                <div className="col-lg-6">
                  <h2 className="mb-5">Company Details</h2>
                </div>
                {["Admin", "Client User"].includes(userType && userType) &&
                  (editMode && !showForm ? (
                    <div className="col-lg-6">
                      <div className="d-flex justify-content-end">
                        {genKeyExist ? (
                          <>
                            <button
                              className="btn btn-sm btn-primary rounded-0 fw-bolder"
                              data-toggle="modal"
                              data-target="#searchModal"
                              onClick={() => {
                                setShow(true);
                                setShowEye(false);
                              }}
                            >
                              Show API Key
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn btn-sm btn-dark rounded-0 fw-bolder"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Generate API Key"
                            disabled={loadingAAK}
                            onClick={() => {
                              onGenerateKey();
                            }}
                          >
                            {loadingAAK ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Generate API Key"
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null)}
              </div>
              <div className="form-group row mb-6">
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                    Company Name:
                  </label>
                  <div className="col-lg-11">
                    <input
                      autoComplete="off"
                      name="company"
                      type="text"
                      className="form-control"
                      placeholder="Company Name"
                      onChange={(e) => handleChange(e)}
                      value={formData.company || ""}
                      maxLength={42}
                      onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {errors && errors.company && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.company}
                    </div>
                  )}
                </div>
                <div className="col-lg-4 mb-2">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    Customer ID (Any CRM):
                  </label>
                  <div className="col-lg-11">
                    <input
                      autoComplete="off"
                      name="customerId"
                      type="text"
                      className="form-control"
                      placeholder="Customer ID"
                      onChange={(e) => handleChange(e)}
                      value={formData.customerId || ""}
                      maxLength={42}
                      onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                    Industry:
                  </label>
                  <div className="col-lg-11">
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name="industry"
                      className="basic-single"
                      classNamePrefix="select"
                      handleChangeReactSelect={handleChangeIndustry}
                      options={industryOption}
                      value={selectedIndustryOption}
                    />
                  </div>
                  {errors && errors.industry && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.industry}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row mb-6">
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    Country:
                  </label>
                  <div className="col-lg-11">
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name="clientCountry"
                      className="basic-single"
                      classNamePrefix="select"
                      handleChangeReactSelect={handleChangeCountry}
                      options={countryOption}
                      value={selectedCountryOption}
                      isLoading={loadingCountry}
                      isDisabled={loadingCity || loadingCountry}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    State:
                  </label>
                  <div className="col-lg-11">
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name="clientState"
                      className="basic-single"
                      classNamePrefix="select"
                      handleChangeReactSelect={handleChangeState}
                      options={stateOption}
                      value={selectedStateOption}
                      isLoading={loadingState}
                      onBlur={(e) => handleSelectBlur(e, "clientState")}
                      noOptionsMessage={() => "Please start typing to search!"}
                      handleInputChange={(e) =>
                        handleSelectInputChange(e, "clientState")
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    City:
                  </label>
                  <div className="col-lg-11">
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name="clientCity"
                      className="basic-single"
                      classNamePrefix="select"
                      handleChangeReactSelect={(e) => handleChangeCity(e)}
                      options={cityOptions}
                      value={selectedCityOption}
                      isLoading={loadingCity}
                      isDisabled={loadingCity}
                      onBlur={(e) => handleSelectBlur(e, "clientCity")}
                      noOptionsMessage={() => "Please start typing to search!"}
                      handleInputChange={(e) =>
                        handleSelectInputChange(e, "clientCity")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row mb-6">
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                    Address:
                  </label>
                  <div className="col-lg-11">
                    <textarea
                      autoComplete="off"
                      name="clientAddress"
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      onChange={(e) => handleChange(e)}
                      value={formData.clientAddress || ""}
                      maxLength={500}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {errors && errors.clientAddress && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.clientAddress}
                    </div>
                  )}
                </div>
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                    Zip Code:
                  </label>
                  <div className="col-lg-11">
                    <input
                      autoComplete="off"
                      name="clientZip"
                      type="text"
                      className="form-control"
                      placeholder="Zip"
                      onChange={(e) => handleChange(e)}
                      value={formData.clientZip || ""}
                      maxLength={6}
                      onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {errors && errors.clientZip && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.clientZip}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row mb-6">
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                    Email:
                  </label>
                  <div className="col-lg-11">
                    <input
                      autoComplete="off"
                      name="clientEmail"
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      onChange={(e) => handleChange(e)}
                      value={formData.clientEmail || ""}
                      maxLength={42}
                      onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {errors && errors.clientEmail && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.clientEmail}
                    </div>
                  )}
                </div>
                <div className="col-lg-4 mb-2">
                  <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                    Phone Number:
                  </label>
                  <div className="col-lg-11">
                    <input
                      autoComplete="off"
                      name="clientPhoneNumber"
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      onChange={(e) => handleChange(e)}
                      value={formData.clientPhoneNumber || ""}
                      maxLength={12}
                      onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.NUMERIC_CHARS.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {errors && errors.clientPhoneNumber && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.clientPhoneNumber}
                    </div>
                  )}
                </div>
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    Phone Extension:
                  </label>
                  <div className="col-lg-11">
                    <input
                      autoComplete="off"
                      name="phoneNumberExtension"
                      type="text"
                      className="form-control"
                      placeholder="Phone Extension"
                      onChange={(e) => handleChange(e)}
                      value={formData.phoneNumberExtension || ""}
                      maxLength={10}
                      onBlur={(e) => handleTrimSpaceOnly(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!/[0-9+]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row mb-6">
                <div className="col-lg-4 mb-2">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    URL:
                  </label>
                  <div className="col-lg-11">
                    <input
                      autoComplete="off"
                      name="clientURL"
                      type="text"
                      className="form-control"
                      placeholder="URL"
                      onChange={(e) => handleChange(e)}
                      value={formData.clientURL || ""}
                      maxLength={500}
                      onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    Lead Source:
                  </label>
                  <div className="col-lg-11">
                    <input
                      autoComplete="off"
                      name="leadSource"
                      type="text"
                      className="form-control"
                      placeholder="Lead Source"
                      onChange={(e) => handleChange(e)}
                      value={formData.leadSource || ""}
                      maxLength={42}
                      onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {*********Changing from text field to dropbox********} */}

                {/* <div className="col-lg-4 mb-2">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    Client Complexity:
                  </label>
                  <div className="col-lg-11">
                    <input
                      autoComplete="off"
                      name="clientComplexity"
                      type="text"
                      className="form-control"
                      placeholder="Client Complexity"
                      onChange={(e) => handleChange(e)}
                      value={formData.clientComplexity || ""}
                      maxLength={42}
                      onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                </div> */}

                <div className="col-lg-4 mb-2">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    Client Complexity:
                  </label>
                  <div className="col-lg-11">
                    <select
                      name="clientComplexity"
                      className="form-control  "
                      value={formData.clientComplexity || ""}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group row mb-6">
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    Currency:
                  </label>
                  <div className="col-lg-11">
                    <ReactSelect
                      isClearable
                      styles={customStyles}
                      isMulti={false}
                      name="currency"
                      className="basic-single"
                      classNamePrefix="select"
                      handleChangeReactSelect={handleChangeCurrency}
                      options={currencyOptions}
                      value={selectedCurrencyOption}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-2">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    Company Description:
                  </label>
                  <div className="col-lg-11">
                    <textarea
                      autoComplete="off"
                      name="clientDescription"
                      type="text"
                      className="form-control"
                      placeholder="Company Description"
                      onChange={(e) => handleChange(e)}
                      value={formData.clientDescription || ""}
                      maxLength={500}
                      onKeyPress={(e) => {
                        if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <label className="font-size-xs  font-weight-bold mb-2 form-label">
                    Company Logo:
                  </label>
                  <div className="col-lg-10">
                    <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleFileUpload(
                            acceptedFiles,
                            "file_to_upload",
                            "logo"
                          );
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="container w-30rem">
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                name="logo"
                                id="file_to_upload"
                                accept=".png, .jpg, .jpeg"
                                multiple={false}
                              />
                              <p className="text-primary fw-bolder fs-6">
                                Click here to select files
                              </p>
                            </div>
                            {errors && errors.file_to_upload && (
                              <div className="rr mt-1">
                                <style>{".rr{color:red;}"}</style>
                                {errors.file_to_upload}
                              </div>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </StyledDiv>
                    {loadingAMUpload ? (
                      <div className="d-flex justify-content-center align-items-center py-3">
                        <div className="d-flex justify-content-center">
                          <span
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                          >
                            <span className="visually-hidden" />
                          </span>
                          <span
                            className="spinner-grow spinner-grow-sm mx-1"
                            role="status"
                          >
                            <span className="visually-hidden" />
                          </span>
                        </div>
                        <div className="mx-2">Uploading</div>
                      </div>
                    ) : null}
                    {formData.logo || (dataCD && dataCD.logo) ? (
                      <div className="ms-20">
                        <img
                          className="mx-20 mt-2"
                          src={`${API_URL}/uploads/${formData.logo}`}
                          style={{ width: 120, height: 120 }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {showForm ? (
          <div className="form-group row mb-4">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <div className="col-lg-11">
                <button
                  className="btn btn-orange m-2 fa-pull-right"
                  id="submitBtn"
                  onClick={(event) => {
                    handleSubmit(event);
                  }}
                  disabled={
                    Object.values(errors).join("").split("").length > 0
                      ? true
                      : false
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {editMode && !showForm ? (
          <div className="form-group row g-0 mb-4">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <div className="col-lg-11">
                <button
                  className="btn btn-blue m-2 fa-pull-right"
                  onClick={(event) => {
                    handleSubmit(event);
                  }}
                  disabled={
                    Object.values(errors).join("").split("").length > 0
                      ? true
                      : false
                  }
                >
                  {loadingECL ? (
                    <span
                      className="spinner-border spinner-border-sm mx-3"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { apiKeyStore } = state;
  return {
    getCountrys: state && state.countryStore && state.countryStore.getCountrys,
    loadingCountry: state && state.countryStore && state.countryStore.loading,
    getStates: state && state.stateStore && state.stateStore.getStates,
    loadingState: state && state.stateStore && state.stateStore.loading,
    getCitys: state && state.cityStore && state.cityStore.getCitys,
    loadingCity: state && state.cityStore && state.cityStore.loading,
    getIndustry:
      state && state.industryStore && state.industryStore.getIndustry,
    dataCD:
      state && state.clientDetailsStore && state.clientDetailsStore.dataCD,
    statusCD:
      state && state.clientDetailsStore && state.clientDetailsStore.statusCD,
    messageCD:
      state && state.clientDetailsStore && state.clientDetailsStore.messageCD,
    loadingClientDetails:
      state &&
      state.clientDetailsStore &&
      state.clientDetailsStore.loadingClientDetails,
    dataECL: state && state.editClientStore && state.editClientStore.dataECL,
    statusECL:
      state && state.editClientStore && state.editClientStore.statusECL,
    messageECL:
      state && state.editClientStore && state.editClientStore.messageECL,
    loadingECL:
      state && state.editClientStore && state.editClientStore.loadingECL,
    loadingAAK:
      state && state.addApiKeyStore && state.addApiKeyStore.loadingAAK,
    statusAAK: state && state.addApiKeyStore && state.addApiKeyStore.statusAAK,
    messageAAK:
      state && state.addApiKeyStore && state.addApiKeyStore.messageAAK,
    dataAAK: state && state.addApiKeyStore && state.addApiKeyStore.dataAAK,
    dataCD:
      state && state.clientDetailsStore && state.clientDetailsStore.dataCD,
    getApiKey:
      apiKeyStore && apiKeyStore.getApiKey ? apiKeyStore.getApiKey : [],
    loadingApi:
      apiKeyStore && apiKeyStore.loading ? apiKeyStore.loading : false,
    loadingAMUpload:
      state &&
      state.addMerchantUploadStore &&
      state.addMerchantUploadStore.loadingAMUpload,
    dataAMUpload:
      state &&
      state.addMerchantUploadStore &&
      state.addMerchantUploadStore.dataAMUpload,
    statusAMUpload:
      state &&
      state.addMerchantUploadStore &&
      state.addMerchantUploadStore.statusAMUpload,
    messageAMUpload:
      state &&
      state.addMerchantUploadStore &&
      state.addMerchantUploadStore.messageAMUpload,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCountryDispatch: (params, headers) =>
    dispatch(countryActions.getCountrys(params, headers)),
  getStateDispatch: (params, headers) =>
    dispatch(stateActions.getStates(params, headers)),
  getCityDispatch: (params, headers) =>
    dispatch(cityActions.getCitys(params, headers)),
  getIndustryDispatch: (params, headers) =>
    dispatch(industryActions.getIndustry(params, headers)),
  clientDetailsDispatch: (id, headers) =>
    dispatch(clientDetailsActions.getClientDetails(id, headers)),
  clearClientDetailsDispatch: () =>
    dispatch(clientDetailsActions.clearClientDetails()),
  editClientsDispatch: (id, data, headers) =>
    dispatch(editClientsActions.editClients(id, data, headers)),
  cleareditClientsDispatch: () =>
    dispatch(editClientsActions.cleareditClients()),
  addApiKeyDispatch: (data, headers) =>
    dispatch(addApiKeyActions.addApiKey(data, headers)),
  clearAddApiKeyDispatch: () => dispatch(addApiKeyActions.clearaddApiKey()),
  getApiKeyDispatch: (id, headers) =>
    dispatch(apiKeyActions.getApiKey(id, headers)),
  addMerchantUploadDispatch: (data) =>
    dispatch(addMerchantUploadActions.addMerchantUpload(data)),
  clearaddMerchantUploadDispatch: () =>
    dispatch(addMerchantUploadActions.clearaddMerchantUpload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
