import { all } from 'redux-saga/effects'
import { fetchPostsWatcher } from './posts'
import { fetchChargebackWatcher } from './chargebackManagement'
import { fetchPreventionWatcher } from './preventionAlert'
import { fetchOrderinsightWatcher } from './orderInsight'
import { fetchLoginWatcher } from './login'
import { fetchForgotWatcher } from './forgotPassword'
import { fetchExportWatcher } from './export'
import { fetchClientWatcher } from './clients'
import { fetchPartnerWatcher } from './partners'
import { fetchFilterPartnerWatcher } from './authBasedFilter'
import { fetchMerchantWatcher } from './merchants'
import creatCaseSagas from './issuerBank'
import { fetchBankWatcher } from './bank'
import { fetchIssuerbanklistWatcher } from './issuerBankList'
import { fetchTransactionWatcher } from './CBDetails'
import { fetchaddClientWatcher } from './clientManagement'
import { fetchCountryWatcher } from './country'
import { fetchCRMWatcher } from './crm'
import { fetchClientCRMWatcher } from './clientCRM'
import { fetchCoverletterWatcher } from './manageCoverletter'
import { fetchUserroleWatcher } from './userRole'
import { fetchUsertypeWatcher } from './userType'
import { fetchComponentsWatcher } from './userComponents'
import { fetchReasoncodeWatcher } from './reasonCode'
import { fetchReasoncodeMatrixWatcher } from './reasonCodeMatrix'
import { fetchUploadsCBWatcher } from './uploadChargeback'
import { fetchCrmSettingsWatcher } from './CrmSettings'
import { fetchProcessorSettingsWatcher } from './ProcessorSettings'
import { fetchGatewaySettingsWatcher } from './GatewaySettings'
import { fetchIndustrySettingsWatcher } from './IndustrySettings'
import { fetchMenuCompWatcher } from './MenuComponents'
import { fetchPrivilegesWatcher } from './userPrevileges'
import { fetchUploadsMerchantWatcher } from './uploadMerchant'
import { fetchAcquirerSettingsWatcher } from './AcquirerSettings'
import { fetchSiteConfigWatcher } from './siteConfig'
import { fetchClientFilterWatcher } from './filterDropdown'
import { fetchApiKeyWatcher } from './apiKey'
import { fetchUserProfileWatcher } from './userProfile'
import { fetchMerchantSMTPWatcher } from './SmtpConfig'
import { fetchSubsPackageWatcher } from './subscriptionPackage'
import { fetchPaypalWatcher } from './PaypalGateway'
import { fetchSubscriptionHistoryWatcher } from './subscriptionHistory'
import { fetchUserRegisterWatcher } from './userRegister'
import { fetchClientSignupWatcher } from './clientSignup'
import { fetchNotifyWatcher } from './NotificationType'
import { fetchEmailResendWatcher } from './emailResend'
import { fetchUserNotificationWatcher } from './userNotificationSaga'
import { fetchShopifyConnectWatcher } from './ShopifyConnectSaga'
import { fetchMerchantBillingDetailsWatcher } from './merchantBillingDetails'

export default function * rootSaga () {
  yield all([
    fetchClientSignupWatcher(),
    fetchChargebackWatcher(),
    fetchPostsWatcher(),
    fetchPreventionWatcher(),
    fetchOrderinsightWatcher(),
    fetchLoginWatcher(),
    fetchForgotWatcher(),
    fetchExportWatcher(),
    fetchClientWatcher(),
    fetchPartnerWatcher(),
    fetchFilterPartnerWatcher(),
    fetchMerchantWatcher(),
    fetchBankWatcher(),
    fetchIssuerbanklistWatcher(),
    fetchTransactionWatcher(),
    fetchaddClientWatcher(),
    fetchPrivilegesWatcher(),
    fetchCountryWatcher(),
    fetchCRMWatcher(),
    fetchClientCRMWatcher(),
    fetchCoverletterWatcher(),
    fetchUserroleWatcher(),
    fetchUsertypeWatcher(),
    fetchComponentsWatcher(),
    fetchReasoncodeWatcher(),
    fetchReasoncodeMatrixWatcher(),
    fetchUploadsCBWatcher(),
    fetchCrmSettingsWatcher(),
    fetchProcessorSettingsWatcher(),
    fetchGatewaySettingsWatcher(),
    fetchIndustrySettingsWatcher(),
    fetchMenuCompWatcher(),
    fetchUploadsMerchantWatcher(),
    fetchAcquirerSettingsWatcher(),
    fetchSiteConfigWatcher(),
    fetchClientFilterWatcher(),
    fetchApiKeyWatcher(),
    fetchUserProfileWatcher(),
    fetchMerchantSMTPWatcher(),
    fetchSubsPackageWatcher(),
    fetchPaypalWatcher(),
    fetchSubscriptionHistoryWatcher(),
    fetchUserRegisterWatcher(),
    fetchNotifyWatcher(),
    fetchEmailResendWatcher(),
    fetchUserNotificationWatcher(),
    fetchShopifyConnectWatcher(),
    fetchMerchantBillingDetailsWatcher(),
    ...creatCaseSagas
  ])
}
