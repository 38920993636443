import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { connect } from "react-redux";
import { KTSVG } from "../../theme/helpers";
import color from "../../utils/colors";
import _ from "lodash";
import ReactSelect from "../../theme/layout/components/ReactSelect";
import { DateSelector } from "../../theme/layout/components/DateSelector";
import {
  DATE,
  REGEX,
  CHARGEBACK_LAYOUT,
  currencyOptions,
  CHARGEBACK_ID,
  SESSION,
  API,
  SET_STORAGE
} from "../../utils/constants";
import {
  chargebackActions,
  clientActions,
  merchantActions,
  countryActions,
  getFunctionalActions,
  getRootCauseActions,
  getSubFunactionalActions,
  serviceTypeActions,
  midCRMActions,
  processorActions,
  ReasoncodesActions,
  clientProcessorActions,
} from "../../store/actions";
import { getLocalStorage, setLocalStorage, removeLocalStorage, headersWithAuth, headerWithApiKey } from "../../utils/helper";
import { searchDefaultCols } from "./formData/searchDefaultCols";

function SearchList(props) {
  const {
    getChargebackColumns,
    getClientDispatch,
    getClient,
    getMerchantDispatch,
    getMerchant,
    getCountryDispatch,
    getCountrys,
    getFunctionalDispatch,
    getFunctional,
    getRootCauseDispatch,
    getRootCause,
    getSubFuncDispatch,
    getSubFunctional,
    getServicetypeDispatch,
    getServicetype,
    setSearchParams,
    getMIDCodeDispatch,
    getmidCRM,
    getProcessorDispatch,
    loadingClient,
    loadingMerchant,
    loadingMID,
    loadingProcessor,
    clientProcessorData,
    activePageNumber,
    setActivePageNumber,
    limit,
  } = props;
  const isAuthorized = getLocalStorage(SESSION.TOKEN);

  const pathUrl = new URL(window.location.href);
  const query = pathUrl && pathUrl.search.split("?");
  const apiKeyParam = query && query[1];
  const apiParam = apiKeyParam && apiKeyParam.split("apiKey=")
  const apiParamId = apiParam && apiParam[1]
  apiParamId && setLocalStorage(API.API_KEY, apiParamId);
  apiParamId && setLocalStorage(SET_STORAGE.SET_USER_ROLE, 'Client User');
  const [selectedClientOption, setSelectedClientOption] = useState("");
  const [clientOption, setClientOption] = useState();
  const [selectedMerchantOption, setSelectedMerchantOption] = useState("");
  const [merchantOption, setMerchantOption] = useState();
  const [countryOption, setCountryOption] = useState();
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [cardCountryOption, setCardCountryOption] = useState();
  const [selectedCardCountryOption, setSelectedCardCountryOption] = useState("");
  const [functionalOption, setFunctionalOption] = useState();
  const [selectedFunctionalOption, setSelectedFunctionalOption] = useState("");
  const [rootCauseOption, setRootCauseOption] = useState();
  const [selectedRootCauseOption, setSelectedRootCauseOption] = useState("");
  const [subFuncOption, setsubFuncOption] = useState();
  const [selectedsubFuncOption, setSelectedsubFuncOption] = useState("");
  const [serviceOption, setServiceOption] = useState();
  const [selectedServiceOption, setSelectedServiceOption] = useState("");
  const [processorOption, setProcessorOption] = useState();
  const [selectedProcessorOption, setSelectedProcessorOption] = useState("");
  const [midOption, setMIDOption] = useState();
  const [selectedMIDOption, setSelectedMIDOption] = useState("");
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState();
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const chargebackBatchId = getLocalStorage(CHARGEBACK_ID.BATCH_ID)
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': apiParamId ? apiParamId : getLocalStorage(API.API_KEY)
  }

  useEffect(() => {
    const params = {
      skipPagination: true,
    };
    getClientDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    getMerchantDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    getMIDCodeDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    getProcessorDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    getCountryDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    getFunctionalDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    getRootCauseDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    getSubFuncDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    getServicetypeDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  }, []);

  const [formData, setFormData] = useState({
    acquirerName: "",
    affiliateId: "",
    arn: "",
    authCode: "",
    avs: "",
    blacklisted: "",
    cardCountry: "",
    cardFirstSix: "",
    cardLastFour: "",
    cardNetwork: "",
    cardType: "",
    caseId: "",
    chargebackAmount: "",
    chargebackCurrency: "",
    chargebackDate: "",
    chargebackReason: "",
    chargeBackStatus: "",
    chargeBackType: "",
    clientId: "",
    companyName: "",
    country: "",
    customerEmail: "",
    customerIP: "",
    customerName: "",
    customerPhone: "",
    cvv: "",
    DBAName: "",
    deviceId: "",
    disputeFiledDate: "",
    disputeMethod: "",
    functionalArea: "",
    gateway: "",
    issuer: "",
    merchantId: "",
    merchantName: "",
    mid: "",
    nonUs: "",
    orderId: "",
    paymentId: "",
    paymentProcessor: "",
    paymentMethod: "",
    productName: "",
    reasonForLegitimateChargeback: "",
    refundIssued: "",
    rootCause: "",
    serviceType: "",
    saleType: "",
    subFunctionalArea: "",
    transactionAmount: "",
    transactionDate: "",
    transactionId: "",
    upiId: "",
    batchId: ""
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const getDefaultOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  const getDefaultProcessorOptions = (data) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) => {
        if (item.paymentProcessorId && item.paymentProcessorId.processorName) {
          defaultOptions.push({
            label:
              item.paymentProcessorId && item.paymentProcessorId.processorName
                ? item.paymentProcessorId.processorName
                : "",
            value:
              item.paymentProcessorId && item.paymentProcessorId._id
                ? item.paymentProcessorId._id
                : "",
          });
        }
      });
      return defaultOptions;
    }
  };

  useEffect(() => {
    const data = getDefaultOptions(getClient && getClient.result, "company");
    setClientOption(data);
  }, [getClient]);

  useEffect(() => {
    const data = getDefaultOptions(
      getMerchant && getMerchant.result,
      "merchantName"
    );
    setMerchantOption(data);
  }, [getMerchant]);

  useEffect(() => {
    const nonUs = getDefaultOptions(getCountrys, "name");
    setCountryOption(nonUs);
  }, [getCountrys]);

  useEffect(() => {
    const cardCountry = getDefaultOptions(getCountrys, "name");
    setCardCountryOption(cardCountry);
  }, [getCountrys]);

  useEffect(() => {
    const data = getDefaultOptions(getRootCause, "rootCauseType");
    setRootCauseOption(data);
  }, [getRootCause]);

  useEffect(() => {
    const data = getDefaultOptions(getFunctional, "functionalType");
    setFunctionalOption(data);
  }, [getFunctional]);

  useEffect(() => {
    const data = getDefaultOptions(getSubFunctional, "subFunctionalType");
    setsubFuncOption(data);
  }, [getSubFunctional]);

  useEffect(() => {
    const data = getDefaultOptions(getServicetype, "serviceType");
    setServiceOption(data);
  }, [getServicetype]);

  useEffect(() => {
    const data = getDefaultOptions(getmidCRM && getmidCRM.data, "midNumber");
    setMIDOption(data);
  }, [getmidCRM]);

  useEffect(() => {
    const data = getDefaultProcessorOptions(
      clientProcessorData,
      "processorName"
    );
    setProcessorOption(data);
  }, [clientProcessorData]);

  const handleChangeClient = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedClientOption(selectedOption);
      setFormData((values) => ({ ...values, clientId: selectedOption.value }));
      setErrors({ ...errors, clientId: "", companyName: "" });
    } else {
      setSelectedClientOption();
      setSelectedMerchantOption();
      setSelectedMIDOption();
      setFormData((values) => ({ ...values, clientId: "", companyName: "" }));
    }
  };

  const handleChangeMerchant = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedMerchantOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantId: selectedOption.value,
      }));
      const midParams = {
        skipPagination: true,
        clientId: formData.clientId,
        merchantId: selectedOption.value,
      };
      getMIDCodeDispatch(midParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
      setErrors({ ...errors, merchantId: "", merchantName: "" });
    } else {
      setSelectedMerchantOption();
      setSelectedMIDOption();
      setFormData((values) => ({
        ...values,
        merchantId: "",
        merchantName: "",
      }));
    }
  };

  const handleChangeMID = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedMIDOption(selectedOption);
      setFormData((values) => ({ ...values, mid: selectedOption.value }));
      setErrors({ ...errors, mid: "" });
    } else {
      setSelectedMIDOption();
      setSelectedProcessorOption();
      setFormData((values) => ({ ...values, mid: "" }));
    }
  };

  const handleChangeProcessor = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedProcessorOption(selectedOption);
      setFormData((values) => ({
        ...values,
        paymentProcessor: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, paymentProcessor: "" }));
    } else {
      setSelectedProcessorOption();
      setFormData((values) => ({ ...values, paymentProcessor: "" }));
    }
  };

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCountryOption(selectedOption);
      setFormData((values) => ({ ...values, nonUs: selectedOption.value }));
      setErrors({ ...errors, nonUs: "" });
    } else {
      setSelectedCountryOption();
      setFormData((values) => ({ ...values, nonUs: "" }));
    }
  };

  const handleChangeCardCountry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCardCountryOption(selectedOption);
      setFormData((values) => ({
        ...values,
        cardCountry: selectedOption.value,
      }));
      setErrors({ ...errors, cardCountry: "" });
    } else {
      setSelectedCardCountryOption();
      setFormData((values) => ({ ...values, cardCountry: "" }));
    }
  };

  const handleChangeFunctional = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedFunctionalOption(selectedOption);
      setFormData((values) => ({
        ...values,
        functionalArea: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, functionalArea: "" }));
    } else {
      setSelectedFunctionalOption();
      setFormData((values) => ({ ...values, functionalArea: "" }));
    }
  };

  const handleChangeRootCause = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedRootCauseOption(selectedOption);
      setFormData((values) => ({
        ...values,
        rootCause: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, rootCause: "" }));
    } else {
      setSelectedRootCauseOption();
      setFormData((values) => ({ ...values, rootCause: "" }));
    }
  };

  const handleChangeSubFunc = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedsubFuncOption(selectedOption);
      setFormData((values) => ({
        ...values,
        subFunctionalArea: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, subFunctionalArea: "" }));
    } else {
      setSelectedsubFuncOption();
      setFormData((values) => ({ ...values, subFunctionalArea: "" }));
    }
  };

  const handleChangeService = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedServiceOption(selectedOption);
      setFormData((values) => ({
        ...values,
        serviceType: selectedOption.value,
      }));
    } else {
      setSelectedServiceOption();
      setFormData((values) => ({ ...values, serviceType: "" }));
    }
  };

  const handleChangeCurrency = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCurrencyOption(selectedOption);
      setFormData((values) => ({
        ...values,
        chargebackCurrency: selectedOption.value,
      }));
    } else {
      setSelectedCurrencyOption();
      setFormData((values) => ({ ...values, chargebackCurrency: "" }));
    }
    setErrors({ ...errors, chargebackCurrency: "" });
  };

  const checkValidations = (e, regex, message) => {
    const { name, value } = e.target;
    if (REGEX.NUMERIC.test(value)) {
      setFormData({ ...formData, [name]: value });
      if (value && value.length >= 2 && regex && !regex.test(value)) {
        setErrors({ ...errors, [name]: message });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    } else {
      setFormData({ ...formData, [name]: "" });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "cardFirstSix") {
      checkValidations(
        e,
        REGEX.NUMERIC_SIX_DIGITS,
        "Card First Six should be in 6 digits!"
      );
    } else if (name === "cardLastFour") {
      checkValidations(
        e,
        REGEX.NUMERIC_FOUR_DIGITS,
        "Card Last Four should be in 4 digits!"
      );
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const resetState = () => {
    setFormData({
      acquirerName: "",
      affiliateId: "",
      arn: "",
      authCode: "",
      avs: "",
      blacklisted: "",
      cardCountry: "",
      cardFirstSix: "",
      cardLastFour: "",
      cardNetwork: "",
      cardType: "",
      caseId: "",
      chargebackAmount: "",
      chargebackCurrency: "",
      chargebackDate: "",
      chargebackReason: "",
      chargebackStatus: "",
      chargeBackType: "",
      clientId: "",
      companyName: "",
      country: "",
      customerEmail: "",
      customerIP: "",
      customerName: "",
      customerPhone: "",
      cvv: "",
      DBAName: "",
      deviceId: "",
      disputeFiledDate: "",
      disputeMethod: "",
      functionalArea: "",
      gateway: "",
      issuer: "",
      merchantId: "",
      merchantName: "",
      mid: "",
      nonUs: "",
      orderId: "",
      paymentId: "",
      paymentProcessor: "",
      paymentMethod: "",
      productName: "",
      reasonForLegitimateChargeback: "",
      refundIssued: "",
      rootCause: "",
      serviceType: "",
      saleType: "",
      subFunctionalArea: "",
      transactionAmount: "",
      transactionDate: "",
      transactionId: "",
      batchId: ""
    });
    setSelectedClientOption();
    setSelectedMerchantOption();
    setSelectedCountryOption();
    setSelectedCardCountryOption();
    setSelectedFunctionalOption();
    setSelectedRootCauseOption();
    setSelectedsubFuncOption();
    setSelectedServiceOption();
    setSelectedProcessorOption();
    setSelectedMIDOption();
    setSelectedCurrencyOption();
  };

  const handleSearch = () => {
    const params = {
      limit: limit,
      page: 1,
    };
    setActivePageNumber(1);
    for (const key in formData) {
      if (
        Object.prototype.hasOwnProperty.call(formData, key) &&
        formData[key] !== ""
      ) {
        params[key] = formData[key];
      }
    }
    const result = _.omit(params, ["limit", "page"]);
    setSearchParams(_.pickBy(result));
    setShow(false);
    getChargebackColumns(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    formData.batchId && setLocalStorage(CHARGEBACK_ID.BATCH_ID, formData.batchId)
  };

  const handleReset = () => {
    resetState();
    setFormData((values) => ({ ...values, batchId: '' }))
    setSearchParams();
    const params = {
      limit: limit,
      page: activePageNumber,
    };
    getChargebackColumns(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  useEffect(() => {
    if (show) {
      const localSearchData = JSON.parse(
        getLocalStorage(CHARGEBACK_LAYOUT.SEARCH)
      );
      if (!_.isEmpty(localSearchData)) {
        setData(localSearchData);
      } else {
        setLocalStorage(
          CHARGEBACK_LAYOUT.SEARCH,
          JSON.stringify(searchDefaultCols)
        );
        setData(searchDefaultCols);
      }
    }
  }, [show]);

  return (
    <>
      <div>
        <button
          type="button"
          className="btn btn-sm btn-light-primary m-1"
          data-toggle="modal"
          data-target="#searchModal"
          onClick={() => {
            setShow(true);
            chargebackBatchId &&
              setFormData((values) => ({ ...values, batchId: chargebackBatchId }));
          }}
        >
          {/* eslint-disable */}
          <KTSVG path="/media/icons/duotune/general/gen021.svg" />
          {/* eslint-disable */}
          Advanced Search
        </button>
      </div>
      <div
        className="modal fade"
        id="searchModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="''"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered mw-1000px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="me-8">Advanced Search</h2>
              <button
                type="button"
                className="btn btn-lg btn-icon btn-active-light-primary close"
                data-dismiss="modal"
                onClick={() => {
                  setShow(false);
                  resetState();
                }}
              >
                {/* eslint-disable */}
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1"
                />
                {/* eslint-disable */}
              </button>
            </div>
            <div className="modal-body">
              <form className="container-fixed">
                <div className="card-header bg-lightBlue">
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      {data && data.batchId && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3 form-label">
                            Batch Id:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="batchId"
                              type="text"
                              className="form-control"
                              placeholder="Batch Id"
                              onChange={(e) => handleChange(e)}
                              value={formData.batchId || ""}
                              autoComplete="off"
                              maxLength={50}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.chargebackStatus && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Chargeback Status:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="chargeBackStatus"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.chargeBackStatus || ""}
                            >
                              <option value="">Select...</option>
                              <option value="NEW">NEW</option>
                              <option value="PENDING">PENDING</option>
                              <option value="ONGOING">ONGOING</option>
                              <option value="WON">WON</option>
                              <option value="LOST">LOST</option>
                              <option value="NOT FAUGHT">NOT FOUGHT</option>
                              <option value="DISPUTED">DISPUTED</option>
                              <option value="DUPLICATE">DUPLICATE</option>
                              <option value="OPENED">OPENED</option>
                              <option value="WAITING FOR EVIDENCE">
                                WAITING FOR EVIDENCE
                              </option>
                              <option value='LOST REFUNDED'>LOST REFUNDED</option>
                              <option value='REJECTION DUE TO REFUND'>REJECTION DUE TO REFUND</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.clientId && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3 form-label">
                            Client:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="clientId"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeClient}
                              options={clientOption}
                              value={selectedClientOption}
                              isLoading={loadingClient}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.merchantId && (
                        <div className="col-lg-3 mb-2">
                          <label className="font-size-xs text-darkfont-weight-bold mb-3  form-label">
                            Merchant:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="merchantId"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeMerchant}
                              options={merchantOption}
                              value={selectedMerchantOption}
                              isLoading={loadingMerchant}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.productName && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Product Name:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="productName"
                              type="text"
                              className="form-control"
                              placeholder="Product Name"
                              onChange={(e) => handleChange(e)}
                              value={formData.productName || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.chargebackDate && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Chargeback Date:
                          </label>
                          <div className="col-lg-11">
                            <DateSelector
                              name="chargebackDate"
                              placeholder="Chargeback Date"
                              className="form-control"
                              selected={formData.chargebackDate || ""}
                              onChange={(date) => {
                                setErrors({ ...errors, chargebackDate: "" });
                                setFormData((values) => ({
                                  ...values,
                                  chargebackDate: date,
                                }));
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.chargebackAmount && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Chargebck Amount:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="chargebackAmount"
                              type="text"
                              className="form-control"
                              placeholder="Chargeback Amount"
                              onChange={(e) => handleChange(e)}
                              value={formData.chargebackAmount || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!/^[0-9.]*$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.DBAName && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            DBA Name:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="DBAName"
                              type="text"
                              className="form-control"
                              placeholder="DBA Name"
                              onChange={(e) => handleChange(e)}
                              value={formData.DBAName || ""}
                              autoComplete="off"
                              maxLength={50}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.cardType && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Card Type:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="cardType"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.cardType || ""}
                            >
                              <option value="">Select...</option>
                              <option value="Credit Card">Credit Card</option>
                              <option value="Debit Card">Debit Card</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.upiId && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            UPI Id:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="upiId"
                              type="text"
                              className="form-control"
                              placeholder="UPI Id"
                              onChange={(e) => handleChange(e)}
                              value={formData.upiId || ""}
                              autoComplete="off"
                              maxLength={52}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.transactionAmount && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Transaction Amount:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="transactionAmount"
                              type="text"
                              className="form-control"
                              placeholder="Transaction Amount"
                              onChange={(e) => handleChange(e)}
                              value={formData.transactionAmount || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!/^[0-9.]*$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.transactionDate && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Transaction Date:
                          </label>
                          <div className="col-lg-11">
                            <DateSelector
                              name="transactionDate"
                              placeholder="Transaction Date"
                              className="form-control"
                              selected={formData.transactionDate || ""}
                              onChange={(date) => {
                                setErrors({ ...errors, transactionDate: "" });
                                setFormData((values) => ({
                                  ...values,
                                  transactionDate: date,
                                }));
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.transactionId && (
                        <div className="col-lg-3 mb-3">
                          <label className="font-size-xs text-darkfont-weight-bold mb-3  form-label">
                            Transaction Number:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="transactionId"
                              type="text"
                              className="form-control"
                              placeholder="Transaction Number"
                              onChange={(e) => handleChange(e)}
                              value={formData.transactionId || ""}
                              autoComplete="off"
                              maxLength={52}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.acquirerName && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Acquirer Name:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="acquirerName"
                              type="text"
                              className="form-control"
                              placeholder="Acquirer Name"
                              onChange={(e) => handleChange(e)}
                              value={formData.acquirerName || ""}
                              maxLength={52}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.affiliateId && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Card Type:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="affiliateId"
                              type="text"
                              className="form-control"
                              placeholder="Card Type"
                              onChange={(e) => handleChange(e)}
                              value={formData.affiliateId || ""}
                              autoComplete="off"
                              maxLength={52}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.arn && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            ARN:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="arn"
                              type="text"
                              className="form-control"
                              placeholder="ARN"
                              onChange={(e) => handleChange(e)}
                              value={formData.arn || ""}
                              autoComplete="off"
                              maxLength={52}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.authCode && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Auth Code:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="authCode"
                              type="text"
                              className="form-control"
                              placeholder="Authorization Code"
                              onChange={(e) => handleChange(e)}
                              value={formData.authCode || ""}
                              autoComplete="off"
                              maxLength={52}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.avs && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            AVS Match:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="avs"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.avs || ""}
                            >
                              <option value="">Select...</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.blacklisted && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Blacklisted:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="blacklisted"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.blacklisted || ""}
                            >
                              <option value="">Select...</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.cardCountry && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Card Country:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="cardCountry"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeCardCountry}
                              options={cardCountryOption}
                              value={selectedCardCountryOption}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.cardFirstSix && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Card Bin:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="cardFirstSix"
                              type="text"
                              className="form-control"
                              placeholder="Card Bin"
                              onChange={(e) => handleChange(e)}
                              value={formData.cardFirstSix || ""}
                              autoComplete="off"
                              maxLength={6}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.cardLastFour && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Card Last Four Digits:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="cardLastFour"
                              type="text"
                              className="form-control"
                              placeholder="Credit card Last four"
                              onChange={(e) => handleChange(e)}
                              value={formData.cardLastFour || ""}
                              autoComplete="off"
                              maxLength={4}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.cardNetwork && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Card Network:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="cardNetwork"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.cardNetwork || ""}
                            >
                              <option value="">Select...</option>
                              <option value="Paypal">Paypal</option>
                              <option value="Amex">Amex</option>
                              <option value="Visa">Visa</option>
                              <option value="Master Card">Master Card</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.caseId && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Case Id:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="caseId"
                              type="text"
                              className="form-control"
                              placeholder="Case Id"
                              onChange={(e) => handleChange(e)}
                              value={formData.caseId || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.chargebackCurrency && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Chargeback Currency:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="chargebackCurrency"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeCurrency}
                              options={currencyOptions}
                              value={selectedCurrencyOption}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.chargebackReason && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Chargeback Reason:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="chargebackReason"
                              type="text"
                              className="form-control"
                              placeholder="Chargeback Reason"
                              onChange={(e) => handleChange(e)}
                              value={formData.chargebackReason || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.chargeBackType && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Auth Code:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="chargeBackType"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.chargeBackType || ""}
                            >
                              <option value="">Select...</option>
                              <option value="Retrieval Request">
                                Retrieval Request
                              </option>
                              <option value="1st Chargeback">
                                1st Chargeback
                              </option>
                              <option value="Pre-Arbitration">
                                Pre-Arbitration
                              </option>
                              <option value="Arbitration">Arbitration</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.companyName && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Company Name:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="companyName"
                              type="text"
                              className="form-control"
                              placeholder="Company Name"
                              onChange={(e) => handleChange(e)}
                              value={formData.companyName || ""}
                              maxLength={42}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.TEXT.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.country && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Country:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="country"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.country || ""}
                            >
                              <option value="">Select...</option>
                              <option value="US">US</option>
                              <option value="NON_US">NON_US</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.customerEmail && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Customer Email:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="customerEmail"
                              type="text"
                              className="form-control"
                              placeholder="Customer Email"
                              onChange={(e) => handleChange(e)}
                              value={formData.customerEmail || ""}
                              autoComplete="off"
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (
                                  !REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.customerIP && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Customer IP:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="customerIP"
                              type="text"
                              className="form-control"
                              placeholder="Customer IP"
                              onChange={(e) => handleChange(e)}
                              value={formData.customerIP || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.customerName && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Customer Name:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="customerName"
                              type="text"
                              className="form-control"
                              placeholder="Customer Name"
                              onChange={(e) => handleChange(e)}
                              value={formData.customerName || ""}
                              maxLength={42}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.TEXT.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.customerPhone && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Customer Phone:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="customerPhone"
                              type="text"
                              className="form-control"
                              placeholder="Customer Phone"
                              onChange={(e) => handleChange(e)}
                              value={formData.customerPhone || ""}
                              autoComplete="off"
                              maxLength={12}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.cvv && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            CVV:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="cvv"
                              type="text"
                              className="form-control"
                              placeholder="CVV"
                              onChange={(e) => handleChange(e)}
                              value={formData.cvv || ""}
                              autoComplete="off"
                              maxLength={4}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.deviceId && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Device Id:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="deviceId"
                              type="text"
                              className="form-control"
                              placeholder="Device Id"
                              onChange={(e) => handleChange(e)}
                              value={formData.deviceId || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.disputeFiledDate && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Disputed Filed Date:
                          </label>
                          <div className="col-lg-11">
                            <DateSelector
                              name="disputeFiledDate"
                              placeholder="Dispute Filed Date"
                              className="form-control"
                              selected={formData.disputeFiledDate || ""}
                              onChange={(date) => {
                                setErrors({ ...errors, disputeFiledDate: "" });
                                setFormData((values) => ({
                                  ...values,
                                  disputeFiledDate: date,
                                }));
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.disputeMethod && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Dispute Method:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="disputeMethod"
                              type="text"
                              className="form-control"
                              placeholder="Dispute Method"
                              onChange={(e) => handleChange(e)}
                              value={formData.disputeMethod || ""}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      )}
                      {data && data.functionalArea && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Functional Area:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="functionalArea"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeFunctional}
                              options={functionalOption}
                              value={selectedFunctionalOption}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.gateway && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Gateway:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="gateway"
                              type="text"
                              className="form-control"
                              placeholder="Gateway"
                              onChange={(e) => handleChange(e)}
                              value={formData.gateway || ""}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      )}
                      {data && data.issuer && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Issuer:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="issuer"
                              type="text"
                              className="form-control"
                              placeholder="Issuer"
                              onChange={(e) => handleChange(e)}
                              value={formData.issuer || ""}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      )}
                      {data && data.merchantName && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Merchant Name:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="merchantName"
                              type="text"
                              className="form-control"
                              placeholder="Merchant Name"
                              onChange={(e) => handleChange(e)}
                              value={formData.merchantName || ""}
                              maxLength={42}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.TEXT.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.mid && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            MID:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="mid"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeMID}
                              options={midOption}
                              value={selectedMIDOption}
                              isLoading={loadingMID}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.nonUs && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Non US:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="nonUs"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeCountry}
                              options={countryOption}
                              value={selectedCountryOption}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.orderId && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Order Id:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="orderId"
                              type="text"
                              className="form-control"
                              placeholder="Order Id"
                              onChange={(e) => handleChange(e)}
                              value={formData.orderId || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {data && data.createdBy && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3 form-label">
                            Created By:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="createdBy"
                              type="text"
                              className="form-control"
                              placeholder="Created By"
                              onChange={(e) => handleChange(e)}
                              value={formData.createdBy || ""}
                              autoComplete="off"
                              maxLength={50}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}

                        {data && data.updatedBy && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3 form-label">
                            Updated By:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="updatedBy"
                              type="text"
                              className="form-control"
                              placeholder="Updated By"
                              onChange={(e) => handleChange(e)}
                              value={formData.updatedBy || ""}
                              autoComplete="off"
                              maxLength={50}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}



                      {data && data.paymentId && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Payment Id:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="paymentId"
                              type="text"
                              className="form-control"
                              placeholder="Payment Id"
                              onChange={(e) => handleChange(e)}
                              value={formData.paymentId || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.paymentProcessor && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Payment Processor:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="paymentProcessor"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeProcessor}
                              options={processorOption}
                              value={selectedProcessorOption}
                              isLoading={loadingProcessor}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.paymentMethod && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Payment Method:
                          </label>
                          <div className="col-lg-11">
                            <input
                              name="paymentMethod"
                              type="text"
                              className="form-control"
                              placeholder="Payment Method"
                              onChange={(e) => handleChange(e)}
                              value={formData.paymentMethod || ""}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.reasonForLegitimateChargeback && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Reason For Legitimate Chargeback:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="reasonForLegitimateChargeback"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={
                                formData.reasonForLegitimateChargeback || ""
                              }
                            >
                              <option value="">Select</option>
                              <option value="clientRuleSet">
                                Client ruleset
                              </option>
                              <option value="disputeExpired">
                                Dispute expired
                              </option>
                              <option value="lackOfEvidence">
                                Lack of evidence
                              </option>
                              <option value="legacyDisputes">
                                Legacy disputes
                              </option>
                              <option value="lowNoRecoveryRates">
                                Low/No recovery rate
                              </option>
                              <option value="merchantError">
                                Merchant error
                              </option>
                              <option value="other">Other</option>
                              <option value="representedPrior">
                                Represented prior
                              </option>
                              <option value="reversalCase">
                                Reversal case
                              </option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.refundIssued && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Refund Issued:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="refundIssued"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.refundIssued || ""}
                            >
                              <option value="">Select...</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.rootCause && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Root Cause:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="rootCause"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeRootCause}
                              options={rootCauseOption}
                              value={selectedRootCauseOption}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.serviceType && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Service Type:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="serviceType"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeService}
                              options={serviceOption}
                              value={selectedServiceOption}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.saleType && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Sale Type:
                          </label>
                          <div className="col-lg-11">
                            <select
                              name="saleType"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.saleType || ""}
                            >
                              <option value="">Select</option>
                              <option value="trialSale">Trial Sale</option>
                              <option value="firstRecurringDate">
                                First Recurring Sale
                              </option>
                              <option value="straightSale">
                                Straight Sale
                              </option>
                              <option value="subssequentRecurring">
                                Subsequent Recurring
                              </option>
                              <option value="notFought">Not Fought</option>
                              <option value="parentOrder">Parent Order</option>
                              <option value="notFoughtTrialSale">
                                Not Fought - Trial Sale
                              </option>
                              <option value="notFoughtFirstRecurringOrder">
                                Not Fought - First Recurring Order
                              </option>
                              <option value="notFoughtSubsequentRecurringOrder">
                                Not Fought - Subsequent Recurring Order
                              </option>
                              <option value="notFoughtParentOrder">
                                Not Fought - Parent Order
                              </option>
                              <option value="notFoughtStraightSale">
                                Not Fought - Straight Sale
                              </option>
                            </select>
                          </div>
                        </div>
                      )}
                      {data && data.subFunctionalArea && (
                        <div className="col-lg-3 mb-3">
                          <label className="fs-xs fw-bold mb-3  form-label">
                            Sub Functional Area:
                          </label>
                          <div className="col-lg-11">
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name="subFunctionalArea"
                              className="basic-single"
                              classNamePrefix="select"
                              handleChangeReactSelect={handleChangeSubFunc}
                              options={subFuncOption}
                              value={selectedsubFuncOption}
                            />
                          </div>
                        </div>
                      )}
                      {data && data.createdAtFrom && (
                        <>
                          <div className="col-lg-3 mb-3">
                            <label className="fs-xs fw-bold mb-3  form-label">
                              Created At - From:
                            </label>
                            <div className="col-lg-11">
                              <DateSelector
                                name="createdAtFrom"
                                placeholder="Created At"
                                className="form-control"
                                selected={formData.createdAtFrom || ""}
                                onChange={(date) => {
                                  setErrors({ ...errors, createdAtFrom: "" });
                                  setFormData((values) => ({
                                    ...values,
                                    createdAtFrom: date,
                                  }));
                                }}
                                dateFormat={DATE.DATE_FOR_PICKER}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 mb-3">
                            <label className="fs-xs fw-bold mb-3  form-label">
                              Created At - To:
                            </label>
                            <div className="col-lg-11">
                              <DateSelector
                                name="createdAtTo"
                                placeholder="Created To"
                                className="form-control"
                                selected={formData.createdAtTo || ""}
                                onChange={(date) => {
                                  setErrors({ ...errors, createdAtTo: "" });
                                  setFormData((values) => ({
                                    ...values,
                                    createdAtTo: date,
                                  }));
                                }}
                                dateFormat={DATE.DATE_FOR_PICKER}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {data && data.updatedAtFrom && (
                        <>
                          <div className="col-lg-3 mb-3">
                            <label className="fs-xs fw-bold mb-3  form-label">
                              Updated At - From:
                            </label>
                            <div className="col-lg-11">
                              <DateSelector
                                name="updatedAtFrom"
                                placeholder="Updated At"
                                className="form-control"
                                selected={formData.updatedAtFrom || ""}
                                onChange={(date) => {
                                  setErrors({ ...errors, updatedAtFrom: "" });
                                  setFormData((values) => ({
                                    ...values,
                                    updatedAtFrom: date,
                                  }));
                                }}
                                dateFormat={DATE.DATE_FOR_PICKER}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 mb-3">
                            <label className="fs-xs fw-bold mb-3  form-label">
                              Updated At - To:
                            </label>
                            <div className="col-lg-11">
                              <DateSelector
                                name="updatedAtTo"
                                placeholder="Updated To"
                                className="form-control"
                                selected={formData.updatedAtTo || ""}
                                onChange={(date) => {
                                  setErrors({ ...errors, updatedAtTo: "" });
                                  setFormData((values) => ({
                                    ...values,
                                    updatedAtTo: date,
                                  }));
                                }}
                                dateFormat={DATE.DATE_FOR_PICKER}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="form-group row m-4">
                        <div className="col-lg-8" />
                        <div className="col-lg-4">
                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn btn-sm btn-light-primary m-2 fa-pull-right"
                              onClick={() => { handleSearch() }}
                              data-dismiss="modal"
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-light-danger m-2 fa-pull-right close"
                              onClick={() => {
                                handleReset()
                                removeLocalStorage(CHARGEBACK_ID.BATCH_ID)
                              }}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    rootCauseStore,
    functionalStore,
    subFunctionalStore,
    serviceTypeStore,
    processorStore,
    midCRMStore,
    clientProcessorStore,
  } = state;
  return {
    getRootCause:
      rootCauseStore && rootCauseStore.getRootCause
        ? rootCauseStore.getRootCause
        : {},
    getFunctional:
      functionalStore && functionalStore.getFunctional
        ? functionalStore.getFunctional
        : {},
    getSubFunctional:
      subFunctionalStore && subFunctionalStore.getSubFunctional
        ? subFunctionalStore.getSubFunctional
        : {},
    getServicetype:
      serviceTypeStore && serviceTypeStore.getServicetype
        ? serviceTypeStore.getServicetype
        : {},
    processorData:
      processorStore && processorStore.processorData
        ? processorStore.processorData
        : {},
    loadingProcessor:
      state &&
      state.clientProcessorStore &&
      state.clientProcessorStore.clientProcessorLoading,
    getmidCRM:
      midCRMStore && midCRMStore.getmidCRM ? midCRMStore.getmidCRM : {},
    clientProcessorData:
      clientProcessorStore && clientProcessorStore.clientProcessorData
        ? clientProcessorStore.clientProcessorData
        : {},
    loadingMID: state && state.midCRMStore && state.midCRMStore.loading,
    loadingGRC:
      state && state.ReasoncodeStore && state.ReasoncodeStore.loadingGRC,
    reasonCode:
      state && state.ReasoncodeStore && state.ReasoncodeStore.reasonCode,
    getCountrys: state && state.countryStore && state.countryStore.getCountrys,
    loading: state && state.countryStore && state.countryStore.loading,
    getClient: state && state.clientStore && state.clientStore.getClient,
    loadingClient: state && state.clientStore && state.clientStore.loading,
    getMerchant:
      state && state.merchantStore && state.merchantStore.getMerchant,
    loadingMerchant:
      state && state.merchantStore && state.merchantStore.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCountryDispatch: (params, headers) => dispatch(countryActions.getCountrys(params, headers)),
  countryActions: (data, headers) => dispatch(countryActions.getCountrys(data, headers)),
  getClientDispatch: (params, headers) => dispatch(clientActions.getClient(params, headers)),
  clientActions: (data, headers) => dispatch(clientActions.getClient(data, headers)),
  getMerchantDispatch: (params, headers) =>
    dispatch(merchantActions.getMerchant(params, headers)),
  merchantActions: (data, headers) => dispatch(merchantActions.getMerchant(data, headers)),
  getFunctionalDispatch: (params, headers) => dispatch(getFunctionalActions.get(params, headers)),
  getFunctionalActions: (data, headers) => dispatch(getFunctionalActions.get(data, headers)),
  getRootCauseDispatch: (params, headers) => dispatch(getRootCauseActions.get(params, headers)),
  getRootCauseActions: (data, headers) => dispatch(getRootCauseActions.get(data, headers)),
  getSubFuncDispatch: (params, headers) =>
    dispatch(getSubFunactionalActions.get(params, headers)),
  getSubFunactionalActions: (data, headers) =>
    dispatch(getSubFunactionalActions.get(data, headers)),
  getServicetypeDispatch: (params, headers) =>
    dispatch(serviceTypeActions.getServicetype(params, headers)),
  serviceTypeActions: (data, headers) =>
    dispatch(serviceTypeActions.getServicetype(data, headers)),
  getProcessorDispatch: (params, headers) =>
    dispatch(clientProcessorActions.getClientProcessor(params, headers)),
  processorActions: (data, headers) => dispatch(processorActions.getProcessor(data, headers)),
  getReasonCodeDispatch: (params, headers) =>
    dispatch(ReasoncodesActions.reasonCode(params, headers)),
  ReasoncodesActions: (data, headers) => dispatch(ReasoncodesActions.reasonCode(data, headers)),
  getMIDCodeDispatch: (params, headers) => dispatch(midCRMActions.getmidCRM(params, headers)),
  midCRMActions: (data, headers) => dispatch(midCRMActions.getmidCRM(data, headers)),
  getChargebackColumns: (data, headers) =>
    dispatch(chargebackActions.getChargeback(data, headers)),
  clientProcessorActions: (data, headers) =>
    dispatch(clientProcessorActions.getClientProcessor(data, headers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
