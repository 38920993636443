import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { KTSVG } from "../../theme/helpers";
import {
  chargebackActions,
  deleteChargebackActions,
  partnerCredFilterActions,
  approveCBActions,
  addNotificationToMerchant,
  updateCBApprove,
  singleNotifyToMerchant,
  singleApproveMerchant,
  downloadInvoiceActions,
} from "../../store/actions";
import routeConfig from "../../routing/routeConfig";
import serviceList from "../../services/serviceList";
import Layout from "./layoutOptions";
import SearchList from "./searchList";
import Export from "./export";
import moment from "moment";
import {
  getUserPermissions,
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  headersWithAuth,
} from "../../utils/helper";
import { Can } from "../../theme/layout/components/can";
import {
  DATE,
  SWEET_ALERT_MSG,
  STATUS_RESPONSE,
  CHARGEBACK_STATUS,
  CREATE_PERMISSION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  EXPORT_PERMISSION,
  UPDATE_DELETE_PERMISSION,
  AUTH_BASED_FILTER,
  CHARGEBACK_ID,
  SET_STORAGE,
  APPROVE_MESSAGE,
  STATIC_PERMISSION,
  CONFIRMATION_TEXTS,
  API,
  SESSION,
  CB_URL,
} from "../../utils/constants";
import ReactPaginate from "react-paginate";
import "../../style.scss";
import _ from "lodash";
import {
  confirmationAlert,
  warningAlert,
  confirmAlert,
} from "../../utils/alerts";
import { Modal } from "../../theme/layout/components/modal";
import UploadBulkStatus from "./UploadModal";

function ChargebackList(props) {
  const {
    getChargebackDispatch,
    className,
    chargebacks,
    loading,
    deleteChargebackDispatch,
    deleteChargebackStatus,
    deleteChargebackMessage,
    clearDeleteChargebackDispatch,
    setFilterFunction,
    setFilterFunctionDispatch,
    setCredFilterParams,
    approveCBActionDispatch,
    clearApproveCBActionDispatch,
    updateCBApproveDispatch,
    clearUpdateCBApproveDispatch,
    addNotificationToMerchantDispatch,
    clearNotificationToMerchantDispatch,
    singleNotifyToMerchantDispatch,
    clearSingleNotifyToMerchantDispatch,
    singleApproveMerchantDispatch,
    clearSingleApproveMerchantDispatch,
    statusAPCB,
    messageAPCB,
    loadingAPCB,
    dataNotifyMerchant,
    loadingNotifyToMerchant,
    messageNotifyMerchant,
    statusNotifyMerchant,
    loadingUPCBA,
    statusUPCBA,
    messageUPCBA,
    loadingSingleNotifyToMerchant,
    statusSingleNotifyMerchant,
    messageSingleNotifyMerchant,
    loadingSingleApproveMerchant,
    statusSingleApproveMerchant,
    messageSingleApproveMerchant,
    addDownloadDispatch,
    clearaddDownloadDispatch,
    loadingADI,
    statusADI,
    dataADI,
    messageADI,
  } = props;

  const pathUrl = new URL(window.location.href);
  const query = pathUrl && pathUrl.search.split("?");
  const apiKeyParam = query && query[1];
  const apiParam = apiKeyParam && apiKeyParam.split("apiKey=");
  const apiParamId = apiParam && apiParam[1];
  apiParamId && setLocalStorage(API.API_KEY, apiParamId);
  apiParamId && setLocalStorage(SET_STORAGE.SET_USER_ROLE, "Client User");

  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": apiParamId ? apiParamId : getLocalStorage(API.API_KEY),
  };

  const apiToken = getLocalStorage(API.API_KEY);
  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const didMount = React.useRef(false);
  const history = useHistory();
  const [columns, setColumns] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [searchColumns, setSearchColumns] = useState({});
  const [exportColumns, setExportColumns] = useState({});
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [merchantNotification, setMerchantNotification] = useState(false);
  const url = useLocation().pathname;
  const getUsersPermissions = getUserPermissions(url, false)
    ? getUserPermissions(url, false)
    : [];
  const currentPermissons =
    url === routeConfig.chargebackManagement
      ? getUsersPermissions
      : STATIC_PERMISSION;
  const userType = getLocalStorage(SET_STORAGE.SET_USER_ROLE);
  const chargebackBatchId = getLocalStorage(CHARGEBACK_ID.BATCH_ID);
  const [EmailModalShow, setEmailModalShow] = useState(false);
  const [UploadModalShow, setUploadModalShow] = useState(false);
  const [merchantList, setMerchantList] = useState();
  const [selectedMerchant, setSelectedMerchant] = useState([]);
  const [, setData] = useState({});
  const [limit, setLimit] = useState(25);
  const [sorting, setSorting] = useState({
    chargebackStatus: false,
    clientId: false,
    merchantId: false,
    productName: false,
    chargebackDate: false,
    chargebackDeadlineDate: false,
    chargebackAmount: false,
    DBAName: false,
    cardType: false,
    transactionAmount: false,
    transactionDate: false,
    transactionId: false,
    acquirerName: false,
    affiliateId: false,
    arn: false,
    authCode: false,
    avs: false,
    blacklisted: false,
    cardCountry: false,
    cardFirstSix: false,
    cardLastFour: false,
    cardNetwork: false,
    caseId: false,
    chargebackCurrency: false,
    chargebackReason: false,
    chargeBackType: false,
    companyName: false,
    country: false,
    customerEmail: false,
    customerIP: false,
    customerName: false,
    customerPhone: false,
    cvv: false,
    deviceId: false,
    disputeFiledDate: false,
    disputeMethod: false,
    functionalArea: false,
    gateway: false,
    issuer: false,
    merchantName: false,
    mid: false,
    nonUs: false,
    orderId: false,
    paymentId: false,
    paymentProcessor: false,
    paymentMethod: false,
    reasonCodeGrouping: false,
    reasonForLegitimateChargeback: false,
    refundIssued: false,
    rootCause: false,
    serviceType: false,
    saleType: false,
    subFunctionalArea: false,
    upiId: false,
    createdAtFrom: false,
    createdAtTo: false,
    updatedAtFrom: false,
    updatedAtTo: false,
    createdBy: false,
    updatedBy: false,
  });

  const addColumns = (data) => {
    setColumns(data);
    setData({});
  };

  const addSearchColumns = (data) => {
    setSearchColumns(data);
    setData({});
  };

  const addExportColumns = (data) => {
    setExportColumns(data);
    setData({});
  };
  useEffect(() => {
    const credBasedPartnerValue = getLocalStorage(AUTH_BASED_FILTER.PARTNER);
    const credBasedClientValue = getLocalStorage(AUTH_BASED_FILTER.CLIENT);
    const credBasedMerchantValue = getLocalStorage(AUTH_BASED_FILTER.MERCHANT);
    const credBasedMidValue = getLocalStorage(AUTH_BASED_FILTER.MID);
    const credBasedParams = {
      parnerId: credBasedPartnerValue,
      clientId: credBasedClientValue,
      merchantId: credBasedMerchantValue,
      mid: credBasedMidValue,
      batchId: chargebackBatchId,
    };
    const params = {
      limit: limit,
      page: activePageNumber,
      ...searchParams,
      ...credBasedParams,
    };
    const pickByParams = _.pickBy(params);
    if (!isAuthorized && !apiToken) {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
    getChargebackDispatch(
      pickByParams,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  }, []);

  useEffect(() => {
    if (didMount.current && setFilterFunction) {
      const currentFilterParams = {
        partnerId: setCredFilterParams.partnerId,
        clientId: setCredFilterParams.clientId,
        merchantId: setCredFilterParams.merchantId,
      };
      const params = {
        limit: limit,
        page: activePageNumber,
        batchId: chargebackBatchId,
        ...setCredFilterParams,
      };
      const pickByParams = _.pickBy(params);
      getChargebackDispatch(
        pickByParams,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
      setFilterFunctionDispatch(false);
      setSearchParams(currentFilterParams);
    }
  }, [setFilterFunction, setCredFilterParams]);

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
    setActivePageNumber(1);
    const params = {
      limit: value,
      page: 1,
      ...searchParams,
      batchId: chargebackBatchId,
    };
    const pickByParams = _.pickBy(params);
    getChargebackDispatch(
      pickByParams,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const paginationParams = {
      limit: limit,
      page: pageNumber,
      ...searchParams,
      batchId: chargebackBatchId,
    };
    const pickByParams = _.pickBy(paginationParams);
    setActivePageNumber(pageNumber);
    getChargebackDispatch(
      pickByParams,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const handleSorting = (name) => {
    if (sorting && sorting[name]) {
      sorting[name] = !sorting[name];
      setSorting(sorting);
      const params = {
        ...searchParams,
        limit: limit,
        page: activePageNumber,
        sort: name,
        order: "DESC",
        batchId: chargebackBatchId,
      };
      const pickByParams = _.pickBy(params);
      getChargebackDispatch(
        pickByParams,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
    } else {
      const filter = _.mapValues(sorting, () => {
        return false;
      });
      filter[name] = !filter[name];
      setSorting(filter);
      const params = {
        ...searchParams,
        limit: limit,
        page: activePageNumber,
        sort: name,
        order: "ASC",
        batchId: chargebackBatchId,
      };
      const pickByParams = _.pickBy(params);
      getChargebackDispatch(
        pickByParams,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
    }
  };

  const handleNotifyToMerchant = () => {
    const payload = {
      batchId: chargebackBatchId,
      ...searchParams,
    };
    if (chargebackBatchId) {
      addNotificationToMerchantDispatch(
        _.pickBy(payload),
        serviceList.merchantMailNotificationCount,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
    }
  };

  const onAprroveAll = () => {
    const payload = {
      batchId: chargebackBatchId,
      chargebackStatus: "Approved",
      merchantId: searchParams ? searchParams.merchantId : "",
      ...searchParams,
    };
    updateCBApproveDispatch(
      _.pickBy(payload),
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const handleApproveAll = () => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.APPROVE_ALL_EVI,
      "warning",
      "Yes",
      "No",
      () => {
        onAprroveAll();
      },
      () => {}
    );
  };

  const onAcquirerSend = () => {
    const payload = {
      batchId: chargebackBatchId,
      merchantId: searchParams ? searchParams.merchantId : "",
      ...searchParams,
    };
    approveCBActionDispatch(
      _.pickBy(payload),
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const handleNotifyToAcquirer = () => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.SEND_NOTIFY_ACQUIRER,
      "warning",
      "Yes",
      "No",
      () => {
        onAcquirerSend();
      },
      () => {}
    );
  };

  const handleCheckBox = (e) => {
    const { value, checked } = e.target;
    setSelectedMerchant((prevState) =>
      checked
        ? !selectedMerchant.includes(value)
          ? [...prevState, value]
          : []
        : prevState.filter((obj) => (obj !== value ? obj : null))
    );
  };

  const handleSingleNotify = () => {
    const payload = {
      batchId: chargebackBatchId,
      merchantIds: selectedMerchant,
      ...searchParams,
    };
    singleNotifyToMerchantDispatch(
      payload,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const handleSingleApprove = () => {
    const payload = {
      batchId: chargebackBatchId,
      merchantIds: selectedMerchant,
      chargebackStatus: "Approved",
      ...searchParams,
    };
    singleApproveMerchantDispatch(
      payload,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const onApproveConfirm = () => {
    const clearBtn = document.getElementById("clear-btn");
    clearBtn && clearBtn.click();
    const params = {
      limit: limit,
      page: activePageNumber,
      ...searchParams,
      batchId: chargebackBatchId,
    };
    getChargebackDispatch(
      _.pickBy(params),
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const onAprrove = () => {
    const notifyPayload = {
      resendNotification: 1,
      batchId: chargebackBatchId,
    };
    approveCBActionDispatch(
      notifyPayload,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  const onErrorAprrove = () => {
    const notifyPayload = {
      sendNotification: 1,
      batchId: chargebackBatchId,
    };
    approveCBActionDispatch(
      notifyPayload,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  };

  useEffect(() => {
    const status = statusSingleNotifyMerchant || statusSingleApproveMerchant;
    const messages =
      messageSingleNotifyMerchant || messageSingleApproveMerchant;
    if (status === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messages,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
    } else if (status === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messages, "", "Ok");
    }
    clearSingleApproveMerchantDispatch();
    clearSingleNotifyToMerchantDispatch();
  }, [statusSingleNotifyMerchant, statusSingleApproveMerchant]);

  const onMerchantNotifyConfirm = () => {
    const payload = {
      batchId: chargebackBatchId,
    };
    if (chargebackBatchId) {
      setMerchantNotification(true);
      clearNotificationToMerchantDispatch();
      addNotificationToMerchantDispatch(
        payload,
        serviceList.addMerchantNotification,
        apiParamId ? headercurrentWithApiKey : headersWithAuth
      );
    }
  };

  useEffect(() => {
    if (statusUPCBA === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageUPCBA,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearUpdateCBApproveDispatch();
    } else if (statusUPCBA === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageUPCBA, "", "Ok");
    }
    clearUpdateCBApproveDispatch();
  }, [statusUPCBA]);

  useEffect(() => {
    if (statusNotifyMerchant === STATUS_RESPONSE.SUCCESS_MSG) {
      if (merchantNotification) {
        confirmAlert(
          "Success",
          messageNotifyMerchant,
          "success",
          "ok",
          () => {
            setMerchantNotification(false);
          },
          () => {
            setMerchantNotification(false);
          }
        );
        clearNotificationToMerchantDispatch();
      } else {
        setEmailModalShow(true);
        setMerchantList(dataNotifyMerchant);
      }
    } else if (statusNotifyMerchant === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageNotifyMerchant, "", "Ok");
      clearNotificationToMerchantDispatch();
    }
    clearNotificationToMerchantDispatch();
  }, [statusNotifyMerchant]);

  useEffect(() => {
    if (statusAPCB === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAPCB,
        "success",
        "ok",
        () => {
          onApproveConfirm();
        },
        () => {}
      );
      clearApproveCBActionDispatch();
    } else if (statusAPCB === STATUS_RESPONSE.ERROR_MSG) {
      if (messageAPCB === APPROVE_MESSAGE.RESEND_SOME_MERCHANT_MSG) {
        confirmationAlert(
          SWEET_ALERT_MSG.CONFIRMATION_TEXT,
          messageAPCB,
          "warning",
          CONFIRMATION_TEXTS.COUNTINUE_ANYWAY,
          "Cancel",
          () => {
            onAprrove();
          },
          () => {}
        );
      } else if (messageAPCB === APPROVE_MESSAGE.SEND_MESSAGE) {
        confirmationAlert(
          SWEET_ALERT_MSG.CONFIRMATION_TEXT,
          SWEET_ALERT_MSG.RESEND_NOTIFY,
          "warning",
          CONFIRMATION_TEXTS.COUNTINUE_ANYWAY,
          "No",
          () => {
            onErrorAprrove();
          },
          () => {}
        );
      } else {
        warningAlert("Error", messageAPCB, "", "Ok");
      }
      clearApproveCBActionDispatch();
    }
  }, [statusAPCB]);

  const totalPages =
    chargebacks && chargebacks.count
      ? Math.ceil(parseInt(chargebacks && chargebacks.count) / limit)
      : 1;

  function onConfirmDelete(id) {
    deleteChargebackDispatch(
      id,
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
  }

  const onConfirm = () => {
    const params = {
      limit: limit,
      page: activePageNumber,
      ...searchParams,
      batchId: chargebackBatchId,
    };
    getChargebackDispatch(
      _.pickBy(params),
      apiParamId ? headercurrentWithApiKey : headersWithAuth
    );
    setEmailModalShow(false);
    setSelectedMerchant([]);
  };

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_CHARGEBACK,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete(id);
      },
      () => {}
    );
  };

  useEffect(() => {
    if (deleteChargebackStatus === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        deleteChargebackMessage,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearDeleteChargebackDispatch();
    } else if (deleteChargebackStatus === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", deleteChargebackMessage, "", "Ok");
    }
    clearDeleteChargebackDispatch();
  }, [deleteChargebackStatus]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    }
  });

  const handleDownloadInvoice = () => {
    const downloadParams = {
      ...searchParams,
      batchId: chargebackBatchId,
    };
    addDownloadDispatch(
      downloadParams,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const downloadFiles = (uri) => {
    const link = document.createElement("a");
    const url = window.URL || window.webkitURL;
    const revokeUrlAfterSec = 1000;
    link.href = uri;
    link.download = true;
    link.target = "_blank";
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => url.revokeObjectURL(link.href), revokeUrlAfterSec);
  };

  useEffect(() => {
    if (statusADI === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataADI) {
        const downloadUrl = `${CB_URL}${dataADI}`;
        downloadFiles(downloadUrl);
      }
    } else if (statusADI === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageADI, "", "Ok");
    }
    clearaddDownloadDispatch();
  }, [statusADI]);

  return (
    <>
      <button
        className="d-none"
        id="clear-btn"
        onClick={() => {
          removeLocalStorage(CHARGEBACK_ID.BATCH_ID);
        }}
      >
        Clear
      </button>
      {UploadModalShow ? (
        <Modal showModal={UploadModalShow} modalWidth={1000}>
          <div className="" id="crmModal">
            <div className="">
              <div className="p-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="me-8">Upload Status</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    onClick={() => {
                      setUploadModalShow(false);
                    }}
                  >
                    {/* eslint-disable */}
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                    {/* eslint-disable */}
                  </button>
                </div>
                <UploadBulkStatus setUploadModalShow={setUploadModalShow} />
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {EmailModalShow ? (
        <Modal showModal={EmailModalShow} modalWidth={500}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header bg-secondary">
                  <h2 className="me-8">Notify to Merchant</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={() => {
                      setEmailModalShow(false);
                      setMerchantList();
                      setSelectedMerchant([]);
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body ">
                  <div className={`card ${className}`}>
                    <div className="d-flex justify-content-center px5 pt-5">
                      <div className="text-center scroll h-400px">
                        {merchantList &&
                          merchantList?.map((item, i) => {
                            return (
                              <div key={i} className="my-5">
                                <label className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={
                                      item.merchantId && item.merchantId
                                        ? item.merchantId
                                        : ""
                                    }
                                    onChange={(e) => handleCheckBox(e)}
                                  />
                                  <span
                                    className="form-check-label"
                                    htmlFor="flexCheckChecked"
                                  >
                                    {item ? item.text : "--"}
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <div className="col-lg-6" />
                      <div className="col-lg-6">
                        <div className="col-lg-11">
                          <button
                            type="button"
                            className="btn btn-sm btn-light-info m-2 fa-pull-right"
                            onClick={() => {
                              handleSingleNotify();
                            }}
                            data-dismiss="modal"
                            disabled={
                              loadingSingleNotifyToMerchant ||
                              loadingSingleApproveMerchant ||
                              selectedMerchant.length == 0
                            }
                          >
                            {loadingSingleNotifyToMerchant ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Notify"
                            )}
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-light-success m-2 fa-pull-right close"
                            onClick={() => {
                              handleSingleApprove();
                            }}
                            disabled={
                              loadingSingleNotifyToMerchant ||
                              loadingSingleApproveMerchant ||
                              selectedMerchant.length == 0
                            }
                          >
                            {loadingSingleApproveMerchant ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Approve"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className={`card g-0 ${className}`}>
        <div className="card-body py-3">
          <div className="d-flex  px - 2">
            <div className="d-flex justify-content-start col-md-4">
              <div className="col-md-3 mt-1">
                {chargebacks && chargebacks.count ? (
                  <span className="text-muted fw-bold d-flex fs-5 mt-2">
                    Total: &nbsp;{" "}
                    <span className="text-gray-700 fw-bolder text-hover-primary fs-5">
                      {chargebacks.count}
                    </span>
                  </span>
                ) : null}
              </div>
              <div className="col-md-7 d-flex">
                <label className="col-form-label text-lg-start">
                  Record(s) per Page : &nbsp;{" "}
                </label>
                <div className="col-md-3">
                  <select
                    className="form-select w-6rem"
                    data-control="select"
                    data-placeholder="Select an option"
                    data-allow-clear="true"
                    onChange={(e) => handleRecordPerPage(e)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="d-flex col-md-8 justify-content-end my-auto">
              <Can
                permissons={currentPermissons}
                componentPermissions={CREATE_PERMISSION}
              >
                {["Admin"].includes(userType && userType) &&
                url === routeConfig.chargebackManagement ? (
                  <>
                    <button
                      className="btn btn-sm btn-light-primary m-1"
                      onClick={() => {
                        history.push("/chargeback-management/sdk");
                      }}
                    >
                      <KTSVG path="/media/icons/duotune/files/fil003.svg" />
                      Generate SDK
                    </button>
                  </>
                ) : null}
                <button
                  className="btn btn-sm btn-light-primary m-1"
                  onClick={() => {
                    history.push("/chargeback-management/select-chargeback");
                  }}
                >
                  <span>
                    <i className="bi bi-plus"></i>
                  </span>
                  <span>Add Chargeback</span>
                </button>
              </Can>
              <div className="my-auto">
                <SearchList
                  searchColumns={searchColumns}
                  setSearchParams={setSearchParams}
                  limit={limit}
                  activePageNumber={activePageNumber}
                  setActivePageNumber={setActivePageNumber}
                />
              </div>
              <div className="my-auto">
                <Layout
                  addColumns={(data) => addColumns(data)}
                  addSearch={(data) => addSearchColumns(data)}
                  addExport={(data) => addExportColumns(data)}
                />
              </div>
              <Can
                permissons={currentPermissons}
                componentPermissions={EXPORT_PERMISSION}
              >
                <div className="my-auto">
                  <Export
                    exportColumns={exportColumns}
                    searchParams={{
                      ...searchParams,
                      batchId: chargebackBatchId,
                    }}
                  />
                </div>
              </Can>
            </div>
          </div>
          <div className="d-flex justify-content-end my-auto mt-3">
            <Can
              permissons={currentPermissons}
              componentPermissions={CREATE_PERMISSION}
            >
              {["Admin", "Client User"].includes(userType && userType) &&
              chargebackBatchId ? (
                <>
                  <button
                    className="btn btn-sm btn-light-dark m-1"
                    onClick={(event) => {
                      handleDownloadInvoice(event);
                    }}
                  >
                    {loadingADI ? (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>
                        <span>
                          <i class="bi bi-download" />
                        </span>
                        <span>Download Invoice</span>
                      </>
                    )}
                  </button>
                  <button
                    className="btn btn-sm btn-light-info m-1"
                    onClick={() => {
                      handleNotifyToMerchant();
                    }}
                  >
                    {loadingNotifyToMerchant ? (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>
                        <span>
                          <i className="bi bi-bell" />
                        </span>
                        <span>Notify to Merchant</span>
                      </>
                    )}
                  </button>
                  <button
                    className="btn btn-sm btn-light-success m-1"
                    onClick={() => handleApproveAll()}
                  >
                    {loadingUPCBA ? (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>
                        <span>
                          <i className="bi bi-check-circle-fill" />
                        </span>
                        <span>Accept All</span>
                      </>
                    )}
                  </button>
                  <button
                    className="btn btn-sm btn-light-info m-1"
                    onClick={() => handleNotifyToAcquirer()}
                  >
                    {loadingAPCB ? (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>
                        <span>
                          <i className="bi bi-send" />
                        </span>
                        <span>Send Acquirer</span>
                      </>
                    )}
                  </button>
                </>
              ) : null}
              <button
                className="btn btn-sm btn-light-primary m-1"
                onClick={() => {
                  window.open(
                    `https://staging-pred-idpms.chargebackzero.com`,
                    "_blank"
                  );
                }}
              >
                <span>
                  <i className="bi bi-file-earmark-arrow-up" />
                </span>
                <span>Predict Chargeback Status</span>
              </button>
              <button
                className="btn btn-sm btn-light-success m-1"
                onClick={() => {
                  setUploadModalShow(true);
                }}
              >
                <span>
                  <i className="bi bi-plus"></i>
                </span>
                <span>Update Status</span>
              </button>
            </Can>
          </div>
          <div className="table-responsive mt-5">
            <table className="table table-hover table-rounded table-striped border">
              <thead className="fw-bolder fs-8 text-gray-800">
                <tr>
                  <Can
                    permissons={currentPermissons}
                    componentPermissions={UPDATE_DELETE_PERMISSION}
                  >
                    <th className="min-w-70px">Action</th>
                  </Can>
                  {columns && columns.chargebackStatus && (
                    <th>
                      <div className="d-flex">
                        <span>Chargeback Status</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("chargebackStatus")}
                          >
                            <i
                              className={`bi ${
                                sorting.chargebackStatus
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.batchId && (
                    <th>
                      <div className="d-flex">
                        <span>Batch Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("batchId")}
                          >
                            <i
                              className={`bi ${
                                sorting.batchId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.clientId && (
                    <th>
                      <div className="d-flex">
                        <span>Client</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("clientId")}
                          >
                            <i
                              className={`bi ${
                                sorting.clientId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.merchantId && (
                    <th>
                      <div className="d-flex">
                        <span>Merchant</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("merchantId")}
                          >
                            <i
                              className={`bi ${
                                sorting.merchantId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.orderId && (
                    <th>
                      <div className="d-flex">
                        <span>Order Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("orderId")}
                          >
                            <i
                              className={`bi ${
                                sorting.orderId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.productName && (
                    <th>
                      <div className="d-flex">
                        <span>Product Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("productName")}
                          >
                            <i
                              className={`bi ${
                                sorting.productName
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}

                  {columns && columns.createdBy && (
                    <th>
                      <div className="d-flex">
                        <span>Created By</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("createdBy")}
                          >
                            <i
                              className={`bi ${
                                sorting.createdBy
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}

                  {columns && columns.updatedBy && (
                    <th>
                      <div className="d-flex">
                        <span>Updated By</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("updatedBy")}
                          >
                            <i
                              className={`bi ${
                                sorting.updatedBy
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}

                  {columns && columns.chargebackDate && (
                    <th>
                      <div className="d-flex">
                        <span>Chargeback Date</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("chargebackDate")}
                          >
                            <i
                              className={`bi ${
                                sorting.chargebackDate
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.chargebackDeadlineDate && (
                    <th>
                      <div className="d-flex">
                        <span>Chargeback Deadline Date</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("chargebackDeadlineDate")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.chargebackDeadlineDate
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.chargebackAmount && (
                    <th>
                      <div className="d-flex">
                        <span>Chargeback Amount</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("chargebackAmount")}
                          >
                            <i
                              className={`bi ${
                                sorting.chargebackAmount
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.DBAName && (
                    <th>
                      <div className="d-flex">
                        <span>DBA Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("DBAName")}
                          >
                            <i
                              className={`bi ${
                                sorting.DBAName
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardType && (
                    <th>
                      <div className="d-flex">
                        <span>Card Type</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardType")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardType
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.upiId && (
                    <th>
                      <div className="d-flex">
                        <span>UPI Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("upiId")}
                          >
                            <i
                              className={`bi ${
                                sorting.upiId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.transactionAmount && (
                    <th>
                      <div className="d-flex">
                        <span>Transaction Amount</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("transactionAmount")}
                          >
                            <i
                              className={`bi ${
                                sorting.transactionAmount
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.transactionDate && (
                    <th>
                      <div className="d-flex">
                        <span>Transaction Date</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("transactionDate")}
                          >
                            <i
                              className={`bi ${
                                sorting.transactionDate
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.transactionId && (
                    <th>
                      <div className="d-flex">
                        <span>Transaction Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("transactionId")}
                          >
                            <i
                              className={`bi ${
                                sorting.transactionId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.acquirerName && (
                    <th>
                      <div className="d-flex">
                        <span>Acquirer Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("acquirerName")}
                          >
                            <i
                              className={`bi ${
                                sorting.acquirerName
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.affiliateId && (
                    <th>
                      <div className="d-flex">
                        <span>Affliate Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("affiliateId")}
                          >
                            <i
                              className={`bi ${
                                sorting.affiliateId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.arn && (
                    <th>
                      <div className="d-flex">
                        <span>ARN</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("arn")}
                          >
                            <i
                              className={`bi ${
                                sorting.arn
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.authCode && (
                    <th>
                      <div className="d-flex">
                        <span>Auth Code</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("authCode")}
                          >
                            <i
                              className={`bi ${
                                sorting.authCode
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.avs && (
                    <th>
                      <div className="d-flex">
                        <span>AVS Matched</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("avs")}
                          >
                            <i
                              className={`bi ${
                                sorting.avs
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.blacklisted && (
                    <th>
                      <div className="d-flex">
                        <span>Blacklisted</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("blacklisted")}
                          >
                            <i
                              className={`bi ${
                                sorting.blacklisted
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardCountry && (
                    <th>
                      <div className="d-flex">
                        <span>Card Country</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardCountry")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardCountry
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardFirstSix && (
                    <th>
                      <div className="d-flex">
                        <span>Card Bin</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardFirstSix")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardFirstSix
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardLastFour && (
                    <th>
                      <div className="d-flex">
                        <span>Card Last Four Digits</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardLastFour")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardLastFour
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cardNetwork && (
                    <th>
                      <div className="d-flex">
                        <span>Card Network</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cardNetwork")}
                          >
                            <i
                              className={`bi ${
                                sorting.cardNetwork
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.caseId && (
                    <th>
                      <div className="d-flex">
                        <span>Case Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("caseId")}
                          >
                            <i
                              className={`bi ${
                                sorting.caseId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.chargebackCurrency && (
                    <th>
                      <div className="d-flex">
                        <span>Chargeback currency</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("chargebackCurrency")}
                          >
                            <i
                              className={`bi ${
                                sorting.chargebackCurrency
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.chargebackReason && (
                    <th>
                      <div className="d-flex">
                        <span>Chargeback Reason</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("chargebackReason")}
                          >
                            <i
                              className={`bi ${
                                sorting.chargebackReason
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.chargeBackType && (
                    <th>
                      <div className="d-flex">
                        <span>Chargeback Type</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("chargeBackType")}
                          >
                            <i
                              className={`bi ${
                                sorting.chargeBackType
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.companyName && (
                    <th>
                      <div className="d-flex">
                        <span>Company Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("companyName")}
                          >
                            <i
                              className={`bi ${
                                sorting.companyName
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.country && (
                    <th>
                      <div className="d-flex">
                        <span>Country</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("country")}
                          >
                            <i
                              className={`bi ${
                                sorting.country
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.customerEmail && (
                    <th>
                      <div className="d-flex">
                        <span>Customer Email</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("customerEmail")}
                          >
                            <i
                              className={`bi ${
                                sorting.customerEmail
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.customerIP && (
                    <th>
                      <div className="d-flex">
                        <span>Customer IP</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("customerIP")}
                          >
                            <i
                              className={`bi ${
                                sorting.customerIP
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.customerName && (
                    <th>
                      <div className="d-flex">
                        <span>Customer Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("customerName")}
                          >
                            <i
                              className={`bi ${
                                sorting.customerName
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.customerPhone && (
                    <th>
                      <div className="d-flex">
                        <span>Customer Phone</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("customerPhone")}
                          >
                            <i
                              className={`bi ${
                                sorting.customerPhone
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.cvv && (
                    <th>
                      <div className="d-flex">
                        <span>CVV</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("cvv")}
                          >
                            <i
                              className={`bi ${
                                sorting.cvv
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.deviceId && (
                    <th>
                      <div className="d-flex">
                        <span>Device Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("deviceId")}
                          >
                            <i
                              className={`bi ${
                                sorting.deviceId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.disputeFiledDate && (
                    <th>
                      <div className="d-flex">
                        <span>Disputed Filed Date</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("disputeFiledDate")}
                          >
                            <i
                              className={`bi ${
                                sorting.disputeFiledDate
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.disputeMethod && (
                    <th>
                      <div className="d-flex">
                        <span>Dispute Method</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("disputeMethod")}
                          >
                            <i
                              className={`bi ${
                                sorting.disputeMethod
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.functionalArea && (
                    <th>
                      <div className="d-flex">
                        <span>Functional Area</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("functionalArea")}
                          >
                            <i
                              className={`bi ${
                                sorting.functionalArea
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.gateway && (
                    <th>
                      <div className="d-flex">
                        <span>Gateway</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("gateway")}
                          >
                            <i
                              className={`bi ${
                                sorting.gateway
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.issuer && (
                    <th>
                      <div className="d-flex">
                        <span>Issuer</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("issuer")}
                          >
                            <i
                              className={`bi ${
                                sorting.issuer
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.merchantName && (
                    <th>
                      <div className="d-flex">
                        <span>Merchant Name</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("merchantName")}
                          >
                            <i
                              className={`bi ${
                                sorting.merchantName
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.mid && (
                    <th>
                      <div className="d-flex">
                        <span>MID</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("mid")}
                          >
                            <i
                              className={`bi ${
                                sorting.mid
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.nonUs && (
                    <th>
                      <div className="d-flex">
                        <span>Non US</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("nonUs")}
                          >
                            <i
                              className={`bi ${
                                sorting.nonUs
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.paymentId && (
                    <th>
                      <div className="d-flex">
                        <span>Payment Id</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("paymentId")}
                          >
                            <i
                              className={`bi ${
                                sorting.paymentId
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.paymentProcessor && (
                    <th>
                      <div className="d-flex">
                        <span>Payment Processor</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("paymentProcessor")}
                          >
                            <i
                              className={`bi ${
                                sorting.paymentProcessor
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.paymentMethod && (
                    <th>
                      <div className="d-flex">
                        <span>Payment Method</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("paymentMethod")}
                          >
                            <i
                              className={`bi ${
                                sorting.paymentMethod
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.reasonCodeGrouping && (
                    <th>
                      <div className="d-flex">
                        <span>Reason Code Grouping</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("reasonCodeGrouping")}
                          >
                            <i
                              className={`bi ${
                                sorting.reasonCodeGrouping
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.reasonForLegitimateChargeback && (
                    <th>
                      <div className="d-flex">
                        <span>Reason For Legitimate Chargeback</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleSorting("reasonForLegitimateChargeback")
                            }
                          >
                            <i
                              className={`bi ${
                                sorting.reasonForLegitimateChargeback
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.refundIssued && (
                    <th>
                      <div className="d-flex">
                        <span>Refund Issued</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("refundIssued")}
                          >
                            <i
                              className={`bi ${
                                sorting.refundIssued
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.rootCause && (
                    <th>
                      <div className="d-flex">
                        <span>Root Cause</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("rootCause")}
                          >
                            <i
                              className={`bi ${
                                sorting.rootCause
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.serviceType && (
                    <th>
                      <div className="d-flex">
                        <span>Service Type</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("serviceType")}
                          >
                            <i
                              className={`bi ${
                                sorting.serviceType
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.saleType && (
                    <th>
                      <div className="d-flex">
                        <span>Sale Type</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("saleType")}
                          >
                            <i
                              className={`bi ${
                                sorting.saleType
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.subFunctionalArea && (
                    <th>
                      <div className="d-flex">
                        <span>Sub Functional Area</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("subFunctionalArea")}
                          >
                            <i
                              className={`bi ${
                                sorting.subFunctionalArea
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.createdAtFrom && (
                    <th>
                      <div className="d-flex">
                        <span>Created At</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("createdAtFrom")}
                          >
                            <i
                              className={`bi ${
                                sorting.createdAtFrom
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                  {columns && columns.updatedAtFrom && (
                    <th>
                      <div className="d-flex">
                        <span>Updated At</span>
                        <div className="min-w-25px text-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSorting("updatedAtFrom")}
                          >
                            <i
                              className={`bi ${
                                sorting.updatedAtFrom
                                  ? "bi-arrow-up-circle-fill"
                                  : "bi-arrow-down-circle"
                              } text-green`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="fs-8">
                {!loading ? (
                  chargebacks && chargebacks.data && chargebacks.data ? (
                    chargebacks.data.map((chargeback, _id) => {
                      return (
                        <tr
                          key={_id}
                          style={
                            _id === 0
                              ? { borderColor: "black" }
                              : { borderColor: "white" }
                          }
                        >
                          <Can
                            permissons={currentPermissons}
                            componentPermissions={UPDATE_DELETE_PERMISSION}
                          >
                            <td className="min-width-150px">
                              <div>
                                <Can
                                  permissons={currentPermissons}
                                  componentPermissions={UPDATE_PERMISSION}
                                >
                                  <button
                                    className="btn btn-icon btn-icon-primary btn-sm w-10px h-10px "
                                    onClick={() =>
                                      history.push(
                                        `/chargeback-management/update-chargeback/${chargeback._id}`
                                      )
                                    }
                                  >
                                    <i className="bi bi-eye-fill" />
                                  </button>
                                </Can>
                                &nbsp;&nbsp;&nbsp;
                                <Can
                                  permissons={currentPermissons}
                                  componentPermissions={DELETE_PERMISSION}
                                >
                                  <button
                                    className="btn btn-icon btn-icon-danger btn-sm w-10px h-10px"
                                    onClick={() => onDeleteItem(chargeback._id)}
                                  >
                                    {/* {/ eslint-disable /} */}
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen027.svg"
                                      className="svg-icon-3"
                                    />
                                    {/* {/ eslint-enable /} */}
                                  </button>
                                </Can>
                              </div>
                            </td>
                          </Can>
                          {columns && columns.chargebackStatus && (
                            <td>
                              <span
                                className={`badge badge-cb ${
                                  chargeback.chargeBackStatus &&
                                  chargeback.chargeBackStatus === "NOT FAUGHT"
                                    ? CHARGEBACK_STATUS.NOT_FAUGHT
                                    : chargeback.chargeBackStatus &&
                                      chargeback.chargeBackStatus ===
                                        "WAITING FOR EVIDENCE"
                                    ? CHARGEBACK_STATUS.WAITING_FOR_EVIDENCE
                                    : chargeback.chargeBackStatus &&
                                      chargeback.chargeBackStatus ===
                                        "LOST REFUNDED"
                                    ? CHARGEBACK_STATUS.LOST_REFUNDED
                                    : chargeback.chargeBackStatus &&
                                      chargeback.chargeBackStatus ===
                                        "REJECTION DUE TO REFUND"
                                    ? CHARGEBACK_STATUS.REJECTION_DUE_TO_REFUND
                                    : CHARGEBACK_STATUS[
                                        chargeback.chargeBackStatus
                                      ]
                                }`}
                              >
                                {chargeback.chargeBackStatus
                                  ? chargeback.chargeBackStatus
                                  : "--"}
                              </span>
                            </td>
                          )}
                          {columns && columns.batchId && (
                            <td
                              className="ellipsis fw-bolder"
                              style={{ color: "#39619a" }}
                            >
                              {chargeback && chargeback.batchId
                                ? `${chargeback.batchId}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.clientId && (
                            <td className="ellipsis">
                              {chargeback && chargeback.clientId
                                ? `${chargeback.clientId.company}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.merchantId && (
                            <td className="ellipsis">
                              {chargeback && chargeback.merchantId
                                ? `${chargeback.merchantId.merchantName}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.orderId && (
                            <td className="ellipsis">
                              {chargeback.orderId ? chargeback.orderId : "--"}
                            </td>
                          )}
                          {columns && columns.productName && (
                            <td className="ellipsis">
                              {chargeback.productName
                                ? chargeback.productName
                                : "--"}
                            </td>
                          )}

                          {columns && columns.createdBy && (
                            <td className="ellipsis">
                              {chargeback.createdBy
                                ? chargeback.createdBy
                                : "--"}
                            </td>
                          )}

                          {columns && columns.updatedBy && (
                            <td className="ellipsis">
                              {chargeback.updatedBy
                                ? chargeback.updatedBy
                                : "--"}
                            </td>
                          )}

                          {columns && columns.chargebackDate && (
                            <td className="ellipsis">
                              {chargeback && chargeback.chargebackDate
                                ? moment(chargeback.chargebackDate).format(
                                    DATE.DATE_FORMAT_MINS
                                  )
                                : "--"}
                            </td>
                          )}
                          {columns && columns.chargebackDeadlineDate && (
                            <td className="ellipsis">
                              {chargeback && chargeback.chargebackDeadlineDate
                                ? moment(
                                    chargeback.chargebackDeadlineDate
                                  ).format(DATE.DATE_FORMAT_MINS)
                                : "--"}
                            </td>
                          )}
                          {columns && columns.chargebackAmount && (
                            <td className="ellipsis">
                              {chargeback.chargebackAmount
                                ? chargeback.chargebackAmount
                                : "--"}
                            </td>
                          )}
                          {columns && columns.DBAName && (
                            <td className="ellipsis">
                              {chargeback.DBAName ? chargeback.DBAName : "--"}
                            </td>
                          )}
                          {columns && columns.cardType && (
                            <td className="ellipsis">
                              {chargeback.cardType ? chargeback.cardType : "--"}
                            </td>
                          )}
                          {columns && columns.upiId && (
                            <td className="ellipsis">
                              {chargeback.upiId ? chargeback.upiId : "--"}
                            </td>
                          )}
                          {columns && columns.transactionAmount && (
                            <td className="ellipsis">
                              {chargeback.transactionAmount
                                ? chargeback.transactionAmount
                                : "--"}
                            </td>
                          )}
                          {columns && columns.transactionDate && (
                            <td className="ellipsis">
                              {chargeback && chargeback.transactionDate
                                ? moment(chargeback.transactionDate).format(
                                    DATE.DATE_FORMAT_MINS
                                  )
                                : "--"}
                            </td>
                          )}
                          {columns && columns.transactionId && (
                            <td className="ellipsis">
                              {chargeback.transactionId
                                ? chargeback.transactionId
                                : "--"}
                            </td>
                          )}

                          {columns && columns.acquirerName && (
                            <td className="ellipsis">
                              {chargeback.acquirerName
                                ? chargeback.acquirerName
                                : "--"}
                            </td>
                          )}
                          {columns && columns.affiliateId && (
                            <td className="ellipsis">
                              {chargeback.affiliateId
                                ? chargeback.affiliateId
                                : "--"}
                            </td>
                          )}
                          {columns && columns.arn && (
                            <td className="ellipsis">
                              {chargeback.arn ? chargeback.arn : "--"}
                            </td>
                          )}
                          {columns && columns.authCode && (
                            <td className="ellipsis">
                              {chargeback.authCode ? chargeback.authCode : "--"}
                            </td>
                          )}
                          {columns && columns.avs && (
                            <td className="ellipsis">
                              {chargeback.avs ? chargeback.avs : "--"}
                            </td>
                          )}
                          {columns && columns.blacklisted && (
                            <td className="ellipsis">
                              {chargeback.blacklisted
                                ? chargeback.blacklisted
                                : "--"}
                            </td>
                          )}
                          {columns && columns.cardCountry && (
                            <td className="ellipsis">
                              {chargeback && chargeback.cardCountry
                                ? `${chargeback.cardCountry.name}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.cardFirstSix && (
                            <td className="ellipsis">
                              {chargeback.cardFirstSix
                                ? chargeback.cardFirstSix
                                : "--"}
                            </td>
                          )}
                          {columns && columns.cardLastFour && (
                            <td className="ellipsis">
                              {chargeback.cardLastFour
                                ? chargeback.cardLastFour
                                : "--"}
                            </td>
                          )}
                          {columns && columns.cardNetwork && (
                            <td className="ellipsis">
                              {chargeback.cardNetwork
                                ? chargeback.cardNetwork
                                : "--"}
                            </td>
                          )}
                          {columns && columns.caseId && (
                            <td className="ellipsis">
                              {chargeback.caseId ? chargeback.caseId : "--"}
                            </td>
                          )}
                          {columns && columns.chargebackCurrency && (
                            <td className="ellipsis">
                              {chargeback.chargebackCurrency
                                ? chargeback.chargebackCurrency
                                : "--"}
                            </td>
                          )}
                          {columns && columns.chargebackReason && (
                            <td className="ellipsis">
                              {chargeback.chargebackReason
                                ? chargeback.chargebackReason
                                : "--"}
                            </td>
                          )}
                          {columns && columns.chargeBackType && (
                            <td className="ellipsis">
                              {chargeback.chargeBackType
                                ? chargeback.chargeBackType
                                : "--"}
                            </td>
                          )}
                          {columns && columns.companyName && (
                            <td className="ellipsis">
                              {chargeback.companyName
                                ? chargeback.companyName
                                : "--"}
                            </td>
                          )}
                          {columns && columns.country && (
                            <td className="ellipsis">
                              {chargeback.country ? chargeback.country : "--"}
                            </td>
                          )}
                          {columns && columns.customerEmail && (
                            <td className="ellipsis">
                              {chargeback.customerEmail
                                ? chargeback.customerEmail
                                : "--"}
                            </td>
                          )}
                          {columns && columns.customerIP && (
                            <td className="ellipsis">
                              {chargeback.customerIP
                                ? chargeback.customerIP
                                : "--"}
                            </td>
                          )}
                          {columns && columns.customerName && (
                            <td className="ellipsis">
                              {chargeback.customerName
                                ? chargeback.customerName
                                : "--"}
                            </td>
                          )}
                          {columns && columns.customerPhone && (
                            <td className="ellipsis">
                              {chargeback.customerPhone
                                ? chargeback.customerPhone
                                : "--"}
                            </td>
                          )}
                          {columns && columns.cvv && (
                            <td className="ellipsis">
                              {chargeback.cvv ? chargeback.cvv : "--"}
                            </td>
                          )}
                          {columns && columns.deviceId && (
                            <td className="ellipsis">
                              {chargeback.deviceId ? chargeback.deviceId : "--"}
                            </td>
                          )}
                          {columns && columns.disputeFiledDate && (
                            <td className="ellipsis">
                              {chargeback && chargeback.disputeFiledDate
                                ? moment(chargeback.disputeFiledDate).format(
                                    DATE.DATE_FORMAT_MINS
                                  )
                                : "--"}
                            </td>
                          )}
                          {columns && columns.disputeMethod && (
                            <td className="ellipsis">
                              {chargeback.disputeMethod
                                ? chargeback.disputeMethod
                                : "--"}
                            </td>
                          )}
                          {columns && columns.functionalArea && (
                            <td className="ellipsis">
                              {chargeback && chargeback.functionalArea
                                ? `${chargeback.functionalArea.functionalType}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.gateway && (
                            <td className="ellipsis">
                              {chargeback.gateway ? chargeback.gateway : "--"}
                            </td>
                          )}
                          {columns && columns.issuer && (
                            <td className="ellipsis">
                              {chargeback.issuer ? chargeback.issuer : "--"}
                            </td>
                          )}
                          {columns && columns.merchantName && (
                            <td className="ellipsis">
                              {chargeback.merchantName
                                ? chargeback.merchantName
                                : "--"}
                            </td>
                          )}
                          {columns && columns.mid && (
                            <td className="ellipsis">
                              {chargeback.mid ? chargeback.mid : "--"}
                            </td>
                          )}
                          {columns && columns.nonUs && (
                            <td className="ellipsis">
                              {chargeback && chargeback.nonUs
                                ? `${chargeback.nonUs.name}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.paymentId && (
                            <td className="ellipsis">
                              {chargeback.paymentId
                                ? chargeback.paymentId
                                : "--"}
                            </td>
                          )}
                          {columns && columns.paymentProcessor && (
                            <td className="ellipsis">
                              {chargeback && chargeback.paymentProcessor
                                ? `${chargeback.paymentProcessor.processorName}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.paymentMethod && (
                            <td className="ellipsis">
                              {chargeback.paymentMethod
                                ? chargeback.paymentMethod
                                : "--"}
                            </td>
                          )}
                          {columns && columns.reasonCodeGrouping && (
                            <td className="ellipsis">
                              {chargeback && chargeback.reasonCodeGrouping
                                ? `${chargeback.reasonCodeGrouping.reasonCodeGrouping}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.reasonForLegitimateChargeback && (
                            <td className="ellipsis">
                              {chargeback.reasonForLegitimateChargeback
                                ? chargeback.reasonForLegitimateChargeback
                                : "--"}
                            </td>
                          )}
                          {columns && columns.refundIssued && (
                            <td className="ellipsis">
                              {chargeback.refundIssued
                                ? chargeback.refundIssued
                                : "--"}
                            </td>
                          )}
                          {columns && columns.rootCause && (
                            <td className="ellipsis">
                              {chargeback && chargeback.rootCause
                                ? `${chargeback.rootCause.rootCauseType}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.serviceType && (
                            <td className="ellipsis">
                              {chargeback && chargeback.serviceType
                                ? `${chargeback.serviceType.serviceType}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.saleType && (
                            <td className="ellipsis">
                              {chargeback.saleType ? chargeback.saleType : "--"}
                            </td>
                          )}
                          {columns && columns.subFunctionalArea && (
                            <td className="ellipsis">
                              {chargeback && chargeback.subFunctionalArea
                                ? `${chargeback.subFunctionalArea.subFunctionalType}`
                                : "--"}
                            </td>
                          )}
                          {columns && columns.createdAtFrom && (
                            <td className="ellipsis">
                              {chargeback && chargeback.createdAt
                                ? moment(chargeback.createdAt).format(
                                    DATE.DATE_FORMAT_MINS
                                  )
                                : "--"}
                            </td>
                          )}
                          {columns && columns.updatedAtFrom && (
                            <td className="ellipsis">
                              {chargeback && chargeback.updatedAt
                                ? moment(chargeback.updatedAt).format(
                                    DATE.DATE_FORMAT_MINS
                                  )
                                : "--"}
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center py-3">
                      <td colSpan="100%">No record(s) found</td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan="100%" className="text-center">
                      <div
                        className="spinner-border text-primary m-5"
                        role="status"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="form-group row mb-4 mt-6">
            <div className="col-lg-12 mb-4 align-items-end d-flex">
              <div className="col-lg-12 pagination">
                <ReactPaginate
                  nextLabel="Next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  forcePage={activePageNumber - 1}
                  pageCount={totalPages}
                  previousLabel="< Prev"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    chargebackStore,
    chargebackDeleteStore,
    approveCBStore,
    partnerCrudFilterStore,
    updateCBApproveStore,
    addNotificationToMerchantStore,
    singleNotifyToMerchantStore,
    singleApproveMerchantStore,
    downloadInvoiceStore,
  } = state;
  return {
    chargebacks:
      chargebackStore && chargebackStore.chargebacks
        ? chargebackStore.chargebacks
        : [],
    loading:
      chargebackStore && chargebackStore.loading
        ? chargebackStore.loading
        : false,
    deleteChargeback:
      chargebackDeleteStore && chargebackDeleteStore.deleteChargeback
        ? chargebackDeleteStore.deleteChargeback
        : [],
    deleteChargebackStatus:
      chargebackDeleteStore && chargebackDeleteStore.deleteChargebackStatus
        ? chargebackDeleteStore.deleteChargebackStatus
        : " ",
    deleteChargebackMessage:
      chargebackDeleteStore && chargebackDeleteStore.deleteChargebackMessage
        ? chargebackDeleteStore.deleteChargebackMessage
        : " ",
    deleteChargebackLoading:
      chargebackDeleteStore && chargebackDeleteStore.loading
        ? chargebackDeleteStore.loading
        : false,
    statusAPCB:
      approveCBStore && approveCBStore.statusAPCB
        ? approveCBStore.statusAPCB
        : " ",
    messageAPCB:
      approveCBStore && approveCBStore.messageAPCB
        ? approveCBStore.messageAPCB
        : " ",
    loadingAPCB:
      approveCBStore && approveCBStore.loadingAPCB
        ? approveCBStore.loadingAPCB
        : false,
    setFilterFunction:
      partnerCrudFilterStore && partnerCrudFilterStore.setFilterFunction
        ? partnerCrudFilterStore.setFilterFunction
        : false,
    setCredFilterParams:
      partnerCrudFilterStore && partnerCrudFilterStore.setCredFilterParams
        ? partnerCrudFilterStore.setCredFilterParams
        : {},
    loadingUPCBA:
      updateCBApproveStore && updateCBApproveStore.loadingUPCBA
        ? updateCBApproveStore.loadingUPCBA
        : false,
    statusUPCBA:
      updateCBApproveStore && updateCBApproveStore.statusUPCBA
        ? updateCBApproveStore.statusUPCBA
        : " ",
    messageUPCBA:
      updateCBApproveStore && updateCBApproveStore.messageUPCBA
        ? updateCBApproveStore.messageUPCBA
        : " ",
    dataNotifyMerchant:
      addNotificationToMerchantStore &&
      addNotificationToMerchantStore.dataNotifyMerchant
        ? addNotificationToMerchantStore.dataNotifyMerchant
        : [],
    loadingNotifyToMerchant:
      addNotificationToMerchantStore &&
      addNotificationToMerchantStore.loadingNotifyToMerchant
        ? addNotificationToMerchantStore.loadingNotifyToMerchant
        : false,
    statusNotifyMerchant:
      addNotificationToMerchantStore &&
      addNotificationToMerchantStore.statusNotifyMerchant
        ? addNotificationToMerchantStore.statusNotifyMerchant
        : " ",
    messageNotifyMerchant:
      addNotificationToMerchantStore &&
      addNotificationToMerchantStore.messageNotifyMerchant
        ? addNotificationToMerchantStore.messageNotifyMerchant
        : " ",
    loadingSingleNotifyToMerchant:
      singleNotifyToMerchantStore &&
      singleNotifyToMerchantStore.loadingSingleNotifyToMerchant
        ? singleNotifyToMerchantStore.loadingSingleNotifyToMerchant
        : false,
    statusSingleNotifyMerchant:
      singleNotifyToMerchantStore &&
      singleNotifyToMerchantStore.statusSingleNotifyMerchant
        ? singleNotifyToMerchantStore.statusSingleNotifyMerchant
        : " ",
    messageSingleNotifyMerchant:
      singleNotifyToMerchantStore &&
      singleNotifyToMerchantStore.messageSingleNotifyMerchant
        ? singleNotifyToMerchantStore.messageSingleNotifyMerchant
        : " ",
    loadingSingleApproveMerchant:
      singleApproveMerchantStore &&
      singleApproveMerchantStore.loadingSingleApproveMerchant
        ? singleApproveMerchantStore.loadingSingleApproveMerchant
        : false,
    statusSingleApproveMerchant:
      singleApproveMerchantStore &&
      singleApproveMerchantStore.statusSingleApproveMerchant
        ? singleApproveMerchantStore.statusSingleApproveMerchant
        : " ",
    messageSingleApproveMerchant:
      singleApproveMerchantStore &&
      singleApproveMerchantStore.messageSingleApproveMerchant
        ? singleApproveMerchantStore.messageSingleApproveMerchant
        : " ",
    loadingADI:
      downloadInvoiceStore && downloadInvoiceStore.loadingADI
        ? downloadInvoiceStore.loadingADI
        : false,
    statusADI:
      downloadInvoiceStore && downloadInvoiceStore.statusADI
        ? downloadInvoiceStore.statusADI
        : " ",
    messageADI:
      downloadInvoiceStore && downloadInvoiceStore.messageADI
        ? downloadInvoiceStore.messageADI
        : " ",
    dataADI:
      downloadInvoiceStore && downloadInvoiceStore.dataADI
        ? downloadInvoiceStore.dataADI
        : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  getChargebackDispatch: (params, headers) =>
    dispatch(chargebackActions.getChargeback(params, headers)),
  deleteChargebackDispatch: (data, headers) =>
    dispatch(deleteChargebackActions.deleteChargeback(data, headers)),
  clearDeleteChargebackDispatch: () =>
    dispatch(deleteChargebackActions.clearDeleteChargeback()),
  setFilterFunctionDispatch: (data) =>
    dispatch(partnerCredFilterActions.setFilterFunction(data)),
  approveCBActionDispatch: (data, headers) =>
    dispatch(approveCBActions.add(data, headers)),
  clearApproveCBActionDispatch: () => dispatch(approveCBActions.clear()),
  updateCBApproveDispatch: (data, headers) =>
    dispatch(updateCBApprove.add(data, headers)),
  clearUpdateCBApproveDispatch: () => dispatch(updateCBApprove.clear()),
  singleNotifyToMerchantDispatch: (data, headers) =>
    dispatch(singleNotifyToMerchant.add(data, headers)),
  clearSingleNotifyToMerchantDispatch: () =>
    dispatch(singleNotifyToMerchant.clear()),
  singleApproveMerchantDispatch: (data, headers) =>
    dispatch(singleApproveMerchant.add(data, headers)),
  clearSingleApproveMerchantDispatch: () =>
    dispatch(singleApproveMerchant.clear()),
  addNotificationToMerchantDispatch: (data, url, headers) =>
    dispatch(addNotificationToMerchant.add(data, url, headers)),
  clearNotificationToMerchantDispatch: () =>
    dispatch(addNotificationToMerchant.clear()),
  addDownloadDispatch: (params, headers) =>
    dispatch(downloadInvoiceActions.get(params, headers)),
  clearaddDownloadDispatch: () => dispatch(downloadInvoiceActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChargebackList);
