import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import { exportActions, exportActionsTypes } from '../actions'

function * fetchExport (action) {
  const { url, params, headers } = action
  try {
    const json = yield axiosInstance.get(url, { params, headers:headers })
    if (json.status === 200) {
      yield put(exportActions.saveexportResponse(json.data))
    } else {
      yield put(exportActions.saveexportResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: exportActionsTypes.SAVE_EXPORT_RESPONSE, data })
  }
}

export function * fetchExportWatcher () {
  yield all([
    yield takeLatest(exportActionsTypes.GET_EXPORT, fetchExport)
  ])
}
