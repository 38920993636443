export const crmActionsTypes = {
  GET_CRM: 'GET_CRM',
  SAVE_CRM_RESPONSE: 'SAVE_CRM_RESPONSE',
  CLEAR_CRM: 'CLEAR_CRM'
}

export const crmActions = {
  getCRM: (params, headers) => ({
    type: crmActionsTypes.GET_CRM,
    params, headers
  }),
  saveCRMResponse: data => ({
    type: crmActionsTypes.SAVE_CRM_RESPONSE,
    data
  }),
  clearCRM: () => ({
    type: crmActionsTypes.CLEAR_CRM
  })
}

export const packageActionsTypes = {
  GET_PACKAGE: 'GET_PACKAGE',
  SAVE_PACKAGE_RESPONSE: 'SAVE_PACKAGE_RESPONSE',
  CLEAR_PACKAGE: 'CLEAR_PACKAGE'
}

export const packageActions = {
  getPackage: (params, headers) => ({
    type: packageActionsTypes.GET_PACKAGE,
    params, headers
  }),
  savePackageResponse: data => ({
    type: packageActionsTypes.SAVE_PACKAGE_RESPONSE,
    data
  }),
  clearPackage: () => ({
    type: packageActionsTypes.CLEAR_PACKAGE
  })
}

export const listActionsTypes = {
  REQUEST: 'GET_PACKAGE_LIST',
  RESPONSE: 'SAVE_PACKAGE_LIST_RESPONSE',
  CLEAR: 'CLEAR_PACKAGE_LIST'
}

export const listActions = {
  get: (params, headers) => ({
    type: listActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: data => ({
    type: listActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: listActionsTypes.CLEAR
  })
}

export const packPaymentActionTypes = {
  GET_PACK_PAYMENT: 'GET_PACK_PAYMENT',
  SAVE_PACK_PAYMENT_RESPONSE: 'SAVE_PACK_PAYMENT_RESPONSE',
  CLEAR_PACK_PAYMENT: 'CLEAR_PACK_PAYMENT'
}

export const packPaymentActions = {
  getPackPayment: (params, headers) => ({
    type: packPaymentActionTypes.GET_PACK_PAYMENT,
    params,
    headers
  }),
  savePackPaymentResponse: data => ({
    type: packPaymentActionTypes.SAVE_PACK_PAYMENT_RESPONSE,
    data
  }),
  clearPackPayment: () => ({
    type: packPaymentActionTypes.CLEAR_PACK_PAYMENT
  })
}

export const packageGetDetailsTypes = {
  GET_PACKAGE_DETAILS: 'GET_PACKAGE_DETAILS',
  PACKAGE_DETAILS_RESPONSE: 'PACKAGE_DETAILS_RESPONSE',
  CLEAR_PACKAGE_DETAILS: 'CLEAR_PACKAGE_DETAILS'
}
export const packageGetDetailsActions = {
  getPackageDetails: (id, headers) => ({
    type: packageGetDetailsTypes.GET_PACKAGE_DETAILS,
    id, headers
  }),
  savePackageDetailsResponse: data => ({
    type: packageGetDetailsTypes.PACKAGE_DETAILS_RESPONSE,
    data
  }),
  clearPackageDetails: () => ({
    type: packageGetDetailsTypes.CLEAR_PACKAGE_DETAILS
  })
}

export const editPackageTypes = {
  REQUEST: 'EDIT_PACKAGE_REQUEST',
  RESPONSE: 'EDIT_PACKAGE_RESPONSE',
  ERROR: 'EDIT_PACKAGE_ERROR',
  CLEAR: 'EDIT_PACKAGE_CLEAR'
}

export const editPackageActions = {
  editPackage: (id, params, headers) => {
    return {
      type: editPackageTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditPackageResponse: (data) => {
    return {
      type: editPackageTypes.RESPONSE,
      data
    }
  },
  cleareditPackage: () => ({
    type: editPackageTypes.CLEAR
  })
}

export const userActionsTypes = {
  GET_USER: 'GET_USER',
  SAVE_USER_RESPONSE: 'SAVE_USER_RESPONSE',
  CLEAR_USER: 'CLEAR_USER'
}

export const userActions = {
  getUser: (params, headers) => ({
    type: userActionsTypes.GET_USER,
    params, headers
  }),
  saveUserResponse: data => ({
    type: userActionsTypes.SAVE_USER_RESPONSE,
    data
  }),
  clearUser: () => ({
    type: userActionsTypes.CLEAR_USER
  })
}

export const addUserTypes = {
  REQUEST: 'ADD_USER_INFO_REQUEST',
  RESPONSE: 'ADD_USER_INFO_RESPONSE',
  ERROR: 'ADD_USER_INFO_ERROR',
  CLEAR: 'ADD_USER_INFO_CLEAR'
}

export const addUserActions = {
  addUser: (data, headers) => {
    return {
      type: addUserTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddUserResponse: (data) => {
    return {
      type: addUserTypes.RESPONSE,
      data
    }
  },
  clearaddUser: () => ({
    type: addUserTypes.CLEAR
  })
}

export const userGetDetailsTypes = {
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  USER_DETAILS_RESPONSE: 'USER_DETAILS_RESPONSE',
  CLEAR_USER_DETAILS: 'CLEAR_USER_DETAILS'
}
export const userGetDetailsActions = {
  getUserDetails: (id, headers) => ({
    type: userGetDetailsTypes.GET_USER_DETAILS,
    id, headers
  }),
  saveUserDetailsResponse: data => ({
    type: userGetDetailsTypes.USER_DETAILS_RESPONSE,
    data
  }),
  clearUserDetails: () => ({
    type: userGetDetailsTypes.CLEAR_USER_DETAILS
  })
}

export const editUserTypes = {
  REQUEST: 'EDIT_USER_REQUEST',
  RESPONSE: 'EDIT_USER_RESPONSE',
  ERROR: 'EDIT_USER_ERROR',
  CLEAR: 'EDIT_USER_CLEAR'
}

export const editUserActions = {
  editUser: (id, params, headers) => {
    return {
      type: editUserTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditUserResponse: (data) => {
    return {
      type: editUserTypes.RESPONSE,
      data
    }
  },
  cleareditUser: () => ({
    type: editUserTypes.CLEAR
  })
}

export const deleteUsersActionsTypes = {
  REQUEST: 'DELETE_USERS_REQUEST',
  RESPONSE: 'DELETE_USERS_RESPONSE',
  ERROR: 'DELETE_USERS_ERROR',
  CLEAR: 'DELETE_USERS_CLEAR'
}

export const deleteUsersActions = {
  delete: (id, headers) => ({
    type: deleteUsersActionsTypes.REQUEST,
    id, headers
  }),
  savemResponse: (data) => ({
    type: deleteUsersActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteUsersActionsTypes.CLEAR
  })
}

export const processorActionsTypes = {
  GET_PROCESSOR: 'GET_PROCESSOR',
  SAVE_PROCESSOR_RESPONSE: 'SAVE_PROCESSOR_RESPONSE',
  CLEAR_PROCESSOR: 'CLEAR_PROCESSOR'
}

export const processorActions = {
  getProcessor: (params, headers) => ({
    type: processorActionsTypes.GET_PROCESSOR,
    params, headers
  }),
  saveProcessorResponse: data => ({
    type: processorActionsTypes.SAVE_PROCESSOR_RESPONSE,
    data
  }),
  clearProcessor: () => ({
    type: processorActionsTypes.CLEAR_PROCESSOR
  })
}

export const paymentActionsTypes = {
  GET_PAYMENT: 'GET_PAYMENT',
  SAVE_PAYMENT_RESPONSE: 'SAVE_PAYMENT_RESPONSE',
  CLEAR_PAYMENT: 'CLEAR_PAYMENT'
}

export const paymentActions = {
  getPayment: (params, headers) => ({
    type: paymentActionsTypes.GET_PAYMENT,
    params, headers
  }),
  savePaymentResponse: data => ({
    type: paymentActionsTypes.SAVE_PAYMENT_RESPONSE,
    data
  }),
  clearPayment: () => ({
    type: paymentActionsTypes.CLEAR_PAYMENT
  })
}

export const clientPackagesActionsTypes = {
  GET_CLIENT_PACKAGES: 'GET_CLIENT_PACKAGES',
  SAVE_CLIENT_PACKAGES_RESPONSE: 'SAVE_CLIENT_PACKAGES_RESPONSE',
  CLEAR_CLIENT_PACKAGES: 'CLEAR_CLIENT_PACKAGES'
}

export const clientPackagesActions = {
  getPayment: (headers) => ({
    type: clientPackagesActionsTypes.GET_CLIENT_PACKAGES,
    headers
  }),
  savePaymentResponse: data => ({
    type: clientPackagesActionsTypes.SAVE_CLIENT_PACKAGES_RESPONSE,
    data
  }),
  clearPayment: () => ({
    type: clientPackagesActionsTypes.CLEAR_CLIENT_PACKAGES
  })
}

export const partnerClientActionsTypes = {
  GET_PARTNER_CLIENT: 'GET_PARTNER_CLIENT',
  SAVE_PARTNER_CLIENT_RESPONSE: 'SAVE_PARTNER_CLIENT_RESPONSE',
  CLEAR_PARTNER_CLIENT: 'CLEAR_PARTNER_CLIENT'
}

export const partnerClientActions = {
  getPartnerClient: (data, headers) => ({
    type: partnerClientActionsTypes.GET_PARTNER_CLIENT,
    data,
    headers
  }),
  savePartnerClientResponse: data => ({
    type: partnerClientActionsTypes.SAVE_PARTNER_CLIENT_RESPONSE,
    data
  }),
  clearPartnerClient: () => ({
    type: partnerClientActionsTypes.CLEAR_PARTNER_CLIENT
  })
}

export const addPartnersClientTypes = {
  REQUEST: 'ADD_DELETE_PARTNERS_CLIENT_REQUEST',
  RESPONSE: 'ADD_DELETE_PARTNERS_CLIENT_RESPONSE',
  ERROR: 'ADD_DELETE_PARTNERS_CLIENT_ERROR',
  CLEAR: 'ADD_DELETE_PARTNERS_CLIENT_CLEAR'
}

export const addPartnersClientAction = {
  addPartnersClient: (data, params, headers) => {
    return {
      type: addPartnersClientTypes.REQUEST,
      payload: data,
      params,
      headers
    }
  },
  saveAddPartnersClientResponse: (data) => {
    return {
      type: addPartnersClientTypes.RESPONSE,
      data
    }
  },
  clearAddPartnersClient: () => ({
    type: addPartnersClientTypes.CLEAR
  })
}

export const clientcrmActionsTypes = {
  GET_CLIENT_CRM_DETAILS: 'GET_CLIENT_CRM_DETAILS',
  SAVE_CLIENT_CRM_RESPONSE: 'SAVE_CLIENT_CRM_RESPONSE',
  CLEAR_CLIENT_CRM: 'CLEAR_CLIENT_CRM'
}

export const clientcrmActions = {
  getClientCrm: (id, headers) => ({
    type: clientcrmActionsTypes.GET_CLIENT_CRM_DETAILS,
    id, headers
  }),
  saveClientCrmResponse: data => ({
    type: clientcrmActionsTypes.SAVE_CLIENT_CRM_RESPONSE,
    data
  }),
  clearClientCrm: () => ({
    type: clientcrmActionsTypes.CLEAR_CLIENT_CRM
  })
}
