export const addProcessorSettingsActionsTypes = {
  REQUEST: 'ADD_PROCESSOR_SETTINGS_REQUEST',
  RESPONSE: 'ADD_PROCESSOR_SETTINGS_RESPONSE',
  ERROR: 'ADD_PROCESSOR_SETTINGS_ERROR',
  CLEAR: 'ADD_PROCESSOR_SETTINGS_CLEAR'
}

export const addProcessorSettingsActions = {
  add: (data, headers) => {
    return {
      type: addProcessorSettingsActionsTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addProcessorSettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addProcessorSettingsActionsTypes.CLEAR
  })
}

export const getProcessorSettingsDetailsActionsTypes = {
  REQUEST: 'GET_PROCESSOR_DETAILS_REQUEST',
  RESPONSE: 'GET_PROCESSOR_DETAILS_RESPONSE',
  ERROR: 'GET_PROCESSOR_DETAILS_ERROR',
  CLEAR: 'GET_PROCESSOR_DETAILS_CLEAR'
}
export const getProcessorSettingsDetailsActions = {
  getDetails: (id, headers) => ({
    type: getProcessorSettingsDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getProcessorSettingsDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getProcessorSettingsDetailsActionsTypes.CLEAR
  })
}

export const updateProcessorSettingsActionsTypes = {
  REQUEST: 'UPDATE_PROCESSOR_SETTINGS_REQUEST',
  RESPONSE: 'UPDATE_PROCESSOR_SETTINGS_RESPONSE',
  ERROR: 'UPDATE_PROCESSOR_SETTINGS_ERROR',
  CLEAR: 'UPDATE_PROCESSOR_SETTINGS_CLEAR'
}

export const updateProcessorSettingsActions = {
  update: (id, params, headers) => {
    return {
      type: updateProcessorSettingsActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updateProcessorSettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updateProcessorSettingsActionsTypes.CLEAR
  })
}

export const deleteProcessorSettingsActionsTypes = {
  REQUEST: 'DELETE_PROCESSOR_SETTINGS_REQUEST',
  RESPONSE: 'DELETE_PROCESSOR_SETTINGS_RESPONSE',
  ERROR: 'DELETE_PROCESSOR_SETTINGS_ERROR',
  CLEAR: 'DELETE_PROCESSOR_SETTINGS_CLEAR'
}

export const deleteProcessorSettingsActions = {
  delete: (params, headers) => ({
    type: deleteProcessorSettingsActionsTypes.REQUEST,
    params, headers
  }),
  savemResponse: (data) => ({
    type: deleteProcessorSettingsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteProcessorSettingsActionsTypes.CLEAR
  })
}
