import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { REGEX, STATUS_RESPONSE, SESSION, API } from "../../../utils/constants";
import { addMerchantOnlyValidation } from "../onboarding/userDetails/validation";
import _ from "lodash";
import {
  setMerchantData,
  getMerchantPayload,
} from "../onboarding/functions/formData";
import { warningAlert, confirmAlert } from "../../../utils/alerts";
import { getLocalStorage, headersWithAuth } from "../../../utils/helper";
import color from "../../../utils/colors";
import {
  countryActions,
  stateActions,
  cityActions,
  merchantGetDetailsActions,
  editMerchantActions,
  industryActions,
} from "../../../store/actions";
import ReactSelect from "../../../theme/layout/components/ReactSelect";

const Step1 = (props) => {
  const {
    getCountryDispatch,
    getCountrys,
    loadingCountry,
    getStateDispatch,
    getStates,
    loadingState,
    getCityDispatch,
    getCitys,
    loadingCity,
    getMerchantDetailsDispatch,
    merchantGetDetails,
    statusGMD,
    clearMerchantDetailsDispatch,
    loadingGMD,
    editMerchantDispatch,
    clearEditMerchantDispatch,
    statusEMD,
    messageEMD,
    loadingEMD,
    getIndustryDispatch,
    getIndustry,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };

  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update-merchant");
  const id = url && url[1];
  const [countryOption, setCountryOption] = useState();
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [stateOption, setStateOption] = useState();
  const [selectedStateOption, setSelectedStateOption] = useState("");
  const [cityOptions, setCityOptions] = useState();
  const [editMode, setEditMode] = useState(false);
  const [selectedCityOption, setSelectedCityOption] = useState("");
  const [selectedIndustryOption, setSelectedIndustryOption] = useState("");
  const [industryOption, setIndustryOption] = useState();
  const [errors, setErrors] = useState({});
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [onBlurOptions, setOnBlurOptions] = useState({
    state: false,
    city: false,
  });
  const [formData, setFormData] = useState({
    merchantName: "",
    country: "",
    industry: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    phoneNumber: "",
    phoneNumberExtension: "",
    externalId: "",
    companyDescription: "",
  });

  const params = {
    skipPagination: true,
  };
  useEffect(() => {
    getCountryDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    getIndustryDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }, []);

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = () => {
    const errorMsg = addMerchantOnlyValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      if (editMode) {
        const getPayload = getMerchantPayload(formData);
        editMerchantDispatch(
          id,
          getPayload,
          isAuthorized ? headersWithAuth : headercurrentWithApiKey
        );
      }
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getStateDispatch(
            {
              countryId: selectedOption.value,
              skipPagination: true,
            },
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      );
      setSelectedCountryOption(selectedOption);
      setFormData((values) => ({
        ...values,
        country: selectedOption.value,
        state: "",
        city: "",
      }));
      setSelectedStateOption();
      setSelectedCityOption();
    } else {
      setSelectedCountryOption();
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({
        ...values,
        country: "",
        state: "",
        city: "",
      }));
    }
    setErrors({ ...errors, country: "" });
  };

  const handleChangeState = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getCityDispatch(
            {
              stateId: selectedOption.value,
              skipPagination: true,
            },
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      );
      setSelectedStateOption(selectedOption);
      setFormData((values) => ({
        ...values,
        state: selectedOption.value,
        city: "",
      }));
      setSelectedCityOption();
    } else {
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({ ...values, state: "", city: "" }));
    }
    setErrors({ ...errors, state: "" });
  };

  const handleChangeCity = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCityOption(selectedOption);
      setFormData((values) => ({ ...values, city: selectedOption.value }));
    } else {
      setSelectedCityOption();
      setFormData((values) => ({ ...values, city: "" }));
    }
    setErrors({ ...errors, city: "" });
  };
  const handleSelectBlur = (e, name) => {
    typingTimeout && clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (name === "state" && onBlurOptions.state) {
          const param = { limit: 25 };
          getStateDispatch(
            param,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setOnBlurOptions((values) => ({ ...values, state: false }));
        }
        if (name === "city" && onBlurOptions.city) {
          const param = { limit: 25 };
          getCityDispatch(
            param,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setOnBlurOptions((values) => ({ ...values, city: false }));
        }
      }, 1000)
    );
  };

  const handleSelectInputChange = (typedOption, name) => {
    if (typedOption.length >= 3) {
      typingTimeout && clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          if (
            name === "state" &&
            !(selectedCountryOption && selectedCountryOption.value)
          ) {
            const param = { name: typedOption, skipPagination: true };
            getStateDispatch(
              param,
              isAuthorized ? headersWithAuth : headercurrentWithApiKey
            );
            setOnBlurOptions((values) => ({ ...values, state: true }));
          }
          if (
            name === "city" &&
            !(selectedStateOption && selectedStateOption.value)
          ) {
            const param = { name: typedOption, skipPagination: true };
            getCityDispatch(
              param,
              isAuthorized ? headersWithAuth : headercurrentWithApiKey
            );
            setOnBlurOptions((values) => ({ ...values, city: true }));
          }
        }, 2000)
      );
    }
  };

  const handleChangeIndustry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedIndustryOption(selectedOption);
      setFormData((values) => ({ ...values, industry: selectedOption.value }));
      setErrors({ ...errors, industry: "" });
    } else {
      setSelectedIndustryOption();
      setFormData((values) => ({ ...values, industry: "" }));
    }
  };

  useEffect(() => {
    const country = getDefaultOptions(getCountrys);
    setCountryOption(country);
  }, [getCountrys]);

  useEffect(() => {
    const data = getDefaultIndustryOptions(getIndustry, "industryType");
    setIndustryOption(data);
  }, [getIndustry]);

  useEffect(() => {
    if (merchantGetDetails && merchantGetDetails.merchantCountry) {
      const country = getDefaultOptions(getCountrys);
      const selOption = _.filter(country, function (x) {
        if (_.includes(merchantGetDetails.merchantCountry, x.value)) {
          return x;
        }
      });
      if (selOption.length > 0) {
        setSelectedCountryOption(selOption);
      }
    }
  }, [merchantGetDetails && merchantGetDetails.merchantCountry]);

  useEffect(() => {
    if (statusGMD === STATUS_RESPONSE.SUCCESS_MSG) {
      if (merchantGetDetails && merchantGetDetails._id) {
        const data = setMerchantData(merchantGetDetails);
        setFormData(data);
        if (merchantGetDetails.merchantCountry) {
          const country = getDefaultOptions(getCountrys);
          const selOption = _.filter(country, function (x) {
            if (_.includes(merchantGetDetails.merchantCountry, x.value)) {
              return x;
            }
          });
          if (selOption.length > 0) {
            setSelectedCountryOption(selOption);
          }
        }
        if (merchantGetDetails.merchantState) {
          const state = getDefaultOptions(getStates);
          const selOption = _.filter(state, function (x) {
            if (_.includes(merchantGetDetails.merchantState, x.value)) {
              return x;
            }
          });
          if (selOption.length > 0) {
            setSelectedStateOption(selOption);
          }
        }
        if (merchantGetDetails.merchantCity) {
          const city = getDefaultOptions(getCitys);
          const selOption = _.filter(city, function (x) {
            if (_.includes(merchantGetDetails.merchantCity, x.value)) {
              return x;
            }
          });
          if (selOption.length > 0) {
            setSelectedCityOption(selOption);
          }
        }
        if (merchantGetDetails.industry) {
          const data = getDefaultIndustryOptions(getIndustry, "industryType");
          const selOption = _.filter(data, function (x) {
            if (_.includes(merchantGetDetails.industry, x.value)) {
              return x;
            }
          });
          setSelectedIndustryOption(selOption);
        }
        clearMerchantDetailsDispatch();
      }
    }
  }, [statusGMD]);

  useEffect(() => {
    const state = getDefaultOptions(getStates);
    setStateOption(state);
  }, [getStates]);

  useEffect(() => {
    const city = getDefaultOptions(getCitys);
    setCityOptions(city);
  }, [getCitys]);

  const getDefaultOptions = (rawData) => {
    const defaultOptions = [];
    for (const item in rawData) {
      defaultOptions.push({
        label: rawData[item].name,
        value: rawData[item]._id,
      });
    }
    return defaultOptions;
  };

  const getDefaultIndustryOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  const onConfirm = () => {
    getMerchantDetailsDispatch(
      id,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  useEffect(() => {
    if (statusEMD === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageEMD,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearEditMerchantDispatch();
    } else if (statusEMD === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageEMD, "", "Ok");
    }
    clearEditMerchantDispatch();
  }, [statusEMD]);

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getMerchantDetailsDispatch(
        id,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    } else {
      setEditMode(false);
    }
  }, [id]);

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  return (
    <>
      {!loadingGMD ? (
        <div className="card-header bg-skyBlue py-10">
          <div className="card-body">
            <h2 className="mb-5">Merchant Info</h2>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  Merchant Name:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="merchantName"
                    type="text"
                    className="form-control"
                    placeholder="Merchant Name"
                    onChange={(e) => handleChange(e)}
                    value={formData.merchantName || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.merchantName && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.merchantName}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  Industry:
                </label>
                <div className="col-lg-12">
                  <ReactSelect
                    isClearable
                    styles={customStyles}
                    isMulti={false}
                    name="industry"
                    className="basic-single"
                    classNamePrefix="select"
                    handleChangeReactSelect={handleChangeIndustry}
                    options={industryOption}
                    value={selectedIndustryOption}
                  />
                  {errors && errors.industry && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.industry}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Country:
                </label>
                <div className="col-lg-12">
                  <ReactSelect
                    isClearable
                    styles={customStyles}
                    isMulti={false}
                    name="country"
                    className="basic-single"
                    classNamePrefix="select"
                    handleChangeReactSelect={handleChangeCountry}
                    options={countryOption}
                    value={selectedCountryOption}
                    isLoading={loadingCountry}
                    isDisabled={loadingCity || loadingCountry}
                  />
                  {errors && errors.country && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.country}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  State:
                </label>
                <div className="col-lg-12">
                  <ReactSelect
                    isClearable
                    styles={customStyles}
                    isMulti={false}
                    name="state"
                    className="basic-single"
                    classNamePrefix="select"
                    handleChangeReactSelect={handleChangeState}
                    options={stateOption}
                    value={selectedStateOption}
                    isLoading={loadingState}
                    onBlur={(e) => handleSelectBlur(e, "state")}
                    handleInputChange={(e) =>
                      handleSelectInputChange(e, "state")
                    }
                  />
                  {errors && errors.state && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.state}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  City:
                </label>
                <div className="col-lg-12">
                  <ReactSelect
                    isClearable
                    styles={customStyles}
                    isMulti={false}
                    name="city"
                    className="basic-single"
                    classNamePrefix="select"
                    handleChangeReactSelect={handleChangeCity}
                    options={cityOptions}
                    value={selectedCityOption}
                    isLoading={loadingCity}
                    onBlur={(e) => handleSelectBlur(e, "city")}
                    noOptionsMessage={() => "Please start typing to search!"}
                    handleInputChange={(e) =>
                      handleSelectInputChange(e, "city")
                    }
                  />
                  {errors && errors.city && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.city}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  Address:
                </label>
                <div className="col-lg-12">
                  <textarea
                    autoComplete="off"
                    name="address"
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    onChange={(e) => handleChange(e)}
                    value={formData.address || ""}
                    maxLength={500}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.address && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.address}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  Zip Code:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="zip"
                    type="text"
                    className="form-control"
                    placeholder="Zip Code"
                    onChange={(e) => handleChange(e)}
                    value={formData.zip || ""}
                    maxLength={6}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.zip && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.zip}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  Email:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="email"
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => handleChange(e)}
                    value={formData.email || ""}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  />
                  {errors && errors.email && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  Phone Number:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="phoneNumber"
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    onChange={(e) => handleChange(e)}
                    value={formData.phoneNumber || ""}
                    maxLength={12}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.NUMERIC_CHARS.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.phoneNumber && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.phoneNumber}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Phone Extension:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="phoneNumberExtension"
                    type="text"
                    className="form-control"
                    placeholder="Phone Extension"
                    onChange={(e) => handleChange(e)}
                    value={formData.phoneNumberExtension || ""}
                    maxLength={10}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!/[0-9+]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.phoneNumberExtension && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.phoneNumberExtension}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  External Id:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="externalId"
                    type="text"
                    className="form-control"
                    placeholder="External Id"
                    onChange={(e) => handleChange(e)}
                    value={formData.externalId || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.externalId && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.externalId}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Company Description:
                </label>
                <div className="col-lg-12">
                  <textarea
                    autoComplete="off"
                    name="companyDescription"
                    type="text"
                    className="form-control"
                    placeholder="Company Description"
                    onChange={(e) => handleChange(e)}
                    value={formData.companyDescription || ""}
                    maxLength={500}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.companyDescription && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.companyDescription}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Merchant Currency:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="currency"
                    type="text"
                    className="form-control"
                    placeholder="Currency"
                    onChange={(e) => handleChange(e)}
                    value={formData.currency || ""}
                    maxLength={4}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  />
                  {errors && errors.currency && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.currency}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-2 mt-3">
              <div className="col-lg-6" />
              <div className="col-lg-6">
                <div className="col-lg-11">
                  <button
                    className="btn btn-primary m-2 fa-pull-right"
                    onClick={(event) => {
                      handleSubmit(event);
                    }}
                  >
                    {loadingEMD ? (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center py-5">
          <div className="spinner-border text-primary m-5" role="status" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getCountrys: state && state.countryStore && state.countryStore.getCountrys,
  loadingCountry: state && state.countryStore && state.countryStore.loading,
  getStates: state && state.stateStore && state.stateStore.getStates,
  loadingState: state && state.stateStore && state.stateStore.loading,
  getCitys: state && state.cityStore && state.cityStore.getCitys,
  loadingCity: state && state.cityStore && state.cityStore.loading,
  getIndustry: state && state.industryStore && state.industryStore.getIndustry,
  merchantGetDetails:
    state && state.merchantStore && state.merchantStore.merchantGetDetails,
  statusGMD: state && state.merchantStore && state.merchantStore.statusGMD,
  messagesGMD: state && state.merchantStore && state.merchantStore.messagesGMD,
  loadingGMD: state && state.merchantStore && state.merchantStore.loadingGMD,
  dataEMD: state && state.editMerchantStore && state.editMerchantStore.dataEMD,
  statusEMD:
    state && state.editMerchantStore && state.editMerchantStore.statusEMD,
  messageEMD:
    state && state.editMerchantStore && state.editMerchantStore.messageEMD,
  loadingEMD:
    state && state.editMerchantStore && state.editMerchantStore.loadingEMD,
});

const mapDispatchToProps = (dispatch) => ({
  getCountryDispatch: (headers) =>
    dispatch(countryActions.getCountrys(headers)),
  getStateDispatch: (params, headers) =>
    dispatch(stateActions.getStates(params, headers)),
  getCityDispatch: (params, headers) =>
    dispatch(cityActions.getCitys(params, headers)),
  getIndustryDispatch: (params, headers) =>
    dispatch(industryActions.getIndustry(params, headers)),
  getMerchantDetailsDispatch: (data, headers) =>
    dispatch(merchantGetDetailsActions.getMerchantDetails(data, headers)),
  clearMerchantDetailsDispatch: () =>
    dispatch(merchantGetDetailsActions.clearMerchantDetails()),
  editMerchantDispatch: (id, data, headers) =>
    dispatch(editMerchantActions.editMerchant(id, data, headers)),
  clearEditMerchantDispatch: () =>
    dispatch(editMerchantActions.cleareditMerchant()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
