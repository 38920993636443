import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  getComponentsActionsTypes,
  getComponentsActions,
  addComponentsActionsTypes,
  addComponentsActions,
  getComponentsDetailsActionsTypes,
  getComponentsDetailsActions,
  updateComponentsActionsTypes,
  updateComponentsActions,
  deleteComponentsActionsTypes,
  deleteComponentsActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchComponents (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userComponents, {
      params,
      headers: headers
    })
    if (json.status === 200) {
      yield put(getComponentsActions.saveResponse(json.data))
    } else {
      yield put(getComponentsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getComponentsActionsTypes.RESPONSE, data })
  }
}

function * addComponents (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.userComponents, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addComponentsActions.saveResponse(json.data))
    } else {
      yield put(addComponentsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addComponentsActionsTypes.RESPONSE, data })
  }
}

function * getComponentsDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.userComponents}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(getComponentsDetailsActions.saveResponse(json.data))
    } else {
      yield put(getComponentsDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getComponentsDetailsActionsTypes.RESPONSE, data })
  }
}

function * updateComponents (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.userComponents}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(updateComponentsActions.saveResponse(json.data))
    } else {
      yield put(updateComponentsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updateComponentsActionsTypes.RESPONSE, data })
  }
}

function * deleteComponents (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.userComponents}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteComponentsActions.savemResponse(json.data))
    } else {
      yield put(deleteComponentsActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteComponentsActionsTypes.RESPONSE, data })
  }
}

export function * fetchComponentsWatcher () {
  yield all([
    yield takeLatest(getComponentsActionsTypes.REQUEST, fetchComponents),
    yield takeLatest(addComponentsActionsTypes.REQUEST, addComponents),
    yield takeLatest(
      getComponentsDetailsActionsTypes.REQUEST,
      getComponentsDetails
    ),
    yield takeLatest(updateComponentsActionsTypes.REQUEST, updateComponents),
    yield takeLatest(deleteComponentsActionsTypes.REQUEST, deleteComponents)
  ])
}
