import { SESSION, SET_STORAGE, API, HEADER, REASON } from "../utils/constants";
import _ from "lodash";

export const setLocalStorage = (key, varToSet) =>
  localStorage.setItem(key, btoa(varToSet));

export const getLocalStorage = (key) => {
  const getStorage = localStorage.getItem(key);
  try {
    return getStorage ? atob(getStorage) : false;
  } catch (e) {
    return false;
  }
};
export const getLocalStorageMoment = (key) => {
  const getStorage = localStorage.getItem(key);
  try {
    return getStorage ? atob(getStorage) : null;
  } catch (e) {
    return null;
  }
};

export const setLastInteractionTime = (key, valueToSet) => {
  localStorage.setItem(key, valueToSet);
};

export const getLastInteractionTime = (key) => {
  const getStorage = localStorage.getItem(key);
  try {
    return getStorage || "";
  } catch (e) {
    return "";
  }
};

export const getLocalStorageMenu = (key) => {
  const getStorage = localStorage.getItem(key);
  try {
    return getStorage ? atob(getStorage) : "";
  } catch (e) {
    return "";
  }
};

export const unsetLocalStorage = () => localStorage.clear();

export const removeLocalStorage = (key) => localStorage.removeItem(key);

export const addEllipsis = (string, sliceUpTo) => {
  if (string && string.length >= 30) {
    return <span>{string.substring(0, sliceUpTo) + "..."}</span>;
  } else {
    return <span>{string}</span>;
  }
};

export const setDynamicPasswordRegex = (min, max) => {
  const exp = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{${min || 7},${max || 14}}$`;
  const regex = new RegExp(exp);
  return regex;
};

export const handleShopifySession = (shopifyDomainUrl) => {
  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const apiToken = getLocalStorage(API.API_KEY);
  if ((shopifyDomainUrl && isAuthorized) || (shopifyDomainUrl && apiToken)) {
    window.location.href = shopifyDomainUrl ? shopifyDomainUrl : '/';
    unsetLocalStorage()
    setLocalStorage(REASON.TO_LOGOUT, REASON.SESSION_NOT_EXIST)
  }
};

export const handleAuthFail = (error) => {
  if (error === SESSION.AUTH_FAILED || error === SESSION.NOT_FOUND) {
    window.location.href = "/authorization-failed";
  }
  if (error === SESSION.AUTH_RESTRICTED) {
    window.location.href = "/session-exist";
  }
};

export const getUserPermissions = (slug, checkCondition) => {
  const getUserMenuDetails = getLocalStorageMenu(SET_STORAGE.USER_MENU_DETAILS);
  if (getUserMenuDetails && getUserMenuDetails) {
    const getMenuList = JSON.parse(getUserMenuDetails);
    if (getMenuList && getMenuList.length > 0) {
      let val = [];
      _.forEach(getMenuList, (parent) => {
        const currentSlug = `/${parent.slug}`;
        if (parent && parent.submenu && parent.submenu.length > 0) {
          _.forEach(parent.submenu, (child) => {
            const currentSlug = `/${child.slug}`;
            if (currentSlug === slug) {
              const permissions =
                child.permissions && child.permissions.length > 0
                  ? child.permissions
                  : [];
              val = permissions;
            } else if (child && child.submenu && child.submenu.length > 0) {
              _.forEach(child.submenu, (grand) => {
                const currentSlug = `/${grand.slug}`;
                if (currentSlug === slug) {
                  const permissions =
                    grand.permissions && grand.permissions.length > 0
                      ? grand.permissions
                      : [];
                  val = permissions;
                }
              });
            }
          });
        } else if (currentSlug === slug) {
          const permissions =
            parent.permissions && parent.permissions.length > 0
              ? parent.permissions
              : [];
          val = permissions;
        }
      });
      return val;
    }
  } else {
    if (checkCondition) {
      window.location.href = "/session-expired";
      unsetLocalStorage();
    }
  }
};

const checkIfParentExist = (tempSlug, arr) => {
  switch (tempSlug) {
    case "/prevention-alerts":
      arr.push(
        "/prevention-alerts",
        "/prevention-alerts/update/:id",
        "/prevention-alerts"
      );
      return;
    case "/chargeback-management":
      arr.push(
        "/chargeback-management",
        "/chargeback-management/select-chargeback",
        "/chargeback-management/add-chargeback",
        "/chargeback-management/upload-chargeback",
        "/chargeback-management/update-chargeback/:id",
        "/chargeback-management/update-chargeback/:id/mail-history",
        "/chargeback-management/sdk"
      );
      return;
    case "/client-management":
      arr.push(
        "/client-management",
        "/client-onboarding",
        "/client-onboarding/update/:id",
        "/client-onboarding/update/:id/add-template",
        "/client-onboarding/update/:id/update-template/:id",
        "/client-management/sdk"
      );
      return;
    case "/partner-management":
      arr.push(
        "/partner-management",
        "/partner-onboarding",
        "/partner-onboarding/update/:id"
      );
      return;
    case "/manage-coverletter":
      arr.push(
        "/manage-coverletter",
        "/manage-coverletter/add-coverletter",
        "/manage-coverletter/update-coverletter/:id"
      );
      return;
    case "/manage-reasons":
      arr.push(
        "/manage-reasons",
        "/manage-reasons/add-reason",
        "/manage-reasons/update-reason/:id"
      );
      return;
    case "/issuer-bank-inquiries":
      arr.push("/issuer-bank-inquiries", "/issuer-bank-inquiries/sdk");
      return;
    case "/issuer-bank-alerts":
      arr.push("/issuer-bank-alerts", "/issuer-bank-alerts/sdk");
      return;
    case "/manage-crm":
      arr.push("/manage-crm", "/manage-crm/add", "/manage-crm/update/:id");
      return;
    case "/manage-payment-processor":
      arr.push(
        "/manage-payment-processor",
        "/manage-payment-processor/add",
        "/manage-payment-processor/update/:id"
      );
      return;
    case "/manage-payment-gateway":
      arr.push(
        "/manage-payment-gateway",
        "/manage-payment-gateway/add",
        "/manage-payment-gateway/update/:id"
      );
      return;
    case "/manage-industry":
      arr.push(
        "/manage-industry",
        "/manage-industry/add",
        "/manage-industry/update/:id"
      );
      return;
    case "/user-components":
      arr.push(
        "/user-components",
        "/add-user-components",
        "/update-user-components/:id"
      );
      return;
    case "/user-management":
      arr.push(
        "/user-management",
        "/user-management/update-user/:id",
        "/assign-partner/:id",
        "/assign-client/:id"
      );
      return;
    case "/manage-acquirer":
      arr.push(
        "/manage-acquirer",
        "/manage-acquirer/add",
        "/manage-acquirer/update/:id"
      );
    case "/site-configuration":
      arr.push("/site-configuration");
    case "/subscription-package":
      arr.push(
        "/subscription-package",
        "/subscription-package/add-package",
        "/subscription-package/update/:id"
      );
    case "/email-templates":
      arr.push(
        "/email-templates",
        "/email-templates/add-template",
        "/email-templates/update/:id"
      );
    case "/paypal-configuration":
      arr.push(
        "/paypal-configuration"
      );
      return;
    case "/chargeback-mail-batch":
      arr.push(
        "/chargeback-mail-batch"
      );
    case "/aws-dashboards":
      arr.push(
        "/aws-dashboards",
        "/chargebackSummary",
        "/rootCauseAnalysis",
        "/preventionAlert",
        "/performanceAnalysis",
        "/fraudAnalysis",
        "/preventionInquiriesIntelligence",
        "/generate-sdk",
      );
      return;
    default:
      arr.push(tempSlug);
  }
};

export const getUserMatrixSlugs = () => {
  const getUserMenuDetails = getLocalStorageMenu(SET_STORAGE.USER_MENU_DETAILS);
  if (getUserMenuDetails && getUserMenuDetails) {
    const getMenuList = JSON.parse(getUserMenuDetails);
    if (getMenuList && getMenuList.length > 0) {
      const slugs = [
        "/perform-summary",
        "/user-profile",
        "/user-change-password",
        "/manage-chargeback",
        "/manage-client",
        "/chargeback-summary-dashboard",
        "/rootcause-analyzer-dashboard",
        "/prevention-alerts-dashboard",
        "/performance-analyzer-dashboard",
        "/fraud-analyzer-dashboard",
        "/prevention-inquiries-dashboard",
      ];
      _.forEach(getMenuList, (parent) => {
        if (parent.submenu && parent.submenu.length > 0) {
          _.forEach(parent.submenu, (child) => {
            if (child.submenu && child.submenu.length > 0) {
              _.forEach(child.submenu, (grand) => {
                if (grand.slug) {
                  const prepSlug = grand.slug
                    .replace(/\s+/g, "-")
                    .toLowerCase();
                  checkIfParentExist(`/${prepSlug}`, slugs);
                }
              });
            } else {
              const prepSlug = child.slug.replace(/\s+/g, "-").toLowerCase();
              checkIfParentExist(`/${prepSlug}`, slugs);
            }
          });
        } else {
          const prepSlug = parent.slug.replace(/\s+/g, "-").toLowerCase();
          checkIfParentExist(`/${prepSlug}`, slugs);
        }
      });
      return slugs;
    }
  }
};

const events = ["click", "keydown"];
export const isAuthorized = getLocalStorage(SESSION.TOKEN);

export const addEvents = (eventHandler) => {
  events.forEach((eventName) => {
    window.addEventListener(eventName, eventHandler);
  });
};

export const removeEvents = (eventHandler) => {
  events.forEach((eventName) => {
    window.removeEventListener(eventName, eventHandler);
  });
};

export const getSuggestionValue = (suggestion) => suggestion.name;
export const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

export const getSuggestions = (value, suggestion) => {
  const inputValue = value && value.trim().toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0
    ? []
    : suggestion.filter(
      (val) => val.name.toLowerCase().slice(0, inputLength) === inputValue
    );
};

export const headersWithAuth = {
  'X-Custom-Header': 'foobar',
  [`${HEADER.TOKEN}`]: `Bearer ${getLocalStorage(SESSION.TOKEN)}`
}

export const headerWithApiKey = {
  'X-Custom-Header': 'foobar',
  'api-key': `${getLocalStorage(API.API_KEY)}`
}