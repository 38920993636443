import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  addEmailActions,
  chargeBackDetailsActions,
  clientProcessorActions,
} from '../../../../store/actions'
import { connect } from 'react-redux'
import { confirmAlert, warningAlert } from '../../../../utils/alerts'
import { STATUS_RESPONSE, SESSION, API } from '../../../../utils/constants'

import { Modal } from '../../../../theme/layout/components/modal'
import { KTSVG } from '../../../../theme/helpers'
import { validateStep7 } from './validation'
import _ from 'lodash'
import { setEmailData } from './formDataCB'
import { getLocalStorage, headersWithAuth } from '../../../../utils/helper'

const Step7 = (props) => {
  const {
    EmailDispatch,
    clearEmailDispatch,
    goBack,
    loadingAECB,
    statusAECB,
    messageAECB,
    ChargebackDetails,
    getProcessorDispatch,
    getCBDetailsDispatch,
    statusCLPD,
    clientProcessorData,
    loadingProcessor
  } = props

  const redirectUrl = getLocalStorage(API.API_KEY);
  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }
  const history = useHistory()
  const url = useLocation().pathname
  const fields = url && url.split('/')
  const id = fields && fields[3]
  const [errors, setErrors] = useState({})
  const [show, setShow] = useState(false)
  const [formData, setFormData] = useState({
    chargebackId: id,
    email: '',
    bccemail: '',
    ccemail: ''
  })
  const handleChange = (e) => {
    e.persist()
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
  }
  useEffect(() => {
    if (id) {
      getCBDetailsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }, [id])

  useEffect(() => {
    const processorParams = {
      skipPagination: true,
      clientId:
        ChargebackDetails.clientId && ChargebackDetails.clientId._id
          ? ChargebackDetails.clientId._id
          : '',
      merchantId:
        ChargebackDetails.merchantId && ChargebackDetails.merchantId._id
          ? ChargebackDetails.merchantId._id
          : '',
      mid: ChargebackDetails.mid,
      paymentProcessor:
        ChargebackDetails.paymentProcessor &&
          ChargebackDetails.paymentProcessor._id
          ? ChargebackDetails.paymentProcessor._id
          : ''
    }
    getProcessorDispatch(processorParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
  }, [id])

  useEffect(() => {
    if (statusCLPD === STATUS_RESPONSE.SUCCESS_MSG) {
      if (clientProcessorData && clientProcessorData.length > 0) {
        const data = setEmailData(clientProcessorData)
        setFormData({ ...formData, ...data })
      }
    }
  }, [statusCLPD])

  const handleSubmit = () => {
    const errorMsg = validateStep7(formData, setErrors)
    if (_.isEmpty(errorMsg)) {
      EmailDispatch(formData, isAuthorized ? headersWithAuth : headercurrentWithApiKey)
    }
  }

  const onConfirm = () => {
    setShow(false)
    history.push(isAuthorized ? '/chargeback-management' : `/manage-chargeback?apiKey=${redirectUrl}`)
  }

  useEffect(() => {
    if (statusAECB === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'Success',
        messageAECB,
        'success',
        'Ok',
        () => {
          onConfirm()
        },
        () => {
          onConfirm()
        }
      )
      clearEmailDispatch()
    } else if (statusAECB === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert('Error', messageAECB, 'error', 'Ok')
    }
    clearEmailDispatch()
  }, [statusAECB])

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  return (
    <div className='card-header py-10 bg-skyBlue'>
      <div className='container-fixed '>
        <div className='card-body h-300px'>
          <div>
            <div className='row'>
              <div className='col-lg-3 overflow-hidden' />
              <div className='col-lg-5'>
                <div className='card-body bg-purple h-auto m-auto mt-5 p-6'>
                  <span className='svg-icon svg-icon-w.hite svg-icon-3x ms-n1 d-flex justify-content-center mt-8'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='#fff'
                      className='bi bi-send-fill'
                      viewBox='0 0 16 16'
                    >
                      <path d='M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z' />
                    </svg>
                  </span>
                  <div className='text-white fw-bolder fs-2 mt-8 d-flex justify-content-center '>
                    Send the Rebuttal Form To Acquirer
                  </div>
                  <div className='fw-bold text-white d-flex justify-content-center'>
                    It's recommended to download to review the rebuttal file
                  </div>
                  <div className='d-flex justify-content-center mt-3'>
                    {!['WON', 'LOST', 'NOT FAUGHT', 'LOST REFUNDED'].includes(ChargebackDetails.chargeBackStatus) &&
                      <button
                        className='btn btn-sm btn-green btn-responsive font-5vw me-3 pull-right'
                        data-toggle='modal'
                        data-target='#searchModal'
                        disabled={loadingProcessor}
                        onClick={() => {
                          const data = setEmailData(clientProcessorData)
                          setFormData({ ...formData, ...data })
                          setShow(true)
                        }}
                      >
                        Send Mail
                      </button>
                    }
                  </div>
                </div>
                <div className='col-lg-4' />
              </div>
            </div>
            <Modal showModal={show} modalWidth={650}>
              <div className='' id='mailModal'>
                <div>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h2 className='me-8'>Email Details :</h2>
                      <button
                        type='button'
                        className='btn btn-lg btn-icon btn-active-light-primary close'
                        data-dismiss='modal'
                        onClick={() => {
                          setShow(false)
                        }}
                      >
                        {/* eslint-disable */}
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr061.svg"
                          className="svg-icon-1"
                        />
                        {/* eslint-disable */}
                      </button>
                    </div>
                    <div className="modal-body">
                      <form className="container-fixed">
                        <div className="card-header bg-lightBlue">
                          <div className="card-body p-8">
                            <div className="form-group row mb-4">
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-5">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label d-flex justify-content-center">
                                      Email:
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <input
                                      name="email"
                                      type="text"
                                      className="form-control"
                                      placeholder="Email"
                                      onChange={(e) => handleChange(e)}
                                      value={formData.email || ""}
                                      autoComplete="off"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(e, setFormData)
                                      }
                                    />
                                    {errors && errors.email && (
                                      <div className="rr mt-1">
                                        <style>{".rr{color:red;}"}</style>
                                        {errors.email}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-5">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label d-flex justify-content-center">
                                      BCC:
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <input
                                      name="bccemail"
                                      type="text"
                                      className="form-control"
                                      placeholder="BCC"
                                      onChange={(e) => handleChange(e)}
                                      value={formData.bccemail || ""}
                                      autoComplete="off"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(e, setFormData)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-5">
                                    <label className="font-size-xs font-weight-bold mb-3  form-label d-flex justify-content-center">
                                      CC:
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <input
                                      name="ccemail"
                                      type="text"
                                      className="form-control"
                                      placeholder="CC"
                                      onChange={(e) => handleChange(e)}
                                      value={formData.ccemail || ""}
                                      autoComplete="off"
                                      onBlur={(e) =>
                                        handleTrimWhiteSpace(e, setFormData)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row mb-2 mt-2">
                              <div className="col-lg-8" />
                              <div className="col-lg-4">
                                <div className="col-lg-12">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-green m-1 fa-pull-right"
                                    id="submitBtn"
                                    onClick={(event) => {
                                      handleSubmit(event);
                                    }}
                                  >
                                    {loadingAECB ? (
                                      <span
                                        className="spinner-border spinner-border-sm mx-3"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      "Send"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <button
            className="btn btn-darkRed m-2 fa-pull-right"
            onClick={() => {
              goBack(5);
            }}
          >
            <span className="bi bi-caret-left-fill mt-2" />&nbsp;
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { clientProcessorStore, chargebackStore } = state;
  return {
    getEmail: state && state.addEmailStore ? state.addEmailStore.getEmail : [],
    loadingAECB: state && state.addEmailStore && state.addEmailStore.loadingAECB,
    statusAECB: state && state.addEmailStore && state.addEmailStore.statusAECB,
    messageAECB: state && state.addEmailStore && state.addEmailStore.messageAECB,
    clientProcessorData:
      clientProcessorStore && clientProcessorStore.clientProcessorData
        ? clientProcessorStore.clientProcessorData
        : {},
    statusCLPD:
      clientProcessorStore && clientProcessorStore.statusCLPD
        ? clientProcessorStore.statusCLPD
        : "",
    messageCLPD:
      clientProcessorStore && clientProcessorStore.messageCLPD
        ? clientProcessorStore.messageCLPD
        : "",
    loadingProcessor:
      state &&
      state.clientProcessorStore &&
      state.clientProcessorStore.clientProcessorLoading,
    ChargebackDetails:
      chargebackStore && chargebackStore.ChargebackDetails
        ? chargebackStore.ChargebackDetails
        : {},
    statusCBD:
      chargebackStore && chargebackStore.statusCBD
        ? chargebackStore.statusCBD
        : "",
    messagesCBD:
      chargebackStore && chargebackStore.messagesCBD
        ? chargebackStore.messagesCBD
        : "",
    loadingCBD:
      chargebackStore && chargebackStore.loadingCBD
        ? chargebackStore.loadingCBD
        : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  EmailDispatch: (data, headers) => dispatch(addEmailActions.addEmail(data, headers)),
  clearEmailDispatch: () => dispatch(addEmailActions.clearaddEmail()),
  getProcessorDispatch: (params, headers) =>
    dispatch(clientProcessorActions.getClientProcessor(params, headers)),
  clientProcessorActions: (data, headers) =>
    dispatch(clientProcessorActions.getClientProcessor(data, headers)),
  getCBDetailsDispatch: (id, headers) =>
    dispatch(chargeBackDetailsActions.getChargebackDetails(id, headers)),
  clearCBDetailsDispatch: () =>
    dispatch(chargeBackDetailsActions.clearChargebackDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step7);
