import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  getRootCauseActionsTypes,
  getRootCauseActions,
  getFunctionalActionsTypes,
  getFunctionalActions,
  getSubFunactionalActionsTypes,
  getSubFunactionalActions,
  getOperationalTypeActionsTypes,
  getOperationalTypeActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchRootCause (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.RootCause, { params, headers: headers })
    if (json.status === 200) {
      yield put(getRootCauseActions.saveResponse(json.data))
    } else {
      yield put(getRootCauseActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getRootCauseActionsTypes.RESPONSE, data })
  }
}

function * fetchFunctional (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.functional, { params, headers: headers })
    if (json.status === 200) {
      yield put(getFunctionalActions.saveResponse(json.data))
    } else {
      yield put(getFunctionalActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getFunctionalActionsTypes.RESPONSE, data })
  }
}

function * fetchSubFunctional (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.SubFunctional, { params, headers: headers })
    if (json.status === 200) {
      yield put(getSubFunactionalActions.saveResponse(json.data))
    } else {
      yield put(getSubFunactionalActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getSubFunactionalActionsTypes.RESPONSE, data })
  }
}

function * fetchOperationalType (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.OperationalType, { params, headers: headers })
    if (json.status === 200) {
      yield put(getOperationalTypeActions.saveResponse(json.data))
    } else {
      yield put(getOperationalTypeActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getOperationalTypeActionsTypes.RESPONSE, data })
  }
}

export function * fetchReasoncodeMatrixWatcher () {
  yield all([
    yield takeLatest(getRootCauseActionsTypes.REQUEST, fetchRootCause),
    yield takeLatest(getFunctionalActionsTypes.REQUEST, fetchFunctional),
    yield takeLatest(getSubFunactionalActionsTypes.REQUEST, fetchSubFunctional),
    yield takeLatest(getOperationalTypeActionsTypes.REQUEST, fetchOperationalType)
  ])
}
