import React from 'react'

const ShowFields = (props) => {
  const { children } = props
  return (
    <>
      {children}
    </>
  )
}

export default ShowFields
