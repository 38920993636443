export const getPaypalActionsTypes = {
  REQUEST: 'GET_PAYPAL_REQUEST',
  RESPONSE: 'GET_PAYPAL_RESPONSE',
  ERROR: 'GET_PAYPAL_ERROR',
  CLEAR: 'GET_PAYPAL_CLEAR'
}

export const getPaypalActions = {
  get: (params, headers) => ({
    type: getPaypalActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getPaypalActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getPaypalActionsTypes.CLEAR
  })
}

export const addPaypalActionsTypes = {
  REQUEST: 'ADD_PAYPAL_REQUEST',
  RESPONSE: 'ADD_PAYPAL_RESPONSE',
  ERROR: 'ADD_PAYPAL_ERROR',
  CLEAR: 'ADD_PAYPAL_CLEAR'
}

export const addPaypalActions = {
  add: (data, url, headers) => {
    return {
      type: addPaypalActionsTypes.REQUEST,
      payload: data,
      url, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addPaypalActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addPaypalActionsTypes.CLEAR
  })
}

export const deleteSubscriptionActionsTypes = {
  REQUEST: 'DELETE_SUBSCRIPTION_REQUEST',
  RESPONSE: 'DELETE_SUBSCRIPTION_RESPONSE',
  ERROR: 'DELETE_SUBSCRIPTION_ERROR',
  CLEAR: 'DELETE_SUBSCRIPTION_CLEAR'
}

export const deleteSubscriptionActions = {
  delete: (params, headers) => ({
    type: deleteSubscriptionActionsTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: deleteSubscriptionActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteSubscriptionActionsTypes.CLEAR
  })
}

export const addPaypalSubscriptionHistoryActionsTypes = {
  REQUEST: 'POST_PAYPAL_SUBSCRIPTION_HISTORY_REQUEST',
  RESPONSE: 'POST_PAYPAL_SUBSCRIPTION_HISTORY_RESPONSE',
  ERROR: 'POST_PAYPAL_SUBSCRIPTION_HISTORY_ERROR',
  CLEAR: 'POST_PAYPAL_SUBSCRIPTION_HISTORY_CLEAR'
}

export const addPaypalSubscriptionHistoryAction = {
  add: (data, url, headers) => {
    return {
      type: addPaypalSubscriptionHistoryActionsTypes.REQUEST,
      payload: data,
      url,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addPaypalSubscriptionHistoryActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addPaypalSubscriptionHistoryActionsTypes.CLEAR
  })
}

export const getPaypalSubscriptionHistoryTypes = {
  REQUEST: 'GET_PAYPAL_SUBSCRIPTION_HISTORY_REQUEST',
  RESPONSE: 'GET_PAYPAL_SUBSCRIPTION_HISTORY_RESPONSE',
  ERROR: 'GET_PAYPAL_SUBSCRIPTION_HISTORY_ERROR',
  CLEAR: 'GET_PAYPAL_SUBSCRIPTION_HISTORY_CLEAR'
}

export const getPaypalSubscriptionHistoryAction = {
  get: (params, headers) => ({
    type: getPaypalSubscriptionHistoryTypes.REQUEST,
    params, headers
  }),
  saveResponse: (data) => ({
    type: getPaypalSubscriptionHistoryTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getPaypalSubscriptionHistoryTypes.CLEAR
  })
}

export const addPayConfigActionsTypes = {
  REQUEST: 'ADD_PAY_CONFIG_REQUEST',
  RESPONSE: 'ADD_PAY_CONFIG_RESPONSE',
  ERROR: 'ADD_PAY_CONFIG_ERROR',
  CLEAR: 'ADD_PAY_CONFIG_CLEAR'
}

export const addPayConfigActions = {
  add: (data, headers) => {
    return {
      type: addPayConfigActionsTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addPayConfigActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addPayConfigActionsTypes.CLEAR
  })
}

export const getPayConfigActionsTypes = {
  REQUEST: 'GET_PAY_CONFIG_REQUEST',
  RESPONSE: 'GET_PAY_CONFIG_RESPONSE',
  ERROR: 'GET_PAY_CONFIG_ERROR',
  CLEAR: 'GET_PAY_CONFIG_CLEAR'
}

export const getPayConfigActions = {
  get: (headers) => ({
    type: getPayConfigActionsTypes.REQUEST,
    headers
  }),
  saveResponse: (data) => ({
    type: getPayConfigActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getPayConfigActionsTypes.CLEAR
  })
}

export const getPayConfigDetailsActionsTypes = {
  REQUEST: 'GET_PAY_CONFIG_DETAILS_REQUEST',
  RESPONSE: 'GET_PAY_CONFIG_DETAILS_RESPONSE',
  ERROR: 'GET_PAY_CONFIG_DETAILS_ERROR',
  CLEAR: 'GET_PAY_CONFIG_DETAILS_CLEAR'
}
export const getPayConfigDetailsActions = {
  getDetails: (id, headers) => ({
    type: getPayConfigDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getPayConfigDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getPayConfigDetailsActionsTypes.CLEAR
  })
}

export const updatePayConfigActionsTypes = {
  REQUEST: 'UPDATE_PAY_CONFIG_REQUEST',
  RESPONSE: 'UPDATE_PAY_CONFIG_RESPONSE',
  ERROR: 'UPDATE_PAY_CONFIG_ERROR',
  CLEAR: 'UPDATE_PAY_CONFIG_CLEAR'
}

export const updatePayConfigActions = {
  update: (id, params, headers) => {
    return {
      type: updatePayConfigActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updatePayConfigActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updatePayConfigActionsTypes.CLEAR
  })
}