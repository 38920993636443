import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addReasoncodeActions,
  addReasoncodeTypes,
  ReasoncodesActions,
  ReasoncodeActionsTypes,
  ReasoncodeDetailsActions,
  ReasoncodeGetDetailsTypes,
  editReasoncodeTypes,
  editReasoncodesActions,
  ReasoncodeDeleteTypes,
  deleteReasoncodeActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addReasoncode (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.reasonCode, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addReasoncodeActions.saveaddReasoncodeResponse(json.data))
    } else {
      yield put(addReasoncodeActions.clearaddReasoncode([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addReasoncodeTypes.RESPONSE, data })
  }
}

function * fetchReasoncode (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.reasonCode, { params, headers: headers })
    if (json.status === 200) {
      yield put(ReasoncodesActions.saveReasoncodeResponse(json.data))
    } else {
      yield put(ReasoncodesActions.saveReasoncodeResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: ReasoncodeActionsTypes.SAVE_REASON_CODE_RESPONSE, data })
  }
}

function * getReasoncodeDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.reasonCode}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(ReasoncodeDetailsActions.saveReasoncodeDetailsResponse(json.data))
    } else {
      yield put(ReasoncodeDetailsActions.saveReasoncodeDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: ReasoncodeGetDetailsTypes.REASON_CODE_DETAILS_RESPONSE, data })
  }
}

function * editReasoncode (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.reasonCode}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(editReasoncodesActions.saveeditReasoncodesResponse(json.data))
    } else {
      yield put(editReasoncodesActions.saveeditReasoncodesResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editReasoncodeTypes.RESPONSE, data })
  }
}

function * deleteReasoncode (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.reasonCode}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteReasoncodeActions.savemResponseDeleteReasoncode(json.data))
    } else {
      yield put(deleteReasoncodeActions.savemResponseDeleteReasoncode([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: ReasoncodeDeleteTypes.SAVE_DELETE_REASON_CODE_RESPONSE, data })
  }
}

export function * fetchReasoncodeWatcher () {
  yield all([
    yield takeLatest(addReasoncodeTypes.REQUEST, addReasoncode),
    yield takeLatest(ReasoncodeActionsTypes.GET_REASON_CODE, fetchReasoncode),
    yield takeLatest(ReasoncodeGetDetailsTypes.GET_REASON_CODE_DETAILS, getReasoncodeDetails),
    yield takeLatest(editReasoncodeTypes.REQUEST, editReasoncode),
    yield takeLatest(ReasoncodeDeleteTypes.DELETE_REASON_CODE, deleteReasoncode)
  ])
}
