import React, { useEffect, useState, useRef } from "react";
import { packageValidation } from "./validation";
import {
  CRM_FORM,
  STATUS_BADGES_LIGHT,
  DATE,
  SWEET_ALERT_MSG,
  STATUS_RESPONSE,
  SET_STORAGE,
  SESSION,
  API
} from "../../../../utils/constants";
import { setLocalStorage, getLocalStorage, headersWithAuth } from "../../../../utils/helper";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  packageActions,
  packPaymentActions,
  getPaypalSubscriptionHistoryAction,
  deleteSubsPackageActions,
  addPaypalSubscriptionHistoryAction,
  getPaypalActions,
  listActions,
} from "../../../../store/actions";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { PayPalBtn } from "../../../../theme/layout/components/paypalBtn";
import { Modal } from "../../../../theme/layout/components/modal";
import { KTSVG } from "../../../../theme/helpers";
import {
  confirmationAlert,
  warningAlert,
  confirmAlert,
} from "../../../../utils/alerts";

const Package = (props) => {
  const {
    loadingPack,
    loading,
    onClickNext,
    goBack,
    setClientDetails,
    getPackageDispatch,
    getPackages,
    getPaymentDispatch,
    getPaypalSubscriptionHistoryDispatch,
    getPayments,
    getPaypalHistory,
    loadingPaypalGetHistory,
    deleteSubsDispatch,
    clearDeleteSubsDispatch,
    statusDSP,
    messageDSP,
    getPaypalDispatch,
    addPaypalSubscriptionHistoryDispatch,
    getPaypal,
    getListDispatch,
    getList,
    loadingList,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    'X-Custom-Header': 'foobar',
    'api-key': `${getLocalStorage(API.API_KEY)}`
  }
  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update/");
  const id = url && url[1];
  const [limit, setLimit] = useState(25);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [packageShow, setPackageShow] = useState(true);
  const [paymentShow, setPaymentShow] = useState(false);
  const [historyShow, setHistoryShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [packageAppList, setPackageAppList] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [packId, setPackId] = useState();
  const [planId, setPlanId] = useState();
  const type = getLocalStorage(SET_STORAGE.CLIENT_ATTRIBUTE_DEATILS);

  const [currenctListValue, setCurrenctListValue] = useState({
    Chargeback: null,
    PreventionAlerts: null,
    PreventionInquiries: null,
  });
  const [tenure, setTenure] = useState({
    Basic: 'monthly', Freemium: 'monthly', Premium: 'monthly',
  });
  const [paypalClientId, setPaypalClientId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [subscribedItems, setSubscribedItems] = useState(null);

  useEffect(() => {
    const params = {
      null: null
    }
    getListDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  }, []);

  useEffect(() => {
    if (editMode) {
      const paginationParams = {
        limit: limit,
        page: activePageNumber,
        clientId: id
      };
      const pickByParams = _.pickBy(paginationParams);
      getPaypalSubscriptionHistoryDispatch(pickByParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
      getPaypalDispatch(pickByParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    }
  }, [editMode]);

  const hasNoDuplicates = (array1, array2) => {
    let valid = true;
    array1 &&
      array1.length > 0 &&
      array1.map((o) => {
        array2 &&
          array2.length > 0 &&
          array2.map((x) => {
            if (x._id && x._id === o._id) {
              valid = false;
            }
          });
      });
    return valid;
  };

  const handleChoose = (name, id) => {
    setSelectedId(id);
    setPackId(name);
    const getId = getSelectedPackId(name, packageAppList);
    setPlanId(getId);
    setErrors({ ...errors, subscriptionErr: "" });
  };

  useEffect(() => {
    if (packageAppList && packageAppList.length > 0) {
      if (hasNoDuplicates(packageAppList, getList)) {
        setPackageAppList((values) => [...values, ...getList]);
      }
    } else {
      setPackageAppList(getList);
    }
  }, [getList]);

  const handleInputChange = (e, packTitle) => {
    e.preventDefault();
    setTenure((values) => ({ ...values, [packTitle]: e.target.value }));
  };

  const getSelectedPackId = (selectedId, array) => {
    if (selectedId && array && array.length > 0) {
      const getSelectedTenure = tenure[selectedId];
      let selectedPackId = "";
      _.forEach(array, (x) => {
        if (x.appName === selectedId) {
          let currenrtPackId = _.find(x.plans, (o) => {
            selectedPackId = getSelectedTenure ? o.planId : "";
            return o.tenure === getSelectedTenure ? o.planId : "";
          });
          return currenrtPackId && currenrtPackId.planId;
        }
      });
      return selectedPackId;
    }
  };

  useEffect(() => {
    const params = {
      limit: limit,
      page: 1,
    };
    getPaymentDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  }, [limit]);

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      setShowForm(false);
    } else {
      setEditMode(false);
    }
  }, [id]);

  useEffect(() => {
    if (getPaypal && !_.isEmpty(getPaypal)) {
      const getClientId =
        getPaypal &&
          getPaypal[0].credentials &&
          getPaypal[0].credentials.clientId
          ? getPaypal[0].credentials.clientId
          : "";
      setPaypalClientId(getClientId);
    }
  }, [getPaypal]);

  const handleSubmit = () => {
    const errorMsg = packageValidation(selectedId, setErrors);
    if (_.isEmpty(errorMsg)) {
      onClickNext(5);
      const packageData = {
        selectedId,
        tenure: tenure[packId],
        package: packId,
      }
      setClientDetails((values) => ({ ...values, package: packageData }));
      setLocalStorage(CRM_FORM.PACKAGE_TENURE, JSON.stringify(tenure));
      setLocalStorage(CRM_FORM.PACKAGE_SELECTED_ID, JSON.stringify(selectedId));
      setLocalStorage(
        CRM_FORM.PACKAGE_PACK_APP_LIST,
        JSON.stringify(packageAppList)
      );
      setLocalStorage(
        CRM_FORM.PACKAGE_CURRENT_LIST_VALUE,
        JSON.stringify(currenctListValue)
      );
      setLocalStorage(CRM_FORM.PACKAGE_PACK_ID, packId);
    }
  };

  const localPackTenure = JSON.parse(getLocalStorage(CRM_FORM.PACKAGE_TENURE));
  const localPackSelectedId = JSON.parse(
    getLocalStorage(CRM_FORM.PACKAGE_SELECTED_ID)
  );
  const localPackList = JSON.parse(
    getLocalStorage(CRM_FORM.PACKAGE_PACK_APP_LIST)
  );
  const localPackCurrentValue = JSON.parse(
    getLocalStorage(CRM_FORM.PACKAGE_CURRENT_LIST_VALUE)
  );
  const localPackId = getLocalStorage(CRM_FORM.PACKAGE_PACK_ID);

  useEffect(() => {
    if (!_.isEmpty(localPackList) && localPackSelectedId) {
      setCurrenctListValue(localPackCurrentValue);
      setPackageAppList(localPackList);
      setSelectedId(localPackSelectedId);
      setPackId(localPackId);
      setTenure(localPackTenure);
    }
  }, []);

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const params = {
      limit: limit,
      page: pageNumber,
    };
    setActivePageNumber(pageNumber);
    getPaymentDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  const totalPages =
    getPayments && getPayments.count
      ? Math.ceil(parseInt(getPayments && getPayments.count) / limit)
      : 1;

  const handlePagesClick = (event) => {
    const pageNumber = event.selected + 1;
    const params = {
      limit: limit,
      page: pageNumber,
    };
    setActivePageNumber(pageNumber);
    getPackageDispatch(params, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  const totalPaages =
    getPackages && getPackages.count
      ? Math.ceil(parseInt(getPackages && getPackages.count) / limit)
      : 1;

  const handleSubPagesClick = (event) => {
    const pageNumber = event.selected + 1;
    const paginationParams = {
      limit: limit,
      page: pageNumber,
      clientId: id
    };
    const pickByParams = _.pickBy(paginationParams);
    setActivePageNumber(pageNumber);
    getPaypalSubscriptionHistoryDispatch(pickByParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  const totalSubPages =
    getPaypalHistory && getPaypalHistory.count
      ? Math.ceil(parseInt(getPaypalHistory && getPaypalHistory.count) / limit)
      : 1;

  const dateConverter = (timeEnd) => {
    const newStartDate = new Date();
    const newEndDate = moment(timeEnd).add(1, "days");
    let result = moment(newEndDate).diff(newStartDate, "days");
    return `${result} days left`;
  };

  const paypalOnError = (err) => {
    warningAlert("Error", "An Error occured with your process", "", "Ok");
  };

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: subscribedItems && subscribedItems ? subscribedItems : "",
    });
  };

  const onCanfirmApprove = () => {
    setShowModal(false);
    const paginationParams = {
      limit: limit,
      page: activePageNumber,
      clientId: id.replace("/", ""),
    };
    const pickByParams = _.pickBy(paginationParams);
    getPaypalSubscriptionHistoryDispatch(pickByParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
    if (pathName.includes("client-onboarding/update") && showForm) {
      setShowForm(false);
      setEditMode(true);
    }
  };

  const paypalOnApprove = (data, detail) => {
    const packdata = getPackages && getPackages[0] ? getPackages[0] : {};
    const payload = {
      ...data,
      ...packdata,
      clientId: id.replace("/", ""),
    };
    if (data.subscriptionID) {
      addPaypalSubscriptionHistoryDispatch(payload, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
      confirmAlert(
        "Success",
        "Your Subscription has been successfully completed.",
        "success",
        "Ok",
        () => {
          onCanfirmApprove();
        },
        () => {
          onCanfirmApprove();
        }
      );
    }
  };

  const handleRenew = () => {
    const errorMsg = packageValidation(planId, setErrors);
    if (_.isEmpty(errorMsg)) {
      setShowModal(true);
      if (planId) {
        setSubscribedItems(planId);
      }
    }
  };

  function onConfirmDelete(id) {
    deleteSubsDispatch(id, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  }

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_SUBS,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete(id);
      },
      () => { }
    );
  };

  const onConfirm = () => {
    const paginationParams = {
      limit: limit,
      page: activePageNumber,
      clientId: id.replace("/", ""),
    };
    const pickByParams = _.pickBy(paginationParams);
    getPaypalSubscriptionHistoryDispatch(pickByParams, isAuthorized ? headersWithAuth : headercurrentWithApiKey);
  };

  useEffect(() => {
    if (statusDSP === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageDSP,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearDeleteSubsDispatch();
    } else if (statusDSP === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageDSP, "", "Ok");
    }
    clearDeleteSubsDispatch();
  }, [statusDSP]);

  return (
    <>
      {showModal ? (
        <Modal showModal={showModal} modalWidth={750}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">Renew Subscription</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container-fixed">
                    <div className="card m-2 d-flex justify-content-center">
                      {paypalClientId ? (
                        <PayPalBtn
                          amount="0.01"
                          currency="USD"
                          createSubscription={paypalSubscribe}
                          onApprove={paypalOnApprove}
                          catchError={paypalOnError}
                          onError={paypalOnError}
                          onCancel={paypalOnError}
                          paypalKey={paypalClientId}
                        />
                      ) : (
                        <div className="d-flex justify-content-center">
                          <span
                            className="spinner-border spinner-border-md mx-3"
                            role="status"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="card-header bg-skyBlue p-3">
        <div className="card-body  m-8">
          <h2 className="mb-2">Subscription Packages</h2>
          {!editMode && showForm ? (
            <div className="card-header bg-skyBlue">
              <div className="card-body p-8">
                {loadingList ? (
                  <div className="d-flex justify-content-center py-5">
                    <div
                      className="spinner-border text-primary m-5"
                      role="status"
                    />
                  </div>
                ) : (
                  <>
                    <div className="d-flex  px-2 mb-5">
                      <div className="d-flex justify-content-start col-md-5" />
                      <div className="d-flex col-md-7  justify-content-end my-auto">
                        {pathName.includes("client-onboarding/update") ? (
                          <button
                            className="btn btn-sm btn-darkRed mx-2"
                            onClick={() => {
                              setShowForm(false);
                              setEditMode(true);
                            }}
                          >
                            Back
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <div className="row g-10">
                      {packageAppList &&
                        packageAppList.map((packages, i) => (
                          <div
                            className="col-xl-4 col-lg-5 col-sm-6 col-md-6"
                            key={i}
                          >
                            <div className="overflow-hidden position-relative card-rounded">
                              <div className="card card-bordered bg-secondary mb-20">
                                <div className="ribbon-wrapper">
                                  <div className="ribbon" />
                                </div>
                                <div className="card-header bg-dark d-flex justify-content-center">
                                  <h4 className="mt-10 fw-bolder text-white">
                                    {packages && packages.appName
                                      ? _.upperCase(packages.appName)
                                      : "--"}
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <div className="mb-4 text-center">
                                    {selectedId === packages._id ? (
                                      <i className="bi bi-check2-circle text-success font-xxxl-bold" />
                                    ) : null}
                                  </div>
                                  <div className="row mt-7 me-2">
                                    <div className="col-lg-12 d-flex justify-content-start">
                                      <p className="mt-2 fw-bolder">
                                        Select Subscription
                                        Tenure:
                                      </p>
                                    </div>
                                    <div className="col-lg-12 me-3">
                                      <select
                                        name="price"
                                        className="form-select form-select-solid bg-secondary"
                                        data-control="select"
                                        data-placeholder="Select an option"
                                        data-allow-clear="true"
                                        onChange={(e) => handleInputChange(e, packages.appName)}
                                        value={tenure && tenure[packages.appName] || ""}
                                      >
                                        <option value="monthly">
                                          Monthly{""}(Per Month)
                                        </option>
                                        <option value="quarterly">
                                          Quarterly{""}(3 Months)
                                        </option>
                                        <option value="semiannually">
                                          Semiannually{""}(9
                                          Months)
                                        </option>
                                        <option value="annually">
                                          Annually{""}(12 Months)
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <h5 className="text-dark fs-4 fw-bolder text-center mt-8 mb-2">
                                    {packages && packages.subTitle
                                      ? packages.subTitle
                                      : ""}
                                  </h5>
                                  <h6 className="text-dark fs-6 fw-bold text-center mt-4 mb-2">
                                    {packages && packages.description
                                      ? packages.description
                                      : "--"}
                                  </h6>
                                  <div className="text-center">
                                    <span className="mb-2 text-primary">
                                      $
                                    </span>
                                    <span className="fs-2x fw-bolder text-primary">
                                      {["monthly", "quarterly", "semiannually", "annually"].includes(tenure && tenure[packages.appName])
                                        ? packages && packages.price && packages.price[tenure && tenure[packages.appName]]
                                          ? packages.price[tenure && tenure[packages.appName]] : "0"
                                        : null}
                                    </span>
                                    {["monthly", "quarterly", "semiannually", "annually"].includes(tenure && tenure[packages.appName]
                                    ) ? (
                                      <span className="fs-7 fw-bold opacity-50">
                                        /
                                        <span data-kt-element="period">
                                          {_.startCase(tenure && tenure[packages.appName])}
                                        </span>
                                      </span>
                                    ) : null}
                                  </div>
                                  <div className="w-100 mb-10 mt-8">
                                    {packages.features?.map(
                                      (item, index) => {
                                        return (
                                          <div
                                            className="d-flex align-items-center mb-5"
                                            key={index}
                                          >
                                            <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">
                                              {item}
                                            </span>
                                            <span className="svg-icon svg-icon-1 svg-icon-success">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                              >
                                                <rect
                                                  opacity="0.3"
                                                  x="2"
                                                  y="2"
                                                  width="20"
                                                  height="20"
                                                  rx="10"
                                                  fill="black"
                                                />
                                                <path
                                                  d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                  fill="black"
                                                />
                                              </svg>
                                            </span>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div className="d-flex mt-15 mb-10">
                                    <div className="col-lg-12 d-flex justify-content-center">
                                      <button
                                        className={`btn btn-sm ${selectedId === packages._id ? "bg-primary text-white" : "bg-blueDark"}`}
                                        onClick={() => { handleChoose(packages.appName, packages._id); }}
                                      >
                                        {selectedId === packages._id ? "Selected" : "Select"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      {errors && errors.subscriptionErr && (
                        <div className="rr mt-5 text-center bg-light-warning py-3">
                          <style>{".rr{color:black;}"}</style>
                          {errors.subscriptionErr}
                        </div>
                      )}
                    </div>
                    {pathName.includes("client-onboarding/update") &&
                      showForm ? (
                      <div className="text-center mt-3">
                        <button
                          className="btn btn-sm btn-dark mx-2 w-200px"
                          onClick={() => {
                            handleRenew();
                          }}
                        >
                          <i className="bi bi-currency-dollar" />
                          Pay and Subscribe
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              {showForm && !pathName.includes("client-onboarding/update") ? (
                <div className="form-group row mt-4 mb-1">
                  <div className="col-lg-6" />
                  <div className="col-lg-6">
                    <div className="col-lg-11">
                      <button
                        className="btn btn-orange m-2 fa-pull-right"
                        onClick={(event) => {
                          handleSubmit(event);
                        }}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm mx-3"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Next"
                        )}
                      </button>
                      <button
                        className="btn btn-darkRed m-2 fa-pull-right"
                        onClick={() => {
                          goBack(3);
                        }}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {editMode && pathName.includes("client-onboarding/update") ? (
                <div className="form-group row mt-4">
                  <div className="col-lg-6" />
                  <div className="col-lg-6">
                    <div className="col-lg-11">
                      <button className="btn btn-blue m-2 fa-pull-right">
                        {"" ? (
                          <span
                            className="spinner-border spinner-border-sm mx-3"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null
          }
          {
            editMode && !showForm ? (
              <>
                <div className="d-flex  px - 2">
                  <div className="d-flex justify-content-start col-md-6">
                    <div className="col-md-3 mt-1">
                      {getPackages && getPackages.count ? (
                        <span className="text-muted fw-bold d-flex fs-3 mt-2">
                          Total: &nbsp;{" "}
                          <span className="text-gray-700 fw-bolder text-hover-primary fs-3">
                            {getPackages.count}
                          </span>
                        </span>
                      ) : null}
                    </div>
                    <div className="col-md-9 d-flex" />
                    {/* {!type.includes('Client') && (
                      <div className="d-flex col-md-12 justify-content-end my-auto">
                        <div className="my-auto">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setShowForm(true);
                            }}
                          >
                            Modify Package
                          </button>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="mt-5 ms-5">
                  <div className="p-3 bg-white rounded shadow d-flex justify-content-start">
                    <ul
                      id="myTab"
                      role="tablist"
                      className="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav col-lg-7"
                    >
                      <li className="nav-item flex-sm-fill">
                        <a
                          id="home-tab"
                          data-toggle="tab"
                          href="#home"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                          className="nav-link active border-0 text-uppercase font-weight-bold"
                          onClick={() => setPackageShow(true)}
                        >
                          Packages
                        </a>
                      </li>
                      <li className="nav-item flex-sm-fill">
                        <a
                          id="profile-tab"
                          data-toggle="tab"
                          href="#profile"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                          className="nav-link border-0 text-uppercase font-weight-bold"
                          onClick={() => setPaymentShow(true)}
                        >
                          Payments
                        </a>
                      </li>
                      <li className="nav-item flex-sm-fill">
                        <a
                          id="history-tab"
                          data-toggle="tab"
                          href="#history"
                          role="tab"
                          aria-controls="history"
                          aria-selected="false"
                          className="nav-link border-0 text-uppercase font-weight-bold"
                          onClick={() => setHistoryShow(true)}
                        >
                          Subscription History
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home"
                    className="tab-pane active px-4 py-5 "
                  >
                    {packageShow && (
                      <div className="card h-auto">
                        <div className="card-body py-3">
                          <div className="d-flex justify-content-start col-md-6">
                            <div className="col-md-3 mt-1">
                              <h4 className="mt-5">Packages</h4>
                            </div>
                            <div className="col-md-7 d-flex">
                              <label className="col-form-label text-lg-start mt-3">
                                Record(s) per Page : &nbsp;{" "}
                              </label>
                              <div className="col-md-3 mt-3">
                                <select
                                  className="form-select w-6rem"
                                  data-control="select"
                                  data-placeholder="Select an option"
                                  data-allow-clear="true"
                                  onChange={(e) => handleRecordPerPage(e)}
                                >
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="75">75</option>
                                  <option value="100">100</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-hover table-rounded table-striped border gs-2 mt-6">
                              <thead className="fw-bolder fs-8 text-gray-800">
                                <tr>
                                  <th>Package ID</th>
                                  <th>Subscription Date</th>
                                  <th>Package Details</th>
                                  <th>Gateway</th>
                                  <th>Transaction ID</th>
                                </tr>
                              </thead>
                              <tbody className="fs-8">
                                {!loadingPack ? (
                                  getPackages && getPackages ? (
                                    getPackages.map((getPackage, _id) => {
                                      return (
                                        <tr
                                          key={_id}
                                          style={
                                            _id === 0
                                              ? { borderColor: "black" }
                                              : { borderColor: "white" }
                                          }
                                        >
                                          <td>
                                            {getPackage._id
                                              ? getPackage._id
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPackage.subscriptionDate
                                              ? getPackage.subscriptionDate
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPackage.packageDetails
                                              ? getPackage.packageDetails
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPackage.gateway
                                              ? getPackage.gateway
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPackage.transactionId
                                              ? getPackage.transactionId
                                              : "--"}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr className="text-center py-3">
                                      <td colSpan="100%">No record(s) found</td>
                                    </tr>
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="100%" className="text-center">
                                      <div
                                        className="spinner-border text-primary m-5"
                                        role="status"
                                      />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="form-group row mb-4 mt-6">
                            <div className="col-lg-12 mb-4 align-items-end d-flex">
                              <div className="col-lg-12 pagination">
                                <ReactPaginate
                                  nextLabel="Next >"
                                  onPageChange={handlePagesClick}
                                  pageRangeDisplayed={3}
                                  marginPagesDisplayed={2}
                                  pageCount={totalPaages}
                                  previousLabel="< Prev"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  renderOnZeroPageCount={null}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile"
                    className="tab-pane px-4 py-5"
                  >
                    {paymentShow && (
                      <div className="card h-auto">
                        <div className="card-body py-3">
                          <div className="d-flex justify-content-start col-md-6">
                            <div className="col-md-3 mt-1">
                              <h4 className="mt-5">Payments</h4>
                            </div>
                            <div className="col-md-7 d-flex">
                              <label className="col-form-label text-lg-start mt-3">
                                Record(s) per Page : &nbsp;{" "}
                              </label>
                              <div className="col-md-3 mt-3">
                                <select
                                  className="form-select w-6rem"
                                  data-control="select"
                                  data-placeholder="Select an option"
                                  data-allow-clear="true"
                                  onChange={(e) => handleRecordPerPage(e)}
                                >
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="75">75</option>
                                  <option value="100">100</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-hover table-rounded table-striped border gs-2 mt-6">
                              <thead className="fw-bolder fs-8 text-gray-800">
                                <tr>
                                  <th>Payment ID</th>
                                  <th>Payment Date</th>
                                  <th>Package ID</th>
                                  <th>Subscription Tenure</th>
                                  <th>Amount</th>
                                  <th>Transaction ID</th>
                                  <th>Payment Gateway</th>
                                </tr>
                              </thead>
                              <tbody className="fs-8">
                                {!loading ? (
                                  getPayments && getPayments ? (
                                    getPayments.map((getPayment, _id) => {
                                      return (
                                        <tr
                                          key={_id}
                                          style={
                                            _id === 0
                                              ? { borderColor: "black" }
                                              : { borderColor: "white" }
                                          }
                                        >
                                          <td>
                                            {getPayment._id
                                              ? getPayment._id
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPayment.paymentDate
                                              ? getPayment.paymentDate
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPayment.packageId
                                              ? getPayment.packageId
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPayment.subscriptionTenure
                                              ? getPayment.subscriptionTenure
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPayment.amount
                                              ? getPayment.amount
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPayment.transactionId
                                              ? getPayment.transactionId
                                              : "--"}
                                          </td>
                                          <td>
                                            {getPayment.paymentGateway
                                              ? getPayment.paymentGateway
                                              : "--"}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr className="text-center py-3">
                                      <td colSpan="100%">No record(s) found</td>
                                    </tr>
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="100%" className="text-center">
                                      <div
                                        className="spinner-border text-primary m-5"
                                        role="status"
                                      />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="form-group row mb-4 mt-6">
                            <div className="col-lg-12 mb-4 align-items-end d-flex">
                              <div className="col-lg-12 pagination">
                                <ReactPaginate
                                  nextLabel="Next >"
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={3}
                                  marginPagesDisplayed={2}
                                  pageCount={totalPages}
                                  previousLabel="< Prev"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  renderOnZeroPageCount={null}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    id="history"
                    role="tabpanel"
                    aria-labelledby="history"
                    className="tab-pane px-4 py-5"
                  >
                    {historyShow && (
                      <div className="card h-auto">
                        <div className="card-body py-3">
                          <div className="d-flex justify-content-start">
                            <div className="col-md-6 mt-1">
                              <h4 className="mt-5">Subscription History</h4>
                            </div>
                            <div className="col-md-6 mt-1 text-end">
                              <button
                                className="btn-sm btn btn-light-success m-2"
                                onClick={() => {
                                  setEditMode(false);
                                  setShowForm(true);
                                }}
                              >
                                Subscribe
                              </button>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start">
                            <div className="col-md-2 mt-1">
                              {getPaypalHistory && getPaypalHistory.count ? (
                                <span className="text-muted fw-bold d-flex fs-5 mt-5">
                                  Total: &nbsp;{" "}
                                  <span className="text-gray-700 fw-bolder text-hover-primary fs-5">
                                    {getPaypalHistory.count}
                                  </span>
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-3 d-flex">
                              <label className="col-form-label text-lg-start mt-3">
                                Record(s) per Page : &nbsp;{" "}
                              </label>
                              <div className="col-md-3 mt-3">
                                <select
                                  className="form-select w-6rem"
                                  data-control="select"
                                  data-placeholder="Select an option"
                                  data-allow-clear="true"
                                  onChange={(e) => handleRecordPerPage(e)}
                                >
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="75">75</option>
                                  <option value="100">100</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-hover table-rounded table-striped border gs-2 mt-6">
                              <thead className="fw-bolder fs-8 text-gray-800">
                                <tr>
                                  <th className="min-w-150px">Plan</th>
                                  <th className="min-w-150px">Status</th>
                                  <th className="min-w-150px">Ends In</th>
                                  <th className="min-w-150px">Price</th>
                                  <th className="min-w-150px">Payer Name</th>
                                  <th className="min-w-150px">Payment Due</th>
                                  <th className="min-w-150px text-center">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="fs-8">
                                {!loadingPaypalGetHistory ? (
                                  getPaypalHistory && getPaypalHistory.result ? (
                                    getPaypalHistory.result.map((item, _id) => {
                                      return (
                                        <tr
                                          key={_id}
                                          className="mt-5"
                                          style={
                                            _id === 0
                                              ? { borderColor: "black" }
                                              : { borderColor: "white" }
                                          }
                                        >
                                          <td>{item.appName}</td>
                                          <td>
                                            <span
                                              className={`badge ${STATUS_BADGES_LIGHT[item.status]
                                                }`}
                                            >
                                              {item.status ? item.status : "--"}
                                            </span>
                                          </td>
                                          <td>
                                            {item.status !== "CANCELLED" &&
                                              item.status !== "INACTIVE" ? (
                                              <span className="mt-2 text-danger fw-boldest">
                                                Ends in {""}
                                                {item && item.finalPaymentTime
                                                  ? moment(
                                                    item.finalPaymentTime
                                                  ).format(DATE.DATE_ONLY)
                                                  : "--"}
                                              </span>
                                            ) : '--'}
                                          </td>
                                          <td>
                                            $
                                            {item.lastPayment
                                              ? item.lastPayment
                                              : "0"}
                                          </td>
                                          <td>{item.paypalPayerName}</td>
                                          <td>
                                            {item.status !== "CANCELLED" &&
                                              item.status !== "INACTIVE"
                                              ? item && item.finalPaymentTime
                                                ? dateConverter(
                                                  item.finalPaymentTime
                                                )
                                                : "--"
                                              : "--"}
                                          </td>
                                          <td className="text-center">
                                            {item.status === "INACTIVE" ? (
                                              <span className='badge badge-light-danger'>Expired</span>
                                            ) : null}
                                            {""}
                                            {item.status === "ACTIVE" ? (
                                              <button
                                                className="btn-sm btn btn-light-danger m-2"
                                                onClick={() =>
                                                  onDeleteItem(
                                                    item.subscriptionId
                                                  )
                                                }
                                              >
                                                Unsubscribe
                                              </button>
                                            ) : null}
                                            {item.status === "CANCELLED" ? (
                                              <span className="mx-5">---</span>
                                            ) : null}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr className="text-center py-3">
                                      <td colSpan="100%">No record(s) found</td>
                                    </tr>
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="100%" className="text-center">
                                      <div
                                        className="spinner-border text-primary m-5"
                                        role="status"
                                      />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="form-group row mb-4 mt-6">
                            <div className="col-lg-12 mb-4 align-items-end d-flex">
                              <div className="col-lg-12 pagination">
                                <ReactPaginate
                                  nextLabel="Next >"
                                  onPageChange={handleSubPagesClick}
                                  pageRangeDisplayed={3}
                                  marginPagesDisplayed={2}
                                  pageCount={totalSubPages}
                                  previousLabel="< Prev"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  renderOnZeroPageCount={null}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : null
          }
        </div >
      </div >
    </>
  );
};

const mapStateToProps = (state) => {
  const { getPaypalSubscriptionHistoryStore } = state;
  return {
    getPackages:
      state && state.packageStore && state.packageStore.getPackages
        ? state.packageStore.getPackages
        : [],
    loadingPack: state && state.packageStore && state.packageStore.loading,
    getList:
      state && state.listStore && state.listStore.getList
        ? state.listStore.getList
        : [],
    loadingList: state && state.listStore && state.listStore.loading,
    getPayments:
      state && state.packPaymentStore && state.packPaymentStore.packPaymentData
        ? state.packPaymentStore.packPaymentData
        : [],
    loading: state && state.packPaymentStore && state.packPaymentStore.loading,
    getPaypalHistory:
      getPaypalSubscriptionHistoryStore &&
        getPaypalSubscriptionHistoryStore.getPaypalHistory
        ? getPaypalSubscriptionHistoryStore.getPaypalHistory
        : [],
    loadingPaypalGetHistory:
      getPaypalSubscriptionHistoryStore &&
        getPaypalSubscriptionHistoryStore.loadingPaypalGetHistory
        ? getPaypalSubscriptionHistoryStore.loadingPaypalGetHistory
        : false,
    deleteSubsPackage:
      state &&
      state.deleteSubsPackageStore &&
      state.deleteSubsPackageStore.deleteSubsPackage,
    statusDSP:
      state &&
      state.deleteSubsPackageStore &&
      state.deleteSubsPackageStore.statusDSP,
    messageDSP:
      state &&
      state.deleteSubsPackageStore &&
      state.deleteSubsPackageStore.messageDSP,
    loadingDSP:
      state &&
      state.deleteSubsPackageStore &&
      state.deleteSubsPackageStore.loadingDSP,
    getPaypal:
      state && state.getPaypalStore && state.getPaypalStore
        ? state.getPaypalStore.getPaypal
        : []
  };
};
const mapDispatchToProps = (dispatch) => ({
  getListDispatch: (params, headers) => dispatch(listActions.get(params, headers)),
  clearListDispatch: () => dispatch(listActions.clear()),
  getPackageDispatch: (params, headers) => dispatch(packageActions.getPackage(params, headers)),
  getPaymentDispatch: (params, headers) =>
    dispatch(packPaymentActions.getPackPayment(params, headers)),
  getPaypalSubscriptionHistoryDispatch: (params, headers) =>
    dispatch(getPaypalSubscriptionHistoryAction.get(params, headers)),
  deleteSubsDispatch: (data, headers) => dispatch(deleteSubsPackageActions.delete(data, headers)),
  clearDeleteSubsDispatch: () => dispatch(deleteSubsPackageActions.clear()),
  addPaypalSubscriptionHistoryDispatch: (data, headers) =>
    dispatch(addPaypalSubscriptionHistoryAction.add(data, headers)),
  getPaypalDispatch: (params, headers) => dispatch(getPaypalActions.get(params, headers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Package);
