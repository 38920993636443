export const addUploadsCBTypes = {
  REQUEST: 'ADD_UPLOAD_CHARGEBACK_REQUEST',
  RESPONSE: 'ADD_UPLOAD_CHARGEBACK_RESPONSE',
  ERROR: 'ADD_UPLOAD_CHARGEBACK_ERROR',
  CLEAR: 'ADD_UPLOAD_CHARGEBACK_CLEAR'
}

export const addUploadsCBActions = {
  addUploadsCB: (data, url, headers) => {
    return {
      type: addUploadsCBTypes.REQUEST,
      payload: data,
      url, headers
    }
  },
  saveaddUploadsCBResponse: (data) => {
    return {
      type: addUploadsCBTypes.RESPONSE,
      data
    }
  },
  clearaddUploadsCB: () => ({
    type: addUploadsCBTypes.CLEAR
  })
}

export const addUploadsCBstatusTypes = {
  REQUEST: 'ADD_UPLOAD_CHARGEBACK_STATUS_REQUEST',
  RESPONSE: 'ADD_UPLOAD_CHARGEBACK_STATUS_RESPONSE',
  ERROR: 'ADD_UPLOAD_CHARGEBACK_STATUS_ERROR',
  CLEAR: 'ADD_UPLOAD_CHARGEBACK_STATUS_CLEAR'
}

export const addUploadsCBstatusActions = {
  add: (data, url, headers) => {
    return {
      type: addUploadsCBstatusTypes.REQUEST,
      payload: data,
      url, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addUploadsCBstatusTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addUploadsCBTypes.CLEAR
  })
}
