import React from "react";
import { toAbsoluteUrl } from "../../../../../theme/helpers";

export const CRMStep1 = (props) => {
  const { onClickNext } = props;

  return (
    <div className="modal-content  h-500px">
      <div className="modal-body bg-secondary">
        <div>
          <div className="text-center pb-5 pe-4">
            <img
              alt="CRM"
              src={toAbsoluteUrl("/media/illustrations/sigma-1/6.png")}
              style={{ height: "214px" }}
            />
            <h3 className="text-info mt-5">Connect with CRM!</h3>
          </div>
          <div className="row mt-5 mb-4">
            <div className="col-lg-3 d-flex justify-content-end">
              <span className="svg-icon svg-icon-success svg-icon-2hx">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                    fill="#00A3FF"
                  />
                  <path
                    className="permanent"
                    d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
            <div className="col-lg-8">
              <p className="fw-bold text-dark fs-3">
                It sounds like you're not related to the CRM. Follow the steps
                to sign in to the CRM system.
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-lg-4" />
            <div className="col-lg-4 d-flex justify-content-center mb-5">
              <button
                className="btn btn-sm btn-darkRed m-1"
                onClick={() => onClickNext(1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CRMStep2 = (props) => {
  const { onClickNext, goBack } = props;

  return (
    <div className="modal-content h-500px">
      <div className="modal-body bg-secondary">
        <div>
          <div className="text-center pb-5 pe-4">
            <img
              alt="CRM"
              src={toAbsoluteUrl("/media/illustrations/sigma-1/17.png")}
              style={{ height: "214px" }}
            />
            <h3 className="text-info mt-5">Login to Shopify!</h3>
          </div>
          <div className="row">
            <div className="col-lg-3 d-flex justify-content-end">
              <span className="svg-icon svg-icon-success svg-icon-2hx">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                    fill="#00A3FF"
                  />
                  <path
                    className="permanent"
                    d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
            <div className="col-lg-8">
              <p className="fw-bold text-dark fs-3">
                Log in to the Shopify App Store
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 d-flex justify-content-end">
              <span className="svg-icon svg-icon-success svg-icon-2hx">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                    fill="#00A3FF"
                  />
                  <path
                    className="permanent"
                    d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
            <div className="col-lg-8">
              <p className="fw-bold text-dark fs-3">
                Find Chargeback app, click it and install it.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 d-flex justify-content-end">
              <span className="svg-icon svg-icon-success svg-icon-2hx">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                    fill="#00A3FF"
                  />
                  <path
                    className="permanent"
                    d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
            <div className="col-lg-8">
              <p className="fw-bold text-dark fs-3">
                On the app listing page, click Add app.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 d-flex justify-content-end">
              <span className="svg-icon svg-icon-success svg-icon-2hx">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                    fill="#00A3FF"
                  />
                  <path
                    className="permanent"
                    d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
            <div className="col-lg-8">
              <p className="fw-bold text-dark fs-3">
                In your Shopify admin, to authorize the use of the app, click
                Install app.
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-lg-3" />
            <div className="col-lg-6 d-flex justify-content-center mb-5">
              <button
                className="btn btn-sm btn-darkRed m-1"
                onClick={() => {
                  goBack(0);
                }}
              >
                Back
              </button>
              <button
                className="btn btn-sm btn-darkRed m-1"
                onClick={() => onClickNext(2)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CRMStep3 = (props) => {
  const { goBack, setConnectedStatus } = props;

  return (
    <div className="modal-content h-500px">
      <div className="modal-body bg-secondary">
        <div>
          <div className="text-center pb-5 pe-4">
            <img
              alt="CRM"
              src={toAbsoluteUrl("/media/illustrations/sigma-1/11.png")}
              style={{ height: "214px" }}
            />
            <h3 className="text-info mt-5">Login to ChargebackZero!</h3>
          </div>
          <div className="row mt-5 mb-5">
            <div className="col-lg-3 d-flex justify-content-end">
              <span className="svg-icon svg-icon-success svg-icon-2hx">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                    fill="#00A3FF"
                  />
                  <path
                    className="permanent"
                    d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
            <div className="col-lg-8">
              <p className="fw-bold text-dark fs-3">
                Sit back and RELAX!!. You will be redirected again to
                ChargebackZero.
              </p>
            </div>
          </div>
          <div className="row mb-5 mt-5">
            <div className="col-lg-3" />
            <div className="col-lg-6 d-flex justify-content-center mb-5">
              <button
                className="btn btn-sm btn-darkRed m-1"
                onClick={() => {
                  goBack(0);
                }}
              >
                Back
              </button>
              <button
                className="btn btn-sm btn-success m-1"
                onClick={() => {
                  window
                    .open(
                      "https://apps.shopify.com/search?q=chargeback&st_source=autocomplete",
                      "_blank"
                    )
                    .focus();
                  setConnectedStatus(true);
                }}
              >
                Connect
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
