export const siteConfigActionsTypes = {
  GET_SITE_CONFIG: 'GET_SITE_CONFIG',
  SAVE_SITE_CONFIG_RESPONSE: 'SAVE_SITE_CONFIG_RESPONSE',
  CLEAR_SITE_CONFIG: 'CLEAR_SITE_CONFIG'
}

export const siteConfigActions = {
  getSiteConfig: (params, headers) => ({
    type: siteConfigActionsTypes.GET_SITE_CONFIG,
    params,
    headers
  }),
  saveSiteConfigResponse: data => ({
    type: siteConfigActionsTypes.SAVE_SITE_CONFIG_RESPONSE,
    data
  }),
  clearSiteConfig: () => ({
    type: siteConfigActionsTypes.CLEAR_SITE_CONFIG
  })
}

export const addSiteConfigTypes = {
  REQUEST: 'ADD_SITE_CONFIG_REQUEST',
  RESPONSE: 'ADD_SITE_CONFIG_RESPONSE',
  ERROR: 'ADD_SITE_CONFIG_ERROR',
  CLEAR: 'ADD_SITE_CONFIG_CLEAR'
}

export const addSiteConfigActions = {
  addSiteConfig: (data, headers) => {
    return {
      type: addSiteConfigTypes.REQUEST,
      payload: data, headers
    }
  },
  saveaddSiteConfigResponse: (data) => {
    return {
      type: addSiteConfigTypes.RESPONSE,
      data
    }
  },
  clearaddSiteConfig: () => ({
    type: addSiteConfigTypes.CLEAR
  })
}

export const siteConfigGetDetailsTypes = {
  GET_SITE_CONFIG_DETAILS: 'GET_SITE_CONFIG_DETAILS',
  SITE_CONFIG_DETAILS_RESPONSE: 'SITE_CONFIG_DETAILS_RESPONSE',
  CLEAR_SITE_CONFIG_DETAILS: 'CLEAR_SITE_CONFIG_DETAILS'
}
export const siteConfigDetailsActions = {
  getSiteConfigDetails: (id, headers) => ({
    type: siteConfigGetDetailsTypes.GET_SITE_CONFIG_DETAILS,
    id, headers
  }),
  saveSiteConfigDetailsResponse: data => ({
    type: siteConfigGetDetailsTypes.SITE_CONFIG_DETAILS_RESPONSE,
    data
  }),
  clearSiteConfigDetails: () => ({
    type: siteConfigGetDetailsTypes.CLEAR_SITE_CONFIG_DETAILS
  })
}

export const editSiteConfigTypes = {
  REQUEST: 'EDIT_SITE_CONFIG_REQUEST',
  RESPONSE: 'EDIT_SITE_CONFIG_RESPONSE',
  ERROR: 'EDIT_SITE_CONFIG_ERROR',
  CLEAR: 'EDIT_SITE_CONFIG_CLEAR'
}

export const editSiteConfigsActions = {
  editSiteConfigs: (id, params, headers) => {
    return {
      type: editSiteConfigTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditSiteConfigsResponse: (data) => {
    return {
      type: editSiteConfigTypes.RESPONSE,
      data
    }
  },
  cleareditSiteConfigs: () => ({
    type: editSiteConfigTypes.CLEAR
  })
}

export const siteConfigDeleteTypes = {
  DELETE_SITE_CONFIG: 'DELETE_SITE_CONFIG',
  SAVE_DELETE_SITE_CONFIG_RESPONSE: 'SAVE_DELETE_SITE_CONFIG_RESPONSE',
  CLEAR_DELETE_SITE_CONFIG: 'CLEAR_DELETE_SITE_CONFIG'
}

export const deleteSiteConfigActions = {
  deleteSiteConfig: (params, headers) => ({
    type: siteConfigDeleteTypes.DELETE_SITE_CONFIG,
    params, headers
  }),
  savemResponseDeleteSiteConfig: data => ({
    type: siteConfigDeleteTypes.SAVE_DELETE_SITE_CONFIG_RESPONSE,
    data
  }),
  clearDeleteSiteConfig: () => ({
    type: siteConfigDeleteTypes.CLEAR_DELETE_SITE_CONFIG
  })
}
