import {
  USER_ERROR,
  USER_MANAGEMENT_ERROR,
  CRM_ERROR,
  REGEX,
  SET_STORAGE,
  SESSION,
  dynamicError
} from "../../../../utils/constants";
import {
  getLocalStorage,
  setDynamicPasswordRegex
} from "../../../../utils/helper";

const userType = getLocalStorage(SET_STORAGE.SET_USER_ROLE);
const isAuthorized = getLocalStorage(SESSION.TOKEN);

export const companyValidation = (values, setErrors) => {
  const errors = {};
  if (!values.company) {
    errors.company = USER_ERROR.COMPANYNAME;
  }
  if (!values.clientAddress) {
    errors.clientAddress = USER_ERROR.ADDRESS_REQUIRED;
  }
  if (!values.clientEmail) {
    errors.clientEmail = USER_ERROR.EMAIL;
  }
  if (values.clientEmail && !REGEX.EMAIL.test(values.clientEmail)) {
    errors.clientEmail = USER_ERROR.EMAIL_INVALID;
  }
  if (values.clientEmail) {
    const getEmailName = values.clientEmail.split("/");
    const emailName = getEmailName && getEmailName[1];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.clientEmail = USER_ERROR.EMAIL_INVALID;
    }
  }
  if (!values.clientPhoneNumber) {
    errors.clientPhoneNumber = USER_ERROR.PHONE_NUMBER;
  }
  if (
    values.clientPhoneNumber &&
    !REGEX.PHONE_NUMBER_2.test(values.clientPhoneNumber)
  ) {
    errors.clientPhoneNumber = USER_ERROR.PHONE_NUMBER_INVALID;
  }
  if (!values.clientZip) {
    errors.clientZip = USER_ERROR.ZIPCODE_REQUIRED;
  }
  if (values.clientZip && !REGEX.ZIP_CODE_1.test(values.clientZip)) {
    errors.clientZip = USER_ERROR.ZIPCODE_INVALID_1;
  }
  if (!values.industry) {
    errors.industry = USER_ERROR.INDUSTRY_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const addMerchantOnlyValidation = (values, setErrors) => {
  const errors = {};
  if (!values.merchantName) {
    errors.merchantName = USER_ERROR.MERCHANT_NAME_REQUIRED;
  }
  if (!values.address) {
    errors.address = USER_ERROR.ADDRESS_REQUIRED;
  }
  if (!values.email) {
    errors.email = USER_ERROR.EMAIL;
  }
  if (values.email && !REGEX.EMAIL.test(values.email)) {
    errors.email = USER_ERROR.EMAIL_INVALID;
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = USER_ERROR.PHONE_NUMBER;
  }
  if (values.phoneNumber && !REGEX.PHONE_NUMBER_2.test(values.phoneNumber)) {
    errors.phoneNumber = USER_ERROR.PHONE_NUMBER_INVALID;
  }
  if (!values.zip) {
    errors.zip = USER_ERROR.ZIPCODE_REQUIRED;
  }
  if (values.zip && !REGEX.ZIP_CODE_1.test(values.zip)) {
    errors.zip = USER_ERROR.ZIPCODE_INVALID_1;
  }
  if (!values.industry) {
    errors.industry = USER_ERROR.INDUSTRY_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const addMerchantValidation = (values, setErrors) => {
  const errors = {};
  if (!values.merchantName) {
    errors.merchantName = USER_ERROR.MERCHANT_NAME_REQUIRED;
  }
  if (!values.address) {
    errors.address = USER_ERROR.ADDRESS_REQUIRED;
  }
  if (!values.city) {
    errors.city = USER_ERROR.CITY_REQUIRED;
  }
  if (!values.email) {
    errors.email = USER_ERROR.EMAIL;
  }
  if (values.email && !REGEX.EMAIL.test(values.email)) {
    errors.email = USER_ERROR.EMAIL_INVALID;
  }
  if (values.email) {
    const getEmailName = values.email.split("@");
    const emailName = getEmailName && getEmailName[0];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.email = USER_ERROR.EMAIL_INVALID;
    }
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = USER_ERROR.PHONE_NUMBER;
  }
  if (values.phoneNumber && !REGEX.PHONE_NUMBER_2.test(values.phoneNumber)) {
    errors.phoneNumber = USER_ERROR.PHONE_NUMBER_INVALID;
  }
  if (!values.state) {
    errors.state = USER_ERROR.STATE_REQUIRED;
  }
  if (!values.ext) {
    errors.ext = USER_ERROR.EXISTING_PHONE_REQUIRED;
  }
  if (!values.zip) {
    errors.zip = USER_ERROR.ZIPCODE_REQUIRED;
  }
  if (values.zip && !REGEX.ZIP_CODE_1.test(values.zip)) {
    errors.zip = USER_ERROR.ZIPCODE_INVALID_1;
  }
  if (!values.country) {
    errors.country = USER_ERROR.COUNTRY_REQUIRED;
  }
  if (!values.industry) {
    errors.industry = USER_ERROR.INDUSTRY_REQUIRED;
  }
  if (!values.midNumber) {
    errors.midNumber = USER_ERROR.MIDNUMBER_REQUIRED;
  }
  if (!values.doingBusinessAs) {
    errors.doingBusinessAs = USER_ERROR.DOING_BUSINESS_AS_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const addMIDCRMValidation = (values, setErrors) => {
  const errors = {};
  if (!values.midNumber) {
    errors.midNumber = USER_ERROR.MIDNUMBER_REQUIRED;
  }
  if (!values.doingBusinessAs) {
    errors.doingBusinessAs = USER_ERROR.DOING_BUSINESS_AS_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const merchantFormValidation = (values, formData, setErrors) => {
  const errors = {};
  if (!values.merchantName) {
    errors.merchantName = USER_ERROR.MERCHANT_NAME_REQUIRED;
  }
  if (!values.company) {
    errors.company = USER_ERROR.COMPANYNAME;
  }
  if (!values.merchantAddress) {
    errors.merchantAddress = USER_ERROR.ADDRESS_REQUIRED;
  }
  if (!values.merchantEmail) {
    errors.merchantEmail = USER_ERROR.EMAIL;
  }
  if (values.merchantEmail && !REGEX.EMAIL.test(values.merchantEmail)) {
    errors.merchantEmail = USER_ERROR.EMAIL_INVALID;
  }
  if (!values.merchantPhoneNumber) {
    errors.merchantPhoneNumber = USER_ERROR.PHONE_NUMBER;
  }
  if (
    values.merchantPhoneNumber &&
    !REGEX.PHONE_NUMBER_2.test(values.merchantPhoneNumber)
  ) {
    errors.merchantPhoneNumber = USER_ERROR.PHONE_NUMBER_INVALID;
  }
  if (!values.merchantZip) {
    errors.merchantZip = USER_ERROR.ZIPCODE_REQUIRED;
  }
  if (values.merchantZip && !REGEX.ZIP_CODE_1.test(values.merchantZip)) {
    errors.merchantZip = USER_ERROR.ZIPCODE_INVALID_1;
  }
  if (!values.industry) {
    errors.industry = USER_ERROR.INDUSTRY_REQUIRED;
  }
  if (!formData.midNumber) {
    errors.midNumber = USER_ERROR.MIDNUMBER_REQUIRED;
  }
  if (!formData.doingBusinessAs) {
    errors.doingBusinessAs = USER_ERROR.DOING_BUSINESS_AS_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const merchantValidation = (values, setErrors) => {
  const errors = {};
  if (!values.merchantName) {
    errors.merchantName = USER_ERROR.MERCHANT_NAME_REQUIRED;
  }
  if (!values.merchantAddress) {
    errors.merchantAddress = USER_ERROR.ADDRESS_REQUIRED;
  }
  if (!values.merchantEmail) {
    errors.merchantEmail = USER_ERROR.EMAIL;
  }
  if (values.merchantEmail && !REGEX.EMAIL.test(values.merchantEmail)) {
    errors.merchantEmail = USER_ERROR.EMAIL_INVALID;
  }
  if (!values.merchantPhoneNumber) {
    errors.merchantPhoneNumber = USER_ERROR.PHONE_NUMBER;
  }
  if (
    values.merchantPhoneNumber &&
    !REGEX.PHONE_NUMBER_2.test(values.merchantPhoneNumber)
  ) {
    errors.merchantPhoneNumber = USER_ERROR.PHONE_NUMBER_INVALID;
  }
  if (!values.merchantZip) {
    errors.merchantZip = USER_ERROR.ZIPCODE_REQUIRED;
  }
  if (values.merchantZip && !REGEX.ZIP_CODE_1.test(values.merchantZip)) {
    errors.merchantZip = USER_ERROR.ZIPCODE_INVALID_1;
  }
  if (!values.industry) {
    errors.industry = USER_ERROR.INDUSTRY_REQUIRED;
  }
  if (!values.mid) {
    errors.mid = USER_ERROR.MIDNUMBER_REQUIRED;
  }
  if (!values.doingBusinessAs) {
    errors.doingBusinessAs = USER_ERROR.DOING_BUSINESS_AS_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const userValidation = (values, setErrors, configData) => {
  const errors = {};
  if (!values.email) {
    errors.email = USER_ERROR.EMAIL_REQUIRED;
  } else if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = USER_ERROR.EMAIL_INVALID;
  }
  if (values.email) {
    const getEmailName = values.email.split("@");
    const emailName = getEmailName && getEmailName[0];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.email = USER_ERROR.EMAIL_INVALID;
    }
  }
  const getPasswordRegex = setDynamicPasswordRegex(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
  if (!values.password) {
    errors.password = USER_ERROR.PASSWORD_REQUIRED
  } else if (values.password && !getPasswordRegex.test(values.password)) {
    errors.password = dynamicError(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
  }
  if (!values.cPassword) {
    errors.cPassword = USER_ERROR.CONFIRM_PASSWORD_REQUIRED;
  }
  if (values.password !== values.cPassword) {
    errors.cPassword = USER_ERROR.PASSWORD_SAME;
  }
  if (!values.firstName) {
    errors.firstName = USER_MANAGEMENT_ERROR.USER_FNAME;
  }
  if (!values.lastName) {
    errors.lastName = USER_MANAGEMENT_ERROR.USER_LNAME;
  }
  if (isAuthorized && !values.roleId) {
    errors.roleId = USER_ERROR.ROLE_REQUIRED;
  }
  if (!values.mobile) {
    errors.mobile = USER_ERROR.PHONE_NUMBER;
  }
  if (values.mobile && !REGEX.PHONE_NUMBER_2.test(values.mobile)) {
    errors.mobile = USER_ERROR.PHONE_NUMBER_INVALID;
  }
  setErrors(errors);
  return errors;
};

export const userInfoValidation = (values, setErrors) => {
  const errors = {};
  if (!values.email) {
    errors.email = USER_ERROR.EMAIL_REQUIRED;
  } else if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = USER_ERROR.EMAIL_INVALID;
  }
  if (values.email) {
    const getEmailName = values.email.split("@");
    const emailName = getEmailName && getEmailName[0];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.email = USER_ERROR.EMAIL_INVALID;
    }
  }
  if (!values.firstName) {
    errors.firstName = USER_MANAGEMENT_ERROR.USER_FNAME;
  }
  if (!values.lastName) {
    errors.lastName = USER_MANAGEMENT_ERROR.USER_LNAME;
  }
  if (userType && !['Client User'].includes(userType) && !values.roleId) {
    errors.roleId = USER_ERROR.ROLE_REQUIRED;
  }
  if (!values.mobile) {
    errors.mobile = USER_ERROR.PHONE_NUMBER;
  }
  if (values.mobile && !REGEX.PHONE_NUMBER_2.test(values.mobile)) {
    errors.mobile = USER_ERROR.PHONE_NUMBER_INVALID;
  }
  setErrors(errors);
  return errors;
};

export const integrationValidation = (values, setErrors) => {
  const errors = {};
  if (!values.companyName) {
    errors.companyName = USER_ERROR.COMPANYNAME;
  }
  if (!values.processorType) {
    errors.processorType = CRM_ERROR.PROCESSOR_TYPE_REQUIRED;
  }
  if (!values.paymentGateway) {
    errors.paymentGateway = CRM_ERROR.PAYMENT_GATE_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const crmValidation = (values, setErrors) => {
  const errors = {};
  if (!values.crmId) {
    errors.crmId = USER_ERROR.CRM_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const processorValidation = (values, setErrors) => {
  const errors = {};
  if (!values.paymentProcessorId) {
    errors.paymentProcessorId = CRM_ERROR.PROCESSOR_TYPE_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const processorUpdateValidation = (values, setErrors) => {
  const errors = {};
  if (!values.paymentProcessorId) {
    errors.paymentProcessorId = CRM_ERROR.PROCESSOR_TYPE_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const paymentValidation = (values, setErrors) => {
  const errors = {};
  if (!values.paymentGateway) {
    errors.paymentGateway = CRM_ERROR.PAYMENT_GATE_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const paymentUpdateValidation = (values, setErrors) => {
  const errors = {};
  if (!values.paymentGatewayId) {
    errors.paymentGatewayId = CRM_ERROR.PAYMENT_GATE_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const addCrmValidation = (values, setErrors) => {
  const errors = {};
  if (!values.apiKey) {
    errors.apiKey = CRM_ERROR.API_KEY_REQUIRED;
  }
  if (!values.apiSecretKey) {
    errors.apiSecretKey = CRM_ERROR.API_SECRET_REQUIRED;
  }
  if (!values.apiEndPoint) {
    errors.apiEndPoint = CRM_ERROR.API_END_POINT_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const addPaymentGateValidation = (values, setErrors) => {
  const errors = {};
  if (!values.gatewayEndPoint) {
    errors.gatewayEndPoint = CRM_ERROR.API_KEY_REQUIRED;
  }
  if (!values.gatewayApiKey) {
    errors.gatewayApiKey = CRM_ERROR.API_SECRET_REQUIRED;
  }
  if (!values.gatewayApiSecretKey) {
    errors.gatewayApiSecretKey = CRM_ERROR.API_END_POINT_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const addGateValidation = (values, setErrors) => {
  const errors = {};
  if (!values.apiEndpoint) {
    errors.apiEndpoint = CRM_ERROR.API_KEY_REQUIRED;
  }
  if (!values.apiKey) {
    errors.apiKey = CRM_ERROR.API_SECRET_REQUIRED;
  }
  if (!values.apiSecretKey) {
    errors.apiSecretKey = CRM_ERROR.API_END_POINT_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const addSFTPValidation = (values, setErrors, configData) => {
  const errors = {};
  if (!values.hostName) {
    errors.hostName = CRM_ERROR.HOST_NAME_REQUIRED;
  }
  if (!values.portNumber) {
    errors.portNumber = CRM_ERROR.PORT_NUMBER_REQUIRED;
  }
  if (!values.username) {
    errors.username = CRM_ERROR.USER_NAME_REQUIRED;
  }
  const getPasswordRegex = setDynamicPasswordRegex(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
  if (!values.password) {
    errors.password = CRM_ERROR.PASSWORD_REQUIRED
  } else if (values.password && !getPasswordRegex.test(values.password)) {
    errors.password = dynamicError(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
  }
  setErrors(errors);
  return errors;
};

export const addFaxValidation = (values, setErrors) => {
  const errors = {};
  if (!values.faxNumber) {
    errors.faxNumber = CRM_ERROR.FAX_NUMBER_REQUIRED;
  }
  setErrors(errors);
  return errors;
};

export const addEmailValidation = (values, setErrors) => {
  const errors = {};
  if (!values.email) {
    errors.email = CRM_ERROR.EMAIL_REQUIRED;
  }
  if (!values.email) {
    errors.email = USER_ERROR.EMAIL_REQUIRED;
  } else if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = USER_ERROR.EMAIL_INVALID;
  }
  if (values.email) {
    const getEmailName = values.email.split("@");
    const emailName = getEmailName && getEmailName[0];
    if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
      errors.email = USER_ERROR.EMAIL_INVALID;
    }
  }
  setErrors(errors);
  return errors;
};

export const addProcessorlValidation = (values, setErrors, configData) => {
  const errors = {};
  if (!values.disputeSubmissionMode) {
    errors.disputeSubmissionMode = CRM_ERROR.DISPUTE_MODE_REQUIRED;
  }
  if (values.disputeSubmissionMode && values.disputeSubmissionMode === "FAX") {
    if (!values.faxNumber) {
      errors.faxNumber = CRM_ERROR.FAX_REQUIRED;
    }
  }
  if (values.disputeSubmissionMode && values.disputeSubmissionMode === "SFTP") {
    if (!values.username) {
      errors.username = CRM_ERROR.USER_NAME_REQUIRED;
    }
    const getPasswordRegex = setDynamicPasswordRegex(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
    if (!values.password) {
      errors.password = CRM_ERROR.PASSWORD_REQUIRED
    } else if (values.password && !getPasswordRegex.test(values.password)) {
      errors.password = dynamicError(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
    }
    if (!values.hostName) {
      errors.hostName = CRM_ERROR.HOST_NAME_REQUIRED;
    }
    if (!values.portNumber) {
      errors.portNumber = CRM_ERROR.PORTNUMBER_REQUIRED;
    }
  }
  if (
    values.disputeSubmissionMode &&
    values.disputeSubmissionMode === "EMAIL"
  ) {
    if (!values.email) {
      errors.email = USER_ERROR.EMAIL_REQUIRED;
    }
  }
  if (values.disputeSubmissionMode && values.disputeSubmissionMode === "API") {
    if (!values.requestApiKey) {
      errors.requestApiKey = CRM_ERROR.REQUEST_API_KEY_REQUIRED;
    }
    if (!values.requestApiSecret) {
      errors.requestApiSecret = CRM_ERROR.REQUEST_API_SECRET_REQUIRED;
    }
    if (!values.requestApiEndPoint) {
      errors.requestApiEndPoint = CRM_ERROR.REQUEST_API_END_POINT_REQUIRED;
    }
  }
  setErrors(errors);
  return errors;
};

export const addApilValidation = (values, setErrors, configData) => {
  const errors = {};
  if (!values.method) {
    errors.method = CRM_ERROR.METHOD_REQUIRED;
  }
  // if (values.method === "API") {
  //   if (!values.apiKey) {
  //     errors.apiKey = CRM_ERROR.API_KEY_REQUIRED;
  //   }
  //   if (!values.apiSecretKey) {
  //     errors.apiSecretKey = CRM_ERROR.API_SECRET_REQUIRED;
  //   }
  //   if (!values.apiEndPoint) {
  //     errors.apiEndPoint = CRM_ERROR.API_END_POINT_REQUIRED;
  //   }
  // }
  // if (values.method === "OAUTH") {
  //   if (!values.oAuthUrl) {
  //     errors.oAuthUrl = CRM_ERROR.OAUTH_URL;
  //   }
  //   if (!values.oAuthUserName) {
  //     errors.oAuthUserName = CRM_ERROR.OAUTH_USERNAME;
  //   }
  //   if (!values.oAuthPassword) {
  //     errors.oAuthPassword = CRM_ERROR.OAUTH_PASSWORD;
  //   }
  // }
  if (!values.disputeSubmissionMode) {
    errors.disputeSubmissionMode = CRM_ERROR.DISPUTE_MODE_REQUIRED;
  }
  if (values.disputeSubmissionMode && values.disputeSubmissionMode === "FAX") {
    if (!values.faxNumber) {
      errors.faxNumber = CRM_ERROR.FAX_REQUIRED;
    }
  }
  if (values.disputeSubmissionMode && values.disputeSubmissionMode === "SFTP") {
    if (!values.username) {
      errors.username = CRM_ERROR.USER_NAME_REQUIRED;
    }
    const getPasswordRegex = setDynamicPasswordRegex(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
    if (!values.password) {
      errors.password = CRM_ERROR.PASSWORD_REQUIRED
    } else if (values.password && !getPasswordRegex.test(values.password)) {
      errors.password = dynamicError(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
    }
    if (!values.hostName) {
      errors.hostName = CRM_ERROR.HOST_NAME_REQUIRED;
    }
    if (!values.portNumber) {
      errors.portNumber = CRM_ERROR.PORTNUMBER_REQUIRED;
    }
  }
  if (
    values.disputeSubmissionMode &&
    values.disputeSubmissionMode === "EMAIL"
  ) {
    if (!values.email) {
      errors.email = USER_ERROR.EMAIL_REQUIRED;
    }
    if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = USER_ERROR.EMAIL_INVALID;
    }
    if (values.email) {
      const getEmailName = values.email.split("@");
      const emailName = getEmailName && getEmailName[0];
      if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
        errors.email = USER_ERROR.EMAIL_INVALID;
      }
    }
  }
  if (values.disputeSubmissionMode && values.disputeSubmissionMode === "API") {
    if (!values.requestApiKey) {
      errors.requestApiKey = CRM_ERROR.REQUEST_API_KEY_REQUIRED;
    }
    if (!values.requestApiSecret) {
      errors.requestApiSecret = CRM_ERROR.REQUEST_API_SECRET_REQUIRED;
    }
    if (!values.requestApiEndPoint) {
      errors.requestApiEndPoint = CRM_ERROR.REQUEST_API_END_POINT_REQUIRED;
    }
  }
  setErrors(errors);
  return errors;
};

export const addProcessorValidation = (values, setErrors, configData) => {
  const errors = {};
  if (!values.method) {
    errors.method = CRM_ERROR.METHOD_REQUIRED;
  }
  if (values.method === "API") {
    if (!values.apiKey) {
      errors.apiKey = CRM_ERROR.API_KEY_REQUIRED;
    }
    if (!values.apiSecretKey) {
      errors.apiSecretKey = CRM_ERROR.API_SECRET_REQUIRED;
    }
    if (!values.apiEndPoint) {
      errors.apiEndPoint = CRM_ERROR.API_END_POINT_REQUIRED;
    }
  }
  if (values.method === "OAUTH") {
    if (!values.oAuthUrl) {
      errors.oAuthUrl = CRM_ERROR.OAUTH_URL;
    }
    if (!values.oAuthUserName) {
      errors.oAuthUserName = CRM_ERROR.OAUTH_USERNAME;
    }
    if (!values.oAuthPassword) {
      errors.oAuthPassword = CRM_ERROR.OAUTH_PASSWORD;
    }
  }
  if (values.disputeSubmissionMode && values.disputeSubmissionMode === "FAX") {
    if (!values.faxNumber) {
      errors.faxNumber = CRM_ERROR.FAX_REQUIRED;
    }
  }
  if (values.disputeSubmissionMode && values.disputeSubmissionMode === "SFTP") {
    if (!values.username) {
      errors.username = CRM_ERROR.USER_NAME_REQUIRED;
    }
    const getPasswordRegex = setDynamicPasswordRegex(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
    if (!values.password) {
      errors.password = CRM_ERROR.PASSWORD_REQUIRED
    } else if (values.password && !getPasswordRegex.test(values.password)) {
      errors.password = dynamicError(configData && configData.passwordMinChar, configData && configData.passwordMaxChar)
    }
    if (!values.hostName) {
      errors.hostName = CRM_ERROR.HOST_NAME_REQUIRED;
    }
    if (!values.portNumber) {
      errors.portNumber = CRM_ERROR.PORTNUMBER_REQUIRED;
    }
  }
  if (
    values.disputeSubmissionMode &&
    values.disputeSubmissionMode === "EMAIL"
  ) {
    if (!values.email) {
      errors.email = USER_ERROR.EMAIL_REQUIRED;
    }
    if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = USER_ERROR.EMAIL_INVALID;
    }
    if (values.email) {
      const getEmailName = values.email.split("@");
      const emailName = getEmailName && getEmailName[0];
      if (REGEX.ALPHA_UPPER_CASE.test(emailName)) {
        errors.email = USER_ERROR.EMAIL_INVALID;
      }
    }
  }
  if (values.disputeSubmissionMode && values.disputeSubmissionMode === "API") {
    if (!values.requestApiKey) {
      errors.requestApiKey = CRM_ERROR.REQUEST_API_KEY_REQUIRED;
    }
    if (!values.requestApiSecret) {
      errors.requestApiSecret = CRM_ERROR.REQUEST_API_SECRET_REQUIRED;
    }
    if (!values.requestApiEndPoint) {
      errors.requestApiEndPoint = CRM_ERROR.REQUEST_API_END_POINT_REQUIRED;
    }
  }
  setErrors(errors);
  return errors;
};

export const packageValidation = (values, setErrors) => {
  const errors = {};
  if (!values) {
    errors.subscriptionErr = "Please Select at least one subscription!";
  }
  setErrors(errors);
  return errors;
};

export default function Sdkvalidate(values, setErrors) {
  const errors = {}
  if (!values.clientId) {
    errors.clientId = USER_ERROR.CLIENT_REQUIRED
  }
  setErrors(errors)
  return errors
}