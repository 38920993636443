export const addAcquirerSettingsActionsTypes = {
  REQUEST: 'ADD_ACQUIRER_SETTINGS_REQUEST',
  RESPONSE: 'ADD_ACQUIRER_SETTINGS_RESPONSE',
  ERROR: 'ADD_ACQUIRER_SETTINGS_ERROR',
  CLEAR: 'ADD_ACQUIRER_SETTINGS_CLEAR'
}

export const addAcquirerSettingsActions = {
  add: (data, headers) => {
    return {
      type: addAcquirerSettingsActionsTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addAcquirerSettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addAcquirerSettingsActionsTypes.CLEAR
  })
}

export const acquirerActionsTypes = {
  GET_ACQUIRER: 'GET_ACQUIRER',
  SAVE_ACQUIRER_RESPONSE: 'SAVE_ACQUIRER_RESPONSE',
  CLEAR_ACQUIRER: 'CLEAR_ACQUIRER'
}

export const acquirerActions = {
  getAcquirer: (params, headers) => ({
    type: acquirerActionsTypes.GET_ACQUIRER,
    params,
    headers
  }),
  saveAcquirerResponse: data => ({
    type: acquirerActionsTypes.SAVE_ACQUIRER_RESPONSE,
    data
  }),
  clearAcquirer: () => ({
    type: acquirerActionsTypes.CLEAR_ACQUIRER
  })
}

export const getAcquirerSettingsDetailsActionsTypes = {
  REQUEST: 'GET_ACQUIRER_DETAILS_REQUEST',
  RESPONSE: 'GET_ACQUIRER_DETAILS_RESPONSE',
  ERROR: 'GET_ACQUIRER_DETAILS_ERROR',
  CLEAR: 'GET_ACQUIRER_DETAILS_CLEAR'
}
export const getAcquirerSettingsDetailsActions = {
  getDetails: (id, headers) => ({
    type: getAcquirerSettingsDetailsActionsTypes.REQUEST,
    id,
    headers
  }),
  saveResponse: (data) => ({
    type: getAcquirerSettingsDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getAcquirerSettingsDetailsActionsTypes.CLEAR
  })
}

export const updateAcquirerSettingsActionsTypes = {
  REQUEST: 'UPDATE_ACQUIRER_SETTINGS_REQUEST',
  RESPONSE: 'UPDATE_ACQUIRER_SETTINGS_RESPONSE',
  ERROR: 'UPDATE_ACQUIRER_SETTINGS_ERROR',
  CLEAR: 'UPDATE_ACQUIRER_SETTINGS_CLEAR'
}

export const updateAcquirerSettingsActions = {
  update: (id, params, headers) => {
    return {
      type: updateAcquirerSettingsActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updateAcquirerSettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updateAcquirerSettingsActionsTypes.CLEAR
  })
}

export const deleteAcquirerSettingsActionsTypes = {
  REQUEST: 'DELETE_ACQUIRER_SETTINGS_REQUEST',
  RESPONSE: 'DELETE_ACQUIRER_SETTINGS_RESPONSE',
  ERROR: 'DELETE_ACQUIRER_SETTINGS_ERROR',
  CLEAR: 'DELETE_ACQUIRER_SETTINGS_CLEAR'
}

export const deleteAcquirerSettingsActions = {
  delete: (params, headers) => ({
    type: deleteAcquirerSettingsActionsTypes.REQUEST,
    params,
    headers
  }),
  savemResponse: (data) => ({
    type: deleteAcquirerSettingsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteAcquirerSettingsActionsTypes.CLEAR
  })
}
