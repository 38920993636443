export const saveClientIdTypes = {
  SAVE: 'SAVE_CLIENT_ID',
  CLEAR: 'CLEAR_CLIENT_Id'
}

export const getClientIdActions = {
  save: data => ({
    type: saveClientIdTypes.SAVE,
    data
  }),
  clear: () => ({
    type: saveClientIdTypes.CLEAR
  })
}

export const clientActionsTypes = {
  GET_CLIENT: 'GET_CLIENT',
  SAVE_CLIENT_RESPONSE: 'SAVE_CLIENT_RESPONSE',
  CLEAR_CLIENT: 'CLEAR_CLIENT'
}

export const clientActions = {
  getClient: (params, headers) => ({
    type: clientActionsTypes.GET_CLIENT,
    params, headers
  }),
  saveclientResponse: data => ({
    type: clientActionsTypes.SAVE_CLIENT_RESPONSE,
    data
  }),
  clearClient: () => ({
    type: clientActionsTypes.CLEAR_CLIENT
  })
}

export const usersRoleTypes = {
  REQUEST: 'GET_USER_ROLE',
  RESPONSE: 'SAVE_USER_ROLE_RESPONSE',
  CLEAR: 'CLEAR_USER_ROLE'
}

export const userRoleActions = {
  getUserRole: (params, headers) => ({
    type: usersRoleTypes.REQUEST,
    params, headers
  }),
  saveUserRoleResponse: data => ({
    type: usersRoleTypes.RESPONSE,
    data
  }),
  clearUserRole: () => ({
    type: usersRoleTypes.CLEAR
  })
}

export const industryTypes = {
  REQUEST: 'GET_INDUSTRY',
  RESPONSE: 'SAVE_INDUSTRY_RESPONSE',
  CLEAR: 'CLEAR_INDUSTRY'
}

export const industryActions = {
  getIndustry: (params, headers) => ({
    type: industryTypes.REQUEST,
    params, headers
  }),
  saveIndustryResponse: data => ({
    type: industryTypes.RESPONSE,
    data
  }),
  clearIndustry: () => ({
    type: industryTypes.CLEAR
  })
}
