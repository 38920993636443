import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  REGEX,
  STATUS_RESPONSE,
  SAVE_CURRENT,
  FILE_FORMAT_MID,
  DROPZONE_MESSAGES,
  API_URL,
  SESSION,
  API,
} from "../../../utils/constants";
import { addMIDCRMValidation } from "../onboarding/userDetails/validation";
import _ from "lodash";
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  headersWithAuth,
} from "../../../utils/helper";
import { getMIDCRMPayload } from "../onboarding/functions/formData";
import {
  warningAlert,
  confirmAlert,
  confirmationAlert,
} from "../../../utils/alerts";
import {
  editmidCRMActions,
  midCRMActions,
  addmidCRMActions,
  midCRMGetDetailsActions,
  addMerchantUploadActions,
} from "../../../store/actions";
import Dropzone, { useDropzone } from "react-dropzone";
import styled from "styled-components";

const StyledDiv = styled.div`
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
    width: 500px;
    height: 46px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #9ab6d9;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
`;
const MidForm = (props) => {
  const {
    statusEMIDCRM,
    messageEMIDCRM,
    cleareditmidCRMDispatch,
    getmidCRMDetailsDispatch,
    editmidCRMDispatch,
    loadingEMIDCRM,
    loadingGMIDCRM,
    editMode,
    getDetailsMID,
    statusGMIDCRM,
    clearmidCRMDispatch,
    loadingAMIDCRM,
    addmidCRMDispatch,
    statusAMIDCRM,
    messageAMIDCRM,
    clearAddmidCRMDispatch,
    getMidCrmDispatch,
    setTable,
    currentMerchantId,
    currentId,
    setCurrentId,
    clearmidCRMDetailsDispatch,
    dataAMIDCRM,
    addMerchantUploadDispatch,
    clearaddMerchantUploadDispatch,
    dataAMUpload,
    loadingAMUpload,
    statusAMUpload,
    deleteMerchantStatus,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };
  const { getRootProps } = useDropzone();
  const currentMidID = getLocalStorage(SAVE_CURRENT.MID_ID);
  const currentClientID = getLocalStorage(SAVE_CURRENT.CLIENT_ID);
  const [errors, setErrors] = useState({});
  const [targetFileName, setTargetFileName] = useState();
  const [formData, setFormData] = useState({
    midNumber: "",
    descriptorName: "",
    descriptorId: "",
    doingBusinessAs: "",
    bin: "",
    caId: "",
  });

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = () => {
    const errorMsg = addMIDCRMValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      if (editMode) {
        const getPayload = getMIDCRMPayload(formData);
        editmidCRMDispatch(
          currentId,
          getPayload,
          isAuthorized ? headersWithAuth : headercurrentWithApiKey
        );
      } else {
        removeLocalStorage(SAVE_CURRENT.MID_ID);
        const payload = {
          ...formData,
          clientId: currentClientID,
          merchantId: currentMerchantId,
        };
        addmidCRMDispatch(
          payload,
          isAuthorized ? headersWithAuth : headercurrentWithApiKey
        );
      }
    }
  };

  useEffect(() => {
    if (
      getDetailsMID &&
      getDetailsMID._id &&
      statusGMIDCRM === STATUS_RESPONSE.SUCCESS_MSG
    ) {
      const setPayload = getMIDCRMPayload(getDetailsMID);
      setFormData(setPayload);
      clearmidCRMDispatch();
      clearmidCRMDetailsDispatch();
    }
  }, [statusGMIDCRM]);

  const onConfirm = () => {
    const midParam = {
      clientId: currentClientID,
      merchantId: currentMerchantId,
    };
    getMidCrmDispatch(
      midParam,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    setTable(true);
  };

  useEffect(() => {
    const midId = currentMidID ? currentMidID : currentId;
    if (midId) {
      getmidCRMDetailsDispatch(
        midId,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    }
  }, [currentId, currentMidID]);

  useEffect(() => {
    return () => {
      setFormData({
        midNumber: "",
        descriptorName: "",
        descriptorId: "",
        doingBusinessAs: "",
        bin: "",
        caId: "",
      });
      setCurrentId();
      clearmidCRMDetailsDispatch();
    };
  }, []);

  useEffect(() => {
    if (statusAMIDCRM === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataAMIDCRM && dataAMIDCRM.data && dataAMIDCRM.data._id) {
        setLocalStorage(SAVE_CURRENT.MID_ID, dataAMIDCRM.data._id);
      }
      confirmationAlert(
        "Success",
        messageAMIDCRM,
        "success",
        "Back to MID",
        "Ok",
        () => {
          onConfirm();
        },
        () => {}
      );
      clearAddmidCRMDispatch();
    } else if (statusAMIDCRM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        "Error",
        messageAMIDCRM,
        "error",
        "Close",
        "Ok",
        () => {
          onConfirm();
        },
        () => {}
      );
    }
    clearAddmidCRMDispatch();
  }, [statusAMIDCRM]);

  useEffect(() => {
    if (statusEMIDCRM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageEMIDCRM,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      cleareditmidCRMDispatch();
    } else if (statusEMIDCRM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageEMIDCRM, "", "Ok");
    }
    cleareditmidCRMDispatch();
  }, [statusEMIDCRM]);

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  const handleFileUpload = (files, name) => {
    setErrors({ ...errors, [name]: "" });
    setTargetFileName(name);
    const maxFileSize = 5;
    let isValidFileFormat = true;
    let currentFileName = "";
    const data = new FormData();
    files.forEach((item) => {
      const fileType = item && item.type;
      const uploadedFileSize = item && item.size;
      currentFileName = item && item.name;
      isValidFileFormat = _.includes(FILE_FORMAT_MID, fileType);
      const fileSize = Number(maxFileSize) * 1024 * 1024;
      if (isValidFileFormat) {
        if (uploadedFileSize < fileSize) {
          data.append("type", "screenshot");
          data.append("file_to_upload", item);
          addMerchantUploadDispatch(data);
        } else {
          setErrors({
            ...errors,
            [name]: `File size must below ${
              fileSize / 1048576
            } MB!. You file size is : ${Math.round(uploadedFileSize / 1024)}KB`,
          });
        }
      } else {
        setErrors({ ...errors, [name]: DROPZONE_MESSAGES.IMAGE_INVALID });
      }
    });
  };

  useEffect(() => {
    if (statusAMUpload === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataAMUpload && dataAMUpload.path) {
        setFormData((values) => ({
          ...values,
          [targetFileName]: dataAMUpload.path,
        }));
      }
      clearaddMerchantUploadDispatch();
      setTargetFileName();
    } else if (deleteMerchantStatus === STATUS_RESPONSE.ERROR_MSG) {
      setTargetFileName();
      clearaddMerchantUploadDispatch();
    }
  }, [statusAMUpload]);

  return (
    <>
      {!loadingGMIDCRM ? (
        <div className="card-header bg-skyBlue py-10">
          <div className="card-body m-8">
            <h2 className="mb-5"> {editMode ? "Update" : "Add"} MID Info </h2>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  MID number:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="midNumber"
                    type="text"
                    className="form-control"
                    placeholder="MID number"
                    onChange={(e) => handleChange(e)}
                    value={formData.midNumber || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.midNumber && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.midNumber}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Descriptor name:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="descriptorName"
                    type="text"
                    className="form-control"
                    placeholder="Descriptor name"
                    onChange={(e) => handleChange(e)}
                    value={formData.descriptorName || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.descriptorName && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.descriptorName}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Descriptor ID:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="descriptorId"
                    type="text"
                    className="form-control"
                    placeholder="Descriptor ID"
                    onChange={(e) => handleChange(e)}
                    value={formData.descriptorId || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.descriptorId && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.descriptorId}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  Doing business as:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="doingBusinessAs"
                    type="text"
                    className="form-control"
                    placeholder="Doing business as"
                    onChange={(e) => handleChange(e)}
                    value={formData.doingBusinessAs || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.doingBusinessAs && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.doingBusinessAs}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  BIN (Bank Identification Number):
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="bin"
                    type="text"
                    className="form-control"
                    placeholder="BIN (Bank Identification Number)"
                    onChange={(e) => handleChange(e)}
                    value={formData.bin || ""}
                    maxLength={6}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.NUMERIC.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.bin && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.bin}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  CAID :
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="caId"
                    type="text"
                    className="form-control"
                    placeholder="CAID (Cardholder Acceptance Identification Number)"
                    onChange={(e) => handleChange(e)}
                    value={formData.caId || ""}
                    maxLength={6}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.NUMERIC.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.caId && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.caId}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Store Name:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="storeName"
                    type="text"
                    className="form-control"
                    placeholder=" Store Name"
                    onChange={(e) => handleChange(e)}
                    value={formData.storeName || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.storeName && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.storeName}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Store Type:
                </label>
                <div className="col-lg-12">
                  <select
                    name="storeType"
                    className="form-select form-select-solid"
                    data-control="select"
                    data-placeholder="Select an option"
                    data-allow-clear="true"
                    onChange={(e) => handleChange(e)}
                    value={formData.storeType || ""}
                  >
                    <option value="">Select...</option>
                    <option value="physicalGoods">Physical Goods</option>
                    <option value="digitalGoods">Digital Goods</option>
                    <option value="subscription">Subscription</option>
                  </select>
                  {errors && errors.storeType && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.storeType}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Sign Up URL :
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="signupUrl"
                    type="text"
                    className="form-control"
                    placeholder="Sign Up URL"
                    onChange={(e) => handleChange(e)}
                    value={formData.signupUrl || ""}
                    maxLength={500}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  />
                  {errors && errors.signupUrl && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.signupUrl}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  TOS URL:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="tosUrl"
                    type="text"
                    className="form-control"
                    placeholder="TOS URL"
                    onChange={(e) => handleChange(e)}
                    value={formData.tosUrl || ""}
                    maxLength={500}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  />
                  {errors && errors.tosUrl && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.tosUrl}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Checkout URL:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="checkoutUrl"
                    type="text"
                    className="form-control"
                    placeholder="Checkout URL"
                    onChange={(e) => handleChange(e)}
                    value={formData.checkoutUrl || ""}
                    maxLength={500}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  />
                  {errors && errors.checkoutUrl && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.checkoutUrl}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Return Policy URL :
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="returnPolicyUrl"
                    type="text"
                    className="form-control"
                    placeholder="Return Policy URL"
                    onChange={(e) => handleChange(e)}
                    value={formData.returnPolicyUrl || ""}
                    maxLength={500}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  />
                  {errors && errors.returnPolicyUrl && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.returnPolicyUrl}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  TOS Policy Screenshot:
                </label>
                <div className="col-lg-12">
                  <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleFileUpload(acceptedFiles, "tosPolicyScreenshot");
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="container w-25rem">
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              name="tosPolicyScreenshot"
                            />
                            <p className="text-primary fw-bolder fs-6">
                              Click here to select files
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </StyledDiv>
                  {loadingAMUpload &&
                  targetFileName === "tosPolicyScreenshot" ? (
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <div className="d-flex justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                        <span
                          className="spinner-grow spinner-grow-sm mx-1"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                      </div>
                      <div className="mx-2">Uploading</div>
                    </div>
                  ) : null}
                  {formData && formData.tosPolicyScreenshot ? (
                    <div className="text-center mt-2 cursor-pointer">
                      <a
                        href={`${API_URL}/uploads/${formData.tosPolicyScreenshot}`}
                        target="_blank"
                      >
                        {formData.tosPolicyScreenshot.includes("pdf") ? (
                          <i
                            className="bi bi-file-earmark-pdf-fill"
                            style={{
                              color: "dodgerblue",
                              width: "75px",
                              height: "75px",
                              fontSize: "3rem",
                            }}
                          />
                        ) : (
                          <img
                            src={`${API_URL}/uploads/${formData.tosPolicyScreenshot}`}
                            alt="your image"
                            style={{ width: 100, height: 100 }}
                          />
                        )}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Refund Policy Screenshot:
                </label>
                <div className="col-lg-12">
                  <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleFileUpload(
                          acceptedFiles,
                          "refundPolicyScreenshot"
                        );
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="container w-25rem">
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              name="refundPolicyScreenshot"
                            />
                            <p className="text-primary fw-bolder fs-6">
                              Click here to select files
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </StyledDiv>
                  {loadingAMUpload &&
                  targetFileName === "refundPolicyScreenshot" ? (
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <div className="d-flex justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                        <span
                          className="spinner-grow spinner-grow-sm mx-1"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                      </div>
                      <div className="mx-2">Uploading</div>
                    </div>
                  ) : null}
                  {formData && formData.refundPolicyScreenshot ? (
                    <div className="text-center mt-2 cursor-pointer">
                      <a
                        href={`${API_URL}/uploads/${formData.refundPolicyScreenshot}`}
                        target="_blank"
                      >
                        {formData.refundPolicyScreenshot.includes("pdf") ? (
                          <i
                            className="bi bi-file-earmark-pdf-fill"
                            style={{
                              color: "dodgerblue",
                              width: "75px",
                              height: "75px",
                              fontSize: "3rem",
                            }}
                          />
                        ) : (
                          <img
                            src={`${API_URL}/uploads/${formData.refundPolicyScreenshot}`}
                            alt="your image"
                            style={{ width: 100, height: 100 }}
                          />
                        )}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Checkout Screenshot:
                </label>
                <div className="col-lg-12">
                  <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleFileUpload(acceptedFiles, "checkoutScreenshot");
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="container w-25rem">
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              name="checkoutScreenshot"
                            />
                            <p className="text-primary fw-bolder fs-6">
                              Click here to select files
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </StyledDiv>
                  {loadingAMUpload &&
                  targetFileName === "checkoutScreenshot" ? (
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <div className="d-flex justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                        <span
                          className="spinner-grow spinner-grow-sm mx-1"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                      </div>
                      <div className="mx-2">Uploading</div>
                    </div>
                  ) : null}
                  {formData && formData.checkoutScreenshot ? (
                    <div className="text-center mt-2 cursor-pointer">
                      <a
                        href={`${API_URL}/uploads/${formData.checkoutScreenshot}`}
                        target="_blank"
                      >
                        {formData.checkoutScreenshot.includes("pdf") ? (
                          <i
                            className="bi bi-file-earmark-pdf-fill"
                            style={{
                              color: "dodgerblue",
                              width: "75px",
                              height: "75px",
                              fontSize: "3rem",
                            }}
                          />
                        ) : (
                          <img
                            src={`${API_URL}/uploads/${formData.checkoutScreenshot}`}
                            alt="your image"
                            style={{ width: 100, height: 100 }}
                          />
                        )}
                      </a>
                    </div>
                  ) : null}
                  {errors && errors.checkoutScreenshot && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.checkoutScreenshot}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 form-label">
                  Shipping Policy Screenshot:
                </label>
                <div className="col-lg-12">
                  <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleFileUpload(
                          acceptedFiles,
                          "shippingPolicyScreenshot"
                        );
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="container w-25rem">
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              name="shippingPolicyScreenshot"
                            />
                            <p className="text-primary fw-bolder fs-6">
                              Click here to select files
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </StyledDiv>
                  {loadingAMUpload &&
                  targetFileName === "shippingPolicyScreenshot" ? (
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <div className="d-flex justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                        <span
                          className="spinner-grow spinner-grow-sm mx-1"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                      </div>
                      <div className="mx-2">Uploading</div>
                    </div>
                  ) : null}
                  {formData && formData.shippingPolicyScreenshot ? (
                    <div className="text-center mt-2 cursor-pointer">
                      <a
                        href={`${API_URL}/uploads/${formData.shippingPolicyScreenshot}`}
                        target="_blank"
                      >
                        {formData.shippingPolicyScreenshot.includes("pdf") ? (
                          <i
                            className="bi bi-file-earmark-pdf-fill"
                            style={{
                              color: "dodgerblue",
                              width: "75px",
                              height: "75px",
                              fontSize: "3rem",
                            }}
                          />
                        ) : (
                          <img
                            src={`${API_URL}/uploads/${formData.shippingPolicyScreenshot}`}
                            alt="your image"
                            style={{ width: 100, height: 100 }}
                          />
                        )}
                      </a>
                    </div>
                  ) : null}
                  {errors && errors.shippingPolicyScreenshot && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.shippingPolicyScreenshot}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row mb-2 mt-3">
              <div className="col-lg-6" />
              <div className="col-lg-6">
                <div className="col-lg-11">
                  <button
                    className="btn btn-primary m-2 fa-pull-right"
                    onClick={(event) => {
                      handleSubmit(event);
                    }}
                  >
                    {loadingEMIDCRM || loadingAMIDCRM ? (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center py-5">
          <div className="spinner-border text-primary m-5" role="status" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  mids: state && state.midCRMStore && state.midCRMStore.getmidCRM,
  loading: state && state.midCRMStore && state.midCRMStore.loading,
  dataEMIDCRM:
    state && state.editMIDCRMStore && state.editMIDCRMStore.dataEMIDCRM,
  statusEMIDCRM:
    state && state.editMIDCRMStore && state.editMIDCRMStore.statusEMIDCRM,
  messageEMIDCRM:
    state && state.editMIDCRMStore && state.editMIDCRMStore.messageEMIDCRM,
  loadingEMIDCRM:
    state && state.editMIDCRMStore && state.editMIDCRMStore.loadingEMIDCRM,
  getDetailsMID: state && state.midCRMStore && state.midCRMStore.getDetailsMID,
  statusGMIDCRM: state && state.midCRMStore && state.midCRMStore.statusGMIDCRM,
  messagesGMIDCRM:
    state && state.midCRMStore && state.midCRMStore.messagesGMIDCRM,
  loadingGMIDCRM:
    state && state.midCRMStore && state.midCRMStore.loadingGMIDCRM,
  loadingAMIDCRM:
    state && state.addmidCRMStore && state.addmidCRMStore.loadingAMIDCRM,
  statusAMIDCRM:
    state && state.addmidCRMStore && state.addmidCRMStore.statusAMIDCRM,
  messageAMIDCRM:
    state && state.addmidCRMStore && state.addmidCRMStore.messageAMIDCRM,
  dataAMIDCRM:
    state && state.addmidCRMStore && state.addmidCRMStore.dataAMIDCRM,
  loadingAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.loadingAMUpload,
  dataAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.dataAMUpload,
  statusAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.statusAMUpload,
  messageAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.messageAMUpload,
});

const mapDispatchToProps = (dispatch) => ({
  getMidCrmDispatch: (params, headers) =>
    dispatch(midCRMActions.getmidCRM(params, headers)),
  editmidCRMDispatch: (id, data, headers) =>
    dispatch(editmidCRMActions.editmidCRM(id, data, headers)),
  cleareditmidCRMDispatch: () => dispatch(editmidCRMActions.cleareditmidCRM()),
  clearmidCRMDispatch: () => dispatch(midCRMActions.clearmidCRM()),
  addmidCRMDispatch: (data, headers) =>
    dispatch(addmidCRMActions.addmidCRM(data, headers)),
  clearAddmidCRMDispatch: () => dispatch(addmidCRMActions.clearaddmidCRM()),
  clearmidCRMDetailsDispatch: () =>
    dispatch(midCRMGetDetailsActions.clearmidCRMDetails()),
  getmidCRMDetailsDispatch: (data, headers) =>
    dispatch(midCRMGetDetailsActions.getmidCRMDetails(data, headers)),
  addMerchantUploadDispatch: (data) =>
    dispatch(addMerchantUploadActions.addMerchantUpload(data)),
  clearaddMerchantUploadDispatch: () =>
    dispatch(addMerchantUploadActions.clearaddMerchantUpload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MidForm);
