import { toAbsoluteUrl } from "../theme/helpers";

export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";

export const colors = {
  oxfordBlue: "#181c32",
};

export const SHOPIFY_STORAGE = {
  PROCESSOR: "PROCESSOR",
  SERVICE_TYPE: "SERVICE TYPE",
  PLAN_TYPE: "PLAN TYPE",
  ALL_PLAN_TYPE: "ALL_PLAN_TYPE",
  SHOP_NAME: "SHOP_NAME",
  SHP_HOST_NAME: "SHP_HOST_NAME",
  SHP_SESSION: "SHP_SESSION",
  SHP_HMAC: "SHP_HMAC",
  SHP_SERVICE_URL: "SHP_SERVICE_URL",
  SHP_ACCESS_TOKEN: "SHP_ACCESS_TOKEN",
};

export const STATIC_PERMISSION = [
  "view",
  "create",
  "update",
  "delete",
  "import",
  "export",
];

export const VIEW_PERMISSION = ["view"];
export const CREATE_PERMISSION = ["create"];
export const UPDATE_PERMISSION = ["update"];
export const DELETE_PERMISSION = ["delete"];
export const IMPORT_PERMISSION = ["import"];
export const EXPORT_PERMISSION = ["export"];
export const UPDATE_DELETE_PERMISSION = ["update", "delete"];
export const API_URL = `${process.env.REACT_APP_API_URL}`;
export const CB_URL = `${process.env.REACT_APP_CHARGEBACK_URL}/uploads`;
export const CB_DOWNLOAD_URL = `${process.env.REACT_APP_CHARGEBACK_URL}`;
export const FRONTEND_URL = `${process.env.REACT_APP_URL}`;
export const ISSUER_URL = `${process.env.REACT_APP_URL}`;
export const ISSUER_ALERT_URL = `${process.env.REACT_APP_ALERT_URL}`;

export const refundVerifiOptions = [
  { value: "Partially", label: "Partially" },
  { value: "Fully", label: "Fully" },
];

export const currencyOptions = [
  { value: "AED", label: "United Arab Emirates Dirham" },
  { value: "AFN", label: "Afghan Afghani" },
  { value: "ALL", label: "Albanian Lek" },
  { value: "AMD", label: "Armenian Dram" },
  { value: "ANG", label: "Netherlands Antillean Guilder" },
  { value: "AOA", label: "Angolan Kwanza" },
  { value: "ARS", label: "Argentine Peso" },
  { value: "AUD", label: "Australian Dollar" },
  { value: "AWG", label: "Aruban Florin" },
  { value: "AZN", label: "Azerbaijani Manat" },
  { value: "BAM", label: "Bosnia-Herzegovina Convertible Mark" },
  { value: "BBD", label: "Barbadian Dollar" },
  { value: "BDT", label: "Bangladeshi Taka" },
  { value: "BGN", label: "Bulgarian Lev" },
  { value: "BHD", label: "Bahraini Dinar" },
  { value: "BIF", label: "Burundian Franc" },
  { value: "BMD", label: "Bermudan Dollar" },
  { value: "BND", label: "Brunei Dollar" },
  { value: "BOB", label: "Bolivian Boliviano" },
  { value: "BRL", label: "Brazilian Real" },
  { value: "BSD", label: "Bahamian Dollar" },
  { value: "BTC", label: "Bitcoin" },
  { value: "BTN", label: "Bhutanese Ngultrum" },
  { value: "BWP", label: "Botswanan Pula" },
  { value: "BYN", label: "Belarusian Ruble" },
  { value: "BZD", label: "Belize Dollar" },
  { value: "CAD", label: "Canadian Dollar" },
  { value: "CDF", label: "Congolese Franc" },
  { value: "CHF", label: "Swiss Franc" },
  { value: "CLF", label: "Chilean Unit of Account (UF)" },
  { value: "CLP", label: "Chilean Peso" },
  { value: "CNH", label: "Chinese Yuan (Offshore)" },
  { value: "CNY", label: "Chinese Yuan" },
  { value: "COP", label: "Colombian Peso" },
  { value: "CRC", label: "Costa Rican Colón" },
  { value: "CUC", label: "Cuban Convertible Peso" },
  { value: "CUP", label: "Cuban Peso" },
  { value: "CVE", label: "Cape Verdean Escudo" },
  { value: "CZK", label: "Czech Republic Koruna" },
  { value: "DJF", label: "Djiboutian Franc" },
  { value: "DKK", label: "Danish Krone" },
  { value: "DOP", label: "Dominican Peso" },
  { value: "DZD", label: "Algerian Dinar" },
  { value: "EGP", label: "Egyptian Pound" },
  { value: "ERN", label: "Eritrean Nakfa" },
  { value: "ETB", label: "Ethiopian Birr" },
  { value: "EUR", label: "Euro" },
  { value: "FJD", label: "Fijian Dollar" },
  { value: "FKP", label: "Falkland Islands Pound" },
  { value: "GBP", label: "British Pound Sterling" },
  { value: "GEL", label: "Georgian Lari" },
  { value: "GGP", label: "Guernsey Pound" },
  { value: "GHS", label: "Ghanaian Cedi" },
  { value: "GIP", label: "Gibraltar Pound" },
  { value: "GMD", label: "Gambian Dalasi" },
  { value: "GNF", label: "Guinean Franc" },
  { value: "GTQ", label: "Guatemalan Quetzal" },
  { value: "GYD", label: "Guyanaese Dollar" },
  { value: "HKD", label: "Hong Kong Dollar" },
  { value: "HNL", label: "Honduran Lempira" },
  { value: "HRK", label: "Croatian Kuna" },
  { value: "HTG", label: "Haitian Gourde" },
  { value: "HUF", label: "Hungarian Forint" },
  { value: "IDR", label: "Indonesian Rupiah" },
  { value: "ILS", label: "Israeli New Sheqel" },
  { value: "IMP", label: "Manx pound" },
  { value: "INR", label: "Indian Rupee" },
  { value: "IQD", label: "Iraqi Dinar" },
  { value: "IRR", label: "Iranian Rial" },
  { value: "ISK", label: "Icelandic Króna" },
  { value: "JEP", label: "Jersey Pound" },
  { value: "JMD", label: "Jamaican Dollar" },
  { value: "JOD", label: "Jordanian Dinar" },
  { value: "JPY", label: "Japanese Yen" },
  { value: "KES", label: "Kenyan Shilling" },
  { value: "KGS", label: "Kyrgystani Som" },
  { value: "KHR", label: "Cambodian Riel" },
  { value: "KMF", label: "Comorian Franc" },
  { value: "KPW", label: "North Korean Won" },
  { value: "KRW", label: "South Korean Won" },
  { value: "KWD", label: "Kuwaiti Dinar" },
  { value: "KYD", label: "Cayman Islands Dollar" },
  { value: "KZT", label: "Kazakhstani Tenge" },
  { value: "LAK", label: "Laotian Kip" },
  { value: "LBP", label: "Lebanese Pound" },
  { value: "LKR", label: "Sri Lankan Rupee" },
  { value: "LRD", label: "Liberian Dollar" },
  { value: "LSL", label: "Lesotho Loti" },
  { value: "LYD", label: "Libyan Dinar" },
  { value: "MAD", label: "Moroccan Dirham" },
  { value: "MDL", label: "Moldovan Leu" },
  { value: "MGA", label: "Malagasy Ariary" },
  { value: "MKD", label: "Macedonian Denar" },
  { value: "MMK", label: "Myanma Kyat" },
  { value: "MNT", label: "Mongolian Tugrik" },
  { value: "MOP", label: "Macanese Pataca" },
  { value: "MRU", label: "Mauritanian Ouguiya" },
  { value: "MUR", label: "Mauritian Rupee" },
  { value: "MVR", label: "Maldivian Rufiyaa" },
  { value: "MWK", label: "Malawian Kwacha" },
  { value: "MXN", label: "Mexican Peso" },
  { value: "MYR", label: "Malaysian Ringgit" },
  { value: "MZN", label: "Mozambican Metical" },
  { value: "NAD", label: "Namibian Dollar" },
  { value: "NGN", label: "Nigerian Naira" },
  { value: "NIO", label: "Nicaraguan Córdoba" },
  { value: "NOK", label: "Norwegian Krone" },
  { value: "NPR", label: "Nepalese Rupee" },
  { value: "NZD", label: "New Zealand Dollar" },
  { value: "OMR", label: "Omani Rial" },
  { value: "PAB", label: "Panamanian Balboa" },
  { value: "PEN", label: "Peruvian Nuevo Sol" },
  { value: "PGK", label: "Papua New Guinean Kina" },
  { value: "PHP", label: "Philippine Peso" },
  { value: "PKR", label: "Pakistani Rupee" },
  { value: "PLN", label: "Polish Zloty" },
  { value: "PYG", label: "Paraguayan Guarani" },
  { value: "QAR", label: "Qatari Rial" },
  { value: "RON", label: "Romanian Leu" },
  { value: "RSD", label: "Serbian Dinar" },
  { value: "RUB", label: "Russian Ruble" },
  { value: "RWF", label: "Rwandan Franc" },
  { value: "SAR", label: "Saudi Riyal" },
  { value: "SBD", label: "Solomon Islands Dollar" },
  { value: "SCR", label: "Seychellois Rupee" },
  { value: "SDG", label: "Sudanese Pound" },
  { value: "SEK", label: "Swedish Krona" },
  { value: "SGD", label: "Singapore Dollar" },
  { value: "SHP", label: "Saint Helena Pound" },
  { value: "SLL", label: "Sierra Leonean Leone" },
  { value: "SOS", label: "Somali Shilling" },
  { value: "SRD", label: "Surinamese Dollar" },
  { value: "SSP", label: "South Sudanese Pound" },
  { value: "STD", label: "São Tomé and Príncipe Dobra (pre-2018)" },
  { value: "STN", label: "São Tomé and Príncipe Dobra" },
  { value: "SVC", label: "Salvadoran Colón" },
  { value: "SYP", label: "Syrian Pound" },
  { value: "SZL", label: "Swazi Lilangeni" },
  { value: "THB", label: "Thai Baht" },
  { value: "TJS", label: "Tajikistani Somoni" },
  { value: "TMT", label: "Turkmenistani Manat" },
  { value: "TND", label: "Tunisian Dinar" },
  { value: "TOP", label: "Tongan Pa'anga" },
  { value: "TRY", label: "Turkish Lira" },
  { value: "TTD", label: "Trinidad and Tobago Dollar" },
  { value: "TWD", label: "New Taiwan Dollar" },
  { value: "TZS", label: "Tanzanian Shilling" },
  { value: "UAH", label: "Ukrainian Hryvnia" },
  { value: "UGX", label: "Ugandan Shilling" },
  { value: "USD", label: "United States Dollar" },
  { value: "UYU", label: "Uruguayan Peso" },
  { value: "UZS", label: "Uzbekistan Som" },
  { value: "VEF", label: "Venezuelan Bolívar Fuerte (Old)" },
  { value: "VES", label: "Venezuelan Bolívar Soberano" },
  { value: "VND", label: "Vietnamese Dong" },
  { value: "VUV", label: "Vanuatu Vatu" },
  { value: "WST", label: "Samoan Tala" },
  { value: "XAF", label: "CFA Franc BEAC" },
  { value: "XAG", label: "Silver Ounce" },
  { value: "XAU", label: "Gold Ounce" },
  { value: "XCD", label: "East Caribbean Dollar" },
  { value: "XDR", label: "Special Drawing Rights" },
  { value: "XOF", label: "CFA Franc BCEAO" },
  { value: "XPD", label: "Palladium Ounce" },
  { value: "XPF", label: "CFP Franc" },
  { value: "XPT", label: "Platinum Ounce" },
  { value: "YER", label: "Yemeni Rial" },
  { value: "ZAR", label: "South African Rand" },
  { value: "ZMW", label: "Zambian Kwacha" },
  { value: "ZWL", label: "Zambian Dollar" },
];

export const API_MESSAGES = {
  SOMETHING_WRONG: "Something went wrong. Please try again later!",
};

export const CRM_ERROR = {
  API_KEY_REQUIRED: "API Key is required.",
  API_KEY_INVALID: "API Key is Invalid.",
  API_SECRET_REQUIRED: "API Secret is required.",
  METHOD_REQUIRED: "Method is required.",
  API_SECRET_INVALID: "API Secret is Invalid.",
  API_END_POINT_REQUIRED: "API End Point is required.",
  API_END_POINT_INVALID: "API End Point is Invalid.",
  PROCESSOR_TYPE_REQUIRED: "Processor Type is required.",
  PAYMENT_GATE_REQUIRED: "Payment Gateway Type is required.",
  GATEWAY: "Gateway Type is required.",
  PORT_NUMBER_REQUIRED: "Port Number is required.",
  FAX_NUMBER_REQUIRED: "Fax Number is required.",
  EMAIL_REQUIRED: "Email is required.",
  API_REQUIRED: "API is required.",
  CRM_ALREADY: "CRM is Already Exist!",
  PROCESSOR_ALREADY: "Processor Type is Already Exist!",
  PAYMENT_ALREADY: "Payment Gateway Type is Already Exist!",
  DISPUTE_MODE_REQUIRED: "Submission Mode is required.",
  PORTNUMBER_REQUIRED: "PortNumber is required.",
  USER_NAME_REQUIRED: "Username is required.",
  HOST_NAME_REQUIRED: "Host name is required.",
  OAUTH_URL_REQUIRED: "OAuth Url is required.",
  PASSWORD_REQUIRED: "Password is required.",
  FAX_REQUIRED: "Fax Number is required.",
  REQUEST_API_KEY_REQUIRED: "Request ApiKey is required.",
  REQUEST_API_SECRET_REQUIRED: "Request Api secret key is required.",
  REQUEST_API_END_POINT_REQUIRED: "Request Api end point is required.",
  OAUTH_URL: "OAuth Url is required.",
  OAUTH_USERNAME: "User Name is required.",
  OAUTH_PASSWORD: "OAuth Password is required.",
  MODE: "Mode is required.",
  PAYMENT_GATEWAY: "Payment Gateway is required.",
  CREDENTIALS: "Credentials is required.",
  LIVE_URL: "Live URL is required.",
  SAND_URL: "Sandbox URL is required.",
  PACKAGE_REQUIRED: "Please Select the Package!",
};

export const USER_MANAGEMENT_ERROR = {
  USER_ROLE_REQUIRED: "User Role is required.",
  USER_TYPE_REQUIRED: "User Type is required.",
  PERMISSIONS_REQUIRED: "Please select the permissions.",
  USER_FNAME: "First Name is required.",
  USER_LNAME: "Last Name is required.",
  USER_EMAIL: "Email is required.",
  USER_PHONE: "Phone Number is required.",
  USER_PASSWORD: "Password is required.",
};

export const SITE_CONFIG_ERROR = {
  PASS_MAX_REQUIRED: "Password Max Character is required.",
  PASS_MIN_REQUIRED: "Password Min Character is required.",
  PASS_LEAST_REQUIRED: "Password Least Digits is required.",
  PASS_REPEATED_REQUIRED: "Previous Password Repeated Times is required.",
  PASS_EXPIRED_REQUIRED: "Password Expired Days is required.",
  PASS_MAX_FAIL_REQUIRED: "Password Maximum Failure Attempts is required.",
  LOGIN_DURA_REQUIRED: "Login Duration After Failure Attempts is required.",
  SESSION_TIME: "Session Timeout is required.",
};

export const dynamicError = (min, max) => {
  const PASSWORD_MIN_MAX_LENGTH = `The password should contain at least one Capital & Small letter, one Number & Special Character with a Minimum of ${
    min || 7
  } to a Maximum of ${max || 14} Characters.`;
  return PASSWORD_MIN_MAX_LENGTH;
};

export const USER_ERROR = {
  PARTNER_FIRST_NAME_REQUIRED: "Partner First Name is required.",
  PARTNER_LAST_NAME_REQUIRED: "Partner Last Name is required.",
  CLIENT_FIRST_NAME_REQUIRED: "Client First Name is required.",
  CLIENT_LAST_NAME_REQUIRED: "Client Last Name is required.",
  CLIENT_DESCRIPTION_REQUIRED: "Client Description is required.",
  PARTNER_REQUIRED: "Partner is required.",
  CLIENT_REQUIRED: "Client is required.",
  CLIENT_ALREADY: "Client is Already Exist!",
  COMPANYNAME: "Company Name is required.",
  COMPANY_ID: "Company Id is required.",
  PRODUCT_NAME: "Product Name is required.",
  ACQUIRER_NAME: "Acquirer Name is required.",
  CRM_REQUIRED: "CRM is required.",
  PROCESSOR_REQUIRED: "Payment processor is required.",
  ACQUIRER_REQUIRED: "Acquirer is required.",
  INDUSTRY_REQUIRED: "Industry is required.",
  GATEWAY_REQUIRED: "Payment Gateway is required.",
  MERCHANTNAME: "Merchant Name is required.",
  MIDNUMBER_REQUIRED: "MID Number is required.",
  DESCRIPTOR_NAME_REQUIRED: "Descriptor Name is required.",
  DESCRIPTOR_ID_REQUIRED: "Descriptor Id is required.",
  DOING_BUSINESS_AS_REQUIRED: "Doing Business As is required.",
  BIN_REQUIRED: "BIN is required.",
  CAID_REQUIRED: "CAID is required.",
  GATEWAY: "Gateway is required.",
  DBA: "DBA Name is required.",
  ISSUER: "Issuer is required.",
  MERCHANT_ID: "Please select Merchant.",
  DISPUTE_METHOD: "Dispute Method is required.",
  DISPUTE_AMOUNT: "Dispute Amount is required.",
  DISPUTE_DATE: "Dispute date is required.",
  DISPUTE_FILE_DATE: "Dispute Filed Date is required.",
  CB_CASE: "Chargeback case is required.",
  CB_CURRENCY: "Chargeback Currency is required.",
  CB_DATE: "Chargeback Date is required.",
  CB_DEADLINE: "Chargeback Deadline is required.",
  CB_AMOUNT: "Chargeback Amount is required.",
  CB_ANALYST: "Chargeback analyst is required.",
  CB_STATUS: "Chargeback status is required.",
  CB_TYPE: "Please select Chargeback Type.",
  CARD_SIX: "Card Bin is required.",
  CARD_FOUR: "Credit Card Last four digit is required.",
  CARD_TYPE: "Please select Card Type.",
  CARD_Network: "Please select Card Network.",
  CB_REASON: "Please select Chargeback Reason.",
  TD_DATE: "Transaction Date is required.",
  FIRST_TD_DATE: "First Transaction Date is required.",
  TD_AMT: "Transaction Amount is required.",
  TD_NO: "Transaction number is required.",
  FUNC_AREA: "Please select Functional Area.",
  SA_TY: "Sale Type is required.",
  CUS_IP: "Cutomer IP is required.",
  CUS_PH: "Customer Phone is required.",
  AFF_ID: "Affiliate Id is required.",
  AVS_MATCH: "Please select AVS Match.",
  CLIENT: "Please select Client.",
  BLACKLISTED: "Blacklisted is required.",
  CVV: "CVV is required.",
  CUS_NA: "Customer Name is required.",
  CUS_EMAIL: "Customer Email is required.",
  CUS_ADDRESS: "Customer Address is required.",
  AUTH_CODE: "Please select Authentication code.",
  AVS_CHECKED: "AVS Checked is required.",
  COUNTRY: "Please select Country.",
  CARD_COUNTRY: "Please select Card Country.",
  NON_US: "Non US country is required.",
  MERCHANT_DESCRIPTOR: "Merchant Descriptor is required.",
  ARN: "ARN is required.",
  TRANSACTION_CODE: "Transaction Code is required.",
  AVS_RESULT: "AVS Result Code is required.",
  TRANSACTION_ID: "Transaction ID is required.",
  TRANSACTION_NUMBER: "Transaction Number is required.",
  TRANSACTION_AMOUNT: "Transaction Amount is required.",
  CVV_CHECKED: "CVV Checked is required.",
  CVV_RESULT_CODE: "CVV Result Code is required.",
  TRANSACTION_CURRENCY: "Transaction Currency is required.",
  AVS_CODE: "AVS Code is required.",
  CVV_CODE: "CVV Code is required.",
  SECURE_CODE: "Secure Code is required.",
  RE_FUNDED: "Refunded is required.",
  ISSUE_BANK: "IssueBank is required.",
  CUSTOMER_CITY: "Please select Customer City.",
  CUSTOMER_STATE: "Please select Customer State.",
  CUSTOMER_COUNTRY: "Please select Customer Country.",
  BILLING_ADDRESS: "Billing Address is required.",
  BILLING_ADDRESS_DETAILS: "Billing Address Details is required.",
  SHIPPING_AMOUNT: "Shipping Amount is required.",
  SHIPPING_CURRENCY: "Shipping Currency is required.",
  CUSTOMER_ZIPCODE: "Customer Zip Code is required.",
  COUNTRY_REQUIRED: "Country is required.",
  STATE_REQUIRED: "State is required.",
  CITY_REQUIRED: "City is required.",
  ADDRESS_REQUIRED: "Address is required.",
  CLIENT_COMPLEXITY_REQUIRED: "Client Complexity is required.",
  SOURCE_REQUIRED: "Source is required.",
  COMPANY_DESCRIPTION_REQUIRED: "Company Description is required.",
  CURRENCY_REQUIRED: "Currency is required.",
  CUSTOMER_ID_REQUIRED: "Customer Id is required.",
  CUSTOMER_SALUTATION: "Customer Salutation is required.",
  EXISTENCE_REQUIRED: "Extension is required.",
  ZIPCODE_REQUIRED: "Zip Code is required.",
  ZIPCODE_INVALID: "Zip Code is should be in 6 digits.",
  ZIPCODE_INVALID_1: "Zip Code is should be in valid format.",
  CAID_INVALID: "CAID is should be in 6-8 digits",
  BIN_INVALID: "BIN is should be in 4-6 digits",
  EXTERNAL_ID_REQUIRED: "External Id is required.",
  EXISTING_PHONE_REQUIRED: "Phone Extension Number is required.",
  MERCHANT_NAME_REQUIRED: "Merchant Name is required.",
  ACC_USERNAME: "Account UserName is required.",
  EMAIL: "Email is required.",
  CUSTOMER_EMAIL_NAME: "Customer Email Name is required.",
  PHONE_NUMBER: "Phone Number is required.",
  TRACKING_NUMBER: "Tracking Number is required.",
  SHIPPING_PHONE_NUMBER: "Shipping Carrier Phone is required.",
  PHONE_NUMBER_INVALID: "Phone Number is invalid.",
  ACC_ACTIVEDATE: "Account Active Date is required.",
  PURCHASE_DATE: "First Purchase Date is required.",
  CUS_PHONE_TYPE: "Customer Phone Type is required.",
  LAST_LOGIN: "Last Login is required.",
  IP_ADDRESS: "IP Address is required.",
  LENGTH: "Length of Relationship is required.",
  IP_LOCATION: "IP Location is required.",
  AUTH_IDENTIFICATION: "Auth Used for Identification is required.",
  DEVICE_ID: "Device Id is required.",
  CASE_ID: "Case Id is required.",
  DEVICE_NAME: "Device Name is required.",
  LOGIN_HISTORY: "Login History is required.",
  TRANSACTION_HISTORY: "Transaction History is required.",
  PRODUCT_TYPE: "Product Type is required.",
  SHIPPING_URL: "Shipping Carrier URL is required.",
  PAYMENT_ID: "Payment Id is required.",
  PAYMENT_PROCESSOR: "Please select Payment Processor.",
  PAYMENT_METHOD: "Payment Method is required.",
  REASON_CB: "Reason For Legitimate Chargeback is required.",
  PRO_MODEL: "Product Model is required.",
  PRO_SKEW: "Product Skew is required.",
  PRO_DES: "Product Description is required.",
  PRO_URL: "Product URL is required.",
  PRO_IMAGE_URL: "Product Image URL is required.",
  UNIT_CURRENCY: "Unit Price Currency is required.",
  UNIT_AMOUNT: "Unit Price Amount is required.",
  INDUSTRY_DETAILS: "Industry Details is required.",
  DELIVERY_DATE: "Delivery Date is required.",
  TRACKING_NO: "Tracking Number is required.",
  SIGN_CONFIRM: "Signature Confirmation is required.",
  SIGN_BY: "Signed By is required.",
  SHIPPING_COUNTRY: "Shipping Country is required.",
  SHIPPING_STATE: "Shipping State is required.",
  SHIPPING_CITY: "Shipping City is required.",
  SHIPPING_ADDRESS: "Shipping Address is required.",
  SHIPPING_ADDRESS_DETAILS: "Shipping Address Details is required.",
  SHIPPING_ZIPCODE: "Shipping Zip Code is required.",
  WEBSITE: "Website is required.",
  DESCRIPTION: "Description is required.",
  BENIFITS: "Benifits is required.",
  SPECIFICINFO: "Specific Info is required.",
  URL_REQUIRED: "URL is required.",
  URL_VALID: "URL is invalid.",
  CONTACT_URL: "Contact URL is required.",
  LIVE_CHAT: "Support Live Chat is required.",
  TERMS_URL: "Terms and Condition URL is required.",
  REFUND_URL: "Refund Policy URL is required.",
  FILE: "File is required. ",
  UPLOAD_FILE: "Please select atleast one document to continue. ",
  NAME_REQUIRED: "Name is required.",
  STATUS_REQUIRED: "Status is required.",
  ROLE_REQUIRED: "Role is required.",
  EMAIL_REQUIRED: "Email address is required.",
  BCC_REQUIRED: "BCC is required.",
  CC_REQUIRED: "CC is required.",
  EMAIL_INVALID: "Email address is invalid.",
  PASSWORD_REQUIRED: "Password is required.",
  CAPTCHA_REQUIRED: "Captcha is required.",
  OLD_PASSWORD_REQUIRED: "Old Password is required.",
  NEW_PASSWORD_REQUIRED: "New Password is required.",
  PASSWORD_MIN_MAX_LENGTH:
    "Password should contain atleat one Capital, Number & Special Character(ex: Pass@123).",
  PASSWORD_INVALID: "Please enter valid password.",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password is required.",
  PASSWORD_SAME: "Password and Confirm password should be same.",
  ALERT_ID: "Alert Id is required.",
  ALERT_TYPE: "Alert Type is required.",
  OUT_COME: "Outcome is required.",
  COMMENTS: "Comments is required.",
  ORDER_ID: "Order Id is required.",
  ALERT_DATE: "Alert Date is required.",
  TRNS_TYPE: "Transaction Type is required.",
  UPI_ID: "UPI Transaction ID is required.",
  UPI: "UPI Id is required.",
  CUS_FNAME: "Customer First Name is required.",
  CARD_BIN: "Card Bin is required. ",
  EXP_MONTH: "Expiration Month is required.",
  EXP_YEAR: "Expiration Year is required.",
  CUS_LNAME: "Customer Last Name is required.",
  DES: "Descriptor is required.",
  DES_CONTACT: "Descriptor Contact is required.",
  DES_CONTACT_INVALID: "Descriptor Contact is invalid.",
  NOT_TYPE: "Notification Type is requirted.",
  TO_ADDRESS: "To Address is requirted.",
  NOTES: "Notes is requirted.",
  EX_NOTES: "Exception Notes is requirted.",
  PHYSICAL_NOTES: "Physical Fullfillment Notes is requirted.",
  SERVICE_PROOF: "Digital Services Delivery Notes is requirted.",
  DELIVERY_PROOF: "Proof of Delivery is requirted.",
  USAGE_PROOF: "Proof of Usage is requirted.",
  LOCATION_PROOF: "Location of Usage is requirted.",
  BANK: "Bank Selection is required.",
  NUMERIC_SIX_DIGITS: "Card Bin should be in 6 or 8 digits.",
  NUMERIC_FOUR_DIGITS: "Card Last Four should be in 4 digits.",
  PROVIDER: "Provider is required. ",
  REFUND_ISSUED: "Please select Refund Issued.",
  ROOT_CAUSE: "Please select Root Cause.",
  SUB_AREA: "Please select Sub Functional Area.",
  RES_DATE: "Resolution Date is required.",
  DURATION: "Duration is required.",
  MERCHANT: "Merchant is required.",
  REFUND_STATUS: "Refund Status is required.",
  REFUND_AMOUNT: "Refund Amount is required.",
  REFUND_CURRENCY: "Refund Currency is required.",
  REFUND_DATE: "Refund Date is required.",
  REASON_CODE: "Reason Code is required.",
  SERVICE_TYPE: "Please select Service Type.",
  CONTENT: "Content is required.",
  BCC: "BCC is required.",
  CC: "CC is required.",
  TYPE: "Document type is required.",
  AMEX_CODE: "Amex Code is required.",
  PAYPAL_CODE: "Paypal Code is required.",
  DISCOVER_CODE: "Discover Code is required.",
  MASTER_CODE: "Master Code is required.",
  VISA_CODE_OLD: "Visa Code Old is required.",
  VISA_CODE_NEW: "Visa Code New is required.",
  CHARGEBACK_REASON: "Chargeback Reason is required.",
  REASON_CODE_GROUPING: "Reason Code Grouping is required.",
  RROT_CAUSE_ID: "Root Cause Id is required.",
  OPERATIONAL_TYPE_ID: "Operational Type Id is required.",
  FUNCTIONAL_TYPE_ID: "Functional Type Id is required.",
  SUB_FUNCTIONAL_TYPE_ID: "Sub Functional Type Id is required.",
  CORRECTIONS: "Corrections is required.",
  STATUS: "Status is required.",
  Three_D: "3D Secure Code is required.",
  Three_D_SECURE: "3D Secure Challenge is required.",
  Three_D_TRANSACTION: "3D Transaction Id is required.",
  Three_D_AUTH: "3D Auth Value is required.",
  REFUND_PROCESSED: "Refund Processed is required.",
  DATE_REFUND: "Date of Refund is required.",
  DATE_DELIVERY: "Date of Delivery is required.",
  DATE_SHIPMENT: "Date of Shipment is required.",
  DOB: "Date of Birth is required.",
  REFUND_POLICY: "Refund Policy is required.",
  QUANTITY: "Quantity is required.",
  STORE_NAME: "Store Name is required.",
  STORE_TYPE: "Please select Store Type.",
  SIGNUP_URL: "Signup URL is required.",
  TOS_URL: "Terms of Service URL is required.",
  CHECKOUT_URL: "Checkout URL is required.",
  TOS_IMAGE: "Terms of Service Screenshot is required.",
  REFUND_IMAGE: "Refund Policy Screenshot is required.",
  CHECKOUT_IMAGE: "Checkout Screenshot is required.",
  SHIPPING_IMAGE: "Shipping Policy is required.",
  COMPONENT: "Component is required.",
  PARENT_MENU: "Parent Menu is required.",
  PERMISSION: "Please select any one the options.",
  IMAP_ERR: "Please select the Imap.",
  USER_NAME: "User Name is required.",
  SMTP_HOST: "SMTP Host Address is required.",
  REMAINDER_DAY: "Reminder Interval is required.",
  EXPIRY_DAY: "Token Expiry Day is required.",
  REMAINDER_END_DAY: "Due date is required.",
  SUBJECT: "Subject is required.",
  EMAIL_TEMPLATE: "Template is required.",
  MAIL_CONTENT: "Mail Content is required.",
  OTHERS_NOTIFICATION_TYPE_REQUIRED: "Other Notification Type is required.",
  APP_NAME: "App Name is required.",
  TITLE: "Title is required.",
  FEATURES: "Features is required.",
  PRICE: "Price is required.",
  DISCOUNT: "Discount is required.",
  LIMITATION: "Limitation is required.",
  IMAP_HOST_REQUIRED: "Imap Host address is required.",
  IMAP_PORT_REQUIRED: "Imap Port is required.",
  IMAP_FROM_EMAIL_REQUIRED: "Imap From email is required.",
  RECEIVE_MAIL_REQUIRED: "Please enable the Receive Mail.",
  IMAP_REQUIRED: "Please enable the Imap.",
  PACK_TITLE_REQUIRED: "Please select the title.",
  EMAIL_ID: "Email Id`s are required",
  DED_MANAGER: "Dedicated Manager is required.",
  MANAGED_SERVIES: "Managed Services is required.",
  PAY_ID: "Payment Gateway Product Id is required.",
  PAY_LINK: "Payment Gateway Product Links is required.",
  NO_OF_CB: "Number Of Chargebacks Per Month is required.",
  OVER_CHARGE: "Over Charge Per Month is required.",
  ADD_CONN: "Additional Connection is required.",
  PRO_CHECK: "Processor Check Ins is required.",
  CB_ANALYTICS: "Chargeback Analytics is required.",
  P_G_RECOMMEND: "Personalized Growth Recommendation is required.",
  P_G_PLANS: "Payment Gateway Plans is required.",
  SLUG_REQUIRED: "Please select Dashboard.",
  NOT_AUTHORIZED_USER: "This userID is not authorized to access this resource.",
  SESSION_ALREADY_EXISTS: "Session Already Exists",
  SESSION_ALREADY_EXISTS_MESSAGE:
    "A session already exists with a different user!",
  NOT_AUTHORIZED_TO_REQUEST_PAGE:
    "You are not authorized to access this requested Page!",
  NOT_AUTHORIZED: "Not Authorized",
  SOURCE_NOT_FOUND: "Source not found!",
  SOURCE_NOT_FOUND_MESSAGE:
    "The Source you were trying to reach could not be found on the server.",
};

export const CHARGEBACK_STATUS_LABEL = {
  LOST_REFUNDED: "badge-orange",
  LOST: "badge-red",
  DISPUTED: "badge-dark text-white",
};

export const CHARGEBACK_STATUS = {
  NEW: "badge-primary",
  PENDING: "badge-warning",
  WAITING_FOR_EVIDENCE: "badge-danger",
  REJECTION_DUE_TO_REFUND: "badge-danger",
  LOST_REFUNDED: "badge-orange",
  ONGOING: "badge-success",
  WON: "badge-success",
  LOST: "badge-red",
  NOT_FAUGHT: "badge-secondary",
  DISPUTED: "badge-success",
  DUPLICATE: "badge-dark text-white",
  OPENED: "badge-blue",
  undefined: "text-dark",
};

export const STATUS_BADGES = {
  ACTIVE: "badge-success",
  INACTIVE: "badge-warning text-black",
  PENDING: "badge-info",
  DELETED: "badge-danger",
  undefined: "text-dark",
};

export const CONFIRMATION_TEXTS = {
  COUNTINUE_ANYWAY: "Continue Anyway",
};

export const STATUS_BADGES_LIGHT = {
  ACTIVE: "badge-light-success",
  INACTIVE: "badge-light-warning text-black",
  PENDING: "badge-light-info",
  DELETED: "badge-light-danger",
  CANCELLED: "badge-danger",
  undefined: "text-dark",
};

export const PACKAGE_TITLE = {
  CHARGEBACK: "Chargeback",
  PRVENTION_ALERT: "Prevention Alerts",
  PRVENTION_INQUERIES: "Prevention Inquiries",
};

export const CHARGEBACK_ID = {
  BATCH_ID: "CHARGEBACK_BATCH_ID",
};

export const AUTH_BASED_FILTER = {
  CLIENT: "SAVE_CLIENT_FILTER_DATA",
  MERCHANT: "SAVE_MERCHANT_FILTER_DATA",
  MID: "SAVE_MID_FILTER_DATA",
  PARTNER: "SAVE_PARTNER_FILTER_DATA",
};

export const GET_AUTH_BASED_FILTER = {
  CLIENT: "GET_SAVED_CLIENT_FILTER_DATA",
  MERCHANT: "GET_SAVED_MERCHANT_FILTER_DATA",
  MID: "GET_SAVED_MID_FILTER_DATA",
  PARTNER: "GET_SAVED_PARTNER_FILTER_DATA",
};

export const DATE = {
  TIME: "Time",
  TIME_INTERVAL: 15,
  TWELVE_HRS_FORMAT: "h:mm A",
  DATE_TIME_FORMAT: " YYYY/MM/DD, hh:mm A",
  DATE_ONLY: "DD/MM/YYYY",
  DATE_FOR_PICKER: "yyyy/MM/dd",
  DATE_MONTH_ONLY: "dd/MM",
  DATE_MONTH_ONLY__: "DD/MM",
  MONTH_ONLY: "MM",
  YEAR_ONLY: "yyyy",
  EXAMPLE_DATE: "2017-03-13",
  DATE_FORMAT: "YYYY-MM-DD",
  DATE_FORMAT_MINS: "MMM-DD-YYYY, hh:mm",
  DATE_FOR_PICKER_MONTH: "MM",
};

export const REGEX = {
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  DOB: /^\d{2}[-/.]\d{2}[-/.]\d{4}$/,
  PHONE: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/,
  PHONE_NUMBER_1: /^[0-9]+(-[0-9]+)+$/,
  PHONE_NUMBER:
    /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/,
  PHONE_NUMBER_2: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  NUMERIC: /^[0-9]+$/,
  NUMERIC_CHARS: /^[0-9-_]+$/,
  PASSWORD: /^[a-zA-Z0-9@#^\-_$]*$/,
  PASSWORD_MIN_MAX_LENGTH:
    /(?=^.{7,14}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  PASSWORD_SMALL_CAPITAL_NUMBER_SPECIAL_CHARS:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,14}$/,
  TEXT: /^[a-zA-Z_ ]*$/,
  ALPHA_UPPER_CASE: /^[A-Z]*$/,
  ALPHA_NUMERIC: /^[a-zA-Z0-9 /]*$/,
  ALPHA_NUMERIC_CHARS: /^[a-zA-Z0-9@#^\-_$/]*$/,
  ALPHA_NUMERIC_CHARS_SPACE: /^[a-zA-Z0-9@^\-_.,àâçéèêëîïôûùüÿñæœ +g]*$/,
  ALPHA_NUMERIC_SPECIAL_CHARS:
    /^[a-zA-Z0-9@^\-_:/'"{}|.,àâçéèêëîïôûùüÿñæœ +g]*$/,
  ALPHA_CHARS_SPACE: /^[a-zA-Z^-_$.,àâçéèêëîïôûùüÿñæœ +g]*$/,
  ZIP_CODE: /^([0-9]){6}$/,
  ZIP_CODE_1: /^(?=.*\d.*)[A-Za-z0-9]{3,10}$/,
  CAID_NUMBER: /^([0-9]){6,8}$/,
  BIN_NUMBER: /^([0-9]){4,6}$/,
  NUMERIC_SIX_DIGITS: /^([0-9]{6}|[0-9]{8})$/,
  NUMERIC_FOUR_DIGITS: /^([0-9]){4}$/,
  SPACE: / +/g,
  URL: /^(ftp|http|https):\/\/[^ "]+\.[a-zA-Z]{2,4}$/,
  URL_ONE: /^(ftp|http|https):\/\/[^ "]+$/,
};

export const RESPONSE_STATUS = {
  SUCCESS: "ok",
  ERROR: "error",
};

export const SESSION = {
  TOKEN: "token",
  EXPIRED: "session_expired",
  EXPIRED_MESSAGE: "Token expired/invalid",
  RESET_TOKEN: "resetToken",
  AUTH_FAILED: "Auth failed",
  AUTH_RESTRICTED: "Auth_Restricted",
  NOT_FOUND: "session_not_found",
  LAST_INTERACTION_TIME: "lastInteractionTime",
  IDLE_TIME: 15,
};

export const REASON = {
  TO_LOGOUT: "REASON_TO_LOGOUT",
  SESSION_NOT_EXIST: "SESSION_NOT_EXIST",
};

export const CLIENT_SESSION = {
  CLIENT_TOKEN: "client_token",
  SELECTED_SUBSCRIPTION_PACKAGE: "SELECTED_SUBSCRIPTION_PACKAGE",
  SELECTED_SUBSCRIPTION_PACKAGE_PLAN: "SELECTED_SUBSCRIPTION_PACKAGE_PLAN",
  SELECTED_SUBSCRIPTION_TENURE: "SELECTED_SUBSCRIPTION_TENURE",
};

export const SUBSCRIPTION_PACKAGE_PLAN = {
  FREEMIUM: "Freemium",
  PREMIUM: "Premium",
  BASIC: "Basic",
};

export const ORDER_INSIGHT_LAYOUT = {
  EXPORT: "exportPIselectedCols",
  SEARCH: "searchPIselectedCols",
  TABLE_LAYOUT: "tableLayoutPIselectedCols",
};

export const CHARGEBACK_LAYOUT = {
  EXPORT: "exportCBselectedCols",
  SEARCH: "searchCBselectedCols",
  TABLE_LAYOUT: "tableLayoutCBselectedCols",
};

export const HEADER_LAYOUT = {
  SAVE_SELECT_PARTNER: "SAVE_SELECT_PARTNER",
  SAVE_SELECT_CLIENT: "SAVE_SELECT_CLIENT",
  SAVE_SELECT_MERCHANT: "SAVE_SELECT_MERCHANT",
  SAVE_SELECT_MID: "SAVE_SELECT_MID",
};

export const SAVE_CURRENT = {
  CLIENT_ID: "SAVE_CURRENT_CLIENT_ID",
  MID_ID: "SAVE_CURRENT_MID_ID",
  ADD_MID_ID: "SAVE_CURRENT_ADD_MID_ID",
  PARTNER_ID: "SAVE_CURRENT_PARTNER_ID",
};

export const INQUIRY_ALERT_LAYOUT = {
  EXPORT: "export",
  SEARCH: "search",
  TABLE_LAYOUT: "tableLayout",
};

export const LAYOUT_DETAILS = {
  EXPORT_IBA: "exportIBAselectedCols",
  SEARCH_IBA: "searchIBAselectedCols",
  TABLE_LAYOUT_IBA: "tableLayoutIBAselectedCols",
};

export const PREVENTION_LAYOUT = {
  EXPORT: "exportPAselectedCols",
  SEARCH: "searchPAselectedCols",
  TABLE_LAYOUT: "tableLayoutPAselectedCols",
};

export const SLICE_CHARACTERS = {
  UP_TO_70: 70,
  UP_TO_35: 35,
  UP_TO_50: 50,
  UP_TO_40: 40,
  UP_TO_25: 25,
  UP_TO_15: 15,
  UP_TO_9: 9,
};

export const ENV = {
  SDK_URL: process.env.REACT_APP_SDK_URL,
};

export const STATUS_RESPONSE = {
  SUCCESS_MSG: "ok",
  ERROR_MSG: "error",
};

export const MESSAGE_RESPONSE = {
  ERROR_RECORDS: "No records found",
};

export const ALERT_TYPE = {
  SELECT: "",
  INQUIERY: "Inquiry",
  NOTIFICATION: "Notification",
};

export const DROPZONE_MESSAGES = {
  JSON_INVALID: "Uploaded file is not a valid json file.",
  IMAGE_INVALID:
    "Uploaded file is not a valid image. Only JPG, PNG and JPEG files are allowed.",
  VIDEO_INVALID: "Uploaded file is not a valid video format.",
  AUDIO_INVALID: "Uploaded file is not a valid audio format.",
  DOCUMENT_INVALID: "Uploaded file is not a valid document format.",
  PDF_INVALID:
    "Uploaded Document is not a valid document.Only pdf files are allowed",
  CSV_INVALID: "Uploaded file is not a valid CSV file.",
  FILE_INVALID: "Uploaded file is not a valid CSV or Excel file.",
  DOUBLE_EXTENSION: "Double extension files are not allowed",
};

export const FILE_TYPE = {
  COVER_LETTER: "coverLetter",
  TRANSACTION_COPY: "transactionCopy",
  ORDER_CONFIRMATION: "orderConfirmation",
  DELIVERY_CONFIRMATION: "deliveryConfirmation",
  REFRENCE_DOCUMENT: "referenceDocument",
  WEBSITE_HOME_PAGE: "websiteHomePage",
  CHECKOUT_PAGE: "checkoutPage",
  TERMS_AND_CONDITION: "termsAndCondition",
};

export const FILE_FORMAT_TYPE_DOCUMENT = [
  "text/csv",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "text/plain",
  "image/gif",
  "image/vnd.adobe.photoshop",
];

export const FILE_FORMAT_CB_DOCUMENT = ["application/pdf"];

export const FILE_CB_DOCUMENT = [
  "application/pdf",
  "text/csv",
  "application/vnd.ms-excel",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const FILE_FORMAT_CB_EVIDENCE = ["text/csv", "application/vnd.ms-excel"];

export const FILE_FORMAT_IMAGES = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/svg+xml",
  "image/JPEG",
  "image/PNG",
  "image/JPG",
];

export const FILE_FORMAT_MID = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/svg+xml",
  "image/JPEG",
  "image/PNG",
  "image/JPG",
  "application/pdf",
];

export const NOTIFICATION_TYPE = {
  SELECT: "",
  BLANK_SPACE: "Blank Space",
  CANCEL_REQURRING: "Cancel Recurring",
  CHARGEBACK: "Chargeback",
  EXCEPTION_UNKOWN: "Exception or Unknown",
  FRAUD_ALERT: "Fraud Alert",
};

export const SET_STORAGE = {
  PARTNER_DETAILS: "PARTNER_DETAILS",
  PARTNER_CLIENT_DETAILS: "PARTNER_CLIENT_DETAILS",
  PARTNER_USER_DETAILS: "PARTNER_USER_DETAILS",
  USER_MENU_DETAILS: "USER_MENU_DETAILS",
  ATTRIBUTE_DEATILS: "ATTRIBUTE_DEATILS",
  CLIENT_ATTRIBUTE_DEATILS: "CLIENT_ATTRIBUTE_DEATILS",
  IS_FIRST_LOGIN: "IS_FIRST_LOGIN",
  SITE_CONFIG_DATA: "SITE_CONFIG_DATA",
  LAST_LOCATION: "LAST_LOCATION",
  SHOPIFY_PLATFORM: "SHOPIFY_PLATFORM",
  SHOPIFY_DOMAIN: "SHOPIFY_DOMAIN",
  SET_USER_ROLE: "SET_USER_ROLE",
  SET_CURRENT_ROUTE: "SET_CURRENT_ROUTE",
};

export const LOGGED_USER_ROLE = {
  CLIENT: "Client User",
};

export const API = {
  API_KEY: "API_KEY",
};
export const CRM_FORM = {
  COMPANY_DETAILS: "COMPANY_DETAILS",
  MERCHANT_DETAILS: "MERCHANT_DETAILS",
  USER_DETAILS: "USER_DETAILS",
  CRM_DETAILS: "CRM_DETAILS",
  CRM_DETAILS_TABLE: "CRM_DETAILS_TABLE",
  PROCESSOR_DETAILS: "PROCESSOR_DETAILS",
  PROCESSOR_DETAILS_TABLE: "PROCESSOR_DETAILS_TABLE",
  GATEWAY_DETAILS: "GATEWAY_DETAILS",
  GATEWAY_DETAILS_TABLE: "GATEWAY_DETAILS_TABLE",
  PACKAGE_DETAILS: "PACKAGE_DETAILS",
  ACTIVE_STEP: "ACTIVE_STEP",
  EDIT_CRM: "EDIT_CRM",
  EDIT_CRM_TABLE: "EDIT_CRM_TABLE",
  EDIT_PROCESSOR: "EDIT_PROCESSOR",
  EDIT_PROCESSOR_TABLE: "EDIT_PROCESSOR_TABLE",
  EDIT_GATEWAY: "EDIT_GATEWAY",
  EDIT_GATEWAY_TABLE: "EDIT_GATEWAY_TABLE",
  PACKAGE_TENURE: "PACKAGE_TENURE",
  PACKAGE_SELECTED_ID: "PACKAGE_SELECTED_ID",
  PACKAGE_CURRENT_LIST_VALUE: "PACKAGE_CURRENT_LIST_VALUE",
  PACKAGE_PACK_APP_LIST: "PACKAGE_PACK_APP_LIST",
  PACKAGE_PACK_ID: "PACKAGE_PACK_ID",
};

export const SWEET_ALERT_MSG = {
  DELETE: "Deleted Successfully",
  CREATE: "Created Successfully",
  UPDATE: "Updated Successfully",
  CONFIRMATION_TEXT: "Are you sure?",
  DELETE_USER: "Do you want to delete this User?",
  DELETE_USER_ROLE: "Do you want to delete this User Role?",
  DELETE_USER_TYPE: "Do you want to delete this User Type?",
  DELETE_USER_COMPONENT: "Do you want to delete this Component?",
  DELETE_USER_PRIVILEGES: "Do you want to delete this Privileges?",
  DELETE_MERCHANT: "Do you want to delete this Merchant?",
  DELETE_CHARGEBACK: "Do you want to delete this Chargeback?",
  DELETE_CRM: "Do you want to delete this CRM?",
  DELETE_CLIENT: "Do you want to delete this Client?",
  DELETE_PROCESSOR: "Do you want to delete this Processor?",
  DELETE_ACQUIRER: "Do you want to delete this Acquirer?",
  DELETE_PAYMENT: "Do you want to delete this Payment?",
  DELETE_PAYMENT_GATEWAY: "Do you want to delete this Payment Gateway?",
  DELETE_INDUSTRY: "Do you want to delete this Industry?",
  DELETE_MID: "Do you want to delete this MID?",
  DELETE_COVERLETTER: "Do you want to delete this Coverletter?",
  DELETE_DOCUMENT: "Do you want to delete this Document?",
  DELETE_REASON_CODE: "Do you want to delete this Reason Code?",
  DELETE_PARTNER: "Do you want to delete this Partner?",
  DELETE_SUBS: "Do you want to Unsubscribe this Package?",
  DELETE_PACK_SUBS: "Do you want to delete this Package?",
  DELETE_BATCH: "Do you want to delete this Batch?",
  DELETE_TEMPLATE: "Do you want to delete this Template?",
  AVOID_CRM: "Do you want to continue without CRM?",
  AVOID_PROCESSOR: "Do you want to continue without Processor?",
  RESEND_NOTIFY: "Do you want to Resend the Notification to Acquirer?",
  SEND_NOTIFY_ACQUIRER: "Do you want to Notify the Acquirer?",
  SEND_NOTIFY: "Do you want to Send the Notification to Merchant?",
  SUBMIT_EVI: "Do you want to Submit the Evidence?",
  APPROVE_EVI: "Do you want to Accept this Chargeback?",
  APPROVE_ALL_EVI: "Do you want to Accept all this Chargeback?",
};

export const APPROVE_MESSAGE = {
  SEND_MESSAGE:
    "Total Approved Cases mismatch to the Chargebacks count. Do you want to send the notification to Acquirer",
  RESEND_MESSAGE:
    "Already Notified to Acquirer, Do you want to resend the Notification?",
  RESEND_SOME_MERCHANT_MSG:
    "Already Notified to Acquirer or some merchant in this Batch id, Do you want to resend the Notification?",
};
export const HEADER = {
  TOKEN: "Authorization",
  API_KEY: "api-key",
  CONTENT_TYPE: "application/json",
  MULTIPART_CONTENT_TYPE:
    "multipart/form-data,boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL",
  TIMEOUT: 30000,
};

// export const API_URL = 'https://chargebackzero-userservice.herokuapp.com'
// export const CB_URL = 'https://chargebackzero-chargeback.herokuapp.com/uploads'

// export const CB_URL = 'https://chargebackzero-chargeback.herokuapp.com/uploads'

export const iconOptions = [
  {
    value: "/media/icons/duotune/technology/teh002.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/technology/teh002.svg")}
        alt="Technology"
      />
    ),
  },
  {
    value: "/media/icons/duotune/communication/com005.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/communication/com005.svg")}
        alt="Communication"
      />
    ),
  },
  {
    value: "/media/icons/duotune/communication/com013.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/communication/com013.svg")}
        alt="Communication"
      />
    ),
  },
  {
    value: "/media/icons/duotune/communication/com010.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/communication/com010.svg")}
        alt="Communication"
      />
    ),
  },
  {
    value: "/media/icons/duotune/communication/com004.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/communication/com004.svg")}
        alt="Communication"
      />
    ),
  },
  {
    value: "/media/icons/duotune/communication/com006.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/communication/com006.svg")}
        alt="Communication"
      />
    ),
  },
  {
    value: "/media/icons/duotune/communication/com002.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/communication/com002.svg")}
        alt="Communication"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen008.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen008.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen001.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen001.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen028.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen028.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen007.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen007.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen032.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen032.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen035.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen035.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen049.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen049.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen019.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen019.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen022.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen022.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen014.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen014.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen017.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen017.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen054.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen054.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen011.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen011.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/general/gen026.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/general/gen026.svg")}
        alt="General"
      />
    ),
  },
  {
    value: "/media/icons/duotune/social/soc001.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/social/soc001.svg")}
        alt="Social"
      />
    ),
  },
  {
    value: "/media/icons/duotune/social/soc003.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/social/soc003.svg")}
        alt="Social"
      />
    ),
  },
  {
    value: "/media/icons/duotune/social/soc006.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/social/soc006.svg")}
        alt="Social"
      />
    ),
  },
  {
    value: "/media/icons/duotune/social/soc002.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/social/soc002.svg")}
        alt="Social"
      />
    ),
  },
  {
    value: "/media/icons/duotune/social/soc004.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/social/soc004.svg")}
        alt="Social"
      />
    ),
  },
  {
    value: "/media/icons/duotune/social/soc008.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/social/soc008.svg")}
        alt="Social"
      />
    ),
  },
  {
    value: "/media/icons/duotune/social/soc005.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/social/soc005.svg")}
        alt="Social"
      />
    ),
  },
  {
    value: "/media/icons/duotune/social/soc007.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/social/soc007.svg")}
        alt="Social"
      />
    ),
  },
  {
    value: "/media/icons/duotune/social/soc010.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/social/soc010.svg")}
        alt="Social"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra003.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra003.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra006.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra006.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra007.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra007.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra011.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra011.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra009.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra009.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra004.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra004.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra001.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra001.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra010.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra010.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra002.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra002.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra005.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra005.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra008.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra008.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/graphs/gra012.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/graphs/gra012.svg")}
        alt="Graphs"
      />
    ),
  },
  {
    value: "/media/icons/duotune/finance/fin006.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/finance/fin006.svg")}
        alt="Finance"
      />
    ),
  },
  {
    value: "/media/icons/duotune/finance/fin008.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/finance/fin008.svg")}
        alt="Finance"
      />
    ),
  },
  {
    value: "/media/icons/duotune/finance/fin003.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/finance/fin003.svg")}
        alt="Finance"
      />
    ),
  },
  {
    value: "/media/icons/duotune/finance/fin002.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/finance/fin002.svg")}
        alt="Finance"
      />
    ),
  },
  {
    value: "/media/icons/duotune/finance/fin001.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/finance/fin001.svg")}
        alt="Finance"
      />
    ),
  },
  {
    value: "/media/icons/duotune/finance/fin004.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/finance/fin004.svg")}
        alt="Finance"
      />
    ),
  },
  {
    value: "/media/icons/duotune/finance/fin010.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/finance/fin010.svg")}
        alt="Finance"
      />
    ),
  },
  {
    value: "/media/icons/duotune/finance/fin009.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/finance/fin009.svg")}
        alt="Finance"
      />
    ),
  },
  {
    value: "/media/icons/duotune/coding/cod002.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/coding/cod002.svg")}
        alt="Coding"
      />
    ),
  },
  {
    value: "/media/icons/duotune/coding/cod008.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/coding/cod008.svg")}
        alt="Coding"
      />
    ),
  },
  {
    value: "/media/icons/duotune/coding/cod004.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/coding/cod004.svg")}
        alt="Coding"
      />
    ),
  },
  {
    value: "/media/icons/duotune/coding/cod001.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/coding/cod001.svg")}
        alt="Coding"
      />
    ),
  },
  {
    value: "/media/icons/duotune/coding/cod009.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/coding/cod009.svg")}
        alt="Coding"
      />
    ),
  },
  {
    value: "/media/icons/duotune/coding/cod010.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/coding/cod010.svg")}
        alt="Coding"
      />
    ),
  },
  {
    value: "/media/icons/duotune/coding/cod007.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/coding/cod007.svg")}
        alt="Coding"
      />
    ),
  },
  {
    value: "/media/icons/duotune/coding/cod005.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/coding/cod005.svg")}
        alt="Coding"
      />
    ),
  },
  {
    value: "/media/icons/duotune/art/art002.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/art/art002.svg")}
        alt="Art"
      />
    ),
  },
  {
    value: "/media/icons/duotune/maps/map010.svg",
    label: (
      <img
        src={toAbsoluteUrl("/media/icons/duotune/maps/map010.svg")}
        alt="Map"
      />
    ),
  },
];
