import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addCoverletterActions,
  addCoverletterTypes,
  coverLetterActions,
  coverLetterActionsTypes,
  coverLetterDetailsActions,
  coverLetterGetDetailsTypes,
  editCoverletterTypes,
  editCoverlettersActions,
  coverLetterDeleteTypes,
  deleteCoverletterActions,
  reasonGroupingActionsTypes,
  reasonGroupingActions,
  serviceTypeActionsTypes,
  serviceTypeActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addCoverletter (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.coverletter, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addCoverletterActions.saveaddCoverletterResponse(json.data))
    } else {
      yield put(addCoverletterActions.clearaddCoverletter([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addCoverletterTypes.RESPONSE, data })
  }
}

function * fetchCoverletter (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.coverletter, { params, headers:headers })
    if (json.status === 200) {
      yield put(coverLetterActions.saveCoverletterResponse(json.data))
    } else {
      yield put(coverLetterActions.saveCoverletterResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: coverLetterActionsTypes.SAVE_COVER_LETTER_RESPONSE, data })
  }
}

function * getCoverletterDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.coverletter}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(coverLetterDetailsActions.saveCoverletterDetailsResponse(json.data))
    } else {
      yield put(coverLetterDetailsActions.saveCoverletterDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: coverLetterGetDetailsTypes.COVER_LETTER_DETAILS_RESPONSE, data })
  }
}

function * editCoverletter (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.coverletter}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(editCoverlettersActions.saveeditCoverlettersResponse(json.data))
    } else {
      yield put(editCoverlettersActions.saveeditCoverlettersResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editCoverletterTypes.RESPONSE, data })
  }
}

function * deleteCoverletter (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.coverletter}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deleteCoverletterActions.savemResponseDeleteCoverletter(json.data))
    } else {
      yield put(deleteCoverletterActions.savemResponseDeleteCoverletter([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: coverLetterDeleteTypes.SAVE_DELETE_COVER_LETTER_RESPONSE, data })
  }
}

function * fetchReasonGrouping (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.reasonGrouping, { params, headers:headers })
    if (json.status === 200) {
      yield put(reasonGroupingActions.saveReasonGroupingResponse(json.data))
    } else {
      yield put(reasonGroupingActions.saveReasonGroupingResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: reasonGroupingActionsTypes.SAVE_REASON_GROUPING_RESPONSE, data })
  }
}

function * fetchServiceType (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.serviceType, { params, headers:headers })
    if (json.status === 200) {
      yield put(serviceTypeActions.saveServicetypeResponse(json.data))
    } else {
      yield put(serviceTypeActions.saveServicetypeResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: serviceTypeActionsTypes.SAVE_SERVICE_TYPE_RESPONSE, data })
  }
}
export function * fetchCoverletterWatcher () {
  yield all([
    yield takeLatest(addCoverletterTypes.REQUEST, addCoverletter),
    yield takeLatest(coverLetterActionsTypes.GET_COVER_LETTER, fetchCoverletter),
    yield takeLatest(coverLetterGetDetailsTypes.GET_COVER_LETTER_DETAILS, getCoverletterDetails),
    yield takeLatest(editCoverletterTypes.REQUEST, editCoverletter),
    yield takeLatest(coverLetterDeleteTypes.DELETE_COVER_LETTER, deleteCoverletter),
    yield takeLatest(reasonGroupingActionsTypes.GET_REASON_GROUPING, fetchReasonGrouping),
    yield takeLatest(serviceTypeActionsTypes.GET_SERVICE_TYPE, fetchServiceType)
  ])
}
