import {
  getClientSignupActionsTypes,
  postClientSignupActionsTypes,
  postClientPackageActionsTypes
} from "../actions";

export const getClientSignupStoreKey = "getClientSignupStore";

export const getClientSignupReducer = (state = {}, action) => {
  const res = action.data && action.data;
  switch (action.type) {
    case getClientSignupActionsTypes.REQUEST:
      return { ...state, loadingClientSignup: true };
    case getClientSignupActionsTypes.RESPONSE:
      return {
        ...state,
        getClientSignup: res.data,
        count: res.count,
        statusClientSignup: res.status,
        messageClientSignup: res.message,
        loadingClientSignup: false,
      };
    case getClientSignupActionsTypes.CLEAR:
      return {
        ...state,
        getClientSignup: null,
        count: 0,
        statusClientSignup: "",
        messageClientSignup: "",
      };
      case getClientSignupActionsTypes.DID_MOUNT:
        return {
          ...state, didMount: action.didMount
        };
    default:
      return state;
  }
};

export const addClientSignupStoreKey = "addClientSignupStore";

export const addClientSignupReducer = (state = {}, action) => {
  const res = action.data && action.data;
  switch (action.type) {
    case postClientSignupActionsTypes.REQUEST:
      return { ...state, loadingAddClientSignup: true };
    case postClientSignupActionsTypes.RESPONSE:
      return {
        ...state,
        addClientSignup: res.data,
        statusAddClientSignup: res.status,
        messageAddClientSignup: res.message,
        loadingAddClientSignup: false,
      };
    case postClientSignupActionsTypes.CLEAR:
      return {
        ...state,
        addClientSignup: null,
        statusAddClientSignup: "",
        messageAddClientSignup: "",
      };
    default:
      return state;
  }
};

export const addClientPackageStoreKey = "addClientPackageStore";

export const addClientPackageReducer = (state = {}, action) => {
  const res = action.data && action.data;
  switch (action.type) {
    case postClientPackageActionsTypes.REQUEST:
      return { ...state, loadingAddClientPackage: true };
    case postClientPackageActionsTypes.RESPONSE:
      return {
        ...state,
        addClientPackage: res.data,
        statusAddClientPackage: res.status,
        messageAddClientPackage: res.message,
        loadingAddClientPackage: false,
      };
    case postClientPackageActionsTypes.CLEAR:
      return {
        ...state,
        addClientPackage: null,
        statusAddClientPackage: "",
        messageAddClientPackage: "",
      };
    default:
      return state;
  }
};
