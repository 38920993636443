import {
  addAcquirerSettingsActionsTypes,
  getAcquirerSettingsDetailsActionsTypes,
  updateAcquirerSettingsActionsTypes,
  deleteAcquirerSettingsActionsTypes,
  acquirerActionsTypes
} from '../actions'

export const addAcquirerSettingsStoreKey = 'addAcquirerSettingsStore'

export const addAcquirerSettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addAcquirerSettingsActionsTypes.REQUEST:
      return { ...state, loadingAddProcessor: true }
    case addAcquirerSettingsActionsTypes.RESPONSE:
      return {
        ...state,
        addProcessorSettings: res.data,
        statusAddProcessor: res.status,
        messageAddProcessor: res.message,
        loadingAddProcessor: false
      }
    case addAcquirerSettingsActionsTypes.CLEAR:
      return {
        ...state,
        addProcessorSettings: null,
        statusAddProcessor: '',
        messageAddProcessor: ''
      }
    default:
      return state
  }
}

export const acquirerStoreKey = 'acquirerStore'

export const acquirerReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case acquirerActionsTypes.GET_ACQUIRER:
      return { ...state, processorLoading: true }
    case acquirerActionsTypes.SAVE_ACQUIRER_RESPONSE:
      return { ...state, processorData: res.data, count: res.count, processorLoading: false }
    case acquirerActionsTypes.CLEAR_ACQUIRER:
      return { ...state, processorData: null }
    default:
      return state
  }
}

export const getAcquirerSettingsDetailsStoreKey = 'getAcquirerSettingsDetailsStore'

export const getAcquirerSettingsDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getAcquirerSettingsDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsProcessor: true }
    case getAcquirerSettingsDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsProcessorSettings: res.data,
        statusGetDetailsProcessor: res.status,
        messageGetDetailsProcessor: res.message,
        loadingGetDetailsProcessor: false
      }
    case getAcquirerSettingsDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsProcessorSettings: null,
        statusGetDetailsProcessor: '',
        messageGetDetailsProcessor: ''
      }
    default:
      return state
  }
}

export const updateAcquirerSettingsStoreKey = 'updateAcquirerSettingsStore'

export const updateAcquirerSettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updateAcquirerSettingsActionsTypes.REQUEST:
      return { ...state, loadingUpdateProcessor: true }
    case updateAcquirerSettingsActionsTypes.RESPONSE:
      return {
        ...state,
        updateProcessorSettings: res.data,
        statusUpdateProcessor: res.status,
        messageUpdateProcessor: res.message,
        loadingUpdateProcessor: false
      }
    case updateAcquirerSettingsActionsTypes.CLEAR:
      return {
        ...state,
        updateProcessorSettings: null,
        statusUpdateProcessor: '',
        messageUpdateProcessor: ''
      }
    default:
      return state
  }
}

export const deleteAcquirerSettingsStoreKey = 'deleteAcquirerSettingsStore'

export const deleteAcquirerSettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteAcquirerSettingsActionsTypes.REQUEST:
      return { ...state, loadingDeleteProcessor: true }
    case deleteAcquirerSettingsActionsTypes.RESPONSE:
      return {
        ...state,
        deleteProcessorSettings: res.data,
        statusDeleteProcessor: res.status,
        messageDeleteProcessor: res.message,
        loadingDeleteProcessor: false
      }
    case deleteAcquirerSettingsActionsTypes.CLEAR:
      return {
        ...state,
        deleteProcessorSettings: null,
        statusDeleteProcessor: '',
        messageDeleteProcessor: ''
      }
    default:
      return state
  }
}
