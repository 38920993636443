import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addUserRegisterTypes,
  addUserRegisterActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addUserInfo (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.userRegister, payload, { headers:headers })
    if (json.status === 200) {
      yield put(addUserRegisterActions.saveResponse(json.data))
    } else {
      yield put(addUserRegisterActions.clear([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addUserRegisterTypes.RESPONSE, data })
  }
}

export function * fetchUserRegisterWatcher () {
  yield all([
    yield takeLatest(addUserRegisterTypes.REQUEST, addUserInfo)
  ])
}
