import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addNotifyTypes,
  addNotifyActions,
  notifyActionsTypes,
  notifyActions
} from '../actions'
import serviceList from '../../services/serviceList'

function* addNotify(action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.notificationType, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addNotifyActions.saveResponse(json.data))
    } else {
      yield put(addNotifyActions.saveResponse(json.data))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addNotifyTypes.RESPONSE, data })
  }
}

function* fetchNotify(actions) {
  const { headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.notificationType, {headers:headers})
    if (json.status === 200) {
      yield put(notifyActions.saveResponse(json.data))
    } else {
      yield put(notifyActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: notifyActionsTypes.RESPONSE, data })
  }
}

export function* fetchNotifyWatcher() {
  yield all([
    yield takeLatest(addNotifyTypes.REQUEST, addNotify),
    yield takeLatest(notifyActionsTypes.REQUEST, fetchNotify)
  ])
}
