import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  UserNotificationActionsTypes,
  UserNotificationActions,
 
} from '../actions'
import serviceList from '../../services/serviceList'
function * fetchUserNotification (actions) {
  const { headers,payload } = actions
  const { params } = payload && payload

  try {
    const json = yield axiosInstance.get(serviceList.getNotification,{headers: {
      "Content-Type": "application/json"}
  })
    if (json.status === 200) {
      yield put(UserNotificationActions.UserNotificationSuccess(json))
    } else {
      yield put(UserNotificationActions.UserNotificationSuccess([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: UserNotificationActionsTypes.USER_NOTIFICATION_SUCCESS, data })
  }
}


export function * fetchUserNotificationWatcher () {
  yield all([
    yield takeLatest(UserNotificationActionsTypes.USER_NOTIFICATION_INIT, fetchUserNotification),
  ])
}
