import { exportActionsTypes } from '../actions'

export const exportInitialState = {
  list: null
}

export const exportStoreKey = 'exportStore'

const exportReducer = (state = exportInitialState, action) => {
  switch (action.type) {
    case exportActionsTypes.GET_EXPORT:
      return { ...state, exportLoading: true }
    case exportActionsTypes.SAVE_EXPORT_RESPONSE:
      return { ...state, exports: action.data, exportLoading: false }
    case exportActionsTypes.CLEAR_EXPORT:
      return { ...state, exports: null }
    case exportActionsTypes.ADD_COLUMNS:
      return {
        ...state,
        selectedColsIBA: action.data.data,
        colHasChanged: action.data.hasColChanged
      }
    case exportActionsTypes.CLER_COLUMNS:
      return { ...state, selectedColsIBA: {}, colHasChanged: false }
    default:
      return state
  }
}
export default exportReducer
