import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../theme/helpers";
import { useHistory, Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { serviceValidation } from "./validate/validation";
import { setLocalStorage, getLocalStorage } from "../../../utils/helper";
import { CLIENT_SESSION, SHOPIFY_STORAGE } from "../../../utils/constants";
import { warningAlert } from "../../../utils/alerts";
import {
  getClientSignupActions,
  postClientSignUpActions,
} from "../../../store/actions";
import { colorLighten } from "../../../theme/assets/ts/_utils";

const ShopifyBusinessModel = (props) => {
  console.log(props, " props ");
  console.log(
    getLocalStorage(SHOPIFY_STORAGE.PROCESSOR),
    " shopify storage props "
  );
  const selectedProcessorName = getLocalStorage(SHOPIFY_STORAGE.PROCESSOR);
  const {
    getClientSignupDispatch,
    statusClientSignup,
    getClientSignup,
    addClientSignupDispatch,
    clearaddClientSignupDispatch,
    statusAddClientSignup,
    messageAddClientSignup,
    loadingAddClientSignup,
  } = props;

  const history = useHistory();
  const [serviceType, setSelectedService] = useState([]);
  const [errors, setErrors] = useState({});
  const clientSignupId = getLocalStorage(CLIENT_SESSION.CLIENT_TOKEN);

  const handleChoose = (name, value) => {
    console.log(name, value, " name value ");
    if (!serviceType.includes(value)) {
      console.log(name, value, " name value 1 ");
      setSelectedService((prevVal) => [...prevVal, value]);
      setErrors({ ...errors, serviceType: "" });
    } else {
      console.log(name, value, " name value 2 ");
      setSelectedService((prevVal) =>
        prevVal.filter((x) => (x !== value ? x : null))
      );
    }
  };

  const handleSubmit = (e) => {
    const errorMsg = serviceValidation(serviceType, setErrors);
    console.log(errorMsg, "  erre d ");
    console.log(serviceType, "  erre d ");

    if (_.isEmpty(errorMsg)) {
      serviceType && setLocalStorage(SHOPIFY_STORAGE.SERVICE_TYPE, serviceType);
    } else {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="bg-shadow">
        <div className="d-flex justify-content-center ms-10">
          <a href="#" className="mb-12 mt-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              className="h-65px"
              style={{ filter: "drop-shadow(rgb(0, 0, 0) 4px 2px 4px)" }}
            />
          </a>
        </div>
        <div className="row mb-10 gx-0">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xl-1" />
          <div className="col-lg-10 col-md-10 col-sm-10 col-xl-10 d-flex justify-content-center">
            <div className="card shadow-sm ms-5">
              <div
                className="card-header  d-flex justify-content-center"
                style={{ minHeight: "49px" }}
              >
                <h2
                  className="text mb-2 mt-5 fw-bolder"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  Select Your Service Method
                </h2>
              </div>
              <div className="card-body">
                <div className="mb-5">
                  <div className="d-flex justify-content-center">
                    <h5
                      className="text fw-bolder fs-5 ms-10"
                      style={{ color: "rgb(25 14 70)" }}
                    >
                      To provide the best responses to your chargebacks, we need
                      to know how your business
                    </h5>
                  </div>
                  <div className="d-flex justify-content-center">
                    <h5
                      className="text mb-2 fw-bolder ms-10 fs-5"
                      style={{ color: "rgb(25 14 70)" }}
                    >
                      provides its products or services. Please select all that
                      apply.
                    </h5>
                  </div>
                </div>
                <div className="row col-lg-12">
                  <div className="col-lg-4">
                    <div className="card shadow-sm ms-5 h-500px">
                      <div className="card-body">
                        <div className="d-flex justify-content-center">
                          <a href="#">
                            <img
                              alt="Logo"
                              src={toAbsoluteUrl(
                                "/media/illustrations/unitedpalms-1/3-dark.png"
                              )}
                              className="w-250px h-250px"
                            />
                          </a>
                        </div>
                        <h3
                          className="fw-bolder fs-4 d-flex justify-content-center"
                          style={{ color: "rgb(25 14 70)" }}
                        >
                          Digital Product/Service
                        </h3>
                        <h6
                          className="fw-bold fs-5 ms-10 mb-5 text-center"
                          style={{ color: "rgb(25 14 70)" }}
                        >
                          Your product or service is delivered to your customer
                          via digital methods such as streaming, download, or
                          email.
                        </h6>
                        <div className="text-center mt-5">
                          <i
                            className={`bi bi-check-circle fs-1 ${
                              serviceType.includes("Digital Service or Product")
                                ? "text-success"
                                : "text-white"
                            }`}
                          />
                        </div>
                        <div className="text-center mt-5">
                          <button
                            className={`btn btn-sm
                            ${
                              serviceType.includes("Digital Service or Product")
                                ? "bg-success"
                                : "bg-primary"
                            }
                            fw-bolder text-white w-100px`}
                            onClick={() => {
                              handleChoose(
                                "digitalProduct",
                                "Digital Service or Product"
                              );
                            }}
                          >
                            {serviceType.includes("Digital Service or Product")
                              ? "Selected"
                              : "Select"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card shadow-sm ms-5 h-500px">
                      <div className="card-body">
                        <div className="d-flex justify-content-center">
                          <a href="#">
                            <img
                              alt="Logo"
                              src={toAbsoluteUrl(
                                "/media/illustrations/dozzy-1/20-dark.png"
                              )}
                              className="w-250px h-250px"
                            />
                          </a>
                        </div>
                        <h3
                          className="fw-bolder fs-4 d-flex justify-content-center"
                          style={{ color: "rgb(25 14 70)" }}
                        >
                          Physical Product
                        </h3>
                        <h6
                          className="fw-bold fs-5 ms-10 text-center"
                          style={{ color: "rgb(25 14 70)" }}
                        >
                          Your product is physically delivered or shipped to
                          your customer.
                        </h6>
                        <div className="text-center mt-5">
                          <i
                            className={`bi bi-check-circle fs-1 ${
                              serviceType.includes("Physical Product")
                                ? "text-success"
                                : "text-white"
                            }`}
                          />
                        </div>
                        <div className="text-center mt-15">
                          <button
                            className={`btn btn-sm
                            ${
                              serviceType.includes("Physical Product")
                                ? "bg-success"
                                : "bg-primary"
                            }
                            fw-bolder text-white w-100px`}
                            onClick={() => {
                              handleChoose(
                                "physicalProduct",
                                "Physical Product"
                              );
                            }}
                          >
                            {serviceType.includes("Physical Product")
                              ? "Selected"
                              : "Select"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card shadow-sm ms-5 h-500px">
                      <div className="card-body">
                        <div className="d-flex justify-content-center">
                          <a href="#">
                            <img
                              alt="Logo"
                              src={toAbsoluteUrl(
                                "/media/illustrations/dozzy-1/9-dark.png"
                              )}
                              className="w-250px h-250px"
                            />
                          </a>
                        </div>
                        <h3
                          className="fw-bolder fs-4 d-flex justify-content-center"
                          style={{ color: "rgb(25 14 70)" }}
                        >
                          Offline Service
                        </h3>
                        <h6
                          className="fw-bold fs-5 ms-10 text-center"
                          style={{ color: "rgb(25 14 70)" }}
                        >
                          Your service is provided directly in physical
                          proximity to your customer.
                        </h6>
                        <div className="text-center mt-5">
                          <i
                            className={`bi bi-check-circle fs-1 ${
                              serviceType.includes("Offline Service")
                                ? "text-success"
                                : "text-white"
                            }`}
                          />
                        </div>
                        <div className="text-center mt-15">
                          <button
                            className={`btn btn-sm
                            ${
                              serviceType.includes("Offline Service")
                                ? "bg-success"
                                : "bg-primary"
                            }
                            fw-bolder text-white w-100px`}
                            onClick={() => {
                              handleChoose("offlineService", "Offline Service");
                            }}
                          >
                            {serviceType.includes("Offline Service")
                              ? "Selected"
                              : "Select"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {errors && errors.serviceType && (
                  <div className="rr mt-5 text-center bg-light-warning py-3">
                    <style>{".rr{color:black;}"}</style>
                    {errors.serviceType}
                  </div>
                )}
                <div className="form-group row mb-4 mt-15">
                  <div className="text-center">
                    <Link
                      to={`/shopify-selectcrm`}
                      className="btn btn-sm m-2 w-120px fw-bolder text-white h-40px"
                      style={{ backgroundColor: "#f56c61" }}
                    >
                      Previous
                    </Link>
                    <Link
                      className="btn btn-sm fw-bolder btn-primary m-2 w-120px h-40px"
                      to="shopify-subscription"
                      onClick={(event) => {
                        handleSubmit(event);
                      }}
                    >
                      Continue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ShopifyBusinessModel;
