import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import 'bootstrap-icons/font/bootstrap-icons.css'

export const Stepper = (props) => {
  const {
    completedSteps,
    activeStep,
    stepperArr,
    onStepperClick
  } = props

  const arryIncludes = (currentId) => {
    return completedSteps.includes(currentId)
  }

  const stepperClick = (i) => {
    if (completedSteps.length > i) {
      onStepperClick(i)
    }
  }

  return (
    <>
      <div className='stepper-horizontal mx-auto' id='stepper1'>
        {
          stepperArr.length > 0 && stepperArr.map((item, i) => (
            <div
              className={`step ${arryIncludes(i) ? 'done' : ''} ${activeStep === i ? 'editing' : ''}`}
              key={i}
            >
              <div
                className='step-circle'
                onClick={() => { stepperClick(i) }}
              >
                <span>
                  {
                    arryIncludes(i)
                      ? (
                        <i className={`bi bi-check fa-lg m-auto ${activeStep === i ? 'text-dark' : 'text-white'}`} />
                      )
                      : (
                        <span>{i + 1}</span>
                      )
                  }
                </span>
              </div>
              <div
                className='step-title'
                onClick={() => { stepperClick(i) }}
              >
                {item.stepperLabel}
              </div>
              <div className='step-optional'>{item.Optional}</div>
              <div className='step-bar-left' />
              <div className='step-bar-right' />
            </div>
          ))
        }
      </div>
    </>
  )
}

Stepper.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
  completedSteps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  stepperArr: PropTypes.array.isRequired,
  onStepperClick: PropTypes.func.isRequired
}
