export const addClientMgmtTypes = {
  REQUEST: 'ADD_CLIENT_MGMT_REQUEST',
  RESPONSE: 'ADD_CLIENT_MGMT_RESPONSE',
  ERROR: 'ADD_CLIENT_MGMT_ERROR',
  CLEAR: 'ADD_CLIENT_MGMT_CLEAR'
}

export const clientMgmtActions = {
  addClientMgmt: (data, headers) => {
    return {
      type: addClientMgmtTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveaddClientMgmtResponse: (data) => {
    return {
      type: addClientMgmtTypes.RESPONSE,
      data
    }
  },
  clearaddClientMgmt: () => ({
    type: addClientMgmtTypes.CLEAR
  })
}

export const clientDetailsTypes = {
  REQUEST: 'CLIENT_DETAILS_REQUEST_',
  RESPONSE: 'CLIENT_DETAILS_RESPONSE_',
  ERROR: 'CLIENT_DETAILS_ERROR_',
  CLEAR: 'CLIENT_DETAILS_CLEAR_',
  CURRENT_TABS_VALUE: 'CURRENT_TABS_VALUE',
}

export const clientDetailsActions = {
  getClientDetails: (data, headers) => {
    return {
      type: clientDetailsTypes.REQUEST,
      payload: data,
      headers
    }
  },
  saveClientDetailsResponse: (data) => {
    return {
      type: clientDetailsTypes.RESPONSE,
      data
    }
  },
  clearClientDetails: () => ({
    type: clientDetailsTypes.CLEAR
  }),
  clientCurrentTabValue: (currentTab) => ({
    type: clientDetailsTypes.CURRENT_TABS_VALUE,
    currentTab
  })
}

export const clientGetDetailsTypes = {
  GET_CLIENT_DETAILS: 'GET_CLIENT_DETAILS',
  CLIENT_DETAILS_RESPONSE: 'CLIENT_DETAILS_RESPONSE',
  CLEAR_CLIENT_DETAILS: 'CLEAR_CLIENT_DETAILS'
}
export const clientGetDetailsActions = {
  getClientDetails: (id, headers) => ({
    type: clientGetDetailsTypes.GET_CLIENT_DETAILS,
    id, headers
  }),
  saveClientDetailsResponse: data => ({
    type: clientGetDetailsTypes.CLIENT_DETAILS_RESPONSE,
    data
  }),
  clearClientDetails: () => ({
    type: clientGetDetailsTypes.CLEAR_CLIENT_DETAILS
  })
}

export const editClientTypes = {
  REQUEST: 'EDIT_CLIENT_REQUEST',
  RESPONSE: 'EDIT_CLIENT_RESPONSE',
  ERROR: 'EDIT_CLIENT_ERROR',
  CLEAR: 'EDIT_CLIENT_CLEAR'
}

export const editClientsActions = {
  editClients: (id, params, headers) => {
    return {
      type: editClientTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditClientsResponse: (data) => {
    return {
      type: editClientTypes.RESPONSE,
      data
    }
  },
  cleareditClients: () => ({
    type: editClientTypes.CLEAR
  })
}
