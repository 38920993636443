import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  clientActions, clientActionsTypes,
  userRoleActions, usersRoleTypes,
  industryActions, industryTypes
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchClient (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.getClient, { params, headers:headers  })
    if (json.status === 200) {
      yield put(clientActions.saveclientResponse(json.data))
    } else {
      yield put(clientActions.saveclientResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: clientActionsTypes.SAVE_CLIENT_RESPONSE, data })
  }
}

function * fetchUserRole (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userRole, { params, headers:headers })
    if (json.status === 200) {
      yield put(userRoleActions.saveUserRoleResponse(json.data))
    } else {
      yield put(userRoleActions.saveUserRoleResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: usersRoleTypes.RESPONSE, data })
  }
}

function * fetchIndustry (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.Industry, { params, headers:headers })
    if (json.status === 200) {
      yield put(industryActions.saveIndustryResponse(json.data))
    } else {
      yield put(industryActions.saveIndustryResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: industryTypes.RESPONSE, data })
  }
}

export function * fetchClientWatcher () {
  yield all([
    yield takeLatest(clientActionsTypes.GET_CLIENT, fetchClient),
    yield takeLatest(usersRoleTypes.REQUEST, fetchUserRole),
    yield takeLatest(industryTypes.REQUEST, fetchIndustry)
  ])
}
