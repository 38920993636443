import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../../theme/helpers";
import {
  REGEX,
  STATUS_RESPONSE,
  SWEET_ALERT_MSG,
  SAVE_CURRENT,
  SESSION,
  API,
} from "../../../../../utils/constants";
import { getLocalStorage, headersWithAuth } from "../../../../../utils/helper";
import ReactSelect from "../../../../../theme/layout/components/ReactSelect";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  warningAlert,
  confirmAlert,
  confirmationAlert,
} from "../../../../../utils/alerts";
import {
  crmActions,
  clientCRMActions,
  editClientCrmActions,
  deleteclientCRMActions,
  addclientCRMActions,
  checkCrmActions,
} from "../../../../../store/actions";
import color from "../../../../../utils/colors";
import { crmValidation, addCrmValidation } from "../../userDetails/validation";
import ReactPaginate from "react-paginate";

const CrmModal = (props) => {
  const {
    getClientCRM,
    loadingClientCRM,
    className,
    getCRM,
    getClientCRMDispatch,
    getCRMDispatch,
    statusECCRM,
    messageECCRM,
    deleteclientCRMDispatch,
    deleteclientCRMStatus,
    deleteclientCRMMessage,
    clearDeleteclientCRMDispatch,
    clearaddclientCRMDispatch,
    addclientCRMDispatch,
    statusACCRM,
    messageACCRM,
    currentMerchantId,
    editClientCrmDispatch,
    cleareditClientCrmDispatch,
    checkDupclientCRMDispatch,
    clearCheckDupclientCRMDispatch,
    statusCCRM,
    messageCCRM,
    loadingCCRM,
    loadingACCRM,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };

  const currentClientID = getLocalStorage(SAVE_CURRENT.CLIENT_ID);
  const [limit, setLimit] = useState(25);
  const pathName = useLocation().pathname;
  const url = pathName && pathName.split("update-merchant/");
  const id = url && url[1];
  const [editMode, setEditMode] = useState(false);
  const [showCrmForm, setShowCrmForm] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [errors, setErrors] = useState({});
  const [crmOption, setCrmOption] = useState();
  const [selectedCrmOption, setSelectedCrmOption] = useState("");
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [formData, setFormData] = useState({
    crmId: "",
  });
  const [form, setForm] = useState({
    crmId: "",
    crmLabel: "",
    apiKey: "",
    apiSecretKey: "",
    apiEndPoint: "",
  });

  const handleInputChange = (e, type) => {
    e.preventDefault();
    if (type === "setForm") {
      setForm((values) => ({ ...values, [e.target.name]: e.target.value }));
    }
    if (type === "setFormData") {
      setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSaveCrm = () => {
    const errorMsg = addCrmValidation(form, setErrors);
    if (_.isEmpty(errorMsg)) {
      if (editMode) {
        const payload = {
          crmId: form.crmId,
          apiKey: form.apiKey,
          apiSecretKey: form.apiSecretKey,
          apiEndPoint: form.apiEndPoint,
        };
        editClientCrmDispatch(
          currentId,
          payload,
          isAuthorized ? headersWithAuth : headercurrentWithApiKey
        );
      } else {
        addclientCRMDispatch(
          {
            clientId: currentClientID,
            merchantId: currentMerchantId,
            crmId: formData.crmId,
            apiKey: form.apiKey,
            apiSecretKey: form.apiSecretKey,
            apiEndPoint: form.apiEndPoint,
          },
          isAuthorized ? headersWithAuth : headercurrentWithApiKey
        );
      }
    }
  };

  const handleCheckDupCrm = () => {
    const errorMsg = crmValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      const params = {
        clientId: currentClientID,
        crmId: formData.crmId,
      };
      checkDupclientCRMDispatch(
        params,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    }
  };

  const onEditClick = (item) => {
    setCurrentId(item._id);
    setShowCrmForm(true);
    setEditMode(true);
    setSelectedCrmOption({
      label: item.crmId && item.crmId.crmName,
      value: item.crmId && item.crmId._id,
    });
    setFormData((values) => ({
      ...values,
      crmId: item.crmId && item.crmId._id,
    }));
    setForm({
      crmId: item.crmId && item.crmId._id,
      crmLabel: item.crmLabel,
      apiKey: item.apiKey,
      apiSecretKey: item.apiSecretKey,
      apiEndPoint: item.apiEndPoint,
    });
  };

  const onConfirmDelete = (id) => {
    deleteclientCRMDispatch(
      id,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_CRM,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete(id);
      },
      () => {}
    );
  };

  const clearFunc = () => {
    setSelectedCrmOption();
    setForm({
      apiKey: "",
      apiSecretKey: "",
      apiEndPoint: "",
      crmId: "",
      crmLabel: "",
    });
    setFormData({ crmId: "" });
    setEditMode(false);
    const params = {
      limit: limit,
      page: activePageNumber,
      merchantId: id,
      clientId: currentClientID,
    };
    getClientCRMDispatch(
      _.pickBy(params),
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    setShowCrmForm(false);
  };

  const onPostConfirm = () => {
    clearFunc();
  };

  useEffect(() => {
    if (statusACCRM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageACCRM,
        "success",
        "ok",
        () => {
          onPostConfirm();
        },
        () => {
          onPostConfirm();
        }
      );
      clearaddclientCRMDispatch();
    } else if (statusACCRM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageACCRM, "", "Ok");
    }
    clearaddclientCRMDispatch();
  }, [statusACCRM]);

  useEffect(() => {
    if (deleteclientCRMStatus === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        deleteclientCRMMessage,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearDeleteclientCRMDispatch();
    } else if (deleteclientCRMStatus === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", deleteclientCRMMessage, "", "Ok");
    }
    clearDeleteclientCRMDispatch();
  }, [deleteclientCRMStatus]);

  useEffect(() => {
    if (statusCCRM === STATUS_RESPONSE.SUCCESS_MSG) {
      setShowCrmForm(true);
      clearCheckDupclientCRMDispatch();
    } else if (statusCCRM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageCCRM, "", "Ok");
    }
    clearCheckDupclientCRMDispatch();
  }, [statusCCRM]);

  useEffect(() => {
    const params = {
      limit: limit,
      page: activePageNumber,
      merchantId: id,
      clientId: currentClientID,
    };
    getClientCRMDispatch(
      _.pickBy(params),
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    getCRMDispatch(
      null,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const handleChangeCRM = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCrmOption(selectedOption);
      setFormData((values) => ({ ...values, crmId: selectedOption.value }));
      setErrors((values) => ({ ...values, crmId: "" }));
    } else {
      setSelectedCrmOption();
      setFormData((values) => ({ ...values, crmId: "" }));
    }
    showCrmForm && setShowCrmForm(false);
    setForm({
      crmId: "",
      crmLabel: "",
      apiKey: "",
      apiSecretKey: "",
      apiEndPoint: "",
    });
  };

  useEffect(() => {
    const crmId = getDefaultOptions(getCRM);
    setCrmOption(crmId);
    if (!_.isEmpty(formData.crmId)) {
      const selOption = _.filter(crmId, function (x) {
        if (_.includes(formData.crmId._id, x.value)) {
          return x;
        }
      });
      setSelectedCrmOption(selOption);
    }
  }, [getCRM]);

  const getDefaultOptions = (getCRM) => {
    const defaultOptions = [];
    if (!_.isEmpty(getCRM)) {
      const { data } = getCRM;
      if (!_.isEmpty(data)) {
        data.map((item) =>
          defaultOptions.push({
            label: `${item.crmName ? item.crmName : ""}`,
            value: item._id,
          })
        );
      }
      return defaultOptions;
    }
  };

  const onConfirm = () => {
    clearFunc();
  };

  useEffect(() => {
    if (statusECCRM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageECCRM,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      cleareditClientCrmDispatch();
    } else if (statusECCRM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageECCRM, "", "Ok");
      cleareditClientCrmDispatch();
    }
  }, [statusECCRM]);

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const params = {
      limit: limit,
      page: pageNumber,
      merchantId: id,
      clientId: currentClientID,
    };
    setActivePageNumber(pageNumber);
    getClientCRMDispatch(
      _.pickBy(params),
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const totalPages =
    getClientCRM && getClientCRM.count
      ? Math.ceil(parseInt(getClientCRM && getClientCRM.count) / limit)
      : 1;

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
    const params = {
      limit: value,
      page: 1,
      merchantId: id,
      clientId: currentClientID,
    };
    const pickByParams = _.pickBy(params);
    getClientCRMDispatch(
      pickByParams,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  return (
    <>
      <div className="card-header bg-skyBlue scroll h-600px  py-10">
        <div className="card-body m-8">
          <h2 className="mb-5">CRM</h2>
          <div className="form-group row mb-2">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="d-flex justify-content-start my-auto">
                    <div className="col-lg-5 pr-3 me-3">
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name="crmId"
                        className="basic-single"
                        classNamePrefix="select"
                        handleChangeReactSelect={handleChangeCRM}
                        options={crmOption}
                        value={selectedCrmOption}
                        isDisabled={
                          loadingACCRM || loadingCCRM || loadingClientCRM
                        }
                      />
                      {errors && errors.crmId && (
                        <div className="rr mt-1">
                          <style>{".rr{color:red}"}</style>
                          {errors.crmId}
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className="btn btn-green mb-3 py-2"
                        disabled={
                          loadingACCRM ||
                          loadingCCRM ||
                          loadingClientCRM ||
                          showCrmForm
                        }
                        onClick={() => {
                          handleCheckDupCrm();
                        }}
                      >
                        {loadingCCRM ? (
                          <span
                            className="spinner-border spinner-border-sm mx-3"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showCrmForm ? (
              <form className="container-fixed">
                <div className="card-body">
                  {form.crmLabel ? (
                    <h4 className="me-8 mb-2">{form.crmLabel}</h4>
                  ) : null}
                  <div className="mb-3 pt-5">
                    <div className="row">
                      <div className="col-lg-2 mt-3">
                        <label className="font-size-xs font-weight-bold mb-3  form-label">
                          API End Point :
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <input
                          name="apiEndPoint"
                          type="text"
                          className="form-control"
                          placeholder="API End Point"
                          onChange={(e) => handleInputChange(e, "setForm")}
                          autoComplete="off"
                          value={form.apiEndPoint || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setForm)}
                          onKeyPress={(e) => {
                            if (
                              !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.apiEndPoint && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red}"}</style>
                            {errors.apiEndPoint}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-lg-2 mt-3">
                        <label className="font-size-xs font-weight-bold mb-3 form-label">
                          API Key :
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <input
                          name="apiKey"
                          type="text"
                          className="form-control"
                          placeholder="API Key"
                          onChange={(e) => handleInputChange(e, "setForm")}
                          autoComplete="off"
                          value={form.apiKey || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setForm)}
                          onKeyPress={(e) => {
                            if (
                              !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.apiKey && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red}"}</style>
                            {errors.apiKey}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-lg-2 mt-3">
                        <label className="font-size-xs font-weight-bold mb-3  form-label">
                          API Secret :
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <input
                          name="apiSecretKey"
                          type="text"
                          className="form-control"
                          placeholder="API Secret"
                          onChange={(e) => handleInputChange(e, "setForm")}
                          autoComplete="off"
                          value={form.apiSecretKey || ""}
                          maxLength={42}
                          onBlur={(e) => handleTrimWhiteSpace(e, setForm)}
                          onKeyPress={(e) => {
                            if (
                              !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors && errors.apiSecretKey && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red}"}</style>
                            {errors.apiSecretKey}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row pb-3">
                  <div className="col-lg-2" />
                  <div className="col-lg-6">
                    <button
                      type="button"
                      className="btn btn-sm btn-green m-2 fa-pull-right"
                      disabled={loadingACCRM}
                      onClick={() => {
                        handleSaveCrm();
                      }}
                    >
                      {loadingACCRM ? (
                        <span
                          className="spinner-border spinner-border-sm mx-3"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            ) : null}
          </div>
          <>
            <div className="w-100 d-flex justify-content-start mt-2">
              <div className={`card ${className}`}>
                <div className="card-body py-3">
                  <div className="col-md-7 d-flex">
                    <label className="col-form-label text-lg-start mt-3 ms-3">
                      Record(s) per Page : &nbsp;{" "}
                    </label>
                    <div className="col-md-3 mt-3">
                      <select
                        className="form-select w-6rem"
                        data-control="select"
                        data-placeholder="Select an option"
                        data-allow-clear="true"
                        onChange={(e) => handleRecordPerPage(e)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-hover table-rounded table-striped border gs-2 mt-6">
                      <thead>
                        <tr className="fw-bold fs-6 text-gray-800 ">
                          <th className="min-w-80px text-start">
                            <div className="d-flex">
                              <span>S.No</span>
                            </div>
                          </th>
                          <th className="min-w-200px text-start ps-6">
                            Action
                          </th>
                          <th className="min-w-200px text-start">
                            <div className="d-flex">
                              <span>CRM</span>
                            </div>
                          </th>
                          <th className="min-w-200px text-start">
                            <div className="d-flex">
                              <span>API Key</span>
                            </div>
                          </th>
                          <th className="min-w-200px text-start">
                            <div className="d-flex">
                              <span>API Secret</span>
                            </div>
                          </th>
                          <th className="min-w-200px text-start">
                            <div className="d-flex">
                              <span>API End Point</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingClientCRM ? (
                          <tr>
                            <td colSpan="8" className="text-center">
                              <div
                                className="spinner-border text-primary m-5"
                                role="status"
                              />
                            </td>
                          </tr>
                        ) : getClientCRM &&
                          getClientCRM.data &&
                          getClientCRM.data.length > 0 ? (
                          getClientCRM.data.map((item, i) => {
                            return item.apiKey ? (
                              <tr
                                key={i}
                                style={
                                  i === 0
                                    ? { borderColor: "black" }
                                    : { borderColor: "white" }
                                }
                              >
                                <td className="pb-0 pt-5  text-start">
                                  {i + 1}
                                </td>
                                <td className="pb-0 pt-3 text-start">
                                  <div className="my-auto d-flex">
                                    <button
                                      className="btn btn-icon btn-bg-light btn-icon-warning btn-active-color-warning btn-sm"
                                      onClick={() => {
                                        onEditClick(item);
                                      }}
                                    >
                                      {/* eslint-disable */}
                                      <KTSVG
                                        path="/media/icons/duotune/art/art005.svg"
                                        className="svg-icon-3"
                                      />
                                      {/* eslint-enable */}
                                    </button>
                                    <button
                                      className="btn btn-icon btn-bg-light btn-icon-danger btn-active-color-danger btn-sm"
                                      onClick={() => onDeleteItem(item._id)}
                                    >
                                      {/* eslint-disable */}
                                      <KTSVG
                                        path="/media/icons/duotune/general/gen027.svg"
                                        className="svg-icon-3"
                                      />
                                      {/* eslint-enable */}
                                    </button>
                                  </div>
                                </td>
                                <td className="pb-0 pt-5  text-start">
                                  {item.crmId && item.crmId.crmName}
                                </td>
                                <td className="pb-0 pt-5  text-start">
                                  {item.apiKey}
                                </td>
                                <td className="pb-0 pt-5  text-start">
                                  {item.apiSecretKey}
                                </td>
                                <td className="pb-0 pt-5  text-start">
                                  {item.apiEndPoint}
                                </td>
                              </tr>
                            ) : null;
                          })
                        ) : (
                          <tr className="text-center py-3">
                            <td colSpan="100%">No record(s) found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="form-group row mb-4 mt-6">
                    <div className="col-lg-12 mb-4 align-items-end d-flex">
                      <div className="col-lg-12 pagination">
                        <ReactPaginate
                          nextLabel="Next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={totalPages}
                          previousLabel="< Prev"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getCRM: state && state.crmStore && state.crmStore.getCRM,
  loading: state && state.crmStore && state.crmStore.loading,
  getClientCRM:
    state && state.clientCRMStore && state.clientCRMStore.getClientCRM,
  loadingClientCRM:
    state && state.clientCRMStore && state.clientCRMStore.loading,
  statusECCRM:
    state && state.editClientCrmStore && state.editClientCrmStore.statusECCRM,
  messageECCRM:
    state && state.editClientCrmStore && state.editClientCrmStore.messageECCRM,
  loadingECCRM:
    state &&
    state.editClientCrmStore &&
    state.editClientCrmStore.loadingECCRM &&
    state.editClientCrmStore.loadingECCRM,
  deleteclientCRM:
    state &&
    state.clientCRMDeleteStore &&
    state.clientCRMDeleteStore.deleteclientCRM,
  deleteclientCRMStatus:
    state &&
    state.clientCRMDeleteStore &&
    state.clientCRMDeleteStore.deleteclientCRMStatus,
  deleteclientCRMMessage:
    state &&
    state.clientCRMDeleteStore &&
    state.clientCRMDeleteStore.deleteclientCRMMessage,
  deleteclientCRMLoading:
    state && state.clientCRMDeleteStore && state.clientCRMDeleteStore.loading,
  currentClientId:
    state && state.merchantStore && state.merchantStore.currentClientId,
  statusACCRM:
    state && state.addclientCRMStore && state.addclientCRMStore.statusACCRM,
  messageACCRM:
    state && state.addclientCRMStore && state.addclientCRMStore.messageACCRM,
  loadingACCRM:
    state && state.addclientCRMStore && state.addclientCRMStore.loadingACCRM,
  statusCCRM: state && state.checkCrmStore && state.checkCrmStore.statusCCRM,
  messageCCRM: state && state.checkCrmStore && state.checkCrmStore.messageCCRM,
  loadingCCRM: state && state.checkCrmStore && state.checkCrmStore.loadingCCRM,
});

const mapDispatchToProps = (dispatch) => ({
  getCRMDispatch: (data, headers) => dispatch(crmActions.getCRM(data, headers)),
  getClientCRMDispatch: (data, headers) =>
    dispatch(clientCRMActions.getClientCRM(data, headers)),
  clearClientCRMDispatch: () => dispatch(clientCRMActions.clearClientCRM()),
  editClientCrmDispatch: (id, params, headers) =>
    dispatch(editClientCrmActions.editClientCrm(id, params, headers)),
  cleareditClientCrmDispatch: () =>
    dispatch(editClientCrmActions.cleareditClientCrm()),
  deleteclientCRMDispatch: (data, headers) =>
    dispatch(deleteclientCRMActions.deleteclientCRM(data, headers)),
  clearDeleteclientCRMDispatch: () =>
    dispatch(deleteclientCRMActions.clearDeleteclientCRM()),
  addclientCRMDispatch: (data, headers) =>
    dispatch(addclientCRMActions.addclientCRM(data, headers)),
  clearaddclientCRMDispatch: () =>
    dispatch(addclientCRMActions.clearaddclientCRM()),
  checkDupclientCRMDispatch: (data, headers) =>
    dispatch(checkCrmActions.add(data, headers)),
  clearCheckDupclientCRMDispatch: () => dispatch(checkCrmActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CrmModal);
