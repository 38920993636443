import React, { Suspense, lazy } from 'react'
import _ from 'lodash'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { FallbackView } from '../theme/partials'
import { getUserMatrixSlugs, getLocalStorage } from '../utils/helper'
import { SET_STORAGE } from '../utils/constants'
import routeConfig from './routeConfig'

export function PrivateRoutes() {
  const PerformanceAnalysis = lazy(() => import('../containers/performanceAnalyzerAWS/index'))
  const PreventionInquiriesIntelligence = lazy(() => import('../containers/preventionInquiriesIntelligenceAWS/index'))
  const FraudAnalysis = lazy(() => import('../containers/fraudAnalyzerAWS/index'))
  const PreventionAlert = lazy(() => import('../containers/preventionAlertAWS/index'))
  const RootCauseAnalysis = lazy(() => import('../containers/rootCauseAnalyzerAWS/index'))
  const ChargebackSummary = lazy(() => import('../containers/chargebackSummary/index'))
  const DashboardPage = lazy(() => import('../containers/dashboard'))
  const ClientManagement = lazy(() => import('../containers/clientManagement'))
  const ClientOnboarding = lazy(() => import('../containers/clientManagement/'))
  const UsersContainer = lazy(() => import('../containers/UsersContainer'))
  const UsersTypeContainer = lazy(() => import('../containers/UserType'))
  const Components = lazy(() => import('../containers/components'))
  const UsersPrivileges = lazy(() => import('../containers/userPrivileges'))
  const UsersType = lazy(() => import('../containers/UserType'))
  const PreventionAlerts = lazy(() => import('../containers/preventionAlerts'))
  const ChargebackManagement = lazy(() =>
    import('../containers/chargebackManagement/index')
  )
  const AddChargeback = lazy(() => import('../containers/chargebackManagement/index'))
  const UploadChargeback = lazy(() => import('../containers/chargebackManagement/index'))
  const UpdateChargeback = lazy(() => import('../containers/chargebackManagement/index'))
  const OrderInsight = lazy(() => import('../containers/orderInsight/index'))
  const IssuerBank = lazy(() => import('../containers/issuerBank/index'))
  const IssuerBankAlerts = lazy(() => import('../containers/IssuerBankAlerts/index'))
  const QuickSight = lazy(() => import('../containers/quicksight'))
  const PerformanceSummary = lazy(() => import('../containers/performanceSummary/index'))
  const PerformanceAnalyzer = lazy(() => import('../containers/performanceAnalyzer/index'))
  const RootCauseAnalyzer = lazy(() => import('../containers/rootCauseAnalyzer/index'))
  const FraudAnalyzer = lazy(() => import('../containers/fraudAnalyzer/index'))
  const DashboardPreventionAlert = lazy(() => import('../containers/dashboardPreventionAlert/index'))
  const OrderIntelligence = lazy(() => import('../containers/orderIntelligence/index'))
  const Partners = lazy(() => import('../containers/clientManagement/index'))
  const PartnerOnboarding = lazy(() => import('../containers/clientManagement/index'))
  const ManageDocumentCenter = lazy(() => import('../containers/documentCenter/index'))
  const AddDocument = lazy(() => import('../containers/documentCenter/index'))
  const ManageReasons = lazy(() => import('../containers/reasonManagement/index'))
  const AddReason = lazy(() => import('../containers/reasonManagement/index'))
  const CrmList = lazy(() => import('../containers/Settings/CrmManagement/index'))
  const AddCRM = lazy(() => import('../containers/Settings/CrmManagement/index'))
  const ProcessorList = lazy(() => import('../containers/Settings/ProcessorManagement/index'))
  const AddProcessor = lazy(() => import('../containers/Settings/ProcessorManagement/index'))
  const GatewayList = lazy(() => import('../containers/Settings/GatewayManagement/index'))
  const AddGateway = lazy(() => import('../containers/Settings/GatewayManagement/index'))
  const IndustryList = lazy(() => import('../containers/Settings/IndustryManagement/index'))
  const AddIndustry = lazy(() => import('../containers/Settings/IndustryManagement/index'))
  const UserList = lazy(() => import('../containers/UserManagement/index'))
  const AssignPartner = lazy(() => import('../containers/UserManagement/index'))
  const AssignClient = lazy(() => import('../containers/UserManagement/index'))
  const ViewUser = lazy(() => import('../containers/UserManagement/index'))
  const SiteConfig = lazy(() => import('../containers/SiteConfig/index'))
  const UserProfile = lazy(() => import('../components/Profile/UserProfile'))
  const AcquirerContainer = lazy(() => import('../containers/Settings/acquirer'))
  const SubscriptionContainer = lazy(() => import('../containers/subscriptionPackage/index'))
  const PaypalConfigurationContainer = lazy(() => import('../containers/paypalConfiguration/index'))
  const TemplateContainer = lazy(() => import('../containers/Settings/EmailTemplate/index'))
  const CBMailBatchContainer = lazy(() => import('../containers/ChargebackMailBatch/index'))
  const DashboardSdkContainer = lazy(() => import('../containers/DashboardSdk/index'))

  const pathName = useLocation().pathname
  const getSlugs = getUserMatrixSlugs()
  const slugToRedirect = getLocalStorage(SET_STORAGE.IS_FIRST_LOGIN) === 'true'
    ? '/session-expired'
    : '/auth-failed'

  const checkSlugsMatched = () => {
    if (pathName.includes('update') || pathName.includes('assign')) {
      let check = false
      _.forEach(getSlugs && getSlugs, (ele) => {
        if (ele.includes(':id')) {
          const currentUrl = ele.split('/:id')
          if (ele.includes(currentUrl && currentUrl[0])) {
            check = true
          }
        }
      })
      return check
    } else if (getLocalStorage(SET_STORAGE.IS_FIRST_LOGIN) === 'true') {
      return false
    } else if (getSlugs && getSlugs.includes(pathName)) {
      return true
    }
    return false
  }

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route
          path={routeConfig.dashboard}
          render={(props) => checkSlugsMatched()
            ? <DashboardPage {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.rootCauseAnalysis}
          component={RootCauseAnalysis}
        />
        <Route
          path={routeConfig.performanceAnalysis}
          component={PerformanceAnalysis}
        />
        <Route
          path={routeConfig.fraudAnalysis}
          component={FraudAnalysis}
        />
        <Route
          path={routeConfig.preventionInquiriesIntelligence}
          component={PreventionInquiriesIntelligence}
        />
        <Route
          path={routeConfig.preventionAlert}
          component={PreventionAlert}
        />
        <Route
          path={routeConfig.chargebackSummary}
          component={ChargebackSummary}
        />
        <Route
          path={routeConfig.updateChargeback}
          render={(props) => checkSlugsMatched()
            ? <UpdateChargeback {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.clientManagement}
          render={(props) => checkSlugsMatched()
            ? <ClientManagement {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.partnerManagement}
          render={(props) => checkSlugsMatched()
            ? <Partners {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.partnerOnboarding}
          render={(props) => checkSlugsMatched()
            ? <PartnerOnboarding {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.updatePartnersClient}
          render={(props) => checkSlugsMatched()
            ? <PartnerOnboarding {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.updatePartnersUser}
          render={(props) => checkSlugsMatched()
            ? <DashboardPage {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.clientOnboarding}
          render={(props) => checkSlugsMatched()
            ? <ClientOnboarding {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.emailTemplate}
          render={(props) => checkSlugsMatched()
            ? <ClientManagement {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addEmailTemplate}
          render={(props) => checkSlugsMatched()
            ? <ClientManagement {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.editClientOnboarding}
          render={(props) => checkSlugsMatched()
            ? <ClientOnboarding {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.userRole}
          render={(props) => checkSlugsMatched()
            ? <UsersContainer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addUserRole}
          render={(props) => checkSlugsMatched()
            ? <UsersContainer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.updateUserRole}
          render={(props) => checkSlugsMatched()
            ? <UsersContainer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.userType}
          render={(props) => checkSlugsMatched()
            ? <UsersTypeContainer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addUserType}
          render={(props) => checkSlugsMatched()
            ? <UsersTypeContainer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.updateUserType}
          render={(props) => checkSlugsMatched()
            ? <UsersTypeContainer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.userComponents}
          render={(props) => checkSlugsMatched()
            ? <Components {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addUserComponents}
          render={(props) => checkSlugsMatched()
            ? <Components {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.updateUserComponents}
          render={(props) => checkSlugsMatched()
            ? <Components {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.userPrivileges}
          render={(props) => checkSlugsMatched()
            ? <UsersPrivileges {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addUserPrivileges}
          render={(props) => checkSlugsMatched()
            ? <UsersPrivileges {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.updateUserPrivileges}
          render={(props) => checkSlugsMatched()
            ? <UsersPrivileges {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.userType}
          render={(props) => checkSlugsMatched()
            ? <UsersType {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.preventionAlerts}
          render={(props) => checkSlugsMatched()
            ? <PreventionAlerts {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.chargebackManagement}
          render={(props) => checkSlugsMatched()
            ? <ChargebackManagement {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addChargeback}
          render={(props) => checkSlugsMatched()
            ? <AddChargeback {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addChargeback}
          render={(props) => checkSlugsMatched()
            ? <UploadChargeback {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.uploadChargeback}
          render={(props) => checkSlugsMatched()
            ? <UpdateChargeback {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.issuerBank}
          render={(props) => checkSlugsMatched()
            ? <IssuerBank {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.issuerBankAlerts}
          render={(props) => checkSlugsMatched()
            ? <IssuerBankAlerts {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.orderInsight}
          render={(props) => checkSlugsMatched()
            ? <OrderInsight {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.quickSight}
          render={(props) => checkSlugsMatched()
            ? <QuickSight {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.performanceSummary}
          render={(props) => checkSlugsMatched()
            ? <PerformanceSummary {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.performanceAnalyzer}
          render={(props) => checkSlugsMatched()
            ? <PerformanceAnalyzer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.rootCauseAnalyzer}
          render={(props) => checkSlugsMatched()
            ? <RootCauseAnalyzer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.fraudAnalyzer}
          render={(props) => checkSlugsMatched()
            ? <FraudAnalyzer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.dashboardPreventionAlert}
          render={(props) => checkSlugsMatched()
            ? <DashboardPreventionAlert {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.orderIntelligence}
          render={(props) => checkSlugsMatched()
            ? <OrderIntelligence {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.documentCenter}
          render={(props) => checkSlugsMatched()
            ? <ManageDocumentCenter {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addDocument}
          render={(props) => checkSlugsMatched()
            ? <AddDocument {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.manageReason}
          render={(props) => checkSlugsMatched()
            ? <ManageReasons {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addReason}
          render={(props) => checkSlugsMatched()
            ? <AddReason {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.manageCrm}
          render={(props) => checkSlugsMatched()
            ? <CrmList {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addManageCrm}
          render={(props) => checkSlugsMatched()
            ? <AddCRM {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.updateManageCrm}
          render={(props) => checkSlugsMatched()
            ? <AddCRM {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.managePaymentProcessor}
          render={(props) => checkSlugsMatched()
            ? <ProcessorList {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addPaymentProcessor}
          render={(props) => checkSlugsMatched()
            ? <AddProcessor {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.managePaymentGateway}
          render={(props) => checkSlugsMatched()
            ? <GatewayList {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addPaymentGateway}
          render={(props) => checkSlugsMatched()
            ? <AddGateway {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.manageIndustry}
          render={(props) => checkSlugsMatched()
            ? <IndustryList {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addIndustry}
          render={(props) => checkSlugsMatched()
            ? <AddIndustry {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.manageUsers}
          render={(props) => checkSlugsMatched()
            ? <UserList {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.assignPartner}
          render={(props) => checkSlugsMatched()
            ? <AssignPartner {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.assignClient}
          render={(props) => checkSlugsMatched()
            ? <AssignClient {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.viewUsers}
          render={(props) => checkSlugsMatched()
            ? <ViewUser {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.manageAcquirer}
          render={(props) => checkSlugsMatched()
            ? <AcquirerContainer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.addAcquirer}
          render={(props) => checkSlugsMatched()
            ? <AcquirerContainer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.updateAcquirer}
          render={(props) => checkSlugsMatched()
            ? <AcquirerContainer {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.siteconfiguration}
          render={(props) => checkSlugsMatched()
            ? <SiteConfig {...props} />
            : <Redirect to={slugToRedirect} />}
        />
        <Route
          path={routeConfig.userProfile}
          render={(props) => checkSlugsMatched()
            ? <UserProfile {...props} />
            : <Redirect to='/auth-failed' />}
        />
        <Route
          path={routeConfig.userChangePassword}
          render={(props) => checkSlugsMatched()
            ? <UserProfile {...props} />
            : <Redirect to='/auth-failed' />}
        />
        <Route
          path={routeConfig.subscriptionPackage}
          render={(props) => checkSlugsMatched()
            ? <SubscriptionContainer {...props} />
            : <Redirect to='/auth-failed' />}
        />
        <Route
          path={routeConfig.paypalConfig}
          render={(props) => checkSlugsMatched()
            ? <PaypalConfigurationContainer {...props} />
            : <Redirect to='/auth-failed' />}
        />
        <Route
          path={routeConfig.emailTemplates}
          render={(props) => checkSlugsMatched()
            ? <TemplateContainer {...props} />
            : <Redirect to='/auth-failed' />}
        />
        <Route
          path={routeConfig.chargebackMailBatch}
          render={(props) => checkSlugsMatched()
            ? <CBMailBatchContainer {...props} />
            : <Redirect to='/auth-failed' />}
        />
        <Route
          path={routeConfig.generateSdk}
          render={(props) => checkSlugsMatched()
            ? <DashboardSdkContainer {...props} />
            : <Redirect to='/auth-failed' />}
        />
      </Switch>
    </Suspense>
  )
}
