import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  countryActions,
  countryActionsTypes,
  stateActions,
  stateActionsTypes,
  cityActions,
  cityActionsTypes
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchCountry (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.country, { params, headers:headers })
    if (json.status === 200) {
      yield put(countryActions.saveCountryResponse(json.data))
    } else {
      yield put(countryActions.saveCountryResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: countryActionsTypes.SAVE_COUNTRY_RESPONSE, data })
  }
}

function * getState (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.state, { params, headers:headers  })
    if (json.status === 200) {
      yield put(stateActions.saveStateResponse(json.data))
    } else {
      yield put(stateActions.saveStateResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: stateActionsTypes.SAVE_STATE_RESPONSE, data })
  }
}

function * getCity (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.city, { params, headers:headers })
    if (json.status === 200) {
      yield put(cityActions.saveCityResponse(json.data))
    } else {
      yield put(cityActions.saveCityResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: cityActionsTypes.SAVE_CITY_RESPONSE, data })
  }
}

export function * fetchCountryWatcher () {
  yield all([
    yield takeLatest(countryActionsTypes.GET_COUNTRY, fetchCountry),
    yield takeLatest(stateActionsTypes.GET_STATE, getState),
    yield takeLatest(cityActionsTypes.GET_CITY, getCity)
  ])
}
