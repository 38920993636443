export const addNotifyTypes = {
  REQUEST: 'ADD_NOTIFICATION_REQUEST',
  RESPONSE: 'ADD_NOTIFICATION_RESPONSE',
  ERROR: 'ADD_NOTIFICATION_ERROR',
  CLEAR: 'ADD_NOTIFICATION_CLEAR'
}

export const addNotifyActions = {
  add: (data, headers) => {
    return {
      type: addNotifyTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addNotifyTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addNotifyTypes.CLEAR
  })
}

export const notifyActionsTypes = {
  REQUEST: 'GET_NOTIFICATION',
  RESPONSE: 'SAVE_NOTIFICATION_RESPONSE',
  CLEAR: 'CLEAR_NOTIFICATION'
}

export const notifyActions = {
  get: (headers) => ({
    type: notifyActionsTypes.REQUEST,
    headers
  }),
  saveResponse: data => ({
    type: notifyActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: notifyActionsTypes.CLEAR
  })
}
