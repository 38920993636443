import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Dropzone, { useDropzone } from 'react-dropzone'
import { CSVLink } from 'react-csv'
import _ from 'lodash'
import styled from 'styled-components'
import { importAlertActions } from '../../store/actions'
import { acceteCSV, FILE_FORMAT_TYPE_CSV } from './formData/formData'
import { STATUS_RESPONSE } from '../../utils/constants'

const StyledDiv = styled.div`
  .container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-style: dashed;
      background-color: #fafafa;
      color:#9ab6d9;
      outline: none;
      transition: border .24s ease-in-out;
  }
`
const ImportAlertCase = (props) => {
  const {
    loadingIAP,
    statusIAP,
    messageIAP,
    importAlertDispatch,
    importAlertClearDispatch
  } = props
  const [errors, setErrors] = useState({})
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const paramField = query.get('bank_id')
  const [alert, setAlert] = useState({
    type: 'success',
    message: ''
  })
  const { getRootProps } = useDropzone()
  const [formData, setFormData] = useState({
    file: {},
    issuerBankId: ''
  })
  const [status, setStatus] = useState('Drag \'n\' drop some files here, or click to select files')
  const history = useHistory()

  const handleSubmit = () => {
    if (paramField && !_.isEmpty(formData.file)) {
      const data = new FormData()
      data.append('file', formData.file)
      data.append('issuerBankId', paramField)
      importAlertDispatch(data)
    } else {
      setErrors({ ...errors, file: 'Please upload the valid format.' })
    }
  }

  const csvData = [
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'Card',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      upiTransactionId: '',
      isLastSixMonths: 'TRUE',
      cardNumber: 4708549635896,
      salesTransactionDate: '2021-04-25',
      alertDescriptor: '61bc43446a98d6818084a4a3',
      salesTransactionAmount: 120,
      salesTransactionCurrency: 'USD',
      alertDescriptorContact: 9876543210,
      customerFirstName: 'Ravi',
      customerLastName: 'Varma',
      expirationMonth: 12,
      expirationYear: 2025,
      transactionId: ''
    },
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'Card',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      upiTransactionId: '',
      isLastSixMonths: '',
      cardNumber: 4125549635653,
      salesTransactionDate: '',
      alertDescriptor: '61bc43446a98d6818084a4a3',
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      alertDescriptorContact: 9876543210,
      customerFirstName: 'Raja',
      customerLastName: 'Dashing',
      expirationMonth: 8,
      expirationYear: 2022,
      transactionId: ''
    },
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'UPI',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      upiTransactionId: 380325514052396,
      isLastSixMonths: '',
      cardNumber: '',
      salesTransactionDate: '',
      alertDescriptor: 395700497843,
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      alertDescriptorContact: 9856231470,
      customerFirstName: 'Raju',
      customerLastName: 'Sundaram',
      expirationMonth: '',
      expirationYear: '',
      transactionId: ''
    },
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'UPI',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      upiTransactionId: 380325514052396,
      isLastSixMonths: '',
      cardNumber: '',
      salesTransactionDate: '',
      alertDescriptor: 395700497843,
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      alertDescriptorContact: 9856231470,
      customerFirstName: 'Raju',
      customerLastName: 'Sundaram',
      expirationMonth: '',
      expirationYear: '',
      transactionId: ''
    },
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'UPI',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      upiTransactionId: 380325514052396,
      isLastSixMonths: '',
      cardNumber: '',
      salesTransactionDate: '',
      alertDescriptor: 395700497843,
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      alertDescriptorContact: 9856231470,
      customerFirstName: 'Raju',
      customerLastName: 'Sundaram',
      expirationMonth: '',
      expirationYear: '',
      transactionId: ''
    },
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'UPI',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      upiTransactionId: 380325514052396,
      isLastSixMonths: '',
      cardNumber: '',
      salesTransactionDate: '',
      alertDescriptor: 395700497843,
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      alertDescriptorContact: 9856231470,
      customerFirstName: 'Raju',
      customerLastName: 'Sundaram',
      expirationMonth: '',
      expirationYear: '',
      transactionId: ''
    },
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'UPI',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      upiTransactionId: 380325514052396,
      isLastSixMonths: '',
      cardNumber: '',
      salesTransactionDate: '',
      alertDescriptor: 395700497843,
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      alertDescriptorContact: 9856231470,
      customerFirstName: 'Raju',
      customerLastName: 'Sundaram',
      expirationMonth: '',
      expirationYear: '',
      transactionId: ''
    },
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'UPI',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      upiTransactionId: 380325514052396,
      isLastSixMonths: '',
      cardNumber: '',
      salesTransactionDate: '',
      alertDescriptor: 395700497843,
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      alertDescriptorContact: 9856231470,
      customerFirstName: 'Raju',
      customerLastName: 'Sundaram',
      expirationMonth: '',
      expirationYear: '',
      transactionId: ''
    },
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'UPI',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      upiTransactionId: 380325514052396,
      isLastSixMonths: '',
      cardNumber: '',
      salesTransactionDate: '',
      alertDescriptor: 395700497843,
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      alertDescriptorContact: 9856231470,
      customerFirstName: 'Raju',
      customerLastName: 'Sundaram',
      expirationMonth: '',
      expirationYear: '',
      transactionId: ''
    },
    {
      alertDate: '2021-12-15T00:00:00.000Z',
      alertType: 'Inquiry',
      transactionType: 'Card',
      cardFirstSixDigits: 987456,
      cardLastFourDigits: 123,
      upiTransactionId: '',
      isLastSixMonths: '',
      cardNumber: '',
      salesTransactionDate: '',
      alertDescriptor: 395700497843,
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      alertDescriptorContact: 9486048538,
      customerFirstName: 'San',
      customerLastName: 'france',
      expirationMonth: 10,
      expirationYear: 2022,
      transactionId: 'abcd123'
    }
  ]

  const handleFileUpload = (files, name, maxSize) => {
    setErrors({ ...errors, [name]: '' })
    files.forEach((item) => {
      let isValidFileFormat = false
      const formData = new FormData()
      formData.append('type', 'inquiryAlert')
      const fileType = item && item.type
      const uploadedFileSize = item && item.size
      isValidFileFormat = _.includes(FILE_FORMAT_TYPE_CSV, fileType)
      const fileSize = Number(maxSize) * 1024 * 1024

      if (isValidFileFormat) {
        if (uploadedFileSize < fileSize) {
          formData.append('file', item)
          formData.append('type', fileType)
          setFormData((values) => ({ ...values, [name]: item }))
          setStatus(item.name)
        } else {
          const err = `File size must below ${fileSize / 1048576} MB!. You file size is : ${Math.round(uploadedFileSize / 1024)}KB`
          setErrors({ ...errors, [name]: err })
          setStatus(err)
        }
      } else {
        const err2 = 'Please upload the valid format.'
        setErrors({ ...errors, [name]: err2 })
        setStatus(err2)
      }
    })
  }

  const fileHeaders = [
    { label: 'alertDate', key: 'alertDate' },
    { label: 'alertType', key: 'alertType' },
    { label: 'transactionType', key: 'transactionType' },
    { label: 'cardFirstSixDigits', key: 'cardFirstSixDigits' },
    { label: 'cardLastFourDigits', key: 'cardLastFourDigits' },
    { label: 'upiTransactionId', key: 'upiTransactionId' },
    { label: 'isLastSixMonths', key: 'isLastSixMonths' },
    { label: 'cardNumber', key: 'cardNumber' },
    { label: 'salesTransactionDate', key: 'salesTransactionDate' },
    { label: 'alertDescriptor', key: 'alertDescriptor' },
    { label: 'salesTransactionAmount', key: 'salesTransactionAmount' },
    { label: 'salesTransactionCurrency', key: 'salesTransactionCurrency' },
    { label: 'alertDescriptorContact', key: 'alertDescriptorContact' },
    { label: 'customerFirstName', key: 'customerFirstName' },
    { label: 'customerLastName', key: 'customerLastName' },
    { label: 'expirationMonth', key: 'expirationMonth' },
    { label: 'expirationYear', key: 'expirationYear' },
    { label: 'transactionId', key: 'transactionId' }
  ]

  useEffect(() => {
    if (statusIAP === STATUS_RESPONSE.SUCCESS_MSG) {
      setAlert({ type: 'success', message: messageIAP })
      setTimeout(() => { setAlert({ type: 'success', message: '' }) }, 2000)
      setStatus('Drag \'n\' drop some files here, or click to select files')
      importAlertClearDispatch()
    } else if (statusIAP === STATUS_RESPONSE.ERROR_MSG) {
      setAlert({ type: 'danger', message: messageIAP })
      setTimeout(() => { setAlert({ type: 'success', message: '' }) }, 2000)
      importAlertClearDispatch()
    }
  }, [statusIAP])

  return (
    <div className='card-header bg-skyBlue h-100 py-10'>
      {
        alert.message
          ? (
            <div className={`alert alert-${alert.type} mb-15 mx-5`} role='alert'>
              {alert.message}
            </div>
            )
          : null
      }
      <div className='card-body'>
        <div className='d-flex justify-content-center '>
          <div className='m-3' style={{ width: 500 }}>
            <h6 className=''>Export Alerts to Chargebackzero</h6>
            <p className=''>
              Would you like to send a Batch file to Chargebackzero?
              Please browse and select the CSV file you would like to upload.
              Then click to "Export Alert" in order to upload your file.
              Please do not navigate away from the page until the file has completed loading.
            </p>
            <StyledDiv {...getRootProps({ refKey: 'innerRef' })}>
              <Dropzone onDrop={acceptedFiles => handleFileUpload(acceptedFiles, 'file', 5000)}>
                {({ getRootProps, getInputProps }) => (
                  <div className='container'>
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        name='file'
                        acceptedfiles={acceteCSV}
                      />
                      <p className='text-primary fw-bolder fs-6'>{status}</p>
                      <aside>
                        <h4 className='text-dark fw-bolder fs-5 mt-6 text-center'>Files</h4>
                      </aside>
                    </div>
                  </div>
                )}
              </Dropzone>
            </StyledDiv>
            {errors && errors.file && (<div className='rr mt-1'><style>{'.rr{color:red;}'}</style>{errors.file}</div>)}
            <div className='my-3 d-flex justify-content-center'>
              {csvData?.length &&
                <CSVLink
                  headers={fileHeaders}
                  data={csvData}
                  filename='Sample_file.csv'
                  target='_blank'
                  className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm m-1'
                >
                  Download Sample File
                </CSVLink>}
              <button className='btn btn-blue btn-sm m-1' onClick={() => { handleSubmit() }}>
                {
                  loadingIAP
                    ? (
                      <span className='spinner-border spinner-border-sm mx-5' role='status' aria-hidden='true' />
                      )
                    : 'Export Inquiry Alert'
                }
              </button>
              <button className='btn btn-darkRed btn-sm m-1' onClick={() => { history.goBack() }}>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { issuerbankAlertStore } = state
  return {
    loadingIAP: issuerbankAlertStore && issuerbankAlertStore.loadingIAP ? issuerbankAlertStore.loadingIAP : false,
    dataIAP: issuerbankAlertStore && issuerbankAlertStore.dataIAP ? issuerbankAlertStore.dataIAP : {},
    statusIAP: issuerbankAlertStore && issuerbankAlertStore.statusIAP ? issuerbankAlertStore.statusIAP : '',
    messageIAP: issuerbankAlertStore && issuerbankAlertStore.messageIAP ? issuerbankAlertStore.messageIAP : ''
  }
}

const mapDispatchToProps = dispatch => ({
  importAlertDispatch: (data, config) => dispatch(importAlertActions.importAlert(data, config)),
  importAlertClearDispatch: () => dispatch(importAlertActions.clear())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportAlertCase)
