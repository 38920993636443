export const addCrmSettingsActionsTypes = {
  REQUEST: 'ADD_CRM_SETTINGS_REQUEST',
  RESPONSE: 'ADD_CRM_SETTINGS_RESPONSE',
  ERROR: 'ADD_CRM_SETTINGS_ERROR',
  CLEAR: 'ADD_CRM_SETTINGS_CLEAR'
}

export const addCrmSettingsActions = {
  add: (data, headers) => {
    return {
      type: addCrmSettingsActionsTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addCrmSettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addCrmSettingsActionsTypes.CLEAR
  })
}

export const getCrmSettingsDetailsActionsTypes = {
  REQUEST: 'GET_CRM_DETAILS_REQUEST',
  RESPONSE: 'GET_CRM_DETAILS_RESPONSE',
  ERROR: 'GET_CRM_DETAILS_ERROR',
  CLEAR: 'GET_CRM_DETAILS_CLEAR'
}
export const getCrmSettingsDetailsActions = {
  getDetails: (id, headers) => ({
    type: getCrmSettingsDetailsActionsTypes.REQUEST,
    id, headers
  }),
  saveResponse: (data) => ({
    type: getCrmSettingsDetailsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: getCrmSettingsDetailsActionsTypes.CLEAR
  })
}

export const updateCrmSettingsActionsTypes = {
  REQUEST: 'UPDATE_CRM_SETTINGS_REQUEST',
  RESPONSE: 'UPDATE_CRM_SETTINGS_RESPONSE',
  ERROR: 'UPDATE_CRM_SETTINGS_ERROR',
  CLEAR: 'UPDATE_CRM_SETTINGS_CLEAR'
}

export const updateCrmSettingsActions = {
  update: (id, params, headers) => {
    return {
      type: updateCrmSettingsActionsTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveResponse: (data) => {
    return {
      type: updateCrmSettingsActionsTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: updateCrmSettingsActionsTypes.CLEAR
  })
}

export const deleteCrmSettingsActionsTypes = {
  REQUEST: 'DELETE_CRM_SETTINGS_REQUEST',
  RESPONSE: 'DELETE_CRM_SETTINGS_RESPONSE',
  ERROR: 'DELETE_CRM_SETTINGS_ERROR',
  CLEAR: 'DELETE_CRM_SETTINGS_CLEAR'
}

export const deleteCrmSettingsActions = {
  delete: (params, headers) => ({
    type: deleteCrmSettingsActionsTypes.REQUEST,
    params, headers
  }),
  savemResponse: (data) => ({
    type: deleteCrmSettingsActionsTypes.RESPONSE,
    data
  }),
  clear: () => ({
    type: deleteCrmSettingsActionsTypes.CLEAR
  })
}
