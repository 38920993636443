import React, { useState, useEffect } from 'react'
import { toAbsoluteUrl } from '../../../theme/helpers'
import { Link, useHistory } from "react-router-dom";
import _ from 'lodash'
import { connect } from 'react-redux'
import { STATUS_RESPONSE, REGEX } from '../../../utils/constants'
import {
  warningAlert,
  confirmAlert
} from '../../../utils/alerts'
import {
  addUserRegisterActions
} from '../../../store/actions'
import { clientSignUpValidation } from './validate/validation'

const ClientSignup = (props) => {
  const {
    loadingAUR,
    dataAUR,
    statusAUR,
    messageAUR,
    addUserDispatch,
    clearaddUserDispatch,
    siteConfigs
  } = props

  const history = useHistory()
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    cPassword: '',
    isFirstLogin: 'true'
  })

  const handleChange = (e) => {
    e.persist()
    setFormData(values => ({ ...values, [e.target.name]: e.target.value }))
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const handleSubmit = () => {
    const errorMsg = clientSignUpValidation(formData, setErrors, siteConfigs)
    if (_.isEmpty(errorMsg)) {
      addUserDispatch(formData)
    }
  }

  const onConfirm = () => {
    history.push('/client-signup')
  }

  useEffect(() => {
    if (statusAUR === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        'Success',
        messageAUR,
        'success',
        'Ok',
        () => { onConfirm() },
        () => { onConfirm() }
      )
      clearaddUserDispatch()
    } else if (statusAUR === STATUS_RESPONSE.ERROR_MSG) {
      let message = ''
      dataAUR && dataAUR.data?.map((item) => {
        message = item.message
      })
      const errorMessage = `${messageAUR ? messageAUR : ''} ${message ? _.startCase(message)  : ''}`
      warningAlert(
        'Error',
        `${errorMessage} in Password` ,
        '',
        'Ok'
      )
      clearaddUserDispatch()
    }
  }, [statusAUR])

  return (
    <>
      <div className='bg-shadow'>
        <div className='d-flex justify-content-center mt-6 pe-15'>
          <div>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-1.svg')}
              style={{ width: '253px', filter: 'drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)' }}
            />
          </div>
        </div>
        <div className='d-flex'>
          <div className='rounded shadow-sm p-5 mx-auto my-10 bg-white'>
            <h2 className="mt-7 fw-bolder text-center">Create your ChargebackZero Account</h2>
            <div className='d-flex p-8'>
              <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 p-3'>
                <div>
                  <div className='ps-10'>
                    <h3 className="text-start fw-bolder text-dark">
                      Sign up and let ChargebackZero respond to and
                    </h3>
                    <h3 className="text-start mt-1  fw-bolder text-dark">
                      win your chargebacks automatically
                    </h3>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <a href='#' className='mb-12'>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/illustrations/sigma-1/17.png')}
                        className='w-300px h-200px'
                      />
                    </a>
                  </div>
                  <div className='row mt-2 mb-4'>
                    <div className='col-lg-2 d-flex justify-content-end'>
                      <span className="svg-icon svg-icon-success svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                        <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                      </svg></span>
                    </div>
                    <div className='col-lg-8'>
                      <div>
                        <h4 className='sub-header-text fw-bolder'>Get started quickly</h4>
                      </div>
                      <p className='fw-bold text-dark fs-4'>
                        Simple and transparent pricing, no contracts, no integration required - get setup in 60 seconds.
                      </p>
                    </div>
                  </div>
                  <div className='row mt-5 mb-4'>
                    <div className='col-lg-2 d-flex justify-content-end'>
                      <span className="svg-icon svg-icon-success svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                        <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                      </svg></span>
                    </div>
                    <div className='col-lg-8'>
                      <div>
                        <h4 className='sub-header-text fw-bolder'>Automatic ChargebackZero response</h4>
                      </div>
                      <p className='fw-bold text-dark fs-4'>
                        We help e-commerce merchants win and recover their chargebacks on full auto-pilot
                      </p>
                    </div>
                  </div>
                  <div className='row mt-5 mb-4'>
                    <div className='col-lg-2 d-flex justify-content-end'>
                      <span className="svg-icon svg-icon-success svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                        <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                      </svg></span>
                    </div>
                    <div className='col-lg-8'>
                      <div>
                        <h4 className='sub-header-text fw-bolder'>You are in good company</h4>
                      </div>
                      <p className='fw-bold text-dark fs-4'>
                        Merchants around the world, just like you trust ChargebackZero to respond to and win their chargebacks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 p-3'>
                <div>
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <label className="font-size-xs fw-bolder mb-2 required form-label mb-2">First Name</label>
                    <input
                      name='firstName'
                      type='text'
                      className='form-control'
                      placeholder='First Name'
                      value={formData.firstName || ''}
                      onChange={(e) => handleChange(e)}
                      autoComplete='off'
                      onKeyPress={(e) => {
                        if (!REGEX.TEXT.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                    {errors && errors.firstName && (
                      <div className='rr mt-1'>
                        <style>{'.rr{color:red}'}</style>
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <label className="font-size-xs fw-bolder mb-2 required form-label mb-2">Last Name</label>
                    <input
                      name='lastName'
                      type='text'
                      className='form-control'
                      placeholder='Last Name'
                      value={formData.lastName || ''}
                      onChange={(e) => handleChange(e)}
                      autoComplete='off'
                      onKeyPress={(e) => {
                        if (!REGEX.TEXT.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                    {errors && errors.lastName && (
                      <div className='rr mt-1'>
                        <style>{'.rr{color:red}'}</style>
                        {errors.lastName}
                      </div>
                    )}
                  </div>
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <label className="font-size-xs fw-bolder mb-2 required form-label mb-2">Email</label>
                    <input
                      name='email'
                      type='text'
                      className='form-control'
                      placeholder='Email'
                      value={formData.email || ''}
                      onChange={(e) => handleChange(e)}
                      autoComplete='off'
                    />
                    {errors && errors.email && (
                      <div className='rr mt-1'>
                        <style>{'.rr{color:red}'}</style>
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <label className="font-size-xs fw-bolder mb-2 required form-label mb-2">Password</label>
                    <input
                      name='password'
                      type='password'
                      className='form-control'
                      placeholder='Password'
                      value={formData.password || ''}
                      onChange={(e) => handleChange(e)}
                      autoComplete='off'
                    />
                    {errors && errors.password && (
                      <div className='rr mt-1'>
                        <style>{'.rr{color:red}'}</style>
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <label className="font-size-xs fw-bolder mb-2 required form-label">Confirm Password</label>
                    <input
                      name='cPassword'
                      type='password'
                      className='form-control'
                      id='basic-url'
                      placeholder='Confirm Password'
                      aria-describedby='basic-addon3'
                      onChange={(e) => handleChange(e)}
                      value={formData.cPassword || ''}
                      autoComplete='off'
                    />
                    {errors && errors.cPassword && (
                      <div className='rr mt-1'>
                        <style>{'.rr{color:red}'}</style>
                        {errors.cPassword}
                      </div>
                    )}
                  </div>
                  <div className='text-center mt-15'>
                    <button
                      className='btn btn-primary w-90 mb-5 fw-bolder'
                      onClick={(event) => {
                        handleSubmit(event)
                      }}
                    >
                      {loadingAUR
                        ? (
                          <span
                            className='spinner-border spinner-border-sm mx-3'
                            role='status'
                            aria-hidden='true'
                          />
                        )
                        : (
                          ' Create Account'
                        )}
                    </button>
                  </div>
                  <div className='d-flex align-items-center mb-8'>
                    <div className='border-bottom border-gray-300 mw-50 w-100' />
                    <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
                    <div className='border-bottom border-gray-300 mw-50 w-100' />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <p className='text-dark fs-4 mb-4'>
                      Already have an account?
                    </p>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Link
                      to='/'
                      className='fw-bolder btn btn-secondary '
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-1.svg')}
                style={{ width: 100, filter: 'drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4))' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    addUserRegisterStore
  } = state
  return {
    messageAUR:
      addUserRegisterStore && addUserRegisterStore.messageAUR
        ? addUserRegisterStore.messageAUR
        : 0,
    statusAUR:
      addUserRegisterStore && addUserRegisterStore.statusAUR
        ? addUserRegisterStore.statusAUR
        : {},
    dataAUR:
      addUserRegisterStore && addUserRegisterStore.dataAUR
        ? addUserRegisterStore.dataAUR
        : {},
    loadingAUR:
      addUserRegisterStore && addUserRegisterStore.loadingAUR
        ? addUserRegisterStore.loadingAUR
        : false
  }
}

const mapDispatchToProps = (dispatch) => ({
  addUserDispatch: (data) => dispatch(addUserRegisterActions.add(data)),
  clearaddUserDispatch: () => dispatch(addUserRegisterActions.clear())
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientSignup)