import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addUsertypeActions,
  addUsertypeTypes,
  fetchUserTypesActions,
  fetchUserTypeActionsTypes,
  userTypeDetailsActions,
  userTypeGetDetailsTypes,
  editUsertypeTypes,
  editUsertypeActions,
  userTypeDeleteTypes,
  deleteUsertypeActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addUsertype (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.userType, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addUsertypeActions.saveaddUsertypeResponse(json.data))
    } else {
      yield put(addUsertypeActions.clearaddUsertype([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addUsertypeTypes.RESPONSE, data })
  }
}

function * fetchUsertype (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userType, { params, headers: headers })
    if (json.status === 200) {
      yield put(fetchUserTypesActions.saveUserTypeResponse(json.data))
    } else {
      yield put(fetchUserTypesActions.saveUserTypeResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: fetchUserTypeActionsTypes.RESPONSE, data })
  }
}

function * getUsertypeDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.userType}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(userTypeDetailsActions.saveUsertypeDetailsResponse(json.data))
    } else {
      yield put(userTypeDetailsActions.saveUsertypeDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: userTypeGetDetailsTypes.USER_TYPE_DETAILS_RESPONSE, data })
  }
}

function * editUsertype (actions) {
  const { payload, headers } = actions
  const { id, data } = payload && payload
  const endPointUrl = `${serviceList.userType}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, data, { headers: headers })
    if (json.status === 200) {
      yield put(editUsertypeActions.saveeditUsertypesResponse(json.data))
    } else {
      yield put(editUsertypeActions.saveeditUsertypesResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editUsertypeTypes.RESPONSE, data })
  }
}

function * deleteUsertypeSaga (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.userType}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteUsertypeActions.savemResponseDeleteUsertype(json.data))
    } else {
      yield put(deleteUsertypeActions.savemResponseDeleteUsertype([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: userTypeDeleteTypes.SAVE_DELETE_USER_TYPE_RESPONSE, data })
  }
}

export function * fetchUsertypeWatcher () {
  yield all([
    yield takeLatest(addUsertypeTypes.REQUEST, addUsertype),
    yield takeLatest(fetchUserTypeActionsTypes.REQUEST, fetchUsertype),
    yield takeLatest(userTypeGetDetailsTypes.GET_USER_TYPE_DETAILS, getUsertypeDetails),
    yield takeLatest(editUsertypeTypes.REQUEST, editUsertype),
    yield takeLatest(userTypeDeleteTypes.DELETE_USER_TYPE, deleteUsertypeSaga)
  ])
}
