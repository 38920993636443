import React, { useEffect, useState, useRef } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap-v5";
import { toAbsoluteUrl } from "../../../theme/helpers";
import { useHistory, Link } from "react-router-dom";
import { setLocalStorage, getLocalStorage } from "../../../utils/helper";
import _ from "lodash";
import { SHOPIFY_STORAGE } from "../../../utils/constants";
import { setTypeData } from "../../../components/UserType/formData";
import { planTypeValidation } from "./validate/validation";

const ShopifySubscription = () => {
  const [planType, setPlantype] = useState([]);
  const [showAlerts, setShowalerts] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    // order: false,
    rdr: false,
    cdrn: false,
    ethoca: false,
    chg: false,
    // perchg: false,
    // hybrid: false,
  });
  const checkboxAlerts = useRef(false);

  console.log(
    getLocalStorage(SHOPIFY_STORAGE.PROCESSOR),
    " shopify storage props ",
    getLocalStorage(SHOPIFY_STORAGE.SERVICE_TYPE),
    " service type "
  );

  const history = useHistory();

  const handleShowAlerts = () => {
    if (showAlerts === true) {
      setShowalerts(false);
    } else {
      setShowalerts(true);
    }
  };

  const handleChoosePlan2 = (e) => {
    setLocalStorage(SHOPIFY_STORAGE.PLAN_TYPE, "notchosen");
    e.preventDefault();
    window.location.href = "https://chargebackzero.com/contactus.html#calendly";
  };

  const handleChangeAlerts = (e) => {
    e.persist();
    const { name, checked, value } = e.target;
    console.log(name, checked, value, " name checked value ");
    setFormData((values) => ({ ...values, [e.target.name]: e.target.checked }));
  };

  const handleChangePrevent = (e) => {
    e.persist();
    const { name, checked, value } = e.target;
    console.log(name, checked, value, " name checked value ");
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const handleChange = (name, value) => {
    console.log(name, value, " name value ");
    console.log(JSON.stringify(planType), " plantype ");
    if (!planType.includes(value)) {
      if (name === "prevention") {
        checkboxAlerts.current = true;
        console.log(checkboxAlerts);
        setFormData((values) => ({
          ...values,
          rdr: true,
          cdrn: true,
          ethoca: true,
        }));
      } else {
        setFormData((values) => ({
          ...values,
          chg: true,
        }));
      }
      console.log(name, value, " name value 1 ");
      setPlantype((prevVal) => [...prevVal, value]);
      setErrors({ ...errors, planType: "" });
    } else {
      if (name === "prevention") {
        console.log(checkboxAlerts);
        checkboxAlerts.current = false;
        console.log(checkboxAlerts);
        setFormData((values) => ({
          ...values,
          rdr: false,
          cdrn: false,
          ethoca: false,
        }));
      } else {
        setFormData((values) => ({
          ...values,
          chg: false,
        }));
      }
      console.log(name, value, " name value 2 ");
      setPlantype((prevVal) => prevVal.filter((x) => (x !== value ? x : null)));
    }
    console.log(JSON.stringify(planType), " plantype last ");
  };

  const handleSubmit = (e) => {
    const errorMsg = planTypeValidation(planType, setErrors);
    console.log(errorMsg, "  errormessage ");
    console.log(planType, "  plantype ");

    if (_.isEmpty(errorMsg)) {
      console.log("Hello good one");
      console.log(formData.chg, " chargeback ");
      //setLocalStorage(SHOPIFY_STORAGE.ALL_PLAN_TYPE, JSON.stringify(formData));
      console.log(
        getLocalStorage(SHOPIFY_STORAGE.SHOP_NAME),
        " shopify shopname  ",
        getLocalStorage(SHOPIFY_STORAGE.PROCESSOR),
        " shopify storage props ",
        getLocalStorage(SHOPIFY_STORAGE.SERVICE_TYPE),
        " service type ",
        JSON.stringify(
          getLocalStorage(SHOPIFY_STORAGE.SHP_ACCESS_TOKEN, null, 4)
        ),
        " access token "
      );

      const shop = getLocalStorage(SHOPIFY_STORAGE.SHOP_NAME);
      const shp_hmac = getLocalStorage(SHOPIFY_STORAGE.SHP_HMAC);
      const processorname = getLocalStorage(SHOPIFY_STORAGE.PROCESSOR);
      let servicetypevalue = getLocalStorage(SHOPIFY_STORAGE.SERVICE_TYPE);
      // servicetypevalue = servicetypevalue.replace(/\s/g, "");
      // servicetypevalue = servicetypevalue.replaceAll(",", "-");
      // console.log(servicetypevalue, " servicetypevalue ");
      // const serviceTypeValue = getLocalStorage(SHOPIFY_STORAGE.SERVICE_TYPE);
      const cbplantype = formData.chg ? 1 : 0;
      // console.log(cbplantype, " cbplantype ");
      const preventplantype = formData.preventcb ? formData.preventcb : "no";
      // console.log(preventplantype, " preventplanType ");
      const shp_access_token = getLocalStorage(
        SHOPIFY_STORAGE.SHP_ACCESS_TOKEN
      );
      const shp_service_url = getLocalStorage(SHOPIFY_STORAGE.SHP_SERVICE_URL);
      console.log(
        `${shp_service_url}/billing?shop=${shop}&access_token=${shp_access_token}&shp_hmac=${shp_hmac}&shp_service_url=${shp_service_url}&processor=${processorname}&servicetypevalue=${servicetypevalue}&cbplantype=${cbplantype}&preventplantype=${preventplantype}&fromportal=1`
      );
      // return false;
      e.preventDefault();
      window.location.href = `${shp_service_url}/billing?shop=${shop}&access_token=${shp_access_token}&shp_hmac=${shp_hmac}&shp_service_url=${shp_service_url}&processor=${processorname}&servicetypevalue=${servicetypevalue}&cbplantype=${cbplantype}&preventplantype=${preventplantype}&fromportal=1`;
    } else {
      e.preventDefault();
    }
  };

  return (
    <>
      {/* <body className="bg-shadow"> */}
      <div className="d-flex justify-content-center ms-10">
        <a href="https://idpms.chargebackzero.com" className="mb-12 mt-5">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/logo-1.svg")}
            style={{
              width: "253px",
              filter: "drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)",
            }}
          />
        </a>
      </div>
      <div className="row mb-10 gx-0">
        <div className="col-lg-1 col-md-1 col-sm-1 col-xl-1" />
        <div className="col-lg-10 col-md-10 col-sm-10 col-xl-10 d-flex justify-content-center">
          <div className="card shadow-sm ms-5">
            <div className="card-header mt-3 d-flex justify-content-center">
              <h2
                className="text mb-2 mt-5 fw-bolder"
                style={{ color: "rgb(25 14 70)" }}
              >
                Get Started with ChargebackZero
              </h2>
            </div>
            <Container>
              <Row className="justify-content-center">
                <Col md={6} lg={4} className="mb-4">
                  <Card
                    className="border border-primary"
                    style={{
                      minHeight: "302px",
                    }}
                  >
                    <Card.Body>
                      <h1
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "center",
                          marginLeft: "-10px",
                          color: "#009EF7",
                          whiteSpace: "nowrap",
                        }}
                      >
                        CHARGEBACK <br />
                        REPRESENTMENT
                      </h1>
                      <br />
                      <h5>
                        Success - Pay 25% per successful chargeback settled in
                        your favor
                      </h5>
                      <br />
                      <ul>
                        <li>100% Automated chargeback management</li>
                        <li>No set-up fees, monthly fees or contracts</li>
                      </ul>
                      <Button
                        className={`${
                          planType.includes("Chargeback Representment")
                            ? "btn btn-success"
                            : "btn btn-primary"
                        }`}
                        onClick={() => {
                          handleChange(
                            "chargeback",
                            "Chargeback Representment"
                          );
                        }}
                      >
                        {planType.includes("Chargeback Representment")
                          ? "Selected"
                          : "Select"}
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={4} className="mb-4 ">
                  <Card
                    className="border border-primary"
                    style={{
                      minHeight: "302px",
                    }}
                  >
                    <Card.Body>
                      <h1 style={{ color: "#009EF7" }}>PREVENT CHARGEBACKS</h1>
                      <br />
                      <h5>
                        What's the best way to fight a chargeback? Prevent it
                        from ever happening
                      </h5>
                      <br />
                      25% - 45% of chargebacks can be prevented using our
                      prevention tools, <b>TALK TO US</b>
                      <Card.Title></Card.Title>
                      {/* <Button variant="primary" onClick={handleShowAlerts}>
                        {showAlerts === true ? "Hide" : "Show"}
                      </Button> */}
                      <h6>Are you interested:</h6>
                      <div>
                        <input
                          type="radio"
                          name="preventcb"
                          value="yes"
                          onClick={handleChangePrevent}
                        />
                        &nbsp; Yes&nbsp;&nbsp;&nbsp;
                        <input
                          type="radio"
                          name="preventcb"
                          value="no"
                          onClick={handleChangePrevent}
                        />
                        &nbsp; No
                      </div>
                      {/* <Button
                        className={`${
                          planType.includes("Prevention Alerts")
                            ? "btn btn-success"
                            : "btn btn-primary"
                        }`}
                        onClick={() => {
                          handleChange("prevention", "Prevention Alerts");
                        }}
                      >
                        {planType.includes("Prevention Alerts")
                          ? "Selected"
                          : "Select"}
                      </Button> */}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={4} className="mb-4 ">
                  <Card
                    className="border border-primary"
                    style={{
                      minHeight: "302px",
                    }}
                  >
                    <Card.Body>
                      <h1
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "center",
                          marginLeft: "-10px",
                          color: "#009EF7",
                        }}
                      >
                        HOW TO PREVENT <br />
                        CHARGEBACK, <br />
                        TALK TO US
                      </h1>
                      <Card.Title>Enterprise</Card.Title>
                      <Card.Subtitle className="mb-2">
                        Contact Sales
                      </Card.Subtitle>
                      <Card.Text>
                        <ul>
                          <li>Custom API Integrations</li>
                        </ul>
                      </Card.Text>
                      <Button variant="primary" onClick={handleChoosePlan2}>
                        Contact Sales &rarr;
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
            {errors && errors.planType && (
              <div className="rr mt-5 text-center bg-light-warning py-3">
                <style>{".rr{color:black;}"}</style>
                {errors.planType}
              </div>
            )}
          </div>
        </div>
      </div>
      {showAlerts && showAlerts === true && (
        <div className="row mb-10 gx-0">
          <div className="col-lg-1 col-md-1 col-sm-1 col-xl-1" />
          <div className="col-lg-10 col-md-10 col-sm-10 col-xl-10 d-flex justify-content-center">
            <div className="toggle-text">
              <br />
              <div className="form-check form-switch">
                <input
                  className="form-check-input "
                  type="checkbox"
                  role="switch"
                  name="rdr"
                  checked={checkboxAlerts}
                  ref={checkboxAlerts}
                  onClick={handleChangeAlerts}
                />
                <label className="form-check-label" for="rdr">
                  <b>RDR </b>- Lorem Ipsum is simply dummy text of the printing
                  and typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </label>
              </div>
              <br />
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="cdrn"
                  checked={checkboxAlerts}
                  ref={checkboxAlerts}
                  onClick={handleChangeAlerts}
                />
                <label className="form-check-label" for="cdrn">
                  <b> VERIFI ALERTS (CDRN) </b>- Lorem Ipsum is simply dummy
                  text of the printing and typesetting industry. Lorem Ipsum has
                  been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled it
                  to make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged.
                </label>
              </div>
              <br />
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="ethoca"
                  checked={checkboxAlerts}
                  ref={checkboxAlerts}
                  onClick={handleChangeAlerts}
                />
                <label className="form-check-label" for="ethoca">
                  <b>ETHOCA ALERTS </b> - Lorem Ipsum is simply dummy text of
                  the printing and typesetting industry. Lorem Ipsum has been
                  the industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled it to
                  make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged.
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="form-group row mb-4 mt-15">
        <div className="text-center">
          <Link
            to={`/shopify-businessmodel`}
            className="btn btn-sm m-2 w-120px fw-bolder text-white h-40px"
            style={{ backgroundColor: "#f56c61" }}
          >
            Previous
          </Link>
          <Link
            className="btn btn-sm fw-bolder btn-primary m-2 w-120px h-40px"
            to="shopify-subscription"
            onClick={(event) => {
              handleSubmit(event);
            }}
          >
            Approve
          </Link>
        </div>
        {/* {JSON.stringify(formData, null, 4)} */}
      </div>
      {/* </body> */}
    </>
  );
};

export default ShopifySubscription;
