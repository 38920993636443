const routeConfig = {
  dashboard: "/dashboard",
  awsdashboard: "/awsdashboard",
  rootCauseAnalysis: "/rootCauseAnalysis",
  chargebackSummary: "/chargebackSummary",
  preventionAlert: "/preventionAlert",
  performanceAnalysis: "/performanceAnalysis",
  fraudAnalysis: "/fraudAnalysis",
  preventionInquiriesIntelligence: "/preventionInquiriesIntelligence",
  forgotPassword: "/forgotPassword",
  registration: "/registration",
  logout: "/session-expired",
  authFailed: "/auth-failed",
  changePassword: "/change-password",
  userProfile: "/user-profile",
  userChangePassword: "/user-change-password",
  authorizationFailed: "/authorization-failed",
  clientManagement: "/client-management",
  userRole: "/user-role",
  userType: "/user-type",
  addUserType: "/add-user-type",
  updateUserType: "/update-user-type/:id",
  addUserRole: "/add-user-role",
  userComponents: "/user-components",
  addUserComponents: "/add-user-components",
  updateUserComponents: "/update-user-components/:id",
  addUserSubComponents: "/add-user-sub-components",
  updateUserSubComponents: "/update-user-sub-components/:id",
  userPrivileges: "/user-privileges",
  addUserPrivileges: "/add-user-privileges",
  updateUserPrivileges: "/update-user-privileges/:id",
  updateUserRole: "/update-user-role/:id",
  clientOnboarding: "/client-onboarding",
  updateMerchant: "/client-onboarding/update-merchant:id",
  editClientOnboarding: "/client-onboarding/update:id",
  preventionAlerts: "/prevention-alerts",
  updatePreventionAlerts: "/prevention-alerts/update/:id",
  chargebackManagement: "/chargeback-management",
  quickSight: "/quickSight",
  addChargeback: "/chargeback-management/add-chargeback",
  selectChargeback: "/chargeback-management/select-chargeback",
  updateChargeback: "/chargeback-management/update-chargeback/:id",
  orderInsight: "/order-insight",
  uploadChargeback: "/chargeback-management/upload-chargeback",
  mailHistory: "/chargeback-management/update-chargeback/:id/mail-history",
  resetPassword: "/resetPassword",
  issuerBank: "/issuer-bank-inquiries",
  issuerBankAlerts: "/issuer-bank-alerts",
  userSdk: "/issuer-bank-inquiries-sdk",
  userSdkAlerts: "/issuer-bank-alerts-sdk",
  import: "/import-inquiries",
  importAlert: "/import-alert",
  performanceSummary: "/perform-summary",
  performanceAnalyzer: "/perform-analyzer",
  rootCauseAnalyzer: "/root-analyzer",
  fraudAnalyzer: "/fraud-analyzer",
  dashboardPreventionAlert: "/dashboard-prevention-alert",
  orderIntelligence: "/order-intelligence",
  partnerManagement: "/partner-management",
  partnerOnboarding: "/partner-onboarding",
  updatePartnersClient: "/update-partners-client/:id",
  editPartnerOnboarding: "/partner-onboarding/update:id",
  updatePartnersUser: "/partners-user/update/:id",
  documentCenter: "/manage-coverletter",
  addDocument: "/manage-coverletter/add-coverletter",
  updateDocument: "/manage-coverletter/update-coverletter",
  manageReason: "/manage-reasons",
  addReason: "/manage-reasons/add-reason",
  updateReason: "/manage-reasons/update-reason",
  manageCrm: "/manage-crm",
  addManageCrm: "/manage-crm/add",
  updateManageCrm: "/manage-crm/update/:id",
  managePaymentProcessor: "/manage-payment-processor",
  addPaymentProcessor: "/manage-payment-processor/add",
  updatePaymentProcessor: "/manage-payment-processor/update/:id",
  managePaymentGateway: "/manage-payment-gateway",
  addPaymentGateway: "/manage-payment-gateway/add",
  updatePaymentGateway: "/manage-payment-gateway/update/:id",
  manageIndustry: "/manage-industry",
  addIndustry: "/manage-industry/add",
  updateIndustry: "/manage-industry/update/:id",
  manageUsers: "/user-management",
  viewUsers: "/user-management/update-user",
  manageAcquirer: "/manage-acquirer",
  addAcquirer: "/manage-acquirer/add",
  updateAcquirer: "/manage-acquirer/update/:id",
  evidence: "/chargeback/evidence/:id",
  merchantEvidence: "/chargeback/merchant/:id",
  timeout: "/session-timeout",
  siteconfiguration: "/site-configuration",
  subscriptionPackage: "/subscription-package",
  addPackage: "/subscription-package/add-package",
  updatePackage: "/subscription-package/update/:id",
  assignPartner: "/assign-partner",
  assignClient: "/assign-client",
  emailTemplate: "/client-onboarding/update-merchant/:id/email-template",
  addEmailTemplate: "/client-onboarding/update-merchant/:id/email-template/add",
  clientSignupPage: "/client-signup-page",
  redirectPage: "/client-signup",
  getStarted: "/get-started/:id",
  merchantDetails: "/select-processor",
  selectProcessor: "/select-crm",
  selectService: "/select-service",
  selectPackage: "/select-package",
  packageSubs: "/package-subscription",
  contactDetails: "/contact-details",
  emailTemplates: "/email-templates",
  addClientTemplate: "/client-onboarding/update/:id/add-template",
  paypalConfig: "/paypal-configuration",
  generateSdk: "/generate-sdk",
  chargebackMailBatch: "/chargeback-mail-batch",
  //shopifyAuth: '/auth?shop=pavilam-app.myshopify.com',
  shopifyAuth: "/auth?shop=cbztesting.myshopify.com",
  //shopifyTopLevelAuth: '/auth/toplevel?shop=pavilam-app.myshopify.com',
  //shopifyTopLevelAuth: '/auth/toplevel?shop=cbztesting.myshopify.com',
  inquiriesSdk: "/issuerbank-inquiries-sdk",
  alertsSdk: "/issuerbank-alerts-sdk",
  shopifyLogin: "/shopify-login",
  shopifySubscription: "/shopify-subscription",
  shopifyGetStarted: "/shopify-startpage",
  shopifySelectCrm: "/shopify-selectcrm",
  shopifyBusinessModel: "/shopify-businessmodel",
};

export default routeConfig;
