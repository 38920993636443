import React, { useState, useEffect } from "react";
import validate from "./validation";
import { connect } from "react-redux";
import _ from "lodash";
import color from "../../../utils/colors";
import { useHistory, Link } from "react-router-dom";
import {
  DATE,
  STATUS_RESPONSE,
  REGEX,
  currencyOptions,
  SESSION,
  API
} from "../../../utils/constants";
import { DateSelector } from "../../../theme/layout/components/DateSelector";
import ReactSelect from "../../../theme/layout/components/ReactSelect";
import {
  clientActions,
  merchantActions,
  addChargebackActions,
  countryActions,
  getFunctionalActions,
  getRootCauseActions,
  getSubFunactionalActions,
  serviceTypeActions,
  clientProcessorActions,
  ReasoncodesActions,
  midCRMActions
} from "../../../store/actions";
import { confirmAlert, warningAlert } from "../../../utils/alerts";
import {
  headersWithAuth,
  headerWithApiKey,
  getLocalStorage,
} from "../../../utils/helper";
import moment from "moment";

function AddForms(props) {
  const {
    addChargebackDispatch,
    clearAddChargebackDispatch,
    statusAC,
    messageAC,
    loadingAC,
    getClientDispatch,
    getClient,
    getMerchantDispatch,
    getMerchant,
    getCountryDispatch,
    getCountrys,
    getReasonCodeDispatch,
    reasonCode,
    getFunctionalDispatch,
    getFunctional,
    getRootCauseDispatch,
    getRootCause,
    getSubFuncDispatch,
    getSubFunctional,
    getServicetypeDispatch,
    getServicetype,
    getProcessorDispatch,
    clientProcessorData,
    loadingGRC,
    getMIDCodeDispatch,
    getmidCRM,
    loadingClient,
    loadingMerchant,
    loadingMID,
    loadingProcessor,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const redirectUrl = getLocalStorage(API.API_KEY)
  const history = useHistory();
  const [selectedClientOption, setSelectedClientOption] = useState("");
  const [clientOption, setClientOption] = useState();
  const [selectedMerchantOption, setSelectedMerchantOption] = useState("");
  const [merchantOption, setMerchantOption] = useState();
  const [errors, setErrors] = useState({});
  const [countryOption, setCountryOption] = useState();
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [cardCountryOption, setCardCountryOption] = useState();
  const [selectedCardCountryOption, setSelectedCardCountryOption] =
    useState("");
  const [functionalOption, setFunctionalOption] = useState();
  const [selectedFunctionalOption, setSelectedFunctionalOption] = useState("");
  const [rootCauseOption, setRootCauseOption] = useState();
  const [selectedRootCauseOption, setSelectedRootCauseOption] = useState("");
  const [subFuncOption, setsubFuncOption] = useState();
  const [selectedsubFuncOption, setSelectedsubFuncOption] = useState("");
  const [serviceOption, setServiceOption] = useState();
  const [selectedServiceOption, setSelectedServiceOption] = useState("");
  const [processorOption, setProcessorOption] = useState();
  const [selectedProcessorOption, setSelectedProcessorOption] = useState("");
  const [midOption, setMIDOption] = useState();
  const [selectedMIDOption, setSelectedMIDOption] = useState("");
  const [authCodeOption, setAuthCodeOption] = useState();
  const [selectedAuthCodeOption, setSelectedAuthCodeOption] = useState("");
  const [cbReasonOption, setCBReasonOption] = useState();
  const [selectedCBReasonOption, setSelectedCBReasonOption] = useState("");
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [, setAuthCodeLabel] = useState("");
  const [Iperror, setIpError] = useState('')
  const [formData, setFormData] = useState({
    acquirerName: "",
    affiliateId: "",
    arn: "",
    authCode: "",
    avs: "",
    blacklisted: "",
    cardCountry: "",
    upiId: "",
    cardFirstSix: "",
    cardLastFour: "",
    cardNetwork: "",
    cardType: "",
    caseId: "",
    chargebackAmount: "",
    chargebackCurrency: "",
    chargebackDate: "",
    chargebackReason: "",
    chargeBackType: "",
    clientId: "",
    companyName: "",
    country: "",
    customerEmail: "",
    customerIP: "",
    customerName: "",
    customerPhone: "",
    cvv: "",
    DBAName: "",
    deviceId: "",
    disputeFiledDate: "",
    disputeMethod: "",
    functionalArea: "",
    gateway: "",
    issuer: "",
    merchantId: "",
    merchantName: "",
    mid: "",
    nonUs: "",
    orderId: "",
    paymentId: "",
    paymentProcessor: "",
    paymentMethod: "",
    productName: "",
    reasonForLegitimateChargeback: "",
    refundIssued: "No",
    rootCause: "",
    serviceType: "",
    saleType: "",
    subFunctionalArea: "",
    transactionAmount: "",
    transactionDate: "",
    transactionId: "",
  });

  const params = {
    skipPagination: true,
  };

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
    e.preventDefault();
    const { value, name } = e.target;
    setErrors({ ...errors, [e.target.name]: "" });
    if (name === "country") {
      if (value === "US") {
        setFormData({
          ...formData,
          [name]: value,
          nonUs: "",
        });
        setErrors({ ...errors, nonUs: "", country: "" });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleIPValidation = (e) => {
    if (!/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?$/.test(formData.customerIP)) {
      setIpError('Invalid IP Address');
    } else {
      setIpError('');
    }
  };

  const handleAuthCodeChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (
      value === "paypal" ||
      value === "visa" ||
      value === "amex" ||
      value === "master"
    ) {
      setFormData({
        ...formData,
        [name]: value,
      });
      const authParams = {
        skipPagination: true,
        cardNetwork: value,
      };
      getReasonCodeDispatch(authParams, isAuthorized ? headersWithAuth : headerWithApiKey);
      setSelectedAuthCodeOption();
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    const errorMsg = validate(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      addChargebackDispatch(
        _.pickBy(formData),
        isAuthorized ? headersWithAuth : headerWithApiKey
      );
    }
  };

  const onConfirm = () => {
    history.push(isAuthorized ? '/chargeback-management' : `/manage-chargeback?apiKey=${redirectUrl}`);
  };

  useEffect(() => {
    if (statusAC === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageAC,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearAddChargebackDispatch();
    } else if (statusAC === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageAC, "", "Ok");
    }
    clearAddChargebackDispatch();
  }, [statusAC, messageAC]);

  useEffect(() => {
    getClientDispatch(
      params,
      isAuthorized ? headersWithAuth : headerWithApiKey
    );
    getMerchantDispatch(
      params,
      isAuthorized ? headersWithAuth : headerWithApiKey
    );
    getCountryDispatch(
      params,
      isAuthorized ? headersWithAuth : headerWithApiKey
    );
    getFunctionalDispatch(
      params,
      isAuthorized ? headersWithAuth : headerWithApiKey
    );
    getRootCauseDispatch(
      params,
      isAuthorized ? headersWithAuth : headerWithApiKey
    );
    getSubFuncDispatch(
      params,
      isAuthorized ? headersWithAuth : headerWithApiKey
    );
    getServicetypeDispatch(
      params,
      isAuthorized ? headersWithAuth : headerWithApiKey
    );
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const getDefaultOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  const getDefaultCBOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item[name],
        })
      );
      return defaultOptions;
    }
  };

  const getDefaultMerchantOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  const getDefaultProcessorOptions = (data) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) => {
        if (item.paymentProcessorId && item.paymentProcessorId.processorName) {
          defaultOptions.push({
            label:
              item.paymentProcessorId && item.paymentProcessorId.processorName
                ? item.paymentProcessorId.processorName
                : "",
            value:
              item.paymentProcessorId && item.paymentProcessorId._id
                ? item.paymentProcessorId._id
                : "",
          });
        }
      });
      return defaultOptions;
    }
  };

  const getDefaultAuthCodeOptions = (data) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) => {
        if (formData.cardNetwork === "paypal") {
          if (item.paypalCode) {
            defaultOptions.push({
              label: `${item.paypalCode}`,
              value: item.paypalCode,
            });
          }
        } else if (formData.cardNetwork === "visa") {
          if (item.visaCodeOld || item.visaCodeNew) {
            defaultOptions.push({
              label: `${item.visaCodeOld} || ${item.visaCodeNew}`,
              value: `${item.visaCodeOld} ${item.visaCodeNew}`,
            });
          }
        } else if (formData.cardNetwork === "amex") {
          if (item.amexCode) {
            defaultOptions.push({
              label: `${item.amexCode}`,
              value: item.amexCode,
            });
          }
        } else if (formData.cardNetwork === "master") {
          if (item.masterCode) {
            defaultOptions.push({
              label: `${item.masterCode}`,
              value: item.masterCode,
            });
          }
        }
      });
      return defaultOptions;
    }
  };

  useEffect(() => {
    const data = getDefaultOptions(getClient && getClient.result, "company");
    setClientOption(data);
  }, [getClient]);

  useEffect(() => {
    const data = getDefaultMerchantOptions(
      getMerchant && getMerchant.result,
      "merchantName"
    );
    setMerchantOption(data);
  }, [getMerchant]);

  useEffect(() => {
    const nonUs = getDefaultOptions(getCountrys, "name");
    setCountryOption(nonUs);
  }, [getCountrys]);

  useEffect(() => {
    const cardCountry = getDefaultOptions(getCountrys, "name");
    setCardCountryOption(cardCountry);
  }, [getCountrys]);

  useEffect(() => {
    const data = getDefaultOptions(getRootCause, "rootCauseType");
    setRootCauseOption(data);
  }, [getRootCause]);

  useEffect(() => {
    const data = getDefaultOptions(getFunctional, "functionalType");
    setFunctionalOption(data);
  }, [getFunctional]);

  useEffect(() => {
    const data = getDefaultOptions(getSubFunctional, "subFunctionalType");
    setsubFuncOption(data);
  }, [getSubFunctional]);

  useEffect(() => {
    const data = getDefaultOptions(getServicetype, "serviceType");
    setServiceOption(data);
  }, [getServicetype]);

  useEffect(() => {
    const data = getDefaultProcessorOptions(
      clientProcessorData,
      "processorName"
    );
    setProcessorOption(data);
  }, [clientProcessorData]);

  useEffect(() => {
    const data = getDefaultOptions(getmidCRM && getmidCRM.data, "midNumber");
    setMIDOption(data);
  }, [getmidCRM]);

  useEffect(() => {
    const getReasonCode =
      reasonCode && reasonCode.data && reasonCode.data.result
        ? reasonCode.data.result
        : [];
    const data = getDefaultAuthCodeOptions(getReasonCode);
    setAuthCodeOption(data);
  }, [reasonCode]);

  useEffect(() => {
    const getReasonCode =
      reasonCode && reasonCode.data && reasonCode.data.result
        ? reasonCode.data.result
        : [];
    const data = getDefaultCBOptions(getReasonCode, "chargebackReason");
    setCBReasonOption(data);
  }, [reasonCode]);

  const handleChangeClient = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          const merchantParams = {
            skipPagination: true,
            clientId: selectedOption.value,
          };
          getMerchantDispatch(
            merchantParams,
            isAuthorized ? headersWithAuth : headerWithApiKey
          );
          const midParams = {
            skipPagination: true,
            clientId: selectedOption.value,
          };
          getMIDCodeDispatch(
            midParams,
            isAuthorized ? headersWithAuth : headerWithApiKey
          );
          const processorParams = {
            skipPagination: true,
            clientId: selectedOption.value,
          }
          getProcessorDispatch(
            processorParams,
            isAuthorized ? headersWithAuth : headerWithApiKey
          );
        }, 1500)
      );
      setSelectedClientOption(selectedOption);
      setFormData((values) => ({
        ...values,
        clientId: selectedOption.value,
        companyName: selectedOption.label,
      }));
      setErrors({ ...errors, clientId: "", companyName: "" });
    } else {
      setSelectedClientOption();
      setSelectedMerchantOption();
      setSelectedMIDOption();
      setFormData((values) => ({ ...values, clientId: "", companyName: "" }));
    }
  };

  const handleChangeMerchant = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          const midParams = {
            skipPagination: true,
            clientId: formData.clientId,
            merchantId: selectedOption.value,
          };
          getMIDCodeDispatch(
            midParams,
            isAuthorized ? headersWithAuth : headerWithApiKey
          );
          const processorParams = {
            skipPagination: true,
            merchantId: selectedOption.value,
          }
          getProcessorDispatch(
            processorParams,
            isAuthorized ? headersWithAuth : headerWithApiKey
          );
        }, 1500)
      );
      setSelectedMerchantOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantId: selectedOption.value,
        merchantName: selectedOption.label,
      }));
      setErrors({ ...errors, merchantId: "", merchantName: "" });
    } else {
      setSelectedMerchantOption();
      setSelectedMIDOption();
      setFormData((values) => ({
        ...values,
        merchantId: "",
        merchantName: "",
      }));
    }
  };

  const handleChangeMID = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          const processorParams = {
            skipPagination: true,
            clientId: formData.clientId,
            merchantId: formData.merchantId,
            mid: selectedOption.value,
          };
          getProcessorDispatch(
            processorParams,
            isAuthorized ? headersWithAuth : headerWithApiKey
          );
        }, 1500)
      );
      setSelectedMIDOption(selectedOption);
      setFormData((values) => ({ ...values, mid: selectedOption.value }));
      setErrors({ ...errors, mid: "" });
    } else {
      setSelectedMIDOption();
      setSelectedProcessorOption();
      setFormData((values) => ({ ...values, mid: "" }));
    }
  };

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCountryOption(selectedOption);
      setFormData((values) => ({ ...values, nonUs: selectedOption.value }));
      setErrors({ ...errors, nonUs: "" });
    } else {
      setSelectedCountryOption();
      setFormData((values) => ({ ...values, nonUs: "" }));
    }
  };

  const handleChangeCardCountry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCardCountryOption(selectedOption);
      setFormData((values) => ({
        ...values,
        cardCountry: selectedOption.value,
      }));
      setErrors({ ...errors, cardCountry: "" });
    } else {
      setSelectedCardCountryOption();
      setFormData((values) => ({ ...values, cardCountry: "" }));
    }
  };

  const handleChangeFunctional = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedFunctionalOption(selectedOption);
      setFormData((values) => ({
        ...values,
        functionalArea: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, functionalArea: "" }));
    } else {
      setSelectedFunctionalOption();
      setFormData((values) => ({ ...values, functionalArea: "" }));
    }
  };

  const handleChangeRootCause = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedRootCauseOption(selectedOption);
      setFormData((values) => ({
        ...values,
        rootCause: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, rootCause: "" }));
    } else {
      setSelectedRootCauseOption();
      setFormData((values) => ({ ...values, rootCause: "" }));
    }
  };

  const handleChangeSubFunc = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedsubFuncOption(selectedOption);
      setFormData((values) => ({
        ...values,
        subFunctionalArea: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, subFunctionalArea: "" }));
    } else {
      setSelectedsubFuncOption();
      setFormData((values) => ({ ...values, subFunctionalArea: "" }));
    }
  };

  const handleChangeService = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedServiceOption(selectedOption);
      setFormData((values) => ({
        ...values,
        serviceType: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, serviceType: "" }));
    } else {
      setSelectedServiceOption();
      setFormData((values) => ({ ...values, serviceType: "" }));
    }
  };

  const handleChangeProcessor = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedProcessorOption(selectedOption);
      setFormData((values) => ({
        ...values,
        paymentProcessor: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, paymentProcessor: "" }));
    } else {
      setSelectedProcessorOption();
      setFormData((values) => ({ ...values, paymentProcessor: "" }));
    }
  };

  const handleChangeAuthcode = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedAuthCodeOption(selectedOption);
      setFormData((values) => ({
        ...values,
        authCode: selectedOption.value,
      }));
      setAuthCodeLabel(selectedOption.label);
      setSelectedCBReasonOption();
      if (formData.cardNetwork === "paypal") {
        const authParams = {
          skipPagination: true,
          cardNetwork: formData.cardNetwork,
          authCode: selectedOption.value,
        };
        getReasonCodeDispatch(
          authParams,
          isAuthorized ? headersWithAuth : headerWithApiKey
        );
      }
      if (formData.cardNetwork === "visa") {
        const visaParams = {
          skipPagination: true,
          cardNetwork: formData.cardNetwork,
          authCode: selectedOption.value,
        };
        getReasonCodeDispatch(
          visaParams,
          isAuthorized ? headersWithAuth : headerWithApiKey
        );
      }
      if (formData.cardNetwork === "amex") {
        const amexParams = {
          skipPagination: true,
          cardNetwork: formData.cardNetwork,
          authCode: selectedOption.value,
        };
        getReasonCodeDispatch(
          amexParams,
          isAuthorized ? headersWithAuth : headerWithApiKey
        );
      }
      if (formData.cardNetwork === "master") {
        const masterParams = {
          skipPagination: true,
          cardNetwork: formData.cardNetwork,
          authCode: selectedOption.value,
        };
        getReasonCodeDispatch(
          masterParams,
          isAuthorized ? headersWithAuth : headerWithApiKey
        );
      }
      setErrors((values) => ({ ...values, authCode: "" }));
    } else {
      setSelectedAuthCodeOption();
      setFormData((values) => ({ ...values, authCode: "" }));
    }
  };

  const handleChangeCBReason = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCBReasonOption(selectedOption);
      setFormData((values) => ({
        ...values,
        chargebackReason: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, chargebackReason: "" }));
    } else {
      setSelectedCBReasonOption();
      setFormData((values) => ({ ...values, chargebackReason: "" }));
    }
  };

  const handleChangeCurrency = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCurrencyOption(selectedOption);
      setFormData((values) => ({
        ...values,
        chargebackCurrency: selectedOption.value,
      }));
    } else {
      setSelectedCurrencyOption();
      setFormData((values) => ({ ...values, chargebackCurrency: "" }));
    }
    setErrors({ ...errors, chargebackCurrency: "" });
  };

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  return (
    <>
      <div className="card-header bg-skyBlue py-10">
        <div className="card-body m-8">
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Affiliate Id:
              </label>
              <div className="col-lg-11">
                <input
                  name="affiliateId"
                  type="text"
                  className="form-control"
                  placeholder="Affiliate Id"
                  onChange={(e) => handleChange(e)}
                  value={formData.affiliateId || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  maxLength={15}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">ARN:</label>
              <div className="col-lg-11">
                <input
                  name="arn"
                  type="text"
                  className="form-control"
                  placeholder="ARN"
                  onChange={(e) => handleChange(e)}
                  value={formData.arn || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  maxLength={15}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                AVS Match:
              </label>
              <div className="col-lg-11">
                <select
                  name="avs"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleChange(e)}
                  value={formData.avs || ""}
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Blacklisted:
              </label>
              <div className="col-lg-11">
                <select
                  name="blacklisted"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleChange(e)}
                  value={formData.blacklisted || ""}
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">DBA Name:</label>
              <div className="col-lg-11">
                <input
                  name="DBAName"
                  type="text"
                  className="form-control"
                  placeholder="DBA Name"
                  onChange={(e) => handleChange(e)}
                  value={formData.DBAName || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  maxLength={42}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.DBAName && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.DBAName}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Client:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="clientId"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeClient}
                  options={clientOption}
                  value={selectedClientOption}
                  isLoading={loadingClient}
                />
              </div>
              {errors && errors.clientId && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.clientId}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Company Name:
              </label>
              <div className="col-lg-11">
                <input
                  name="companyName"
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  onChange={(e) => handleChange(e)}
                  value={formData.companyName || ""}
                  maxLength={42}
                  autoComplete="off"
                  disabled
                />
              </div>
              {errors && errors.companyName && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.companyName}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Merchant:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="merchantId"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeMerchant}
                  options={merchantOption}
                  noOptionsMessage={() => "No Records Found!"}
                  value={selectedMerchantOption}
                  isLoading={loadingMerchant}
                />
              </div>
              {errors && errors.merchantId && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.merchantId}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Merchant Name:
              </label>
              <div className="col-lg-11">
                <input
                  name="merchantName"
                  type="text"
                  className="form-control"
                  placeholder="Merchant Name"
                  onChange={(e) => handleChange(e)}
                  value={formData.merchantName || ""}
                  maxLength={42}
                  autoComplete="off"
                  disabled
                />
              </div>
              {errors && errors.merchantName && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.merchantName}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                MID Number:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="mid"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeMID}
                  options={midOption}
                  noOptionsMessage={() => "No Records Found!"}
                  value={selectedMIDOption}
                  isLoading={loadingMID}
                />
              </div>
              {errors && errors.mid && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.mid}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Chargeback Type:
              </label>
              <div className="col-lg-11">
                <select
                  name="chargeBackType"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleChange(e)}
                  value={formData.chargeBackType || ""}
                >
                  <option value="">Select...</option>
                  <option value="Retrieval Request">Retrieval Request</option>
                  <option value="1st Chargeback">1st Chargeback</option>
                  <option value="Pre-Arbitration">Pre-Arbitration</option>
                  <option value="Arbitration">Arbitration</option>
                </select>
              </div>
              {errors && errors.chargeBackType && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.chargeBackType}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Chargeback Date
              </label>
              <div className="col-lg-11">
                <DateSelector
                  name="chargebackDate"
                  placeholder="Chargeback Date"
                  className="form-control"
                  selected={formData.chargebackDate || ""}
                  maxDate={moment().toDate()}
                  onChange={(date) => {
                    setErrors({ ...errors, chargebackDate: "" });
                    setFormData((values) => ({
                      ...values,
                      chargebackDate: date,
                    }));
                  }}
                  dateFormat={DATE.DATE_FOR_PICKER}
                />
              </div>
              {errors && errors.chargebackDate && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.chargebackDate}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Card Country:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="cardCountry"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeCardCountry}
                  options={cardCountryOption}
                  value={selectedCardCountryOption}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Chargeback Amount:
              </label>
              <div className="col-lg-11">
                <input
                  name="chargebackAmount"
                  type="text"
                  className="form-control"
                  placeholder="Chargeback Amount"
                  onChange={(e) => handleChange(e)}
                  value={formData.chargebackAmount || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!/^[0-9.]*$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.chargebackAmount && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.chargebackAmount}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Chargeback Currency:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="chargebackCurrency"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeCurrency}
                  options={currencyOptions}
                  value={selectedCurrencyOption}
                />
              </div>
              {errors && errors.chargebackCurrency && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.chargebackCurrency}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Service Type:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="serviceType"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeService}
                  options={serviceOption}
                  value={selectedServiceOption}
                />
              </div>
              {errors && errors.serviceType && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.serviceType}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Payment Id:
              </label>
              <div className="col-lg-11">
                <input
                  name="paymentId"
                  type="text"
                  className="form-control"
                  placeholder="Payment Id"
                  onChange={(e) => handleChange(e)}
                  value={formData.paymentId || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC_CHARS.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Payment Processor:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="paymentProcessor"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeProcessor}
                  options={processorOption}
                  noOptionsMessage={() => "No Records Found!"}
                  value={selectedProcessorOption}
                  isLoading={loadingProcessor}
                />
              </div>
              {errors && errors.paymentProcessor && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.paymentProcessor}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Payment Method:
              </label>
              <div className="col-lg-11">
                <input
                  name="paymentMethod"
                  type="text"
                  className="form-control"
                  placeholder="Payment Method"
                  onChange={(e) => handleChange(e)}
                  value={formData.paymentMethod || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.paymentMethod && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.paymentMethod}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Card Type:
              </label>
              <div className="col-lg-11">
                <select
                  name="cardType"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleChange(e)}
                  value={formData.cardType || ""}
                >
                  <option value="">Select...</option>
                  <option value="creditCard">Credit Card</option>
                  <option value="upiId">UPI</option>
                </select>
              </div>
              {errors && errors.cardType && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.cardType}
                </div>
              )}
            </div>
            {formData && formData.cardType === "upiId" ? (
              <div className="col-lg-4 mb-2">
                <label className="fs-xsfw-bold mb-2 form-label">UPI Id:</label>
                <div className="col-lg-11">
                  <input
                    name="upiId"
                    type="text"
                    className="form-control"
                    placeholder="UIP Id"
                    onChange={(e) => handleChange(e)}
                    value={formData.upiId || ""}
                    autoComplete="off"
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {errors && errors.upiId && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.upiId}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-2">
              <label className="fs-xsfw-bold mb-2 form-label">Card Bin:</label>
              <div className="col-lg-11">
                <input
                  name="cardFirstSix"
                  type="text"
                  className="form-control"
                  placeholder="Card Bin"
                  onChange={(e) => handleChange(e)}
                  value={formData.cardFirstSix || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  maxLength={8}
                  onKeyPress={(e) => {
                    if (!REGEX.NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Credit card Last four:
              </label>
              <div className="col-lg-11">
                <input
                  name="cardLastFour"
                  type="text"
                  className="form-control"
                  placeholder="Credit card Last four"
                  onChange={(e) => handleChange(e)}
                  value={formData.cardLastFour || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  maxLength={4}
                  onKeyPress={(e) => {
                    if (!REGEX.NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">CVV:</label>
              <div className="col-lg-11">
                <input
                  name="cvv"
                  type="text"
                  className="form-control"
                  placeholder="CVV"
                  onChange={(e) => handleChange(e)}
                  value={formData.cvv || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  maxLength={4}
                  onKeyPress={(e) => {
                    if (!REGEX.NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Card Network:
              </label>
              <div className="col-lg-11">
                <select
                  name="cardNetwork"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleAuthCodeChange(e)}
                  value={formData.cardNetwork || ""}
                >
                  <option value="">Select...</option>
                  <option value="paypal">Paypal</option>
                  <option value="amex">Amex</option>
                  <option value="visa">Visa</option>
                  <option value="master">Master Card</option>
                </select>
              </div>
              {errors && errors.cardNetwork && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.cardNetwork}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xsfw-bold mb-2 form-label">
                Authorization Code:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="authCode"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeAuthcode}
                  options={authCodeOption}
                  value={selectedAuthCodeOption}
                  isLoading={loadingGRC}
                />
              </div>
              {errors && errors.authCode && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.authCode}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xsfw-bold mb-2 form-label">
                Chargeback Reason:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="chargebackReason"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeCBReason}
                  options={cbReasonOption}
                  value={selectedCBReasonOption}
                  isLoading={loadingGRC}
                />
              </div>
              {errors && errors.chargebackReason && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.chargebackReason}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Order Id:
              </label>
              <div className="col-lg-11">
                <input
                  name="orderId"
                  type="text"
                  className="form-control"
                  placeholder="Order Id"
                  onChange={(e) => handleChange(e)}
                  value={formData.orderId || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.orderId && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.orderId}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xsfw-bold mb-2  form-label">Case Id:</label>
              <div className="col-lg-11">
                <input
                  name="caseId"
                  type="text"
                  className="form-control"
                  placeholder="Case Id"
                  onChange={(e) => handleChange(e)}
                  value={formData.caseId || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.caseId && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.caseId}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">Gateway:</label>
              <div className="col-lg-11">
                <input
                  name="gateway"
                  type="text"
                  className="form-control"
                  placeholder="Gateway"
                  onChange={(e) => handleChange(e)}
                  value={formData.gateway || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">Issuer:</label>
              <div className="col-lg-11">
                <input
                  name="issuer"
                  type="text"
                  className="form-control"
                  placeholder="Issuer"
                  onChange={(e) => handleChange(e)}
                  value={formData.issuer || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xsfw-bold mb-2 form-label">
                Product Name:
              </label>
              <div className="col-lg-11">
                <input
                  name="productName"
                  type="text"
                  className="form-control"
                  placeholder="Product Name"
                  onChange={(e) => handleChange(e)}
                  value={formData.productName || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.productName && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.productName}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Refund Issued:
              </label>
              <div className="col-lg-11">
                <select
                  name="refundIssued"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleChange(e)}
                  value={formData.refundIssued || ""}
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Transaction Amount:
              </label>
              <div className="col-lg-11">
                <input
                  name="transactionAmount"
                  type="text"
                  className="form-control"
                  placeholder="Transaction Amount"
                  onChange={(e) => handleChange(e)}
                  value={formData.transactionAmount || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!/^[0-9.]*$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.transactionAmount && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.transactionAmount}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Transaction Date:
              </label>
              <div className="col-lg-11">
                <DateSelector
                  name="transactionDate"
                  placeholder="Transaction Date"
                  className="form-control"
                  selected={formData.transactionDate || ""}
                  maxDate={moment().toDate()}
                  onChange={(date) => {
                    setErrors({ ...errors, transactionDate: "" });
                    setFormData((values) => ({
                      ...values,
                      transactionDate: date,
                    }));
                  }}
                  dateFormat={DATE.DATE_FOR_PICKER}
                />
              </div>
              {errors && errors.transactionDate && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.transactionDate}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 required form-label">
                Transaction Number:
              </label>
              <div className="col-lg-11">
                <input
                  name="transactionId"
                  type="text"
                  className="form-control"
                  placeholder="Transaction Number"
                  onChange={(e) => handleChange(e)}
                  value={formData.transactionId || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  maxLength={15}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.transactionId && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.transactionId}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Customer Name:
              </label>
              <div className="col-lg-11">
                <input
                  name="customerName"
                  type="text"
                  className="form-control"
                  placeholder="Customer Name"
                  onChange={(e) => handleChange(e)}
                  value={formData.customerName || ""}
                  maxLength={42}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.TEXT.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.customerName && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.customerName}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Customer Email:
              </label>
              <div className="col-lg-11">
                <input
                  name="customerEmail"
                  type="text"
                  className="form-control"
                  placeholder="Customer Email"
                  onChange={(e) => handleChange(e)}
                  value={formData.customerEmail || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  maxLength={42}
                />
              </div>
              {errors && errors.customerEmail && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.customerEmail}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Customer Phone:
              </label>
              <div className="col-lg-11">
                <input
                  name="customerPhone"
                  type="text"
                  className="form-control"
                  placeholder="Customer Phone"
                  onChange={(e) => handleChange(e)}
                  value={formData.customerPhone || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  maxLength={10}
                  onKeyPress={(e) => {
                    if (!REGEX.NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {errors && errors.customerPhone && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.customerPhone}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Customer IP:
              </label>
              <div className="col-lg-11">
                <input
                  name="customerIP"
                  type="text"
                  className="form-control"
                  placeholder="Customer IP"
                  onChange={(e) => handleChange(e)}
                  value={formData.customerIP || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyUp={handleIPValidation}
                />
                {Iperror && <div  className='error-message rr mt-1'><style>{'.rr{color:red;}'}</style>{Iperror}</div>}
              </div>
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Device Id:
              </label>
              <div className="col-lg-11">
                <input
                  name="deviceId"
                  type="text"
                  className="form-control"
                  placeholder="Device Id"
                  onChange={(e) => handleChange(e)}
                  value={formData.deviceId || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Dispute Filed Date:
              </label>
              <div className="col-lg-11">
                <DateSelector
                  name="disputeFiledDate"
                  placeholder="Dispute Filed Date"
                  className="form-control"
                  selected={formData.disputeFiledDate || ""}
                  maxDate={moment().toDate()}
                  onChange={(date) => {
                    setErrors({ ...errors, disputeFiledDate: "" });
                    setFormData((values) => ({
                      ...values,
                      disputeFiledDate: date,
                    }));
                  }}
                  dateFormat={DATE.DATE_FOR_PICKER}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">
                Dispute Method:
              </label>
              <div className="col-lg-11">
                <input
                  name="disputeMethod"
                  type="text"
                  className="form-control"
                  placeholder="Dispute Method"
                  onChange={(e) => handleChange(e)}
                  value={formData.disputeMethod || ""}
                  autoComplete="off"
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                />
              </div>
              {errors && errors.disputeMethod && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.disputeMethod}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <label className="fs-xs fw-bold mb-2 form-label">Country:</label>
              <div className="col-lg-11">
                <select
                  name="country"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleChange(e)}
                  value={formData.country || ""}
                >
                  <option value="">Select...</option>
                  <option value="US">US</option>
                  <option value="nonUs">NON_US</option>
                </select>
              </div>
            </div>
            {formData && formData.country === "nonUs" ? (
              <div className="col-lg-4 mb-3">
                <label className="fs-xs fw-bold mb-2 required form-label">
                  Non US Countries:
                </label>
                <div className="col-lg-11">
                  <ReactSelect
                    isClearable
                    styles={customStyles}
                    isMulti={false}
                    name="nonUs"
                    className="basic-single"
                    classNamePrefix="select"
                    handleChangeReactSelect={handleChangeCountry}
                    options={countryOption}
                    value={selectedCountryOption}
                  />
                </div>
                {errors && errors.nonUs && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.nonUs}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Functional Area:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="functionalArea"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeFunctional}
                  options={functionalOption}
                  value={selectedFunctionalOption}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Sub Functional Area:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="subFunctionalArea"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeSubFunc}
                  options={subFuncOption}
                  value={selectedsubFuncOption}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Root Cause:
              </label>
              <div className="col-lg-11">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="rootCause"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeRootCause}
                  options={rootCauseOption}
                  value={selectedRootCauseOption}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-6">
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Sale Type:
              </label>
              <div className="col-lg-11">
                <select
                  name="saleType"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleChange(e)}
                  value={formData.saleType || ""}
                >
                  <option value="">Select</option>
                  <option value="trialSale">Trial Sale</option>
                  <option value="firstRecurringDate">
                    First Recurring Sale
                  </option>
                  <option value="straightSale">Straight Sale</option>
                  <option value="subssequentRecurring">
                    Subsequent Recurring
                  </option>
                  <option value="notFought">Not Fought</option>
                  <option value="parentOrder">Parent Order</option>
                  <option value="notFoughtTrialSale">
                    Not Fought - Trial Sale
                  </option>
                  <option value="notFoughtFirstRecurringOrder">
                    Not Fought - First Recurring Order
                  </option>
                  <option value="notFoughtSubsequentRecurringOrder">
                    Not Fought - Subsequent Recurring Order
                  </option>
                  <option value="notFoughtParentOrder">
                    Not Fought - Parent Order
                  </option>
                  <option value="notFoughtStraightSale">
                    Not Fought - Straight Sale
                  </option>
                </select>
              </div>
              {errors && errors.saleType && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.saleType}
                </div>
              )}
            </div>
            <div className="col-lg-4 mb-3">
              <label className="fs-xs fw-bold mb-2 form-label">
                Reason for Legitimate Chargeback:
              </label>
              <div className="col-lg-11">
                <select
                  name="reasonForLegitimateChargeback"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleChange(e)}
                  value={formData.reasonForLegitimateChargeback || ""}
                >
                  <option value="">Select</option>
                  <option value="clientRuleSet">Client ruleset</option>
                  <option value="disputeExpired">Dispute expired</option>
                  <option value="lackOfEvidence">Lack of evidence</option>
                  <option value="legacyDisputes">Legacy disputes</option>
                  <option value="lowNoRecoveryRates">
                    Low/No recovery rate
                  </option>
                  <option value="merchantError">Merchant error</option>
                  <option value="other">Other</option>
                  <option value="representedPrior">Represented prior</option>
                  <option value="reversalCase">Reversal case</option>
                </select>
              </div>
              {errors && errors.reasonForLegitimateChargeback && (
                <div className="rr mt-1">
                  <style>{".rr{color:red;}"}</style>
                  {errors.reasonForLegitimateChargeback}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <div className="col-lg-11">
                <button
                  className="btn btn-blue m-2 fa-pull-right"
                  onClick={(event) => {
                    handleSubmit();
                  }}
                >
                  {loadingAC ? (
                    <span
                      className="spinner-border spinner-border-sm mx-3"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <Link
                  to="/chargeback-management/select-chargeback"
                  className="btn btn-darkRed m-2 fa-pull-right"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    rootCauseStore,
    functionalStore,
    subFunctionalStore,
    serviceTypeStore,
    clientProcessorStore,
    midCRMStore,
  } = state;
  return {
    getRootCause:
      rootCauseStore && rootCauseStore.getRootCause
        ? rootCauseStore.getRootCause
        : {},
    getFunctional:
      functionalStore && functionalStore.getFunctional
        ? functionalStore.getFunctional
        : {},
    getSubFunctional:
      subFunctionalStore && subFunctionalStore.getSubFunctional
        ? subFunctionalStore.getSubFunctional
        : {},
    getServicetype:
      serviceTypeStore && serviceTypeStore.getServicetype
        ? serviceTypeStore.getServicetype
        : {},
    clientProcessorData:
      clientProcessorStore && clientProcessorStore.clientProcessorData
        ? clientProcessorStore.clientProcessorData
        : {},
    loadingProcessor:
      state &&
      state.clientProcessorStore &&
      state.clientProcessorStore.clientProcessorLoading,
    getmidCRM:
      midCRMStore && midCRMStore.getmidCRM ? midCRMStore.getmidCRM : {},
    loadingMID: state && state.midCRMStore && state.midCRMStore.loading,
    loadingGRC:
      state && state.ReasoncodeStore && state.ReasoncodeStore.loadingGRC,
    reasonCode:
      state && state.ReasoncodeStore && state.ReasoncodeStore.reasonCode,
    getCountrys: state && state.countryStore && state.countryStore.getCountrys,
    loading: state && state.countryStore && state.countryStore.loading,
    getClient: state && state.clientStore && state.clientStore.getClient,
    loadingClient: state && state.clientStore && state.clientStore.loading,
    getMerchant:
      state && state.merchantStore && state.merchantStore.getMerchant,
    loadingMerchant:
      state && state.merchantStore && state.merchantStore.loading,
    loadingAC:
      state && state.addChargebackStore && state.addChargebackStore.loadingAC,
    statusAC:
      state && state.addChargebackStore && state.addChargebackStore.statusAC,
    messageAC:
      state && state.addChargebackStore && state.addChargebackStore.messageAC,
    dataAC:
      state && state.addChargebackStore && state.addChargebackStore.dataAC,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCountryDispatch: (params, headers) =>
    dispatch(countryActions.getCountrys(params, headers)),
  countryActions: (data, headers) =>
    dispatch(countryActions.getCountrys(data, headers)),
  getClientDispatch: (params, headers) =>
    dispatch(clientActions.getClient(params, headers)),
  clientActions: (data, headers) =>
    dispatch(clientActions.getClient(data, headers)),
  getMerchantDispatch: (params, headers) =>
    dispatch(merchantActions.getMerchant(params, headers)),
  merchantActions: (data, headers) =>
    dispatch(merchantActions.getMerchant(data, headers)),
  getFunctionalDispatch: (params, headers) =>
    dispatch(getFunctionalActions.get(params, headers)),
  getFunctionalActions: (data, headers) =>
    dispatch(getFunctionalActions.get(data, headers)),
  getRootCauseDispatch: (params, headers) =>
    dispatch(getRootCauseActions.get(params, headers)),
  getRootCauseActions: (data, headers) =>
    dispatch(getRootCauseActions.get(data, headers)),
  getSubFuncDispatch: (params, headers) =>
    dispatch(getSubFunactionalActions.get(params, headers)),
  getSubFunactionalActions: (data, headers) =>
    dispatch(getSubFunactionalActions.get(data, headers)),
  getServicetypeDispatch: (params, headers) =>
    dispatch(serviceTypeActions.getServicetype(params, headers)),
  serviceTypeActions: (data, headers) =>
    dispatch(serviceTypeActions.getServicetype(data, headers)),
  getProcessorDispatch: (params, headers) =>
    dispatch(clientProcessorActions.getClientProcessor(params, headers)),
  clientProcessorActions: (data, headers) =>
    dispatch(clientProcessorActions.getClientProcessor(data, headers)),
  getReasonCodeDispatch: (params, headers) =>
    dispatch(ReasoncodesActions.reasonCode(params, headers)),
  ReasoncodesActions: (data, headers) =>
    dispatch(ReasoncodesActions.reasonCode(data, headers)),
  getMIDCodeDispatch: (params, headers) =>
    dispatch(midCRMActions.getmidCRM(params, headers)),
  midCRMActions: (data, headers) =>
    dispatch(midCRMActions.getmidCRM(data, headers)),
  addChargebackDispatch: (data, headers) =>
    dispatch(addChargebackActions.addChargeback(data, headers)),
  clearAddChargebackDispatch: () =>
    dispatch(addChargebackActions.clearaddChargeback()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddForms);
