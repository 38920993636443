export const addUploadsMerchantTypes = {
  REQUEST: 'ADD_UPLOAD_MERCHANT_REQUEST',
  RESPONSE: 'ADD_UPLOAD_MERCHANT_RESPONSE',
  ERROR: 'ADD_UPLOAD_MERCHANT_ERROR',
  CLEAR: 'ADD_UPLOAD_MERCHANT_CLEAR'
}

export const addUploadsMerchantActions = {
  addUploadsMerchant: (data, url) => {
    return {
      type: addUploadsMerchantTypes.REQUEST,
      payload: data,
      url
    }
  },
  saveaddUploadsMerchantResponse: (data) => {
    return {
      type: addUploadsMerchantTypes.RESPONSE,
      data
    }
  },
  clearaddUploadsMerchant: () => ({
    type: addUploadsMerchantTypes.CLEAR
  })
}
