import {
  addProcessorSettingsActionsTypes,
  getProcessorSettingsDetailsActionsTypes,
  updateProcessorSettingsActionsTypes,
  deleteProcessorSettingsActionsTypes
} from '../actions'

export const addProcessorSettingsStoreKey = 'addProcessorSettingsStore'

export const addProcessorSettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addProcessorSettingsActionsTypes.REQUEST:
      return { ...state, loadingAddProcessor: true }
    case addProcessorSettingsActionsTypes.RESPONSE:
      return {
        ...state,
        addProcessorSettings: res.data,
        statusAddProcessor: res.status,
        messageAddProcessor: res.message,
        loadingAddProcessor: false
      }
    case addProcessorSettingsActionsTypes.CLEAR:
      return {
        ...state,
        addProcessorSettings: null,
        statusAddProcessor: '',
        messageAddProcessor: ''
      }
    default:
      return state
  }
}

export const getProcessorSettingsDetailsStoreKey = 'getProcessorSettingsDetailsStore'

export const getProcessorSettingsDetailsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case getProcessorSettingsDetailsActionsTypes.REQUEST:
      return { ...state, loadingGetDetailsProcessor: true }
    case getProcessorSettingsDetailsActionsTypes.RESPONSE:
      return {
        ...state,
        getDetailsProcessorSettings: res.data,
        statusGetDetailsProcessor: res.status,
        messageGetDetailsProcessor: res.message,
        loadingGetDetailsProcessor: false
      }
    case getProcessorSettingsDetailsActionsTypes.CLEAR:
      return {
        ...state,
        getDetailsProcessorSettings: null,
        statusGetDetailsProcessor: '',
        messageGetDetailsProcessor: ''
      }
    default:
      return state
  }
}

export const updateProcessorSettingsStoreKey = 'updateProcessorSettingsStore'

export const updateProcessorSettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case updateProcessorSettingsActionsTypes.REQUEST:
      return { ...state, loadingUpdateProcessor: true }
    case updateProcessorSettingsActionsTypes.RESPONSE:
      return {
        ...state,
        updateProcessorSettings: res.data,
        statusUpdateProcessor: res.status,
        messageUpdateProcessor: res.message,
        loadingUpdateProcessor: false
      }
    case updateProcessorSettingsActionsTypes.CLEAR:
      return {
        ...state,
        updateProcessorSettings: null,
        statusUpdateProcessor: '',
        messageUpdateProcessor: ''
      }
    default:
      return state
  }
}

export const deleteProcessorSettingsStoreKey = 'deleteProcessorSettingsStore'

export const deleteProcessorSettingsReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteProcessorSettingsActionsTypes.REQUEST:
      return { ...state, loadingDeleteProcessor: true }
    case deleteProcessorSettingsActionsTypes.RESPONSE:
      return {
        ...state,
        deleteProcessorSettings: res.data,
        statusDeleteProcessor: res.status,
        messageDeleteProcessor: res.message,
        loadingDeleteProcessor: false
      }
    case deleteProcessorSettingsActionsTypes.CLEAR:
      return {
        ...state,
        deleteProcessorSettings: null,
        statusDeleteProcessor: '',
        messageDeleteProcessor: ''
      }
    default:
      return state
  }
}
