import {
  crmActionsTypes,
  packageActionsTypes,
  listActionsTypes,
  packageGetDetailsTypes,
  editPackageTypes,
  userActionsTypes,
  addUserTypes,
  userGetDetailsTypes,
  editUserTypes,
  processorActionsTypes,
  paymentActionsTypes,
  packPaymentActionTypes,
  clientPackagesActionsTypes,
  partnerClientActionsTypes,
  addPartnersClientTypes,
  deleteUsersActionsTypes,
  clientCRMActionsTypes
} from '../actions'

export const crmInitialState = {
  list: null
}

export const crmStoreKey = 'crmStore'

export const crmReducer = (state = crmInitialState, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case crmActionsTypes.GET_CRM:
      return { ...state, loading: true }
    case crmActionsTypes.SAVE_CRM_RESPONSE:
      return { ...state, getCRM: action.data, count: res.count, loading: false }
    case crmActionsTypes.CLEAR_CRM:
      return { ...state, getCRM: null }
    default:
      return state
  }
}

export const processorStoreKey = 'processorStore'

export const procesorReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case processorActionsTypes.GET_PROCESSOR:
      return { ...state, processorLoading: true }
    case processorActionsTypes.SAVE_PROCESSOR_RESPONSE:
      return { ...state, processorData: res.data, count: res.count, processorLoading: false }
    case processorActionsTypes.CLEAR_PROCESSOR:
      return { ...state, processorData: null }
    default:
      return state
  }
}

export const paymentStoreKey = 'paymentStore'

export const paymentReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case paymentActionsTypes.GET_PAYMENT:
      return { ...state, paymentLoading: true }
    case paymentActionsTypes.SAVE_PAYMENT_RESPONSE:
      return { ...state, paymentData: res.data, count: res.count, paymentLoading: false }
    case paymentActionsTypes.CLEAR_PAYMENT:
      return { ...state, paymentData: null }
    default:
      return state
  }
}

export const packageInitialState = {
  list: null
}

export const packageStoreKey = 'packageStore'

export const packageReducer = (state = {}, action) => {
  switch (action.type) {
    case packageActionsTypes.GET_PACKAGE:
      return { ...state, loading: true }
    case packageActionsTypes.SAVE_PACKAGE_RESPONSE:
      return {
        ...state,
        getPackages: action.data && action.data.data,
        status: action.data && action.data.status,
        count: action.data && action.data.count,
        loading: false
      }
    case packageActionsTypes.CLEAR_PACKAGE:
      return { ...state, getPackages: null, status: '', count: null }

    case packageGetDetailsTypes.GET_PACKAGE_DETAILS:
      return { ...state, loadingGPD: true }
    case packageGetDetailsTypes.PACKAGE_DETAILS_RESPONSE:
      return {
        ...state,
        packageGetDetails: action.data && action.data.data,
        statusGPD: action.data && action.data.status,
        messagesGPD: action.data && action.data.message,
        loadingGPD: false
      }
    case packageGetDetailsTypes.CLEAR_PACKAGE_DETAILS:
      return { ...state, statusGPD: '', messagesGPD: '' }
    default:
      return state
  }
}

export const editPackageStoreKey = 'editPackageStore'

export const editPackageReducer = (state = {}, action) => {
  switch (action.type) {
    case editPackageTypes.REQUEST:
      return { ...state, loadingEPD: true }
    case editPackageTypes.RESPONSE:
      return { ...state, dataEPD: action.data, statusEPD: action.data.status, messageEPD: action.data.message, loadingEPD: false }
    case editPackageTypes.CLEAR:
      return { ...state, dataEPD: null, messageEPD: '', statusEPD: '' }
    default:
      return state
  }
}

export const packPaymentStoreKey = 'packPaymentStore'

export const packPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case packPaymentActionTypes.GET_PACK_PAYMENT:
      return { ...state, packPaymentLoading: true }
    case packPaymentActionTypes.SAVE_PACK_PAYMENT_RESPONSE:
      return { ...state, packPaymentData: action.data && action.data.data, packPaymentLoading: false }
    case packPaymentActionTypes.CLEAR_PACK_PAYMENT:
      return { ...state, packPaymentData: null }
    default:
      return state
  }
}

export const userInitialState = {
  list: null
}

export const userStoreKey = 'userStore'

export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case userActionsTypes.GET_USER:
      return { ...state, loading: true }
    case userActionsTypes.SAVE_USER_RESPONSE:
      return {
        ...state,
        getUser: action.data && action.data.data,
        status: action.data && action.data.status,
        count: action.data && action.data.count,
        loading: false
      }
    case userActionsTypes.CLEAR_USER:
      return { ...state, getUser: null, status: '', count: null }

    case userGetDetailsTypes.GET_USER_DETAILS:
      return { ...state, loadingGUD: true }
    case userGetDetailsTypes.USER_DETAILS_RESPONSE:
      return {
        ...state,
        userGetDetails: action.data && action.data.data,
        statusGUD: action.data && action.data.status,
        messagesGUD: action.data && action.data.message,
        loadingGUD: false
      }
    case userGetDetailsTypes.CLEAR_USER_DETAILS:
      return { ...state, statusGUD: '', messagesGUD: '' }
    default:
      return state
  }
}

export const addUserStoreKey = 'addUserStore'

export const addUserReducer = (state = {}, action) => {
  switch (action.type) {
    case addUserTypes.REQUEST:
      return { ...state, loadingAUD: true }
    case addUserTypes.RESPONSE:
      return { ...state, dataAUD: action.data, statusAUD: action.data.status, messageAUD: action.data.message, loadingAUD: false }
    case addUserTypes.CLEAR:
      return { ...state, dataAUD: null, messageAUD: '', statusAUD: '' }
    default:
      return state
  }
}

export const editUserStoreKey = 'editUserStore'

export const editUserReducer = (state = {}, action) => {
  switch (action.type) {
    case editUserTypes.REQUEST:
      return { ...state, loadingEUD: true }
    case editUserTypes.RESPONSE:
      return { ...state, dataEUD: action.data, statusEUD: action.data.status, messageEUD: action.data.message, loadingEUD: false }
    case editUserTypes.CLEAR:
      return { ...state, dataEUD: null, messageEUD: '', statusEUD: '' }
    default:
      return state
  }
}

export const deleteUsersStoreKey = 'deleteUsersStore'

export const deleteUsersReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case deleteUsersActionsTypes.REQUEST:
      return { ...state, loadingDeleteUsers: true }
    case deleteUsersActionsTypes.RESPONSE:
      return {
        ...state,
        deleteUsers: res.data,
        statusDeleteUsers: res.status,
        messageDeleteUsers: res.message,
        loadingDeleteUsers: false
      }
    case deleteUsersActionsTypes.CLEAR:
      return {
        ...state,
        deleteUsers: null,
        statusDeleteUsers: '',
        messageDeleteUsers: ''
      }
    default:
      return state
  }
}

export const clientPackagesStoreKey = 'clientPackagesStore'

export const clientPackagesReducer = (state = {}, action) => {
  switch (action.type) {
    case clientPackagesActionsTypes.GET_CLIENT_PACKAGES:
      return { ...state, clientPackagesLoading: true }
    case clientPackagesActionsTypes.SAVE_CLIENT_PACKAGES_RESPONSE:
      return { ...state, clientPackagesData: action.data && action.data.data, clientPackagesLoading: false }
    case clientPackagesActionsTypes.CLEAR_CLIENT_PACKAGES:
      return { ...state, clientPackagesData: null }
    default:
      return state
  }
}

export const partnerClientStoreKey = 'partnerClientStore'

export const partnerClientReducer = (state = {}, action) => {
  switch (action.type) {
    case partnerClientActionsTypes.GET_PARTNER_CLIENT:
      return { ...state, partnerClientLoading: true }
    case partnerClientActionsTypes.SAVE_PARTNER_CLIENT_RESPONSE:
      return { ...state, partnerClientData: action.data && action.data.data, partnerClientLoading: false }
    case partnerClientActionsTypes.CLEAR_PARTNER_CLIENT:
      return { ...state, partnerClientData: null }
    default:
      return state
  }
}

export const addPartnerClientStoreKey = 'addPartnerClientStore'

export const addPartnerClientReducer = (state = {}, action) => {
  const res = action.data ? action.data : {}
  switch (action.type) {
    case addPartnersClientTypes.REQUEST:
      return { ...state, addPartnerClientLoading: true }
    case addPartnersClientTypes.RESPONSE:
      return {
        ...state,
        messageAPClientErr: res.message,
        statusAPClient: res.status,
        messageAPClient: res.data,
        addPartnerClientLoading: false
      }
    case addPartnersClientTypes.CLEAR:
      return {
        ...state,
        addPartnerClientData: null,
        statusAPClient: '',
        messageAPClient: '',
        messageAPClientErr: ''
      }
    default:
      return state
  }
}

export const listInitialState = {
  list: null
}

export const listStoreKey = 'listStore'

export const listReducer = (state = {}, action) => {
  switch (action.type) {
    case listActionsTypes.REQUEST:
      return { ...state, loading: true }
    case listActionsTypes.RESPONSE:
      return {
        ...state,
        getList: action.data && action.data.data,
        status: action.data && action.data.status,
        count: action.data && action.data.count,
        loading: false
      }
    case listActionsTypes.CLEAR:
      return { ...state, getList: null, status: '', count: null }
    default:
      return state
  }
}
export const clientCrmInitialList = {
  list: null
}

export const clientCrmlistKey = 'clientCrmDetailStore'
export const clientcrmDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case clientCRMActionsTypes.GET_CLIENT_CRM_REQUEST:
      return { ...state, loading: true }
    case clientCRMActionsTypes.SAVE_CLIENT_CRM_RESPONSE:
      return {
        ...state,
        clientcrmDetails:action.data&&action.data.data,
        status: action.data && action.data.status,
        messages: action.data && action.data.message,
        loading: false
      }
    case clientCRMActionsTypes.CLEAR_CLIENT_CRM:
      return { ...state, status: '', messages: '' }
    default:
      return state
  }
}
