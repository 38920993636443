import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../theme/helpers";
import {
  REGEX,
  STATUS_RESPONSE,
  SWEET_ALERT_MSG,
  SAVE_CURRENT,
  SESSION,
  API,
} from "../../../../utils/constants";
import ReactSelect from "../../../../theme/layout/components/ReactSelect";
import { Modal } from "../../../../theme/layout/components/modal";
import _ from "lodash";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { getLocalStorage, headersWithAuth } from "../../../../utils/helper";
import {
  warningAlert,
  confirmAlert,
  confirmationAlert,
} from "../../../../utils/alerts";
import {
  processorActions,
  clientProcessorActions,
  editClientProcessorActions,
  deleteclientProcessorActions,
  addclientProcessorActions,
  checkProcessorActions,
} from "../../../../store/actions";
import color from "../../../../utils/colors";
import {
  processorUpdateValidation,
  addProcessorlValidation,
} from "../userDetails/validation";

const AddProcessorForm = (props) => {
  const {
    clientProcessorData,
    className,
    getProcessorDispatch,
    clientProcessorLoading,
    editClientProcessorDispatch,
    processorData,
    getClientProcessorDispatch,
    statusECP,
    messageECP,
    addclientProcessorDispatch,
    clearaddclientProcessorDispatch,
    cleareditClientProcessorDispatch,
    deleteclientProcessorDispatch,
    clearDeleteclientProcessorDispatch,
    deleteclientProcessorStatus,
    deleteclientProcessorMessage,
    statusACPR,
    messageACPR,
    currentMerchantId,
    checkProcessorDispatch,
    clearcheckProcessorDispatch,
    statusCPR,
    messageCPR,
    loadingCPR,
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };
  const currentMidID = getLocalStorage(SAVE_CURRENT.MID_ID);
  const currentClientID = getLocalStorage(SAVE_CURRENT.CLIENT_ID);
  const url = useLocation().pathname;
  const fields = url && url.split("/");
  const merchantId = fields && fields[3];
  const [showAddProcessor, setShowAddProcessor] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentId, setCurrentId] = useState();
  const [showSFTPTable, setShowSFTPTable] = useState(true);
  const [selectedProcessorOption, setSelectedProcessorOption] = useState("");
  const [processorOption, setProcessorOption] = useState();
  const [limit, setLimit] = useState(25);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [formData, setFormData] = useState({
    paymentProcessorId: "",
  });

  const [paymentProcesserDetails, setPaymentProcessorDetails] = useState({
    paymentProcessorId: "",
    processorLabel: "",
    disputeSubmissionMode: "",
    method: "",
    oAuthUrl: "",
    oAuthUserName: "",
    oAuthPassword: "",
    hostName: "",
    portNumber: "22",
    username: "",
    password: "",
    faxNumber: "",
    email: "",
    emailBcc: "",
    emailCc: "",
    apiKey: "",
    apiSecretKey: "",
    apiEndPoint: "",
    requestApiKey: "",
    requestApiSecret: "",
    requestApiEndPoint: "",
  });

  const [processorTableForm, setProcessorTableForm] = useState([]);

  const handleInputChange = (e, type) => {
    e.preventDefault();
    if (type === "setFormData") {
      setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    }
    if (type === "setPaymentProcessorDetails") {
      setPaymentProcessorDetails((values) => ({
        ...values,
        [e.target.name]: e.target.value,
      }));
    }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleAddClick = (type) => {
    if (type === "PAYMENT_PROCESSOR") {
      const errorMsg = processorUpdateValidation(formData, setErrors);
      if (_.isEmpty(errorMsg)) {
        setShowAddProcessor(true);
      }
    }
  };

  const handleAddProcessor = () => {
    if (editMode) {
      const payload = {
        paymentProcessorId: paymentProcesserDetails.paymentProcessorId,
        processorLabel: paymentProcesserDetails.processorLabel,
        method: paymentProcesserDetails.method,
        hostName: paymentProcesserDetails.hostName,
        portNumber: paymentProcesserDetails.portNumber,
        username: paymentProcesserDetails.username,
        password: paymentProcesserDetails.password,
        faxNumber: paymentProcesserDetails.faxNumber,
        email: paymentProcesserDetails.email,
        emailBcc: paymentProcesserDetails.emailBcc,
        emailCc: paymentProcesserDetails.emailCc,
        disputeSubmissionMode: paymentProcesserDetails.disputeSubmissionMode,
        oAuthUrl: paymentProcesserDetails.oAuthUrl,
        oAuthUserName: paymentProcesserDetails.oAuthUserName,
        oAuthPassword: paymentProcesserDetails.oAuthPassword,
        apiKey: paymentProcesserDetails.apiKey,
        apiSecretKey: paymentProcesserDetails.apiSecretKey,
        apiEndPoint: paymentProcesserDetails.apiEndPoint,
        requestApiKey: paymentProcesserDetails.requestApiKey,
        requestApiSecret: paymentProcesserDetails.requestApiSecret,
        requestApiEndPoint: paymentProcesserDetails.requestApiEndPoint,
      };
      editClientProcessorDispatch(
        currentId,
        payload,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    } else {
      const payload = {
        paymentProcessorId: formData.paymentProcessorId,
        processorLabel: formData.processorLabel,
        method: paymentProcesserDetails.method,
        hostName: paymentProcesserDetails.hostName,
        portNumber: paymentProcesserDetails.portNumber,
        username: paymentProcesserDetails.username,
        password: paymentProcesserDetails.password,
        faxNumber: paymentProcesserDetails.faxNumber,
        email: paymentProcesserDetails.email,
        emailBcc: paymentProcesserDetails.emailBcc,
        emailCc: paymentProcesserDetails.emailCc,
        disputeSubmissionMode: paymentProcesserDetails.disputeSubmissionMode,
        oAuthUrl: paymentProcesserDetails.oAuthUrl,
        oAuthUserName: paymentProcesserDetails.oAuthUserName,
        oAuthPassword: paymentProcesserDetails.oAuthPassword,
        apiKey: paymentProcesserDetails.apiKey,
        apiSecretKey: paymentProcesserDetails.apiSecretKey,
        apiEndPoint: paymentProcesserDetails.apiEndPoint,
        requestApiKey: paymentProcesserDetails.requestApiKey,
        requestApiSecret: paymentProcesserDetails.requestApiSecret,
        requestApiEndPoint: paymentProcesserDetails.requestApiEndPoint,
        clientId: currentClientID,
        merchantId: currentMerchantId,
        midId: currentMidID,
      };
      addclientProcessorDispatch(
        payload,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    }
    setShowSFTPTable(true);
  };

  const handleAddProcessorTable = () => {
    const errorMsg = addProcessorlValidation(
      paymentProcesserDetails,
      setErrors
    );
    if (_.isEmpty(errorMsg)) {
      handleAddProcessor();
      setSelectedProcessorOption();
      setPaymentProcessorDetails({
        hostName: "",
        disputeSubmissionMode: "",
        method: "",
        oAuthUrl: "",
        oAuthUserName: "",
        oAuthPassword: "",
        portNumber: "22",
        username: "",
        password: "",
        faxNumber: "",
        email: "",
        emailBcc: "",
        emailCc: "",
        apiKey: "",
        apiSecretKey: "",
        apiEndPoint: "",
        requestApiKey: "",
        requestApiSecret: "",
        requestApiEndPoint: "",
      });
      setFormData({ paymentProcessorId: "" });
      setShowAddProcessor(false);
      setEditMode(false);
    }
  };

  const onCloseProcessor = () => {
    setShowAddProcessor(false);
    setFormData({ paymentProcessorId: "" });
    setSelectedProcessorOption();
    setPaymentProcessorDetails({
      hostName: "",
      disputeSubmissionMode: "",
      method: "",
      oAuthUrl: "",
      oAuthUserName: "",
      oAuthPassword: "",
      portNumber: "22",
      username: "",
      password: "",
      faxNumber: "",
      email: "",
      emailBcc: "",
      emailCc: "",
      apiKey: "",
      apiSecretKey: "",
      apiEndPoint: "",
      requestApiKey: "",
      requestApiSecret: "",
      requestApiEndPoint: "",
    });
    setErrors((values) => ({
      ...values,
      hostName: "",
      disputeSubmissionMode: "",
      method: "",
      oAuthUrl: "",
      oAuthUserName: "",
      oAuthPassword: "",
      portNumber: "22",
      username: "",
      password: "",
      faxNumber: "",
      email: "",
      emailBcc: "",
      emailCc: "",
      apiKey: "",
      apiSecretKey: "",
      apiEndPoint: "",
      requestApiKey: "",
      requestApiSecret: "",
      requestApiEndPoint: "",
    }));
    setEditMode(false);
  };

  const onEditProcessorClick = (item) => {
    setCurrentId(item._id);
    setShowAddProcessor(true);
    setEditMode(true);
    setPaymentProcessorDetails({
      disputeSubmissionMode: item.disputeSubmissionMode,
      oAuthUrl: item.oAuthUrl,
      oAuthUserName: item.oAuthUserName,
      oAuthPassword: item.oAuthPassword,
      method: item.method,
      paymentProcessorId: item.paymentProcessorId,
      processorLabel: item.processorLabel,
      hostName: item.hostName,
      portNumber: item.portNumber,
      username: item.username,
      password: item.password,
      faxNumber: item.faxNumber,
      email: item.email,
      emailBcc: item.emailBcc,
      emailCc: item.emailCc,
      apiKey: item.apiKey,
      apiSecretKey: item.apiSecretKey,
      apiEndPoint: item.apiEndPoint,
      requestApiKey: item.requestApiKey,
      requestApiSecret: item.requestApiSecret,
      requestApiEndPoint: item.requestApiEndPoint,
    });
  };

  useEffect(() => {
    getProcessorDispatch(
      null,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    const params = {
      limit: limit,
      page: activePageNumber,
      merchantId: currentMerchantId,
      clientId: currentClientID,
      midId: currentMidID,
    };
    getClientProcessorDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const getDefaultProcessorOptions = (getData) => {
    const defaultOptions = [];
    if (!_.isEmpty(getData)) {
      getData &&
        getData.map((item) => {
          defaultOptions.push({
            label: `${item.processorName ? item.processorName : ""}`,
            value: item._id,
          });
        });
      return defaultOptions;
    }
  };

  const handleChangeProcessor = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedProcessorOption(selectedOption);
      setFormData((values) => ({
        ...values,
        paymentProcessorId: selectedOption.value,
      }));
      setErrors((values) => ({ ...values, paymentProcessorId: "" }));
    } else {
      setSelectedProcessorOption();
      setFormData((values) => ({ ...values, paymentProcessorId: "" }));
    }
  };

  useEffect(() => {
    const data = getDefaultProcessorOptions(processorData);
    setProcessorOption(data);
    if (!_.isEmpty(formData.paymentProcessorId)) {
      const selOption = _.filter(data, function (x) {
        if (_.includes(formData.paymentProcessorId._id, x.value)) {
          return x;
        }
      });
      setSelectedProcessorOption(selOption);
    }
  }, [processorData]);

  const onConfirm = () => {
    const params = {
      limit: limit,
      page: activePageNumber,
      merchantId: currentMerchantId,
      clientId: currentClientID,
      midId: currentMidID,
    };
    getClientProcessorDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  useEffect(() => {
    if (statusACPR === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageACPR,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearaddclientProcessorDispatch();
    } else if (statusACPR === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageACPR, "", "Ok");
      clearaddclientProcessorDispatch();
    }
  }, [statusACPR]);

  useEffect(() => {
    if (statusECP === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageECP,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      cleareditClientProcessorDispatch();
    } else if (statusECP === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageECP, "", "Ok");
      cleareditClientProcessorDispatch();
    }
  }, [statusECP]);

  const onConfirmDelete = (id) => {
    deleteclientProcessorDispatch(
      id,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const handleRecordPerPage = (e) => {
    const { value } = e.target;
    setLimit(value);
  };

  const onDeleteItem = (id) => {
    confirmationAlert(
      SWEET_ALERT_MSG.CONFIRMATION_TEXT,
      SWEET_ALERT_MSG.DELETE_PROCESSOR,
      "warning",
      "Yes",
      "No",
      () => {
        onConfirmDelete(id);
      },
      () => {}
    );
  };

  useEffect(() => {
    if (deleteclientProcessorStatus === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        deleteclientProcessorMessage,
        "success",
        "ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearDeleteclientProcessorDispatch();
    } else if (deleteclientProcessorStatus === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", deleteclientProcessorMessage, "", "Ok");
    }
    clearDeleteclientProcessorDispatch();
  }, [deleteclientProcessorStatus]);

  const handlePageClick = (event) => {
    const pageNumber = event.selected + 1;
    const params = {
      limit: limit,
      page: pageNumber,
      merchantId: currentMerchantId,
      clientId: currentClientID,
      midId: currentMidID,
    };
    setActivePageNumber(pageNumber);
    getClientProcessorDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  };

  const totalPages =
    getProcessorDispatch && getProcessorDispatch.count
      ? Math.ceil(
          parseInt(getProcessorDispatch && getProcessorDispatch.count) / limit
        )
      : 1;

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  const onAddConfirm = () => {
    handleAddClick("PAYMENT_PROCESSOR");
  };

  useEffect(() => {
    if (statusCPR === STATUS_RESPONSE.SUCCESS_MSG) {
      onAddConfirm();
      clearcheckProcessorDispatch();
    } else if (statusCPR === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageCPR, "", "Ok");
    }
    clearcheckProcessorDispatch();
  }, [statusCPR]);

  const handleCheckProcessor = () => {
    const errorMsg = processorUpdateValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      const payload = {
        merchantId: merchantId,
        clientId: currentClientID,
        midId: currentMidID,
        paymentProcessorId: formData.paymentProcessorId,
      };
      checkProcessorDispatch(
        payload,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    }
  };

  return (
    <>
      <Modal showModal={showAddProcessor} modalWidth={550}>
        <div className="">
          <div className="">
            <div className="p-5">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="me-8">Payment Processor Details</h2>
                <button
                  type="button"
                  className="btn btn-lg btn-icon btn-active-light-primary close"
                  onClick={() => {
                    onCloseProcessor();
                  }}
                >
                  {/* eslint-disable */}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon-1"
                  />
                  {/* eslint-disable */}
                </button>
              </div>
              <div className="bg-light">
                <form className="container-fixed">
                  <div className="card-body">
                    <div className="mb-0 pt-5">
                      <div className="row">
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-lg-4 mt-3">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                Method:
                              </label>
                            </div>
                            <div className="col-lg-8">
                              <select
                                name="method"
                                className="form-select form-select-solid bg-secondary "
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "setPaymentProcessorDetails"
                                  )
                                }
                                value={paymentProcesserDetails.method || ""}
                              >
                                <option value="">Select...</option>
                                <option value="API">API</option>
                                <option value="OAUTH">OAuth</option>
                              </select>
                              {errors && errors.method && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.method}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {paymentProcesserDetails &&
                        paymentProcesserDetails.method === "API" ? (
                          <>
                            <div className="mb-3">
                              <div className="row">
                                <div className="col-lg-4 mt-3">
                                  <label className="font-size-xs font-weight-bold mb-3  form-label">
                                    API End Point :
                                  </label>
                                </div>
                                <div className="col-lg-8">
                                  <input
                                    name="apiEndPoint"
                                    type="text"
                                    className="form-control bg-secondary"
                                    placeholder="API End Point"
                                    onBlur={(e) =>
                                      handleTrimWhiteSpace(
                                        e,
                                        setPaymentProcessorDetails
                                      )
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "setPaymentProcessorDetails"
                                      )
                                    }
                                    autoComplete="off"
                                    value={
                                      paymentProcesserDetails.apiEndPoint || ""
                                    }
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                          e.key
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {errors && errors.apiEndPoint && (
                                    <div className="rr mt-1">
                                      <style>{".rr{color:red}"}</style>
                                      {errors.apiEndPoint}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="row">
                                <div className="col-lg-4 mt-3">
                                  <label className="font-size-xs font-weight-bold mb-3  form-label">
                                    API Key :
                                  </label>
                                </div>
                                <div className="col-lg-8">
                                  <input
                                    name="apiKey"
                                    type="text"
                                    className="form-control bg-secondary"
                                    placeholder="API Key"
                                    onBlur={(e) =>
                                      handleTrimWhiteSpace(
                                        e,
                                        setPaymentProcessorDetails
                                      )
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "setPaymentProcessorDetails"
                                      )
                                    }
                                    autoComplete="off"
                                    value={paymentProcesserDetails.apiKey || ""}
                                    maxLength={100}
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                          e.key
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {errors && errors.apiKey && (
                                    <div className="rr mt-1">
                                      <style>{".rr{color:red}"}</style>
                                      {errors.apiKey}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="row">
                                <div className="col-lg-4 mt-3">
                                  <label className="font-size-xs font-weight-bold mb-3  form-label">
                                    API Secret Key:
                                  </label>
                                </div>
                                <div className="col-lg-8">
                                  <input
                                    name="apiSecretKey"
                                    type="text"
                                    className="form-control bg-secondary"
                                    placeholder="API Secret Key"
                                    onBlur={(e) =>
                                      handleTrimWhiteSpace(
                                        e,
                                        setPaymentProcessorDetails
                                      )
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "setPaymentProcessorDetails"
                                      )
                                    }
                                    autoComplete="off"
                                    value={
                                      paymentProcesserDetails.apiSecretKey || ""
                                    }
                                    maxLength={100}
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                          e.key
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {errors && errors.apiSecretKey && (
                                    <div className="rr mt-1">
                                      <style>{".rr{color:red}"}</style>
                                      {errors.apiSecretKey}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className="mb-3">
                          {paymentProcesserDetails &&
                          paymentProcesserDetails.method === "OAUTH" ? (
                            <>
                              <div className="row my-6">
                                <div className="col-lg-4 mt-0">
                                  <label className="font-size-xs font-weight-bold mb-3  form-label">
                                    OAuth Url:
                                  </label>
                                </div>
                                <div className="col-lg-8">
                                  <input
                                    name="oAuthUrl"
                                    type="text"
                                    className="form-control bg-secondary"
                                    placeholder="OAuth Url"
                                    onBlur={(e) =>
                                      handleTrimWhiteSpace(
                                        e,
                                        setPaymentProcessorDetails
                                      )
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "setPaymentProcessorDetails"
                                      )
                                    }
                                    autoComplete="off"
                                    value={
                                      paymentProcesserDetails.oAuthUrl || ""
                                    }
                                    maxLength={42}
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                          e.key
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {errors && errors.oAuthUrl && (
                                    <div className="rr mt-1">
                                      <style>{".rr{color:red}"}</style>
                                      {errors.oAuthUrl}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-lg-6">
                                  <label className="font-size-xs font-weight-bold mb-3  form-label">
                                    User Name :
                                  </label>
                                  <input
                                    name="oAuthUserName"
                                    type="text"
                                    className="form-control bg-secondary"
                                    placeholder="User Name"
                                    onBlur={(e) =>
                                      handleTrimWhiteSpace(
                                        e,
                                        setPaymentProcessorDetails
                                      )
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "setPaymentProcessorDetails"
                                      )
                                    }
                                    autoComplete="off"
                                    value={
                                      paymentProcesserDetails.oAuthUserName ||
                                      ""
                                    }
                                    maxLength={42}
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                          e.key
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {errors && errors.oAuthUserName && (
                                    <div className="rr mt-1">
                                      <style>{".rr{color:red}"}</style>
                                      {errors.oAuthUserName}
                                    </div>
                                  )}
                                </div>
                                <div className="col-lg-6">
                                  <label className="font-size-xs font-weight-bold mb-3  form-label">
                                    Password :
                                  </label>
                                  <input
                                    name="oAuthPassword"
                                    type="password"
                                    className="form-control bg-secondary"
                                    placeholder="Password"
                                    onBlur={(e) =>
                                      handleTrimWhiteSpace(
                                        e,
                                        setPaymentProcessorDetails
                                      )
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "setPaymentProcessorDetails"
                                      )
                                    }
                                    autoComplete="off"
                                    value={
                                      paymentProcesserDetails.oAuthPassword ||
                                      ""
                                    }
                                    maxLength={42}
                                    onKeyPress={(e) => {
                                      if (
                                        !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                          e.key
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {errors && errors.oAuthPassword && (
                                    <div className="rr mt-1">
                                      <style>{".rr{color:red}"}</style>
                                      {errors.oAuthPassword}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                        <div className="col-lg-4 mt-0">
                          <label className="font-size-xs font-weight-bold mb-3  form-label">
                            Mode of Dispute submission:
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <select
                            name="disputeSubmissionMode"
                            className="form-select form-select-solid bg-secondary "
                            onChange={(e) =>
                              handleInputChange(e, "setPaymentProcessorDetails")
                            }
                            value={
                              paymentProcesserDetails.disputeSubmissionMode ||
                              ""
                            }
                          >
                            <option value="">Select submission mode...</option>
                            <option value="SFTP">SFTP</option>
                            <option value="FAX">Fax</option>
                            <option value="EMAIL">Email</option>
                            <option value="API">API</option>
                          </select>
                          {errors && errors.disputeSubmissionMode && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.disputeSubmissionMode}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      {paymentProcesserDetails.disputeSubmissionMode ===
                      "SFTP" ? (
                        <>
                          <div className="row">
                            <div className="col-lg-8">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                Host Name :
                              </label>
                              <input
                                name="hostName"
                                type="text"
                                className="form-control bg-secondary"
                                placeholder="Host Name"
                                onBlur={(e) =>
                                  handleTrimWhiteSpace(
                                    e,
                                    setPaymentProcessorDetails
                                  )
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "setPaymentProcessorDetails"
                                  )
                                }
                                autoComplete="off"
                                value={paymentProcesserDetails.hostName || ""}
                                maxLength={42}
                                onKeyPress={(e) => {
                                  if (
                                    !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                      e.key
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors && errors.hostName && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.hostName}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                Port Number :
                              </label>
                              <input
                                name="portNumber"
                                type="text"
                                className="form-control bg-secondary"
                                placeholder="Port Number"
                                onBlur={(e) =>
                                  handleTrimWhiteSpace(
                                    e,
                                    setPaymentProcessorDetails
                                  )
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "setPaymentProcessorDetails"
                                  )
                                }
                                autoComplete="off"
                                value={paymentProcesserDetails.portNumber || ""}
                                maxLength={10}
                                onKeyPress={(e) => {
                                  if (!REGEX.NUMERIC.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors && errors.portNumber && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.portNumber}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col-lg-6">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                User Name :
                              </label>
                              <input
                                name="username"
                                type="text"
                                className="form-control bg-secondary"
                                placeholder="User Name"
                                onBlur={(e) =>
                                  handleTrimWhiteSpace(
                                    e,
                                    setPaymentProcessorDetails
                                  )
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "setPaymentProcessorDetails"
                                  )
                                }
                                autoComplete="off"
                                value={paymentProcesserDetails.username || ""}
                                maxLength={42}
                                onKeyPress={(e) => {
                                  if (
                                    !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                      e.key
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors && errors.username && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.username}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <label className="font-size-xs font-weight-bold mb-3  form-label">
                                Password :
                              </label>
                              <input
                                name="password"
                                type="password"
                                className="form-control bg-secondary"
                                placeholder="Password"
                                onBlur={(e) =>
                                  handleTrimWhiteSpace(
                                    e,
                                    setPaymentProcessorDetails
                                  )
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "setPaymentProcessorDetails"
                                  )
                                }
                                autoComplete="off"
                                value={paymentProcesserDetails.password || ""}
                                maxLength={42}
                                onKeyPress={(e) => {
                                  if (
                                    !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                      e.key
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors && errors.password && (
                                <div className="rr mt-1">
                                  <style>{".rr{color:red}"}</style>
                                  {errors.password}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : null}
                      {paymentProcesserDetails.disputeSubmissionMode ===
                      "FAX" ? (
                        <div className="row">
                          <div className="col-lg-4 mt-3">
                            <label className="font-size-xs font-weight-bold mb-3  form-label">
                              Fax Number :
                            </label>
                          </div>
                          <div className="col-lg-8">
                            <input
                              name="faxNumber"
                              type="text"
                              className="form-control bg-secondary"
                              placeholder="Fax Number"
                              onBlur={(e) =>
                                handleTrimWhiteSpace(
                                  e,
                                  setPaymentProcessorDetails
                                )
                              }
                              maxLength={10}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "setPaymentProcessorDetails"
                                )
                              }
                              autoComplete="off"
                              value={paymentProcesserDetails.faxNumber || ""}
                            />
                            {errors && errors.faxNumber && (
                              <div className="rr mt-1">
                                <style>{".rr{color:red}"}</style>
                                {errors.faxNumber}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      {paymentProcesserDetails.disputeSubmissionMode ===
                      "API" ? (
                        <div className="card-body">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  API End Point :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="requestApiEndPoint"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="API End Point"
                                  onBlur={(e) =>
                                    handleTrimWhiteSpace(
                                      e,
                                      setPaymentProcessorDetails
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={
                                    paymentProcesserDetails.requestApiEndPoint ||
                                    ""
                                  }
                                  maxLength={42}
                                  onKeyPress={(e) => {
                                    if (
                                      !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                        e.key
                                      )
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors && errors.requestApiEndPoint && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.requestApiEndPoint}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  API Key :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="requestApiKey"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="API Key"
                                  onBlur={(e) =>
                                    handleTrimWhiteSpace(
                                      e,
                                      setPaymentProcessorDetails
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={
                                    paymentProcesserDetails.requestApiKey || ""
                                  }
                                  maxLength={42}
                                  onKeyPress={(e) => {
                                    if (
                                      !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                        e.key
                                      )
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors && errors.requestApiKey && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.requestApiKey}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  API Secret Key:
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="requestApiSecret"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="API Secret Key"
                                  onBlur={(e) =>
                                    handleTrimWhiteSpace(
                                      e,
                                      setPaymentProcessorDetails
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={
                                    paymentProcesserDetails.requestApiSecret ||
                                    ""
                                  }
                                  maxLength={42}
                                  onKeyPress={(e) => {
                                    if (
                                      !REGEX.ALPHA_NUMERIC_SPECIAL_CHARS.test(
                                        e.key
                                      )
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors && errors.requestApiSecret && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.requestApiSecret}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {paymentProcesserDetails.disputeSubmissionMode ===
                      "EMAIL" ? (
                        <>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  Email :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="email"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="Email"
                                  onBlur={(e) =>
                                    handleTrimWhiteSpace(
                                      e,
                                      setPaymentProcessorDetails
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={paymentProcesserDetails.email || ""}
                                />
                                {errors && errors.email && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  BCC :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="emailBcc"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="BCC"
                                  onBlur={(e) =>
                                    handleTrimWhiteSpace(
                                      e,
                                      setPaymentProcessorDetails
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={paymentProcesserDetails.emailBcc || ""}
                                />
                                {errors && errors.emailBcc && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.emailBcc}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-lg-4 mt-3">
                                <label className="font-size-xs font-weight-bold mb-3  form-label">
                                  CC :
                                </label>
                              </div>
                              <div className="col-lg-8">
                                <input
                                  name="emailCc"
                                  type="text"
                                  className="form-control bg-secondary"
                                  placeholder="CC"
                                  onBlur={(e) =>
                                    handleTrimWhiteSpace(
                                      e,
                                      setPaymentProcessorDetails
                                    )
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "setPaymentProcessorDetails"
                                    )
                                  }
                                  autoComplete="off"
                                  value={paymentProcesserDetails.emailCc || ""}
                                />
                                {errors && errors.emailCc && (
                                  <div className="rr mt-1">
                                    <style>{".rr{color:red}"}</style>
                                    {errors.emailCc}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row pb-2">
                    <div className="col-lg-6" />
                    <div className="col-lg-6">
                      <div className="col-lg-11">
                        <button
                          type="button"
                          className="btn btn-sm btn-green m-2 fa-pull-right"
                          onClick={() => {
                            handleAddProcessorTable();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="card-header bg-skyBlue py-10">
        <div className="card-body mt-5 m-8">
          <h2 className="mb-5">Payment Processor</h2>
          <div className="form-group row mb-2">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="d-flex justify-content-start my-auto">
                    <div className="col-lg-5 pr-3 me-3">
                      <ReactSelect
                        isClearable
                        styles={customStyles}
                        isMulti={false}
                        name="crmId"
                        className="basic-single"
                        classNamePrefix="select"
                        handleChangeReactSelect={handleChangeProcessor}
                        options={processorOption}
                        value={selectedProcessorOption}
                      />
                      {errors && errors.paymentProcessorId && (
                        <div className="rr mt-1">
                          <style>{".rr{color:red}"}</style>
                          {errors.paymentProcessorId}
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        className="btn btn-green mb-3 py-2"
                        onClick={() => {
                          handleCheckProcessor();
                        }}
                      >
                        {loadingCPR ? (
                          <span
                            className="spinner-border spinner-border-sm mx-3"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showSFTPTable ? (
            <>
              <div className="w-100 d-flex justify-content-start mt-2">
                <div className={`card ${className}`}>
                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <div className="col-md-7 d-flex">
                        <label className="col-form-label text-lg-start mt-3 ms-3">
                          Record(s) per Page : &nbsp;{" "}
                        </label>
                        <div className="col-md-3 mt-3">
                          <select
                            className="form-select w-6rem"
                            data-control="select"
                            data-placeholder="Select an option"
                            data-allow-clear="true"
                            onChange={(e) => handleRecordPerPage(e)}
                          >
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>

                      <table className="table table-hover table-rounded table-striped border w-100 gs-2 mt-2">
                        <thead>
                          <tr className="fw-bold fs-6 text-gray-800">
                            <th className="min-w-80px text-start">
                              <div className="d-flex">
                                <span>S.No</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start ps-6">
                              Action
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>Payment Processor</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>Submission mode</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>API End Point</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>API Key</span>
                              </div>
                            </th>
                            <th className="min-w-200px text-start">
                              <div className="d-flex">
                                <span>API Secret</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientProcessorLoading ? (
                            <tr>
                              <td colSpan="6" className="text-center">
                                <div
                                  className="spinner-border text-primary m-5"
                                  role="status"
                                />
                              </td>
                            </tr>
                          ) : clientProcessorData &&
                            clientProcessorData.length > 0 ? (
                            clientProcessorData.map((item, i) => {
                              return (
                                <tr
                                  key={i}
                                  style={
                                    i === 0
                                      ? { borderColor: "black" }
                                      : { borderColor: "white" }
                                  }
                                >
                                  <td className="pb-0 pt-5  text-start">
                                    {i + 1}
                                  </td>
                                  <td className="pb-0 pt-3 text-start">
                                    <div className="my-auto d-flex">
                                      <button
                                        className="btn btn-icon btn-bg-light btn-icon-warning btn-active-color-warning btn-sm"
                                        onClick={() => {
                                          onEditProcessorClick(item);
                                        }}
                                      >
                                        {/* eslint-disable */}
                                        <KTSVG
                                          path="/media/icons/duotune/art/art005.svg"
                                          className="svg-icon-3"
                                        />
                                        {/* eslint-enable */}
                                      </button>
                                      <button
                                        className="btn btn-icon btn-bg-light btn-icon-danger btn-active-color-danger btn-sm"
                                        onClick={() => {
                                          onDeleteItem(item._id);
                                        }}
                                      >
                                        {/* eslint-disable */}
                                        <KTSVG
                                          path="/media/icons/duotune/general/gen027.svg"
                                          className="svg-icon-3"
                                        />
                                        {/* eslint-enable */}
                                      </button>
                                    </div>
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    {item.paymentProcessorId &&
                                      item.paymentProcessorId &&
                                      item.paymentProcessorId.processorName}
                                  </td>
                                  <td className="pb-0 pt-5 text-start">
                                    {item.disputeSubmissionMode}
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    {item.apiEndPoint}
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    {item.apiKey}
                                  </td>
                                  <td className="pb-0 pt-5  text-start">
                                    {item.apiSecretKey}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="text-center py-3">
                              <td colSpan="100%">No record(s) found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group row mb-4 mt-6">
                      <div className="col-lg-12 mb-4 align-items-end d-flex">
                        <div className="col-lg-12 pagination">
                          <ReactPaginate
                            nextLabel="Next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={totalPages}
                            previousLabel="< Prev"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  processorLoading:
    state && state.processorStore && state.processorStore.processorLoading,
  processorData:
    state && state.processorStore && state.processorStore.processorData,
  clientProcessorLoading:
    state &&
    state.clientProcessorStore &&
    state.clientProcessorStore.clientProcessorLoading,
  clientProcessorData:
    state &&
    state.clientProcessorStore &&
    state.clientProcessorStore.clientProcessorData,
  statusECP:
    state &&
    state.editClientProcessorStore &&
    state.editClientProcessorStore.statusECP,
  messageECP:
    state &&
    state.editClientProcessorStore &&
    state.editClientProcessorStore.messageECP,
  loadingECP:
    state &&
    state.editClientProcessorStore &&
    state.editClientProcessorStore.loadingECP,
  statusACPR:
    state &&
    state.addclientProcessorStore &&
    state.addclientProcessorStore.statusACPR,
  messageACPR:
    state &&
    state.addclientProcessorStore &&
    state.addclientProcessorStore.messageACPR,
  loadingACPR:
    state &&
    state.addclientProcessorStore &&
    state.addclientProcessorStore.loadingACPR,
  deleteclientProcessor:
    state &&
    state.clientProcessorDeleteStore &&
    state.clientProcessorDeleteStore.deleteclientProcessor,
  deleteclientProcessorStatus:
    state &&
    state.clientProcessorDeleteStore &&
    state.clientProcessorDeleteStore.deleteclientProcessorStatus,
  deleteclientProcessorMessage:
    state &&
    state.clientProcessorDeleteStore &&
    state.clientProcessorDeleteStore.deleteclientProcessorMessage,
  deleteclientProcessorLoading:
    state &&
    state.clientProcessorDeleteStore &&
    state.clientProcessorDeleteStore.loading,
  statusCPR:
    state && state.checkProcessorStore && state.checkProcessorStore.statusCPR,
  messageCPR:
    state && state.checkProcessorStore && state.checkProcessorStore.messageCPR,
  loadingCPR:
    state && state.checkProcessorStore && state.checkProcessorStore.loadingCPR,
});

const mapDispatchToProps = (dispatch) => ({
  getProcessorDispatch: (params, headers) =>
    dispatch(processorActions.getProcessor(params, headers)),
  getClientProcessorDispatch: (params, headers) =>
    dispatch(clientProcessorActions.getClientProcessor(params, headers)),
  clearClientProcessorDispatch: () =>
    dispatch(clientProcessorActions.clearClientProcessor()),
  editClientProcessorDispatch: (id, params, headers) =>
    dispatch(
      editClientProcessorActions.editClientProcessor(id, params, headers)
    ),
  cleareditClientProcessorDispatch: () =>
    dispatch(editClientProcessorActions.cleareditClientProcessor()),
  deleteclientProcessorDispatch: (data, headers) =>
    dispatch(deleteclientProcessorActions.deleteclientProcessor(data, headers)),
  clearDeleteclientProcessorDispatch: () =>
    dispatch(deleteclientProcessorActions.clearDeleteclientProcessor()),
  addclientProcessorDispatch: (data, headers) =>
    dispatch(addclientProcessorActions.addclientProcessor(data, headers)),
  clearaddclientProcessorDispatch: () =>
    dispatch(addclientProcessorActions.clearaddclientProcessor()),
  checkProcessorDispatch: (data, headers) =>
    dispatch(checkProcessorActions.add(data, headers)),
  clearcheckProcessorDispatch: () => dispatch(checkProcessorActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProcessorForm);
