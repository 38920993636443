import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SESSION, SET_STORAGE } from "../../../utils/constants";

import { setLocalStorage } from "../../../utils/helper";
import _ from "lodash";
import axios from "axios";

const ShopifySubscription = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  console.log("sd s sdf sdfds");
  const authSuccess = (responseData) => {
    // console.log(responseData, " responseData ");
    // console.log(responseData.data.data.token);
    setLocalStorage(SESSION.TOKEN, responseData.data.token);
    setLocalStorage(SET_STORAGE.SET_USER_ROLE, responseData.data.role);
    const menuList =
      responseData && responseData.menulist && responseData.menulist
        ? responseData.menulist
        : [];
    setLocalStorage(SET_STORAGE.USER_MENU_DETAILS, JSON.stringify(menuList));
    const authDetails =
      responseData && responseData.data ? responseData.data : {};
    setLocalStorage(SET_STORAGE.ATTRIBUTE_DEATILS, JSON.stringify(authDetails));
    setLocalStorage(
      SET_STORAGE.IS_FIRST_LOGIN,
      responseData && responseData.data && responseData.data.isFirstLogin
    );
    if (
      responseData &&
      responseData.data &&
      responseData.data.isFirstLogin === true
    ) {
      console.log(" session expired ");
      window.location = "/session-expired";
    } else if (
      responseData &&
      responseData.data &&
      responseData.data.isFirstLogin === false
    ) {
      console.log("object ");
      let redirectTo = "";
      if (menuList.length > 0) {
        if (
          menuList[0] &&
          menuList[0].submenu &&
          menuList[0].submenu.length > 0
        ) {
          redirectTo = menuList[0].submenu && menuList[0].submenu[0].slug;
          if (
            menuList[0].submenu[0] &&
            menuList[0].submenu[0].submenu &&
            menuList[0].submenu[0].submenu.length > 0
          ) {
            redirectTo =
              menuList[0].submenu[0].submenu &&
              menuList[0].submenu[0].submenu[0].slug;
          }
        }
      }
      window.location = redirectTo ? redirectTo : "/chargeback-management";
    }
  };

  useEffect(() => {
    console.log("hello good one");
    const requestDetails = {
      email: query.get("user"),
      password: "Cbzero@123",
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/login`, requestDetails)
      .then((response) => {
        console.log(response, " resoonse ");
        authSuccess(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return <div></div>;
};

export default ShopifySubscription;
