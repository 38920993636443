import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { KTSVG } from "../../../theme/helpers";
import { toAbsoluteUrl } from "../../../theme/helpers";
import { addEmailResendActions } from "../../../store/actions";
import { warningAlert, confirmAlert } from "../../../utils/alerts";
import { STATUS_RESPONSE } from "../../../utils/constants";
import { emailValidation } from "./validate/validation";
import { Modal } from "../../../theme/layout/components/modal";

const RedirectPage = (props) => {
  const {
    addResendEmailDispatch,
    clearaddResendEmailDispatch,
    statusERL,
    messageERL,
    loadingERL,
  } = props;

  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = () => {
    const errorMsg = emailValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      addResendEmailDispatch(formData);
    }
  };

  const onConfirm = () => {
    setShow(false);
  };

  useEffect(() => {
    if (statusERL === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageERL,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearaddResendEmailDispatch();
    } else if (statusERL === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert("Error", messageERL, "", "Ok");
      clearaddResendEmailDispatch();
    }
  }, [statusERL]);

  return (
    <>
      {show ? (
        <Modal showModal={show} modalWidth={550}>
          <div className="" id="uploadModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="me-8">Resend Email</h2>
                  <button
                    type="button"
                    className="btn btn-lg btn-icon btn-active-light-primary close"
                    data-dismiss="modal"
                    onClick={(e) => {
                      setShow(false);
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body p-5">
                  <div className="container-fixed">
                    <div className="card m-2 p-5">
                      <div className="row mt-2">
                        <label className="required fw-bolder fs-4 mb-2">
                          Email Id:
                        </label>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Email Id"
                          value={formData.email || ""}
                          onChange={(e) => handleChange(e)}
                          autoComplete="off"
                        />
                        {errors && errors.email && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red}"}</style>
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mt-4">
                      <div className="col-lg-5" />
                      <div className="col-lg-7">
                        <div className="col-lg-12">
                          <button
                            className="btn btn-primary  mt-7 fa-pull-right"
                            onClick={(event) => {
                              handleSubmit(event);
                            }}
                          >
                            {loadingERL ? (
                              <span
                                className="spinner-border spinner-border-sm mx-3"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Send Mail"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <body className="bg-shadow">
        <div className="d-flex justify-content-center ms-10">
          <a href="#" className="mb-12 mt-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-1.svg")}
              style={{ width: '253px', filter: 'drop-shadow(rgba(0, 0, 0, 0.8) 1px 1px 1px)' }}
            />
          </a>
        </div>
        <div className="row mb-10 gx-0">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xl-12 d-flex justify-content-center"
            style={{ minHeight: "49px" }}
          >
            <div className="card shadow-sm w-600px ms-5">
              <div className="card-header mt-5 d-flex justify-content-center">
                <h2
                  className="text mb-2 mt-5 fw-bolder"
                  style={{ color: "rgb(25 14 70)" }}
                >
                  Thanks for signing up
                </h2>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <a href="#" className="mb-12 ">
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl("/media/illustrations/sigma-1/6.png")}
                      className="w-350px h-300px"
                    />
                  </a>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-3 d-flex justify-content-end">
                    <span className="svg-icon svg-icon-primary svg-icon-2hx">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="1.5"
                          d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="col-lg-8 mt-1">
                    <p className="fw-bold text-dark fs-5">
                      We have sent a validation link to the given Mail Id.
                    </p>
                  </div>
                </div>
                <div className="row mt-4 mb-2">
                  <div className="col-lg-3 d-flex justify-content-end">
                    <span className="svg-icon svg-icon-primary svg-icon-2hx">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="1.5"
                          d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="col-lg-8 mt-1">
                    <p className="fw-bold text-dark fs-5">
                      Please check your inbox and click the link to validate
                      your email address.
                    </p>
                  </div>
                </div>
                <div className="row mt-2 mb-4">
                  <div className="col-lg-3 d-flex justify-content-end">
                    <span className="svg-icon svg-icon-danger svg-icon-2hx">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z"
                          fill="currentColor"
                        />
                        <path
                          d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="col-lg-8 mt-1">
                    <p className="fw-bold text-dark fs-5">
                      Having trouble in validating your email account.
                    </p>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button
                    className="btn btn-sm btn-primary fw-bolder fs-5"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Resend Email
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

const mapStateToProps = (state) => {
  const { addEmailResendStore } = state;
  return {
    addERL:
      addEmailResendStore && addEmailResendStore.addERL
        ? addEmailResendStore.addERL
        : [],
    statusERL:
      addEmailResendStore && addEmailResendStore.statusERL
        ? addEmailResendStore.statusERL
        : "",
    messageERL:
      addEmailResendStore && addEmailResendStore.messageERL
        ? addEmailResendStore.messageERL
        : "",
    loadingERL:
      addEmailResendStore && addEmailResendStore.loadingERL
        ? addEmailResendStore.loadingERL
        : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addResendEmailDispatch: (payload) =>
    dispatch(addEmailResendActions.add(payload)),
  clearaddResendEmailDispatch: () => dispatch(addEmailResendActions.clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage);
