import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { useLocation, Link } from 'react-router-dom'
import _ from 'lodash'
import userPlaceholder from '../../../assets/userPlaceholder.png'
import TextSunEditor from "../../../theme/layout/components/SunEditor";
import {
  sendCustomMailActions,
  chargeBackDetailsActions,
  SendLinkActions,
} from "../../../store/actions";
import { warningAlert, confirmAlert } from "../../../utils/alerts";
import moment from "moment";
import {
  DATE,
  STATUS_RESPONSE,
  CB_URL
} from "../../../utils/constants";
import { customMailValidation } from '../chargebackDetails/steps/validation'
import { headersWithAuth } from '../../../utils/helper'

function MailHistory(props) {
  const {
    sendLinkDispatch,
    clearsendLinkDispatch,
    statusSCM,
    messageSCM,
    loadingSCM,
    getEmailHistoryDispatch,
    getMailHistory,
    loadingMailHistory,
  } = props;

  const url = useLocation().pathname;
  const fields = url && url.split("/");
  const id = fields && fields[3];
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    chargeBackId: id,
    subject: "",
    mailContent: "",
  });

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
    e.preventDefault();
  };

  const handleSubmit = () => {
    const errorMsg = customMailValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      sendLinkDispatch(formData, headersWithAuth);
    }
  };

  const onConfirm = () => {
    getEmailHistoryDispatch(id, headersWithAuth);
  };

  useEffect(() => {
    if (statusSCM === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmAlert(
        "Success",
        messageSCM,
        "success",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearsendLinkDispatch();
    } else if (statusSCM === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        'Error',
        messageSCM,
        '',
        'Ok'
      )
      clearsendLinkDispatch();
    }
  }, [statusSCM]);

  useEffect(() => {
    if (id) {
      getEmailHistoryDispatch(id, headersWithAuth);
    }
  }, [id]);

  const downloadFiles = (uri) => {
    const link = document.createElement("a");
    const url = window.URL || window.webkitURL;
    const revokeUrlAfterSec = 1000;
    link.href = uri;
    link.download = true;
    link.target = "_self";
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => url.revokeObjectURL(link.href), revokeUrlAfterSec);
  };

  const downloadDocumentFiles = (obj) => {
    if (!_.isEmpty(obj)) {
      const downloadUrl = `${CB_URL}/evidence/${obj}`;
      downloadFiles(downloadUrl);
    }
  };

  return (
    <>
      <div className='card-header d-flex'>
        <div className='col-lg-6'>
          <h2 className='fw-semibold my-6 '>
            Remainder Mail History
          </h2>
        </div>
        <div className='col-lg-6'>
          <Link
            to={`/chargeback-management/update-chargeback/${id}`}
            className="btn btn-darkRed btn-sm m-5 w-150px fa-pull-right"
          >
            Back
          </Link>
          <button
            className="btn btn-sm btn-dark m-5 w-150px fa-pull-right"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <i className="bi bi-reply-fill" /> &nbsp;
            Reply
          </button>
        </div>
      </div>
      <div className="collapse bg-skyBlue py-5" id="collapseExample">
        <div className="card-body m-10 mt-5">
          <div className="row mt-2">
            <div className="col-lg-6">
              <label className="required fw-bold fs-6 mb-2">
                Subject
              </label>
              <textarea
                name="subject"
                type="text"
                className="form-control"
                placeholder="Subject"
                onChange={(e) => handleChange(e)}
                value={formData.subject || ""}
                autoComplete="off"
              />
              {errors && errors.subject && (
                <div className="rr mt-1">
                  <style>{".rr{color:red}"}</style>
                  {errors.subject}
                </div>
              )}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-7">
              <label className="required fw-bold fs-6 mb-2">
                Mail Content
              </label>
              <TextSunEditor
                setData={setFormData}
                contentData={formData.mailContent}
                name="mailContent"
                minHeight={"400px"}
              />
              {errors && errors.mailContent && (
                <div className="rr mt-1">
                  <style>{".rr{color:red}"}</style>
                  {errors.mailContent}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row mt-4">
            <div className="col-lg-5" />
            <div className="col-lg-7">
              <div className="col-lg-12">
                <button
                  className="btn btn-blue mt-7 fa-pull-right"
                  onClick={(event) => {
                    handleSubmit(event);
                  }}
                >
                  {loadingSCM ? (
                    <span
                      className="spinner-border spinner-border-sm mx-3"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        loadingMailHistory
          ? (
            <div className='d-flex justify-content-center py-5'>
              <div className='spinner-border text-primary m-5' role='status' />
            </div>
          )
          : (
            <>
              <div className='card-body min-w-200px'>
                {getMailHistory && getMailHistory.result ? (
                  getMailHistory.result.map((item, i) =>
                    <>
                      <div className='card mt-5 mb-5'
                        style={{
                          backgroundColor: `${item && item.type === 'RECEIVED' ? 'rgb(218 235 224)' : 'rgb(210 232 237)'}`
                        }} key={i}>
                        <div data-kt-inbox-message="message_wrapper m-10">
                          <div className="d-flex flex-wrap gap-2 flex-stack cursor-pointer m-10" data-kt-inbox-message="header">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-50 me-4">
                                <img
                                  style={{ width: 50, height: 50 }}
                                  src={userPlaceholder}
                                >
                                </img>
                              </div>
                              <div className="pe-5">
                                <div className="d-flex align-items-center flex-wrap gap-1">
                                  <a href="#" className="fw-bold text-dark text-hover-primary">
                                    {item && item.fromAddress ? item.fromAddress : '--'}
                                  </a>
                                  <span className="text-muted fw-bold gap-1 ms-6">
                                    <div className={`badge badge-sm d-inline ${item && item.type === 'RECEIVED' ? 'bg-success' : 'bg-primary'}`}>
                                      {item && item.type
                                        ? _.startCase(item.type)
                                        : '--'}
                                    </div>
                                  </span>
                                </div>
                                <div data-kt-inbox-message="details">
                                  <span className="text-muted fw-semibold">to {item && item.toAddress ? item.toAddress : '--'}</span>
                                </div>
                                <div className="text-muted fw-semibold mw-450px d-none" data-kt-inbox-message="preview">With resrpect, i must disagree with Mr.Zinsser. We all know the most part of important part....</div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flex-wrap gap-2">
                              <span className="fw-semibold text-muted text-end me-3">
                                {item && item.sentedDate
                                  ? moment(item.sentedDate).format(
                                    DATE.DATE_FORMAT_MINS
                                  )
                                  : "--"}
                              </span>
                            </div>
                          </div>
                          <div data-kt-inbox-message="message">
                            <div className="d-flex mt-5 mb-2 ms-10">
                              <div className="fw-bolder">Subject:</div> &nbsp; &nbsp;
                              <div className="fw-bold">{item && item.subject ? item.subject : '--'}</div>
                            </div>
                            <div className="py-5 ms-20">
                              <p>{item && item.emailtemplates ? <div dangerouslySetInnerHTML={{ __html: item.emailtemplates }} /> : '--'}</p>
                            </div>
                          </div>
                          {item && item.attachments.length > 0 &&
                            <h2 className="fw-bolder mt-5 ms-5">
                              Attachments
                              <i className='bi bi-paperclip fw-bolder fs-2 text-dark' /> :
                            </h2>
                          }
                          <div className="d-flex mt-5 mb-2 ms-12">
                            {item.attachments?.map((obj, index) => (
                              <>
                                <div
                                  className="border border-dashed border-gray-300 rounded p-2 text-center"
                                  style={{ width: "100px", cursor: "pointer" }}
                                  key={index}
                                >
                                  <i
                                    className="bi bi-file-earmark-text-fill"
                                    style={{
                                      color: "dodgerblue",
                                      width: "50px",
                                      height: "50px",
                                      fontSize: "3rem",
                                    }}
                                    onClick={() => {
                                      downloadDocumentFiles(obj);
                                    }}
                                  />
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )) : (
                  <tr className="d-flex justify-content-center py-5">
                    <td colSpan="100%">No record(s) found</td>
                  </tr>
                )}
              </div>
            </>
          )
      }
    </>
  )
}

const mapStateToProps = (state) => {
  const { chargebackStore, SendLinkStore } = state;
  return {
    loadingSCM:
      state && state.sendCustomMailStore && state.sendCustomMailStore.loadingSCM,
    statusSCM:
      state && state.sendCustomMailStore && state.sendCustomMailStore.statusSCM,
    messageSCM:
      state && state.sendCustomMailStore && state.sendCustomMailStore.messageSCM,
    dataSCM: state && state.sendCustomMailStore && state.sendCustomMailStore.dataSCM,
    ChargebackDetails:
      chargebackStore && chargebackStore.ChargebackDetails
        ? chargebackStore.ChargebackDetails
        : {},
    getMailHistory:
      SendLinkStore && SendLinkStore.getMailHistory
        ? SendLinkStore.getMailHistory
        : [],
    loadingMailHistory:
      SendLinkStore && SendLinkStore.loading ? SendLinkStore.loading : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendLinkDispatch: (data, headers) => dispatch(sendCustomMailActions.add(data, headers)),
  clearsendLinkDispatch: () => dispatch(sendCustomMailActions.clear()),
  getCBDetailsDispatch: (id, headers) =>
    dispatch(chargeBackDetailsActions.getChargebackDetails(id, headers)),
  getEmailHistoryDispatch: (id, headers) => dispatch(SendLinkActions.get(id, headers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailHistory);
