import React, { useState } from 'react'
import { KTSVG } from '../../theme/helpers'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Search from './search'
import ExportLayout from './exportLayout'
import TableAlertLayout from './tableLayout'

function Layout (props) {
  const { addColumns, addSearch, addExport } = props
  const [show, setShow] = useState('tablelayout')
  const [showModal, setShowModal] = useState(false)

  const addLayoutColumns = (data) => {
    addColumns(data)
  }

  const addSearchColumns = (data) => {
    addSearch(data)
  }

  const addExportColumns = (data) => {
    addExport(data)
  }

  const handleShow = (name) => {
    setShow(name)
  }

  return (
    <>
      <div>
        <button
          type='button'
          className='btn btn-sm btn-light-primary btn-responsive font-5vw me-3 pull-right'
          data-toggle='modal'
          data-target='#columnsModal'
          onClick={() => { setShowModal(true) }}
        >
          {/* eslint-disable */}
          <KTSVG path='/media/icons/duotune/arrows/arr087.svg' />
          {/* eslint-disable */}
          Add Columns
        </button>
      </div>
      <div className='modal fade' id='columnsModal' aria-hidden='true' data-backdrop="static" data-keyboard="false">
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2>Layout Options</h2>
              <button
                type='button'
                className='btn btn-lg btn-icon btn-active-light-primary close'
                data-dismiss='modal'
                onClick={() => { setShowModal(false) }}
              >
                {/* eslint-disable */}
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                {/* eslint-disable */}
              </button>
            </div>
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 font-weight-bold d-flex justify-content-center'>
              <li className='nav-item'>
                <a className={`${show && show === 'tablelayout' ? 'nav-link active' : 'nav-link'}`} data-bs-toggle='tab' data-target='#tablelayout' onClick={() => handleShow('tablelayout')}><span className='bi bi-layout-split m-2' />Table Layout</a>
              </li>
              <li className='nav-item'>
                <a className={`${show && show === 'search' ? 'nav-link active' : 'nav-link'}`} data-bs-toggle='tab' data-target='#search' onClick={() => handleShow('search')}><span className='bi bi-layout-split m-2' />Search Layout</a>
              </li>
              <li className='nav-item'>
                <a className={`${show && show === 'exportlayout' ? 'nav-link active' : 'nav-link'}`} data-bs-toggle='tab' data-target='#exportlayout' onClick={() => handleShow('exportlayout')}><span className='bi bi-layout-split m-2' />Export Layout</a>
              </li>
            </ul>
            <div className='modal-body py-lg-10 px-lg-10'>
              <div className='pt-1'>
                <div className='row'>
                  <div>
                    {show && show === 'search' && 
                    <Search
                    addSearchColumns={(data) => addSearchColumns(data)}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    show={show}
                     />
                    }
                    {show && show === 'exportlayout' &&
                    <ExportLayout
                      addExportColumns={(data) => addExportColumns(data)}
                      setShowModal={setShowModal}
                      showModal={showModal}
                      show={show}
                    />
                    }
                    {show && show === 'tablelayout' && 
                    <TableAlertLayout
                      addLayoutColumns={(data) => addLayoutColumns(data)}
                      setShowModal={setShowModal}
                      showModal={showModal}
                      show={show}
                    />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout
