export const bankActionsTypes = {
  GET_BANK: 'GET_BANK',
  SAVE_BANK_RESPONSE: 'SAVE_BANK_RESPONSE',
  CLEAR_BANK: 'CLEAR_BANK'
}

export const bankActions = {
  getBank: (params, headers) => ({
    type: bankActionsTypes.GET_BANK,
    params,
    headers
  }),
  savebankResponse: data => ({
    type: bankActionsTypes.SAVE_BANK_RESPONSE,
    data
  }),
  clearBank: () => ({
    type: bankActionsTypes.CLEAR_BANK
  })
}
