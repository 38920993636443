import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ShowFields from './ShowFields'
import { Stepper } from '../../../theme/layout/components/stepper/Stepper'
import { STATUS_RESPONSE, SESSION } from '../../../utils/constants'
import {
  addEvidenceActions,
  chargeBackDetailsActions
} from "../../../store/actions";
import Step1 from './steps/step1'
import Step2 from './steps/step2'
import Step3 from './steps/step3'
import Step4 from './steps/step4'
import Step5 from './steps/step5'
import Step6 from './steps/step6'
import Step7 from './steps/step7'
import { getLocalStorage, headersWithAuth, headerWithApiKey } from '../../../utils/helper'

const KTStepper = (props) => {
  const {
    addEvidenceDispatch,
    clearaddEvidenceDispatch,
    loadingAEP,
    getCBDetailsDispatch,
    ChargebackDetails,
    statusAEP
  } = props;

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const url = useLocation().pathname;
  const fields = url && url.split("/");
  const id = fields && fields[3];
  const [completedSteps, setCompletedSteps] = useState([-1])
  const [activeStep, setActiveStep] = useState(0)
  const [, setCBDetails] = useState({})

  useEffect(() => {
    if (id) {
      getCBDetailsDispatch(id, isAuthorized ? headersWithAuth : headerWithApiKey)
    }
  }, [id])

  const handleSubmit = () => {
    const payload = {
      chargebackId: id,
      merchantId: ChargebackDetails.merchantId ? ChargebackDetails.merchantId._id : '',
      transactionId: ChargebackDetails.transactionId ? ChargebackDetails.transactionId : '',
      arn: ChargebackDetails.arn ? ChargebackDetails.arn : '',
      authCode: ChargebackDetails.authCode ? ChargebackDetails.authCode : '',
      orderId: ChargebackDetails.orderId ? ChargebackDetails.orderId : '',
      cardFirstSix: ChargebackDetails.cardFirstSix ? ChargebackDetails.cardFirstSix : '',
      cardLastFour: ChargebackDetails.cardLastFour ? ChargebackDetails.cardLastFour : '',
      chargebackAmount: ChargebackDetails.chargebackAmount ? ChargebackDetails.chargebackAmount : '',
      chargebackDate: ChargebackDetails.chargebackDate ? ChargebackDetails.chargebackDate : '',
      transactionCurrency: ChargebackDetails.transactionCurrency ? ChargebackDetails.transactionCurrency : ''
    }
    addEvidenceDispatch(payload, isAuthorized ? headersWithAuth : headerWithApiKey)
  }

  if (statusAEP === STATUS_RESPONSE.SUCCESS_MSG) {
    clearaddEvidenceDispatch()
  }
  const stepperArray = [
    {
      stepperLabel: 'TRANSACTION INFORMATION ',
      Optional: ''
    },
    {
      stepperLabel: 'CUSTOMER INFORMATION',
      Optional: ''
    },
    {
      stepperLabel: 'PURCHASE INFORMATION',
      Optional: ''
    },
    {
      stepperLabel: 'MERCHANT INFORMATION',
      Optional: ''
    },
    {
      stepperLabel: 'COMPELLING EVIDENCE',
      Optional: ''
    },
    {
      stepperLabel: 'REBUTTAL PREVIEW',
      Optional: ''
    },
    {
      stepperLabel: 'SEND TO ACQUIRER',
      Optional: ''
    }
  ]

  const onClickNext = (currentId) => {
    const id = currentId - 1
    setActiveStep(currentId)
    if (completedSteps.length === currentId) {
      if (completedSteps && !completedSteps.includes(id)) {
        setCompletedSteps((values) => [...values, id])
      }
    }
  }

  const goBack = (id) => {
    setActiveStep(id)
  }

  return (
    <>
      <div className='bg-skyBlue'>
        <div className='d-flex justify-content-end mt-5'>
          <button
            className='btn btn-sm btn-pista rounded-0 m-5 mt-5'
            onClick={() => {
              handleSubmit()
            }}
          >
            {loadingAEP
              ? (
                <span
                  className='spinner-border spinner-border-sm mx-3'
                  role='status'
                  aria-hidden='true'
                />
              )
              : (
                <>
                  <i className='bi bi-magic text-white' />
                  AUTO FILL
                </>
              )}
          </button>
        </div>
        <Stepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          completedSteps={completedSteps}
          stepperArr={stepperArray}
          setCompletedSteps={setCompletedSteps}
          onStepperClick={onClickNext}
        />
        <ShowFields>
          {activeStep === 0
            ? (
              <Step1
                onClickNext={onClickNext}
                goBack={goBack}
                setCBDetails={setCBDetails}
              />
            )
            : null}
          {activeStep === 1
            ? (
              <Step2
                onClickNext={onClickNext}
                goBack={goBack}
                setCBDetails={setCBDetails}
              />
            )
            : null}
          {activeStep === 2
            ? (
              <Step3
                onClickNext={onClickNext}
                goBack={goBack}
                setCBDetails={setCBDetails}
              />
            )
            : null}
          {activeStep === 3
            ? (
              <Step4
                onClickNext={onClickNext}
                goBack={goBack}
                setCBDetails={setCBDetails}
              />
            )
            : null}
          {activeStep === 4
            ? (
              <Step5
                onClickNext={onClickNext}
                goBack={goBack}
                setCBDetails={setCBDetails}
              />
            )
            : null}
          {activeStep === 5
            ? (
              <Step6
                onClickNext={onClickNext}
                goBack={goBack}
                setCBDetails={setCBDetails}
              />
            )
            : null}
          {activeStep === 6
            ? (
              <Step7
                onClickNext={onClickNext}
                goBack={goBack}
                setCBDetails={setCBDetails}
              />
            )
            : null}
        </ShowFields>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { chargebackStore } = state;
  return {
    loadingAEP:
      state && state.addEvidenceStore && state.addEvidenceStore.loadingAEP,
    statusAEP:
      state && state.addEvidenceStore && state.addEvidenceStore.statusAEP,
    messageAEP:
      state && state.addEvidenceStore && state.addEvidenceStore.messageAEP,
    dataAEP: state && state.addEvidenceStore && state.addEvidenceStore.dataAEP,
    ChargebackDetails: chargebackStore && chargebackStore.ChargebackDetails ? chargebackStore.ChargebackDetails : {}
  };
};

const mapDispatchToProps = (dispatch) => ({
  addEvidenceDispatch: (data, headers) => dispatch(addEvidenceActions.add(data, headers)),
  clearaddEvidenceDispatch: () =>
    dispatch(addEvidenceActions.clear()),
  getCBDetailsDispatch: (id, headers) =>
    dispatch(chargeBackDetailsActions.getChargebackDetails(id, headers))
});

export default connect(mapStateToProps, mapDispatchToProps)(KTStepper);