import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addUserroleActions,
  addUserroleTypes,
  userRolesActions,
  userRoleActionsTypes,
  userTypeActionsTypes,
  userTypesActions,
  userRoleDetailsActions,
  userRoleGetDetailsTypes,
  editUserroleTypes,
  editUserrolesActions,
  userRoleDeleteTypes,
  deleteUserroleActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addUserrole (actions) {
  const { payload, headers } = actions
  try {
    const json = yield axiosInstance.post(serviceList.addUserrole, payload, {headers: headers})
    if (json.status === 200) {
      yield put(addUserroleActions.saveaddUserroleResponse(json.data))
    } else {
      yield put(addUserroleActions.saveaddUserroleResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addUserroleTypes.RESPONSE, data })
  }
}

function * fetchUserrole (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userRole, { params, headers:headers })
    if (json.status === 200) {
      yield put(userRolesActions.saveUserroleResponse(json.data))
    } else {
      yield put(userRolesActions.saveUserroleResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: userRoleActionsTypes.SAVE_USER_ROLE_RESPONSE, data })
  }
}

function * fetchUserType (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userTypes, { params, headers:headers })
    if (json.status === 200) {
      yield put(userTypesActions.saveUserTypeResponse(json.data))
    } else {
      yield put(userTypesActions.saveUserTypeResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: userTypeActionsTypes.RESPONSE, data })
  }
}

function * getUserroleDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.userRole}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers})
    if (json.status === 200) {
      yield put(userRoleDetailsActions.saveUserroleDetailsResponse(json.data))
    } else {
      yield put(userRoleDetailsActions.saveUserroleDetailsResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: userRoleGetDetailsTypes.USER_ROLE_DETAILS_RESPONSE, data })
  }
}

function * editUserrole (actions) {
  const { payload, headers } = actions
  const { id, data } = payload && payload
  const endPointUrl = `${serviceList.userRole}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, data, { headers:headers })
    if (json.status === 200) {
      yield put(editUserrolesActions.saveeditUserrolesResponse(json.data))
    } else {
      yield put(editUserrolesActions.saveeditUserrolesResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: editUserroleTypes.RESPONSE, data })
  }
}

function * deleteUserrole (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.userRole}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers: headers})
    if (json.status === 200) {
      yield put(deleteUserroleActions.savemResponseDeleteUserrole(json.data))
    } else {
      yield put(deleteUserroleActions.savemResponseDeleteUserrole([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: userRoleDeleteTypes.SAVE_DELETE_USER_ROLE_RESPONSE, data })
  }
}

export function * fetchUserroleWatcher () {
  yield all([
    yield takeLatest(addUserroleTypes.REQUEST, addUserrole),
    yield takeLatest(userRoleActionsTypes.GET_USER_ROLE, fetchUserrole),
    yield takeLatest(userRoleGetDetailsTypes.GET_USER_ROLE_DETAILS, getUserroleDetails),
    yield takeLatest(editUserroleTypes.REQUEST, editUserrole),
    yield takeLatest(userRoleDeleteTypes.DELETE_USER_ROLE, deleteUserrole),
    yield takeLatest(userTypeActionsTypes.REQUEST, fetchUserType)
  ])
}
