import {
  addEmailResendActionsTypes,
} from '../actions'

export const addEmailResendStoreKey = 'addEmailResendStore'

export const addEmailResendReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case addEmailResendActionsTypes.REQUEST:
      return { ...state, loadingERL: true }
    case addEmailResendActionsTypes.RESPONSE:
      return {
        ...state,
        addERL: res.data,
        statusERL: res.status,
        messageERL: res.message,
        loadingERL: false
      }
    case addEmailResendActionsTypes.CLEAR:
      return {
        ...state,
        addERL: null,
        statusERL: '',
        messageERL: ''
      }
    default:
      return state
  }
}