import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  CRM_FORM,
  REGEX,
  STATUS_RESPONSE,
  FILE_FORMAT_MID,
  DROPZONE_MESSAGES,
  API_URL,
  SESSION,
  API,
} from "../../../../utils/constants";
import { merchantValidation } from "./validation";
import {
  setLocalStorage,
  getLocalStorage,
  headersWithAuth,
} from "../../../../utils/helper";
import { setAddressDetails, getAdressValues } from "../functions/functions";
import _ from "lodash";
import color from "../../../../utils/colors";
import {
  countryActions,
  stateActions,
  cityActions,
  merchantActions,
  deleteMerchantActions,
  industryActions,
  addMerchantUploadActions,
} from "../../../../store/actions";
import ReactSelect from "../../../../theme/layout/components/ReactSelect";
import { warningAlert, confirmAlert } from "../../../../utils/alerts";
import { getProcessorPayload } from "../../onboarding/functions/formData";
import Dropzone, { useDropzone } from "react-dropzone";
import styled from "styled-components";

const StyledDiv = styled.div`
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
    width: 450px;
    height: 55px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #9ab6d9;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
`;

const MerchantForm = (props) => {
  const {
    onClickNext,
    setClientDetails,
    setSummary,
    getCountrys,
    loadingCountry,
    getStates,
    loadingState,
    getCitys,
    loadingCity,
    deleteMerchantStatus,
    deleteMerchantMessage,
    clearDeleteMerchantDispatch,
    goBack,
    getCountryDispatch,
    getStateDispatch,
    getCityDispatch,
    getIndustryDispatch,
    getIndustry,
    addMerchantUploadDispatch,
    clearaddMerchantUploadDispatch,
    dataAMUpload,
    loadingAMUpload,
    statusAMUpload,
  } = props;

  const { getRootProps } = useDropzone();
  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };
  const didMount = React.useRef(false);
  const [countryOption, setCountryOption] = useState();
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [stateOption, setStateOption] = useState();
  const [selectedStateOption, setSelectedStateOption] = useState("");
  const [cityOptions, setCityOptions] = useState();
  const [selectedCityOption, setSelectedCityOption] = useState("");
  const [selectedIndustryOption, setSelectedIndustryOption] = useState("");
  const [industryOption, setIndustryOption] = useState();
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [targetFileName, setTargetFileName] = useState();
  const [onBlurOptions, setOnBlurOptions] = useState({
    merchantState: false,
    merchantCity: false,
  });
  const [formData, setFormData] = useState({
    merchantName: "",
    merchantCountry: "",
    industry: "",
    merchantAddress: "",
    merchantCity: "",
    merchantState: "",
    merchantZip: "",
    merchantEmail: "",
    merchantPhoneNumber: "",
    phoneNumberExtension: "",
    externalId: "",
    companyDescription: "",
    mid: "",
    descriptorName: "",
    descriptorId: "",
    doingBusinessAs: "",
    acquirerBin: "",
    caid: "",
    storeName: "",
    storeType: "",
    signupUrl: "",
    tosUrl: "",
    checkoutUrl: "",
    returnPolicyUrl: "",
    tosPolicyScreenshot: "",
    refundPolicyScreenshot: "",
    checkoutScreenshot: "",
    shippingPolicyScreenshot: "",
  });

  const params = {
    skipPagination: true,
  };
  useEffect(() => {
    getCountryDispatch(
      params,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
    getIndustryDispatch(
      null,
      isAuthorized ? headersWithAuth : headercurrentWithApiKey
    );
  }, []);

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const getSelectedIndustryLabel = () => {
    const getIndustryLabel = getDefaultIndustryOptions(
      getIndustry,
      "industryType"
    );
    const selIndOption = _.filter(getIndustryLabel, function (x) {
      if (_.includes(formData.industry, x.value)) {
        return x;
      }
    });
    if (selIndOption.length > 0) {
      return selIndOption[0];
    }
  };

  const handleSubmit = () => {
    const errorMsg = merchantValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      onClickNext(2);
      const getCountryValue = getAdressValues(
        getCountrys,
        formData.merchantCountry
      );
      const getStateValue = getAdressValues(getStates, formData.merchantState);
      const gerCityValue = getAdressValues(getCitys, formData.merchantCity);
      const getIndValue = getSelectedIndustryLabel();
      setSummary((values) => ({
        ...values,
        merchant: {
          country: getCountryValue && getCountryValue.label,
          state: getStateValue && getStateValue.label,
          city: gerCityValue && gerCityValue.label,
          industry: getIndValue && getIndValue.label,
        },
      }));
      const getPayload = getProcessorPayload(formData);
      setClientDetails((values) => ({
        ...values,
        merchant: getPayload && getPayload.merchant,
        mid: getPayload && getPayload.mid,
      }));
      setLocalStorage(CRM_FORM.MERCHANT_DETAILS, JSON.stringify(formData));
    }
  };

  useEffect(() => {
    const localData = JSON.parse(getLocalStorage(CRM_FORM.MERCHANT_DETAILS));
    if (!_.isEmpty(localData)) {
      setFormData(localData);
      setAddressDetails(
        getCountrys,
        getStates,
        getCitys,
        localData.merchantCountry,
        localData.merchantState,
        localData.merchantCity,
        setSelectedCountryOption,
        setSelectedStateOption,
        setSelectedCityOption
      );
      if (localData.industry) {
        const data = getDefaultIndustryOptions(getIndustry, "industryType");
        const selOption = _.filter(data, function (x) {
          if (_.includes(localData.industry, x.value)) {
            return x;
          }
        });
        if (selOption.length > 0) {
          setSelectedIndustryOption(selOption);
        }
      }
    }
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : "",
    }),
  };

  const handleChangeCountry = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getStateDispatch(
            {
              countryId: selectedOption.value,
              skipPagination: true,
            },
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      );
      setSelectedCountryOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantCountry: selectedOption.value,
        merchantState: "",
        merchantCity: "",
      }));
      setSelectedStateOption();
      setSelectedCityOption();
    } else {
      setSelectedCountryOption();
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({
        ...values,
        merchantCountry: "",
        merchantState: "",
        merchantCity: "",
      }));
    }
    setErrors({ ...errors, merchantCountry: "" });
  };

  const handleChangeState = (selectedOption) => {
    if (selectedOption !== null) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          getCityDispatch(
            {
              stateId: selectedOption.value,
              skipPagination: true,
            },
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
        }, 1500)
      );
      setSelectedStateOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantState: selectedOption.value,
        merchantCity: "",
      }));
      setSelectedCityOption();
    } else {
      setSelectedStateOption();
      setSelectedCityOption();
      setFormData((values) => ({
        ...values,
        merchantState: "",
        merchantCity: "",
      }));
    }
    setErrors({ ...errors, merchantState: "" });
  };

  const handleChangeCity = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedCityOption(selectedOption);
      setFormData((values) => ({
        ...values,
        merchantCity: selectedOption.value,
      }));
    } else {
      setSelectedCityOption();
      setFormData((values) => ({ ...values, merchantCity: "" }));
    }
    setErrors({ ...errors, merchantCity: "" });
  };

  const handleSelectBlur = (e, name) => {
    typingTimeout && clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (name === "merchantState" && onBlurOptions.merchantState) {
          const param = { limit: 25 };
          getStateDispatch(
            param,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setOnBlurOptions((values) => ({ ...values, merchantState: false }));
        }
        if (name === "merchantCity" && onBlurOptions.merchantCity) {
          const param = { limit: 25 };
          getCityDispatch(
            param,
            isAuthorized ? headersWithAuth : headercurrentWithApiKey
          );
          setOnBlurOptions((values) => ({ ...values, merchantCity: false }));
        }
      }, 1000)
    );
  };

  const handleSelectInputChange = (typedOption, name) => {
    if (typedOption.length >= 3) {
      typingTimeout && clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          if (
            name === "merchantState" &&
            !(selectedCountryOption && selectedCountryOption.value)
          ) {
            const param = { name: typedOption, skipPagination: true };
            getStateDispatch(
              param,
              isAuthorized ? headersWithAuth : headercurrentWithApiKey
            );
            setOnBlurOptions((values) => ({ ...values, merchantState: true }));
          }
          if (
            name === "merchantCity" &&
            !(selectedStateOption && selectedStateOption.value)
          ) {
            const param = { name: typedOption, skipPagination: true };
            getCityDispatch(
              param,
              isAuthorized ? headersWithAuth : headercurrentWithApiKey
            );
            setOnBlurOptions((values) => ({ ...values, merchantCity: true }));
          }
        }, 2000)
      );
    }
  };

  const handleChangeIndustry = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedIndustryOption(selectedOption);
      setFormData((values) => ({ ...values, industry: selectedOption.value }));
      setErrors({ ...errors, industry: "" });
    } else {
      setSelectedIndustryOption();
      setFormData((values) => ({ ...values, industry: "" }));
    }
  };

  useEffect(() => {
    const country = getDefaultOptions(getCountrys);
    setCountryOption(country);
  }, [getCountrys]);

  useEffect(() => {
    const state = getDefaultOptions(getStates);
    setStateOption(state);
  }, [getStates]);

  useEffect(() => {
    const city = getDefaultOptions(getCitys);
    setCityOptions(city);
  }, [getCitys]);

  useEffect(() => {
    const data = getDefaultIndustryOptions(getIndustry, "industryType");
    setIndustryOption(data);
  }, [getIndustry]);

  const getDefaultOptions = (rawData) => {
    const defaultOptions = [];
    for (const item in rawData) {
      defaultOptions.push({
        label: rawData[item].name,
        value: rawData[item]._id,
      });
    }
    return defaultOptions;
  };

  const getDefaultIndustryOptions = (data, name) => {
    const defaultOptions = [];
    if (!_.isEmpty(data)) {
      data.map((item) =>
        defaultOptions.push({
          label: `${item[name] ? item[name] : ""}`,
          value: item._id,
        })
      );
      return defaultOptions;
    }
  };

  const onConfirm = () => {};

  useEffect(() => {
    if (didMount.current) {
      if (deleteMerchantStatus === STATUS_RESPONSE.SUCCESS_MSG) {
        confirmAlert(
          "Success",
          deleteMerchantMessage,
          "success",
          "ok",
          () => {
            onConfirm();
          },
          () => {
            onConfirm();
          }
        );
        clearDeleteMerchantDispatch();
      } else if (deleteMerchantStatus === STATUS_RESPONSE.ERROR_MSG) {
        warningAlert("Error", deleteMerchantMessage, "", "Ok");
      }
      clearDeleteMerchantDispatch();
    }
  }, [deleteMerchantStatus]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    }
  });

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target;
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, " ");
      setState((values) => ({ ...values, [name]: getData.trim() }));
    } else {
      setState((values) => ({ ...values, [name]: "" }));
    }
  };

  const handleFileUpload = (files, name) => {
    setErrors({ ...errors, [name]: "" });
    setTargetFileName(name);
    const maxFileSize = 5;
    let isValidFileFormat = true;
    let currentFileName = "";
    const data = new FormData();
    files.forEach((item) => {
      const fileType = item && item.type;
      const uploadedFileSize = item && item.size;
      currentFileName = item && item.name;
      isValidFileFormat = _.includes(FILE_FORMAT_MID, fileType);
      const fileSize = Number(maxFileSize) * 1024 * 1024;
      if (isValidFileFormat) {
        if (uploadedFileSize < fileSize) {
          data.append("type", "screenshot");
          data.append("file_to_upload", item);
          addMerchantUploadDispatch(data);
        } else {
          setErrors({
            ...errors,
            [name]: `File size must below ${
              fileSize / 1048576
            } MB!. You file size is : ${Math.round(uploadedFileSize / 1024)}KB`,
          });
        }
      } else {
        setErrors({ ...errors, [name]: DROPZONE_MESSAGES.IMAGE_INVALID });
      }
    });
  };

  const handleOpen = () => {
    setShow((show) => !show);
  };
  useEffect(() => {
    if (statusAMUpload === STATUS_RESPONSE.SUCCESS_MSG) {
      if (dataAMUpload && dataAMUpload.path) {
        setFormData((values) => ({
          ...values,
          [targetFileName]: dataAMUpload.path,
        }));
      }
      clearaddMerchantUploadDispatch();
      setTargetFileName();
    } else if (deleteMerchantStatus === STATUS_RESPONSE.ERROR_MSG) {
      setTargetFileName();
      clearaddMerchantUploadDispatch();
    }
  }, [statusAMUpload]);

  return (
    <>
      <div className="card-header bg-skyBlue py-10">
        <div className="card-body  m-8">
          <h2 className="mb-5">Merchant Info</h2>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                Merchant Name:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="merchantName"
                  type="text"
                  className="form-control"
                  placeholder="Merchant Name"
                  onChange={(e) => handleChange(e)}
                  value={formData.merchantName || ""}
                  maxLength={42}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.TEXT.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors && errors.merchantName && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.merchantName}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                Industry:
              </label>
              <div className="col-lg-12">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="industry"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeIndustry}
                  options={industryOption}
                  value={selectedIndustryOption}
                />
                {errors && errors.industry && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.industry}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Country:
              </label>
              <div className="col-lg-12">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="merchantCountry"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeCountry}
                  options={countryOption}
                  value={selectedCountryOption}
                  isLoading={loadingCountry}
                  isDisabled={loadingCity || loadingCountry}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                State:
              </label>
              <div className="col-lg-12">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="merchantState"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeState}
                  options={stateOption}
                  value={selectedStateOption}
                  isLoading={loadingState}
                  onBlur={(e) => handleSelectBlur(e, "merchantState")}
                  noOptionsMessage={() => "Please start typing to search!"}
                  handleInputChange={(e) =>
                    handleSelectInputChange(e, "merchantState")
                  }
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                City:
              </label>
              <div className="col-lg-12">
                <ReactSelect
                  isClearable
                  styles={customStyles}
                  isMulti={false}
                  name="merchantCity"
                  className="basic-single"
                  classNamePrefix="select"
                  handleChangeReactSelect={handleChangeCity}
                  options={cityOptions}
                  value={selectedCityOption}
                  isLoading={loadingCity}
                  onBlur={(e) => handleSelectBlur(e, "merchantCity")}
                  noOptionsMessage={() => "Please start typing to search!"}
                  handleInputChange={(e) =>
                    handleSelectInputChange(e, "merchantCity")
                  }
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                Address:
              </label>
              <div className="col-lg-12">
                <textarea
                  autoComplete="off"
                  name="merchantAddress"
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  onChange={(e) => handleChange(e)}
                  value={formData.merchantAddress || ""}
                  maxLength={500}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors && errors.merchantAddress && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.merchantAddress}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                Zip Code:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="merchantZip"
                  type="text"
                  className="form-control"
                  placeholder="Zip Code"
                  onChange={(e) => handleChange(e)}
                  value={formData.merchantZip || ""}
                  maxLength={6}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors && errors.merchantZip && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.merchantZip}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                Email:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="merchantEmail"
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => handleChange(e)}
                  value={formData.merchantEmail || ""}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                />
                {errors && errors.merchantEmail && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.merchantEmail}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                Phone Number:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="merchantPhoneNumber"
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  onChange={(e) => handleChange(e)}
                  value={formData.merchantPhoneNumber || ""}
                  maxLength={12}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.NUMERIC_CHARS.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors && errors.merchantPhoneNumber && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.merchantPhoneNumber}
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Phone Extension:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="phoneNumberExtension"
                  type="text"
                  className="form-control"
                  placeholder="Phone Extension"
                  onChange={(e) => handleChange(e)}
                  value={formData.phoneNumberExtension || ""}
                  maxLength={10}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!/[0-9+]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                External Id:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="externalId"
                  type="text"
                  className="form-control"
                  placeholder="External Id"
                  onChange={(e) => handleChange(e)}
                  value={formData.externalId || ""}
                  maxLength={42}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Company Description:
              </label>
              <div className="col-lg-12">
                <textarea
                  autoComplete="off"
                  name="companyDescription"
                  type="text"
                  className="form-control"
                  placeholder="Company Description"
                  onChange={(e) => handleChange(e)}
                  value={formData.companyDescription || ""}
                  maxLength={500}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <h2 className="mb-5">MID Info</h2>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                MID number:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="mid"
                  type="text"
                  className="form-control"
                  placeholder="MID number"
                  onChange={(e) => handleChange(e)}
                  value={formData.mid || ""}
                  maxLength={42}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors && errors.mid && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.mid}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Descriptor name:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="descriptorName"
                  type="text"
                  className="form-control"
                  placeholder="Descriptor name"
                  onChange={(e) => handleChange(e)}
                  value={formData.descriptorName || ""}
                  maxLength={42}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC_CHARS.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Descriptor ID:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="descriptorId"
                  type="text"
                  className="form-control"
                  placeholder="Descriptor ID"
                  onChange={(e) => handleChange(e)}
                  value={formData.descriptorId || ""}
                  maxLength={42}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                Doing business as:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="doingBusinessAs"
                  type="text"
                  className="form-control"
                  placeholder="Doing business as"
                  onChange={(e) => handleChange(e)}
                  value={formData.doingBusinessAs || ""}
                  maxLength={42}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors && errors.doingBusinessAs && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.doingBusinessAs}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                BIN (Bank Identification Number):
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="acquirerBin"
                  type="text"
                  className="form-control"
                  placeholder="BIN (Bank Identification Number)"
                  onChange={(e) => handleChange(e)}
                  value={formData.acquirerBin || ""}
                  maxLength={6}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                CAID :
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="caid"
                  type="text"
                  className="form-control"
                  placeholder="CAID (Cardholder Acceptance Identification Number)"
                  onChange={(e) => handleChange(e)}
                  value={formData.caid || ""}
                  maxLength={6}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.NUMERIC.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Store Name:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="storeName"
                  type="text"
                  className="form-control"
                  placeholder=" Store Name"
                  onChange={(e) => handleChange(e)}
                  value={formData.storeName || ""}
                  maxLength={42}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                  onKeyPress={(e) => {
                    if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors && errors.storeName && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.storeName}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Store Type:
              </label>
              <div className="col-lg-12">
                <select
                  name="storeType"
                  className="form-select form-select-solid"
                  data-control="select"
                  data-placeholder="Select an option"
                  data-allow-clear="true"
                  onChange={(e) => handleChange(e)}
                  value={formData.storeType || ""}
                >
                  <option value="">Select...</option>
                  <option value="physicalGoods">Physical Goods</option>
                  <option value="digitalGoods">Digital Goods</option>
                  <option value="subscription">Subscription</option>
                </select>
                {errors && errors.storeType && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.storeType}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Sign Up URL :
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="signupUrl"
                  type="text"
                  className="form-control"
                  placeholder="Sign Up URL"
                  onChange={(e) => handleChange(e)}
                  value={formData.signupUrl || ""}
                  maxLength={500}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                />
                {errors && errors.signupUrl && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.signupUrl}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                TOS URL:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="tosUrl"
                  type="text"
                  className="form-control"
                  placeholder="TOS URL"
                  onChange={(e) => handleChange(e)}
                  value={formData.tosUrl || ""}
                  maxLength={500}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                />
                {errors && errors.tosUrl && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.tosUrl}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Checkout URL:
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="checkoutUrl"
                  type="text"
                  className="form-control"
                  placeholder="Checkout URL"
                  onChange={(e) => handleChange(e)}
                  value={formData.checkoutUrl || ""}
                  maxLength={500}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                />
                {errors && errors.checkoutUrl && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.checkoutUrl}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Return Policy URL :
              </label>
              <div className="col-lg-12">
                <input
                  autoComplete="off"
                  name="returnPolicyUrl"
                  type="text"
                  className="form-control"
                  placeholder="Return Policy URL"
                  onChange={(e) => handleChange(e)}
                  value={formData.returnPolicyUrl || ""}
                  maxLength={500}
                  onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                />
                {errors && errors.returnPolicyUrl && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.returnPolicyUrl}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                TOS Policy Screenshot:
              </label>
              <div className="col-lg-12">
                <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleFileUpload(acceptedFiles, "tosPolicyScreenshot");
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="container w-17rem">
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            name="tosPolicyScreenshot"
                          />
                          <p className="text-primary fw-bolder fs-6">
                            Click here to select files
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDiv>
                {loadingAMUpload && targetFileName === "tosPolicyScreenshot" ? (
                  <div className="d-flex justify-content-center align-items-center py-3">
                    <div className="d-flex justify-content-center">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden" />
                      </span>
                      <span
                        className="spinner-grow spinner-grow-sm mx-1"
                        role="status"
                      >
                        <span className="visually-hidden" />
                      </span>
                    </div>
                    <div className="mx-2">Uploading</div>
                  </div>
                ) : null}
                {formData && formData.tosPolicyScreenshot ? (
                  <div className="text-center mt-2 cursor-pointer">
                    <a
                      href={`${API_URL}/uploads/${formData.tosPolicyScreenshot}`}
                      target="_blank"
                    >
                      {formData.tosPolicyScreenshot.includes("pdf") ? (
                        <i
                          className="bi bi-file-earmark-pdf-fill"
                          style={{
                            color: "dodgerblue",
                            width: "75px",
                            height: "75px",
                            fontSize: "3rem",
                          }}
                        />
                      ) : (
                        <img
                          src={`${API_URL}/uploads/${formData.tosPolicyScreenshot}`}
                          alt="your image"
                          style={{ width: 100, height: 100 }}
                        />
                      )}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Refund Policy Screenshot:
              </label>
              <div className="col-lg-12">
                <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleFileUpload(acceptedFiles, "refundPolicyScreenshot");
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="container w-17rem">
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            name="refundPolicyScreenshot"
                          />
                          <p className="text-primary fw-bolder fs-6">
                            Click here to select files
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDiv>
                {loadingAMUpload &&
                targetFileName === "refundPolicyScreenshot" ? (
                  <div className="d-flex justify-content-center align-items-center py-3">
                    <div className="d-flex justify-content-center">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden" />
                      </span>
                      <span
                        className="spinner-grow spinner-grow-sm mx-1"
                        role="status"
                      >
                        <span className="visually-hidden" />
                      </span>
                    </div>
                    <div className="mx-2">Uploading</div>
                  </div>
                ) : null}
                {formData && formData.refundPolicyScreenshot ? (
                  <div className="text-center mt-2 cursor-pointer">
                    <a
                      href={`${API_URL}/uploads/${formData.refundPolicyScreenshot}`}
                      target="_blank"
                    >
                      {formData.refundPolicyScreenshot.includes("pdf") ? (
                        <i
                          className="bi bi-file-earmark-pdf-fill"
                          style={{
                            color: "dodgerblue",
                            width: "75px",
                            height: "75px",
                            fontSize: "3rem",
                          }}
                        />
                      ) : (
                        <img
                          src={`${API_URL}/uploads/${formData.refundPolicyScreenshot}`}
                          alt="your image"
                          style={{ width: 100, height: 100 }}
                        />
                      )}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Checkout Screenshot:
              </label>
              <div className="col-lg-12">
                <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleFileUpload(acceptedFiles, "checkoutScreenshot");
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="container w-17rem">
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            name="checkoutScreenshot"
                          />
                          <p className="text-primary fw-bolder fs-6">
                            Click here to select files
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDiv>
                {loadingAMUpload && targetFileName === "checkoutScreenshot" ? (
                  <div className="d-flex justify-content-center align-items-center py-3">
                    <div className="d-flex justify-content-center">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden" />
                      </span>
                      <span
                        className="spinner-grow spinner-grow-sm mx-1"
                        role="status"
                      >
                        <span className="visually-hidden" />
                      </span>
                    </div>
                    <div className="mx-2">Uploading</div>
                  </div>
                ) : null}
                {formData && formData.checkoutScreenshot ? (
                  <div className="text-center mt-2 cursor-pointer">
                    <a
                      href={`${API_URL}/uploads/${formData.checkoutScreenshot}`}
                      target="_blank"
                    >
                      {formData.checkoutScreenshot.includes("pdf") ? (
                        <i
                          className="bi bi-file-earmark-pdf-fill"
                          style={{
                            color: "dodgerblue",
                            width: "75px",
                            height: "75px",
                            fontSize: "3rem",
                          }}
                        />
                      ) : (
                        <img
                          src={`${API_URL}/uploads/${formData.checkoutScreenshot}`}
                          alt="your image"
                          style={{ width: 100, height: 100 }}
                        />
                      )}
                    </a>
                  </div>
                ) : null}
                {errors && errors.checkoutScreenshot && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.checkoutScreenshot}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row mb-4">
            <div className="col-lg-4 mb-3">
              <label className="font-size-xs  font-weight-bold mb-2 form-label">
                Shipping Policy Screenshot:
              </label>
              <div className="col-lg-12">
                <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleFileUpload(
                        acceptedFiles,
                        "shippingPolicyScreenshot"
                      );
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="container w-17rem">
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            name="shippingPolicyScreenshot"
                          />
                          <p className="text-primary fw-bolder fs-6">
                            Click here to select files
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDiv>
                {loadingAMUpload &&
                targetFileName === "shippingPolicyScreenshot" ? (
                  <div className="d-flex justify-content-center align-items-center py-3">
                    <div className="d-flex justify-content-center">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden" />
                      </span>
                      <span
                        className="spinner-grow spinner-grow-sm mx-1"
                        role="status"
                      >
                        <span className="visually-hidden" />
                      </span>
                    </div>
                    <div className="mx-2">Uploading</div>
                  </div>
                ) : null}
                {formData && formData.shippingPolicyScreenshot ? (
                  <div className="text-center mt-2 cursor-pointer">
                    <a
                      href={`${API_URL}/uploads/${formData.shippingPolicyScreenshot}`}
                      target="_blank"
                    >
                      {formData.shippingPolicyScreenshot.includes("pdf") ? (
                        <i
                          className="bi bi-file-earmark-pdf-fill"
                          style={{
                            color: "dodgerblue",
                            width: "75px",
                            height: "75px",
                            fontSize: "3rem",
                          }}
                        />
                      ) : (
                        <img
                          src={`${API_URL}/uploads/${formData.shippingPolicyScreenshot}`}
                          alt="your image"
                          style={{ width: 100, height: 100 }}
                        />
                      )}
                    </a>
                  </div>
                ) : null}
                {errors && errors.shippingPolicyScreenshot && (
                  <div className="rr mt-1">
                    <style>{".rr{color:red;}"}</style>
                    {errors.shippingPolicyScreenshot}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <button className="btn btn-green mt-8" onClick={handleOpen}>
                Add more &nbsp;
                <span>
                  <i className="bi bi-plus-lg text-white"></i>
                </span>
              </button>
            </div>
          </div>
          {show ? (
            <div className="form-group row mb-4">
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  Screenshot Type:
                </label>
                <div className="col-lg-12">
                  <input
                    autoComplete="off"
                    name="screenshotType"
                    type="text"
                    className="form-control"
                    placeholder="Screenshot Type"
                    onChange={(e) => handleChange(e)}
                    value={formData.screenshotType || ""}
                    maxLength={42}
                    onBlur={(e) => handleTrimWhiteSpace(e, setFormData)}
                    onKeyPress={(e) => {
                      if (!REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors && errors.screenshotType && (
                    <div className="rr mt-1">
                      <style>{".rr{color:red;}"}</style>
                      {errors.screenshotType}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <label className="font-size-xs  font-weight-bold mb-2 required form-label">
                  Other Screenshot:
                </label>
                <div className="col-lg-12">
                  <StyledDiv {...getRootProps({ refKey: "innerRef" })}>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleFileUpload(acceptedFiles, "otherDocument");
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="container w-17rem">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} name="otherDocument" />
                            <p className="text-primary fw-bolder fs-6">
                              Click here to select files
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </StyledDiv>
                  {loadingAMUpload && targetFileName === "otherDocument" ? (
                    <div className="d-flex justify-content-center align-items-center py-3">
                      <div className="d-flex justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                        <span
                          className="spinner-grow spinner-grow-sm mx-1"
                          role="status"
                        >
                          <span className="visually-hidden" />
                        </span>
                      </div>
                      <div className="mx-2">Uploading</div>
                    </div>
                  ) : null}
                  {formData && formData.otherDocument ? (
                    <div className="text-center mt-2 cursor-pointer">
                      <a
                        href={`${API_URL}/uploads/${formData.otherDocument}`}
                        target="_blank"
                      >
                        {formData.otherDocument.includes("pdf") ? (
                          <i
                            className="bi bi-file-earmark-pdf-fill"
                            style={{
                              color: "dodgerblue",
                              width: "75px",
                              height: "75px",
                              fontSize: "3rem",
                            }}
                          />
                        ) : (
                          <img
                            src={`${API_URL}/uploads/${formData.otherDocument}`}
                            alt="your image"
                            style={{ width: 100, height: 100 }}
                          />
                        )}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-group row mb-2 mt-3">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <div className="col-lg-11">
                <button
                  className="btn btn-orange m-2 fa-pull-right"
                  onClick={(event) => {
                    handleSubmit(event);
                  }}
                >
                  Next
                </button>
                <button
                  className="btn btn-darkRed m-2 fa-pull-right"
                  onClick={() => {
                    goBack(0);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getCountrys: state && state.countryStore && state.countryStore.getCountrys,
  loadingCountry: state && state.countryStore && state.countryStore.loading,
  getStates: state && state.stateStore && state.stateStore.getStates,
  loadingState: state && state.stateStore && state.stateStore.loading,
  getCitys: state && state.cityStore && state.cityStore.getCitys,
  loadingCity: state && state.cityStore && state.cityStore.loading,
  loadingAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.loadingAMUpload,
  dataAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.dataAMUpload,
  statusAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.statusAMUpload,
  messageAMUpload:
    state &&
    state.addMerchantUploadStore &&
    state.addMerchantUploadStore.messageAMUpload,
  getMerchant: state && state.merchantStore && state.merchantStore.getMerchant,
  merchantLoading: state && state.merchantStore && state.merchantStore.loading,
  getIndustry: state && state.industryStore && state.industryStore.getIndustry,
  deleteMerchant:
    state &&
    state.merchantDeleteStore &&
    state.merchantDeleteStore.deleteMerchant,
  deleteMerchantStatus:
    state &&
    state.merchantDeleteStore &&
    state.merchantDeleteStore.deleteMerchantStatus,
  deleteMerchantMessage:
    state &&
    state.merchantDeleteStore &&
    state.merchantDeleteStore.deleteMerchantMessage,
  deleteMerchantLoading:
    state && state.merchantDeleteStore && state.merchantDeleteStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getCountryDispatch: (params, headers) =>
    dispatch(countryActions.getCountrys(params, headers)),
  getStateDispatch: (params, headers) =>
    dispatch(stateActions.getStates(params, headers)),
  getCityDispatch: (params, headers) =>
    dispatch(cityActions.getCitys(params, headers)),
  getIndustryDispatch: (params, headers) =>
    dispatch(industryActions.getIndustry(params, headers)),
  addMerchantUploadDispatch: (data) =>
    dispatch(addMerchantUploadActions.addMerchantUpload(data)),
  clearaddMerchantUploadDispatch: () =>
    dispatch(addMerchantUploadActions.clearaddMerchantUpload()),
  clearDeleteMerchantDispatch: () =>
    dispatch(deleteMerchantActions.clearDeleteMerchant()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantForm);
