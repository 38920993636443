import React, { useState, useEffect } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { connect } from 'react-redux'
import { KTSVG } from '../../theme/helpers'
import color from '../../utils/colors'
import _ from 'lodash'
import ReactSelect from '../../theme/layout/components/ReactSelect'
import { DateSelector } from '../../theme/layout/components/DateSelector'
import { DATE, REGEX, NOTIFICATION_TYPE, LAYOUT_DETAILS } from '../../utils/constants'
import { clientActions, partnerActions, merchantActions, bankActions, issuerbanklistActions, cityActions } from '../../store/actions'
import { getLocalStorage, setLocalStorage, headersWithAuth } from '../../utils/helper'
import { searchDefaultCols } from './fromData/showSearcFields'

function SearchList(props) {
  const {
    getClientDispatch,
    getClient,
    getPartnerDispatch,
    getPartner,
    getMerchantDispatch,
    getMerchant,
    getBankDispatch,
    getBank,
    getIssuerBankColumns,
    getCitys,
    getCityDispatch,
    setSearchParams,
    activePageNumber,
    setActivePageNumber,
    limit
  } = props
  const [selectedClientOption, setSelectedClientOption] = useState()
  const [clientOption, setClientOption] = useState()
  const [selectedPartnerOption, setSelectedPartnerOption] = useState()
  const [partnerOption, setPartnerOption] = useState()
  const [selectedMerchantOption, setSelectedMerchantOption] = useState()
  const [merchantOption, setMerchantOption] = useState()
  const [selectedBankOption, setSelectedBankOption] = useState()
  const [bankOption, setBankOption] = useState()
  const [selectedCityOption, setSelectedCityOption] = useState()
  const [selectedMerchantCityOption, setSelectedMerchantCityOption] = useState()
  const [cityOption, setCityOption] = useState()
  const [data, setData] = useState({})
  const [show, setShow] = useState(false)
  const d = new Date()
  const year = d.getFullYear()
  const month = d.getMonth()
  const day = d.getDate()

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? color.gray : color.black,
      background: state.isSelected ? color.white : ''
    })
  }

  const handleChangeClient = selectedOption => {
    if (selectedOption !== null) {
      setSelectedClientOption(selectedOption)
      setFormData(values => ({ ...values, clientId: selectedOption.value }))
    } else {
      setSelectedClientOption()
      setFormData(values => ({ ...values, clientId: '' }))
    }
  }

  const handleChangePartner = selectedOption => {
    if (selectedOption !== null) {
      setSelectedPartnerOption(selectedOption)
      setFormData(values => ({ ...values, partnerId: selectedOption.value }))
    } else {
      setSelectedPartnerOption()
      setFormData(values => ({ ...values, partnerId: '' }))
    }
  }

  const handleChangeMerchant = selectedOption => {
    if (selectedOption !== null) {
      setSelectedMerchantOption(selectedOption)
      setFormData(values => ({ ...values, merchantId: selectedOption.value }))
    } else {
      setSelectedMerchantOption()
      setFormData(values => ({ ...values, merchantId: '' }))
    }
  }

  useEffect(() => {
    const clientId = getDefaultOptions(getClient)
    setClientOption(clientId)
    if (!_.isEmpty(formData.clientId)) {
      const selOption = _.filter(clientId, function (x) { if (_.includes(formData.clientId._id, x.value)) { return x } })
      setSelectedClientOption(selOption)
    }
  }, [getClient])

  useEffect(() => {
    const data = getDefaultOption(getPartner)
    setPartnerOption(data)
  }, [getPartner])

  useEffect(() => {
    const merchantId = getDefaultOptionss(getMerchant)
    setMerchantOption(merchantId)
    if (!_.isEmpty(formData.merchantId)) {
      const selOption = _.filter(merchantId, function (x) { if (_.includes(formData.merchantId._id, x.value)) { return x } })
      setSelectedMerchantOption(selOption)
    }
  }, [getMerchant])

  const getDefaultOptions = (getClient) => {
    const defaultOptions = []
    if (!_.isEmpty(getClient)) {
      const data = getClient && getClient.data && getClient.data.result ? getClient.data.result : {}
      if (!_.isEmpty(data)) {
        data.map((item) => (
          defaultOptions.push({
            label: `${item.company ? item.company : ''}`,
            value: item._id
          })
        ))
      }
      return defaultOptions
    }
  }

  const getDefaultOption = (data) => {
    const defaultOptions = []
    if (!_.isEmpty(getPartner)) {
      if (!_.isEmpty(data)) {
        data.map((item) => (
          defaultOptions.push({
            label: `${item.partnerFirstName ? item.partnerFirstName : ''} ${item.partnerLastName
              ? item.partnerLastName
              : ''}`,
            value: item._id
          })
        ))
      }
      return defaultOptions
    }
  }

  const getDefaultOptionss = (getMerchant) => {
    const defaultOptions = []
    if (!_.isEmpty(getMerchant)) {
      const data = getMerchant && getMerchant.data && getMerchant.data.result ? getMerchant.data.result : {}
      if (!_.isEmpty(data)) {
        data.map((item) => (
          defaultOptions.push({
            label: `${item.merchantFirstName} ${item.merchantLastName}`, value: item._id
          })
        ))
      }
      return defaultOptions
    }
  }

  const handleChangeBank = selectedOption => {
    if (selectedOption !== null) {
      setSelectedBankOption(selectedOption)
      setFormData(values => ({ ...values, issuerBankId: selectedOption.value }))
    } else {
      setSelectedBankOption()
      setFormData(values => ({ ...values, issuerBankId: '' }))
    }
  }

  useEffect(() => {
    const issuerBankId = getDefaultBankOptions(getBank)
    setBankOption(issuerBankId)
    if (!_.isEmpty(formData.issuerBankId)) {
      const selOption = _.filter(issuerBankId, function (x) { if (_.includes(formData.issuerBankId._id, x.value)) { return x } })
      setSelectedBankOption(selOption)
    }
  }, [getBank])

  const getDefaultBankOptions = (getBank) => {
    const defaultOptions = []
    if (!_.isEmpty(getBank)) {
      const { data } = getBank
      if (!_.isEmpty(data)) {
        data.map((item) => (
          defaultOptions.push({ label: `${item.bankName}`, value: item._id })
        ))
      }
      return defaultOptions
    }
  }

  const handleChangeCity = selectedOption => {
    if (selectedOption !== null) {
      setSelectedCityOption(selectedOption)
      setFormData(values => ({ ...values, city: selectedOption.value }))
    } else {
      setSelectedCityOption()
      setFormData(values => ({ ...values, city: '' }))
    }
  }

  const handleChangeCityMerchant = selectedOption => {
    if (selectedOption !== null) {
      setSelectedMerchantCityOption(selectedOption)
      setFormData(values => ({ ...values, cityOfMerchant: selectedOption.value }))
    } else {
      setSelectedMerchantCityOption()
      setFormData(values => ({ ...values, cityOfMerchant: '' }))
    }
  }

  useEffect(() => {
    const city = getDefaultCityOptions(getCitys)
    setCityOption(city)
  }, [getCitys])

  const getDefaultCityOptions = (getCitys) => {
    const defaultOptions = []
    if (!_.isEmpty(getCitys)) {
      getCitys.map((item) => (
        defaultOptions.push({ label: `${item.name}`, value: item._id })
      ))
      return defaultOptions
    }
  }

  const [formData, setFormData] = useState({
    issuerBankId: '',
    clientId: '',
    merchantId: '',
    partnerId: '',
    alertId: '',
    alertDate: '',
    alertType: '',
    notificationType: '',
    transactionType: '',
    upiTransactionId: '',
    cardFirstSixDigits: '',
    cardLastFourDigits: '',
    cardBin: '',
    expirationMonth: '',
    expirationYear: '',
    transactionId: '',
    customerFirstName: '',
    customerLastName: '',
    alertDescriptor: '',
    alertDescriptorContact: '',
    refundedBeforeTime: '',
    outputResolutionDate: '',
    expirationStatus: '',
    isLastSixMonths: '',
    provider: '',
    cardNumber: '',
    salesTransactionDate: '',
    salesTransactionAmount: '',
    salesTransactionCurrency: '',
    customerAddress: '',
    acquirerReferrenceNumber: '',
    assignedToId: '',
    authCode: '',
    blacklistCustomer: '',
    cardVendor: '',
    chargeBackId: '',
    chargeBackReasonCode: '',
    chargeBackSaleDate: '',
    chargeBackStatus: '',
    city: '',
    cityOfMerchant: '',
    cardType: '',
    deflected: '',
    updatedDeflectionstatus: '',
    updatedDeflectionDate: '',
    status: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'amount' || name === 'ARN' || name === 'cardNumber' || name === 'transactionAmount' || name === 'creditCardNumber') {
      if (REGEX.NUMERIC.test(value)) {
        setFormData({ ...formData, [name]: value })
      } else {
        setFormData({ ...formData, [name]: '' })
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
  }

  const resetState = () => {
    setFormData({
      issuerBankId: '',
      clientId: '',
      merchantId: '',
      partnerId: '',
      alertId: '',
      alertDate: '',
      alertType: '',
      notificationType: '',
      transactionType: '',
      upiTransactionId: '',
      cardFirstSixDigits: '',
      cardLastFourDigits: '',
      cardBin: '',
      expirationMonth: '',
      expirationYear: '',
      transactionId: '',
      customerFirstName: '',
      customerLastName: '',
      alertDescriptor: '',
      alertDescriptorContact: '',
      refundedBeforeTime: '',
      outputResolutionDate: '',
      expirationStatus: '',
      isLastSixMonths: '',
      provider: '',
      cardNumber: '',
      salesTransactionDate: '',
      salesTransactionAmount: '',
      salesTransactionCurrency: '',
      customerAddress: '',
      acquirerReferrenceNumber: '',
      assignedToId: '',
      authCode: '',
      blacklistCustomer: '',
      cardVendor: '',
      chargeBackId: '',
      chargeBackReasonCode: '',
      chargeBackSaleDate: '',
      chargeBackStatus: '',
      city: '',
      cityOfMerchant: '',
      cardType: '',
      deflected: '',
      updatedDeflectionstatus: '',
      updatedDeflectionDate: '',
      status: ''
    })
    setSelectedMerchantCityOption()
    setSelectedCityOption()
    setSelectedPartnerOption()
    setSelectedBankOption()
    setSelectedMerchantOption()
  }

  const handleSearch = () => {
    const params = {
      limit: limit,
      page: 1
    }
    setActivePageNumber(1)
    for (const key in formData) {
      if (
        Object.prototype.hasOwnProperty.call(formData, key) &&
        formData[key] !== ""
      ) {
        params[key] = formData[key];
      }
    }
    const result = _.omit(params, ['limit', 'page']);
    setSearchParams(_.pickBy(result))
    setShow(false)
    getIssuerBankColumns(params, headersWithAuth)
    resetState()
  }

  const handleReset = () => {
    resetState()
    setSearchParams()
    const params = {
      limit: limit,
      page: activePageNumber,
    }
    getIssuerBankColumns(params, headersWithAuth)
  }

  const params = {
    skipPagination: true
  }

  useEffect(() => {
    if (show) {
      const localSearchData = JSON.parse(getLocalStorage(LAYOUT_DETAILS.SEARCH_IBA))
      getClientDispatch(params, headersWithAuth)
      getPartnerDispatch(params, headersWithAuth)
      getMerchantDispatch(params, headersWithAuth)
      getBankDispatch(params, headersWithAuth)
      getCityDispatch(params, headersWithAuth)
      if (!_.isEmpty(localSearchData)) {
        setData(localSearchData)
      } else {
        setLocalStorage(LAYOUT_DETAILS.SEARCH_IBA, JSON.stringify(searchDefaultCols))
        setData(searchDefaultCols)
      }
    }
  }, [show])

  const tenYearFromNow = new Date(year + 10, month, day)
  const oneYearBefore = new Date(year - 1, month, day)
  const oneMonthBefore = new Date(year, month - 1, day)

  const handleTrimWhiteSpace = (e, setState) => {
    const { name, value } = e.target
    if (value && value.length > 1) {
      const getData = value.replace(/ +/g, ' ')
      setState((values) => ({ ...values, [name]: getData.trim() }))
    } else {
      setState((values) => ({ ...values, [name]: '' }))
    }
  }

  return (
    <>
      <div>
        <button
          type='button'
          className='btn btn-sm btn-light-primary btn-responsive font-5vw me-3 pull-right'
          data-toggle='modal'
          data-target='#searchModal'
          onClick={() => { setShow(true) }}
        >
          {/* eslint-disable */}
          <KTSVG path='/media/icons/duotune/general/gen021.svg' />
          {/* eslint-disable */}
          Advanced Search
        </button>
      </div>
      <div
        className='modal fade'
        id='searchModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden="''"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className='modal-dialog modal-dialog-centered mw-1500px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='me-8'>Advanced Search</h2>
              <button
                type='button'
                className='btn btn-lg btn-icon btn-active-light-primary close'
                data-dismiss='modal'
                onClick={() => {
                  setShow(false)
                  resetState()
                }}
              >
                {/* eslint-disable */}
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                {/* eslint-disable */}
              </button>
            </div>
            <div className='modal-body'>
              <form className='container-fixed'>
                <div className='card-header bg-lightBlue'>
                  <div className='card-body'>
                    <div className='form-group row mb-4'>
                      {data && data.alertId &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Alert Id:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='alertId'
                              type='text'
                              className='form-control'
                              placeholder='Alert Id'
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              value={formData.alertId || ''}
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.issuerBankId &&
                        <div className='col-lg-3 mb-3' >
                          <label className='font-size-xsfont-weight-bold mb-3 form-label'>
                            Issuer Bank:
                          </label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='issuerBankId'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeBank}
                              options={bankOption}
                              value={selectedBankOption}
                            />
                          </div>
                        </div>
                      }
                      {data && data.alertDate &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Alert Date:
                          </label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='alertDate'
                              type='date'
                              placeholder='Alert Date'
                              className='form-control'
                              selected={formData.alertDate || ''}
                              maxDate={formData.alertDate || new Date()}
                              onChange={(date) => {
                                setFormData((values) => ({
                                  ...values,
                                  alertDate: date,
                                }))
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                            />
                          </div>
                        </div>
                      }
                      {data && data.alertType &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Alert Type:
                          </label>
                          <div className='col-lg-11'>
                            <select
                              name='alertType'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.alertType || ''}
                            >
                              <option value=''>Select...</option>
                              <option value='Inquiry'>Inquiry</option>
                              <option value='chargeback'>Chargeback</option>
                            </select>
                          </div>
                        </div>
                      }
                      {data && data.notificationType &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Notification Type:
                          </label>
                          <div className='col-lg-11'>
                            <select
                              name='notificationType'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={handleChange}
                              value={formData.notificationType}
                            >
                              <option value={NOTIFICATION_TYPE.SELECT}>Select... </option>
                              <option value={NOTIFICATION_TYPE.BLANK_SPACE}>{NOTIFICATION_TYPE.BLANK_SPACE}</option>
                              <option value={NOTIFICATION_TYPE.CANCEL_REQURRING}>{NOTIFICATION_TYPE.CANCEL_REQURRING}</option>
                              <option value={NOTIFICATION_TYPE.CHARGEBACK}>{NOTIFICATION_TYPE.CHARGEBACK}</option>
                              <option value={NOTIFICATION_TYPE.EXCEPTION_UNKOWN}>{NOTIFICATION_TYPE.EXCEPTION_UNKOWN}</option>
                              <option value={NOTIFICATION_TYPE.FRAUD_ALERT}>{NOTIFICATION_TYPE.FRAUD_ALERT}</option>
                            </select>
                          </div>
                        </div>
                      }
                      {data && data.transactionType &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Transaction Type:
                          </label>
                          <div className='col-lg-11'>
                            <select
                              name='transactionType'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.transactionType || ''}
                            >
                              <option value=''>Select...</option>
                              <option value='UPI'>UPI Transaction</option>
                              <option value='Card'>Card Transaction</option>
                            </select>
                          </div>
                        </div>
                      }
                      {data && data.upiTransactionId &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            UPI Transaction Id :
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='upiTransactionId'
                              type='text'
                              className='form-control'
                              placeholder='UPI Transaction Id'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.upiTransactionId || ''}
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.cardBin &&
                        <div className='col-lg-3 mb-2'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Card Bin:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='cardBin'
                              type='text'
                              className='form-control'
                              placeholder='Card Bin'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.cardBin || ''}
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.transactionId &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Transaction Id:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='transactionId'
                              type='text'
                              className='form-control'
                              placeholder='Transaction Id'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              maxLength={42}
                              autoComplete='off'
                              value={formData.transactionId || ''}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.customerFirstName &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Customer First Name:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='customerFirstName'
                              type='text'
                              className='form-control'
                              placeholder='Customer First Name'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.customerFirstName || ''}
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_CHARS_SPACE.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.customerLastName &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Customer Last Name:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='customerLastName'
                              type='text'
                              className='form-control'
                              placeholder='Customer Last Name'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.customerLastName || ''}
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_CHARS_SPACE.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.alertDescriptor &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Alert Descriptor:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='alertDescriptor'
                              type='text'
                              className='form-control'
                              placeholder='Alert Descriptor'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.alertDescriptor || ''}
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.alertDescriptorContact &&
                        <div className='col-lg-3 mb-3' >
                          <label className='font-size-xsfont-weight-bold mb-3 form-label'>
                            Alert Descriptor Contact:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='alertDescriptorContact'
                              value={formData.alertDescriptorContact || ''}
                              type='text'
                              className='form-control'
                              placeholder='Descriptor Contact'
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              maxLength={10}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.cardFirstSixDigits &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Card First 6 Digits:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='cardFirstSixDigits'
                              value={formData.cardFirstSixDigits || ''}
                              type='text'
                              className='form-control'
                              placeholder='Card First 6 Digit'
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              maxLength={6}
                              onChange={(e) => handleChange(e)}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.cardLastFourDigits &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Card Last 4 Digits:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='cardLastFourDigits'
                              value={formData.cardLastFourDigits || ''}
                              type='text'
                              className='form-control'
                              placeholder='Card Last 4 Digit'
                              autoComplete='off'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              maxLength={4}
                              onChange={(e) => handleChange(e)}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.expirationMonth &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Expiration Month:
                          </label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='expirationMonth'
                              placeholder='Expiration Month'
                              className='form-control'
                              selected={formData.expirationMonth || ''}
                              autoComplete='off'
                              maxLength={2}
                              onChange={(date) => {
                                setFormData((values) => ({
                                  ...values,
                                  expirationMonth: date,
                                }))
                              }}
                              dateFormat="MM"
                              minDate={oneMonthBefore}
                              showMonthYearPicker
                            />
                          </div>
                        </div>
                      }
                      {data && data.expirationYear &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Expiration Year:
                          </label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='expirationYear'
                              placeholder='Expiration Month'
                              className='form-control'
                              selected={formData.expirationYear || ''}
                              autoComplete='off'
                              onChange={(date) => {
                                setFormData((values) => ({
                                  ...values,
                                  expirationYear: date,
                                }))
                              }}
                              dateFormat="yyyy"
                              minDate={oneYearBefore}
                              maxDate={tenYearFromNow}
                              yearRange='+10y'
                              showYearPicker
                            />
                          </div>
                        </div>
                      }
                      {data && data.partnerId &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Partner:
                          </label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='partnerId'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangePartner}
                              options={partnerOption}
                              value={selectedPartnerOption}
                            />
                          </div>
                        </div>
                      }
                      {data && data.merchantId &&
                        <div className='col-lg-3 mb-2'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Merchant:
                          </label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='merchantId'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeMerchant}
                              options={merchantOption}
                              value={selectedMerchantOption}
                            />
                          </div>
                        </div>
                      }
                      {data && data.clientId &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Company:
                          </label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='clientId'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeClient}
                              options={clientOption}
                              value={selectedClientOption}
                            />
                          </div>
                        </div>
                      }
                      {data && data.refundedBeforeTime &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xsfont-weight-bold mb-3  form-label'>
                            Refunded Before Time:
                          </label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='refundedBeforeTime'
                              placeholder='Refunded Before Time'
                              className='form-control'
                              selected={formData.refundedBeforeTime || ''}
                              onChange={(date) => {
                                setFormData((values) => ({
                                  ...values,
                                  refundedBeforeTime: date,
                                }))
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                            />
                          </div>
                        </div>
                      }
                      {data && data.outputResolutionDate &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Output Resolution Date:
                          </label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='outputResolutionDate'
                              placeholder='Output Resolution Date'
                              className='form-control'
                              selected={formData.outputResolutionDate || ''}
                              onChange={(date) => {
                                setFormData((values) => ({
                                  ...values,
                                  outputResolutionDate: date,
                                }))
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                            />
                          </div>
                        </div>
                      }
                      {data && data.expirationStatus &&
                        <div className='col-lg-3 mb-2'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Expiration Status:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='expirationStatus'
                              type='text'
                              className='form-control'
                              placeholder='Expiration Status'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.expirationStatus || ''}
                            />
                          </div>
                        </div>
                      }
                      {data && data.isLastSixMonths &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Is Last Six Months:
                          </label>
                          <div className='col-lg-11'>
                            <select
                              name='isLastSixMonths'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.isLastSixMonths || ''}
                            >
                              <option value=''>Select...</option>
                              <option value='YES'>Yes</option>
                              <option value='NO'>No</option>
                            </select>
                          </div>
                        </div>
                      }
                      {data && data.provider &&
                        <div className='col-lg-3 mb-2'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Provider:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='provider'
                              type='text'
                              className='form-control'
                              placeholder='Provider'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.provider || ''}
                              maxLength={42}
                              autoComplete='off'
                              onCopy={(e) => {
                                e.preventDefault()
                                return false
                              }}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_CHARS_SPACE.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.cardNumber &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Card Number:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='cardNumber'
                              type='text'
                              className='form-control'
                              placeholder='Card Number'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.cardNumber || ''}
                              maxLength={15}
                            />
                          </div>
                        </div>
                      }
                      {data && data.salesTransactionDate &&
                        <div className='col-lg-3 mb-2'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Sales Transaction Date:
                          </label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='salesTransactionDate'
                              placeholder='Sale Transaction Date'
                              className='form-control'
                              selected={formData.salesTransactionDate || ''}
                              autoComplete='off'
                              onChange={(date) => {
                                setFormData((values) => ({
                                  ...values,
                                  salesTransactionDate: date,
                                }))
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                            />
                          </div>
                        </div>
                      }
                      {data && data.salesTransactionAmount &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Sales Transaction Amount:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='salesTransactionAmount'
                              type='text'
                              className='form-control'
                              placeholder='Sales Transaction Amount'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.salesTransactionAmount || ''}
                              maxLength={42}
                              autoComplete='off'
                              onKeyPress={(e) => {
                                if (!/^[0-9@#^\-_$.]*$/.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.salesTransactionCurrency &&
                        <div className='col-lg-3 mb-2'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Sales Transaction Currency:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='salesTransactionCurrency'
                              type='text'
                              className='form-control'
                              placeholder='Sales Transaction Currency'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.salesTransactionCurrency || ''}
                              maxLength={42}
                            />
                          </div>
                        </div>
                      }
                      {data && data.customerAddress &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Customer Address:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='customerAddress'
                              type='text'
                              className='form-control'
                              placeholder='Customer Address'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.customerAddress || ''}
                            />
                          </div>
                        </div>
                      }
                      {data && data.acquirerReferrenceNumber &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            ARN:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='acquirerReferrenceNumber'
                              type='text'
                              className='form-control'
                              placeholder='ARN'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.acquirerReferrenceNumber || ''}
                              autoComplete='off'
                              maxLength={25}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.assignedToId &&
                        <div className='col-lg-3 mb-2'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Assigned ToId:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='assignedToId'
                              type='text'
                              className='form-control'
                              placeholder='Assigned ToId'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.assignedToId || ''}
                            />
                          </div>
                        </div>
                      }
                      {data && data.authCode &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Auth Code:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='authCode'
                              type='text'
                              className='form-control'
                              placeholder='Auth Code'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.authCode || ''}
                              autoComplete='off'
                              maxLength={15}
                              onPaste={(e) => {
                                e.preventDefault()
                                return false
                              }}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.blacklistCustomer &&
                        <div className='col-lg-3 mb-2'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Blacklist Customer:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='blacklistCustomer'
                              type='text'
                              className='form-control'
                              placeholder='Blacklist Customer'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.blacklistCustomer || ''}
                              onPaste={(e) => {
                                e.preventDefault()
                                return false
                              }}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_CHARS_SPACE.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.cardVendor &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Card Vendor:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='cardVendor'
                              type='text'
                              className='form-control'
                              placeholder='Card Vendor'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.cardVendor || ''}
                              maxLength={42}
                              onKeyPress={(e) => {
                                if (!REGEX.ALPHA_NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.chargeBackId &&
                        <div className='col-lg-3 mb-2'>
                          <label className='font-size-xs text-darkfont-weight-bold mb-3  form-label'>
                            Charge Back Id:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='chargeBackId'
                              type='text'
                              className='form-control'
                              placeholder='Charge Back Id'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.chargeBackId || ''}
                              maxLength={50}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.chargeBackReasonCode &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Charge Back Reason Code:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='chargeBackReasonCode'
                              type='text'
                              className='form-control'
                              placeholder='Charge Back Reason Code'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.chargeBackReasonCode || ''}
                              onKeyPress={(e) => {
                                if (!REGEX.NUMERIC.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      }
                      {data && data.chargeBackSaleDate &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Charge Back Sale Date:
                          </label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='chargeBackSaleDate'
                              placeholder='Chargeback Sale Date'
                              className='form-control'
                              selected={formData.chargeBackSaleDate || ''}
                              onChange={(date) => {
                                setFormData((values) => ({
                                  ...values,
                                  chargeBackSaleDate: date,
                                }))
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                            />
                          </div>
                        </div>
                      }
                      {data && data.chargeBackStatus &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Charge Back Status:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='chargeBackStatus'
                              type='text'
                              className='form-control'
                              placeholder='Charge Back Status'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              value={formData.chargeBackStatus || ''}
                            />
                          </div>
                        </div>
                      }
                      {data && data.city &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            City:
                          </label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='city'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeCity}
                              options={cityOption}
                              value={selectedCityOption}
                            />
                          </div>
                        </div>
                      }
                      {data && data.cityOfMerchant &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            City Of Merchant:
                          </label>
                          <div className='col-lg-11'>
                            <ReactSelect
                              isClearable
                              styles={customStyles}
                              isMulti={false}
                              name='cityOfMerchant'
                              className='basic-single'
                              classNamePrefix='select'
                              handleChangeReactSelect={handleChangeCityMerchant}
                              options={cityOption}
                              value={selectedMerchantCityOption}
                            />
                          </div>
                        </div>
                      }
                      {data && data.cardType &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Card Type:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='cardType'
                              type='text'
                              className='form-control'
                              placeholder='Card Type'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.cardType || ''}
                            />
                          </div>
                        </div>
                      }
                      {data && data.deflected &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Deflected:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='deflected'
                              type='text'
                              className='form-control'
                              placeholder='Deflected'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.deflected || ''}
                            />
                          </div>
                        </div>
                      }
                      {data && data.updatedDeflectionstatus &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Updated Deflection Status:
                          </label>
                          <div className='col-lg-11'>
                            <input
                              name='updatedDeflectionstatus'
                              type='text'
                              className='form-control'
                              onBlur={e => handleTrimWhiteSpace(e, setFormData)}
                              placeholder='Updated Deflection Status'
                              onChange={(e) => handleChange(e)}
                              autoComplete='off'
                              value={formData.updatedDeflectionstatus || ''}
                            />
                          </div>
                        </div>
                      }
                      {data && data.updatedDeflectionDate &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Updated Deflection Date:
                          </label>
                          <div className='col-lg-11'>
                            <DateSelector
                              name='updatedDeflectionDate'
                              placeholder='Updated Deflection Date'
                              className='form-control'
                              selected={formData.updatedDeflectionDate || ''}
                              onChange={(date) => {
                                setFormData((values) => ({
                                  ...values,
                                  updatedDeflectionDate: date,
                                }))
                              }}
                              dateFormat={DATE.DATE_FOR_PICKER}
                            />
                          </div>
                        </div>
                      }
                      {data && data.status &&
                        <div className='col-lg-3 mb-3'>
                          <label className='font-size-xs font-weight-bold mb-3  form-label'>
                            Status:
                          </label>
                          <div className='col-lg-11'>
                            <select
                              name='status'
                              className='form-select form-select-solid'
                              data-control='select'
                              data-placeholder='Select an option'
                              data-allow-clear='true'
                              onChange={(e) => handleChange(e)}
                              value={formData.status || ''}
                            >
                              <option value=''>Select...</option>
                              <option value='ACTIVE'>ACTIVE</option>
                              <option value='INACTIVE'>INACTIVE</option>
                            </select>
                          </div>
                        </div>
                      }
                      <div className='form-group row mb-4'>
                        <div className='col-lg-6' />
                        <div className='col-lg-6'>
                          <div className='col-lg-11'>
                            <button
                              type='button'
                              className='btn btn-sm btn-light-primary m-2 fa-pull-right'
                              onClick={() => handleSearch()}
                              data-dismiss='modal'
                            >
                              Search
                            </button>
                            <button
                              type='button'
                              className='btn btn-sm btn-light-danger m-2 fa-pull-right close'
                              onClick={() => handleReset()}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = state => ({
  getClient: state && state.clientStore && state.clientStore.getClient,
  loading: state && state.clientStore && state.clientStore.loading,
  getPartner: state && state.partnerStore && state.partnerStore.getPartner,
  loading: state && state.partnerStore && state.partnerStore.loading,
  getMerchant: state && state.merchantStore && state.merchantStore.getMerchant,
  loading: state && state.merchantStore && state.merchantStore.loading,
  getBank: state && state.bankStore && state.bankStore.getBank,
  loading: state && state.bankStore && state.bankStore.loading,
  getCitys: state && state.cityStore && state.cityStore.getCitys
})

const mapDispatchToProps = dispatch => ({
  getClientDispatch: (params, headers) => dispatch(clientActions.getClient(params, headers)),
  clientActions: () => dispatch(clientActions.getClient()),
  getPartnerDispatch: (params, headers) => dispatch(partnerActions.getPartner(params, headers)),
  partnerActions: () => dispatch(partnerActions.getPartner()),
  getIssuerBankColumns: (data, headers) =>
    dispatch(issuerbanklistActions.getIssuerbanklist(data, headers)),
  getMerchantDispatch: (params, headers) => dispatch(merchantActions.getMerchant(params, headers)),
  merchantActions: () => dispatch(merchantActions.getMerchant()),
  getBankDispatch: (data, headers) => dispatch(bankActions.getBank(data, headers)),
  bankActions: () => dispatch(bankActions.getBank()),
  getCityDispatch: (params, headers) => dispatch(cityActions.getCitys(params, headers)),
  cityActions: () => dispatch(cityActions.getCitys())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchList)