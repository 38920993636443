import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  getSubscriptionHistoryActionsTypes,
  getSubscriptionHistoryActions,
  addSubscriptionHistoryActionsTypes,
  addSubscriptionHistoryActions,
  getSubscriptionHistoryDetailsActionsTypes,
  getSubscriptionHistoryDetailsActions,
  updateSubscriptionHistoryActionsTypes,
  updateSubscriptionHistoryActions,
  deleteSubscriptionHistoryActionsTypes,
  deleteSubscriptionHistoryActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * fetchSubscriptionHistory (actions) {
  const { params, headers } = actions
  try {
    const json = yield axiosInstance.get(serviceList.userSubscriptionHistory, {
      params,
      headers: headers
    })
    if (json.status === 200) {
      yield put(getSubscriptionHistoryActions.saveResponse(json.data))
    } else {
      yield put(getSubscriptionHistoryActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getSubscriptionHistoryActionsTypes.RESPONSE, data })
  }
}

function * addSubscriptionHistory (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.userSubscriptionHistory, payload, {headers: headers})
    if (json.status === 200) {
      yield put(addSubscriptionHistoryActions.saveResponse(json.data))
    } else {
      yield put(addSubscriptionHistoryActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addSubscriptionHistoryActionsTypes.RESPONSE, data })
  }
}

function * getSubscriptionHistoryDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.userSubscriptionHistory}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers: headers})
    if (json.status === 200) {
      yield put(getSubscriptionHistoryDetailsActions.saveResponse(json.data))
    } else {
      yield put(getSubscriptionHistoryDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getSubscriptionHistoryDetailsActionsTypes.RESPONSE, data })
  }
}

function * updateSubscriptionHistory (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.userSubscriptionHistory}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers: headers})
    if (json.status === 200) {
      yield put(updateSubscriptionHistoryActions.saveResponse(json.data))
    } else {
      yield put(updateSubscriptionHistoryActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updateSubscriptionHistoryActionsTypes.RESPONSE, data })
  }
}

function * deleteSubscriptionHistory (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.userSubscriptionHistory}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers: headers})
    if (json.status === 200) {
      yield put(deleteSubscriptionHistoryActions.savemResponse(json.data))
    } else {
      yield put(deleteSubscriptionHistoryActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteSubscriptionHistoryActionsTypes.RESPONSE, data })
  }
}

export function * fetchSubscriptionHistoryWatcher () {
  yield all([
    yield takeLatest(getSubscriptionHistoryActionsTypes.REQUEST, fetchSubscriptionHistory),
    yield takeLatest(addSubscriptionHistoryActionsTypes.REQUEST, addSubscriptionHistory),
    yield takeLatest(
      getSubscriptionHistoryDetailsActionsTypes.REQUEST,
      getSubscriptionHistoryDetails
    ),
    yield takeLatest(updateSubscriptionHistoryActionsTypes.REQUEST, updateSubscriptionHistory),
    yield takeLatest(deleteSubscriptionHistoryActionsTypes.REQUEST, deleteSubscriptionHistory)
  ])
}