export const addUserroleTypes = {
  REQUEST: 'ADD_USER_ROLE_REQUEST',
  RESPONSE: 'ADD_USER_ROLE_RESPONSE',
  ERROR: 'ADD_USER_ROLE_ERROR',
  CLEAR: 'ADD_USER_ROLE_CLEAR'
}

export const addUserroleActions = {
  addUserrole: (data, headers) => {
    return {
      type: addUserroleTypes.REQUEST,
      payload: data, headers
    }
  },
  saveaddUserroleResponse: (data) => {
    return {
      type: addUserroleTypes.RESPONSE,
      data
    }
  },
  clearaddUserrole: () => ({
    type: addUserroleTypes.CLEAR
  })
}

export const userRoleActionsTypes = {
  GET_USER_ROLE: 'FETCH_USER_ROLE',
  SAVE_USER_ROLE_RESPONSE: 'SAVE_FETCHED_USER_ROLE_RESPONSE',
  CLEAR_USER_ROLE: 'CLEAR_FETCHED_USER_ROLE'
}

export const userRolesActions = {
  getUserrole: (params, headers) => ({
    type: userRoleActionsTypes.GET_USER_ROLE,
    params, headers
  }),
  saveUserroleResponse: data => ({
    type: userRoleActionsTypes.SAVE_USER_ROLE_RESPONSE,
    data
  }),
  clearUserrole: () => ({
    type: userRoleActionsTypes.CLEAR_USER_ROLE
  })
}

export const userTypeActionsTypes = {
  REQUEST: 'GET_USER_TYPE',
  RESPONSE: 'SAVE_USER_TYPE_RESPONSE',
  CLEAR: 'CLEAR_USER_TYPE'
}

export const userTypesActions = {
  getUserType: (params, headers) => ({
    type: userTypeActionsTypes.REQUEST,
    params, headers
  }),
  saveUserTypeResponse: data => ({
    type: userTypeActionsTypes.RESPONSE,
    data
  }),
  clearUserType: () => ({
    type: userTypeActionsTypes.CLEAR
  })
}

export const userRoleGetDetailsTypes = {
  GET_USER_ROLE_DETAILS: 'GET_USER_ROLE_DETAILS',
  USER_ROLE_DETAILS_RESPONSE: 'USER_ROLE_DETAILS_RESPONSE',
  CLEAR_USER_ROLE_DETAILS: 'CLEAR_USER_ROLE_DETAILS'
}
export const userRoleDetailsActions = {
  getUserroleDetails: (id, headers) => ({
    type: userRoleGetDetailsTypes.GET_USER_ROLE_DETAILS,
    id, headers
  }),
  saveUserroleDetailsResponse: data => ({
    type: userRoleGetDetailsTypes.USER_ROLE_DETAILS_RESPONSE,
    data
  }),
  clearUserroleDetails: () => ({
    type: userRoleGetDetailsTypes.CLEAR_USER_ROLE_DETAILS
  })
}

export const editUserroleTypes = {
  REQUEST: 'EDIT_USER_ROLE_REQUEST',
  RESPONSE: 'EDIT_USER_ROLE_RESPONSE',
  ERROR: 'EDIT_USER_ROLE_ERROR',
  CLEAR: 'EDIT_USER_ROLE_CLEAR'
}

export const editUserrolesActions = {
  editUserroles: (id, data, headers) => {
    return {
      type: editUserroleTypes.REQUEST,
      payload: { id, data },
      headers
    }
  },
  saveeditUserrolesResponse: (data) => {
    return {
      type: editUserroleTypes.RESPONSE,
      data
    }
  },
  cleareditUserroles: () => ({
    type: editUserroleTypes.CLEAR
  })
}

export const userRoleDeleteTypes = {
  DELETE_USER_ROLE: 'DELETE_USER_ROLE',
  SAVE_DELETE_USER_ROLE_RESPONSE: 'SAVE_DELETE_USER_ROLE_RESPONSE',
  CLEAR_DELETE_USER_ROLE: 'CLEAR_DELETE_USER_ROLE'
}

export const deleteUserroleActions = {
  deleteUserrole: (params, headers) => ({
    type: userRoleDeleteTypes.DELETE_USER_ROLE,
    params, headers
  }),
  savemResponseDeleteUserrole: data => ({
    type: userRoleDeleteTypes.SAVE_DELETE_USER_ROLE_RESPONSE,
    data
  }),
  clearDeleteUserrole: () => ({
    type: userRoleDeleteTypes.CLEAR_DELETE_USER_ROLE
  })
}
