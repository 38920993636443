export const addCoverletterTypes = {
  REQUEST: 'ADD_COVER_LETTER_REQUEST',
  RESPONSE: 'ADD_COVER_LETTER_RESPONSE',
  ERROR: 'ADD_COVER_LETTER_ERROR',
  CLEAR: 'ADD_COVER_LETTER_CLEAR'
}

export const addCoverletterActions = {
  addCoverletter: (data, headers) => {
    return {
      type: addCoverletterTypes.REQUEST,
      payload: data, headers
    }
  },
  saveaddCoverletterResponse: (data) => {
    return {
      type: addCoverletterTypes.RESPONSE,
      data
    }
  },
  clearaddCoverletter: () => ({
    type: addCoverletterTypes.CLEAR
  })
}

export const coverLetterActionsTypes = {
  GET_COVER_LETTER: 'GET_COVER_LETTER',
  SAVE_COVER_LETTER_RESPONSE: 'SAVE_COVER_LETTER_RESPONSE',
  CLEAR_COVER_LETTER: 'CLEAR_COVER_LETTER'
}

export const coverLetterActions = {
  getCoverletter: (params, headers) => ({
    type: coverLetterActionsTypes.GET_COVER_LETTER,
    params, headers
  }),
  saveCoverletterResponse: data => ({
    type: coverLetterActionsTypes.SAVE_COVER_LETTER_RESPONSE,
    data
  }),
  clearCoverletter: () => ({
    type: coverLetterActionsTypes.CLEAR_COVER_LETTER
  })
}

export const coverLetterGetDetailsTypes = {
  GET_COVER_LETTER_DETAILS: 'GET_COVER_LETTER_DETAILS',
  COVER_LETTER_DETAILS_RESPONSE: 'COVER_LETTER_DETAILS_RESPONSE',
  CLEAR_COVER_LETTER_DETAILS: 'CLEAR_COVER_LETTER_DETAILS'
}
export const coverLetterDetailsActions = {
  getCoverletterDetails: (id, headers) => ({
    type: coverLetterGetDetailsTypes.GET_COVER_LETTER_DETAILS,
    id, headers
  }),
  saveCoverletterDetailsResponse: data => ({
    type: coverLetterGetDetailsTypes.COVER_LETTER_DETAILS_RESPONSE,
    data
  }),
  clearCoverletterDetails: () => ({
    type: coverLetterGetDetailsTypes.CLEAR_COVER_LETTER_DETAILS
  })
}

export const editCoverletterTypes = {
  REQUEST: 'EDIT_COVER_LETTER_REQUEST',
  RESPONSE: 'EDIT_COVER_LETTER_RESPONSE',
  ERROR: 'EDIT_COVER_LETTER_ERROR',
  CLEAR: 'EDIT_COVER_LETTER_CLEAR'
}

export const editCoverlettersActions = {
  editCoverletters: (id, params, headers) => {
    return {
      type: editCoverletterTypes.REQUEST,
      payload: { id, params },
      headers
    }
  },
  saveeditCoverlettersResponse: (data) => {
    return {
      type: editCoverletterTypes.RESPONSE,
      data
    }
  },
  cleareditCoverletters: () => ({
    type: editCoverletterTypes.CLEAR
  })
}

export const coverLetterDeleteTypes = {
  DELETE_COVER_LETTER: 'DELETE_COVER_LETTER',
  SAVE_DELETE_COVER_LETTER_RESPONSE: 'SAVE_DELETE_COVER_LETTER_RESPONSE',
  CLEAR_DELETE_COVER_LETTER: 'CLEAR_DELETE_COVER_LETTER'
}

export const deleteCoverletterActions = {
  deleteCoverletter: (params, headers) => ({
    type: coverLetterDeleteTypes.DELETE_COVER_LETTER,
    params, headers
  }),
  savemResponseDeleteCoverletter: data => ({
    type: coverLetterDeleteTypes.SAVE_DELETE_COVER_LETTER_RESPONSE,
    data
  }),
  clearDeleteCoverletter: () => ({
    type: coverLetterDeleteTypes.CLEAR_DELETE_COVER_LETTER
  })
}

export const reasonGroupingActionsTypes = {
  GET_REASON_GROUPING: 'GET_REASON_GROUPING',
  SAVE_REASON_GROUPING_RESPONSE: 'SAVE_REASON_GROUPING_RESPONSE',
  CLEAR_REASON_GROUPING: 'CLEAR_REASON_GROUPING'
}

export const reasonGroupingActions = {
  getReasonGrouping: (params, headers) => ({
    type: reasonGroupingActionsTypes.GET_REASON_GROUPING,
    params, headers
  }),
  saveReasonGroupingResponse: data => ({
    type: reasonGroupingActionsTypes.SAVE_REASON_GROUPING_RESPONSE,
    data
  }),
  clearReasonGrouping: () => ({
    type: reasonGroupingActionsTypes.CLEAR_REASON_GROUPING
  })
}

export const serviceTypeActionsTypes = {
  GET_SERVICE_TYPE: 'GET_SERVICE_TYPE',
  SAVE_SERVICE_TYPE_RESPONSE: 'SAVE_SERVICE_TYPE_RESPONSE',
  CLEAR_SERVICE_TYPE: 'CLEAR_SERVICE_TYPE'
}

export const serviceTypeActions = {
  getServicetype: (params, headers) => ({
    type: serviceTypeActionsTypes.GET_SERVICE_TYPE,
    params, headers
  }),
  saveServicetypeResponse: data => ({
    type: serviceTypeActionsTypes.SAVE_SERVICE_TYPE_RESPONSE,
    data
  }),
  clearServicetype: () => ({
    type: serviceTypeActionsTypes.CLEAR_SERVICE_TYPE
  })
}
