import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addProcessorSettingsActionsTypes,
  addProcessorSettingsActions,
  getProcessorSettingsDetailsActionsTypes,
  getProcessorSettingsDetailsActions,
  updateProcessorSettingsActionsTypes,
  updateProcessorSettingsActions,
  deleteProcessorSettingsActionsTypes,
  deleteProcessorSettingsActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addProcessorSettings (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.ProcessorSettings, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addProcessorSettingsActions.saveResponse(json.data))
    } else {
      yield put(addProcessorSettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addProcessorSettingsActionsTypes.RESPONSE, data })
  }
}

function * getProcessorSettingsDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.ProcessorSettings}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, { headers: headers })
    if (json.status === 200) {
      yield put(
        getProcessorSettingsDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getProcessorSettingsDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getProcessorSettingsDetailsActionsTypes.RESPONSE, data })
  }
}

function * updateProcessorSettings (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.ProcessorSettings}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, { headers: headers })
    if (json.status === 200) {
      yield put(updateProcessorSettingsActions.saveResponse(json.data))
    } else {
      yield put(updateProcessorSettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updateProcessorSettingsActionsTypes.RESPONSE, data })
  }
}

function * deleteProcessorSettings (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.ProcessorSettings}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, { headers: headers })
    if (json.status === 200) {
      yield put(deleteProcessorSettingsActions.savemResponse(json.data))
    } else {
      yield put(deleteProcessorSettingsActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteProcessorSettingsActionsTypes.RESPONSE, data })
  }
}

export function * fetchProcessorSettingsWatcher () {
  yield all([
    yield takeLatest(addProcessorSettingsActionsTypes.REQUEST, addProcessorSettings),
    yield takeLatest(getProcessorSettingsDetailsActionsTypes.REQUEST, getProcessorSettingsDetails),
    yield takeLatest(updateProcessorSettingsActionsTypes.REQUEST, updateProcessorSettings),
    yield takeLatest(deleteProcessorSettingsActionsTypes.REQUEST, deleteProcessorSettings)
  ])
}
