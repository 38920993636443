import {
  addReasoncodeTypes,
  ReasoncodeActionsTypes,
  ReasoncodeGetDetailsTypes,
  editReasoncodeTypes,
  ReasoncodeDeleteTypes
} from '../actions'

export const addReasoncodeStoreKey = 'addReasoncodeStore'

export const addReasoncodeReducer = (state = {}, action) => {
  switch (action.type) {
    case addReasoncodeTypes.REQUEST:
      return { ...state, loadingARC: true }
    case addReasoncodeTypes.RESPONSE:
      return { ...state, dataARC: action.data, statusARC: action.data.status, messageARC: action.data.message, loadingARC: false }
    case addReasoncodeTypes.CLEAR:
      return { ...state, dataARC: null, messageARC: '', statusARC: '' }
    default:
      return state
  }
}

export const ReasoncodeStoreKey = 'ReasoncodeStore'

export const ReasoncodeReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case ReasoncodeActionsTypes.GET_REASON_CODE:
      return { ...state, loadingGRC: true }
    case ReasoncodeActionsTypes.SAVE_REASON_CODE_RESPONSE:
      return { ...state, reasonCode: action.data, count: res.count, loadingGRC: false }
    case ReasoncodeActionsTypes.CLEAR_REASON_CODE:
      return { ...state, reasonCode: null, count: 0 }
    case ReasoncodeGetDetailsTypes.GET_REASON_CODE_DETAILS:
      return { ...state, loadingGRCD: true }
    case ReasoncodeGetDetailsTypes.REASON_CODE_DETAILS_RESPONSE:
      return {
        ...state,
        ReasoncodeDetails: res && res.data,
        statusGRCD: res && res.status,
        messagesGRC: res && res.message,
        loadingGRC: false
      }
    case ReasoncodeGetDetailsTypes.CLEAR_REASON_CODE_DETAILS:
      return { ...state, loadingGRCD: '', statusGRCD: '', messagesGRC: '' }
    default:
      return state
  }
}

export const editReasoncodeStoreKey = 'editReasoncodeStore'

export const editReasoncodeReducer = (state = {}, action) => {
  switch (action.type) {
    case editReasoncodeTypes.REQUEST:
      return { ...state, loadingERC: true }
    case editReasoncodeTypes.RESPONSE:
      return { ...state, dataERC: action.data && action.data, statusERC: action.data.status, messageERC: action.data.message, loadingERC: false }
    case editReasoncodeTypes.CLEAR:
      return { ...state, dataERC: null, messageERC: '', statusERC: '' }
    default:
      return state
  }
}

export const ReasoncodeDeleteStoreKey = 'ReasoncodeDeleteStore'

export const deleteReasoncodeReducer = (state = {}, action) => {
  const res = action.data
  switch (action.type) {
    case ReasoncodeDeleteTypes.DELETE_REASON_CODE:
      return { ...state, loading: true }
    case ReasoncodeDeleteTypes.SAVE_DELETE_REASON_CODE_RESPONSE:
      return {
        ...state,
        deleteReasoncode: res.data,
        deleteReasoncodeStatus: res.status,
        deleteReasoncodeMessage: res.message,
        loading: false
      }
    case ReasoncodeDeleteTypes.CLEAR_DELETE_REASON_CODE:
      return { ...state, deleteReasoncode: null, deleteReasoncodeStatus: '', deleteReasoncodeMessage: '' }
    default:
      return state
  }
}
