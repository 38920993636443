import {
  clientActionsTypes,
  usersRoleTypes,
  industryTypes,
  saveClientIdTypes
} from '../actions'

export const clientIdStoreKey = 'clientIdStore'

export const clientIdStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case saveClientIdTypes.SAVE:
      return { ...state, getCurrentClientId: action.data }
    case saveClientIdTypes.CLEAR:
      return { ...state, getCurrentClientId: null }
    default:
      return state
  }
}

export const clientInitialState = {
  list: null
}

export const clientStoreKey = 'clientStore'

export const clientReducer = (state = clientInitialState, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case clientActionsTypes.GET_CLIENT:
      return { ...state, loading: true }
    case clientActionsTypes.SAVE_CLIENT_RESPONSE:
      return { ...state, getClient: res.data, loading: false }
    case clientActionsTypes.CLEAR_CLIENT:
      return { ...state, getClient: null }
    default:
      return state
  }
}

export const userRoleStoreKey = 'userRoleStore'

export const userRoleReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case usersRoleTypes.REQUEST:
      return { ...state, loading: true }
    case usersRoleTypes.RESPONSE:
      return { ...state, gerUserRole: res.data, loading: false }
    case usersRoleTypes.CLEAR:
      return { ...state, gerUserRole: null }
    default:
      return state
  }
}

export const industryStoreKey = 'industryStore'

export const industryReducer = (state = {}, action) => {
  const res = action.data && action.data
  switch (action.type) {
    case industryTypes.REQUEST:
      return { ...state, loading: true }
    case industryTypes.RESPONSE:
      return { ...state, getIndustry: res.data, count: res.count, loading: false }
    case industryTypes.CLEAR:
      return { ...state, getIndustry: null }
    default:
      return state
  }
}
