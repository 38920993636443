import React, { useEffect, useState } from "react";
import { userInfoValidation } from "./validation";
import { addUserActions, userActions } from "../../../../store/actions";
import {
  STATUS_RESPONSE,
  REGEX,
  SAVE_CURRENT,
  SET_STORAGE,
  LOGGED_USER_ROLE,
  SESSION,
  API,
} from "../../../../utils/constants";
import { confirmationAlert, warningAlert } from "../../../../utils/alerts";
import { getLocalStorage, headersWithAuth } from "../../../../utils/helper";
import _ from "lodash";
import { connect } from "react-redux";

const AddUserInfo = (props) => {
  const {
    loadingAUD,
    statusAUD,
    messageAUD,
    addUserDispatch,
    clearUserDetailsDispatch,
    setAddForm,
    gerUserRole,
    getUserDispatch,
  } = props;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    roleId: "",
  });

  const isAuthorized = getLocalStorage(SESSION.TOKEN);
  const headercurrentWithApiKey = {
    "X-Custom-Header": "foobar",
    "api-key": `${getLocalStorage(API.API_KEY)}`,
  };
  const [errors, setErrors] = useState({});
  const currentClientID = getLocalStorage(SAVE_CURRENT.CLIENT_ID);
  const userRoleType = getLocalStorage(SET_STORAGE.SET_USER_ROLE);

  const handleChange = (e) => {
    e.persist();
    setFormData((values) => ({ ...values, [e.target.name]: e.target.value }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = () => {
    const errorMsg = userInfoValidation(formData, setErrors);
    if (_.isEmpty(errorMsg)) {
      const payload = {
        ...formData,
        clientId: currentClientID ? currentClientID : "",
      };
      addUserDispatch(
        payload,
        isAuthorized ? headersWithAuth : headercurrentWithApiKey
      );
    }
  };

  const onConfirm = () => {
    setAddForm(false);
    const params = {
      clientId: currentClientID ? currentClientID : "",
    };
    getUserDispatch(params, headersWithAuth);
  };

  useEffect(() => {
    if (statusAUD === STATUS_RESPONSE.SUCCESS_MSG) {
      confirmationAlert(
        "success",
        messageAUD,
        "success",
        "Back to User",
        "Ok",
        () => {
          onConfirm();
        },
        () => {
          onConfirm();
        }
      );
      clearUserDetailsDispatch();
    } else if (statusAUD === STATUS_RESPONSE.ERROR_MSG) {
      warningAlert(
        "error",
        messageAUD,
        "",
        "Cancel",
        "Ok",
        () => {},
        () => {}
      );
      clearUserDetailsDispatch();
    }
  }, [statusAUD]);

  useEffect(() => {
    if (
      gerUserRole &&
      gerUserRole.length > 0 &&
      userRoleType === LOGGED_USER_ROLE.CLIENT
    ) {
      let getCleintRole = _.find(gerUserRole, (item) => {
        return item.role === LOGGED_USER_ROLE.CLIENT ? item : null;
      });
      setFormData((values) => ({ ...values, roleId: getCleintRole._id }));
    }
  }, [gerUserRole]);

  return (
    <>
      <div className="bg-skyBlue">
        <div className="card-header bg-skyBlue py-10">
          <div className="card-body">
            <h2 className="mb-5">Login Info</h2>
            <div className="card-header bg-skyBlue py-10">
              <div className="card-body">
                <div className="form-group row mb-4">
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <div className="col-lg-2" />
                      <div className="col-lg-2">
                        <label className="font-size-xs font-weight-bold mb-2 required form-label mt-4">
                          First Name:
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group mb-5">
                          <span className="input-group-text" id="basic-addon3">
                            <i className="bi bi-person-circle" />
                          </span>
                          <input
                            name="firstName"
                            type="text"
                            className="form-control"
                            id="basic-url"
                            placeholder="First Name"
                            aria-describedby="basic-addon3"
                            onChange={(e) => handleChange(e)}
                            value={formData.firstName || ""}
                            autoComplete="off"
                            maxLength={42}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_CHARS_SPACE.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {errors && errors.firstName && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.firstName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <div className="col-lg-2" />
                      <div className="col-lg-2">
                        <label className="font-size-xs font-weight-bold mb-2 required form-label mt-4">
                          Last Name:
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group mb-5">
                          <span className="input-group-text" id="basic-addon3">
                            <i className="bi bi-person-circle" />
                          </span>
                          <input
                            name="lastName"
                            type="text"
                            className="form-control"
                            id="basic-url"
                            placeholder="Last Name"
                            aria-describedby="basic-addon3"
                            onChange={(e) => handleChange(e)}
                            value={formData.lastName || ""}
                            autoComplete="off"
                            maxLength={42}
                            onKeyPress={(e) => {
                              if (!REGEX.ALPHA_CHARS_SPACE.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {errors && errors.lastName && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.lastName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <div className="col-lg-2" />
                      <div className="col-lg-2">
                        <label className="font-size-xs font-weight-bold mb-2 required form-label mt-4">
                          Phone Number:
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group mb-5">
                          <span className="input-group-text" id="basic-addon3">
                            <i className="bi bi-telephone-fill" />
                          </span>
                          <input
                            name="mobile"
                            type="text"
                            className="form-control"
                            id="basic-url"
                            placeholder="Phone Number"
                            aria-describedby="basic-addon3"
                            onChange={(e) => handleChange(e)}
                            value={formData.mobile || ""}
                            autoComplete="off"
                            maxLength={10}
                            onKeyPress={(e) => {
                              if (!REGEX.NUMERIC.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {errors && errors.mobile && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.mobile}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <div className="col-lg-2" />
                      <div className="col-lg-2">
                        <label className="font-size-xs font-weight-bold mb-2 required form-label mt-4">
                          Email:
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group mb-5">
                          <span className="input-group-text" id="basic-addon3">
                            <i className="bi bi-envelope-fill" />
                          </span>
                          <input
                            name="email"
                            type="text"
                            className="form-control"
                            id="basic-url"
                            placeholder="Email"
                            aria-describedby="basic-addon3"
                            onChange={(e) => handleChange(e)}
                            value={formData.email || ""}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {errors && errors.email && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <div className="col-lg-2" />
                      <div className="col-lg-2">
                        <label className="font-size-xs font-weight-bold mb-2 required form-label mt-4">
                          Password:
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group mb-5">
                          <span className="input-group-text" id="basic-addon3">
                            <i className="bi bi-lock-fill" />
                          </span>
                          <input
                            name="password"
                            type="password"
                            className="form-control"
                            id="basic-url"
                            placeholder="Password"
                            aria-describedby="basic-addon3"
                            onChange={(e) => handleChange(e)}
                            value={formData.password || ""}
                            autoComplete="off"
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {errors && errors.password && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <div className="col-lg-12 mb-3">
                    <div className="row">
                      <div className="col-lg-2" />
                      <div className="col-lg-2">
                        <label className="font-size-xs font-weight-bold mb-2 required form-label mt-4">
                          Confirm Password:
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group mb-5">
                          <span className="input-group-text" id="basic-addon3">
                            <i className="bi bi-lock" />
                          </span>
                          <input
                            name="cPassword"
                            type="password"
                            className="form-control"
                            id="basic-url"
                            placeholder="Confirm Password"
                            aria-describedby="basic-addon3"
                            onChange={(e) => handleChange(e)}
                            value={formData.cPassword || ""}
                            autoComplete="off"
                            maxLength={42}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onKeyPress={(e) => {
                              if (
                                !REGEX.ALPHA_NUMERIC_CHARS_SPACE.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        {errors && errors.cPassword && (
                          <div className="rr mt-1">
                            <style>{".rr{color:red;}"}</style>
                            {errors.cPassword}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {userRoleType !== LOGGED_USER_ROLE.CLIENT ? (
                  <div className="form-group row mb-4">
                    <div className="col-lg-12 mb-3">
                      <div className="row">
                        <div className="col-lg-2" />
                        <div className="col-lg-2">
                          <label className="font-size-xs font-weight-bold mb-2 required form-label mt-4">
                            Role:
                          </label>
                        </div>
                        <div className="col-lg-4">
                          <div className="input-group mb-5">
                            <span
                              className="input-group-text"
                              id="basic-addon3"
                            >
                              <i className="bi bi-envelope" />
                            </span>
                            <select
                              name="roleId"
                              className="form-select form-select-solid"
                              data-control="select"
                              data-placeholder="Select an option"
                              data-allow-clear="true"
                              onChange={(e) => handleChange(e)}
                              value={formData.roleId || ""}
                            >
                              <option value="">Select Role...</option>
                              {gerUserRole &&
                                gerUserRole.length > 0 &&
                                gerUserRole.map((item, i) => (
                                  <option key={i} value={item._id}>
                                    {item.role}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {errors && errors.roleId && (
                            <div className="rr mt-1">
                              <style>{".rr{color:red}"}</style>
                              {errors.roleId}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row mt-4">
              <div className="col-lg-6" />
              <div className="col-lg-6">
                <div className="col-lg-11">
                  <button
                    className="btn btn-success m-2 fa-pull-right"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {loadingAUD ? (
                      <span
                        className="spinner-border spinner-border-sm mx-3"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { addUserStore, userRoleStore, clientIdStore } = state;
  return {
    statusAUD:
      addUserStore && addUserStore.statusAUD ? addUserStore.statusAUD : "",
    messageAUD:
      addUserStore && addUserStore.messageAUD ? addUserStore.messageAUD : "",
    loadingAUD:
      addUserStore && addUserStore.loadingAUD ? addUserStore.loadingAUD : false,
    gerUserRole:
      userRoleStore && userRoleStore.gerUserRole
        ? userRoleStore.gerUserRole
        : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  addUserDispatch: (data, headers) =>
    dispatch(addUserActions.addUser(data, headers)),
  clearUserDetailsDispatch: () => dispatch(addUserActions.clearaddUser()),
  getUserDispatch: (params, headers) =>
    dispatch(userActions.getUser(params, headers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserInfo);
