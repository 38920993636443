import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES, SESSION, HEADER } from '../../utils/constants'
import { getLocalStorage } from '../../utils/helper'
import axiosInstance from '../../services'
import {
  addUploadsCBTypes,
  addUploadsCBActions,
  addUploadsCBstatusTypes,
  addUploadsCBstatusActions
} from '../actions'
import serviceList from '../../services/serviceList'

const headers = {
  'Content-Type': 'multipart/form-data',
  [`${HEADER.TOKEN}`]: `Bearer ${getLocalStorage(SESSION.TOKEN)}`
}

function * addUpload (action) {
  const { payload, url } = action
  const uploadUrl = url || serviceList.uploadChargeback
  try {
    const json = yield axiosInstance.post(uploadUrl, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addUploadsCBActions.saveaddUploadsCBResponse(json.data))
    } else {
      yield put(addUploadsCBActions.clearaddUploadsCB([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addUploadsCBTypes.RESPONSE, data })
  }
}

function * addUploadCBStatus (action) {
  const { payload, url } = action
  const uploadUrl = url || serviceList.uploadChargebackStatus
  try {
    const json = yield axiosInstance.post(uploadUrl, payload, { headers: headers })
    if (json.status === 200) {
      yield put(addUploadsCBstatusActions.saveResponse(json.data))
    } else {
      yield put(addUploadsCBstatusActions.clear([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addUploadsCBstatusTypes.RESPONSE, data })
  }
}

export function * fetchUploadsCBWatcher () {
  yield all([
    yield takeLatest(addUploadsCBTypes.REQUEST, addUpload),
    yield takeLatest(addUploadsCBstatusTypes.REQUEST, addUploadCBStatus),
  ])
}
