import { put, takeLatest, all } from 'redux-saga/effects'
import { API_MESSAGES } from '../../utils/constants'
import axiosInstance from '../../services'
import {
  addIndustrySettingsActionsTypes,
  addIndustrySettingsActions,
  getIndustrySettingsDetailsActionsTypes,
  getIndustrySettingsDetailsActions,
  updateIndustrySettingsActionsTypes,
  updateIndustrySettingsActions,
  deleteIndustrySettingsActionsTypes,
  deleteIndustrySettingsActions
} from '../actions'
import serviceList from '../../services/serviceList'

function * addIndustrySettings (action) {
  const { payload, headers } = action
  try {
    const json = yield axiosInstance.post(serviceList.IndustrySettings, payload, {headers:headers})
    if (json.status === 200) {
      yield put(addIndustrySettingsActions.saveResponse(json.data))
    } else {
      yield put(addIndustrySettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: addIndustrySettingsActionsTypes.RESPONSE, data })
  }
}

function * getIndustrySettingsDetails (actions) {
  const { id, headers } = actions
  const endPointUrl = `${serviceList.IndustrySettings}/${id}`
  try {
    const json = yield axiosInstance.get(endPointUrl, {headers:headers})
    if (json.status === 200) {
      yield put(
        getIndustrySettingsDetailsActions.saveResponse(json.data)
      )
    } else {
      yield put(getIndustrySettingsDetailsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: getIndustrySettingsDetailsActionsTypes.RESPONSE, data })
  }
}

function * updateIndustrySettings (actions) {
  const { payload, headers } = actions
  const { id, params } = payload && payload
  const endPointUrl = `${serviceList.IndustrySettings}/${id}`
  try {
    const json = yield axiosInstance.put(endPointUrl, params, {headers:headers})
    if (json.status === 200) {
      yield put(updateIndustrySettingsActions.saveResponse(json.data))
    } else {
      yield put(updateIndustrySettingsActions.saveResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: updateIndustrySettingsActionsTypes.RESPONSE, data })
  }
}

function * deleteIndustrySettings (actions) {
  const { params, headers } = actions
  const deleteUrl = `${serviceList.IndustrySettings}/${params} `
  try {
    const json = yield axiosInstance.delete(deleteUrl, {headers:headers})
    if (json.status === 200) {
      yield put(deleteIndustrySettingsActions.savemResponse(json.data))
    } else {
      yield put(deleteIndustrySettingsActions.savemResponse([]))
    }
  } catch (error) {
    const data = { status: 'error', message: API_MESSAGES.SOMETHING_WRONG }
    yield put({ type: deleteIndustrySettingsActionsTypes.RESPONSE, data })
  }
}

export function * fetchIndustrySettingsWatcher () {
  yield all([
    yield takeLatest(addIndustrySettingsActionsTypes.REQUEST, addIndustrySettings),
    yield takeLatest(getIndustrySettingsDetailsActionsTypes.REQUEST, getIndustrySettingsDetails),
    yield takeLatest(updateIndustrySettingsActionsTypes.REQUEST, updateIndustrySettings),
    yield takeLatest(deleteIndustrySettingsActionsTypes.REQUEST, deleteIndustrySettings)
  ])
}
