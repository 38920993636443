import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { AuthPage } from "../containers/auth";
import ClientSignupPage from "../containers/OnboardClient/index";
import CreateCase from "../containers/createCase/index";
import ImportCase from "../containers/createCase/import";
import CreateCaseAlerts from "../containers/createCase/CreateCaseAlerts";
import ImportAlertCase from "../containers/createCase/importAlert";
import ChargebackEvidence from "../components/ChargebackEvidence/ChargebackEvidence";
import MerchantEvidence from "../components/MerchantEvidenece/MerchantEvi";
import ChargebackList from "../components/chargebackManagement/chargebackManagement";
import Chargeback from "../components/chargebackManagement/chargeBack";
import AddForms from "../components/chargebackManagement/addChargeback/addChargeback";
import UploadChargeback from "../components/chargebackManagement/uploadChargeback/uploadChargeback";
import CBData from "../components/chargebackManagement/chargebackDetails/cbData";
import MailHistory from "../components/chargebackManagement/chargebackDetails/MailHistory";
import Clients from "../components/clientManagement/clients";
import ClientOnboarding from "../components/clientManagement/onboarding/clientOnboarding";
// import AddMerchant from '../containers/clientManagement/index'
import AuthFail from "../components/auth/AuthFail";
import AddTemplateForm from "../components/clientManagement/onboarding/userDetails/EmailTemplate/AddTemplateForm";
import AddMerchant from "../components/clientManagement/merchant/addMerchant";
import ChargebackSummaryAWS from "../components/chargebackSummary/chargebackSummaryAWS";
import RootCauseAnalyzerAWS from "../components/rootCauseAnalyzerAWS/rootCauseAnalyzerAWS";
import PreventionAlertAWS from "../components/preventionAlertAWS/preventionAlertAWS";
import PerformanceAnalyzerAWS from "../components/performanceAnalyzerAWS/performanceAnalyzerAWS";
import FraudAnalyzerAWS from "../components/fraudAnalyzerAWS/fraudAnalyzerAWS";
import PreventionInquiriesIntelligenceAWS from "../components/preventionInquiriesIntelligenceAWS/preventionInquiriesIntelligenceAWS";
import ShopifyLogin from "../containers/OnboardClient/clientSignUp/shopifyLogin";
import ShopifySubscription from "../containers/OnboardClient/clientSignUp/shopifySubscription";
import ShopifyGetStarted from "../containers/OnboardClient/clientSignUp/shopifyGetStarted";
import ShopifySelectCrm from "../containers/OnboardClient/clientSignUp/shopifySelectCrm";
import ShopifyBusinessModel from "../containers/OnboardClient/clientSignUp/shopifyBusinessModel";
import ShopifyPrevention from "../containers/OnboardClient/clientSignUp/shopifyPrevention";

export function PublicRoutes() {
  const pathName = useLocation().pathname;
  useEffect(() => {
    localStorage.removeItem("isAuthorized");
  }, []);

  const publicUrlEndPoints = [
    "/client-signup-page",
    "/client-signup",
    "/get-started",
    "/select-processor",
    "/select-crm",
    "/select-service",
    "/select-package",
    "/package-subscription",
    "/contact-details",
    "/issuerbank-inquiries-sdk",
    "/issuerbank-alerts-sdk",
    "/import-inquiries",
    "/import-alert",
    "/chargeback/evidence",
    "/manage-chargeback",
    "/chargeback/merchant",
    "/auth-failed",
    "/session-exist",

    "/shopify-login",
    "/shopify-subscription",
    "/shopify-startpage",
    "/shopify-selectcrm",
    "/shopify-businessmodel",
    "/shopify-prevention",
  ];

  return (
    <Switch>
      {publicUrlEndPoints.includes(pathName) ||
      pathName.includes("/get-started") ||
      pathName.includes("/chargeback/evidence") ||
      pathName.includes("/chargeback/merchant") ||
      pathName.includes("/chargeback-management/select-chargeback") ||
      pathName.includes("/chargeback-management/add-chargeback") ||
      pathName.includes("/chargeback-management/upload-chargeback") ||
      pathName.includes("/chargeback-management/update-chargeback/") ||
      pathName.includes(
        "/chargeback-management/update-chargeback/:id/mail-history"
      ) ||
      pathName.includes("/client-onboarding/update/:id") ||
      pathName.includes("/client-onboarding") ||
      pathName.includes("/client-onboarding/update") ||
      pathName.includes("/client-onboarding/update-merchant") ||
      pathName.includes("/client-onboarding/update/:id/add-template") ||
      pathName.includes("/client-onboarding/update/:id/update-template/:id") ||
      pathName.includes("/client-onboarding/update-merchant/") ||
      pathName.includes("/manage-client") ||
      pathName.includes("/chargeback-summary-dashboard") ||
      pathName.includes("/rootcause-analyzer-dashboard") ||
      pathName.includes("/prevention-alerts-dashboard") ||
      pathName.includes("/performance-analyzer-dashboard") ||
      pathName.includes("/fraud-analyzer-dashboard") ||
      pathName.includes("/prevention-inquiries-dashboard") ? (
        <>
          <Route path="/session-exist" component={AuthFail} />
          <Route path="/auth-failed" component={AuthFail} />
          <Route path="/issuerbank-inquiries-sdk" component={CreateCase} />
          <Route path="/issuerbank-alerts-sdk" component={CreateCaseAlerts} />
          <Route path="/import-inquiries" component={ImportCase} />
          <Route path="/import-alert" component={ImportAlertCase} />
          <Route
            path="/chargeback/evidence/:id"
            component={ChargebackEvidence}
          />
          <Route path="/chargeback/merchant/:id" component={MerchantEvidence} />
          <Route path="/client-signup-page" component={ClientSignupPage} />
          <Route path="/client-signup" component={ClientSignupPage} />
          <Route path="/get-started/:id" component={ClientSignupPage} />
          <Route path="/select-processor" component={ClientSignupPage} />
          <Route path="/select-crm" component={ClientSignupPage} />
          <Route path="/select-service" component={ClientSignupPage} />
          <Route path="/select-package" component={ClientSignupPage} />
          <Route path="/package-subscription" component={ClientSignupPage} />
          <Route path="/contact-details" component={ClientSignupPage} />
          <Route path="/manage-chargeback" component={ChargebackList} />
          <Route
            path="/chargeback-management/select-chargeback"
            component={Chargeback}
          />
          <Route
            path="/chargeback-management/add-chargeback"
            component={AddForms}
          />
          <Route
            path="/chargeback-management/upload-chargeback"
            component={UploadChargeback}
          />
          <Route
            exact
            path="/chargeback-management/update-chargeback/:id"
            component={CBData}
          />
          <Route
            exact
            path="/chargeback-management/update-chargeback/:id/mail-history"
            component={MailHistory}
          />
          <Route path="/manage-client" component={Clients} />
          <Route exact path="/client-onboarding" component={ClientOnboarding} />
          <Route
            exact
            path="/client-onboarding/update/:id"
            component={ClientOnboarding}
          />
          <Route
            exact
            path="/client-onboarding/update/:id/add-template"
            component={AddTemplateForm}
          />
          <Route
            exact
            path="/client-onboarding/update/:id/update-template/:id"
            component={AddTemplateForm}
          />
          <Route
            exact
            path="/client-onboarding/update-merchant/:id"
            component={AddMerchant}
          />
          <Route
            path="/chargeback-summary-dashboard"
            component={ChargebackSummaryAWS}
          />
          <Route
            path="/rootcause-analyzer-dashboard"
            component={RootCauseAnalyzerAWS}
          />
          <Route
            path="/prevention-alerts-dashboard"
            component={PreventionAlertAWS}
          />
          <Route
            path="/performance-analyzer-dashboard"
            component={PerformanceAnalyzerAWS}
          />
          <Route
            path="/fraud-analyzer-dashboard"
            component={FraudAnalyzerAWS}
          />
          <Route
            path="/prevention-inquiries-dashboard"
            component={PreventionInquiriesIntelligenceAWS}
          />
          <Route path="/shopify-login" component={ShopifyLogin} />
          <Route path="/shopify-subscription" component={ShopifySubscription} />
          <Route path="/shopify-startpage" component={ShopifyGetStarted} />
          <Route path="/shopify-selectcrm" component={ShopifySelectCrm} />
          <Route
            path="/shopify-businessmodel"
            component={ShopifyBusinessModel}
          />
          <Route path="/shopify-prevention" component={ShopifyPrevention} />
        </>
      ) : (
        <>
          <Route path="/" component={AuthPage} />
        </>
      )}
    </Switch>
  );
}
