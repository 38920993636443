export const addUserRegisterTypes = {
  REQUEST: 'ADD_USER_REGISTER_REQUEST',
  RESPONSE: 'ADD_USER_REGISTER_RESPONSE',
  ERROR: 'ADD_USER_REGISTER_ERROR',
  CLEAR: 'ADD_USER_REGISTER_CLEAR'
}

export const addUserRegisterActions = {
  add: (data, headers) => {
    return {
      type: addUserRegisterTypes.REQUEST,
      payload: data, headers
    }
  },
  saveResponse: (data) => {
    return {
      type: addUserRegisterTypes.RESPONSE,
      data
    }
  },
  clear: () => ({
    type: addUserRegisterTypes.CLEAR
  })
}